import * as React from 'react';

import { useLifecycle } from '../utils';

import { cmdCtrlKey } from './cmdCtrlKey';

export type ShortcutCallback = () => void;

export type Shortcut = Partial<Pick<KeyboardEvent, 'key' | 'shiftKey'>> & {cmdCtrl?:boolean, focus?:HTMLElement};

interface ShortcutInfo {
  shortcut:Shortcut;
  cb:ShortcutCallback;
}

export class ShortcutManager {
  static shortcuts:ShortcutInfo[] = [];

  static onKeyDown = (event:KeyboardEvent) => {
    const pos = this.find({key: event.key, shiftKey: event.shiftKey, cmdCtrl: cmdCtrlKey(event)});

    if (pos === -1) {
      return;
    }

    event.preventDefault();

    const combination = this.shortcuts[pos];
    combination.cb();
  }

  static find(shortcut:Shortcut, cb?:ShortcutCallback) {
    const invoking = !cb;

    if (!shortcut.shiftKey && !shortcut.cmdCtrl) {
      return -1;
    }

    return this.shortcuts.findIndex(s => {
      return (cb === undefined || s.cb === cb) &&
        s.shortcut.key == shortcut.key && 
        (s.shortcut.shiftKey === undefined || s.shortcut.shiftKey === shortcut.shiftKey) &&
        (s.shortcut.cmdCtrl === undefined || s.shortcut.cmdCtrl === shortcut.cmdCtrl) &&
        ((!invoking && s.shortcut.focus == shortcut.focus) || 
          (invoking && (!s.shortcut.focus || s.shortcut.focus.contains(document.activeElement))))
    });
  }

  static add(shortcut:Shortcut, cb:ShortcutCallback):void {
    this.shortcuts.push({shortcut, cb});
  }

  static remove(shortcut:Shortcut, cb:ShortcutCallback):void {
    const pos = this.find(shortcut, cb);

    if (pos == -1) {
      return;
    }

    this.shortcuts.splice(pos, 1);
  }
}

document.addEventListener('keydown', ShortcutManager.onKeyDown, true);

export function useShortcut(shortcut:Shortcut, cb:ShortcutCallback) {
  const info = React.useRef<{shortcut:Shortcut, cb:ShortcutCallback}>(null);
  const onShortcutMemo = React.useCallback(onShortcut, []);

  useLifecycle({onUnmount});

  const cur = info.current?.shortcut;
  const changed = cur?.key != shortcut?.key || cur?.shiftKey != shortcut?.shiftKey || cur?.cmdCtrl != shortcut?.cmdCtrl || cur?.focus != shortcut?.focus;

  if (changed) {
    add();
  }

  if (info.current) {
    info.current.cb = cb;
  }

  function onUnmount() {
    remove();
  }

  function add() {
    remove();

    if (!shortcut) {
      return;
    }

    info.current = {shortcut, cb};

    ShortcutManager.add(info.current.shortcut, onShortcutMemo);
  }

  function remove() {
    if (!info.current) {
      return;
    }

    ShortcutManager.remove(info.current.shortcut, onShortcutMemo);
    info.current = null;
  }

  function onShortcut() {
    info.current.cb();
  }
}
