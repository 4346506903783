import * as React from 'react'

import { Box, BoxProps } from 'app/app2/components'

interface Props extends BoxProps {
  type: 'vertical' | 'horizontal';
}

export function MenuSeparator(props:Props) {
  const {type, ...remaining} = props;

  return type == 'vertical'
    ? renderVertical(remaining)
    : renderHorizontal(remaining)
}

function renderVertical(props:BoxProps) {
  return <Box height='100%' borderRight='solid 1px' borderColor='border' {...props} />
}

function renderHorizontal(props:BoxProps) {
  return <Box width='100%' borderBottom='solid 1px' borderColor='border' {...props} />
}

MenuSeparator.defaultProps = {
  type: 'horizontal'
}