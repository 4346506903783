import { DeepPartial, Event } from 'app2/api';

import { longDateFormatter } from 'app2/components';

export class EventUtils {
  static sortEvents(a:Pick<Event, 'createdAt'>, b:Pick<Event, 'createdAt'>):number {
    return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
  }

  static displayableEvent(e:Pick<Event, 'eventName'>):boolean {
    return e.eventName in eventNames;
  }

  static formatEvent(e:DeepPartial<Pick<Event, 'createdAt' | 'eventName' | 'contact'>>):string {
    return `${longDateFormatter(e.createdAt)} ${eventNames[e.eventName]} sent to ${e.contact.email}`
  }
}

const eventNames:Record<string, string> = {
  'vendor_invite': 'Course request',
  'course_request_reminder_vendor': 'Course request reminder',
}
