import * as React from 'react';
import { useParams } from 'react-router';

import { SeasonUtils } from 'app2/api';
import { VBox, Link } from 'app2/components';

import { SeasonRouteParams } from '../organizerRoutes';
import { useOrganizerSeasonQuery } from '../shared';

export function useOldReportsNoneMsg() {
  const { season } = useParams<SeasonRouteParams>();
  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId:season } });

  return seasonResult?.data && SeasonUtils.oldReports(seasonResult.data.season)
    ? <VBox maxWidth='500px' textAlign='center' vItemSpace='$20'>
      <div>We have migrated to a new system to enhance our reporting functionality. Our old reports are not formatted to meet these new requirements.</div>
      <div> To request reports for activities prior to August 1st, 2019, please email <Link to='mailto:hello@homeroom.com'>hello@homeroom.com</Link> and a member of our team will reach out shortly with the requested report.'</div>
    </VBox>
    : 'No transactions to show'
}
