import * as React from 'react'

import { Box, HBox, VBox, BoxProps } from './Box';
import { Icon, IconNames } from './icons';
import { Subtitle1, Subtitle2 } from './Text';
import { flattenChildren } from './utils'

export interface TitleProps extends BoxProps {
  icon?:IconNames;
  title?:React.ReactNode;
  subtitle?:React.ReactNode;
  primaryActions?:React.ReactNode;
}

export function Title(props:TitleProps) {
  const {icon, title, subtitle, primaryActions, flexWrap, ...remaining} = props;

  // This is needed because we can get an node that is an array of null nodes,
  // flattendChildren removes those so we can accurately check if there's no actions.
  const actions = flattenChildren(primaryActions);

  if (!icon && !title && !actions.length && !subtitle) {
    return <></>;
  }

  const hasLeftSide = Boolean(icon || title);

  return <VBox {...remaining}>
    <HBox vAlign='center' flexWrap={flexWrap || 'wrap'} gap='20px 8px'>
      {hasLeftSide && <HBox vAlign='center' gap='20px 8px'>{icon && <Icon name={icon} size='medium' />}{title && <Subtitle1>{title}</Subtitle1>}</HBox>}

      <Box flex={1} display={[!hasLeftSide ? 'none' : 'block', 'block']} />{primaryActions}
    </HBox>
    {subtitle && <Subtitle2 mt='$4'>{subtitle}</Subtitle2>}
  </VBox>
}