import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSitePendingRefundsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminSitePendingRefundsQuery = {
  site?:
    | {
        id: string;
        pendingRefunds?:
          | Array<{
              id: string;
              createdAt: string;
              amount: number;
              parent?: { id: string; name?: string | null | undefined } | null | undefined;
              course?: { id: string; name?: string | null | undefined } | null | undefined;
              student?: { id: string; name: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminSitePendingRefundsDocument = gql`
  query AdminSitePendingRefunds($id: ID!) {
    site(site: $id) {
      id
      pendingRefunds {
        id
        createdAt
        amount
        parent {
          id
          name
        }
        course {
          id
          name
        }
        student {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminSitePendingRefundsArgs = MakeOptional<UseQueryArgs<AdminSitePendingRefundsQueryVariables, AdminSitePendingRefundsQuery>, 'query'>;

export function useAdminSitePendingRefundsQuery(options: AdminSitePendingRefundsArgs = {}) {
  return useQuery<AdminSitePendingRefundsQueryVariables, AdminSitePendingRefundsQuery>({ query: AdminSitePendingRefundsDocument, ...options });
}

export type AdminSitePendingRefundsOptions = ExecQueryOptions<AdminSitePendingRefundsQueryVariables>;

export function adminSitePendingRefunds(options: AdminSitePendingRefundsOptions) {
  return executeQuery<AdminSitePendingRefundsQuery, AdminSitePendingRefundsQueryVariables>(AdminSitePendingRefundsDocument, options);
}

export type AdminSitePendingRefundsSelections = AdminSitePendingRefundsQuery['site'];
