import * as React from 'react'

import { ButtonStrip, ButtonStripProps } from 'app2/components';
import { abbreviatedDayOptions } from 'app2/views/shared-public';

export interface DaysButtonStripProps extends Omit<ButtonStripProps, 'options'> {
}

export function DaysButtonStrip(props:DaysButtonStripProps) {
  return <ButtonStrip width='370px' multiple options={abbreviatedDayOptions} {...props} />
}

DaysButtonStrip.fieldProps = ButtonStrip.fieldProps;
