import * as React from 'react'

import { Text, TextProps } from './Text';

// does a few things
//  - preserves whitespace
//  - detects hyperlinks and makes them clickable
//
//  only supports text children

export function FormattedText(props:TextProps) {
  const {children, ...remaining} = props;

  const converted = convertLinks();

  function render() {
    return converted
      ? <Text preserveWhitespace css={linkCss} dangerouslySetInnerHTML={{__html: converted}} {...remaining} />
      : <Text preserveWhitespace {...props} />
  }

  function convertLinks() {
    return React.useMemo(() => {
      if (typeof children != 'string') {
        return;
      }

      return convertToClickableLinks(children)
    }, [children]);
  }

  return render();
}

FormattedText.fieldProps = {
  valueProperty:'children'
}

const linkCss = {
  '> a': {
    textDecoration: 'underline'
  }
}

function convertToClickableLinks(text:string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/g;
  const phoneRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g;

  text = text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
  });

  text = text.replace(emailRegex, function(email) {
      return '<a href="mailto:' + email + '">' + email + '</a>';
  });

  text = text.replace(phoneRegex, function(phone) {
      return '<a href="tel:' + phone + '">' + phone + '</a>';
  });

  return text;
}
