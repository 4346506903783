import { UserPreferences } from '../shared';

export const authorizedCoursePreferenceKeys = {
  activityTab: 'activityTab',
  paidEnrollmentsTable: 'paidEnrollmentsTable',
  billedEnrollmentsTable: 'billedEnrollmentsTable',
  billedAdvancedEnrollmentsTable: 'billedAdvancedEnrollmentsTable',
  billedUsageEnrollmentsTable: 'billedUsageEnrollmentsTable',
  billedLaterUsageEnrollmentsTable: 'billedLaterUsageEnrollmentsTable',
  billedLaterAdvancedEnrollmentsTable: 'billedLaterAdvancedEnrollmentsTable',
  waitlistedEnrollmentsTable: 'waitlistedEnrollmentsTable',
  removedEnrollmentsTable: 'removedEnrollmentsTable',
  attendanceTable: 'attendanceTable',
  courseVariantsTable: 'courseVariantsTable',
};

export class CoursePreferences extends UserPreferences {
  course: string;

  constructor(course: string) {
    super();

    this.course = course;
  }

  get activityTab(): number {
    return this.load(authorizedCoursePreferenceKeys.activityTab);
  }

  set activityTab(value: number) {
    this.save(authorizedCoursePreferenceKeys.activityTab, value);
  }

  get paidEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.paidEnrollmentsTable);
  }

  set paidEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.paidEnrollmentsTable, value);
  }

  get billedEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.billedEnrollmentsTable);
  }

  set billedEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.billedEnrollmentsTable, value);
  }

  get billedAdvancedEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.billedAdvancedEnrollmentsTable);
  }

  set billedAdvancedEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.billedAdvancedEnrollmentsTable, value);
  }

  get billedUsageEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.billedUsageEnrollmentsTable);
  }

  set billedUsageEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.billedUsageEnrollmentsTable, value);
  }

  get billedLaterUsageEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.billedLaterUsageEnrollmentsTable);
  }

  set billedLaterUsageEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.billedLaterUsageEnrollmentsTable, value);
  }

  get billedLaterAdvancedEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.billedLaterAdvancedEnrollmentsTable);
  }

  get waitlistedEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.waitlistedEnrollmentsTable);
  }

  set waitlistedEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.waitlistedEnrollmentsTable, value);
  }

  get removedEnrollmentsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.removedEnrollmentsTable);
  }

  set removedEnrollmentsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.removedEnrollmentsTable, value);
  }

  get attendanceTable(): string {
    return this.load(authorizedCoursePreferenceKeys.attendanceTable);
  }

  set attendanceTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.attendanceTable, value);
  }

  get courseVariantsTable(): string {
    return this.load(authorizedCoursePreferenceKeys.courseVariantsTable);
  }

  set courseVariantsTable(value: string) {
    this.save(authorizedCoursePreferenceKeys.courseVariantsTable, value);
  }

  get path(): (string | number)[] {
    return super.path.concat(this.course);
  }
}

// this can't be a prop on user preferences because it extends it
export function authorizedCoursePreferences(course: string) {
  return new CoursePreferences(course);
}

