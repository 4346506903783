import * as React from 'react'

import { Team as TeamBase } from '../shared'

import { adminCompanyMemberAdd, adminCompanyMemberRemove, useAdminCompanyTeamQuery } from './generated';

export function Team() {
  return <TeamBase query={useAdminCompanyTeamQuery} 
    addTeamMember={(company, user) => adminCompanyMemberAdd({variables:{company, user}})} 
    removeTeamMember={(company, user) => adminCompanyMemberRemove({variables:{company, user}})} />
}