import * as React from 'react'
import { useHistory } from 'react-router';

import { UserRole } from 'app2/api';
import { FormModel, Panel, RadioGroup, Redirect, Section, useForm } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';

import { UserPage } from '../UserPage';
import { useUserInvitesQuery } from '../invites';

import { AccountType } from './AccountType';
import { userAddRole } from './generated';
import parent from './signup-parent.svg'
import organizer from './signup-organizer.svg'
import provider from './signup-provider.svg'

export function AddRole() {
  const history = useHistory();
  const user = useCurrentUser();
  const form = useForm({role: undefined as UserRole}, []);

  const [invites] = useUserInvitesQuery();

  function render() {
    if (!invites.data) {
      return <></>
    }

    if (invites.data.user.invites.length > 0) {
      return <Redirect to='/user/invites' />
    }

    return <UserPage useMaxWidth navigation={false}>
        <Panel title='Which of the following best describes you?' type='edit' onOk={onOk} ok='Continue' form={form} onNavigation='nothing'>
          <Section name='role' required component={<RadioGroup component={AccountType} options={options} layout={['vbox', 'hbox']} />} />
        </Panel>
    </UserPage>
  }

  async function onOk(form:FormModel) {
    const [success] = await userAddRole({variables: {role: form.values.role}});

    if (success) {
      await user.reexecute();
      history.replace(defaultRedirects[form.values.role] || '/');
    }

    return success;
  }

  return render();
}

const options = [
  {label:'Parent', value:UserRole.Parent, image:parent, description:'Sign up to manage your family’s activities and enroll in classes.'},
  {label:'Organizer', value:UserRole.Organizer, image:organizer, description:'Sign up to manage your organization’s activities and enroll in classes.'},
  {label:'Provider', value:UserRole.Vendor, image:provider, description:'Sign up to list your activities and classes.'},
];

const defaultRedirects:any = {
  [UserRole.Vendor]: '/provider/quick-start-guide',
}