import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateVendorComplianceMutationVariables = Types.Exact<{
  attributes: Types.ComplianceRequirementInput;
}>;

export type UpdateVendorComplianceMutation = { vendorUpdateCompliance?: { requirement?: { id: string; target: { id: string } | { id: string } } | null | undefined } | null | undefined };

export const UpdateVendorComplianceDocument = gql`
  mutation UpdateVendorCompliance($attributes: ComplianceRequirementInput!) @urql(additionalTypenames: ["Vendor", "VendorTeacher"]) {
    vendorUpdateCompliance(attributes: $attributes) {
      requirement {
        id
        target {
          id
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateVendorComplianceOptions = ExecMutationOptions<UpdateVendorComplianceMutationVariables>;

export function updateVendorCompliance(options: UpdateVendorComplianceOptions) {
  return executeMutation<UpdateVendorComplianceMutation, UpdateVendorComplianceMutationVariables>(UpdateVendorComplianceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Vendor', 'VendorTeacher']) }
  });
}
