import * as React from 'react';

import { InputField, Modal, Form, Section, Text, useForm, VBox } from 'app2/components';

import { AdminUsersSelections, UserDropdown } from '../shared';
import { useSingleAdminUserQuery, SingleAdminUserSelections, mergeParents } from './generated';

interface Props {
  id:string;
}

export function MergeParents(props:Props) {
  const [result] = useSingleAdminUserQuery({variables: {id: props.id}});
  const user = result.data?.adminUser || ({} as SingleAdminUserSelections);

  const form = useForm<{user:AdminUsersSelections}>({}, []);
  const source = form.values.user?.name ? `${form.values.user.name} (${form.values.user.id})` : '[select user]';
  const destination = `${user.name} (${props.id})`;

  function render() {
    return <Modal title='Merge users' danger info={{type:'warning', message: 'This can not be undone', small: true, title: false, layout: 'hbox'}}>
      <Form form={form} onOk={onOk} editing>
        <Section label={`Choose which user to merge into ${destination}`} name='user' component={UserDropdown} />
        <Section label='Type merge! to confirm you want to merge' name='merge' validators={validateMerge} component={InputField} />
        <VBox>
          <Text text='body'>This will move the following <b>from</b> {source} <b>to</b> {destination}:</Text>
          <Text text='body'>- orders and enrollments</Text>
          <Text text='body'>- accounting data</Text>
          <Text text='body'>- if both users only have one family, and both families only have one parent, then the families will be merged (see family merge for details)</Text>
          <Text text='body'>- {source} will be marked deleted</Text>
          <Text text='body'>- {source}'s version history will stay on {source}</Text>
          <Text text='body'>- {source}'s event history will stay on {source}</Text>
        </VBox>
      </Form>
    </Modal>
  }

  async function onOk() {
    const [success] = await mergeParents({variables:{source: form.values.user.id, destination: props.id}})

    return success;
  }

  return render();
}

function validateMerge(value:string) {
  return value?.toLowerCase() == 'merge!'
    ? null
    : 'You must type merge! to confirm you want to merge the companies, which can not be undone'
}