import * as React from 'react';

import { FCT, useFormInfo } from '../form';
import { FieldWithLabelProps, FieldWithLabel } from './FieldWithLabel'

export type SectionProps<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any> = FieldWithLabelProps<T, P, C1, C2> & {
  continued?:boolean;
  pair?:boolean;
}

export function Section<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any>(props:SectionProps<T, P, C1, C2>) {
  const form = useFormInfo();
  const editing = form?.editing;
  const mobileHbox = !editing && props.pair;
  const mobileLayout = !mobileHbox ? 'vbox' : 'hbox'; 
  const mobileAlign = !mobileHbox ? undefined : 'baseline'; 
  const {layout, continued, paid, gap, ...remaining} = props;

  const formMode = form?.editing ? 'edit' : 'display';
  const childItemFlex = props.stretch === false || props[formMode]?.stretch === false ? undefined : 1;

  return <FieldWithLabel outer={{width:'100%', itemFlex:1, mt:props.continued ? '-22px' : undefined}} 
    inner={{layout:layout || [mobileLayout, mobileLayout, 'hbox'], width:props.width || '100%', maxWidth:props.maxWidth || '100%', alignItems:[mobileAlign, mobileAlign, 'baseline'], itemFlex:childItemFlex, gap:gap || (layout == 'vbox' ? '8px 30px' : '30px 8px')}} 
    {...remaining} labelProps={{text:'subtitle2', mb:'$8', ...props.labelProps}} />
}

