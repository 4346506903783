import * as React from 'react'

import { useForceUpdate } from './useForceUpdate';

// a ref that automatic initiates a redraw when the ref has changed
// useful for when you need the ref to render completely but its null
// in the first render

export function useRef<T>(initialValue?:T) {
  const ref = React.useRef<T>(initialValue);
  const forceUpdate = useForceUpdate();
  const was = ref.current;

  React.useEffect(() => {
    // seems like this shouldn't be needed given the dependency list below
    // but yet react seems to call this twice regardless
    const changed = was != ref.current;

    if (!changed) {
      return;
    }

    forceUpdate();
  }, [ref.current]);

  return ref;
}