import { omit } from 'lodash-es';

import { FieldWithLabelProps } from '../../panel';

import { DataTableColumn } from './DataTableColumn';

const nonFieldProps:(keyof DataTableColumn)[] = [
  'width',
  'header',
  'sortable',
  'filterable',
  'allowAdditionalFilterValues',
  'sort',
  'filter',
  'getFilterOptions',
  'filterType',
  'sortValue',
  'onSortFilter',
  'break'
]

export function stripNonFieldColProps<T = any>(cols:DataTableColumn<T>[]):FieldWithLabelProps<T>[] {
  return cols?.map(c => (omit(c, nonFieldProps)));
}
