import { hasAppMode } from '../AppModes';
import { embedMessageManager } from '../embed';

import { AnimateProperties, AnimateConfiguration, EaseOut } from './AnimateProperties';

// a few reasons for this customized scrolling code:
// - chrome and safari have a bug where "smooth" scrolling will
//   sometimes not work.
//
// - when the user holds down the arrow keys, it will cause repeated 
//   scrolling, that can get rather jerky.
//
// - support scrolling in an embeded frame

type AnimateInfo = {options:ScrollToOptions, scrolling:AnimateProperties};
const activeScrollers = new Map<HTMLElement, AnimateInfo>();

let scrollModeEnabled = true;

export function disableSmoothScrolling() {
  scrollModeEnabled = false;
}

export function smoothScrollingEnabled() {
  return scrollModeEnabled;
}

export function stopAllScrolling() {
  activeScrollers.forEach(({scrolling}) => scrolling.stop());
}

export function finishAllScrolling() {
  activeScrollers.forEach(({scrolling}) => scrolling.finish());
}

export function scrollTo(scroller:HTMLElement, options:ScrollToOptions) {
  const active = activeScrollers.get(scroller);

  if (active) {
    if (active.options.left == options.left && active.options.top == options.top) {
      // already scrolling to the specified coordinates so nothing is needed
      return;
    }

    // already scrolling but to a new location, stop the previous animation
    activeScrollers.delete(scroller);
    active.scrolling.stop();
  }

  if (hasAppMode('embed')) {
    embedMessageManager.sendMessage('scroll-to', options);
    return;
  }

  if (!scrollModeEnabled || options.behavior != 'smooth') {
    scroller.scrollTop = options.top;
    scroller.scrollLeft = options.left;
    return;
  }

  const distance = Math.max(Math.abs(scroller.scrollTop - options.top), Math.abs(scroller.scrollLeft - options.left))
  const length = Math.min(Math.ceil(distance / 250) * 250, 2000);
  const properties = [{name: 'scrollTop', to: Math.max(0, options.top)}, {name: 'scrollLeft', to: Math.max(0, options.left)}];
  const scrolling = new AnimateProperties({element: scroller, properties, duration: length, timing: EaseOut, cb: generateScrollEvent});

  const info = {options, scrolling}
  activeScrollers.set(scroller, info);

  scrollToAsync(scroller, info);

  return scrolling;
}

export async function scrollToAsync(scroller:HTMLElement, info:AnimateInfo) {
  await info.scrolling.animate()

  // the current scroll info can get replaced with a new one, so only remove it if it is still the same
  if (activeScrollers.get(scroller) === info) {
    activeScrollers.delete(scroller);
  }
}

// used during scroll animation to generate fake scroll events
// because they seem to not get generated by the browser.  this leads
// to shaking for elements using Sticky because they are
// getting scroll events less quickly than is occurring (so
// they don't update their positions, so you get laggy behavior).

function generateScrollEvent(config:AnimateConfiguration) {
  config.element.dispatchEvent(new Event('scroll'));
}
