import { useState, useEffect } from 'react';

type UseDownloadResult = {
  data: string | null;
  error: Error | null;
  loading: boolean;
};

export function useDownload(url: string):UseDownloadResult {
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          headers: { 'Content-Type': 'text/html' }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch resource: ${response.statusText}`);
        }

        const textData = await response.text();
        setData(textData);
      }
      catch (err) {
        setError(err as Error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, error, loading };
};

export default useDownload;