import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProviderMutationVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
  companyName: Types.Scalars['String'];
  contacts?: Types.InputMaybe<Array<Types.ContactInput> | Types.ContactInput>;
}>;

export type UpdateProviderMutation = { vendorUpdate?: { vendor?: { id: string } | null | undefined } | null | undefined };

export const UpdateProviderDocument = gql`
  mutation UpdateProvider($vendor: ID!, $companyName: String!, $contacts: [ContactInput!]) {
    vendorUpdate(vendor: $vendor, companyName: $companyName, contacts: $contacts) {
      vendor {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateProviderOptions = ExecMutationOptions<UpdateProviderMutationVariables>;

export function updateProvider(options: UpdateProviderOptions) {
  return executeMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UpdateProviderDocument, options);
}
