import { AxiosResponse } from 'axios';

export class ApiVersion {
  static version = '3';

  static checkVersion(response: AxiosResponse | Response) {
    const oldApiVersion = response.headers instanceof Headers ? response.headers.get('old_api_version') : response.headers.old_api_version;

    if (oldApiVersion) {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      
      // adds a new random value to the url to help try and prevent getting a cached response
      const randomValue = new Date().getTime();
      params.set('reload', randomValue.toString());
      url.search = params.toString();
      
      window.location.replace(url);
    }
  }
}
