import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type MessageSenderFieldsFragment = {
  role?: string | null | undefined;
  from: { __typename: 'SharedUser'; id: string; name?: string | null | undefined };
  students?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
};

export const MessageSenderFieldsFragmentDoc = gql`
  fragment messageSenderFields on MessageSender {
    from {
      id
      name
      __typename
    }
    role
    students {
      id
      name
    }
  }
`;
