import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyOrdersQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type FamilyOrdersQuery = {
  family?:
    | {
        receipts?:
          | Array<
              | { id: string; createdAt: string; total: number }
              | {
                  status: Types.PaymentStatus;
                  paymentFailureReason?: string | null | undefined;
                  id: string;
                  createdAt: string;
                  total: number;
                  items: Array<{
                    id: string;
                    total: number;
                    product:
                      | { typename: 'Donation'; site: { charityName?: string | null | undefined; name?: string | null | undefined } }
                      | {
                          id: string;
                          billPeriod?: string | null | undefined;
                          withdrawable?: boolean | null | undefined;
                          isOption: boolean;
                          typename: 'Enrollment';
                          enrollmentPriceTierUse?: { cartPriceTierUse: { name: string } } | null | undefined;
                          course: {
                            id: string;
                            name?: string | null | undefined;
                            lastWithdrawDate?: string | null | undefined;
                            courseFramework?: { id: string } | null | undefined;
                            company?: { slug: string; name?: string | null | undefined } | null | undefined;
                          };
                          priceConfig?:
                            | { kind: Types.PriceConfigKind; purchaseDescription?: string | null | undefined; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; purchaseDescription?: string | null | undefined; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; purchaseDescription?: string | null | undefined; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; purchaseDescription?: string | null | undefined; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; purchaseDescription?: string | null | undefined; updateDescription?: string | null | undefined }
                            | null
                            | undefined;
                          student?: { id: string; name: string } | null | undefined;
                        };
                  }>;
                }
              | { id: string; createdAt: string; total: number }
              | {
                  description?: string | null | undefined;
                  status: Types.PaymentStatus;
                  void: boolean;
                  id: string;
                  createdAt: string;
                  total: number;
                  order?: { id: string; createdAt: string } | null | undefined;
                  item: {
                    id: string;
                    product:
                      | { typename: 'Donation'; site: { charityName?: string | null | undefined; name?: string | null | undefined } }
                      | {
                          billPeriod?: string | null | undefined;
                          typename: 'Enrollment';
                          course: { id: string; name?: string | null | undefined; courseFramework?: { id: string } | null | undefined; company?: { slug: string; name?: string | null | undefined } | null | undefined };
                          student?: { id: string; name: string } | null | undefined;
                        };
                  };
                }
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FamilyOrdersDocument = gql`
  query FamilyOrders($family: ID!) {
    family(family: $family) {
      receipts {
        id
        createdAt
        total
        ... on Order {
          status
          paymentFailureReason
          items {
            id
            total
            product {
              ... on Enrollment {
                typename: __typename
                id
                billPeriod
                withdrawable
                enrollmentPriceTierUse {
                  cartPriceTierUse {
                    name
                  }
                }
                isOption
                course {
                  id
                  name
                  lastWithdrawDate
                  courseFramework {
                    id
                  }
                  company {
                    slug
                    name
                  }
                }
                priceConfig {
                  kind
                  purchaseDescription
                  updateDescription
                }
                student {
                  id
                  name
                }
              }
              ... on Donation {
                typename: __typename
                site {
                  charityName
                  name
                }
              }
            }
          }
        }
        ... on Refund {
          order {
            id
            createdAt
          }
          description
          status
          void
          item {
            id
            product {
              ... on Enrollment {
                typename: __typename
                billPeriod
                course {
                  id
                  name
                  courseFramework {
                    id
                  }
                  company {
                    slug
                    name
                  }
                }
                student {
                  id
                  name
                }
              }
              ... on Donation {
                typename: __typename
                site {
                  charityName
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyOrdersArgs = MakeOptional<UseQueryArgs<FamilyOrdersQueryVariables, FamilyOrdersQuery>, 'query'>;

export function useFamilyOrdersQuery(options: FamilyOrdersArgs = {}) {
  return useQuery<FamilyOrdersQueryVariables, FamilyOrdersQuery>({ query: FamilyOrdersDocument, ...options });
}

export type FamilyOrdersOptions = ExecQueryOptions<FamilyOrdersQueryVariables>;

export function familyOrders(options: FamilyOrdersOptions) {
  return executeQuery<FamilyOrdersQuery, FamilyOrdersQueryVariables>(FamilyOrdersDocument, options);
}

export type FamilyOrdersSelections = FamilyOrdersQuery['family'];
