import * as React from 'react'

import { NonePanel } from 'app2/views/shared-public'
import { CourseSchedule } from 'app2/views/shared'

import { useCompanyQuery, CompanyPage, useProviderPreference } from '../shared'

import schedule from './schedule.png'
import { useCompanyScheduleCoursesQuery } from './generated'

export function Schedule() {
  const { company, id, fetching } = useCompanyQuery(useCompanyScheduleCoursesQuery);
  const courses = company?.upcomingActiveOrRecentCourses;
  const [showWeekends, setShowWeekends] = useProviderPreference('showWeekends');

  function render() {
    return <CompanyPage title='Schedule'>
      {fetching
        ? <></>
        : !courses?.length 
          ? renderNone() 
          : <CourseSchedule courses={courses as any} weekends={showWeekends} onChangeWeekends={setShowWeekends}
            scheduleUrlBase={`/provider/${id}/schedule`} activityUrlBase={`/activities/manage`} />}
    </CompanyPage>
  }

  function renderNone() {
    return <NonePanel description='As you create more activities, watch your schedule fill up. This calendar will always show your ongoing and upcoming activities.' 
      image={schedule} add={company?.nonPartnerApproved ? 'Add an activity' : undefined} onAdd='/provider/activities?edit=enrichment' />
  }

  return render()
}