import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { MessageSenderFieldsFragmentDoc } from './messageSenderFields.generated';
import { MessageRecipientFieldsFragmentDoc } from './messageRecipientFields.generated';
export type MessageFieldsFragment = {
  id: string;
  threadId?: string | null | undefined;
  threadScope?: string | null | undefined;
  createdAt: string;
  preferredService?: Types.MessageServiceKind | null | undefined;
  systemMessage?: boolean | null | undefined;
  broadcast?: boolean | null | undefined;
  read?: boolean | null | undefined;
  subject?: string | null | undefined;
  from?:
    | { role?: string | null | undefined; from: { __typename: 'SharedUser'; id: string; name?: string | null | undefined }; students?: Array<{ id: string; name?: string | null | undefined }> | null | undefined }
    | null
    | undefined;
  to?:
    | Array<{
        smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        role?: string | null | undefined;
        to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
        toEntity?: { name?: string | null | undefined } | null | undefined;
        students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
      }>
    | null
    | undefined;
  cc?:
    | Array<{
        smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        role?: string | null | undefined;
        to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
        toEntity?: { name?: string | null | undefined } | null | undefined;
        students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
      }>
    | null
    | undefined;
  bcc?:
    | Array<{
        smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
        role?: string | null | undefined;
        to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
        toEntity?: { name?: string | null | undefined } | null | undefined;
        students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
      }>
    | null
    | undefined;
  labels?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
  body?: { text?: string | null | undefined; html?: string | null | undefined } | null | undefined;
};

export const MessageFieldsFragmentDoc = gql`
  fragment messageFields on Message {
    id
    threadId
    threadScope
    createdAt
    preferredService
    systemMessage
    broadcast
    read
    from {
      ...messageSenderFields
    }
    to {
      ...messageRecipientFields
    }
    cc {
      ...messageRecipientFields
    }
    bcc {
      ...messageRecipientFields
    }
    labels {
      id
      name
    }
    subject
    body {
      text
      html
    }
  }
  ${MessageSenderFieldsFragmentDoc}
  ${MessageRecipientFieldsFragmentDoc}
`;
