import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonImportMutationVariables = Types.Exact<{
  season: Types.Scalars['ID'];
  importFrom: Types.Scalars['ID'];
}>;

export type SeasonImportMutation = {
  seasonImport?:
    | { season?: { id: string; courseKindGroups: Array<Types.CourseKindGroup> } | null | undefined; courses?: Array<{ id: string; kind?: Types.CourseKind | null | undefined }> | null | undefined }
    | null
    | undefined;
};

export const SeasonImportDocument = gql`
  mutation SeasonImport($season: ID!, $importFrom: ID!) {
    seasonImport(season: $season, importFrom: $importFrom) {
      season {
        id
        courseKindGroups
      }
      courses {
        id
        kind
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SeasonImportOptions = ExecMutationOptions<SeasonImportMutationVariables>;

export function seasonImport(options: SeasonImportOptions) {
  return executeMutation<SeasonImportMutation, SeasonImportMutationVariables>(SeasonImportDocument, options);
}
