import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
export type EnrollmentInvitationFieldsFragment = { isInvitation: boolean; invitationExpired?: boolean | null | undefined };

export const EnrollmentInvitationFieldsFragmentDoc = gql`
  fragment enrollmentInvitationFields on RosterItem {
    isInvitation
    invitationExpired
  }
`;
