import { scriptManager } from 'app/scriptLoading/ScriptManager';
import * as React from 'react';

interface ScriptLoadInfo {
  script: string;
  isUrl: boolean;
  blockInTestMode?: boolean;
}
export interface ScriptLoaderComponentProps {
  scripts: ScriptLoadInfo[];
}
export interface ScriptLoaderComponentState {
  loaded: boolean;
}

export class ScriptLoader<P extends ScriptLoaderComponentProps = ScriptLoaderComponentProps, S extends ScriptLoaderComponentState = ScriptLoaderComponentState> extends React.Component<P, S> {
  numScriptsLoaded: number;
  unmounted: boolean;

  constructor(props: P) {
    super(props);

    this.unmounted = false;
    this.numScriptsLoaded = 0;
    this.state = {
      loaded: false
    } as S;
  }

  componentDidMount() {
    this.props.scripts.forEach(script =>
      scriptManager.register(script.script, script.isUrl, this.onLoad, script.blockInTestMode)
    );
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    return this.state.loaded ? this.props.children : '';
  }

  onLoad = () => {
    ++this.numScriptsLoaded;
    if (this.numScriptsLoaded == this.props.scripts.length && !this.unmounted) {
      this.setState({ loaded: true });
      this.onScriptsLoaded();
    }
  };

  onScriptsLoaded() {}
}
