import * as React from 'react';

import { CourseKind, CourseUtils } from 'app2/api';
import { ActionButton, ButtonProps, DataTable } from 'app2/components';
import { ExecMutationOptions, idsToindex } from 'app2/views/shared';

import { TableCoursesSelections } from '../generated'

export interface TableActionProps {
  table:DataTable<TableCoursesSelections>;
  kind?:CourseKind;
}

export interface AllTableActionProps extends TableActionProps, Omit<ButtonProps, 'onClick' | 'kind'> {
  label:string;
  msg?:string;
  ability:keyof ReturnType<typeof CourseUtils.getCourseAbilities>
  mutation?:(variables:ExecMutationOptions) => Promise<any>;
  onClick?:() => void;
}

export function TableAction(props:AllTableActionProps) {
  const { table, icon, label, msg, ability, mutation, kind, ...remaining } = props;
  const tableAbilities = React.useMemo(() => CourseUtils.getCourseAbilities(table?.allItems), [table?.props?.data]);

  function render() {
    return tableAbilities[ability] ? <ActionButton icon={icon} onClick={action} {...remaining}>{label}</ActionButton> : <></>;
  }

  function action() {
    mutation(mutationOptions(msg))
  }

  function mutationOptions(successMsg: string):ExecMutationOptions {
    const ids = table?.selectedItems.map(item => item.id);

    return {
      variables: {ids},
      successMsg,
      error: {
        handler: table,
        transform: idsToindex
      }
    };
  }

  return render();;
}
