import * as React from 'react'

import { Box } from '../Box'
import { Title, TitleProps } from '../Title'
import { formContentClass, formTitleClass, theme } from '../theme'

export interface FormContentProps extends TitleProps {
  scrollable?:boolean;
}

export const FormContent = React.forwardRef((props:FormContentProps, ref:any) => {
  const {as, icon, title, subtitle, primaryActions, onSubmit, children, mt, scrollable, overflow, ...remaining} = props;
  const scrollableProps = scrollable ? {overflow:overflow || 'auto', pr:theme.space.pannelPadding, mr:theme.space.negativePannelPadding} : undefined;

  return <Box ref={ref} layout='vbox' role='form' flex={1} vItemSpace={props.gap ? undefined : 'form-content'} onSubmit={onSubmit} className={formContentClass} {...scrollableProps} {...remaining}>
    <Title icon={icon} title={title} subtitle={subtitle} primaryActions={primaryActions} className={formTitleClass} />
    {children}
  </Box>
})

FormContent.displayName = 'FormContent';

FormContent.defaultProps = {
  containerBreakpoints: true,
  breakpoints: [400, 500]
}