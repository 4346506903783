export class Point {
  x:number;
  y:number;

  constructor(x:number = 0, y:number = 0) {
    this.x = x;
    this.y = y;
  }

  get col() {
    return this.x;
  }

  set col(value:number) {
    this.x = value;
  }

  get colLetter() {
    let letters = ''
    let col = this.col;

    while (col >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[col % 26] + letters
        col = Math.floor(col / 26) - 1
    }

    return letters
  }

  get cellPos() {
    return this.colLetter + (this.row + 1);
  }

  get width() {
    return this.x;
  }

  get row() {
    return this.y;
  }

  set row(value:number) {
    this.y = value;
  }

  get height() {
    return this.y;
  }

  clone() {
    return new Point(this.x, this.y);
  }

  copy(other:Point):this {
    this.x = other.x;
    this.y = other.y;

    return this;
  }

  offset(x:number = 0, y:number = 0) {
    this.x += x;
    this.y += y;

    return this;
  }

  equal(other:Point) {
    return this.y == other.y && this.x == other.x;
  }
}

export type PointGetSet = (pt:Point, value?:number) => number;

export function x(pt:Point, value:number = undefined) {
  if (value !== undefined) {
    pt.x = value;
  }

  return pt.x;
}

export function y(pt:Point, value:number = undefined) {
  if (value !== undefined) {
    pt.y = value;
  }

  return pt.y;
}
