import * as React from 'react';

import { HBox, Link, Text } from 'app2/components'

import { useCompanyQuery } from './useCompanyQuery';
import { useBasicCompanyQuery } from './generated';

export function SiteDirectoryCTA() {
  const { company } = useCompanyQuery(useBasicCompanyQuery);

  function render() {
    return <HBox bg='successFill' border='solid 1px' borderColor='success' borderRadius='standard' p='$12' mb='$12' position='sticky' left={0} display={['none','flex']}>
      <Text text='body' flex={1}>Leverage our School Directory to bring your activities to more schools</Text>
      <Link to={`/directory/school/${company?.zipcode || ''}`} button='primary'>School directory</Link>
    </HBox>
  }

  return render();
}
