import * as React from 'react'
import { useParams } from 'react-router';

import { Field, Form, RepeatingSection } from 'app2/components';

import { UserLink } from '../shared';

import { useAdminCourseReviewsQuery } from './generated';

export function Reviews() {
  const { course } = useParams<{course:string}>();

  const [result] = useAdminCourseReviewsQuery({variables:{id:course}});
  const item = result.data?.course;

  function render() {
    return <Form initialValues={item} editing={false}>
      <RepeatingSection name='reviews' equalWidths={false} fields={[
        <Field name='comments' label='Comments' />,
        <Field name='instructorRating' label='Instructor' />,
        <Field name='qualityRating' label='Quality' />,
        <Field name='overallRating' label='Overall' />,
        <Field name='enrollment.student.name' label='Student' />,
        <Field name='enrollment.parent.name' label='Parent' component={UserLink} />,
      ]} />
    </Form>
  }

  return render();
}
