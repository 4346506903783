import * as React from 'react'

import { CourseKind } from 'app2/api';
import { BooleanField, DateField, Form, FormModel, InputField, Modal, Section, useForm } from 'app2/components';
import { GradeField, showAllStudentDetails } from 'app2/views/shared-public';

import { CartItem } from './CheckoutModel';
import { parentCreateAndAssignStudentAtCheckout } from './generated';

interface Props {
  name:string;
  enrollment: CartItem;
  site?:string;
  courseKinds: CourseKind[];
  family:string;
}

export function AddStudentModal(props:Props) {
  const form = useForm(() => {
    const words = props.name.split(' ');
    const firstName = words.shift() || '';

    return {
      firstName,
      lastName: words.join(' '),
      grade: null,
      automaticGradeUpdate: true,
      family: props.family
    }

  }, [props.name]);

  function render() {
    return <Modal title='Add new student'>
      <Form form={form} onOk={onOk}>
        <Section name='firstName' label='First name' required component={InputField} />
        <Section name='lastName' label='Last name' required component={InputField} />
        <Section name='nickname' label='Nickname' component={InputField} />
        <Section name='grade' label='Grade' required component={GradeField} description='Enter current grade level' site={props.site} />
        {form.values.grade && form.values.grade != 'N/A' && <Section name='automaticGradeUpdate' label='Advance to the next grade at the end of the school year' component={BooleanField} />}
        <Section name='dob' label='Date of birth' required display={DateField} edit={{component: DateField, max: 'now'}} />
      </Form>
    </Modal>
  }

  async function onOk(form: FormModel) {
    const attributes = {...form.values};

    if (props.site && !showAllStudentDetails(props.courseKinds)) {
      attributes['classroomId'] = `${props.site}_unassigned`;
    }

    const [success] = await parentCreateAndAssignStudentAtCheckout({ variables: { attributes, enrollmentId: props.enrollment.id } });

    return success;
  }

  return render();
}
