import * as React from 'react'

import { EmailField, Field, InputField, Panel, PanelProps, PhoneField, RepeatingSection } from 'app2/components'

export function BaseEmergencyContacts(props:PanelProps) {
  return <Panel icon='FileText' title='Emergency contacts' type='toggle' {...props}>
    <RepeatingSection name='contacts' add='Add emergency contact' fields={[
      <Field label='Name' name='name' component={InputField} />,
      <Field label='Role' name='position' required component={InputField} />,
      <Field name='phone' component={PhoneField} />,
      <Field name='email' component={EmailField} />,
      'remove'
    ]} />
  </Panel>
}