import * as React from 'react'
import { useParams, useHistory } from 'react-router'
import { isEqual } from 'lodash-es'

import { ErrorWithPath, Info, Link, Text, useLifecycle, VBox } from 'app2/components'
import { PublicPage, useCurrentUser } from 'app2/views/shared-public'
import { MutationFunction } from 'app2/views/shared';

interface Props {
  title:string;
  message?:string;
  variables?:any;
  mutation:MutationFunction<{token:string}, any>;
  onSuccess:string | ((history:ReturnType<typeof useHistory>, response:any) => string);
  onFailure?:(response:any, errors?:ErrorWithPath[]) => void;
  children?:React.ReactNode;
}

export function BaseAcceptInvite(props:Props) {
  const { title, message, variables:propsVariables, mutation, onSuccess, onFailure, children } = props;
  const { token } = useParams<{token: string}>();
  
  const user = useCurrentUser();

  const history = useHistory();

  const [errors, setErrors] = React.useState<ErrorWithPath[]>([]);
  const needsLogin = errors.find(e => e.code === 'LOGIN_REQUIRED' || e.code === 'AUTHORIZATION_ERROR');
  const loaded = errors.length > 0;

  const variables = React.useRef(propsVariables);
  useLifecycle({onMount, onUpdate})

  function render() {
    return <PublicPage useMaxWidth title={loaded ? title : ''}>
      {needsLogin
        ? renderNeedsLogin()
        : loaded
          ? renderErrors()
          : <>{children}</>
          }
    </PublicPage>
  }

  function renderNeedsLogin() {
    const url = `?redirect=${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;

    return <VBox gap='$20'>
      {message && <Text text='heading2'>{message}</Text>}
      <Text text='subtitle2'>To accept this invitation you must <Link text='subtitle2' to={`/login${url}`}>sign in</Link> or <Link text='subtitle2' to={`/register${url}`}>create an account</Link>.</Text>
    </VBox>
  }

  function renderErrors() {
    return <VBox gap='$20'>
      {message && <Text text='heading2'>{message}</Text>}
      {children}
    <Info type='error' message={errors.map(e => e.message)} /></VBox>
  }

  async function onMount() {
    submit();
  }

  async function onUpdate() {
    if (!isEqual(variables.current, propsVariables)) {
      variables.current = propsVariables;
      submit();
    }
  }

  async function submit() {
    const [success, result] = await mutation({variables: {...propsVariables, token}, error: (errors => setErrors(errors))});

    if (success) {
      await user.reexecute();
      
      if (typeof onSuccess == 'string') {
        history.replace(onSuccess);
      }
      else {
        onSuccess(history, result.data);
      }
    }
    else
    if (onFailure) {
      onFailure(result.data, errors);
    }

    return success;
  }

  return render();
}
