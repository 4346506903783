import * as React from 'react';

import { useFormInfo } from '../form';
import { useForceUpdate } from '../utils';
import { FormModel, UpdateType } from './FormModel';

interface Options<T> {
  field?: string | keyof T;
  form?: FormModel<any>;
  onChange?:(form:FormModel, type: UpdateType) => void;
  subscribeToChildren?: boolean;
}

// using this hook ensures your component re-renders when the form changes
export function useFormSubscription<T = any>(options?: Options<T>) {
  const form = options?.form || useFormInfo().form;
  const forceUpdate = useForceUpdate();

  function onFormChange(form:FormModel, type: UpdateType) {
    if (!options?.field || type?.reset || type.fields[options?.field as string] || (options.subscribeToChildren && Object.keys(type.fields)?.some(f => f.startsWith(options?.field as string)))) {
      options?.onChange?.(form, type);
      forceUpdate();
    }
  }

  React.useEffect(() => {
    form.subscribe?.(onFormChange);

    return () => {
      form.unsubscribe?.(onFormChange);
    };
  }, [form]);
}
