import * as React from 'react';

import { replaceSelectedText } from './replaceSelectedText';

// for use with textarea and input, handles when a simulated paste event comes
// in, since the default textarea and input, won't accept it

export function untrustedPaste(event:React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>, element:HTMLInputElement | HTMLTextAreaElement, changeHandler:any) {
  if (event.isTrusted) {
    return;
  }

  // handle simulated paste events
  const clipboard = event.clipboardData;
  const text = clipboard?.getData('text/plain');

  if (!text) {
    return;
  }

  replaceSelectedText(element, text, changeHandler);
}
