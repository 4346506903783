import * as React from 'react'
import pluralize from 'pluralize';

import { Course, CourseDayUtils, CourseDay, CourseDayName, CourseKind, DeepPartial, DAY_ABBREVIATION_3_LETTER } from 'app2/api';
import { BoxProps, Box, defaultFieldProps, FieldInfo, HBox, Icon, formatTime, useFormInfo, VBox } from 'app2/components'
import { CourseLink, courseKindBehavior } from 'app2/views/shared-public';

import { CourseLessonsIcon } from '../course';

import { formatCourseDays } from './formatCourseDays';

interface Props extends BoxProps {
  start?:number;
  // when used in a tag we are only passed a single
  // course day at a time.  the total represents the total
  // of all including this one
  total?:number;
  value?:CourseDay[];
  // pass in to restrict to a subset of the course days
  days?:string[]
  info?:FieldInfo<DeepPartial<Pick<Course, 'id' | 'name' | 'kind' | 'courseDays' | 'children'>>>;
  course?:DeepPartial<Pick<Course, 'id' | 'name' | 'kind' | 'courseDays' | 'children'>>
  icon?:boolean;
}

export function CourseDaysDisplay(props:Props) {
  let {start, total, value, info, course, icon, ...remaining} = props;
  course = course || info?.record;
  value = value || course?.courseDays as CourseDay[];

  const formInfo = useFormInfo();

  function render() {
    if (courseKindBehavior[course?.kind]?.sessionType == 'none') {
      return <></>;
    }

    return course?.kind == CourseKind.LessonSet
      ? renderLessons()
      : renderNormal()
  }

  function renderNormal() {
    return  <VBox className='hr-value' {...remaining}>
      {CourseDayUtils.sortCourseDays(value).map((courseDay, index) => renderCourseDay(courseDay, index))}
    </VBox>
  }

  function renderCourseDay(courseDay:CourseDay, index:number) {
    const days = CourseDayUtils.sortDays(courseDay.days).filter(d => !props.days || props.days.includes(d));

    if (!days.length) {
      return;
    }

    return <HBox key={index} text='body' color='primary' gap='$8'>
      {icon && <Icon name='Clock' opacity={index == 0 ? 1 : 1} />}
      <Box><b>{days?.map(day => DAY_ABBREVIATION_3_LETTER[day as CourseDayName]).join(', ')}</b>{` from ${formatTime(courseDay.start)} to ${formatTime(courseDay.finish)}`}</Box>
    </HBox>
  }

  function renderLessons() {
    const variantCount = course?.children?.length || 0;
    const disable = formInfo?.editing;

    return <HBox gap='$8' {...remaining}>
      {icon && <CourseLessonsIcon info={info} course={course} />}
      <CourseLink disabled={disable} info={info as any} course={course as any} suffix='/time-slots'>{variantCount} Time {pluralize('slot', variantCount)}</CourseLink>
    </HBox>
  }

  return render();
}

CourseDaysDisplay.defaultProps = {
  start: 0
}

CourseDaysDisplay.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info',
}

export const CourseDayField = {component: CourseDaysDisplay, marginTop: '-4px', marginBottom: '-3px', errorProperty: null as string, copy: formatCourseDays, disallowNone: true};