import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertClassroomsMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  classrooms: Array<Types.ClassroomInput> | Types.ClassroomInput;
}>;

export type OrganizerUpsertClassroomsMutation = { siteUpsertClassrooms?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpsertClassroomsDocument = gql`
  mutation OrganizerUpsertClassrooms($siteId: ID!, $classrooms: [ClassroomInput!]!) {
    siteUpsertClassrooms(site: $siteId, classrooms: $classrooms) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertClassroomsOptions = ExecMutationOptions<OrganizerUpsertClassroomsMutationVariables>;

export function organizerUpsertClassrooms(options: OrganizerUpsertClassroomsOptions) {
  return executeMutation<OrganizerUpsertClassroomsMutation, OrganizerUpsertClassroomsMutationVariables>(OrganizerUpsertClassroomsDocument, options);
}
