import * as React from 'react'
import { useHistory } from 'react-router-dom';

import { logout } from 'app2/api';
import { useLifecycle } from 'app2/components';
import { clearCartNotificationTimeouts } from 'app2/views/shared-public';

import { UserPage } from '../UserPage';

export function Logout() {
  const history = useHistory();

  useLifecycle({onMount});

  async function onMount() {
    clearCartNotificationTimeouts();  
    await logout();
    history.push('/');
  }

  function render() {
    return <UserPage></UserPage>;
  }

  return render();
}
