import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyPayoutsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCompanyPayoutsQuery = {
  company?:
    | {
        id: string;
        pendingPayoutTotal: number;
        payouts?:
          | Array<{
              id: string;
              scheduledAt?: string | null | undefined;
              status?: string | null | undefined;
              amount?: number | null | undefined;
              paymentServiceId?: string | null | undefined;
              lastError?: string | null | undefined;
              sites?: Array<{ name?: string | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminCompanyPayoutsDocument = gql`
  query AdminCompanyPayouts($id: ID!) {
    company(company: $id) {
      id
      pendingPayoutTotal
      payouts {
        id
        scheduledAt
        status
        amount
        paymentServiceId
        lastError
        sites {
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCompanyPayoutsArgs = MakeOptional<UseQueryArgs<AdminCompanyPayoutsQueryVariables, AdminCompanyPayoutsQuery>, 'query'>;

export function useAdminCompanyPayoutsQuery(options: AdminCompanyPayoutsArgs = {}) {
  return useQuery<AdminCompanyPayoutsQueryVariables, AdminCompanyPayoutsQuery>({ query: AdminCompanyPayoutsDocument, ...options });
}

export type AdminCompanyPayoutsOptions = ExecQueryOptions<AdminCompanyPayoutsQueryVariables>;

export function adminCompanyPayouts(options: AdminCompanyPayoutsOptions) {
  return executeQuery<AdminCompanyPayoutsQuery, AdminCompanyPayoutsQueryVariables>(AdminCompanyPayoutsDocument, options);
}

export type AdminCompanyPayoutsSelections = AdminCompanyPayoutsQuery['company'];
