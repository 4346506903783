import * as React from 'react'

import { Threads } from 'app2/views/shared'

import { ParentCourseSelections, PublicCourseSelections } from './generated';

interface Props {
  course:PublicCourseSelections;
  parentCourse:ParentCourseSelections;
}

export function CourseMessages(props:Props) {
  return <Threads role='parent' labelOrParticipant={{type: 'Course', id: props.course.id}} entityMessages={false} />
}
