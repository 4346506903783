import * as React from 'react'

import { scrollIntoView, useLifecycle } from 'app2/components';

export function useScrollToTheBottom(element:HTMLElement, scroll:boolean, dependencies:any[] = []) {
  const scrollChanged = React.useRef(false);
  React.useMemo(() => scrollChanged.current = true, [...dependencies, scroll, element]);

  useLifecycle({onUpdate});

  function onUpdate() {
    if (!element || !scrollChanged.current) {
      return;
    }

    scrollChanged.current = false;

    if (!scroll) {
      return;
    }

    scrollIntoView(element, {alignment: 'end', behavior: 'auto'});
  }
}
