import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertCfQuestionsMutationVariables = Types.Exact<{
  ownerType: Types.CfOwnerKind;
  ownerId: Types.Scalars['ID'];
  questions: Array<Types.CfQuestionInput> | Types.CfQuestionInput;
}>;

export type OrganizerUpsertCfQuestionsMutation = {
  cfQuestionsUpsert?:
    | {
        questions?:
          | Array<{
              kind?: Types.CfKind | null | undefined;
              title?: string | null | undefined;
              description?: string | null | undefined;
              choices?: Array<string> | null | undefined;
              stage?: Types.CfStage | null | undefined;
              required?: boolean | null | undefined;
              filterCourses?: Array<string | null | undefined> | null | undefined;
              filterCourseKinds?: Array<Types.CourseKind | null | undefined> | null | undefined;
              files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const OrganizerUpsertCfQuestionsDocument = gql`
  mutation OrganizerUpsertCfQuestions($ownerType: CfOwnerKind!, $ownerId: ID!, $questions: [CfQuestionInput!]!) @urql(additionalTypenames: ["CfQuestion", "Course"]) {
    cfQuestionsUpsert(ownerId: $ownerId, ownerType: $ownerType, questions: $questions) {
      questions {
        kind
        title
        description
        choices
        stage
        required
        files {
          id
          name
          fileType
        }
        filterCourses
        filterCourseKinds
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertCfQuestionsOptions = ExecMutationOptions<OrganizerUpsertCfQuestionsMutationVariables>;

export function organizerUpsertCfQuestions(options: OrganizerUpsertCfQuestionsOptions) {
  return executeMutation<OrganizerUpsertCfQuestionsMutation, OrganizerUpsertCfQuestionsMutationVariables>(OrganizerUpsertCfQuestionsDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['CfQuestion', 'Course']) }
  });
}
