import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminProcessPendingRefundMutationVariables = Types.Exact<{
  refund: Types.Scalars['ID'];
}>;

export type AdminProcessPendingRefundMutation = { homeroomProcessPendingRefund?: boolean | null | undefined };

export const AdminProcessPendingRefundDocument = gql`
  mutation AdminProcessPendingRefund($refund: ID!) {
    homeroomProcessPendingRefund(refund: $refund)
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminProcessPendingRefundOptions = ExecMutationOptions<AdminProcessPendingRefundMutationVariables>;

export function adminProcessPendingRefund(options: AdminProcessPendingRefundOptions) {
  return executeMutation<AdminProcessPendingRefundMutation, AdminProcessPendingRefundMutationVariables>(AdminProcessPendingRefundDocument, options);
}
