import * as React from 'react'
import { useParams } from 'react-router'

import { FormModel } from 'app2/components'
import { BaseBeforeActivity } from 'app2/views/shared-public'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerSiteBeforeActivityPoliciesQuery } from './gql'

interface Props {
    handleOk: (form:FormModel) => void;
}

export function BeforeActivity(props: Props) {
  const {site} = useParams<SiteRouteParams>();
  const [policiesResult] = useOrganizerSiteBeforeActivityPoliciesQuery({ variables: { siteId: site }})
  const data = policiesResult.data?.site?.policy;

  return <BaseBeforeActivity type='toggle' initialValues={data} onOk={form => props.handleOk(form)} />
}
