import * as React from 'react';

import {
  Heading2,
  Subtitle1,
  Subtitle2,
  Box,
  VBox,
  HBox
} from 'app2/components';

import { PhotoFrame } from '../../shared-public';

import howItWorks from './how-it-works.png';

export function HowItWorks() {
  return (
    <Box
      layout={['vbox', 'hbox']}
      bg="primary"
      borderRadius="standard"
      width="100%"
      mb="$60"
      overflow="hidden"
    >
      <PhotoFrame
        minWidth={['unset', '50%']}
        minHeight={['240px', 'unset']}
        image={howItWorks}
      />

      <VBox minWidth={['unset', '50%']} p="$30" color="white">
        <Heading2 mb="$10" color="inherit">
          How Homeroom Works
        </Heading2>
        <Subtitle1 mb="$30" color="inherit">
          Homeroom helps schools in your area easily bring enrichment programs
          to campus.
        </Subtitle1>
        <HowItWorksItem bullet={1}>
          Discover unique after school activities that are available in your
          area.
        </HowItWorksItem>
        <HowItWorksItem bullet={2}>
          Request Homeroom to bring specific after school activities to your
          local school.
        </HowItWorksItem>
        <HowItWorksItem bullet={3}>
          We contact your PTO/PTA about building a program based on your
          community’s interests.
        </HowItWorksItem>
      </VBox>
    </Box>
  );
}

function HowItWorksItem(props: React.PropsWithChildren<{ bullet: number }>) {
  return (
    <HBox mb="$15">
      <Bullet>{props.bullet}</Bullet>
      <Subtitle2 color="inherit">{props.children}</Subtitle2>
    </HBox>
  );
}

function Bullet(props: React.PropsWithChildren<{}>) {
  return (
    <HBox
      text="subtitle2"
      color="inherit"
      mr="$15"
      height="24px"
      minHeight="24px"
      width="24px"
      minWidth="24px"
      hAlign="center"
      vAlign="center"
      borderRadius="12px"
      border="solid 1px #fff"
      {...props}
    />
  );
}
