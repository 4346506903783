import * as React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { useMediaQueryBreakpoints } from '../breakpoints/useBreakpoints';
import { MultiContext, MultiContextProvider } from '../utils';

import { theme, Theme, GlobalStyles, Breakpoints } from './theme';

export interface BreakpointInfo {
  theme:Theme;
  breakpoint:Breakpoints;
  deviceBreakpoint:Breakpoints;
}

interface Props {
  theme?:Theme;
  shield?:boolean;
}

export function ThemeProvider(props:React.PropsWithChildren<Props>) {
  const themeToUse = props.theme || theme;
  const breakpoint = useMediaQueryBreakpoints(themeToUse.breakpoints);

  const context = React.useContext<BreakpointInfo>(MultiContext);
  const deviceBreakpoint = isFinite(context?.deviceBreakpoint) ? context?.deviceBreakpoint : breakpoint;

  //@ts-ignore
  const Shield = window['__Shield'];
  const children = <MultiContextProvider theme={themeToUse} breakpoint={breakpoint} deviceBreakpoint={deviceBreakpoint}>
    {props.children}
    </MultiContextProvider>


  return <StyledComponentsThemeProvider theme={props.theme || theme}>
    {!props.theme && <GlobalStyles />}
    {props.shield
      ? <Shield>{children}</Shield>
      : children}
  </StyledComponentsThemeProvider>
}

ThemeProvider.defaultProps = {
  shield: false
}

//@ts-ignore
window['__ThemeProvider'] = ThemeProvider;
