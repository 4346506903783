import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentSetPriceTierMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type ParentSetPriceTierMutation = { cartSetPriceTier?: { success?: boolean | null | undefined } | null | undefined };

export const ParentSetPriceTierDocument = gql`
  mutation ParentSetPriceTier($id: ID) @urql(additionalTypenames: ["Cart"]) {
    cartSetPriceTier(id: $id) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentSetPriceTierOptions = ExecMutationOptions<ParentSetPriceTierMutationVariables>;

export function parentSetPriceTier(options: ParentSetPriceTierOptions) {
  return executeMutation<ParentSetPriceTierMutation, ParentSetPriceTierMutationVariables>(ParentSetPriceTierDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Cart']) }
  });
}
