import * as React from 'react';

import { CourseKindGroup, CourseKind } from 'app2/api';
import { VBox } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

interface Props {
  groups:CourseKindGroup[];
  filter?:(kind:CourseKind) => boolean;
  component:React.ComponentType<{kind:CourseKind}>;
}

export function CourseTablesForGroups(props:Props) {
  const {groups, filter, component:Component} = props;

  return <VBox gap='$40' width='100%'>
    {groups?.map(kindGroup => courseKindBehavior[kindGroup].kinds.filter(filter || all).map(kind => <Component key={kind} kind={kind} />))}
  </VBox>
}

function all(kind:CourseKind) {
  return true;
}