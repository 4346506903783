import { EventRecorder, EventProperties, Callback, StandardEvents} from './EventRecorder';
import { LoggedInUser } from './LoggedInUser';

export class EventRecorderProxy extends EventRecorder {
  proxied:EventRecorder;

  constructor(proxied?:EventRecorder) {
    super();

    this.proxied = proxied;
  }

  get recordForHomeroomStaff(): boolean {
    return this.proxied.recordForHomeroomStaff;
  }

  get loaded(): boolean {
    return this.proxied.loaded;
  }

  onAppStart(urlParams: EventProperties): void {
    return this.proxied.onAppStart(urlParams);
  }

  onLogRocketUrlUpdated(url: string): void {
    return this.proxied.onLogRocketUrlUpdated(url);
  }

  identifyUser(user:LoggedInUser, callback?: Callback): void {
    return this.proxied.identifyUser(user, callback);
  }

  identifyNoUser(callback?: Callback): void {
    return this.proxied.identifyNoUser(callback);
  }

  logStandard(event: StandardEvents, data?: EventProperties, callback?: Callback): void {
    return this.proxied.logStandard(event, data, callback);
  }

  log(event: string, data?: EventProperties, callback?: Callback): void {
    return this.proxied.log(event, data, callback);
  }

  pageView(url: string): void {
    return this.proxied.pageView(url);
  }

  get isHomeroomStaff() {
    return this.proxied.isHomeroomStaff;
  }
}
