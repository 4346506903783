import * as React from 'react'

import { Field } from 'app2/components';

import { UserLink, QueryList, StudentLink } from '../shared';

import { useAdminFamilyStudentsQuery } from './generated'

export function Students() {
  return <QueryList icon='Users' title='Students' query={useAdminFamilyStudentsQuery} name='students' fields={[
    <Field name='id' label='ID' />,
    <Field name='name' label='Student' component={StudentLink} />,
    <Field name='parents.0.name' label='Family' component={UserLink} />,
  ]} />
}