import * as React from 'react';
import moment from 'moment'

import { Box, Heading1, Text, VBox, BoxProps } from 'app/app2/components';
import { reactNodeToString } from './reactNodeToString';

export interface Props extends BoxProps {
  name: string;
  description?: string;
  omitProps?: string[];
  code?: boolean;
}

export function Demo(props:Props) {
  const { name, description, omitProps, code, children, ...remaining } = props;
  const [markup, setMarkup] = React.useState(undefined);

  if (markup == undefined && code) {
    const updateMarkup = reactNodeToString(children, {omitDefaultProps: true, omitProps, omitFunctions: new Set([moment().constructor])});

    setMarkup(updateMarkup);
  }

  return (
    <VBox pb="$30" width="100%" {...remaining}>
      <Box pb="$15">
        <Text text="heading2" pb="$15" textDecoration="underline">{name}</Text>
      </Box>
      {description && <Text mb='$15' text='body' fontSize='16px' whiteSpace='pre'>{description}</Text>}
      <Box pb="$15" vItemSpace='5px'>{children}</Box>
      {code && <Box bg="#EFEFEF" p="$15"><Text text="body" whiteSpace="pre-wrap">{markup}</Text></Box>}
    </VBox>
  );
}

Demo.defaultProps = {
  code: true
};


interface DemosProps extends BoxProps {
  name:string;
  description?:string;
}

export function Demos(props:React.PropsWithChildren<DemosProps>) {
  const {name, description, children, ...remaining} = props;

  return <VBox width='100%' {...remaining}>
    <Heading1>{name}</Heading1>
    {description && <Text mb='$15' text='body' fontSize='16px' whiteSpace='pre'>{description}</Text>}
    {children}
  </VBox>
}
