import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { kebabCase } from 'lodash-es';

import { HBox, VBox, Link, Text } from 'app2/components';

import { AdminPage } from '../AdminPage';

import { demos } from './demos';

export function ComponentDemoRoutes() {
  return (
    <AdminPage title='Components'>
      <HBox flex={1} overflow='hidden'>
        <VBox padding="$15" overflow='scroll'>
          {demos.map((demo, index) => <Link key={index} to={`/admin2/component-demos/${kebabCase(demo.name)}`} text="subtitle2">{demo.name}</Link>)}
        </VBox>
        <VBox flex={1} height="100%" pl='40px' overflow='auto'>
          <Switch>
            {demos.map((demo, index) => <Route key={index} path={`/admin2/component-demos/${kebabCase(demo.name)}`} component={demo.demos}></Route>)}
          </Switch>
        </VBox>
      </HBox>
    </AdminPage>
  );
}
