import * as React from 'react'

import { BoxProps } from '../Box';

export enum ScrollAxis {
  horizontal,
  vertical
}

export interface ScrollbarProps extends Omit<BoxProps, 'onScroll'> {
  axis:ScrollAxis;
  // set the scrollLeft/Top directly instead of setting start, so there's no lag in scrolling waiting for a react render
  // start:number;// viewable start
  size:number;// total size (not viewable size)
  scrollbarSize:number; // the height of a horizontal scrollbar, width of a vertical scrollbar
  onScroll?:(start:number) => void;
}