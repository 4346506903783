import * as React from 'react';

import { Button, EmailField, EmailLabelField, Form, FormContent, InputField, Field, FormModel, HBox, RepeatingSection, Section, PhoneLabelField, PhoneField, Tag } from 'app2/components';
import { addDeletedItems } from 'app2/views/shared'
import { Beta } from 'app2/views/shared-public'

import { updateProvider, organizerMakeSiteCompany, VendorSelections } from './gql';

interface Props {
  loading:boolean;
  provider:VendorSelections;
}

export function Details(props: Props) {
  const accepted = props.provider?.accepted;

  function render() {
    return (
      <Form initialValues={props.provider} editing onOk={onOk} onNavigation='nothing'>
        <FormContent icon="User" title="Provider details">
          <HBox vAlign='bottom'>
            <Section label="Name" name="name" readOnly />
            {renderSiteCompany()}
          </HBox>
          <Section label="Nickname" name="companyName" required component={InputField} />
          <Section name="email" readOnly component={EmailLabelField} />
          <Section name="phone" readOnly component={PhoneLabelField} />
        </FormContent>
        <FormContent icon="Phone" title="Contacts">
          <RepeatingSection name="contacts" add={accepted ? undefined : 'Add contact'} requireOne fields={[
            <Field label="Name" name="name" component={InputField} readOnly={accepted} />, 
            <Field name="email" component={EmailField} readOnly={accepted} />, 
            <Field name="phone" component={PhoneField} readOnly={accepted} />, 
            accepted ? undefined : 'remove'
          ].filter(f => f !== undefined)}
          />
        </FormContent>
      </Form>
    );
  }

  function renderSiteCompany() {
    if (props.loading) {
      return;
    }

    if (accepted && !props.provider.siteCompany) {
      return;
    }

    return accepted
      ? <Beta><Tag small whiteSpace='nowrap' bg='courseDay' icon={false}>School provider</Tag></Beta>
      : <Beta><Button onClick={onMakeSiteCompany} autoLoader>Make school provider</Button></Beta>
  }

  async function onMakeSiteCompany() {
    const [success] = await organizerMakeSiteCompany({variables: {vendor: props.provider.id}});

    return success
  }

  async function onOk(model:FormModel<VendorSelections>) {
    const [success] = await updateProvider({ 
      variables: { 
        vendor: props.provider.id, 
        companyName: model.values.companyName, 
        contacts: accepted ? undefined : addDeletedItems(props.provider.contacts, model.values.contacts, '_destroy')
      }, 
      error: model, 
      successMsg: 'Provider update successful.'
    });

    return success;
  }

  return render();
}
