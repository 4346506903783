import moment from 'moment'

import { DateInput } from './DateInput';

// if tz is missing this just returns the given input

export function toTz(d:DateInput, tz:string):moment.Moment {
  // once in a while we get something that looks like a moment
  // but isn't causing moment to throw an error, so just eat it
  try {
    return !tz ? moment(d) : moment(d)?.tz(tz);
  }
  catch(e) {
    return moment();
  }
}
