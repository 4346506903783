import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserInvitesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserInvitesQuery = {
  user?: { invites?: Array<{ kind?: Types.InviteKind | null | undefined; token?: string | null | undefined; invitedTo?: string | null | undefined }> | null | undefined } | null | undefined;
};

export const UserInvitesDocument = gql`
  query UserInvites {
    user: currentUser {
      invites {
        kind
        token
        invitedTo
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type UserInvitesArgs = MakeOptional<UseQueryArgs<UserInvitesQueryVariables, UserInvitesQuery>, 'query'>;

export function useUserInvitesQuery(options: UserInvitesArgs = {}) {
  return useQuery<UserInvitesQueryVariables, UserInvitesQuery>({ query: UserInvitesDocument, ...options });
}

export type UserInvitesOptions = ExecQueryOptions<UserInvitesQueryVariables>;

export function userInvites(options: UserInvitesOptions) {
  return executeQuery<UserInvitesQuery, UserInvitesQueryVariables>(UserInvitesDocument, options);
}

export type UserInvitesSelections = UserInvitesQuery['user'];
