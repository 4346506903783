import * as React from 'react';
import { useHistory } from 'react-router';

import { Link } from 'app2/components';

export function AttendanceCancel() {
  const history = useHistory();
  const startOverUrl = history.location.pathname.replace('/students', '')

  // the state is used to reset the code page form state (because its a unique object, it forces a change in url)
  return <Link button='secondary' to={startOverUrl} replace state={{}}>Cancel</Link>
}
