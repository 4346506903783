import * as React from 'react'

import { VBox } from '../Box'
import { PreferenceStore } from '../preferences';

import { useTabs, TabsHookProps } from './useTabs';

export interface TabsProps<T extends PreferenceStore = any> extends TabsHookProps<T> {
}  

export function Tabs<T extends PreferenceStore = any>(props:TabsProps<T>) {
  const {selected, tabs, tabState, tabsRef, component, pref, prefName, onChange, tabStrip, arrows, baseUrl, urlParameter, urlHideSuffix, mode, preserveQuery, ...remaining} = props;
  const rendered = useTabs(props);

  function render() {
    return <VBox {...rendered.normalizedTabs?.[rendered.selected]?.container} {...remaining}>
      {!rendered.hideTabs ? rendered.tabs : ''}
      {rendered.content}
    </VBox>
  }

  return render();
}

Tabs.defaultProps = {
  mode: 'hide-single-tab'
}