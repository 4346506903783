import * as React from 'react'

import { CourseStatus } from 'app2/api'
import { HBox, BoxProps, palette } from 'app2/components'

interface Props extends BoxProps {
  status?:CourseStatus;
  small?:boolean;
}

export function CourseStatusTag(props:Props) {
  const {status, small, ...remaining} = props;
  const statusProps = statuses[status];

  if (!statusProps) {
    return <></>;
  }

  return <HBox bg={statusProps.bg} color={statusProps.color} tooltip={statusProps.tooltip} cursor='pointer' text={small ? 'formlabel' : 'subtitle2'} 
    borderRadius='standard' px='$12' py={small ? '4.5px' : '$10'} {...remaining} disabled={false} width='fit-content'>
    {statusProps.label}
  </HBox>
}

CourseStatusTag.defaultProps = {
  small: false
}

CourseStatusTag.fieldProps = {
  valueProperty: 'status',
}

const statuses = {
  [CourseStatus.Active]:            {bg: palette.secondary.lightGreen.hex, color: undefined as string, label: 'Active', tooltip: 'The activity has started / is in session.'},
  [CourseStatus.AwaitingApproval]:  {bg: '#FFD79A', color: undefined as string, label: 'Ready to publish', tooltip: 'The activity is ready to be published by the organizer.'},
  [CourseStatus.Cancelled]:         {bg: '#FFBBBB', color: undefined as string, label: 'Cancelled', tooltip: 'The activity has been canceled.'},
  [CourseStatus.Completed]:         {bg: '#8F8F8F', color: '#fff',              label: 'Completed', tooltip: 'The activity has been completed / is over.'},
  [CourseStatus.Deleted]:           {bg: '#FFBBBB', color: undefined as string, label: 'Deleted', tooltip: ''},
  [CourseStatus.Enrolling]:         {bg: '#BFE5DD', color: undefined as string, label: 'Enrolling', tooltip: 'The activity is approved and open for enrollment.'},
  [CourseStatus.Draft]:             {bg: palette.secondary.lightYellow.hex, color: undefined as string, label: 'Draft', tooltip: 'The activity has not been sent to the provider yet.'},
  [CourseStatus.Request]:           {bg: '#E1D0D6', color: undefined as string, label: 'Requested', tooltip: 'This activity is ready for the provider to complete the required details.'},
  [CourseStatus.Upcoming]:          {bg: '#E0CDFF', color: undefined as string, label: 'Upcoming', tooltip: 'The activity is approved but not open for enrollment yet.'},
}