import * as React from 'react';

import { DeepPartial, Season } from 'app2/api'
import { Link, LinkProps, FieldInfo } from 'app2/components'

interface Props extends LinkProps {
  info?:FieldInfo<Season>;
  season?:DeepPartial<Season>;
}

export function SeasonLink(props:Props) {
  let {info, season, ...remaining} = props;
  season = season || info.record;

  return <Link text='body' to={`/organizer/${season.site.slug}/seasons/${season?.id}`} {...remaining}>{season?.name}</Link>
}

SeasonLink.fieldProps = {
  fieldProperty: 'info',
}