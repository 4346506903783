import { CourseDay, CourseDayUtils, CourseDayName, WEEKDAYS_SET } from 'app2/api';

// parse a course day to text (for reading input from
// a control or copy/paste)

export function parseCourseDays(input:string | CourseDay[]):CourseDay[] {
  if (Array.isArray(input)) {
    return parseCourseDaysAsArray(input);
  }

  if (typeof input == 'string') {
    return parseCourseDaysAsString(input);
  }

  return [];
}

function parseCourseDaysAsArray(input:CourseDay[]):CourseDay[] {
  return input.map(d => parseCourseDaysAsObject(d)).filter(d => d != null);
}

function parseCourseDaysAsObject(input:CourseDay):CourseDay {
  if (typeof input.start != 'string' || typeof input.finish != 'string' || !Array.isArray(input.days)) {
    return null;
  }

  return input
}

function parseCourseDaysAsString(input:string):CourseDay[] {
  if (!input) {
    return [];
  }

  const days = input.split(';').map(day => day.trim());

  return days.map(day => {
    const parts = /(.*) from (.+) to (.+)/.exec(day);
    
    if (!parts) {
      return {days: [], start: '', finish: ''};
    }

    const days = parts[1].split(', ')
      .map(CourseDayUtils.parseCourseDay)
      .filter(day => WEEKDAYS_SET.has(day as CourseDayName));

    return {days, start: parts[2], finish: parts[3]};
  });
}
