import { Preferences, preference, preference_folder } from '../shared/preferences/Preferences';

import { CoursesTablePreferences } from './CoursesTablePreferences';

export class CoursesTableWithTabsPreferences extends Preferences {
  @preference
  tab:number;

  @preference
  showAdvanced:boolean;

  @preference_folder(CoursesTablePreferences)
  requests:CoursesTablePreferences;
  
  @preference_folder(CoursesTablePreferences)
  published:CoursesTablePreferences;

  @preference_folder(CoursesTablePreferences)
  drafts:CoursesTablePreferences;
}

