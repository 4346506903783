import * as React from 'react';

import { Student, DeepPartial } from 'app2/api';
import { HBox, Tag, palette } from 'app2/components';

interface Props {
  student:DeepPartial<Student>;
}

export function StudentMedicalBadges(props: Props) {
  const medical = props.student?.medical;

  function render() {
    return <HBox gap='$8'>
      {renderAllergies()}
      {renderEpiPen()}
      {renderMedications()}
    </HBox>
  }

  function renderAllergies() {
    return Boolean(medical?.allergies?.length) && <Tag bg={palette.secondary.lightYellow.hex}>Has allergies</Tag>
  }

  function renderEpiPen() {
    return Boolean(medical?.epiPen) && <Tag bg='errorFill'>EpiPen</Tag>
  }

  function renderMedications() {
    return Boolean(medical?.medications?.length) && <Tag bg='warningFill'> Takes medication</Tag>
  }

  return render();
};
