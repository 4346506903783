import * as React from 'react';

import { Enrollment, EnrollmentUtils } from 'app2/api'
import { FieldInfo, HBox, Icon, TextProps } from 'app2/components'
import { CourseLink } from 'app2/views/shared-public';

import { WaitlistBadge } from './WaitlistBadge';

interface Props extends TextProps {
  info?:FieldInfo<Enrollment>;
  enrollment?:Enrollment;
  badge?:boolean; //indicates show the waitlist badge vs the clock icon
  role: 'organizer' | 'public';
  cart?: boolean;
  waitlist?:'always' | 'never'; // by default the enrollment is looked at if to show a waitlist indicator, this can be used to override
  status?:boolean;
}

export function EnrollmentActivityLink(props:Props) {
  const {info, enrollment:propsEnrollment, badge, role, cart, waitlist, status, ...remaining} = props;
  const enrollment = props.enrollment || props.info?.record;
  const course = enrollment.course;
  const waitlisted = props.waitlist == 'always' || (EnrollmentUtils.waitlistFinalized(enrollment) && props.waitlist != 'never');

  function render() {
    return !waitlisted ? renderActivityLink() : renderWithBadge();
  }

  function renderActivityLink() {
    return <CourseLink course={course} option={enrollment.isOption} role={props.role} status={status} {...remaining} />;
  }

  function renderWithBadge() {
    return <HBox vAlign='center' gap='$8' tooltip='Waitlisted' {...remaining}>
      {renderActivityLink()}
      {renderBadge()}
    </HBox>
  }

  function renderBadge() {
    return props.badge
      ? <WaitlistBadge />
      : <Icon name='Clock' size='small' cursor='default' />
  }

  return render();
}

EnrollmentActivityLink.fieldProps = {
  fieldProperty: 'info'
}

EnrollmentActivityLink.defaultProps = {
  role: 'organizer'
}