import { compact } from 'lodash-es';

import { Student } from '../graphql'

export type StudentWithNickName = Pick<Student, 'nickname'>;
export type StudentWithNames = Pick<Student, 'firstName' | 'lastName'> | Pick<Student, 'name'> & Partial<StudentWithNickName>;
export type StudentWithSingleName = Pick<Student, 'name'> & Partial<StudentWithNickName>;
export type StudentWithName = StudentWithNames | StudentWithSingleName;
export type StudentWithNameAndId = StudentWithName & {id?: string};

interface SpecialNeeds {
  allergies:string;
  conditions:string;
  notes:string;
  exists:boolean;
}
export class StudentUtils {
  static getStudentFirstName(student: StudentWithName) {
    const s = student as Student;

    return !s.firstName
      ? this.getStudentName(student)
    : s.nickname && s.nickname != s.firstName
        ? `${s.firstName} (${s.nickname})`
        : `${s.firstName}`;
  }

  static getStudentName(student: StudentWithName) {
    const s = student as Student;

    if (!s) {
      return;
    }

    if (s.nameWithNickname) {
      return s.nameWithNickname;
    }

    return !s.firstName || !s.lastName
      ? s.nickname && s.nickname != s.name
        ? `${s.name} (${s.nickname})`
        : `${s.name}`
    : s.nickname && s.nickname != s.firstName
        ? `${s.firstName} (${s.nickname}) ${s.lastName}`
        : `${s.firstName} ${s.lastName}`;
  }

  static getFormattedAllergies(allergies:Student['medical']['allergies']) {
    return allergies?.map(a => compact([a.allergicTo, a.notes]).join(', ')).join('\n');
  }

  static getFormattedMedications(medications:Student['medical']['medications']) {
    return medications?.map(m => compact([m.medication, m.dose, m.notes]).join(', ')).join('\n');
  }

  static getHasSpecialNeed(need:string): boolean {
    return need && !SPECIAL_NEEDS_FILTER_WORDS.includes(need.toLowerCase().trim());
  }
}

export const SPECIAL_NEEDS_FILTER_WORDS = ['none','n/a','na','no','nope', 'no allergies', 'nothing']