import * as React from 'react';

import { InputField, Info, Modal, Form, Section, useForm } from 'app2/components';

import { CompaniesDropdown } from '../shared/CompaniesDropdown';
import { SingleAdminCompanySelections, mergeCompanies } from './generated';

interface Props {
  company:SingleAdminCompanySelections;
}

export function MergeCompanies(props:Props) {
  const form = useForm<{company:SingleAdminCompanySelections}>({}, []);

  function render() {
    return <Modal title='Merge companies' danger info={{type:'warning', message: 'This can not be undone'}}>
      <Form form={form} onOk={onOk} editing>
        <Section label={`Choose which company to merge into ${props.company.name} (${props.company.id})`} name='company' component={CompaniesDropdown} />
        <Section label='Type merge! to confirm you want to merge' name='merge' validators={validateMerge} component={InputField} />
      </Form>
    </Modal>
  }

  async function onOk() {
    const [success] = await mergeCompanies({variables:{source: form.values.company.id, destination: props.company.id}})

    return success;
  }

  return render();
}

function validateMerge(value:string) {
  return value?.toLowerCase() == 'merge!'
    ? null
    : 'You must type merge! to confirm you want to merge the companies, which can not be undone'
}