import { isEmpty, isNaN } from 'lodash-es';

export function isBlank(object: any) {
  switch (typeof object) {
    case 'boolean':
      return false;
    case 'function':
      return false;
    case 'number':
      return isNaN(object);
    default:
      return isEmpty(object);
  }
}

export function isPresent(object: any) {
  return !isBlank(object);
}
