import * as React from 'react';

import { OrganizerRole } from 'app2/api';
import { findRoute, Redirect } from 'app2/components';
import { Route } from 'app2/views/shared-public';

import { Enrollments } from './enrollments';
import { Providers } from './providers';
import { Reports } from './reports';
import { Schedule } from './schedule';
import { Season } from './season';
import { SiteAttendance } from './attendance';
import { SiteMessages } from './messages';
import { SiteTimeTrackingEntry } from './time-tracking';
import { Setup } from './setup';

export const seasonsPath = '/organizer/:site?/seasons/:season(\\d+)?';
const coursePath = '/organizer/activities/:course';

const admin = OrganizerRole.Coordinator;
const member = OrganizerRole.SiteMember;

export const organizerRoutes = [
  // left hand navigation
  <Route path="/organizer/:site?/schedule/:season?/:week?" component={Schedule} role={member} />,
  <Route path={seasonsPath} component={Season} role={member} />,
  <Route path="/organizer/:site?/setup/:tab(general)" exact component={Setup} role={member} />,
  <Route path="/organizer/:site?/setup/:tab(payment)" exact component={Setup} role={admin} />,
  <Route path="/organizer/:site?/setup/:tab(student-discounts)" exact component={Setup} role={admin} />,
  <Route path="/organizer/:site?/setup/:tab(policies)" exact component={Setup} role={member} />,
  <Route path="/organizer/:site?/setup/:tab(team)" exact component={Setup} role={member} />,
  <Route path="/organizer/:site?/setup" exact component={Setup} role={member} />,
  <Route path='/organizer/:site?/attendance/:date?' component={SiteAttendance} role={member} />,
  <Route path='/organizer/:site?/enrollments/:season?/:view?' component={Enrollments} role={member} />,
  <Route path="/organizer/:site?/providers" exact component={Providers} role={member} />,
  <Route path="/organizer/:site?/reports/:season([0-9]+)?/:report(overview|transactions|compliance|time-tracking)/:course([0-9]+)?" component={Reports} role={admin} />,
  <Route path="/organizer/:site?/reports/:season([0-9]+)?/:report(overview|transactions|compliance|time-tracking)?" component={Reports} role={admin} />,
  <Route path="/organizer/:site?/messages" component={SiteMessages} role={member} />,

  // setup page redirects
  <Redirect from="/organizer/:site?/general" to="/organizer/:site?/setup/general" />,
  <Redirect from="/organizer/:site?/payment" to="/organizer/:site?/setup/payment" />, 
  <Redirect from="/organizer/:site?/policies" to="/organizer/:site?/setup/policies" />,
  <Redirect from="/organizer/:site?/team" to="/organizer/:site?/setup/team" />,

  // courses
  // these redirects has to be listed before the regular route else "classes" will match as a date
  <Redirect from={`${coursePath}/attendance/classes`} to="/activities/manage/:course/attendance" />,
  <Redirect from={`${coursePath}/attendance/students`} to="/activities/manage/:course/attendance" />,

  // /orgnizer/activities -> /activities/manage
  <Redirect from={`${coursePath}/:activityTab(attendance)/:date?`} to="/activities/manage/:course/:activityTab(attendance)/:date?" />,
  <Redirect from={`${coursePath}/:activityTab?`} to="/activities/manage/:course/:activityTab?" />,

  // pseudo public - have to be logged in
  <Route path="/sites/:site?/time-entry" component={SiteTimeTrackingEntry} role='logged-in' />,

  // redirects
  // season (singular) to seasons (plural)
  <Redirect from="/organizer/:site?/season/:season?" to={seasonsPath} />,

  // missing page
  <Redirect from="/organizer/:site" to="/organizer/:site/seasons" exact />,

  // courses -> activities
  <Redirect from="/organizer/courses/:course" to='/activities/manage/:course' />,

  // old ui to new ui for when the routes are different
  <Redirect from="/settings/team" to="/organizer/team" />,
  <Redirect from="/settings/account" to="/organizer/general" />,
  <Redirect from="/settings/profile" to="/user/account-settings" />,
  <Redirect from="/settings" to="/organizer/team" />,
  <Redirect from="/organizer/course-requests/:course" to="/activities/manage/:course" />,
  <Redirect from="/organizer/course-requests/:course/edit" to="/activities/manage/:course" />,
  <Redirect from="/courses/:course/edit" to="/activities/manage/:course" />,
  <Redirect from="/organizer/:site?/vendors" to="/organizer/:site?/providers" />,
  <Redirect from="/claim-site" to="/organizer" />,
  <Redirect from="/create-site" to="/organizer" />,

  // missing site and page
  <Redirect from="/organizer" to="/organizer/schedule" exact />,
]

export interface SiteRouteParams {
  site: string;
}

export interface SeasonRouteParams extends SiteRouteParams {
  season: string;
}

export interface ScheduleRouteParams extends SeasonRouteParams {
  week: string;
}

export interface ReportRouteParams extends SeasonRouteParams {
  report: 'overview' | 'transactions' | 'donations' | 'compliance';
  course?:string;
}

export interface EnrollmentRouteParams extends SeasonRouteParams {
  view: string;
}

export function getOrganizerPath(name:string) {
  return findRoute('/organizer/' + name, organizerRoutes)?.props?.path;
}
