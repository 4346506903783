import * as React from 'react';

import {
  Category,
  MarketArea,
  COURSE_TAG_CARD_IMAGE_HEIGHT,
  COURSE_TAG_CARD_IMAGE_WIDTH
} from 'app2/api';
import {
  buildCloudinaryImageUrl,
  HBox,
  Subtitle1,
  BoxProps,
  RatioBox,
  Link,
  Img
} from 'app2/components';

import { eventManager } from 'app2/api';

export const CATEGORY_CARD_RATIO = (185 * 100) / 390 + '%';

interface Props extends BoxProps {
  category: Category;
  marketArea?: MarketArea;
}

export function CategoryCard(props: Props) {
  const { category, marketArea, ...remaining } = props;

  function handleCategoryCardClick() {
    eventManager.log('clickCategoryCard', {
      url: window.location.href,
      category: category.name
    });
  }

  const to = marketArea
    ? `/in/${marketArea.slug}/category/${category.slug}`
    : `/category/${category.slug}`;

  return (
    <RatioBox
      boxShadow="box"
      borderRadius="standard"
      ratio={CATEGORY_CARD_RATIO}
      content={{ layout: 'vbox', px: '$15', py: '$15' }}
      {...remaining}
    >
      <Link
        to={to}
        underline={false}
        onClick={handleCategoryCardClick}
        style={{ height: '100%', width: '100%' }}
      >
        <HBox
          flex="1"
          height="100%"
          width="100%"
          vAlign="center"
          hAlign="center"
        >
          {category.card_img_url && (
            <HBox
              minWidth="50%"
              maxWidth="50%"
              pr="$40_half"
              hAlign="right"
              vAlign="center"
            >
              <Img
                maxHeight={COURSE_TAG_CARD_IMAGE_HEIGHT}
                maxWidth={`calc(min(${COURSE_TAG_CARD_IMAGE_WIDTH}px, 100%))`}
                src={buildCloudinaryImageUrl(category.card_img_url)}
              />
            </HBox>
          )}
          <Subtitle1
            minWidth={category.card_img_url ? '50%' : 'unset'}
            pl="$40_half"
            fontWeight="bold"
            maxLines={2}
          >
            {category.name}
          </Subtitle1>
        </HBox>
      </Link>
    </RatioBox>
  );
}
