import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminFamiliesSearchQueryVariables = Types.Exact<{
  term?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type AdminFamiliesSearchQuery = { families?: Array<{ id: string; name?: string | null | undefined }> | null | undefined };

export const AdminFamiliesSearchDocument = gql`
  query AdminFamiliesSearch($term: [Filter!]) {
    families(filters: $term, sorts: [{ name: "name", direction: ASC }]) {
      id
      name
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminFamiliesSearchArgs = MakeOptional<UseQueryArgs<AdminFamiliesSearchQueryVariables, AdminFamiliesSearchQuery>, 'query'>;

export function useAdminFamiliesSearchQuery(options: AdminFamiliesSearchArgs = {}) {
  return useQuery<AdminFamiliesSearchQueryVariables, AdminFamiliesSearchQuery>({ query: AdminFamiliesSearchDocument, ...options });
}

export type AdminFamiliesSearchOptions = ExecQueryOptions<AdminFamiliesSearchQueryVariables>;

export function adminFamiliesSearch(options: AdminFamiliesSearchOptions) {
  return executeQuery<AdminFamiliesSearchQuery, AdminFamiliesSearchQueryVariables>(AdminFamiliesSearchDocument, options);
}

export type AdminFamiliesSearchSelections = AdminFamiliesSearchQuery['families'][0];

(AdminFamiliesSearchDocument as any).additionalTypenames = ['Family'];
