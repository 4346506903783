
import * as React from 'react'

import { Img, Text, VBox } from 'app2/components';
import { BackLink, PublicPage } from 'app2/views/shared-public';

import NotFoundImg from './404.svg'

export function NotFound() {
  return <PublicPage useMaxWidth title='Whoops!'>
    <VBox gap='$40'>
      <VBox gap='$12'>
        <Text text='heading2'>We couldn't find the page you're looking for</Text>
        <BackLink to="/">Return to the homepage</BackLink>
      </VBox>
      <VBox hAlign='center'>
        <Img src={NotFoundImg} maxWidth='550px' />
      </VBox>
    </VBox>
  </PublicPage>
}
