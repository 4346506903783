import * as React from 'react'
import { uniqBy } from 'lodash-es';

import { ageOptions, Course } from 'app2/api';
import { Box, Dropdown, Field, FieldPlaceholder, Form, FormModel, Text, useFormToUrl } from 'app2/components';
import { GradeField } from 'app2/views/shared-public';

import { FilterOptions } from './FilterOptions';
import { GameSeasonsSelections, GamesSelections } from './generated';

interface Props {
  site:GameSeasonsSelections;
  filterOptions:FormModel<FilterOptions>;
  games:GamesSelections['games'];
}

export function Filter(props:Props) {
  useFormToUrl(props.filterOptions);

  const needsGradeFilter = React.useMemo(() => props.games?.some(c => c.grades?.length), [props.games]);
  const needsAgeFilter = React.useMemo(() => props.games?.some(c => typeof c.ageMin == 'number'), [props.games]);

  function render() {
    return <Form onNavigation='nothing' form={props.filterOptions} width='100%' flex='unset'>
      <Box layout={['vbox', 'hbox', 'hbox']} gap='$8' hAlign={['stretch', 'left']} vAlign={['top', 'center']} width='100%' flexWrap='wrap'>
        <Text text='subtitle2' whiteSpace='nowrap'>Filter by</Text>
        <Field name='season' label='Season' placeholder={FieldPlaceholder.select} component={Dropdown} options={getSeasonOptions()} flex={1} nowrap disablePhoneInput />
        <Field name='teams' label='Teams' placeholder={FieldPlaceholder.select} component={Dropdown} multiple options={getTeamOptions()} flex={1} nowrap disablePhoneInput />
        {needsGradeFilter && <Field name='grades' label='Grades' placeholder={FieldPlaceholder.select} multiple component={GradeField} site={props.site?.id} flex={1} nowrap disablePhoneInput />}
        {needsAgeFilter && <Field name='ages' label='Ages' placeholder={FieldPlaceholder.select} multiple component={Dropdown} options={ageOptions} flex={1} nowrap disablePhoneInput />}
      </Box>
    </Form>
  }

  function getSeasonOptions() {
    return props.site?.gameSeasons?.map(s => ({label: s.name, value: s.id}));
  }

  function getTeamOptions() {
    const teams = uniqBy(props.games?.map(g => teamOption(g.homeTeam)).concat(props.games?.map(g => teamOption(g.awayTeam))), t => t.value);
    return teams;
  }

  function teamOption(team:Pick<Course, 'id' | 'disambiguatedName' | 'name'>) {
    return {label: team?.disambiguatedName || team?.name, value: team?.id};
  }

  return render();
}

