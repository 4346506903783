import * as React from 'react';

import { Student, DeepPartial, StudentUtils, StudentWithName } from 'app2/api';
import { ModalWithTabs, ModalWithTabsProps, VBox } from 'app2/components';
import { StudentMedicalBadges } from 'app2/views/shared'

interface Props extends Omit<ModalWithTabsProps, 'title'> {
  student: DeepPartial<Student> & StudentWithName;
}

export function StudentModalBase(props: Props) {
  const {student, ...remaining} = props;

  return <ModalWithTabs title={<VBox gap='$4'>{StudentUtils.getStudentName(student) || "Student details"}<StudentMedicalBadges student={student} /></VBox>} 
    arrows size='large' ok='Close' cancel={null} {...remaining} />
}
