export interface Category {
  name: string;
  slug: string;
  card_img_url: string;
}

export const COURSE_TAG_CARD_IMAGE_WIDTH = 135;
export const COURSE_TAG_CARD_IMAGE_HEIGHT = 125;
export const COURSE_TAG_CARD_IMAGE_WIDTH_RETINA =
  COURSE_TAG_CARD_IMAGE_WIDTH * 2;
export const COURSE_TAG_CARD_IMAGE_HEIGHT_RETINA =
  COURSE_TAG_CARD_IMAGE_HEIGHT * 2;
