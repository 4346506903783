import * as React from 'react'
import { useHistory } from 'react-router';
import queryString from 'query-string'

import { resetPassword } from 'app2/api';
import { NotificationManager, Panel, FormModel, InputField, Section, validatePassword, validatePasswordsMatch } from 'app2/components';
import { PublicPage } from 'app2/views/shared-public';

import { handleAuthResponse } from './handleAuthResponse';

interface Params {
  email:string;
  'access-token':string;
  client:string;
  client_id:string;
  uid:string;
}

export function PasswordReset() {
  const history = useHistory();
  const {'access-token': accessToken, client, client_id, uid} = queryString.parse(history.location.search);

  function render() {
    return <PublicPage title='Reset your password' useMaxWidth>
      <Panel type='edit' ok='Submit' onOk={submit} onNavigation='nothing' alwaysSave>
        <Section label='New password' name='password' required component={InputField} type='password' validators={validatePassword} autoComplete="new-password" />
        <Section label='Confirm new password' name='passwordConfirmation' required type='password' component={InputField}  validators={validatePasswordsMatch} autoComplete="new-password" />
      </Panel>
    </PublicPage>
  }

  async function submit(form:FormModel) {
    const args = {
      'access-token':accessToken,
      client,
      client_id,
      uid,
      password:form.values.password,
      password_confirmation: form.values.passwordConfirmation
    };

    const response = await resetPassword(args);
    const {success, errors} = response;

    if (success) {
      NotificationManager.add('Your password has been reset.');
    }

    return handleAuthResponse(form, response, history, '/login');
  }

  return render();
}
