import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAdminFeeMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  feeConfig: Types.SiteFeeConfigInput;
}>;

export type UpdateAdminFeeMutation = { siteUpdateAdminFee?: { site?: { id: string } | null | undefined } | null | undefined };

export const UpdateAdminFeeDocument = gql`
  mutation UpdateAdminFee($siteId: ID!, $feeConfig: SiteFeeConfigInput!) {
    siteUpdateAdminFee(siteId: $siteId, feeConfig: $feeConfig) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateAdminFeeOptions = ExecMutationOptions<UpdateAdminFeeMutationVariables>;

export function updateAdminFee(options: UpdateAdminFeeOptions) {
  return executeMutation<UpdateAdminFeeMutation, UpdateAdminFeeMutationVariables>(UpdateAdminFeeDocument, options);
}
