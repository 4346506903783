import * as React from 'react';

export interface Delimeter {
  delimeter:React.ReactNode;
  last?:React.ReactNode;
}

export function renderDelimitedList<T>(items:T[], delimeter:Delimeter | React.ReactNode, render:(item:T, index:number) => React.ReactNode = basicRender, max:number = -1) {
  const d = (delimeter as Delimeter)?.delimeter || delimeter;
  const l = (delimeter as Delimeter)?.last || d;
  const hasMax = max != -1 && items.length > max;
  const length = hasMax ? max + 1 : items.length;

  return <>{items.map((item, index) => {
    if (index >= length) {
      return;
    }

    const isLast = index == length - 1;
    const isNextToLast = index == length - 2;
    const delimeterToUse = isLast ? undefined : isNextToLast ? l : d;
    const renderedItem = hasMax && isLast ? 'more' : render(item, index);
    return <React.Fragment key={index}>{renderedItem}{delimeterToUse}</React.Fragment>
  })}</>
}

export const commaAnd:Delimeter = {
  delimeter: ', ',
  last: ' and ',
}

export const commaOr:Delimeter = {
  delimeter: ', ',
  last: ' or ',
}

function basicRender<T>(item:T) {
  return item;
}