import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RefundEnrollmentsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  courseId: Types.Scalars['ID'];
  amount?: Types.InputMaybe<Types.Scalars['Float']>;
  description: Types.Scalars['String'];
  kind: Types.RefundKind;
  remove: Types.Scalars['Boolean'];
  includeOptions?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type RefundEnrollmentsMutation = { enrollmentsRefund?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const RefundEnrollmentsDocument = gql`
  mutation RefundEnrollments($ids: [ID!]!, $courseId: ID!, $amount: Float, $description: String!, $kind: RefundKind!, $remove: Boolean!, $includeOptions: Boolean) {
    enrollmentsRefund(ids: $ids, courseId: $courseId, amount: $amount, description: $description, kind: $kind, remove: $remove, includeOptions: $includeOptions) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RefundEnrollmentsOptions = ExecMutationOptions<RefundEnrollmentsMutationVariables>;

export function refundEnrollments(options: RefundEnrollmentsOptions) {
  return executeMutation<RefundEnrollmentsMutation, RefundEnrollmentsMutationVariables>(RefundEnrollmentsDocument, options);
}
