import * as React from 'react';

import { SwooshHeader } from 'app2/views/shared-public'

import { useCurrentSite, useCurrentSeason } from '../shared';

export function SiteHeader() {
  const { site } = useCurrentSite();
  const season = useCurrentSeason();

  return <SwooshHeader icon='BookOpen' title={site?.name || ' '} 
    details={[site?.streetAddress, site?.city && `${site?.city}, ${site?.state}, ${site?.zipcode}`]}
    primary={[site?.attendanceUrl && {to:site?.attendanceUrl, children:'Family attendance page', target: '__blank'}, {to:`/sites/${site?.slug}`, children:'School registration page'}]}
    secondary={season?.private ? {children: 'Private registration', to: `/sites/private/${site?.slug}`} : undefined} />
}
