import * as React from 'react';
import shallowEqual from 'shallowequal';

import { observable, observableManager } from '../../../observable';
import { handler, ignoreProps } from './ReactObservableProxyHandler';

import {
  ReactClassHooks,
  makeHookable,
  addStaticHook
} from '../../react-class-hooks';

export function makeClassObserver<T extends React.ComponentClass>(clazz: T): T {
  makeHookable(clazz);
  addStaticHook(clazz, observableHook);
  clazz = observable(clazz, false, handler, ignoreProps);

  return clazz;
}

const observableHook: ReactClassHooks = {
  beforeRender: function(component: React.Component) {
    observableManager.startCapturingDependencies(component);
  },

  afterRender: function(component: React.Component) {
    observableManager.stopCapturingDependencies(component);
  },

  unmount: function(component: React.Component) {
    observableManager.unsubscribeAll(component);
  },

  shouldComponentUpdate: function(component: React.Component, args: any[]) {
    const nextProps = args[0];
    const nextState = args[1];

    return (
      !shallowEqual(component.props, nextProps) ||
      !shallowEqual(component.state, nextState)
    );
  }
};
