import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateSitePoliciesMutationVariables = Types.Exact<{
  attributes: Types.PolicyInput;
}>;

export type OrganizerUpdateSitePoliciesMutation = { siteUpdatePolicies?: { policy?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateSitePoliciesDocument = gql`
  mutation OrganizerUpdateSitePolicies($attributes: PolicyInput!) {
    siteUpdatePolicies(policies: $attributes) {
      policy {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateSitePoliciesOptions = ExecMutationOptions<OrganizerUpdateSitePoliciesMutationVariables>;

export function organizerUpdateSitePolicies(options: OrganizerUpdateSitePoliciesOptions) {
  return executeMutation<OrganizerUpdateSitePoliciesMutation, OrganizerUpdateSitePoliciesMutationVariables>(OrganizerUpdateSitePoliciesDocument, options);
}
