import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentAcceptTermsOfServiceMutationVariables = Types.Exact<{
  accepted?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type ParentAcceptTermsOfServiceMutation = { userAcceptTos?: { success?: boolean | null | undefined } | null | undefined };

export const ParentAcceptTermsOfServiceDocument = gql`
  mutation ParentAcceptTermsOfService($accepted: Boolean) @urql(additionalTypenames: ["User"]) {
    userAcceptTos(accepted: $accepted) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentAcceptTermsOfServiceOptions = ExecMutationOptions<ParentAcceptTermsOfServiceMutationVariables>;

export function parentAcceptTermsOfService(options: ParentAcceptTermsOfServiceOptions) {
  return executeMutation<ParentAcceptTermsOfServiceMutation, ParentAcceptTermsOfServiceMutationVariables>(ParentAcceptTermsOfServiceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
