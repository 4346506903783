import * as React from 'react'
import * as qs from 'query-string';
import { useLocation } from 'react-router';

import { BaseAcceptInvite } from '../../shared'

import { siteInviteAccept } from './generated';

export function SiteAcceptInvite() {
  const location = useLocation();
  const { invitor, site}  = qs.parse(location.search);
  const who = invitor ? `${invitor} has invited you` : "You've been invited";
  const message = `Welcome! ${who} to join ${site || 'a school'}.`
  
  return <BaseAcceptInvite title='School team member invite' message={message} mutation={siteInviteAccept} onSuccess='/' />
}
