import * as React from 'react';
import { capitalize } from 'lodash-es';

import { Dropdown, Img, List, Modal, VBox } from 'app2/components';

import { buildCloudinaryImageProps } from './utils';

export interface CloudinaryImageSearchResult {
  filename?:string;
  publicId?:string;
  tags?:string[];
}

export interface CloudinaryPhotoPickerProps {
  width:number;
  resolution?:number;
  ratio?:number;
  borderRadius?:number;

  image?: string;
  search:() => CloudinaryImageSearchResult[];
  onChange: (imageId: string) => void;
}

export function CloudinaryPhotoPicker(props:CloudinaryPhotoPickerProps) {
  const {ratio, width, resolution, borderRadius, image, search, onChange} = props;
  const [selected, setSelected] = React.useState(image);
  const [searchTags, setSearchTags] = React.useState([]);
  const [searchImages, setSearchImages] = React.useState<ReturnType<typeof getImages>>();
  const {tags, images} = getOptions();

  function render() {
    return <VBox vItemSpace='$8'>
      <Dropdown options={tags} multiple value={searchTags} onChange={onChangeTags} />
      <List options={searchImages || images} measuredRows rowHeight={230} width='100%' height='300px' value={selected} onChange={event => onChange?.(event.target.value)} />
    </VBox>
  }

  function getOptions() {
    const imageList = search();

    return {tags:getTags(imageList) || [], images:getImages(imageList) || []};
  }

  function getImages(imageList:CloudinaryImageSearchResult[]) {
    return React.useMemo(() => {
      return imageList?.map((imageInfo, index) => {
        return {label: <Img key={index} {...buildCloudinaryImageProps(imageInfo.publicId, ratio, width, resolution, borderRadius)} maxWidth='100%' height='auto' />, value: imageInfo.publicId, tags: imageInfo.tags };
      })
    }, [imageList]);
  }

  function getTags(imageList:CloudinaryImageSearchResult[]) {
    return React.useMemo(() => {
      const allTags = new Set<string>();
      imageList?.forEach(imageInfo => imageInfo.tags.forEach(tag => allTags.add(capitalize(tag))), []);

      return [...allTags].sort();

    }, [imageList]);
  }

  function onChangeTags(event:React.ChangeEvent<Dropdown>) {
    const searchTags = event.target.value;
    setSearchTags(searchTags);

    const searchTagsSet = new Set(searchTags);
    const searchImages = images.filter(imageOption => searchTagsSet.size == 0 || imageOption.tags.find(tag => searchTagsSet.has(capitalize(tag))));
    setSearchImages(searchImages);
  }

  return render();
}

export async function showPhotoPickerModal(props:CloudinaryPhotoPickerProps) {
  let choice:string = '';

  function onChange(image:string) {
    choice = image;
  }

  const result = await Modal.show(<Modal title='Pick image'><CloudinaryPhotoPicker {...props} onChange={onChange} /></Modal>);

  if (result.action == 1) {
    props.onChange(choice);
  }
}
