import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerGradesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerGradesQuery = { site?: { id: string; grades?: Array<{ label: string; value: string; hidden?: boolean | null | undefined }> | null | undefined } | null | undefined };

export const OrganizerGradesDocument = gql`
  query OrganizerGrades($siteId: ID!) {
    site(site: $siteId) {
      id
      grades {
        label
        value
        hidden
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerGradesArgs = MakeOptional<UseQueryArgs<OrganizerGradesQueryVariables, OrganizerGradesQuery>, 'query'>;

export function useOrganizerGradesQuery(options: OrganizerGradesArgs = {}) {
  return useQuery<OrganizerGradesQueryVariables, OrganizerGradesQuery>({ query: OrganizerGradesDocument, ...options });
}

export type OrganizerGradesOptions = ExecQueryOptions<OrganizerGradesQueryVariables>;

export function organizerGrades(options: OrganizerGradesOptions) {
  return executeQuery<OrganizerGradesQuery, OrganizerGradesQueryVariables>(OrganizerGradesDocument, options);
}

export type OrganizerGradesSelections = OrganizerGradesQuery['site'];
