import * as React from 'react'

import { HBox, BoxProps } from '../Box';
import { Link } from '../Link';

export interface TabProps extends Partial<BoxProps> {
  to?:string;//if you are using a custom link
  selected?:boolean;
  label:React.ReactNode;
  name?:string;//represents the value of url parameter to control selection, used cojunction with urlParameter
  content?:React.ReactNode;//used with Tabs
  // props to put on the overall tab container when this tab is active
  container?:BoxProps;
}

export function Tab(props:TabProps) {
  const {to, selected, label, name, content, container, children, as, ...remaining} = props;
  const color = props.selected ? 'primary' : 'disabled';

  function render() {
    return as
      ? renderAs()
      : to
        ? renderLink()
        : renderTab()
  }

  function renderAs() {
    return React.createElement(as, {to, selected, children, ...remaining});
  }

  function renderLink() {
    return <Link text='subtitle1' color={color} to={to} underline={false}>
      {renderTab()}
    </Link>;
  }

  function renderTab() {
    const testId = [label, children, name].find(l => typeof l === 'string');
    const borderColor = props.selected ? 'primary' : '#64646400';
    return <HBox text='subtitle1' data-test={`Tab(${testId})`} borderBottom='solid 4px' pb='$4' height='min-content' color={color} borderColor={borderColor} cursor='pointer' whiteSpace='nowrap' hAlign='center' vAlign='center' {...remaining}>
      {children}
    </HBox>;
  }

  return render();
}

