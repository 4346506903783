export const dayOptions = [
  {
    value: 'Sunday',
    label: 'Sunday',
    tag: 'Sun'
  },
  {
    value: 'Monday',
    label: 'Monday',
    tag: 'Mon'
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
    tag: 'Tue'
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
    tag: 'Wed'
  },
  {
    value: 'Thursday',
    label: 'Thursday',
    tag: 'Thu'
  },
  {
    value: 'Friday',
    label: 'Friday',
    tag: 'Fri'
  },
  {
    value: 'Saturday',
    label: 'Saturday',
    tag: 'Sat'
  }
];

export const abbreviatedDayOptions = [
  {
    value: 'Sunday',
    label: 'Sun',
    tag: 'Sun'
  },
  {
    value: 'Monday',
    label: 'Mon',
    tag: 'Mon'
  },
  {
    value: 'Tuesday',
    label: 'Tue',
    tag: 'Tue'
  },
  {
    value: 'Wednesday',
    label: 'Wed',
    tag: 'Wed'
  },
  {
    value: 'Thursday',
    label: 'Thu',
    tag: 'Thu'
  },
  {
    value: 'Friday',
    label: 'Fri',
    tag: 'Fri'
  },
  {
    value: 'Saturday',
    label: 'Sat',
    tag: 'Sat'
  }
];
