import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorTeachersPickerQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type VendorTeachersPickerQuery = {
  vendor?:
    | { company?: { teachers?: Array<{ id: string; name: string; phone?: string | null | undefined; email?: string | null | undefined; typename: 'Teacher' }> | null | undefined } | null | undefined }
    | null
    | undefined;
};

export const VendorTeachersPickerDocument = gql`
  query VendorTeachersPicker($vendor: ID!) @urql(additionalTypenames: ["Teacher"]) {
    vendor(vendor: $vendor) {
      company {
        teachers(pageSize: 400) {
          typename: __typename
          id
          name
          phone
          email
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorTeachersPickerArgs = MakeOptional<UseQueryArgs<VendorTeachersPickerQueryVariables, VendorTeachersPickerQuery>, 'query'>;

export function useVendorTeachersPickerQuery(options: VendorTeachersPickerArgs = {}) {
  return useQuery<VendorTeachersPickerQueryVariables, VendorTeachersPickerQuery>({ query: VendorTeachersPickerDocument, ...options });
}

export type VendorTeachersPickerOptions = ExecQueryOptions<VendorTeachersPickerQueryVariables>;

export function vendorTeachersPicker(options: VendorTeachersPickerOptions) {
  return executeQuery<VendorTeachersPickerQuery, VendorTeachersPickerQueryVariables>(VendorTeachersPickerDocument, options);
}

export type VendorTeachersPickerSelections = VendorTeachersPickerQuery['vendor'];

(VendorTeachersPickerDocument as any).additionalTypenames = ['Teacher'];
