import * as React from 'react'

import { defaultFieldProps } from 'app2/components';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useNonPartnerSitesQuery, NonPartnerSitesSelections } from './generated';

export function NonPartnerSitePicker(props:SearchDropdownProps<NonPartnerSitesSelections>) {
  function render() {
    return <SearchDropdown<NonPartnerSitesSelections> query={useNonPartnerSitesQuery} variables={renderVars} placeholder='Select a school' renderItem={renderOptionLabel} dropdownWidth={400} {...props} />
  }
  
  function renderOptionLabel(item:NonPartnerSitesSelections) {
    const hasCityState = item?.city && item?.state;
    const cityState = hasCityState ? ` (${item?.city}, ${item?.state})` : '';
    return `${item?.name}${cityState}`;
  }

  function renderVars(term:string) {
    return [{search:term || ""}]
  }
  
  return render();
}

NonPartnerSitePicker.fieldProps = {
  ...defaultFieldProps,
  readOnlyProperty: 'readOnly'
}
