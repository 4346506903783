import * as React from 'react'
import { useParams } from 'react-router'

import { Panel, Field, RepeatingSection, Section, Info, InputField, useForm } from 'app2/components'
import { SiteRouteParams } from '../organizerRoutes'

import { organizerUpdateGrades, useOrganizerGradesQuery } from './gql'

export function Grades() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ classroomsQueryResult ] = useOrganizerGradesQuery({variables: { siteId }});
  const site = classroomsQueryResult.data?.site;
  const grades = site?.grades
  const form = useForm({ grades: grades }, [grades]);

  function render() {
    return <Panel icon='FileText' title='Grades'  type='toggle' form={form} onOk={onOk}>
      <Section mode='edit'>
        <Info type='warning'>
          Changing a grade's label does not change the actual grade.  The grade values in the first column are grades used to
          determine activity grade restrictions and the order in which students’ grades are automatically advanced each year.
        </Info>
      </Section>
      <RepeatingSection name='grades' onRemove='hidden' fields={[
        <Field label='Grade' name='value' required  />,
        <Field label='Label' name='label' required component={InputField} />,
        'hide'
      ]} />
    </Panel>
  }
  
  async function onOk() {
    const variables = { site: site.id, grades: form.values.grades };
    const [success] = await organizerUpdateGrades({variables, successMsg: "Successfully saved changes to the classrooms.", error: form})
    return success;
  }

  return render();
}
