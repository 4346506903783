import * as React from 'react';
import { variant } from './styled-system';

import { untrustedPaste, createChangeEvent } from './dom-utils'
import { theme } from './theme';
import { ComponentProps, componentStyles, createComponent } from './utils';
import { HBox, BoxCustomProps } from './Box';
import { Icon, IconNames } from './icons';
import { Option, OptionValue } from './Option';
import { Tag, TagProps } from './Tag';

export type InputKind = 'normal' | 'error' | 'warning' | 'disabled';
type TagClickHandler1 = (tag:string | Option, tagNo:number) => void;
type TagClickHandler2 = (tag:string, tagNo:number) => void;
type TagClickHandler3 = (tag:Option, tagNo:number) => void;
type TagClickHandler = TagClickHandler1 | TagClickHandler2 | TagClickHandler3;
type Tags = (string | Option)[];

interface InputCustomProps {
  kind?: InputKind;
  error?: boolean | string;
  warning?: boolean | string;
  value?: OptionValue;
  // if specified, the input will display this
  // text as a placeholder, when the input has a value
  shadowValue?:string;
  icon?: IconNames;
  iconSize?: number;
  onIconClick?:(event:React.MouseEvent) => void;
  onIconMouseDown?:(event:React.MouseEvent) => void;
  tags?: Tags;
  tagProps?:TagProps;
  inputProps?:ComponentProps<React.InputHTMLAttributes<HTMLInputElement>>;
  nowrap?:boolean;
  onTagClick?:TagClickHandler;
  onTagCloseClick?:TagClickHandler;
  outerRef?:React.RefObject<HTMLDivElement>;
  // indicates an X icon should be added. if true by default
  // this will clear the contents, but you can override this
  // via the onClear callback
  clearable?:boolean;
  onClear?:() => void;
  blockCreditCards?:boolean;
  // allows adding controls to the left of the icon, and right of the input
  controls?:React.ReactNode;
}

export type InputProps = ComponentProps<React.InputHTMLAttributes<HTMLInputElement>> & InputCustomProps & BoxCustomProps;
const propsToExclude = ['kind', 'error', 'warning', 'shadowValue', 'comparator', 'icon', 'iconSize', 'onIconClick', 'onIconMouseDown', 'tags', 'tagProps', 'inputProps', 'nowrap', 'onTagClick', 'onTagCloseClick', 'hasTags', 'clearable', 'onClear', 'blockCreditCards', 'controls'];

// because input supports icons the input control is more than just an input tag
// we use a box to wrap the input that potentially contains an icon.
// this is because html does not allow input tags to contain children.
// both the box and the input have styles, the box styles are used to apply
// selectors to the icon (svg) to color it based on the kind property.
// the rest of the styles (as well as any passed in as props) are applied to the input

// for some reason when specifying all the placeholders together it breaks chrome
export const placeholder = '::-webkit-input-placeholder,::placeholder';
export const placeholder2 = '::-moz-placeholder,:-moz-placeholder,:-ms-input-placeholder';
const icon = 'input+div>svg';
export const selection = '::selection';

const iconPaddingX = 4;
export const inputPaddingX = 11;
export const inputPaddingY = 8.5;
export const inputHeight = (inputPaddingY * 2) + parseFloat(theme.lineHeights.input);
const focused = ':focus-within:not(:disabled)';

const OuterStyles = createComponent(HBox, propsToExclude)<InputProps & BoxCustomProps>`
  padding: ${props => !props.hasTags ? `${inputPaddingY}px ${inputPaddingX}px ${inputPaddingY}px ${inputPaddingX}px` : `6px ${inputPaddingX}px 6px ${inputPaddingX}px`};
  position: relative;
  ::after {
    font-weight: ${props => props.theme.fontWeights.normal};
    font-size: ${props => props.theme.fontSizes.input};
    font-family: ${props => props.theme.fonts.input};
    position: absolute;
    content: attr(data-shadow-value);
    pointer-events: none;
    opacity: 0.6;
  }
  display: inline-flex;
  ${props => variant({
    prop: 'kind',
    variants: {
      normal: {
        cursor: 'text',
        borderColor: props.borderColor ? theme.colors[props.borderColor as keyof typeof theme.colors] || props.borderColor : 'border',
        [icon + ':hover']: {
          stroke: theme.colors.primaryHover,
          color: theme.colors.primaryHover,
        },
        [focused]: {
          borderColor: 'primary',
        }
      },
      error: {
        cursor: 'text',
        borderColor: 'error',
        [icon]: {
          stroke: 'error',
          color: 'error'
        },
        [icon + ':hover']: {
          stroke: theme.colors.errorHover,
          color: theme.colors.errorHover
        }
      },
      warning: {
        cursor: 'text',
        borderColor: 'warning',
        [icon]: {
          stroke: 'warning',
          color: 'warning'
        },
        [icon + ':hover']: {
          stroke: theme.colors.warningHover,
          color: theme.colors.warningHover
        }
      },
      disabled: {
        color: 'disabled',
        borderColor: 'border',
        bg: 'disabledBackground',
        [icon]: {
          stroke: 'disabled',
          color: 'disabled'
        }  
      }
    }
  })}
` as React.ComponentType<InputProps & BoxCustomProps & {hasTags:boolean}>;

OuterStyles.defaultProps = {
  bg: 'background',
  border: 'solid 1px',
  borderRadius: 'standard'
}

const InputStyles = createComponent('input', propsToExclude)`
  background: inherit;
  appearance: none;
  outline: none;
  border: none;
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: ${props => props.theme.fontSizes.input};
  font-family: ${props => props.theme.fonts.input};
  padding: 0px;
  line-height: ${props => props.theme.lineHeights.input};
  flex: 1;
  height: ${
    /* this is needed because the type field can cause the browser
       to add controls that make the input slightly larger than normal */
    props => parseInt(props.theme.lineHeights.input) + 'px'};

  input&._tags::-webkit-input-placeholder {
    color: transparent;
  }
  input&._tags:-moz-placeholder {
    color: transparent;
  }
  input&._tags::-moz-placeholder {
    color: transparent;
  }
  input&._tags:-ms-input-placeholder {
    color: transparent;
  }
  input&._tags:placeholder {
    color: transparent;
  }

  ${variant({
    prop: 'kind',
    variants: {
      normal: {
        color: 'input',
        [placeholder]: {
          color: 'placeholder'
        },
        [placeholder2]: {
          color: 'placeholder'
        },
        [selection]: {
          bg: 'selection'
        }
      },
      error: {
        color: 'error',
        [placeholder]: {
          color: 'error'
        },
        [placeholder2]: {
          color: 'error'
        },
        [selection]: {
          bg: 'errorSelection'
        }
      },
      warning: {
        color: 'warningText',
        [placeholder]: {
          color: 'warning'
        },
        [placeholder2]: {
          color: 'warning'
        },
        [selection]: {
          bg: 'warningSelection'
        }
      }
    }
  })}
  ${componentStyles}
` as React.ComponentType<InputProps>;

export const Input = React.forwardRef((props: InputProps, ref:any) => {
  let { kind, error, warning, shadowValue, icon, iconSize, onIconClick, onIconMouseDown, tags, tagProps, inputProps, nowrap, onTagClick, onTagCloseClick, outerRef, readOnly, clearable, onClear, blockCreditCards, controls,
    value, disabled, onChange, onKeyDown, border, borderRadius, tooltip, children, ...allRemaining } = props;
  let { input, remaining} = separateInputProps(allRemaining);

  const hasTags = tags && tags.length != 0;
  const [stateValue, setValue] = React.useState(value);
  const ourRef = React.useRef(ref);
  ref = ref || ourRef;

  kind = disabled ? 'disabled' : error ? 'error' : warning ? 'warning' : kind;
  disabled = disabled || kind === 'disabled';
  value = ("value" in props ? value : stateValue) || '';// note that if value is numeric its ignored and turned into empty string, which is intentional

  if (disabled) {
    onTagClick = null;
    onTagCloseClick = null;
    onIconClick = null;
    onIconMouseDown = null;
  }

  function render() {
    const paddingRight = icon ? (iconSize + inputPaddingX) + 'px!important' : undefined;

    if (!tooltip && (typeof error == 'string' || typeof warning == 'string')) {
      tooltip = error || warning;
    }

    // since border and borderRadius have defaults, they are explicitly extracted from props so that more 
    // specialized props (like borderLeft) and come after and override the defaults
    return <OuterStyles ref={outerRef} data-test='Input' kind={kind} vAlign="center" paddingRight={paddingRight} gap='$4' hasTags={hasTags} data-shadow-value={shadowValue} 
      flexWrap={nowrap ? 'nowrap' : hasTags ? 'wrap' : undefined} onMouseDown={handleMouseDown} border={border} borderRadius={borderRadius} tooltip={tooltip} {...remaining}>
      {children}
      {renderTags()}
      {renderInput()}
      {controls}
      {renderIcon()}
    </OuterStyles>;
  }

  function renderTags() {
    if (!hasTags) {
      return;
    }
    
    return tags.map((tag:string | Option, index:number) => <Tag key={index} optionOrLabel={tag} nowrap={nowrap}
      onClick={() => onTagClick?.(tag as any, index)} onCloseClick={() => onTagCloseClick?.(tag as any, index)} onMouseDown={handleTagMouseDown} {...tagProps} />)
  }
  
  function renderInput() {  
    const numChars = value.toString().length;
    const width = hasTags 
      ? (numChars == 0 
        ? '3px' // prevents allocating an extra line when there's no text (which looks odd)
        : `calc(min(100%, ${Math.max(Math.ceil(numChars * 1.1), 8) + 'ch'}))`) : '100%';

    if (hasTags) {
      // @ts-ignore
      if (!input.className) {
        // @ts-ignore
        input.className = '';
      }
      
      // @ts-ignore
      input.className += ' _tags';
    }
  
    return <InputStyles kind={kind} ref={ref} width={width} maxWidth='100%' disabled={disabled} mr='0'
      value={value} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} readOnly={readOnly} {...inputProps} {...input}  />
  }
  
  function renderIcon() {
    if (!icon) {
      return;
    }
  
    return <>
      {clearable && <Icon name='XCircle' size={iconSize} cursor='pointer' position='relative' left='2px' marginLeft='-5px' onMouseDown={handleClear} opacity={.7} />}
      <Icon position="absolute" size={iconSize} top={`calc((100% - ${iconSize}px)/2)`} right={inputPaddingX - iconPaddingX} 
        name={icon} cursor={onIconClick || onIconMouseDown ? 'pointer' : 'default'} onClick={onIconClick} onMouseDown={onIconMouseDown} />
    </>
  }

  function handleMouseDown(event:React.MouseEvent) {
    // this prevents the focus from switching off the input when
    // the user clicks on anything other than the input
    if (event.target != ref.current || document.activeElement != ref.current) {
      ref.current.focus();
      event.preventDefault();
    }
  }

  function handleTagMouseDown(event:React.MouseEvent) {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    ref.current.focus();
  }

  function handleChange(event:React.ChangeEvent<HTMLInputElement>) {
    if (readOnly) {
      return;
    }

    if (blockCreditCards) {
      const creditCardNumber = creditCard.test(event.currentTarget.value?.trim())

      if (creditCardNumber) {
        return;
      }
    }

    // always set the value, even if controlled, because the comparator needs it and may cause
    // the input to use the state value instead of the props value.
    setValue(event.currentTarget.value);
    onChange?.(event);
  }
  
  function handleKeyDown(event:React.KeyboardEvent<HTMLInputElement>) {
    if ((event.key == 'Backspace' || event.key == 'Delete') && tags && tags.length && ref.current.selectionStart == 0 && ref.current.selectionEnd == 0) {
      const pos = tags.length - 1;
      onTagCloseClick?.(tags[pos] as any, pos);
    }

    onKeyDown?.(event);
  }

  function handlePaste(event:React.ClipboardEvent<HTMLInputElement>) {
    untrustedPaste(event, ref.current, handleChange)
  }

  function handleClear(event:React.MouseEvent) {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    ref.current.focus();

    if (onClear) {
      onClear()
    }
    else {
      handleChange(createChangeEvent(ref.current, ''));
    }
  }
  
  return render();
})

function separateInputProps(props:Partial<InputProps>) {
  // input specific attributes that need to be passed on to the input
  const {ref, accept, alt, autoComplete, autoFocus, capture, form, list, max, maxLength, min, multiple, spellCheck, inputMode,
  // style specific properties that aren't getting applied properly due to how styled components and styled system
    color, fontWeight, fontSize, fontFamily,
  // @ts-ignore
    pattern, placeholder, required, step, type, value, textAlign, ...remaining } = props;
  
  return {remaining, input: {accept, alt, autoComplete, autoFocus, capture, form, list, max, maxLength, min, multiple, spellCheck, color, fontWeight, fontSize, fontFamily, inputMode, pattern, placeholder, required, step, type, textAlign}}
}

Input.defaultProps = {
  kind: 'normal',
  iconSize: 14,
  spellCheck: false,
  vAlign: 'center',
  blockCreditCards: true
};

Input.displayName = 'Input'

const digits4 = '\\d{4}'
const digits5 = '\\d{5}'
const digits6 = '\\d{6}'
const sep = '(\\-|\\.|\\s)?'

const amex = digits4 + sep + digits6 + sep + digits5
const others = digits4 + sep + digits4 + sep + digits4 + sep + digits4
const creditCard = new RegExp(`(^${amex}$)|(^${others}$)`)

