import * as React from 'react'

import { VBox } from 'app2/components';
import { PrivatePage, PrivatePageProps } from 'app2/views/shared';

import { FamilyDropdown, FamilyDropdownProps } from './FamilyDropdown';

export function ParentPage(props:PrivatePageProps & {familyDropdown?:FamilyDropdownProps}) {
  const {actions, familyDropdown, ...remaining} = props;

  function render() {
    return <PrivatePage actions={renderActions()}  {...remaining} />
  }

  function renderActions() {
    return <VBox hAlign='right' gap='$16' vAlign='top' width='100%'>
      <FamilyDropdown {...familyDropdown} />
      {actions}
    </VBox>
  }

  return render();
}
