import React from 'react';

import { Course, Vendor } from 'app2/api';
import { Dropdown, DropdownProps, defaultFieldProps, FieldInfo } from 'app2/components';
import { findQueryOption } from 'app2/views/shared-public';

import { useSiteVendorsQuery, siteVendors, SiteVendorsSelections } from './generated';

interface EntityInputProps {
  field?:FieldInfo<Course>;
  course?:Course;
  site?:String;
}

interface Props extends Omit<DropdownProps, 'options'>, EntityInputProps {
}

export function SiteVendorPicker(props: Props) {  
  const {field, site:siteProp, ...remaining} = props;
  const site = getEntity(props);
  const options = getOptions();

  function render() {
    return <Dropdown options={options} {...remaining} />
  }

  function getOptions() {
    const [result] = useSiteVendorsQuery({variables: {site}, requestPolicy: 'cache-first', hideLoader: true})
    return React.useMemo(() => result.data?.site?.vendors?.map?.(createOptions), [result]);
  }

  function createOptions(item:SiteVendorsSelections['vendors'][0]) {
    return {value: item, label: item.displayName};
  }

  return render();
}

export async function findSiteVendor(nameOrObArray:number | string | Pick<Vendor, 'id' | 'displayName'> | string[] | Pick<Vendor, 'id' | 'displayName'>[], props:EntityInputProps):Promise<SiteVendorsSelections['vendors'][0]> {
  const site = getEntity(props);

  if (!site) {
    return;
  }

  return findQueryOption(siteVendors, {site}, nameOrObArray, 'displayName')
}

function getEntity(entity:EntityInputProps) {
  const course = entity.course || entity.field?.values?.[0];
  const site = entity.site || course?.site?.id || course?.siteId;

  return site;
}

SiteVendorPicker.findVendor = findSiteVendor;

SiteVendorPicker.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'field'
}

