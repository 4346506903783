export function isScrollable(node:Element, checkSize:boolean = false) {
  if (!node) {
    return false;
  }

  const { overflowX, overflowY } = window.getComputedStyle(node);
  let scrollable = node != document.body &&
    ((overflowX.startsWith('scroll') || overflowX.startsWith('auto')) ||
     (overflowY.startsWith('scroll') || overflowY.startsWith('auto')))

  // note its possible that the above thinks it found the
  // scrollable container, but its possible, based on how its
  // sized, that it might not scroll, which is hard to detect
  // reliably, so this check is optional to when the caller knows
  // this might occur.

  if (checkSize && scrollable) {
    // if the element is not scrolled and its scroll dimensions same or smaller it might not be scrollable
    if (node.scrollTop == 0 && node.scrollLeft == 0 && node.scrollHeight <= node.clientHeight && node.scrollWidth <= node.clientWidth) {
      // tests if the ndoe can scroll
      node.scrollTop = 1;
      node.scrollLeft = 1;

      scrollable = node.scrollTop == 1 && node.scrollLeft == 1;

      node.scrollTop = 0;
      node.scrollLeft = 0;
    }
  }

  return scrollable;
}
