import * as React from 'react'

import { Rating, RatingProps } from 'app2/components'

export function CourseRating(props:RatingProps) {
  if (props.rating === undefined || props.rating === null || props.count === 0) {
    return <>No reviews yet</>
  }

  return <Rating icon='Star' fill='#fbb034' strokeWidth={1} gap='4px' units='review' {...props} />
}

CourseRating.fieldProps = Rating.fieldProps;