import * as React from 'react';
import { useParams } from 'react-router'

import { organizerPreferences } from 'app2/api';
import { BoxProps, Dropdown, HBox, Text, Span } from 'app2/components';
import { useDropdownWithUrl } from 'app2/views/shared-public';

import { useSeasons } from './useSeasons';
import { OrganizerSiteSeasonsSelections } from '.'
import { SeasonRouteParams } from '../organizerRoutes';

export function SeasonDropdown(props:BoxProps) {
  function render() {
    const {site} = useParams<SeasonRouteParams>();
    const {seasons} = useSeasons();
    const options = React.useMemo(() => seasons?.map(renderSeasonOption) || [], [seasons]);
    const valueAndOnChange = useDropdownWithUrl({options, preferences: organizerPreferences.sitePreferences(site), urlParameter: 'season', valueParameter: 'id'});
    
    if (!site) {
      return <></>;
    }

  return <HBox vAlign="center" mb="$20" width={['100%', 'unset']} {...props}>
      <Text text={['subtitle2', 'subtitle1', 'subtitle1']} mr="$8">Season</Text>
      <Dropdown flex={[1, 'unset', 'unset']} options={options} {...valueAndOnChange} tooltips
        // @ts-ignore - ts is incorrectly generating an error here
        width={['unset', '300px', '300px']} />
    </HBox>
  }

  function renderSeasonOption(season:OrganizerSiteSeasonsSelections['seasonsByStatus'][0]) {
    return {value: season, text: season.name, label: renderSeasonLabel(season)}
  }

  function renderSeasonLabel(season:OrganizerSiteSeasonsSelections['seasonsByStatus'][0]) {
    if (!season.completed && !season.upcoming) {
      return season.name;
    }

    const status = season.completed ? 'Completed' : 'Upcoming';

    return <Text display='inline-flex' width='100%'>
      {season.name}
      <HBox flex={1} />
      <Span fontStyle='italic' color='disabled'>{status}</Span>
    </Text>
  }

  return render();
}
