import { axios } from 'app2/api';
declare const cloudinary: any;

import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_API_KEY } from './constants';

export interface UploadSuccess {
  // includes version + root folder (prod or dev) + specified folder
  url: string;
  version: number;
}

export interface CloundinaryUploaderOptions {
  uploadPreset?: string;
  sources?: string[];
  defaultSource?: string;
  invalidate?: boolean;
  folder?: string;
  publicId?: string;
  overwrite?: boolean;
  cropping?: boolean;
  croppingAspectRatio?: number;
  croppingDefaultSelectionRatio?: number;
  multiple?: boolean;
  showSkipCropButton?: boolean;

  // this is not a real option but easiest to have it here
  signatureUrl: string;
  customSignatureParams?: object;
}

interface FullCloundinaryUploaderOptions extends CloundinaryUploaderOptions {
  apiKey: string;
  cloudName: string;
  uploadSignature: Function;
  showPoweredBy: boolean;
}

const defaultOptions = {
  sources: ['camera', 'local'],
  invalidate: true,
  overwrite: true,
  multiple: false,
  showSkipCropButton: false,
  defaultSource: 'local',
  apiKey: CLOUDINARY_API_KEY,
  cloudName: CLOUDINARY_CLOUD_NAME,
  showPoweredBy: false,
  cropping: true,
  croppingAspectRatio: 1,
  croppingDefaultSelectionRatio: 1
};

// if you use this, you must wrap your react component in CloudinaryLoader
export class CloudinaryUploader {
  options: Partial<FullCloundinaryUploaderOptions>;
  onUploadSuccess?: (info: UploadSuccess) => void;
  onUploadError?: (err: any) => void;
  widget: any;

  constructor(
    options: Partial<CloundinaryUploaderOptions>,
    onUploadSuccess?: (info: UploadSuccess) => void,
    onUploadError?: (err: any) => void
  ) {
    this.onUploadSuccess = onUploadSuccess;
    this.onUploadError = onUploadError;
    const defaults = Object.assign({}, defaultOptions);

    // Set options using defaults and customized
    this.options = Object.assign({}, defaults, options);
    // set signature
    this.options.uploadSignature = this.generateCloudinarySignature;

    this.widget = cloudinary.createUploadWidget(
      this.options,
      this.onUploadEvent
    );
  }

  open() {
    this.widget.open();
  }

  generateCloudinarySignature = async (callback: Function, params: any) => {
    const mergedParams = this.options.customSignatureParams
      ? { ...params, ...this.options.customSignatureParams }
      : params;

    try {
      const response = await axios.post(
        this.options.signatureUrl as string,
        mergedParams
      );
      const signature = response.data.signature;

      callback(signature);
    } catch (err) {
      this.handleUploadError(err);
    }
  };

  onUploadEvent = (err: any, info: any) => {
    if (!err && info.event == 'success') {
      this.widget = null;

      if (this.onUploadSuccess) {
        const version = info.info.version;
        const url = 'v' + version + '/' + info.info.public_id;

        this.onUploadSuccess({ version, url });
      }
    } else if (err) {
      if (err.statusText) {
        err = new Error(err.statusText);
      }

      this.handleUploadError(err);
    }
  };

  handleUploadError(err: any) {
    if (this.widget) {
      this.widget.close({ quiet: true });
      this.widget = null;
    }

    if (this.onUploadError) {
      this.onUploadError(err);
    }
  }
}
