import * as React from 'react'

import { compact } from 'app2/api';
import { DropdownField, EmailField, FieldInfo, FormModel, Icon, InputField, Panel, Part, PhoneField, Section, TextAreaField, validUrl } from 'app2/components'

import { useCompanyQuery } from '../shared'

import { CompanyInfoSelections, useCompanyInfoQuery, companyUpdate } from './generated';

export function CompanyInfo() {
  const {company} = useCompanyQuery(useCompanyInfoQuery);

  function render() {
    return <Panel icon='FileText' title='Provider info' type='toggle' initialValues={company} onOk={onOk}>
      <Section name='name' label='Name' component={InputField} />
      <Section name='size' label='Size' component={DropdownField} options={companySizes} />
      <Section name='email' label='Email' component={EmailField} />
      <Section name='address' label='Address' component={InputField} />
      <Section continued>
        <Part name='city' mode='display' format={renderCityStateZip} none={false} />
        <Part label='Zip code' name='zipcode' required component={InputField} mode='edit' />
      </Section>
      <Section label='Teaching radius' name='teachingRadius' options={teachingRadiusOptions} required component={DropdownField} />
      <Section name='website' label='Website' component={InputField} validators={validUrl} />
      <Section name='phone' label='Phone' component={PhoneField} />
      <Section name='about' label='About' component={TextAreaField} placeholder='Enter a description for your enrichment program that will be publicly displayed to parents' />
      <Section name='headline' label={<>Headline <Icon name='Info' size='small' tooltip={headlineTooltip}/></>} component={InputField} />
    </Panel>
  }

  function renderCityStateZip(_: any, _2:any, info:FieldInfo<CompanyInfoSelections>) {
    const company = info.record;

    if (!company) {
      return '';
    }

    return compact([company.city, compact([company.state, company.zipcode]).join(' ')]).join(', ');
  }

  async function onOk(form:FormModel<CompanyInfoSelections>) {
    const [success] = await companyUpdate({variables: {company: company.id, ...form.values}, error: form});

    return success;
  }

  return render();
}

const headlineTooltip = 'Include a catchy title that gives families a glimpse into your experience and passion. For example, “Experienced and enthusiastic Sacramento-based arts educator, focused on bringing new and different art forms to children at home and at school."'
export const companySizes = [
  {
    label: 'Just me',
    value: 0
  },
  {
    label: '2-5 people',
    value: 1
  },
  {
    label: '6-10 people',
    value: 2
  },
  {
    label: '11-20 people',
    value: 3
  },
  {
    label: '20+ people',
    value: 4
  }
];

export const teachingRadiusOptions = [
  { label: 'Up to 5 miles away', value: 5 },
  { label: 'Up to 10 miles away', value: 10 },
  { label: 'Up to 25 miles away', value: 25 },
  { label: 'Up to 50 miles away', value: 50 },
  { label: 'Up to 100 miles away', value: 100 },
  { label: 'Open to all opportunities', value: -1 }
]
