import * as React from 'react'

import { CourseKind, DeepPartial, Course, CourseStatus } from 'app2/api';
import { BoxProps, defaultFieldProps, HBox, FieldInfo, FieldValue, useFormInfo } from 'app2/components'
import { CourseLink } from 'app2/views/shared-public'

import { CourseLessonsIcon } from './CourseLessonsIcon';

interface Props extends BoxProps {
  info:FieldInfo<DeepPartial<Course>>;
  name:string;
  tab?:string;
}

export function CourseName(props:Props) {
  const {info, name, tab, ...remaining} = props;
  const formInfo = useFormInfo();

  function render() {
    return !formInfo?.editing
      ? renderDisplay()
      : renderEditDisplay()
  }

  function renderDisplay() {
    // for games we let the home/away field be the link to the activities
    const linkable = info.record?.kind != CourseKind.Game;

    return linkable
      ? renderDisplayLink()
      : renderDisplayText()
  }

  function renderDisplayLink() {
    const course = info.record;
    const provider = !course?.site?.userSiteRole;
    const request = course?.status == CourseStatus.Request;
    const suffix = tab || (provider && request ? '/info?edit' : undefined);

    return <HBox text='body' display='inline' maxLines={2} {...remaining}>
      <CourseLink info={info as any} display='inline!important' suffix={suffix} />
      <CourseLessonsIcon info={info as any} ml='4px' display='inline-block' />
    </HBox>
  }

  function renderDisplayText() {
    return <FieldValue {...remaining}>{props.name}</FieldValue>
  }

  // the editor manager renders the actual edit control
  // this is the non-editing display while in table edit mode
  function renderEditDisplay() {
    return <FieldValue {...remaining}>{props.name}</FieldValue>
  }

  return render();
}

CourseName.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info',
  valueProperty: 'name'
}
