import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReenrollMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type ReenrollMutation = { enrollmentsReenroll?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ReenrollDocument = gql`
  mutation Reenroll($courseId: ID!, $ids: [ID!]!) {
    enrollmentsReenroll(courseId: $courseId, ids: $ids) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ReenrollOptions = ExecMutationOptions<ReenrollMutationVariables>;

export function reenroll(options: ReenrollOptions) {
  return executeMutation<ReenrollMutation, ReenrollMutationVariables>(ReenrollDocument, options);
}
