import * as React from 'react'
import * as ReactIs from 'react-is';

import { Text, TextProps } from '../Text';
import { inputHeight } from '../Input';

import { useFormInfo } from '.';

export interface FieldValueProps extends TextProps {
  // indicates that this field value is part of something readonly even if the form is being edited
  readOnly?:boolean;
}

export function FieldValue(props:FieldValueProps) {
  let {children, readOnly, ...remaining} = props;

  const ctx = useFormInfo();

  // FieldValue ignores values are plain POJOs (which happens for parent form sections that want to display errors but not values) but doesn't
  // ignore react elements which formatters can return (but which also are POJOs)
  children = typeof children != 'object' || ReactIs.isElement(children) || (Array.isArray(children) && (children.length == 0 || ReactIs.isElement(children[0]) || children.filter(c => typeof c != 'string').length == 0)) ? children : '';

  return <Text className='hr-value' style={ctx?.editing && !readOnly ? editStyles : undefined}  {...remaining}>{children}</Text>
}

const editStyles = {
  minHeight:inputHeight,
  display: 'flex',
  alignItems: 'center'
}

FieldValue.defaultProps = {
  text: 'body'
}

FieldValue.fieldProps = {
  valueProperty:'children',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  readOnlyProperty: 'readOnly',
  preserveWhitespace: true
}
