import pluralize from 'pluralize';

export function formatAge(months:number, field?:any, info?:any) {
  return formatAges(months);
}

export function formatAges(min:number, max?:number, short?:boolean) {
  if (!(min ?? false) && !(max ?? false)) {
    return '';
  }

  const minYears = Math.floor(min / 12);
  const minMonths = min % 12;

  if (max === undefined || min == max) {
    return formatAgeParts(minYears, minMonths, short);
  }

  const maxYears = Math.floor(max / 12);
  const maxMonths = max % 12;

  if (!minYears && !maxYears) {
    return `${minMonths} - ${formatAgeParts(0, maxMonths, short)}`;
  }

  if (!minMonths && !maxMonths) {
    return `${minYears} - ${formatAgeParts(maxYears, 0, short)}`;
  }

  return `${formatAgeParts(minYears, minMonths, short)} - ${formatAgeParts(maxYears, maxMonths, short)}`;
}

function formatAgeParts(years:number, months:number, short?:boolean) {
  return short ? formatAgePartsShort(years, months) : formatAgePartsLong(years, months);
}

function formatAgePartsShort(years:number, months:number) {
  if (isNaN(years) || isNaN(months)) {
    return '';
  }

  if (years && !months) {
    return `${years}y`;
  }

  if (!years && months) {
    return `${months}m`
  }

  return `${years}y${months}m` 
}

function formatAgePartsLong(years:number, months:number) {
  if (isNaN(years) || isNaN(months)) {
    return '';
  }

  if (years && !months) {
    return `${years} ${pluralize('year', years)}`;
  }

  if (!years && months) {
    return `${months} ${pluralize('month', months)}`
  }

  return `${years} ${pluralize('year', years)} ${months} ${pluralize('month', months)}` 
}
