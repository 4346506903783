import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type TeamParticipantsFragment_Company_Fragment = {
  id: string;
  teamParticipantsUpsert?: boolean | null | undefined;
  teamChangeRole?: boolean | null | undefined;
  teamSetAsContact?: boolean | null | undefined;
  userMemberRole?: Types.MemberRole | null | undefined;
  participants?:
    | Array<
        | {
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            isContact?: boolean | null | undefined;
            isCurrentUser?: boolean | null | undefined;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            sentAt: string;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
      >
    | null
    | undefined;
};

export type TeamParticipantsFragment_Family_Fragment = {
  id: string;
  teamParticipantsUpsert?: boolean | null | undefined;
  teamChangeRole?: boolean | null | undefined;
  teamSetAsContact?: boolean | null | undefined;
  userMemberRole?: Types.MemberRole | null | undefined;
  participants?:
    | Array<
        | {
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            isContact?: boolean | null | undefined;
            isCurrentUser?: boolean | null | undefined;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            sentAt: string;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
      >
    | null
    | undefined;
};

export type TeamParticipantsFragment_Site_Fragment = {
  id: string;
  teamParticipantsUpsert?: boolean | null | undefined;
  teamChangeRole?: boolean | null | undefined;
  teamSetAsContact?: boolean | null | undefined;
  userMemberRole?: Types.MemberRole | null | undefined;
  participants?:
    | Array<
        | {
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            isContact?: boolean | null | undefined;
            isCurrentUser?: boolean | null | undefined;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
        | {
            sentAt: string;
            id: string;
            kind: Types.ParticipantKind;
            name?: string | null | undefined;
            role: string;
            relation?: string | null | undefined;
            email?: string | null | undefined;
            phone?: string | null | undefined;
            attendanceCodeCode?: string | null | undefined;
          }
      >
    | null
    | undefined;
};

export type TeamParticipantsFragmentFragment = TeamParticipantsFragment_Company_Fragment | TeamParticipantsFragment_Family_Fragment | TeamParticipantsFragment_Site_Fragment;

export const TeamParticipantsFragmentFragmentDoc = gql`
  fragment teamParticipantsFragment on WithTeam {
    id
    teamParticipantsUpsert
    teamChangeRole
    teamSetAsContact
    userMemberRole
    participants {
      id
      kind
      name
      role
      relation
      email
      phone
      attendanceCodeCode
      ... on Member {
        isContact
        isCurrentUser
      }
      ... on MemberInvitation {
        sentAt
      }
    }
  }
`;
