import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteFeesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  cost?: Types.InputMaybe<Types.Scalars['Float']>;
  feeType?: Types.InputMaybe<Types.SiteFeeTypeEnum>;
  feeRate?: Types.InputMaybe<Types.Scalars['Float']>;
}>;

export type OrganizerSiteFeesQuery = {
  siteFees?:
    | {
        homeroomCharges: number;
        listPrice: number;
        siteCharges: number;
        siteCreditCardFee: number;
        siteReceives: number;
        vendorCourseCost: number;
        vendorMaterialsFee: number;
        vendorCharges: number;
        vendorCreditCardFee: number;
        vendorReceives: number;
      }
    | null
    | undefined;
};

export const OrganizerSiteFeesDocument = gql`
  query OrganizerSiteFees($id: ID!, $cost: Float, $feeType: SiteFeeTypeEnum, $feeRate: Float) {
    siteFees(site: $id, cost: $cost, feeType: $feeType, feeRate: $feeRate) {
      homeroomCharges
      listPrice
      siteCharges
      siteCreditCardFee
      siteReceives
      vendorCourseCost
      vendorMaterialsFee
      vendorCharges
      vendorCreditCardFee
      vendorReceives
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSiteFeesArgs = MakeOptional<UseQueryArgs<OrganizerSiteFeesQueryVariables, OrganizerSiteFeesQuery>, 'query'>;

export function useOrganizerSiteFeesQuery(options: OrganizerSiteFeesArgs = {}) {
  return useQuery<OrganizerSiteFeesQueryVariables, OrganizerSiteFeesQuery>({ query: OrganizerSiteFeesDocument, ...options });
}

export type OrganizerSiteFeesOptions = ExecQueryOptions<OrganizerSiteFeesQueryVariables>;

export function organizerSiteFees(options: OrganizerSiteFeesOptions) {
  return executeQuery<OrganizerSiteFeesQuery, OrganizerSiteFeesQueryVariables>(OrganizerSiteFeesDocument, options);
}

export type OrganizerSiteFeesSelections = OrganizerSiteFeesQuery['siteFees'];
