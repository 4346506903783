import * as React from 'react';
import { startCase } from 'lodash-es';

import { Button, ButtonMenu, ButtonKind, HBox, MenuItem, VBox, Subtitle2 } from 'app/app2/components';

import { Demo } from './Demo';

export function ButtonDemos() {
  return <VBox>
    {renderButtonVariations('primary')}
    {renderButtonVariations('secondary')}
    {renderButtonVariations('tertiary')}
    <Demo name='On a background'>
      <VBox padding='50px' bg='red'>
        <Button>Submit</Button>
      </VBox>
    </Demo>
    <Demo name='Button with modal and tooltip'>
      <Button tooltip='Click me' onClick={<Button>test</Button>}>Show a modal</Button>
    </Demo>
    <Demo name='Button menu'>
      <ButtonMenu menu={[<MenuItem to='https://googe.com' target='_blank'>google</MenuItem>, <MenuItem to='https://yahoo.com' target='_blank'>yahoo</MenuItem>]}>Show a menu</ButtonMenu>
    </Demo>
    <Demo name='Various styles'>
      <Button m='$10' px='50px' border='solid 10px blue' color='red' background='purple'>Submit</Button> 
    </Demo>
    <Demo name='Custom CSS'>
    <Button css={{':hover':{border: 'solid 10px red!important'}}}>Move the mouse over me</Button>
    </Demo>
    <Demo name='Using theme props'>
      <Button fontSize='heading2' bg='brandLight'>Submit</Button> 
    </Demo>
  </VBox>
}

function renderButtonVariations(kind:ButtonKind) {
  const kindName = startCase(kind);
  return <>
    <Demo name={kindName}>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled</Subtitle2>
        <Button kind={kind}>Button</Button>
        <Button kind={kind} icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} icon='X'>Button</Button> 
        <Button kind={kind}></Button>

        <Button kind={kind} small>Button</Button>
        <Button kind={kind} small icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small icon='X'>Button</Button> 
        <Button kind={kind} small></Button>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Hovered</Subtitle2>
        <Button kind={kind} className='hovered'>Button</Button>
        <Button kind={kind} className='hovered' icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} className='hovered' icon='X'>Button</Button> 
        <Button kind={kind} className='hovered'></Button> 

        <Button kind={kind} small className='hovered'>Button</Button>
        <Button kind={kind} small className='hovered' icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small className='hovered' icon='X'>Button</Button> 
        <Button kind={kind} small className='hovered'></Button> 
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Pressed</Subtitle2>
        <Button kind={kind} className='pressed'>Button</Button>
        <Button kind={kind} className='pressed' icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} className='pressed' icon='X'>Button</Button> 
        <Button kind={kind} className='pressed'></Button> 

        <Button kind={kind} small className='pressed'>Button</Button>
        <Button kind={kind} small className='pressed' icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small className='pressed' icon='X'>Button</Button> 
        <Button kind={kind} small className='pressed'></Button> 
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Disabled</Subtitle2>
        <Button kind={kind} disabled>Button</Button>
        <Button kind={kind} disabled icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} disabled icon='X'>Button</Button> 
        <Button kind={kind} disabled></Button> 

        <Button kind={kind} small disabled>Button</Button>
        <Button kind={kind} small disabled icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small disabled icon='X'>Button</Button> 
        <Button kind={kind} small disabled></Button> 
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Loading</Subtitle2>
        <Button kind={kind} loading>Button</Button>
        <Button kind={kind} loading icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} loading icon='X'>Button</Button> 
        <Button kind={kind} loading></Button> 

        <Button kind={kind} small loading>Button</Button>
        <Button kind={kind} small loading icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small loading icon='X'>Button</Button> 
        <Button kind={kind} small loading></Button> 
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Error</Subtitle2>
        <Button kind={kind} error>Button</Button>
        <Button kind={kind} error icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} error icon='X'>Button</Button> 
        <Button kind={kind} error></Button> 

        <Button kind={kind} small error>Button</Button>
        <Button kind={kind} small error icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small error icon='X'>Button</Button> 
        <Button kind={kind} small error></Button> 
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Danger</Subtitle2>
        <Button kind={kind} danger>Button</Button>
        <Button kind={kind} danger icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} danger icon='X'>Button</Button> 
        <Button kind={kind} danger></Button> 

        <Button kind={kind} small danger>Button</Button>
        <Button kind={kind} small danger icon='Plus' iconPosition='left'>Button</Button> 
        <Button kind={kind} small danger icon='X'>Button</Button> 
        <Button kind={kind} small danger></Button> 
      </HBox>
    </Demo>
  </>
}
