import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const Right = React.forwardRef((props:Props, ref:React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg ref={ref} width={props.size} height={props.size} viewBox="0 0 18 18" stroke={color} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <g fill='currentColor'>
      <path d="M6.75 13.5L11.25 9L6.75 4.5" />
      <path d="M6.75 13.5L11.25 9L6.75 4.5" />
      <path d="M6.75 13.5L6.75 4.5" />
    </g>
  </svg>
})

Right.displayName = 'Right';
Right.defaultProps = {
  size: 18
}