import { getSymbol } from './util/getSymbol';

import { observableManager } from './ObservableManager';
import { makeObservable } from './ObservableVisitor';

const observableSymbol: any = getSymbol('observable');

export class Observable {
  constructor() {
    const observable = new Proxy(this, handler);

    //@ts-ignore
    this[observableSymbol] = observable;

    return observable;
  }
}

//@ts-ignore
Observable[observableSymbol] = true;

export class ObservableProxyHandler {
  set(target: any, prop: PropertyKey, newValue: any, receiver: any) {
    const oldValue = target[prop];

    if (oldValue !== newValue) {
      newValue = makeObservable(newValue);
      observableManager.onChanged(receiver, prop, newValue);
    }

    return Reflect.set(target, prop, newValue, receiver);
  }

  get(target: any, prop: PropertyKey, receiver: any) {
    observableManager.onObserved(receiver, prop);

    return Reflect.get(target, prop, receiver);
  }
}

export const handler = new ObservableProxyHandler();
