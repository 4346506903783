import { RedirectProps, RouteProps, matchPath, LinkProps } from 'react-router-dom';

type RouteElement = React.ReactElement<RouteProps | RedirectProps>;

export function findRouteIndex(path:string, routes:(RouteElement | RouteProps | LinkProps)[]) {
  if (!Array.isArray(routes)) {
    return null;
  }

  return routes.findIndex(child => {
    const props = (child as RouteElement).props ? (child as RouteElement).props : child as (RouteProps | LinkProps);

    if (!props) {
      return false;
    }

    const route = {...props, path: (props as RouteProps).path || (props as LinkProps).to} as RouteProps;

    if (!route.path) {
      return false;
    }

    if ((route as any).from) {
      return false;
    }

    return matchPath(path, route);
  });
}

// this will not match on redirects

export function findRoute<T extends (RouteElement | RouteProps | LinkProps)>(path:string, routes:T[]):T {
  const pos = findRouteIndex(path, routes);

  return pos != -1 ? routes[pos] : null;
}