import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentRemoveCreditCardMutationVariables = Types.Exact<{
  token: Types.Scalars['ID'];
}>;

export type ParentRemoveCreditCardMutation = { parentRemoveCreditCard?: { success?: boolean | null | undefined } | null | undefined };

export const ParentRemoveCreditCardDocument = gql`
  mutation ParentRemoveCreditCard($token: ID!) @urql(additionalTypenames: ["CreditCard"]) {
    parentRemoveCreditCard(token: $token) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentRemoveCreditCardOptions = ExecMutationOptions<ParentRemoveCreditCardMutationVariables>;

export function parentRemoveCreditCard(options: ParentRemoveCreditCardOptions) {
  return executeMutation<ParentRemoveCreditCardMutation, ParentRemoveCreditCardMutationVariables>(ParentRemoveCreditCardDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['CreditCard']) }
  });
}
