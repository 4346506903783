import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompaniesQueryVariables = Types.Exact<{
  term: Types.Scalars['OptionValue'];
}>;

export type AdminCompaniesQuery = { companies?: Array<{ id: string; name?: string | null | undefined }> | null | undefined };

export const AdminCompaniesDocument = gql`
  query AdminCompanies($term: OptionValue!) {
    companies(filters: [{ name: "name", op: CONTAINS, value: $term }]) {
      id
      name
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCompaniesArgs = MakeOptional<UseQueryArgs<AdminCompaniesQueryVariables, AdminCompaniesQuery>, 'query'>;

export function useAdminCompaniesQuery(options: AdminCompaniesArgs = {}) {
  return useQuery<AdminCompaniesQueryVariables, AdminCompaniesQuery>({ query: AdminCompaniesDocument, ...options });
}

export type AdminCompaniesOptions = ExecQueryOptions<AdminCompaniesQueryVariables>;

export function adminCompanies(options: AdminCompaniesOptions) {
  return executeQuery<AdminCompaniesQuery, AdminCompaniesQueryVariables>(AdminCompaniesDocument, options);
}

export type AdminCompaniesSelections = AdminCompaniesQuery['companies'][0];

(AdminCompaniesDocument as any).additionalTypenames = ['Company'];
