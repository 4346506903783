import * as React from 'react';

import { Field, Panel, RepeatingSection } from 'app2/components';
import { EnrollmentActivityLink } from 'app2/views/shared';
import { ParentStudentLink } from 'app2/views/parent/shared';

import { useFamilyQuery } from '../shared';

import { useFamilyBilledLaterQuery } from './generated';
import { WithdrawAction } from './WithdrawAction';

export function BilledLater() {
  const {family} = useFamilyQuery(useFamilyBilledLaterQuery);

  function render() {
    return (
      <Panel icon="Clock" title="Billed later" initialValues={family}>
        <RepeatingSection name='billedLater' fields={[
          <Field label='Student' name='student.name' readOnly component={ParentStudentLink} />,
          <Field label='Activity' name='id' component={EnrollmentActivityLink} role='public' badge />,
          <Field label='Schedule' name='rosterPeriod' readOnly />,
          <Field label='Billing' name='rosterAmount' readOnly />,
          <Field component={WithdrawAction} />,
        ]} />
      </Panel>
    );
  }

  return render();
}
