import { axios } from 'app2/api';
import { ProviderCard, MarketArea, PaginateResult } from 'app2/api';
import { observable } from 'app/observable';

import { CatalogResults } from './CatalogResults';
import { Category } from './Category';

interface PaginationMeta {
  provider_count: number;
}

type Catalogs = CatalogResults<PaginationMeta>;

export type ProviderResults = PaginateResult<ProviderCard>;

@observable
export class MarketAreaStore {
  slug: string;
  catalogPageSize: number;
  catalogPageNumber: number;
  providerPageSize: number;
  providerPageNumber: number;
  marketArea: MarketArea;
  catalogs: Catalogs;
  categories: Category[];
  providers: ProviderResults;

  load(slug: string) {
    const promises = [];

    if (this.slug != slug) {
      this.slug = slug;
      this.marketArea = null;
      this.catalogs = null;
      this.categories = null;
      this.providers = null;
      this.catalogPageSize = null;
      this.catalogPageNumber = null;
      this.providerPageSize = null;
      this.providerPageNumber = null;
      promises.push(this.loadMarketArea(), this.loadCategories());
    }

    return promises;
  }

  loadCatalogPage(pageSize: number, pageNumber: number = 0) {
    if (
      this.catalogPageSize === pageSize &&
      this.catalogPageNumber === pageNumber
    ) {
      return;
    }

    this.catalogPageSize = pageSize;
    this.catalogPageNumber = pageNumber;

    return this.loadCatalogs();
  }

  loadProviderPage(pageSize: number, pageNumber: number = 0) {
    if (
      this.providerPageSize === pageSize &&
      this.providerPageNumber === pageNumber
    ) {
      return;
    }

    this.providerPageSize = pageSize;
    this.providerPageNumber = pageNumber;

    return this.loadProviders();
  }

  async loadMarketArea() {
    try {
      const response = await axios.get<MarketArea>(
        `/market_areas/${this.slug}`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(`/in/${response.data.redirect_to_slug}`);
      }

      this.marketArea = response.data;
    } catch (_) {
      window.location.replace('/not-found');
    }
  }

  async loadCatalogs() {
    try {
      const response = await axios.get<Catalogs>(
        `/market_areas/${this.slug}/catalogs?items_per_page=${
          this.catalogPageSize
        }&page=${this.catalogPageNumber + 1}`
      );
      this.catalogs = response.data;
    } catch (_) {}
  }

  async loadCategories() {
    try {
      const response = await axios.get<Array<Category>>(
        `/market_areas/${this.slug}/course_tags`
      );
      this.categories = response.data;
    } catch (_) {}
  }

  async loadProviders() {
    try {
      const response = await axios.get<ProviderResults>(
        `/market_areas/${this.slug}/providers?items_per_page=${
          this.providerPageSize
        }&page=${this.providerPageNumber + 1}`
      );
      this.providers = response.data;
    } catch (_) {}
  }
}
