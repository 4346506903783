import * as React from 'react'

import { BoxProps, Heading2, HBox, VBox, Subtitle2 } from 'app2/components'

import { TwoCols } from './TwoCols';

// module is just a section on a page with a title and a border

interface Props extends BoxProps {
  title?:React.ReactNode;
  name?:string;//alternative test name
  subtitle?:React.ReactNode;
  actions?:React.ReactNode;
}

export function Module(props:Props) {
  const {title, subtitle, actions, ...remaining} = props;

  // the relative positioning below is intentional in 
  // case the children need it.  if they need try absolute, they should use React.createPortal

  return <VBox pb='$30' mb='$30' hAlign='left' width='100%' data-test={`Module(${props.title || props.name})`} css={css} {...remaining}>
    <TwoCols left={
      <VBox pb="$20">
        {props.title && <Heading2 className='hr-module'>{props.title}</Heading2>}
        {props.subtitle && <Subtitle2 mt='$8'>{props.subtitle}</Subtitle2>}
      </VBox>
      } right={
        <HBox hItemSpace='$8' pb="$20" gap='20px 0' flexWrap='wrap'>{props.actions}</HBox>
        } />
    <div style={{position:'relative', width: '100%', height:'100%'}}>{props.children}</div>
  </VBox>
}


const css = {
  '&:not(:last-child)': {
    borderBottom: 'solid 1px',
    borderColor: 'border'
  }
}
