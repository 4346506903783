import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteClaimMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteClaimMutation = { siteClaim?: { id: string } | null | undefined };

export const SiteClaimDocument = gql`
  mutation SiteClaim($site: ID!) {
    siteClaim(site: $site) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SiteClaimOptions = ExecMutationOptions<SiteClaimMutationVariables>;

export function siteClaim(options: SiteClaimOptions) {
  return executeMutation<SiteClaimMutation, SiteClaimMutationVariables>(SiteClaimDocument, options);
}
