import * as React from 'react';
import * as CSS from 'csstype';
import { variant } from './styled-system';

import { dispatchChangeEvent, replaceSelectedText, untrustedPaste } from './dom-utils'
import { ComponentProps, componentStyles, createComponent, useLifecycle } from './utils';
import { placeholder, placeholder2, selection } from './Input';
import { HAlign, VAlign } from './Box';

export type TextAreaKind = 'normal' | 'error' | 'disabled';
interface TextAreaCustomProps {
  kind?: TextAreaKind;
  resize?: string;
  // note you need to remove the default minHeight if you want this to start at 1 line
  autoSize?: boolean;
  error?: boolean;
  hAlign?: HAlign;
  vAlign?: VAlign; //only works if there's a minHeight, height, or height forced by the parent container
}

const px = 11;
const py = 10.5;
const focused = ':focus-within:not(:disabled)';

export type TextAreaProps = ComponentProps<TextAreaCustomProps & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>>;
export const TextAreaStyles = createComponent('textarea', ['kind', 'resize'])`
  appearance: none;
  outline: none;
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: ${props => props.theme.fontSizes.input};
  font-family: ${props => props.theme.fonts.input};
  border-radius: ${props => props.theme.radii.standard};
  border: solid 1px;
  padding: ${py}px ${px}px ${py}px ${px}px;
  line-height: ${props => props.theme.lineHeights.input};  
  ${props => props.resize ? `resize: ${props.resize};` : ``}

  ${variant({
    prop: 'kind',
    variants: {
      normal: {
        color: 'input',
        [placeholder]: {
          color: 'placeholder',
          whiteSpace: 'nowrap'
        },
        [placeholder2]: {
          color: 'placeholder',
          whiteSpace: 'nowrap'
        },
        borderColor: 'border',
        [selection]: {
          bg: 'selection'
        },
        [focused]: {
          borderColor: 'primary',
        }
      },
      error: {
        color: 'error',
        [placeholder]: {
          color: 'error',
          whiteSpace: 'nowrap'
        },
        [placeholder2]: {
          color: 'error',
          whiteSpace: 'nowrap'
        },
        borderColor: 'error',
        [selection]: {
          bg: 'errorSelection'
        }
      },
      disabled: {
        borderColor: 'border',
        color: 'disabled',
        bg: 'disabledBackground'
      }
    }
  })}
  ${componentStyles}
` as React.ComponentType<TextAreaProps>;

//@ts-ignore
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props: TextAreaProps, ref:React.RefObject<HTMLTextAreaElement>) => {
  let { kind, disabled, autoSize, error, height, maxHeight, minHeight, overflow, style, onKeyDown, onChange, vAlign, hAlign, value, ...remainingProps } = props;
  const [heights, setHeights] = React.useState({text:0, container:0, prevValue: value});

  ref = ref || React.useRef<HTMLTextAreaElement>();

  const [gotRef, setGotRef] = React.useState(ref.current != null);
  React.useEffect(() => ref.current && !gotRef ? setGotRef(true) : undefined);

  useLifecycle({onMount});

  React.useEffect(() => calcTextAreaSize(true));

  function render() {
    // react complains if value is null
    value = value || '';

    if (autoSize) {
      height = (py + heights.text + py) + 'px';
      maxHeight = undefined;

      if (style) {
        delete style.height;
        delete style.maxHeight;
      }

      overflow = 'hidden';
    }
    
    const {pt, pb} = calculatePadding();

    overflow = overflow || (pt == '10.5px' ? overflow : 'hidden');

    if (overflow && style?.overflow) {
      delete style.overflow;
    }

    const ta = hAlign ? hAlign : undefined;
    disabled = disabled || kind === 'disabled';
    kind = disabled ? 'disabled' : error ? 'error' : kind;

    return <TextAreaStyles ref={ref} data-test='Input' value={value} disabled={disabled} kind={kind} height={height} minHeight={minHeight} maxHeight={maxHeight} overflow={overflow} onPaste={pasteHandler}
      onChange={changeHandler} onKeyDown={keyHandler} style={style} pt={pt} pb={pb} textAlign={ta == 'baseline' ? undefined : ta as CSS.Property.TextAlign} opacity={!ref.current ? 0 : undefined} {...remainingProps} />
  }

  function onMount() {
    calcTextAreaSize();
  }
  
  function changeHandler(event:React.ChangeEvent<HTMLTextAreaElement>) {
    calcTextAreaSize();
    onChange?.(event)
  }

  function keyHandler(event:React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key == 'Enter' && event.metaKey) {
      splitSelected();
    }

    onKeyDown?.(event);
  }

  function pasteHandler(event:React.ClipboardEvent<HTMLTextAreaElement>) {
    untrustedPaste(event, ref.current, changeHandler);
  }

  function splitSelected() {
    replaceSelectedText(ref.current, '\r\n', changeHandler);
  }

  function calcTextAreaSize(checkVal?:boolean) {
    if (checkVal && props.value == heights.prevValue || !autoSize) {
      return;
    }

    const textArea = ref.current;
    const curHeight = textArea.style.height;
    const curMinHeight = textArea.style.minHeight;
    const curPadding = textArea.style.padding;
    textArea.rows = 1;
    textArea.style.height = "1px";
    textArea.style.minHeight = "1px";
    textArea.style.padding = '0 11px 0 11px';
  
    const textHeight = textArea.scrollHeight + 2;// add 2 for the border which we aren't taking away like we are with padding

    textArea.style.minHeight = curMinHeight;

    // if not using autoResize, then padding is calculated on the height
    // which should be specified or pre-determined based on the parent
    // but if using autoSize then we want to look at min size, since size
    // is going to be based on the content unless there's a min size
    const containerMinHeight = textArea.scrollHeight + 2;

    textArea.style.height = curHeight;

    const containerHeight = textArea.scrollHeight + 2;

    textArea.style.padding = curPadding;

    setHeights({text:textHeight, container:autoSize ? containerMinHeight : containerHeight, prevValue:props.value});
  }

  // there's no way in a textarea to do vertical alignment, so we fake it
  // by adding padding to fill the space (if there's space available).
  function calculatePadding() {
    let pt:number = py;
    let pb:number = py;

    if (vAlign == 'center' && ref.current) {
      const padding = (heights.container - heights.text) / 2;
      pt = pb = Math.max(padding, 10.5);
    }
    else
    if (vAlign == 'bottom'  && ref.current) {
      const padding = heights.container - heights.text - 10.5;
      pt = Math.max(padding, 10.5);
    }

    return {pt: pt + 'px', pb: pb + 'px'}
  }
  
  return render();
})

TextArea.defaultProps = {
  kind: 'normal'
};

TextArea.displayName = 'TextArea'
