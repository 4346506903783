import { ActivityStatusDemos } from './ActivityStatusDemos';
import { BreakpointDemos } from './BreakpointDemos';
import { ButtonDemos } from './ButtonDemos';
import { ButtonStripDemos } from './ButtonStripDemos';
import { CheckboxDemos } from './CheckboxDemos';
import { CalendarDemos } from './CalendarDemos';
import { CloudinaryDemos } from './CloudinaryDemos';
import { ColorDemos } from './ColorDemos';
import { CollapsibleDemos } from './CollapsibleDemos';
import { ComboboxDemos } from './ComboboxDemos';
import { CourseDaysPickerDemos } from './CourseDaysPickerDemos';
import { CurrencyInputDemos } from './CurrencyInputDemos';
import { DataTableDemos } from './DataTableDemos';
import { DatePickerDemos } from './DatePickerDemos';
import { DropdownDemos } from './DropdownDemos';
import { ErrorDisplayDemos } from './ErrorDisplayDemos';
import { FileDemos } from './FileDemos';
import { FormDemos } from './FormDemos';
import { IconDemos } from './IconDemos';
import { InfoDemos } from './InfoDemos';
import { InputDemos } from './InputDemos';
import { LinkDemos } from './LinkDemos';
import { ListDemos } from './ListDemos';
import { MenuDemos } from './MenuDemos';
import { ModalDemos } from './ModalDemos';
import { NavigationDemo } from './NavigationDemo';
import { NumberStepperDemo } from './NumberStepperDemo';
import { PanelDemos } from './PanelDemos';
import { PercentInputDemos } from './PercentInputDemos';
import { PhoneInputDemos } from './PhoneInputDemos';
import { RadioDemos } from './RadioDemos';
import { ScalableDemos } from './ScalableDemos';
import { ScheduleDemos } from './ScheduleDemos';
import { ScrollerDemos } from './ScrollerDemos';
import { ShieldDemos } from './ShieldDemos';
import { TabDemos } from './TabDemos';
import { TextAreaDemos } from './TextAreaDemos';
import { TextDemos } from './TextDemos';
import { TimePickerDemos } from './TimePickerDemos';
import { VirtualGridDemos } from './VirtualGridDemos';
import { VirtualTableDemos } from './VirtualTableDemos';
import { WizardDemos } from './WizardDemos';

export interface DemoDefinition {
  name:string;
  demos:React.ComponentType<any>;
}

export const demos:DemoDefinition[] = [
  { name: 'CourseStatusTag', demos: ActivityStatusDemos },
  { name: 'Breakpoints', demos: BreakpointDemos },
  { name: 'Button', demos: ButtonDemos },
  { name: 'ButtonStrip', demos: ButtonStripDemos },
  { name: 'Checkbox', demos: CheckboxDemos },
  { name: 'Calendar', demos: CalendarDemos },
  { name: 'Colors', demos: ColorDemos },
  { name: 'Cloudinary', demos: CloudinaryDemos },
  { name: 'Collapsible', demos: CollapsibleDemos },
  { name: 'Combobox', demos: ComboboxDemos },
  { name: 'CourseDaysPicker', demos: CourseDaysPickerDemos },
  { name: 'CurrencyInput', demos: CurrencyInputDemos },
  { name: 'Dropdown', demos: DropdownDemos },
  { name: 'DataTable', demos: DataTableDemos },
  { name: 'DatePicker', demos: DatePickerDemos },
  { name: 'ErorrDisplay', demos: ErrorDisplayDemos },
  { name: 'File', demos: FileDemos },
  { name: 'Form', demos: FormDemos },
  { name: 'Icon', demos: IconDemos },
  { name: 'Info', demos: InfoDemos },
  { name: 'Input', demos: InputDemos },
  { name: 'Link', demos: LinkDemos },
  { name: 'List', demos: ListDemos },
  { name: 'Menu', demos: MenuDemos },
  { name: 'Modal', demos: ModalDemos },
  { name: 'Navigation', demos: NavigationDemo },
  { name: 'NumberStepper', demos: NumberStepperDemo },
  { name: 'Panel', demos: PanelDemos },
  { name: 'PercentInput', demos: PercentInputDemos },
  { name: 'PhoneInput', demos: PhoneInputDemos },
  { name: 'Radio', demos: RadioDemos },
  { name: 'Scalable', demos: ScalableDemos },
  { name: 'Schedule', demos: ScheduleDemos },
  { name: 'Scroller', demos: ScrollerDemos },
  { name: 'Shield', demos: ShieldDemos },
  { name: 'Tabs', demos: TabDemos },
  { name: 'Text', demos: TextDemos },
  { name: 'TextArea', demos: TextAreaDemos },
  { name: 'TimePicker', demos: TimePickerDemos },
  { name: 'VirtualGrid', demos: VirtualGridDemos },
  { name: 'VirtualTable', demos: VirtualTableDemos },
  { name: 'Wizard', demos: WizardDemos }
];
