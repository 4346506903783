import * as React from 'react'
import { useParams } from 'react-router-dom';

import { MenuItem, MenuItemProps, useHistory, generatePath } from 'app2/components';

export type NavigationLinkProps = MenuItemProps;

export function NavigationLink(props:NavigationLinkProps) {
  const history = useHistory();
  const params = useParams();
  const current = React.useMemo(() => history.location.pathname.indexOf(generatePath(props.to, params)) != -1, [history.location.pathname]);

  const fw = current ? 'bold' : 'normal';

  return <MenuItem color='navigationLink' fontWeight={fw} {...props}/>
}
