import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyInviteAcceptMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type CompanyInviteAcceptMutation = { companyInviteAccept?: { success?: boolean | null | undefined } | null | undefined };

export const CompanyInviteAcceptDocument = gql`
  mutation CompanyInviteAccept($token: String!) {
    companyInviteAccept(token: $token) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyInviteAcceptOptions = ExecMutationOptions<CompanyInviteAcceptMutationVariables>;

export function companyInviteAccept(options: CompanyInviteAcceptOptions) {
  return executeMutation<CompanyInviteAcceptMutation, CompanyInviteAcceptMutationVariables>(CompanyInviteAcceptDocument, options);
}
