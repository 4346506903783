import * as React from 'react'
import { useParams } from 'react-router';

import { CurrencyField, Field, Panel, Section, RepeatingSection, FormLabel } from 'app2/components';

import { AdminSiteRouteParams } from '../../adminRoutes';

import { useAdminPayoutQuery } from './generated';

export function Payout() {
  const { subid } = useParams<AdminSiteRouteParams>();
  const [result] = useAdminPayoutQuery({ variables: { id: subid } });

  // __typename is a hidden property so we need to clone the items to make its visible to forms
  const payout = React.useMemo(() => {
    return {items: result.data?.payout?.items?.map(i => ({...i, type: i.__typename.replace('Admin', '')}))}
  }, [result.data?.payout])

  function render() {
    return (
      <Panel icon="Info" title="Payout" initialValues={payout} type="display">
        <Section label="ID" name="id" readOnly />
        <Section label="Amount" name="amount" />
        <FormLabel>Note that payout item amounts are not what the school/provider is being paid, but what the parent paid/received</FormLabel>
        <RepeatingSection name='items' fields={[
          <Field name='id' label='ID' />,
          <Field name='type' label='Type' />,
          <Field name='course.name' label='Activity' />,
          <Field name='course.site.name' label='School' />,
          <Field name='total' label='Total' component={CurrencyField} />,
        ]} />
      </Panel>
    );
  }

  return render();
}
