import * as React from 'react'
import * as ReactIs from 'react-is'
import { omit } from 'lodash-es';

import { HBox } from './Box';
import { Icon, IconProps } from './icons';
import { Input, InputProps } from './Input';
import { Tag } from './Tag';
import { TextProps } from './Text';
import { Option, OptionLabelProps, OptionValue, findOption, findOptionByLabel } from './Option'

export interface OptionTextProps extends TextProps {
  // for display
  value?:OptionValue;
  option?:Option;
  
  // for edit
  options?:Option[];
  input?:InputProps;

  // for display and edit
  multiple?:boolean;

  // additional label props
  label?:TextProps;

  // additional icon props
  icon?:IconProps;
}

export function OptionText(props:OptionTextProps) {
  const {value, option:propsOption, options:propsOptions, multiple, input, label, icon, placeholder, ...remaining} = props;

  // support for when the value is just an array of strings and there's no options
  const options = propsOptions ? propsOptions : !propsOption && Array.isArray(value) ? value : [];

  const editing = !!props.input;
  let option = editing && !props.multiple
    ? findOptionByLabel(options, props.input.value, true)
    : props.option || findOption(options, props.value)
    ;

  const optionIcon = (option?.label as OptionLabelProps)?.icon;
  const hasStyles = (typeof option?.label === 'object' && !ReactIs.isElement(option?.label)) || optionIcon !== undefined;
  let formatProps = hasStyles ? omit(option.label as OptionLabelProps, 'icon') : undefined;
  let actualLabel = (hasStyles ? option?.text : option?.label) || (typeof option == 'string' ? option : (typeof value == 'string' ? value : ''));

  if (!actualLabel && placeholder) {
    actualLabel = placeholder;
    formatProps ||= {};
    formatProps.color ||= 'placeholder';
  }

  function render() {
    return editing ? renderEdit() : renderDisplay();
  }

  function renderEdit() {
    return <Input {...props.input}>{renderIcon()}</Input>
  }

  function renderDisplay() {
    return optionIcon !== undefined ? renderIconAndLabel() : renderLabel(remaining);
  }

  function renderIconAndLabel() {
    return <HBox width='100%' vAlign='center' gap='$4' {...remaining}>{renderIcon()}{renderLabel()}</HBox>
  }

  function renderLabel(additionalProps?:any) {
    return <HBox width='100%' gap='$4' vAlign="center" display='inline-flex' flexWrap='wrap' text='body' {...props.label} {...additionalProps} {...formatProps}>{isMultiple() ? renderTags() : actualLabel}</HBox>
  }

  function isMultiple() {
    return props.multiple && Array.isArray(props.value);
  }

  function renderTags() {
    if (!props.multiple || !Array.isArray(props.value)) {
      return;
    }

    return props.value.map((tag, index) => <Tag key={index} label={findOption(options, tag)?.label || tag} bold={false} nowrap />)
  }

  function renderIcon() {
    if (optionIcon === undefined) {
      return;
    }

    const element = typeof optionIcon == 'string'
      ? <Icon name={optionIcon} color={formatProps?.color} size={formatProps?.size as string} {...props.icon} />
      : ReactIs.isElement(optionIcon)
         ? React.cloneElement(optionIcon, props.icon)
         : <Icon color={formatProps?.color || remaining?.color} size={(formatProps?.size || remaining?.size) as string} {...optionIcon} {...props.icon} />

    return element;
  }

  return render();
}

OptionText.fieldProps = {
  valueProperty: 'value',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  preserveWhitespace: true
}
