import { eventManager, EventProperties } from 'app/app2/api/events';
import { uniq } from 'lodash-es';

const canUseObserver = typeof IntersectionObserver === 'function';

let anchorObserver: IntersectionObserver;

export function logWhenVisible(
  selector: string,
  event: string,
  eventData: EventProperties = {},
  threshold: number = 1.0
) {
  if (canUseObserver) {
    let observer: IntersectionObserver;

    const onIntersection = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          eventManager.log(event, eventData);
          observer.unobserve(document.querySelector(selector) as Element);
        }
      }
    };

    observer = new IntersectionObserver(onIntersection, {
      rootMargin: '0px',
      threshold
    });

    observer.observe(document.querySelector(selector) as Element);
  }
}

export function startAnchorLogging() {
  if (canUseObserver) {
    const observerConfig = { rootMargin: '0px' };

    anchorObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            const anchorId = entry.target.id;

            eventManager.log('viewAnchor', { anchorName: anchorId });

            try {
              const target = document.querySelector(`a#${anchorId}`) as Element;
              if (target) {
                anchorObserver.unobserve(target);
              }
            } catch (_) {}
          }
        }
      },
      observerConfig
    );

    const anchorList = Array.from(document.querySelectorAll('a[id]')).map(
      anchor => {
        return anchor.id;
      }
    );

    const anchors = uniq(anchorList).filter(anchor => {
      const re = /^[0-9]+$/;
      const matches = anchor.match(re);

      return matches === null;
    });

    anchors.forEach(anchor => {
      try {
        const target = document.querySelector(`#${anchor}`) as Element;
        if (target) {
          anchorObserver.observe(target);
        }
      } catch (_) {}
    });
  }
}

export function restartAnchorLogging() {
  if (canUseObserver) {
    anchorObserver.disconnect();
    startAnchorLogging();
  }
}
