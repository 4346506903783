import * as React from 'react'

import { FormModel, Panel, Section, TextAreaField } from 'app2/components'

import { useCompanyQuery } from '../shared'

import { CompanyCancellationSelections, useCompanyCancellationQuery, companyUpdate } from './generated';

export function CancellationPolicy() {
  const {company} = useCompanyQuery(useCompanyCancellationQuery);

  function render() {
    return <Panel icon='FileText' title='Cancellation policy' initialValues={company} type='toggle' onOk={onOk}>
      <Section name='courseCancellationPolicy' label='Cancellation policy' component={TextAreaField} />
    </Panel>
  }

  async function onOk(form:FormModel<CompanyCancellationSelections>) {
    const [success] = await companyUpdate({variables: {company: company.id, ...form.values}, error: form});

    return success;
  }

  return render();
}

