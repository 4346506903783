import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateBrandingMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  branding: Types.SiteBrandingInput;
}>;

export type OrganizerUpdateBrandingMutation = { siteUpdateGeneralInfo?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateBrandingDocument = gql`
  mutation OrganizerUpdateBranding($siteId: ID!, $branding: SiteBrandingInput!) {
    siteUpdateGeneralInfo(site: $siteId, branding: $branding) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateBrandingOptions = ExecMutationOptions<OrganizerUpdateBrandingMutationVariables>;

export function organizerUpdateBranding(options: OrganizerUpdateBrandingOptions) {
  return executeMutation<OrganizerUpdateBrandingMutation, OrganizerUpdateBrandingMutationVariables>(OrganizerUpdateBrandingDocument, options);
}
