import { loadThirdParty } from 'app/scriptLoading'

import React from 'react'
import { render } from 'react-dom'
import ErrorBoundary from './components/ErrorBoundary'
import { installTestLogCapturing } from 'app/helpers/environments'
import Root from './components/Root'
// this has to come last for now due to legacy dependancy issues
import { history } from './components'

installTestLogCapturing()
loadThirdParty()

if (!Object.entries) {
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

render(
  <ErrorBoundary>
    <Root history={history} />
  </ErrorBoundary>,
  document.getElementById('root')
)
