import * as React from 'react';

import { HBox, Subtitle2, formatCurrency } from 'app2/components';

interface Props {
  label: string;
  amount: number | React.ReactNode;
}

export function FeeTotal(props: Props) {
  return (
    <HBox alignItems="center" justifyContent="space-between" gap='$8'>
      <Subtitle2>{props.label}</Subtitle2>
      <Subtitle2 textAlign='right'>{typeof props.amount == 'number' ? formatCurrency(props.amount) : props.amount}</Subtitle2>
    </HBox>
  );
}
