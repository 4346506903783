import * as React from 'react';

import { Box, BoxProps } from './Box';

// RatioBox ensures the ratio of dimensions of a box
// stay the same as its resized.

export interface RatioBoxProps extends BoxProps {
  // expressed as percentage such as '85%'
  ratio: string;
  content?: BoxProps;
}

export function RatioBox(props: RatioBoxProps) {
  const { ratio, children, content = {}, ...remaining } = props;

  if (!content.children) {
    content.children = children;
  }

  // these needs three boxes to work
  //  - first box is sized to whatever width is passed in or created by the parent container
  //  - second box is sized to 100% of that, and uses paddingTop to allocate the needed ratio height.
  //    this box could be eliminated if we new the width of the element, but that can't be guaranteed.
  //  - third box uses absolute positioning to put the children over the padding
  return (
    <Box minHeight="fit-content" {...remaining}>
      <Box width="100%" paddingTop={ratio} position="relative">
        <Box
          position="absolute"
          left="0"
          top="0"
          right="0"
          bottom="0"
          {...content}
        />
      </Box>
    </Box>
  );
}
