import * as React from 'react';

import { DataTable, TabsHandle, useSafeState, useLoader, usePreference } from 'app2/components';
import { CoursesTableWithTabsPreferences } from 'app2/api';

import { TableCoursesSelections, TableCoursesQuery } from './generated'

interface CoursesTableState {
  prefs:CoursesTableWithTabsPreferences;
  editing:boolean;
  setEditing:(editing:boolean) => void;
  courses:TableCoursesQuery['seasonCourses']['items'];
  coursesCollection:DataTable<TableCoursesSelections>['data'];
  setCourses:(courses:DataTable<TableCoursesSelections>['data']) => void;
  coursesQueryResult:Partial<TableCoursesQuery['seasonCourses']>;
  setCoursesQueryResult:(result:Partial<TableCoursesQuery['seasonCourses']>) => void;
  loader:ReturnType<typeof useLoader>;
  loading:boolean;
  empty:boolean;
  tab:number;
  setTab:(tab:number) => void;
  showAdvanced:boolean;
  setShowAdvanced:(showAdvanced:boolean) => void;
  tabs:React.MutableRefObject<TabsHandle>;
  table:React.MutableRefObject<DataTable<TableCoursesSelections>>;
}

export function useCourseTableState(prefs?:CoursesTableWithTabsPreferences):CoursesTableState {
  const [editing, setEditing] = React.useState(false);
  const [coursesQueryResult, setCoursesQueryResult] = useSafeState<Partial<TableCoursesQuery['seasonCourses']>>();
  const courses = coursesQueryResult?.items;
  const [coursesCollection, setCourses] = React.useState<DataTable<TableCoursesSelections>['data']>();

  const loader = useLoader();
  const noCourses = coursesQueryResult?.totals?.every?.(total => total === 0);
  const empty = !editing && !loader.showingLoader && noCourses;
  // don't look at loader.showingLoader to indicate loading because
  // it will trigger when anything on the page is loading or reloading, not just courses
  const loading = !editing && !coursesQueryResult;

  const [tab, setTab] = prefs ? usePreference(prefs, 'tab', 0) : React.useState(0);

  const [showAdvanced, setShowAdvanced] = prefs ? usePreference(prefs, 'showAdvanced') : [false, (val:boolean) => {}];

  const tabs = React.useRef<TabsHandle>();
  const table = React.useRef<DataTable<TableCoursesSelections>>();

  const state = React.useRef<CoursesTableState>({} as CoursesTableState);
  Object.assign(state.current, { prefs, editing, setEditing, courses, coursesCollection, setCourses, coursesQueryResult, setCoursesQueryResult, loader, loading, empty, tab, setTab, showAdvanced, setShowAdvanced, tabs, table });

  return state.current;
}

export type TableState = ReturnType<typeof useCourseTableState>;
