import * as React from 'react';
import { useParams } from 'react-router-dom';

import { PublicPage, SwooshHeader } from 'app2/views/shared-public';
import { HBox } from 'app2/components';

import { PublicSiteParams } from '../../publicRoutes';

import { usePublicSiteQuery } from '../registration/generated';

interface Props {
  children: React.ReactNode;
}

export function PublicAttendancePage(props: Props) {
  const { site: siteId } = useParams<PublicSiteParams>();
  const [siteQueryResult] = usePublicSiteQuery({ variables: { id: siteId } });
  const site = siteQueryResult?.data?.site;

  function render() {
    return (
      <PublicPage useMaxWidth markingLinksWhenLoggedIn={false} header={false} footer={false} hAlign='center'>
        <SwooshHeader title={site?.name} />
        {renderChildren()}
      </PublicPage>
    );
  }

  function renderChildren() {
    if (!site) {
      return <HBox minHeight="200px" />
    }

    return <HBox maxWidth={['100%', '500px']} width={['100%', '100%']} vAlign="center" mt="$60">
      {props.children}
    </HBox>
  }

  return render();
}
