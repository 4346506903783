import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertComplianceDefinitionsMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  complianceDefinitions: Types.ComplianceDefinitionsInput;
}>;

export type OrganizerUpsertComplianceDefinitionsMutation = { siteUpsertComplianceDefinitions?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpsertComplianceDefinitionsDocument = gql`
  mutation OrganizerUpsertComplianceDefinitions($siteId: ID!, $complianceDefinitions: ComplianceDefinitionsInput!) {
    siteUpsertComplianceDefinitions(site: $siteId, complianceDefinitions: $complianceDefinitions) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertComplianceDefinitionsOptions = ExecMutationOptions<OrganizerUpsertComplianceDefinitionsMutationVariables>;

export function organizerUpsertComplianceDefinitions(options: OrganizerUpsertComplianceDefinitionsOptions) {
  return executeMutation<OrganizerUpsertComplianceDefinitionsMutation, OrganizerUpsertComplianceDefinitionsMutationVariables>(OrganizerUpsertComplianceDefinitionsDocument, options);
}
