import * as React from 'react';

import { BulkRefundBreakdown } from 'app2/api';
import { VBox, BoxProps, Body, formatCurrency, Icon, HBox } from 'app2/components';

import { FeeItem, FeeTotal } from '../currency';

export type RefundBreakdownType = Pick<BulkRefundBreakdown, 'familiesReceive' | 'otherFeeReversals' | 'providerWithdrawAmount' | 'siteWithdrawAmount' | 'nonrefundableSiteFee'>;

interface Props extends RefundBreakdownType {
  boxProps?: BoxProps;
}

export function RefundBreakdown(props: Props) {
  const familiesReceive = props.familiesReceive;

  function render() {
    const showFeeWarning = props.nonrefundableSiteFee > 0;

    return (
      <VBox borderRadius="standard" bg="backgroundMediumGrey" p="$16" vItemSpace="$16" {...props.boxProps}>
        <FeeItem label="Total provider earnings reversed" amount={props.providerWithdrawAmount} />
        <FeeItem label="Total school earnings reversed" amount={props.siteWithdrawAmount} />
        <FeeItem label="Total fees reversed" amount={props.otherFeeReversals} />
        <FeeTotal label="Families receive in total" amount={familiesReceive} />
        {showFeeWarning && (
          <HBox>
            <Icon name="Info" size={18} mr="$4" />
            <Body fontWeight="bold" mb="$20">
              There&apos;s a {formatCurrency(props.nonrefundableSiteFee)} nonrefundable school fee that isn&apos;t included in the totals above.
            </Body>
          </HBox>
        )}
      </VBox>
    );
  }

  return render();
}

RefundBreakdown.defaultProps = {
  boxProps: {}
};
