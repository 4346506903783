import * as React from 'react';

import { VirtualGrid, CellProps } from 'app/app2/components';

import { Demo } from './Demo';

const factory = {
  numRows: 500,
  rowHeight: () => 40,
  numCols: 50,
  colWidth: () => 100,
  render: (props: CellProps) => {
    return (
      <span
        key={`row:${props.row},col:${props.col}`}
        style={{
          background: '#fff',
          border: 'solid 1px',
          marginRight: '-1px',
          marginBottom: '-1px',
          ...props.style
        }}
      >{`row:${props.row}, col: ${props.col}`}</span>
    );
  }
};

export function VirtualGridDemos() {
  return <Demo name='Basic'>
    <div style={{ overflow: 'scroll', width: '600px', height: '400px' }}>
      <VirtualGrid factory={factory} />
    </div>
  </Demo>
}
