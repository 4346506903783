import * as React from 'react'

import { HBox, BoxProps } from 'app2/components'

interface Props extends Omit<BoxProps, 'left' | 'right'> {
  left?:React.ReactNode;
  right?:React.ReactNode;
}

export function TwoCols(props:Props) {
  const {left, right, ...remaining} = props;

  if (!left && !right) {
    return <></>;
  }

  return <HBox width="100%" vAlign="center" flexWrap='wrap' {...remaining}>
    {left}
    <HBox flex={1} />
    {right}
  </HBox>
}
