export type OnIdle = () => void;

export class IdleDetector {
  static SECOND = 1000;
  static MINUTE = 60 * IdleDetector.SECOND;
  static HOUR = 60 * IdleDetector.MINUTE;
  static EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
  ];

  onIdle: OnIdle;
  element: EventTarget;
  idleDuration: number;

  lastActivity: number;
  idleTimeout: any;

  constructor(onIdle: OnIdle, element: EventTarget = window, idleDuration: number = IdleDetector.HOUR) {
    if (onIdle) {
      this.start(onIdle, element, idleDuration);
    }
  }

  start(onIdle: OnIdle, element: EventTarget, idleDuration: number) {
    this.stop();

    this.onIdle = onIdle;
    this.element = element;
    this.idleDuration = idleDuration;

    IdleDetector.EVENTS.forEach(e => this.element.addEventListener(e, this.handleEvent, { capture: true, passive: true }));

    this.lastActivity = Date.now();
    this.idleTimeout = setTimeout(this.onTimeout, this.idleDuration);
  }

  stop() {
    if (this.idleTimeout === undefined) {
      return;
    }

    clearTimeout(this.idleTimeout);
    IdleDetector.EVENTS.forEach(e => this.element.removeEventListener(e, this.handleEvent, { capture: true }));

    this.onIdle = undefined;
    this.element = undefined;
    this.idleDuration = undefined;

    this.idleTimeout = undefined;
    this.lastActivity = undefined;
  }

  handleEvent = (e: Event) => {
    const now = Date.now();

    // this is to avoid generating a lot of new timeouts because
    // there can be a lot of events that come in quickly
    if (now - this.lastActivity < IdleDetector.SECOND) {
      return;
    }

    this.lastActivity = now;

    clearTimeout(this.idleTimeout);
    this.idleTimeout = setTimeout(this.onTimeout, this.idleDuration);
  };

  onTimeout = () => {
    this.lastActivity = Date.now();
    this.idleTimeout = setTimeout(this.onTimeout, this.idleDuration);

    this.onIdle();
  };
}
