import * as React from 'react';

import { BoxProps, colId, ColMenu, DataTable, DataTableColumn, getColText, HBox, Icon, Repeater } from 'app2/components';

export interface GroupHeaderProps extends BoxProps {
  table:DataTable;
  cols:DataTableColumn[];
  groupCount:number;
}

export function GroupByHeader(props:GroupHeaderProps) {
  const {table, cols, groupCount, ...remaining} = props;
  const groupedTableCols = getCols();
  const values = getValues();

  function render() {
    return <HBox position='sticky' bg='primary' color='#fff' text='subtitle2' p='$12' gap='$8' vAlign='center' {...remaining}>
      <Icon color='#fff' name='Folder' size='small' />
      {groupedTableCols.map(renderLabel)}
    </HBox>
  }

  function renderLabel(c:DataTableColumn, index:number) {
    const value = values[index];

    const allValuesFiltered = groupCount == 1 && c.filter;

    if (!value && !allValuesFiltered) {
      return null;
    }

    return <HBox key={colId(c)} mr='$4' gap='$4' vAlign='center'>{value || 'none'}<ColMenu table={table} col={c} color='primaryInverse' size='small' name='DropdownOpen' hide={false} /></HBox>
  }

  function getCols() {
    if (!table) {
      return []
    }

    // the tables clone their cols and we need to pass the column menu 
    // those instance of the cols because they are mutated by the col menu
    // which a callback might be relying on (even though the col menu callbacks
    // provide all the changes)
    return cols.map(col => table.allCols.find(c => colId(c)== colId(col)));
  }

  function getValues() {
    return groupedTableCols.map(col => getColText(col, table.data.getItem(0)))
  }

  return render();
}

export const groupByHeaderHeight = 46;