export function marketingUrl(page: string) {
  if (!page) {
    return;
  }

  if (page.startsWith('http')) {
    return page;
  }

  if (page.startsWith('/')) {
    page = page.substring(1);
  }

  return `${document.location.origin}/about/${page}`;
}
