import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUpdateEnrollmentsMutationVariables = Types.Exact<{
  enrollments: Array<Types.ConfirmedEnrollmentInput> | Types.ConfirmedEnrollmentInput;
}>;

export type ParentUpdateEnrollmentsMutation = { enrollmentsUpdate?: { success?: boolean | null | undefined } | null | undefined };

export const ParentUpdateEnrollmentsDocument = gql`
  mutation ParentUpdateEnrollments($enrollments: [ConfirmedEnrollmentInput!]!) @urql(additionalTypenames: ["Enrollment", "Cart", "Student"]) {
    enrollmentsUpdate(enrollments: $enrollments) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentUpdateEnrollmentsOptions = ExecMutationOptions<ParentUpdateEnrollmentsMutationVariables>;

export function parentUpdateEnrollments(options: ParentUpdateEnrollmentsOptions) {
  return executeMutation<ParentUpdateEnrollmentsMutation, ParentUpdateEnrollmentsMutationVariables>(ParentUpdateEnrollmentsDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Enrollment', 'Cart', 'Student']) }
  });
}
