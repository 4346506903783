import * as React from 'react'
import { commaListsAnd } from 'common-tags';

import { Panel, RepeatingSection, Field, FormModel, EmailField, InputField, Modal, SaveableResult, PhoneField } from 'app2/components'
import { ArrayDiff, diffArrays, removeBlanks } from 'app2/views/shared'
import { NonePanel } from 'app2/views/shared-public'
import HighFive from 'images/HighFiveSquare.svg'

import { CompanyPage, useCompanyQuery } from '../shared'

import { CompanyTeachersSelections, providerUpsertTeachers, useCompanyTeachersQuery } from './generated'

type Teacher = CompanyTeachersSelections['teachers'][0] & {archived?:boolean};

export function Teachers() {
  const {company, id} = useCompanyQuery(useCompanyTeachersQuery);

  function render() {
    return <CompanyPage title='Instructors'>
      <Panel icon='Users' title='Instructors' initialValues={company} type='toggle' onOk={onOk}>
        <RepeatingSection name='teachers' add='Add an instructor' none={renderNone()} width='100%' alwaysOne onRemove='archived' fields={[
          <Field name='name' label='Name' component={InputField} />,
          <Field name='email' label='Email' component={EmailField} />,
          <Field name='phone' label='Phone' component={PhoneField} />,
          'remove'
        ]} />
      </Panel>
    </CompanyPage>
  }

  function renderNone() {
    return <NonePanel description='Adding instructors now makes it easy to choose from your team while scheduling activities later.' 
      image={HighFive} add='Add an instructor' property='teachers' />
  }

  async function onOk(form:FormModel<{teachers:Teacher[]}>) {
    const withoutDeleted = form.values.teachers.filter(t => !t.archived);
    const diff = diffArrays(company.teachers, withoutDeleted, ['name']);

    if (!(await removalWarning(diff)) || !(await renameWarning(diff))) {
      return false
    }

    const teachers = removeBlanks(form.values.teachers).map(c => ({...c, archived: c.archived || false}));
    const [success] = await providerUpsertTeachers({variables: {company: id, teachers}, error: form});

    return success;
  }

  async function removalWarning(diff:ArrayDiff<Teacher>) {
    if (!diff.removed.length) {
      return true;
    }

    const removed = diff.removed.map(t => t.name);
    const result = await Modal.warning('Instructors removed', `Are you sure you want to delete ${commaListsAnd`${removed}`}.`, true);

    return result.action == SaveableResult.ok;
  }

  async function renameWarning(diff:ArrayDiff<Teacher>) {
    if (!diff.updated.length) {
      return true;
    }

    const result = await Modal.warning('Instructors renamed', 'Renaming an instructor will rename the instructor in all activities they are assigned to.', true);

    return result.action == SaveableResult.ok;
  }

  return render();
}