import * as React from 'react';

import { CurrencyInput } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function CurrencyInputDemos() {
  const [value, setValue] = React.useState(10);

  return <Demos name='CurrencyInput'>
    <Demo name='Uncontrolled'>
      <CurrencyInput width='300px' />
    </Demo>

    <Demo name='Controlled'>
      <CurrencyInput width='300px' value={value} onChange={event => setValue(event.target.value as unknown as number)} />
      Value: {value}
    </Demo>
  </Demos>
}
