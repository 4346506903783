import * as React from 'react';

import { Site } from 'app2/api';
import { Option } from 'app2/components';

export interface UseClassOptionsSite extends Site {
  id: string;
}

export function useClassOptions(sites: UseClassOptionsSite[]) {
  return React.useMemo(() => {
    const siteOptions = {} as {[siteId:string]:{beforeClass:Option[], afterClass:Option[]}};

    sites?.forEach(site => {
      siteOptions[site.id] = {
        beforeClass: site.pickupDestinations?.map(d => ({label: d, value:d})),
        afterClass: site.checkoutDestinations?.map(d => ({label: d, value:d}))
      }
    })

    const hasBeforeClass = sites?.find(s => s.pickupDestinations?.length) != null;
    const allHaveBeforeClass = hasBeforeClass && sites?.filter(s => s.pickupDestinations?.length).length == sites?.length;

    return {allHaveBeforeClass, hasBeforeClass, siteOptions};
  }, [sites]);
}
