import * as React from 'react'

import { VBox } from 'app2/components'

import { CompanyInfo } from './CompanyInfo'
import { Images } from './Images'
import { CancellationPolicy } from './CancellationPolicy'

export function GeneralInfo() {
  return <VBox>
    <CompanyInfo />
    <Images />
    <CancellationPolicy />
  </VBox>
}