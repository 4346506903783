import * as React from 'react'
import { isElement } from 'react-is';

import { VirtualGridFactory, CellProps } from "../virtualized";
import { Field, FieldRendererProps, computePlaceholder, isNone, FieldValue, useDefaultNoneMsg } from '../form';
import { logger, MultiContextProvider } from '../utils'

import { DataTable } from "./DataTable";
import { getFieldPropsFromCol, colId } from './column/DataTableColumn';
import { CellRenderer } from './renderers';

export class CellFactory implements VirtualGridFactory {
  _table:DataTable;

  constructor(table:DataTable) {
    this._table = table;
  }

  get numRows():number {
    return this._table.colHeaderCount + this._table.numRows;
  }

  rowHeight(row:number):number {
    return this._table.rowHeight(row);
  }

  get numCols():number {
    return this._table.rowHeaderCount + this._table.numCols;
  }

  colWidth(col:number):number {
    return this._table.colWidth(col);
  }

  render(props:CellProps):React.ReactElement {
    const table = this._table;
    const row = props.row;
    const col = props.col;
    const colHeaders = table.props.colHeaders;
    const rowHeaders = table.props.rowHeaders;
    let cell:React.ReactElement;

    try {
      if (row > table.numRows || col > table.numCols) {
        return <></>
      }

      if (row == 0 && colHeaders && col == 0 && rowHeaders) {
        const Renderer = table.props.topLeftHeader;
        cell = <Renderer key='tl' table={table} style={props.style} />;
      }
      else 
      if (row == 0 && colHeaders) {
        const colPos = col - (rowHeaders ? 1 : 0);
        const dataCol = table.cols[colPos];
        const Renderer = table.props.colHeader;
        cell = <Renderer key={`ch:${colId(dataCol)}`} table={table} col={dataCol} colPos={colPos} style={props.style} {...dataCol.header} />;
      }
      else
      if (col == 0 &&  rowHeaders) {
        const Renderer = table.props.rowHeader;
        const rowPos = row - (colHeaders ? 1 : 0);
        const rowId = table.data.getId(rowPos);
        cell = <Renderer key={`rh:${rowId}`} table={table} row={rowPos} style={props.style} />;
      }
      else {
        const Renderer = table.props.cellRenderer || CellRenderer;
        const rowPos = row - (colHeaders ? 1 : 0);
        const rowId = table.data.getId(rowPos);
        const colPos = col - (rowHeaders ? 1 : 0);
        const dataCol = table.cols[colPos];
        const fieldProps = getFieldPropsFromCol(dataCol);

        const render = (renderProps:FieldRendererProps) => {
          if (!renderProps.info) {
            logger.error('bad col', dataCol.name, dataCol);
          }

          const focus = this.isSingleCellSelected(rowPos, colPos);
          const rendererProps = {col:dataCol, colPos, rowPos, table, errors: renderProps.info?.errors, readOnly: renderProps.info?.readOnly, 
            disabled: renderProps.info?.disabled, measuring: props.measuring, focus, className:(props.measuring ? ' hr-measuring' : ''), 
            style:props.style, children:renderProps.children};

          // for columns that don't define placeholder on both display and edit (and just add to edit)
          // we have to calculate it for it (because normally a field shows "none" instead)
          const existingPlaceholder = (renderProps.children as React.ReactElement)?.props?.placeholder;
          const placeholderSetting = dataCol.edit?.placeholder ?? dataCol.placeholder ?? dataCol.component?.placeholder ?? dataCol.component?.edit?.placeholder;

          if (table.editable && isNone(renderProps.info.value) && !renderProps.disallowNone && !renderProps.info.disabled && !renderProps.info.readOnly && (existingPlaceholder || placeholderSetting !== false)) {
            const required = typeof renderProps.field.required == 'function' ? renderProps.field.required(renderProps.info) : renderProps.field.required;
            const placeholder = existingPlaceholder || computePlaceholder(dataCol.label, placeholderSetting || true, required);
            rendererProps.children = <FieldValue error={Boolean(renderProps.info.errors?.length)} disabled={true} whiteSpace='nowrap' overflow='hidden'>{placeholder}</FieldValue>
          }

          // attempt to get the original render, but since we don't call
          // mergeComponentProps on those props this isn't fool proof
          const mode = renderProps.editing ? 'edit' : 'display';
          const originalRender = fieldProps[mode]?.render || fieldProps.component?.render || fieldProps.render;

          if (originalRender) {
            rendererProps.children = originalRender(renderProps);
          }
          
          return isElement(Renderer) 
            ? React.cloneElement(Renderer, rendererProps)
            : <Renderer {...rendererProps} />
        }

        if (table.editable) {
          fieldProps.none = false
        }
        else
        if (useDefaultNoneMsg(fieldProps.none ?? fieldProps.component?.none ?? fieldProps.component?.fieldProps?.none)) {
          fieldProps.none = '-';
        }
      
        cell = <MultiContextProvider formInfo={{editing: table.props.keepFormEditingFalse ? false : table.editable, form: table.form, parents:[rowPos], cellEditor: false}}>
          <Field {...fieldProps} key={`${rowId}:${colId(dataCol)}`} render={render} editing={false} />
        </MultiContextProvider>      
      }
    }
    catch(e) {
      // not sure why, but occasionally we get asked render cells that dont seem
      // to exist in the rows/cols and the above rows/cols check doesn't catch it
      // its probably an async issue that will get worked out, so just swallow the error
      // https://joinhomeroom.slack.com/archives/CGEPTUTU7/p1691629782953249
      cell = <></>
    }

    return cell;
  }

  // for showing the focused cell as its true size
  // row/col are in data coordinates
  isSingleCellSelected(row:number, col:number) {
    const selection = this._table.selection;
    return selection && selection.singleCellSelected && selection.focus.row == row && selection.focus.col == col;
  }
}
