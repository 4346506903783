import * as React from 'react'

import { FieldModel } from './FieldModel';
import { useFormInfo } from './useFormInfo';
import { useFormSubscription } from './useFormSubscription';
import { FieldInfo } from './FormModel';

// a hook that will define a field in a form and
// register a form change subscription that will
// cause a rerender when the field changes value
//
// this does not replace Field, it will not
// do Field's magic of combining properties of
// compenent, display and edit.  further, it
// will not render a field.  this just registers
// a field.

export function useField<T = any>(field:FieldModel<T>, subscribeToChildren = false):FieldInfo<any, keyof T> {
  if (!field || !field.name) {
    return {} as FieldInfo<any>;
  }

  const info = useFormInfo();
  let [fieldInfo, setFieldInfo] = React.useState({form: null, parents: null, field: null, added: false});

  if (!info) {
    return {} as FieldInfo<any>;
  }

  if (info.form != fieldInfo.form) {
    if (fieldInfo.added) {
      // its important to modify the exact instance of the state because
      // this instance is stored in the use effect closure and not the most recent state
      fieldInfo.added = false;
      fieldInfo.form.removeField(fieldInfo.parents, fieldInfo.field);
      fieldInfo = {form: null, parents: null, field: null, added: false};
    }

    if (info.form) {
      info.form.addField?.(info.parents, field);
      fieldInfo = {form: info.form, parents: info.parents, field, added: true};
    }

    setFieldInfo(fieldInfo);
  }

  useFormSubscription({field: fieldInfo.parents.concat(field.name as string).join('.'), subscribeToChildren});

  React.useEffect(() => {
    return () => {
      if (fieldInfo.added) {
        fieldInfo.form.removeField?.(fieldInfo.parents, fieldInfo.field);
      }
    }
  }, [fieldInfo]);

  return info.form.getInfo(info.parents, field.name);
}
