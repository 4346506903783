import * as React from 'react';

import { VBox } from 'app2/components';

import { CourseSelections } from '../generated';

import { Enrolled, Waitlisted, Removed } from '.';

interface Props {
  course: CourseSelections;
}

export function Enrollments(props: Props) {
  const { course } = props;

  return (
    <VBox>
      <Enrolled course={course} />
      <Waitlisted course={course} />
      <Removed course={course} />
    </VBox>
  );
}
