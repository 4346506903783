import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerZipcodeQueryVariables = Types.Exact<{
  zipcode: Types.Scalars['String'];
}>;

export type OrganizerZipcodeQuery = { zipCodeSearch?: { city?: string | null | undefined; state?: string | null | undefined; timezone?: string | null | undefined } | null | undefined };

export const OrganizerZipcodeDocument = gql`
  query OrganizerZipcode($zipcode: String!) {
    zipCodeSearch(zipcode: $zipcode) {
      city
      state
      timezone
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerZipcodeArgs = MakeOptional<UseQueryArgs<OrganizerZipcodeQueryVariables, OrganizerZipcodeQuery>, 'query'>;

export function useOrganizerZipcodeQuery(options: OrganizerZipcodeArgs = {}) {
  return useQuery<OrganizerZipcodeQueryVariables, OrganizerZipcodeQuery>({ query: OrganizerZipcodeDocument, ...options });
}

export type OrganizerZipcodeOptions = ExecQueryOptions<OrganizerZipcodeQueryVariables>;

export function organizerZipcode(options: OrganizerZipcodeOptions) {
  return executeQuery<OrganizerZipcodeQuery, OrganizerZipcodeQueryVariables>(OrganizerZipcodeDocument, options);
}

export type OrganizerZipcodeSelections = OrganizerZipcodeQuery['zipCodeSearch'];
