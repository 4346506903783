import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserCompaniesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserCompaniesQuery = { adminUser?: { companies?: Array<{ id: string; name?: string | null | undefined }> | null | undefined } | null | undefined };

export const AdminUserCompaniesDocument = gql`
  query AdminUserCompanies($id: ID!) {
    adminUser(user: $id) {
      companies {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserCompaniesArgs = MakeOptional<UseQueryArgs<AdminUserCompaniesQueryVariables, AdminUserCompaniesQuery>, 'query'>;

export function useAdminUserCompaniesQuery(options: AdminUserCompaniesArgs = {}) {
  return useQuery<AdminUserCompaniesQueryVariables, AdminUserCompaniesQuery>({ query: AdminUserCompaniesDocument, ...options });
}

export type AdminUserCompaniesOptions = ExecQueryOptions<AdminUserCompaniesQueryVariables>;

export function adminUserCompanies(options: AdminUserCompaniesOptions) {
  return executeQuery<AdminUserCompaniesQuery, AdminUserCompaniesQueryVariables>(AdminUserCompaniesDocument, options);
}

export type AdminUserCompaniesSelections = AdminUserCompaniesQuery['adminUser'];
