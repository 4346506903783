import * as React from 'react';

import { CourseStatus } from 'app2/api';
import { Dropdown, Panel, Part, Section } from 'app2/components';
import { CourseStatusTag } from 'app2/views/shared-public';

import { Demos, Demo } from './Demo';

export function ActivityStatusDemos() {
  const statuses:CourseStatus[] = [
    CourseStatus.Active,
    CourseStatus.AwaitingApproval,
    CourseStatus.Cancelled,
    CourseStatus.Completed,
    CourseStatus.Deleted,
    CourseStatus.Enrolling,
    CourseStatus.Draft,
    CourseStatus.Request,
    CourseStatus.Upcoming
  ];

  return <Demos name='CourseStatusTag'>
    <Demo name='Small'>
      {statuses.map((status, index) => <CourseStatusTag key={index} status={status} small />)}
    </Demo>

    <Demo name='Large'>
      {statuses.map((status, index) => <CourseStatusTag key={index} status={status} />)}
    </Demo>

    <Demo name='Form'>
      <Panel type='edit'>
        <Section label='Choose a status' name='status' component={Dropdown} options={statuses} />
        <Part label='Status' name='status' component={CourseStatusTag} small />
        <Part label='Status' name='status' component={CourseStatusTag} />
      </Panel>
    </Demo>
  </Demos>
}
