import * as React from 'react'

import { Tab as TabBase, TabProps } from 'app2/components';

import { HideNavigation, useNavigationRouteInfo } from './useNavigationRouteInfo';

interface Props extends TabProps {
  hide?:HideNavigation;
}

export function Tab(props:Props) {
  let {hide, ...remaining} = props;

  const route = useNavigationRouteInfo(hide, props.to);

  if (!route?.allowed) {
    return <></>;
  }

  return <TabBase css={route.css} {...remaining}/>
}
