export function findQuoteLocation(content:string) {
  if (!content) {
    return -1;
  }

  for (let pos = 0; pos < ALL_REGEXES.length; ++pos) {
    const re = ALL_REGEXES[pos]
    const results = content.match(re);

    if (results) {
      return results.index;
    }
  }

  return -1;
}

// taken from https://github.com/t2bot/node-email-reply-parser
// but its doing a lot we don't need and we just want the
// location, it creates several sections of the email

const SIGNATURE_REGEX = /(?:^\s*--|^\s*__|^-\w|^-- $)|(?:^Sent from my (?:\s*\w+){1,4}$)|(?:^={30,}$)$/;

// look for two consecutive lines starting with ">"
const QUOTE_REGEX = /^\s*>.*(\r|\n|\r\n)(^\s*>.*$)/m;

const QUOTE_HEADERS_REGEX = [
    /^\s*(On(?:(?!.*On\b|\bwrote:)[\s\S])+wrote:)$/m, // On DATE, NAME <EMAIL> wrote:
    /^\s*(Le(?:(?!.*Le\b|\bécrit:)[\s\S])+écrit(\s|\xc2\xa0):)$/m, // On DATE, NAME <EMAIL> wrote:
    /^\s*(El(?:(?!.*El\b|\bescribió:)[\s\S])+escribi(ó|eron):)$/m, // On DATE, NAME <EMAIL> wrote:
    /^\s*(Il(?:(?!.*Il\b|\bscritto:)[\s\S])+scritto:)$/m, // On DATE, NAME <EMAIL> wrote:
    /^\s*(From\s?:.+\s?(\[|<).+(\]|>))/mu, // "From: NAME <EMAIL>" OR "From : NAME <EMAIL>" OR "From : NAME<EMAIL>"(With support whitespace before start and before <)
    /^(20[0-9]{2}-(?:0?[1-9]|1[012])-(?:0?[0-9]|[1-2][0-9]|3[01]|[1-9])\s[0-2]?[0-9]:\d{2}\s[\S\s]+?:)$/m, // 20YY-MM-DD HH:II GMT+01:00 NAME <EMAIL>:
    /^\s*([a-z]{3,4}\.[\s\S]+\sskrev[\s\S]+:)$/m, // DATE skrev NAME <EMAIL>:
];

const ALL_REGEXES = [...QUOTE_HEADERS_REGEX, SIGNATURE_REGEX, QUOTE_REGEX]