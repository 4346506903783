import * as React from 'react'

import { formatAge, parseAge } from 'app2/api';
import { InputProps, RestrictedInput } from 'app2/components'

export const AgeInput = React.forwardRef((props:InputProps, ref:any) => {
  return <RestrictedInput inputMode='numeric' parse={(age:string) => parseAge(age)} format={(age:number) => formatAge(age)} {...props} />
})

AgeInput.displayName = 'AgeInput';
