import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyPaymentMethodsQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type FamilyPaymentMethodsQuery = {
  family?:
    | {
        creditCard?: { id: string; brand: Types.CardBrand; expiration: string; last4: string; name?: string | null | undefined } | null | undefined;
        usBankAccount?: { fingerprint: string; last4: string; bankName: string; routingNumber: string } | null | undefined;
      }
    | null
    | undefined;
};

export const FamilyPaymentMethodsDocument = gql`
  query FamilyPaymentMethods($family: ID!) {
    family(family: $family) {
      creditCard {
        id
        brand
        expiration
        last4
        name
      }
      usBankAccount {
        fingerprint
        last4
        bankName
        routingNumber
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyPaymentMethodsArgs = MakeOptional<UseQueryArgs<FamilyPaymentMethodsQueryVariables, FamilyPaymentMethodsQuery>, 'query'>;

export function useFamilyPaymentMethodsQuery(options: FamilyPaymentMethodsArgs = {}) {
  return useQuery<FamilyPaymentMethodsQueryVariables, FamilyPaymentMethodsQuery>({ query: FamilyPaymentMethodsDocument, ...options });
}

export type FamilyPaymentMethodsOptions = ExecQueryOptions<FamilyPaymentMethodsQueryVariables>;

export function familyPaymentMethods(options: FamilyPaymentMethodsOptions) {
  return executeQuery<FamilyPaymentMethodsQuery, FamilyPaymentMethodsQueryVariables>(FamilyPaymentMethodsDocument, options);
}

export type FamilyPaymentMethodsSelections = FamilyPaymentMethodsQuery['family'];
