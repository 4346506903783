import * as React from 'react';

import { formatCurrency, formatDate, DataTableColumn, Link, FieldInfo } from 'app2/components';
import { GenericReportTable } from 'app2/views/shared';

import { useCompanyParameter } from '../shared';

import { useCompanyCourseSummaryReportQuery, CompanyCourseSummaryReportSelections } from './generated';

export function CompanyCourseSummaryReport() {
  const company = useCompanyParameter();
  const cols = React.useMemo(getCols, [company]);

  function render() {
    return <GenericReportTable title='Activities' query={useCompanyCourseSummaryReportQuery} resultType='companyCourseSummaryReport' 
      totals={totals} cols={cols} colsVersion='3' colsPreferenceName='reportsCoursesOverview' csvUrl={`/reports/vendor_courses_csv?company_id=${company}`} queryOptions={{ variables:{ company }}} />
  }

  function getCols() {
    const cols:Array<DataTableColumn<CourseSummaryColumn>> = [
      {
        name: 'course',
        label: 'Activity name',
        width: 200,
        format: (name:string, _:any, info:FieldInfo<CourseSummaryColumn>) => {
          return <Link to={`/provider/${company}/reports/transactions/${info.record.id}`}>{name}</Link>;
        }
      },
      {
        name: 'site',
        label: 'School',
        width: 200,
      },
      {
        name: 'grades',
        label: 'Grades',
        width: 150,
      },
      {
        name: 'period',
        label: 'Days',
        width: 150,
      },
      {
        name: 'startDate',
        label: 'Start date',
        format: (val:string) => formatDate(val)
      },
      {
        name: 'endDate',
        label: 'End date',
        format: (val:string) => formatDate(val)
      },
      {
        name: 'providerEarnings',
        label: 'Provider earnings',
        format: formatCurrency
      }
    ];

    return cols;
  }

  return render();
}

const totals = [{
    label: 'Total provider earnings',
    name: 'totalProviderEarnings',
    format: formatCurrency
  }
];

type CourseSummaryColumn = CompanyCourseSummaryReportSelections['data'][0];

