import * as React from 'react';

import { AthleticsConfigInput } from 'app2/api';
import { InputField, EmailField, Field, FormModel, Text, FormContent, Panel, Part, PhoneField, RepeatingSection, Section, useForm } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';
import { updatePersonalInfo } from 'app2/views/shared';

import { ParentUserWithCartSelections, parentSetTeamRegistration } from './generated';

type CartedEnrollment = ParentUserWithCartSelections['cart']['enrollments'][0];

interface Props {
  enrollment:CartedEnrollment;
}

export function TeamInfo(props:Props) {
  const {enrollment} = props;
  const {user} = useCurrentUser();
  const form = useForm({ user, teamName: enrollment.athleticsConfig?.teamName, students: enrollment.athleticsConfig?.students || [] }, []);

  function render() {
    return <Panel form={form} type='edit-no-save-button' autoSave onNavigation='nothing' onOk={onSave}>
      <FormContent icon='Users' title={`Team information: ${enrollment.course.name}`}>
        <Section label='Team name' name='teamName' required component={InputField} />
        <Section label='Coach'>
          <Part label='Name' component={<Text text='body'>{user?.name} (you)</Text>} />
          <Part label='Phone number' name='user.phone' required component={PhoneField} />
        </Section>
      </FormContent>
      <FormContent icon='Clipboard' title={`Player information (max. ${enrollment.course.teamMaxCapacity})`} borderBottom='none'
        subtitle='You can do this later. A spot will be held for each student until their family completes enrollment.'>
        <RepeatingSection name='students' add='Add player' none={false} assignIds={false} initialOne maxRows={enrollment.course.teamMaxCapacity} fields={[
          <Field label="Student's name" name='name' required component={InputField} />,
          <Field label="Family's email" name='email' required component={EmailField} />,
          'remove'
        ]} />
      </FormContent>
    </Panel>
  }

  async function onSave(form: FormModel) {
    form.clearErrors();

    let userSuccess = true;

    if (user?.phone != form.values.user?.phone) {
      [userSuccess] = await updatePersonalInfo({ variables: { personalInfo: { phone: form.values.user?.phone } }, error: form });
    }

    const teamName = form.values.teamName;
    const students = (form.values.students || []).filter((s:AthleticsConfigInput['students'][0]) => s.name?.length && s.email?.length);
    const athleticsConfig = { teamName, students };
    const [teamSuccess] = await parentSetTeamRegistration({ variables: { enrollmentId: enrollment.id, athleticsConfig }, error: form });

    return userSuccess && teamSuccess;
  }

  return render();
}
