import * as React from 'react';

import { BoxProps, HBox, Icon } from 'app2/components';

import { CourseCardCourse } from './CourseInfo';

interface Props extends BoxProps {
  course:CourseCardCourse;
}

export function CourseTeacher(props: Props) {
  const {course, ...remaining} = props;

  function render() {
    if (!course?.teacher?.name) {
      return <></>;
    }

    return <HBox text='body' hAlign='center' vAlign='center' gap='$8' {...remaining}>
      <Icon name='User' />
      {course.teacher.name}
    </HBox>
  }

  return render();
}
