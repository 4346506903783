import * as React from 'react'

import { UserUtils } from 'app2/api'
import { CloudinaryPhotoField, FormModel, HBox, Link, Panel, Section, useForm } from 'app2/components'
import { companyFolder } from 'app/helpers';
import { courseImageSearch } from 'app2/views/shared';

import { useCompanyQuery } from '../shared'

import { CompanyImagesSelections, useCompanyImagesQuery, companyUpdate } from './generated';

export function Images() {
  const {company} = useCompanyQuery(useCompanyImagesQuery);
  const form = useForm<Partial<CompanyImagesSelections>>(company, [company]);

  function render() {
    return <Panel icon='Image' title='Choose Profile and Background Image' form={form} type='toggle' onOk={onOk}
      subtitle={<>Please upload a high-quality, head-on profile photo to show to families considering your activities. Next, choose from a library of background images. The images you upload and select will show up on the top of your <Link to={`/provider/${company?.slug}/about`} text='subtitle2' target="_blank">Provider Profile Page</Link>.</>}>
      <Section name='profileBackground' label='Background' component={CloudinaryPhotoField} {...backgroundOptions()} />
      <Section name='profilePhoto' label='Profile' component={CloudinaryPhotoField} {...profileOptions()} none={false} />
      {renderDefaultProfilePhoto()}
    </Panel>
  }

  function backgroundOptions() {
    return {
      maxWidth: '750px',
      search: courseImageSearch,
      transformations: 'c_scale,h_766,w_2880/c_crop,g_center,h_766,w_2880,q_auto,f_auto/',
      options: {
        uploadPreset: 'company_background_image',
        folder: companyFolder(company?.id),
        signatureUrl: `/companies/${company?.id}/company_background_image_signature`,
        cropping: true,
        croppingAspectRatio: 2880/766,
        showSkipCropButton: false
      }
    }
  }

  function profileOptions() {
    return {
      maxWidth: '250px',
      imageId: 'profile',
      borderRadius: 'max',
      options: {
        uploadPreset: 'profile_photo',
        folder: companyFolder(company?.id),
        signatureUrl: `/companies/${company?.id}/profile_photo_signature`,
      }
    }
  }

  function renderDefaultProfilePhoto() {
    if (form?.values?.profilePhoto) {
      return;
    }

    return <HBox width='200px' height='200px' vAlign='center' hAlign='center' borderRadius='50%' bg='#5584c1' color='#fff' lineHeight='54px' fontSize='100px'>{UserUtils.getInitials(company?.name)}</HBox>;
  }

  async function onOk(form:FormModel<Partial<CompanyImagesSelections>>) {
    const [success] = await companyUpdate({variables: {company: company.id, ...form.values}, error: form});

    return success;
  }

  return render();
}

