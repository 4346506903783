import * as React from 'react';
import { pick } from 'lodash-es';

import { FormModel, Panel, useForm } from 'app2/components';
import { SchoolFeeCombo, siteFeeFields, removePathSegment } from 'app2/views/shared';

import { useCurrentSite } from '../shared';

import { updateAdminFee } from './gql';
import { useOrganizerSiteFeesQuery } from './gql';

export function SchoolFee() {
  const { site } = useCurrentSite();
  const form = useForm(() => ({ feeType: site?.feeType, feeRate: site?.feeRate }), [site])
  const [feesResult] = useOrganizerSiteFeesQuery({
    // autoPause is turned off once we have a site, and feeRate is || 0 on the
    // feeRate ensures as the user changes the fee type that if there's a null
    // value (the default) that we recalculate the price else we will show
    // incorrect values when switching to a new fee type
    autoPause: site ? false : true,
    hideLoader: true, // prevent the input from losing focus
    variables: { id: site?.slug, cost: 100, feeType: form.values.feeType, feeRate: form.values.feeRate || 0 }
  });
  const fees = feesResult.data?.siteFees;

  return (
    <Panel icon="DollarSign" title="School fee" subtitle="Changes will only apply to future enrollments" form={form} onOk={onOk} type="toggle">
      <SchoolFeeCombo layout="hbox" vAlign="top" hItemSpace="$4" itemFlex={1} fees={fees} fieldsConfig={siteFeeFields} />
    </Panel>
  );

  async function onOk(form: FormModel) {
    const feeConfig = pick(form.values, ['feeType', 'feeRate']);
    const [success] = await updateAdminFee({ variables: { siteId: site?.slug, feeConfig }, successMsg: 'School fee updated.', error: {handler: form, transform: removePathSegment('feeConfig') } });
    return success;
  }
}
