import * as React from 'react';

import { Modal } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CancelCoursesModal } from '../../course/course-actions';

import { TableAction, TableActionProps } from './TableAction';

interface CancelProps extends TableActionProps {
  createActivities?:boolean;
}

export function CancelCourseAction(props:CancelProps) {
  const { createActivities, kind, ...remaining} = props;
  const behavior = courseKindBehavior[props.kind];

  function render() {
    return <TableAction icon='XCircle' label={behavior?.terms?.delete || (props.createActivities ? 'Delete' : 'Cancel')} onClick={() => Modal.show(cancelCourses())} autoLoader={false} 
      clickEvent='Click Cancel' ability='cancelableOrDeletable' {...remaining} />
  }

  function cancelCourses() {
    return <CancelCoursesModal showDelete={props.createActivities} courses={props.table.selectedItems} />
  }

  return render();
}
