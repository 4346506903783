import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseTagsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CourseTagsQuery = { courseTags?: Array<{ id: string; name?: string | null | undefined; typename: 'CourseTag' }> | null | undefined };

export const CourseTagsDocument = gql`
  query CourseTags {
    courseTags {
      typename: __typename
      id
      name
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CourseTagsArgs = MakeOptional<UseQueryArgs<CourseTagsQueryVariables, CourseTagsQuery>, 'query'>;

export function useCourseTagsQuery(options: CourseTagsArgs = {}) {
  return useQuery<CourseTagsQueryVariables, CourseTagsQuery>({ query: CourseTagsDocument, ...options });
}

export type CourseTagsOptions = ExecQueryOptions<CourseTagsQueryVariables>;

export function courseTags(options: CourseTagsOptions) {
  return executeQuery<CourseTagsQuery, CourseTagsQueryVariables>(CourseTagsDocument, options);
}

export type CourseTagsSelections = CourseTagsQuery['courseTags'][0];

(CourseTagsDocument as any).additionalTypenames = ['CourseTag'];
