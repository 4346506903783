import { useParams } from 'react-router-dom';

export function useThreadId() {
  const { thread } = useParams<{thread:string}>();

  const scoped = thread && thread.indexOf('-') != -1;
  const threadId = scoped ? thread.split('-')[0] : thread;
  const threadScope = scoped ? thread.split('-')[1] : null;

  return {scoped, id:thread, threadId, threadScope};

}
