import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeactivateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeactivateUserMutation = { userDeactivate?: { id: string } | null | undefined };

export const DeactivateUserDocument = gql`
  mutation DeactivateUser($id: ID!) {
    userDeactivate(user: $id) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type DeactivateUserOptions = ExecMutationOptions<DeactivateUserMutationVariables>;

export function deactivateUser(options: DeactivateUserOptions) {
  return executeMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument, options);
}
