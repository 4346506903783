import * as React from 'react';

import { CourseKind, CourseUtils } from 'app2/api';
import { HBox, Icon, } from 'app2/components';

import { CoursePrices, CoursePricesProps } from './CoursePrices';

interface Props extends CoursePricesProps {
}

export function CoursePricesWithIcon(props: Props) {
  const {course, breakdowns, ...remaining} = props;

  function render() {
    if (!course || course.kind == CourseKind.LessonSet) {
      return <></>
    }

    return <HBox mb="$8" vAlign="center" {...remaining} text='body'>
      <Icon name="Tag" size='medium' mr="$8" />
      <span>
        {renderPrompt()}
        <CoursePrices course={course} breakdowns={breakdowns} display="inline" />
      </span>
    </HBox>
  }

  function renderPrompt() {
    const prompt = CourseUtils.schedulableAndNeedsPrompt(course);

    if (prompt) {
      return <><b>Choose schedule</b> for </>
    }

    if (course.hasCheckoutOptions) {
      return <><b>Choose options</b> for </>
    }

    return <></>
  }

  return render();
}

