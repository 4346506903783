import { get, set, flatten, debounce } from 'lodash-es';
import { isNullOrUndefined } from 'util';

import { cloneObservable } from 'app/observable';
import { getSymbol } from 'app/observable/observable/util/getSymbol';

export const form = Symbol('form');

export interface FieldInfo<T, P extends keyof T = any, F = FormData<T>> {
  formData: F;
  fieldName: P;
  initialValue: T[P];
  value: T[P];
  touched: boolean;
  showErrors: boolean;
  errors: string[];
  displayableErrors: string[];
  disabled: boolean;
}

// undefined or true means there's no error
// string is the error message

export type ValidatorResult = undefined | false | true | string | string[];

export type FieldValidator<T, P extends keyof T> = (
  value: T[P],
  info: FieldInfo<T, P>
) => ValidatorResult | Promise<ValidatorResult>;

export type FieldValidators<T, P extends keyof T> =
  | FieldValidator<T, P>
  | FieldValidator<T, P>[];

export type FieldValidatorsMap<T> = { [P in keyof T]?: FieldValidators<T, P> };

export type FormValidator<T> = (
  formData: FormData<T>
) => ValidatorResult | Promise<ValidatorResult>;

export type FormValidators<T> = FormValidator<T> | FormValidator<T>[];

export type Validators<T> =
  | FieldValidatorsMap<T>
  | { [form]?: FormValidators<T> };

export type Submit<T> = () => Promise<boolean>;

export const metadataSymbol = Symbol('metadata');

export interface FieldMetadata<T> {
  touched?: boolean;
  errors?: string[];
}

// FieldMetadatas is stored on the parent value of the field
// for flat form structures with no nesting objects, metadata
// is stored on formData.values.
export type FieldMetadatas<T> = {
  [P in keyof T]?: FieldMetadata<P>;
};

export type FieldChangeHandler<T, P extends keyof T = keyof T> = (
  value: T[P],
  info: FieldInfo<T, P>
) => void;
export type FieldChangeHandlers<T, P extends keyof T = keyof T> =
  | FieldChangeHandler<T, P>
  | FieldChangeHandler<T, P>[];
export type FieldChangeHandlersMap<T> = {
  [P in keyof T]?: FieldChangeHandlers<T, P>;
};

export type ChangeHandlers<T> =
  | FieldChangeHandlersMap<T>
  | { [form]?: FieldChangeHandlersMap<T> };

export type DisabledMap<T> = {
  [prop in keyof Partial<T>]: (undefined | true)[];
};

// the FormData implementation must be observable
export interface FormData<T = any> {
  // Everything in FormData is optional.  Creating a FormData object is optional.  If none is specified to Form, it will create it's own.
  // Most all attributes on FormData are available in some form in Form & Field.
  // FormData is optionally typed to your data structure.

  // Form-wide attributes
  // The actual form data.  Field is resonsible for copying data from the controls to the data field.
  values?: Partial<T>;

  // Initial values that will be copied to the form.
  initialValues?: Partial<T>;

  // Form-wide errors.  These would be generated by a form-wide validate method or your submit method throwing an error.
  errors?: string[];

  // Indicates if the form has been submitted.  Mainly used by other FormData methods to indicate when some errors should be shown.
  submitted?: boolean;

  // Indicates if any form fields have changed since the form was last submitted.
  dirty?: boolean;

  // If specified, invoked when a form is submitted
  submit?: Submit<T>;

  submitting?: boolean;

  // Validation
  // - validators can return undefined or true to indicate else return a string to indicate an error
  // - validators can return a Promise if they are async
  // - can be a single or an array of validation functions
  // - can be field specific
  // - can be form-wide (use the form symbol as the key)
  validators?: Validators<T>;

  // Called when a field value is changed
  changes?: ChangeHandlers<T>;
  change?: FieldChangeHandler<T>;

  disabled?: DisabledMap<T>;
}

// helper to properly type Object.keys, that should have already been typed by Typescript
function getKeys<T>(o: T): Array<keyof T> {
  return o ? <Array<keyof T>>Object.keys(o) : [];
}

function toArray<T>(o: T | T[]): T[] {
  return o === undefined || o === null ? [] : Array.isArray(o) ? o : [o];
}

function getMetadatasForValues<T>(values: Partial<T>): FieldMetadatas<T> {
  const metadata = (values as any)[metadataSymbol];

  return metadata;
}

function getFieldMetadata<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  def: FieldMetadata<P> = { errors: [] }
): FieldMetadata<P> {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  const parts = ('values.' + (fieldName as string)).split('.');
  const field: P = parts.pop() as P;

  const parentValue = get(formData, parts);

  if (!parentValue) {
    return def;
  }

  const metadata = getMetadatasForValues<T>(parentValue);

  if (!metadata) {
    return def;
  }

  return metadata[field] || def;
}

function setFieldMetadata<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  metadataProp: 'errors' | 'touched',
  value: any
) {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  if (!formData.values) {
    formData.values = {};
  }

  const parts = ('values.' + (fieldName as string)).split('.');
  const field: P = parts.pop() as P;

  let parentValue = get(formData, parts);

  if (!parentValue) {
    parentValue = {};
    set(formData, parts, parentValue);
  }

  let metadatas: FieldMetadatas<T> = parentValue[metadataSymbol];
  if (!metadatas) {
    metadatas = parentValue[metadataSymbol] = {};
  }

  let metadata = metadatas[field];
  if (!metadata) {
    metadata = metadatas[field] = {};
  }

  metadata[metadataProp] = value;
}

function getErrors(results: ValidatorResult[]): string[] {
  return flatten(results.filter(
    result => typeof result == 'string' || Array.isArray(result)
  ) as (string | string[])[]);
}

// determines if the form has any errors and if not can be submitted.
export function hasErrors<T>(formData: FormData<T>): boolean {
  return hasFormErrors(formData) || hasFieldErrors(formData);
}

// determines if there are form-wide errors, does not include field errorss
export function hasFormErrors<T>(formData: FormData<T>): boolean {
  return formData.errors && formData.errors.length != 0;
}

export function hasFieldErrors<T>(formData: FormData<T>): boolean {
  return fieldsHaveError(formData.values);
}

export function fieldsHaveError<T>(values: Partial<T>): boolean {
  if (!values) {
    return false;
  }

  const metadata = getMetadatasForValues(values);

  if (!metadata) {
    return false;
  }

  for (const fieldName of getKeys(metadata)) {
    const fieldMetadata = metadata[fieldName];

    if (fieldMetadata) {
      let errors = fieldMetadata.errors;

      if (errors && errors.length) {
        return true;
      }
    }

    const value = values[fieldName];

    if (fieldsHaveError(value)) {
      return true;
    }
  }

  return false;
}

export function clearErrors<T>(formData: FormData<T>) {
  if (hasFormErrors(formData)) {
    formData.errors = [];
  }

  clearFieldErrors(formData.values);
}

function clearFieldErrors<T>(values: Partial<T>) {
  if (!values) {
    return;
  }

  const metadata = getMetadatasForValues(values);

  if (!metadata) {
    return;
  }

  for (const fieldName of getKeys(metadata)) {
    const fieldMetadata = metadata[fieldName];

    if (fieldMetadata) {
      fieldMetadata.errors = null;
    }

    const value = values[fieldName];
    clearFieldErrors(value);
  }
}

export function getFieldInfo<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  defaultValue?: T[P]
): FieldInfo<T, P> {
  const touched = isFieldTouched(formData, fieldName);
  const showErrors = formData.submitted || touched;

  return {
    formData,
    fieldName,
    initialValue: get(formData, `initialValues.${fieldName}`),
    value: getFieldValue(formData, fieldName, defaultValue),
    errors: getFieldErrors(formData, fieldName),
    touched,
    showErrors,
    displayableErrors: getDisplayableFieldErrors(formData, fieldName),
    disabled: getFieldDisabled(formData, fieldName)
  };
}

export function getFieldInfos<T, P extends keyof T>(
  formData: FormData<T>,
  fieldNames: P[]
): FieldInfo<T, P>[] {
  return fieldNames.map(fieldName => getFieldInfo(formData, fieldName));
}

export function getFieldValues<T, P extends keyof T>(
  formData: FormData<T>,
  fieldNames: P[]
): Partial<T> {
  const ret: Partial<T> = {};

  fieldNames.map(
    fieldName => (ret[fieldName] = getFieldValue(formData, fieldName))
  );

  return ret;
}

export function getFieldValue<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  defaultValue?: T[P]
): T[P] {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  return get(formData, `values.${fieldName}`, defaultValue);
}

export function setFieldValues<T>(
  formData: FormData<T>,
  values: Partial<T>,
  touch: boolean = false,
  ignoreChangeHandlers: boolean = false
) {
  for (const fieldName in values) {
    setFieldValue(
      //@ts-ignore
      formData,
      fieldName,
      values[fieldName],
      touch,
      ignoreChangeHandlers
    );
  }
}

export function setFieldValue<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  fieldValue: T[P],
  touch: boolean = false,
  ignoreChangeHandlers: boolean = false
) {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  const existingValue = getFieldValue(formData, fieldName);

  // some controls erroneously send change events when going
  // from undefined to null.  ignore these, as it makes it look
  // like the field value changed when it didn't really.
  if (isNullOrUndefined(existingValue) && isNullOrUndefined(fieldValue)) {
    return;
  }

  if (existingValue === fieldValue) {
    return;
  }

  if (!formData.values) {
    formData.values = {};
  }

  set(formData, `values.${fieldName}`, fieldValue);
  formData.dirty = true;

  const displayableErrors = getDisplayableFieldErrors(formData, fieldName);
  const errors = getFieldErrors(formData, fieldName);

  // if errors exist but aren't displaying, just remove
  // them because touch will show them immediately, possibly
  // before validation will clear them, and validation
  // will generate new errors if needed
  //
  // even if touch = false, it's possible for a ui controls
  // focus to be changed, resulting in a touch field call,
  // possibly before the validateForm runs below, so no
  // matter what, when we change a field value, we clear
  // non-displayable errors to avoid a flash of an error.
  // the validateForm will recreate them as needed.
  if (displayableErrors.length == 0 && errors.length) {
    setFieldErrors(formData, fieldName, []);
  }

  if (touch) {
    touchField(formData, fieldName);
  }

  if (!ignoreChangeHandlers) {
    const formChangeHandlers = getFormChangeHandlers(formData);
    const fieldChangeHanders = getFieldChangeHandlers(formData, fieldName);
    //@ts-ignore
    const changeHandlers = formChangeHandlers.concat(fieldChangeHanders);

    if (changeHandlers.length) {
      const info = getFieldInfo(formData, fieldName);
      changeHandlers.forEach((changeHandler: FieldChangeHandler<T>) =>
        changeHandler(fieldValue, info)
      );
    }
  }

  debouncedValidateForm(formData);
}

// get all errors for a form field
export function getFieldErrors<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): string[] {
  return getFieldMetadata(formData, fieldName).errors || [];
}

// get all displayable errors for a form field.  this omits validation errors for fields that the
// user hasn't "touched" yet (put the focus in), unless the form has already been submitted.
// always returns an array, never null/undefined.
export function getDisplayableFieldErrors<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): string[] {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  const fieldMetadata: FieldMetadata<P> = getFieldMetadata(formData, fieldName);
  const errors = fieldMetadata.errors || [];
  const submitted = formData && formData.submitted;

  return submitted || fieldMetadata.touched ? errors : [];
}

export function displayErrorsForField<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): boolean {
  if (formData.submitted) {
    return true;
  }

  return getFieldMetadata(formData, fieldName).touched;
}

export function getFieldDisabled<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): boolean {
  return get(formData, `disabled.${fieldName}`, false) as boolean;
}

export function setFieldErrors<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P,
  errors: string[]
) {
  setFieldMetadata(formData, fieldName, 'errors', errors);
}

export function isFieldTouched<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
) {
  const fieldMetadata: FieldMetadata<P> = getFieldMetadata(formData, fieldName);

  return fieldMetadata && fieldMetadata.touched;
}

export function areFieldsTouched<T, P extends keyof T>(
  formData: FormData<T>,
  fieldNames: P[]
) {
  return (
    formData.submitted ||
    fieldNames.reduce(
      (prevTouched, fieldName) =>
        prevTouched && isFieldTouched(formData, fieldName),
      true as boolean
    )
  );
}

// indicate that the user has put the focus in a form field
export function touchField<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
) {
  return setFieldTouched(formData, fieldName);
}

export function touchFields<T, P extends keyof T>(
  formData: FormData<T>,
  fieldNames: P[]
) {
  return setFieldsTouched(formData, fieldNames);
}

export function setFieldTouched<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
) {
  if (!fieldName) {
    throw new Error('Missing field name');
  }

  setFieldMetadata(formData, fieldName, 'touched', true);
}

export function setFieldsTouched<T, P extends keyof T>(
  formData: FormData<T>,
  fieldNames: P[]
) {
  fieldNames.forEach(field => setFieldTouched(formData, field));
}

export function getFormValidators<T>(
  formData: FormData<T>
): FormValidator<T>[] {
  return toArray(get(formData, ['validators', form]));
}

function stripArrayPositionsFromPath(fieldName: any): string {
  return (fieldName as string)
    .split('.')
    .filter(part => !isFinite(parseInt(part)))
    .join('.');
}

export function getFieldValidators<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): FieldValidator<T, P>[] {
  return toArray(
    get(formData, `validators.${stripArrayPositionsFromPath(fieldName)}`)
  );
}

export function getFormChangeHandlers<T, P extends keyof T>(
  formData: FormData<T>
): FieldChangeHandler<T, P>[] {
  const handlers = toArray(get(formData, ['changes', form]));

  if (formData.change) {
    handlers.push(formData.change);
  }

  return handlers;
}

export function getFieldChangeHandlers<T, P extends keyof T>(
  formData: FormData<T>,
  fieldName: P
): FieldChangeHandler<T, P>[] {
  return toArray(
    get(formData, `changes.${stripArrayPositionsFromPath(fieldName)}`)
  );
}

// performs form-wide and field validations on touched fields
export async function validateForm<T>(formData: FormData<T>): Promise<boolean> {
  if (!formData.validators) {
    return;
  }

  const promises: Promise<boolean>[] = [runFormValidators(formData)];
  promises.push(...validateValues(formData, '', formData.validators));

  const hasErrors = await Promise.all(promises);

  return hasErrors.find(fieldHasErrors => fieldHasErrors);
}

export function validateValues<T>(
  formData: FormData<T>,
  path: string,
  validators: Validators<T>
): Promise<boolean>[] {
  const promises: Promise<boolean>[] = [];

  for (const fieldName of getKeys(validators)) {
    const pathName = path.length
      ? path + '.' + (fieldName as any)
      : (fieldName as any);
    const childValidators = validators[fieldName];

    if (
      typeof childValidators == 'function' ||
      Array.isArray(childValidators)
    ) {
      promises.push(validateField(formData, pathName as any));
    } else if (typeof childValidators == 'object') {
      const value = getFieldValue(formData, pathName as any);

      if (Array.isArray(value)) {
        for (let pos = 0; pos < value.length; ++pos) {
          validateValues(
            formData,
            pathName + '.' + pos,
            childValidators as any
          );
        }
      } else {
        validateValues(formData, pathName, childValidators as any);
      }
    }
  }

  return promises;
}

export const debouncedValidateForm: FormValidator<any> = debounce(
  async <T>(formData: FormData<T>): Promise<boolean> => {
    return validateForm(formData);
  },
  200
);

export async function runFormValidators<T>(
  formData: FormData<T>
): Promise<boolean> {
  if (!formData.validators) {
    return;
  }

  const validators = getFormValidators(formData);
  const resultsOrPromises = validators.map(validator => validator(formData));

  const results = await Promise.all(resultsOrPromises);
  const errors = getErrors(results);

  formData.errors = errors;

  return errors.length != 0;
}

export async function validateField<T>(
  formData: FormData<T>,
  fieldName: keyof T
): Promise<boolean> {
  if (!formData.validators) {
    return false;
  }

  const validators = getFieldValidators(formData, fieldName);

  if (!validators.length) {
    return;
  }

  const fieldInfo = getFieldInfo(formData, fieldName);
  const resultsOrPromises = validators.map(validator =>
    validator(fieldInfo.value, fieldInfo)
  );
  const results = await Promise.all(resultsOrPromises);
  const errors = getErrors(results);
  const fieldMetadata = getFieldMetadata(formData, fieldName);
  const hasErrors = errors.length != 0;
  const hadErrors =
    fieldMetadata && fieldMetadata.errors && fieldMetadata.errors.length;

  if (!hadErrors && !hasErrors) {
    return false;
  }

  setFieldErrors(formData, fieldName, errors);

  return true;
}

export async function setFieldValidators<T>(
  formData: FormData<T>,
  fieldName: keyof T,
  validators: FieldValidators<T, keyof T>
) {
  if (!formData.validators) {
    formData.validators = {};
  }

  set(
    formData,
    `validators.${stripArrayPositionsFromPath(fieldName)}`,
    validators
  );
}

export async function addFieldValidator<T>(
  formData: FormData<T>,
  fieldName: keyof T,
  validator: FieldValidator<T, keyof T>
) {
  if (!formData.validators) {
    formData.validators = {};
  }

  let validators = getFieldValidators(formData, fieldName);
  validators.push(validator);

  setFieldValidators(formData, fieldName, validators);
}

export async function removeFieldValidator<T>(
  formData: FormData<T>,
  fieldName: keyof T,
  validator: FieldValidator<T, keyof T>
) {
  if (!formData.validators) {
    return;
  }

  const validators = getFieldValidators(formData, fieldName);

  if (!validators || !validators.length) {
    return;
  }

  const pos = validators.indexOf(validator);

  if (pos == -1) {
    return;
  }

  validators.splice(pos, 1);

  setFieldValidators(formData, fieldName, validators);
}

// does the necessary checks and updates before submitting a form
// validates all form fields, and returns false if there are errors
// else, marks that the form has been submitted (though the called
// has to do the actual submit).

export async function presubmit<T>(formData: FormData<T>): Promise<boolean> {
  const hasErrors = await validateForm(formData);

  formData.submitted = true;

  if (hasErrors) {
    return false;
  }

  formData.dirty = false;
  clearErrors(formData);

  return true;
}

export async function submit<T>(formData: FormData<T>): Promise<boolean> {
  const success = await presubmit(formData);

  if (!success) {
    return false;
  }

  if (!formData.submit) {
    return true;
  }

  return formData.submit();
}

const observableTargetSymbol: any = getSymbol('observableTarget');

export function resetForm<T>(
  formData: FormData<T>,
  initialValues?: Partial<T>
) {
  formData.errors = undefined;
  formData.submitted = undefined;
  formData.dirty = undefined;
  formData.values = cloneObservable(
    initialValues || formData.initialValues || {}
  );

  validateForm(formData);
}
