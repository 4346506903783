import * as React from 'react';
import { oneLine, commaListsAnd } from 'common-tags';

import { EnrollmentUtils } from 'app2/api';
import { Info, useFormInfo } from 'app2/components';

import { CheckoutModel } from './CheckoutModel';

export function WaitlistWarning() {
  const formUi = useFormInfo<CheckoutModel>();
  const form = formUi.form;
  const waitlisted = form.values.cart?.enrollments?.filter(e => EnrollmentUtils.waitlistUnfinalized(e));

  function render() {
    if (!waitlisted.length) {
      return null;
    }

    return (
      <Info type="warning" width="100%" mb="$12">
        {renderMessage()}
      </Info>
    );
  }

  function renderMessage() {
    const names = commaListsAnd`${[...new Set(waitlisted.map(e => e.course.name))]}`;

    return oneLine`
    You’re joining the waitlist for ${names}. If you select a payment method and a spot opens up in the next 30 minutes, we’ll automatically purchase it for you! 
    Otherwise, we’ll email you when a spot becomes available and you’ll have 24 hours to accept it.`
  }

  return render();
}
