import * as React from 'react';

import { Panel, FormContent, VBox, useFormInfo, useLifecycle } from 'app2/components';

import { CheckoutModel } from '../CheckoutModel';
import { WaitlistWarning } from '../WaitlistWarning';

import { PaymentMethod } from './PaymentMethod';
import { Donation } from './Donation';
import { Total } from './Total';

export function Payment() {
  const form = useFormInfo<CheckoutModel>().form;

  useLifecycle({onMount});

  function onMount() {
    // all of the sections in checkout use the same form
    // and when you go from section to section in checkout
    // each section gets validated and the form is marked as
    // submitted.  because of that, when you get to the last section
    // it will show required fields as in error, so we reset the
    // submitted back to false.
    form.submitted = false;
  }

  function render() {
    return (
      <VBox width="100%" hAlign="right">
        <Panel icon="CreditCard" title="Payment information" width="100%">
          <FormContent width="100%" maxWidth="750px">
            <PaymentMethod />
            <Donation />
          </FormContent>
        </Panel>
        <WaitlistWarning />
        <Total />
      </VBox>
    );
  }

  return render();
}
