import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicSiteDirectoryQueryVariables = Types.Exact<{
  zipCode?: Types.InputMaybe<Types.Scalars['String']>;
  company?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type PublicSiteDirectoryQuery = {
  directorySiteSearch?:
    | {
        total: number;
        sites: Array<{
          distance?: number | null | undefined;
          site: {
            id: string;
            name?: string | null | undefined;
            city?: string | null | undefined;
            state?: string | null | undefined;
            zipcode?: string | null | undefined;
            slug: string;
            directoryCourseTags?: Array<{ name?: string | null | undefined }> | null | undefined;
            latestSeason: { coursesFinish: string };
          };
        }>;
      }
    | null
    | undefined;
};

export const PublicSiteDirectoryDocument = gql`
  query PublicSiteDirectory($zipCode: String, $company: String) {
    directorySiteSearch(zipCode: $zipCode, company: $company) {
      sites {
        site {
          id
          name
          city
          state
          zipcode
          slug
          directoryCourseTags {
            name
          }
          latestSeason {
            coursesFinish
          }
        }
        distance
      }
      total
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicSiteDirectoryArgs = MakeOptional<UseQueryArgs<PublicSiteDirectoryQueryVariables, PublicSiteDirectoryQuery>, 'query'>;

export function usePublicSiteDirectoryQuery(options: PublicSiteDirectoryArgs = {}) {
  return useQuery<PublicSiteDirectoryQueryVariables, PublicSiteDirectoryQuery>({ query: PublicSiteDirectoryDocument, ...options });
}

export type PublicSiteDirectoryOptions = ExecQueryOptions<PublicSiteDirectoryQueryVariables>;

export function publicSiteDirectory(options: PublicSiteDirectoryOptions) {
  return executeQuery<PublicSiteDirectoryQuery, PublicSiteDirectoryQueryVariables>(PublicSiteDirectoryDocument, options);
}

export type PublicSiteDirectorySelections = PublicSiteDirectoryQuery['directorySiteSearch'];
