import { Rect } from './Rect';
import { Point } from './Point';
import { scrollTo } from './scrollTo';
import { getScrollParent } from './getScrollParent';

type ScrollOptions = {
  // defaults to smooth
  behavior?:ScrollBehavior;
  // when the scrolled point is out of view
  // this refers to way on the visible screen it
  // should be scrolled to.  by default it's auto
  // which will scroll to the nearest edge.  for example
  // if the desired scrolled point is below the current
  // visible area, auto will scroll that so the point
  // is on the bottom.  if its above, then auto would
  // scroll it so that it was on the top.
  alignment?:'begin' | 'end' | 'auto';
} | ScrollBehavior;

export function scrollIntoView(element:HTMLElement, optionsOrBehavior:ScrollOptions = 'smooth', offset = new Point(20, 30)) {
  let style = window.getComputedStyle(element);

  while (element && style?.display == 'none') {
    element = element.parentElement;
    style = window.getComputedStyle(element);
  }

  if (!element) {
    return;
  }

  const scrollParent = getScrollParent(element, true);
  const scrollerRect = scrollParent.getBoundingClientRect();

  const elementRect = new Rect(element.getBoundingClientRect());
  elementRect.offset(scrollParent.scrollLeft - scrollerRect.left, scrollParent.scrollTop - scrollerRect.top);

  return scrollRectIntoView(scrollParent, elementRect, offset, offset, optionsOrBehavior);
}

// scrolls a rect into view if its not visible.  if the rect is above
// the scrollable area, then its aligned to the top of the scrollable area.  
// if its below then its aligned to the bottom of the scrollable area.
// similar for left/right.

export function scrollRectIntoView(scrollParent:HTMLElement, elementRect:Rect, tlOffset:Point = new Point(), brOffset:Point = new Point(), optionsOrBehavior:ScrollOptions = "auto") {
  let visibleLeft = scrollParent.scrollLeft + tlOffset.x;
  let visibleTop = scrollParent.scrollTop + tlOffset.y;
  let visibleRight = scrollParent.scrollLeft + scrollParent.clientWidth - brOffset.y;
  let visibleBottom = scrollParent.scrollTop + scrollParent.clientHeight - brOffset.x;

  // if this is the main scrollable area, we need to account for the embed size being larger than the viewport
  // for now we detect this via a hack...TODO - need a better way
  if (scrollParent.id == 'Page' || scrollParent == document.documentElement) {
    const embeddedViewportTop = parseFloat(document.documentElement.style.getPropertyValue('--viewport-top')) || 0
    const embeddedViewportHeight = parseFloat(document.documentElement.style.getPropertyValue('--viewport-height')) || Number.MAX_SAFE_INTEGER;

    visibleTop = Math.max(visibleTop, embeddedViewportTop);
    visibleBottom = Math.min(visibleBottom, embeddedViewportTop + embeddedViewportHeight);
  }

  const visible = new Rect(visibleLeft, visibleTop, visibleRight, visibleBottom);

  const containsX = visible.containsX(elementRect) || elementRect.containsX(visible);// the latter makes scroll bail when the element is larger than visible
  const containsY = visible.containsY(elementRect) || elementRect.containsY(visible);

  const options = typeof optionsOrBehavior == 'string' ? {behavior:optionsOrBehavior} : optionsOrBehavior || {alignment: 'auto', behavior: 'smooth'};
  options.alignment = options.alignment || 'auto';
  options.behavior = options.behavior || 'smooth';

  if (containsX && containsY && options.alignment == 'auto') {
    return;
  }

  let top = scrollParent.scrollTop;

  if (!containsY || options.alignment != 'auto') {
    if ((elementRect.top < visible.top || elementRect.height > visible.height || options.alignment == 'begin') && options.alignment != 'end') {
      top = elementRect.top - tlOffset.y;
    }
    else {
      top = elementRect.bottom + brOffset.y - scrollParent.clientHeight;
    }
  }

  let left = scrollParent.scrollLeft;

  if (!containsX || options.alignment != 'auto') {
    if ((elementRect.left < visible.left || elementRect.width > visible.width || options.alignment == 'begin') && options.alignment != 'end') {
      left = elementRect.left - tlOffset.x;
    }
    else {
      left = elementRect.right + brOffset.x - scrollParent.clientWidth;
    }
  }

  const smoothThreshold = 500;
  const underThreshold = Math.abs(left - scrollParent.scrollLeft) < smoothThreshold && Math.abs(top - scrollParent.scrollTop) < smoothThreshold;
  const smooth = options.behavior == 'smooth' || (options.behavior == 'auto' && underThreshold);
  const scrollOptions:ScrollToOptions = {left, top, behavior: smooth ? 'smooth' : 'auto'};

  return scrollTo(scrollParent, scrollOptions);
}
