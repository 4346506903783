import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const DropdownOpen = React.forwardRef((props: Props, ref: React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg ref={ref} width={props.size} height={props.size} viewBox="0 0 18 18" stroke={color} strokeWidth={1} strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <g fill='currentColor'>
      <path d="M4.5 6.75L9 11.25L13.5 6.75" />
      <path d="M4.5 6.75L9 11.25L13.5 6.75" />
      <path d="M4.5 6.75H13.5" />
    </g>
  </svg>
})

DropdownOpen.displayName = 'DropdownOpen';
DropdownOpen.defaultProps = {
  size: 18
};
