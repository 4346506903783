import { CLIENT_VERSION, eventManager } from 'app2/api'

import { localStorageHelper } from './localStorageHelper'
import {
  isProductionEnv,
  isRealProduction,
  isAutomatedTestingEnv,
  isStagingEnv,
  isReleaseEnv
} from './environments'
import { isPresent } from './is'

export * from './localStorageHelper'
export * from './environments'
export * from './downloadFile'
export * from './xpath'
export * from './image'

export const HOMEROOM_VERSION = 'homeroom_version';

export const resetVersion = () => {
  // Clear cache based on client version of site
  const version = localStorageHelper.getItem(HOMEROOM_VERSION)
  if (
    version === 'undefined' ||
    !version ||
    version !== process.env.CLIENT_VERSION
  ) {
    localStorageHelper.removeItem(HOMEROOM_VERSION)
    localStorageHelper.setItem(HOMEROOM_VERSION, process.env.CLIENT_VERSION)
  }
}

export function captureLogRocketSessions() {
  return isRealProduction() || isAutomatedTestingEnv() || isStagingEnv() || isReleaseEnv();
}

export function captureSentry() {
  return isProductionEnv()
}

export function captureException({ err, errInfo, action }) {
  if (!isProductionEnv()) {
    console.error('Exception captured:', err)

    return
  }

  Sentry.withScope(scope => {
    scope.setExtra('clientVersion - env', process.env.CLIENT_VERSION)
    scope.setExtra('clientVersion - build', CLIENT_VERSION)

    // Provided by `React.Component.componentDidCatch`.
    if (isPresent(errInfo)) {
      scope.setExtras(errInfo)
    }

    if (isPresent(action)) {
      scope.setExtra('action', action)
    }

    // Send user details to Sentry.
    if (eventManager.user) {
      scope.setUser({
        id: eventManager.user.id,
        email: eventManager.user.email
      })
    }

    if (captureLogRocketSessions()) {
      LogRocket.captureException(err, errInfo)
    }

    Sentry.captureException(err)
  })
}

export function isAxiosError(error = {}) {
  return Boolean(error.isAxiosError || error.response)
}

