import * as React from 'react';
import { useHistory } from 'react-router'
import { flatten } from 'lodash-es';

import { EnrollmentInput, EnrollmentOptionInput } from 'app2/api';
import { HBox, Icon, Modal, Link, Panel, Repeater, SaveableResult, Text, VBox } from 'app2/components';
import { CourseLink, addToCart, EnrollmentCourseOptionsModal, EnrollmentOptionsFormValues } from 'app2/views/shared-public';
import { WaitlistBadge } from 'app2/views/shared';
import { ParentStudentDetailsSelections } from 'app2/views/parent/shared';

import { ParentStudentEnrollmentOptionsSelections, useParentStudentEnrollmentOptionsQuery} from './generated';

type Enrollment = ParentStudentEnrollmentOptionsSelections['enrollments'][0];
type CourseOption = Enrollment['course']['options'][0];

interface Props {
  student:ParentStudentDetailsSelections;
}

export function FamilyCourseOptions(props:Props) {
  const enrollments = getEnrollmentsWithOptions();
  const history = useHistory();
  
  function render() {
    if (!enrollments.length) {
      return <></>
    }

    return <Panel icon='Star' title='Activity options' id='options'>
      <Text text='body' mb='0px!important'>Note: After confirming your selections, changes can only be made by directly <Link to='/family/messages'>contacting the activity provider</Link>.</Text>
      <VBox css={rowCss}>{enrollments.map(renderEnrollment)}</VBox>
    </Panel>
  }

  function renderEnrollment(e:Enrollment) {
    return <VBox key={e.id} gap='$16'>
      <HBox hAlign='justify'>
        <CourseLink text='subtitle1' underline={false} course={e.course} role='public' />
        <Icon name='Edit' onClick={() => onEditEnrollment(e)} />
      </HBox>
      <HBox flexWrap='wrap' gap='12px 12px'>
        {e.course.options.map(o => renderOption(e, o))}
      </HBox>
    </VBox>
  }

  function renderOption(e:Enrollment, o:CourseOption) {
    const choices = e.enrollmentOptions.find(op => op.option.id === o.id)?.choices;

    return <VBox key={o.id} gap='$4' width='30%'>
      <Text text='subtitle2'>{o.title}</Text>
      <HBox><Repeater items={choices} delimeter=',&nbsp;' none='None' render={(choice:Enrollment['enrollmentOptions'][0]['choices'][0]) => <HBox gap='$8'><CourseLink underline={false} course={choice.course} role='public' /><WaitlistBadge enrollment={choice} /></HBox>} /></HBox>
    </VBox>
  }

  async function onEditEnrollment(e:Enrollment) {
    const result = await Modal.show<EnrollmentOptionsFormValues>(<EnrollmentCourseOptionsModal course={e.course.id} choices={e.enrollmentOptions} checkout={false} ok='Checkout' />);

    if (result.action == SaveableResult.cancel) {
      return;
    }

    const optionsChoices = result.result.choices;
    const toAdd = flatten(optionsChoices.map(optionChoices => optionChoices.choices.
        filter(choice => newPurchase(e, optionChoices, choice)).
        map(choice => ({...choice, studentId: props.student.id, beforeClassPickup: e.pickup, dismissal: e.dismissal}))));

    if (!toAdd.length) {
      return;
    }

    const results = await addToCart(toAdd);

    if (results) {
      history.push('/checkout?auto');
    }
  }

  function newPurchase(e:Enrollment, optionChoices:EnrollmentOptionInput, choice:EnrollmentInput) {
    return !e.enrollmentOptions.find(o => o.option.id === optionChoices.option)?.choices.find(c => c.course.id === choice.id)
  }

  // this strips enrollments w/o options
  function getEnrollmentsWithOptions() {
    const [result] = useParentStudentEnrollmentOptionsQuery({ variables: { id: props.student?.id } });

    return React.useMemo(() => {
      return (result.data?.student?.enrollments || []).filter(e => e.course.options?.length)
    }, [result.data?.student]);
  }

  return render();
}

const rowCss = {
  '>*':{
    borderBottom:'solid 1px', 
    borderColor:'border', 
    paddingTop: '30px', 
    paddingBottom:'30px'
  },
  '>*:last-child':{
    border:'none',
    paddingBottom: '0px'
  }
}