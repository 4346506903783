import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentConfirmStudentYearlyUpdateMutationVariables = Types.Exact<{
  students: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type ParentConfirmStudentYearlyUpdateMutation = { studentConfirmYearlyUpdate?: { students?: Array<{ id: string; upToDate?: boolean | null | undefined }> | null | undefined } | null | undefined };

export const ParentConfirmStudentYearlyUpdateDocument = gql`
  mutation ParentConfirmStudentYearlyUpdate($students: [ID!]!) {
    studentConfirmYearlyUpdate(students: $students) {
      students {
        id
        upToDate
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentConfirmStudentYearlyUpdateOptions = ExecMutationOptions<ParentConfirmStudentYearlyUpdateMutationVariables>;

export function parentConfirmStudentYearlyUpdate(options: ParentConfirmStudentYearlyUpdateOptions) {
  return executeMutation<ParentConfirmStudentYearlyUpdateMutation, ParentConfirmStudentYearlyUpdateMutationVariables>(ParentConfirmStudentYearlyUpdateDocument, options);
}
