import * as React from 'react';

import { Checkbox } from '../../Checkbox';
import { HBox } from '../../Box';
import { Icon } from '../../icons';
import { FormLabel } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { theme } from '../../theme';

import { GroupRowInfo } from '../DataTable';
import { RowHeaderRendererProps } from './RowHeaderRendererProps';

export function RowHeaderRenderer(props: RowHeaderRendererProps) {
  // when we render only checkbox, we still render the number
  // mainly for tis gridlines, and then the width (in the tables
  // colWidth function) is cut short hide the row numbers.

  return props.table.props.rowSelection
    ? renderCheckboxAndRowNumber(props)
    : renderOnlyRowNumber(props)
}

function renderCheckboxAndRowNumber(props: RowHeaderRendererProps) {
  delete props.style.overflow;

  return <HBox data-row={props.row} data-field='TableRowHeader' paddingLeft="12px" vAlign="center" hAlign="left" bg="#FFF" style={props.style} paddingTop='1px' paddingBottom='1px'>
    {renderCheckbox(props)}
    {renderRowNumber(props)}
  </HBox>
}

function renderOnlyRowNumber(props: RowHeaderRendererProps) {
  return <HBox data-row={props.row} data-field='TableRowHeader' vAlign="center" hAlign="center" bg="#FFF" style={props.style} paddingTop='1px' paddingBottom='1px'>
    {renderRowNumber(props)}
  </HBox>
}

function renderCheckbox(props: RowHeaderRendererProps) {
  return (
    <Checkbox
      onMouseDown={event => event.stopPropagation()}
      checked={props.table.selections.containsRow(props.row)}
      onChange={event => {
        props.table.selections = props.table.selections
          .clone()
          .toggleRow(props.row);
        
        props.table.focus();
      }}
    />
  );
}

function renderRowNumber(props:RowHeaderRendererProps) {
  const table = props.table;
  const tableProps = table.props;

  const errors = table.getRecordErrors(props.row);
  const hasError = errors && errors.length;
  const hackRowNumbersOnlyWithLockedColsFix = !tableProps.rowNumbers && table.lockedCol != -1 ? .5 : 1;
  const border = `${hackRowNumbersOnlyWithLockedColsFix}px solid ${hasError ? theme.colors.error : theme.colors.border}`;
  const bg = hasError ? 'errorFill' : '#F6F6F6';
  const zIndex = hasError ? 1 : undefined;
  
  const showNumberSpace = tableProps.rowNumbers || !!tableProps.group;
  const rowNumOrGroupState = table.getRowNumberOrGroupState(props.row);
  const isGroup = typeof rowNumOrGroupState == 'object';
  const groupState = isGroup ? rowNumOrGroupState as GroupRowInfo : undefined;
  const rowNumber = !isGroup && tableProps.rowNumbers ? Number(rowNumOrGroupState) : undefined;
  const rowPosOrGrouping = !isGroup 
    ? rowNumber + 1 
    : <><HBox vAlign='center'>
      {groupState.state == 'opened' ? <Icon name='ChevronDown' button onClick={() => onToggleState(props)} /> : <Icon name='ChevronRight' button onClick={() => onToggleState(props)} />}
      <HBox right='8px' width='20px' height='20px' borderRadius='10px' position='absolute' vAlign='center' hAlign='center' color='#fff' bg='primary'>{groupState.count}</HBox>
    </HBox></>;

  const inside = hasError 
    ? <Icon name='Info' color='error' size='small' /> 
    : (showNumberSpace ? <FormLabel>{rowPosOrGrouping}</FormLabel> : undefined);

  const header = <HBox position='relative' bg={bg} vAlign="center" hAlign="center" width='100%' height='100%' border={border} borderRadius="3px 0px 0px 3px" color={hasError ? 'error' : undefined} zIndex={zIndex}>
    {inside}
  </HBox>;

  return hasError ? renderTooltip(header, errors) : header;
}

function renderTooltip(contents:any, errors:string[]) {
  return <Tooltip tip={errors}>
    {contents}
  </Tooltip>
}

function onToggleState(props: RowHeaderRendererProps) {
  const table = props.table;
  const result = table.props.group.onToggleState(table.data.getItem(props.row));
}
