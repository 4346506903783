import * as React from 'react'

import { CourseUtils, Course } from 'app2/api';

export type CourseWithDates = Pick<Course, 'enrollmentOpens' | 'enrollmentCloses' | 'noEnrichmentDays' | 'courseDays'>;

export function useCourseDates(course:CourseWithDates) {
  return React.useMemo(() => CourseUtils.getCourseDates(course), [course]);
}

