import * as React from 'react';

import { TimePicker } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function TimePickerDemos() {
  const [time, setTime] = React.useState('');

  return <Demos name='TimePicker'>
    <Demo name='Basic'>
      <TimePicker />
    </Demo>

    <Demo name='Controlled'>
      <TimePicker value={time} onChange={(event) => setTime(event.currentTarget.timeValue)} />
      You selected: {time}
    </Demo>

    <Demo name='Error'>
      <TimePicker error />
    </Demo>
  </Demos>
}
