export function getZindex(element:HTMLElement) {
  let zIndex = 0;

  while (element) {
    const style = window.getComputedStyle(element);

    zIndex = Math.max(zIndex, parseInt(style.zIndex) || 0);

    if (style.position != 'static' && parseInt(style.opacity) != 0) {
      // this starts a new stacking context
      break;
    }

    element = element.parentElement;
  }

  return zIndex;
}
