import * as React from 'react';
import { O } from 'ts-toolbelt'

import { Course } from 'app2/api';
import { Text, Subtitle2, VBox } from 'app2/components';

import { PublicCourseLink, PublicCompanyLink } from '../../';

interface Props {
  course: Pick<Course, 'id' | 'name'> & Partial<O.P.Pick<Course, ['vendor', 'name' | 'company']>> & Partial<O.P.Pick<Course, ['company', 'name' | 'id' | 'email']>>;
  showCompany?:boolean;
  kind?:'course-page' | 'card' | 'card-preview' | 'course-option';
  children?:React.ReactNode;
}

export function CourseAndCompanyName(props: Props) {
  const course = props.course;
  const showCompany = props.showCompany !== false && (props.showCompany || props.kind !== 'course-option');

  function render() {
    const company = course?.vendor ? {...course.vendor, id: course.vendor.company?.id || ''} : course?.company;
    const companyName = company?.name;

    return <VBox gap='$8'>
      <PublicCourseLink text={props.kind == 'course-page' ? 'heading1' : 'subtitle1'} maxLines={showCompany ? 2 : 3} course={course} underline={false} tooltip={course.name} />
      {showCompany && <Subtitle2 fontSize='14px' maxLines={2} tooltip={companyName}>
        <Text fontWeight="normal">by <PublicCompanyLink company={company} text='subtitle2' fontSize='14px' underline={false} display='inline' /></Text>
      </Subtitle2>}
      {props.children}
    </VBox>
  }

  return render();
}
