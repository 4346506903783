import * as React from 'react';
import moment from 'moment';

import { CalendarProps, Calendar, DateInput, dispatchChangeEvent, iso8601Date, Menu, MenuDropdown, MenuItem, MenuSeparator } from 'app/app2/components';

export function PeriodMenu(props:CalendarProps) {
  function render() {
    return <Menu>
      <MenuItem label='Last 30 days' onClick={() => dispatch([iso8601Date(moment().subtract(1, 'months')), iso8601Date(moment())])} />
      <MenuItem label='Last 60 days' onClick={() => dispatch([iso8601Date(moment().subtract(2, 'months')), iso8601Date(moment())])} />
      <MenuSeparator />
      <MenuDropdown label='By month' rightAligned={false}>
        {Array(12).fill(0).map((_, i) => {
          const month = moment().subtract(i, 'months').startOf('month');
          return <MenuItem key={i} label={month.format('MMMM YYYY')} onClick={() => dispatch([iso8601Date(month), iso8601Date(month.add(1, 'months').subtract(1, 'days'))])} />
        })
      }
      </MenuDropdown>
      <MenuDropdown label='Custom period' rightAligned={false}>
        <Calendar type='range' {...props} />
      </MenuDropdown>
    </Menu>
  }

  function dispatch(values:DateInput[]) {
    dispatchChangeEvent({}, values, props.onChange);
  }

  return render();
}
