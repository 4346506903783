import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateParentAttendanceMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  attendanceConfig: Types.SiteParentAttendanceConfigInput;
}>;

export type OrganizerUpdateParentAttendanceMutation = { siteUpdateParentAttendance?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateParentAttendanceDocument = gql`
  mutation OrganizerUpdateParentAttendance($siteId: ID!, $attendanceConfig: SiteParentAttendanceConfigInput!) {
    siteUpdateParentAttendance(site: $siteId, attendanceConfig: $attendanceConfig) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateParentAttendanceOptions = ExecMutationOptions<OrganizerUpdateParentAttendanceMutationVariables>;

export function organizerUpdateParentAttendance(options: OrganizerUpdateParentAttendanceOptions) {
  return executeMutation<OrganizerUpdateParentAttendanceMutation, OrganizerUpdateParentAttendanceMutationVariables>(OrganizerUpdateParentAttendanceDocument, options);
}
