import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MergeCompaniesMutationVariables = Types.Exact<{
  source: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
}>;

export type MergeCompaniesMutation = { companiesMerge?: { company?: { id: string; name?: string | null | undefined } | null | undefined } | null | undefined };

export const MergeCompaniesDocument = gql`
  mutation MergeCompanies($source: ID!, $destination: ID!) {
    companiesMerge(source: $source, destination: $destination) {
      company {
        id
        name
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MergeCompaniesOptions = ExecMutationOptions<MergeCompaniesMutationVariables>;

export function mergeCompanies(options: MergeCompaniesOptions) {
  return executeMutation<MergeCompaniesMutation, MergeCompaniesMutationVariables>(MergeCompaniesDocument, options);
}
