import { urql } from '../';

import { axios, clearUserTokens } from './axios';

export async function logout() {
  try {
    await axios.delete('/auth/sign_out')
  }
  catch(e) {
  }

  clearUserTokens();
  urql.reset();
}