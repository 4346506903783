import * as React from 'react';

import { Combobox, VBox, CurrencyInput } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ComboboxDemos() {
  const [rateType, setRateType] = React.useState('No discount');
  const [amount, setAmount] = React.useState('0');

  return <Demos name='Combobox'>
    <Demo name='Basic'>
      <Combobox width='300px' options={['No discount', 'Fixed amount', 'Percentage']} placeholder='Enter amount' />
    </Demo>

    <Demo name='Controlled'>
      <Combobox width='300px' options={['No discount', 'Fixed amount', 'Percentage']} placeholder='Enter amount' 
        selected={rateType} onChangeOptions={event => setRateType(event.target.value)} 
        value={amount} onChangeValue={event => setAmount(event.target.value)} />
      
      <VBox>
        <span>Rate type: {rateType}</span>
        <span>Amount: {amount}</span>
      </VBox>
    </Demo>

    <Demo name='Customized Input (Currency)'>
      <Combobox width='300px' options={['No discount', 'Fixed amount', 'Percentage']} placeholder='Enter amount' input={CurrencyInput}
        selected={rateType} onChangeOptions={event => setRateType(event.target.value)} 
        value={amount} onChangeValue={event => setAmount(event.target.value)} />
      
      <VBox>
        <span>Rate type: {rateType}</span>
        <span>Amount: {amount}</span>
      </VBox>
    </Demo>
  </Demos>
}
