import React from 'react';
import { pick } from 'lodash-es';

import { Course, Teacher } from 'app2/api';
import { Dropdown, defaultFieldProps, Form, Section, Part, PopupManager, InputField, EmailField, PhoneField, Modal } from 'app2/components';
import { findQueryOption } from 'app2/views/shared-public';

import { PickerProps, PickerEntityProps, getPickerEntityId } from '../PickerProps';

import { addTeacher, useVendorTeachersPickerQuery, vendorTeachersPicker, VendorTeachersPickerSelections, useCompanyTeachersPickerQuery, companyTeachersPicker, CompanyTeachersPickerSelections } from './generated';

export function TeacherPicker(props:PickerProps) {  
  const {field, course:propsCourse, vendor:vendorProp, company:companyProp, ...remaining} = props;
  const {company, companyId, vendorId} = getPickerEntityId(props);
  const options = getOptions();

  function render() {
    return <Dropdown options={options} onAdd={onAddTeacher} {...remaining} />
  }

  function getOptions() {
    const [result1] = useCompanyTeachersPickerQuery({variables: {company: companyId}, requestPolicy: 'cache-first', hideLoader: true, pause: !companyId})
    const [result2] = useVendorTeachersPickerQuery({variables: {vendor: vendorId}, requestPolicy: 'cache-first', hideLoader: true, pause: !!companyId || !vendorId})
    const companyData = result1.data?.company || result2.data?.vendor?.company;
    return React.useMemo(() => companyData?.teachers.map(createOptions), [companyData]);
  }

  function createOptions(t:VendorTeachersPickerSelections['company']['teachers'][0]) {
    return {value: t, label: t.name};
  }

  async function onAddTeacher(name:string) {
    if (!companyId && !vendorId) {
      await Modal.warning('Instructor Information', 'Please assign a provider to this activity before adding an instructor.');
      return;
    }
    
    if (!company?.userCompanyRole) {
      return;
    }

    const result = await PopupManager.addModal<Teacher>(<Modal title='Instructor Information'>
      <Form icon='User' title='Instructor details' editing={true} initialValues={{name}}>
        <Section label='Name' name='name' required component={InputField} />
        <Section label='Contact'>
          <Part label='Email' name='email' required component={EmailField} />
          <Part label='Phone' name='phone' required component={PhoneField} />
        </Section>
      </Form>
    </Modal>);
  
    if (result.action != 1) {
      return;
    }
    
    const teacher = result.result;
    const [succcess, addResult ] = await addTeacher({variables:{company: companyId, vendor: vendorId, teacher: pick(teacher, ['name', 'email', 'phone'])}});

    if (!succcess) {
      return;
    }

    return createOptions(addResult.data.companyUpsertTeacher.teacher).value;
  }

  return render();
}

export async function findTeacher(nameOrObArray:string | Teacher | string[] | Teacher[], entity:PickerEntityProps) {
  const {companyId, vendorId} = getPickerEntityId(entity);

  if (!companyId && !vendorId) {
    return null;
  }

  return companyId
    ? findQueryOption(companyTeachersPicker, {company: companyId}, nameOrObArray)
    : findQueryOption(vendorTeachersPicker, {vendor: vendorId}, nameOrObArray);
}

TeacherPicker.findTeacher = findTeacher;

TeacherPicker.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'field'
}

