import * as React from 'react';
import { useParams } from 'react-router-dom';

import { CfStage, CourseKind } from 'app2/api';
import { BackLink } from 'app2/views/shared-public';
import { PrivatePage } from 'app2/views/shared';
import { ParentStudentDetails, FamilyCustomFields, useParentStudentDetailsQuery } from 'app2/views/parent/shared';

import { ParentStudentRouteParams } from '../parentRoutes';

import { FamilyCourseOptions } from './FamilyCourseOptions';

export function Student() {
  const {studentId} = useParams<ParentStudentRouteParams>();
  const [result] = useParentStudentDetailsQuery({ variables: { id: studentId } });
  const student = result?.data?.student;

  return <PrivatePage title={`${student?.name || ''}`} back={<BackLink to={`/family/family`}>Back to Family</BackLink>}>
    <ParentStudentDetails id={studentId} purpose='edit' courseKinds={[CourseKind.Enrichment]} />
    <FamilyCustomFields id={studentId} type="edit" stage={CfStage.Later} autoSave autoFocus={false} onNavigation='prompt-dirty' />
    <FamilyCourseOptions student={student} />
  </PrivatePage>
}