import * as React from 'react';

import { organizerPreferences, organizerPreferencesKeys, VendorInput } from 'app2/api';
import { ActionButton, Button, DataTable, DataTableColumn, FieldValue, EmailLabelField, PhoneLabelField, FieldInfo, Modal, Link, VBox } from 'app2/components';
import { HrDataTable, MIN_COL_WIDTH, copyEmails } from 'app2/views/shared';

import { CompanyDirectoryCTA, OrganizerPage, useCurrentSite } from '../shared';

import { ProviderModal } from './edit-provider';
import { AddProviderModal } from './AddProviderModal';
import { useOrganizerProvidersQuery, OrganizerProvidersSelections } from './gql';
import { DeleteProvidersModal } from './DeleteProvidersModal';

type Vendors = OrganizerProvidersSelections['vendors'][0];

const PROVIDERS_TABLE_PREFS_VERSION = '4';

export function Providers() {
  const { site, loading } = useCurrentSite();
  const cols = React.useMemo(() => getCols(), [site]);
  const table = React.useRef<DataTable<Vendors>>();

  function render() {
    return (
      <OrganizerPage title="Providers" above={<CompanyDirectoryCTA />}>
        <HrDataTable<Vendors>
          header={{
            icon: 'Users',
            title: 'Providers',
            primaryActions: renderPrimaryActions(),
            secondaryActions:renderSecondaryActions()
          }}
          table={{ rowSelection: true, cols, ref: table, none: !loading && renderNone() }}
          prefs={organizerPreferences}
          prefsKey={organizerPreferencesKeys.providersTable}
          prefsVersion={PROVIDERS_TABLE_PREFS_VERSION}
          queryHook={useOrganizerProvidersQuery}
          queryOptions={{ variables: { siteId: site?.id } }}
          queryResponseHandler={response => ({items:response?.data?.site?.vendors})}
        />
      </OrganizerPage>
    );
  }

  function renderPrimaryActions() {
    return <Button icon="Plus" iconPosition='left' autoLoader={false} onClick={showAddProvider}>
      Add provider
    </Button>
  }

  function renderSecondaryActions() {
    return [
      <ActionButton selection={false} icon='User' onClick={copyProviderEmailsToClipboard}>Copy provider emails</ActionButton>,
      <ActionButton icon="XCircle" onClick={() => <DeleteProvidersModal providers={table.current.selectedItems} />}>Delete provider</ActionButton>
    ]
  }

  function renderNone() {
    return <VBox hAlign='center' gap='$16'>
      Add a provider to get started
      {renderPrimaryActions()}
      </VBox>;
  }

  function showProviderModal(event: React.MouseEvent, info: FieldInfo<Vendors>) {
    Modal.show(<ProviderModal vendor={info.record.id} />);
  }

  async function showAddProvider() {
    Modal.show<VendorInput>(<AddProviderModal site={site.id} />);
  }

  function copyProviderEmailsToClipboard() {
    const providers = table.current.allItems;
    copyEmails(providers.map(p => p.email));
  }

  function getCols() {
    const cols: DataTableColumn<Vendors>[] = [
      { label: 'Name (Nickname)', name: 'displayName', width: 250, component: Link, onClick: showProviderModal },
      { label: 'Email address', name: 'email', width: 250, component: EmailLabelField },
      { label: 'Phone number', name: 'phone', width: MIN_COL_WIDTH, component: PhoneLabelField }
    ];

    if (site?.usingCompliance) {
      cols.push({ label: 'Compliance', name: 'inCompliance', width: MIN_COL_WIDTH, format: getComplianceStatus });
    }

    return cols;
  }

  function getComplianceStatus(inCompliance: boolean, _:any, info:FieldInfo<Vendors>) {
    return !info.record.company 
      ? 'Provider has not finished setup' 
      : inCompliance 
        ? <FieldValue color='positive'>Complete</FieldValue> 
        : <FieldValue color='negative'>Incomplete</FieldValue>;
  }

  return render();
}