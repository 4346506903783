import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyCourseSummaryReportQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyCourseSummaryReportQuery = {
  companyCourseSummaryReport?:
    | {
        totalProviderEarnings?: number | null | undefined;
        data: Array<{ id: string; course: string; grades: string; period: string; site: string; startDate: string; endDate: string; providerEarnings?: number | null | undefined } | null | undefined>;
      }
    | null
    | undefined;
};

export const CompanyCourseSummaryReportDocument = gql`
  query CompanyCourseSummaryReport($company: ID!) {
    companyCourseSummaryReport(company: $company) {
      data {
        id
        course
        grades
        period
        site
        startDate
        endDate
        providerEarnings
      }
      totalProviderEarnings
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyCourseSummaryReportArgs = MakeOptional<UseQueryArgs<CompanyCourseSummaryReportQueryVariables, CompanyCourseSummaryReportQuery>, 'query'>;

export function useCompanyCourseSummaryReportQuery(options: CompanyCourseSummaryReportArgs = {}) {
  return useQuery<CompanyCourseSummaryReportQueryVariables, CompanyCourseSummaryReportQuery>({ query: CompanyCourseSummaryReportDocument, ...options });
}

export type CompanyCourseSummaryReportOptions = ExecQueryOptions<CompanyCourseSummaryReportQueryVariables>;

export function companyCourseSummaryReport(options: CompanyCourseSummaryReportOptions) {
  return executeQuery<CompanyCourseSummaryReportQuery, CompanyCourseSummaryReportQueryVariables>(CompanyCourseSummaryReportDocument, options);
}

export type CompanyCourseSummaryReportSelections = CompanyCourseSummaryReportQuery['companyCourseSummaryReport'];
