import * as React from 'react';

import { Checkbox, CheckboxGroup, Field, HBox, Input, InputField, Form, VBox, Section, Subtitle2, Icon } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function CheckboxDemos() {
  return <Demos name='Checkbox'
    description={`      
      Checkbox supports both a single checkbox and when there are controls associated with the checkbox.

      - Use the label property to set the checkbox label.  Do not use children.
      
      - Use the children property to place controls underneath (and indented) that are associated with the checkbox.

      - You can provide non-text React elements for the label.
      `}>
    {renderCheckboxVariations()}
    <Demo name='With children'>
      <Checkbox label='Submit'>
        <Input />
      </Checkbox>
    </Demo>
    <Demo name='With components as a label'>
      <Checkbox label={<><Icon name='CreditCard' /> Credit card</>}/>
    </Demo>
    <Demo name='Checkbox group, controlled'
    description={`
      CheckboxGroup allows specifying a list of options as an array.
    `}>
      <CheckboxGroup selected='19-30' options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>
    <Demo name='Checkbox group with inputs' description='Any option can have an input control'>
      <Form>
        <Section component={CheckboxGroup} name='ageRange' options={[
          {label:'0-18', value:'0-18'},
          {label:'19-30', value:'19-30'},
          {label:'30-50', value:'30-50'},
          {label:'51+', value:'51+'},
          {label:'Other', value:'other', input: <Field name='other' component={InputField} />}
        ]} />
      </Form>
    </Demo>
    <Demo name='Checkbox group, disabled'>
      <CheckboxGroup disabled options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>
    <Demo name='Checkbox group, error'>
      <CheckboxGroup error options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>    
    <Demo name='On a background'>
      <VBox padding='50px' bg='red'>
        <Checkbox label='Submit' />
      </VBox>
    </Demo>
    <Demo name='Various styles'>
      <Checkbox m='$10' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer' label='Submit' />
    </Demo>
    <Demo name='Custom CSS'>
      <Checkbox css={{':hover':{border: 'solid 10px red!important'}}} label='Move the mouse over me'/>
    </Demo>
    <Demo name='Using theme props'>
      <Checkbox fontSize='heading2' bg='brandLight' label='Submit' />
    </Demo>
  </Demos>
}

function renderCheckboxVariations() {
  return <>
    <Demo name='Basic'>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled</Subtitle2>
        <Checkbox label='Checkbox' />
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, checked</Subtitle2>
        <Checkbox checked label='Checkbox'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, error</Subtitle2>
        <Checkbox error label='Checkbox' />
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, checked, error</Subtitle2>
        <Checkbox checked error label='Checkbox'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Disabled</Subtitle2>
        <Checkbox disabled label='Checkbox'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Disabled</Subtitle2>
        <Checkbox disabled checked label='Checkbox'/>
      </HBox>
    </Demo>
  </>
}
