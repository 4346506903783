import * as React from 'react';

import { List, Option, Text } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ListDemos() {
  const [value, setValue] = React.useState<string[]>([]);

  return <Demos name='List'>
    <Demo name='Basic'>
      <List options={options} width={200} />
    </Demo>

    <Demo name='Multiple'>
      <List options={options} width={200} multiple />
    </Demo>

    <Demo name='Controlled'>
      <List options={options} width={200} multiple value={value} onChange={event => setValue(event.currentTarget.value as string[])} />
      You selected: {value.map(value => options.find(option => option.value == value).label).join(', ')}
    </Demo>

    <Demo name='Custom renderer'>
      <List width={200} options={options.map(option => {
        return {
          value: option.value, 
          text: option.label as string, 
          label: <Text width='100%' height='100%' display='inline-block' text='subtitle1' border='solid 1px #00000000' css={{
            ':hover': {
              border: 'solid 1px red'
            }}}>{option.label}</Text>
        }
      })}/>
    </Demo>
  </Demos>
}

const options = [
  {label:'abc'}, 
  {label:'123-a'}, 
  {label: 'xyz'}, 
  {label: '456'}, 
  {label: 'abc'}, 
  {label: '123-c'}, 
  {label: 'xyz'}, 
  {label: '456'},
  {label: 'abc'}, 
  {label: '123-b'}, 
  {label: 'xyz'}, 
  {label: '456'}
] as Option[];

options.forEach((option:any, index) => option.value = index);
