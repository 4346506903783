import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteClassroomsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type SiteClassroomsQuery = { site?: { id: string; classrooms: Array<{ id: string; name: string; grade: string }> } | null | undefined };

export const SiteClassroomsDocument = gql`
  query SiteClassrooms($siteId: ID!) {
    site(site: $siteId) {
      id
      classrooms {
        id
        name
        grade
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteClassroomsArgs = MakeOptional<UseQueryArgs<SiteClassroomsQueryVariables, SiteClassroomsQuery>, 'query'>;

export function useSiteClassroomsQuery(options: SiteClassroomsArgs = {}) {
  return useQuery<SiteClassroomsQueryVariables, SiteClassroomsQuery>({ query: SiteClassroomsDocument, ...options });
}

export type SiteClassroomsOptions = ExecQueryOptions<SiteClassroomsQueryVariables>;

export function siteClassrooms(options: SiteClassroomsOptions) {
  return executeQuery<SiteClassroomsQuery, SiteClassroomsQueryVariables>(SiteClassroomsDocument, options);
}

export type SiteClassroomsSelections = SiteClassroomsQuery['site'];
