import * as React from 'react';
import { O } from 'ts-toolbelt'
import moment from 'moment';

import { Refund } from 'app2/api';
import { BoxProps, CardTable, Icon, formatCurrency, longDateFormatter, VBox } from 'app2/components';

type RefundSelections = O.P.Pick<Refund, ['amount' | 'createdAt' | 'remainder' | 'pending' | 'description' | 'enrollment' | 'initiatedBy', 'name' | 'course' | 'billPeriod', 'name' | 'disambiguatedName']>;

interface Props {
  refunds: RefundSelections[];
  box: BoxProps;
  showBillPeriod?: boolean;
}

export function RefundTable(props: Props) {
  const labels = ['Amount', 'Activity', 'Date issued', 'By', 'Remainder'];

  if (props.showBillPeriod) {
    labels.push('Billing period');
  }

  function render() {
    return (
      <VBox {...props.box}>
        <CardTable labels={labels} rows={renderRows()} none="This student hasn't been refunded." />
      </VBox>
    );
  }

  function renderRows() {
    return props.refunds.
      sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).
      map(r => [
      <>{formatCurrency(r.amount)}{r.pending ? ' (pending)' : ''}{r.description ? <Icon name='Info' size='small' ml='$4' tooltip={r.description} /> : ''}</>, 
      r.enrollment.course.disambiguatedName || r.enrollment.course.name, 
      longDateFormatter(r.createdAt), 
      r.initiatedBy.name,
      formatCurrency(r.remainder),
      r.enrollment.billPeriod
    ]);
  }

  return render();
}

RefundTable.defaultProps = {
  refunds: [],
  box: {}
};
