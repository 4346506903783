import * as React from 'react';
import { get, set } from 'lodash-es';

import { CfStage, CfQuestion, CfAnswer, CfAnswerInput } from 'app2/api';
import { Repeater, Field, FormProps, Form, FormModel, useFormInfo, FormSubmitHandler } from 'app2/components';
import { CfQuestionInput, answerId } from './CfQuestionInput'

// this assumes its being used in a form, and the current parent
// is the student who this form is for
interface Props extends Omit<FormProps<CfQuestionsAndAnswers>, 'onOk' | 'initialValues'> {
  stage:CfStage;
  questions:Partial<CfQuestion>[];
  answers:Partial<CfAnswer>[];
  onOk:(answers:CfAnswerInput[]) => FormSubmitHandler;
  // allows overriding required so things are not required for organizers
  overrideRequired?:boolean;
}

export function CfStudentForm(props:Props) {
  const {stage, questions, answers, onOk:propsOnOk, overrideRequired, ...remaining} = props;
  const info = useFormInfo();
  info.form.indexPaths = true;

  const dupeTitles = React.useMemo(() => findDuplicateTitles(props.questions), [props.questions]);
  const qAndA = React.useMemo(() => ({cfQuestions: questionsForStage(questions, stage), cfAnswers: convertServerAnswers(answers)}), [questions, answers]);

  function render() {
    return <Form initialValues={qAndA} onOk={onOk} {...remaining} editing={info.editing}>
      <Repeater name='cfQuestions'>
        <Field component={CfQuestionInput} mb='$10' overrideRequired={overrideRequired} none={false} dupeTitles={dupeTitles} />
      </Repeater>
    </Form>
  }

  function onOk(form:FormModel<CfQuestionsAndAnswers>) {
    const answers = convertClientAnswers(form);
    return propsOnOk?.(answers);
  }

  // server sends back answers that are aggregated on season or course
  // but we want them separated by question to work with our forms

  function convertServerAnswers(answers:Partial<CfAnswer>[]) {
    return answers?.reduce((answers, answer:Partial<CfAnswer>) => {
      for (const q of Object.keys(answer.answers || {})) {
        set(answers, q + '.answer', answer.answers[q].answer)
      }
      return answers;
    }, {} as CfQuestionsAndAnswers['cfAnswers'])
  }

  function convertClientAnswers(form:FormModel<CfQuestionsAndAnswers>) {
    const answers:CfAnswerInput[] = [];

    form.values.cfQuestions.forEach(q => {
      let ownerAnswers = answers.find(a => a.ownerId == q.ownerId && a.ownerType == q.ownerType);

      if (!ownerAnswers) {
        ownerAnswers = {ownerId: q.ownerId, ownerType: q.ownerType, answers:{} as any};
        answers.push(ownerAnswers);  
      }

      ownerAnswers.answers['q' + q.id] = {answer:get(form.values.cfAnswers, answerId(q))}
    })

    return answers;
  }

  return render();
}

export interface CfQuestionsAndAnswers {
  cfQuestions:Partial<CfQuestion>[];
  cfAnswers?:{[question: string]:{answers: any}};
}

export function questionsForStage(questions:Partial<CfQuestion>[], viewing:CfStage) {
  return questions?.filter(q => questionInStage(q, viewing));
}

export function questionInStage(question:Partial<CfQuestion>, viewing:CfStage) {
  return viewing == question.stage || viewing == CfStage.Later;
}

function findDuplicateTitles(items: Partial<CfQuestion>[]): Record<string, true> {
  const nameMap: Record<string, Partial<CfQuestion>[]> = {};

  items.forEach(item => {
    if (nameMap[item.title]) {
      nameMap[item.title].push(item);
    } else {
      nameMap[item.title] = [item];
    }
  });

  const duplicates: Record<string, true> = {};

  for (const name in nameMap) {
    if (nameMap[name].length > 1) {
      duplicates[name] = true;
    }
  }

  return duplicates;
}