import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChangeSeasonPlanMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  kind: Types.PriceConfigKind;
  recurring?: Types.InputMaybe<Types.RecurringPriceConfigInput>;
  dropIn?: Types.InputMaybe<Types.DropInPriceConfigInput>;
  configurableSeason?: Types.InputMaybe<Types.SeasonPriceConfigInput>;
  discount?: Types.InputMaybe<Types.DiscountInput>;
  discountId?: Types.InputMaybe<Types.Scalars['ID']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ChangeSeasonPlanMutation = { enrollmentsChangeSeasonPlan?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ChangeSeasonPlanDocument = gql`
  mutation ChangeSeasonPlan(
    $courseId: ID!
    $ids: [ID!]!
    $kind: PriceConfigKind!
    $recurring: RecurringPriceConfigInput
    $dropIn: DropInPriceConfigInput
    $configurableSeason: SeasonPriceConfigInput
    $discount: DiscountInput
    $discountId: ID
    $description: String
  ) {
    enrollmentsChangeSeasonPlan(
      courseId: $courseId
      ids: $ids
      kind: $kind
      recurring: $recurring
      dropIn: $dropIn
      configurableSeason: $configurableSeason
      discount: $discount
      discountId: $discountId
      description: $description
    ) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ChangeSeasonPlanOptions = ExecMutationOptions<ChangeSeasonPlanMutationVariables>;

export function changeSeasonPlan(options: ChangeSeasonPlanOptions) {
  return executeMutation<ChangeSeasonPlanMutation, ChangeSeasonPlanMutationVariables>(ChangeSeasonPlanDocument, options);
}
