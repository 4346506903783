import * as React from 'react-dom';

import { ObserverAdapter } from '../../observable/ObserverAdapter';
import { ReactObserver } from './ReactObserver';

export class ReactObserverAdapter implements ObserverAdapter {
  onUpdate(observers: Set<ReactObserver>): void {
    observers.forEach(observer => {
      React.unstable_batchedUpdates(() => {
        try {
          observer.forceUpdate();
        } catch (e) {
          console.error('Error during observer update', e);
        }
      });
    });
  }
}
