export type Logger = Pick<Console, 'log' | 'warn' | 'error'>;

export interface LoggerContext {
  logger:Logger;
}

class NoOpLogger {
  log(message?: any, ...optionalParams: any[]): void {
  }

  warn(message?: any, ...optionalParams: any[]): void {
  }

  error(message?: any, ...optionalParams: any[]): void {
  }
}

const dev = process.env.NODE_ENV === 'development';
export const logger = dev ? console : new NoOpLogger();
