import * as React from 'react';

import { siteFolder } from 'app/helpers';
import { CloudinaryPhotoField, FormModel, Panel, Section } from 'app2/components'
import { Beta } from 'app2/views/shared-public';

import { useCurrentSite } from '../shared';
import { organizerUpdateBranding } from './gql'

const SITE_LOGO_WIDTH = 850;
const SITE_LOGO_HEIGHT = 500;

export function Branding() {
  const { site } = useCurrentSite();

  function render() {
    return <Beta position='top'>
      <Panel title="Branding" type='toggle' onOk={onOk} initialValues={site}>
        {renderLogo()}
      </Panel>
    </Beta>
  }

  function renderLogo() {
    return <Section name='branding.logo' label='Logo' options={getLogoOptions()} width={SITE_LOGO_WIDTH}  component={CloudinaryPhotoField} />
  }

  function getLogoOptions() {
    return React.useMemo(() => {
      return {
        uploadPreset: 'site_logo',
        folder: siteFolder(site?.id),
        signatureUrl: `/sites/logo_signature/${site?.id}`,
        customSignatureParams: {site_id: site?.id},
        cropping: false
      };
    }, [site?.id]);
  }

  async function onOk(form:FormModel) {
    const variables = { siteId: site.id, branding: form.values.branding};
    const [success] = await organizerUpdateBranding({variables, successMsg: "Site information updated successfully.", error:{handler: form}})
    return success;
  }


  return render()
}

const  courseImage = {
  name: 'courseImage',
  label: 'Background image',
  disabled: false
}
