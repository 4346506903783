import { cloneDeep } from 'lodash-es';

import { Command } from "../../undo";
import { Point } from "../../dom-utils";
import { FormModel } from "../../form";

import { DataTable, Selection, colId } from "..";
import { ObservableCollectionCommand, CollectionEvent, CollectionEventType } from "../collection";

export class PasteTableCommand<T> implements Command, ObservableCollectionCommand<T> {

  table:DataTable;
  selection:Selection;
  values:any[][];
  oldValues:T[];
  addedRows:number;

  constructor(table:DataTable, values:any[][], selection?:Selection) {
    this.table = table;
    this.selection = (selection || table.selection).clone();
    this.values = values;
  }

  get isCollectionCommand() {
    return true;
  }

  get collectionEvents():CollectionEvent<T>[] {
    return this.selection.rows.map(row => {
      return {
        collection: this.table.data,
        type: CollectionEventType.update,
        position: row,
        id: this.table.posToIds(new Point(0, row)).rowId,
        item: this.table.data.getItem(row)
      }
    })
  }

  focus():void {
    this.table.focus();
    this.table.setSelection(this.selection, true);
  }

  createDesitinationSelection(selection:Selection) {
    if (!selection.singleCellSelected) {
      return selection.clone();
    }

    const tl = selection.topLeft;
    const br = tl.clone().offset(Math.max(this.values[0].length - 1, 0), this.values.length - 1);

    return new Selection(this.table, tl, br);
  }

  do():void {
    this.doOrRedo(this.selection);
  }

  async doOrRedo(selection:Selection) {
    // if the paste source range doesn't match the table selection range, paste:
    // - clips if the range is smaller
    // - repeats if the range is larger
    // - expands the range if its just one cell

    this.addNeededRows(selection);

    selection = this.createDesitinationSelection(selection);
    this.oldValues = selection.items.map(item => cloneDeep(item));
    await this.setValues(selection, this.getValues(selection));
  }

  async undo() {
    const selection = this.createDesitinationSelection(this.selection);
    
    this.oldValues.forEach((item, index) => this.table.restore(selection.topLeft.row + index, item));
    this.removeAddedRows(selection);
  }

  redo():void {
    this.doOrRedo(this.selection);
  }

  addNeededRows(selection:Selection) {
    if (!this.table.props.appendable) {
      return
    }

    selection = this.createDesitinationSelection(selection);

    let rowsToAdd = this.addedRows = Math.max(0, this.values.length - selection.numRows);

    while (rowsToAdd > 0) {
      // can't use append here because we don't want to create undo entries
      // for each row that is added 
      const item = cloneDeep(this.table.defaultRecord) as T;
      this.table.editableData.insert(this.table.data.length, item);
      --rowsToAdd;
    }
  }

  removeAddedRows(selection:Selection) {
    if (this.addedRows) {
      let addedRows = this.addedRows;

      while (addedRows) {
        this.table.editableData.remove(this.table.editableData.length - 1);
        --addedRows;
      }

      // after removing the rows we need to shrink the selection
      selection = this.createDesitinationSelection(selection);
      this.table.selection = selection.makeValid();
    }
  }

  getValues(selection:Selection) {
    const r = selection.normalizedRect;
    const values:any[][] = [];

    for (let rowPos = r.top; rowPos <= r.bottom; ++rowPos) {
      const row:any[] = [];
      values.push(row)

      for (let colPos = r.left; colPos <= r.right; ++colPos) {
        const valueRowPos = (rowPos - r.top) % this.values.length;
        const valueColPos = (colPos - r.left) % this.values[valueRowPos].length;
        row.push(this.values[valueRowPos][valueColPos]);
      }
    }

    return values;
  }

  async setValues(selection:Selection, values:any[][]) {
    const table = this.table;
    const data = table.editableForm;
    const r = selection.normalizedRect;
    const parseRow = table.props.parseRow;

    for (let rowPos = r.top; rowPos <= r.bottom; ++rowPos) {
      let orderedValues:NameValuePair<T>[] = [];

      for (let colPos = r.left; colPos <= r.right; ++colPos) {
        const col = table.cols[colPos];
        const value = values[rowPos - r.top][colPos - r.left];

        // we don't check readonly or disabled because a)
        // the form row should do that, b) the ability to
        // paste into some columns might be dependent on
        // pasting data into other columns
        orderedValues.push({id: colId(col) as keyof T, value});
      }

      orderedValues = await parseRow(table, data.getForm(rowPos), orderedValues);

      const orderedValuesMap = {} as any;

      for (let nameAndValue of orderedValues) {
        const colPos = table.getColumnIndex(nameAndValue.id as string);

        if (colPos == -1) {
          continue;
        }

        const field = table.getFieldForCopyPaste(rowPos, colPos, true);
        const paste = field.paste || field.parse || ((value:any) => value);
        const info = data.getInfo([rowPos], field.name);
        const value = await paste(nameAndValue.value, field, info);

        if (field.names) {
          for (const name of field.names) {
            orderedValuesMap[name] = value[name];
          }
        }
        else {
          orderedValuesMap[field.name] = value;
        }
      }

      data.setValues(rowPos, orderedValuesMap, {touch: true});
    }

    this.table.selection = selection.clone();
  }
}

export type NameValuePair<T> = {id:keyof T, value:any};
export type ParseValues<T> = (table:DataTable<T>, form:FormModel<T>, values:NameValuePair<T>[]) => NameValuePair<T>[] | Promise<NameValuePair<T>[]>;

export async function setValuesNoop<T = any>(table:DataTable<T>, form:FormModel<T>, values:NameValuePair<T>[]) {
  return values;
}

export function orderedParseValues<T>(fields:(keyof T)[]) {
  const positionMap = new Map();

  for (let pos = 0; pos < fields.length; ++pos) {
    positionMap.set(fields[pos], pos);
  }

  return function(table:DataTable<T>, form:FormModel<T>, values:NameValuePair<T>[]) {
    return values.sort((a, b) => {
      const posA = positionMap.get(a.id) ?? Number.MAX_SAFE_INTEGER;
      const posB = positionMap.get(b.id) ?? Number.MAX_SAFE_INTEGER;

      return posA - posB;
    });
  }
}
