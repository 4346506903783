import * as React from 'react';

import { Company } from 'app2/api'
import { Link, LinkProps, FieldInfo } from 'app2/components'

type Company1 = Pick<Company, 'slug' | 'name'>;
type Company2 = Pick<Company, 'id' | 'name'>;

interface Props extends LinkProps {
  info?:FieldInfo<Company>;
  company?:Company1 | Company2;
}

export function PublicCompanyLink(props:Props) {
  const {info, company:companyProp, ...remaining} = props;
  const company = companyProp || info?.record;

  return <Link className='hr-value' text='body' to={`/provider/${(company as Company1)?.slug || (company as Company2)?.id}/about`} alignItems='left' {...remaining}>{remaining.children || company?.name}</Link>
}

PublicCompanyLink.fieldProps = {
  fieldProperty: 'info',
}
