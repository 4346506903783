import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type OptionFieldsFragment = { label: string; value?: string | number | null | undefined };

export const OptionFieldsFragmentDoc = gql`
  fragment optionFields on Option {
    label
    value
  }
`;
