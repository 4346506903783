import * as React from 'react';
import Helmet from 'react-helmet';

import brandmark from 'images/brandmark.svg';

export interface HeadProps {
  title: string;
  description: string;
  image?: string;
}

export function Head(props: HeadProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:url" content={document.location.href} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image || brandmark} />
    </Helmet>
  );
}
