import * as React from 'react';

import { Img, ImgProps } from '../Tags';

import { CloundinaryUploaderOptions } from './CloudinaryUploader';
import { buildCloudinaryImageProps } from './utils';

export interface CloudinaryPhotoProps extends ImgProps {
  options?:Partial<CloundinaryUploaderOptions>;//ignored, but here to make it more compatible with CloudinaryPhotoInput
  src?:string;//use src or value, but not both
  defaultImage?:string;
  width?:number;
  // imageWidth is alternative prop so you avoid collisions with box width
  imageWidth?:number;
  resolution?:number;
  ratio?:number;
  borderRadius?:number;
  search?:any;//ignored, but here to make it more compatible with CloudinaryPhotoInput
  imageId?:string;//ignored, but here to make it more compatible with CloudinaryPhotoInput
}

export function CloudinaryPhoto(props:CloudinaryPhotoProps) {
  const {options, src, defaultImage, width, imageWidth, resolution, ratio, borderRadius, search, imageId, ...remaining} = props;

  return <Img maxWidth='100%' flex='unset' {...buildCloudinaryImageProps(src || defaultImage, ratio, width || imageWidth, resolution, borderRadius)} {...remaining} />
}

CloudinaryPhoto.displayName = 'CloudinaryPhoto'

CloudinaryPhoto.fieldProps = {
  valueProperty: 'src'
}