import * as React from 'react'

import { HBox } from '../Box';
import { Link, LinkProps } from '../Link';
import { Icon } from '../icons';

export interface MenuItemProps extends LinkProps {
  label?:React.ReactNode;
  selected?:boolean;
  hidden?:boolean;
}

export function MenuItem(props:MenuItemProps) {
  const {label, selected, hidden, children, ...remaining} = props;

  if (hidden) {
    return <></>;
  }

  function render() {
    return <HBox vAlign='center' position='relative'>
      {renderContent()}
    </HBox>
  }

  function renderContent() {
    const check = selected ? <Icon ml='8px' size='small' name='Check' /> : undefined;

    return <><Link text='subtitle2' fontWeight='normal' underline={false} blurOnClick {...remaining}>{label || children}</Link>{check}</>
  }

  return render();
}
