function getName<T extends {name?:string}>(item:T) {
  return item.name;
}

export function createOptions<T extends {name?:string}>(data:T[], nameFn:(item:T) => string = getName) {
  if (!data) {
    return [];
  }

  return data.map((item:T) =>({value: item, label: nameFn(item) }));
}
