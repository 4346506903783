import * as React from 'react';
import * as ReactIs from 'react-is';

import { Box, BoxProps } from './Box';
import { Field, FieldProps, InputField } from './form';
import { Part } from './panel';
import { Option, OptionValue, SelectOptionInterface, useSelectOptions } from './Option';
import { Checkbox } from './Checkbox';
import { createOrClone } from './utils';

// CheckboxGroup functions only as controlled

interface OptionWithInput extends Option {
  input?:React.ReactElement<Field> | FieldProps<any, any, any>;
  inputName?:string;
}

interface Props extends BoxProps {
  onChange?:React.ChangeEventHandler<SelectOptionInterface>;
  options:OptionWithInput[];
  selected?:OptionValue[] | OptionValue;
  disabled?:boolean;
  error?:boolean;
  component?:React.ComponentType<any> | React.ReactElement<any>;
}

export const CheckboxGroup = React.forwardRef((props: Props, ref:any) => {
  const {onChange:onChangeProp, options:optionsProp, selected:selectedProp, disabled, error, component, ...remaining} = props;
  const {options, isSelected, onChange} = useSelectOptions(true, props, ref);

  function render() {
    return <Box vItemSpace={props.layout != 'hbox' ? '$16' : undefined} hItemSpace={props.layout == 'hbox' ? '$16' : undefined} {...remaining}>
      {options?.map((option, index) => renderCheckbox(option, index))}
    </Box>
  }

  function renderCheckbox(option:OptionWithInput, index:number) {
    const {label, value, input, inputName, ...remaining} = option;

    return createOrClone(Checkbox, {key:index, label: option.label, checked: isSelected(option.value), disabled, error, onChange:(event:React.ChangeEvent<any>) => onChange(event, option.value), children: renderInput(option), ...remaining}, component);
  }

  function renderInput(option:OptionWithInput) {
    const otherSelected = isSelected(option.value);

    if (ReactIs.isElement(option.input)) {
      //@ts-ignore
      return React.cloneElement(option.input, {disabled: !otherSelected});
    }
    else 
    if (option.input) {
      return <Part disabled={!otherSelected} {...option.input} />
    }

    if (option.inputName) {
      return <Field component={InputField} name={option.inputName} disabled={!otherSelected} />
    }

    return;
  }

  return render();
})

//@ts-ignore
CheckboxGroup.fieldProps = {
  valueProperty: 'selected',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur',
}

CheckboxGroup.defaultProps = {
  layout: 'vbox',
  component: Checkbox,
}

CheckboxGroup.displayName = 'CheckboxGroup';

