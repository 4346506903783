import * as React from 'react';
import { Route as RRRoute, RouteProps as RRRouteProps, Redirect } from 'react-router-dom';

import { CompanyRole, CourseRole, OrganizerRole } from 'app2/api';

import { useScrollablePage } from './PageContext';
import { useRouteInfo } from './useRouteInfo';
import { mergeQueryParams } from './mergeQueryParams';

// only use member roles for teachers
export type RouteRole = 'parent' | 'admin' | CompanyRole | OrganizerRole | 'logged-in' | 'logged-out' | CourseRole;
export const companyOrOrganizerMember = [CompanyRole.CompanyMember, OrganizerRole.SiteMember];

export interface RouteProps extends RRRouteProps {
  // member roles assume admin roles.  so if you specify
  // CompanyRole.CompanyMember, it implies CompanyRole.CompanyAdmin as well
  role?:RouteRole[] | RouteRole;
  // where to redirect if the user is not authorized
  redirect?:string;
  beta?:boolean;
  // by default this is true
  // if true, it mean the page will manage the scrollability
  // of the content area and allow scrolling. 
  // if false, page will constrict the content pane to the height
  // of the visible area and the content can define its own scrolling area.
  scrollable?:boolean;
  // if true or undefined, the page will show the helpscout beacon
  helpscout?:boolean;
  // each route can specify content to be displayed in the header next to the account menu
  header?:React.ReactNode;
}

export function Route(props:RouteProps) {
  const {role, beta, scrollable, helpscout, header, ...remaining} = props;

  const { user, route, allowed, fetching } = useRouteInfo();
  useScrollablePage(scrollable);

  function render() {
    if (!allowed && !fetching) {
      return renderNotAuthorized();
    }

    if (!allowed && fetching) {
      return <></>;
    }

    return <RRRoute {...remaining} />
  }

  function renderNotAuthorized() {
    if (!user) {
      const current = window.location.pathname + window.location.search + window.location.hash;
      const to = new URL(window.location.origin + (route?.props?.redirect || '/login'));
      const params = mergeQueryParams(to, new URLSearchParams({redirect: current}));
      to.search = params.toString();

      return <Redirect to={to.pathname + to.search} />
    }
    else {
      return <Redirect to='/' />
    }
  }

  return render();
}
