import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MergeParentsMutationVariables = Types.Exact<{
  source: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
}>;

export type MergeParentsMutation = { parentsMerge?: { user?: { id: string } | null | undefined } | null | undefined };

export const MergeParentsDocument = gql`
  mutation MergeParents($source: ID!, $destination: ID!) {
    parentsMerge(source: $source, destination: $destination) {
      user {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MergeParentsOptions = ExecMutationOptions<MergeParentsMutationVariables>;

export function mergeParents(options: MergeParentsOptions) {
  return executeMutation<MergeParentsMutation, MergeParentsMutationVariables>(MergeParentsDocument, options);
}
