import * as React from 'react'

import { BoxProps, HBox } from './Box';
import { Icon, IconNames } from './icons';
import { Option } from './Option';
import { OptionText } from './OptionText';
import { useClickLoader } from './shield';

export interface TagProps extends BoxProps {
  optionOrLabel?:string | React.ReactNode | Option;
  label?:string | React.ReactNode;
  option?:Option;
  // this is the right side icon
  icon?:IconNames | false;
  size?:number;
  onCloseClick?:() => void;
  nowrap?:boolean;
  autoLoader?: boolean;
}

export function Tag(props:React.PropsWithChildren<TagProps>) {
  let {optionOrLabel, label, option, icon, size, onCloseClick, nowrap, color, children, autoLoader, ...remaining} = props;
  const { loading, clickLoaderHandler, loader } = useClickLoader(handleCloseClick);
  const handleClick = autoLoader ? clickLoaderHandler : handleCloseClick;

  if (optionOrLabel) {
    option = option || typeof optionOrLabel === 'object' ? optionOrLabel as Option : undefined;
    label = label || option ? option.tag || option.text || option.label : optionOrLabel
  }

  if (!onCloseClick) {
    icon = null;
  }

  function render() {
    // the icon is positioned absolute to it is center aligned with the first
    // line of text (not center aligned with the height of the tag) so to do
    // that we make the right side padding account of the absolute aligned tag

    return <HBox text='body' data-tag fontWeight='bold' color={color || 'primary'} bg='border' pl='4px' pr={(icon ? (8 + 14) : 0 + 4) + 'px'} py='2px' 
      borderRadius='standard' vAlign='center' cursor='pointer' position='relative' whiteSpace={nowrap ? 'nowrap' : undefined} {...remaining}>
      {option && !option.tag ? <OptionText option={option} /> : option?.tag || label || children}
      {icon && <Icon name={icon} size={size} ml='$8' color={color || 'primary'} position='absolute' top='6px' right='4px' onClick={handleClick} />}
    </HBox>
  }

  function handleCloseClick(event:React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();

    return onCloseClick?.();
  }

  return render();
}

Tag.defaultProps = {
  icon: 'X',
  size: 14
}

export const smallTagProps = {
  text:'formlabel' as BoxProps['text'], borderRadius: 'standard', px: '$12', py:'4.5px', width: 'fit-content', bg: 'border', minHeight: 'unset'
}