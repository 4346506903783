import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyImagesQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyImagesQuery = {
  company?:
    | {
        slug: string;
        profileBackground?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyImagesDocument = gql`
  query CompanyImages($company: ID!) {
    company(company: $company) {
      ...commonCompany
      slug
      profileBackground
      profilePhoto
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyImagesArgs = MakeOptional<UseQueryArgs<CompanyImagesQueryVariables, CompanyImagesQuery>, 'query'>;

export function useCompanyImagesQuery(options: CompanyImagesArgs = {}) {
  return useQuery<CompanyImagesQueryVariables, CompanyImagesQuery>({ query: CompanyImagesDocument, ...options });
}

export type CompanyImagesOptions = ExecQueryOptions<CompanyImagesQueryVariables>;

export function companyImages(options: CompanyImagesOptions) {
  return executeQuery<CompanyImagesQuery, CompanyImagesQueryVariables>(CompanyImagesDocument, options);
}

export type CompanyImagesSelections = CompanyImagesQuery['company'];
