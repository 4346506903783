import * as React from 'react';

import { Button } from 'app2/components';

import { CoursesQueryVars, SaveAndTransitionModal } from '../course';

import { saveCoursesTable, useCourseTableState } from '.';

interface Props {
  state:ReturnType<typeof useCourseTableState>;
  queryVars:Partial<CoursesQueryVars>;
}

export function SaveAndTransition(props:Props) {
  const { state } = props;

  function render() {
    const label = SaveAndTransitionModal.title(state.coursesCollection);

    if (!label) {
      return <></>;
    }

    return <Button kind='secondary' autoLoader onClick={onSaveTransition} clickEvent={`Click ${label}`}>{label}</Button>;
  }

  async function onSaveTransition() {
    return SaveAndTransitionModal.show(state.coursesCollection, saveAndTransition);
  }

  async function saveAndTransition() {
    const saveSuccess = await saveCoursesTable(props.state.table.current, props.queryVars, state, true);

    if (!saveSuccess) {
      return;
    }

    props.state.tabs.current?.setTab(0);
  }

  return render();
}
