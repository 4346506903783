import * as React from 'react'

import { InputProps } from './Input'
import { floatParser } from './form';
import { RestrictedInput } from './RestrictedInput';

export const PercentInput = React.forwardRef((props:InputProps, ref:any) => {
  return <RestrictedInput parse={floatParser} valid={val => Number(val) >= 0 && Number(val) <= 100} type="number" step="any" min={0} max={100} placeholder='0' {...props} />
})

PercentInput.displayName = 'PercentInput';