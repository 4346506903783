import { isImpersonatingUser } from './axios';

export function getUserFromResponse(data:any) {
  const impersonating = isImpersonatingUser();
  const homeroom = impersonating || data.roles?.indexOf('admin') != -1 || data.email?.endsWith('homeroom.com');

  return {
    id: data.id,
    name: data.name,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    tosAccepted: data.tos_accepted,
    impersonating,
    homeroom
  };
}
