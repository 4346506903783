import { GraphQLError } from 'graphql'
import { OperationResult, UseQueryState } from 'urql';
import { flatten } from 'lodash-es';

import { ErrorWithStringPath } from 'app2/api';

import { GraphQlErrorWithExtension } from './GraphQlErrorWithExtension';
import { HomeroomGraphQlError } from './HomeroomGraphQlError';
import { indexPathToIdPath } from './parsePaths';

type GraphQlErrors = (GraphQLError | HomeroomGraphQlError)[];
type GraphQlResponse = Partial<OperationResult> | UseQueryState<any, any>;

export function convertGraphQlErrors(response:GraphQlResponse):ErrorWithStringPath[] {
  if (!response) {
    return [];
  }
  
  const errors = processGraphQlErrors(response.error?.graphQLErrors, response.operation?.variables);

  if (response.error?.networkError) {
    errors.push({message: response.error.networkError.message, path:[]})
  }

  return errors;
}

function processGraphQlErrors(errors:GraphQlErrors, input:any):ErrorWithStringPath[] {
  if (!errors) {
    return [];
  }
  
  return flatten(errors.map(error => {
    return error.extensions?.code === undefined
      ? graphQlErrorToDisplayErrors(error)
      : homeroomApiErrorToDisplayError(error, input)
  }));
}

function graphQlErrorToDisplayErrors(error:GraphQLError | GraphQlErrorWithExtension) {
  const gqlError = error as GraphQlErrorWithExtension;
  return gqlError.extensions?.problems
    ? gqlError.extensions?.problems.map(p => {
      return {
        message: p.explanation,
        code: 'unknown',
        path: indexPathToIdPath(p.path, gqlError.extensions.value)
      }})
    : [{
      message: error.message,
      code: 'unknown',
      path: error.path?.map(s => s.toString()) || []
    }]
}

function homeroomApiErrorToDisplayError(error:HomeroomGraphQlError, input:any) {
  return {
    message: error.message,
    code: error.extensions.code,
    path: indexPathToIdPath(error.extensions.path || [], input)
  }
}

