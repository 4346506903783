import * as React from 'react'
import { flatten } from 'lodash-es';

import { FocusTracker } from '../dom-utils';
import { isPromise } from '../utils';

import { useShield } from './useShield'
import { ShieldConsumer } from './ShieldConsumer';
import { ShieldProvider } from './ShieldProvider';

// this is a hook that will wrap any callback invocation your
// component will make such that if a promise is returned
// to your invocation, a full screen shield/loader will be
// put up.  focus is automatically returned to what had it
// before the callback was invoked.

export function useCallbackShield(callback:(...args:any[]) => any) {
  const shield = useShield();
  const memoizedProxyCallback = React.useMemo(() => proxyCallback, [callback]);

  FocusTracker.trackFocus();

  function proxyCallback(...args:any[]) {
    const resultOrPromise = typeof callback == 'function' ? callback?.(...args) as any : callback;
    callbackResultShield(shield, resultOrPromise);

    return resultOrPromise;
  }

  return memoizedProxyCallback;
}

export function callbackResultShield(shield:ShieldConsumer | ShieldProvider, result:any) {
  const hasPromise = Array.isArray(result) ? (result = flatten(result)).filter(isPromise).length != 0 : isPromise(result);

  if (hasPromise) {
    // unique id for the shield call that ShieldProvider needs (ShieldConsumer does not)
    const id:any[] = [];
    shield.loader(true, id);
    
    (Promise as any).allSettled(result)
      .then((...args2:any[]) => {
        shield.loader(false, id);

        if (FocusTracker.prevFocus?.isConnected) {
          FocusTracker.prevFocus?.focus();
        }
      });
  }

  return result;
}
