export class Mutex<T> {
  private promise:Promise<T> = Promise.resolve(undefined as T);

  async withLock(fn: () => Promise<T>): Promise<T> {
    const current = this.promise.then(fn);

    this.promise = current.catch(() => undefined as T);
    
    return current;
  }
}
