import * as React from 'react'

import { useLifecycle } from '../utils'

import { ScrollWatcher, ScrollWatcherOptions } from './ScrollWatcher'

export function useScrollWatcher(element:HTMLElement, options:ScrollWatcherOptions) {
  // need to save the option because our call backs will be memoized
  // and have the original options in the closure
  const args = React.useRef({options, element});
  args.current = {options, element};

  const [scrollWatcher] = React.useState(() => new ScrollWatcher({...options, onResize, onScroll}));

  useLifecycle({onUnmount: () => {
    scrollWatcher.unwatch();
  }});

  function onResize() {
    if (!args.current.element) {
      return;
    }

    (args.current.options.onResize || args.current.options.onScroll)();
  }

  function onScroll() {
    if (!args.current.element) {
      return;
    }

    args.current.options.onScroll();
  }

  if (scrollWatcher.element != element) {
    scrollWatcher.watch(element);
  }

  return scrollWatcher;
}