import * as React from 'react'

import { SafeStripeProvider, StripeContext, StripeElements } from 'app/scriptLoading'
import { Form, Modal, MultiContext, Link, Info, useForm } from 'app2/components';

import { addCreditCard  } from './utils';
import { CardForm } from './CardForm';
import { CardModel } from './CardModel';

export function CardModal() {
  const stripe = React.useContext<StripeContext>(MultiContext).stripe;
  const form = useForm<CardModel>({}, []);

  function render() {
    return <Modal title="Add credit card">
      <Form form={form} editing onNavigation='nothing' onOk={onSaveCard}>
        <CardForm />
        {!stripe && renderStripeUnavailable()}
      </Form>
    </Modal>
  }

  function renderStripeUnavailable() {
    return <Info type='error'>Unable to load payment service, please reload the page or contact <Link email='hello@homeroom.com' /></Info>
  }

  async function onSaveCard() {
    const [success] = await addCreditCard({ name: form.values.newCard.name, stripe, error: form, updateCartPaymentService: true })

    return success;
  }

  return render();
}

export async function showCardModal() {
  const result = await Modal.show(<SafeStripeProvider><StripeElements><CardModal /></StripeElements></SafeStripeProvider>);

  return result.action;
}