import * as React from 'react'
import { useParams } from 'react-router';

import { MessageServiceKind } from 'app2/api';
import { Img, Link, VBox } from 'app2/components';
import { ThreadComposeState } from 'app2/views/shared'
import conversation from 'app2/views/shared/messages/create/conversation.png';

import { PublicCourseParams } from '../publicRoutes';

import { ParentCourseSelections, PublicCourseSelections } from './generated';

interface Props {
  course:PublicCourseSelections;
  parentCourse:ParentCourseSelections;
}

export function CourseMessagingActions(props:Props) {
  if (!props.parentCourse?.optionParents?.length) {
    return <></>;
  }

  const company = {...props.course.company, type:'Company'};
  const state:Partial<ThreadComposeState> = {
    replyAll: true,
    hideReplyAll: true,
    service: MessageServiceKind.Email,
    hideService: true
  };

  const { id } = useParams<PublicCourseParams>();
  const compose = `/activities/${id}/messages/compose#threads`;

  return <VBox gap='$8'>
    <Link key='contact' button='primary' icon='Mail' iconPosition='left' to={compose} state={{...state, to: [company]}}>Contact provider</Link>
    {/* <Link key='message' button='secondary' to={compose} state={{...state, to: props.parentCourse?.parents}}><Img src={conversation} width='18px' ml='$8' />Connect with activity families</Link> */}
  </VBox>
}
