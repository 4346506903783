import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUpsertStudentMutationVariables = Types.Exact<{
  attributes: Types.StudentInput;
}>;

export type ParentUpsertStudentMutation = { studentUpsert?: { student?: { id: string } | null | undefined } | null | undefined };

export const ParentUpsertStudentDocument = gql`
  mutation ParentUpsertStudent($attributes: StudentInput!) {
    studentUpsert(attributes: $attributes) {
      student {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentUpsertStudentOptions = ExecMutationOptions<ParentUpsertStudentMutationVariables>;

export function parentUpsertStudent(options: ParentUpsertStudentOptions) {
  return executeMutation<ParentUpsertStudentMutation, ParentUpsertStudentMutationVariables>(ParentUpsertStudentDocument, options);
}
