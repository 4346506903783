import moment from 'moment'
import { startCase } from 'lodash-es';

import { CourseDay } from "../graphql";

import { DAY_SORT_KEY, WEEKDAYS_SET, WEEKDAYS, WEEKDAYS_ABBR } from './constants';
import { CourseDayName } from './CourseDayName';

export const COURSE_TIME_FORMAT = 'h:mm A'

export class CourseDayUtils {
  static isValid(day:CourseDay) {
    return day.days.filter(day => WEEKDAYS_SET.has(day as CourseDayName)).length && CourseDayUtils.isValidTime(day.start) && CourseDayUtils.isValidTime(day.finish);
  }

  static isValidTime(time:string) {
    const m = moment(time, COURSE_TIME_FORMAT);

    return m.isValid();  
  }

  static parseCourseDay(day:string) {
    day = startCase(day.trim());

    const pos = WEEKDAYS_ABBR.indexOf(day);

    if (pos == -1) {
      return day;
    }

    return WEEKDAYS[pos];
  }

  // TODO cleanup the old usage that is using time not days and is very confusing
  // so confusing that i didnt want to change the names and copied and created new functions

  static compareCourseDays2(a:Partial<CourseDay>[], b:Partial<CourseDay>[]):number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareCourseDayTime(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static compareCourseDayTime(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    return CourseDayUtils.compareWeekdays(a?.days, b?.days) || CourseDayUtils.compareTime(a?.start, b?.start)
  }

  static compareCourseDays(a:Partial<CourseDay>[], b:Partial<CourseDay>[]):number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareCourseDay(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static compareCourseDay(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    return CourseDayUtils.compareCourseDayPrioritizeTime(a, b);
    //return CourseDayUtils.compareCourseDayPrioritizeDay(a, b);
  }

  static compareCourseDayPrioritizeTime(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    const result = CourseDayUtils.compareTime(a?.start, b?.start)
  
    return result
      ? result
      : CourseDayUtils.compareWeekdays(a?.days, b?.days)
  }

  static compareCourseDayPrioritizeDay(a:CourseDay, b:CourseDay):number {
    const result = CourseDayUtils.compareWeekdays(a?.days, b?.days);
  
    return result
      ? result
      : CourseDayUtils.compareTime(a?.start, b?.start)
  }

  static compareTime(a:string, b:string) {
    return (moment(a, 'hh:mm a').valueOf() - moment(b, 'hh:mm a').valueOf())
  }

  static compareWeekdays(a: string[], b: string[]): number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareDays(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static sortCourseDays(courseDays:CourseDay[]) {
    return (courseDays || []).slice().sort(CourseDayUtils.compareCourseDay);
  }  
  
  static compareDays(a: string, b: string): number {
    if (!a && !b) {
      return 0;
    }
  
    if (!a) {
      return -1;
    }
  
    if (!b) {
      return 1;
    }
  
    return DAY_SORT_KEY[a.toLowerCase()] - DAY_SORT_KEY[b.toLowerCase()];
  }
  
  static sortDays(days:string[]) {
    return days.slice().sort(this.compareDays);
  }
}
