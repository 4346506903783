import * as React from 'react'
import { useLifecycle } from '.';

export interface ForceUpdate {
  ():void;
  counter:number;
}

export function useForceUpdate():ForceUpdate {
  const counter = React.useRef(1);
  const [_, setCounter] = React.useState(0);
  const mounted = useLifecycle();
  
  function update() {
    ++counter.current;

    if (!mounted.current) {
      return;
    }
    
    setCounter(counter.current);
  }

  update.counter = counter.current;

  return update;
}
