import { getSymbol } from './util/getSymbol';

import { observableManager } from './ObservableManager';
import { makeObservable } from './ObservableVisitor';

const observableSymbol: any = getSymbol('observable');
const observableTargetSymbol: any = getSymbol('observableTarget');

export class ObservableArray<T> extends Array<T> {
  constructor(numberOrArray?: T[] | number) {
    const isNumber = typeof numberOrArray === 'number';
    const isArray = !isNumber && Array.isArray(numberOrArray);
    const length: number = isNumber ? (numberOrArray as number) : 0;
    const source: T[] = isArray ? (numberOrArray as T[]) : undefined;

    super(length);

    const observable = new Proxy(this, handler);

    //@ts-ignore
    this[observableSymbol] = observable;

    //@ts-ignore
    this[observableTargetSymbol] = this;

    if (source && source.length) {
      // note this will not call our derived version probably because still in the constructor
      this.push(...source.map(o => makeObservable(o)));
    }

    // prior versions of webpack compilation were not properly
    // having observable array instances constructor properly
    // set to the observable array constructor.  in newer compilations
    // this is appears fixed, but it revealed issues in code
    //  comparing observable arrays with arrays (in fast-deep-equal)
    // which now fail because they have different constructors.
    // to preserve backwards compatibility we reset the constructor
    // back to an array.
    this.constructor = Array;

    return observable;
  }

  private makeObservable(args: any[]): any[] {
    return args.map(o => makeObservable(o));
  }

  concat(...args: any[]) {
    observableManager.onObserved(this);

    return super.concat.apply(this, this.makeObservable(args));
  }

  copyWithin(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.copyWithin.apply(this, args);
  }

  entries(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.entries.apply(this, args);
  }

  every(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.every.apply(this, args);
  }

  fill(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.fill.apply(this, this.makeObservable(args));
  }

  filter(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.filter.apply(this, args);
  }

  find(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.find.apply(this, args);
  }

  findIndex(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.findIndex.apply(this, args);
  }

  flat(...args: any[]): T[] {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.flat.apply(this, args);
  }

  forEach(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.forEach.apply(this, args);
  }

  includes(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.includes.apply(this, args);
  }

  indexOf(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.indexOf.apply(this, args);
  }

  join(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.join.apply(this, args);
  }

  keys(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.keys.apply(this, args);
  }

  lastIndexOf(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.lastIndexOf.apply(this, args);
  }

  //@ts-ignore
  map(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.map.apply(this, args);
  }

  pop(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.pop.apply(this, args);
  }

  push(...args: any[]) {
    observableManager.onChanged(this);

    return super.push.apply(this, this.makeObservable(args));
  }

  //@ts-ignore
  reduce(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.reduce.apply(this, args);
  }

  //@ts-ignore
  reduceRight(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.reduceRight.apply(this, args);
  }

  reverse(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.reverse.apply(this, args);
  }

  shift(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.shift.apply(this, args);
  }

  slice(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.slice.apply(this, args);
  }

  some(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.some.apply(this, args);
  }

  sort(...args: any[]) {
    observableManager.onChanged(this);

    //@ts-ignore
    return super.sort.apply(this, args);
  }

  splice(start: number, deleteCount: number, ...items: T[]) {
    observableManager.onChanged(this);

    items = items ? this.makeObservable(items) : undefined;

    return super.splice.call(this, start, deleteCount, ...items);
  }

  toLocaleString(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.toLocaleString.apply(this, args);
  }

  toSource(...args: any[]) {
    observableManager.onObserved(this);

    return super.concat.apply(this, args);
  }

  toString(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.toString.apply(this, args);
  }

  unshift(...args: any[]) {
    observableManager.onChanged(this);

    return super.unshift.apply(this, this.makeObservable(args));
  }

  values(...args: any[]) {
    observableManager.onObserved(this);

    //@ts-ignore
    return super.values.apply(this, args);
  }
}

export class ObservableArrayProxyHandler {
  set(target: any, prop: PropertyKey, newValue: any, receiver: any) {
    const existing = target[prop];

    if (existing !== newValue) {
      newValue = makeObservable(newValue);
      observableManager.onChanged(target, prop);
    }

    return Reflect.set(target, prop, newValue, receiver);
  }

  get(target: any, prop: PropertyKey, receiver: any) {
    observableManager.onObserved(target, prop);

    return Reflect.get(target, prop, receiver);
  }
}

const handler = new ObservableArrayProxyHandler();
