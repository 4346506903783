import * as React from 'react';

import { Field } from 'app2/components';

import { CompanyLink, QueryList } from '../shared';

import { useVendorsQuery } from './generated';

export function Vendors() {
  return <QueryList icon='Home' title='Providers' query={useVendorsQuery} name='vendors' fields={[
    <Field name='company.name' label='Name' component={CompanyLink} none='Invited' />,
    <Field name='displayName' label='Nickname' />,
  ]} />
}
