import { LoggedInUser } from './LoggedInUser';

export const SHARER_PARAM = 'hr_sharer'
export const SHARED_FROM_PARAM = 'hr_shared_from'

export enum StandardEvents {
  CompleteRegistration,
  AddToCart,
  Purchase
}

const standardEventsMapping = {
  [StandardEvents.CompleteRegistration]: 'createNewUser',
  [StandardEvents.AddToCart]: 'addItemToCart',
  [StandardEvents.Purchase]: 'checkout'
};

export interface EventProperties {
  [key: string]: any;
}

export type Callback = () => void;

export class EventRecorder {
  prevLocation?: string;
  logRocketUrl: string;
  user:LoggedInUser;
  capture:boolean = true;

  get recordForHomeroomStaff(): boolean {
    return false;
  }

  get loaded(): boolean {
    return true;
  }

  onAppStart(urlParams: EventProperties): void {
  }

  onLogRocketUrlUpdated(url: string): void {
    this.logRocketUrl = url;
  }

  // current used for both start when the user is known and when the user logs in
  identifyUser(user:LoggedInUser, callback?: Callback): void {
    this.user = user;
    callback();
  }

  // current used for both start when the user is not known and when the user logs out
  identifyNoUser(callback?: Callback): void {
    callback();
  }

  logStandard(
    event: StandardEvents,
    data?: EventProperties,
    callback?: Callback
  ): void {
    this.log(standardEventsMapping[event], data, callback);
  }

  log(event: string, data?: EventProperties, callback?: Callback): void {}

  get standardEventProperties() {
    const attributes:any = {
      applicationLocation: location.href,
      logRocketUrl: this.logRocketUrl,
      userId: this.user?.id,
      email: this.user?.email
    }

    const searchParams = new URLSearchParams(location.search)
    const sharerUUID = searchParams.get(SHARER_PARAM);

    if (sharerUUID) {
      attributes['sharerUuid'] = sharerUUID;
    }

    const sharedFrom = searchParams.get(SHARED_FROM_PARAM);

    if (sharedFrom) {
      attributes['sharedFrom'] = sharedFrom;
    }

    return attributes;
  }

  pageView(url: string): void {
    this.prevLocation = url;
  }

  get isHomeroomStaff() {
    return this.user?.homeroom;
  }

  mapEventProperties(
    standardEventPropertyMapping: any,
    event: StandardEvents,
    data?: EventProperties
  ): EventProperties {
    const mappedData = Object.assign({}, data);
    const eventPropertyMapping = standardEventPropertyMapping[event];
    if (eventPropertyMapping) {
      for (const name in eventPropertyMapping) {
        mappedData[eventPropertyMapping[name]] = mappedData[name];
        delete mappedData[name];
      }
    }
    return mappedData;
  }
}

export function doNothing() {}
