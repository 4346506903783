import * as React from 'react';
import { makeObservable, isObservable, observer, assert } from 'app/observable';

import {
  FormData,
  FormValidators,
  resetForm,
  submit,
  presubmit
} from './FormData';

export interface FormProps<T> extends React.Props<any> {
  // if false, this will not render an HTML form
  renderForm?: boolean;

  // either
  onSubmit?: (formData: FormData<T>) => void;
  initialValues?: Partial<T>;
  validators?: FormValidators<T>;
  // or (but not both)
  formData?: FormData<T>;
  style?: React.CSSProperties;
}

export const FormContext = React.createContext(null as FormData<any>);

@observer
export class Form<T> extends React.Component<FormProps<T>> {
  private _formData: FormData<T>;

  get formData(): FormData<T> {
    return this.props.formData || this._formData;
  }

  render() {
    let formData = this.formData;

    if (!formData) {
      formData = this._formData = makeObservable({
        initialValues: this.props.initialValues,
        validators: this.props.validators
      });
    }

    assert(isObservable(formData), 'Form data must be observable');

    if (!formData.values) {
      resetForm(formData, this.props.initialValues);
    }

    // if for some reason we are a form inside a form
    // don't render a second form because its illegal
    const haveForm = this.context != null;

    return haveForm || this.props.renderForm === false ? (
      this.renderInner()
    ) : (
      <form style={this.props.style} onSubmit={this.handleSubmit}>{this.renderInner()}</form>
    );
  }

  renderInner() {
    return (
      <FormContext.Provider value={this.formData}>
        {this.props.children}
      </FormContext.Provider>
    );
  }

  handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.props.onSubmit) {
      const success = await presubmit(this.formData);

      if (!success) {
        return false;
      }

      this.props.onSubmit(this.formData);
    } else {
      await submit(this.formData);
    }
  };
}

Form.contextType = FormContext;
