import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUserWithCartSetupIntentQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentUserWithCartSetupIntentQuery = { user?: { name?: string | null | undefined; email: string; cart?: { stripeSetupIntentToken?: string | null | undefined } | null | undefined } | null | undefined };

export const ParentUserWithCartSetupIntentDocument = gql`
  query ParentUserWithCartSetupIntent {
    user: currentUser {
      name
      email
      cart {
        stripeSetupIntentToken
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentUserWithCartSetupIntentArgs = MakeOptional<UseQueryArgs<ParentUserWithCartSetupIntentQueryVariables, ParentUserWithCartSetupIntentQuery>, 'query'>;

export function useParentUserWithCartSetupIntentQuery(options: ParentUserWithCartSetupIntentArgs = {}) {
  return useQuery<ParentUserWithCartSetupIntentQueryVariables, ParentUserWithCartSetupIntentQuery>({ query: ParentUserWithCartSetupIntentDocument, ...options });
}

export type ParentUserWithCartSetupIntentOptions = ExecQueryOptions<ParentUserWithCartSetupIntentQueryVariables>;

export function parentUserWithCartSetupIntent(options: ParentUserWithCartSetupIntentOptions) {
  return executeQuery<ParentUserWithCartSetupIntentQuery, ParentUserWithCartSetupIntentQueryVariables>(ParentUserWithCartSetupIntentDocument, options);
}

export type ParentUserWithCartSetupIntentSelections = ParentUserWithCartSetupIntentQuery['user'];
