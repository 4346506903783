import { useParams } from 'react-router';

import { useSiteVendorsQuery } from 'app2/views/shared';

import { SeasonRouteParams } from '../organizerRoutes';

export function useSiteCompany(pause?:boolean) {
  const { site } = useParams<SeasonRouteParams>();
  const [ result ] = useSiteVendorsQuery({ variables: { site } });

  return result?.data?.site?.vendors?.find(vendor => vendor.company?.userCompanyRole);
}

