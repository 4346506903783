import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyCopyStripeTokenMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
  destinationType: Types.Scalars['String'];
}>;

export type CompanyCopyStripeTokenMutation = { companyCopyStripeToken?: { id: string } | { id: string } | null | undefined };

export const CompanyCopyStripeTokenDocument = gql`
  mutation CompanyCopyStripeToken($company: ID!, $destination: ID!, $destinationType: String!) {
    companyCopyStripeToken(company: $company, destination: $destination, destinationType: $destinationType) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyCopyStripeTokenOptions = ExecMutationOptions<CompanyCopyStripeTokenMutationVariables>;

export function companyCopyStripeToken(options: CompanyCopyStripeTokenOptions) {
  return executeMutation<CompanyCopyStripeTokenMutation, CompanyCopyStripeTokenMutationVariables>(CompanyCopyStripeTokenDocument, options);
}
