import { flatten } from 'lodash-es';

import { compact } from 'app2/api';
import { copyToClipboard } from './copyToClipboard'

// ensures emails are flattened, unique, sorted, and we use the same success message

export function copyEmails(emails:string[] | string[][]) {
  const processedEmails = Array.from(new Set(compact(flatten(emails)))).filter(e => !!e).sort().join(', ');

  copyToClipboard(processedEmails, 'Emails copied');
}