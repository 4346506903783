import * as React from 'react'

import { useRepeaterItems, RepeaterProps } from './form';
import { renderDelimitedList } from './utils';
import { Link } from './Link';

interface Props<T> extends RepeaterProps<T, any> {
  initialCount?:number;
}

export function CollapsibleList<T = any>(props:Props<T>) {
  const {initialCount, ...remaining} = props;
  const [showAll, setShowAll] = React.useState(initialCount === undefined);
  const {items, allItems, renderItem} = useRepeaterItems({...remaining, max: showAll ? undefined : initialCount});

  function render() {
    return <>
      {renderDelimitedList(items, props.delimeter, renderItem)}
      {renderShowMore()}
    </>
  }

  function renderShowMore() {
    if (items?.length == allItems?.length) {
      return <></>
    }

    return <Link onClick={onShowMore} iconPosition='right' bold icon='DropdownOpen'>Show more times</Link>
  }

  function onShowMore(event:React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setShowAll(!showAll);
  }

  return render();
}

