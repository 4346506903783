import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamInviteResendMutationVariables = Types.Exact<{
  entityKind: Types.EntityKind;
  entityId: Types.Scalars['ID'];
  member: Types.Scalars['ID'];
}>;

export type TeamInviteResendMutation = {
  teamInviteResend?:
    | {
        entity?:
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const TeamInviteResendDocument = gql`
  mutation TeamInviteResend($entityKind: EntityKind!, $entityId: ID!, $member: ID!) {
    teamInviteResend(entityKind: $entityKind, entityId: $entityId, member: $member) {
      entity {
        id
        participants {
          id
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TeamInviteResendOptions = ExecMutationOptions<TeamInviteResendMutationVariables>;

export function teamInviteResend(options: TeamInviteResendOptions) {
  return executeMutation<TeamInviteResendMutation, TeamInviteResendMutationVariables>(TeamInviteResendDocument, options);
}
