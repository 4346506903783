import * as React from 'react';

import { Course, CourseInput } from 'app2/api';
import { FieldInfo, defaultFieldProps } from 'app2/components';

import { useCompanyTimezoneQuery, useSiteTimezoneQuery } from './generated'

export function withSiteOrCompanyTimezone<P extends {timezone?:string}>(Component:React.ComponentType<Omit<P, 'info'>>) {
  interface Props {
    info?:FieldInfo<Course | CourseInput>;
    site?:string;
    company?:string;
  }

  const existingFieldProp = (Component as any).fieldProps?.['fieldProperty'];

  const NewComponent = function (props:P & Props) {
    const {info, site:propsSite, company:propsCompany, ...remaining} = props;

    const course = info.values[0] as Course;
    const courseTimezone = course?.site?.timezone || course?.company?.timezone;

    const siteId = propsSite || course?.site?.id;
    const [resultSite] = useSiteTimezoneQuery({variables: {site: siteId}, pause: !siteId || !!courseTimezone});
    const site = resultSite.data?.site;

    const companyId = propsCompany || course?.company?.id;
    const [result] = useCompanyTimezoneQuery({variables:{company:companyId}, pause: !!siteId || !companyId || !!courseTimezone});
    const company = result.data?.company;

    const timezone = courseTimezone || site?.timezone || company?.timezone;
    const propsToUse:any = {...remaining, timezone};

    if (existingFieldProp) {
      propsToUse[existingFieldProp] = info;
    }
    return <Component {...propsToUse} />;
  }

  NewComponent.displayName = Component.displayName || Component.name;
  NewComponent.fieldProps = {
    ...((Component as any).fieldProps || defaultFieldProps),
    fieldProperty: 'info'
  }

  return NewComponent;
}
