import * as React from 'react'

import { userPreferences } from "app2/api";
import { NavigationSection as NavigationSectionBase, NavigationSectionProps } from 'app2/components';
import { useUserPreference } from 'app2/views/shared-public';

import { CurrentUserInfo, useCurrentUser } from '../user'

import { betaImageCssLeft, useShowBeta } from './Beta';

interface Props extends NavigationSectionProps {
  beta?:boolean
  initialOpenState?:(user:CurrentUserInfo['user']) => boolean;
}

export function NavigationSection(props:Props) {
  let {beta, initialOpenState, ...remaining} = props;

  const { user } = useCurrentUser();
  const [open, setOpen] = useUserPreference(userPreferences, 'navigation:' + (props.name || props.label) as any, initialOpenState?.(user));

  function render() {
    if (beta && !useShowBeta()) {
      return <></>;
    }

    const css = beta ? betaImageCssLeft : undefined;

    return <NavigationSectionBase css={css} open={open} onOpen={setOpen} {...remaining}/>
  }

  return render();
}
