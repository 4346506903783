import * as React from 'react';
import { findLast } from 'lodash-es'

import { CartUtils } from 'app2/api';
import { noOpErrorHandler } from 'app2/components';
import { addToCart, CartCourse, Cart, configureEnrollment, removeCourseFromCart as removeEnrollmentFromCart } from './';
import { PublicUserWithCartQuery, usePublicUserWithCartQuery } from './generated';

type CartData = PublicUserWithCartQuery['user']['cart'];

export class CartImpl implements Cart {
  counts:Map<string, number>;
  data:PublicUserWithCartQuery['user']['cart'];

  constructor(data:CartData) {
    this.data = data;
    if (!this.data) {
      return;
    }
    
    this.calcCourseCounts();
  }

  get hasItems() {
    return !!this.data?.enrollments?.length;
  }

  addToCart = async (course:CartCourse) => {
    const enrollment = await configureEnrollment(course);

    if (!enrollment) {
      return;
    }

    return addToCart([enrollment]);
  }

  removeFromCart = async (courseId:string) => {
    const enrollment = findLast(this.data.enrollments, ci => ci.course.id == courseId);

    if (!enrollment) {
      return;
    }
  
    await removeEnrollmentFromCart({ variables: { id: enrollment.id } });
  }

  calcCourseCounts() {
    const {visible} = CartUtils.classifyEnrollments(this.data);

    this.counts = new Map<string, number>();

    visible?.forEach?.(ci => {
      const count = this.counts.get(ci.course.id) || 0;
      this.counts.set(ci.course.id, count + 1);
    });
  }
}

export function useCart() {
  const [result, ] = usePublicUserWithCartQuery({hideLoader: true, error: noOpErrorHandler});
  const fetching = result.fetching;
  const stale = result.stale;
  const loading = result.fetching || result.stale;

  const cartData = result.data?.user?.cart;
  const cart = React.useMemo(() => new CartImpl(cartData), [cartData]);

  return { fetching, stale, loading, cart };
}
