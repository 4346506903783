import * as React from 'react';
import { useHistory } from 'react-router';

import { CourseKind, CourseKindGroup } from 'app2/api';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CreateActivities } from './CreateActivities';

export function useCreateActivitiesButton(kinds:(CourseKind | CourseKindGroup)[]) {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const editing = params.has('edit');

  const kind = kinds?.sort((a, b) => courseKindBehavior[a].order - courseKindBehavior[b].order)[0];
  const createActivitiesButton = <CreateActivities kind={kind} />;

  return { editing, createActivitiesButton }

}
