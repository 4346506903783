import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateMessageMutationVariables = Types.Exact<{
  attributes: Types.MessageInput;
}>;

export type CreateMessageMutation = { messageThreadCreate?: { message?: { id: string } | null | undefined } | null | undefined };

export const CreateMessageDocument = gql`
  mutation CreateMessage($attributes: MessageInput!) {
    messageThreadCreate(attributes: $attributes) {
      message {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CreateMessageOptions = ExecMutationOptions<CreateMessageMutationVariables>;

export function createMessage(options: CreateMessageOptions) {
  return executeMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
}
