import * as React from 'react';

import { Scalable, MultiContext, BreakpointInfo } from 'app2/components';

import { courseKindBehavior } from '../..';
import { CourseInfo, CourseInfoProps } from './CourseInfo';

export type CourseCardProps = CourseInfoProps;

export function CourseCard(props:CourseInfoProps) {
  const context = React.useContext<BreakpointInfo>(MultiContext);
  const behavior = courseKindBehavior[props.course.kind];

  function render() {
    return (behavior.registrationLayout == 'tile' && props.size != 'wide') || props.size == 'tile'
      ? renderTile()
      : renderRow();
  }

  function renderTile() {
    const minHeight = '364px';
    const width = 362;
    return <Scalable scale={['100%', 288/width, 1]}>
      <CourseInfo {...props} p="$16" border="solid 1px" width={width + 'px'} minHeight={minHeight} borderColor="border" borderRadius="form" hAlign="left" />
    </Scalable>
  }

  function renderRow() {
    // potentially force into mobile layout for activity preview
    const breakpoint = behavior.registrationLayout == 'row' && props.kind == 'card-preview' ? 0 : context.breakpoint;
    const width = [362, 800, 862][breakpoint];
    const height = [364, 225, 217][breakpoint];

    return <Scalable scale={['100%', '100%', '100%']} > 
      <CourseInfo {...props} p="$16" border="solid 1px" minWidth={width + 'px'} minHeight={height + 'px'} borderColor="border" borderRadius="form" hAlign="left" />
    </Scalable>
  }

  return render();
}
