import * as React from 'react';

import { EntityKind } from 'app2/api';
import { Button } from 'app2/components'

import { ContactModal } from '../../shared-public';

interface Props {
  provider: {
    display_name:string;
    id:number | string;
  }
  label?:string;
  onClick?:() => void
}

export function ContactProvider(props:Props) {
  function render() {
    return <div>
      <Button onClick={onClick}>{props.label || 'Contact provider'}</Button>
    </div>
  }

  function onClick() {
    props.onClick?.();
    
    return <ContactModal to={props.provider.display_name} toId={props.provider.id?.toString()} toKind={EntityKind.Company} 
      message='I am interested in learning more about your program' />
  }

  return render();
}
