import * as React from 'react'

import { Field } from 'app2/components';

import { QueryList, SiteLink } from '../shared';

import { useAdminCompanyVendorsQuery } from './generated';

export function Vendors() {
  return <QueryList icon='Home' title='Schools' query={useAdminCompanyVendorsQuery} name='vendors' fields={[
    <Field name='site.name' label='School name' component={SiteLink} />,
    <Field name='nickName' label='Provider nickname at school' />,
  ]} />
}