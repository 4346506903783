import * as React from 'react'

import { Tag, TagProps } from 'app2/components'

import { FileLink } from './FileLink'

interface FileTagProps extends TagProps {
  id:string;
}

export function FileTag(props:FileTagProps) {
  const {id, label, ...remaining} = props;

  return <Tag {...remaining}><FileLink id={id} name={label} fontWeight='bold' /></Tag>
}
