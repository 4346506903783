import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddTeacherMutationVariables = Types.Exact<{
  company?: Types.InputMaybe<Types.Scalars['ID']>;
  vendor?: Types.InputMaybe<Types.Scalars['ID']>;
  teacher: Types.SingleTeacherInput;
}>;

export type AddTeacherMutation = {
  companyUpsertTeacher?: { company?: { id: string } | null | undefined; teacher?: { id: string; name: string; email?: string | null | undefined; typename: 'Teacher' } | null | undefined } | null | undefined;
};

export const AddTeacherDocument = gql`
  mutation AddTeacher($company: ID, $vendor: ID, $teacher: SingleTeacherInput!) {
    companyUpsertTeacher(company: $company, vendor: $vendor, teacher: $teacher) {
      company {
        id
      }
      teacher {
        typename: __typename
        id
        name
        email
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AddTeacherOptions = ExecMutationOptions<AddTeacherMutationVariables>;

export function addTeacher(options: AddTeacherOptions) {
  return executeMutation<AddTeacherMutation, AddTeacherMutationVariables>(AddTeacherDocument, options);
}
