import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReportTransactionsFilterOptionsQueryVariables = Types.Exact<{
  type: Types.EntityKind;
  current: Types.Scalars['ID'];
  selected?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  name: Types.Scalars['String'];
}>;

export type ReportTransactionsFilterOptionsQuery = { reportTransactionsFilterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined };

export const ReportTransactionsFilterOptionsDocument = gql`
  query ReportTransactionsFilterOptions($type: EntityKind!, $current: ID!, $selected: [ID!], $name: String!) {
    reportTransactionsFilterOptions(type: $type, current: $current, selected: $selected, name: $name) {
      label
      value
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ReportTransactionsFilterOptionsArgs = MakeOptional<UseQueryArgs<ReportTransactionsFilterOptionsQueryVariables, ReportTransactionsFilterOptionsQuery>, 'query'>;

export function useReportTransactionsFilterOptionsQuery(options: ReportTransactionsFilterOptionsArgs = {}) {
  return useQuery<ReportTransactionsFilterOptionsQueryVariables, ReportTransactionsFilterOptionsQuery>({ query: ReportTransactionsFilterOptionsDocument, ...options });
}

export type ReportTransactionsFilterOptionsOptions = ExecQueryOptions<ReportTransactionsFilterOptionsQueryVariables>;

export function reportTransactionsFilterOptions(options: ReportTransactionsFilterOptionsOptions) {
  return executeQuery<ReportTransactionsFilterOptionsQuery, ReportTransactionsFilterOptionsQueryVariables>(ReportTransactionsFilterOptionsDocument, options);
}

export type ReportTransactionsFilterOptionsSelections = ReportTransactionsFilterOptionsQuery['reportTransactionsFilterOptions'][0];

(ReportTransactionsFilterOptionsDocument as any).additionalTypenames = ['Option'];
