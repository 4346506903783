import { ErrorWithStringPath } from 'app2/api';
import { ErrorPathSegment, ErrorPathSegmentType, FormModel } from 'app2/components';

import { pathValueTraversal } from './parsePaths';

export type ErrorTransform = (errors:ErrorWithStringPath, input:any) => ErrorWithStringPath;

export function transformErrors(errors:ErrorWithStringPath[], transformOrTransforms:ErrorTransform[] | ErrorTransform, input:any):ErrorWithStringPath[] {
  if (!transformOrTransforms) {
    return errors;
  }

  const transforms = Array.isArray(transformOrTransforms) ? transformOrTransforms : [transformOrTransforms];

  return errors.map(error => {
    transforms.forEach(transform => error = transform(error, input));
    return error;
  });
}

// transformer that can transform paths using a regex
// i.e. errorPathTransform('site.classrooms', 'rooms') 
// would change a path of foo.site.classrooms.name to foo.rooms.name

export function errorPathTransform(from:RegExp | string, to:string) {
  return function (error:ErrorWithStringPath):ErrorWithStringPath {
    if (!error.path?.length && !!from) {
      return {...error};
    }

    let path = (error.path || []).join('.').replace(from, to).split('.');

    if (path?.[0] == '') {
      path = [];
    }

    return {...error, path }
  }
}

export function errorPathTransformHandler(form:FormModel, from:RegExp | string, to:string) {
  return { handler: form, transform: errorPathTransform(from, to) }
}

// transformer that can remove a path segment
// i.e. removePathSegment('foo.site') would
// would change a path of foo.site.classrooms.name to classrooms.name

export function removePathSegment(segment:string) {
  // these different regex's handle different cases because
  // path transform works on a fully joined path

  return errorPathTransform(new RegExp(
    '^' + segment + '\.' +  '|' +  // catches "match_path.something_else"
    '^' + segment + '$' +   '|' +  // catches "match_path"
    '\.' + segment + '\.' + '|' +  // catches "something_else.match_path.something_else"
    '\.' + segment + '$'           // catches "something_else.match_path"
  ), '');
}

// transformer that can a path index into an array of ids
// and turn it into a id path.  for example, give an input of
//  ids: ['123', '456']
//
// and at path of ids.1
//
// calling this function with a segment to change of 1, would change the path to
//
// ids.id-456

export function listIndexToIdSegment(segmentNoToChange:number) {
  return function (error:ErrorWithStringPath, input:any):ErrorWithStringPath {
    const updatedPath = pathValueTraversal(error.path, input, (segment:ErrorPathSegment, segmentNo:number, value:any) => {
      const updated = {...segment};
  
      if ((updated.type == ErrorPathSegmentType.index || updated.type == ErrorPathSegmentType.property) && segmentNo == segmentNoToChange && Array.isArray(value)) {
        updated.value = value[Number(segment.value)];
        updated.type = ErrorPathSegmentType.id;
      }
  
      return updated;
    });

    return {
      ...error,
      path: updatedPath
    }
  }
}

export function removePath(error:ErrorWithStringPath):ErrorWithStringPath {
  return {...error, path:[]}
}

export const idsToindex = [listIndexToIdSegment(1), removePathSegment('ids')];