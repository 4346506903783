import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DiscountDefinitionFilterOptionsQueryVariables = Types.Exact<{
  entityKind: Types.Scalars['String'];
  entityId: Types.Scalars['ID'];
  queries?: Types.InputMaybe<Array<Types.Scalars['JSON']> | Types.Scalars['JSON']>;
  filterOptions: Types.Scalars['String'];
}>;

export type DiscountDefinitionFilterOptionsQuery = {
  entity?:
    | { discountDefinitionsQuery?: { filterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined } | null | undefined }
    | { discountDefinitionsQuery?: { filterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined } | null | undefined }
    | { discountDefinitionsQuery?: { filterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined } | null | undefined }
    | null
    | undefined;
};

export const DiscountDefinitionFilterOptionsDocument = gql`
  query discountDefinitionFilterOptions($entityKind: String!, $entityId: ID!, $queries: [JSON!], $filterOptions: String!) {
    entity(entityKind: $entityKind, entityId: $entityId) {
      ... on WithDiscountDefinitions {
        discountDefinitionsQuery(queries: $queries, filterOptions: $filterOptions, pageSize: 1000) {
          filterOptions {
            label
            value
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type DiscountDefinitionFilterOptionsArgs = MakeOptional<UseQueryArgs<DiscountDefinitionFilterOptionsQueryVariables, DiscountDefinitionFilterOptionsQuery>, 'query'>;

export function useDiscountDefinitionFilterOptionsQuery(options: DiscountDefinitionFilterOptionsArgs = {}) {
  return useQuery<DiscountDefinitionFilterOptionsQueryVariables, DiscountDefinitionFilterOptionsQuery>({ query: DiscountDefinitionFilterOptionsDocument, ...options });
}

export type DiscountDefinitionFilterOptionsOptions = ExecQueryOptions<DiscountDefinitionFilterOptionsQueryVariables>;

export function discountDefinitionFilterOptions(options: DiscountDefinitionFilterOptionsOptions) {
  return executeQuery<DiscountDefinitionFilterOptionsQuery, DiscountDefinitionFilterOptionsQueryVariables>(DiscountDefinitionFilterOptionsDocument, options);
}

export type DiscountDefinitionFilterOptionsSelections = DiscountDefinitionFilterOptionsQuery['entity'];
