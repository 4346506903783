import * as React from 'react';

import { Payouts as PayoutsBase, QueryApiNoParams } from '../shared';

import { useAdminPayoutsQuery } from './generated'

export function Payouts() {
  function render() {
    return <PayoutsBase query={useAdminPayoutsQuery as QueryApiNoParams} to='/admin2/homeroom' variables={{kind: "admin_payouts"}} showTarget />
  }

  return render();
}
