import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminEventQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminEventQuery = {
  event?:
    | {
        id: string;
        createdAt: string;
        eventName?: string | null | undefined;
        serviceType?: Types.EventService | null | undefined;
        eventAttributes?: { [key: string]: any } | null | undefined;
        contact?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const AdminEventDocument = gql`
  query AdminEvent($id: ID!) {
    event(event: $id) {
      id
      createdAt
      eventName
      contact {
        id
        name
        email
        phone
      }
      serviceType
      eventAttributes
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminEventArgs = MakeOptional<UseQueryArgs<AdminEventQueryVariables, AdminEventQuery>, 'query'>;

export function useAdminEventQuery(options: AdminEventArgs = {}) {
  return useQuery<AdminEventQueryVariables, AdminEventQuery>({ query: AdminEventDocument, ...options });
}

export type AdminEventOptions = ExecQueryOptions<AdminEventQueryVariables>;

export function adminEvent(options: AdminEventOptions) {
  return executeQuery<AdminEventQuery, AdminEventQueryVariables>(AdminEventDocument, options);
}

export type AdminEventSelections = AdminEventQuery['event'];
