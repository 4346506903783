export function dispatchChangeEvent(target:any, value:any, onChange:any, valuePropNameOrValues:string | any = 'value') {
  if (!onChange || !target) {
    return;
  }

  const event = createChangeEvent(target, value, valuePropNameOrValues);

  return onChange(event);
}

export function createChangeEvent(target:any, value:any, valuePropNameOrValues:string | any = 'value') {
  const values = typeof valuePropNameOrValues == 'string' 
    ? {[valuePropNameOrValues]: value}
    : valuePropNameOrValues

  const proxy = new Proxy(target, {
    has: (target: any, prop: string | symbol): boolean => {
      return prop in values
        ? true 
        : Reflect.has(target, prop);
    },
    get:(target: any, prop: PropertyKey, receiver: any) => {
      return prop in values
        ? values[prop]
        : Reflect.get(target, prop, receiver);
    }
  });

  const event = {
    type: 'change',
    readonly: true,
    cancelBubble: false,
    composed: false,
    target: proxy,
    currentTarget: proxy,
    srcElement: proxy,
    preventDefault: () => {},
    stopImmediatePropagation: () => {},
    stopPropagation: () => {},
    nativeEvent: null as Event,
    bubbles: false,
    cancelable: false,
    defaultPrevented: false,
    eventPhase: 0, 
    isTrusted: false,
    isDefaultPrevented: () => false,
    isPropagationStopped: () => false,
    persist: () => undefined as void, 
    timeStamp: Date.now(),
    returnValue: undefined as any,
    composedPath: [] as string[],
    initEvent: () => undefined as void
  }

  return event;
}
