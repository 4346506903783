import { flatten } from 'lodash-es';

import { ObservableCollectionCommand, CollectionEvent } from "../datatable/collection";

import { Command } from "./Command";


export class MultipleCommands<T> implements Command, ObservableCollectionCommand<T> {
  commands:Command[];

  constructor(commands:Command[]) {
    this.commands = commands;
  }

  get isCollectionCommand() {
    return true;
  }

  get collectionEvents():CollectionEvent<T>[] {
    return flatten(this.commands.map(command => (command as unknown as ObservableCollectionCommand<T>).collectionEvents).filter(events => !!events));
  }

  focus():void {
  }

  do():void {
    this.commands.forEach(command => command.do());
  }

  undo():void {
    this.commands.slice().reverse().forEach(command => {
      command.focus();
      command.undo();
    });
  }

  redo():void {
    this.commands.forEach(command => {
      command.focus();
      command.redo();
    });
  }
}
