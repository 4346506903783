import * as React from 'react'

import { Panel, PanelProps, Section, TextAreaField } from 'app/app2/components'

export function BaseAfterActivity(props:PanelProps) {
  return <Panel icon='Shield' title='Post-activity' {...props}>
    <Section label='Student dismissal process' name='studentDismissal' component={TextAreaField} />
    <Section label='Late family pick-up' name='lateParent' component={TextAreaField} />
  </Panel>
}
