import * as React from 'react';

import { courseKindBehavior } from 'app2/views/shared-public';

import { closeCourses } from '../../course/course-actions';

import { TableAction, TableActionProps } from './TableAction';

export function CloseEnrollmentAction(props:TableActionProps) {
  const behavior = courseKindBehavior[props.kind];

  if (behavior?.fields?.enrollmentPeriod === false) {
    return <></>;
  }

  return <TableAction icon='LogOut' label='Close enrollment' msg='Activities closed for enrollment' clickEvent='Click Close enrollment' ability='enrollable' mutation={closeCourses} {...props} />
}
