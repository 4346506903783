import { Sentry, SentryWithScopeCallBack } from './Sentry';
import { captureException } from 'app/helpers';

type ContextInfo = { name: string; props: any };
export class SentryShim implements Sentry {
  contexts: ContextInfo[] = [];
  exceptions: any = [];

  init(config: object): void {}

  withScope(scope: SentryWithScopeCallBack): void {}

  setContext(name: string, props: any): void {
    this.contexts.push({ name, props });
  }

  captureException(err: any): void {
    this.exceptions.push(err);
  }

  applyShimCalls() {
    this.contexts.forEach((info: ContextInfo) => {
      window.Sentry.setContext(info.name, info.props);
    });

    this.contexts = [];

    this.exceptions.forEach((exception: any) => {
      captureException(exception);
    });

    this.exceptions = [];
  }
}
