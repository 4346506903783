import * as React from 'react'
import { useParams } from 'react-router';

import { Threads as Threads } from 'app2/views/shared'

import { CompanyPage } from '../shared';
import { CompanyRouteParams } from '../companyRoutes';

export function CompanyMessages() {
  const { company } = useParams<CompanyRouteParams>();

  return <CompanyPage title='Messages' subtitle='To send a new message, navigate to an individual activity, select one or more students, and compose your message from there.'>
    <Threads role='provider' labelOrParticipant={{type: 'Company', id: company}} entityMessages={true} />
  </CompanyPage>
}
