import { Preferences, preference } from '../shared/preferences/Preferences';

export class CoursesTablePreferences extends Preferences {
  @preference
  base:string;

  // deprecated
  @preference
  advanced:string;

  // deprecated
  @preference
  orgProvider:string;

  // deprecated
  @preference
  orgProviderAdvanced:string;
}
