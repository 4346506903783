import * as React from 'react'

import { AffirmLoader } from 'app/scriptLoading'
import { Icon, Text, HBox } from 'app2/components';

import { ParentUserWithCartSelections } from '../generated';

interface Props {
  waitlist:boolean;
}

export function AffirmForm(props:Props) {
  return <AffirmLoader>
    <HBox vAlign='center' gap='$8'>
      <Icon name='CheckCircle' size='medium' />
      <Text text='body'>You're all set! Click {!props.waitlist ? "confirm and pay when you're ready to complete your purchase." : "join waitlist when you're ready to complete joining the waitlist."} </Text>
    </HBox>
    <HBox vAlign='center' gap='$8'>
      <Icon name='CheckCircle' size='medium' opacity={0} />
      <Text text='body' disabled>Note: Donations cannot be added when paying with Affirm.</Text>
    </HBox>
  </AffirmLoader>
}

export declare var affirm: any;
interface AffirmResult {
  checkout_token:string;
  reason?: 'canceled';
}

export async function affirmCheckout(cart:ParentUserWithCartSelections['cart']):Promise<AffirmResult> {  
  return new Promise((accept, _reject) => {
    const payload = buildAffirmPayload(cart);
    if (!payload.items.length) {
      return accept({  checkout_token: '' });
    }
    
    affirm.checkout(payload);
    affirm.checkout.open({onSuccess: accept, onFail: accept})
  });
}

function buildAffirmPayload(cart:ParentUserWithCartSelections['cart']) {
  return {
    config: {
      financial_product_key: process.env.AFFIRM_PRODUCT_KEY
    },
    merchant: {
      public_api_key: process.env.AFFIRM_PUBLIC_API_KEY,
      user_cancel_url: window.location.href,
      user_confirmation_url: window.location.href
    },
    items: buildAffirmItems(cart),
    metadata: {
      mode: 'modal'
    },
    currency: 'USD',
    tax_amount: 0,
    shipping_amount: 0,
    total: cart.total * 100 // in cents
  }
}

function buildAffirmItems(cart:ParentUserWithCartSelections['cart']) {
  return cart.enrollments.filter(i => i.affirmEnabled).map(item => {
    const course = item.course;

    return {
      display_name: course.name,
      sku: course.id,
      // why is the old code using list_price???
      unit_price: item.amount * 100, // in cents
      qty: 1,
      item_url: `${window.location.protocol}//${window.location.host}/activities/${course.id}`
    }
  })
}

