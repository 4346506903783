import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentDetailsFieldsFragmentDoc } from '../../../../shared/student/tabs/gql/generated/studentDetailsFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentStudentDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentStudentDetailsQuery = {
  student?:
    | {
        id: string;
        name: string;
        firstName: string;
        lastName: string;
        nickname?: string | null | undefined;
        dob?: string | null | undefined;
        grade: string;
        automaticGradeUpdate: boolean;
        notes?: string | null | undefined;
        site?: { id: string; name?: string | null | undefined } | null | undefined;
        classroom?: { id: string; name: string; grade: string } | null | undefined;
        parents: Array<{ id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
        medical?:
          | {
              epiPen?: boolean | null | undefined;
              conditions?: string | null | undefined;
              allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
              medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentStudentDetailsDocument = gql`
  query ParentStudentDetails($id: ID!) {
    student(student: $id) {
      ...studentDetailsFields
    }
  }
  ${StudentDetailsFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentStudentDetailsArgs = MakeOptional<UseQueryArgs<ParentStudentDetailsQueryVariables, ParentStudentDetailsQuery>, 'query'>;

export function useParentStudentDetailsQuery(options: ParentStudentDetailsArgs = {}) {
  return useQuery<ParentStudentDetailsQueryVariables, ParentStudentDetailsQuery>({ query: ParentStudentDetailsDocument, ...options });
}

export type ParentStudentDetailsOptions = ExecQueryOptions<ParentStudentDetailsQueryVariables>;

export function parentStudentDetails(options: ParentStudentDetailsOptions) {
  return executeQuery<ParentStudentDetailsQuery, ParentStudentDetailsQueryVariables>(ParentStudentDetailsDocument, options);
}

export type ParentStudentDetailsSelections = ParentStudentDetailsQuery['student'];
