import * as React from 'react'
import { Elements } from '@stripe/react-stripe-js';

import { StripeLoader } from './StripeLoader';
import { loadStripeHelper } from './loadStripeHelper';

export function StripeElements2(props:React.PropsWithChildren<any>) {
  return <StripeLoader>
    <Elements stripe={loadStripeHelper()} {...elementOptions} {...props} />
  </StripeLoader>
}

const elementOptions = {
  options: {
      fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Mulish:300,400,500,600,700,900'
      }
    ]
  }
}
