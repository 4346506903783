import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyFrameworkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type CompanyFrameworkQuery = {
  courseFramework?:
    | {
        name: string;
        description: string;
        courseCardImage?: string | null | undefined;
        courseImage?: string | null | undefined;
        supplies?: Array<string | null | undefined> | null | undefined;
        public?: boolean | null | undefined;
        courseTags?: Array<{ id: string } | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyFrameworkDocument = gql`
  query CompanyFramework($id: ID!) {
    courseFramework(courseFramework: $id) {
      name
      description
      courseTags {
        id
      }
      courseCardImage
      courseImage
      supplies
      public
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyFrameworkArgs = MakeOptional<UseQueryArgs<CompanyFrameworkQueryVariables, CompanyFrameworkQuery>, 'query'>;

export function useCompanyFrameworkQuery(options: CompanyFrameworkArgs = {}) {
  return useQuery<CompanyFrameworkQueryVariables, CompanyFrameworkQuery>({ query: CompanyFrameworkDocument, ...options });
}

export type CompanyFrameworkOptions = ExecQueryOptions<CompanyFrameworkQueryVariables>;

export function companyFramework(options: CompanyFrameworkOptions) {
  return executeQuery<CompanyFrameworkQuery, CompanyFrameworkQueryVariables>(CompanyFrameworkDocument, options);
}

export type CompanyFrameworkSelections = CompanyFrameworkQuery['courseFramework'];
