import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MessageThreadArchiveMutationVariables = Types.Exact<{
  thread: Types.Scalars['ID'];
}>;

export type MessageThreadArchiveMutation = { messageThreadArchive?: { thread?: { id: string } | null | undefined } | null | undefined };

export const MessageThreadArchiveDocument = gql`
  mutation MessageThreadArchive($thread: ID!) {
    messageThreadArchive(thread: $thread) {
      thread {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MessageThreadArchiveOptions = ExecMutationOptions<MessageThreadArchiveMutationVariables>;

export function messageThreadArchive(options: MessageThreadArchiveOptions) {
  return executeMutation<MessageThreadArchiveMutation, MessageThreadArchiveMutationVariables>(MessageThreadArchiveDocument, options);
}
