import * as React from 'react'

import { useLifecycle } from './useLifecycle';

export interface TimeoutInfo {
  reset:(cb?:() => void | number, updatedTimeout?:number) => any;
  stop:() => void;
  active:boolean;
}

export function useTimeout(cbOrTimeout?:() => any | number, timeoutOrNothing?:number) {
  const cb = typeof cbOrTimeout == 'function' ? cbOrTimeout : undefined;
  const timeout = typeof cbOrTimeout == 'number' ? cbOrTimeout : timeoutOrNothing;

  const timeInfo = React.useRef({id: undefined, timeout: 0, cb});
  const timerApi = React.useRef<TimeoutInfo>({reset:addTimeout, stop:removeTimeout, active:false});

  useLifecycle({onUnmount});
  timeInfo.current.cb = cb;

  function onUnmount() {
    removeTimeout();
  }

  function addTimeout(cb?:() => any, updatedTimeout?:number) {
    removeTimeout();

    timeInfo.current.cb = cb || timeInfo.current.cb;
    timeInfo.current.id = setTimeout(onTimeout, updatedTimeout || timeout);
    timeInfo.current.timeout = timeout;
    timerApi.current.active = true;
  }

  function removeTimeout() {
    if (!timeInfo.current.id) {
      return;
    }

    clearTimeout(timeInfo.current.id);
    // don't clear the timeout duration or callback
    // else that would cause a timeout to get added
    // with each hook invocation
    timeInfo.current.id = undefined;
    timerApi.current.active = false;
  }

  function onTimeout() {
    const cb = timeInfo.current.cb;

    // same comment as above in removeTimeout
    timeInfo.current.id = undefined;
    timerApi.current.active = false;

    cb();
  }

  timerApi.current.reset = addTimeout;
  timerApi.current.stop = removeTimeout;


  return timerApi.current;
}
