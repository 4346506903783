import * as React from 'react'
import * as ReactIs from 'react-is'

import { HBox, BoxProps } from './Box'
import { Button, ButtonProps } from './Button'
import { isMultilineEditor } from './dom-utils'
import { InfoProps, Info } from './Info'
import { Shield } from './shield'
import { MultiContextProvider } from './utils';

export type HandlerResult = boolean | InfoProps | object | undefined | void;
export type Handler = (event?:React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent | boolean, buttonNo?:number) => HandlerResult | Promise<HandlerResult>;

export enum SaveableResult {
  cancel,
  ok
}

export enum SaveableState {
  dirty,
  saving,
}

export interface SaveableProps extends BoxProps, SaveableActionHandlers {
  cancelable?:boolean;

  content?:React.ReactNode;

  // you can either:
  // - provide button labels & button click handlers
  // - or your own buttons for cancel/ok (the handler props 
  //   are ignored if you provide your own buttons)
  // - or your own button array (the button and handler props
  //   are ignored if you provide your own button array)

  cancel?:string | React.ReactElement<ButtonProps>;
  ok?:string | React.ReactElement<ButtonProps>;
  danger?:boolean;

  // if there are more than 2 buttons, buttons 2+ are treated as ok buttons
  buttons?:React.ReactNode[];

  // any elements you want to the left of the buttons
  footerActions?:React.ReactElement<any>;

  info?: InfoProps;

  // see SaveableActionHandlers for additional handlers
}

interface SaveableActionHandlers {
  // button handlers
  // - if you don't want the modal to close when clicking 
  //   on cancel or ok, return false from your handler.

  // - you can return props for an Info notice.  doing so
  //   will keep the modal open and display that info notice.

  // - you can return a result.  doing so will pass it along
  //   to the onActionComplete handler.  in the case of a modal
  //   the ModalManager takes that result and returns it from it's
  //   async add method.

  // - handlers can be async and return boolean of InfoProps.

  // - async handlers automatically cause a button the show
  //   a working state.

  // - throwing an error will show an error info box with
  //   the error's message.

  onCancel?:Handler;
  onOk?:Handler;

  // called when any button action completes
  onActionComplete?:(button:number, result?:any) => void;
}

interface State {
  info?:InfoProps;
  saveableState?:SaveableState;
}

export class Saveable extends React.Component<SaveableProps, State> {
  static defaultProps = {
    cancelable: true,
    cancel: 'Cancel',
    ok: 'OK'
  }

  state:State = {};
  handlers:SaveableActionHandlers[] = [];
  mounted:boolean = true;
  processing:boolean;

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {cancelable, cancel, ok, danger, content, onCancel, onOk, buttons, onActionComplete: onAction, footerActions, children, ...remaining} = this.props;
    const hasButtons = cancel != null || ok != null || buttons?.length > 0 || footerActions != null;

    return <MultiContextProvider saveable={this}>
      <Shield layout='vbox' width='100%' onKeyPress={this.onKeyPress} {...remaining}>
        {content || children}
        {this.renderInfo()}
        {hasButtons && <HBox pt='$12' width='100%' flexWrap='wrap' gap='$8'>
          {footerActions}
          <HBox flex={1} />
          <HBox gap='$8'>{this.renderButtons()}</HBox>
        </HBox>}
      </Shield>
    </MultiContextProvider>
  }
  
  renderInfo() {
    return <>
      {this.props.info && <Info mt='$10' mb='18px' {...this.props.info} />}
      {this.state.info && <Info mt={this.props.info ? 0 : '$30'} mb='18px' {...this.state.info} />}
    </>
  }

  renderButtons() {
    const {cancel, ok, cancelable} = this.props;

    const buttons = this.props.buttons || [
      cancelable && (typeof cancel === 'string'
        ? <Button kind='secondary'>{cancel}</Button>
        : cancel),
      typeof ok === 'string'
        ? <Button autoLoader loading={this.state.saveableState == SaveableState.saving} danger={this.props.danger}>{this.state.saveableState == SaveableState.dirty && ok == 'Close' ? 'Save' : ok}</Button>
        : ok,
    ];

    return buttons.map((button, index) => 
      button && ReactIs.isElement(button)
        ? React.cloneElement(button, {key: index, onClick: (event:React.MouseEvent) => this.onButtonClick(index, event)})
        : button
      ).filter(element => !!element)
    }

  addHandlers(add:SaveableActionHandlers):void {
    Object.keys(add).forEach(handlerName => this.handlers.push({[handlerName]:add[handlerName as keyof SaveableActionHandlers]}));
  }

  removeHandlers(remove:SaveableActionHandlers):void {
    const handlers = this.handlers.slice();

    Object.keys(remove).forEach(handlerName => {
      const pos = handlers.findIndex(handler => handler[handlerName as keyof SaveableActionHandlers] == remove[handlerName as keyof SaveableActionHandlers]);
      handlers.splice(pos, 1)
    });

    this.handlers = handlers;
  }

  setSaveableState(saveableState?:SaveableState):void {
    if (this.mounted) {
      this.setState({saveableState});
    }
  }

  getActionCompleteHandlers() {
    return [this.props.onActionComplete, ...this.handlers.map(handler => handler.onActionComplete)].filter(handler => handler);
  }

  // button 0 is always cancel, any other is ok
  getButtonHandlers(buttonNo:number):Handler[] {
    // handlers specified via onXxx props
    const {onOk, onCancel} = this.props;
    const handlers = this.handlers
      .map(handlers => buttonNo == 0 ? handlers.onCancel : handlers.onOk)
      // handlers already on specified buttons (if they are specified)
      .concat((this.props.buttons?.[buttonNo] as React.ReactElement)?.props?.onClick)
      // handlers added via SaveableContext
      .concat(buttonNo == 0 ? onCancel : onOk)
      // map to the requested button
      .filter(handler => handler != null);

    return handlers;
  }

  onKeyPress = (event:React.KeyboardEvent) => {
    if (event.key == 'Enter' && !isMultilineEditor(document.activeElement)) {
      event.stopPropagation();
      this.onButtonClick(1, event);
    }
  }
  
  async onButtonClick(buttonNo:number, event:React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent | boolean) {
    if ((event as React.MouseEvent).defaultPrevented || this.processing) {
      return;
    }

    this.processing = true;

    try {
      let success:boolean = true;
      let handlers = this.getButtonHandlers(buttonNo);
      let results:HandlerResult[];

      if (handlers.length) {
        const resultsOrPromises = handlers.map(handler => handler(event, buttonNo));
        let info:InfoProps;

        try {
          results = await Promise.all(resultsOrPromises);

          results.forEach(result => {
            if (isInfo(result)) {
              info = result as InfoProps;
            }
          });
        }
        catch(e) {
          info = {
            type: 'error',
            // handles an array of strings being thrown as errors so they are display as bullets in info
            children: e instanceof Error ? e.message : e
          }
        }

        success = !info && results.findIndex(result => result !== undefined && !result) == -1;

        if (info && this.mounted) {
          this.setState({info});
        }
      }

      if (!success) {
        return;
      }

      if ((event as React.MouseEvent).defaultPrevented) {
        return;
      }

      if (this.mounted) {
        this.setState({info: undefined});
      }

      // if there's only a single handler, then we should only return one result
      const result = results?.length == 1 ? results?.[0] : results;
      this.getActionCompleteHandlers().forEach(handler => (handler as any)(buttonNo, result));
    }
    finally {
      this.processing = false;
    }
  }

  cancel(historyChange?:boolean) {
    if (!this.mounted) {
      return;
    }

    this.onButtonClick(0, historyChange == true ? historyChange : new KeyboardEvent('keypress', {key:'Escape'}));
  }
}

export interface SaveableHandlers {
  addHandlers(handlers:SaveableActionHandlers):void;
  removeHandlers(handlers:SaveableActionHandlers):void;
  cancel(historyChange?:boolean):void;
  setSaveableState(saveableState?:SaveableState):void;
}

export interface SaveableContext {
  saveable:SaveableHandlers;
}

export function isInfo(result:any) {
  const info = result as InfoProps;

  return typeof result === 'object' && info.type && (info.message || info.children);
}
