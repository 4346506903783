import { Point } from "../dom-utils";

import { Selection } from "./Selection";
import { DataTable } from "./DataTable";
import { PasteTableCommand } from './commands';
import { UndoManager, MultipleCommands } from '../undo';

export class MultipleSelection<T = any> {
  _table:DataTable;
  _selections:Selection[];
  _allSelected:boolean;

  constructor(table?:DataTable, selections?:Selection[] | Selection) {
    this._table = table;
    this._selections = Array.isArray(selections) ? selections.map(sel => sel.clone()) : selections ? [selections.clone()] : [new Selection(table)];
  }

  get table() {
    return this._table;
  }

  set table(table:DataTable) {
    this._table = table;
    this._allSelected = false;
    this._selections.forEach(selection => {
      selection.table = table;
    });
  }

  makeValid() {
    this._allSelected = undefined;
    this._selections.forEach(sel => sel.makeValid());
    this._selections = this._selections.filter(sel => !sel.empty);

    if (!this._selections.length) {
      this._selections = [new Selection(this._table)];
    }
  }

  reset() {
    this._selections.forEach(sel => sel.reset());
    this.makeValid();
  }

  get selections():Selection[] {
    return this._selections;
  }

  get selection():Selection {
    return this._selections[0];
  }

  set selection(selection:Selection) {
    this._allSelected = undefined;
    this._selections = [selection];
  }

  clone() {
    return new MultipleSelection(this._table, this._selections.map(selection => selection.clone()));
  }

  equal(selection:Selection) {
    return this._selections.length == 1 && this._selections[0].equal(selection);
  }

  add(selection:Selection) {
    this._allSelected = undefined;
    this._selections.push(selection);
    return this;
  }

  // toggles whole rows to be selected:
  // - if there are any non-full row selections they will get removed
  // - if there any multi-row selections they will get converted to individual selections (so they can individual be toggled off)

  toggleRow(row:number) {
    if (row < 0) {
      return;
    }

    this._allSelected = undefined;

    const mutlipleRowSelections = this._selections.filter(selection => selection.allColsSelected && selection.multipleRowsSelected);
    const selections = this._selections.filter(selection => selection.allColsSelected && !selection.multipleRowsSelected);

    // add back rows that are selected but are part of a multiple row selection
    mutlipleRowSelections.forEach(selection => selection.rows.map(row => selections.push(new Selection(this._table).selectRow(row))));

    const pos = selections.findIndex(selection => selection.containsRow(row));

    if (pos != -1) {
      selections.splice(pos, 1);
    }
    else {
      selections.push(new Selection(this._table).selectRow(row));
    }

    this._selections = selections;

    if (this._selections.length == 0) {
      this._selections.push(new Selection(this._table, new Point(this._table.visibleTopLeft.x, row)));
    }

    return this;
  }

  selectRows(rows:number[]) {
    this._allSelected = undefined;
    this._selections = rows.map(row => new Selection(this._table).selectRow(row));
  }

  // similar behavior as toggleRow

  toggleCol(col:number) {
    if (col < 0) {
      return;
    }

    this._allSelected = undefined;

    const mutlipleColSelections = this._selections.filter(selection => selection.allRowsSelected && selection.multipleColsSelected);
    const selections = this._selections.filter(selection => selection.allRowsSelected && !selection.multipleColsSelected);

    // add back rows that are selected but are part of a multiple row selection
    mutlipleColSelections.forEach(selection => selection.cols.map(col => selections.push(new Selection(this._table).selectCol(col))));

    const pos = selections.findIndex(selection => selection.containsCol(col));

    if (pos != -1) {
      selections.splice(pos, 1);
    }
    else {
      selections.push(new Selection(this._table).selectCol(col));
    }

    this._selections = selections;

    if (this._selections.length == 0) {
      this._selections.push(new Selection(this._table, new Point(col, this._table.visibleTopLeft.y)));
    }

    return this;
  }

  get allSelected() {
    if (this._allSelected === undefined) {
      const rows = this.selectedRows.sort((a, b) => a - b);
      let rowPos = 0;

      while (this._allSelected === undefined || this._allSelected === true && rowPos < this._table.numRows) {
        this._allSelected = rows[rowPos] == rowPos;
        ++rowPos;
      }
    }

    return this._allSelected;
  }

  // only returns rows that are entirely selected
  get selectedRows() {
    const rows:number[] = [];

    this._selections.forEach(selection => {
      if (selection.allColsSelected) {
        rows.push(...selection.rows);
      }      
    });

    return rows;
  }

  get selectedItems():T[] {
    const rows:number[] = [];

    // this is intentionally different then selectedRows to make it
    // so that you don't have to select the entire row to indicate selecting
    // and item for some kind of table action
    this._selections.forEach(selection => {
      rows.push(...selection.rows);
    });

    // row should always map to a valid item, but sometimes it
    // doesn't, probably because the selection wasn't adjusted
    // properly after a delete.  filter them out to about null errors.
    return rows.map(row => this.table.data.getItem(row)).filter(item => item != null);
  }

  // only returns rows that are entirely selected
  get selectedCols() {
    const cols:number[] = [];

    this._selections.forEach(selection => {
      if (selection.allRowsSelected) {
        cols.push(...selection.cols);
      }
    });

    return cols;
  }

  updateSelected(values:Partial<T>) {
    const commands:PasteTableCommand<T>[] = [];
    
    this.selectedRows.forEach(row => {
      for (const propName in values) {
        const propValue = values[propName];
        const col = this.table.getColumnIndex(propName);
        const sel = new Selection(this.table, new Point(col, row));
        commands.push(new PasteTableCommand(this.table, [[propValue]], sel));
      }
    });

    UndoManager.instance.push(new MultipleCommands(commands));
  }

  containsPoint(pt:Point) {
    return this._selections.find(selection => selection.containsPoint(pt)) != null;
  }

  // only looks at entire rows that are selected
  containsRow(row:number) {
    return this._selections.find(selection => selection.containsRow(row) && selection.allColsSelected) != null;
  }

  // only looks at entire cols that are selected
  containsCol(col:number) {
    return this._selections.find(selection => selection.containsCol(col) && selection.allRowsSelected) != null;
  }
}