import { camelCase } from 'lodash-es';
import { AxiosError, AxiosResponse } from "axios";

import { ApiResponse } from './ApiResponse';

export function standardizeAxiosApiError(e:AxiosError | Error, paths:string[] = [], camelPaths:boolean = false):ApiResponse {
  const response:AxiosResponse = (e as AxiosError).response;

  if (!response || !response.data || !response.data.errors) {
    return {
      success: false,
      errors: [{
        message: e.message || e.toString(),
        path: [] as string[]
      }]
    }
  }

  return {
    success: false,
    errors: typeof response.data.errors == 'string' 
      ? [{
        message: response.data.errors,
        path: []
      }]
      : Object.keys(response.data.errors).map(key => {
      if (key === 'full_messages') {
        return;
      }

      return {
        message: response.data.errors[key]?.toString(),
        path: paths.includes(key) ? [camelPaths ? camelCase(key) : key] : []
      }
    }).filter(e => e !== undefined)
  }
}
