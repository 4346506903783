import React from 'react';
import { useHistory } from 'react-router'
import queryString from 'query-string'

// this is a utility that helps track if
// a "mode" is turned "on" in the app, via
// url query param other mechanism.  the modes
// are globally tracked.  once mode is set
// via url, it is never removed.  this way a
// mode can be turned on, without having to snake
// the flag through all url navigation.  this should be
// placed below react router so that it re-renders on navigation

// warning - keep in mind that these are settable on the url
// which means any bad actor can set these values

export const modes:any = {};

interface Props {
  // specifies the valid app modes, other query params are ignored
  modes:string[];
}

export function AppModes(props:React.PropsWithChildren<Props>) {
  const history = useHistory();
  const options = queryString.parse(history.location.search);

  for (const mode in options) {
    if (props.modes.indexOf(mode) == -1) {
      continue;
    }

    modes[mode] = parseVal(options[mode]);
  }

  return props.children;
}

function parseVal(val:string) {
  if (val == 'true') {
    return true;
  }

  if (val == 'false') {
    return false;
  }

  if (isFinite(val as any)) {
    return Number(val)
  }

  return val;
}

export function getAppModes() {
  return modes;
}

export function hasAppMode(mode:string) {
  return mode in modes;
}

export function getAppMode(mode:string, def?:any) {
  return mode in modes ? modes[mode] : def;
}

export function setAppMode(mode:string, value:any = true) {
  modes[mode] = value;
}
