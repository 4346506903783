import * as React from 'react';
import { isEmpty, last, sumBy } from 'lodash-es';

import { DiscountFormat, ChangePricingPeriod, PriceConfig, PriceConfigKind } from 'app2/api';
import { noOpErrorHandler } from 'app2/components';
import { EnrollmentConfig } from 'app2/views/shared-public';

import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

import { EnrollmentPriceBreakdownSelections, EnrollmentPriceBreakdownQueryVariables, useEnrollmentPriceBreakdownQuery } from './generated';

type Charge = EnrollmentPriceBreakdownSelections['first']['charges'][0];

export interface AddEditEnrollmentBreakdown {
  // first is only present if the first
  // charge is a) prorated, b) not billed by the system, but billed now/registration
  first?: {
    listPrice: number;
    date: string;
    refund?: number;
    prorated?: boolean;
  } & SellerFields;
  next?: {
    listPrice: number;
    prorated: boolean;
    date: string;
  } & SellerFields;
  // first regular ongoing payment
  ongoing?: {
    date: string;
  } & SellerFields;
  // standard non-prorated pricing
  // this is needed regardless of whether there will be a
  // ongoing bill (for non-subscription and because even
  // for subscription we show this info)
  standard?: {
    listPrice: number;
    listPriceBeforeDiscount: number;
    discountAmount: number;
    priceConfig: Partial<PriceConfig>;
  } & SellerFields;
}

interface SellerFields {
  homeroomCharges?: number;
  siteCharges?: number;
  siteCreditCardFee?: number;
  siteReceives?: number;
  vendorCharges?: number;
  vendorCreditCardFee?: number;
  vendorReceives?: number;
}

export interface UseEnrollmentBreakdownProps {
  course: string;
  format?: DiscountFormat;
  rate?: number;
  discount?:string;
  config?: EnrollmentConfig;
  changingEnrollment?: DistinctEnrollmentsSelections;
  effective?: ChangePricingPeriod;
  pause?: boolean;
}

export function useEnrollmentBreakdown(props:UseEnrollmentBreakdownProps): AddEditEnrollmentBreakdown {
  const {course, format, rate, discount, config, changingEnrollment, effective, pause} = props;

  const all = getBreakdowns();
  const breakdown = extractBreakdowns() as AddEditEnrollmentBreakdown;

  function getBreakdowns() {
    const invalidConfig = !config || !config.kind;
    const variables: EnrollmentPriceBreakdownQueryVariables = {
      id: course,
      changingEnrollment: changingEnrollment?.id,
      discountId: discount,
      kind: config?.kind,
      recurring: config?.recurring,
      dropIn: config?.dropIn,
      configurableSeason: config?.configurableSeason,
      custom: config?.custom,
      effective
    };
    if (format || rate) {
      variables.discount = { format, rate };
    }
    const [result] = useEnrollmentPriceBreakdownQuery({
      variables,
      pause: pause || invalidConfig,
      hideLoader: true,
      debounce: { delay: format ? 500 : 0 },
      error: noOpErrorHandler
    });

    return invalidConfig ? null : result.data?.enrollmentPriceBreakdown;
  }

  function extractBreakdowns() {
    return React.useMemo(() => {
      if (!all || isEmpty(all)) {
        return { standard: null, first: null } as AddEditEnrollmentBreakdown;
      }

      const priceConfig = all.priceConfig;
      const standard = all.standard;

      if (priceConfig.kind === PriceConfigKind.Usage) {
        return {
          standard: {
            ...standard,
            listPrice: standard.amount,
            listPriceBeforeDiscount: standard.listPrice,
            discountAmount: standard.discountAmount,
            priceConfig
          }
        };
      }

      if (priceConfig.kind === PriceConfigKind.DropIn) {
        return {
          standard: sumDropIns(all.first.charges, priceConfig)
        };
      }

      const first = all.first;
      const future = all.future;
      const firstCharge = last(first.charges);
      const futureCharge = last(future?.charges);
      const result: AddEditEnrollmentBreakdown = {
        first: {
          ...firstCharge,
          listPrice: sumBy(first.charges, 'amount'),
          date: firstCharge.date,
          refund: first.refund,
          prorated: firstCharge.prorated
        },
        next: {
          ...(futureCharge || {}),
          listPrice: sumBy(future?.charges, 'amount'),
          date: futureCharge?.date,
          prorated: futureCharge?.prorated
        },
        standard: {
          ...(standard || {}),
          listPrice: standard?.amount,
          listPriceBeforeDiscount: standard?.listPrice,
          discountAmount: standard?.discountAmount,
          priceConfig
        },
        ongoing: {
          ...(futureCharge || {}),
          date: futureCharge?.date
        }
      };

      return result;
    }, [all]);
  }

  function sumDropIns(dropIns: Charge[], priceConfig: EnrollmentPriceBreakdownSelections['priceConfig']) {
    return dropIns.reduce<AddEditEnrollmentBreakdown['standard']>(
      (aggregate, breakdown) => {
        return {
          ...aggregate,
          discountAmount: aggregate.discountAmount + breakdown.discountAmount,
          listPrice: aggregate.listPrice + breakdown.amount,
          listPriceBeforeDiscount: aggregate.listPriceBeforeDiscount + breakdown.listPrice
        };
      },
      { priceConfig, discountAmount: 0, listPrice: 0, listPriceBeforeDiscount: 0, homeroomCharges: 0, siteCharges: 0, siteCreditCardFee: 0, siteReceives: 0, vendorCharges: 0, vendorCreditCardFee: 0, vendorReceives: 0 }
    );
  }

  return breakdown;
}
