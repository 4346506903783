import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateGradesMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  grades: Array<Types.GradeInput> | Types.GradeInput;
}>;

export type OrganizerUpdateGradesMutation = {
  siteUpdateGrades?: { site?: { id: string; grades?: Array<{ label: string; value: string; hidden?: boolean | null | undefined }> | null | undefined } | null | undefined } | null | undefined;
};

export const OrganizerUpdateGradesDocument = gql`
  mutation OrganizerUpdateGrades($site: ID!, $grades: [GradeInput!]!) {
    siteUpdateGrades(site: $site, grades: $grades) {
      site {
        id
        grades {
          label
          value
          hidden
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateGradesOptions = ExecMutationOptions<OrganizerUpdateGradesMutationVariables>;

export function organizerUpdateGrades(options: OrganizerUpdateGradesOptions) {
  return executeMutation<OrganizerUpdateGradesMutation, OrganizerUpdateGradesMutationVariables>(OrganizerUpdateGradesDocument, options);
}
