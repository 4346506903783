import * as React from 'react'
import { MessageCircle, X } from 'react-feather'

import { HBox, getAppMode, Rect, useLifecycle, useInterval, Viewport } from 'app2/components'

import { useRouteInfo } from './page'

export function HelpScoutButton() {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLElement>();
  const [isCovered, setIsCovered] = React.useState(false);
  const resetInterval = useInterval(checkPosition, 1000);
  const routeInfo = useRouteInfo();
  const on = routeInfo?.route?.props?.helpscout !== false;

  useLifecycle({onMount});

  function onMount() {
    window.Beacon('on', 'open', () => setOpen(true));
    window.Beacon('on', 'close', () => setOpen(false));
    window.addEventListener('scroll', onScroll, true);
  }

  function onScroll() {
    resetInterval();
  }

  function checkPosition() {
    if (!ref.current) {
      return;
    }
    
    const buttonRect = new Rect(ref.current.getBoundingClientRect());
    const covered = Array.from(document.querySelectorAll('button, input, textarea')).find(hit) != null;

    function hit(e:Element) {
      const overlaps = !buttonRect.intersection(e.getBoundingClientRect()).empty;

      return overlaps
        ? window.getComputedStyle(e)?.pointerEvents != 'none'
        : false;
    }

    if (covered != isCovered) {
      setIsCovered(covered);
    }
  }

  function render() {
    if (!on) {
      return <></>;
    }

    return <Viewport>
      <HBox ref={ref} transition='opacity .5s' opacity={isCovered ? .1 : 1} pointerEvents={isCovered ? 'none' : 'all'} onClick={() => window.Beacon('toggle')} width='60px' height='60px' borderRadius='30px' cursor='pointer'
        position='absolute' right='20px' bottom={['70px', '10px', '10px']} zIndex={5000} background='rgb(0, 59, 59)' hAlign='center' vAlign='center'>
        {open ? <X color='white'/> : <MessageCircle color='white' style={{transform: 'scaleX(-1)' }} size={28} />}
      </HBox>
    </Viewport>
  }

  return getAppMode('support', true) ? render() : <></>;
}
