import * as React from 'react';

export function useFormPanelEditingState(editingInitial: boolean = false, cb?: (editing: boolean) => void) {
  const [editing, setEditing] = React.useState(editingInitial);

  function handleToggleEditing(editing: boolean) {
    setEditing(editing);

    if (cb) {
      cb(editing);
    }
  }

  return { editing, handleToggleEditing };
}
