import { get } from "lodash-es";
import { isNullOrUndefined } from 'util';
import moment from 'moment';

import { ObservableCollection, ObservableCollectionObserver } from "./ObservableCollection";
import { CollectionEventType, CollectionEvent } from "./CollectionEvent";

export const NEW_ITEM_PREFIX = '__new';

export class ReadOnlyObservableCollectionArray<T> implements ObservableCollection<T> {
  data:T[];
  idProperty:string;

  constructor(data:T[], idProperty?:string) {
    this.data = data;
    this.idProperty = idProperty;
  }

  get length():number {
    return this.data.length;
  }

  getItem(position:number):T {
    return this.data[position];
  }

  getValue(position:number, property:string):T {
    return !property
      ? this.data[position]
      : get(this.data[position], property);
  }

  getId?(position:number): number | string {
    return this.idProperty ? (this.getItem(position) as any)[this.idProperty] : position;
  }

  getIndex?(id:number | string): number {
    return this.idProperty ? this.data.findIndex((_, index) => this.getId(index) == id) : id as number;
  }
}

export class ObservableCollectionArray<T> extends ReadOnlyObservableCollectionArray<T> implements ObservableCollection<T> {
  observers:ObservableCollectionObserver<T>[];

  constructor(data:any[], idProperty?:string) {
    super(data, idProperty);
    this.observers = [];
  }

  setValue(position:number, property:string, value:any):void {
    (this.data[position] as any)[property] = value;
    this.notifyObservers({type:CollectionEventType.update, position, item: this.data[position]});
  }

  append():T {
    const appended:any = {} as T;

    if (this.idProperty) {
      assignId(appended, this.idProperty);
    }

    this.data.push(appended);
    this.notifyObservers({type:CollectionEventType.create, position: this.data.length - 1, item: appended});

    return appended;
  }

  insert?(position:number, item:T):void {
    assignId(item, this.idProperty);

    this.data.splice(position, 0, item);
    this.notifyObservers({type:CollectionEventType.create, position: position, item});
  }

  remove(position:number):T {
    const removed = this.data.splice(position, 1)[0];
    this.notifyObservers({type:CollectionEventType.delete, position, item: removed});

    return removed;
  }

  notifyObservers(event:Partial<CollectionEvent<T>>) {
    event.collection = this;
    this.observers.slice().forEach(observer => observer(event as CollectionEvent<T>));
  }

  observe(observer:ObservableCollectionObserver<T>):void {
    this.observers.push(observer);
  }

  unobserve(observerToRemove:ObservableCollectionObserver<T>):void {
    const pos = this.observers.findIndex(observer => observer == observerToRemove);

    if (pos != -1) {
      this.observers.splice(pos, 1);
    }
  }
}

let newCount:number = 0;

export function assignId(itemOrItems:any[] | any, idProperty:string = 'id') {
  if (!idProperty || !itemOrItems || moment.isMoment(itemOrItems)) {
    return;
  }

  if (Array.isArray(itemOrItems)) {
    itemOrItems.forEach(item => assignId(item, idProperty));
  }
  else
  if (typeof itemOrItems === 'object' && itemOrItems && isNullOrUndefined(itemOrItems.id)) {
    itemOrItems[idProperty] = NEW_ITEM_PREFIX + (newCount++);
  }

  return itemOrItems;
}

export function hasNewId(item:any, idProperty:string = 'id') {
  if (!idProperty || !item) {
    return false;
  }

  return isNewId(item[idProperty]);
}

export function isNewId(id:string) {
  return id && id.toString().startsWith(NEW_ITEM_PREFIX)
}
