import * as React from 'react';

import { CourseUtils } from 'app2/api';
import { ActionButton, DataTable } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { MoveStudentsModal } from './MoveStudentsModal';

import { CourseSelections } from '../../generated';

export function renderMoveButton(course: CourseSelections, table: DataTable) {
  const abilities = CourseUtils.getCourseAbilities(course);

  if (!abilities.moveable || !course?.behavior?.enrollmentsMove) {
    return;
  }

  const units = courseKindBehavior[course.kind].units.toLowerCase();

  return <ActionButton icon="CornerUpRight" autoLoader={false} tooltip={`Move student to a different ${units}`} onClick={() => <MoveStudentsModal course={course} enrollments={table.selectedItems} />}>Move</ActionButton>
}
