export * from './account';
export * from './company';
export * from './error';
export * from './events';
export * from './graphql';
export * from './organizer';
export * from './parent';
export * from './public';
export * from './shared';
export * from './shared-authorized';
export * from './teacher';
export * from './query';
export * from './urql';

// these are missing from the version of lodash-es we are using
export interface DebounceSettings {
	/**
	 * The number of milliseconds to delay.
	 * @default 0
	 */
	wait?: number;

	/**
	 * The maximum time `func` is allowed to be delayed before it's invoked
	 * @default null
	 */
	maxWait?: number | null;
}

export interface Cancelable {
  cancel(): void;
  flush(): void;
}
