import * as React from 'react'
import { useParams } from 'react-router';

import { Panel, Section, InputField, FormModel, useForm } from 'app2/components'
import { DonationConfig } from 'app2/api'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerDonationConfigQueryQuery } from './gql'
import { organizerUpdateDonationConfig, OrganizerUpdateDonationConfigMutationVariables } from './gql'


export function Tax() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ donationConfigQueryResult ] = useOrganizerDonationConfigQueryQuery({variables: { siteId }});
  const site = donationConfigQueryResult.data?.site
  const donationConfig = site?.donationConfig

  const form = useForm<DonationConfig>(donationConfig, [donationConfig]);

  function render() {
    return <Panel icon='DollarSign' title='Taxes' type='toggle' form={form} subtitle='If specified, this EIN will be included on receipts for parents' onOk={onOk}>
        <Section name='siteEin' label='Tax ID (EIN)' component={InputField} />
    </Panel>
  }

  async function onOk(form:FormModel<DonationConfig>) {
    const variables: OrganizerUpdateDonationConfigMutationVariables = { siteId: site.id, donationConfig: form.values }
    const [success] = await organizerUpdateDonationConfig({variables, successMsg: 'Tax settings updated.', error: form})

    return success;
  }

  return render();
}

