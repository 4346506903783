import pluralize from 'pluralize';

import { RecurringRate, RecurringUnit } from 'app2/api';

export function formatRecurringRates(items:RecurringRate[]) {
  return items?.map(formatRecurringRate).join('\n');
}

export function formatRecurringRate(item:RecurringRate) {
  const labels = {
    [RecurringUnit.Month]: 'month',
    [RecurringUnit.Week]: 'week'
  }

  return `${item.days || '?'} ${pluralize('day', item.days)}/week for $${Number.isFinite(item.rate) ? item.rate : '?'} per ${labels[item.unit] || '?'}`
}
