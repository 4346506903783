import * as React from 'react';

import { ActionButton, DataTable, Modal } from 'app2/components';

import { CourseSelections } from '../../../generated';
import { RefundModal } from './RefundModal';

export function renderRefundButton(course: CourseSelections, table: DataTable) {
  if (!course?.behavior?.enrollmentsRefund) {
    return;
  }
  
  return <ActionButton icon="Refund" autoLoader={false} tooltip="Refund and optionally remove" onClick={() => Modal.show(<RefundModal course={course} enrollments={table.selections.selectedItems} />)}>
    Refund
  </ActionButton>
}
