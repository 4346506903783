import * as React from 'react'

import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useAdminSitesQuery, AdminSitesSelections } from './generated';

export function SitesDropdown(props:Omit<SearchDropdownProps<AdminSitesSelections>, 'search'>) {
  function render() {
    return <SearchDropdown query={useAdminSitesQuery} renderItem={renderSite} {...props} />
  }

  function renderSite(site:AdminSitesSelections) {
    return `${site.name} (${site.id})`
  }

  return render();
}
