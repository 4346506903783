import * as React from 'react';

import { DatePicker, MenuDropdown, MenuItem, MenuSeparator } from 'app/app2/components';

import { Demo } from './Demo';

export function MenuDemos() {
  return <>
    <Demo name='Basic'>
      <MenuDropdown label='Test' rightAligned={false}>
        <MenuItem label='Account settings' />
        <MenuItem label='Help desk' />
        <MenuItem label='Logout' />
        <MenuItem label='Click me to see a loader' onClick={async () => await sleep(5000)} />
      </MenuDropdown>
    </Demo>

    <Demo name='Nested'>
      <MenuDropdown label='Test' rightAligned={false}>
        <MenuItem label='Last 30 days' />
        <MenuItem label='Last 60 days' />
        <MenuSeparator />
        <MenuDropdown label='Months' rightAligned={false}>
          <MenuItem label='January' />
          <MenuItem label='February' />
          <MenuItem label='March' />
          <MenuItem label='April' />
        </MenuDropdown>
        <MenuDropdown label='Custom period' rightAligned={false}>
          <DatePicker inline type='range' />
        </MenuDropdown>
      </MenuDropdown>
    </Demo>
  </>
}

async function sleep(time:number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
