import { UseQueryResponse } from 'urql'

import { providerPreferences } from 'app2/api';
import { UseQueryArgs } from 'app2/views/shared/urql';

import { useCompanyParameter } from './useCompanyParameter';

type Result<T> = {company?:T};
type CompanyFrameworksArgs<T> = Omit<UseQueryArgs<{company:string}, Result<T>>, 'query'>;
type Query<T> = (args:CompanyFrameworksArgs<T>) => UseQueryResponse<Result<T>>;

export function useCompanyQuery<T>(query:Query<T>) {
  const companyId = useCompanyParameter();
  const [result] = query({variables:{company:companyId}});
  const company = result.data?.company;
  const fetching = result.fetching;
  const prefs = companyId ? providerPreferences.companyPreferences(companyId) : null;

  return {company, prefs, id:companyId, fetching};
}
