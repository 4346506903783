import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateReplyMutationVariables = Types.Exact<{
  fromEntity?: Types.InputMaybe<Types.MessageEntityInput>;
  replyTo: Types.Scalars['ID'];
  subject?: Types.InputMaybe<Types.Scalars['String']>;
  body: Types.Scalars['String'];
}>;

export type CreateReplyMutation = { messageThreadReply?: { message?: { id: string } | null | undefined } | null | undefined };

export const CreateReplyDocument = gql`
  mutation CreateReply($fromEntity: MessageEntityInput, $replyTo: ID!, $subject: String, $body: String!) {
    messageThreadReply(fromEntity: $fromEntity, replyTo: $replyTo, subject: $subject, body: $body) {
      message {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CreateReplyOptions = ExecMutationOptions<CreateReplyMutationVariables>;

export function createReply(options: CreateReplyOptions) {
  return executeMutation<CreateReplyMutation, CreateReplyMutationVariables>(CreateReplyDocument, options);
}
