import * as React from 'react';
import * as qs from 'query-string';
import { Location } from 'history';

import {
  CardRow,
  HBox,
  Link,
  Subtitle1,
  useBreakpoints,
  VBox,
  Loader
} from 'app2/components';
import { PaginateResult } from 'app2/api';
import { SeeMoreCard } from './SeeMoreCard';
import { CardNavigation } from './CardNavigation';

interface CardProps<T> {
  item: T;
}

interface Props<T, M = {}> {
  results: PaginateResult<T, M>;
  url: string;
  location: Location;
  CardComponent: React.ComponentType<CardProps<T>>;
  previewAmount: number;
  pageParam: string;
  title: string;
  seeMorePath: string;
  seeMoreCardRatio: string;
  onSeeMoreLinkClick: () => void;
  onSeeMoreCardClick: () => void;
}

export function CardPreviewRow<T, M = {}>(props: Props<T, M>) {
  const {
    CardComponent,
    onSeeMoreCardClick,
    onSeeMoreLinkClick,
    pageParam,
    previewAmount,
    results,
    seeMoreCardRatio,
    title,
    seeMorePath
  } = props;

  if (!results) {
    return (
      <VBox hAlign="center" mb="$30" width="100%">
        <Loader />
      </VBox>
    );
  }

  const url = props.url;
  const params = qs.parse(props.location.search);
  const breakpoint = useBreakpoints();
  const numPerRow = [3, 2, 3][breakpoint];

  const numItemsRetreived = results.records.length;
  const numItemsTotal = results.pagination.count;
  const needsSeeMoreCard = numItemsTotal > previewAmount;
  const numCards = needsSeeMoreCard ? numItemsRetreived + 1 : numItemsRetreived;
  const numPages = Math.ceil(numCards / numPerRow) || 0;
  const needNav = breakpoint != 0 && numPages > 1;
  const page = Math.min(Math.max(params[pageParam] || 0, 0), numPages - 1);
  const prevPage = page == 0 ? numPages - 1 : page - 1;
  const nextPage = page == numPages - 1 ? 0 : page + 1;
  const pageStart = page * numPerRow;
  const cards = results.records
    .slice(pageStart, pageStart + numPerRow)
    .map((item, idx) => <CardComponent key={idx} item={item} />);
  const isLastPage = page == numPages - 1;
  const showSeeMoreCard = isLastPage && needsSeeMoreCard;

  if (showSeeMoreCard) {
    cards.push(
      <SeeMoreCard
        link={
          <Link
            to={`${url}${seeMorePath}`}
            text="subtitle1"
            onClick={onSeeMoreCardClick}
          >
            See all ({results.pagination.count})
          </Link>
        }
        ratio={seeMoreCardRatio}
      />
    );
  }

  const prevPageParams = { ...params, [pageParam]: prevPage };
  const nextPageParams = { ...params, [pageParam]: nextPage };
  const prevPageQueryString = qs.stringify(prevPageParams);
  const nextPageQueryString = qs.stringify(nextPageParams);

  return (
    <VBox mb="$60" maxWidth="100%" minWidth="100%">
      <HBox mb="$10" maxWidth="100%" minWidth="100%" vAlign="center">
        <Subtitle1>{title}</Subtitle1>
        <HBox flex="1" />
        <Link
          to={`${url}${seeMorePath}`}
          text="subtitle2"
          mr={['unset', '$30']}
          onClick={onSeeMoreLinkClick}
        >
          See all ({results.pagination.count})
        </Link>
        {needNav && (
          <CardNavigation
            prevPage={`${url}?${prevPageQueryString}`}
            nextPage={`${url}?${nextPageQueryString}`}
          />
        )}
      </HBox>
      <CardRow
        layout={['vbox', 'hbox']}
        items={cards}
        numPerRow={breakpoint == 0 ? 1 : numPerRow}
      />
    </VBox>
  );
}

CardPreviewRow.defaultProps = {
  previewAmount: 11
};
