import * as React from 'react'

import { Panel, PanelProps, Section, TextAreaField } from 'app/app2/components'

export function BaseBeforeActivity(props:PanelProps) {
  return <Panel icon='Shield' title='Pre-activity' {...props}>
    <Section label='Instructor check-in' name='teacherCheckin' component={TextAreaField} />
    <Section label='Student pick-up process' name='studentPickup' component={TextAreaField} />
    <Section label='Missing student' name='missingStudent' component={TextAreaField} />
  </Panel>
}
