import * as React from 'react';

import { CourseUtils, CourseWithRoles, Course, authorizedPreferences } from 'app2/api';

import { GeneratedQueryHook, GeneratedQueryArgs, HrDataTable, DataTableProps, HrDataTableHeaderProps } from '../../datatable';
import { autoPauseQuery } from '../../urql';

interface Props<T> {
  course: Pick<Course, 'id' | 'status'> & CourseWithRoles;
  header: HrDataTableHeaderProps;
  table: DataTableProps<T>;
  prefsVersion?: string;
  prefsKey?: string;
  queryHook: GeneratedQueryHook;
  queryOptions?: GeneratedQueryArgs;
  view:{table:string};
}

export function EnrollmentsTable<T = any>(props: Props<T>) {  
  function render() {
    const pause = (!props.course || CourseUtils.isRequest(props.course)) || (props.queryOptions && autoPauseQuery(props.queryOptions));

    const entityInfo = CourseUtils.getEntityInfo(props.course);
    const views = entityInfo ? {...entityInfo, ...props.view} : undefined;

    return (
      <HrDataTable<T>
        header={{ units: 'students', mb: '15px', ...props.header }}
        views={views}
        table={props.table}
        prefs={authorizedPreferences.coursePreferences(props.course?.id)}
        prefsKey={props.prefsKey}
        prefsVersion={props.prefsVersion}
        queryHook={props.queryHook}
        queryOptions={{ pause, ...props.queryOptions, variables: { groupingId: props.course?.id, ...props.queryOptions?.variables } }}
      />
    );
  }

  return render();
}
