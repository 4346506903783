import * as React from 'react';
import { Action, Location, History } from 'history';
import { useHistory } from 'react-router';

import { useLifecycle } from '..';

export type HistoryAction = Action | 'MOUNT';
export type LocationListener = (location: Location, action: HistoryAction, prevLocation: Location, history:History) => void;

export function useHistoryListener(listener: LocationListener, triggerOnMount?:boolean) {
  const history = useHistory();
  const historyListener = React.useRef({unregister: null, listener: listener, prevLocation: history.location});

  historyListener.current.listener = listener;

  useLifecycle({onMount, onUnmount});

  function onMount() {
    addHistoryListener();

    if (triggerOnMount) {
      onHistoryChange(history.location, 'MOUNT');
    }
  }

  function onUnmount() {
    removeHistoryListener();
  }

  function addHistoryListener() {
    historyListener.current.unregister = history.listen(onHistoryChange);
  }

  function removeHistoryListener() {
    historyListener.current.unregister();
    historyListener.current.unregister = null;
  }

  function onHistoryChange(location: Location, action: HistoryAction) {
    const prevLocation = historyListener.current.prevLocation;
    historyListener.current.prevLocation = location;

    historyListener.current.listener(location, action, prevLocation, history);
  }
}
