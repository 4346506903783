import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SingleAdminSiteQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SingleAdminSiteQuery = {
  site?:
    | {
        id: string;
        name?: string | null | undefined;
        slug: string;
        streetAddress?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipcode?: string | null | undefined;
        timezone?: string | null | undefined;
        searchable?: boolean | null | undefined;
        retired?: boolean | null | undefined;
        partner: boolean;
        active?: boolean | null | undefined;
        stripeAccount?: string | null | undefined;
        homeroomRate?: number | null | undefined;
        txnFeeRate?: number | null | undefined;
        hubspotId?: string | null | undefined;
        organizationType?: Types.Organization | null | undefined;
      }
    | null
    | undefined;
};

export const SingleAdminSiteDocument = gql`
  query SingleAdminSite($id: ID!) {
    site(site: $id) {
      id
      name
      slug
      streetAddress
      city
      state
      zipcode
      timezone
      searchable
      retired
      partner
      active
      stripeAccount
      homeroomRate
      txnFeeRate
      hubspotId
      organizationType
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SingleAdminSiteArgs = MakeOptional<UseQueryArgs<SingleAdminSiteQueryVariables, SingleAdminSiteQuery>, 'query'>;

export function useSingleAdminSiteQuery(options: SingleAdminSiteArgs = {}) {
  return useQuery<SingleAdminSiteQueryVariables, SingleAdminSiteQuery>({ query: SingleAdminSiteDocument, ...options });
}

export type SingleAdminSiteOptions = ExecQueryOptions<SingleAdminSiteQueryVariables>;

export function singleAdminSite(options: SingleAdminSiteOptions) {
  return executeQuery<SingleAdminSiteQuery, SingleAdminSiteQueryVariables>(SingleAdminSiteDocument, options);
}

export type SingleAdminSiteSelections = SingleAdminSiteQuery['site'];
