import { SeasonRate } from 'app2/api';

export function parseConfigurableSeasonRates(rate: string | SeasonRate[]) {
  if (Array.isArray(rate)) {
    return rate;
  }

  if (typeof rate !== 'string') {
    return [];
  }

  const re = /([1-7]) days?\/week for \$?([0-9]+)?/gim;
  const rates = [...(rate as any).matchAll(re)].map(r => {
    return {
      days: parseInt(r[1]),
      rate: parseInt(r[2])
    };
  });

  return rates;
}
