import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertDiscountsMutationVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  ownerType?: Types.InputMaybe<Types.Scalars['String']>;
  discounts: Array<Types.DiscountDefinitionInput> | Types.DiscountDefinitionInput;
}>;

export type OrganizerUpsertDiscountsMutation = { discountDefinitionsUpsert?: { discounts?: Array<{ id: string }> | null | undefined } | null | undefined };

export const OrganizerUpsertDiscountsDocument = gql`
  mutation OrganizerUpsertDiscounts($ownerId: ID!, $ownerType: String, $discounts: [DiscountDefinitionInput!]!) {
    discountDefinitionsUpsert(ownerId: $ownerId, ownerType: $ownerType, discounts: $discounts) {
      discounts {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertDiscountsOptions = ExecMutationOptions<OrganizerUpsertDiscountsMutationVariables>;

export function organizerUpsertDiscounts(options: OrganizerUpsertDiscountsOptions) {
  return executeMutation<OrganizerUpsertDiscountsMutation, OrganizerUpsertDiscountsMutationVariables>(OrganizerUpsertDiscountsDocument, options);
}
