import * as React from 'react';

import { EmailField, Field, Form, PhoneField, RepeatingSection, Section } from 'app2/components';

import { CompanyVendorSelections } from './generated';

interface Props {
  vendor:CompanyVendorSelections;
}

export function Details(props: Props) {
  const vendor = React.useMemo(() => ({...props?.vendor, site: {...props?.vendor?.site, contact:[props?.vendor?.site?.contact]}}), [props.vendor]);

  function render() {
    return <Form initialValues={vendor} editing={false} onNavigation='nothing'>
      <Section label="Name" name="site.name" />
      <Section label="Address" name="site" format={(site:CompanyVendorSelections['site']) => `${site?.streetAddress}\n${site?.city}, ${site?.state} ${site?.zipcode}`} />
      <Section label="Contact">
        <RepeatingSection name="site.contact" numbered={false} fields={[
          <Field label="Name" name="name" />, 
          <Field label="Email" name='email' component={EmailField} />, 
          <Field label="Phone" name="phone" component={PhoneField} />
        ]} />
      </Section>
    </Form>
  }

  return render();
}
