import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { BreakdownFieldsFragmentDoc, ConfigurableSeasonBreakdownFieldsFragmentDoc, RecurringBreakdownFieldsFragmentDoc, UsageBreakdownFieldsFragmentDoc } from './breakdownFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CoursePriceBreakdownsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  rates?: Types.InputMaybe<Types.RatesInput>;
  feeRate?: Types.InputMaybe<Types.Scalars['Float']>;
  feeType?: Types.InputMaybe<Types.SiteFeeTypeEnum>;
  discountFormat?: Types.InputMaybe<Types.DiscountFormat>;
  discountRate?: Types.InputMaybe<Types.Scalars['Float']>;
}>;

export type CoursePriceBreakdownsQuery = {
  coursePriceBreakdowns?:
    | {
        season?:
          | {
              discountAmount?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              listPrice?: number | null | undefined;
              listPriceBeforeDiscount?: number | null | undefined;
              price?: number | null | undefined;
              priceBeforeDiscount?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
            }
          | null
          | undefined;
        seasons?:
          | Array<{
              discountAmount?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              listPrice?: number | null | undefined;
              listPriceBeforeDiscount?: number | null | undefined;
              price?: number | null | undefined;
              priceBeforeDiscount?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
              days?: number | null | undefined;
            }>
          | null
          | undefined;
        recurring?:
          | Array<{
              discountAmount?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              listPrice?: number | null | undefined;
              listPriceBeforeDiscount?: number | null | undefined;
              price?: number | null | undefined;
              priceBeforeDiscount?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
              days?: number | null | undefined;
              unit?: Types.RecurringUnit | null | undefined;
            }>
          | null
          | undefined;
        dropIn?:
          | {
              discountAmount?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              listPrice?: number | null | undefined;
              listPriceBeforeDiscount?: number | null | undefined;
              price?: number | null | undefined;
              priceBeforeDiscount?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
            }
          | null
          | undefined;
        usage?:
          | Array<{
              discountAmount?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              listPrice?: number | null | undefined;
              listPriceBeforeDiscount?: number | null | undefined;
              price?: number | null | undefined;
              priceBeforeDiscount?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
              unit?: Types.UsageUnit | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CoursePriceBreakdownsDocument = gql`
  query CoursePriceBreakdowns($id: ID!, $rates: RatesInput, $feeRate: Float, $feeType: SiteFeeTypeEnum, $discountFormat: DiscountFormat, $discountRate: Float) {
    coursePriceBreakdowns(id: $id, rates: $rates, feeRate: $feeRate, feeType: $feeType, discountFormat: $discountFormat, discountRate: $discountRate) {
      season {
        ...breakdownFields
      }
      seasons {
        ...configurableSeasonBreakdownFields
      }
      recurring {
        ...recurringBreakdownFields
      }
      dropIn {
        ...breakdownFields
      }
      usage {
        ...usageBreakdownFields
      }
    }
  }
  ${BreakdownFieldsFragmentDoc}
  ${ConfigurableSeasonBreakdownFieldsFragmentDoc}
  ${RecurringBreakdownFieldsFragmentDoc}
  ${UsageBreakdownFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CoursePriceBreakdownsArgs = MakeOptional<UseQueryArgs<CoursePriceBreakdownsQueryVariables, CoursePriceBreakdownsQuery>, 'query'>;

export function useCoursePriceBreakdownsQuery(options: CoursePriceBreakdownsArgs = {}) {
  return useQuery<CoursePriceBreakdownsQueryVariables, CoursePriceBreakdownsQuery>({ query: CoursePriceBreakdownsDocument, ...options });
}

export type CoursePriceBreakdownsOptions = ExecQueryOptions<CoursePriceBreakdownsQueryVariables>;

export function coursePriceBreakdowns(options: CoursePriceBreakdownsOptions) {
  return executeQuery<CoursePriceBreakdownsQuery, CoursePriceBreakdownsQueryVariables>(CoursePriceBreakdownsDocument, options);
}

export type CoursePriceBreakdownsSelections = CoursePriceBreakdownsQuery['coursePriceBreakdowns'];
