import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  name:string;
}

interface Props {
  info?:FieldInfo<NameAndId>;
  user?:NameAndId;
}

export function UserLink(props:Props) {
  const user = props.user || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/users/${user?.id}`}>{user?.name}</Link>
}

UserLink.fieldProps = {
  fieldProperty: 'info',
}