import * as React from 'react';

import { InputField, Modal, Form, Section, Text, useForm, VBox } from 'app2/components';

import { StudentDropdown } from '../shared';
import { AdminStudentSelections, mergeStudents } from './generated';

interface Props {
  student:AdminStudentSelections;
}

export function MergeStudents(props:Props) {
  const form = useForm<{student:AdminStudentSelections}>({}, []);
  const source = form.values.student?.name ? `${form.values.student.name} (${form.values.student.id})` : '[select student]';
  const destination = `${props.student.name} (${props.student.id})`;

  function render() {
    return <Modal title='Merge families' danger info={{type:'warning', message: 'This can not be undone', small: true, title: false, layout: 'hbox'}}>
      <Form form={form} onOk={onOk} editing>
        <Section label={`Choose which student to merge into ${destination}.  The student must be in the same family as ${destination}`} name='student' component={StudentDropdown} />
        <Section label='Type merge! to confirm you want to merge' name='merge' validators={validateMerge} component={InputField} />
        <VBox>
          <Text text='body'>This will move the following <b>from</b> {source} <b>to</b> {destination}:</Text>
          <Text text='body'>- enrollments that don't overlap in activities</Text>
          <Text text='body'>- attendance records that don't overlap in activities</Text>
          <Text text='body'>- registration question answers that don't overlap in questions</Text>
          <Text text='body'>- medical log entries</Text>
          &nbsp;
          <Text text='body'>These will <b>not</b> be changed:</Text>
          <Text text='body'>- enrollments & attendances in overlapping activities</Text>
          <Text text='body'>- registration question answers in overlapping questions</Text>
          <Text text='body'>- the {destination} name, nickname, age, grade, classroom and medical notes (medications, allergies, etc) will be <b>kept</b> and {source}'s <b>will not be copied</b></Text>
          <Text text='body'>- {source}'s version history will stay on {source}</Text>
          <Text text='body'>- {source}'s event history will stay on {source}</Text>
        </VBox>
      </Form>
    </Modal>
  }

  async function onOk() {
    const [success] = await mergeStudents({variables:{source: form.values.student.id, destination: props.student.id}})

    return success;
  }

  return render();
}

function validateMerge(value:string) {
  return value?.toLowerCase() == 'merge!'
    ? null
    : 'You must type merge! to confirm you want to merge the companies, which can not be undone'
}