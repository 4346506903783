import * as React from 'react';

import { Panel, Form, FormProps, FormModel, Modal, useForm } from 'app2/components';
import { DefaultSite } from 'app2/views/shared-public';
import { StudentDetailsFields, StudentDetailsConfigProps, StudentForm, upsertStudent } from 'app2/views/shared';

import { useParentStudentDetailsQuery, useParentSiteClassroomsQuery, parentUpsertStudent } from './generated';

interface Props extends FormProps<StudentForm>, Omit<StudentDetailsConfigProps, 'role'> {
  id?:string;
  family?:string;
  defaultSite?:DefaultSite;
}

export function ParentStudentDetails(props:Props) {
  const { courseKinds, purpose, id, family, defaultSite, ...remaining } = props;
  const student = getStudent();
  const form = useForm<StudentForm>(student);
  const formProps:FormProps<StudentForm> = {icon:"User", title:"Student details", form, alwaysSave:false, onOk, ...remaining};

  function render() {
    return purpose == 'add'
      ? renderAdd()
      : renderEdit()
  }

  function renderAdd() {
    return <Modal title='Add new student'>
      <Form {...formProps} editing> 
        {renderBody()}
      </Form>
    </Modal>
  }

  function renderEdit() {
    return <Panel type={purpose == 'add' ? 'edit' : purpose == 'edit' ? 'toggle' : 'edit-no-save-button'} autoSave={purpose == 'checkout'} name='details' {...formProps}>
      {renderBody()}
    </Panel>
  }

  function renderBody() {
    return <StudentDetailsFields title={false} role='parent' courseKinds={courseKinds} purpose={purpose} getClassrooms={getClassrooms} defaultSite={defaultSite} />
  }

  function getStudent() {
    return purpose == 'add' ? createStudent() : loadStudent()
  }

  function createStudent() {
    return React.useMemo(() => {
      return {
        automaticGradeUpdate: true,
        family
      } as StudentForm
    }, []);
  }

  function loadStudent() {
    const [result] = useParentStudentDetailsQuery({ variables: { id:props.id } });
    return result?.data?.student;
  }

  function getClassrooms(site:string, pause?:boolean) {
    const [response] = useParentSiteClassroomsQuery({variables: {siteId: site}, pause, hideLoader: true});
    return response.data?.site?.classrooms;
  }

  async function onOk(form:FormModel<StudentForm>) {
    const success = await upsertStudent(parentUpsertStudent, form, student);

    return success;
  }
    
  return render();
}
