import * as React from 'react'
import pluralize from 'pluralize'

import { UserUtils } from 'app2/api';
import { Button, commaAnd, CloudinaryPhoto, HBox, Panel, renderDelimitedList, Text, VBox } from 'app2/components';
import { PublicSiteLink, PublicCompanyLink } from 'app2/views/shared-public';

import { ContactModal } from './ContactModal';
import { DirectoryResult } from './DirectoryResult';
import { DirectorySiteSelections } from './generated';

interface Props {
  site:DirectorySiteSelections;
  result:DirectoryResult;
}

export function CompanyResult(props:Props) {
  const site = props.site;
  const result = props.result;
  const company = result.course.company;
  const companyPhotoSize = [60, 112]
  const companyPhotoSizePx = [companyPhotoSize[0] + 'px', companyPhotoSize[1] + 'px']
  const companyPhotoRadius = [(companyPhotoSize[0] / 2) + 'px', (companyPhotoSize[1] / 2) + 'px']
  const distance = result.distance > 100 ? '' : `${result.distance} ${pluralize('miles', result.distance)} away`;

  function render() {
    return <Panel title='About the provider' text='body' p="$16" m={0} primaryActions={distance} vItemSpace='16px' border='unset' borderRadius='unset'>
        <HBox gap='$16' layout={['vbox', 'hbox']}>
          {company.profilePhoto
            ? <CloudinaryPhoto src={company.profilePhoto} imageWidth={companyPhotoSize[1]} ratio={1} borderRadius={companyPhotoSize[1] / 2} />
            : <VBox bg='brand' hAlign='center' vAlign='center' color='white' text='heading2' fontSize={['30px', '60px']} overflow='hidden'
              minWidth={companyPhotoSizePx} width={companyPhotoSizePx} height={companyPhotoSizePx} borderRadius={companyPhotoRadius}>{UserUtils.getInitials(company?.name)}</VBox>}
          <VBox gap='$8' hAlign='left' width='100%' vAlign='top'>
            <PublicCompanyLink company={company} text='subtitle1' underline={false} />
            <Text maxLines={2} text='formlabel'>{company.headline}</Text>
            <Button width='100%' onClick={<ContactModal site={site} result={result} />}>Contact this provider</Button>
          </VBox>
        </HBox>
        <Text maxLines={3}>{company.about}</Text>
        <HBox flex={1} />
        <span>Teaches at {renderDelimitedList(result.course.company.sites, commaAnd, s => <PublicSiteLink site={s} underline={false} bold />, 5)}</span>
  </Panel>
}

  return render();
}

