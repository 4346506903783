import * as React from 'react'
import { useParams } from 'react-router'

import { PublicPage } from 'app2/views/shared-public'
import { CourseAttendance } from 'app2/views/shared'

import { AttendanceRouteParams } from '../obfuscatedRoutes'

export function ObfuscatedCourseAttendance() {
  const { token } = useParams<AttendanceRouteParams>();
  
  return <PublicPage footer={false}>
    <CourseAttendance token={token} url={`/activities/${token}/attendance`} pageHeader tabs teacher />
  </PublicPage>
}
