import { UserPreferences } from '../shared';
import { CoursesTableWithTabsPreferences } from '../shared-authorized';

export const organizerSeasonPreferenceKeys = {
  reportsTransactions: 'reportsTransactions',
};

export class OrganizerSeasonPreferences extends UserPreferences {
  season: string;

  constructor(season: string) {
    super();

    this.season = season;
  }
  
  tablePreferences(type:string) {
    return new CoursesTableWithTabsPreferences(this, type);
  }

  get reportsTransactions(): string {
    return this.load(organizerSeasonPreferenceKeys.reportsTransactions);
  }

  set reportsTransactions(value: string) {
    this.save(organizerSeasonPreferenceKeys.reportsTransactions, value);
  }

  get path(): (string | number)[] {
    return super.path.concat(this.season);
  }
}
