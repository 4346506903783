import { getSymbol } from './util/getSymbol';

import { makeObservable } from './ObservableVisitor';
import { observableManager } from './ObservableManager';

const observableSymbol: any = getSymbol('observable');
const observableTargetSymbol: any = getSymbol('observableTarget');

// babel doesn't allow extending built in classes
// so this has to use the old school way of
// extending a class
import * as util from 'util';

interface ObservableSet<T> extends Set<T> {}

export const ObservableSet = (function<T>(
  this: ObservableSet<T>,
  values?: Iterable<any>
) {
  const self = new Set();
  Object.setPrototypeOf(self, ObservableSet.prototype);

  //@ts-ignore
  self[observableSymbol] = self;

  //@ts-ignore
  self[observableTargetSymbol] = self;

  if (values) {
    for (const val of values) {
      self.add(makeObservable(val));
    }
  }

  return self;
} as any) as { new (values?: Iterable<any>): ObservableSet<any> };

util.inherits(ObservableSet, Set);
Object.setPrototypeOf(ObservableSet, Set);

ObservableSet.prototype.add = function<T>(value: T) {
  observableManager.onChanged(this);

  return Set.prototype.add.call(this, value);
};

ObservableSet.prototype.clear = function() {
  observableManager.onChanged(this);

  Set.prototype.clear.call(this);
};

ObservableSet.prototype.delete = function<T>(value: T) {
  observableManager.onChanged(this);

  return Set.prototype.delete.call(this, value);
};

ObservableSet.prototype.has = function<T>(value: T) {
  observableManager.onObserved(this);

  return Set.prototype.has.call(this, value);
};

ObservableSet.prototype.forEach = function<T>(
  callbackfn: (value: T, value2: T, set: Set<T>) => void,
  thisArg?: any
): void {
  observableManager.onChanged(this);

  return Set.prototype.forEach.call(this, callbackfn);
};

ObservableSet.prototype.entries = function<T>(): IterableIterator<[T, T]> {
  observableManager.onObserved(this);

  return Set.prototype.entries.call(this);
};

ObservableSet.prototype.keys = function<T>(): IterableIterator<T> {
  observableManager.onObserved(this);

  return Set.prototype.keys.call(this);
};

ObservableSet.prototype.values = function<T>(): IterableIterator<T> {
  observableManager.onObserved(this);

  return Set.prototype.values.call(this);
};

ObservableSet.prototype[Symbol.iterator] = function() {
  observableManager.onObserved(this);

  return Set.prototype[Symbol.iterator].call(this);
};
