import * as React from 'react'
import { useParams } from 'react-router';

import { DateField, Panel, Section, SHORT_DATE_TIME_FORMAT } from 'app2/components';

import { EventLink, UserLink } from '../shared';
import { EventRouteParams } from '../adminRoutes';
import { AdminPage } from '../AdminPage';

import { useAdminEventQuery, AdminEventSelections } from './generated';

export function Event() {
  const { id } = useParams<EventRouteParams>();
  const [result] = useAdminEventQuery({variables: {id}});
  const item = result.data?.event || ({} as AdminEventSelections);

  function render() {
    return <AdminPage title='Event'>
      <Panel title={item.id} initialValues={item} type='display'>
        <Section name='id' label='ID name' component={EventLink} />
        <Section name='createdAt' label='Sent' component={DateField} dateFormat={SHORT_DATE_TIME_FORMAT} />
        <Section name='eventName' label='Event' />
        <Section name='contact.name' label='Recipient' component={UserLink} />
        <Section name='contact.email' label='Email' />
        <Section name='contact.phone' label='Phone' />
        <Section name='serviceType' label='Type' />
        <Section name='eventAttributes' label='Attributes' format={formatJson} />
      </Panel>
    </AdminPage>
  }

  function formatJson(val:any) {
    return JSON.stringify(val, null, 2);
  }

  return render();
}