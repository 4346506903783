import moment from 'moment'
import { isEqualWith } from 'lodash-es'

import { DateInput } from './DateInput'
import { compareDates } from './compareDates'

export function compareDateArrays(dates1:DateInput[], dates2:DateInput[], startYear?:number, endYear?:number, granularity?: moment.unitOfTime.StartOf, timezone?:string) {
  function comparator(date1:moment.Moment, date2:moment.Moment) {
    if (Array.isArray(date1) && Array.isArray(date2)) {
      return undefined;
    }

    return compareDates(date1, date2, startYear, endYear, granularity, timezone);
  }

  return isEqualWith(dates1, dates2, comparator);
}
