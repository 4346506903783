import * as React from 'react'

import { CourseKindGroup } from 'app2/api'
import { DropdownField, OptionPicker, OptionPickerProps } from 'app2/components';

export function CourseKindGroupPicker(props:OptionPickerProps) {
  return <OptionPicker options={courseKindGroupOptions} {...props} />
}

CourseKindGroupPicker.fieldProps = OptionPicker.fieldProps;
CourseKindGroupPicker.defaultProps = {
  type: 'button-strip'
}

const courseKindGroupOptions = [{
  label: 'Enrichment',
  value: CourseKindGroup.Enrichment,
}, {
  label: 'Extended care',
  value: CourseKindGroup.ExtendedCare,
}, {
  label: 'Camp',
  value: CourseKindGroup.Camp,
}, {
  label: 'Lessons',
  value: CourseKindGroup.LessonSet,
}, {
  label: 'Leagues',
  value: CourseKindGroup.Athletics,
}];

export const CourseKindGroupField = {edit: {...DropdownField.edit, component: CourseKindGroupPicker}, display: DropdownField.display, options: courseKindGroupOptions, multiple: true};
