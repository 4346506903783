import * as React from 'react';

import { ModalWithTabs, HBox, Icon } from 'app2/components';
import { VendorCompliance, TeacherCompliance } from 'app2/views/shared';

import { Details } from './Details';
import { useCompanyVendorQuery } from './generated';

interface Props {
  vendor: string;
}

export function SiteModal(props: Props) {
  const query = useCompanyVendorQuery({ variables: { vendor: props.vendor } });
  const vendor = query[0].data?.vendor;
  const teachersInCompliance = vendor?.vendorTeachers?.every(t => t.inCompliance);

  function render() {
    return <ModalWithTabs title="School details" cancel={null} ok='Close' tabs={renderTabs()} {...props} />
  }

  function renderTabs() {
    return [{
        label: 'Details',
        content: <Details vendor={vendor} />,
      },
      vendor?.showProviderCompliance && {
        label: <HBox gap='$8' vAlign='center'>Provider compliance{!vendor.vendorInCompliance && <Icon name='AlertCircle' color='error' />}</HBox>,
        content: <VendorCompliance vendor={props.vendor} />
      },
      vendor?.showTeacherCompliance && {
        label: <HBox gap='$8' vAlign='center'>Instructor compliance{!teachersInCompliance && <Icon name='AlertCircle' color='error' />}</HBox>,
        content: <TeacherCompliance vendor={props.vendor} teachers={vendor?.vendorTeachers || []} />
      }
    ];
  }

  return render();
}
