import * as React from 'react';
import * as ReactIs from 'react-is';

export function convertElements<T = any>(nodes:React.ReactNode, from:any, to:any, additionalProps?:any) {
  return React.Children.toArray(nodes)
    .map((child, index) => ReactIs.isElement(child) && child.type == from
    ? React.createElement(to, { ...additionalProps, ...updateDefaultProps(from, to, child.props), key: child.props.key || index})
    : child) as React.ReactElement<T>[];
}

function updateDefaultProps(oldType:any, newType:any, props:any) {
  const updated = {...props};

  if (oldType.defaultProps) {
    for (const prop in oldType.defaultProps) {
      if (updated[prop] == oldType.defaultProps[prop]) {
        delete updated[prop];
      }
    }
  }

  if (newType.defaultProps) {
    for (const prop in newType.defaultProps) {
      if (!(prop in updated)) {
        updated[prop] = newType.defaultProps[prop];
      }
    }
  }

  return updated;
}
