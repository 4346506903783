import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserFilesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserFilesQuery = { user?: { files?: Array<{ id: string; name: string; createdAt?: string | null | undefined }> | null | undefined } | null | undefined };

export const UserFilesDocument = gql`
  query UserFiles {
    user: currentUser {
      files {
        id
        name
        createdAt
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type UserFilesArgs = MakeOptional<UseQueryArgs<UserFilesQueryVariables, UserFilesQuery>, 'query'>;

export function useUserFilesQuery(options: UserFilesArgs = {}) {
  return useQuery<UserFilesQueryVariables, UserFilesQuery>({ query: UserFilesDocument, ...options });
}

export type UserFilesOptions = ExecQueryOptions<UserFilesQueryVariables>;

export function userFiles(options: UserFilesOptions) {
  return executeQuery<UserFilesQuery, UserFilesQueryVariables>(UserFilesDocument, options);
}

export type UserFilesSelections = UserFilesQuery['user'];
