import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateCheckoutDestinationsMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  checkoutDestinations: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type OrganizerUpdateCheckoutDestinationsMutation = { siteUpdateDismissals?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateCheckoutDestinationsDocument = gql`
  mutation OrganizerUpdateCheckoutDestinations($siteId: ID!, $checkoutDestinations: [String!]!) {
    siteUpdateDismissals(site: $siteId, dismissals: $checkoutDestinations) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateCheckoutDestinationsOptions = ExecMutationOptions<OrganizerUpdateCheckoutDestinationsMutationVariables>;

export function organizerUpdateCheckoutDestinations(options: OrganizerUpdateCheckoutDestinationsOptions) {
  return executeMutation<OrganizerUpdateCheckoutDestinationsMutation, OrganizerUpdateCheckoutDestinationsMutationVariables>(OrganizerUpdateCheckoutDestinationsDocument, options);
}
