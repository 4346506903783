import * as React from 'react'

import { HBox, VBox } from '../Box'
import { Icon } from '../icons'
import { Heading2 } from '../Text'
import { theme } from '../theme'
import { Info } from '../Info';
import { Saveable, SaveableProps } from '../Saveable'

import { PopupOptions, PopupHandleContext, PopupHandle, PopupManager } from './PopupManager';

export interface ModalProps extends SaveableProps {
  // To add a modal
  // - use ModalManager.add
  //
  // To remove a modal
  // - you generally do not need to do anything.
  //   see below about button handlers and closing the modal.
  //
  // If you do need to remove the modal:
  // - use the returned modal handle from ModalManager.add.
  // - add a ref and call the remove method on modal.
  // - call modal.remove on the handle passed to your component props.
  // - use PopupHandle context to get your modal's handle and call remove.

  size?:'medium' | 'large';
  title:React.ReactNode;
  // anything specified in header is placed above children/content, and is
  // outside the scrollable area such as if you want tabs
  header?:React.ReactNode;
  // whether the content is scrollable, by default it is
  scrollable?:boolean;

  // for the buttons, see notes in Saveable
}

export class Modal extends React.Component<ModalProps> {
  static contextType = PopupHandleContext;
  static defaultProps = {
    size:'medium',
    cancelable: true,
    cancel: 'Cancel',
    ok: 'OK',
    scrollable: true
  }

  static show<T = any>(modal:React.ReactElement, options:PopupOptions = {cancelable: true}) {
    return PopupManager.addModal<T>(modal, options);
  }

  static success(optionsOrTitle: ModalProps | string, content?:React.ReactNode | string[], cancelable?:boolean) {
    return this.info('success', optionsOrTitle, content, cancelable);
  }

  static error(optionsOrTitle: ModalProps | string, content?:React.ReactNode | string[], cancelable?:boolean) {
    return this.info('error', optionsOrTitle, content, cancelable);
  }

  static warning(optionsOrTitle: ModalProps | string, content?:React.ReactNode | string[], cancelable?:boolean) {
    return this.info('warning', optionsOrTitle, content, cancelable);
  }

  static info(type:'success' | 'warning' | 'error', optionsOrTitle: ModalProps | string, infoContent?:React.ReactNode | string[], cancelable?:boolean) {
    const options:ModalProps = typeof optionsOrTitle == 'object'
      ? optionsOrTitle
      : {
        title: optionsOrTitle,
        content:infoContent,
        cancel: cancelable ? 'Cancel' : null
      };

    const {title, content, ...remaining} = options;

    return PopupManager.addModal(<Modal title={title} {...remaining}><Info type={type}>{content}</Info></Modal>);
  }

  ref = React.createRef<Saveable>();
  context:PopupHandle;

  render() {
    const {size, title, cancelable, onCancel, header, scrollable, content, children, ...remaining} = this.props;

    // hack to make the scrollbar right align to the edge of the modal
    // but safari has a bug (or chrome is wrong)  that if both overflow are auto, it 
    // shows a horizontal scrollbar (because we are moving the width of the content over)
    // so we overflow hidden horizontally, which will be an issue if we have scrollbar 
    // horizontal content, but we typically dont
    const scrollableProps = scrollable ? {overflowY:'auto' as any, overflowX: 'hidden' as any, pr:'30px', mr:'-30px'} : {overflow:'hidden'};
    const width = {
      'medium': '700px',
      'large': '80%',
    }[size] || '700px';
    const maxHeight = 'calc(min(var(--app-height, 100vh), var(--viewport-height, 100vh)) * .9)';
    const height = {
      'medium': undefined,
      'large': maxHeight,
    }[size];

    return <Saveable data-test={`Modal(${title})`} data-modal ref={this.ref} p={theme.space.pannelPadding} maxWidth={`calc(min(100vw - 32px, ${width}))`} width={width} maxHeight={maxHeight} height={height} bg='formBackground' onActionComplete={this.onActionComplete} 
      borderRadius='standard' onCancel={onCancel} cancelable={cancelable} onKeyUp={this.onKeyUp} {...remaining}>
      <HBox pb='$30'>
        <Heading2>{title}</Heading2>
        <HBox flex={1} />
        {cancelable && <Icon name='X' button onClick={(event:React.MouseEvent) => this.ref.current.onButtonClick(0, event)} />}
      </HBox>
      {header}
      <VBox maxHeight='100%' height='100%' {...scrollableProps}>
        {content || children}
      </VBox>
    </Saveable>
  }

  close(action:number, result?:any) {
    setTimeout(() => this.onActionComplete(action, result));
  }
  
  onActionComplete = (action:number, result?:any) => {
    this.context.remove(action, result);
  }

  onKeyUp = (event:React.KeyboardEvent) => {
    if (!this.props.cancelable) {
      return;
    }

    if (event.key != 'ESCAPE') {
      return;
    }

    event.stopPropagation();

    this.ref.current.cancel();
  }
}

// makes the modal class available to the component base
// because of circular imports it cant be imported directly
// @ts-ignore
window['__Modal'] = Modal;