import { colorToComponent, componentToHex } from '../theme';

// there's no good way to get a background that is being used for a node
// getComputedStyle just gets the style that would apply based on css 
// classes, but won't tell you the background coming through if the component
// has no background...this does its best to get it by taking the midpoint and
// getting the elements at that point and taking the first bg it finds.

export function getBgColorForNode(node:HTMLElement) {
  if (!node) {
    return;
  }

  const r = node.getBoundingClientRect();
  const elements = document.elementsFromPoint(r.left + (r.width / 2), r.top + (r.height / 2));

  while (elements.length) {
    const atPoint = elements.shift();

    if (node != atPoint && node.contains(atPoint)) {
      continue;
    }

    const style = window.getComputedStyle(atPoint);
    const color = colorToComponent(style.backgroundColor);

    if (color.a == 0) {
      continue;
    }

    return componentToHex(color);
  }
  

  return;
}
