import * as React from 'react';
import { useParams } from 'react-router';

import { FileUploadAttributes } from 'app2/api';
import { Panel, Section, Field, RepeatingSection, InputField, FieldRendererProps, FormModel } from 'app2/components';
import { FileUploader, FileLink, addDeletedItems } from 'app2/views/shared';

import { SiteRouteParams } from '../organizerRoutes';

import { OrganizerPolicyWaiversSelections, useOrganizerPolicyWaiversQuery } from './gql';
import { organizerUpdatePolicyWaivers } from './gql';

type WaiverForm = {attributes:(OrganizerPolicyWaiversSelections | FileUploadAttributes)[]};

export function Waivers() {
  const { site } = useParams<SiteRouteParams>();
  const [policyWaiversResult] = useOrganizerPolicyWaiversQuery({ variables: { siteId: site } });
  const initialValues:WaiverForm = React.useMemo(() => {
    return {attributes: policyWaiversResult.data?.site?.waivers}
  }, [policyWaiversResult.data?.site?.waivers]);

  function render() {
    return (
      <Panel icon="Shield" title="Waivers & forms" type="toggle" initialValues={initialValues} onOk={onOk}>
        <Section label="Families will be required to consent to the terms of these documents during the checkout process. Please give each document a clear, descriptive title." />
        <RepeatingSection name="attributes" buttonCols={1} fields={[
          <Field name="name" required label="Waiver name" component={InputField} />, 
          <Field name="name" required label="File" render={renderLink} />, 'remove']}
          add={<FileUploader kind="tertiary" icon="PlusCircle" onChoose={onChooseFile}>Add waiver</FileUploader>}
        />
      </Panel>
    );
  }

  function renderLink(props: FieldRendererProps) {
    return (!props.info.record.id && props.info.value) || <FileLink id={props.info.record.id} name={props.info.value} target="_blank" />;
  }

  async function onOk(form: FormModel<WaiverForm>) {
    const variables = { siteId: site, attributes: addDeletedItems(initialValues.attributes as any, form.values.attributes as any, 'archived') };
    const [success] = await organizerUpdatePolicyWaivers({ variables:variables as any, error: form, successMsg: 'Waivers saved' });
    return success;
  }

  function onChooseFile(name: string, fileType: string, fileData: string, form: FormModel<WaiverForm>) {
    const policyWaiverMutationAttributes = { fileData, fileType, name };

    form.setValue('attributes', form.values.attributes.concat(policyWaiverMutationAttributes));
  }

  return render();
}

