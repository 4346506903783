import * as React from 'react';

import { Modal, FormProps } from 'app2/components';

import { EnrollmentCourseOptionsForm } from './EnrollmentCourseOptionsForm';
import { EnrollmentOptions, useCourseWithOptions } from './useCourseWithOptions';

interface Props extends FormProps {
  course?:string;
  checkout?:boolean;
  choices?:EnrollmentOptions;
  ok?:string;
}

export function EnrollmentCourseOptionsModal(props:Props) {
  const {course, options} = useCourseWithOptions(props.course, props.checkout, null);
  const [page, setPage] = React.useState(0);

  function render() {
    if (!course) {
      return <></>;
    }

    const hasNext = page < options?.length - 1;

    return <Modal title={`${course.name}: Choose activity options`} size='large' ok={hasNext ? 'Continue' : props.ok || 'Complete'}>
      <EnrollmentCourseOptionsForm {...props} page={page} title='' editing onNext={onNext} />
    </Modal>
  }

  function onNext() {
    setPage(Math.min(page + 1, options.length - 1));
  }

  return render();
}
