import * as React from 'react';
import moment from 'moment';
import { omit } from 'lodash-es';

import { StudentLogEntryKind } from 'app2/api';
import { Button, DateField, DropdownField, Field, FieldInfo, Form, Modal, RepeatingSection, SaveableResult, Section, TextAreaField, TimeField, VBox } from 'app2/components';

import { useStudentLogEntriesQuery, StudentLogEntriesSelections, upsertStudentLogEntry, removeStudentLogEntry } from './gql'

type LogEntry = StudentLogEntriesSelections['logEntries'][0];

interface Props {
  id: string;
}

export function StudentLogEntries(props:Props) {
  const [result] = useStudentLogEntriesQuery({ variables: { id: props.id } });
  const student = result?.data?.student;
  const none = !(result.loading || student?.logEntries?.length);

  function render() {
    return <Form<StudentLogEntriesSelections> initialValues={student} editing={false} primaryActions={!result.loading && !none && <Button iconPosition='left' icon='Plus' onClick={onAdd}>Add note</Button>}>
      {none
        ? renderNone()
        : <RepeatingSection name="logEntries" equalWidths={false} onRemove={onRemove} readOnly={false} fields={[
          <Field name="date" label='Date' width='120px' whiteSpace='nowrap' component={DateField} cursor='pointer' underline onClick={onEdit} />,
          <Field name="date" label='Time' width='120px' whiteSpace='nowrap' component={TimeField} />,
          <Field name="createdBy" label='Added by' width='120px' format={(createdBy:LogEntry['createdBy']) => `${createdBy?.firstName} ${createdBy?.lastName}`} />,
          <Field name="kind" label='Type' width='120px' whiteSpace='nowrap' component={DropdownField} options={studentLogEntryOptions} />,
          <Field name="notes" label='Notes' minWidth='200px' />,
          'remove'
        ]} />
      }
    </Form>
  }

  function renderNone() {
    if (!none) {
      return;
    }

    return <VBox text='body' width='100%' height='100%' hAlign='center' vAlign='center' gap='$8'>No notes yet!<Button onClick={onAdd}>Add note</Button></VBox>
  }

  async function onAdd() {
    const initialValues:Partial<LogEntry> = {
      date: moment() as any,
      kind: StudentLogEntryKind.Medical,
    }

    return upsertLogEntry(initialValues);
  }

  async function onEdit(_:any, info:FieldInfo<LogEntry>) {
    return upsertLogEntry(omit(info.record, 'createdBy'));
  }

  async function upsertLogEntry(logEntry:Partial<LogEntry>) {
    const result = await Modal.show(<Modal title={logEntry.id ? 'Edit note' : 'Add note'}>
      <Form initialValues={logEntry}>
        <Section name='date' label='Date' component={DateField} valueFormat='datetime' required />
        <Section name='date' label='Time' component={TimeField} valueFormat='datetime' required />
        <Section name='kind' label='Type' component={DropdownField} options={studentLogEntryOptions} required />
        <Section name='notes' label='Notes' component={TextAreaField} required />
      </Form>
    </Modal>)

    if (result.action != SaveableResult.ok) {
      return;
    }

    const note = result.result;
    const [success] = await upsertStudentLogEntry({ variables: { student: props.id, attributes: note } });

    return success;
  }

  // hack because the trash can puts a shield that is on top of the confirmation
  // modal, so we let the function exit (via calling the async version) to get the shield to go away
  
  function onRemove(pos:number) {
    remove(pos)
  }

  async function remove(pos:number) {
    const result = await Modal.warning('Delete note', 'Are you sure you want to delete this note? This action can not be undone', true);

    if (result.action != SaveableResult.ok) {
      return;
    }

    const [success] = await removeStudentLogEntry({ variables: { logEntry: student.logEntries[pos].id } });

    return success;
  }

  return render();
}

export const studentLogEntryOptions = [
  {label:'Medical', value: StudentLogEntryKind.Medical}, 
  {label:'Other', value: StudentLogEntryKind.Other}
]