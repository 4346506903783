import moment from 'moment'

import { FieldProps, FieldInfo, LONG_DATE_FORMAT } from "app2/components";
import { DateInput } from './DateInput';

// this will return a date thats within the formats specified in allowableFormats
// this is needed over moments parse because moment parse in strict mode
// is specific to a single format, and in non-strict mode allows very loose
// matches, suchs 1p1.  this methods allows a strict set of variations.

export function parseSingleDate(input:DateInput, timezone?:string):moment.Moment {
  const result = parseDate(input, timezone);

  return Array.isArray(result) ? result[0] : result;
}

export function parseDate(input:DateInput, timezone?:string):moment.Moment | (moment.Moment[]) {
  return parseDateWithFormats(input, usDateFormats, timezone);
}

export function parseDateWithFormats(input:DateInput | DateInput[], allowableFormats:string[], timezone?: string):moment.Moment | moment.Moment[] {
  let date:moment.Moment;

  if (!input) {
    return null;
  }

  if (typeof input == 'string') {
    input = input.trim();
  }

  if (typeof input == 'string'  && input.indexOf(';') != -1) {
    return parseDateWithFormats(input.split(';'), allowableFormats, timezone);
  }
  else
  if (Array.isArray(input)) {
    return input.map(date => parseDateWithFormats(date, allowableFormats, timezone) as moment.Moment);
  }
  if (input instanceof Date || moment.isMoment(input)) {
    date = moment(input);

    if (timezone) {
      date = date.tz(timezone);
    }
  }
  else {
    const formats = allowableFormats.slice();

    // moment will successfully parse 0802 as an moment.ISO_8601, but as the year 802
    // which we don't want, so for iso date matching it needs to be at least 8 characters (yyyymmdd)
    if (input.length > 7) {
      date = timezone ? moment.tz(input, moment.ISO_8601, true, timezone) : moment(input, moment.ISO_8601, true);
    }

    while ((!date || !date.isValid()) && formats.length) {
      const format = formats.shift()
      date = timezone ? moment.tz(input, format, true, timezone) : moment(input, format, true);
    }
  }

  return date && date.isValid() ? date : null;
}

const shortFormats = [
  'YYYY-MM-DD',
  'MM-DD-YYYY',
  'M-DD-YYYY',
  'MM-D-YYYY',
  'M-D-YYYY',
  'MM-DD-YY',
  'M-DD-YY',
  'M-D-YY',
  'MM-D-YY',
  'MM-DD',
  'MM-D',
  'M-DD',
  'M-D',
  'MMDDYYYY',
  'MMDDYY',
  'MMDD',
  'MDD',
  'MMD',
  'MD',
];

export const usDateFormats = [
  ...shortFormats,
  ...shortFormats.map(format => format.replace(/\-/g, '/')),
  ...shortFormats.map(format => format.replace(/\-/g, '.')),
  'MMM Do, YYYY',
  'MMMM D, YYYY',
  'MMM D, YYYY',
  'MMMM D, YY',
  'MMM D, YY',
  'MMMM D',
  'MMM D',
];


export function onlyDate(input:DateInput):moment.Moment {
  const date = parseDate(input);

  return Array.isArray(date) ? date[0] : date;
}

export function onlyDateString(input:string, props:FieldProps<any, any, any>, info:FieldInfo<any>):string {
  const date = parseDate(input);
  const isArray = Array.isArray(date);

  if (!date || (isArray && !date.length)) {
    return '';
  }

  return isArray
    ? date[0].format('YYYY-MM-DD') 
    : date.format('YYYY-MM-DD');
}

export function createDateInpuPasteParserTz(timezone: string, paste: 'date'|'time') {
  return function (input:DateInput, props:FieldProps<any, any, any>, info:FieldInfo<any>):moment.Moment {
    const date = parseDate(input, timezone);
    const parsedDate = Array.isArray(date) ? date[0] : date

    const ret = (paste == 'date') ? moment.tz(info.value, timezone).set({
      year: parsedDate.year(),
      month: parsedDate.month(),
      date: parsedDate.date()
    }) : moment.tz(info.value, timezone).set({
      hour: parsedDate.hour(),
      minute: parsedDate.minute(),
      second: parsedDate.second()
    });

    info.form.setValue([], info.name, ret);

    return ret;
  }
}


export function createOnlyDateParserTz(timezone:string) {
  return function (input:moment.Moment, props:FieldProps<any, any, any>, info:FieldInfo<any>):moment.Moment {
    return addDateWhilePreservingTime(info.value, input, timezone);
  }
}

function addDateWhilePreservingTime(existing:moment.Moment, updated:moment.Moment, timezone:string) {
  if (!existing) {
    return updated;
  }

  if (!updated || !updated.isValid()) {
    return null;
  }

  return moment.tz(existing, timezone).set({
    year: updated.year(),
    month: updated.month(),
    date: updated.date()
  });
}

export function onlyDateArray(input:DateInput):moment.Moment[] {
  const date = parseDate(input);

  return !date 
    ? [] 
    : !Array.isArray(date) 
      ? [date] 
      : date.filter(d => !!d);
}

export function createOnlyDateArrayTz(timezone: string) {
  return function (input:DateInput, props:FieldProps<any, any, any>, info:FieldInfo<any>):moment.Moment[] {
    const date = parseDate(input, timezone);

    return !date 
    ? [] 
    : !Array.isArray(date) 
      ? [setTimeMidnight(date,timezone)] 
      : date.filter(d => !!d).map(d => setTimeMidnight(d,timezone));
  }
}

function setTimeMidnight(date: moment.Moment, timezone: string):moment.Moment {
  const ret = moment.tz(date, timezone).set({
    hour: 0,
    minute: 0,
    second: 0
  })

  return ret;
}