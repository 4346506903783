import * as React from 'react';

import { EntityKind } from 'app2/api';
import { UnifiedTeam } from 'app2/views/shared';

import { useCompanyParameter } from '../shared';

export function Team() {
  const company = useCompanyParameter();

  return <UnifiedTeam entityKind={EntityKind.Company} entityId={company} cols={cols} numbered panel editing={true} />
}

const cols = ['email', 'role', 'isContact', 'sentAt', 'resendInvite']