import * as React from 'react'
import moment from 'moment';
import { isEqual, transform, isObject } from 'lodash-es';
import { Location } from 'history';
import { debounce } from "lodash";

import { BoxProps } from '../Box';
import { FormContent } from '../form';
import { HistoryAction, HistoryListener } from '../history';
import { Info } from '../Info';
import { SaveableContext, SaveableHandlers, SaveableState } from '../Saveable';
import { TitleProps } from '../Title';
import { UnsavedChanges } from '../UnsavedChanges';
import { getFirstFocusableOfParent, scrollIntoView } from '../dom-utils';
import { MultiContext, MultiContextProvider } from '../utils';
import { ErrorWithPath } from '../error';

import { FormModel, FormSubmitHandler, UpdateType, FormResetHandler } from './FormModel';
import { createForm } from './FormModelImpl';

export interface FormInfo<T = any> extends BoxProps {
  form:FormModel<T>;
  editing:boolean;
  // because editing is alway true when a table is in edit mode
  // the table will also set cellEditing for the actual cell being editing (and only the that cell)
  cellEditor?:boolean;
  parents:(string | number)[];
  submit:() => void;
  addSubform(form:Form):void;
  removeSubform(form:Form):void;
}

export interface FormContext<T> {
  formInfo:FormInfo<T>;
}

export type NavigationPrompt = 'nothing' | 'prompt' | 'prompt-dirty' | 'cancel';

export interface FormProps<T = any> extends Omit<BoxProps, 'autoSave'>, Omit<TitleProps, 'autoSave'> {
  form?:FormModel<T>;
  editing?:boolean;
  // use form either as controlled or uncontrolled
  // use initialValues for uncontrolled
  // use values for controlled
  // do not specify both
  initialValues?:Partial<T>;
  values?:T;
  // only applies if the user is editing when there's a navigation
  onNavigation?:NavigationPrompt;
  onOk?:(form:FormModel<T>) => FormSubmitHandler;
  onReset?:FormResetHandler<T>;
  onChange?:(form:FormModel<T>, type:UpdateType) => void;
  scrollable?:boolean;
  autoFocus?:boolean;
  // indicates if onOk should be called when there are changes
  // specifying a number indicates how much time to debounce this
  // if you specify true, the default debounce of 2 seconds will be used
  autoSave?:boolean | number;
  // gets set on the form:
  // indicates if a form, regardless of whether its dirty
  // should save.  if false, then clean forms will not call their
  // ok handlers. this defaults to false if editing is true, else false
  alwaysSave?:boolean;
  showUnhandledErrors?:boolean;
}

export class Form<T = any> extends React.Component<FormProps<T>> {
  static contextType = MultiContext;
  static defaultProps = {
    onNavigation: 'prompt',
    scrollable: true,
    autoFocus: true,
    showUnhandledErrors: true
  }

  ref = React.createRef<HTMLDivElement>();
  form:FormModel;
  context:SaveableContext & FormContext<T>;
  subforms:Set<Form>;

  constructor(props:FormProps<T>, context:SaveableHandlers) {
    super(props, context);
    this.state = {};
    this.update(this.props, null, true);

    this.saveable?.addHandlers({onCancel: this.onCancel, onOk: this.onOk, onActionComplete: this.onActionComplete});
    this.subforms = new Set();
  }

  get saveable() {
    return this.context.saveable;
  }

  get element() {
    return this.ref.current;
  }

  componentDidMount() {
    this.context?.formInfo?.addSubform?.(this);

    if (this.props.editing) {
      this.setInitialFocus();
    }
  }

  componentDidUpdate(prevProps:FormProps<T>) {
    this.update(this.props, prevProps);
  }

  componentWillUnmount() {
    this.context?.formInfo?.removeSubform?.(this);
    this.form?.unsubscribe(this.onFormChange);
    this.saveable?.removeHandlers({onCancel: this.onCancel, onOk: this.onOk, onActionComplete: this.onActionComplete});
  }

  update(curProps:FormProps<T>, prevProps:FormProps<T>, mount:boolean = false) {
    let needsUpdate = false;

    let autoSaveDiff = this.saveDiffForAutoSave();

    if (curProps.form !== prevProps?.form || this.form === undefined) {
      if (this.form) {
        this.form.unsubscribe(this.onFormChange);
      }

      this.form = curProps.form || createForm();
      this.form.onReset = curProps.onReset || this.form.onReset;

      if (this.form) {
        this.form.subscribe(this.onFormChange);
      }
      
      needsUpdate = true;
    }

    if (!isEqual(curProps.initialValues, prevProps?.initialValues)) {
      this.form.onReset = curProps.onReset || this.form.onReset;
      this.form.reset({initialValues: curProps.initialValues, values: curProps.values});
      needsUpdate = true;
    }
    else
    if (curProps.values && !isEqual(prevProps?.values, curProps.values)) {
      this.form.onReset = curProps.onReset || this.form.onReset;
      this.form.reset({initialValues: curProps.initialValues || curProps.values, values: curProps.values});
      needsUpdate = true;
    }

    if (needsUpdate && !mount) {
      this.applyAutoSaveDiff(autoSaveDiff);
      this.setState(this.state);
    }

    if (this.props.alwaysSave !== undefined) {
      this.form.alwaysSave = this.props.alwaysSave;
    }

    if (curProps.editing && curProps.editing != prevProps?.editing) {
      this.setInitialFocus();
    }
  }

  // all this diff nonsense is to deal with after save
  // with graphql that causes a refetch of the data from the
  // server, which will often cause a new initialValues and
  // therefore a form object to get created.  this is a problem
  // with autoSave, when you are still editing the form, and 
  // there's changes that were made while the autoSave was 
  // occurring.  the diff attempts to re-apply those changes.

  saveDiffForAutoSave() {
    if (!this.props?.autoSave || !this.form?.dirty) {
      return null;
    }

    const oldValues = {...this.form?.values};
    const oldInitialValues = {...this.form?.initialValues};
    return this.difference(oldValues, oldInitialValues);
  }

  applyAutoSaveDiff(diff:any):void {
    if (!this.props?.autoSave || !diff) {
      return null;
    }

    const visited = new Set();

    const applyDiff = (o:any, parents:string) => {
      if (visited.has(o)) {
        return;
      }

      visited.add(o);

      Object.keys(o).forEach(key => {
        const value = o[key];
        const path = parents.length ? parents + '.' + key : key;
        
        if (typeof value == 'object' && value !== null && value !== undefined && !moment.isMoment(value)) {
          applyDiff(value, path);
        }
        else {
          this.form.setValue(path, value);
        }
      });
    }

    applyDiff(diff, '');
  }

  difference(object:any, base:any) {
    function changes(object:any, base:any) {
      return transform(object, function(result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = moment.isMoment(value)
          ? value.clone()
          : (isObject(value) && isObject(base[key])) 
            ? changes(value, base[key]) 
            : value;
        }
      });
    }
    return changes(object, base);
  }

  render() {
    const {form, editing, initialValues, values, onNavigation, onOk, onChange, onReset, autoFocus, autoSave, alwaysSave, showUnhandledErrors, children, ...remaining} = this.props;

    return <MultiContextProvider<FormContext<T>> formInfo={{form: this.form, editing, parents: [], submit: this.onOk, addSubform: this.addSubform, removeSubform: this.removeSubform}}>
      <HistoryListener onChange={this.onHistoryChange} />
      {onNavigation == 'prompt' || (onNavigation == 'prompt-dirty' && this.form.dirty)
        ? <UnsavedChanges editing={editing} /> 
        : ''}
      <FormContent ref={this.ref} onSubmit={this.blockDefaultSubmit} {...remaining}>
        {children}
        {this.props.showUnhandledErrors && this.form.errors?.length ? <Info type='error'>{this.form.errors}</Info> : ''}
      </FormContent>
    </MultiContextProvider>
  }

  onHistoryChange = (location: Location, action: HistoryAction) => {
    if ((action != 'PUSH' && action != 'POP') || this.props.onNavigation == 'nothing' || !this.props.editing) {
      return;
    }

    if (this.saveable) {
      this.saveable.cancel(true);
    }
    else {
      this.form.reset();
    }
  }

  onCancel = () => {
    this.form.reset();
  }

  // returns form values if form.submit returns true, else it returns the result
  onOk = async (_?:any, buttonNo?:number) => {
    const childResults = await this.validSubforms();

    if (!childResults) {
      setTimeout(this.scrollFirstErrorIntoView, 500);
      return false;
    }

    this.saveable?.setSaveableState(SaveableState.saving);

    const result = await this.form.submit(this.props.onOk, buttonNo);

    this.saveable?.setSaveableState(!result ? SaveableState.dirty : undefined);

    if (!result) {
      setTimeout(this.scrollFirstErrorIntoView, 500);
      return false;
    }

    return result === true ? this.form.values : result;
  }

  async validSubforms() {
    if (!this.subforms.size) {
      return true;
    }

    const subformValidations = Array.from(this.subforms).map(f => f.form.presubmit());
    const results = await Promise.all(subformValidations);
    const failures = results.filter(result => !result);

    return failures.length == 0;
  }

  onActionComplete = (action:number) => {
    if (action == 0) {
      this.form.reset();
    }
  }

  onFormChange = (form:FormModel, type:UpdateType) => {
    this.props.onChange?.(this.form, type);

    // redraw in case there are form error messages
    // or if we need to update the prompt navigation state

    if (type.anyMetadata || type.reset || this.props.onNavigation == 'prompt-dirty') {
      this.forceUpdate();
    }

    if (this.props.autoSave && type.anyValue && !type.reset) {
      this.saveable?.setSaveableState(SaveableState.dirty);

      this.autoSave(form);
    }
  }

  get autoSaveWait() {
    return this.props.autoSave == true ? 1000 : Number(this.props.autoSave);
  }

  autoSave = debounce(async (form:FormModel) => {
    form.validateSync();

    if (!form.valid) {
      return;
    }

    this.onOk();
  }, this.autoSaveWait);

  // prevents buttons inside the form when clicked upon from
  // navigating the browser...this avoids each button needing to
  // specify preventDefault.
  blockDefaultSubmit = (event:React.FormEvent) => {
    event.preventDefault();
  }

  setInitialFocus() {
    if (!this.props.autoFocus) {
      return;
    }

    const focusable = getFirstFocusableOfParent(this.ref.current, false, true);

    if (focusable) {
      focusable?.focus({preventScroll: true});
      scrollIntoView(focusable)
      // this is a hack to make sure that the input controls are visible on mobile because
      // the mobile browser will show the keyboard hiding the input controls and there's no
      // easy way to detect this or position things properly, but the browser knows
      // how to properly scroll into view
      focusable.scrollIntoView({block: 'nearest', inline: 'nearest'})
    }
  }

  handleErrors(errors:ErrorWithPath[], clearPrevious?:boolean):ErrorWithPath[] {
    const unhandled = this.form.handleErrors(errors, clearPrevious);

    // need to redraw so that the errors are visible before trying to scroll to them
    this.forceUpdate();
    this.scrollFirstErrorIntoView();

    return unhandled;
  }

  scrollFirstErrorIntoView = () => {
    const element = this.element;

    if (!element) {
      return;
    }

    const firstError = element.querySelector('.hr_error_locator') as HTMLElement;

    if (!firstError) {
      return;
    }

    const toFocus = getFirstFocusableOfParent(firstError, true) || firstError;

    // if the element is not focusable then .focus() 
    // will not scroll it into view, so this is to 
    // ensure it's scrolled into view
    scrollIntoView(toFocus);

    // preventScroll is true because the browser scrolling
    // into focus is to always center the component even
    // if its already visible, causing unnecessary movement
    toFocus.focus({preventScroll: true});
  }

  addSubform = (form:Form):void => {
    this.cleanupSubforms();

    if (this.form) {
      this.form.subforms ||= new Set();
      this.form.subforms.add(form.form);
    }

    this.subforms.add(form);
  }

  removeSubform = (form:Form):void => {
    this.cleanupSubforms();

    if (this.form.subforms) {
      this.form.subforms.delete?.(form.form);
    }

    this.subforms.delete(form);
  }

  cleanupSubforms() {
    // hack because form models on forms can change and we won't be notified

    if (!this.form.subforms?.size || !this.form) {
      return;
    }

    if (!this.subforms.size) {
      this.form.subforms = undefined;
      return;
    }

    const existing = new Set(Array.from(this.subforms).map(f => f.form));

    this.form.subforms.forEach(subform => {
      if (!existing.has(subform)) {
        this.form.subforms.delete(subform);
      }
    })
  }
}

