import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteTimezoneQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteTimezoneQuery = { site?: { id: string; timezone?: string | null | undefined } | null | undefined };

export const SiteTimezoneDocument = gql`
  query SiteTimezone($site: ID!) {
    site(site: $site) {
      id
      timezone
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteTimezoneArgs = MakeOptional<UseQueryArgs<SiteTimezoneQueryVariables, SiteTimezoneQuery>, 'query'>;

export function useSiteTimezoneQuery(options: SiteTimezoneArgs = {}) {
  return useQuery<SiteTimezoneQueryVariables, SiteTimezoneQuery>({ query: SiteTimezoneDocument, ...options });
}

export type SiteTimezoneOptions = ExecQueryOptions<SiteTimezoneQueryVariables>;

export function siteTimezone(options: SiteTimezoneOptions) {
  return executeQuery<SiteTimezoneQuery, SiteTimezoneQueryVariables>(SiteTimezoneDocument, options);
}

export type SiteTimezoneSelections = SiteTimezoneQuery['site'];
