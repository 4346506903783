import * as React from 'react';
import pluralize from 'pluralize';

import { ActionButton, DataTable, NotificationManager } from 'app2/components';

import { RemoveStudentsModal } from './RemoveStudentsModal';

import { CourseSelections } from '../../generated';

export function renderRemoveStudentsButton(course: CourseSelections, table: DataTable, waitlistTable:boolean = false) {
  if (!course?.behavior?.enrollmentsRemove) {
    return;
  }

  return <ActionButton icon="XCircle" autoLoader={false} tooltip="Remove without refunding" onClick={() => showModal(course, table, waitlistTable)}>
    Remove
  </ActionButton>
}

function showModal(course: CourseSelections, table: DataTable, waitlistTable:boolean = false) {
  const enrollments = table.selections.selectedItems;
  const multipleEnrollments = !waitlistTable && enrollments.
    filter(e => !e.isInvitation).
    // hack to check for multiple drop-ins to look for commas in roster periods
    filter(e => e.priceConfigs?.length > 1 || (e.rosterPeriods?.match?.(/drop-in(.*)/i)?.[1]?.match?.(/,/g) || []).length > 0).length > 0;

  return multipleEnrollments
    ? void(NotificationManager.warning(`The selected ${pluralize('student', enrollments.length)} ${pluralize('has', enrollments.length)} multiple enrollments that can be removed.  Please use billing tab to select specific enrollments to remove for ${pluralize('this', enrollments.length)} ${pluralize('student', enrollments.length)}.`))
    : <RemoveStudentsModal course={course} enrollments={table.selectedItems} />
}

