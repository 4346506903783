import * as React from 'react'

import { dispatchChangeEvent } from './dom-utils';
import { ButtonList, ButtonListProps } from './ButtonList';
import { ButtonStrip, ButtonStripProps } from './ButtonStrip';
import { Dropdown, DropdownProps } from './Dropdown';
import { OptionButton, OptionButtonProps } from './OptionButton';

export interface OptionPickerProps extends Pick<ButtonListProps, 'flex' | 'm' | 'p'> {
  type:'button-strip' | 'button-list' | 'dropdown' | 'option-button';
  options?:ButtonStripProps['options'] | OptionButtonProps['options'];
  selected?:ButtonStripProps['selected'];
  onChange?:ButtonStripProps['onChange'];
  multiple?:boolean;
  children?:ButtonListProps['children'];
  kind?:ButtonListProps['kind'];
  dropdownWidth?:ButtonListProps['dropdownWidth'];
  selectedStyle?:DropdownProps['selectedStyle'];
}

export function OptionPicker(props:OptionPickerProps) {
  const {type, options, selected, onChange, children, kind, dropdownWidth, multiple, selectedStyle, ...remaining} = props;

  function render() {
    return type == 'button-strip'
      ? <ButtonStrip options={options} multiple={multiple} selected={selected} onChange={onChange as ButtonStripProps['onChange']} {...remaining} />
      : type == 'button-list'
        ? <ButtonList options={options} multiple={multiple} selectedStyle={selectedStyle} value={selected} onChange={handleDropdownChange as ButtonListProps['onChange']} kind={kind} dropdownWidth={dropdownWidth} {...remaining} >{children}</ButtonList>
        : type == 'option-button'
          ? <OptionButton options={options as OptionButtonProps['options']} multiple={multiple} value={selected} onChange={handleDropdownChange as OptionButtonProps['onChange']} {...remaining} />
          : <Dropdown options={options} multiple={multiple} selectedStyle={selectedStyle} value={selected} onChange={handleDropdownChange as DropdownProps['onChange']} dropdownWidth={dropdownWidth} {...remaining} />
  }

  // need to switch from event.value to event.selected
  function handleDropdownChange(event:React.ChangeEvent<any>) {
    if (!onChange) {
      return;
    }

    dispatchChangeEvent(event?.target, event.target.value, onChange, 'selected');
  }

  return render();
}

OptionPicker.fieldProps = ButtonStrip.fieldProps;
OptionPicker.defaultProps = {
  type: 'button-strip'
}

