import * as React from 'react';
import { last } from 'lodash-es';

import { Button, TimeField, Field, FieldRendererProps, Form, RepeatingSection, VBox, useForm } from 'app2/components';
import { PublicPage, SwooshHeader, useCurrentUser } from 'app2/views/shared-public';

import { TimeTrackingProps, getOwner } from '../TimeTrackingProps';

import { useUserCurrentTimeEntriesQuery, timeEntryCheckIn, timeEntryCheckOut } from './generated';

export function TimeTrackingEntry(props:TimeTrackingProps) {
  const { user } = useCurrentUser();

  const variables = getOwner(props);
  const [result] = useUserCurrentTimeEntriesQuery({variables});
  const entries = result.data?.userCurrentTimeEntries;
  
  const form = useForm({entries}, [entries]);

  function render() {
    return <PublicPage useMaxWidth markingLinksWhenLoggedIn={false} header={false} footer={false} hAlign='left'>
      <SwooshHeader title={props.entity?.name} />
      <VBox gap='$30' width='100%'>
        {renderEntries()}
        {renderCheckIn()}
      </VBox>
    </PublicPage>
  }

  function renderEntries() {
    return <Form form={form} editing={false} width='100%' title={`Today's time log for ${user.name}`}>
      <RepeatingSection name='entries' numbered={false} none='No entries yet' fields={[
        <Field label='Checked in' name='checkedIn' component={TimeField} timezone={props.entity?.timezone} />,
        <Field label='Checked out' name='checkedOut' component={TimeField} timezone={props.entity?.timezone} render={renderCheckOut} />
       ]} />
    </Form>
  }

  function renderCheckOut(props:FieldRendererProps) {
    return !props.info.value ? <div><Button onClick={checkOut}>Check out</Button></div> : props.children;
  }

  function renderCheckIn() {
    const lastEntry = last(entries);
  
    if (lastEntry && !lastEntry.checkedOut) {
      return;
    }

    return <div><Button onClick={checkIn}>Check in</Button></div>;
  }

  async function checkIn() {
    return timeEntryCheckIn({variables});
  }

  async function checkOut() {
    return timeEntryCheckOut({variables});
  }

  return render();
}

