import * as React from 'react'

import { CardBrand } from 'app2/api';
import { Box, BoxProps } from 'app2/components';

const cardBrandToPfClass = {
  [CardBrand.Unknown]: 'pf-credit-card',
  [CardBrand.Visa]: 'pf-visa',
  [CardBrand.Mastercard]: 'pf-mastercard',
  [CardBrand.AmericanExpress]: 'pf-american-express',
  [CardBrand.Discover]: 'pf-discover',
  [CardBrand.DinersClub]: 'pf-diners',
  [CardBrand.Jcb]: 'pf-jcb',
  [CardBrand.UnionPay]: 'pf-unionpay'
};

interface Props extends BoxProps {
  kind:CardBrand;
}

export function CardIcon(props:Props) {
  const {kind, ...remaining} = props;

  return <Box fontSize='20px' {...remaining} className={'pf ' + cardBrandToPfClass[kind]} />
}

CardIcon.defaultProps = {
  kind: CardBrand.Unknown
}