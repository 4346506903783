import * as React from 'react'

import { InputProps } from './Input'
import { isCurrencyLike, floatParser, formatCurrency } from './form';
import { RestrictedInput } from './RestrictedInput';

export const CurrencyInput = React.forwardRef((props:InputProps, ref:any) => {
  return <RestrictedInput parse={(value:string) => floatParser(value, Number(props.min), Number(props.max))} format={formatCurrency} valid={isCurrencyLike} placeholder='0' {...props} />
})

CurrencyInput.displayName = 'CurrencyInput';
