import * as React from 'react';

import { Rect, getElement } from './dom-utils';

// given a dom node, this will find text and highlight 
// the text above the dom node

interface Props {
  parent:React.MutableRefObject<HTMLDivElement | HTMLElement>;
  text?:string | string [];
  focus?:boolean;
}

export function TextHighlighter(props:Props) {
  const ref = props.parent;
  const validNode = ref.current != null;
  const pattern = useRegExp(props.text);

  function render() {
    if (!validNode || !pattern) {
      return <></>;
    }

    const mainR = ref.current.getBoundingClientRect();
    const ranges = findText(ref.current, pattern);

    return <>{
      ranges.map((range, index) => {
        const r = new Rect(range.getBoundingClientRect());
        const element = getElement(range.commonAncestorContainer);
        const pR = new Rect(element.getBoundingClientRect());
      
        if (!r.overlaps(pR)) {
          return;
        }

        const left = (r.left - mainR.left - 2) + 'px';
        const top = (r.top - mainR.top - 2) + 'px';
        const width = (r.width + 4) + 'px';
        const height = (r.height + 4) + 'px';

        return <div key={index} data-th style={{pointerEvents: 'none', zIndex: 20, borderRadius:'4px', left, top, width, height, position:'absolute', 
          backdropFilter: props.focus ? 'sepia(1) saturate(1000%) hue-rotate(340deg)' : 'sepia(1) saturate(1000%) hue-rotate(175deg)',
        }} />
      })
    }</>
  }

  return render();
}

function findText(node:Node, pattern:RegExp) {
  let ranges:Range[] = [];
  
  if (node.nodeType == Node.ELEMENT_NODE && (node as Element).hasAttribute('data-th')) {
    return ranges;
  }

  if (node.nodeType == Node.TEXT_NODE) {
    const content = node.textContent;
    let match:RegExpExecArray;

    while (match = pattern.exec(content)) {
      const range = new Range();
      range.setStart(node, match.index);
      range.setEnd(node, match.index + match[0].length);
      ranges.push(range);
    }
  }

  node = node.firstChild;

  while (node) {
    ranges = ranges.concat(findText(node, pattern));
    node = node.nextSibling;
  }

  return ranges;
}

function useRegExp(text:string | string []) {
  return React.useMemo(() => {
    const words = !text ? [] : Array.isArray(text) ? text : [text];
    return words.length
      ? new RegExp(words.map(removeBadChars).join('|'), 'ig')
      : null;
  }, [text]);
}

function removeBadChars(s:string) {
  return s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

