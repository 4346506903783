import * as React from 'react';
import pluralize from 'pluralize';
import { last } from 'lodash-es';

import { CourseUtils, EnrollmentUtils, PriceConfigKind, SeasonPriceConfig } from 'app2/api';
import { Field, Form, FormModel, Info, TextAreaField, Modal, RepeatingSection, Section, Text, useForm, useForceUpdate } from 'app2/components';
import { EnrollmentForm, getEnrollmentConfig, EnrollmentConfigurationForm, formatConfigurableSeasonPrice, courseKindBehavior } from 'app2/views/shared-public';
import { StudentNameField, ScheduleField } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled';
import { DiscountComboAndBreakdown, EnrollmentDiscountForm, useEnrollmentBreakdown } from '../price-config';
import { ChangeSeasonPlanMutationVariables, changeSeasonPlan } from './generated';

interface EditForm extends EnrollmentForm, EnrollmentDiscountForm {
  enrollments: DistinctEnrollmentsSelections[];
  description?: string;
}

interface Props {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function ChangeSeasonPlan(props: Props) {
  const { course, enrollments } = props;

  // todo: does this need to be passed? it can be derived from course
  const behavior = courseKindBehavior[course?.kind];

  const protoEnrollment = enrollments[0];
  const protoConfig = EnrollmentUtils.findPriceConfig(protoEnrollment, config => config.kind == PriceConfigKind.Season) as SeasonPriceConfig;

  const discount = last(protoEnrollment?.discountUses) || { rate: null, format: null };
  const form = useForm<EditForm>({ id: props.course?.id, configurableSeason: protoConfig, ...discount, description: protoConfig?.updateDescription, enrollments }, [course], {alwaysSave: true});
  const config = getEnrollmentConfig(form);
  // todo - need to add discount here? (form.values)
  const { first } = useEnrollmentBreakdown({course: course.id, config, changingEnrollment:protoEnrollment});

  const forceUpdate = useForceUpdate();
  checkForDefaultConfig();

  function render() {
    return (
      <Modal title="Change plans">
        <Form width="100%" form={form} onOk={handleSubmit} editing onNavigation="nothing">
          <RepeatingSection
            name="enrollments"
            bordered
            fields={[
              <Field label="Student" name="student.name" readOnly nameKind="full" link={false} component={StudentNameField} />,
              <Field label="Plan" name="priceConfigs" readOnly render={props => renderPlan(props.info.record)} />,
              <Field label="Schedule" name="rosterPeriods" readOnly component={ScheduleField} />
            ]}
          />
          <EnrollmentConfigurationForm course={props.course} form={form} kind={PriceConfigKind.ConfigurableSeason} />
          <DiscountComboAndBreakdown parentCourse={props.course} course={props.course} config={config} changingEnrollment={protoEnrollment} />
          <Section label="Reason for the change" name="description" autoSize minHeight="65px" component={TextAreaField} />
          {renderWarnings()}
        </Form>
      </Modal>
    );
  }

  function renderPlan(e: DistinctEnrollmentsSelections) {
    const config = e.priceConfigs.find(p => p.kind == PriceConfigKind.Season) as SeasonPriceConfig;
    const price = config ? CourseUtils.findConfigurableSeasonPriceForConfig(props.course.prices, config) : null;

    return price ? formatConfigurableSeasonPrice(behavior, price) : <Text color="red">No plan</Text>;
  }

  function renderWarnings() {
    const messages = [`${pluralize('Student', enrollments.length)} will be enrolled in new plan immediately`];
    const refund = Boolean(first?.refund || 0);
    const charge = Boolean(first?.listPrice || 0);
    if (refund && charge) {
      messages.push("Families will be refunded for the previous plan and then charged for the new one. There may be an overlap where the refund is processing as the new charge is made, which could result in a failed transaction if the refund is not yet complete.")
    }

    return <Info type="warning" message={messages} />
  }

  // EnrollmentConfigurationForm creates a default config, but after we are rendered sometimes
  // so we need to check for it after we render and potentially force update
  function checkForDefaultConfig() {
    React.useEffect(() => {
      if (!config && getEnrollmentConfig(form)) {
        forceUpdate();
      }
    });
  }

  async function handleSubmit(form: FormModel<EditForm>) {
    const values = form.values;
    const variables: ChangeSeasonPlanMutationVariables = {
      courseId: props.course.id,
      ids: values.enrollments.map(e => e.id),
      kind: config.kind,
      configurableSeason: config.configurableSeason,
      description: values.description,
      discountId: values.discount,
    };
    if (values.format && values.rate) {
      variables.discount = { format: values.format, rate: values.rate };
    }

    const successMsg = `${pluralize('Plan', values.enrollments.length, true)} changed`;
    const [success] = await changeSeasonPlan({ variables, successMsg });

    return success;
  }

  return render();
}
