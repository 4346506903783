import * as React from 'react'

import { DateField, Field, Panel, RepeatingSection, SHORT_DATE_TIME_FORMAT } from 'app2/components';
import { FileLink } from 'app2/views/shared'

import { UserPage } from '../UserPage';

import { useUserFilesQuery } from './generated';

export function Files() {
  const [result] = useUserFilesQuery();
  const user = result.data?.user;

  function render() {
    return <UserPage title='Downloads' subtitle='Downloads are automatically removed after 7 days'>
      <Panel initialValues={user} type='display' onNavigation='nothing'>
        <RepeatingSection name='files' numbered={false} equalWidths={false} fields={[
          <Field name='createdAt' component={DateField} width='200px' dateFormat={SHORT_DATE_TIME_FORMAT} />,
          <Field name='name' component={FileLink} />
        ]} />
      </Panel>
    </UserPage>
  }

  return render();
}
