import * as React from 'react'
import { pick } from 'lodash-es';

import { Panel, Section, Part, InputField, EmailField, PhoneField, FormModel } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';
import { updatePersonalInfo } from 'app2/views/shared';

import { UserPage } from '../UserPage';

import { PasswordForm } from './PasswordForm';

export function AccountSettings() {
  const { user } = useCurrentUser();
  
  function render() {
    return <UserPage title='Account settings'>
      <Panel icon='FileText' title='Personal information' type='toggle' initialValues={user} onOk={onOk}>
        <Section label='Name'>
          <Part label='First' name='firstName' component={InputField} required />
          <Part label='Last' name='lastName' component={InputField} required />
        </Section>
        <Section label='Email address' name='email' component={EmailField} required />
        <Section label='Phone number' name='phone' component={PhoneField} required />
      </Panel>
      <PasswordForm />
    </UserPage>
  }

  return render();
}

async function onOk(form:FormModel) {
  const personalInfo = pick(form.values,['email', 'firstName', 'lastName', 'phone'])
  const [success] = await updatePersonalInfo({ variables: { personalInfo }, successMsg: "Personal information updated successfully.", error: form })
  return success;
}
