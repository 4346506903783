import * as React from 'react';

import { parentPreferences } from 'app2/api';
import { Dropdown, DropdownProps, HBox, Subtitle1 } from 'app2/components';
import { createOptions, useDropdownWithUrl } from 'app2/views/shared-public';

import { useUserFamiliesQuery } from './generated'

export type FamilyDropdownProps = Partial<DropdownProps>;

export function FamilyDropdown(props:FamilyDropdownProps) {
  const query = useUserFamiliesQuery();
  const families = query[0].data?.user?.families;
  const options = React.useMemo(() => createOptions(families), [families]);
  const valueAndOnChange = useDropdownWithUrl({options, preferences: parentPreferences, urlParameter: 'family', valueParameter: 'id'});

  const fetching = Boolean(query[0].fetching || !valueAndOnChange.value);
  const onlyOne = Boolean(options.length == 1 && valueAndOnChange.value == options?.[0]?.value)

  return fetching || onlyOne
    ? <></>
    : <HBox flex={1} minWidth='280px' vAlign='center' hAlign='right'>
      <Subtitle1 mr='$8'>Family</Subtitle1>
      <Dropdown flex={[1, 'unset', 'unset']} options={options} {...valueAndOnChange} tooltips
        // @ts-ignore - ts is incorrectly generating an error here
        width={['unset', '300px', '300px']} {...props} />
    </HBox>
}
