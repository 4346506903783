import * as React from 'react'
import { omit } from 'lodash-es'

import { PreferenceStore } from '../preferences';
import { BoxProps } from '../Box'

import { TabStripTab } from './TabStripTab'
import { TabStripHookProps } from './TabStripHookProps'
import { useTabStrip } from './useTabStrip'
import { TabStrip } from './TabStrip'
import { TabProps } from './Tab'

export interface TabsHookProps<T extends PreferenceStore = any> extends Omit<TabStripHookProps<T>, 'tabs'> {
  tabs:TabProps[];
  tabStrip?:Omit<BoxProps, 'onChange'>;
}

export interface RenderedTabs {
  tabs:React.ReactNode;
  content:React.ReactNode;
  normalizedTabs:TabStripTab[];
  hideTabs:boolean;
  selected:number;
}

export function useTabs<T extends PreferenceStore = any>(props:TabsHookProps<T>):RenderedTabs {
  const {tabs, tabStrip, height, width, ...remaining} = props;
  const {selectedTab, normalizedTabs, tabState} = useTabStrip(props);
  const tabsWoContent = normalizedTabs?.map(tab => omit(tab, 'content')) as TabStripTab[];
  const content = (normalizedTabs as TabProps[])[selectedTab]?.content;

  const renderedTabs = tabState.hideTabs ? <></> : <TabStrip width='100%' tabs={tabsWoContent} tabState={tabState} {...remaining} />;
  const renderedContent = content;

  return {tabs: renderedTabs, content: renderedContent, normalizedTabs:normalizedTabs, hideTabs:tabState.hideTabs, selected:selectedTab}
}
