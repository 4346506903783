import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminStudentsSearchQueryVariables = Types.Exact<{
  term?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type AdminStudentsSearchQuery = { students?: Array<{ id: string; name: string }> | null | undefined };

export const AdminStudentsSearchDocument = gql`
  query AdminStudentsSearch($term: [Filter!]) {
    students(filters: $term, sorts: [{ name: "name", direction: ASC }]) {
      id
      name
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminStudentsSearchArgs = MakeOptional<UseQueryArgs<AdminStudentsSearchQueryVariables, AdminStudentsSearchQuery>, 'query'>;

export function useAdminStudentsSearchQuery(options: AdminStudentsSearchArgs = {}) {
  return useQuery<AdminStudentsSearchQueryVariables, AdminStudentsSearchQuery>({ query: AdminStudentsSearchDocument, ...options });
}

export type AdminStudentsSearchOptions = ExecQueryOptions<AdminStudentsSearchQueryVariables>;

export function adminStudentsSearch(options: AdminStudentsSearchOptions) {
  return executeQuery<AdminStudentsSearchQuery, AdminStudentsSearchQueryVariables>(AdminStudentsSearchDocument, options);
}

export type AdminStudentsSearchSelections = AdminStudentsSearchQuery['students'][0];

(AdminStudentsSearchDocument as any).additionalTypenames = ['Student'];
