import * as React from 'react';

import { Panel } from 'app2/components';

import { useSiteQuery } from '../shared';

import { QrCode } from './QrCode';
import { useSiteWithTimeTrackingQuery } from './gql';

export function TimeTracking() {
  const { site } = useSiteQuery(useSiteWithTimeTrackingQuery);

  function render() {
    return <Panel icon="Clock" title="Time tracking" type="display">
      <QrCode qrCode={site?.timeEntryQrCode} link={{to:site?.timeEntryUrl, children: 'Time entry page'}} />
    </Panel>
  }

  return render();
}
