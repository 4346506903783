import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const RadioSelected = React.forwardRef((props: Props, ref: React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 16 16" stroke={color} strokeWidth={1} strokeLinejoin="round">
    <g>
      <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" fill='none' />
      <circle cx="7.99989" cy="8.00001" r="3.55556" fill='currentColor' />
    </g>
  </svg>
})

RadioSelected.displayName = 'RadioSelected';
RadioSelected.defaultProps = {
  size: 18
};
