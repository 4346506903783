// encapsulates capturing the mouse and its cleanup.  also it provides a 
// timer for drag scrolling when the mouse doesn't move.

export interface MouseCapture {
  stop(): void;
}

type MouseEvents =
  | MouseEvent
  | PointerEvent
  | React.MouseEvent
  | React.PointerEvent;

export function captureMouse(onMouseMove: (event: MouseEvent) => void, onMouseUp: (event: MouseEvent) => void, event?: MouseEvents, element?: HTMLElement): MouseCapture {
  const handler = new MouseCaptureHandler(onMouseMove, onMouseUp, event, element);
  return handler;
}

class MouseCaptureHandler implements MouseCapture {
  _listenersAdded: boolean;
  _onMouseMove: (event: MouseEvent) => void;
  _onMouseUp: (event: MouseEvent) => void;
  _element: HTMLElement;
  _pointerId: number;
  _lastEvent: MouseEvent;
  _interval: any;

  constructor(onMouseMove: (event: MouseEvent | PointerEvent) => void, onMouseUp: (event: MouseEvent) => void, localEvent?: MouseEvents, element?: HTMLElement) {
    const eventTouse = event || localEvent;

    this._onMouseMove = onMouseMove;
    this._onMouseUp = onMouseUp;
    this._element = element || (eventTouse.target as HTMLElement);

    if ((eventTouse as PointerEvent).pointerId) {
      this._pointerId = (eventTouse as PointerEvent).pointerId;
    }

    this.addListeners();
  }

  addListeners() {
    this.removeListeners();

    this._listenersAdded = true;

    if (this._pointerId) {
      this._element.setPointerCapture(this._pointerId);
      this._element.addEventListener('pointermove', this.onMouseMove);
      this._element.addEventListener('pointerup', this.onMouseUp);
    } else {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    }

    this._interval = setInterval(this.onInterval, 100);
  }

  removeListeners() {
    if (!this._listenersAdded) {
      return;
    }

    this._listenersAdded = false;

    if (this._pointerId) {
      this._element.releasePointerCapture(this._pointerId);
      this._element.removeEventListener('pointermove', this.onMouseMove);
      this._element.removeEventListener('pointerup', this.onMouseUp);

      this._pointerId = undefined;
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
    }

    clearInterval(this._interval);

    this._element = undefined;
    this._lastEvent = undefined;
    this._interval = undefined;
  }

  stop = () => {
    this.removeListeners();
  };

  onMouseMove = (event: MouseEvent | PointerEvent) => {
    this._lastEvent = event;

    // check if the button was released while the mouse
    // was outside the browser area
    if (this.buttonReleased(event)) {
      const mouseUp = {
        type: 'mouseup',
        altKey: event.altKey,
        button: event.button,
        buttons: event.buttons,
        clientX: event.clientX,
        clientY: event.clientY,
        ctrlKey: event.ctrlKey,
        metaKey: event.metaKey,
        movementX: event.movementX,
        movementY: event.movementY,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
        pageX: event.pageX,
        pageY: event.pageY,
        relatedTarget: event.relatedTarget,
        target: event.target,
        screenX: event.screenX,
        screenY: event.screenY,
        shiftKey: event.shiftKey,
        x: event.x,
        y: event.y,
        getModifierState: (keyArg: string) => {
          event.getModifierState(keyArg);
        },
        defaultPrevented: true,
        eventPhase: event.eventPhase,
        preventDefault: () => {},
        stopImmediatePropagation: () => {},
        stopPropagation: () => {},
      } as MouseEvent

      return this.onMouseUp(mouseUp);
    }

    if (this._onMouseMove) {
      this._onMouseMove(event);
    }
  };

  onMouseUp = (event: MouseEvent) => {
    this.stop();

    if (this._onMouseUp) {
      this._onMouseUp(event);
    }
  };

  onInterval = () => {
    if (!this._lastEvent) {
      return;
    }

    if (this._onMouseMove) {
      this._onMouseMove(this._lastEvent);
    }
  };

  buttonReleased(event: MouseEvent) {
    return event.buttons == 0;
  }
}