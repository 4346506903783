import * as React from 'react';

import { eventManager } from 'app2/api';
import { ButtonMenu, copyToClipboard, MenuDropdown, MenuItem, NotificationManager, Img, TextProps } from 'app2/components';
import { FacebookLoader } from 'app/scriptLoading';
import facebookShareSVG from 'images/facebook_share.svg';

export interface ShareButtonProps extends Omit<TextProps, 'link' | 'as'> {
  // event to record when the button is clicked
  event?:string;
  // button label
  label?:string;
  // only applies to facebook share
  link:string;
  // message used in all sharing types
  message:string;
  // only applies to email share
  subject:string;

  // render as an icon, not a button
  icon?:boolean
}

export function ShareButton(props:ShareButtonProps) {
  const mobileShareBlocked = React.useRef(false);
  const {event, label, link, message, subject, icon, ...remaining} = props;

  function render() {
    return !icon ? renderButtonMenu() : renderIconMenu();
  }

  function renderButtonMenu() {
    return <ButtonMenu icon='Share2' iconPosition='left' menu={renderInviteMenu()} onMouseDown={handleNavigatorShare} {...remaining}>{label}</ButtonMenu>
  }

  function renderIconMenu() {
    return <MenuDropdown icon='Share2' label={label} onMouseDown={handleNavigatorShare} {...remaining}>{renderInviteMenu()}</MenuDropdown>
  }

  function renderInviteMenu() {
    const mail = `mailto:?subject=${subject}&body=${encodeURIComponent(`${message}\n\n${link}`)}`;

    return [
      <MenuItem key='copy' icon='Copy' onClick={handleCopyLinkClick}>Copy</MenuItem>,
      <MenuItem key='mail' icon='Mail' onClick={handleEmailShareClick} to={mail}>Email</MenuItem>,
      <FacebookLoader key='fb'><MenuItem key='fb' onClick={handleFacebookShareClick}><Img src={facebookShareSVG} width='20px' mr='$8' />Facebook</MenuItem></FacebookLoader>
    ];
  }

  function logShareEvent(shareType: string) {
    if (!event) {
      return;
    }

    eventManager.log(event, {
      shareType: shareType
    });
  }

  function handleNavigatorShare(e: React.SyntheticEvent<HTMLElement>): void {
    if (!navigator.share || mobileShareBlocked.current) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    logShareEvent('Web Share');

    navigator.share({
      text: message,
      url: link
    }).catch(() => {
      mobileShareBlocked.current = true;
      e.currentTarget?.click?.();
    });
  }

  function handleCopyLinkClick(e: React.SyntheticEvent<HTMLElement>): void {
    e.preventDefault();

    logShareEvent('Link');

    copyToClipboard(message + '\n\n' + link);

    NotificationManager.add('Copied!');
  }

  function handleEmailShareClick(e: React.SyntheticEvent<HTMLElement>): void {
    e.stopPropagation();

    logShareEvent('Email');
  }

  function handleFacebookShareClick(e: React.SyntheticEvent<HTMLElement>): void {
    e.preventDefault();

    logShareEvent('Facebook');

    FB.ui({
      method: 'share',
      quote: message,
      href: link
    });
  }  

  return render();
}
