import * as React from 'react'

import { useFormInfo } from 'app2/components'

import { Ach } from './Ach';
import { UsBankAccountModel } from './UsBankAccountModel';
import { parentRemoveUsBankAccount } from './generated'

interface Props {
  onRemove?:() => void;
}

export function AchForm(props:Props) {
  const info = useFormInfo<UsBankAccountModel>();
  const form = info.form;

  function render() {
    return form.values.existingUsBankAccount
      ? renderAch()
      : renderAchForm()
  }

  function renderAch() {
    return <div><Ach usBankAccount={form.values.existingUsBankAccount} onRemove={onRemoveUsBankAccount} /></div>
  }

  async function onRemoveUsBankAccount() {
    const [success] = await parentRemoveUsBankAccount({ variables: { fingerprint: form.values.existingUsBankAccount.fingerprint }});

    props.onRemove?.();

    return success;
  }

  function renderAchForm() {
    return <></>;
  }

  return render();
}



