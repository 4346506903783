import * as React from 'react'
import { useParams } from 'react-router'

import { Section, FormModel, CheckboxField } from 'app2/components'
import { BaseSchoolPolicies } from 'app2/views/shared-public'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerSiteSchoolPoliciesQuery } from './gql'

interface Props {
    handleOk: (form:FormModel) => void;
}

export function SchoolPolicies(props: Props) {
  const {site} = useParams<SiteRouteParams>();
  const [policiesResult] = useOrganizerSiteSchoolPoliciesQuery({ variables: { siteId: site } })
  const data = policiesResult.data?.site.policy;

  return <BaseSchoolPolicies type='toggle' initialValues={data} onOk={form => props.handleOk(form)}>
    <Section label='Allow families to withdraw' name='parentWithdrawalsEnabled' component={CheckboxField} />
  </BaseSchoolPolicies>
}
