import moment from 'moment-timezone';
import pluralize from 'pluralize';

import { NotificationManager, useLifecycle, noOpErrorHandler } from 'app2/components';

import { publicCartExpiration } from './generated';

interface Milestone {
  label: string;
  value: number;
}

const milestones: Milestone[] = [
  { label: '15m', value: 900000 },
  { label: '10m', value: 600000 },
  { label: '5m', value: 300000 },
  { label: '1m', value: 60000 },
  { label: '0m', value: 0 }
];

const notificationTimeouts: { [key: string]: number } = {};

let pollInterval: number;

export function useCartNotifications() {
  useLifecycle({ onMount, onUnmount }, []);

  async function onMount() {
    fetchCart();
    pollCart();
  }

  function onUnmount() {
    stopPolling();
    clearCartNotificationTimeouts();
  }

  function pollCart() {
    pollInterval = window.setInterval(fetchCart, 60000);
  }

  async function fetchCart() {
    const [success, cartResult] = await publicCartExpiration({ variables: {}, error: noOpErrorHandler });
    if (!success) {
      return;
    }

    const expiration = cartResult.data.cartExpiration;
    registerTimeouts(expiration);
  }

  function registerTimeouts(expires: string) {
    if (!expires) {
      clearCartNotificationTimeouts();
      return;
    }

    const now = moment();
    const expiresAt = moment(expires);
    const tte = expiresAt.diff(now);
    milestones.forEach(milestone => registerTimeout(milestone, tte));
  }

  function registerTimeout(milestone: Milestone, tte: number) {
    const existingTimeout = notificationTimeouts[milestone.label];
    if (existingTimeout) {
      clearTimeout(existingTimeout);
    }

    const interval = milestone.value;
    if (interval > tte) {
      return;
    }

    const delay = tte - interval;
    notificationTimeouts[milestone.label] = window.setTimeout(notificationCallback(milestone), delay);
  }

  function notificationCallback(milestone: Milestone) {
    return function() {
      let message;

      if (milestone.label === '0m') {
        message = 'Your cart has expired';
      } else {
        const interval = milestone.value;
        const minsLeft = interval / 60000;
        message = `Your cart will expire in ${minsLeft} ${pluralize('minute', minsLeft)}`;
      }

      NotificationManager.add({ type: 'warning', message });
    };
  }
}

export function clearCartNotificationTimeouts() {
  Object.values(notificationTimeouts).forEach(timeout => clearTimeout(timeout));

  for (const key in notificationTimeouts) {
    delete notificationTimeouts[key];
  }
}

function stopPolling() {
  clearInterval(pollInterval);
}
