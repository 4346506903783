import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrDeleteCoursesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  includeOptions?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type CancelOrDeleteCoursesMutation = { coursesCancelOrDelete?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const CancelOrDeleteCoursesDocument = gql`
  mutation CancelOrDeleteCourses($ids: [ID!]!, $includeOptions: Boolean) {
    coursesCancelOrDelete(ids: $ids, includeOptions: $includeOptions) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CancelOrDeleteCoursesOptions = ExecMutationOptions<CancelOrDeleteCoursesMutationVariables>;

export function cancelOrDeleteCourses(options: CancelOrDeleteCoursesOptions) {
  return executeMutation<CancelOrDeleteCoursesMutation, CancelOrDeleteCoursesMutationVariables>(CancelOrDeleteCoursesDocument, options);
}
