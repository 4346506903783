import * as React from 'react'
import { useParams } from 'react-router';

import { StripeConnection } from 'app2/views/shared';

import { SiteRouteParams } from '../organizerRoutes'
import { useOrganizerStripeUrlsQuery } from './gql'

export function Stripe() {
  const { site: siteId } = useParams<SiteRouteParams>();

  function render() {
    return <StripeConnection isSite={true} id={siteId} query={getStripeData} />
  }

  function getStripeData() {
    const [ organizerStripeUrlsQueryResult ] = useOrganizerStripeUrlsQuery({variables: { siteId }});
    const site = organizerStripeUrlsQueryResult.data?.site

    return site;
  }

  return render();
}
