import * as React from 'react'
import moment from 'moment'

import { VBox } from '../Box'
import { Subtitle2, FormLabel } from '../Text';

export interface Props {
  startOfWeek:moment.Moment;
  numDays:number;
  day:number;
  appointments:React.ReactElement[];
}

export function DayOfWeek(props:Props) {
  const {startOfWeek, numDays, day, appointments} = props;

  const date = startOfWeek.clone().add(day, 'd');
  const isToday = date.isSame(moment().startOf('d'));

  return <VBox flex={1} minWidth='210px'>
    <VBox hAlign='center' p='$8' whiteSpace='nowrap' bg='backgroundMediumGrey' position='sticky' zIndex={1} top={0}>
      <Subtitle2>{date.format('ddd')}</Subtitle2>
      <FormLabel>{date.format('MMMM D')}</FormLabel>
    </VBox>
    <VBox flex={1} hAlign='justify' bg={isToday ? 'formBackground' : 'white'} p='$8' borderRight={day != numDays -1 ? 'solid 1px' : undefined} borderColor='border' vItemSpace='$8'>
      {appointments?.map((appointment, index) => React.cloneElement(appointment, {key: index}))}
    </VBox>
  </VBox>
}
