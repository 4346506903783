import { UserPreferences, userPreferences } from "app2/api";
import { usePreference } from 'app2/components'

import { useCurrentUser } from './useCurrentUser'

export function useUserPreference<T extends UserPreferences, P extends keyof T>(name:P, initialValue?:T[P]):[T[P], (v:T[P]) => void];
export function useUserPreference<T extends UserPreferences, P extends keyof T>(preference:T, name:P, initialValue?:T[P]):[T[P], (v:T[P]) => void];
export function useUserPreference<T extends UserPreferences, P extends keyof T>(preferenceOrName:T|P, nameOrInitialValue:P|T[P], initialValueMaybe?:T[P]):[T[P], (v:T[P]) => void] {
  const preference = typeof preferenceOrName === 'string' ? userPreferences as T : preferenceOrName as T;
  const name = typeof preferenceOrName === 'string' ? preferenceOrName as P : nameOrInitialValue as P;
  const initialValue = typeof preferenceOrName === 'string' ? nameOrInitialValue as T[P] : initialValueMaybe;

  const { user } = useCurrentUser();

  return usePreference<T, P>(preference, name, initialValue, [user?.id, initialValue]);
}
