import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyInviteAcceptMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type FamilyInviteAcceptMutation = { familyInviteAccept?: { success?: boolean | null | undefined } | null | undefined };

export const FamilyInviteAcceptDocument = gql`
  mutation FamilyInviteAccept($token: String!) {
    familyInviteAccept(token: $token) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type FamilyInviteAcceptOptions = ExecMutationOptions<FamilyInviteAcceptMutationVariables>;

export function familyInviteAccept(options: FamilyInviteAcceptOptions) {
  return executeMutation<FamilyInviteAcceptMutation, FamilyInviteAcceptMutationVariables>(FamilyInviteAcceptDocument, options);
}
