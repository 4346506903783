
type Predicate = (node:Node) => boolean;

export function traverseDom(node:Node, predicate:Predicate):Node {
  if (!node) {
    return null;
  }

  do {
    if (predicate(node)) {
      return node;
    }

    node = node.firstChild || getNextSiblingOrParentSibling(node);
  }
  while (node);

  return null;
}

export function getNextSiblingOrParentSibling(node:Node) {
  if (!node) {
    return null;
  }

  if (node.nextSibling) {
    return node.nextSibling;
  }

  while ((node = node.parentNode) && !node.nextSibling);

  return node?.nextSibling;
}

export function getPrevSiblingOrParentSibling(node:Node) {
  if (!node) {
    return null;
  }

  if (node.previousSibling) {
    return node.previousSibling;
  }

  while ((node = node.parentNode) && !node.previousSibling);

  return node?.previousSibling;
}