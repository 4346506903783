import * as React from 'react';

import { CompanyRole } from 'app2/api';
import { findRoute, Redirect } from 'app2/components';
import { Route } from 'app2/views/shared-public';

import { QuickStartGuide } from './quick-start-guide';
import { Schedule } from './schedule';
import { CompanyCourses } from './courses';
import { GeneralInfo } from './general-info';
import { PaymentTools } from './payment-tools';
import { CourseFrameworks } from './course-frameworks';
import { Team } from './team';
import { Sites } from './sites';
import { Teachers } from './teachers';
import { Reports } from './reports';
import { CompanyMessages } from './messages';
import { Setup } from './setup';

export const providerRoutes = [
  <Route path="/provider/:company?/quick-start-guide" component={QuickStartGuide} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/schedule/:week?" component={Schedule} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/activities" component={CompanyCourses} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/templates" exact component={CourseFrameworks} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/setup/:tab(general-info)" exact component={Setup} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/setup/:tab(payment)" exact component={Setup} role={CompanyRole.CompanyAdmin} />,
  <Route path="/provider/:company?/setup/:tab(team)" exact component={Setup} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/setup" exact component={Setup} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/schools" component={Sites} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/instructors" component={Teachers} role={CompanyRole.CompanyMember} />,
  <Route path="/provider/:company?/reports/:report(overview|transactions)/:course([0-9]+)?" component={Reports} role={CompanyRole.CompanyAdmin} />,
  <Route path="/provider/:company?/reports" component={Reports} role={CompanyRole.CompanyAdmin} />,
  <Route path="/provider/:company?/messages" component={CompanyMessages} role={CompanyRole.CompanyMember} />,

  <Redirect from="/provider/:company" to="/provider/:company/schedule" exact />,
  <Redirect from="/provider" to="/provider/schedule" exact />,

  // setup page redirects
  <Redirect from="/provider/:company?/general-info" to="/provider/:company?/setup/general-info" />,
  <Redirect from="/provider/:company?/payment" to="/provider/:company?/setup/payment" />, 
  <Redirect from="/provider/:company?/team" to="/provider/:company?/setup/team" />,

  // old
  <Redirect from="/provider/courses/create/site-search" to='/activities/manage/:course' />,
  <Redirect from="/provider/courses/:course" to='/provider/activities' />,
  <Redirect from="/provider/catalog" to='/provider/templates' />,  
  <Redirect from="/vendor/teachers" to='/provider/instructors' />,
  <Redirect from="/vendor/schools" to='/provider/schools' />,
  <Redirect from="/vendor/reports" to='/provider/reports' />,
  <Redirect from="/vendor" to='/provider' />,
]

export interface CompanyRouteParams {
  company:string;
}

export function getCompanyRoutePath(name:string) {
  return findRoute('/provider/' + name, providerRoutes)?.props?.path;
}
