import * as React from 'react';

import { Modal, ModalProps } from '../modal';

import { TabsHookProps, useTabs } from './useTabs'

export type ModalWithTabsProps = TabsHookProps & ModalProps;

export function ModalWithTabs(props:ModalWithTabsProps) {
  const {tabs, tabStrip, arrows, header, ...modalProps} = props;
  const rendered = useTabs({tabs, tabStrip, arrows});

  return <Modal header={<>{header}{rendered.tabs}</>} content={rendered.content} {...modalProps} />
}
