import * as React from 'react'
import { matchPath } from 'react-router';

import { isImpersonatingUser, organizerPreferences, providerPreferences, setPreferenceUserId } from 'app2/api';
import { noOpErrorHandler, MultiContextProvider, useHistory } from 'app2/components'

import { CurrentUserSelections, CurrentUserArgs, useCurrentUserQuery } from './generated'

// ensures the current user is loaded in a consistent way and
// available to all components and we can cache any info at the top

export interface CurrentUserProps {
  onChangeUser?:(user:CurrentUserSelections) => void;
  children:React.ReactNode;
}

export function CurrentUser(props:CurrentUserProps) {
  const params = getParams();  
  const currentUser = React.useRef<CurrentUserSelections>();
  const user = getCurrentUser();
  possibleClearPreferences();
  possibleNotifyOfUserChange();

  function getParams() {
    // we can't use useParams to get the site/company/course because
    // CurrentUser is above the switch, and useParams only works
    // below the switch.  we can't use useRouteInfo because it
    // requires the user, which is this component.  so we use 
    const history = useHistory();
    const route = React.useMemo(() => history.findRoute(history.location.pathname), [history.location.pathname]);
    const match = matchPath<{site?:string, company?:string, course?:string}>(history.location.pathname, route?.props);
    const params = match?.params || {};

    return params;
  }

  function getCurrentUser() {
    const variables = getVariables();
    const [result, reexecuteQuery] = useCurrentUserQuery({hideLoader: true, error: noOpErrorHandler, variables, autoPause: false});

    const impersonating = isImpersonatingUser();
    const homeroom = impersonating || result.data?.currentUser?.roles?.admin || result.data?.currentUser?.email?.endsWith('homeroom.com');
    const userObject = React.useMemo(() => result.data?.currentUser ? {...result.data?.currentUser, impersonating, homeroom} : null, [result.data?.currentUser, impersonating, homeroom]);

    const user = {
      user: userObject,
      fetching: result.fetching,
      stale: result.stale,
      loading: result.fetching || result.stale,
      reexecute: reexecuteQuery,
    }
    
    return user;
  }

  function getVariables() {
    const variables:CurrentUserArgs['variables'] = {};

    if (params.course && !params.site && !params.company) {
      variables.course = params.course;
    }
    else {
      variables.site = params.site || (organizerPreferences.userId && organizerPreferences.site);
      variables.company = params.company || (providerPreferences.userId && providerPreferences.company);
    }

    return variables;
  }

  function possibleClearPreferences() {
    // in case the user doesn't have access to a preference, clear the preference
    // so we dont attempt to keep reloading that site/company for the user
    if (user.user && user.user.roles && !user.user.roles.site && params.site && organizerPreferences.site) {
      organizerPreferences.site = null;
    }
  
    if (user.user && user.user.roles && !user.user.roles.company && params.company && providerPreferences.company) {
      providerPreferences.company = null;
    }
  }

  function possibleNotifyOfUserChange() {
    if (currentUser.current?.id != user.user?.id) {
      setPreferenceUserId(user.user?.id);
      currentUser.current = user.user;
      props.onChangeUser?.(user.user);
    }
  }

  function render() {
    return <MultiContextProvider user={user}>
      {props.children}
    </MultiContextProvider>
  }

  return render();
}
