export function isPromise(possiblePromise:any) {
  return possiblePromise && typeof possiblePromise.then === 'function' && typeof possiblePromise.catch === 'function';
}

// polyfill for old versions of safari < 13

if (!(Promise as any).allSettled) {
  (Promise as any).allSettled = function (promises:any[]) {
    let mappedPromises = promises.map((p) => {
      return p
        .then((value:any) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason:any) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });
    return Promise.all(mappedPromises);
  };
}