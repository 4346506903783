import * as React from 'react';

import { useObservable } from './useObservable';

export function makeFunctionObserver<T extends React.FunctionComponent>(
  func: T
): React.FunctionComponent {
  // @ts-ignore
  return useObservable.bind(useObservable, func);
}
