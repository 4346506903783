import * as React from 'react'
import { uniqBy } from 'lodash-es'

import { MessageServiceKind } from 'app2/api';
import { ButtonMenu, VBox } from 'app2/components'
import { Threads, ThreadComposeState } from 'app2/views/shared'
import { CourseLink } from 'app2/views/shared-public'

import { ParentPage } from '../shared';

import { useParentEnrollmentsQuery } from './generated';

export function ParentMessages() {
  const courses = getCourses();

  function render() {
    return <ParentPage title='Messages'>
      <VBox gap='$8' overflow='hidden'>
        {renderPrimaryActions()}
        <Threads role='parent' labelOrParticipant={{type: 'User'}} entityMessages={false} />
      </VBox>
    </ParentPage>
  }

  function renderPrimaryActions() {
    const state:Partial<ThreadComposeState> = {
      replyAll: true,
      hideReplyAll: true,
      service: MessageServiceKind.Email,
      hideService: true
    };
    
    return courses?.length ? <ButtonMenu menu={courses.map(c => <CourseLink role='public' course={c} state={{...state, to:[{...c.company, type:'Company'}]}} suffix='/messages/compose#threads' />)}>Message activity provider</ButtonMenu> : undefined
  }

  function getCourses() {
    const [query] = useParentEnrollmentsQuery();
    const enrollments = query.data?.user?.enrollments;

    return React.useMemo(() => {
      return uniqBy(enrollments, 'course.id').map(enrollment => enrollment.course)
    }, [enrollments])
  }

  return render();
}
