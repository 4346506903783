import * as React from 'react'

import { InviteKind } from 'app2/api';
import { Panel, HBox, Link } from 'app2/components';

import { UserPage } from '../UserPage';

import { useUserInvitesQuery, UserInvitesSelections } from './generated';

type Invite = UserInvitesSelections['invites'][0];

export function Invites() {
  const [result] = useUserInvitesQuery();
  const user = result.data?.user;

  function render() {
    return <UserPage title='Invitations'>
      <Panel initialValues={user} type='display'>
        {renderInvites()}
      </Panel>
    </UserPage>
  }

  function renderInvites() {
    if (user?.invites?.length === 0) {
      return <HBox text='body'>You have no invitations at this time.</HBox>
    }
    
    const renderers = {
      [InviteKind.Site]: renderSiteInvite,
      [InviteKind.Company]: renderCompanyInvite,
      [InviteKind.Family]: renderFamilyInvite,
      [InviteKind.Course]: renderCourseInvite,
      [InviteKind.Enrollment]: renderEnrollmentInvite,
    }

    return user?.invites?.map(invite => renderers[invite.kind](invite))
  }

  function renderSiteInvite(invite:Invite) {
    return renderInvite(`You've been invited to join ${invite.invitedTo}'s team`, `/organizer/team-invite/${invite.token}`)
  }

  function renderCompanyInvite(invite:Invite) {
    return renderInvite(`You've been invited to join ${invite.invitedTo}'s team`, `/provider/team-invite/${invite.token}`);
  }

  function renderFamilyInvite(invite:Invite) {
    return renderInvite(`You've been invited to join ${invite.invitedTo}'s family`, `/family/team-invite/${invite.token}`);
  }  

  function renderCourseInvite(invite:Invite) {
    return renderInvite(`You've been invited to teach at ${invite.invitedTo}`, `/provider/activity-invite/${invite.token}`);
  }

  function renderEnrollmentInvite(invite:Invite) {
    return renderInvite(`You've been invited to enroll in ${invite.invitedTo}`, `/family/activity-invite/${invite.token}`);
  }

  function renderInvite(text:string, url:string) {
    return <HBox key={url} text='body' width='100%' hAlign='justify' vAlign='center'>{text}<Link to={url} button='primary'>Accept</Link></HBox>
  }

  return render();
}
