import * as React from 'react'

import { Field, FieldRendererProps } from '../../form';
import { Tooltip } from '../../Tooltip';
import { TextArea } from '../../TextArea';
import { forwardKeyPress, getFirstFocusableOfParent, hasFocus } from '../../dom-utils';
import { MultiContextProvider, useResizeObserver } from '../../utils';

import { getFieldPropsFromCol } from '../column/DataTableColumn';

import { CellEditorRendererProps } from './CellEditorRendererProps';

const AutoResizeTextAreaField = {edit: {component: TextArea, placeholder: true, resize: 'none', autoSize: true, vAlign: 'center' }}

export const CellEditorRenderer = React.forwardRef((props:CellEditorRendererProps, ref:any) => {
  const {table, rowPos, col, onBlur, style, event, 'data-row':dataRow, 'data-field':dataField} = props;

  useResizeObserver(ref, onResize);
  React.useEffect(onUpdate, [props.blurred]);

  function onUpdate() {
    if (props.blurred) {
      return;
    }

    // the editor may already have focus (because create an editor on single
    // click) and may have set it to a child it wants have focus. if so, then
    // don't change it.  so only set focus if it doesn't already contain it.

    if (!hasFocus(ref.current)) {
      const focusable = getFirstFocusableOfParent(ref.current);

      if (focusable) {
        focusable.focus();

        const tag = focusable.tagName.toLowerCase();

        if ((tag == 'input' && (focusable as HTMLInputElement).type.toLowerCase() == 'text') || tag == 'textarea') {
          (focusable as HTMLInputElement).setSelectionRange(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        }
      }
    }

    if (event) {
      forwardKeyPress(event);
    }
  }

  function onResize() {
    if (!ref.current) {
      return;
    }

    // textarea adds some pixels to its parent size for text descendants that it doesn't add
    // to the textarea itself, leading to a misleading size, so try to get that element
    const element = (ref.current as HTMLElement).childNodes.length == 1 ? ref.current.firstChild : ref.current;
    const r = element.getBoundingClientRect();
    table.setOversizedCellSize(r.width, r.height);
  }

  function render() {
    const Renderer = col.edit || col.component?.edit || AutoResizeTextAreaField;
    const fieldProps = getFieldPropsFromCol(col);
    const cellHeight = style.height;
    
    // setting position relative is important to make the zindex of 15 work
    
    return <MultiContextProvider formInfo={{form: table.form, parents:[rowPos], editing: true, cellEditor: true}}>
      <Field {...fieldProps} label={col.label} name={col.name} component={Renderer} onBlur={onBlur} 
        style={{height:'100%', minHeight: cellHeight, maxHeight: '100%', width:'100%', minWidth:'100%', maxWidth: '100%'}} zIndex={15} position='relative'
        render={(props:FieldRendererProps<any, any>) => {
            // reactjs-popup is causing some kind of interference that causes the ref to not be set, adding a div fixes it
          const cell = <div style={{...style}}><div ref={ref} data-row={dataRow} data-field={dataField} style={{width: '100%', height:'100%'}}>{props.children}</div></div>;

          // always wrap in the error tooltip because when switching from having a tooltip to not having one (or
          // the reverse, if we have focus, we'll loose it because of the change in hiearchy
          return <Tooltip tip={props.info.errors?.join('; ')} display='block'>{cell}</Tooltip>
        }}
      />
    </MultiContextProvider>
  }

  return render();
})
