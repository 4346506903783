import * as React from 'react';

import { Redirect } from 'app2/components';

// need a direct import to avoid circular import issue
import { Route } from 'app2/views/shared-public/page/Route';

import { Users, User } from './user';
import { Family } from './family';
import { Enrollment } from './enrollment';
import { Events, Event } from './events';
import { Student } from './student';
import { Sites, Site } from './site';
import { Companies, Company } from './company';
import { Course } from './course';
import { History, Mutation, MutationChanges } from './history';
import { Homeroom } from './homeroom';
import { ComponentDemoRoutes } from './component-demos';
import { Season } from './season';

export const adminRoutes = [
  <Route path="/admin2/users/:id/:tab?" component={User} role='admin' />,
  <Route path="/admin2/users" component={Users} role='admin' />,
  <Route path="/admin2/families/:id/:tab?" component={Family} role='admin' />,
  <Route path="/admin2/enrollments/:id/:tab?" component={Enrollment} role='admin' />,
  <Route path="/admin2/events/:id" component={Event} role='admin' />,
  <Route path="/admin2/events" component={Events} role='admin' />,
  <Route path="/admin2/schools/:tab?" exact component={Sites} role='admin' />,
  <Route path="/admin2/schools/:id/:tab" exact component={Site} role='admin' />,
  <Route path="/admin2/schools/:id/:tab/:subid" component={Site} role='admin' />,
  <Route path="/admin2/seasons/:season/:tab?" component={Season} role='admin' />,
  <Route path="/admin2/students/:id/:tab?" component={Student} role='admin' />,
  <Route path="/admin2/providers/:id/:tab?/:subid?" component={Company} role='admin' />,
  <Route path="/admin2/providers" component={Companies} role='admin' />,
  <Route path="/admin2/activities/:course/:tab?" component={Course} role='admin' />,
  <Route path="/admin2/homeroom/:tab/:subid" component={Homeroom} role='admin' />,
  <Route path="/admin2/homeroom/:tab" component={Homeroom} role='admin' />,
  <Route path="/admin2/homeroom" component={Homeroom} role='admin' />,
  <Route path="/admin2/mutations/:id" component={Mutation} role='admin' />,
  <Route path="/admin2/mutation-changes/:type?/:id?" component={MutationChanges} role='admin' />,
  <Route path="/admin2/history/:type?/:id?" component={History} role='admin' />,
  <Route path="/admin2/component-demos" component={ComponentDemoRoutes} />,
  <Route path="/admin2" component={Users} role='admin' />,

  <Redirect from="/admin" to="/admin2" />,
];

export interface EntityRouteParam {
  id: string;
  tab: string;
}

export interface UserRouteParams extends EntityRouteParam {
}

export interface AdminSiteRouteParams extends EntityRouteParam {
  subid: string;
}

export interface AdminCompanyRouteParams extends EntityRouteParam {
}

export interface EventRouteParams extends EntityRouteParam {
}
