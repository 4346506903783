import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicPolicyQueryVariables = Types.Exact<{
  policy: Types.Scalars['ID'];
}>;

export type PublicPolicyQuery = {
  policy?:
    | {
        bathroom?: string | null | undefined;
        classroom?: string | null | undefined;
        lateParent?: string | null | undefined;
        missingStudent?: string | null | undefined;
        parking?: string | null | undefined;
        schoolRules?: string | null | undefined;
        snacks?: string | null | undefined;
        studentDismissal?: string | null | undefined;
        studentPickup?: string | null | undefined;
        teacherCheckin?: string | null | undefined;
        site?:
          | {
              id: string;
              name?: string | null | undefined;
              active?: boolean | null | undefined;
              slug: string;
              streetAddress?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              zipcode?: string | null | undefined;
              contacts: Array<{ id: string; name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined; position?: string | null | undefined }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const PublicPolicyDocument = gql`
  query PublicPolicy($policy: ID!) {
    policy(policy: $policy) {
      bathroom
      classroom
      lateParent
      missingStudent
      parking
      schoolRules
      snacks
      studentDismissal
      studentPickup
      teacherCheckin
      site {
        id
        name
        active
        slug
        streetAddress
        city
        state
        zipcode
        contacts {
          id
          name
          email
          phone
          position
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicPolicyArgs = MakeOptional<UseQueryArgs<PublicPolicyQueryVariables, PublicPolicyQuery>, 'query'>;

export function usePublicPolicyQuery(options: PublicPolicyArgs = {}) {
  return useQuery<PublicPolicyQueryVariables, PublicPolicyQuery>({ query: PublicPolicyDocument, ...options });
}

export type PublicPolicyOptions = ExecQueryOptions<PublicPolicyQueryVariables>;

export function publicPolicy(options: PublicPolicyOptions) {
  return executeQuery<PublicPolicyQuery, PublicPolicyQueryVariables>(PublicPolicyDocument, options);
}

export type PublicPolicySelections = PublicPolicyQuery['policy'];
