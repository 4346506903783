import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TimeEntryCheckInMutationVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
}>;

export type TimeEntryCheckInMutation = { timeEntryCheckIn?: { timeEntry?: { id: string } | null | undefined } | null | undefined };

export const TimeEntryCheckInDocument = gql`
  mutation TimeEntryCheckIn($ownerType: EntityKind!, $ownerId: ID!) {
    timeEntryCheckIn(ownerType: $ownerType, ownerId: $ownerId) {
      timeEntry {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TimeEntryCheckInOptions = ExecMutationOptions<TimeEntryCheckInMutationVariables>;

export function timeEntryCheckIn(options: TimeEntryCheckInOptions) {
  return executeMutation<TimeEntryCheckInMutation, TimeEntryCheckInMutationVariables>(TimeEntryCheckInDocument, options);
}
