import * as React from 'react';

import { OrganizerRole, CompanyRole, CourseRole } from 'app2/api';
import { Route } from 'app2/views/shared-public';

import { Activity } from './Activity';

const siteMember = OrganizerRole.SiteMember;
const companyMember = CompanyRole.CompanyMember;
const courseMember = CourseRole.Member

const coursePath = '/activities/manage/:course';

export const courseRoutes = [
  <Route path={`${coursePath}/:activityTab(attendance)/:date?`} component={Activity} role={[siteMember, companyMember, courseMember]} />,
  <Route path={`${coursePath}/:activityTab?`} component={Activity} role={[siteMember, companyMember, courseMember]} />,
]

export interface ActivityRouteParams {
  course: string;
  activityTab: string;
}
