import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminPayoutQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminPayoutQuery = {
  payout?:
    | {
        id: string;
        amount?: number | null | undefined;
        items?:
          | Array<
              | { __typename: 'Adjustment'; id: string; total: number }
              | { __typename: 'OrderItem'; id: string; total: number; course?: { name?: string | null | undefined; site: { name?: string | null | undefined } } | null | undefined }
              | { __typename: 'Refund'; id: string; total: number; course?: { name?: string | null | undefined; site: { name?: string | null | undefined } } | null | undefined }
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminPayoutDocument = gql`
  query AdminPayout($id: ID!) {
    payout(payout: $id) {
      id
      amount
      items {
        ... on OrderItem {
          __typename
          id
          total
          course {
            name
            site {
              name
            }
          }
        }
        ... on Refund {
          __typename
          id
          total
          course {
            name
            site {
              name
            }
          }
        }
        ... on Adjustment {
          __typename
          id
          total
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminPayoutArgs = MakeOptional<UseQueryArgs<AdminPayoutQueryVariables, AdminPayoutQuery>, 'query'>;

export function useAdminPayoutQuery(options: AdminPayoutArgs = {}) {
  return useQuery<AdminPayoutQueryVariables, AdminPayoutQuery>({ query: AdminPayoutDocument, ...options });
}

export type AdminPayoutOptions = ExecQueryOptions<AdminPayoutQueryVariables>;

export function adminPayout(options: AdminPayoutOptions) {
  return executeQuery<AdminPayoutQuery, AdminPayoutQueryVariables>(AdminPayoutDocument, options);
}

export type AdminPayoutSelections = AdminPayoutQuery['payout'];
