import * as React from 'react';

import { CfStage, CfAnswerInput } from 'app2/api';
import { Form, Text } from 'app2/components';
import { CfStudentForm } from 'app2/views/shared';

import { useStudentCfQuery, studentUpsertCfAnswers } from './gql'

interface Props {
  id:string;
  // allows overriding required so things are not required for organizers
  overrideRequired?:boolean;
}

export function StudentCustomFields(props:Props) {
  const [result] = useStudentCfQuery({variables:{id: props.id}});
  const student = result?.data?.student;

  function render() {
    return <Form alwaysSave={false} onNavigation='nothing' editing autoSave>
      {student?.questions?.length 
        ? <CfStudentForm stage={CfStage.Later} questions={student?.questions} answers={student?.answers} onOk={onOk} autoSave alwaysSave={false} onNavigation='nothing' overrideRequired={props.overrideRequired} />
        : result.loading 
          ? ''
          : <Text text='body'>None</Text>}
    </Form>
  }

  async function onOk(answers:CfAnswerInput[]) {
    const [success] = await studentUpsertCfAnswers({variables:{studentId:props.id, answers}})

    return success;
  }

  return render();
}