import { EventEmitter } from "events";

import { cmdCtrlKey } from '../dom-utils';

import { Command } from "./Command";

export interface UndoEvent {
  action: 'undo' | 'redo';
  preventDefault: boolean;
}

export class UndoManager extends EventEmitter {
  static instance = new UndoManager();

  active:number = -1;
  commands:Command[] = [];

  get undoableCommands() {
    return this.commands.slice(0, this.active + 1);
  }

  push(command:Command, callDo:boolean = true) {
    ++this.active;

    this.commands.splice(this.active, this.commands.length - this.active);
    this.commands.push(command);

    if (callDo) {
      command.do();
    }
  }

  undo(remove:boolean = false, setFocus:boolean = true) {
    if (!this.canUndo) {
      return;
    }

    const active = this.active;

    const event:UndoEvent = {action: 'undo', preventDefault: false};
    this.emit('action', event);

    if (event.preventDefault) {
      return;
    }

    if (setFocus) {
      this.commands[active].focus();
    }

    // remove and decrement active before calling
    // undo because undo can cause something to be
    // put on the undo stack which also manipulates
    // commands and active
    const toUndo = this.commands[active];

    if (remove) {
      this.commands.splice(active, 1);
    }

    --this.active;

    toUndo.undo();
  }

  // skipping a command during undo can cause issues
  // because other commands may depend on this commands
  // change...you should only use when skipping unrelated
  // commands that are not dependent on each other.

  skipUndo() {
    if (!this.canUndo) {
      return;
    }

    --this.active;
  }

  get nextUndoCommand() {
    return this.commands[this.active];
  }

  get canUndo() {
    return this.active >= 0 && this.active < this.commands.length;
  }

  redo() {
    if (!this.canRedo) {
      return;
    }

    const event:UndoEvent = {action: 'redo', preventDefault: false};
    this.emit('action', event);

    if (event.preventDefault) {
      return;
    }

    ++this.active;

    this.commands[this.active].focus();
    this.commands[this.active].redo();
  }

  skipReundo() {
    if (!this.canRedo) {
      return;
    }

    ++this.active;
  }

  get canRedo() {
    return this.active < this.commands.length - 1;
  }

  // removes any possible redo commands so they can't be redone
  truncateRedo() {
    this.commands.splice(this.active + 1, this.commands.length - this.active + 1);
  }
}

window.addEventListener('keydown', (event:KeyboardEvent) => {
  if (!(cmdCtrlKey(event) && event.key.toLowerCase() == 'z')) {
    return;
  }

  if (!event.shiftKey) {
    UndoManager.instance.undo();
  }
  else {
    UndoManager.instance.redo();
  }
});
