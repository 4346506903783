import { omitBy } from 'lodash-es';

interface Dictionary<T> {
  [index: string]: T;
}

export function compact<T>(o:T[]):T[];
export function compact<T>(o:Dictionary<T>):Dictionary<T>;
export function compact<T>(o:Dictionary<T> | T[]) {
  return Array.isArray(o) 
    ? o.filter(x => x !== undefined && x !== null)
    : omitBy<T>(o, (val => val === undefined || val === null));
}
