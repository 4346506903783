import {
  get,
  space,
  layout,
  color,
  typography,
  flexbox,
  background,
  border,
  position,
  shadow
} from '../styled-system';

import { breakpointValue } from '../breakpoints';
import { Theme } from '../theme';

// this takes a property value and if its a theme property name (like a color of 'primary')
// returns the corresponding value.
//
// also, if the property value is an array, it will return the correct value for the breakpoint.
// in order for this to work properly you must specify the breakpoint.  you can use withBreakpoints
// to have the current breakpoint added as a property.s

const allPropConfigs = {
  ...space.config,
  ...layout.config,
  ...color.config,
  ...typography.config,
  ...flexbox.config,
  ...background.config,
  ...border.config,
  ...position.config,
  ...shadow.config
};

export function propertyValue<T>(
  props: T & { theme: Theme; breakpoint?: number },
  propName: keyof T,
  breakpoint?: number
) {
  breakpoint = breakpoint !== undefined ? breakpoint : props.breakpoint;

  const value = breakpointValue(props[propName], breakpoint);

  // this is taken from createParser in https://github.com/styled-system/styled-system/blob/master/packages/core/src/index.js
  const sx = allPropConfigs[propName as keyof typeof allPropConfigs] as any;

  if (!sx) {
    return value;
  }

  const scale = get(props.theme, sx.scale, sx.defaults);

  return scale ? scale[value] || value : value;
}
