import * as React from 'react';
import { commaListsAnd } from 'common-tags';

import { CfStage, CartUtils, CourseKind } from 'app2/api';
import { Modal, VBox, SaveableResult, Text, useLifecycle } from 'app2/components';
import { FamilyTeamPanel } from 'app2/views/shared/family';
import { ParentStudentDetails, FamilyCustomFields } from 'app2/views/parent/shared';

import { TeamInfo } from './TeamInfo';
import { ParentUserWithCartSelections, parentConfirmStudentYearlyUpdate } from './generated';

type CartedStudent = ParentUserWithCartSelections['cart']['family']['students'][0];

interface Props {
  user: ParentUserWithCartSelections;
}

export function AdditionalInfo(props: Props) {
  const user = props.user;
  const cart = user.cart;

  const students = user.cart.family.students;
  const visibleStudents = getVisibleStudents();

  const studentsNeedingUpdate = visibleStudents?.filter(s => !s.upToDate);
  useLifecycle({onMount});

  function render() {
    return (
      <VBox gap='$20'>
        {renderAthletics()}
        {renderStudents()}
        {visibleStudents?.length != 0 && <FamilyTeamPanel small grid={undefined} />}
      </VBox>
    )
  }

  function renderAthletics() {
    const teamRegistrations = cart.enrollments.filter(e => e.priceConfig.template && e.course.kind == CourseKind.AthleticReg);
    const coachIds = Array.from(new Set(teamRegistrations.map(e => e.student?.id)));

    return <>
      {teamRegistrations.map(enrollment => <TeamInfo enrollment={enrollment} key={enrollment.id} />)}
      {coachIds.map(id => renderCustomFields(id))}
    </>
  }

  function renderStudents() {
    if (!visibleStudents.length) {
      return null;
    }
    
    return <VBox gap='$20'>{visibleStudents.map(renderStudent)}</VBox>;
  }

  function renderStudent(student: CartedStudent, index: number) {
    const name = students.find(s => s.id == student.id).name;

    // move this to the server?
    const enrollments = props.user.cart.enrollments.filter(i => i.student?.id == student.id);
    const courseKinds = CartUtils.getCourseKinds(cart, enrollments);
    const defaultSite = cart.sites?.find(s => s.id == enrollments?.[0]?.course?.site?.id);
                
    return (
      <VBox key={student.id} data-test={name}>
        <Text text="subtitle1" mb="$8">
          {name}
        </Text>
        <ParentStudentDetails id={student.id} purpose="checkout" courseKinds={courseKinds} autoFocus={false} onNavigation="nothing" defaultSite={defaultSite} />
        {renderCustomFields(student.id)}
      </VBox>
    );
  }

  function renderCustomFields(id:string) {
    return <FamilyCustomFields key={id} id={id} type="edit-no-save-button" carted autoSave autoFocus={false} onNavigation="nothing" stage={CfStage.PreCheckout} />
  }

  function getVisibleStudents() {
    const studentRequiredEnrollments = props.user.cart.enrollments.filter(e => e.studentSelectionRequired);
    const students = props.user.cart.family.students.filter(s => studentRequiredEnrollments.find(i => i.student?.id === s.id));
    return students;
  }

  async function onMount() {
    if (studentsNeedingUpdate?.length) {
      const studentNames = commaListsAnd`${studentsNeedingUpdate.map(s => s.name)}`;

      const result = await Modal.warning('Review grade and classroom', 
        `It has been a while since you last updated ${studentNames}'s grade and classroom.  Please review and make sure they are up to date.`);

      if (result.action == SaveableResult.ok) {
        await parentConfirmStudentYearlyUpdate({variables:{students:studentsNeedingUpdate.map(s => s.id)}});
      }
    }
  }

  return render();
}
