import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyStripeQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyStripeQuery = {
  company?:
    | { id: string; stripeDashboardUrl?: string | null | undefined; stripeConnectUrl?: string | null | undefined; stripeAccountType?: string | null | undefined; stripeAccountVerified?: boolean | null | undefined }
    | null
    | undefined;
};

export const CompanyStripeDocument = gql`
  query CompanyStripe($company: ID!) {
    company(company: $company) {
      id
      stripeDashboardUrl
      stripeConnectUrl
      stripeAccountType
      stripeAccountVerified
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyStripeArgs = MakeOptional<UseQueryArgs<CompanyStripeQueryVariables, CompanyStripeQuery>, 'query'>;

export function useCompanyStripeQuery(options: CompanyStripeArgs = {}) {
  return useQuery<CompanyStripeQueryVariables, CompanyStripeQuery>({ query: CompanyStripeDocument, ...options });
}

export type CompanyStripeOptions = ExecQueryOptions<CompanyStripeQueryVariables>;

export function companyStripe(options: CompanyStripeOptions) {
  return executeQuery<CompanyStripeQuery, CompanyStripeQueryVariables>(CompanyStripeDocument, options);
}

export type CompanyStripeSelections = CompanyStripeQuery['company'];
