import { DatePicker, DatePickerProps, DateText, DateTextProps, DateLabelField, DateField, MultipleDateField } from 'app2/components';

import { withSiteOrCompanyTimezone } from './withSiteOrCompanyTimezone';

export const SiteOrCompanyDatePicker = withSiteOrCompanyTimezone<DatePickerProps>(DatePicker);
export const SiteOrCompanyDateText = withSiteOrCompanyTimezone<DateTextProps>(DateText);

export const SiteOrCompanyDateLabelField = {...DateLabelField, component:SiteOrCompanyDateText };
export const SiteOrCompanyDateField = {...DateField, display: SiteOrCompanyDateLabelField, edit: {...DateField.edit, component: SiteOrCompanyDatePicker} };
export const SiteOrCompanyMultipleDateField = {...MultipleDateField, ...SiteOrCompanyDateField, edit: {...MultipleDateField.edit, ...SiteOrCompanyDateField.edit} };

