import * as React from 'react';

import { adminRoutes, courseRoutes, providerRoutes, organizerRoutes, obfuscatedRoutes, parentRoutes, teacherRoutes, checkout, userRoutes, publicRoutes } from 'app2/views';

export const routes = [
  ...organizerRoutes,
  ...providerRoutes,
  ...courseRoutes,
  ...obfuscatedRoutes,
  ...parentRoutes,
  ...teacherRoutes,
  ...checkout,
  ...userRoutes,
  ...adminRoutes,
  ...publicRoutes,
].map((r, index) => React.cloneElement(r, { key: index }));
