import * as React from 'react';

import { ComplianceRequirementInput, FileUploadAttributes } from 'app2/api';
import { Form, FieldInfo } from 'app2/components';
import { addDeletedItems } from 'app/app2/views/shared'


import { ComplianceFields, ComplianceFormValues } from './ComplianceFields';
import { updateVendorCompliance, useVendorComplianceQuery } from './generated';

interface Props {
  vendor: string;
}

export function VendorCompliance(props: Props) {
  const query = useVendorComplianceQuery({ variables: { vendor: props.vendor } });
  const compliance = { requirements: query[0].data?.vendorCompliance };

  function render() {
    return (
      <Form initialValues={compliance} editing onNavigation='nothing'>
        <ComplianceFields onChange={onChange} />
      </Form>
    );
  }

  async function onChange(_value: any, info: FieldInfo<ComplianceFormValues>) {
    const requirement = (info.record as unknown) as ComplianceRequirementInput;
    const initialVal = info.form.initialValues?.requirements?.find(req => req.id === requirement.id);

    const uploads = addDeletedItems(initialVal?.uploads as FileUploadAttributes[], requirement.uploads,'archived')

    const attributes: ComplianceRequirementInput = { id: requirement.id, status: requirement.status, expiresAt: requirement.expiresAt, uploads: uploads };
    const [success] = await updateVendorCompliance({ variables: { attributes }, error: info.form, successMsg: 'Compliance saved.' });
    return success;
  }

  return render();
}
