import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const Left = React.forwardRef((props:Props, ref:React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg ref={ref} width={props.size} height={props.size} viewBox="0 0 18 18" stroke={color} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <g fill='currentColor'>
      <path d="M11.25 4.5L6.75 9L11.25 13.5" />
      <path d="M11.25 4.5L6.75 9L11.25 13.5" />
      <path d="M11.25 4.5L11.25 13.5" />
    </g>
  </svg>
})

Left.displayName = 'Left';
Left.defaultProps = {
  size: 18
}