import { isRealProduction } from 'app/helpers/environments';

import { EventRecorder } from './EventRecorder';

const capture = isRealProduction();

export class GoogleTagAnalytics extends EventRecorder {
  id:string;

  constructor(id:string) {
    super();
  
    this.id = id || 'GTM-PJ52DPQ';
    this.capture = isRealProduction();
    this.load();
  }

  load() {
    if (!this.capture) {
      return;
    }

    const script1 = document.createElement('script');
    script1.text =
      "window.dataLayer = window.dataLayer || [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '" + this.id + "');";
    document.body.appendChild(script1);
  }
}
