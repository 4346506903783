import * as React from 'react';

import { organizerPreferences } from 'app2/api';

import { Discounts, DiscountsProps } from './Discounts';

const PREFS_VERSION = '1'

export function StudentDiscounts(props:Omit<DiscountsProps, 'queries' | 'cols' | 'header'>) {
  return <Discounts queries='students' {...props} cols={cols} prefsVersion={PREFS_VERSION} pref={organizerPreferences.pref('studentDiscountsTable')} 
    header={{title:'Student discounts', subtitle:'Student discounts are automatically applied at enrollment and can be combined with promotional discounts.'}}
  />
}

const cols = ['scope.studentObjects.0', 'percentAmount', 'code', 'scope.courseKinds', 'scope.courses', 'usesCount', 'active'];
