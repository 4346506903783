import React from 'react'

import { CreditCard } from 'app2/api';
import { Body, HBox, VBox, Icon } from 'app2/components';

import { CardIcon } from './CardIcon';

interface Props {
  card:CreditCard;
  onRemove?:() => void;
}

export function Card(props:Props) {
  return <VBox border='solid' borderColor='border' borderRadius='form' width='200px' bg='white' p='$10' vItemSpace='$8' data-test='CreditCard'>
    <Body small disabled letterSpacing='1.6px'>{'**** **** **** ' + props.card.last4}</Body>
    <HBox hAlign='justify' width='100%'>
      <Body small disabled letterSpacing='1.6px'>{props.card.expiration}</Body>
      <Body small disabled letterSpacing='1.6px'>{props.card.name}</Body>
    </HBox>
    <HBox width='100%'>
      <CardIcon kind={props.card.brand} mr='8px' />
      <HBox flex={1} />
      {props.onRemove && <Icon name='Trash2' size='medium' onClick={props.onRemove} button />}
    </HBox>
  </VBox>
}
