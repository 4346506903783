import React from 'react';

import { Course, Site } from 'app2/api';
import { Dropdown, DropdownProps, defaultFieldProps, FieldInfo } from 'app2/components';
import { findQueryOption, getTypename } from 'app2/views/shared-public';

import { useCompanySitesQuery, companySites, CompanySitesSelections, useVendorSitesQuery, vendorSites, VendorSitesSelections } from './generated';

interface EntityInputProps {
  field?:FieldInfo<Course>;
  course?:Course;
  company?:String;
  vendor?:string
}

interface Props extends Omit<DropdownProps, 'options'>, EntityInputProps {
}

export function CompanySitePicker(props: Props) {  
  const {field, company:companyProp, vendor:vendorProp, ...remaining} = props;
  const {company, vendor} = getEntity(props);
  const options = getOptions();

  function render() {
    return <Dropdown options={options} {...remaining} />
  }

  function getOptions() {
    const [result1] = useCompanySitesQuery({variables: {company}, requestPolicy: 'cache-first', hideLoader: true, pause: !company});
    const [result2] = useVendorSitesQuery({variables: {vendor}, requestPolicy: 'cache-first', hideLoader: true, pause: !!company || !vendor});
    const sites = result1?.data?.company?.sites || result2?.data?.vendor?.company.sites;

    return React.useMemo(() => sites?.map?.(createOptions), [sites]);
  }

  function createOptions(item:CompanySitesSelections['sites'][0]) {
    return {value: item, label: item.name};
  }

  return render();
}

export async function findCompanySite(nameOrObArray:number | string | Pick<Site, 'id' | 'name'> | string[] | Pick<Site, 'id' | 'name'>[], props:EntityInputProps):Promise<CompanySitesSelections['sites'][0]> {
  if (typeof nameOrObArray == 'object' && getTypename(nameOrObArray) == 'Site' && (nameOrObArray as any).id) {
    return nameOrObArray as any;
  }

  const {company, vendor} = getEntity(props);

  if (!company && !vendor) {
    return;
  }

  return company
    ? findQueryOption(companySites, {company}, nameOrObArray)
    : findQueryOption(vendorSites, {vendor}, nameOrObArray)
}

function getEntity(entity:EntityInputProps) {
  const course = entity?.course || entity?.field?.values?.[0];
  const company = entity?.company || course?.company?.id || course?.companyId;
  const vendor = entity?.vendor || course?.vendor?.id || course?.vendorId;

  return {company, vendor};
}

CompanySitePicker.findSite = findCompanySite;

CompanySitePicker.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'field'
}

