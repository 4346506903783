import * as React from 'react'

import { findOption } from './Option'
import { OptionText, OptionTextProps } from './OptionText'
import { dispatchChangeEvent } from './dom-utils'
import { useBreakpoints } from './breakpoints'

export interface OptionButtonProps extends OptionTextProps {
}

export function OptionButton(props:OptionTextProps) {
  const breakpoint = useBreakpoints();

  function render() {
    return <OptionText {...props} icon={{onClick}} />
  }

  function onClick(event:React.MouseEvent) {
    const option = findOption(props.options, props.value);
    const pos = option ? props.options.indexOf(option) : -1;
    const nextPos = pos + 1 >= props.options.length ? 0 : pos + 1;
    const nextOption = props.options[nextPos];

    dispatchChangeEvent(event.target, nextOption.value, props.onChange);
  }

  return render();
}
