import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminFamilyEnrollmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminFamilyEnrollmentsQuery = {
  family?:
    | {
        enrollments?:
          | Array<
              | {
                  id: string;
                  createdAt: string;
                  status: Types.EnrollmentStatusEnum;
                  student?: { id: string; name: string } | null | undefined;
                  course: { id: string; name?: string | null | undefined; status: Types.CourseStatus };
                  parent?: { id: string; name?: string | null | undefined } | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminFamilyEnrollmentsDocument = gql`
  query AdminFamilyEnrollments($id: ID!) {
    family(family: $id) {
      enrollments(sorts: [{ name: "createdAt", direction: DESC }]) {
        id
        createdAt
        status
        student {
          id
          name
        }
        course {
          id
          name
          status
        }
        parent {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminFamilyEnrollmentsArgs = MakeOptional<UseQueryArgs<AdminFamilyEnrollmentsQueryVariables, AdminFamilyEnrollmentsQuery>, 'query'>;

export function useAdminFamilyEnrollmentsQuery(options: AdminFamilyEnrollmentsArgs = {}) {
  return useQuery<AdminFamilyEnrollmentsQueryVariables, AdminFamilyEnrollmentsQuery>({ query: AdminFamilyEnrollmentsDocument, ...options });
}

export type AdminFamilyEnrollmentsOptions = ExecQueryOptions<AdminFamilyEnrollmentsQueryVariables>;

export function adminFamilyEnrollments(options: AdminFamilyEnrollmentsOptions) {
  return executeQuery<AdminFamilyEnrollmentsQuery, AdminFamilyEnrollmentsQueryVariables>(AdminFamilyEnrollmentsDocument, options);
}

export type AdminFamilyEnrollmentsSelections = AdminFamilyEnrollmentsQuery['family'];
