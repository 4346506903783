import * as React from 'react';

import { Scroller } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function ScrollerDemos() {
  return <Demos name='Scroller'
    description={`
    A scroller allows you to have a scrollable div, but with custom
    scrollbars or scrollbars that are outside the scrollers margins (which
    is not allowed in standard html).
  `}>
    <Demo name='Needs both'>
      <Scroller px={50} border='solid 1px' height='250px' width='250px'>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
      </Scroller>
    </Demo>
    <Demo name='Needs horz'>
      <Scroller px={50} border='solid 1px' height='450px' width='225px'>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
      </Scroller>
    </Demo>
    <Demo name='Needs vert'>
      <Scroller px={50} border='solid 1px' height='250px' width='450px'>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
      </Scroller>
    </Demo>
    <Demo name='Needs neither'>
      <Scroller px={50} border='solid 1px' height='450px' width='450px'>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
        <p style={{width:'200px'}}>Im scrollable and have my scrollbars 50 pixels outside of the scrollable area</p>
      </Scroller>
    </Demo>
  </Demos>
}

