import * as React from 'react';

import { VBox, Subtitle2 } from 'app/app2/components';

interface Props {
  title:string;
  color:string;
}

export function SeasonDay(props:Props) {
  return <VBox p='$8' bg={props.color} width='100%' borderRadius='standard' hAlign='center'>
    <Subtitle2>{props.title}</Subtitle2>
  </VBox>
}
