import * as React from 'react'

import { StudentUtils, StudentWithNameAndId } from 'app2/api'
import { HBox, Icon, Link, LinkProps, FieldInfo } from 'app2/components'

interface Props extends Omit<LinkProps, 'display'> {
  info?:FieldInfo<StudentWithNameAndId>;
  student?:StudentWithNameAndId;
  // shows the name as a link to the student
  display?:boolean;
  // shows an pencil icon as a link to edit the student
  edit?:boolean;
  inline?:boolean;
}

export function ParentStudentLink(props:Props) {
  const {info, student:studentProp, display, edit, inline, ...remaining} = props;
  const student = studentProp || info.record;

  function render() {
    return <HBox vAlign='center' display={inline ? 'inline' : undefined}>
      {renderRegularLink()}
      {renderEditLink()}
    </HBox>
  }

  function renderRegularLink() {
    if (!display) {
      return;
    }

    return <Link className='hr-value' text='body' to={`/family/family/${student?.id}`} {...remaining}>{StudentUtils.getStudentName(student)}</Link>
  }

  function renderEditLink() {
    if (!edit) {
      return;
    }

    return <Link to={`/family/family/${student?.id}?edit=details`}><Icon name='Edit' ml='$8' size='small' /></Link>
  }

  return render();
}

ParentStudentLink.defaultProps = {
  display: true
}

ParentStudentLink.fieldProps = {
  fieldProperty: 'info'
}
