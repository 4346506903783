import * as React from 'react';
import { useParams } from 'react-router';

import { MenuItem, OptionsMenu, Tabs } from 'app2/components';

import { HistoryVersions, MutationChangesPanel } from '../shared';

import { EntityRouteParam } from '../adminRoutes';
import { AdminPage } from '../AdminPage';

import { Students } from './Students';
import { Enrollments } from './Enrollments';
import { Team } from './Team';
import { MergeFamilies } from './MergeFamilies';
import { useAdminFamilyQuery } from './generated';

export function Family() {
  const { id } = useParams<EntityRouteParam>();
  const [result] = useAdminFamilyQuery({variables: {family:id}});
  const family = result.data?.family;

  function render() {
    return <AdminPage title={`${family?.name}'s Family`} actions={renderActions()}>
      <Tabs baseUrl={`/admin2/families/${id}/:tab`} urlParameter='tab' tabs={[
        {label:'Students', name:'students', content: <Students />},
        {label:'Enrollments', name:'enrollments', content: <Enrollments />},
        {label:'Team', name:'team', content: <Team />},
        {label:'History', name:'mutations', content: <MutationChangesPanel type='Family' id={id} />},
        {label:'History (old)', name:'history', content: <HistoryVersions type='Family' id={id} />},
        ]} />
    </AdminPage>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem label="Merge families..." onClick={<MergeFamilies family={family} />} />
    </OptionsMenu>
  }

  return render();
}
