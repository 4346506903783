import * as React from 'react'

import { FocusTracker } from '../dom-utils';
import { SmallLoader } from '../SmallLoader';
import { useLifecycle, OnClickOrElement } from '../utils';

import { useShield } from './useShield'

export function useClickLoader(onClick:OnClickOrElement, inverted:boolean = false, forceLoading:boolean = false, blurOnClick:boolean = false) {
  const unmounted = React.useRef(false);
  useLifecycle({onUnmount});

  FocusTracker.trackFocus();

  function onUnmount() {
    unmounted.current = true;
  }

  let [loading, setLoading] = React.useState(false);
  const shield = useShield();

  function clickLoaderHandler(event:React.MouseEvent<any>) {
    const resultOrPromise = typeof onClick == 'function' ? onClick?.(event) as any : onClick;
    const hasPromise = resultOrPromise && (resultOrPromise as Promise<boolean>).then != null;
    const target = event?.target;
  
    if (hasPromise) {
      shield.shield(true);    
      setLoading(true);
      
      resultOrPromise
        .finally(() => {
          shield.shield(false);
  
          if (!unmounted.current) {
            setLoading(false);

            if (blurOnClick) {
              FocusTracker.restoreFocus(target as HTMLElement);
            }
          }
        });
    }
    else
    if (blurOnClick) {
      FocusTracker.restoreFocus(target as HTMLElement);
    }

    return resultOrPromise;
  }

  if (forceLoading) {
    loading = true;
  }

  return {loading , clickLoaderHandler, loader: loading ? <SmallLoader active inverted={inverted} /> : undefined};
}
