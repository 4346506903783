import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteCreateMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  zipcode: Types.Scalars['String'];
}>;

export type SiteCreateMutation = { siteCreateDemo?: { site?: { id: string } | null | undefined; user?: { id: string } | null | undefined } | null | undefined };

export const SiteCreateDocument = gql`
  mutation SiteCreate($name: String!, $zipcode: String!) {
    siteCreateDemo(name: $name, zipcode: $zipcode) {
      site {
        id
      }
      user {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SiteCreateOptions = ExecMutationOptions<SiteCreateMutationVariables>;

export function siteCreate(options: SiteCreateOptions) {
  return executeMutation<SiteCreateMutation, SiteCreateMutationVariables>(SiteCreateDocument, options);
}
