import { AuthorizedPreferences } from '../shared-authorized';

import { CompanyPreferences } from './CompanyPreferences';

export const providerPreferencesKeys = {
  company: 'company',
  showWeekends: 'showWeekends'
};

export class ProviderPreferences extends AuthorizedPreferences {
  get company(): string {
    return this.load(providerPreferencesKeys.company);
  }

  set company(value: string) {
    this.save(providerPreferencesKeys.company, value);
  }

  companyPreferences(company:string) {
    return new CompanyPreferences(company);
  }
}

export const providerPreferences = new ProviderPreferences();
