import { DataTableColumnSort } from 'app2/components';

export const bySession = {
  label: 'Attendance dates',
  name: 'session',
  sort: ['student.lastName', 'student.firstName', 'course.name'],
  cols: ['student.firstName', {name:'student.lastName', sort: DataTableColumnSort.ascending}, 'student.grade', 
    'course.name', 'startsAt', 'parent.name', 'parent.phone', 'parent.email', 'student.emergencyContacts',  'student.authorizedPickupContacts', 'pickup', 'dismissal', 'groups'
  ],
}
