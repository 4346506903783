import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteMemberRemoveMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  user: Types.Scalars['ID'];
}>;

export type AdminSiteMemberRemoveMutation = { siteAdminMemberRemove?: { id: string } | { id: string } | null | undefined };

export const AdminSiteMemberRemoveDocument = gql`
  mutation AdminSiteMemberRemove($site: ID!, $user: ID!) @urql(additionalTypenames: ["Site"]) {
    siteAdminMemberRemove(site: $site, user: $user) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSiteMemberRemoveOptions = ExecMutationOptions<AdminSiteMemberRemoveMutationVariables>;

export function adminSiteMemberRemove(options: AdminSiteMemberRemoveOptions) {
  return executeMutation<AdminSiteMemberRemoveMutation, AdminSiteMemberRemoveMutationVariables>(AdminSiteMemberRemoveDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Site']) }
  });
}
