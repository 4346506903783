import * as React from 'react'

import {useForceUpdate } from './useForceUpdate';

type StateFn<T> = (prevState?:T) => T;

// similar to useState except it will reset back
// to the passed in *current* initial state if the dependencies
// have chagned

export function useStateWithDeps<T>(initialState: T | StateFn<T>, deps?: React.DependencyList): [T, React.Dispatch<React.SetStateAction<T>>] {
  const forceUpdate = useForceUpdate();
  const memoFn = (typeof initialState === 'function') ? initialState as StateFn<T>: () => initialState;
  const memo = React.useMemo(() => {
    return {
        state:memoFn(),
        setState
      }
    }, deps);

  function setState(state:T | StateFn<T>) {
    const memoFn = (typeof state === 'function') ? state as StateFn<T>: () => state;
    memo.state = memoFn(memo.state);
    forceUpdate();
  }

  return [memo.state, memo.setState];
}
