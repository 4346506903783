import * as React from 'react'

import { Panel, PanelProps, Section, TextAreaField } from 'app2/components'

export function BaseSchoolPolicies(props:PanelProps) {
  const {children, ...remaining} = props;

  return <Panel icon='Shield' title='School policies' {...remaining}>
    {children}
    <Section label='Snack policy' name='snacks' component={TextAreaField} />
    <Section label='Bathroom policy' name='bathroom' component={TextAreaField} />
    <Section label='Classroom policy' name='classroom' component={TextAreaField} />
    <Section label='School rules' name='schoolRules' component={TextAreaField} />
    <Section label='Parking options' name='parking' component={TextAreaField} />
  </Panel>
}
