import * as React from 'react';

import { ClipboardUtils } from '../../dom-utils';
import  { MultiContext } from '../../utils';

import { ActionButton } from './ActionButton';
import { DataTableHeaderContext } from './DataTableHeader';

export function PasteAction() {
  const header = React.useContext<DataTableHeaderContext>(MultiContext).header;

  function render() {
    return header.props.editing
      ? <ActionButton icon='Clipboard' selection={false} onClick={onPaste}>Paste</ActionButton>
      : <></>
  }

  function onPaste() {
    ClipboardUtils.generatePasteEvent(['text/hr', 'text/tsv', 'text/csv', 'text/plain']);
  }

  return render();
}
