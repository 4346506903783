export interface Provider {
  id: number;
  slug: string;
  name: string;
  display_name: string;
  profile_photo: string;
  profile_background: string;
  headline: string;
  about: string;
  redirect_to_slug?: string;
}

export interface ProviderCard {
  name: string;
  slug: string;
  profile_photo: string;
}

export const PROVIDER_BACKGROUND_IMAGE_WIDTH = 2880;
export const PROVIDER_BACKGROUND_IMAGE_HEIGHT = 766;
export const PROVIDER_BACKGROUND_IMAGE_RATIO =
  PROVIDER_BACKGROUND_IMAGE_WIDTH / PROVIDER_BACKGROUND_IMAGE_HEIGHT;
