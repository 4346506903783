import * as React from 'react';

import { Enrollment } from 'app2/api';
import { Box, FieldInfo, Span } from 'app2/components';
import { EnrollmentActivityLink } from 'app2/views/shared';

interface Props {
  otherEnrollments?:Enrollment[];
  info?:FieldInfo<Enrollment>;
}

export function OtherEnrollments(props:Props) {
  function render() {
    return <Box className='hr-value'>{props.otherEnrollments?.map(renderEnrollment)}</Box>
  }

  function renderEnrollment(e:Enrollment) {
    return <Span key={e.id} text='body' maxLines={1}><EnrollmentActivityLink enrollment={e} display='inline' /> {e.formattedTimes}</Span>
  }

  return render();
}

OtherEnrollments.fieldProps = {
  valueProperty: 'otherEnrollments',
  fieldProperty: 'info'
}

export function copyOtherEnrollments(otherEnrollments?:Enrollment[]) {
  return otherEnrollments?.map(e => e.course.name + ' ' + e.formattedTimes).join('\n');
}
