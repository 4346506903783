import { axios } from './axios';

import { ApiResponse, standardizeAxiosApiError } from "../error";

export interface PasswordArgs {
  password:string;
  password_confirmation:string;
}

export async function changePassword(args:PasswordArgs):Promise<ApiResponse> {
  try {
    await axios.put('/auth/password', args);

    return {success: true, errors: []};
  }
  catch(e) {
    return standardizeAxiosApiError(e, ['password', 'password_confirmation']);
  }
}
