import * as React from 'react'
import { History, Location } from 'history';
import * as qs from 'query-string';
import { omitBy, isUndefined } from 'lodash-es';

import { Button } from '../Button';
import { VBox } from '../Box';
import { Icon } from '../icons';
import { Form, FormContent, FormModel, FormProps, NavigationPrompt } from '../form';
import { HistoryAction, HistoryListener } from '../history';
import { Saveable, SaveableProps } from '../Saveable';
import { theme } from '../theme';
import { scrollIntoView, getPrevSiblingOrParentSibling } from '../dom-utils';

export type PanelType = 'none' | 'display' | 'edit' | 'toggle' | 'edit-toggle' | 'edit-no-save-button' | 'edit-dirty-button';

export interface PanelProps<T = any> extends Omit<SaveableProps, 'title' | 'onOk' | 'onChange' | 'onReset' | 'autoSave'>, Omit<FormProps<T>, 'type' | 'editing'> {
  type:PanelType;
  editToggle?:React.ReactElement;
  onToggleEditing?: (editing: boolean) => void;
  // only applies if the user is editing when there's a navigation
  onNavigation?:NavigationPrompt;
  scrollOnSave?:boolean;
  scrollOnCancel?:boolean;
  // optionally give the form a name, that can be used to tell the
  // form to automatically go into edit mode based on the url, via:
  //      server.dom/some-url?edit=$form-name
  // only applies to toggle mode
  name?:string;
  incomplete?:boolean;
  formUi?:React.RefObject<Form>;
}

interface State {
  editing?:boolean;
  needsSave?:boolean;
}

export const PanelParentContext = React.createContext<Panel>(null);

export class Panel<T = any> extends React.Component<PanelProps<T>> {
  static defaultProps = {
    type: 'none',
    ok: 'Save changes',
    onNavigation: 'prompt',
    scrollOnSave:true,
    scrollOnCancel:true
  }

  state:State;
  form = React.createRef<Form | HTMLElement>();
  scrollTo:HTMLElement;
  mounted:boolean;
  subscribedTo?:FormModel[];

  constructor(props:PanelProps<T>) {
    super(props);

    this.state = {editing: this.editingType ? true : false}
    this.mounted = true;
  }

  get element() {
    return (this.form?.current as Form)?.element || this.form.current as HTMLElement;
  }

  componentDidMount() {
    this.scrollTo = this.element;
    this.subscribe();
  }

  componentWillUnmount() {
    this.scrollTo = getPrevSiblingOrParentSibling(this.scrollTo) as HTMLElement;
    this.mounted = false;
    this.unsubscribe();
  }

  componentDidUpdate(prevProps:PanelProps<T>, prevState: State) {
    if (this.props.type !== prevProps.type) {
      this.setState({editing: this.editingType});
    }

    if (prevState.editing !== this.state.editing && this.props.onToggleEditing) {
      this.props.onToggleEditing(this.state.editing)
    }

    const typeChanged = this.props.type != prevProps.type;
    const autoSaveChanged = this.props.autoSave != prevProps.autoSave;

    if ((this.subscribedTo?.length && this.subscribedTo[0] != this.formModel) || typeChanged || autoSaveChanged) {
      this.unsubscribe();
      this.subscribe();
    }
  }

  get editing() {
    return this.state.editing;
  }

  get editingType() {
    return this.props.type == 'edit' || this.props.type == 'edit-toggle' || this.props.type == 'edit-no-save-button' || this.props.type == 'edit-dirty-button';
  }

  get hasSaveable() {
    return this.props.type == 'edit' || this.props.type == 'toggle'  || this.props.type == 'edit-toggle' || this.props.type == 'edit-dirty-button';
  }

  get formModel() {
    return this.props.form || (this.form?.current as Form)?.form;
  }

  get contextualSaveButton() {
    return (this.props.type == 'edit' || this.props.type == 'edit-toggle' || this.props.type == 'edit-dirty-button') && this.props.autoSave && !!this.formModel
  }

  render() {
    const {icon, title, subtitle, editToggle, onNavigation, scrollOnSave, scrollOnCancel, primaryActions, scrollable, type, onOk, onChange, onReset, autoFocus, autoSave, alwaysSave, showUnhandledErrors, form, initialValues, values, cancel, ok, buttons, content, children, 
      onToggleEditing, border, borderRadius, borderColor, bg, vAlign, hAlign, p, gap, vItemSpace, hItemSpace, className, height, formUi, ...remaining} = this.props;
    const { editing } = this.state;
    const hasSaveButtons = editing && this.props.type != 'edit-no-save-button' && (this.props.type != 'edit-dirty-button' || !this.state.needsSave);
    const commonProps = omitBy({
      borderRadius:borderRadius ?? 'form', bg:bg ?? 'formBackground', p: p ?? theme.space.pannelPadding, height:height || '100%', width:'100%', gap, vItemSpace, hItemSpace,
      icon, title, subtitle, primaryActions:this.renderPrimaryActions(), scrollable, border, borderColor, vAlign, hAlign,
      children: content || children, className
    }, isUndefined);
    
    if (formUi) {
      this.form = formUi;
    }

    //turn off the clean save optimization if we have an explicit edit mode
    const alwaysSaveForm = alwaysSave || this.editingType || undefined;
    const renderedContent = type != 'none' || values != null || initialValues != null || form != null
      ? <Form {...commonProps} ref={this.form as React.MutableRefObject<Form>} editing={this.state.editing} {...{onNavigation, onOk, form, initialValues, values, onChange, onReset, autoFocus, autoSave, alwaysSave: alwaysSaveForm, showUnhandledErrors}}/>
      : <FormContent {...commonProps} ref={this.form}  />

    return <PanelParentContext.Provider value={this}>
      <HistoryListener onChange={this.checkForUrlEditCommand} triggerOnMount />
      {this.hasSaveable 
        ? <Saveable mb={hasSaveButtons ? '$30' : '$8'} onActionComplete={this.onActionComplete} {...this.renderEditButtons()} {...remaining}>{renderedContent}</Saveable>
        : <VBox mb={hasSaveButtons ? '$30' : '$8'} {...remaining}>{renderedContent}</VBox>}
    </PanelParentContext.Provider>
  }

  renderEditButtons():Partial<SaveableProps> {
    const showDirtyOnly = this.props.type == 'edit-dirty-button' && this.state.needsSave;
    const show = this.props.type == 'edit' || this.props.type == 'edit-toggle' || (this.props.type == 'toggle' && this.state.editing) || showDirtyOnly;
    
    if (!show) {
      return {cancel: null, ok: null}
    }

    let {cancel, ok, buttons} = this.props;

    if (this.contextualSaveButton && !this.state.needsSave) {
      ok = <Button disabled>All changes saved</Button>
    }

    if (this.props.type == 'edit' && !this.props.onCancel) {
      cancel = null;
    }

    return {cancel, ok, buttons};
  }

  renderPrimaryActions() {
    return <>
      {this.props.primaryActions}
      {this.renderEditIcon()}
    </>
  }

  renderEditIcon() {
    if (this.props.type != 'toggle') {
      return;
    }

    if (this.state.editing) {
      return;
    }

    const editToggle = this.props.editToggle || <Icon name='Edit' alt='edit' />

    return React.cloneElement(editToggle, {cursor: 'pointer', onClick:this.toggleEdit});
  }

  toggleEdit = () => {
    const editing = !this.state.editing
    this.setState({editing});
  }

  onActionComplete = (action:number) => {
    if (!this.mounted) {
      return;
    }

    if (this.props.type == 'toggle' || this.props.type == 'edit-toggle') {
      this.setState({editing: false});
    }

    if ((action == 0 && this.props.scrollOnCancel) || (action == 1 && this.props.scrollOnSave)) {
      this.scrollToTop()
    }
  }

  subscribe() {
    this.unsubscribe();

    if (this.contextualSaveButton) {
      this.subscribedTo = [this.formModel, ...(this.formModel.subforms || [])];
      this.subscribedTo.forEach(f => f.subscribe?.(this.onFormChange));
      this.updateSaveState();
    }
  }

  unsubscribe() {
    if (!this.subscribedTo?.length) {
      return;
    }

    this.subscribedTo.forEach(f => f.unsubscribe?.(this.onFormChange));
    this.subscribedTo = [];
  }

  updateSaveState() {
    const dirty = this.subscribedTo?.some?.(f => f.dirty);
    
    if (this.state.needsSave != dirty) {
      this.setState({needsSave: dirty});
    }
  }

  onFormChange = () => {
    this.updateSaveState();
  }

  scrollToTop() {
    const element = this.scrollTo;

    if (!element) {
      return;
    }

    scrollIntoView(element);
  }

  checkForUrlEditCommand = (location:Location, action:HistoryAction, prev:Location, history:History) => {
    if (!this.props.name || this.props.type != 'toggle' || action != 'MOUNT') {
      return;
    }

    const query = qs.parse(location.search);

    if (query['edit'] != this.props.name) {
      return;
    }

    history.replace(location.pathname + location.hash);
    this.setState({editing: true});
  }
}
