import * as React from 'react';
import pluralize from 'pluralize'

import { Modal, RepeatingSection, Info, Form, Field, FormModel } from 'app2/components';
import { errorPathTransform } from 'app2/views/shared';

import { OrganizerProvidersSelections, deleteProviders } from './gql';

interface FormProvider {
    provider: string;
}

interface FormValues {
  providers: FormProvider[];
}

type DeleteProvidersForm = FormModel<FormValues>;

interface Props {
  providers: OrganizerProvidersSelections['vendors'];
}

export function DeleteProvidersModal(props: Props) {
  const initialValues = React.useMemo(() => {
    const initialProviders = props.providers.map(p => ({ provider: p.displayName }));
    return { providers: initialProviders };
  }, props.providers);

  const title = `Delete ${pluralize('provider', props.providers.length)}`;

  function render() {
    return (
      <Modal title={title} ok={title}>
        <Form initialValues={initialValues} onOk={handleSubmit} onNavigation='nothing' alwaysSave>
          <RepeatingSection name="providers" bordered fields={[<Field label="Provider" name="provider" />]} mb="$30" />
          <Info type="warning">Are you sure you want to delete these providers?</Info>
        </Form>
      </Modal>
    );
  }

  async function handleSubmit(form: DeleteProvidersForm) {
    const company_names = props.providers.map(p => p.displayName);
    const successMsg = `Providers ${company_names.join()} successfully deleted.`

    const [success] = await deleteProviders({
      variables: { ids: props.providers.map(p => p.id) },
      successMsg,
      error: { handler: form, transform: [errorPathTransform('ids', 'providers')] }
    });
    return success;
  }

  return render();
}
