import * as React from 'react';

import { theme } from '../theme';
import { ComponentProps, componentStyles, createComponent } from '../utils';
import { Text, TextProps } from '../Text';
import { VBox } from '../Box';
import { dispatchChangeEvent } from '../dom-utils';

// a basic radio but in html a radio
// and label are separate, so this component
// is really three things:
//  - a container
//  - a radio
//  - a label
//
// you can pass styling for the container and the label
// custom radio styling is not supported
//
// since this is really three components not all the
// attributes available on the radio are exposed
//
// these are the only attributes available on the
// radio.  if you want more, add them here and
// then down below extract them off props and pass
// them to the radio.

// the entire set of properties available for this
// component are defined as RadioProps

// if there are children to the checkbox, they are rendered
// aligned with the checkbox label, and layed out vertically

interface RadioInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  value?: string | number;
  // selectedValue is used to determined checked should be true for 
  // when a Radio is bound to a form
  selectedValue?: string | number;
  error?: boolean;
  readOnly?: boolean;
  formMode?: 'display' | 'edit';
}

// these are non-standard and non-style attributes we add to
// RadioProps and do not want passed on to html
interface RadioCustomProps {
  label?: React.ReactNode;
  labelProps?: TextProps;
  description?: React.ReactNode;
}

// these are the properties that are applied to the container
type RadioContainerProps = ComponentProps<Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'>>;

// the total properties available on the component
export type RadioProps = RadioContainerProps & RadioCustomProps & RadioInputProps;

const propsToExclude: string[] = ['error', 'selectedValue', 'labelProps', 'description'];
export const RadioStyles = createComponent('label', propsToExclude)<RadioProps>`
  display: inline-flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  input {
    appearance: none;
    cursor: pointer;
    position: relative;

    box-sizing: border-box;
    height: 16px;
    width: 16px;
    margin: 1px 8px 1px 1px;

    border-radius: 7.5px;
    border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.primary};
    background: ${theme.colors.white};

    :hover:not(:disabled) {
      border-color: ${props => props.error ? theme.colors.errorHover : theme.colors.primaryHover};
    }

    :disabled {
      border-color: ${theme.colors.disabled};
      cursor: default;
    }

    :checked::before {
      content: '';
      position: absolute;
      background: ${props => props.error ? theme.colors.error : theme.colors.primary};
      height: 8px;
      width: 8px;
      border-radius: 4px;
      margin: 3px;
    }

    :hover:not(:disabled)::before {
      background: ${props => props.error ? theme.colors.errorHover : theme.colors.primaryHover};
    }

    :focus-visible:not(:disabled),
    :focus:not(:disabled) {
      outline: solid 2px ${props => props.error ? theme.colors.errorFocused : theme.colors.primaryFocused};
      outline-offset: -3px;
    }

    :disabled:checked::before {
      background: ${theme.colors.disabled};
    }

    transition: border .075s ease, background .075s ease;
  }

  ${componentStyles}    
` as React.ComponentType<RadioProps>;

export function Radio(props: RadioProps) {
  const { label, labelProps, description, onChange, checked, disabled, name, value, selectedValue, error, readOnly, formMode, children, ...remainingProps } = props;
  const checkedValue = checked || ('selectedValue' in props ? value == selectedValue : checked);
  const displayMode = formMode == 'display';
  const labelIsString = typeof label == 'string';
  const id = description && labelIsString ? label.replace(/[^a-zA-Z0-9_:\-]/g, "") : undefined;
  
  function render() {
    if (displayMode && !checkedValue) {
      return <></>
    }

    return props.children === undefined ? renderControl() : renderWithChildren()
  }

  function renderWithChildren() {
    return <VBox>
      {renderControl()}
      <VBox pl={displayMode ? '$8' : '24px'} pt='$8' vItemSpace='$8'>{props.children}</VBox>
    </VBox>
  }
  
  function renderControl() {
    const control = displayMode ? renderDisplayControl() : renderEditableControl();
  
    return !description
      ? control
      : <VBox>{control}<Text as='label' cursor='pointer' htmlFor={id} ml='24px' color='placeholder'>{description}</Text></VBox>
  }

  function renderDisplayControl() {
    return renderLabel();
  }

  function renderEditableControl() {
    return <RadioStyles disabled={disabled} error={error} {...remainingProps}>
      <input type="radio" id={id} {...{ onChange:handleChange, checked:checkedValue, disabled, name, value, readOnly }} />
      {renderLabel()}
    </RadioStyles>;
  }

  function renderLabel() {
    return <Text userSelect="none" display='inline-flex' alignItems='center' disabled={disabled} error={error} text='formlabel' {...labelProps}>{label}</Text>
  }

  function handleChange(event:React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    
    // when input type='radio' dispatches a change event, the value is going to be true
    // or false (for whether its selected or not). however Radio's values are the value
    // of the choice (such as an enumerated value). this overrides the change event
    // to set value to be the enumerated value assigned to this choice, and not true/false.
    
    dispatchChangeEvent(event.target, value, onChange);
  }

  return render();  
}

Radio.fieldProps = {
  valueProperty: 'selectedValue',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  getValueProperty: 'value',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur',
  labelProperty: 'label',
  formModeProperty: 'formMode'
}
