import * as React from 'react';

import { CourseKind, CourseUtils, PriceConfig, PriceConfigKind } from 'app2/api';
import { HBox, Icon, Tag } from 'app2/components';
import { WaitlistBadge, ScheduledCourse, ScheduledSession, ScheduledSessionProps } from 'app2/views/shared';
import { courseKindBehavior } from 'app2/views/shared-public';


interface ScheduledEnrollmentCourse extends ScheduledCourse {
  enrollmentId:string;
  color:string;
  waitlisted:boolean;
  removeFromWaitlist:(enrollmentId:string)=> void;
  priceConfig:Pick<PriceConfig, 'kind'>;
}

interface Props extends ScheduledSessionProps {
  course:ScheduledEnrollmentCourse;
}

export function EnrollmentSession(props:Props) {
  function render() {
    if (!courseKindBehavior[props.course.kind]?.attendable) {
      return null;
    }

    return <ScheduledSession {...props} bg={props.course.color} site={props.course.site} actions={renderRemoveFromWaitlist()} header={renderHeader()} />
  }

  function renderRemoveFromWaitlist() {
    return props.course.waitlisted && <HBox vAlign='center' gap='$4'><WaitlistBadge position='unset' /><Icon name='Trash2' size='small' onClick={onClick} /></HBox>
  }

  function renderHeader() {
    return props.course.priceConfig.kind == PriceConfigKind.Usage
      ? <Tag label='Flexible attendance' icon={false} />
      : props.course.kind == CourseKind.Game
        ? renderGameHeader()
        : undefined
  }

  function renderGameHeader() {
    return !hasGeneratedGameName(props.course) 
      ? <Tag label={CourseUtils.generatedGameName(props.course)} icon={false} /> 
      : undefined
  }

  function hasGeneratedGameName(game:ScheduledCourse) {
    return CourseUtils.generatedGameName(game).toLocaleLowerCase() == game.name.toLocaleLowerCase();
  }

  function onClick(event:React.MouseEvent) {
    event.preventDefault();
    props.course.removeFromWaitlist(props.course.enrollmentId);
  }

  return render();
}
