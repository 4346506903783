import { captureLogRocketSessions } from 'app/helpers';

import { ILogRocket, LogRocketSessionCallback } from './ILogRocket';

const capturing = captureLogRocketSessions();

interface Identity {
  uid: string;
  email: string;
}

export class LogRocketShim implements ILogRocket {
  identity?: Identity;
  callbacksFromGetSessionUrl: LogRocketSessionCallback[] = [];
  errors: object[] = [];

  init(id: string): void {}

  identify(uid: string, email: string): void {
    if (capturing) {
      this.identity = { uid, email };
    }
  }

  getSessionURL(cb: LogRocketSessionCallback): void {
    if (capturing) {
      this.callbacksFromGetSessionUrl.push(cb);
    } else {
      cb('not available');
    }
  }

  reduxMiddleware(): any {}

  captureException(error: any): void {
    if (capturing) {
      this.errors.push(error);
    }

    console.error(error);
  }

  applyShimCalls() {
    if (this.identity != null) {
      LogRocket.identify(this.identity.uid, this.identity.email);
      this.identity = undefined;
    }

    this.errors.forEach((err: object) => LogRocket.captureException(err));
    this.errors = [];

    this.callbacksFromGetSessionUrl.forEach((cb: LogRocketSessionCallback) =>
      LogRocket.getSessionURL(cb)
    );
    this.callbacksFromGetSessionUrl = [];
  }
}
