import * as React from 'react'

import { LocationListener, useHistoryListener } from './useHistoryListener';

interface HistoryListenerProps {
  onChange:LocationListener;
  triggerOnMount?:boolean;
}

export function HistoryListener(props:HistoryListenerProps) {
  useHistoryListener(props.onChange, props.triggerOnMount);

  return <></>;
}
