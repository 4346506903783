import * as React from 'react';

import { HBox, Text } from 'app2/components';

interface Props {
  name:string;
  count:number;
}

export function CoursesTableTabLabel(props:Props) {
  const { name, count } = props;

  return <>{name}&nbsp;<HBox display='inline-flex' bg='currentColor' borderRadius='form' height='24px' width='24px' 
    hAlign='center' vAlign='center'><Text text='body' fontWeight='bold' color='white' fontSize='12px'>{count}</Text></HBox></>
}
