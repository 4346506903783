import { CourseDay } from 'app2/api';
import { compareTimes } from 'app2/components';

export function getUniqueTimes(days:CourseDay[]) {
  const startEnds:Partial<CourseDay>[] = [];

  days?.forEach(day => {
    const existing = startEnds.find(existing => existing.start == day.start && existing.finish == day.finish);
    
    if (existing) {
      return;
    }

    startEnds.push(day);
  });

  startEnds.sort((left, right) => {
    return compareTimes(left.start, right.start, left.finish, right.finish)
  });

  return startEnds;
}
