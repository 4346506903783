import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserSitesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserSitesQuery = { adminUser?: { sites?: Array<{ id: string; name?: string | null | undefined }> | null | undefined } | null | undefined };

export const AdminUserSitesDocument = gql`
  query AdminUserSites($id: ID!) {
    adminUser(user: $id) {
      sites {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserSitesArgs = MakeOptional<UseQueryArgs<AdminUserSitesQueryVariables, AdminUserSitesQuery>, 'query'>;

export function useAdminUserSitesQuery(options: AdminUserSitesArgs = {}) {
  return useQuery<AdminUserSitesQueryVariables, AdminUserSitesQuery>({ query: AdminUserSitesDocument, ...options });
}

export type AdminUserSitesOptions = ExecQueryOptions<AdminUserSitesQueryVariables>;

export function adminUserSites(options: AdminUserSitesOptions) {
  return executeQuery<AdminUserSitesQuery, AdminUserSitesQueryVariables>(AdminUserSitesDocument, options);
}

export type AdminUserSitesSelections = AdminUserSitesQuery['adminUser'];
