import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentScheduleFieldsFragmentDoc } from './studentScheduleFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyScheduleQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type FamilyScheduleQuery = {
  family?:
    | {
        user?: { id: string; personalCalendar?: string | null | undefined } | null | undefined;
        students?:
          | Array<{
              id: string;
              name: string;
              enrollments?:
                | Array<
                    | {
                        id: string;
                        status: Types.EnrollmentStatusEnum;
                        waitlistKind: Types.WaitlistKind;
                        pickup?: string | null | undefined;
                        dismissal?: string | null | undefined;
                        groups?: Array<string> | null | undefined;
                        rosterPeriod?: string | null | undefined;
                        pickupDropoffRequired: boolean;
                        startDate?: string | null | undefined;
                        endDate?: string | null | undefined;
                        isOption: boolean;
                        priceConfig?:
                          | { kind: Types.PriceConfigKind }
                          | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string> }
                          | { kind: Types.PriceConfigKind; weekdays: Array<string> }
                          | { kind: Types.PriceConfigKind }
                          | null
                          | undefined;
                        optionParent?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
                        course: {
                          id: string;
                          name?: string | null | undefined;
                          room?: string | null | undefined;
                          siteGradeLabels?: Array<string | null | undefined> | null | undefined;
                          ageGroup?: string | null | undefined;
                          status: Types.CourseStatus;
                          kind?: Types.CourseKind | null | undefined;
                          noEnrichmentDays: Array<string | null | undefined>;
                          startDate?: string | null | undefined;
                          endDate?: string | null | undefined;
                          courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
                          company?: { name?: string | null | undefined; id: string } | null | undefined;
                          site: { id: string; name?: string | null | undefined; checkoutDestinations?: Array<string> | null | undefined; pickupDestinations?: Array<string> | null | undefined };
                          season: { id: string; name: string };
                          homeTeam?: { name?: string | null | undefined } | null | undefined;
                          awayTeam?: { name?: string | null | undefined } | null | undefined;
                        };
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FamilyScheduleDocument = gql`
  query FamilySchedule($family: ID!) {
    family(family: $family) {
      user {
        id
        personalCalendar
      }
      students {
        ...studentScheduleFields
      }
    }
  }
  ${StudentScheduleFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyScheduleArgs = MakeOptional<UseQueryArgs<FamilyScheduleQueryVariables, FamilyScheduleQuery>, 'query'>;

export function useFamilyScheduleQuery(options: FamilyScheduleArgs = {}) {
  return useQuery<FamilyScheduleQueryVariables, FamilyScheduleQuery>({ query: FamilyScheduleDocument, ...options });
}

export type FamilyScheduleOptions = ExecQueryOptions<FamilyScheduleQueryVariables>;

export function familySchedule(options: FamilyScheduleOptions) {
  return executeQuery<FamilyScheduleQuery, FamilyScheduleQueryVariables>(FamilyScheduleDocument, options);
}

export type FamilyScheduleSelections = FamilyScheduleQuery['family'];
