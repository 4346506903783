import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerComplianceDefinitionsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerComplianceDefinitionsQuery = {
  site?:
    | {
        id: string;
        complianceDefinitions: {
          vendor?:
            | Array<
                | {
                    id: string;
                    name: string;
                    complianceType: string;
                    instructions?: string | null | undefined;
                    downloads?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          teacher?:
            | Array<
                | {
                    id: string;
                    name: string;
                    complianceType: string;
                    instructions?: string | null | undefined;
                    downloads?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export const OrganizerComplianceDefinitionsDocument = gql`
  query OrganizerComplianceDefinitions($siteId: ID!) {
    site(site: $siteId) {
      id
      complianceDefinitions {
        vendor {
          id
          name
          complianceType
          instructions
          downloads {
            id
            name
            fileType
          }
        }
        teacher {
          id
          name
          complianceType
          instructions
          downloads {
            id
            name
            fileType
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerComplianceDefinitionsArgs = MakeOptional<UseQueryArgs<OrganizerComplianceDefinitionsQueryVariables, OrganizerComplianceDefinitionsQuery>, 'query'>;

export function useOrganizerComplianceDefinitionsQuery(options: OrganizerComplianceDefinitionsArgs = {}) {
  return useQuery<OrganizerComplianceDefinitionsQueryVariables, OrganizerComplianceDefinitionsQuery>({ query: OrganizerComplianceDefinitionsDocument, ...options });
}

export type OrganizerComplianceDefinitionsOptions = ExecQueryOptions<OrganizerComplianceDefinitionsQueryVariables>;

export function organizerComplianceDefinitions(options: OrganizerComplianceDefinitionsOptions) {
  return executeQuery<OrganizerComplianceDefinitionsQuery, OrganizerComplianceDefinitionsQueryVariables>(OrganizerComplianceDefinitionsDocument, options);
}

export type OrganizerComplianceDefinitionsSelections = OrganizerComplianceDefinitionsQuery['site'];
