import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'styled-components';

import { Theme } from '../theme';

export interface BreakpointProps {
  breakpoint: number;
  theme: Theme;
}

export function withBreakpoints<T>(
  Component: React.ComponentType<BreakpointProps & T>,
  breakpoints?: string[]
) {
  const Foo = function(props: BreakpointProps & T): React.ComponentType<T> {
    if (!breakpoints) {
      breakpoints = props.theme.breakpoints as string[];
    }

    const queries = [useMediaQuery({ minWidth: '0px' })].concat(
      breakpoints.map(width => useMediaQuery({ minWidth: width }))
    );
    const breakpoint = queries.lastIndexOf(true);

    //@ts-ignore
    return <Component {...props} breakpoint={breakpoint} />;
  };

  //@ts-ignore
  return withTheme(Foo);
}
