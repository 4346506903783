import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyMemberAddMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  user: Types.Scalars['ID'];
}>;

export type AdminCompanyMemberAddMutation = { companyAdminMemberAdd?: { id: string } | { id: string } | null | undefined };

export const AdminCompanyMemberAddDocument = gql`
  mutation AdminCompanyMemberAdd($company: ID!, $user: ID!) @urql(additionalTypenames: ["Company"]) {
    companyAdminMemberAdd(company: $company, user: $user, role: ADMIN) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminCompanyMemberAddOptions = ExecMutationOptions<AdminCompanyMemberAddMutationVariables>;

export function adminCompanyMemberAdd(options: AdminCompanyMemberAddOptions) {
  return executeMutation<AdminCompanyMemberAddMutation, AdminCompanyMemberAddMutationVariables>(AdminCompanyMemberAddDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Company']) }
  });
}
