import * as React from 'react';

import { EnrollmentUtils, EnrollmentWithStudentName } from 'app2/api';
import { Modal, RepeatingSection, formatCurrency, Form, formatAbbreviatedDate, Field, FormModel } from 'app2/components';
import { errorPathTransform } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';

import { BilledLaterAdvancedEnrollmentsSelections } from '../../enrolled';

import { recalcPendingRecurringEnrollments } from './generated';

interface FormEnrollment {
  student: string;
  billingDate: string;
  billingPeriod: string;
  balance: string;
}

interface FormValues {
  enrollments: FormEnrollment[];
}

type ReenrollForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: BilledLaterAdvancedEnrollmentsSelections[];
}

export function RecalcPendingRecurringModal(props: Props) {
  const initialValues = React.useMemo(() => {
    const initialEnrollments = props.enrollments.map(e => ({
      student: EnrollmentUtils.getStudentName((e as unknown) as EnrollmentWithStudentName),
      billingPeriod: e.billPeriod,
      billingDate: formatAbbreviatedDate(e.priceConfig.billingDate) as string,
      balance: formatCurrency(Math.abs(e.balance))
    }));
    return { enrollments: initialEnrollments };
  }, props.enrollments);
  const title = 'Recalculate upcoming subscription charges';
  const infoMessage = 'Incorporates any changes that affect proration, like proration settings and no activity days. Does not update the rate.';

  function render() {
    return (
      <Modal title={title} info={{ type: 'warning', message: infoMessage }} ok="Submit">
        <Form initialValues={initialValues} onOk={handleSubmit} onNavigation="nothing" alwaysSave>
          <RepeatingSection
            name="enrollments"
            bordered
            fields={[<Field label="Student" name="student" />, <Field label="Billing date" name="billingDate" />, <Field label="Billing period" name="billingPeriod" />, <Field label="Current amount" name="balance" />]}
          />
        </Form>
      </Modal>
    );
  }

  async function handleSubmit(form: ReenrollForm) {
    const [success] = await recalcPendingRecurringEnrollments({
      variables: { courseId: props.course?.id, ids: props.enrollments.map(e => e.id) },
      successMsg: 'Charges recalculated',
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments')] }
    });
    return success;
  }

  return render();
}
