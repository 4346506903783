import * as React from 'react'

import { BoxProps, VBox, HBox } from '../Box';
import { IconNames, Icon } from '../icons';
import { Subtitle2 } from '../Text';

export type NotificationType = 'success' | 'warning' | 'error';

export interface NotificationProps extends BoxProps {
  type: NotificationType;
  title?: React.ReactNode;
  message?: React.ReactNode;
  duration?: number;
  onClickClose?:() => void;
}

const icons:{[icon:string]:IconNames} = {
  success: 'CheckCircle',
  warning: 'AlertTriangle',
  error: 'AlertCircle'
}

const titles = {
  success: 'Success',
  warning: 'Attention',
  error: 'Error'
};

export function Notification(props:React.PropsWithChildren<NotificationProps>) {
    const {type, onClickClose, duration, title:propsTitle, message, children, ...remaining} = props;
    const icon = icons[type];
    const title = propsTitle ?? titles[type];
    const ref = React.useRef<HTMLElement>();
    const animation = React.useRef({cancel:() => {}, play:() => {}, addEventListener: undefined});

    React.useEffect(() => {
      if (!duration || !ref.current) {
        return;
      }

      if (typeof ref.current.animate != 'function') {
        setTimeout(() => onClickClose?.(), duration);
        return;
      }

      animation.current = ref.current.animate({opacity: [1, 1, 0], offset: [0, duration / (duration + 500), 1]}, {duration: duration + 500});
      animation.current.addEventListener('finish', onClickClose);
    });

    return <VBox ref={ref} bg={type + 'Fill'} maxWidth='100%' width='340px' p='$16' border='solid 1px' borderColor={type} borderRadius='standard' text='body' 
       onMouseEnter={() => animation.current.cancel()} onMouseLeave={() => animation.current.play()} {...remaining}>
      <HBox vAlign='center' pb='$12'>
        <Icon name={icon} strokeWidth={1} color={type} mr='$8' size='medium' />
        <Subtitle2 color={type}>{title}</Subtitle2>
        <HBox flex={1}/>
        <Icon name='X' size='medium' color={type} onClick={onClickClose} cursor='pointer' hover />
      </HBox>
      {message || children}
    </VBox>
}

Notification.defaultProps = {
  duration: 4000
}
