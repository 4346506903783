import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyUpdateMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  about?: Types.InputMaybe<Types.Scalars['String']>;
  address?: Types.InputMaybe<Types.Scalars['String']>;
  courseCancellationPolicy?: Types.InputMaybe<Types.Scalars['String']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
  headline?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  phone?: Types.InputMaybe<Types.Scalars['String']>;
  profileBackground?: Types.InputMaybe<Types.Scalars['String']>;
  profilePhoto?: Types.InputMaybe<Types.Scalars['String']>;
  size?: Types.InputMaybe<Types.Scalars['Int']>;
  teachingRadius?: Types.InputMaybe<Types.Scalars['Int']>;
  website?: Types.InputMaybe<Types.Scalars['String']>;
  zipcode?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type CompanyUpdateMutation = { companyUpdate?: { company?: { id: string } | null | undefined } | null | undefined };

export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate(
    $company: ID!
    $about: String
    $address: String
    $courseCancellationPolicy: String
    $email: String
    $headline: String
    $name: String
    $phone: String
    $profileBackground: String
    $profilePhoto: String
    $size: Int
    $teachingRadius: Int
    $website: String
    $zipcode: String
  ) @urql(additionalTypenames: ["User"]) {
    companyUpdate(
      company: $company
      about: $about
      address: $address
      courseCancellationPolicy: $courseCancellationPolicy
      email: $email
      headline: $headline
      name: $name
      phone: $phone
      profileBackground: $profileBackground
      profilePhoto: $profilePhoto
      size: $size
      website: $website
      teachingRadius: $teachingRadius
      zipcode: $zipcode
    ) {
      company {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyUpdateOptions = ExecMutationOptions<CompanyUpdateMutationVariables>;

export function companyUpdate(options: CompanyUpdateOptions) {
  return executeMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(CompanyUpdateDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
