import * as React from 'react';

import { Course, CourseStatus } from 'app2/api';
import { bulletize, hasNewId, Modal, VBox } from 'app2/components';

import { enrollmentDatesWillChange } from './gql';

export async function enrollmentDatesWillChangeCheck(courses: Courses): Promise<boolean> {
  if (!courses.length) {
    throw new Error('Missing courses');
  }

  try {
    const warnable = await getWarnableCourses(courses);
    if (!warnable.length) {
      return false;
    }

    const modalResult = await displayModal(warnable);
    if (modalResult.action) {
      return false;
    }
  }
  catch(e) {
  }

  return true;
}

async function getWarnableCourses(courses: Courses) {
  const warnable: typeof courses = [];

  const omitStatuses = new Set([undefined, null, CourseStatus.Draft, CourseStatus.Cancelled, CourseStatus.Request, CourseStatus.AwaitingApproval]);
  courses = courses.filter(c => c.startDate && c.endDate && !omitStatuses.has(c.status) && !hasNewId(c));

  if (!courses.length) {
    return warnable;
  }

  const [success, result] = await enrollmentDatesWillChange({ variables: { inputs: courses.map(c => ({ id: c.id, startDate: c.startDate, endDate: c.endDate })) } });
  if (!success) {
    throw result.error;
  }

  const results = result.data?.enrollmentDatesWillChange || [];
  results.forEach(r => {
    if (r.result) {
      const course = courses.find(c => c.id === r.id);
      course.name = r.name; // The server returns a unique name if applicable.
      warnable.push(course);
    }
  });
  return warnable;
}

async function displayModal(courses: Courses) {
  const bulk = courses.length > 1;
  const result = await Modal.warning({
    title: 'Date change will affect enrollments',
    content: (
      <VBox hAlign="center">
        Are you sure you want to change the dates for {bulk ? 'the following courses' : `the ${courses[0].name}`}? Doing so will change {bulk ? 'their' : 'its'} enrollments' dates.
        {bulk && (
          <>
            <br />
            <br />
            {bulletize(courses.map(c => c.name))}
          </>
        )}
      </VBox>
    ),
    ok: 'Continue'
  });
  return result;
}

type Courses = Array<Pick<Course, 'id' | 'name' | 'startDate' | 'endDate' | 'status'>>;
