import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertPriceTiersMutationVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  priceTiers: Array<Types.PriceTierInput> | Types.PriceTierInput;
}>;

export type OrganizerUpsertPriceTiersMutation = { siteUpsertPriceTiers?: { priceTiers?: Array<{ id: string }> | null | undefined } | null | undefined };

export const OrganizerUpsertPriceTiersDocument = gql`
  mutation OrganizerUpsertPriceTiers($ownerId: ID!, $priceTiers: [PriceTierInput!]!) {
    siteUpsertPriceTiers(site: $ownerId, priceTiers: $priceTiers) {
      priceTiers {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertPriceTiersOptions = ExecMutationOptions<OrganizerUpsertPriceTiersMutationVariables>;

export function organizerUpsertPriceTiers(options: OrganizerUpsertPriceTiersOptions) {
  return executeMutation<OrganizerUpsertPriceTiersMutation, OrganizerUpsertPriceTiersMutationVariables>(OrganizerUpsertPriceTiersDocument, options);
}
