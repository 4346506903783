import { CurrentUserInfo, useCurrentUser } from '../user';

import { betaImageCssLeft, useShowBeta } from './Beta';
import { useRouteInfo } from './useRouteInfo';

export type HideNavigation = (user:CurrentUserInfo['user']) => boolean;

export function useNavigationRouteInfo(hide:HideNavigation, to:string) {
  const {route, allowed} = useRouteInfo(to);
  const beta = route?.props?.beta;
  const { user } = useCurrentUser();

  if (beta && !useShowBeta()) {
    return {allowed: false};
  }

  if (!allowed) {
    return {allowed: false};
  }

  if (hide && hide(user)) {
    return {allowed: false};
  }

  const css = beta ? betaImageCssLeft : undefined;
  return {allowed: true, css}
}
