import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetCartFamilyMutationVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type SetCartFamilyMutation = { cartSetFamily?: { cart?: { id: string } | null | undefined } | null | undefined };

export const SetCartFamilyDocument = gql`
  mutation SetCartFamily($family: ID!) {
    cartSetFamily(family: $family) {
      cart {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SetCartFamilyOptions = ExecMutationOptions<SetCartFamilyMutationVariables>;

export function setCartFamily(options: SetCartFamilyOptions) {
  return executeMutation<SetCartFamilyMutation, SetCartFamilyMutationVariables>(SetCartFamilyDocument, options);
}
