export async function copyToClipboard(text:string) {
  let result = false;

  try {
    await navigator.clipboard.writeText(text);
    result = true;
  }
  catch(e) {
    const input: HTMLInputElement = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    result = document.execCommand('copy');
    document.body.removeChild(input);
  }

  return result;
}
