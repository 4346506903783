import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentCourseQuery = {
  course?:
    | {
        room?: string | null | undefined;
        optionParents?: Array<{ status: Types.EnrollmentStatusEnum; student?: { id: string; name: string } | null | undefined } | null | undefined> | null | undefined;
        company?: { email?: string | null | undefined; phone?: string | null | undefined } | null | undefined;
        parents?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const ParentCourseDocument = gql`
  query ParentCourse($id: ID!) {
    course(course: $id) {
      optionParents(queries: ["CONFIRMED", "SCHEDULED"]) {
        status
        student {
          id
          name
        }
      }
      room
      company {
        email
        phone
      }
      parents {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentCourseArgs = MakeOptional<UseQueryArgs<ParentCourseQueryVariables, ParentCourseQuery>, 'query'>;

export function useParentCourseQuery(options: ParentCourseArgs = {}) {
  return useQuery<ParentCourseQueryVariables, ParentCourseQuery>({ query: ParentCourseDocument, ...options });
}

export type ParentCourseOptions = ExecQueryOptions<ParentCourseQueryVariables>;

export function parentCourse(options: ParentCourseOptions) {
  return executeQuery<ParentCourseQuery, ParentCourseQueryVariables>(ParentCourseDocument, options);
}

export type ParentCourseSelections = ParentCourseQuery['course'];
