import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentSetTeamRegistrationMutationVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID'];
  athleticsConfig: Types.AthleticsConfigInput;
}>;

export type ParentSetTeamRegistrationMutation = { cartSetTeamRegistration?: { enrollment?: { id: string } | null | undefined } | null | undefined };

export const ParentSetTeamRegistrationDocument = gql`
  mutation ParentSetTeamRegistration($enrollmentId: ID!, $athleticsConfig: AthleticsConfigInput!) @urql(additionalTypenames: ["Cart"]) {
    cartSetTeamRegistration(enrollmentId: $enrollmentId, athleticsConfig: $athleticsConfig) {
      enrollment {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentSetTeamRegistrationOptions = ExecMutationOptions<ParentSetTeamRegistrationMutationVariables>;

export function parentSetTeamRegistration(options: ParentSetTeamRegistrationOptions) {
  return executeMutation<ParentSetTeamRegistrationMutation, ParentSetTeamRegistrationMutationVariables>(ParentSetTeamRegistrationDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Cart']) }
  });
}
