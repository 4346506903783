import { CourseStatus } from 'app2/api';
import { compareTimes } from 'app2/components';

import { ScheduledCourse } from './ScheduledCourse'

export function getSessionsForDay<T extends ScheduledCourse = ScheduledCourse>(courses:T[], date:moment.Moment) {
  if (!courses) {
    return [];
  }

  const day = date.format('dddd');

  return courses
    .filter(course => course.status != CourseStatus.Deleted)
    .map(course => {return {course, day: courseHasDay(course, day), holiday: courseHasHoliday(course, date)}})
    .filter(info => info.day && courseHasDate(info.course, date))
    .sort((a, b) => compareTimes(a.day.start, b.day.start, a.day.finish, b.day.finish));
}

function courseHasDay(course:ScheduledCourse, day:string) {
  return course.courseDays.find(courseDay => courseDay.days.indexOf(day) != -1);
}

function courseHasDate(course:ScheduledCourse, date:moment.Moment) {
  return date.isSameOrAfter(course.startDate) && date.isSameOrBefore(course.endDate);
}

function courseHasHoliday(course:ScheduledCourse, date:moment.Moment) {
  return course.noEnrichmentDays.find(noe => date.isSame(noe, 'd')) != null;
}