import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FinalizeCoursesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type FinalizeCoursesMutation = { coursesFinalize?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const FinalizeCoursesDocument = gql`
  mutation FinalizeCourses($ids: [ID!]!) {
    coursesFinalize(ids: $ids) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type FinalizeCoursesOptions = ExecMutationOptions<FinalizeCoursesMutationVariables>;

export function finalizeCourses(options: FinalizeCoursesOptions) {
  return executeMutation<FinalizeCoursesMutation, FinalizeCoursesMutationVariables>(FinalizeCoursesDocument, options);
}
