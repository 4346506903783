import { CLOUDINARY_URL_BASE, WEBSERVER_IMAGE_ROOT } from './constants';

// using buildCloudinaryTransformation and buildCloudinaryImageUrl
//  - pass in the image url
//  - optionally specify height width else it will download the full image size
//  - if you specify height width, it should be at least double the resolution you want. for retina
//  - you then need to set the height width of your image to have the image scale it down
//  - you should use height width when there are a lot of images on the page and 2x need dimensions is smaller
//    than the original (which is specified in the preset upload in cloudinary admin)

// do not use this as the start of any cloudinary url/folder
// this is used to determine if the passed in image name
// is really non-cloudinary image and should be ignored

export interface CloudinaryOptions {
  width?: number;
  height?: number;
  borderRadius?: number | 'max';
}

export function buildCloudinaryTransformation(options?: CloudinaryOptions) {
  if (!options) {
    return '';
  }

  const transformations: string[] = [];

  if (options.width && options.height) {
    transformations.push(`c_scale,w_${Math.round(options.width)}/c_crop,g_center,w_${Math.round(options.width)},h_${Math.round(options.height)}`
    );
  }

  if (options.borderRadius) {
    transformations.push(`r_${options.borderRadius}`);
  }

  if (transformations.length) {
    transformations.push('q_auto,f_auto');
  }

  return transformations.join(',');
}

export function buildCloudinaryImageUrl(image: string, ratio?:number, width?:number, resolution?:number, borderRadius?:number):string;
export function buildCloudinaryImageUrl(image: string, options: CloudinaryOptions):string;
export function buildCloudinaryImageUrl(image: string, optionsOrRatio: CloudinaryOptions | number, width?:number, resolution: number = 2, borderRadius:number|'max' = 4) {
  if (!image) {
    return '';
  }

  const options = typeof optionsOrRatio == 'object'
    ? optionsOrRatio
    : !optionsOrRatio 
      ? (borderRadius ? {borderRadius} : null)
      : {
        width: width * resolution,
        height: (width * resolution) / optionsOrRatio,
        borderRadius: typeof borderRadius == 'string' ? borderRadius : borderRadius * resolution
      }

  return `${CLOUDINARY_URL_BASE}${buildCloudinaryTransformation(options)}/${image}`;
}

export function buildCloudinaryImageProps(image: string, ratio:number, width:number, resolution: number = 2, borderRadius:number = 4) {
  return {
    width: width + 'px',
    height: 'auto',
    borderRadius: borderRadius,
    objectFit: 'contain',
    objectPosition: 'center',
    src: image?.startsWith(WEBSERVER_IMAGE_ROOT) || /^https?:/i.test(image) 
      ? image 
      : buildCloudinaryImageUrl(image, ratio, width, resolution, borderRadius)
  }
}
