import * as React from 'react';
import { isEqual, last } from 'lodash-es'

import { FieldInfo, Span, VBox } from 'app2/components'

import { AdminHistorySelections } from './generated';

interface Props {
  info:FieldInfo<AdminHistorySelections>;
  hideUnchanged?:boolean;
}

export function Version(props:Props) {
  const pos = last(props.info.parents) as number;
  const cur = props.info.record?.attributes || {};
  const prev = props.info.values[1][pos - 1]?.attributes;

  function render() {
      const curKeys = Object.keys(cur);
      const changes = curKeys.map(key => {
        const prevExists = prev && key in prev;

        if (prevExists && isEqual(cur[key], prev[key])) {
          return renderSame(key, JSON.stringify(cur[key]));
        }
        else
        if (prevExists) {
          return renderChanged(key, JSON.stringify(cur[key]));
        }
        else {
          return renderAdded(key, JSON.stringify(cur[key]));
        }
      });

      let removed = [] as React.ReactNode[];

      if (prev) {
        const prevKeys = Object.keys(prev);
        removed = prevKeys.map(key => {
          if (!(key in cur)) {
            return renderRemoved(key, JSON.stringify(prev[key]));
          }
        })
      }

      return <VBox whiteSpace='pre-wrap'>{changes}{removed}</VBox>;
  }

  function renderSame(key:string, value:any) {
    if (props.hideUnchanged) {
      return;
    }

    return <Span key={key} color='gray'>{key}: {value}</Span>;
  }

  function renderChanged(key:string, value:any) {
    return <Span key={key} color='orange' bold>{key}: {value}</Span>;
  }

  function renderAdded(key:string, value:any) {
    return <Span key={key} color='green'>{key}: {value}</Span>;
  }

  function renderRemoved(key:string, value:any) {
    return <Span key={key} color='red'>{key}: {value}</Span>;
  }

  return render();
}

Version.defaultProps = {
  hideUnchanged:true
}

Version.fieldProps = {
  fieldProperty: 'info'
}
