import * as React from 'react';

import { CurrencyField, Form, Modal, NotificationManager, SaveableResult, Section, MenuItem, OptionsMenu, Tabs } from 'app2/components';

import { AdminPage } from '../AdminPage';
import { ReviewsTable } from '../shared';

import { Payouts } from './Payouts';
import { FailedPayouts } from './FailedPayouts';
import { MissingDeposits } from './MissingDeposits';
import { Financial } from './Financial';

import { adminWithdrawFunds } from './generated';

export function Homeroom() {
  function render() {
    return <AdminPage title='Homeroom' actions={renderActions()}>
      <Tabs baseUrl={`/admin2/homeroom/:tab`} urlParameter="tab" tabs={[
          { label: 'Payouts', name: 'payouts', content: <Payouts /> },
          { label: 'Failed payouts', name: 'failed-payouts', content: <FailedPayouts /> },
          { label: 'Undeposited payouts', name: 'missing-deposit', content: <MissingDeposits /> },
          { label: 'Financial', name: 'stripe', content: <Financial /> },
          { label: 'Reviews', name: 'reviews', content: <ReviewsTable /> },
        ]}
      />
    </AdminPage>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem label='Withdraw funds from Stripe...' onClick={withdrawFunds}/>
    </OptionsMenu>
  }

  async function withdrawFunds() {
    const modal = <Modal title="Withdraw funds from Stripe">
      <Form>
        <Section label='Amount' name='amount' component={CurrencyField} />
      </Form>
    </Modal>

    const result = await Modal.show(modal);

    if (result.result == SaveableResult.cancel) {
      return;
    }

    const [success] = await adminWithdrawFunds({variables:{amount: result.result.amount}});

    if (success) {
      NotificationManager.add('Withdrawl created');
    }

    return success;
  }

  return render();
}
