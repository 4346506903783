import { useTheme } from 'styled-components';

import { useBreakpoints, breakpointValue } from '../breakpoints';

import { Theme } from './theme'

export function useThemeValue(category:keyof Theme, value:any) {
  const theme = useTheme() as Theme;
  const breakpoint = useBreakpoints();

  value = breakpointValue(value, breakpoint);

  return theme[category][value] || value;
}
