export const COURSE_IMAGE_WIDTH = 835;
export const COURSE_IMAGE_HEIGHT = 322;
export const COURSE_IMAGE_RATIO = COURSE_IMAGE_WIDTH / COURSE_IMAGE_HEIGHT;

export const COURSE_CARD_IMAGE_WIDTH = 1160;
export const COURSE_CARD_IMAGE_HEIGHT = 882;
export const COURSE_CARD_IMAGE_RATIO =
  COURSE_CARD_IMAGE_WIDTH / COURSE_CARD_IMAGE_HEIGHT;
export const DEFAULT_COURSE_CARD_IMG =
  '/global-assets/default_course_card_img.png';

export const DEFAULT_HEADER_IMG = '/global-assets/default_header_img.png';
