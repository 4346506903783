import * as React from 'react';

import { Team as TeamBase } from '../shared'

import { adminSiteMemberAdd, adminSiteMemberRemove, useSiteTeamQuery } from './generated';
import {  } from './generated';

export function Team() {
  return <TeamBase query={useSiteTeamQuery} 
    addTeamMember={(site, user) => adminSiteMemberAdd({variables:{site, user}})} 
    removeTeamMember={(site, user) => adminSiteMemberRemove({variables:{site, user}})} />
}
