import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerMakeSiteCompanyMutationVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type OrganizerMakeSiteCompanyMutation = { vendorMakeSiteCompany?: { vendor?: { id: string; siteCompany: boolean; accepted: boolean } | null | undefined } | null | undefined };

export const OrganizerMakeSiteCompanyDocument = gql`
  mutation OrganizerMakeSiteCompany($vendor: ID!) {
    vendorMakeSiteCompany(vendor: $vendor) {
      vendor {
        id
        siteCompany
        accepted
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerMakeSiteCompanyOptions = ExecMutationOptions<OrganizerMakeSiteCompanyMutationVariables>;

export function organizerMakeSiteCompany(options: OrganizerMakeSiteCompanyOptions) {
  return executeMutation<OrganizerMakeSiteCompanyMutation, OrganizerMakeSiteCompanyMutationVariables>(OrganizerMakeSiteCompanyDocument, options);
}
