import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertCoursesMutationVariables = Types.Exact<{
  seasonId?: Types.InputMaybe<Types.Scalars['ID']>;
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  courseId?: Types.InputMaybe<Types.Scalars['ID']>;
  transition?: Types.InputMaybe<Types.Scalars['Boolean']>;
  attributes: Array<Types.CourseInput> | Types.CourseInput;
}>;

export type UpsertCoursesMutation = { coursesUpsert?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const UpsertCoursesDocument = gql`
  mutation UpsertCourses($seasonId: ID, $companyId: ID, $courseId: ID, $transition: Boolean, $attributes: [CourseInput!]!) @urql(additionalTypenames: ["Company", "Season"]) {
    coursesUpsert(seasonId: $seasonId, companyId: $companyId, courseId: $courseId, transition: $transition, attributes: $attributes) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpsertCoursesOptions = ExecMutationOptions<UpsertCoursesMutationVariables>;

export function upsertCourses(options: UpsertCoursesOptions) {
  return executeMutation<UpsertCoursesMutation, UpsertCoursesMutationVariables>(UpsertCoursesDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Company', 'Season']) }
  });
}
