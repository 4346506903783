import { ObservableCollection } from "./ObservableCollection";

export enum CollectionEventType {
  create,
  update,
  delete,
  reset
}

export interface CollectionEvent<T> {
  collection:ObservableCollection<T>;
  type:CollectionEventType;
  position?:number;
  id?:string | number;
  item?:T;
  property?:string;
}

