import * as React from 'react'

import { CourseUtils } from 'app2/api';
import { Box, CollapsibleList, VBox } from 'app2/components';

import { Cart } from '../../cart';

import { AddToCart, AddToCartProps } from './AddToCart';
import { CourseCardCourse } from './CourseInfo';
import { CourseDays } from './CourseDays';
import { CourseTeacher } from './CourseTeacher';
import { CoursePricesWithIcon } from './CoursePricesWithIcon';

interface CourseVariantsProps {
  course:CourseCardCourse;
  cart:Cart;
  addToCart?:AddToCartProps['addToCart'];
  variantFilter?:(c:CourseCardCourse) => boolean;
}

export function CourseVariantsAddToCart(props:CourseVariantsProps) {
  const {course, cart} = props;

  const variants = React.useMemo(() => CourseUtils.sortCoursesByCourseDayTime2(course.children), [course]);
  const filteredVariants = variants.filter(v => props.variantFilter(v)).filter(v => v.searchable);
  const hasCartedVariant = React.useRef(false);
  hasCartedVariant.current ||= filteredVariants.slice(3).some(v => cart?.counts?.has(v.id));

  function render() {
    if (!variants?.length) {
      return <></>
    }
    
    return <VBox mt='$8' gap='$16' width='100%'>
      <CollapsibleList items={filteredVariants} render={renderVariant} initialCount={hasCartedVariant.current ? undefined : 3} />
    </VBox>
  }

  function renderVariant(variant:CourseCardCourse, index:number) {
    return <Box key={index} layout={['vbox', 'hbox']} pt='$16' gap='$8' borderTop='solid 1px' borderColor='border' width='100%' hAlign='justify'>
      <VBox hAlign='left' gap='$8'>
        <CourseDays mb='unset' icon='Clock' course={variant} />
        <CourseTeacher course={variant} />
        <CoursePricesWithIcon course={variant} />
      </VBox>
      <AddToCart course={variant} cart={cart} width={['100%', 'fit-content']} addToCart={props.addToCart} variantFilter={props.variantFilter} />
    </Box>
  }

  return render();
}

CourseVariantsAddToCart.defaultProps = {
  variantFilter: () => true
}
