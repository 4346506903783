import * as React from 'react';

import { RefundTable } from 'app2/views/shared';

import { useStudentRefundsQuery } from './gql'

interface Props {
  id: string;
}

export function StudentRefunds(props:Props) {
  const [result] = useStudentRefundsQuery({ variables: { id: props.id } });
  const refunds = result?.data?.student?.refunds;

  return <RefundTable box={{ mb: '$30' }} refunds={refunds} showBillPeriod />
}
