import { useParams } from 'react-router';

import { parseSingleDate } from 'app2/components';

export function useAttendanceDate() {
  let { date:urlDate } = useParams<{date?:string}>();
  const date = urlDate ? parseSingleDate(urlDate)?.format('YYYY-MM-DD') : null;

  return date;
}

