import * as React from 'react'
import Popup from 'reactjs-popup';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';

import { BoxProps } from './Box';
import { itemize } from './Info';
import { Span } from './Tags';

interface Props extends Omit<BoxProps, 'position'> {
  tip:React.ReactNode;
  on?:EventType | EventType[];
  position?:PopupPosition | PopupPosition[];
  offsetX?:number;
  offsetY?:number;
  children:JSX.Element;
}

export function Tooltip(props:Props) {
  let {tip, on, position, offsetX, offsetY, children, ...remaining} = props;

  // always render the tooltip regardless of whether there's tip content because otherwise
  // it leads to rendering differences that can cause focus loss or cursor movement when rereendering
  return <Popup trigger={children} on={on} mouseEnterDelay={1000} closeOnDocumentClick position={position} offsetX={offsetX} offsetY={offsetY} arrow={false}>
    {tip ? <Span bg='white' p='$12' text='body' border='solid 1px' borderColor='border' borderRadius='standard' maxWidth='70vw' {...remaining}>{itemize(tip)}</Span> : ''}
  </Popup>
}

Tooltip.defaultProps = {
  on: ['hover', 'click'],
  position: 'top left',
  offsetX:10,
  offsetY:-10
}

// makes the tooltip class available to the component base
// because of circular imports it cant be imported directly
// @ts-ignore
window['__Tooltip'] = Tooltip;