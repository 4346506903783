import * as React from 'react';

import { EntityKind } from 'app2/api';
import { ContactModal as BaseContactModal } from 'app2/views/shared-public';

import { DirectorySiteSelections } from './generated';
import { DirectoryResult } from './DirectoryResult';

interface Props {
  site: DirectorySiteSelections;
  result: DirectoryResult;
}

export function ContactModal(props: Props) {
  function render() {
    return <BaseContactModal to={props.result.course.company.name} toId={props.result.course.company.id} toKind={EntityKind.Company}
      message={`Hi, I'm interested in having you teach activities at ${props.site ? props.site.name : 'my school'}.`} />
  }

  return render();
}
