import * as React from 'react';

import { Filter } from 'app2/api';
import { DataTable, DataTableColumn, MenuItem } from 'app2/components';
import { ContentType, downloadReport, createReportViewFromTableV2, getStandardFilterOptions, HrDataTable } from 'app2/views/shared';

import { AdminReviewsSelections, adminReviewsFilterOptions, useAdminReviewsQuery } from './generated'

interface Props {
  filter?:Filter;
}

export function ReviewsTable(props:Props) {
  const table = React.useRef<DataTable>();
  const vars = React.useMemo(() => props.filter ? {filters: [props.filter]} : {}, [props.filter?.name, props.filter?.op, props.filter?.value]);

  function render() {
    return <HrDataTable<AdminReviewsSelections> header={{title: 'Reviews', options: renderOptions()}} query={useAdminReviewsQuery} queryOptions={{variables:vars}} sortFilterType='v2' table={{ref:table, cols: getCols()}} />
  }

  function renderOptions() {
    return [<MenuItem autoLoader onClick={downloadCsv}>Download CSV</MenuItem>];
  }

  function downloadCsv() {
    return downloadReport('Homeroom reviews', ContentType.CSV, createReportViewFromTableV2('reviews', vars, table.current));
  }

  function getCols() {
    return React.useMemo(() => {
      const base = {
        sortable:true,
        filterable:true,
        getFilterOptions: getStandardFilterOptions<AdminReviewsSelections>(adminReviewsFilterOptions, vars)
      }
      
      const cols:DataTableColumn<AdminReviewsSelections>[] = [{
          ...base,
          name: 'course.name' as keyof AdminReviewsSelections,
          label: 'Activity',
        }, {
          ...base,
          name: 'site.name' as keyof AdminReviewsSelections,
          label: 'School',
        }, {
          ...base,
          name: 'company.name' as keyof AdminReviewsSelections,
          label: 'Provider',
        }, {
          name: 'comments',
          label: 'Comments'
        }, {
          name: 'instructorRating',
          label: 'Instructor rating'
        }, {
          name: 'overallRating',
          label: 'Overall rating'
        }, {
          name: 'qualityRating',
          label: 'Quality rating'
        }, {
          ...base,
          name: 'student.firstName' as keyof AdminReviewsSelections,
          label: 'Student first',
        }, {
          ...base,
          name: 'student.lastName' as keyof AdminReviewsSelections,
          label: 'Student last',
        }, {
          ...base,
          name: 'parent.firstName' as keyof AdminReviewsSelections,
          label: 'Parent first',
        }, {
          ...base,
          name: 'parent.lastName' as keyof AdminReviewsSelections,
          label: 'Parent last',
        }]

        return cols;
      }, [vars]);
  }

  return render();
}

