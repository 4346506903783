import * as React from 'react'

import { Input, InputProps } from './Input'
import { useLifecycle } from './utils';
import { dispatchChangeEvent } from './dom-utils';

// allows an Input to act as a pseudo-mask input by allowing
// partial values as the user types.  to use, you provide
// an error tolerant parse and valid function
export interface RestrictedInputProps extends InputProps {
  // function that should be a fault tolerant parser of user input to stored value
  parse?:(value:string) => any;
  // function that should be a fault tolerant formatter of stored value to user input
  format?:(value:any) => string;
  // detects if the user input is valid (useful for filtering out unwanted user input), unlike accept this is always called before parse
  valid?:(value:string) => boolean;
  // dictates if a value that was parsed is truly acceptable before commiting the value via a change event
  // this will be called with a parsed value
  commit?:(value:any) => boolean;
  // function that should return if the input is loosing the focus.  for inputs that 
  // are dropdowns, use this to tell the RestrictedInput if the thing getting the focus
  // is really part of the input and the input didn't really lose focus.
  blurred?:(event:React.FocusEvent) => boolean;
  // dictates if the internal value should also be formatted
  formatValue?:boolean;
}

export const RestrictedInput = React.forwardRef((props:RestrictedInputProps, ref:any) => {
  const {value, onChange, onBlur, parse, format, valid, commit, blurred, formatValue, ...remaining} = props;
  const formattedValue = format(value);
  const [text, setText] = React.useState<string>(formattedValue);
  const previousPropsValue = React.useRef(props.value);
  const ourRef = React.useRef(ref);
  ref = ref || ourRef;

  const hasFocus = ref.current == document.activeElement;
  const incomingValue = "value" in props ? formattedValue : text;
  const valueToUse = hasFocus ? text : typeof incomingValue == 'number' ? String(incomingValue) : incomingValue;

  useLifecycle({onUpdate})

  function render() {
    return <Input ref={ref} value={valueToUse} onChange={changeHandler} onBlur={blurHandler} {...remaining} />
  }

  // block changes from the user that don't look valid (but this allows partial values)
  function changeHandler(event:React.ChangeEvent<HTMLInputElement>) {
    if (!valid(event.currentTarget.value)) {
      return;
    }

    setText(event.currentTarget.value);

    let eventValue = parse(event.currentTarget.value);

    // the value didn't parse we dont accept it if there
    // was somethign to parse (if there's nothing to parse
    // then the user was clearing the value)
    if (((eventValue == null || eventValue == undefined) && event.currentTarget.value.trim().length)) {
      return;
    }

    if (!commit(eventValue)) {
      return;
    }

    if (formatValue) {
      eventValue = format(eventValue);
    }

    if (eventValue !== previousPropsValue.current) {
      dispatchChangeEvent(event.currentTarget, eventValue, onChange);
    }
  }

  function onUpdate() {
    if (previousPropsValue.current != props.value) {
      resetToPropValue();
    }
  }

  // once we lose focus, format the input since it might be formatted incorrectly while the user is typing
  function blurHandler(event:React.FocusEvent<HTMLInputElement>) {
    if (blurred && !blurred(event)) {
      return;
    }

    const parsed = parse(valueToUse);
    const committed = commit(parsed);

    // if the current value is not valid, don't reformat (which would clear) the text
    if (parsed && committed) {
      setText(format(parsed));
    }
    else {
      resetToPropValue();
    }

    onBlur?.(event);
  }

  function resetToPropValue() {
    const formattedCurValue = format(parse(text));
    previousPropsValue.current = props.value;
    
    const formattedPropValue = format(parse(props.value));

    if (formattedCurValue != formattedPropValue) {
      setText(formattedPropValue);
    }
  }
  
  return render();
})


RestrictedInput.displayName = 'RestrictedInput';
RestrictedInput.defaultProps = {
  parse: value => value,
  format: value => value,
  valid: value => true,
  commit: value => true
}
