import * as React from 'react'

import { InactiveWarning as InactiveWarningBase } from 'app2/views/shared-public'

import { useCurrentSite } from '.'

export function InactiveWarning() {
  const { site } = useCurrentSite();

  return <InactiveWarningBase site={site} />
}
