import * as React from 'react';

import { Info, VBox } from 'app2/components';
import { NonePanel } from 'app2/views/shared-public';
import { CourseTablesForGroups, Module, useCreateActivitiesButton } from 'app2/views/shared';

import { useCompanyQuery, CompanyPage } from '../shared'

import { VendorInvites } from './VendorInvites';
import { CompanyCoursesTable } from './CompanyCoursesTable';
import { useCompanyWithCoursesQuery } from './generated';
import Instructor from 'images/Instructor.svg'

export function CompanyCourses() {
  const { company } = useCompanyQuery(useCompanyWithCoursesQuery);

  const showCreate = company?.nonPartnerApproved;
  const { editing, createActivitiesButton } = useCreateActivitiesButton(company?.courseKindGroups);
  const empty = company && !company.hasCourses && !editing;

  function render() {
    return <CompanyPage title='Activities'>
      <VendorInvites />
      <Module name='Activities'>
        <VBox gap='$30'>
          {renderRequestsBanner()}
          {empty ? renderEmpty() : renderCourseGroups()}
        </VBox>
      </Module>
    </CompanyPage>
  }

  function renderRequestsBanner() {
    if (empty || !company?.hasRequests) {
      return;
    }

    return <Info type='success' title={false} hAlign='left' layout='hbox'>You have outstanding activity requests!</Info>
  }

  function renderCourseGroups() {
    return <CourseTablesForGroups groups={company?.courseKindGroups} component={CompanyCoursesTable} />
  }
  
  function renderEmpty() {
    return <NonePanel description="Some schools will send you an activity request first - these will come in via email, so hang tight until they come in! Others will let you directly schedule classes with them. If that’s your setup, start scheduling activities now…"
      image={Instructor} add={showCreate ? createActivitiesButton : undefined} />
  }

  return render();
}
