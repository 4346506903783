import * as React from 'react';

import { TabStrip, Tabs, VBox, Button, Dropdown, ButtonStripButton } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function TabDemos() {
  return <Demos name='Tab'>
    <Demo name='TabStrip'>
      <TabStrip tabs={['Tab', 'A long tab']} />
    </Demo>

    <Demo name='TabStrip that scrolls'>
      <TabStrip width='300px' tabs={['Tab', 'A long tab', 'Another tab', 'The fourth', 'The fifth']} />
    </Demo>

    <Demo name='Tabs'>
      <Tabs tabs={[{
         label: 'Tab 1',
         content: <VBox hAlign='left'><Button>A button</Button>text</VBox>
      }, {
        label: 'Tab 2',
        content: <VBox hAlign='left'><Dropdown options={['abc', '123']} /></VBox>
     }]} />
    </Demo>

    <Demo name='Tabs that scroll'>
      <Tabs width='300px' height='300px' tabs={[{
         label: 'Tab 1',
         content: <VBox hAlign='left'><Button>A button</Button>text</VBox>
      }, {
        label: 'Tab 2',
        content: <VBox hAlign='left'><Dropdown options={['abc', '123']} /></VBox>
     }, { label: 'Tab 3', content: <span />},
     { label: 'Tab 3', content: <span />},
     { label: 'Tab 4', content: <span />},
     { label: 'Tab 5', content: <span />},
     { label: 'Tab 6', content: <span />},
     { label: 'Tab 7', content: <span />},
     { label: 'Tab 8', content: <span />}
     ]} />
    </Demo>

    <Demo name='Tabs that are links'>
      <Tabs width='300px' height='300px' tabs={[
     { label: 'Homeroom', to: 'https://homeroom.com'},
     { label: 'Google', to: 'https://google.com'},
     { label: 'Microsoft', to: 'https://microsoft.com'},
     ]} />
    </Demo>

    <Demo name='Tabs using buttons'>
      <Tabs component={ButtonStripButton} hItemSpace={0} tabs={[{
          label: 'Tab 1',
          content: <VBox hAlign='left'><Button>A button</Button>text</VBox>
        }, {
          label: 'Tab 2',
          content: <VBox hAlign='left'><Dropdown options={['abc', '123']} /></VBox>
      }]} />
    </Demo>
  </Demos>
}
