import { loadStripe } from '@stripe/stripe-js/pure';

import { STRIPE_PUBLIC_KEY } from './constants';

type StripeConstructor = new (publicKey: string) => any;
declare const Stripe: StripeConstructor;

let stripe:Promise<StripeConstructor>;

export function loadStripeHelper() {
  if (stripe) {
    return stripe;
  }

  loadStripe.setLoadParameters({advancedFraudSignals: false});
  stripe = new Stripe(STRIPE_PUBLIC_KEY as string);

  return stripe;
}
