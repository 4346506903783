import { UserPreferences } from '../shared/preferences';
import { CoursesTableWithTabsPreferences } from '../shared-authorized';

export const companyPreferencesKeys = {
  reportsSummary: 'reportsSummary',
  reportsTransactions: 'reportsTransactions',
};

export class CompanyPreferences extends UserPreferences {
  company:string;

  constructor(company:string) {
    super();

    this.company = company;
  }

  tablePreferences(type:string) {
    return new CoursesTableWithTabsPreferences(this, type);
  }

  get reportsSummary(): string {
    return this.load(companyPreferencesKeys.reportsSummary);
  }

  set reportsSummary(value: string) {
    this.save(companyPreferencesKeys.reportsSummary, value);
  }

  get reportsTransactions(): string {
    return this.load(companyPreferencesKeys.reportsTransactions);
  }

  set reportsTransactions(value: string) {
    this.save(companyPreferencesKeys.reportsTransactions, value);
  }

  get path():(string | number)[] {
    return super.path.concat(this.company);
  }
}

