import * as React from 'react';

import { Course } from 'app2/api';
import { FormContent, Panel, Section, EmailField, PhoneField } from 'app/app2/components';
import { useCourseFields } from 'app2/views';

import { ActivityForm } from '../ActivityForm';

interface Props {
  editing: boolean;
  form: ActivityForm;
  onOk: (form: ActivityForm) => Promise<boolean>;
}

export function ProviderInfo(props: Props) {
  const form = props.form;
  const course = form?.values as unknown as Course;
  const common = useCourseFields({site:form?.initialValues?.site?.id, course}).fields;

  return (
    <Panel type='display' form={form} onOk={props.onOk}>
      <FormContent icon="User" title="Provider information">
        <Section>
          <Section name='vendor.email' component={EmailField} readOnly />
          <Section name='vendor.phone' component={PhoneField} readOnly />
        </Section>
        <Section label="Cancellation policy" name="vendor.cancellationPolicy.text" readOnly />
      </FormContent>
      <FormContent icon="User" title="Instructor">
        <Section {...common.teacher} disabled={false} readOnly={common.teacher.disabled} />
        <Section>
          <Section name='teacher.email' component={EmailField} />
          <Section name='teacher.phone' component={PhoneField} />
        </Section>
      </FormContent>
    </Panel>
  );
}
