import * as React from 'react'

import { Box, BoxProps, getBgColorForNode, useRef } from 'app2/components';

// creates a scrollable area that has shadows at the edges

// unfortunately this technical for scroll shadows relies on knowing the current background
// color...else this would be a css class and not a component.  further there's no
// reliable way to get the background of a component (since by default if an element
// has no background, it will pick up background of the component behind it and window.getComputedStyle
// does not fetch that color).  this does it best to get the color by walking all the components
// at the mid point of this component and look for a non-transparent background color.  this
// will not get pick up image backgrounds.  further this will not watch for background color changes.
// it sticks to the first color it finds.

interface Props extends BoxProps {
  size:number;
}

export const ScrollShadow = React.forwardRef((props:Props, refProp:any) => {
  const {size, bg, ...remaining} = props;
  const ref = refProp || useRef<HTMLElement>();

  function render() {
    const bg = getBgColor();
    const css = {
      backgroundImage: `
        linear-gradient(to right, ${bg}, ${bg}),
        linear-gradient(to left, ${bg}, ${bg}),
        linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));`,
      backgroundPosition: 'left center, right center, left center, right center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${size}px 100%, ${size}px 100%, ${size/2}px 100%, ${size/2}px 100%`,
      backgroundAttachment: 'local, local, scroll, scroll'
    }

    return <Box ref={ref} css={css} {...remaining} />  
  }

  function getBgColor() {
    return React.useMemo(() => (bg as string) || getBgColorForNode(ref.current), [bg, ref.current]);
  }
  
  return render();
})

ScrollShadow.defaultProps = {
  size: 20
}