import Axios, { CancelTokenSource, CancelToken } from 'axios';
import { debounce } from 'lodash-es';

import { Observable, assert, cloneObservable } from 'app/observable';

export type { CancelToken };

// The purpose of ApiRequest is to provide a way to:
//  - await on the results of an api call
//  - access an error if there is one
//  - cancel a call
//  - know if a call is running/executing
//  - built in debouncing

// ApiRequest can be used as an object or a class
//  - Object - pass in a callback (to ApiRequest.create)
//    that invokes the desired API function and return
//    the data (not the axios response). The request
//    is executed immediately.
//
// - Class - extend ApiRequest and override doRequest.
//   Call execute to invoke the request.  Repeated
//   calls will result in previous ones being canceled.
//
//
// - cancelToken should be passed to axios calls (which
// takes it in the config parameter.

export type ApiFunction = (cancelToken?: CancelToken) => Promise<any>;

export class ApiRequest<T = any> extends Observable {
  static default = new ApiRequest(null);

  static create(
    apiFunction: ApiFunction,
    swallowErrors: boolean = false
  ): ApiRequest {
    const request = new ApiRequest(apiFunction, swallowErrors);
    request.execute();

    return request;
  }

  private _promise: Promise<T>;
  private _canceled: boolean;
  private _cancelToken: CancelTokenSource;
  private _executing: boolean;
  private _error: Error;
  private _swallowErrors: boolean;
  private _apiFunction?: ApiFunction;
  private _result: T;

  constructor(apiFunction?: ApiFunction, swallowErrors?: boolean) {
    super();

    this._apiFunction = apiFunction;
    this._swallowErrors = swallowErrors;
  }

  debouncedExecute() {
    if (this._executing) {
      this.cancel();
    }

    this._debouncedExecute();
  }

  private _debouncedExecute = debounce(() => {
    this.execute();
  }, 250);

  execute = async (): Promise<T> => {
    let result: T;

    try {
      if (this._executing) {
        this.cancel();
      }

      this._canceled = false;
      this._executing = true;
      this._cancelToken = Axios.CancelToken.source();
      this._promise = this.doRequest(this._cancelToken.token);

      this._result = await this._promise;

      this._error = null;
    } catch (error) {
      this._error = error;

      if (!this._swallowErrors) {
        throw error;
      }
    } finally {
      this._executing = false;
      this._cancelToken = null;
      this._promise = null;
    }

    return this._result;
  };

  cancel() {
    if (!this._cancelToken) {
      return;
    }

    if (this._canceled) {
      return;
    }

    const token = this._cancelToken;

    // the cancel call should resolve the promise so
    // there's no reason to resolve it here
    this._promise = null;
    this._executing = false;

    this._canceled = true;
    this._cancelToken = null;

    token.cancel();
  }

  get executing(): boolean {
    return this._executing;
  }

  get error(): Error {
    return this._error;
  }

  get promise(): Promise<T> {
    return this._promise;
  }

  get result(): T {
    return this._result;
  }

  // allows derived classes to override this
  // instead of passing it in as function
  protected doRequest(cancelToken: CancelToken): Promise<any> {
    return this._apiFunction(cancelToken);
  }
}

// Returns a function that when called will
// - return an immediate default value
// - once the request has completed successfully, the initial returned value
//   is updated such that anything observing it will get notified (except if the
//   return is null...the original value can not be converted to null.
// - use the helper methods getError, getPromise, isRequestable, loading/exectuing
//   for accessing request info
//
// Example usage:
//  const CompanyStore = makeDataRequestor({} as Company, async () => (await getCompaniesAPI()).data);
//    ... later ...
//  const company:Company = CompanyStore();
//  company // <- will get updated once the getCompaniesApi returns

const requestableSymbol = Symbol('_request');
export type Requestable<T = {}> = T & { [requestableSymbol]?: ApiRequest<T> };

export function makeRequestor<T>(
  initialValue: T,
  api: ApiFunction
): () => Requestable<T> {
  return function() {
    return makeRequestable(initialValue, api);
  };
}

// Makes an immediate API request and return a default value:
//  - that will be filled in with the result from the api call and updated observably
//  - use the helper methods getError, getPromise, isRequestable, loading/exectuing
//   for accessing request info
//
// Example usage:
//  const company:Company = makeDataRequest({} as Company, async () => (await getCompaniesAPI()).data);
//  company // <- will get updated once the getCompaniesApi returns

export function makeRequestable<T>(
  initialValue: T,
  api: ApiFunction
): Requestable<T> {
  const requestable: T & {
    [requestableSymbol]?: ApiRequest<T>;
  } = cloneObservable(initialValue);

  runRequest(requestable, api);

  return requestable;
}

export function updateRequestable<T>(
  requestable: Requestable<T>,
  api: ApiFunction
) {
  const request = getRequest(requestable);

  if (request) {
    request.cancel();
  }

  runRequest(requestable, api);
}

function runRequest<T>(requestable: Requestable<T>, api: ApiFunction) {
  //@ts-ignore
  requestable[requestableSymbol] = ApiRequest.create(async (): Promise<T> => {
    const apiResult: T = await api();

    if (Array.isArray(apiResult)) {
      assert(
        Array.isArray(requestable),
        `both the initial value (not array), and api result (array) must be of the same type`
      );
      const resultAsArray = (requestable as unknown) as any[];

      resultAsArray.splice(0, apiResult.length);
      resultAsArray.push(...apiResult);
    } else if (apiResult) {
      Object.assign(requestable, apiResult);
    }

    return requestable;
  }, true);
}

// helpers for ui that needs to display loading state and
// errors for both ApiRequest and Requestable that will
// take one or more of them and return the associated ApiRequests

export type Request = ApiRequest | Requestable;
export type Requests = Request[];

export function getRequest(request: Request): ApiRequest {
  if (!request) {
    return;
  }

  return requestableSymbol in request
    ? /*
      //@ts-ignore */
      request[requestableSymbol]
    : request instanceof ApiRequest
    ? request
    : null;
}

export function getRequests(
  oneOrManyRequests: Request | Requests
): ApiRequest[] {
  if (!oneOrManyRequests) {
    return [];
  }

  const requests: Requests = Array.isArray(oneOrManyRequests) && !getRequest(oneOrManyRequests as any)
    ? oneOrManyRequests
    : [oneOrManyRequests as Request];
  const apiRequests: ApiRequest[] = requests
    .filter(request => request != null)
    .map(
      //@ts-ignore
      request =>
        (request as Requestable)[requestableSymbol] || (request as ApiRequest)
    );

  return apiRequests;
}

export function executing(oneOrManyRequests: Request | Requests) {
  const requests = getRequests(oneOrManyRequests);
  return requests.filter(request => request.executing).length != 0;
}

export const loading = executing;

export function getPromise(request: Request) {
  const apiRequest = getRequest(request);
  return apiRequest ? apiRequest.promise : null;
}

export function getPromises(
  oneOrManyRequests: Request | Requests
): Promise<any>[] {
  return getRequests(oneOrManyRequests)
    .filter(r => r.promise != null)
    .map(r => r.promise);
}

export function getError(request: Request) {
  const apiRequest = getRequest(request);
  return apiRequest ? apiRequest.error : null;
}

export function getErrors(oneOrManyRequests: Request | Requests): Error[] {
  return getRequests(oneOrManyRequests)
    .map(r => r.error)
    .filter(e => e != null);
}

export function isRequestable(request: Request) {
  return request && requestableSymbol in request;
}
