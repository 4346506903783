import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteProvidersMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type DeleteProvidersMutation = { vendorsDelete?: { vendors?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const DeleteProvidersDocument = gql`
  mutation DeleteProviders($ids: [ID!]!) {
    vendorsDelete(ids: $ids) {
      vendors {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type DeleteProvidersOptions = ExecMutationOptions<DeleteProvidersMutationVariables>;

export function deleteProviders(options: DeleteProvidersOptions) {
  return executeMutation<DeleteProvidersMutation, DeleteProvidersMutationVariables>(DeleteProvidersDocument, options);
}
