import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { TeamParticipantsFragmentFragmentDoc } from './teamParticipantsFragment.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamParticipantsQueryVariables = Types.Exact<{
  entityKind: Types.Scalars['String'];
  entityId: Types.Scalars['ID'];
}>;

export type TeamParticipantsQuery = {
  entity?:
    | {
        id: string;
        teamParticipantsUpsert?: boolean | null | undefined;
        teamChangeRole?: boolean | null | undefined;
        teamSetAsContact?: boolean | null | undefined;
        userMemberRole?: Types.MemberRole | null | undefined;
        participants?:
          | Array<
              | {
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  isContact?: boolean | null | undefined;
                  isCurrentUser?: boolean | null | undefined;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  sentAt: string;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
            >
          | null
          | undefined;
      }
    | {
        id: string;
        teamParticipantsUpsert?: boolean | null | undefined;
        teamChangeRole?: boolean | null | undefined;
        teamSetAsContact?: boolean | null | undefined;
        userMemberRole?: Types.MemberRole | null | undefined;
        participants?:
          | Array<
              | {
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  isContact?: boolean | null | undefined;
                  isCurrentUser?: boolean | null | undefined;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  sentAt: string;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
            >
          | null
          | undefined;
      }
    | {
        id: string;
        teamParticipantsUpsert?: boolean | null | undefined;
        teamChangeRole?: boolean | null | undefined;
        teamSetAsContact?: boolean | null | undefined;
        userMemberRole?: Types.MemberRole | null | undefined;
        participants?:
          | Array<
              | {
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  isContact?: boolean | null | undefined;
                  isCurrentUser?: boolean | null | undefined;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
              | {
                  sentAt: string;
                  id: string;
                  kind: Types.ParticipantKind;
                  name?: string | null | undefined;
                  role: string;
                  relation?: string | null | undefined;
                  email?: string | null | undefined;
                  phone?: string | null | undefined;
                  attendanceCodeCode?: string | null | undefined;
                }
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const TeamParticipantsDocument = gql`
  query TeamParticipants($entityKind: String!, $entityId: ID!) {
    entity(entityKind: $entityKind, entityId: $entityId) {
      ...teamParticipantsFragment
    }
  }
  ${TeamParticipantsFragmentFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TeamParticipantsArgs = MakeOptional<UseQueryArgs<TeamParticipantsQueryVariables, TeamParticipantsQuery>, 'query'>;

export function useTeamParticipantsQuery(options: TeamParticipantsArgs = {}) {
  return useQuery<TeamParticipantsQueryVariables, TeamParticipantsQuery>({ query: TeamParticipantsDocument, ...options });
}

export type TeamParticipantsOptions = ExecQueryOptions<TeamParticipantsQueryVariables>;

export function teamParticipants(options: TeamParticipantsOptions) {
  return executeQuery<TeamParticipantsQuery, TeamParticipantsQueryVariables>(TeamParticipantsDocument, options);
}

export type TeamParticipantsSelections = TeamParticipantsQuery['entity'];
