import moment from 'moment'

import { DateInput } from './DateInput'

// returns an array of the dates inclusively between specified dates
export function datesBetween(start:DateInput, end:DateInput):moment.Moment[] {
  const days = [] as moment.Moment[];

  if (!start || !end || !moment(start).isValid() || !moment(end).isValid() || moment(start).isAfter(end)) {
    return days;
  }

  const day = moment(start).clone();
  
  while (true) {
    if (day.isAfter(end)) {
      break;
    }

    days.push(day.clone());

    if (day.isSame(end)) {
      break;
    }

    day.add(1, 'd');
  }

  return days;
}

