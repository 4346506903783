import * as React from 'react'

import { DropdownZindex, HBox, useScrollWatcher, useRef } from 'app2/components';

interface Props {
  size:number;
  onUpdate?:(strength:number) => void;
}

// similar in purpose to ScrollingShadow except this doesn't attach itself
// to the edges of the scrollable area.  it just goes whereever you position
// it and then is sticky while scrolling.

export function FloatingScrollShadow(props:Props) {
  const ref = useRef<HTMLElement>();
  const [scrollPercent, setScrollPercent] = React.useState(0);
  const scrollWatcher = useScrollWatcher(ref.current, {onScroll});

  // deal with the fact that onScroll is memoized but we want to call the most recent onUpdate
  const onUpdate = React.useRef(props.onUpdate);
  onUpdate.current = props.onUpdate;

  function render() {
    return <HBox ref={ref} zIndex={DropdownZindex + 1} width='100%' pointerEvents='none' height={`${props.size}px`} marginBottom={`-${props.size}px`} 
    background={`radial-gradient(ellipse at 50% 0%, rgba(6, 0, 6, ${.3 * scrollPercent}) 0%, rgba(255, 255, 255, 0) 60%)`} />  
  }

  function onScroll() {
    if (!ref.current || !scrollWatcher?.scroller) {
      return;
    }
    
    const top = Math.max(ref.current.getBoundingClientRect().top - scrollWatcher.scroller.scrollTop, 0);
    const percent = 1 - (Math.min(top, props.size) / props.size);
    setScrollPercent(percent);
    onUpdate.current?.(percent);
  }

  return render();
}
