import * as React from 'react';

import { FormModel, Panel, useForm } from 'app2/components';
import { ImmediatePayoutConfig, PausePayouts } from 'app2/views/shared';

import { useCurrentSite } from '../shared';

import { organizerUpdatePayoutConfig } from './gql';

export function PayoutConfig() {
  const { site } = useCurrentSite();
  const form = useForm({ payoutConfig: site?.payoutConfig }, [site]);

  function render() {
    if (!site?.hasSiteCompanies) {
      return <></>
    }

    return <Panel icon="DollarSign" title="Payouts" onOk={onOk} type="toggle" form={form}>
      <ImmediatePayoutConfig />
      <PausePayouts />
    </Panel>
    }
    
  async function onOk(form: FormModel) {
    const [success] = await organizerUpdatePayoutConfig({ variables: { siteId: site.id, payoutConfig: form.values.payoutConfig }, error:form });

    return success;
  }

  return render();
}
