import * as React from 'react';

import { HBox } from '../../Box';
import { Checkbox } from '../../Checkbox';

import { Selection } from '../Selection';
import { TopLeftHeaderRendererProps } from './TopLeftHeaderRendererProps';

export function TopLeftHeaderRenderer(props: TopLeftHeaderRendererProps) {
  return props.table.props.rowSelection ? renderCheckboxAndTopLeft(props) : renderTopLeft(props);
}

function renderCheckboxAndTopLeft(props: TopLeftHeaderRendererProps) {
  return <HBox paddingLeft="12px" vAlign="center" hAlign="center" bg="#E5E5E5" style={props.style}>
    {renderCheckbox(props)}
    {renderTopLeft(props)}
  </HBox>
}

export function renderTopLeft(props: TopLeftHeaderRendererProps, standalone = false) {
  const style = standalone ? props.style : { width: '100%', height: '100%' };

  return <HBox vAlign="center" hAlign="center" bg="#E5E5E5" width="100%" style={style}></HBox>
}

function renderCheckbox(props: TopLeftHeaderRendererProps) {
  const hide = props.table.numRows == 0;

  if (hide) {
    return '';
  }

  return <Checkbox onMouseDown={event => event.stopPropagation()} checked={props.table.selections.allSelected} onChange={event => {
      if (props.table.selections.allSelected) {
        props.table.selection = new Selection(props.table);
      }
      else {
        props.table.selection = props.table.selection.clone().selectAll();
      }

      props.table.focus();
    }}
  />
}