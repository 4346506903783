import * as React from 'react';

import { Button } from './Button';
import { BoxProps, HBox } from './Box';
import { Text } from './Text';

interface NumberStepperProps extends BoxProps {
  value?: number;
  onIncrement?: (event: React.MouseEvent) => void;
  onDecrement?: (event: React.MouseEvent) => void;
}

export const NumberStepper = React.forwardRef((props: NumberStepperProps, ref:any) => {
  const {value, onIncrement, onDecrement, ...remaining} = props;
  const [count, setCount] = React.useState(value || 0);

  function render() {
    return <HBox border='solid' borderRadius='standard' borderWidth='standard' borderColor='primary' vAlign='center' {...remaining}>
      <Button icon='Minus' onClick={props.onDecrement || handleDecrement} autoLoader borderRadius='0px' border='none' minHeight='36px' maxWidth='52px' borderTopLeftRadius='standard' borderBottomLeftRadius='standard'><span style={{display: 'none'}}>-</span></Button>
      <Text text='body' textAlign='center' flex={1}>{value !== undefined ? value : count}</Text>
      <Button icon='Plus' onClick={props.onIncrement || handleIncrement} autoLoader borderRadius='0px' border='none' minHeight='36px' maxWidth='52px' borderTopRightRadius='standard' borderBottomRightRadius='standard'><span style={{display: 'none'}}>+</span></Button>
    </HBox>
  }

  function handleDecrement() {
    setCount(count - 1);
  }

  function handleIncrement() {
    setCount(count + 1);
  }

  return render();
})

NumberStepper.displayName = 'NumberStepper';