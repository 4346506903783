import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BillNowMutationVariables = Types.Exact<{
  course: Types.Scalars['ID'];
}>;

export type BillNowMutation = { courseBillNow?: { success?: boolean | null | undefined } | null | undefined };

export const BillNowDocument = gql`
  mutation billNow($course: ID!) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    courseBillNow(course: $course) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type BillNowOptions = ExecMutationOptions<BillNowMutationVariables>;

export function billNow(options: BillNowOptions) {
  return executeMutation<BillNowMutation, BillNowMutationVariables>(BillNowDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['RosterItem', 'Enrollment']) }
  });
}
