import * as React from 'react';

import { observableManager } from '../../../observable/ObservableManager';

import { ReactFunctionObserver } from './ReactFunctionObserver';

type FunctionComponent = (props?: any) => React.ReactElement<any> | string;

export function useObservable<T extends FunctionComponent>(
  func: T
): React.ReactElement<any> {
  let result: React.ReactElement<any>;

  const [updater, setUpdater]: [
    ReactFunctionObserver,
    React.Dispatch<React.SetStateAction<any>>
  ] = React.useState({
    forceUpdate: function() {},
    counter: 0,
    initialUpdater: null
  });

  React.useEffect(() => {
    const initialUpdater = updater;

    initialUpdater.forceUpdate = function() {
      setUpdater({ counter: initialUpdater.counter + 1, initialUpdater });
    };

    return () => {
      observableManager.unsubscribeAll(initialUpdater);
    };
  }, [0]);

  try {
    observableManager.startCapturingDependencies(
      updater.initialUpdater || updater
    );

    //@ts-ignore
    result = func.apply(this, arguments);
  } finally {
    observableManager.stopCapturingDependencies(
      updater.initialUpdater || updater
    );
  }

  return result;
}
