import { Filter, FilterOperator, Sort, SortDirection } from 'app2/api';
import { ColPref, DataTable, DataTableColumn, DataTableColumnSort, ExpressionNode, Option, OptionValue, useStateWithDeps } from 'app2/components';

import { filterFieldName, sortFieldName } from '../fields';

import { GeneratedQueryArgs } from './HrDataTable';
import { GetStandardFilterOptionsQuery, getStandardFilterOptions } from './getStandardFilterOptions';

export type BaseQueryVarsV2 = {sort?:Sort[], filters?:Filter[]};

export function useSortFilterV2<T extends BaseQueryVarsV2 = any, P extends keyof T = any>(colDefs:DataTableColumn<T>[], queryOptions:GeneratedQueryArgs<T, P>, advancedFilter?:ExpressionNode, filterOptionsQuery?:GetStandardFilterOptionsQuery) {
  const [sortFilters, setSortFilters] = useStateWithDeps(() => buildSortFilter(colDefs, queryOptions, filterOptionsQuery), [colDefs, queryOptions?.variables, filterOptionsQuery]);

  function handleSortFilter(table:DataTable<T>, col:DataTableColumn<T>, sort:DataTableColumnSort, filter:OptionValue[]) {
    setSortFilters(buildSortFilter(table.allCols, queryOptions, filterOptionsQuery));
  }

  return { cols:sortFilters.cols, queryVars:{sorts:sortFilters.sorts, filters:sortFilters.filters, advancedFilter}, handleSortFilter }
}

export function buildSortFilter<T extends BaseQueryVarsV2 = any, P extends keyof T = any>(cols:DataTableColumn<T>[], queryOptions?:GeneratedQueryArgs<T, P>, filterOptionsQuery?:GetStandardFilterOptionsQuery) {
  return {cols:buildCols(cols, queryOptions, filterOptionsQuery), sorts: buildSorts(cols), filters: buildFilters(cols, queryOptions)}
}

export function buildCols<T extends BaseQueryVarsV2 = any, P extends keyof T = any>(cols:DataTableColumn<T>[], queryOptions:GeneratedQueryArgs<T>, filterOptionsQuery?:GetStandardFilterOptionsQuery) {
  return cols.map(c => filterOptionsQuery && c.filterable && !c.getFilterOptions ? {...c, getFilterOptions: getStandardFilterOptions(filterOptionsQuery, queryOptions.variables)} : c);
}

export function buildSorts<T = any>(cols:ColPref<T>[]) {
  return cols.filter(c => c.sort).map(c => ({name: sortFieldName(c), direction: c.sort == DataTableColumnSort.ascending ? SortDirection.Asc : SortDirection.Desc}));
}

export function buildFilters<T extends BaseQueryVarsV2 = any, P extends keyof T = any>(cols:DataTableColumn<T>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  const baseFilters = queryOptions?.variables?.filters || [];
  const colFilters = cols.filter(c => c.filter ?? false).map(c => ({name: filterFieldName(c), value: c.filter,  op: c.filterType == 'date-range' ? FilterOperator.Between : undefined})) as unknown as Filter[];

  // remove any col filters that appears in the base filters, because they override the base
  // but otherwise the base filters are always present
  const filters = baseFilters.filter(col => !colFilters.find(f => f.name == col.name)).concat(colFilters);

  return filters;
}

export type GetFilterOptionsPartial<T> = (table:DataTable<T>, col:DataTableColumn<T>) => Promise<Partial<Option>[]>
export type GetFilterOptions<T> = (table:DataTable<T>, col:DataTableColumn<T>) => Promise<Option[]>
