import * as React from 'react'
import { useParams } from 'react-router';

import { RepeatingSectionFieldProps, Panel, PanelProps, RepeatingSection } from 'app2/components';

import { AdminSiteRouteParams } from '../adminRoutes';

// convience class that will pull the id off the url
// pass it to the query you specify and show the results
// with the specified fields

export type QueryApiNoParams = (options:{variables:{}}) => any;
export type QueryApi = (options:{variables:{id:string}}) => any | QueryApiNoParams;

interface Props<T> extends Omit<PanelProps, 'type' | 'name'> {
  query:QueryApi;
  variables?:any;
  name:keyof T;
  fields:RepeatingSectionFieldProps<T>[];
  onQuery?:(item:T) => void;
}

export function QueryList<T = any>(props:Props<T>) {
  const {query, name, fields, onQuery, variables, ...remaining} = props;

  const { id } = useParams<AdminSiteRouteParams>();
  const [result] = variables ? query({variables}) : id ? query({variables: {id}}) : (query as QueryApiNoParams)({variables: {}});
  const item = React.useMemo(() => {
    // if the query result is an array of items then we wrap it in
    // an object of the specified name for the benefit of forms
    const data = Object.values(result?.data || {})[0] as T;
    return Array.isArray(data) ? {[name]:data} as unknown as T : data;
  }, [result?.data]);

  React.useMemo(() => onQuery?.(item), [item]);  

  function render() {
    return <Panel initialValues={item} type='display' onNavigation='nothing' {...remaining}>
      <RepeatingSection name={name} fields={fields} />
    </Panel>
  }

  return render();
}