import * as React from 'react'

import { Field } from 'app2/components';

import { CompanyLink, QueryList } from '../shared';

import { useAdminUserCompaniesQuery } from './generated'

export function Companies() {
  return <QueryList icon='Home' title='Providers' query={useAdminUserCompaniesQuery} name='companies' fields={[
    <Field name='name' label='Provider' component={CompanyLink} />,
  ]} />
}