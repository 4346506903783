import { Point } from './Point';

export interface RectInterface {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width?: number;
  height?: number;
}

export class Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;

  constructor();
  constructor(left: number, top: number);
  constructor(left: number, top: number, right: number, bottom: number);
  constructor(rect: RectInterface);
  constructor(rectOrLeft: RectInterface | number = undefined, top?: number, right?: number, bottom?: number) {
    if (rectOrLeft === undefined) {
      this.left = 0;
      this.top = 0;
      this.right = 0;
      this.bottom = 0;
    }
    else
    if (typeof rectOrLeft == 'number') {
      this.left = rectOrLeft;
      this.top = top;
      this.right = right !== undefined ? right : rectOrLeft;
      this.bottom = bottom !== undefined ? bottom : top;
    }
    else {
      this.left = rectOrLeft.left;
      this.top = rectOrLeft.top;
      this.right = rectOrLeft.right !== undefined ? rectOrLeft.right : this.left + rectOrLeft.width;
      this.bottom = rectOrLeft.bottom !== undefined ? rectOrLeft.bottom : this.top + rectOrLeft.height;
    }
  }

  clone() {
    return new Rect(this.left, this.top, this.right, this.bottom);
  }

  get topLeft(): Point {
    return new Point(this.left, this.top);
  }

  get topRight(): Point {
    return new Point(this.right, this.top);
  }

  get bottomLeft(): Point {
    return new Point(this.left, this.bottom);
  }

  get bottomRight(): Point {
    return new Point(this.right, this.bottom);
  }

  set bottomRight(pt: Point) {
    this.right = pt.x;
    this.bottom = pt.y;
  }

  get width() {
    return this.right - this.left;
  }

  set width(value: number) {
    this.right = this.left + value;
  }

  get middleX() {
    return this.left + this.width / 2;
  }

  get height() {
    return this.bottom - this.top;
  }

  set height(value: number) {
    this.bottom = this.top + value;
  }

  get middleY() {
    return this.top + this.height / 2;
  }

  get empty() {
    return this.width <= 0 || this.height <= 0;
  }

  equals(r:RectInterface) {
    return this.left == r.left && this.top == r.top && this.right == r.right && this.bottom == r.bottom;
  }

  moveTo(x: number, y: number) {
    this.right = x + this.width;
    this.bottom = y + this.height;
    this.left = x;
    this.top = y;

    return this;
  }

  offset(x: number, y: number) {
    this.left += x;
    this.top += y;
    this.right += x;
    this.bottom += y;

    return this;
  }

  inflate(x: number, y: number, x2: number = undefined, y2: number = undefined) {
    this.left -= x;
    this.top -= y;
    this.right += x2 === undefined ? x : x2;
    this.bottom += y2 === undefined ? y : y2;

    return this;
  }

  add(size:Point):Rect;
  add(widthOrSize: number | Point, height?: number) {
    if (typeof widthOrSize === 'object') {
      this.right += widthOrSize.x;
      this.bottom += widthOrSize.y;
    }
    else {
      this.right += widthOrSize;
      this.bottom += height;
    }

    return this;
  }

  overlaps(r:RectInterface):boolean;
  overlaps(left: number, top?: number, right?: number, bottom?: number):boolean;
  overlaps(left: number | RectInterface, top?: number, right?: number, bottom?: number):boolean {
    return !this.intersection(left as any, top, right, bottom).empty;
  }

  intersection(r:RectInterface):Rect;
  intersection(left: number, top?: number, right?: number, bottom?: number):Rect;
  intersection(left: number | RectInterface, top?: number, right?: number, bottom?: number):Rect {
    if (typeof left == 'object') {
      const r = left;
      left = r.left;
      top = r.top;
      right = r.right;
      bottom = r.bottom;
    }

    return new Rect(Math.max(this.left, left as number), Math.max(this.top, top), Math.min(this.right, right), Math.min(this.bottom, bottom));
  }

  contains(pt: Point): boolean;
  contains(r: Rect): boolean;
  contains(x: number, y: number): boolean;
  contains(x: number | Rect | Point, y: number = undefined): boolean {
    if (typeof x === 'number') {
      return this.containsPoint(new Point(x, y));
    }
    else
    if (x instanceof Point) {
      return this.containsPoint(x);
    }
    else {
      return this.containsPoint(x.topLeft) && this.containsPoint(x.bottomRight);
    }
  }

  containsPoint(pt: Point): boolean {
    return this._containsX(pt.x) && this._containsY(pt.y);
  }

  containsX(pt: Point): boolean;
  containsX(r: Rect): boolean;
  containsX(r: number): boolean;
  containsX(x: number | Rect | Point): boolean {
    if (typeof x === 'number') {
      return this._containsX(x);
    } else if (x instanceof Point) {
      return this._containsX(x.x);
    } else {
      return this._containsX(x.topLeft.x) && this._containsX(x.bottomRight.x);
    }
  }

  containsY(pt: Point): boolean;
  containsY(r: Rect): boolean;
  containsY(y: number | Rect | Point): boolean {
    if (typeof y === 'number') {
      return this._containsY(y);
    } else if (y instanceof Point) {
      return this._containsY(y.y);
    } else {
      return this._containsY(y.topLeft.y) && this._containsY(y.bottomRight.y);
    }
  }

  private _containsX(x: number): boolean {
    return x >= this.left && x <= this.right;
  }

  private _containsY(y: number): boolean {
    return y >= this.top && y <= this.bottom;
  }
}

export type RectGetSet = (r: Rect, value?: number) => number;

export function left(r: Rect, value: number = undefined) {
  if (value !== undefined) {
    r.left = value;
  }

  return r.left;
}

export function top(r: Rect, value: number = undefined) {
  if (value !== undefined) {
    r.top = value;
  }

  return r.top;
}

export function right(r: Rect, value: number = undefined) {
  if (value !== undefined) {
    r.right = value;
  }

  return r.right;
}

export function bottom(r: Rect, value: number = undefined) {
  if (value !== undefined) {
    r.bottom = value;
  }

  return r.bottom;
}