import * as React from 'react'
import shallowEqual from 'shallowequal';

import { Rect, Point } from '../dom-utils';

import { VirtualGrid, VirtualGridUpdateType, CellInfo, getCellCoordinates, computeTotal } from './VirtualGrid'
import { VirtualGridFactory } from './VirtualGridFactory'
import { TableSectionFactory } from './TableSectionFactory';
import { MeasuredHeightFactory, MeasuredHeightFactoryHelper } from './MeasuredHeightFactory';
import { TableSection, TableSectionType } from './TableSection';

// VirtualTable differs from VirtualGrid in that it allows for locked/frozen/sticky
// headers.  It achieves this by dividing up the source grid into 4 sections, using 
// TableSectionFactory to achive this.  Visually the dividing up the grid looks like:
// | top left    | top midddle
// ---------------------------
// | middle left | middle middle

// It then creates a VirtualGrid for each section.
// Currently it does not support locked footers, but this could be added by adding
// an additional 5 sections (2 to the right and then a new row) (9 total).  

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  headerRows?:number;
  headerCols?:number;
  factory?:VirtualGridFactory;
  measuredRows?:boolean;
  stickyOffset?:Point;
  renderSection?:(section:TableSection, style:any, children:React.ReactElement) => React.ReactElement;
  onDimensionsChanged?:() => void;
}

export class VirtualTable extends React.Component<Props> {
  element = React.createRef<HTMLDivElement>();

  factory:VirtualGridFactory;
  topLeftDimensions:Point;
  dimensions:Point;

  topLeft:TableSectionFactory;
  topLeftRef = React.createRef<VirtualGrid>();

  topMiddle:TableSectionFactory;
  topMiddleRef = React.createRef<VirtualGrid>();

  middleLeft:TableSectionFactory;
  middleLeftRef = React.createRef<VirtualGrid>();

  middleMiddle:TableSectionFactory;
  middleMiddleRef = React.createRef<VirtualGrid>();

  constructor(props:Props) {
    super(props);
    this.updateFactories(props);
  }

  get renderedRowCols() {
    return this.middleMiddleRef.current.renderedRowCols;
  }

  componentWillReceiveProps(newProps:Props) {
    // only should compare relevant props, inherited props that change (such as style) shouldn't cause a need to create a new factory
    function getProps(props:Props) {
      const {headerRows, headerCols, factory, measuredRows} = props;
      return {headerRows, headerCols, factory, measuredRows};
    }

    if (!shallowEqual(getProps(this.props), getProps(newProps))) {
      this.updateFactories(newProps);
    }
  }

  updateFactories(props:Props) {
    const {headerRows, headerCols} = props;
    const factory = this.factory = props.measuredRows && !(props.factory as unknown as MeasuredHeightFactoryHelper).measureRow ? new MeasuredHeightFactory(props.factory) : props.factory;

    this.topLeft = new TableSectionFactory(factory, 0, 0, headerRows, headerCols);
    this.topMiddle = new TableSectionFactory(factory, 0, headerCols, headerRows, -(headerCols + 1));
    this.middleLeft = new TableSectionFactory(factory, headerRows, 0, -(headerRows + 1), headerCols);
    this.middleMiddle = new TableSectionFactory(factory, headerRows, headerCols, -(headerRows + 1), -(headerCols + 1));
    this.updateDimensions();
  }

  updateDimensions() {
    const topLeft = computeTotal(this.topLeft);
    const middleMiddle = computeTotal(this.middleMiddle);

    this.topLeftDimensions = topLeft;
    this.dimensions = new Point(topLeft.width + middleMiddle.width, topLeft.height + middleMiddle.height);
  }

  // to be called by user of this component when they've caused a changed
  // to the content that requires remeasuring rows or a relayout
  invalidate(row:number = undefined, col:number = undefined, type:VirtualGridUpdateType = VirtualGridUpdateType.resize) {
    // there's a single measuring factory for all sections
    // because we need to measure entire rows at a time 
    // to get consistent heights (which wouldn't if we allowed
    // each section to measure itself individually)
    
    if (this.measuringFactory) {
      this.measuringFactory.reset(row);
    }

    this.topLeft.invalidateGrid(this.topLeftRef.current, row, col, type);
    this.topMiddle.invalidateGrid(this.topMiddleRef.current, row, col, type);
    this.middleLeft.invalidateGrid(this.middleLeftRef.current, row, col, type);
    this.middleMiddle.invalidateGrid(this.middleMiddleRef.current, row, col, type);
    this.deferredUpdate();
  }

  // called by a table section (a grid) when a row was measured, causing
  // the dimensions of the grid to change.
  onDimensionsChanged = () => {
    this.updateDimensions();

    if (this.props.onDimensionsChanged) {
      this.props.onDimensionsChanged();
    }
    
    // force a render because our dimensions changed
    this.deferredUpdate();
  }

  render() {
    this.clearDeferredUpdate();

    const {headerRows, headerCols, factory, measuredRows, stickyOffset, renderSection: layers, onDimensionsChanged, ...remaining} = this.props;
    const stickyOrigin = stickyOffset || new Point(0, 0);

    // note that 'width: this.dimensions.width' is currently needed to avoid the browser wrapping horizontally
    // after scrolling horizontally past the first page...which happens despire the cells being absolutely positioned
    const style = Object.assign({}, this.props.style, {position:'relative', width: this.dimensions.width + 'px', outline: 'none'});

    // if the parent is positioned, sticky seems to ignore that, so we need to add that to our positioning
    // oddly it doesn't seem to do that to top
    const left = this.element.current?.offsetLeft || 0;

    // sticky seems to ignore top we need to set a negative top margin to get things to align properly
    // oddly it doesn't do this left

    // locked rows are zindex 21 to cover locked columns for when locked columns have overflowed row borders that extend out into non-header areas
    return<div ref={this.element} {...remaining} style={style}>
      {this.measuringFactory ? this.measuringFactory.renderMeasurer() : ''}
      {this.renderSection(TableSectionType.topLeft, this.topLeftRef, this.topLeft, false, false, {zIndex: 30, position: 'sticky', left: stickyOrigin.x, top: stickyOrigin.y}, new Point())}
      {this.renderSection(TableSectionType.topMiddle, this.topMiddleRef, this.topMiddle, true, false, {zIndex: 21, position: 'sticky', marginLeft: (this.topLeftDimensions.width + left) + 'px', top: stickyOrigin.y, marginTop: (-this.topLeftDimensions.height) + 'px'}, new Point(this.topLeftDimensions.width, 0))}
      {this.renderSection(TableSectionType.middleLeft, this.middleLeftRef, this.middleLeft, false, true, {zIndex: 20, position: 'sticky', left: stickyOrigin.x, top: this.topLeftDimensions.height + 'px'}, new Point(0, this.topLeftDimensions.height))}
      {this.renderSection(TableSectionType.middleMiddle, this.middleMiddleRef, this.middleMiddle, true, true, { position: 'absolute', left: this.topLeftDimensions.width + 'px', top: this.topLeftDimensions.height + 'px'}, new Point(this.topLeftDimensions.width, this.topLeftDimensions.height))}
    </div>
  }

  renderSection(type:TableSectionType, ref:React.RefObject<VirtualGrid>, factory:TableSectionFactory, virtualizedX:boolean, virtualizedY:boolean, style:any, offset:Point) {
    if (factory.numCols == 0 || factory.numRows == 0) {
      return;
    }

    const section:React.ReactElement = <VirtualGrid ref={ref} measuredRows={this.props.measuredRows} onDimensionsChanged={this.onDimensionsChanged} factory={factory} virtualizedX={virtualizedX} virtualizedY={virtualizedY} style={style} />;
    const grids = [
      this.topLeftRef,
      this.topMiddleRef,
      this.middleLeftRef,
      this.middleMiddleRef
    ];
    const grid = grids[type].current;
    const coords = new Rect(offset.x, offset.y, offset.x + grid?.totalWidth, offset.y + grid?.totalHeight);

    return this.props.renderSection?.({type, factory, coords}, {...style, width: coords.width || 0, height: coords.height || 0}, section) || section;
  }

  deferredUpdateTimeout:any;

  deferredUpdate() {
    this.clearDeferredUpdate();

    this.deferredUpdateTimeout = setTimeout(() => {
      this.deferredUpdateTimeout = null;

      if (this.element.current) {
        this.forceUpdate();
      }
    }, 100);
  }

  clearDeferredUpdate() {
    if (this.deferredUpdateTimeout) {
      clearTimeout(this.deferredUpdateTimeout);
    }
  }

  get measuringFactory() {
    return this.props.measuredRows ? this.factory as unknown as MeasuredHeightFactoryHelper : null;
  }

  get visibleTopLeft() {
    return this.middleMiddleRef.current.visibleTopLeft;
  }

  getCellFromEvent(event:React.MouseEvent<HTMLElement> | MouseEvent):CellInfo {
    const {headerRows, headerCols} = this.props;
    let cell:CellInfo;

    cell = this.topLeftRef.current?.getCellFromEvent?.(event);

    if (cell) {
      return cell;
    }

    cell = this.topMiddleRef.current?.getCellFromEvent?.(event);

    if (cell) {
      cell.pos = cell.pos.offset(headerCols, 0);
      return cell;
    }

    cell = this.middleLeftRef.current?.getCellFromEvent?.(event);

    if (cell) {
      cell.pos = cell.pos.offset(0, headerRows);
      return cell;
    }

    cell = this.middleMiddleRef.current?.getCellFromEvent?.(event);

    if (cell) {
      cell.pos = cell.pos.offset(headerCols, headerRows);
      return cell;
    }

    return null;
  }

  getCellCoordinates(cell:Point):Rect {
    return getCellCoordinates(this.factory, cell);
  }
}
