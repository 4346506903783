import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveStudentLogEntryMutationVariables = Types.Exact<{
  logEntry: Types.Scalars['ID'];
}>;

export type RemoveStudentLogEntryMutation = { studentRemoveLogEntry?: { success?: boolean | null | undefined } | null | undefined };

export const RemoveStudentLogEntryDocument = gql`
  mutation RemoveStudentLogEntry($logEntry: ID!) @urql(additionalTypenames: ["Student"]) {
    studentRemoveLogEntry(logEntry: $logEntry) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RemoveStudentLogEntryOptions = ExecMutationOptions<RemoveStudentLogEntryMutationVariables>;

export function removeStudentLogEntry(options: RemoveStudentLogEntryOptions) {
  return executeMutation<RemoveStudentLogEntryMutation, RemoveStudentLogEntryMutationVariables>(RemoveStudentLogEntryDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Student']) }
  });
}
