import * as React from 'react'

import { CourseKind, CourseUtils, DeepPartial, Course } from 'app2/api';
import { BoxProps, defaultFieldProps, DropdownField, FieldInfo, FieldValue, useFormInfo } from 'app2/components'
import { CourseLink } from 'app2/views/shared-public'

import { nonCancelledStatuses } from './useCourseFields';
import { CoursePicker } from './CoursePicker';
import { CoursesQueryVars } from './CoursesQueryVars';

interface Props extends BoxProps {
  info:FieldInfo<DeepPartial<Course>>;
  queryVars?:CoursesQueryVars;
}

export function TeamPicker(props:Props) {
  const {info, queryVars, ...remaining} = props;
  const formInfo = useFormInfo();

  const game = info.values[0] as Course;
  const team = info.values[1] as Course;

  function render() {
    return formInfo?.cellEditor
      ? renderEdit()
      : team?.userSiteRole || team?.userCompanyRole || team?.userCourseRole
        ? renderDisplayLink()
        : renderDisplay()
  }

  function renderDisplay() {
    return <FieldValue>{team?.name}</FieldValue>
  }

  function renderDisplayLink() {
    return <CourseLink course={team} display='inline!important' />
  }

  function renderEdit() {
    const queryVars = { seasonId: game.season.id, statuses: nonCancelledStatuses, kinds: [CourseKind.Team] };
    return <CoursePicker valueType='object' queryVars={queryVars} {...remaining} />
  }

  return render();
}

TeamPicker.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info',
}

export const TeamField = {
  display: TeamPicker,
  edit: { ...DropdownField.edit, component: TeamPicker },
  onChange: onTeamChange,
  none: false
}

function onTeamChange(team:Course, info:FieldInfo<Course>, _:boolean, prevValue:Course) {
  const prevHome = info.name == 'homeTeam' ? prevValue : info.values[0].homeTeam;
  const prevAway = info.name == 'homeTeam' ? info.values[0].awayTeam : prevValue;
  const prevGeneratedName = CourseUtils.generatedGameName({homeTeam:prevHome, awayTeam:prevAway});
  const existingName = info.form.getValue([], 'name');

  if (existingName?.length && existingName != prevGeneratedName) {
    return;
  }

  const curHome = info.name == 'homeTeam' ? team : info.values[0].homeTeam;
  const curAway = info.name == 'homeTeam' ? info.values[0].awayTeam : team;

  const newName = CourseUtils.generatedGameName({homeTeam:curHome, awayTeam:curAway});

  info.form.setValue([], 'name', newName);
}
