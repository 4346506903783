import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdatePersonalInfoMutationVariables = Types.Exact<{
  personalInfo: Types.UserInput;
}>;

export type UpdatePersonalInfoMutation = { userUpdate?: { user?: { id: string } | null | undefined } | null | undefined };

export const UpdatePersonalInfoDocument = gql`
  mutation UpdatePersonalInfo($personalInfo: UserInput!) {
    userUpdate(userInfo: $personalInfo) {
      user {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdatePersonalInfoOptions = ExecMutationOptions<UpdatePersonalInfoMutationVariables>;

export function updatePersonalInfo(options: UpdatePersonalInfoOptions) {
  return executeMutation<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>(UpdatePersonalInfoDocument, options);
}
