import * as React from 'react'

import { DiscountFormat, PriceConfigKind } from 'app2/api';
import { Field } from 'app2/components'
import { EnrollmentConfig } from 'app2/views/shared-public'

import { RateCombo, RateComboOption } from '../../../../currency';

import { EligibleDiscountPicker } from './EligibleDiscountPicker';

interface Props {
  config?:EnrollmentConfig;
  course?:string;
  enrollments?:string[];
}

export function EnrollmentDiscountCombo(props:Props) {
  function render() {
    const options = getOptions();
    return <RateCombo typeFieldName='format' valueFieldName='rate' fixedValue={DiscountFormat.Number} percentageValue={DiscountFormat.Percentage} options={options} />
  }

  function getOptions() {
    return React.useMemo(() => {
      const usage = props.config?.kind == PriceConfigKind.Usage;
      const options = usage ? withoutFixed : withFixed;

      return options.concat({label: 'Discount code', value: '', field: renderDiscountDropdown()});
    }, [props.config?.kind, props.course, props.enrollments]);
  }

  function renderDiscountDropdown() {
    return <Field name='discount' edit={{component: <EligibleDiscountPicker config={props.config} enrollments={props.enrollments} course={props.course} />}} />;
  }

  return render();
}

export const withFixed:RateComboOption[] = ['none', 'fixed', 'percentage'];
export const withoutFixed:RateComboOption[] = ['none', 'percentage'];
