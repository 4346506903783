import * as React from 'react';
import { useParams } from 'react-router';

import { DataTableColumn } from 'app2/components';
import { GenericReportTable } from 'app2/views/shared';

import { ReportRouteParams } from '..';

import { OrganizerComplianceReportQuery, useOrganizerComplianceReportQuery } from './gql';

export function ComplianceReportTable() {
  const none = 'Please add items to your compliance checklist in the General Info tab in order to generate a compliance report';
  const {site} = useParams<ReportRouteParams>();

  return <GenericReportTable title='Compliance' query={useOrganizerComplianceReportQuery} queryOptions={{ variables:{ siteId: site }}} 
    queryResponseHandler={(response) => ({items: response?.data?.site?.complianceReport})} resultType='siteComplianceReport' none={none}
    cols={cols} colsVersion='2' colsPreferenceName='reportsCompliance' csvUrl={`sites/${site}/compliance_requirements/report`} />
}

type ComplianceColumn = OrganizerComplianceReportQuery['site']['complianceReport'][0];

const cols:Array<DataTableColumn<ComplianceColumn>> = [
  {
    label: 'Type',
    name: 'type'
  }, {
    label: 'Provider',
    name: 'provider'
  }, {
    label: 'Instructor',
    name: 'instructor'
  }, {
    label: 'Requirement',
    name: 'requirement'
  }, {
    label: 'Status',
    name: 'status'
  }, {
    label: 'Files',
    name: 'files'
  }
];
