import * as React from 'react';
import { useParams } from 'react-router';

import { EntityKind } from 'app2/api';
import { Button, EmailField, Field, FieldRendererProps, FormModel, Modal, Form, Section } from 'app2/components';

import { AdminSiteRouteParams } from '..';
import { QueryApi, UserLink, QueryList, UserDropdown } from '.';

interface Props {
  query:QueryApi;
  addTeamMember?:(entity:string, user:string) => Promise<[boolean, any, any]>;
  removeTeamMember?:(entity:string, user:string) => Promise<[boolean, any, any]>;
}

export function Team(props:Props) {
  const { id } = useParams<AdminSiteRouteParams>();

  function render() {
    return <QueryList icon='Home' title='Team' query={props.query} name='teamMembers' primaryActions={renderAdd()} fields={[
      <Field name='name' label='Name' component={UserLink} />,
      <Field name='email' label='Email' component={EmailField} />,
      <Field name='role' label='Role' />,
      <Field name="id" render={(props: FieldRendererProps) => <Button kind="tertiary" icon="Trash2" autoLoader onClick={event => onRemove(props.info.record.id)} />} />
    ]} />
  }

  function renderAdd() {
    if (!props.addTeamMember) {
      return;
    }

    return <Button onClick={renderAddModal()}>Add user</Button>;
  }    

  function renderAddModal() {
    return <Modal title='Add team member'>
      <Form editing onOk={onOk}>
        <Section name='user' label='User' component={UserDropdown} />
      </Form>
    </Modal>
  }

  async function onOk(form:FormModel<{user:{id:string}}>) {
    const [success] = await props.addTeamMember(id, form.values.user.id)

    return success;
  }

  async function onRemove(memberId:string) {
    if (!props.removeTeamMember) {
      return;
    }

    const [success] = await props.removeTeamMember(id, memberId)

    return success;
  }


  return render();
}
