import { useHistory } from 'react-router-dom';

import { useLifecycle } from 'app2/components';

import { useBaseMessageUrl } from './useBaseMessageUrl';
import { useThreadId } from './useThreadId';
import { MessageThreadsSelections } from './generated'

export function useNavigateToFirstMessage(inline:boolean, threads:MessageThreadsSelections[]) {
  const { scoped, id, threadId, threadScope } = useThreadId();

  const baseUrl = useBaseMessageUrl();

  const history = useHistory();
  useLifecycle({onUpdate})

  function onUpdate() {
    if (!inline || !threads?.length || (scoped && threads[0].id.indexOf('-') == -1)) {
      return;
    }

    if (!threadId || (scoped && !threadScope)) {
      if (!scoped && threads[0].broadcast) {
        return;
      }

      history.replace(`${baseUrl}/messages/${threads[0].id}`);
    }
    else
    if (threadId && !threads.find(t => t.id == id || t.id == threadId)) {
      history.replace(`${baseUrl}/messages`);
    }
  }
}
