import * as React from 'react';

import { TableFilter, TableSort } from './useSortFilterV1';
import { GeneratedQueryArgs } from './HrDataTable';

export function useMergeSortFilterParams(tableSort:TableSort, tableFilters:TableFilter, queryOptions:GeneratedQueryArgs) {
  return React.useMemo(() => {
    let sort = tableSort;
    let filters = tableFilters;

    if (queryOptions?.variables?.sort) {
      sort = {...sort, ...queryOptions.variables.sort};
    }
  
    if (queryOptions?.variables?.filters) {
      filters = {...filters, ...queryOptions.variables.filters};
    }

    return { sort, filters };
    }, [tableSort, tableFilters, queryOptions.variables.sort, queryOptions.variables.filters]);
}

