import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MoveEnrollmentsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  sourceCourseId: Types.Scalars['ID'];
  destinationCourseId: Types.Scalars['ID'];
}>;

export type MoveEnrollmentsMutation = { enrollmentsMove?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const MoveEnrollmentsDocument = gql`
  mutation MoveEnrollments($ids: [ID!]!, $sourceCourseId: ID!, $destinationCourseId: ID!) {
    enrollmentsMove(ids: $ids, sourceCourseId: $sourceCourseId, destinationCourseId: $destinationCourseId) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MoveEnrollmentsOptions = ExecMutationOptions<MoveEnrollmentsMutationVariables>;

export function moveEnrollments(options: MoveEnrollmentsOptions) {
  return executeMutation<MoveEnrollmentsMutation, MoveEnrollmentsMutationVariables>(MoveEnrollmentsDocument, options);
}
