import * as React from 'react'

import { InputProps } from './Input'
import { RestrictedInput } from './RestrictedInput';
import { parsePhone, formatPhone, isPhoneLike } from './form';

export const PhoneInput = React.forwardRef((props:InputProps, ref:any) => {
  return <RestrictedInput parse={parsePhone} format={formatPhone} valid={isPhoneLike} formatValue placeholder='(555) 555-1212' {...props} />
})

PhoneInput.displayName = 'PhoneInput';
