import * as React from 'react'

import { MenuDropdown, MenuDropdownProps } from './MenuDropdown';

export function OptionsMenu(props:MenuDropdownProps) {
  return <MenuDropdown {...props} />;
}

OptionsMenu.defaultProps = {
  icon: 'Menu',
  hAlign: 'right'
}

OptionsMenu.displayName = 'OptionsMenu';