import * as React from 'react'
import { useParams } from 'react-router'

import { Panel, Field, hasNewId, FieldRendererProps, FieldError, FormModel, RepeatingSection, InputField, useForm, VBox } from 'app2/components'
import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerClassroomsQuery } from './gql'
import { organizerUpsertClassrooms, OrganizerUpsertClassroomsMutationVariables } from './gql'

export function Teachers() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ classroomsQueryResult ] = useOrganizerClassroomsQuery({variables: { siteId }});
  const site = classroomsQueryResult.data?.site;
  const classrooms = site?.classrooms
  const form = useForm({ classrooms: classrooms?.map(c => ({...c, archived: false})) }, [classrooms]);

  function render() {
    return <Panel icon='FileText' title='Classrooms' subtitle='Set up your school-day classrooms to keep track of where students will be coming from before your afterschool program'
      type='toggle' form={form} onOk={onOk}>
      <RepeatingSection name='classrooms' add='Add teacher' onRemove='archived' fields={[
        <Field label='Classroom' name='name' required render={renderTeacher} component={InputField} />,
        <Field label='Grade' name='grade' required component={InputField} />,
        'remove'
      ]} />
    </Panel>
  }
  
  function renderTeacher(props:FieldRendererProps<typeof classrooms[0]>) {
    const original = classrooms.find(c => c.id == props.info.record.id);

    if (!props.editing || hasNewId(props.info.record) || original.name == props.info.record.name) {
      return <VBox>{props.children}</VBox>;
    }

    return <VBox>{props.children}<FieldError type='warning' error='Renaming a classroom renames it for all students assigned to the classroom.  If you want to remove this as choice for new students, delete this classrom and add a new one.'/></VBox>;
  }

  async function onOk() {
    const variables: OrganizerUpsertClassroomsMutationVariables = { siteId: site.id, classrooms: form.values.classrooms.map(c => ({...c, archived: c.archived || false})) };
    const [success] = await organizerUpsertClassrooms({variables, successMsg: "Successfully saved changes to the classrooms.", error: form})
    return success;
  }

  return render();
}
