import { capitalize, startCase } from 'lodash-es';

import { FieldValidator, FieldInfo, ValidatorResult } from './FormData';

// basic required field validation
// can be assigned as a validator by itself (e.g. fieldValidators: {firstName: required}), and it will attempt to create a display name from the field name
// can be called to create a required validator with a custom display name (e.g. fieldValidators: {firstName: required('First name is reqiured')})
export function required<T, P extends keyof T>(
  message: string
): FieldValidator<T, P>;
export function required<T, P extends keyof T>(
  value: any,
  info: FieldInfo<any, any>
): ValidatorResult;
export function required<T, P extends keyof T>(
  value: string,
  info?: FieldInfo<any, any>
): ValidatorResult | FieldValidator<T, P> {
  return info
    ? validateRequired(undefined, value, info)
    : customRequired(value as string);
}

function customRequired<T, P extends keyof T>(
  message?: string
): FieldValidator<T, P> {
  return function(value: T[P], info: FieldInfo<T, P>) {
    return validateRequired(message, value, info);
  };
}

function validateRequired(
  message: string,
  value: any,
  info: FieldInfo<any, any>
): ValidatorResult {
  const missing =
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length == 0);
  return missing
    ? message && typeof message == 'string'
      ? message
      : `${capitalize(startCase(String(info.fieldName)))} is required`
    : true;
}

export function numberRequired(value: any, info: FieldInfo<any, any>) {
  return isFinite(value) || 'Must be number';
}

export function greaterThanZero(value: any, info: FieldInfo<any, any>) {
  return value > 0 || 'Must be greater than 0';
}

export function mustBePositive(value: any, info: FieldInfo<any, any>) {
  return value >= 0 || 'Must be a positive number';
}
