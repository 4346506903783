import moment from 'moment'
import * as React from 'react'
import { useHistory } from 'react-router'

import { Panel, Section, DropdownField, ErrorWithPath, FormModel } from 'app2/components'

import { BaseAcceptInvite } from '../../shared'

import { courseInviteAccept, CourseInviteAcceptMutation } from './generated';

type Companies = CourseInviteAcceptMutation['courseInviteAccept']['companies'];

export function CourseAcceptInvite() {
  const [info, setInfo] = React.useState<{message:string, companies?:Companies}>({message:getMessage()});
  const [company, setCompany] = React.useState<string>();

  function render() {
    return <BaseAcceptInvite title='Activity invite' message={info.message} variables={{company}} mutation={courseInviteAccept} onSuccess={onSuccess} onFailure={onFailure}>
      {info.companies && renderCompanies()}
    </BaseAcceptInvite>
  }

  function renderCompanies() {
    return <Panel title='Select a company' type='edit' onOk={onChooseCompany} ok='Submit' onNavigation='nothing'>
      <Section label='Choose which provider to associate with this activity request' name='company' component={DropdownField} options={info.companies.map(c => ({label: c.name, value: c.id}))} />
    </Panel>
  }

  function onChooseCompany(form:FormModel) {
    setCompany(form.values.company);
  }

  function onFailure(inviteResponse?:CourseInviteAcceptMutation, errors?:ErrorWithPath[]) {
    setInfo({message:getMessage(inviteResponse), companies:inviteResponse?.courseInviteAccept?.companies});
  }

  function getMessage(inviteResponse?:CourseInviteAcceptMutation) {
    const site = inviteResponse?.courseInviteAccept?.site?.name;
    const who = site ? `${site} has invited you` : "You've been invited";

    return `Welcome! ${who} to host an enrichment activity.`
  }

  function onSuccess(history:ReturnType<typeof useHistory>, response:CourseInviteAcceptMutation) {
    const joined = moment(response.courseInviteAccept.user.joined);
    const tenMinsAgo = moment().subtract(10, 'minutes');

    if (joined.isAfter(tenMinsAgo)) {
      history.replace('/provider/quick-start-guide');
    }
    else {
      history.replace('/provider/activities');
    }

    return '/';
  }

  return render();
}
