import * as React from 'react'
import { map } from 'lodash-es';

import { Panel, Field, RepeatingSection, InputField, FormModel } from 'app2/components'

import { useCurrentSite } from '../shared';
import { organizerUpdatePickupDestinations, OrganizerUpdatePickupDestinationsMutationVariables } from './gql'

export function Pickup() {
  const { site } = useCurrentSite();
  const pickupDestinations = site?.pickupDestinations
  const pickups = React.useMemo(() => pickupDestinations?.map((cd) => ({ 'name': cd })), [pickupDestinations])

  function render() {
    return <Panel icon='FileText' title='Pickup options' type='toggle' initialValues={{pickups}} onOk={onOk}>
      <RepeatingSection name='pickups' add='Add pickup option' fields={[
        <Field label='Pickup options' name='name' placeholder='Enter pickup option' required component={InputField} />,
        'remove'
      ]} />
    </Panel>
  }

  async function onOk(form:FormModel) {
    const variables: OrganizerUpdatePickupDestinationsMutationVariables = { siteId: site.id, pickupDestinations: map(form.values.pickups,'name') }
    const [success] = await organizerUpdatePickupDestinations({variables, successMsg: "Pickups updated successfully.", error: form})
    return success;
  }

  return render();
}
