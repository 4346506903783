import { UserPreferences } from "../shared";

export const organizerSitePreferenceKeys = {
  season: 'season',
}

export class OrganizerSitePreferences extends UserPreferences {
  site:string;

  constructor(site:string) {
    super();

    this.site = site;
  }

  get season():string {
    return this.load(organizerSitePreferenceKeys.season);
  }

  set season(value:string) {
    this.save(organizerSitePreferenceKeys.season, value);
  }

  seasonEnrollments(tab:string) {
    return this.pathName(tab);
  }

  get path():(string | number)[] {
    return super.path.concat(this.site);
  }
}
