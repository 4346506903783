export interface MouseState {
  // primry button is being pressed
  primary: boolean;
  // secondary button is being pressed
  secondary: boolean;
  clientX: number;
  clientY: number;
}

export const mouseState: Partial<MouseState> = {};

export function installMouseStateTracker() {
  document.addEventListener('mousedown', onMouseEvent, true);
  document.addEventListener('mousemove', onMouseEvent, true);
  document.addEventListener('mouseup', onMouseEvent, true);
}

function onMouseEvent(event: MouseEvent) {
  mouseState.primary = ((event.buttons & 1) == 1);
  mouseState.secondary = ((event.buttons & 2) == 2);
  mouseState.clientX = event.clientX;
  mouseState.clientY = event.clientY;
}

