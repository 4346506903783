import * as React from 'react'

import { theme } from '../../theme';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { TextHighlighter } from '../../TextHighlighter';

import { CellRenderProps } from './CellRendererProps';
import { useOverflow } from './useOverflow';

export function CellRenderer(props:CellRenderProps) {
  const table = props.table;
  const group = getGroupInfo(props);

  const editingStyle = table.props.cellStyle === 'editable' || (table.props.cellStyle !== 'read-only' && table.props.editable);
  let className = (props.className || '') + ' hr-table-cell';
  className += !editingStyle || group ? ' hr-table-cell-readonly' : ' hr-table-cell-editable';

  if (props.focus && !editingStyle) {
    className += ' hr-table-cell-focus';
  }

  const rowErrors = table?.getRecordErrors(props.rowPos);
  const hasRowErrors = rowErrors?.length > 0;
  const showErrorBorder = (props.errors?.length || hasRowErrors);

  if (showErrorBorder) {
    className += ' hr-table-row-error';
  }

  if (props.errors?.length) {
    className += ' hr-table-cell-error';
  }

  // first col needs special styling to make it appear it has a flat left edge
  if (props.colPos == 0) {
    className += ' hr-table-first-col';
  }

  const ref = React.useRef<HTMLDivElement>();
  const incomingStyles =  {...props.style};// clone the styles so that useOverflow can change them if needed
  const overflow = useOverflow(ref, props, incomingStyles);
  const groupStyles = group?.isGroup ? {overflow: 'visible', background: (theme.colors as any)[table.props.group.bg] || table.props.group.bg, color: (theme.colors as any)[table.props.group.color] || table.props.group.color} : undefined
  const style = overflow.style ? {...incomingStyles, ...overflow.style, ...groupStyles} : {...incomingStyles, ...groupStyles};
  
  const nonEditable = editingStyle && (props.readOnly || props.disabled);

  if (nonEditable) {
    className += ' hr-table-cell-disabled';
  }

  if (props.col.break) {
    className += ' hr-table-page-break';
  }

  const row = props.rowPos;
  const highlight = table.props.highlightText;
  const cell = <div ref={ref} data-row={row} data-field={props.col.label} className={className} style={style}>
    {group?.isGroup ? <Text text='body' position='absolute' color={groupStyles.color} zIndex={2} width='max-content'>{group.value}</Text> : props.children}
    {highlight ? <TextHighlighter parent={ref} focus={props.focus} text={highlight} /> : null}
  </div>;

  return props.errors?.length
    ? renderTooltip(cell, props.errors)
    : cell;
}

function getGroupInfo(props:CellRenderProps) {
  const table = props.table;
  const group = table.props.group;

  if (!group) {
    return;
  }

  const dataRow = table.data.getItem(props.rowPos);
  const state = dataRow[group.state];

  if (!state) {
    return;
  }

  const value = props.colPos == 0 ? dataRow[group.value] : undefined;

  return {isGroup:true, value};
}

function renderTooltip(contents:any, errors:string[]) {
  return <Tooltip tip={errors}>
    {contents}
  </Tooltip>
}
