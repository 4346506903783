import { debounce } from 'lodash-es';
import {
  observableManager,
  ObservableManager,
  isObservable,
  getObservableTarget
} from 'app/observable';

type SharedDataConstructor<T = any> = new () => T;

// the shared data manager provides a mechanism to
// share observable data within UI components.

// a ui component calls get to get an instance of shared
// data.  the observable manager already manages which
// ui components are observing observable data.

// when no ui components are looking at shared data
// the shared data manager look to free the data.
// though it will delay the freeing, in case quick
// ui changes result in needing the same data.

// at somepoint in the future this can hook into the redux
// api, so changes can be tracked in LogRocket and other tools.

export class SharedDataManager {
  private keyToData: Map<SharedDataConstructor, any>;
  private dataToKey: Map<any, SharedDataConstructor>;
  private gcCandidates: Set<any>;

  constructor() {
    this.keyToData = new Map();
    this.dataToKey = new Map();
    this.gcCandidates = new Set();

    observableManager.on(ObservableManager.ON_REMOVED, this.onRemoved);
  }

  // gets an existing store or creates a new instance
  // name is optional, and is used if you want to have
  // multiple instances of the same store

  get<T>(
    SharedDataType: SharedDataConstructor<T>,
    createIfNeeded: boolean = true
  ): T {
    const key = SharedDataType;
    let data: T = this.keyToData.get(key) as T;

    if (!data && createIfNeeded) {
      data = new SharedDataType();

      if (!isObservable(data)) {
        console.warn('Shared data should be observable or it will leak memory');
      }

      this.keyToData.set(key, data);
      this.dataToKey.set(data, key);
    }

    return data;
  }

  // updates a property on shared data if, and only if
  // it's already, in the store

  update<T, K extends keyof T>(
    SharedDataType: SharedDataConstructor<T>,
    propName: K,
    propValue: Partial<T[K]>
  ): boolean {
    const key = SharedDataType;
    const data: T = this.keyToData.get(key) as T;

    if (!data) {
      return false;
    }

    // @ts-ignore
    data[propName] = propValue;

    return true;
  }

  private onRemoved = (observable: any) => {
    if (!observable) {
      return;
    }

    let key = this.dataToKey.get(observable);

    // this should never happen if data used
    // in SharedDataManager.get was properly declared
    // as observable...if its not, but the object was
    // turned into an observable, then this will catch that
    if (!key) {
      observable = getObservableTarget(observable);
      key = this.dataToKey.get(observable);
    }

    if (key) {
      this.gcCandidates.add(observable);
      this.gc();
    }
  };

  private gc = debounce(() => {
    const gcCandidates = this.gcCandidates;
    this.gcCandidates = new Set();

    gcCandidates.forEach(observable => {
      if (!observableManager.hasDependencies(observable)) {
        const key = this.dataToKey.get(observable);
        this.dataToKey.delete(observable);
        this.keyToData.delete(key);
      }
    });
  }, 2000);
}

export const sharedDataManager = new SharedDataManager();
