import * as React from 'react'
import moment from 'moment-timezone';

import { FieldValue, FieldValueProps, createDateFormatterTz } from './form';
import { LONG_DATE_FORMAT } from './form/formatters';//specific import due to circular import issue
import { DateInput } from './date-utils';
import { DateValueFormat } from './calendar';

export interface DateTextProps extends FieldValueProps {
  dateFormat?:string;
  timezone?:string;
  value:DateInput | DateInput[];
  // this is unused but included to make it property 
  // compatible with DatePicker when used in a Field
  valueFormat?:DateValueFormat;
}

export function DateText(props:DateTextProps) {
  const { value, dateFormat, timezone, valueFormat, ...remaining} = props;
  const formatter = React.useMemo(() => createDateFormatterTz(dateFormat, timezone || moment.tz.guess(), true), [dateFormat, timezone]);
  // if the formatter fails then we just show what was passed in, in case its not
  // a true date or parseable date
  const d = formatter(value) || value;

  return <FieldValue {...remaining}>{d}</FieldValue>
}

DateText.defaultProps = {
  dateFormat: LONG_DATE_FORMAT
}
