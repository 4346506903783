import * as React from 'react';

import { VBox, Link, Icon, TextType, Heading2, Body, ButtonKind, IconNames } from 'app/app2/components';

import { Demo } from './Demo';

export function LinkDemos() {
  return <VBox width='100%'>
    <Heading2>Link</Heading2>
    <Body whiteSpace='pre'>{`
      a wrapper around <Link /> and <a /> so
      you don't have to do conditional checking
      when using both based on some condition

      - inhertis Text's text formatting
      - by default has a hover state for text and child icons
      - hover state can be turned off by specifying hover=f{false} or you can use A and react-router Link's directly
      - text style and hover can be changed independently
      - if no text style is specified, all text styling is inherited (except if hover=true)
    `}</Body>

    {renderLinks('Internal target', '/admin/component-demos/link')}
    {renderLinks('External target', 'http://homeroom.com')}
    {renderLinks('No target')}
    <Demo name='Modal'>
      <Link onClick={<Body>Im in a modal</Body>}>Show a modal</Link>
    </Demo>
    <Demo name='Email'>
      <Link to='mailto:foo@bar.com'>foo@bar.com</Link>
    </Demo>
    <Demo name='Telephone'>
      <Link to='tel:+4155551212'>+4155551212</Link>
    </Demo>
    <Demo name='Autoloader'>
      <Link autoLoader onClick={async () => await sleep(5000)}>Click me to see a loader</Link>
    </Demo>
  </VBox>
}

function renderLinks(name:string, to?:string) {
  return <>
    {renderLink(name, to, 'subtitle2', true)}
    {renderLink(name, to, undefined, undefined, undefined, undefined, 'AlertCircle')}
    {renderLink(name, to, undefined, undefined, undefined, undefined, 'AlertCircle', 'right')}
    {renderLink(name, to, 'subtitle2', true, <>Link label<Icon name='Home' /></>)}
    {renderLink(name, to, 'subtitle2', false)}
    {renderLink(name, to, 'subtitle2', false, <>Link label<Icon name='Home' /></>)}
    {renderLink(name, to, undefined, true)}
    {renderLink(name, to, undefined, true, <>Link label<Icon name='Home' /></>)}
    {renderLink(name, to, undefined, false)}
    {renderLink(name, to, undefined, false, <>Link label<Icon name='Home' /></>)}
    {renderLink(name, to, undefined, undefined, undefined, 'primary')}
    {renderLink(name, to, undefined, undefined, undefined, 'secondary')}
  </>
}

function renderLink(name:string, to:string, textStyle?:TextType, hover?:boolean, children?:React.ReactNode, button?:ButtonKind, icon?:IconNames, iconPosition?:'left' | 'right') {
  if (textStyle) {
    name += ', text style';
  }

  if (hover) {
    name += ', hover';
  }

  if (children) {
    name += ', any kind of children';
  }

  if (button) {
    name += ', ' + button + ' button';
  }

  if (icon) {
    name += ', ' + icon + ' icon';
  }

  return <Demo name={name}>
    <Link to={to} text={textStyle} hover={hover} button={button} icon={icon} iconPosition={iconPosition}>{children || 'Link label'}</Link>
  </Demo>
}

async function sleep(time:number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
