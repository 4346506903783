import { DataTableColumn, colId } from './DataTableColumn';
import { orderCols } from './orderCols';

// clones the columns and marks columns specified in the array to be hidden by setting the hidden attribute

export function hideCols<T>(cols: DataTableColumn<T>[], colsToHide: string[]) {
  return showOrHideCols(cols, colsToHide, false);
}

// clones the columns and marks columns specified in the array to be visible by setting the hidden attribute
// reorders them to the order specified

export function showCols<T>(cols: DataTableColumn<T>[], colsToShow: string[], remove?:boolean) {
  const updated = orderCols(colsToShow, showOrHideCols(cols, colsToShow, true), false);

  return remove ? updated.filter(c => !c.hidden) : updated;
}

export function showOrHideCols<T>(cols: DataTableColumn<T>[], colsToShowOrHide: string[], show:boolean):DataTableColumn<T>[] {
  const names = new Set(colsToShowOrHide);

  return cols.map(col => {
    return {
    ...col,
    hidden: names.has(colId(col)) != show
  }});
}
