import { getNextFocusable } from './getNextFocusable';

export class FocusTracker {
  private static isTracking:boolean;

  static trackFocus() {
    if (this.isTracking) {
      return;
    }

    this.isTracking = true;

    document.addEventListener('focusin', this.onFocusIn, true);
  }

  static onFocusIn = (event:FocusEvent) => {
    this.prevFocus = this.curFocus;
    this.curFocus = event.target as HTMLElement;
  }

  static restoreFocus(target?:HTMLElement) {
    (document.activeElement as HTMLElement).blur();
    const curFocus = document.activeElement as HTMLElement;
    const next = (FocusTracker.prevFocus?.isConnected && FocusTracker.prevFocus !== curFocus && !FocusTracker.prevFocus.contains(curFocus)
      ? FocusTracker.prevFocus
      : getNextFocusable(target)) || document.body;

    next?.focus();
    
    // this is a hack for dropdowns to get them to close
    // because its blur code is filtering out the focus change
    var event = new KeyboardEvent('keyup', {
      key: 'Escape',
      code: 'Escape',
      bubbles: true,
      cancelable: true
    });

    // if what we tried to set the focus to didn't take it, then
    // last resort just blur us
    if (curFocus == document.activeElement) {
      curFocus?.blur();
    }
  }

  static curFocus:HTMLElement;
  static prevFocus:HTMLElement;
}
