import { isFunction } from './isFunction';

const functionPrototype = Object.getPrototypeOf(function() {});

export function isClass(obj: any) {
  if (!isFunction(obj)) {
    return false;
  }

  // if a class is inheritted
  if (Object.getPrototypeOf(obj) !== functionPrototype) {
    return true;
  }

  const str = obj.toString();
  const constructorRegex = /^\s*class\b/;

  return constructorRegex.test(str);
}
