import * as React from 'react'
import moment from 'moment'

import { breakpointValue } from './breakpoints';
import { getNextFocusable, dispatchChangeEvent } from './dom-utils';
import { compareDateArrays, DateInput, usDateFormats, parseDate, parseDateWithFormats, parseDayOfWeek, sortDates, toTz } from './date-utils';
import { RestrictedInput } from './RestrictedInput'
import { Calendar, CalendarProps, DateValueFormat, formatDateValue, validateValueFormat } from './calendar';
import { Option } from './Option';
import { InputProps } from './Input';
import { DropdownBase, DropdownFocusType, DropdownState } from './DropdownBase';
import { compareDates, } from './calendar/utils';
import { Breakpoints, BreakpointInfo } from './theme';
import { MultiContext } from './utils';
import { PeriodMenu } from './PeriodMenu';

export interface DatePickerProps extends Omit<InputProps, 'onChange' | 'type' | 'value' | 'min' | 'max'>, Omit<CalendarProps, 'onChange' | 'selected' | 'width'> {
  onChange?: React.ChangeEventHandler<DatePicker>;
  value?:DateInput | DateInput[];
  // the display format in the input
  dateFormat?:string | string[];
  // if specified, this will show the date behind the input text
  // in the same color as the placeholder.  you can use this to show
  // an expanded version of the date, but it won't be editable
  shadowFormat?:string | string[];
  // tag format defaults to the input format
  tagDateFormat?:string;
  // formats allowable from the user input
  allowableFormats?:string[]
  modal?: boolean;//show the calendar as a modal
  // the calendar is capable of working with both
  // dates (via string) and date-times (via Date or Moment)
  // by default it will use 'date' when there's no timezone
  // and date-time (Moment) when there's a timezone.  note that
  // having a timezone and a value format of date will result
  // in buggy behavior.
  valueFormat?:DateValueFormat;
  timezone?: string;
  // shows the calendar underneath the input and not as a dropdown
  inline?:boolean;
  // if true, this allows the days of the week 
  // to be clickable and selectable
  daysOfWeek?:boolean;
  // because the selected api was designed for dates
  // there's an alternative selected api for days of the week
  dayOfWeek?:string;

  // will show the period menu instead of just a calendar
  period?:boolean;
}

interface State extends DropdownState {
  text?:string;
  shadowValue?:string;
  selected?:moment.Moment[];
  dayOfWeek?:string;
}

export class DatePicker extends DropdownBase<DatePickerProps, State, BreakpointInfo> {
  static SHORT_DATE_FORMAT = 'M/D/YY'
  static LONG_DATE_FORMAT = 'MMM D, YYYY'
  static DEFAULT_DATE_FORMAT =  DatePicker.LONG_DATE_FORMAT;

  static defaultProps:DatePickerProps = {
    type: 'single',
    dateFormat: DatePicker.DEFAULT_DATE_FORMAT,
    valueFormat: DateValueFormat.guess,
    allowableFormats:  usDateFormats,
    placeholder: 'Enter date'
  }

  static fieldProps = {
    valueProperty: 'value',
    errorProperty: 'error',
    onChangeProperty: 'onChange',
    onBlurProperty: 'onBlur',
    disabledProperty: 'disabled',
    paste: parseDate
  }

  static contextType = MultiContext;
  context:BreakpointInfo;

  state:State;
  inputRef = React.createRef<HTMLInputElement>();
  focusType = DropdownFocusType.both;
  min?:DateInput | 'now';
  max?:DateInput | 'now';

  constructor(props:DatePickerProps) {
    super(props);

    this.updateMinMax();

    const selected = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
    this.state = this.createDateState(selected, this.props.dayOfWeek);
    this.modal = this.props.modal;
    this.inline = this.props.inline;
    this.forceVisible = true;
    validateValueFormat(this.props);
  }

  componentDidUpdate(prevProps:DatePickerProps) {
    this.updateMinMax();

    const selected = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
    const prevSelected = Array.isArray(prevProps.value) ? prevProps.value : [prevProps.value];

    // we need to compare dates at the minute level and not the day level because we want
    // to preserve the time that is passed into us
    if (!compareDateArrays(selected, prevSelected, undefined, undefined, 'minute') || prevProps.dayOfWeek != this.props.dayOfWeek) {
      this.setState(this.createDateState(selected, this.props.dayOfWeek));
    }

    this.modal = this.props.modal || this.context.deviceBreakpoint == Breakpoints.phone;
    this.inline = this.props.inline;
    validateValueFormat(this.props);
  }

  renderTrigger(inputProps:InputProps) {
    let {type, start, legend, dayStyles, value, min, max, dateFormat, shadowFormat, tagDateFormat, allowableFormats, modal, onChange, onBlur, onIconClick, onTagCloseClick, onKeyDown, width, timezone, valueFormat, inline, daysOfWeek, dayOfWeek, period, ...remaining} = this.props;

    const tags = this.multiple ? this.state.selected.map(date => this.formatDate(date, tagDateFormat)) : [];
    const shadowValue = shadowFormat && value ? this.formatDate(this.state.selected[0], breakpointValue(shadowFormat, this.context.deviceBreakpoint)) : undefined;

    width = width || this.props.style?.width;

    return <RestrictedInput ref={this.inputRef} icon='Calendar' onKeyDown={this.onInputKeyDown} onChange={this.onInputChange} tags={tags}
      parse={this.parseDate} format={this.formatDateFromInput} commit={this.commitDate} blurred={this.dropdownLostFocus} onTagCloseClick={this.onRemoveDate}
      onIconMouseDown={this.toggleDropdown} {...remaining} width='100%' value={this.state.text} shadowValue={shadowValue} whiteSpace={shadowValue ? 'nowrap' : undefined} />
  }

  renderDropdown() {
    const selected:DateInput[] = this.state.selected.slice();

    if (this.multiple && this.state.text) {
      selected.push(this.state.text);
    }

    const CalendarType = !this.props.period ? Calendar : PeriodMenu

    return <CalendarType type={this.props.type} width={['100%', 'unset', 'unset']} timezone={this.props.timezone} valueFormat={this.props.valueFormat} selected={selected} start={this.props.start} 
          min={this.props.min} max={this.props.max} onChange={this.onCalendarChange} legend={this.props.legend} daysOfWeek={this.props.daysOfWeek} dayOfWeek={this.state.dayOfWeek} />
  }

  get value() {
    return formatDateValue(this.props, this.state.selected);
  }

  get dayOfWeek() {
    return this.state.dayOfWeek
  }

  get multiple() {
    return this.props.type == 'multiple' || this.props.type == 'range';
  }

  onInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({text: event.target.value});

    // for multiple dates, we dont commit a new date until the user hits
    // enter or types semicolon, otherwise we replace the current date with the existing
    if (this.multiple) {
      return;
    }

    const dayOfWeek = this.parseDayOfWeek(event.target.value);
    const value = dayOfWeek ? undefined : event.target.value;

    this.onDateChange(value ? [value as any] : [], false, dayOfWeek);
  }

  onBlur() {
    if (!this.possiblyMatchMultipleDate() && !this.state.selected?.length && !this.parseDayOfWeek(this.state.text)) {
      this.setState({text:''});
    }
  }

  onInputKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    // if the user hits enter in a multiple date picker then
    // commit the typed in date (to a tag) if present
    if (!(event.key == 'Enter' || event.key == ';' || event.key == 'Tab')) {
      return;
    }

    if (this.possiblyMatchMultipleDate()) {
      // prevent default so a semi-colon isn't
      // added to the text input
      if (event.key === ';') {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  possiblyMatchMultipleDate() {
    if (!this.multiple || !this.state.text) {
      return;
    }

    const date = this.parseDate(this.state.text);

    if (!date) {
      return;
    }

    if (this.isSelected(date)) {
      this.setState({text: ''});
      return;
    }

    this.onDateChange([...this.state.selected.slice(), this.state.text], true);

    return true;
  }

  updateMinMax() {
    const timezone = this.props.timezone;
    const min = this.props.min;
    const max = this.props.max;

    this.min = min == 'now' ? moment.tz(timezone).startOf('d') : min ? this.parseDate(min) : null
    this.max = max == 'now' ? moment.tz(timezone).endOf('d') : max ? this.parseDate(max) : null
  }

  beforeMin(day:moment.Moment) {
    return day && this.min && day.isBefore(this.min);
  }

  afterMax(day:moment.Moment) {
    return day && this.max && day.isAfter(this.max)
  }

  // assume the incoming date is in the display timezone
  // and will convert it to the value timezone
  parseDate = (value:DateInput):any => {
    const dayOfWeek = this.parseDayOfWeek(value);

    if (dayOfWeek) {
      return dayOfWeek;
    }

    let date = parseDateWithFormats(value, this.props.allowableFormats, this.props.timezone);
    date = Array.isArray(date) ? date[0] : date;

    if (!date) {
      return;
    }

    return date;
  }

  commitDate = (date:moment.Moment | string):boolean => {
    const dayOfWeek = this.parseDayOfWeek(date);

    if (dayOfWeek) {
      return true;
    }

    return !this.beforeMin(date as moment.Moment) && !this.afterMax(date as moment.Moment);
  }

  formatDateFromInput = (value:any):string => {
    const dayOfWeek = this.parseDayOfWeek(value);

    if (dayOfWeek) {
      return dayOfWeek;
    }

    const date = this.parseDate(value);
    return this.formatDate(date);
  }

  get dateFormat() {
    return breakpointValue(this.props.dateFormat, this.context?.deviceBreakpoint);
  }

  formatDate(d:moment.Moment, format?:string) {
    return !d ? '' : toTz(d, this.props.timezone).format(format || this.dateFormat);
  }

  dropdownLostFocus = (event:React.FocusEvent):boolean => {
    return !this.isDropdown(event.relatedTarget as HTMLElement);
  }

  forwardDropdownEvent(event:React.KeyboardEvent) {
    return event.key == 'ArrowLeft' || event.key == 'ArrowUp' || event.key == 'ArrowRight' || event.key == 'ArrowDown' || event.key == 'Enter';
  }

  onRemoveDate = (tag:Option, index:number) => {
    const selected = this.state.selected.slice();
    selected.splice(index, 1);

    this.onDateChange(selected, false);
  }

  onCalendarChange = (event:React.ChangeEvent<Calendar>) => {
    const value = event.currentTarget.value;
    const dayOfWeek = event.currentTarget.dayOfWeek;

    this.onDateChange(Array.isArray(value) ? value : [value], true, dayOfWeek);
  }

  onDateChange(selected:DateInput[], close:boolean, dayOfWeek?:string) {
    // somehow invalid dates occasionally sneak in
    if (!dayOfWeek && selected && selected.length && selected[0] != undefined && selected[0] != null && !moment.isMoment(parseDate(selected[0]))) {
      return;
    }

    if (this.props.type == 'range') {
      if (selected.length > 2) {
        selected = selected.slice(selected.length - 2, selected.length);
      }

      selected = sortDates(selected);
    }

    if (this.props.onChange) {
      const value = formatDateValue(this.props, selected);
      dispatchChangeEvent(this, value, this.props.onChange, {value, dayOfWeek});
    }
    else {
      this.setState(this.createDateState(selected, dayOfWeek));
    }

    if (this.props.type == 'range-start') {
      if (close) {
        this.closeDropdown(true);
        setTimeout(() => getNextFocusable(this.inputRef.current).focus(), 250);
      }
    }
    else 
    if ((this.props.type == 'range-end' || this.props.type == 'single') && close) {
      this.closeDropdown(true);
    }
    else 
    if (this.props.type == 'range' && selected.length == 2 && close) {
      this.closeDropdown(true);
    }
  }

  createDateState(selected:DateInput[], dayOfWeek?:string) {
    const updated = selected.filter(date => date !== undefined && date !== null)
      .map(d => this.parseDate(d));

    const dateForText = this.props.type == 'single' || this.props.type == 'range-start'
    ? updated[0]
      : this.props.type == 'range-end'
        ? updated[1]
        : null

    const text = dayOfWeek || this.formatDate(dateForText);

    return {selected:updated, text, dayOfWeek, shadowValue: this.state.shadowValue};
  }

  isSelected(dateOrText:DateInput) {
    const date = this.parseDate(dateOrText);

    if (!date) {
      return false;
    }

    return this.state.selected.find(d => compareDates(date, d)) != null;
  }

  parseDayOfWeek(value:DateInput) {
    return this.props.daysOfWeek && parseDayOfWeek(value);
  }
}
