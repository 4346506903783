import * as React from 'react';

import { Box, ActionButton, Dropdown, Body, Button, TablePrefs, DataTable, DataTableColumn, DataTableHeader, Link, MultipleSelection, ObservableCollectionArray, FieldProps } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function DataTableDemos() {
  const ref = React.useRef<DataTable>();
  const [rowCount, setRowCount] = React.useState(1);
  const [editing, setEditing] = React.useState(false);
  const cols = React.useMemo(() => TablePrefs.loadFromLocalStorage('data-table-demo-cols', {cols:defaultCols}), []).cols;

  return <Demos name='DataTable'>
    <Demo name='Editable' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities' width="800px" editing={editing}
        onEdit={() => startEditing(setEditing)}
        onCancelEdits={() => cancelChanges(setEditing, ref.current)}
        onSaveEdits={() => saveChanges(setEditing, ref.current)}
        primaryActions={!editing ? <Link text='subtitle2'>Download CSV</Link> : <><Body mr='$20'>Selected rows: {rowCount}</Body></>}
        secondaryActions={
          !editing 
          ? <>
            <ActionButton icon='Send'>Send to vendor</ActionButton>
            <ActionButton icon='XCircle'>Cancel activity</ActionButton>
            <ActionButton icon='LogIn'>Open enrollment</ActionButton>
            <ActionButton icon='LogOut'>Close enrollment</ActionButton>
          </>
        : <>
          <ActionButton icon='PlusCircle' onClick={() => addRow(ref.current)}>Add row</ActionButton>
          <ActionButton icon='Delete' onClick={() => deleteRow(ref.current)}>Delete row</ActionButton>
          </>
        }>
      <DataTable ref={ref} height="300px" width="800px" overflow="scroll"
        data={data} cols={cols} lockable sortable filterable
        onViewChange={table => TablePrefs.saveToLocalStorage('data-table-demo-cols', {cols:table.allCols})} 
        onSelectionChange={(table: DataTable, selections: MultipleSelection) => setRowCount(selections.selectedRows.length)}
        />
      </DataTableHeader>
    </Demo>

    <Demo name='Readonly' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Enrollments' width="800px"
        primaryActions={<Button icon='Plus'>Invite Student</Button>}
        secondaryActions={<>
            <ActionButton icon='RefreshCw'>Move student</ActionButton>
            <ActionButton icon='DollarSign'>Refund student</ActionButton>
            <ActionButton icon='Trash'>Remove student</ActionButton>
          </>}>
      <DataTable height="300px" width="800px" overflow="scroll" editable={false}
        data={data} cols={cols}
        onViewChange={table => TablePrefs.saveToLocalStorage('data-table-demo-cols', {cols:table.allCols})} 
        />
      </DataTableHeader>
  </Demo>
  <Demo name='Editable, small rows' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities' width="800px" editing={editing}
        onEdit={() => startEditing(setEditing)}
        onCancelEdits={() => cancelChanges(setEditing, ref.current)}
        onSaveEdits={() => saveChanges(setEditing, ref.current)}>
      <DataTable height="300px" width="800px" overflow="scroll"
        data={emptyData} cols={cols as any} lockable sortable filterable
        onViewChange={table => TablePrefs.saveToLocalStorage('data-table-demo-cols', {cols:table.allCols})} 
        onSelectionChange={(table: DataTable, selections: MultipleSelection) => setRowCount(selections.selectedRows.length)}
        />
      </DataTableHeader>
    </Demo>

    <Demo name='Editable, small rows, no fixed height or width' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities' editing={editing}
        onEdit={() => startEditing(setEditing)}
        onCancelEdits={() => cancelChanges(setEditing, ref.current)}
        onSaveEdits={() => saveChanges(setEditing, ref.current)}>
      <DataTable 
        data={emptyData} cols={cols as any} lockable sortable filterable
        onViewChange={table => TablePrefs.saveToLocalStorage('data-table-demo-cols', {cols:table.allCols})} 
        onSelectionChange={(table: DataTable, selections: MultipleSelection) => setRowCount(selections.selectedRows.length)}
        />
      </DataTableHeader>
    </Demo>

    <Demo name='Empty, no fixed height or width, none message' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities'>
        <DataTable data={[]} cols={cols} none='There are no rows!' />
      </DataTableHeader>
    </Demo>

    <Demo name='Empty, fixed height and width, none message' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities'>
        <DataTable data={[]} cols={cols} none='There are no rows!' height="300px" width="800px" overflow="scroll" />
      </DataTableHeader>
    </Demo>

    <Demo name='Editable, no fixed height or width' omitProps={['data', 'cols']}>
      <DataTableHeader icon='Book' title='Activities' editing={editing}
        onEdit={() => startEditing(setEditing)}
        onCancelEdits={() => cancelChanges(setEditing, ref.current)}
        onSaveEdits={() => saveChanges(setEditing, ref.current)}
        primaryActions={!editing ? <Link text='subtitle2'>Download CSV</Link> : <><Body mr='$20'>Selected rows: {rowCount}</Body></>}
        secondaryActions={
          !editing 
          ? <>
            <ActionButton icon='Send'>Send to vendor</ActionButton>
            <ActionButton icon='XCircle'>Cancel activity</ActionButton>
            <ActionButton icon='LogIn'>Open enrollment</ActionButton>
            <ActionButton icon='LogOut'>Close enrollment</ActionButton>
          </>
        : <>
          <ActionButton icon='PlusCircle' onClick={() => addRow(ref.current)}>Add row</ActionButton>
          <ActionButton icon='Delete' onClick={() => deleteRow(ref.current)}>Delete row</ActionButton>
          </>
        }>
      <DataTable 
        data={data} cols={cols} lockable sortable filterable
        onViewChange={table => TablePrefs.saveToLocalStorage('data-table-demo-cols', {cols:table.allCols})} 
        onSelectionChange={(table: DataTable, selections: MultipleSelection) => setRowCount(selections.selectedRows.length)}
        />
      </DataTableHeader>
    </Demo>
  </Demos>
}

function letter(colNo: number) {
  return String.fromCharCode('A'.charCodeAt(0) + colNo);
}

function createCols(cols: number) {
  return Array.from(new Array(cols)).map((_, col) => {
    const field:FieldProps<any, any, any> = {
      name: col != 3 ? 'Col ' + letter(col) : 'special.' + 'Col ' + letter(col),
      width: 100 + 10 * col
    };

    if (col == 0) {
      field.required = true;
    }
    else
    if (col == 1) {
      field.validators = (val:any) => Number.isFinite(Number(val)) ? undefined : 'Must be finite';
    }
    else 
    if (col == 2) {
      field.edit = {edit: Dropdown, options: [{value:'a', label:'aa'}, {value:'b', label:'bb'}]};
    }

    return field;
  });
}

function createCellData(rows: number, cols: number) {
  const cells: string[][] = [];

  for (let rowNo = 0; rowNo < rows; ++rowNo) {
    const row: any = {id: rowNo};
    cells.push(row);

    for (let colNo = 0; colNo < cols; ++colNo) {
      if (colNo != 3) {
        row['Col ' + letter(colNo)] = 'row:' + rowNo + ', col:' + letter(colNo);
      }
      else {
        row['special'] = {['Col ' + letter(colNo)]: 'row:' + rowNo + ', col:' + letter(colNo)};
      }
    }
  }

  return cells;
}

function startEditing(setEditing:(val:boolean) => void) {
  setEditing(true)
}

async function saveChanges(setEditing:(val:boolean) => void, table:DataTable) {
  console.log('data.edits', table.getChanges());

  await new Promise((resolve) => setTimeout(resolve, 2000));

  throw new Error('Could not save because of bad internet');
}

function cancelChanges(setEditing:(val:boolean) => void, table:DataTable) {
  setEditing(false); 
  table.cancelChanges();
}

function addRow(table:DataTable) {
  table.append();
}

function deleteRow(table:DataTable) {
  table.remove(table.selection.topLeft.row)
}


const numRows = 5000, numCols = 40;
const defaultCols = createCols(numCols);
const data = new ObservableCollectionArray<any>(createCellData(numRows, numCols), 'id');
const emptyData = [{}];
