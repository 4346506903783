import * as React from 'react';
import * as ReactIs from 'react-is';
import { css } from 'styled-components';
import { variant } from './styled-system';

import { Shortcut, useShortcut, ShortcutCallback } from './dom-utils';
import { ComponentProps, componentStyles } from './utils';
import { IconNames, Icon, IconProps } from './icons';
import { useClickLoader } from './shield';
import { TextCustomProps, createTextComponent } from './Text';

export type ButtonKind = 'primary' | 'secondary' | 'tertiary';
interface ButtonCustomProps extends TextCustomProps {
  kind?: ButtonKind;
  icon?: IconNames | React.ReactElement<IconProps>;
  iconPosition?: 'right' | 'left';
  loading?: boolean;
  small?: boolean;
  autoLoader?: boolean;
  selected?: boolean;
  // blocks focus on click, but not via keyboard
  focusable?: boolean;
  as?:keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;
  shortcut?:Shortcut;
  danger?:boolean;
}

export type ButtonProps = ComponentProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & ButtonCustomProps & {ref?: React.Ref<any>};

// note that hovered and pressed selectors only need the css psuedo 
// :hover and :active selectors, but we also allow for them to be manually
// invoked by passing in class names of hovered and pressed mainly for
// testing purposes so they can be shown in the demo pages
const hovered = '&.hovered:not(:disabled),:hover:not(:disabled)';
export const focused = ':active:not(:disabled),:focus:not(:disabled)';
// allow disabled pressed to show pressed so that selected choices in disabled button strips show selected still
const pressed = '&.pressed';
export const disabled = ':disabled:not(.loading):not(.hr-error)';
const error = '&.hr-error,&.hr-error:hover,&.pressed.hr-error,&.pressed.hr-error:hover';

// "type" is reserved an HTML button (i.e. type='submit'), so we use the name "kind" instead
const kindVariants = {
  primary: {
    color: 'white!important',
    backgroundColor: 'primary',
    borderColor: 'transparent',
    [hovered]: {
      textDecoration: 'underline',
      backgroundColor: 'primaryHover'
    },
    [focused]: {
      outline: `solid 2px`,
      outlineColor: `primaryFocused`,
      outlineOffset: '0px',
      textDecoration: 'underline',
      zIndex: 1
    },
    [pressed]: {
      backgroundColor: 'primaryPressed'
    },
    [disabled]: {
      backgroundColor: 'disabled',
    },
    [error]: {
      backgroundColor: 'error',
      [focused]: {
        outlineColor: 'errorFocused',
      },
      [pressed]: {
        backgroundColor: 'errorFocused',
      },
      [hovered]: {
        backgroundColor: 'errorFocused',
      }
    }
  },
  secondary: {
    color: 'primary',
    backgroundColor: 'primaryInverse',
    borderColor: 'primary',
    [hovered]: {
      textDecoration: 'underline',
      color: 'primary',
      backgroundColor: 'secondaryHover',
      svg: {
        stroke: 'primary',
        color: 'primary'
      }
    },
    [focused]: {
      textDecoration: 'underline',
      outline: `solid 2px`,
      outlineColor: `primaryFocused`,
      outlineOffset: '0px',
      zIndex: 1
    },
    [pressed]: {
      backgroundColor: 'secondaryPressed'
    },
    [disabled]: {
      borderColor: 'disabled',
      color: 'disabled',
      svg: {
        stroke: 'disabled',
        color: 'disabled'
      }
    },
    [error]: {
      color: 'error',
      borderColor: 'error',
      svg: {
        stroke: 'error',
        color: 'error'
      },
      [focused]: {
        outlineColor: 'errorFocused',
      },
      [pressed]: {
        backgroundColor: 'errorFocused',
      },
      [hovered]: {
        backgroundColor: 'errorFocused',
      }
    }
  },
  tertiary: {
    color: 'primary',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    [hovered]: {
      textDecoration: 'underline',
      color: 'primaryHover',
      svg: {
        stroke: 'primaryHover',
        color: 'primaryHover'
      }
    },
    [focused]: {
      textDecoration: 'underline',
    },
    [pressed]: {
      color: 'primaryPressed'
    },
    [disabled]: {
      color: 'disabled',
      svg: {
        stroke: 'disabled',
        color: 'disabled'
      }
    },
    [error]: {
      color: 'error',
      svg: {
        stroke: 'error',
        color: 'error'
      }
    }
  }
};

const kind = variant({
  prop: 'kind',
  variants: kindVariants
});

const propsToExclude = ['icon', 'iconPosition', 'kind', 'loading', 'small', 'autoLoader', 'as', 'danger'];
const ButtonStyles = createTextComponent('button', false, propsToExclude, css<ButtonProps>`
  outline: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.small ? props.theme.fontSizes.buttonSmall : props.theme.fontSizes.button};
  font-family: ${props => props.theme.fonts.button};
  padding: ${props => props.small 
      ? '7.5px 11px 7.5px 11px' 
      : props.small !== false && props.kind == 'tertiary'
        ? '0px'
        : '8px 19px 8px 19px'};
  border: solid ${props => props.theme.borderWidths.standard};
  border-radius: ${props => props.theme.radii.standard};
  line-height: normal;

  transition: color .2s ease, border .2s ease, outline .2s ease, background .2s ease, top .05s ease, transform .1s ease;

  :disabled {
    cursor: unset;
  }

  ${props =>
    props.loading
      ? `
    > *:not(.loader) {
      opacity: 0;
    }
    `
      : ''}

  /* important for the loader which uses absolute positioning */
  position:relative;

  /* for the wiggle effect, design asked we copy http://www.magicspoon.com, but because that moves the button down it would often cause a scrollbar to appear, so instead use scale which doesn't cause that */
  :active:not(:disabled) {
    transform: scale(98%)
  }

  /* space between label and icon */
  ${props =>
    props.iconPosition != 'left'
      ? `
      > * {
        margin-right: ${props.theme.space.$8};
      }
    
      > *:last-child {
        margin-right: 0;
      }`
      : `
      > * {
        margin-left: ${props.theme.space.$8};
      }
    
      > *:last-child {
        margin-left: 0;
      }

      flex-direction: row-reverse;
    `}
    
  ${kind}
  ${componentStyles}
`);

export const Button = React.forwardRef((props: ButtonProps, ref:any) => {
  const { children, autoLoader, selected, focusable, shortcut, onClick, onMouseDown, danger, ...remainingProps } = props;
  const inverted = props.kind != 'secondary' && props.kind != 'tertiary' ? true : false;
  const { loading, clickLoaderHandler, loader } = useClickLoader(props.onClick, inverted, remainingProps.loading);
  const handleClick = autoLoader && onClick
    ? clickLoaderHandler
    : onClick;

  useShortcut(shortcut, handleClick as ShortcutCallback);

  function render() {
    remainingProps.loading = loading;

    let childrenArray = React.Children.toArray(children).slice();
  
    addLoading(remainingProps, childrenArray, loader);
    addIcon(remainingProps, childrenArray);
    childrenArray = textToSpan(remainingProps, childrenArray);
    ensureHasChild(remainingProps, childrenArray);

    const className = (remainingProps.className || '') + (selected ? ' pressed' : '') + (props.error || props.danger ? ' hr-error' : '');
  
    return <ButtonStyles ref={ref} onClick={handleClick} onMouseDown={handleMouseDown} {...remainingProps} className={className}>{childrenArray}</ButtonStyles>;
  }

  function handleMouseDown(event:React.MouseEvent<HTMLButtonElement>) {
    if (focusable === false) {
      event.preventDefault();
    }

    onMouseDown?.(event);
  }

  return render();
})

Button.displayName = 'Button';
Button.defaultProps = {
  kind: 'primary',
  whiteSpace: 'nowrap'
};

function addLoading(props: ButtonProps, children: React.ReactNode[], loader:React.ReactNode) {
  if (!props.loading) {
    return;
  }

  props.className = props.className ? props.className + ' loading' : 'loading';
  props.disabled = true;

  // the loader is added and doesn't replace existing content
  // so that the size of the button doesn't change as loading is toggled.
  // to avoid getting margins added to the loader (and taking margins
  // aware from the other elements, put atthe beginning or
  // end depending on icon layout
  if (props.iconPosition != 'right') {
    children.unshift(loader);
  } else {
    children.push(loader);
  }

  if (children.length == 1) {
    // because semantic ui uses aboslute position
    // when there's no other content in the button
    // (no label and no icon) the button padding doesn't
    // get respected. force the padding by having a button
    // label of a zero-width space.
    children.push('\u200B');
  }
}

function addIcon(props: ButtonProps, children: React.ReactNode[]) {
  if (!props.icon) {
    return;
  }

  children.push(React.isValidElement(props.icon)
    ? props.icon
    : <Icon size='small' name={props.icon} color={kindVariants[props.kind].color} />);
}

function textToSpan(props: ButtonProps, children: React.ReactNode[]) {
  // if there's an array of children
  // we wrap any strings in a span
  // so that the margin styling works correctly

  return children.map((child, index) =>
    typeof child == 'object' 
      ?  React.cloneElement(child as React.DetailedReactHTMLElement<any, any>, {key: index})
      : <span key={index}>{child}</span>
  );
}

function ensureHasChild(props: ButtonProps, children: React.ReactNode[]) {
  // if there's no children (no label) add a zero-width space to force
  // padding to take a effect to force consistency with buttons with labels
  if (children.length == 0) {
    children.push('\u200B');
  }
}
