import * as React from 'react'

import { NavigationLink as NavigationLinkBase, NavigationLinkProps } from 'app2/components';

import { HideNavigation, useNavigationRouteInfo } from './useNavigationRouteInfo';

interface Props extends NavigationLinkProps {
  hide?:HideNavigation;
}

export function NavigationLink(props:Props) {
  let {hide, ...remaining} = props;

  const route = useNavigationRouteInfo(hide, props.to);

  if (!route?.allowed) {
    return <></>;
  }

  return <NavigationLinkBase css={route.css} {...remaining}/>
}
