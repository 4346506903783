import * as React from 'react';

import { Img, Text } from 'app2/components';

import { PublicPage } from '../public-page';

import maintenanceImage from './maintenance_illustration.png';

export function Maintenance() {
  return <PublicPage hAlign='center' useMaxWidth title='Under Maintanence | Homeroom' gap='$16'>
    <Img src={maintenanceImage} />
    <Text text='heading2'>Homeroom is currently under maintenance.</Text>
    <Text text='subtitle1'>Sorry for the inconvenience. We will be back up and running soon.</Text>
  </PublicPage>
}
