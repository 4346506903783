import { useHistory } from 'react-router';

import { CourseKindGroup, CourseKind } from 'app2/api';
import { courseKindBehavior } from 'app2/views/shared-public';

export function useNavigateToTableAndEdit() {
  const history = useHistory();
  
  function navigate(kind:CourseKindGroup | CourseKind) {
    const behavior = courseKindBehavior[kind];
    history.push(history.location.pathname + `?edit=${behavior.name}`);
  }

  return navigate;
}
