import { axios } from '../account';

const STRIPE_CONNECT_URL = '/stripe/connect'

export async function stripeConnectAPI(isSite:boolean, id:string, params:any) {
  // UIs that use this API should set the entity type and id
  // via setStripeConnectEntity prior to navigating the user 
  // to stripe and this api will favor those values over the
  // ones passed in

  const result = await axios.get(
    `${STRIPE_CONNECT_URL}/${isSite ? 'sites' : 'companies'}/${id}`,
    { params }
  )
  
  return result;
}