import { useParams } from 'react-router'

import { useOrganizerSiteSeasonsQuery } from '.'
import { SeasonRouteParams } from '../organizerRoutes';

export function useSeasons() {
  const {site} = useParams<SeasonRouteParams>();
  const result = useOrganizerSiteSeasonsQuery({variables:{siteId: site}});
  const fetching = result[0].fetching;
  const seasons = result[0].data?.site?.seasonsByStatus;;

  return {seasons, fetching};
}

