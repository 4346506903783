import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type CommonCompanyFragment = { id: string; name?: string | null | undefined; userCompanyRole?: Types.MemberRole | null | undefined; nonPartnerApproved?: boolean | null | undefined; slug: string };

export const CommonCompanyFragmentDoc = gql`
  fragment commonCompany on Company {
    id
    name
    userCompanyRole
    nonPartnerApproved
    slug
  }
`;
