import * as React from 'react'
import pluralize from 'pluralize';

import { HBox, InputField, Panel, Part, useForm, useFormToUrl, VBox } from 'app2/components';
import { PublicPage } from 'app2/views/shared-public';

import { DirectoryFilter } from './DirectoryFilter'
import { SiteResult } from './SiteResult'
import { usePublicSiteDirectoryQuery, PublicSiteDirectorySelections } from './generated';

export function SiteDirectory() {
  const [filter, setFilter] = React.useState<DirectoryFilter>({});
  const form = useForm<DirectoryFilter>(filter, []);
  const {formToLocation} = useFormToUrl(form, {path: '/directory/school/:zip', watchForm: false, onUrlChange});

  const company = getCompany();
  const { sites, total } = getResults();

  function render() {
    return <PublicPage title='School directory' subtitle='Find schools to teach at' useMaxWidth>
      <VBox gap='$8'>
        {renderFilter()}
        {sites === undefined
          ? ''
          : sites?.length 
            ? <HBox text='body' italic>Showing schools active with Homeroom {company && ` (excluding schools where ${company.name} teaches at)`}</HBox>
            : <HBox text='body' italic>No schools matching your criteria</HBox>}

        {renderResults()}
        {renderMore()}
      </VBox>
    </PublicPage>
  }

  function renderFilter() {
    return <Panel type='edit' form={form} onNavigation='nothing' gap='$12' cancel={null} ok='Search' onOk={onSearch}>
      <Part name='zip' label='Zip code' component={InputField} flex={1} readOnly={!!company} none={company ? `${company.zipcode} (${company.name})` : undefined} />
    </Panel>
  }

  function renderResults() {
    return React.useMemo(() => {
      return <VBox gap='$30' position='relative'>
        {/* <TextHighlighter text={stems} parent={r} /> */}
        {sites?.map(result => <SiteResult key={result.site.id} result={result} />)}
      </VBox>
    }, [sites, company]);
  }

  function renderMore() {
    if (sites?.length == total) {
      return;
    }

    const remaining = total - sites.length;

    return <HBox text='body'>{remaining} more {pluralize('school', remaining)}</HBox>
  }

  function getCompany() {
    return null as any;
  }

  function getResults() {
    const { zip, company } = filter;

    const [result] = usePublicSiteDirectoryQuery({variables:{zipCode:company ? undefined : zip, company}, autoPause: false});
    const results = result.data?.directorySiteSearch || {} as PublicSiteDirectorySelections;

    return results;
  }

  function onSearch() {
    // triggers a onUrlChange
    formToLocation();
  }

  function onUrlChange() {
    setFilter({...form.values});
  }

  return render();
}

