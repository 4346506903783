import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
export type StudentMedicalFieldsFragment = {
  notes?: string | null | undefined;
  medical?:
    | {
        epiPen?: boolean | null | undefined;
        conditions?: string | null | undefined;
        allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
        medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const StudentMedicalFieldsFragmentDoc = gql`
  fragment studentMedicalFields on Student {
    medical {
      allergies {
        allergicTo
        notes
      }
      epiPen
      medications {
        medication
        dose
        notes
      }
      conditions
    }
    notes
  }
`;
