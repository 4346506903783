import * as React from 'react';

import { InputField, Modal, Form, Section, useForm, Body } from 'app2/components';

type SiteOrCompany = {
  id:string;
  name?:string;
}

interface Props {
  entity:SiteOrCompany;
  mutation:any;
}

export function ClearStripeTokenModal(props:Props) {
  const form = useForm({}, []);

  function render() {
    return <Modal title='Clear Stripe account' danger info={{type:'warning', message: 'This can not be undone'}}>
      <Form form={form} onOk={onOk} editing>
        <Body>This will remove the Stripe account from {props.entity.name}.</Body>
        <Section name='confirm' label='To continue, type the word "clear"' component={InputField} placeholder='Enter the word "clear"' validators={validate} />
      </Form>
    </Modal>
  }

  async function onOk() {
    const [success] = await props.mutation({variables:{id: props.entity.id}})

    return success;
  }

  return render();
}

function validate(value:string) {
  return value?.toLowerCase() == 'clear'
    ? null
    : 'You must type clear to confirm you want to proceed with clearing the Stripe account'
}