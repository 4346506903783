import { isFocusable } from './isFocusable';
import { traverseDom, getNextSiblingOrParentSibling } from './traverseDom';

export function getNextFocusable(elementOrNode:Node):HTMLElement {
  if (!elementOrNode) {
    return null;
  }

  const element = elementOrNode.nodeType != Node.ELEMENT_NODE ? elementOrNode.parentElement : elementOrNode as HTMLElement;
  const next = traverseDom(getNextSiblingOrParentSibling(element), isFocusable);

  return next as HTMLElement;
}
