import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerStripeUrlsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerStripeUrlsQuery = {
  site?:
    | { id: string; stripeDashboardUrl?: string | null | undefined; stripeConnectUrl?: string | null | undefined; stripeAccountType?: string | null | undefined; stripeAccountVerified?: boolean | null | undefined }
    | null
    | undefined;
};

export const OrganizerStripeUrlsDocument = gql`
  query OrganizerStripeUrls($siteId: ID!) {
    site(site: $siteId) {
      id
      stripeDashboardUrl
      stripeConnectUrl
      stripeAccountType
      stripeAccountVerified
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerStripeUrlsArgs = MakeOptional<UseQueryArgs<OrganizerStripeUrlsQueryVariables, OrganizerStripeUrlsQuery>, 'query'>;

export function useOrganizerStripeUrlsQuery(options: OrganizerStripeUrlsArgs = {}) {
  return useQuery<OrganizerStripeUrlsQueryVariables, OrganizerStripeUrlsQuery>({ query: OrganizerStripeUrlsDocument, ...options });
}

export type OrganizerStripeUrlsOptions = ExecQueryOptions<OrganizerStripeUrlsQueryVariables>;

export function organizerStripeUrls(options: OrganizerStripeUrlsOptions) {
  return executeQuery<OrganizerStripeUrlsQuery, OrganizerStripeUrlsQueryVariables>(OrganizerStripeUrlsDocument, options);
}

export type OrganizerStripeUrlsSelections = OrganizerStripeUrlsQuery['site'];
