import * as React from 'react'

import { Classroom } from 'app2/api';
import { defaultFieldProps, Dropdown, DropdownProps, useFormInfo, OptionText, useFormSubscription, useSafeState } from 'app2/components';

// note the ClassroomsDropdown handles display as well
// as edit mode due to wanting to encapsluate fetching
// of classrooms when editing which is problematic because
// there is no site property on student (so a requery on
// the student when the user chooses another site won't
// cause the new classroom list to come down because the
// student has no site property).  we could as an alternative
// just set the student to the first classroom in the site
// when changing sites and then the student query would pull down
// the new sites...but that would prevent a cancel action as
// the data would have already been changed

// note that ClassroomsDropdown expects it "value"
// to be a classroom object (id and name, name so it can handle
// display mode w/o having to load all classrooms), and independently 
// you must provide a site_id

type ClassroomWithGrade = Pick<Classroom, 'id'| 'name' | 'grade'>;

export interface ClassroomsDropdownProps extends Omit<DropdownProps, 'options'> {
  getClassrooms:(site: string, pause?:boolean) => ClassroomWithGrade[];
  site:string;
  readOnly?:boolean;
}

export function ClassroomsDropdown(props:ClassroomsDropdownProps) {
  const {site, getClassrooms:getClassroomsCb, readOnly, ...remaining} = props;
  const info = useFormInfo();
  const [opened, setOpened] = useSafeState(false);
  const classrooms = getClassrooms();

  // add a form subscription so we update when the site changes
  useFormSubscription();

  function getClassrooms() {
    const classrooms = props.getClassrooms?.(site, !opened || !site);

    return React.useMemo(() => {
      return !classrooms
        ? props.value !== undefined && props.value !== null 
          ? [classroomToOption(props.value)]
          : []
        : classrooms?.map(classroomToOption);
    }, [classrooms, site, props.value]);
  }

  function render() {
    return info.editing && !readOnly
      ? <Dropdown {...remaining} options={classrooms} onDropdown={onDropdown} placeholder={!site ? 'Select a school to select a classroom' : 'Select a classroom'} />
      : <OptionText value={props.value} options={classrooms} />
  }

  function onDropdown(open:boolean) {
    if (opened) {
      return;
    }

    setOpened(true);
  }

  return render();
}

function classroomToOption(classroom:ClassroomWithGrade) {
  return {
    label: classroom.grade ? `${classroom.name} (${classroom.grade})` : classroom.name, 
    value: classroom
  };
}


ClassroomsDropdown.fieldProps = {
  ...defaultFieldProps,
  readOnlyProperty: 'readOnly'
}