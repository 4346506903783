import { ErrorWithStringPath } from 'app2/api';
import { ErrorWithPath, ErrorPathSegment, ErrorPathSegmentType } from 'app2/components';

export function parsePaths(errors:ErrorWithStringPath[]) {
  return errors.map(error => {
    return {
      ...error,
      path: error.path ? error.path.map(stringToSegment) : []
    } as ErrorWithPath
  });
}

export function stringToSegment(segment:string):ErrorPathSegment {
  if (segment.startsWith('index-')) {
    return {type: ErrorPathSegmentType.index, value: segment.substring('index-'.length)};
  }

  if (segment.startsWith('id-')) {
    return {type: ErrorPathSegmentType.id, value: segment.substring('id-'.length)};
  }

  if (segment.startsWith('client_generated_id-')) {
    return {type: ErrorPathSegmentType.id, value: segment.substring('client_generated_id-'.length)};
  }

  return {type: ErrorPathSegmentType.property, value: segment};
}

export function segmentToString(segment:ErrorPathSegment):string {
  if (segment.type == ErrorPathSegmentType.index) {
    return 'index-' + segment.value;
  }
  else
  if (segment.type == ErrorPathSegmentType.id) {
    return 'id-' + segment.value;
  }
  else {
    return segment.value;
  }
}

// for any id path segment pointing to an element in an array that has an id
// convert it to an id segment.  this is such that when the api structures
// don't entirely map to the in memory ui structure, that it auto-translates.
// this specifically applies to a list of things in the display where only some
// of the items in the list are being submitted, such that the indexes won't match
// between the two.

export function indexPathToIdPath(path:(number | string)[], value:any) {
  return pathValueTraversal(path, value, (segment:ErrorPathSegment, segmentNo:number, segmentValue:any) => {
    const updated = {...segment};

    if ((updated.type == ErrorPathSegmentType.index || updated.type == ErrorPathSegmentType.property) && Array.isArray(segmentValue)) {
      const item = segmentValue[Number(updated.value)];

      if (item?.id) {
        updated.value = item.id;
        updated.type = ErrorPathSegmentType.id;
      }
    }

    return updated;
  });
}

// helper function that can traverse both a path, and the value the path applies to, at
// the same time, and allow that path to be changed, and then returns the updated path

export function pathValueTraversal(path:(number | string)[], value:any, cb:(segment:ErrorPathSegment, segmentNo:number, segmentValue:any) => ErrorPathSegment) {
  return path.map((p, index) => {
    const segment = stringToSegment(p.toString());
    const updatedSegment = cb(segment, index, value);

    let nextValue = null;

    if (value !== undefined && value !== null) {
      if (segment.type == ErrorPathSegmentType.index || segment.type == ErrorPathSegmentType.property) {
        nextValue = value[segment.value];
      }
      else 
      if (Array.isArray(value)) {
        nextValue = value.find(item => item?.id == segment.value);
      }
    }

    value = nextValue;

    return segmentToString(updatedSegment);
});
}
