import * as React from 'react'

import { Field } from 'app2/components';

import { SiteLink, QueryList } from '../shared';

import { useAdminUserSitesQuery } from './generated'

export function Sites() {
  return <QueryList icon='Home' title='Schools' query={useAdminUserSitesQuery} name='sites' fields={[
    <Field name='name' label='School name' component={SiteLink} />,
  ]} />
}