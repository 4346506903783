import * as React from 'react';
import { startCase } from 'lodash-es';

import { TextArea, TextAreaKind, HBox, VBox, Subtitle2 } from 'app/app2/components';

import { Demo } from './Demo';

export function TextAreaDemos() {
  return <VBox>
    <Demo name='Basic states'>
      {renderKind('normal')}
      {renderKind('error')}
      {renderKind('disabled')}
    </Demo>
    <Demo name='Auto-sized'>
      <TextArea width='80%' autoSize />
    </Demo>
    <Demo name='Auto-sized, min height 200 px'>
      <TextArea width='80%' autoSize minHeight='100px' />
    </Demo>
    <Demo name='Center vertical alignment'>
      <TextArea vAlign='center' width='80%' height='200px' />
    </Demo>
    <Demo name='Center vertical alignment, autoSize, min height 200 px'>
      <TextArea vAlign='center' width='80%' autoSize minHeight='200px' />
    </Demo>
    <Demo name='Bottom vertical alignment'>
      <TextArea vAlign='bottom' width='80%' height='200px' />
    </Demo>
    <Demo name='Center horizontal alignment'>
      <TextArea hAlign='center' width='80%' height='200px' />
    </Demo>
    <Demo name='Right horizontal alignment'>
      <TextArea hAlign='right' width='80%' height='200px' />
    </Demo>
    <Demo name='Right horizontal bottom vertical alignment'>
      <TextArea hAlign='right' vAlign='bottom' width='80%' height='200px' />
    </Demo>
    <Demo name='Right horizontal bottom vertical alignment, auto size, min height 200px'>
      <TextArea hAlign='right' vAlign='bottom' width='80%' autoSize minHeight='200px' />
    </Demo>
    <Demo name='Various styles'>
      <TextArea m='10px' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer' />
    </Demo>
    <Demo name='Custom CSS'>
      <TextArea css={{
        ':hover': {
          border: 'solid 10px red'
        }}} placeholder='Mouse over me' />
    </Demo>
  </VBox>
}

function renderKind(name:TextAreaKind) {
  return <HBox hItemSpace='15px' pb='15px' vAlign='center'>
    <Subtitle2 width='70px'>{startCase(name)}</Subtitle2>
    <TextArea kind={name} placeholder='Placeholder' />
    <TextArea kind={name} value='Value' onChange={() => {}} />
  </HBox>
}
