import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSitesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrganizerSitesQuery = {
  user?: { sites?: Array<{ id: string; name?: string | null | undefined; slug: string; city?: string | null | undefined; state?: string | null | undefined }> | null | undefined } | null | undefined;
};

export const OrganizerSitesDocument = gql`
  query OrganizerSites {
    user: currentUser {
      sites {
        id
        name
        slug
        city
        state
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSitesArgs = MakeOptional<UseQueryArgs<OrganizerSitesQueryVariables, OrganizerSitesQuery>, 'query'>;

export function useOrganizerSitesQuery(options: OrganizerSitesArgs = {}) {
  return useQuery<OrganizerSitesQueryVariables, OrganizerSitesQuery>({ query: OrganizerSitesDocument, ...options });
}

export type OrganizerSitesOptions = ExecQueryOptions<OrganizerSitesQueryVariables>;

export function organizerSites(options: OrganizerSitesOptions) {
  return executeQuery<OrganizerSitesQuery, OrganizerSitesQueryVariables>(OrganizerSitesDocument, options);
}

export type OrganizerSitesSelections = OrganizerSitesQuery['user'];
