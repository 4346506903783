import * as React from 'react'

import { HBox } from '../../Box';
import { Text } from '../../Text';
import { addInfoTip } from '../../panel';

import { ColHeaderRendererProps } from './ColHeaderRendererProps';
import { ColMenu } from './ColMenu';

export function ColHeaderRenderer(props:ColHeaderRendererProps) {
  const {table, col, colPos, style:propsStyle, ...remaining} = props;
  const style = Object.assign({}, props.style);
  delete style.overflow;

  const selected = props.table.selections.containsCol(props.colPos)

  return <HBox vAlign='center' hAlign='left' bg={selected ? 'primarySelected' : '#E5E5E5'} p='$10' style={style} {...remaining}>
    <Text text='subtitle2' mr='$10' fontSize='formlabel'>{addInfoTip(props.col.label || props.col.name, props.col.infoTip)}</Text>
    <ColMenu table={props.table} col={props.col} colPos={props.colPos} />
  </HBox>;
}
