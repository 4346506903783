import { Command } from "../../undo";
import { Point } from "../../dom-utils";

import { DataTable, DataCellPositionIds, Selection } from "..";
import { ObservableCollectionCommand, CollectionEventType, CollectionEvent } from "../collection";

export class RemoveRowCommand<T> implements Command, ObservableCollectionCommand<T> {
  table:DataTable;
  ids:DataCellPositionIds[];
  rows:number[];
  infos:{row:number, id:string | number, item:T}[];
  selection:Selection;

  constructor(table:DataTable, rows:number[]) {
    this.table = table;
    this.ids = rows.map(row => table.posToIds(new Point(0, row)));
    this.rows = rows;
  }

  get isCollectionCommand() {
    return true;
  }

  get collectionEvents():CollectionEvent<T>[] {
    return this.infos.map((info, index) => {
      return {
        collection: this.table.data,
        type: CollectionEventType.delete,
        position: info.row,
        id: info.id,
        item: info.item
      }
    })
  }

  focus():void {
    const table = this.table;
    table.focus();

    table.setSelection(this.selection.clone(), true);
  }

  do():void {
    this.saveSelection();

    const table = this.table;

    // remove the rows in reverse so that lower positions don't affect indexes of higher positions
    // so that we are deleting 5, 3, 2, 1, etc.
    this.infos = this.ids.map(id => {
      const row = table.idsToPos(id).row;
      const item = table.data.getItem(row);

      return {row, id:id.rowId, item}
    }).sort((a, b) => a.row - b.row).reverse();

    this.infos.forEach(info => {
      if (info.row >= table.editableData.length) {
        return;
      }

      table.editableData.remove(info.row)
    });

    table.makeSelectionValid();
  }

  undo():void {
    // reverse the order, so that we are adding 1, 2, 3, 5, etc. because the positions
    // we record were relative to when all the rows were there in the table
    this.infos.reverse().forEach(info => this.table.editableData.insert(info.row, info.item));

    this.restoreSelection();
  }
  
  saveSelection() {
    this.selection = this.table.selection.clone();
  }

  restoreSelection() {
    this.table.selection = this.selection.clone();
    this.table.makeSelectionValid();
  }

  redo():void {
    this.do();
  }
}
