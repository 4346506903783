import { formatAge } from 'app2/api';
import { InputField } from 'app2/components'

import { AgeInput } from './AgeInput';

export const AgeField = {
  ...InputField,
  edit: AgeInput,
  display: {format: formatAge},
  disabled: false
}
