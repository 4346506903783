import * as React from 'react';
import { DateField, Field, SHORT_DATE_TIME_FORMAT } from 'app2/components';

import { SearchableList, EventLink, UserLink } from '../shared';
import { AdminPage } from '../AdminPage';

import { useAdminEventsQuery, AdminEventsSelections } from './generated';

export function Events() {
  function render() {
    return <AdminPage title='Events'>
      <SearchableList query={useAdminEventsQuery} name='events' fields={[
        <Field name='id' label='ID name' component={EventLink} />,
        <Field name='createdAt' label='Sent' component={DateField} dateFormat={SHORT_DATE_TIME_FORMAT}/>,
        <Field name='eventName' label='Event' />,
        <Field name='contact.name' label='Recipient' component={UserLink} />,
        <Field label='Email' format={(val:AdminEventsSelections) => val?.contact?.email || val?.recipient} />,
        <Field name='serviceType' label='Type' />,
        <Field name='id' component={<EventLink label='View' />} />,
      ]} />
    </AdminPage>
  }

  return render();
}
