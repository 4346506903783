import * as React from 'react'

import { defaultFieldProps } from 'app2/components';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useAdminCompaniesQuery, AdminCompaniesSelections } from './generated';

export function CompaniesDropdown(props:Omit<SearchDropdownProps, 'search'>) {
  function render() {
    return <SearchDropdown query={useAdminCompaniesQuery} renderItem={renderCompany} {...props} />
  }

  function renderCompany(company:AdminCompaniesSelections) {
    return `${company.name} (${company.id})`
  }

  return render();
}

CompaniesDropdown.fieldProps = {
  ...defaultFieldProps,
  readOnlyProperty: 'readOnly'
}
