import * as React from 'react'

import { Box } from '../Box';

import { ScrollAxis, ScrollbarProps } from './Scrollbar'

export const StandardScrollbar = React.forwardRef((props: ScrollbarProps, ref:any) => {
  const {axis, size, scrollbarSize, onScroll, ...remaining} = props;
  const scrollbar = ref || React.useRef<HTMLElement>();

  function render() {
    return axis == ScrollAxis.vertical
      ? <Box ref={scrollbar} onScroll={handleScroll} width={scrollbarSize + 'px'} overflowX='hidden' overflowY='scroll'  {...remaining}><Box width={scrollbarSize + 'px'} height={size + 'px'} display='inline-block' /></Box>
      : <Box ref={scrollbar} onScroll={handleScroll} height={scrollbarSize + 'px'} overflowX='scroll' overflowY='hidden' {...remaining}><Box height={scrollbarSize + 'px'} width={size + 'px'} display='inline-block' /></Box>
  }

  function handleScroll() {
    const start = axis == ScrollAxis.vertical ? scrollbar.current.scrollTop : scrollbar.current.scrollLeft;

    onScroll?.(start);
  }

  return render();
})

StandardScrollbar.displayName = 'StandardScrollbar';