import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { startCase } from 'lodash-es';

import { Color, Palette, PaletteSection, Text, HBox, VBox } from 'app/app2/components';

import { Demo } from './Demo';

export function ColorDemos() {
  const theme = React.useContext(ThemeContext);
  const palette:Palette = theme.palette;

  return <VBox>
    {Object.keys(palette).map(name=> renderSection(palette, name as keyof Palette))}
  </VBox>
}

function renderSection(palette:Palette, name:keyof Palette) {
  const section = palette[name];

  return <Demo key={name} name={name} code={false}>
    <HBox>{Object.keys(section).map(color => renderColor(section, color as keyof PaletteSection))}</HBox>
  </Demo>
}

function renderColor(section:PaletteSection, name:keyof PaletteSection) {
  const color = section[name] as Color;

  return <VBox key={name} paddingRight='$30'>
    <VBox borderRadius='standard' boxShadow='box' height='200px' width='200px' padding='$15' marginBottom='$15'>
      <VBox borderRadius='standard' background={color.hex} height='100%' width='100%' />
    </VBox>
    <Text>{startCase(name)}</Text>
    <Text text='body'>Hex: {color.hex}</Text>
    <Text text='body'>RGB: {color.rgb}</Text>
  </VBox>
}
