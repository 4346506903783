import { PreferenceStore } from '../../preferences';

import { DataTableColumn, colId } from "./DataTableColumn";
import { ExpressionNode } from '../advanced-filter';

export type ColPref<T = any, P extends keyof T = any> = Pick<DataTableColumn<T, P>, 'name' | 'hidden' | 'width' | 'sort'| 'filter'>;

export type SkipColPrefs = (keyof ColPref | 'order')[];

export const defaultColWidth = 138;

export class TablePrefs<T = any, P extends keyof T = any> {
  id?:string;
  updatedAt?:string;
  cols:ColPref<T, P>[];
  lockedCol?:number;
  advancedFilter?:ExpressionNode;

  version?:string;

  // loads datatable columns from preferences
  //  - the version attribute is saved in the preference such
  //    that the provided verson differs from the one saved in
  //    preferences, the preferences are ignored.  this allows 
  //    adding/removing/changing columns to occur and not have them
  //    obscurred by a preference.

  static load<T, P extends keyof T = any>(prefStore:PreferenceStore, name:string, version:string, target:TablePrefs<T, P>, prefsSkipAttributes?:SkipColPrefs):TablePrefs<T, P> {
    const pref = prefStore?.load(name) as TablePrefs<T, P>;

    if (!pref || pref.version != version) {
      // return a copy because thats what applyPrefs does
      // and react is dependent on there being different objects
      // to know when to update (so a copy is important in case
      // the preference was just reset)
      return {
        id: target.id,
        updatedAt: target.updatedAt,
        lockedCol: target.lockedCol,
        cols: target.cols.slice()
      }
    }

    return this.applyPrefs(pref, target, prefsSkipAttributes);
  }

  static save(prefStore:PreferenceStore, name:string, version:string, target:TablePrefs, prefsSkipAttributes?:SkipColPrefs) {
    prefStore?.save(name, {
      version,
      ...this.createPrefs(target, prefsSkipAttributes)
    })
  }

  static loadFromLocalStorage<T>(name:string, table:TablePrefs<T>, skipAttributes?:SkipColPrefs):TablePrefs<T> {
    return this.applyPrefs(localStorage.getItem(name) || '', table, skipAttributes);
  }

  static applyPrefs<T>(prefsOrString:string | TablePrefs<T>, target:TablePrefs<T>, skipAttributes?:SkipColPrefs):TablePrefs<T> {
    const orderedNames: any = {};
    target.cols.forEach((col, index) => (orderedNames[colId(col)] = index));

    let cols = target.cols.slice();
    let prefs:TablePrefs<T>;
    
    try {
      prefs = typeof prefsOrString === 'string' ? JSON.parse(prefsOrString) : prefsOrString;
    }
    catch(e) {
      prefs = {cols:[]};
    }

    prefs.cols = Array.isArray(prefs.cols) ? prefs.cols : [];

    const visibleCols = new Set<string>();
    const skipAttributesSet = new Set(skipAttributes || []);

    prefs.cols.forEach((colPref, index) => {
      const colIndex = orderedNames[colPref.name];

      if (!colPref.hidden || skipAttributesSet.has('hidden')) {
        visibleCols.add(colId(colPref));
      }

      if (colIndex != undefined) {
        const col = cols[colIndex] = {...target.cols[colIndex]};

        if (!skipAttributesSet.has('width') && 'width' in colPref) {
          col.width = Math.max(colPref.width, defaultColWidth) || defaultColWidth;
        }

        if (!skipAttributesSet.has('hidden')) {
          col.hidden = colPref.hidden;
        }

        if (!skipAttributesSet.has('sort')) {
          col.sort = colPref.sort;
        }

        if (!skipAttributesSet.has('filter')) {
          col.filter = colPref.filter;
        }

        orderedNames[colId(col)] = index;
      }
    });

    // any cols not found in the prefs is hidden
    cols = cols.map(c => ({...c, hidden:!visibleCols.has(colId(c))}));

    if (!skipAttributesSet.has('order')) {
      cols = cols.sort((a, b) => orderedNames[colId(a)] - orderedNames[colId(b)])
    }

    return {
      id: prefs.id,
      updatedAt: prefs.updatedAt,
      lockedCol: prefs.lockedCol,
      cols,
      advancedFilter: prefs.advancedFilter
    }
  }

  static saveToLocalStorage<T = any>(name:string, prefs:TablePrefs<T>, skipAttributes?:SkipColPrefs) {
    localStorage.setItem(name, JSON.stringify(this.createPrefs(prefs, skipAttributes)));
  }

  static createPrefs<T = any>(prefs: TablePrefs<T>, skipAttributes?:SkipColPrefs):TablePrefs {
    const skipAttributesSet = new Set(skipAttributes || []);

    return {
      id: prefs.id,
      updatedAt: prefs.updatedAt,
      lockedCol: prefs.lockedCol,
      cols: prefs.cols.map(col => {
        return {
          name: !skipAttributesSet.has('name') ? colId(col) : undefined,
          // because the datatable has a default width, we need to apply one or the columns will disappear
          width: !skipAttributesSet.has('width') ? col.width || defaultColWidth: undefined, 
          hidden: !skipAttributesSet.has('hidden') ? col.hidden : undefined,
          sort: !skipAttributesSet.has('sort') ? col.sort : undefined,
          filter: !skipAttributesSet.has('filter') ? col.filter : undefined
        }
      }),
      advancedFilter: prefs.advancedFilter
    }
  }
}