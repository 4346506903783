import { preference } from '../shared';
import { AuthorizedPreferences } from '../shared-authorized';
import { OrganizerSitePreferences } from './OrganizerSitePreferences';
import { OrganizerSeasonPreferences } from './OrganizerSeasonPreferences';

export const organizerPreferencesKeys = {
  site: 'site',
  showWeekends: 'showWeekends',
  reportsAdjustments: 'reportsAdjustments',
  reportsCoursesOverview: 'reportsCoursesOverview',
  reportsCompliance: 'reportsCompliance',
  reportsDonations: 'reportsDonations',
  reportsCoursesTransactions: 'reportsCoursesTransactions',
  providersTable: 'providersTable',
};

export class OrganizerPreferences extends AuthorizedPreferences {
  get site(): string {
    return this.load(organizerPreferencesKeys.site);
  }

  set site(value: string) {
    this.save(organizerPreferencesKeys.site, value);
  }

  get reportsAdjustments(): string {
    return this.load(organizerPreferencesKeys.reportsDonations);
  }

  set reportsAdjustments(value: string) {
    this.save(organizerPreferencesKeys.reportsAdjustments, value);
  }

  get reportsCoursesOverview(): string {
    return this.load(organizerPreferencesKeys.reportsCoursesOverview);
  }

  set reportsCoursesOverview(value: string) {
    this.save(organizerPreferencesKeys.reportsCoursesOverview, value);
  }

  get reportsCompliance(): string {
    return this.load(organizerPreferencesKeys.reportsCompliance);
  }

  set reportsCompliance(value: string) {
    this.save(organizerPreferencesKeys.reportsCompliance, value);
  }

  get reportsDonations(): string {
    return this.load(organizerPreferencesKeys.reportsDonations);
  }

  set reportsDonations(value: string) {
    this.save(organizerPreferencesKeys.reportsDonations, value);
  }

  get reportsCoursesTransactions(): string {
    return this.load(organizerPreferencesKeys.reportsCoursesTransactions);
  }

  set reportsCoursesTransactions(value: string) {
    this.save(organizerPreferencesKeys.reportsCoursesTransactions, value);
  }

  get providersTable(): string {
    return this.load(organizerPreferencesKeys.providersTable);
  }

  set providersTable(value: string) {
    this.save(organizerPreferencesKeys.providersTable, value);
  }

  sitePreferences(site: string) {
    return new OrganizerSitePreferences(site);
  }

  seasonPreferences(season: string) {
    return new OrganizerSeasonPreferences(season);
  }

  // currently the season and school level will share the same prefs
  @preference
  promotionalDiscountsTable:string;

  @preference
  studentDiscountsTable:string;
}

export const organizerPreferences = new OrganizerPreferences();
