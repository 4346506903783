import * as React from 'react';

import { HBox } from '../../Box';
import { Button } from '../../Button';
import { Icon } from '../../icons';
import { Input } from '../../Input';
import { NotificationManager } from '../../notification';
import  { MultiContext } from '../../utils';

import { ActionButton } from './ActionButton';
import { DataTableHeaderContext } from './DataTableHeader';

export function FindAction() {
  const header = React.useContext<DataTableHeaderContext>(MultiContext).header;
  const [showFind, setShowFind] = React.useState(false)
  const findInput = React.useRef<HTMLInputElement>();

  function render() {
    return <>
      {renderFindButton()}
      {renderFind()}
    </>
  }

  function renderFindButton() {
    return <ActionButton key='find' selection={false} focus={false} icon='Search' onClick={onShowFind} shortcut={{cmdCtrl:true, key:'f', focus:header.table?.rootElement}}>Find</ActionButton>
  }

  function renderFind() {
    if (!showFind) {
      return <></>;
    }

    return <HBox vAlign='center' hItemSpace='$8' mr='$16'>
      <Input autoFocus ref={findInput} maxHeight='34px' minWidth='150px' onKeyDown={onFindKeyDown} onChange={onFindChange} />
      <Button small onClick={onFindNext}>Next</Button>
      <Icon name='XCircle' size='small' onClick={onToggleFind} />
    </HBox>
  }

  function onFindKeyDown(event:React.KeyboardEvent) {
    if (event.key === 'Enter') {
      onFindNext();
    }
    else
    if (event.key === 'Escape') {
      onToggleFind();
      header.table?.focus();
    }
  }

  function onShowFind() {
    if (!showFind) {
      onToggleFind();
    }

    findInput.current?.focus();
  }

  function onToggleFind() {
    const updatedShowFind = !showFind;

    setShowFind(updatedShowFind);

    if (!updatedShowFind) {
      updateFindText(undefined);
    }
    else {
      findInput.current?.focus();
    }
  }
    
  function onFindChange() {
    updateFindText(findInput.current.value);
  }

  function onFindNext() {
    updateFindText(findInput.current.value);

    const pos = header.table.findText(header.findText);

    if (pos) {
      header.table.select(pos, true);
    }
    else {
      NotificationManager.add({ type: 'warning', message: `${findInput.current.value} not found.` });
    }
  }

  function updateFindText(text:string) {
    header.findText = text;
    header.forceUpdate();
  }

  return render();
}
