import * as React from 'react'

import { InputField, Form, RepeatingSectionFieldProps, Panel, PanelProps, RepeatingSection, Section, Text, useForm } from 'app2/components';

// convience class that will to a search with a param named term

type Api = (options:{variables:{term:any}, debounce: {delay: number}, hideLoader?:boolean}) => any;

interface Props<T> extends Omit<PanelProps, 'type' | 'name'> {
  query:Api;
  variables?:(term:string) => any;
  name:keyof T;
  fields:RepeatingSectionFieldProps<T>[];
  description?:string;
}

export function SearchableList<T = any>(props:Props<T>) {
  const {query, variables, name, fields, description, ...remaining} = props;

  const inputForm = useForm({term: ''}, []);
  const [result] = query({variables: {term: variables ? variables(inputForm.values.term) : inputForm.values.term}, debounce: {delay: 350}, hideLoader: true});
  const items = result?.data;

  function render() {
    return <Panel icon='Search' title='Search' type='none' {...remaining}>
      <Form editing={true} form={inputForm} onNavigation='nothing' overflow='unset'>
        <Section label='Search for' name='term' component={InputField} />
      </Form>

      <Form editing={false} initialValues={items} overflow='unset'>
        {description && <Text text='subtitle2'>{description}</Text>}
        <RepeatingSection name={name} fields={fields} equalWidths={false} />
      </Form>
    </Panel>
  }

  return render();
}