import * as React from 'react';

import { Grade, Student, Course } from 'app2/api';
import { FieldInfo } from 'app2/components'

import { gradeOptions } from './gradeOptions';
import { useSiteGradesQuery } from './generated'

export interface GradeSiteProps {
  site?:string;
  course?:Partial<Pick<Course, 'grades' | 'gradeGroup'>>;
  info?:FieldInfo<Student | Course>;
}

export function getSiteFromGradeInfo(siteOrGradeInfo:string | GradeSiteProps) {
  if (typeof siteOrGradeInfo == 'string') {
    return siteOrGradeInfo;
  }

  const studentRecord = siteOrGradeInfo.info?.record as Student;

  // we use site over course or student because even if the student is assigned
  // to a different school we want to use the sites labels.

  if (studentRecord?.classroom?.siteId) {
    return studentRecord.classroom.siteId;
  }

  const courseRecord = siteOrGradeInfo.info?.record as Course;

  return siteOrGradeInfo?.site ||
    courseRecord?.siteId ||
    courseRecord?.site?.id || 
    courseRecord?.season?.site?.id ||
    (siteOrGradeInfo.course as any)?.site?.id;
}

export function useGradeOptions(siteOrGradeInfo:string | GradeSiteProps, includeHidden:boolean = true, pause?:boolean, hideNA?:boolean) {
  const site = getSiteFromGradeInfo(siteOrGradeInfo);
  const [result] = useSiteGradesQuery({variables:{id: site}, requestPolicy: 'cache-first', pause: pause || !site});

  const grades = React.useMemo(() => {
    const options = (result.data?.site?.grades || gradeOptions).filter(g => includeHidden || !(g as Grade).hidden)
    return hideNA ? options.filter(g => g.label !== "N/A") : options;
  }, [result.data?.site?.grades, site, includeHidden, pause, hideNA]);
  
  return pause ? null : grades;
}
