import * as React from 'react'

import { MultiContext } from 'app2/components'

import { CurrentUserSelections} from './generated'

export interface CurrentUserInfo {
  user:CurrentUserSelections & {impersonating?:boolean, homeroom?:boolean};
  fetching:boolean;
  stale:boolean;
  // same as fetching | stale
  loading:boolean;
  reexecute:() => void;
}

export interface CurrentUserInfoContext {
  user:CurrentUserInfo;
}

export function useCurrentUser() {
  const user = React.useContext<CurrentUserInfoContext>(MultiContext).user;
  return user || {user: null, fetching: false, stale: undefined, loading: false, reexecute: () => void 0};
}
