import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { baseAPIUrl } from 'app/helpers/environments';

import { GuestCart } from '../parent/';

import { AuthTokenService } from './AuthTokenService';
import { CLIENT_VERSION } from './clientVersion';
import { ApiVersion } from './ApiVersion';

// Adds headers to requests.
function authRequestInterceptor(config: AxiosRequestConfig) {
  Object.assign(config.headers, buildHeaders());

  return config;
}

export function buildHeaders() {
  return { 
    'Client-Version': CLIENT_VERSION, 
    'Api-Version': ApiVersion.version, 
    'log-rocket': window.__lrs, 
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    'http.referer': window.location.href,
    ...buildAuthHeaders(),
    ...GuestCart.headers
  };
}

function buildAuthHeaders() {
  const token = AuthTokenService.loadPrimaryToken();
  const tokenValid = AuthTokenService.validate(token);
  if (tokenValid) {
    return AuthTokenService.toHeaders(token);
  }

  return {};
}

function handleImpersonation(response: AxiosResponse<any>) {
  if (!response.headers.shift_token) {
    return;
  }

  // Switch from impersonator token to impersonatee token.
  const impersonateeAuthToken = JSON.parse(response.headers.shift_token);
  const impersonatorAuthToken = AuthTokenService.loadPrimaryToken();
  AuthTokenService.storeImpersonatorToken(impersonatorAuthToken);
  AuthTokenService.storePrimaryToken(impersonateeAuthToken);
}

export function isImpersonatingUser() {
  return AuthTokenService.loadImpersonatorAuthToken() != null;
}

export function stopImpersonatingTokens():void {
  const impersonator = AuthTokenService.loadImpersonatorAuthToken();
  AuthTokenService.discardImpersonatorToken();
  AuthTokenService.storePrimaryToken(impersonator);
}

export function clearUserTokens() {
  AuthTokenService.discardImpersonatorToken();
  AuthTokenService.discardPrimaryToken()
}

// Stores auth tokens.
function authResponseInterceptor(response: AxiosResponse) {
  ApiVersion.checkVersion(response);

  if (response.headers.shift_token) {
    handleImpersonation(response);
  } else if (response.headers['access-token']) {
    AuthTokenService.storePrimaryToken(
      AuthTokenService.fromHeaders(response.headers)
    );
  }

  return response;
}

const axios = Axios.create({
  baseURL: baseAPIUrl(),
  withCredentials: true
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(authResponseInterceptor);

export { axios };
