import * as React from 'react';

import { BoxProps, RatioBox } from 'app2/components';

interface Props extends Omit<BoxProps, 'link'> {
  link: React.ReactNode;
  ratio: string;
}

export function SeeMoreCard(props: Props) {
  const { link, ratio, ...remaining } = props;

  return (
    <RatioBox
      boxShadow="box"
      borderRadius="standard"
      ratio={ratio}
      content={{ layout: 'vbox', p: '$15', hAlign: 'center', vAlign: 'center' }}
      {...remaining}
    >
      {link}
    </RatioBox>
  );
}
