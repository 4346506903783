import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentDatesWillChangeQueryVariables = Types.Exact<{
  inputs: Array<Types.EnrollmentDatesWillChangeInput> | Types.EnrollmentDatesWillChangeInput;
}>;

export type EnrollmentDatesWillChangeQuery = { enrollmentDatesWillChange?: Array<{ id: string; name: string; result: boolean }> | null | undefined };

export const EnrollmentDatesWillChangeDocument = gql`
  query EnrollmentDatesWillChange($inputs: [EnrollmentDatesWillChangeInput!]!) {
    enrollmentDatesWillChange(inputs: $inputs) {
      id
      name
      result
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentDatesWillChangeArgs = MakeOptional<UseQueryArgs<EnrollmentDatesWillChangeQueryVariables, EnrollmentDatesWillChangeQuery>, 'query'>;

export function useEnrollmentDatesWillChangeQuery(options: EnrollmentDatesWillChangeArgs = {}) {
  return useQuery<EnrollmentDatesWillChangeQueryVariables, EnrollmentDatesWillChangeQuery>({ query: EnrollmentDatesWillChangeDocument, ...options });
}

export type EnrollmentDatesWillChangeOptions = ExecQueryOptions<EnrollmentDatesWillChangeQueryVariables>;

export function enrollmentDatesWillChange(options: EnrollmentDatesWillChangeOptions) {
  return executeQuery<EnrollmentDatesWillChangeQuery, EnrollmentDatesWillChangeQueryVariables>(EnrollmentDatesWillChangeDocument, options);
}

export type EnrollmentDatesWillChangeSelections = EnrollmentDatesWillChangeQuery['enrollmentDatesWillChange'][0];

(EnrollmentDatesWillChangeDocument as any).additionalTypenames = ['EnrollmentDatesWillChangeOutput'];
