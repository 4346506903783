import * as React from 'react';

import { courseKindBehavior } from 'app2/views/shared-public';

import { openCourses } from '../../course/course-actions';

import { TableAction, TableActionProps } from './TableAction';

export function OpenEnrollmentAction(props:TableActionProps) {
  const behavior = courseKindBehavior[props.kind];

  if (behavior?.fields?.enrollmentPeriod === false) {
    return <></>;
  }
  
  return <TableAction icon='LogIn' label='Open enrollment' msg='Activities opened for enrollment' clickEvent='Click Open enrollment' ability='enrollable' mutation={openCourses} {...props} />
}
