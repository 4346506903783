import { ObservableCollection, ObservableCollectionObserver } from "./ObservableCollection";

export class ObservableCollectionProxy<T> implements ObservableCollection<T> {
  proxied:ObservableCollection<T>;

  constructor(proxied:ObservableCollection<T>) {
    this.proxied = proxied;
  }

  get length():number {
    return this.proxied.length;
  }

  getItem(position:number):T {
    return this.proxied.getItem(position);
  }

  getValue(position:number, property:string):any {
    return this.proxied.getValue(position, property);
  }

  getId?(position:number): number | string {
    return this.proxied.getId?.(position);
  }

  getIndex?(id:number | string): number {
    return this.proxied.getIndex?.(id);
  }

  observe?(observer:ObservableCollectionObserver<T>):void {
    if (this.proxied.observe) {
      this.proxied.observe(observer);
    }
  }

  unobserve?(observer:ObservableCollectionObserver<T>):void {
    if (this.proxied.unobserve) {
      this.proxied.unobserve(observer);
    }
  }
}
