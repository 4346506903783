import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
export type SiteAttendanceFieldsFragment = { season?: { id: string; name: string } | null | undefined; course: { id: string; name?: string | null | undefined } };

export const SiteAttendanceFieldsFragmentDoc = gql`
  fragment siteAttendanceFields on RosterItem {
    season {
      id
      name
    }
    course {
      id
      name
    }
  }
`;
