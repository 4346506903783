import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CustomChargeMutationVariables = Types.Exact<{
  enrollments?: Types.InputMaybe<Array<Types.EnrollmentInput> | Types.EnrollmentInput>;
}>;

export type CustomChargeMutation = {
  enrollmentsCustomCharge?: { enrollments?: Array<{ id: string; amount?: number | null | undefined; student?: { id: string } | null | undefined } | null | undefined> | null | undefined } | null | undefined;
};

export const CustomChargeDocument = gql`
  mutation CustomCharge($enrollments: [EnrollmentInput!]) {
    enrollmentsCustomCharge(enrollments: $enrollments) {
      enrollments {
        id
        amount
        student {
          id
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CustomChargeOptions = ExecMutationOptions<CustomChargeMutationVariables>;

export function customCharge(options: CustomChargeOptions) {
  return executeMutation<CustomChargeMutation, CustomChargeMutationVariables>(CustomChargeDocument, options);
}
