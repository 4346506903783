import * as React from 'react';

import { ProrationKind } from 'app2/api';
import { CheckboxField, FormModel, Panel, Section, useForm } from 'app2/components';

import { useCurrentSite } from '../shared';

import { organizerUpdateProration } from './gql';

export function Proration() {
  const { site } = useCurrentSite();
  const form = useForm({ proration: site?.proration }, [site?.proration]);

  return (
    <Panel icon="DollarSign" title="Dynamic subscription proration" onOk={onOk} type="toggle" form={form}>
      <Section label='Use proration' name='proration' description="When enabled, monthly subscription billing adapts to reflect the number of scheduled activity days in any given month and adjusts for days where no activities take place. For example, if an activity starts mid-September and ends mid-November, charges for September and November will be prorated, while October will be charged fully."
        format={(val:ProrationKind) => val && val != ProrationKind.None} parse={(val:boolean) => val ? ProrationKind.All : ProrationKind.None} component={CheckboxField} />
    </Panel>
  );

  async function onOk(form: FormModel) {
    const [success] = await organizerUpdateProration({ variables: { siteId: site.id, proration: form.values.proration }, error:form });

    return success;
  }
}
