import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserCurrentTimeEntriesQueryVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
}>;

export type UserCurrentTimeEntriesQuery = { userCurrentTimeEntries?: Array<{ checkedIn?: string | null | undefined; checkedOut?: string | null | undefined }> | null | undefined };

export const UserCurrentTimeEntriesDocument = gql`
  query UserCurrentTimeEntries($ownerType: EntityKind!, $ownerId: ID!) {
    userCurrentTimeEntries(ownerType: $ownerType, ownerId: $ownerId) {
      checkedIn
      checkedOut
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type UserCurrentTimeEntriesArgs = MakeOptional<UseQueryArgs<UserCurrentTimeEntriesQueryVariables, UserCurrentTimeEntriesQuery>, 'query'>;

export function useUserCurrentTimeEntriesQuery(options: UserCurrentTimeEntriesArgs = {}) {
  return useQuery<UserCurrentTimeEntriesQueryVariables, UserCurrentTimeEntriesQuery>({ query: UserCurrentTimeEntriesDocument, ...options });
}

export type UserCurrentTimeEntriesOptions = ExecQueryOptions<UserCurrentTimeEntriesQueryVariables>;

export function userCurrentTimeEntries(options: UserCurrentTimeEntriesOptions) {
  return executeQuery<UserCurrentTimeEntriesQuery, UserCurrentTimeEntriesQueryVariables>(UserCurrentTimeEntriesDocument, options);
}

export type UserCurrentTimeEntriesSelections = UserCurrentTimeEntriesQuery['userCurrentTimeEntries'][0];

(UserCurrentTimeEntriesDocument as any).additionalTypenames = ['TimeEntry'];
