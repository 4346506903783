import * as React from 'react';

import { BoxProps, HBox, Img } from 'app2/components';

import frameTopLeft from './frame-tl.png';
import frameBottomLeft from './frame-bl.png';
import frameBottomRight from './frame-br.png';
import frameBottomRight2 from './frame-br-2.png';
import frameBottomRight3 from './frame-br-3.png';

export interface PhotoFrameProps extends BoxProps {
  image: string;
}

export function PhotoFrame(props: PhotoFrameProps) {
  const { image, ...remaining } = props;

  return (
    <HBox position="relative" {...remaining}>
      <HBox
        background={`url(${image})`}
        backgroundSize="cover"
        backgroundPosition="top center"
        position="absolute"
        left="$30"
        top="$30"
        right="$30"
        bottom="$30"
        zIndex={1}
      />
      <Img
        src={frameTopLeft}
        position="absolute"
        top={0}
        left={0}
        width="75px"
        zIndex={2}
      />
      <Img
        src={frameBottomLeft}
        position="absolute"
        bottom={0}
        left={0}
        width="100px"
        zIndex={2}
      />
      <Img
        src={frameBottomRight}
        position="absolute"
        bottom={0}
        right={0}
        width="75px"
        zIndex={3}
      />
      <Img
        src={frameBottomRight2}
        position="absolute"
        bottom={0}
        right={0}
        width="75px"
        zIndex={0}
      />
      <Img
        src={frameBottomRight3}
        position="absolute"
        bottom={0}
        right={0}
        width="100px"
        zIndex={2}
      />
    </HBox>
  );
}
