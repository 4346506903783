import * as React from 'react'
import { useHistory, useParams } from 'react-router'
import { omit } from 'lodash-es'

import { FormModel, NotificationManager, Panel, Rating, Section, TextAreaField } from 'app2/components';

import { ParentPage } from '../shared';

import { parentReviewUpsert, useParentReviewQuery } from './generated';

export function CourseReviewForm() {
  const { token } = useParams<{ token: string }>();
  const [result] = useParentReviewQuery({ variables: { review:token } });
  const review = result.data?.review;
  const courseName = review?.enrollment?.course?.name;

  const history = useHistory();

  function render() {
    return <ParentPage title='Review your activity'>
      {courseName ? renderForm() : ''}
    </ParentPage>
  }

  function renderForm() {
    return <Panel icon='Star' title={courseName} subtitle='We’d love to get your feedback. In fact, go ahead and ask your student for their thoughts. Don’t worry, it’s not cheating!' initialValues={review || {}} type='edit' autoFocus={false} ok='Submit review' onOk={submit} onNavigation='nothing'>
      <Section label={`On a scale of 1-5, 5 being the best, please rate the quality of the content of ${courseName}`} name='overallRating' component={Rating} required />
      <Section label='Tell us about the instructor, did they manage the class well? Make it fun?' name='qualityRating' component={Rating} required />
      <Section label='Overall how would you rate this activity?' name='instructorRating' component={Rating} required />
      <Section label='Any additional comments? We love quotes from students!' name='comments' component={TextAreaField} />
    </Panel>
  }

  async function submit(form:FormModel) {
    const [success] = await parentReviewUpsert({variables: {review:token, attributes: omit(form.values, 'enrollment')}});

    if (success) {
      history.push('/family');
      NotificationManager.success('Your review has been saved!');
    }

    return success;
  }

  return render();
}
