import * as React from 'react';

import { getSymbol } from './util/getSymbol';
import { assert } from './util/assert';
import { getPrototypeChain } from './util/getPrototypeChain';

import { handler } from './Observable';
import { makeOwnPropertiesObservable } from './ObservableVisitor';

const observableSymbol: any = getSymbol('observable');
const observableTargetSymbol: any = getSymbol('observableTarget');

// this makes a class definition observable
// by inserting an obserable class definition in
// it's class hierachy.  if there is already one
// then this is a no-op
export function observable(
  clazz: any,
  warnReact: boolean = true,
  customProxyHandler: any = undefined,
  ignoreProps: any = undefined
) {
  const chain = getPrototypeChain(clazz);

  assert(chain.length > 1);

  const hasObservable =
    chain.find(prototype => prototype[observableSymbol]) != null;

  if (hasObservable) {
    return;
  }

  if (warnReact) {
    assert(
      !chain.find(base => base == React.Component),
      `Using @observable with React component ${clazz.name}.  Did you mean @observer?`
    );
  }

  const ctor = createObservablePrototype(
    chain[0],
    customProxyHandler,
    ignoreProps,
    true
  );

  copyStatics(clazz, ctor);

  return ctor;
}

const prototypes: WeakMap<any, any> = new WeakMap();

function createObservablePrototype(
  base: any,
  customProxyHandler: any = undefined,
  ignoreProps: any = undefined,
  es6 = true
) {
  let prototype = prototypes.get(base);

  if (!prototype) {
    if (es6) {
      prototype = class Observable extends base {
        constructor() {
          const that: any = super(...arguments);

          const observable = new Proxy(that, customProxyHandler || handler);
          that[observableSymbol] = observable;
          that[observableTargetSymbol] = that;

          makeOwnPropertiesObservable(observable, that, ignoreProps);

          return observable;
        }
      };
    } else {
      // this is the constructor of the new class
      // this is the same code as in Observable
      prototype = function() {
        //@ts-ignore
        const that: any = base.apply(this, arguments) || this;

        const observable = new Proxy(that, customProxyHandler || handler);
        that[observableSymbol] = observable;
        that[observableTargetSymbol] = that;

        makeOwnPropertiesObservable(observable, that, ignoreProps);

        return observable;
      };

      Object.setPrototypeOf(prototype, base);
    }

    prototype.displayName = base.name;
    prototype[observableSymbol] = true;
    prototypes.set(base, prototype);
  }

  return prototype;
}

function copyStatics(base: any, derived: any) {
  Object.getOwnPropertyNames(base).forEach(prop => {
    const skip: any = {
      length: true,
      name: true,
      prototype: true
    };

    if (skip[prop]) {
      return;
    }

    derived[prop] = base[prop];
  });
}
