import * as React from 'react';

import { HBox, Checkbox } from 'app2/components';

import { useCourseTableState } from './useCourseTableState';

interface Props {
  state:ReturnType<typeof useCourseTableState>;
}

export function ShowAdvanced(props:Props) {
  const {showAdvanced, setShowAdvanced} = props.state;

  function render() {
    return <><HBox flex={1}/><Checkbox label='Show advanced' checked={showAdvanced || false} onClick={() => setShowAdvanced(!showAdvanced)} clickEvent='Show advanced' /></>;
  }
  
  return render();
}
