import * as React from 'react';

import { useLifecycle } from './utils/useLifecycle';

import { UnsavedChangesManager } from './UnsavedChangesManager';

export interface UnsavedChangesProps {
  editing: boolean | (() => boolean);
  message: string;
}

export function UnsavedChanges(props: UnsavedChangesProps) {
  const id = React.useMemo(() => Date.now(), []);

  useLifecycle({onMount, onUnmount})

  function render() {
    UnsavedChangesManager.instance.update(prompt());
    return <></>;
  }

  function onMount() {
    UnsavedChangesManager.instance.add(prompt());
  }

  function onUnmount() {
    UnsavedChangesManager.instance.remove(id);
  }

  function prompt() {
    return {id, editing: props.editing, message: props.message};
  }

  return render();
}

UnsavedChanges.defaultProps = {
  editing: true,
  message: 'You are still editing.  Are you sure you want to leave the page?'
};
