import { axios } from 'app2/api';

export enum AppStatus {
  ok = 'ok',
  maintenance = 'maintenance'
}

export async function getStatus(): Promise<AppStatus> {
  try {
    const response = await axios.get<{status:AppStatus}>('/app_status');

    return response.data?.status || AppStatus.maintenance;
  } catch (e) {
    // Unfortunately Heroku's maintenance page has two issues that prevents us from using it as a way of 
    // returning a JSON response when in maintenance mode:
    //  - Heroku returns the custom maintenance page we specify wrapped in an iframe.
    //  - Heroku does not set CORS headers and therefore our request will always get returned
    //    as an error and not the customer HTML maintenance page.
    //
    // Additionally, web standards prevent returning any information when there's a network error
    // (despite that we can see the actual error in the Chrome network tab).  So there's no way to
    // check if the error was a CORS error or something like there's no internet connection.
    //
    // So the best we can do is assume all errors mean that the server is in maintenance mode.
    // Unfortunately, if the user has no internet connection, this will mean we will treat it like
    // maintenance mode and show the maintenance UI.
    
    return AppStatus.maintenance;
  }
}
