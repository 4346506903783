import * as React from 'react'

import { useStateWithDeps } from 'app2/components';

import { PreferenceStore } from '.';

export function usePreference<T extends PreferenceStore, P extends keyof T = keyof T>(preference:T, name:P, initialValue?:T[P], depencies?:any[]):[T[P], (v:T[P]) => void] {
  const [value, setValue] = useStateWithDeps<T[P]>(preference?.load(name) ?? initialValue as any, depencies || []);

  function setValues(newValue:T[P]) {
    if (preference) {
      preference.save(name, newValue);
    }

    setValue(newValue);
  }

  return [value, setValues];
}

