import * as React from 'react';
import { History } from 'history';
import { useParams, useHistory, useRouteMatch, match as Match, generatePath } from "react-router";

import { Option, Dropdown, OptionValue } from 'app/app2/components';

interface Params {
  options:Option[];
  preferences:any;
  preferenceName?:string;
  urlParameter:string;
  valueParameter:string;
  pause?:boolean;
}

export function useDropdownWithUrl<T>(params:Params) {
  const value = useParams<any>()[params.urlParameter];
  const history = useHistory();
  const match = useRouteMatch();
  let option: Option<T>;
  let needNav = false;

  React.useEffect(() => {
    if (needNav) {
      navigateTo(match, history, params, option.value, false);
    }
  });

  if (params.pause) {
    return;
  }
  
  const onChange = handleChange.bind(handleChange, match, history, params) as React.ChangeEventHandler<Dropdown>;

  if (value) {
    const option = find(params, value, params.options);

    if (option) {
      return {value: option.value, onChange};
    }
  }

  option = find(params, params.preferences[params.preferenceName || params.urlParameter], params.options);

  if (!option) {
    option = params.options[0];
  }

  if (option) {
    needNav = true;
  }

  return {value: null as any, onChange};
}

function handleChange(match:Match, history:History, params:Params, event:React.ChangeEvent<Dropdown>) {
  navigateTo(match, history, params, event.target.value, true)
}

function navigateTo(match:Match, history:History, params:Params, value:OptionValue, push:boolean) {
  params.preferences[params.preferenceName || params.urlParameter] = value.id;

  const url = createUrl(match, params, value, history);

  if (history.location.pathname == url) {
    return;
  }

  if (push) {
    history.push(url);
  }
  else {
    history.replace(url);
  }
}

function createUrl(match:Match, params:Params, value:OptionValue, history:History) {
  const urlParams = Object.assign({}, match.params, {[params.urlParameter]:value[params.valueParameter]} as any);
  const url = generatePath(match.path, urlParams);

  return url + history.location.search + history.location.hash;
}

function find(params:Params, value:string, options:Option[]) {
  if (!value) {
    return;
  }

  return options.find(option => option.value[params.valueParameter] == value || option.value.id == value);
}
