import * as React from 'react';
import { css } from 'styled-components';

import { createTextComponent, TextCustomProps } from './Text';
import { createComponent, componentStyles, ComponentProps } from './utils';
import { Box, BoxCustomProps } from './Box';

// example using the Table helper props
// <Table>
//   <Tr thead cells={['Col1', 'Col2', 'Col3']} />
//   <Tbody rows={[
//     ['v1', 'v2', 'v3'],
//     ['v1', 'v2', 'v3'],
//    ]} />
// </Table>

// <Table cols={['Col1', 'Col2', 'Col3']}
//   rows={[
//     ['v1', 'v2', 'v3'],
//     ['v1', 'v2', 'v3'],
//    ]} />
// </Table>

const propsToExclude = ['text'];
export type InnerTableProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>>;
export const InnerTable = createTextComponent<InnerTableProps>('table', false, propsToExclude);

interface TableProps extends InnerTableProps {
  // see Thead rows
  cols?:React.ReactNode[];
  // see Tr rows
  rows?:React.ReactNode[];

  // does what you wish CSS and cellSpacing/cellPadding
  // would do - provides row level padding
  gapX?:string|string[];
  gapY?:string|string[];
}

export function Table(props:TableProps) {
  let {cols, rows, children, gapX, gapY, css, ...remaining} = props;

  if (rows) {
    children = <>
      <Tbody rows={rows} />
      {children}
    </>
  }

  if (cols) {
    children = <>
      <Thead rows={cols} />
      {children}
    </>
  }

  if (gapX || gapY) {
    css = {...css, td:{px:gapX, py:gapY}};
  }
  
  return <InnerTable {...remaining} css={css} children={children} />;
}

export type InnerTheadProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>>;
export const InnerThead = createTextComponent<TheadProps>('thead', false, propsToExclude);

interface TheadProps extends InnerTheadProps {
  // shorthand for wrapping each row in a Tr
  // AND wrapping each item in each row in a Td
  // if rows is an array of arrays
  // don't use with children at the same time
  rows?:React.ReactNode[];
}

export function Thead(props:TheadProps) {
  const {rows, ...remaining} = props;
  let children = props.children;

  if (rows) {
    children = rows.map((row, index) => Array.isArray(row) ? <Tr key={index} cells={row}/> : <Tr key={index}>{row}</Tr>);
  }

  return <InnerThead {...remaining}>{children}</InnerThead>;
}

export type InnerTbodyProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>>;
export const InnerTbody = createTextComponent<InnerTbodyProps>('tbody', false, propsToExclude);

// same as Thead
interface TbodyProps extends InnerTbodyProps {
  // shorthand for wrapping each row in a Tr
  // AND wrapping each item in each row in a Td
  // if rows is an array of arrays
  // don't use with children at the same time
  rows?:React.ReactNode[];
}

export function Tbody(props:TbodyProps) {
  const {rows, ...remaining} = props;
  let children = props.children;

  if (rows) {
    children = rows.map((row, index) => Array.isArray(row) ? <Tr key={index} cells={row}/> : <Tr key={index}>{row}</Tr>);
  }

  return <InnerTbody {...remaining}>{children}</InnerTbody>;
}

export type InnerTrProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>>;
const InnerTr = createTextComponent<InnerTrProps>('tr', false, propsToExclude);

interface TrProps extends InnerTrProps {
  // shorthand for wrapping a Tr in a Thead
  thead?:boolean;
  // shorthand for wrapping each cell in a Td
  // don't use with children at the same time
  cells?:React.ReactNode[];
}

export function Tr(props:TrProps) {
  const {thead, cells, ...remaining} = props;
  let children = props.children;

  if (cells) {
    children = cells.map((cell, index) => <Td key={index}>{cell}</Td>);
  }

  let result = <InnerTr {...remaining}>{children}</InnerTr>;

  if (thead) {
    result = <Thead>{result}</Thead>
  }

  return result;
}

export type TdProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>>;
export const Td = createTextComponent<TdProps>('td', false, propsToExclude);

export type SpanProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>>;
export const Span = createTextComponent<SpanProps>('span', true, propsToExclude);

export type AnchorProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>;
export const A = createTextComponent<AnchorProps>('a', true, propsToExclude);

export type ImgProps = ComponentProps<TextCustomProps & Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'height' | 'width'>>;
export const Img = createComponent('img', propsToExclude)<ImgProps>`
  ${componentStyles}
` as React.ComponentType<ImgProps>;

export type UlProps = ComponentProps<BoxCustomProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>>;
export const Ul = createComponent('ul', propsToExclude)<UlProps>`
  ${componentStyles}
  
  li {
    list-style-type: disc;
    margin-left:20px;
  }
` as React.ComponentType<UlProps>;

export interface OlProps extends ComponentProps<BoxCustomProps & React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>> {
  number?:boolean;
}

export function Ol(props:OlProps) {
  let {className, number, start, ...remaining} = props;

  className = (className || '') + (number ? ' ol-number' : ' ol-no-number');

  //@ts-ignore
  return <Box as='ol' className={className} style={{counterReset: `item ${start -  1}`}} {...remaining} />
}

Ol.displayName = 'Ol';
Ol.defaultProps = {
  number: true
}

export type LiProps = ComponentProps<TextCustomProps & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>>;
export const Li = createTextComponent<LiProps>('li', true, propsToExclude);

Li.defaultProps = {
  display: 'list-item'
}

Li.displayName = 'Li';
