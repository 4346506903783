import * as React from 'react'

import { DateField, Field } from 'app2/components';

import { EventLink, UserLink, QueryList } from '../shared';

import { useAdminUserEventsQuery } from './generated'

export function Events() {
  return <QueryList icon='Activity' title='Events' query={useAdminUserEventsQuery} name='events' fields={[
    <Field name='id' label='ID name' component={EventLink} />,
    <Field name='createdAt' label='Sent' component={DateField} />,
    <Field name='eventName' label='Event' />,
    <Field name='contact.name' label='Recipient' component={UserLink} />,
    <Field name='contact.email' label='Email' />,
    <Field name='serviceType' label='Type' />,
  ]} />
}

