import moment from 'moment';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { isPlainObject } from 'lodash-es';

import { localStorageHelper } from 'app/helpers/localStorageHelper';

export const HOMEROOM_TOKEN = 'homeroom_token';
export const HOMEROOM_IMPERSONATOR_TOKEN = 'homeroom_impersonator_token';

export interface AuthToken {
  'access-token': string;
  client: string;
  uid: string;
  expiry: string;
}

// localStorage will return null if it can't find a key.
type TokenParam = AuthToken | null;

export class AuthTokenService {
  static storePrimaryToken(token: TokenParam) {
    if (!token) {
      return;
    }

    localStorageHelper.setItem(HOMEROOM_TOKEN, JSON.stringify(token));
  }

  static loadPrimaryToken() {
    return JSON.parse(localStorageHelper.getItem(HOMEROOM_TOKEN));
  }

  static discardPrimaryToken() {
    localStorageHelper.removeItem(HOMEROOM_TOKEN);
  }

  static storeImpersonatorToken(token: TokenParam) {
    if (!token) {
      return;
    }

    localStorageHelper.setItem(
      HOMEROOM_IMPERSONATOR_TOKEN,
      JSON.stringify(token)
    );
  }

  static loadImpersonatorAuthToken():AuthToken {
    return JSON.parse(localStorageHelper.getItem(HOMEROOM_IMPERSONATOR_TOKEN));
  }

  static discardImpersonatorToken() {
    localStorageHelper.removeItem(HOMEROOM_IMPERSONATOR_TOKEN);
  }

  static validate(token: TokenParam) {
    const valid = this.isValid(token);
    // if (!valid) {
    //   userLoggedOut('/login');
    // }

    return valid;
  }

  static isValid(token: TokenParam) {
    if (!token) {
      return false;
    }

    // Do not remove. Accounts for existing tokens missing the "expiry" property.
    if (!token.expiry) {
      return true;
    }

    const expiryMoment = moment.unix(Number(token.expiry));
    if (moment().isBefore(expiryMoment)) {
      return true;
    } else {
      return false;
    }
  }

  static toHeaders(token: TokenParam) {
    const headers: AxiosRequestConfig['headers'] = {};
    if (!token) {
      return headers;
    }

    const { 'access-token': accessToken, client, uid } = token;

    return {
      'access-token': accessToken,
      client,
      impersonating: AuthTokenService.loadImpersonatorAuthToken() != null,
      uid
    };
  }

  static fromHeaders(headers: AxiosResponse['headers'] | Headers) {
    let accessToken, client, uid, expiry;
    if (isPlainObject(headers)) {
      accessToken = headers['access-token'];
      client = headers.client;
      uid = headers.uid;
      expiry = headers.expiry;
    } else {
      accessToken = headers.get('access-token');
      client = headers.get('client');
      uid = headers.get('uid');
      expiry = headers.get('expiry')
    }
    if (!accessToken) {
      return null;
    }

    return {
      'access-token': accessToken,
      client,
      uid,
      expiry
    };
  }
}
