import * as React from 'react';

import { Team as TeamBase } from '../shared'

import { useFamilyTeamQuery } from './generated';
import {  } from './generated';

export function Team() {
  return <TeamBase query={useFamilyTeamQuery}  />
}
