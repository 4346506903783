import * as React from 'react';
import pluralize from 'pluralize';

import { CourseUtils, formatAges, Grade, Course } from 'app2/api';
import { BoxProps, HBox, FormLabel } from 'app2/components';

import { useGradeOptions } from '../grades';

interface Props extends BoxProps {
  course: Partial<Pick<Course, 'grades' | 'gradeGroup' | 'ageGroup' | 'ageMin' | 'ageMax'>>;
}

export function CourseGrades(props: Props) {
  const { course, ...remaining } = props;
  const usingGrades = course.gradeGroup != null || course.grades?.length > 0
  const usingAge = (course.ageGroup ?? course.ageMin ?? false) || (course.ageMax ?? false);
  const loaded = usingGrades || usingAge;
  const siteGrades = useGradeOptions(props, true, course.gradeGroup != null || (course as any)?.site?.grades != null);

  function render() {
    if (!loaded) {
      return <></>;
    }

    return <HBox p="$4" mb="$16" bg="enrollment" vAlign='center' borderRadius="standard" {...remaining}>
      {usingGrades ? renderGrades() : renderAges()}
    </HBox>
  }

  function renderGrades() {
    const grades = course.gradeGroup ? course?.gradeGroup : CourseUtils.formatCourseGrades(course, siteGrades as Grade[], false);
    const plural = grades.indexOf('-') != -1 ? 2 : 1;

    return <FormLabel>{pluralize('Grade', plural)} {grades}</FormLabel>
  }

  function renderAges() {
    const ages = course.ageGroup || formatAges(course.ageMin, course.ageMax, true);

    return <FormLabel>{ages}</FormLabel>
  }

  return render();
}
