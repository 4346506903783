import { uniqBy } from 'lodash-es';

import { MessageEntityInput } from 'app2/api';
import { useCurrentUser } from 'app2/views/shared-public';

import { MessageEntity } from '../MessageEntity';

interface Props {
  to:(MessageEntityInput | MessageEntity)[];
  cc?:(MessageEntityInput | MessageEntity)[];
  labels?:(MessageEntityInput | MessageEntity)[];
}

export function useFromTo(props:Props) {
  const { to, cc, labels } = props;

  const userInfo = useCurrentUser();
  const uniqTo = uniqBy([...(to ||[]), ...(cc || [])], 'id').filter(u => u?.id != userInfo?.user?.id);

  const fromEntity = getFromEntity(userInfo);
  const from = userInfo.user;

  return {userInfo, uniqTo, from, fromEntity}
}

function getFromEntity(userInfo:ReturnType<typeof useCurrentUser>) {
  if (!userInfo?.user) {
    return;
  }

  if (userInfo.user.roles.organizer && userInfo.user.roles.site) {
    return {id: userInfo.user.roles.site.id, type: 'Site'};
  }

  if (userInfo.user.roles.provider && userInfo.user.roles.company) {
    return {id: userInfo.user.roles.company.id, type: 'Company'};
  }

  return;
}
