import { parse, print, visit, DocumentNode, FieldNode, InlineFragmentNode, FragmentDefinitionNode, FragmentSpreadNode } from 'graphql';

type VisitContext = {
  ast:DocumentNode;
  fragments:Record<string, FragmentDefinitionNode>;
}

// written by chatgpt, so possibly its buggy
// dottedPaths can be ['field1', 'field2', 'path1.fieldX', 'path2.fieldY']
export function removeQueryFields(query: string | DocumentNode, dottedPaths: string[]) {
  const paths = dottedPaths.map(path => path.split('.'));
  const ast = typeof query == 'string' ? parse(query) : parse(print(query));

  const fragments: Record<string, FragmentDefinitionNode> = {};
    visit(ast, {
      FragmentDefinition(node) {
        fragments[node.name.value] = node;
      }
  });

  const context = {ast, fragments};

  const modifiedAst = visit(ast, {
    Field(node: FieldNode) {
      for (const fieldPath of paths) {
        const updatedNode = removeFieldByPath(context, node, fieldPath);
        if (updatedNode === null) {          
          return null;
        }

        node = updatedNode;
      }

      return node;
    },
    FragmentDefinition(node) {
      return context.fragments[node.name.value];
    }
  });

  // for unknown reasons even though we clone the ast above, it still seems
  // to not get recognized properly useQuery, so we print to force it
  return print(modifiedAst);
}

function removeFieldByPath(context:VisitContext, node:FieldNode, fieldPath: string[]): FieldNode {
  if (node.name.value !== fieldPath[0]) {
    return node;
  }

  // If it's the last field in the path, remove it
  if (fieldPath.length === 1) {
    return null;
  }

  return visitSelectionSet(context, node, fieldPath.slice(1)) as FieldNode;
}

function visitSelectionSet(context:VisitContext, node:FieldNode | InlineFragmentNode, fieldPath: string[]): FieldNode | InlineFragmentNode | null {
  // If there are more fields in the path, continue traversing the selection set
  if (node.selectionSet) {
    const newSelections = node.selectionSet.selections
      .map(selection => {
        if (selection.kind == 'InlineFragment') {
          return visitSelectionSet(context, selection as InlineFragmentNode, fieldPath);
        }
        else
        if (selection.kind === 'Field') {
          return removeFieldByPath(context, selection as FieldNode, fieldPath);
        }
        else
        if (selection.kind === 'FragmentSpread') {
          const fragment = context.fragments[selection.name.value];

          if (!fragment) {
            return selection;
          }

          const processedFragment = visitSelectionSet(context, fragment as unknown as InlineFragmentNode, fieldPath);

          // If all fields in fragment were removed, remove the spread
          if (!processedFragment) {
            context.fragments[selection.name.value] = null;
            return null;
          }

          context.fragments[selection.name.value] = {
            ...fragment,
            selectionSet: (processedFragment as InlineFragmentNode).selectionSet
          };
        }

        return selection;
      })
      .filter(Boolean);

    return newSelections.length == 0
    ? null
    : {
      ...node,
      selectionSet: {
        ...node.selectionSet,
        selections: newSelections
      }
    };
  }

  return node;
}