import * as React from 'react';

import { Form, FormModel } from 'app2/components';
import { removeAuthorizationError } from 'app2/views/shared';

import { upsertStudent } from './upsertStudent';
import { StudentDetailsFields } from './StudentDetailsFields';
import { updateStudent, useStudentDetailsQuery, useSiteClassroomsQuery } from './gql';

interface Props {
  id: string;
  editing?:boolean;
}

export function StudentDetails(props: Props) {
  const [result] = useStudentDetailsQuery({ variables: { id: props.id } });
  const student = result?.data?.student;

  function render() {
    return <Form width="100%" initialValues={student} onOk={onSubmit} onNavigation='nothing' editing={props.editing} autoSave>
      <StudentDetailsFields getClassrooms={getClassrooms} connectedSitesOnly role='organizer' />
    </Form>
  }

  function getClassrooms(site:string, pause?:boolean) {
    const [response] = useSiteClassroomsQuery({variables: {siteId: site}, error: removeAuthorizationError, pause, hideLoader: true});
    return response.data?.site?.classrooms;
  }

  async function onSubmit(form:FormModel) {
    const success = await upsertStudent(updateStudent, form, student);

    return success;
  }

  return render();
}
