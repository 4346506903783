import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AttendanceSiteQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AttendanceSiteQuery = { site?: { usingParentAttendance: boolean; slug: string; timezone?: string | null | undefined } | null | undefined };

export const AttendanceSiteDocument = gql`
  query AttendanceSite($id: ID!) {
    site(site: $id) {
      usingParentAttendance
      slug
      timezone
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AttendanceSiteArgs = MakeOptional<UseQueryArgs<AttendanceSiteQueryVariables, AttendanceSiteQuery>, 'query'>;

export function useAttendanceSiteQuery(options: AttendanceSiteArgs = {}) {
  return useQuery<AttendanceSiteQueryVariables, AttendanceSiteQuery>({ query: AttendanceSiteDocument, ...options });
}

export type AttendanceSiteOptions = ExecQueryOptions<AttendanceSiteQueryVariables>;

export function attendanceSite(options: AttendanceSiteOptions) {
  return executeQuery<AttendanceSiteQuery, AttendanceSiteQueryVariables>(AttendanceSiteDocument, options);
}

export type AttendanceSiteSelections = AttendanceSiteQuery['site'];
