import * as React from 'react';

import { ModalWithTabs } from 'app2/components';
import { courseTerms } from 'app2/views/shared-public';

import { AddStudentForm } from './AddStudentForm';

import { CourseSelections } from '../../../generated';

interface Props {
  course: CourseSelections;
}

export function AddStudentModal(props: Props) {
  const kind = props.course.kind;

  function render() {
    return <ModalWithTabs title={`Add ${courseTerms(kind).participant}`} tabs={renderTabs()} />;
  }

  function renderTabs() {
    return [
      {
        label: `Invite new ${courseTerms(kind).participant}`,
        content: <AddStudentForm course={props.course} type='invite' />
      },
      props.course.behavior?.enrollmentAddExistingStudent && {
        label: `Add existing ${courseTerms(kind).participant}`,
        content: <AddStudentForm course={props.course} type='add' />
      }
    ];
  }

  return render();
}
