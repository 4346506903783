import * as React from 'react';

import { Field } from 'app2/components';

import { SearchableList, CompanyLink, useAdminCompaniesQuery } from '../shared';
import { AdminPage } from '../AdminPage';

export function Companies() {
  return <AdminPage title='Providers'>
    <SearchableList query={useAdminCompaniesQuery} name='companies' fields={[
      <Field name='name' label='Name' component={CompanyLink} />,
    ]} />
  </AdminPage>
}
