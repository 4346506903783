import * as React from 'react';

import { CourseRole } from 'app2/api';
import { Redirect, findRoute } from 'app2/components';
import { Route } from 'app2/views/shared-public';

import { Schedule } from './schedule';
import { TeacherCourses } from './courses';

const member = CourseRole.Member;

export const teacherRoutes = [
  // left hand navigation
  <Route path="/instructor/schedule/:week?" component={Schedule} role={member} />,
  <Route path="/instructor/activities" exact component={TeacherCourses} role={member} />,

  <Redirect path='/instructor' exact to='/instructor/schedule' />
]

export function getTeacherPath(name:string) {
  return findRoute('/instructor/' + name, teacherRoutes)?.props?.path;
}
