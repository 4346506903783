import { VirtualGridUpdateType } from '../../virtualized';

import { DataTable, MultipleSelection } from '..';
import { CollectionEvent, CollectionEventType } from "../collection";
import { SelectionIterator } from "../SelectionIterator";

export class InvalidationManager {
  table:DataTable;

  constructor(table:DataTable) {
    this.table = table;
  }

  onCollectionEvent(event:CollectionEvent<any>) {
    if (event.type == CollectionEventType.create) {
      this.onInsertRow(event.position);
    }
    else
    if (event.type == CollectionEventType.delete) {
      this.onRemoveRow(event.position);
    }
    else
    if (event.type == CollectionEventType.update) {
      this.onChangeCell(event.position, this.table.getColumnIndex(event.property));
    }
    else {
      this.onReset();
    }
  }

  onInsertRow(row:number) {
    this.invalidate(row, undefined, VirtualGridUpdateType.resize);
  }

  onInsertCol(col:number) {
    this.invalidate(undefined, col, VirtualGridUpdateType.resize);
  }

  onRemoveRow(row:number) {
    this.invalidate(row, undefined, VirtualGridUpdateType.resize);
  }

  onRemoveCol(col:number) {
    this.invalidate(undefined, col, VirtualGridUpdateType.resize);
  }

  onResizeCol(col:number) {
    this.invalidate(undefined, col, VirtualGridUpdateType.resize);
  }

  onChangeCell(row:number, col:number) {
    this.invalidate(row, col, VirtualGridUpdateType.resize);
  }

  onReset() {
    this.invalidate(undefined, undefined, VirtualGridUpdateType.resize);
  }

  onSelect(oldSelection:MultipleSelection, newSelection:MultipleSelection) {
    this.invalidateSelection(oldSelection);
    this.invalidateSelection(newSelection);
  }

  invalidateSelection(selection:MultipleSelection) {
    if (!selection.selections.length) {
      return;
    }

    // its too complicated try and invalidate all cells in a multiple selection, so we just do a reset
    // but this will cause measured rows to reset their measuring, so we might want to optimize this
    // at some point...but its important we invalidate all cells in the selection because they might
    // render differently based on being selected (such as row header checkboxes).  also, its not worth
    // the expense of invalidating more than a few hundred says, so we just reset at that point.
    //
    // if all cols selected, we need to have the row headers redraw so in that case we just reset as well.

    if (selection.selections.length > 1 || selection.selection.numCols > 15 || selection.selection.numRows > 15 || selection.selection.allColsSelected || selection.selection.allRowsSelected) {
      this.invalidate(undefined, undefined, VirtualGridUpdateType.redraw);
    }
    else {
      selection.selection.iterate((iterator:SelectionIterator) => {
        this.invalidate(this.table.colHeaderCount + iterator.row, this.table.rowHeaderCount + iterator.col, VirtualGridUpdateType.redraw);
      });
    }
  }

  invalidate(row:number, col:number, type:VirtualGridUpdateType) {
    this.table.deferredUpdate();
    this.table.virtualTable?.invalidate?.(row, col, type);
  }
}
