import * as React from 'react';
import { GraphQLError } from 'graphql';
import { useHistory } from 'react-router-dom';

import { EnrollmentUtils, Enrollment, PriceConfigKind, ErrorWithStringPath, EnrollmentInput, EnrollmentWithStudentName } from 'app2/api';
import { Modal, RepeatingSection, Form, FormModel, Info, Field, useForm, useSafeState, Collapsible, VBox, Text, Ul, Li, PopupManagerProps } from 'app2/components';
import { EnrollmentConfigurationForm, EnrollmentForm, getEnrollmentConfig } from 'app2/views/shared-public';

import { removePathSegment } from '../../../../error';

import { CourseSelections } from '../../../generated';

import { DistinctEnrollmentsSelections } from '../../enrolled';

import { EnrollmentBreakdown } from '../price-config';

import { customCharge, CustomChargeMutation, CustomChargeMutationVariables } from './generated';

type ResultEnrollment = CustomChargeMutation['enrollmentsCustomCharge']['enrollments'][0];

interface FormEnrollment {
  student: string;
  studentId: string;
}

interface FormValues extends EnrollmentForm {
  enrollments: FormEnrollment[];
}

type CustomChargeForm = FormModel<FormValues>;

interface Props extends PopupManagerProps {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function CustomCharge(props: Props) {
  const history = useHistory();
  const form = useForm<FormValues>(
    {
      id: props.course.id,
      custom: { amount: null, description: '' },
      enrollments: props.enrollments.map(e => ({
        student: EnrollmentUtils.getStudentName((e as unknown) as Enrollment),
        studentId: e.student.id
      }))
    },
    [props.course],
    { alwaysSave: true }
  );
  const config = getEnrollmentConfig(form);
  const [errors, setErrors] = useSafeState<GraphQLError[]>([]);
  const [succeeded, setSucceeded] = useSafeState<ResultEnrollment[]>([]);

  function render() {
    return (
      <Modal
        title="Charge extra"
        cancel={errors.length ? null : undefined}
        ok={succeeded.length ? 'View charges' : errors.length ? 'Continue' : 'Charge'}
        onOk={handleOk}
      >
        {renderForm()}
        {renderSuccesses()}
        {renderErrors()}
      </Modal>
    );
  }

  function renderForm() {
    if (succeeded.length || errors.length) {
      return null;
    }

    return (
      <Form form={form} onOk={handleSubmit} onNavigation="nothing">
        <RepeatingSection name="enrollments" bordered fields={[<Field label="Student" name="student" />]} />
        <EnrollmentConfigurationForm course={props.course} form={form} kind={PriceConfigKind.Custom} />
        <Collapsible type="box" label="See charge details">
          <EnrollmentBreakdown parentCourse={props.course} course={props.course} config={config} vendorFeeLabel="Amount (before fees)" />
        </Collapsible>
      </Form>
    );
  }

  function renderSuccesses() {
    if (!succeeded.length) {
      return null;
    }

    return (
      <Info type="success" mb={errors.length ? '$16' : '$0'}>
        <VBox mb="$16">
          <Text mb="$8">
            Charges are being processed for the following students. Visit the Billing tab to see which charges were successful and which may need your attention.
          </Text>
          <Ul>
            {succeeded.map(e => (
              <Li key={e.id}>{getStudentNameFromResult(e)}</Li>
            ))}
          </Ul>
        </VBox>
      </Info>
    );
  }

  function renderErrors() {
    if (!errors.length) {
      return null;
    }

    const paymentRateLimited = errors.filter(e => e.extensions.code === 'TOO_SOON');
    const unknownErrors = errors.filter(e => !['TOO_SOON'].includes(e.extensions.code as string));

    return (
      <Info type="warning">
        {renderErrorSection(paymentRateLimited, "Some families weren't charged because they already have a charge within the past 5 minutes.  You can only charge a student once per activity per 5 minutes. Please reach out to Homeroom support or try again later:")}
        {renderErrorSection(unknownErrors, "Some families weren't charged due to an unknown error. Please reach out to Homeroom support or try again later:")}
      </Info>
    );
  }

  function renderErrorSection(errors: GraphQLError[], message: string) {
    return errors.length ? (
      <VBox mb="$16">
        <Text mb="$8">{message}</Text>
        <Ul>
          {errors.map(e => (
            <Li key={e.message}>{getStudentNameFromError(e)}</Li>
          ))}
        </Ul>
      </VBox>
    ) : null;
  }

  function getStudentNameFromResult(e: ResultEnrollment) {
    const enrollment = props.enrollments.find(enr => enr.student.id === e.student.id);
    return EnrollmentUtils.getStudentName((enrollment as unknown) as EnrollmentWithStudentName);
  }

  function getStudentNameFromError(e: GraphQLError) {
    const enrollment = props.enrollments.find(enr => enr.student.id === (e.extensions.path as string[])[1]);
    return EnrollmentUtils.getStudentName((enrollment as unknown) as EnrollmentWithStudentName);
  }

  async function handleSubmit(form: CustomChargeForm) {
    const custom = form.values.custom;
    const enrollments = props.enrollments.map(e => ({ id: props.course.id, kind: PriceConfigKind.Custom, custom, studentId: e.student.id }));
    const [success, res] = await customCharge({
      variables: { enrollments },
      error: {
        handler: form,
        transform: [idToIndex, removePathSegment('studentId')]
      }
    });

    const succeeded = (res.data?.enrollmentsCustomCharge?.enrollments || []) as ResultEnrollment[];
    const errors = res.error?.graphQLErrors || []; // { extensions { code, path }, message }

    setSucceeded(succeeded);
    setErrors(errors);

    return success;
  }

  function handleOk() {
    if (succeeded.length) {
      props.manager.remove();
      history.push(`/activities/manage/${props.course.id}/billing`);
      return true;
    } else {
      return false;
    }
  }

  return render();
}

// transforms an error path w/ an identifier, e.g. "enrollments.83257.studentId", to a path to the formm field, e.g. "enrollments.0.studentId". 83257 is the student id, and 0 is the index of the student in the form.
function idToIndex(error: ErrorWithStringPath, input: CustomChargeMutationVariables): ErrorWithStringPath {
  const variables = input;
  const id = error.path[1];
  const pos = (variables.enrollments as EnrollmentInput[]).findIndex(e => e.studentId === id);

  const err = { ...error };
  if (pos === undefined) {
    return err;
  }

  err.path[1] = pos.toString();

  return error;
}
