import * as React from 'react';
import pluralize from 'pluralize'

import { Enrollment } from 'app2/api'
import { ActionButton, DataTable, NotificationManager } from 'app2/components';
import { idsToindex } from 'app2/views';

import { CourseSelections } from '../../generated';

import { resendEnrollmentInvites } from './generated';

export function renderResendStudentsButton(course:CourseSelections, table:DataTable) {
  return <ActionButton icon="Repeat" autoLoader={false} tooltip='Resend invite' onClick={() => handleResendInvites(course, table)}>Resend</ActionButton>
}

async function handleResendInvites(course:CourseSelections, table:DataTable<Enrollment>) {
  const ids = table.selectedItems.map(e => e.id);
  const [success] = await resendEnrollmentInvites({variables: { courseId: course?.id, ids }, error: {handler: table, transform: idsToindex}});

  if (!success) {
    return;
  }

  NotificationManager.add({ type: 'success', message: `${pluralize('Invite', ids.length)} resent` });
}
