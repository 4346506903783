// @ts-ignore
import { isRealProduction } from 'app/helpers';
import { ScriptLoader } from 'app/scriptLoading/ScriptLoader';
import { scriptManager } from 'app/scriptLoading/ScriptManager';

const affirmUrl = isRealProduction()
  ? 'https://cdn1.affirm.com/js/v2/affirm.js'
  : 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js';

scriptManager.register(affirmUrl, true);

export class AffirmLoader extends ScriptLoader {
  static defaultProps = {
    scripts: [{ script: affirmUrl, isUrl: true }]
  };

  render() {
    return this.state.loaded ? this.props.children : '';
  }
}
