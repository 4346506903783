import * as React from 'react';

import { TimeTrackingEntry } from 'app2/views/shared'

import { useSiteQuery } from '../shared';

import { useSiteWithTimezoneQuery } from './generated';

export function SiteTimeTrackingEntry() {
  const { site } = useSiteQuery(useSiteWithTimezoneQuery);
  
  return <TimeTrackingEntry entity={site} />
}
