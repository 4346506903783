import * as React from 'react';

import { DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO } from 'app2/views/shared-public';
import { companyFolder } from 'app/helpers';

import { CloudinaryPhotoField, FieldComponentProps, Section } from 'app2/components';

interface Props extends FieldComponentProps<any> {
  siteId?:string;
  companyId:string;
}

export function CourseCardImageField(props:Props) {
  const {siteId, companyId, ...remaining} = props;

  function render() {
    return <Section {...courseCardImage} defaultImage={DEFAULT_COURSE_CARD_IMG} ratio={COURSE_CARD_IMAGE_RATIO} component={CloudinaryPhotoField} options={getCardImageOptions()} imageWidth={132} {...remaining} />
  }

    function getCardImageOptions() {
      return React.useMemo(() => {
        return {
          uploadPreset: 'course_card_image',
          folder: companyFolder(companyId),
          signatureUrl: `/courses/course_card_image_signature/${companyId}`,
          customSignatureParams: { site_id: siteId }
        };
      }, [siteId, companyId]);
    }

    return render()
  }

const  courseCardImage = {
  name: 'courseCardImage',
  label: 'Activity card image',
  disabled: false
}
