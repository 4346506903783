import { DataTableColumn, colId } from './DataTableColumn';

export function removeCols<T extends string | DataTableColumn<T> = any>(cols: T[], colsToRemove:(string | DataTableColumn)[]):T[] {
  if (!cols || !colsToRemove) {
    return cols;
  }

  const colsToRemoveSet = new Set(colsToRemove.map(colId));

  return cols.filter(c => !colsToRemoveSet.has(colId<T>(c))) as T[];
}
