import { ScriptLoader } from 'app/scriptLoading/ScriptLoader';
import { scriptManager } from 'app/scriptLoading/ScriptManager';

const cloudinaryUrl1 = 'https://widget.cloudinary.com/v2.0/global/all.js';
const cloudinaryUrl2 = 'https://product-gallery.cloudinary.com/all.js';

scriptManager.register(cloudinaryUrl1, true);
scriptManager.register(cloudinaryUrl2, true);

export class CloudinaryLoader extends ScriptLoader {
  static defaultProps = {
    scripts: [
      { script: cloudinaryUrl1, isUrl: true },
      { script: cloudinaryUrl2, isUrl: true }
    ]
  };
}
