import * as React from 'react';

import { CfKind, CfQuestion } from 'app2/api'
import { ButtonStrip, BooleanField, Part, Section, TextArea, useFormInfo } from 'app2/components'
import { FileLinks, FileUploads } from 'app2/views/shared';

interface Props {
  question:CfQuestion;
  mode?: 'display' | 'editing' | 'author-preview';
  // allows overriding required so things are not required for organizers
  overrideRequired?:boolean;
  // list of titles that duplicate in the ui indicating if this question should show the owner
  dupeTitles?:{[key:string]:boolean};
}

export function CfQuestionInput(props:Props) {
  const form = useFormInfo();
  const questionAnswers = (form?.parents) || [];
  const parents = questionAnswers.slice(0, questionAnswers.length - 2).concat(['cfAnswers']);

  const q = props.question;
  const disabled = props.mode == 'author-preview';
  const editing = props.mode == 'author-preview' || props.mode == 'editing' || (!props.mode && form.editing);
  const required = q.required && !props.overrideRequired;

  let description = q.description;

  if (props.dupeTitles?.[q.title] && q.owner?.name) {
    description = q.description ? q.owner.name + '\n' + q.description : q.owner.name;
  }
  
  const fieldProps = {label: q.title, description: description as React.ReactNode, editing, disabled, parents, name: answerId(q), none: 'Not answered', required};

  if (q.files?.length) {
    fieldProps.description = <>
      {description}
      <FileLinks files={q.files} />
    </>
  }

  function renderMultiple() {
    return <Section {...fieldProps} edit={TextArea} none='Not answered' />;
  }

  function renderChoice() {
    return <Section {...fieldProps} edit={ButtonStrip} flexWrap='wrap' maxWidth='fit-content' options={q.choices?.map(c => ({label: c, value: c})) || []} />;
  }

  function renderMultipleChoice() {
    return <Section {...fieldProps} edit={ButtonStrip} multiple flexWrap='wrap' maxWidth='fit-content' options={q.choices?.map(c => ({label: c, value: c})) || []} />;
  }

  function renderConfirmation() {
    return <Section label={fieldProps.label} disabled={fieldProps.disabled} required={fieldProps.required} editing={fieldProps.editing}><Part {...fieldProps} component={BooleanField} boolean label="Confirmed" /></Section>;
  }

  function renderUpload() {
    return <Section {...fieldProps} component={FileUploads} uploader={editing} />
  }

  function render() {
    const map = {
      [CfKind.MultipleLines]: renderMultiple,
      [CfKind.Choice]: renderChoice,
      [CfKind.MultipleChoice]: renderMultipleChoice,
      [CfKind.Confirmation]: renderConfirmation,
      [CfKind.Upload]: renderUpload
    }

    return map[q.kind]();
  }

  return render();
}

CfQuestionInput.fieldProps = {
  valueProperty: 'question'
}

export function answerId(question:Partial<CfQuestion>) {
  // answers are stored a json object (hash of question
  // id to answer), however our forms can't handle field
  // names that are just numbers (because they get confused
  // with array positions).  so we append a q to the id.
  return 'q' + question.id + '.answer';
}
