import * as React from 'react'

import { CellRenderProps } from './CellRendererProps';

export function useOverflow(ref:React.MutableRefObject<HTMLDivElement>, props:CellRenderProps, styles:React.CSSProperties) {
  const [overflowed, setOverflowed] = React.useState({x:false, y:false, width: undefined as number, height: undefined as number});
  const table = props.table;
  const result = {overflowed: (overflowed.x || overflowed.y), style: undefined as React.CSSProperties};

  // now that we have editor created whenever you give a cell focus, we don't need to deal
  // with showing the full cell.  the editor will do that.  if we allow this code to run
  // there's a chance that the readonly cell thats underneath the editor, has a different
  // height than the editor, since the editor might render differently (like dropdowns do
  // with tags).  to avoid the cell selection from changing as the overflow and the editor
  // fight, we just bail on calculating the overflow when the table is editable.
  // -- don't return early because of react hook requirements and it appears react is not
  // unmounting between edit and not edit states
  // if (props.table.editable) {
  //   return result;
  // }

  if (!props.table.editable && result.overflowed && props.focus) {
    result.style = {};
    result.style.maxWidth = result.style.width = overflowed.width + 'px';
    result.style.maxHeight = result.style.height = overflowed.height + 'px';

    // ensures that the oversized cell is above its siblings
    result.style.zIndex = 1;
  }

  React.useEffect(() => {
    if (props.table.editable) {
      return;
    }

    const element = ref.current;

    if (!element) {
      if (overflowed.x != false || overflowed.y != false || overflowed.width != undefined || overflowed.height != undefined) {
        setOverflowed({x:false, y:false, width:undefined, height:undefined});
      }  
      return;
    }

    if (!props.focus) {
      return;
    }

    const beforeHeight = element.style.height;
    element.style.height = '';

    const isOverflowedX = element.scrollWidth > element.clientWidth || element.clientWidth > parseFloat(styles.minWidth as string);
    const isOverflowedY = element.scrollHeight > element.clientHeight || element.clientHeight > parseFloat(styles.minHeight as string);
    const width:number = overflowed.x && props.focus ? Math.max(parseInt(styles.width as string), element?.scrollWidth) : 0;
    const height:number = overflowed.y && props.focus ? Math.max(parseInt(styles.height as string), element?.scrollHeight) : 0;

    element.style.height = beforeHeight;

    if (overflowed.x != isOverflowedX || overflowed.y != isOverflowedY || overflowed.width != width || overflowed.height != height) {
      setOverflowed({x: isOverflowedX, y: isOverflowedY, width, height});
    }

    // always call this in case the focus was cleared
    table.setOversizedCellSize(width, height);
  });

  return result;
}
