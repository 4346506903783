import * as React from 'react'
import { useParams } from 'react-router';

import { Panel, Part, Section, InputField, FormModel, FieldInfo, useForm, RadioGroup } from 'app2/components'
import { DonationConfig } from 'app2/api'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerDonationConfigQueryQuery } from './gql'
import { organizerUpdateDonationConfig, OrganizerUpdateDonationConfigMutationVariables } from './gql'


export function Donations() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ donationConfigQueryResult ] = useOrganizerDonationConfigQueryQuery({variables: { siteId }});
  const site = donationConfigQueryResult.data?.site
  const donationConfig = site?.donationConfig

  const form = useForm<DonationConfig>(donationConfig, [donationConfig]);

  const donationsEnabled = form.values?.enabled

  function render() {
    return <Panel icon='DollarSign' title='Donations' type='toggle' form={form} onOk={onOk}>
      <Section required name='enabled' component={RadioGroup} onChange={onChangeDonationEnable} options={[
          {label:'Do not accept donations', value:false},
          {label:'Accept donations', value:true, input: <>
            <Section mode={donationsEnabled ? 'both' : 'edit'}>
              <Part name='ein' label='Tax ID (EIN)' component={InputField} required disabled={!donationsEnabled} />
              <Part name='charityName' label='Charity name' component={InputField} disabled={!donationsEnabled} />
            </Section>
          </>
        },
      ]} />
    </Panel>
  }

  function onChangeDonationEnable(value:boolean, info: FieldInfo<DonationConfig>) {
    if (!info.form.values.enabled) {
      info.form.setValue([], 'ein', '');
      info.form.setValue([], 'charityName', '');
    }
  }

  async function onOk(form:FormModel<DonationConfig>) {
    const variables: OrganizerUpdateDonationConfigMutationVariables = { siteId: site.id, donationConfig: form.values }
    const [success] = await organizerUpdateDonationConfig({variables, successMsg: 'Donation settings updated.', error: form})

    return success;
  }

  return render();
}

