import * as React from 'react';

import { teacherPreferences, CourseKind } from 'app2/api';
import { StandardTableActions, removeCols, orderCols } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';
import { CoursesTableWithTabs, useCourseTableState, courseColumnSets } from 'app2/views/shared';

const PREFS_VERSION = '2';

interface Props {
  kind:CourseKind;
}

export function TeacherCoursesTable(props:Props) {
  const state = useCourseTableState(teacherPreferences.tablePreferences(props.kind));
  const behavior = courseKindBehavior[props.kind];

  // for teachers, for lessons, we override the behavior of only showing the lessons
  // because they might only be assigned to a time slot
  const queryVars = props.kind == CourseKind.LessonSet ? {kinds: [CourseKind.LessonSet, CourseKind.TimeSlot]} : {};

  function render() {
    return <CoursesTableWithTabs 
      kind={props.kind}
      tabs={renderTabs()}
      queryVars={queryVars}
      state={state}
      prefsVersion={PREFS_VERSION}
      table={{editable: false}}
    />
  }
  
  function renderTabs() {
    return React.useMemo(() => {
      const name = behavior.name as keyof typeof courseColumnSets;
      const baseTabs = courseColumnSets[name];
      const cols = orderCols(['name', 'startDate', 'endDate'], removeCols(baseTabs.published.cols.base, ['teacher', 'teacher.email', 'teacher.phone']), false);

      const tabs = [{
        ...baseTabs.published,
        cols: {base: cols},
        secondaryActions:renderSecondaryActions(),
      }];

      return tabs
    }, [behavior])
  }
  
  function renderSecondaryActions() {
    return {display: [
      <StandardTableActions />,
    ]}
  }

  return render();
}
