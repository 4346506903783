import * as React from 'react'

import { Input, Option, DropdownBase, DropdownState, InputProps, DropdownFocusType, MultiContext } from 'app2/components'

import { CourseDaysDisplay } from './CourseDaysDisplay';
import { CourseDaysContext, CourseDaysForm, DEFAULT_ADD_DAY } from './CourseDaysForm';

// CourseDaysPicker expects to be used within a form with a course
interface Props extends InputProps {
  add?:string;
}

export class CourseDaysPicker extends DropdownBase<Props, DropdownState, CourseDaysContext> {
  static defaultProps = {
    add: DEFAULT_ADD_DAY
  }

  static contextType = MultiContext;
  context:CourseDaysContext;
  
  focusType = DropdownFocusType.dropdown;

  get parents() {
    return this.context.formInfo.parents;
  }

  get form() {
    return this.context.formInfo.form;
  }

  renderTrigger() {
    let {value, onChange, onBlur, width, add, ...remaining} = this.props;

    const courseDays = this.form.getValue(this.parents, 'courseDays');
    const tags = courseDays?.map((courseDay, index) => {return {label: <CourseDaysDisplay start={index} total={courseDays.length} value={[courseDay]} />, value: 0}});
    width = width || this.props.style?.width;

    return <Input tags={tags} icon='Calendar' readOnly onTagCloseClick={this.onTagCloseClick} onIconMouseDown={this.toggleDropdown} placeholder='Enter day and time' {...remaining} width='100%' />
  }

  renderDropdown() {
    return <CourseDaysForm add={this.props.add} />
  }

  onTagCloseClick = (tag:Option, index:number) => {
    const days = this.form.values?.courseDays?.slice() || [];
    days.splice(index, 1);

    this.form.setValue(this.parents, 'courseDays', days);
  }

  forwardDropdownEvent(event:React.KeyboardEvent) {
    return false;
  }
}
