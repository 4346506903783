import * as React from 'react';

import { useLifecycle } from "app2/components";

// this is needed because the old ui makes the root element
// the scrollable container (and then hacks around things to get
// the header to not scroll vs. making a child be the scroller).
// removing the scrolling from the root element breaks the old ui.
// having the scrolling in the root element causes the new ui
// issues (espescially on phones).

export function useHackOldUiScrollFix() {
  const styleNode = React.useRef<HTMLElement>();

  useLifecycle({onMount, onUnmount})

  function onMount() {
    styleNode.current = document.createElement('style');
    styleNode.current.innerHTML = "#root {overflow: hidden!important}";
    document.body.appendChild(styleNode.current);
  }

  function onUnmount() {
    if (styleNode.current) {
      styleNode.current.remove();
      styleNode.current = null;
    }
  }
}
