import * as React from 'react';
import moment from 'moment'

import { Calendar, VBox, DATE_STYLE_MAP_KEY_FORMAT } from 'app/app2/components';

import { Demos, Demo } from './Demo';

const month = moment().startOf('month');
const enrollments = [month.clone().add(2, 'days'), month.clone().add(3, 'days'), month.clone().add(4, 'days'), month.clone().add(5, 'days'), month.clone().add(6, 'days')];
const courseDays = [month.clone().add(7, 'days'), month.clone().add(8, 'days'), month.clone().add(9, 'days')];
const noEnrichment = [month.clone().add(14, 'days'), month.clone().add(15, 'days')];

export function CalendarDemos() {
  return <Demos name='Calendar'>
    <Demo name='Basic'>
      <Calendar />
    </Demo>

    <Demo name='Multiselect'>
      <Calendar type='range' />
    </Demo>

    <Demo name='Legend using specific days'>
      <VBox>
        <Calendar type='range' legend={[
          {name: 'Enrollment', bg: 'enrollment', days:enrollments},
          {name: 'Activity days', bg: 'courseDay', days:courseDays},
          {name: 'No enrichment days', bg: 'noEnrichment', days:noEnrichment}
          ]} />
      </VBox>
    </Demo>

    <Demo name='Legend using day ranges'>
      <VBox>
        <Calendar type='range' legend={[
          {name: 'Enrollment', bg: 'enrollment', start:month.clone().add(2, 'days'), end: month.clone().add(6, 'days')},
          {name: 'Activity days', bg: 'courseDay', start: month.clone().add(7, 'days'), end: month.clone().add(9, 'days')},
          {name: 'No enrichment days', bg: 'noEnrichment', start:month.clone().add(14, 'days'), end:month.clone().add(15, 'days')}
          ]} />
      </VBox>
    </Demo>

    <Demo name='Legend using day ranges, smaller'>
      <VBox>
        <Calendar type='range' width='290px' legend={[
          {name: 'Enrollment', bg: 'enrollment', start:month.clone().add(2, 'days'), end: month.clone().add(6, 'days')},
          {name: 'Activity days', bg: 'courseDay', start: month.clone().add(7, 'days'), end: month.clone().add(9, 'days')},
          {name: 'No enrichment days', bg: 'noEnrichment', start:month.clone().add(14, 'days'), end:month.clone().add(15, 'days')}
          ]} />
      </VBox>
    </Demo>

    <Demo name='Custom day styles'>
      <VBox>
        <Calendar dayStyles={{
          [month.format(DATE_STYLE_MAP_KEY_FORMAT)]: {bg: 'red'},
          [month.clone().add(1, 'days').format(DATE_STYLE_MAP_KEY_FORMAT)]: {bg: 'blue', border: 'solid 5px black', color: 'white'}
        }} />
      </VBox>
    </Demo>
  </Demos>
}
