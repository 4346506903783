import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertCourseFrameworkMutationVariables = Types.Exact<{
  items: Array<Types.CourseFrameworkInput> | Types.CourseFrameworkInput;
}>;

export type UpsertCourseFrameworkMutation = { courseFrameworksUpsert?: { items?: Array<{ id: string }> | null | undefined } | null | undefined };

export const UpsertCourseFrameworkDocument = gql`
  mutation UpsertCourseFramework($items: [CourseFrameworkInput!]!) {
    courseFrameworksUpsert(items: $items) {
      items {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpsertCourseFrameworkOptions = ExecMutationOptions<UpsertCourseFrameworkMutationVariables>;

export function upsertCourseFramework(options: UpsertCourseFrameworkOptions) {
  return executeMutation<UpsertCourseFrameworkMutation, UpsertCourseFrameworkMutationVariables>(UpsertCourseFrameworkDocument, options);
}
