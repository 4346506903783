import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyScheduleCoursesQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyScheduleCoursesQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        slug: string;
        upcomingActiveOrRecentCourses?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              room?: string | null | undefined;
              noEnrichmentDays: Array<string | null | undefined>;
              status: Types.CourseStatus;
              courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
              company?: { name?: string | null | undefined; id: string } | null | undefined;
              site: { id: string; name?: string | null | undefined };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CompanyScheduleCoursesDocument = gql`
  query CompanyScheduleCourses($company: ID!) {
    company(company: $company) {
      ...commonCompany
      upcomingActiveOrRecentCourses {
        id
        name
        startDate
        endDate
        room
        noEnrichmentDays
        status
        courseDays {
          start
          finish
          days
        }
        company {
          name
          id
        }
        site {
          id
          name
        }
      }
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyScheduleCoursesArgs = MakeOptional<UseQueryArgs<CompanyScheduleCoursesQueryVariables, CompanyScheduleCoursesQuery>, 'query'>;

export function useCompanyScheduleCoursesQuery(options: CompanyScheduleCoursesArgs = {}) {
  return useQuery<CompanyScheduleCoursesQueryVariables, CompanyScheduleCoursesQuery>({ query: CompanyScheduleCoursesDocument, ...options });
}

export type CompanyScheduleCoursesOptions = ExecQueryOptions<CompanyScheduleCoursesQueryVariables>;

export function companyScheduleCourses(options: CompanyScheduleCoursesOptions) {
  return executeQuery<CompanyScheduleCoursesQuery, CompanyScheduleCoursesQueryVariables>(CompanyScheduleCoursesDocument, options);
}

export type CompanyScheduleCoursesSelections = CompanyScheduleCoursesQuery['company'];
