import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUpsertDonationMutationVariables = Types.Exact<{
  donation: Types.CartDonationInput;
}>;

export type ParentUpsertDonationMutation = { cartUpsertDonation?: { donation?: { id: string } | null | undefined } | null | undefined };

export const ParentUpsertDonationDocument = gql`
  mutation ParentUpsertDonation($donation: CartDonationInput!) @urql(additionalTypenames: ["User"]) {
    cartUpsertDonation(donation: $donation) {
      donation {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentUpsertDonationOptions = ExecMutationOptions<ParentUpsertDonationMutationVariables>;

export function parentUpsertDonation(options: ParentUpsertDonationOptions) {
  return executeMutation<ParentUpsertDonationMutation, ParentUpsertDonationMutationVariables>(ParentUpsertDonationDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
