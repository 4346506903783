import * as React from 'react';
import { Location } from 'history';
import { useHistory } from 'react-router-dom';

import { HistoryAction, useHistoryListener } from './useHistoryListener'
import { AnimateProperties, scrollIntoView, Point } from '../dom-utils';
import { useLoader } from '..';

export function useNavigateToHash(scroller:HTMLElement, offset:Point = undefined) {
  const history = useHistory();
  const loader = useLoader();
  const animation = React.useRef<AnimateProperties>();
  const currentHash = React.useRef<string>();

  useHistoryListener(navigateToHash, true);

  async function navigateToHash(location: Location, action: HistoryAction) {
    if (location.state?.initiatedFromHashWatcher && action != 'MOUNT') {
      return;
    }

    let hash = location.hash.substring(1);

    if (currentHash.current == hash) {
      return;
    }

    if (!hash && currentHash.current && scroller) {
      scroller.scrollTo(0, 0);
    }

    currentHash.current = hash;

    if (!hash) {
      return;
    }

    await loader.waitForLoader();

    // if it changed while we were waiting we bail on this one
    if (currentHash.current != hash) {
      return;
    }

    let remove = false;

    if (hash.endsWith('&remove')) {
      hash = hash.substring(0, hash.length - '&remove'.length);
      remove = true;
    }

    if (remove) {
      history.replace(location.pathname);
    }

    animation.current = scrollHashIntoView(hash);
  }

  function scrollHashIntoView(hash:string) {
    animation.current?.stop();

    const node = document.getElementById(hash);

    if (!node) {
      return;
    }

    return scrollIntoView(node, {alignment:'begin', behavior:'smooth'}, offset);
  }

  function stopScrolling() {
    animation.current?.stop();
    animation.current = undefined;
  }

  return stopScrolling;
}