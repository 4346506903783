import * as React from 'react';

import { HBox, Icon } from 'app2/components';

import { CourseSelections } from '../../generated';

interface Props {
  course: CourseSelections;
}

export function RoundingIncrement(props: Props) {
  const roundingIncrement = props.course?.rates?.usage?.[0]?.roundingIncrement;
  if (!roundingIncrement) {
    return null;
  }

  return (
    <HBox text="body" vAlign="center">
      Billing round-up: {roundingIncrement} minutes
      <Icon name="Info" size="small" ml="$4" tooltip="Time interval for rounding up charges. E.g., a 15-minute interval means a stay of 2 hours and 5 minutes is billed as 2 hours and 15 minutes." />
    </HBox>
  );
}
