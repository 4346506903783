import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateEnrollmentMutationVariables = Types.Exact<{
  enrollments: Array<Types.ConfirmedEnrollmentInput> | Types.ConfirmedEnrollmentInput;
}>;

export type UpdateEnrollmentMutation = { enrollmentsUpdate?: { success?: boolean | null | undefined } | null | undefined };

export const UpdateEnrollmentDocument = gql`
  mutation UpdateEnrollment($enrollments: [ConfirmedEnrollmentInput!]!) @urql(additionalTypenames: ["Enrollment"]) {
    enrollmentsUpdate(enrollments: $enrollments) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateEnrollmentOptions = ExecMutationOptions<UpdateEnrollmentMutationVariables>;

export function updateEnrollment(options: UpdateEnrollmentOptions) {
  return executeMutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>(UpdateEnrollmentDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Enrollment']) }
  });
}
