import { allCourseCols, courseColumnSets } from 'app2/views/shared/course-table';
import { CoursesTableTab } from 'app2/views/shared';

const allCompanyCols = swapVendorForSite(allCourseCols);

export const companyCourseTables = {
  requests: {
    ...courseColumnSets.enrichment.requests,
    cols: {
      base: ['name', 'status', 'site', 'startDate', 'endDate', 'courseDays', 'policies', 'courseFramework', 'teacher', 'courseTags', 'grades', 'description', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'classesCount'],
      advanced: allCompanyCols
    },
    none: 'Some schools will send you an activity request first - these will come in via email, so hang tight until they come in!'
  } as CoursesTableTab,
  published:{
    ...courseColumnSets.enrichment.published,
    cols: {
      base: ['name', 'status', 'site', 'startDate', 'endDate', 'courseDays', 'enrolledCount', 'waitlistedCount', 'teacher', 'policies', 'registration'], 
      advanced: allCompanyCols
    },
    none: 'No activities yet! Schools may either send activity requests by email or let you schedule directly with them. Click the edit icon to create activities.'      
  } as CoursesTableTab,
  draft: {
    ...courseColumnSets.enrichment.draft,
    cols: {
      base: ['site', 'courseFramework', 'name', 'kind', 'teacher', 'courseTags', 'grades', 'ageMin', 'ageMax', 'description', 'room', 'courseDays', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'supplies', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.season.materialsRate', 'classesCount'], 
      advanced: allCompanyCols
    },
    save: 'Save drafts',
    none:'There are no draft activities. Click on the edit icon to add more activities.'
  }
}

function swapVendorForSite(cols:string[]) {
  return cols.map(c => c == 'vendor' ? 'site' : c);
}
