import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserEventsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserEventsQuery = {
  adminUser?:
    | {
        events?:
          | Array<{
              id: string;
              createdAt: string;
              eventName?: string | null | undefined;
              serviceType?: Types.EventService | null | undefined;
              contact?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminUserEventsDocument = gql`
  query AdminUserEvents($id: ID!) {
    adminUser(user: $id) {
      events {
        id
        createdAt
        eventName
        contact {
          id
          name
          email
          phone
        }
        serviceType
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserEventsArgs = MakeOptional<UseQueryArgs<AdminUserEventsQueryVariables, AdminUserEventsQuery>, 'query'>;

export function useAdminUserEventsQuery(options: AdminUserEventsArgs = {}) {
  return useQuery<AdminUserEventsQueryVariables, AdminUserEventsQuery>({ query: AdminUserEventsDocument, ...options });
}

export type AdminUserEventsOptions = ExecQueryOptions<AdminUserEventsQueryVariables>;

export function adminUserEvents(options: AdminUserEventsOptions) {
  return executeQuery<AdminUserEventsQuery, AdminUserEventsQueryVariables>(AdminUserEventsDocument, options);
}

export type AdminUserEventsSelections = AdminUserEventsQuery['adminUser'];
