import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DiscountDefinitionsQueryVariables = Types.Exact<{
  entityKind: Types.Scalars['String'];
  entityId: Types.Scalars['ID'];
  queries?: Types.InputMaybe<Array<Types.Scalars['JSON']> | Types.Scalars['JSON']>;
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type DiscountDefinitionsQuery = {
  entity?:
    | {
        discountDefinitionsQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<{
                    id: string;
                    active: boolean;
                    amount: number;
                    code: string;
                    format: Types.DiscountDefinitionFormat;
                    kind: Types.DiscountKind;
                    usesCount: number;
                    scope: { courses: Array<string>; courseKinds: Array<Types.CourseKind>; studentObjects: Array<{ id: string; name: string }> };
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | {
        discountDefinitionsQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<{
                    id: string;
                    active: boolean;
                    amount: number;
                    code: string;
                    format: Types.DiscountDefinitionFormat;
                    kind: Types.DiscountKind;
                    usesCount: number;
                    scope: { courses: Array<string>; courseKinds: Array<Types.CourseKind>; studentObjects: Array<{ id: string; name: string }> };
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | {
        discountDefinitionsQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<{
                    id: string;
                    active: boolean;
                    amount: number;
                    code: string;
                    format: Types.DiscountDefinitionFormat;
                    kind: Types.DiscountKind;
                    usesCount: number;
                    scope: { courses: Array<string>; courseKinds: Array<Types.CourseKind>; studentObjects: Array<{ id: string; name: string }> };
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const DiscountDefinitionsDocument = gql`
  query discountDefinitions($entityKind: String!, $entityId: ID!, $queries: [JSON!], $sorts: [Sort!], $filters: [Filter!]) @urql(additionalTypenames: ["DiscountDefinition"]) {
    entity(entityKind: $entityKind, entityId: $entityId) {
      ... on WithDiscountDefinitions {
        discountDefinitionsQuery(queries: $queries, sorts: $sorts, filters: $filters) {
          count
          items {
            id
            active
            amount
            code
            format
            kind
            usesCount
            scope {
              courses
              courseKinds
              studentObjects {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type DiscountDefinitionsArgs = MakeOptional<UseQueryArgs<DiscountDefinitionsQueryVariables, DiscountDefinitionsQuery>, 'query'>;

export function useDiscountDefinitionsQuery(options: DiscountDefinitionsArgs = {}) {
  return useQuery<DiscountDefinitionsQueryVariables, DiscountDefinitionsQuery>({ query: DiscountDefinitionsDocument, ...options });
}

export type DiscountDefinitionsOptions = ExecQueryOptions<DiscountDefinitionsQueryVariables>;

export function discountDefinitions(options: DiscountDefinitionsOptions) {
  return executeQuery<DiscountDefinitionsQuery, DiscountDefinitionsQueryVariables>(DiscountDefinitionsDocument, options);
}

export type DiscountDefinitionsSelections = DiscountDefinitionsQuery['entity'];

(DiscountDefinitionsDocument as any).additionalTypenames = ['DiscountDefinition'];
