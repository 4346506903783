import * as React from 'react'
import * as ReactIs from 'react-is'

import { Button, Img, Link, LinkProps, Text, useFormInfo, usePanel, useShield, VBox } from 'app2/components'

interface Props {
  title?:string;
  description:string;
  image:string;
  add?:string | React.ReactElement;
  property?:string;
  onAdd?:(() => void) | string | LinkProps;
}

export function NonePanel(props:Props) {
  const panel = usePanel();
  const formInfo = useFormInfo();
  const shield = useShield();

  function render() {
    if (shield?.showingLoader) {
      return <></>
    }

    if (panel?.editing && props.property && !formInfo?.form?.values?.[props.property]?.length) {
      return <>None</>;
    }

    return <VBox hAlign='center' width='100%'>
      <VBox hAlign='center' width='100%' maxWidth='800px' gap='$30'>
        {props.title && <Text text='heading2'>{props.title}</Text>}
        <Img src={props.image} />
        <Text text='body' textAlign='center'>{props.description}</Text>
        {props.add && (
          ReactIs.isElement(props.add)
          ? props.add
            : typeof props.onAdd === 'object'
            ? <Link button='primary' {...props.onAdd} />
            : typeof props.onAdd === 'string'
              ? <Link button='primary' to={props.onAdd}>{props.add}</Link>
              : <Button onClick={props.onAdd || onAdd}>{props.add}</Button>)}
      </VBox>
    </VBox>
  }

  function onAdd() {
    if (!panel.editing) {
      panel.toggleEdit();
    }

    let collection = formInfo.form.values[props.property]?.slice() || [];

    collection.push({});
    formInfo.form.setValue(props.property, collection);
  }

  return render();
}
