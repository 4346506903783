import * as React from 'react';

import { Section, CheckboxField } from 'app2/components';

import { Beta } from '../../shared-public';

export function PausePayouts() {
  return (
    <Beta>
      <Section label='Pause payouts' name='payoutConfig.pause' description="Pause payouts indefinitely for school-run activities. Payouts will continue to accrue but won't be disbursed." none={false} component={CheckboxField} />
    </Beta>
  );
}
