import * as React from 'react';

import { SiteHeader } from '../season/SiteHeader';
import { OrganizerPage, SeasonDropdown } from '../shared';

import { Sessions } from './Sessions'

export function Schedule() {
  return <OrganizerPage title="Schedule">
    <SiteHeader />
    <SeasonDropdown />
    <Sessions />
  </OrganizerPage>
}
