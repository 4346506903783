import * as React from 'react'

import { Panel, RepeatingSection, FormModel, Field, EmailField } from 'app2/components';
import { addDeletedItems } from 'app2/views/shared';

import { useUserCcContactsQuery, UserCcContactsSelections, upsertUserCcContacts } from './generated';

interface Props {
  newUi?:boolean;
}

export function CcContacts(props:Props) {
  const [result] = useUserCcContactsQuery({});
  const initialValues = React.useMemo(() => ({contacts:result.data?.user?.ccContacts || []}), [result.data?.user?.ccContacts]);
  const oldUiProps = props.newUi
    ? undefined
    : {
      border:'solid 1px',
      borderColor:'border',
      bg:'white'
  }

  function render() {
    return <Panel icon='Mail' title='Additional Emails' type='toggle' initialValues={initialValues} onOk={onSave}
      subtitle='Other email addresses that will receive activity reminders and updates.' {...oldUiProps}>
      <RepeatingSection name='contacts' add='Add additional email' fields={[
        <Field name='email' label='Email address' required component={EmailField} />,
        'remove'
      ]} />
    </Panel>
  }

  async function onSave(form:FormModel<{contacts:UserCcContactsSelections['ccContacts']}>) {
    const variables = { contacts: addDeletedItems(initialValues.contacts, form.values.contacts, 'archived') as any };
    const [success] = await upsertUserCcContacts({variables, error: form});
    return success;
  }

  return render();
}
