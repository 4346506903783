import * as React from 'react';
import { useParams } from 'react-router';

import { useForm, useFormToUrl } from 'app2/components';

import { MutationPanel } from '../shared';
import { AdminPage } from '../AdminPage';

import { AdminHistoryRouteParams } from './AdminHistoryRouteParams';

export function Mutation() {
  const { type, id } = useParams<AdminHistoryRouteParams>();
  const form = useForm({type, id}, []);
  useFormToUrl(form, {path: '/admin2/mutations/:id'});

  function render() {
    return <AdminPage title='Mutations'>
      <MutationPanel id={form.values.id} />
    </AdminPage>
  }

  return render();
}
