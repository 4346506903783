import * as React from 'react';

import { Box, Scalable } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ScalableDemos() {
  return <Demos name='Scalable'       
    description={`
      CSS transform allows you to scale components, but they will always take up the same
      amount of space in their parent.  Scalable is a component that allows a component to
      both scale and only take up a scaled portion of the space in the parent.
    `}>
    <Demo name='Basic'
      description={`
      Here's three components - all the same, except:
      - the first is unscaled
      - the second is scaled with just css transform (note how it's size in the parent doesn't change)
      - the third is scaled with Scalable and note that the space it takes up in the parent scales
      - the fourth is unscaled again
      `}>
        <Box layout='vbox' padding='10px' bg='yellow' gap='10px 10px'>
          <Box height='100px' width='100px' bg='red' fontSize='20px' fontWeight='bold'>The quick brown fox</Box>
          <Box height='100px' width='100px' bg='red' fontSize='20px' fontWeight='bold' transform='scale(.5, .5)'>The quick brown fox</Box>
          <Scalable scale={.5}><Box height='100px' width='100px' bg='red' fontSize='20px' fontWeight='bold'>The quick brown fox</Box></Scalable>
          <Box height='100px' width='100px' bg='red' fontSize='20px' fontWeight='bold'>The quick brown fox</Box>
        </Box>
    </Demo>
  </Demos>
}

