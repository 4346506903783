import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const DropdownClose = React.forwardRef((props:Props, ref:React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg ref={ref} width={props.size} height={props.size} viewBox="0 0 18 18" stroke={color} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <g fill='currentColor'>
      <path d="M13.5 11.25L9 6.75L4.5 11.25" />
      <path d="M13.5 11.25L9 6.75L4.5 11.25" />
      <path d="M13.5 11.25L4.5 11.25" />
    </g>
  </svg>
})

DropdownClose.displayName = 'DropdownClose';
DropdownClose.defaultProps = {
  size: 18
}