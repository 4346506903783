import * as React from 'react';

import { MenuItem } from 'app2/components';
import { Beta } from 'app2/views/shared-public';

import { CourseSelections } from '../../generated';

import { billNow } from './gql';

interface Props {
  course:CourseSelections;
}

export function BillNowAction(props:Props) {
  function render() {
    return <Beta production={false}><MenuItem label='Bill now' onClick={handleBillNow} /></Beta>;
  }

  async function handleBillNow() {
    return await billNow({variables: {course: props.course.id}})
  }

  return render();
}
