import * as React from 'react';
import { useParams } from 'react-router';

import { InputField, LinkField, Panel, Section, CheckboxField, FormModel } from 'app2/components';

import { AdminSiteRouteParams } from '../adminRoutes';

import { useSingleAdminCompanyQuery, SingleAdminCompanySelections, adminUpdateCompany } from './generated';

export function CompanyInfo() {
  const { id } = useParams<AdminSiteRouteParams>();
  const [result] = useSingleAdminCompanyQuery({ variables: { id } });
  const company = result.data?.company || ({} as SingleAdminCompanySelections);

  function render() {
    return (
      <Panel icon="Info" title="Information" initialValues={company} type="toggle" onOk={onOk}>
        <Section label="ID" name="id" readOnly />
        <Section label="Name" name="name" component={InputField} />
        <Section label="Stripe account" name="stripeAccount" component={LinkField} readOnly to='https://dashboard.stripe.com/connect/accounts/$value' />
        <Section label="Non partner approved" name="nonPartnerApproved" component={CheckboxField} />
      </Panel>
    );
  }

  async function onOk(form:FormModel) {
    const [success] = await adminUpdateCompany({variables:{id, name: form.values.name, nonPartnerApproved: form.values.nonPartnerApproved}})

    return success;
  }

  return render();
}
