import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentDetailsFieldsFragmentDoc } from './studentDetailsFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateStudentMutationVariables = Types.Exact<{
  attributes: Types.StudentInput;
}>;

export type UpdateStudentMutation = {
  studentUpdate?:
    | {
        student?:
          | {
              id: string;
              name: string;
              firstName: string;
              lastName: string;
              nickname?: string | null | undefined;
              dob?: string | null | undefined;
              grade: string;
              automaticGradeUpdate: boolean;
              notes?: string | null | undefined;
              site?: { id: string; name?: string | null | undefined } | null | undefined;
              classroom?: { id: string; name: string; grade: string } | null | undefined;
              parents: Array<{ id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
              medical?:
                | {
                    epiPen?: boolean | null | undefined;
                    conditions?: string | null | undefined;
                    allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                    medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const UpdateStudentDocument = gql`
  mutation UpdateStudent($attributes: StudentInput!) {
    studentUpdate(attributes: $attributes) {
      student {
        ...studentDetailsFields
      }
    }
  }
  ${StudentDetailsFieldsFragmentDoc}
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateStudentOptions = ExecMutationOptions<UpdateStudentMutationVariables>;

export function updateStudent(options: UpdateStudentOptions) {
  return executeMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
}
