import * as React from 'react';
import moment from 'moment'
import { useHistory, useParams } from 'react-router'

import { Season, CourseUtils } from 'app2/api';
import { Schedule, ScheduleProps, validOrDefault } from 'app2/components';

import { ScheduledSession, ScheduledSessionProps } from './ScheduledSession'
import { getSessionsForDay } from './getSessionsForDate';
import { SeasonDay } from './SeasonDay';
import { ScheduledCourse } from './ScheduledCourse';

interface Props<T extends ScheduledCourse = ScheduledCourse> extends Omit<ScheduleProps, 'appointments'> {
  season?:Pick<Season, 'coursesBegin' | 'coursesFinish' | 'enrollmentOpens' | 'enrollmentCloses' | 'holidays'>;
  courses:T[];
  scheduleUrlBase:string;
  activityUrlBase:string;
  session:React.ComponentType<ScheduledSessionProps<T>>;
}

export function CourseSchedule<T extends ScheduledCourse = ScheduledCourse>(props:Props<T>) {
  const {season, courses, scheduleUrlBase, activityUrlBase, session, week:propsWeek, ...remaining} = props;
  const {week} = useParams<{week:string}>();
  const history = useHistory();
  const filteredCourses = React.useMemo(() => courses?.filter(CourseUtils.isScheduled), [courses]);
  const intialWeek = React.useMemo(getInitialWeek, [propsWeek, courses]);
  const actualWeek = validOrDefault(moment(week, 'MM-DD-YYYY'), intialWeek);

  function render() {
    return <Schedule width='100%' minHeight='600px'
      appointments={date => getSessions(date)} 
      week={actualWeek} onChange={onChange} {...remaining} />
  }

  function getSessions(date:moment.Moment) {
    const Session = props.session;
    const sessions = getSessionsForDay(filteredCourses, date)
      .map(info => <Session activityUrlBase={props.activityUrlBase} course={info.course} day={info.day} holiday={info.holiday} />);
  
    if (season && date.isBetween(season.enrollmentOpens, season.enrollmentCloses, 'd', '[]')) {
      sessions.unshift(<SeasonDay color='enrollment' title='Enrollment' />)
    }

    if (season && season?.holidays.find(holiday => date.isSame(holiday, 'd'))) {
      sessions.unshift(<SeasonDay color='noEnrichment' title='No activity day' />)
    }

    return sessions;
  }

  function onChange(date:moment.Moment) {
    history.push(`${props.scheduleUrlBase}/${date.format('MM-DD-YYYY')}`)
  }

  function getInitialWeek() {
    if (propsWeek != 'first') {
      return propsWeek;
    }

    const futureCourses = courses.map(c => moment(c.startDate)).filter(date => date.isSameOrAfter(moment(), 'day')).sort((a, b) => a.diff(b))
    const startOrNow = futureCourses.length ? futureCourses[0] : moment();

    return startOrNow
  }

  return render();
}

CourseSchedule.defaultProps = {
  session: ScheduledSession
}
