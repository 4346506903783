import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminWithdrawFundsMutationVariables = Types.Exact<{
  amount: Types.Scalars['Float'];
}>;

export type AdminWithdrawFundsMutation = { homeroomWithdrawFundsFromStripe?: boolean | null | undefined };

export const AdminWithdrawFundsDocument = gql`
  mutation AdminWithdrawFunds($amount: Float!) {
    homeroomWithdrawFundsFromStripe(amount: $amount)
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminWithdrawFundsOptions = ExecMutationOptions<AdminWithdrawFundsMutationVariables>;

export function adminWithdrawFunds(options: AdminWithdrawFundsOptions) {
  return executeMutation<AdminWithdrawFundsMutation, AdminWithdrawFundsMutationVariables>(AdminWithdrawFundsDocument, options);
}
