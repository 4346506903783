import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentRemoveUsBankAccountMutationVariables = Types.Exact<{
  fingerprint: Types.Scalars['ID'];
}>;

export type ParentRemoveUsBankAccountMutation = { parentRemoveUsBankAccount?: { success?: boolean | null | undefined } | null | undefined };

export const ParentRemoveUsBankAccountDocument = gql`
  mutation ParentRemoveUsBankAccount($fingerprint: ID!) @urql(additionalTypenames: ["UsBankAccount"]) {
    parentRemoveUsBankAccount(fingerprint: $fingerprint) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentRemoveUsBankAccountOptions = ExecMutationOptions<ParentRemoveUsBankAccountMutationVariables>;

export function parentRemoveUsBankAccount(options: ParentRemoveUsBankAccountOptions) {
  return executeMutation<ParentRemoveUsBankAccountMutation, ParentRemoveUsBankAccountMutationVariables>(ParentRemoveUsBankAccountDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['UsBankAccount']) }
  });
}
