import * as React from 'react';

import { VBox, Button, ButtonProps, useFormInfo, FieldError, FormModel } from 'app2/components';
import { useLoader } from 'app/app2/components';

import { arrayBufferToBase64 } from './arrayBufferToBase64';

interface Props<T> extends ButtonProps {
  accept?: string;
  acceptError?: string;
  onChoose?: (name: string, fileType: string, fileData: string, form?: FormModel<T>) => void;
  disabled?: boolean;
}

export function FileUploader<T>(props: Props<T>) {
  const { accept, acceptError, onChoose, disabled, children, ...remaining } = props;
  const [loading, setLoading] = React.useState(false);
  const [invalidFileType, setInvalidFileType] = React.useState(false);
  const input = React.useRef<HTMLInputElement>();
  const formInfo = useFormInfo();

  useLoader(loading);

  function render() {
    return <VBox>
      <label style={{ display: 'flex', cursor: disabled ? undefined : 'pointer' }}>
        <Button as='span' disabled={disabled} iconPosition='left' icon='Upload' loading={loading} {...remaining}>
          <input ref={input} style={{ display: 'none' }} type='file' accept={accept} disabled={disabled} onChange={handleFileSelection} />
          {children}
        </Button>
      </label>
      {invalidFileType ? <FieldError error={`File must be ${acceptError}. Please try again`} /> : ''}
    </VBox>;
  }

  function handleFileSelection(changeEvent: React.ChangeEvent<HTMLInputElement>) {
    if (!onChoose) {
      return;
    }

    const file = changeEvent.target.files[0];

    if (!file) {
      return;
    }

    const MAX_SIZE = 20 * 1024 * 1024;

    if (file.size > MAX_SIZE) {
      alert('Files can not exceed 20 MB');
      return;
    }

    if (!accept || accept.indexOf(file.type) != -1) {
      setInvalidFileType(false);
      setLoading(true);

      const reader = new FileReader();
      reader.onload = onFileLoaded.bind(onFileLoaded, file, reader);
      reader.readAsArrayBuffer(file);
    } else {
      setInvalidFileType(true);
    }

    input.current!.value = '';
  }

  async function onFileLoaded(file: File, reader: FileReader) {
    const result = reader.result;
    const fileData = arrayBufferToBase64(result as ArrayBuffer);

    onChoose(file.name, file.type, fileData, formInfo?.form);
    setLoading(false);
  }

  return render();
}

FileUploader.defaultProps = {
  children: 'Upload file',
  accept: [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'text/csv',
    'text/plain'
  ].join(','),
  acceptError: 'image, PDF, MS Office document or text'
};
