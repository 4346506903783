import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StartConversationMutationVariables = Types.Exact<{
  content: Types.Scalars['String'];
  toId: Types.Scalars['ID'];
  toKind: Types.EntityKind;
  from?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type StartConversationMutation = { directoryContact?: { success: boolean } | null | undefined };

export const StartConversationDocument = gql`
  mutation StartConversation($content: String!, $toId: ID!, $toKind: EntityKind!, $from: String) {
    directoryContact(content: $content, toId: $toId, toKind: $toKind, from: $from) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type StartConversationOptions = ExecMutationOptions<StartConversationMutationVariables>;

export function startConversation(options: StartConversationOptions) {
  return executeMutation<StartConversationMutation, StartConversationMutationVariables>(StartConversationDocument, options);
}
