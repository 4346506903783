import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentRemoveDiscountMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentRemoveDiscountMutation = { cartRemoveDiscount?: { success?: boolean | null | undefined } | null | undefined };

export const ParentRemoveDiscountDocument = gql`
  mutation ParentRemoveDiscount($id: ID!) @urql(additionalTypenames: ["User"]) {
    cartRemoveDiscount(id: $id) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentRemoveDiscountOptions = ExecMutationOptions<ParentRemoveDiscountMutationVariables>;

export function parentRemoveDiscount(options: ParentRemoveDiscountOptions) {
  return executeMutation<ParentRemoveDiscountMutation, ParentRemoveDiscountMutationVariables>(ParentRemoveDiscountDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
