import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { Theme, BreakpointInfo } from '../theme';
import { MultiContext } from '../utils';

export function useBreakpoints(breakpoints?: string[]) {
  return !breakpoints ? useCurrentBreakpoint() : useMediaQueryBreakpoints();
}

export function useCurrentBreakpoint() {
  return React.useContext<BreakpointInfo>(MultiContext).breakpoint;
}

export function useMediaQueryBreakpoints(breakpoints?: string[]) {
  const theme = useTheme() as Theme;

  if (!breakpoints) {
    breakpoints = theme.breakpoints as string[];
  }

  const queries = [useMediaQuery({ minWidth: '0px' })].concat(
    breakpoints.map(width => useMediaQuery({ minWidth: width }))
  );
  const breakpoint = queries.lastIndexOf(true);

  return breakpoint;
}
