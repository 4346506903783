import { AnimatedLoader } from "./AnimatedLoader";
import { DropdownClose } from "./DropdownClose";
import { DropdownOpen } from "./DropdownOpen";
import { Left } from "./Left";
import { Right } from "./Right";
import { Refund } from './Refund';
import { InstagramWhite } from './InstagramWhite';
import { Backdrop } from './Backdrop';
import { RadioSelected } from './RadioSelected';

export type CustomIconNames = 
  'DropdownClose' |
  'DropdownOpen' |
  'Left' |
  'Right' |
  'AnimatedLoader' |
  'Refund' |
  'InstagramWhite' |
  'Backdrop' |
  'RadioSelected'
  ;

export const customIcons = {
  DropdownClose: DropdownClose,
  DropdownOpen: DropdownOpen,
  Left: Left,
  Right: Right,
  AnimatedLoader: AnimatedLoader,
  Refund: Refund,
  InstagramWhite: InstagramWhite,
  Backdrop: Backdrop,
  RadioSelected: RadioSelected
}

export const customIconNames:CustomIconNames[] = Object.keys(customIcons) as CustomIconNames[];
