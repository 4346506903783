import { ScriptLoader } from 'app/scriptLoading/ScriptLoader';
import { scriptManager } from 'app/scriptLoading/ScriptManager';

//@ts-ignore
window.fbAsyncInit = function() {
  FB.init({
    appId: '546973036202115',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v6.0'
  });
};

const fbUrl = 'https://connect.facebook.net/en_US/sdk.js';

scriptManager.register(fbUrl, true);

export class FacebookLoader extends ScriptLoader {
  static defaultProps = {
    scripts: [{ script: fbUrl, isUrl: true }]
  };
}
