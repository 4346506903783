export function parseAge(age:string) {
  if (typeof age === 'number') {
    return age;
  }

  if (typeof age !== 'string' || !age) {
    return 0;
  }

  const parts = age.toLowerCase().replace('years', 'year').replace('year', 'y').replace('months', 'month').replace('month', 'm').match(/[0-9]+(\.[0-9]+)?|m|y/g);

  if (!parts) {
    return 0;
  }

  let months = 0;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    const num = Number(part)

    if (typeof num == 'number' && isFinite(num)) {
      if (parts[i + 1] === 'y' || (!parts[i + 1] && !months)) {
        months += Math.round(num * 12);
      } 
      else 
      if (parts[i + 1] === 'm' || (!parts[i + 1] && months)) {
        months += Math.round(num);
      }
    }
  }

  return months;
}
