import * as React from 'react';

import { axios, OrganizerPreferences, organizerPreferences } from 'app2/api';
import { MenuItem, DataTableColumn, HBox, VBox, IconNames, Subtitle2, noopFormatter, useSafeState } from 'app2/components';
import { downloadFile, HrDataTable, HrDataTableProps } from 'app2/views/shared'

interface ReportTotal {
  label:string;
  name:string;
  format?:(value:any) => string;
}

interface Props<T> extends Partial<HrDataTableProps<T>> {
  icon:IconNames;
  title:string;
  resultType:string;
  totals?:ReportTotal[];
  cols:DataTableColumn<T>[];
  colsVersion:string;
  colsPreferenceName:keyof OrganizerPreferences;
  csvUrl:string;
  none:React.ReactNode;
}

export function GenericReportTable<T>(props:Props<T>) {
  const { icon, title, resultType, totals, cols, colsVersion, colsPreferenceName, csvUrl, none, ...remaining} = props;
  const [ result, setResult ] = useSafeState<any>({});

  function render() {
    return (<VBox width="100%">
      <HrDataTable
        header={{icon:props.icon, title:props.title, 
            options: [<MenuItem onClick={downloadCSV}>Download CSV</MenuItem>],
            totals:renderTotals()}}
        table={{cols:props.cols, rowSelection:false, none:props.none}}
        prefs={organizerPreferences}
        prefsKey={props.colsPreferenceName}
        prefsVersion={props.colsVersion}
        queryHook={props.query}
        queryResponseHandler={handleQueryResponse}
        {...remaining}
        />
    </VBox>);
  }

  function renderTotals() {
    if (!props.totals) {
      return null;
    }

    // the -30/30px mt offset each other when there's just 1 row of totals so that
    // the net is nothing, but when the totals wrap and there's more than one row
    // of totals, it causes subsequent rows to have a margin so there's space between the rows
    return <HBox mt={['$8', '-30px']} flexWrap='wrap' hAlign='justify' width='100%'>{props.totals.map((nameValue, index) =>
      <VBox key={index} vItemSpace='$8' width={['100%', 'unset', 'unset']} mt={['$8', '30px']} minWidth='135px' layout={['hbox', 'vbox', 'vbox']} hAlign={['justify', 'left', 'left']}>
        <Subtitle2 whiteSpace='nowrap'>{nameValue.label}</Subtitle2>
        <Subtitle2 fontWeight='normal'>{(nameValue.format || noopFormatter)(result?.[nameValue.name])}</Subtitle2>
      </VBox>
    )}
    </HBox>
  }

  function handleQueryResponse(response:any) {
    const newResult = response.data?.[props.resultType];

    if (result != newResult) {
      setResult(newResult);
    }

    return {items:newResult?.data};
  }

  function downloadCSV() {
    return downloadFile(
      axios.get(props.csvUrl, {
        responseType: 'blob'
      }),
      'text/csv'
    );
  }

  return render();
}

GenericReportTable.defaultProps = {
  icon: 'Book',
  none: 'No transactions to show'
}
