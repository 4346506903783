import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const Refund = React.forwardRef((props: Props, ref: React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return (
    <svg ref={ref} viewBox="0 0 26 26" width={props.size} height={(props.size * 25) / 26}>
      <g transform="translate(-206 -291)" fill="none" fillRule="evenodd">
        <path
          d="M208.007 298.435c1.035-1.098 2.065-2.192 3.12-3.199 2.761-2.632 5.528-4.247 9.04-4.236 6.951 0 12.015 5.49 12.015 12.442 0 5.919-3.941 12.528-12.078 12.528-3.908 0-8.852-2.3-10.993-8.208-.245-.675.144-1.429.86-1.429.495 0 .829.222 1.033.828 1.348 3.998 5.069 6.848 9.093 6.848 7.088 0 9.91-6.594 9.91-10.599 0-5.48-4.096-10.41-9.856-10.41-3.091 0-5.357 1.623-6.86 3.063a164.796 164.796 0 0 0-3.984 3.942l2.475.003c.795 0 1.223.307 1.223.952 0 .988-.905 1.047-1.225 1.047-1.26 0-3.14-.012-4.61-.012-.972 0-1.17-.589-1.17-1.372 0-1.202.008-3.13.008-4.525 0-.646.356-1.092 1.003-1.092.554 0 .996.429.996 1.11v2.32zm11.5-.444v-1.987c0-.52.424-.993.995-.993a.979.979 0 0 1 1.005.993v1.974c.63.001 1.262.009 1.892.028.51.045.93.46.935.955.006.56-.342.988-.935 1.041-.622.018-1.259.012-1.892.004v2.507c2.308.014 3.503 1.688 3.503 3.264 0 1.582-1.386 3.226-3.252 3.226l-.251.002v.999c0 .587-.47.997-1.007.997a.973.973 0 0 1-.993-.997v-.994c-1.235-.002-2.554-.01-3.423-.01-.543 0-1.082-.316-1.082-1.012 0-.697.643-1.003 1.062-1.003.512 0 2.069.006 3.443.012v-2.478l-1.104-.006c-1.503 0-3.404-.972-3.404-3.254 0-1.704 1.345-3.18 3.255-3.25.417-.006.835-.013 1.253-.018zm2 6.543v2.468h.057c.874 0 1.448-.49 1.448-1.236 0-.664-.513-1.23-1.178-1.23l-.327-.002zm-2-4.537l-1.1.001c-.9 0-1.397.62-1.397 1.249 0 .75.576 1.259 1.56 1.259.18 0 .534 0 .937.002v-2.511z"
          fill={color}
        />
      </g>
    </svg>
  );
});

Refund.displayName = 'Refund';
Refund.defaultProps = {
  size: 18
};
