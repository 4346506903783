import * as React from 'react';
import moment from 'moment';

import { CourseUtils, EnrollmentUtils } from 'app2/api';
import { ActionButton, DataTable, NotificationManager } from 'app2/components';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled';

import { ChangeSubscriptions } from './ChangeRecurrings';

interface Props {
  table:DataTable<DistinctEnrollmentsSelections>;
  course: CourseSelections;
}

export function ChangeRecurringsAction(props: Props) {
  function render() {
    return !CourseUtils.isRecurringChangeable(props.course) || !props.course?.behavior?.enrollmentsChangeSubscription
    ? <></>
    : <ActionButton icon="Edit" autoLoader={false} onClick={onClick}>Change subscription</ActionButton>
  }

  function onClick() {
    if (props.course.children?.length) {
      return void(NotificationManager.warning("Please navigate to the specific time slot to change an enrollment's subscription."))
    }

    const enrollments = props.table.selections.selectedItems;
    const inFuture = enrollments.filter(e => moment(e.startDate).isAfter(moment(), 'd'))

    return enrollments.some(e => !EnrollmentUtils.usingRecurring(e) || e.isInvitation)
      ? void(NotificationManager.warning('Change subscription only applies to subscriptions that have been successfully charged. Please review the selected enrollments.'))
        : inFuture.length != 0 && inFuture.length != enrollments.length
          ? void(NotificationManager.warning('You can not change current and future enrollments at the same time.'))
          : <ChangeSubscriptions course={props.course} enrollments={enrollments} />
  }

  return render();
}

