// Javascripts keypress event is deprecated and doesn't behave
// the same on all browsers.  Because of that the only way to know
// if keys are held down when you get KeyboardEvents is you have to
// track them all with keydown and keyup events.  That's tedious
// so this is a global tracker

interface KeyState {
  alt: boolean;
  control: boolean;
  shift: boolean;
  meta: boolean;
}

export const keyState: Partial<KeyState> = {};

export function installKeyStateTracker() {
  document.addEventListener('keydown', onKeyDown, true);
  document.addEventListener('keyup', onKeyUp, true);
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key == 'Alt') {
    keyState.alt = true;
  }

  if (event.key == 'Control') {
    keyState.control = true;
  }

  if (event.key == 'Shift') {
    keyState.shift = true;
  }

  if (event.key == 'Meta') {
    keyState.meta = true;
  }
}

function onKeyUp(event: KeyboardEvent) {
  if (event.key == 'Alt') {
    keyState.alt = false;
  }

  if (event.key == 'Control') {
    keyState.control = false;
  }

  if (event.key == 'Shift') {
    keyState.shift = false;
  }

  if (event.key == 'Meta') {
    keyState.meta = false;
  }
}
