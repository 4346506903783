// The code in this folder is meant to support loading
// various third party resources in a delayed fashiong
// while not breaking the application.  The reason to delay
// load these services is that when these services are loaded
// immediately, it affects the lighthouse score.  There
// are two mechanisms to delay loading the third party scripts.
//
//   - ScriptManager - this manages loading 3rd party scripts.
//     scripts are loaded after 2 seconds after the application start.
//     once loaded it triggers a callback function for each script
//     so that vendor specific logic can be invoked.
//
//  - ScriptLoader - this is a React component that will not render
//    its children until a script is loaded.  this ensures parts of
//    app dependent on the third party code is not invoked until after
//    the code is loaded.  The various uses of ScriptLoader also make
//    a call to ScriptManager at file scope, to load those third party
//    scripts as soon as possible, vs on demand which could cause ui
//    rendering delays.
//
//  - loadThirdParty (shims) - some of our app code is dependent on third party code
//    at startup, that can not be turned off until the script is loaded,
//    like how ScriptLoader functions.  for these third party services,
//    we create small shims that either record the call to be replayed later, or do a no-op

export * from './helpscout';
export * from './logrocket';
export * from './CloudinaryLoader';
export * from './facebook/FacebookLoader';
export * from './AffirmLoader';
export * from './stripe/StripeLoader';
export * from './stripe/StripeElements';
export * from './stripe/StripeElements2';
export * from './stripe/SafeStripeProvider';
export * from './loadThirdParty';
