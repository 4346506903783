import * as React from 'react';
import { Property } from 'csstype';
import * as Icons from 'react-feather';

import { theme, blendColor, colorToComponent, hoverAlpha, componentToHex } from '../theme';
import { Box, BoxProps, HBox } from '../Box';
import { useClickLoader } from '../shield';

import { IconNames } from './IconNames';
import { customIcons } from './CustomIconNames';

// this class wraps the React Feather icons
// but adds the following:
// - property styling
// - change stroke on hover
// - some additional icons

export interface IconProps extends BoxProps {
  // the icon name ypu want to render.  this is
  // the same name as the React Feather component name.
  // if you don't pass an icon name you can alternatively
  // pass the icon you want as a child
  name?: IconNames;
  size?: number | string;
  strokeWidth?: number;
  color?: Property.Color;
  fill?: Property.Color;
  button?: boolean;
  hover?: boolean;
  disabled?: boolean;
  alt?: string;
  loading?:boolean;
  infoTip?:boolean;
}

export function Icon(props:IconProps) {
  function render() {
    let {name, size, strokeWidth, color, fill, button, hover, disabled, alt, cursor, onClick, loading:propsLoading, infoTip, ...boxProps} = props;

    if (infoTip) {
      boxProps['ml'] ='$4';
      size = 'small';
      name = 'Info';
      color = 'currentcolor';
    }

    const { loading, clickLoaderHandler } = useClickLoader(onClick, false, propsLoading);

    if (typeof size === 'string') {
      size = (theme.iconSize as any)[size] || Number(size);
    }
  
    if (Array.isArray(props.name)) {
      return <HBox hAlign='center' vAlign='center'>{
        props.name.map((name, index) => <Icon key={index} {...props} name={name} m={0} p={0} ml={(-Number(size) * index) + 'px'} />)
      }</HBox>;
    }

    const svg = getSvg(loading ? 'AnimatedLoader' : name);

    if (button && !disabled) {
      hover = true;
      cursor = cursor || 'pointer';
    }

    let hoverColor:string;

    if (disabled) {
      color = 'disabled';
    }

    if (color && color != 'currentcolor') {
      color = color.replace('!important', '');
      color = (theme.colors as any)[color] || color;
      hoverColor = !hover || disabled ? undefined : (theme.colors as any)[color + 'Hover'] || componentToHex(blendColor(colorToComponent(color), hoverAlpha));
    }

    let hoverFill:string;

    if (fill) {
      fill = fill.replace('!important', '');
      fill = (theme.colors as any)[fill] || fill;
      hoverFill = !hover || disabled ? undefined : (theme.colors as any)[fill + 'Hover'] || componentToHex(blendColor(colorToComponent(fill), hoverAlpha));
    }

    const css:any = {
      color,
      ':hover,.link:hover &&, &.link:hover': {
        color: hoverFill || hoverColor,
        svg: { 
          stroke: hoverColor,
          fill: hoverFill,
        },
        cursor: disabled ? undefined : cursor
      },
    };

    if (disabled) {
      css.pointerEvents = 'none';
    }

    if (!alt) {
      alt = name as string;
    }

    const svgProps = {size, color, strokeWidth, fill, alt};

    // the icon impl treats undefined props differently than missing props, so remove fill if its undefined
    if (svgProps.fill === undefined) {
      delete svgProps.fill;
    }

    const svgUpdated = svg ? React.cloneElement(svg, svgProps) : svg;

    return <Box display={props.layout ? undefined : 'inline'} lineHeight={0} css={css} cursor={cursor} className={hover ? 'link' : undefined} onClick={clickLoaderHandler} {...boxProps}>{svgUpdated}</Box>;
  }

  function getSvg(name:IconNames): React.ComponentElement<any, any> {
    const children = React.Children.toArray(props.children);

    if (children.length) {
      return children[0] as React.ComponentElement<any, any>;
    }

    if (name) {
      //@ts-ignore
      const icon = Icons[name] as any || customIcons[name];

      if (!icon) {
        return null;
      }

      return React.createElement(icon);
    }
  }

  return render();
}

Icon.defaultProps = {
  size: 'medium',
  strokeWidth: 2,
  color: 'primary'
}