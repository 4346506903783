import * as React from 'react'
import { map } from 'lodash-es';

import { Panel, Field, RepeatingSection, InputField, FormModel } from 'app2/components'

import { useCurrentSite } from '../shared';
import { organizerUpdateCheckoutDestinations, OrganizerUpdateCheckoutDestinationsMutationVariables } from './gql'

export function Dismissal() {
  const { site } = useCurrentSite();
  const checkoutDestinations = site?.checkoutDestinations
  const dismissals = React.useMemo(() => checkoutDestinations?.map((cd) => ({ 'name': cd })), [checkoutDestinations])

  function render() {
    return <Panel icon='FileText' title='Dismissal options' type='toggle' initialValues={{dismissals}} onOk={onOk}>
      <RepeatingSection name='dismissals' add='Add dismissal option' fields={[
        <Field label='Dismissal option name' name='name' placeholder='Enter dismissal option' required component={InputField} />,
        'remove'
      ]} />
    </Panel>
  }

  async function onOk(form:FormModel) {
    const variables: OrganizerUpdateCheckoutDestinationsMutationVariables = { siteId: site.id, checkoutDestinations: map(form.values.dismissals,'name') }
    const [success] = await organizerUpdateCheckoutDestinations({variables, successMsg: "Dismissals updated successfully.", error: form})
    return success
  }

  return render();
}
