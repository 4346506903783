let beaconCalls: IArguments[] = [];

export function BeaconShim() {
  beaconCalls.push(arguments);
}

export function applyShimCalls() {
  beaconCalls.forEach((args: IArguments) => window.Beacon(...args));
  beaconCalls = [];
}
