import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SeasonQuery = {
  season?:
    | { id: string; name: string; status: Types.SeasonStatus; homeroomRate?: number | null | undefined; txnFeeRate?: number | null | undefined; site: { id: string; name?: string | null | undefined } }
    | null
    | undefined;
};

export const SeasonDocument = gql`
  query Season($id: ID!) {
    season(season: $id) {
      id
      name
      status
      site {
        id
        name
      }
      homeroomRate
      txnFeeRate
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SeasonArgs = MakeOptional<UseQueryArgs<SeasonQueryVariables, SeasonQuery>, 'query'>;

export function useSeasonQuery(options: SeasonArgs = {}) {
  return useQuery<SeasonQueryVariables, SeasonQuery>({ query: SeasonDocument, ...options });
}

export type SeasonOptions = ExecQueryOptions<SeasonQueryVariables>;

export function season(options: SeasonOptions) {
  return executeQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
}

export type SeasonSelections = SeasonQuery['season'];
