import * as React from 'react';

import { CourseKind } from 'app2/api';
import { BoxProps, defaultFieldProps, HBox, Icon, IconNames } from 'app2/components';
import { CourseDaysDisplay } from 'app2/views';

import { CourseCardCourse } from './CourseInfo';

interface Props extends BoxProps {
  icon?:IconNames;
  course:CourseCardCourse;
}

export function CourseDays(props: Props) {
  const {course, gap, mb, icon, ...remaining} = props;

  function render() {
    if (course?.kind == CourseKind.LessonSet) {
      return <></>;
    }

    return <HBox mb={mb || "$8"} vAlign="top" gap={'$8' || gap}>
      {course?.courseDays && <Icon name={icon || "Calendar"} size='medium' />}
      <CourseDaysDisplay value={course?.courseDays} {...remaining} />
    </HBox>
  }

  return render();
}

CourseDays.fieldProps = {
  ...defaultFieldProps,
  valueProperty: 'course'
}
