import { createColors, blendColor, hexToComponent } from './Color';

export const hoverAlpha = 0.8;
export const selectedTextAlpha = 0.15;
export const selectedItemAlpha = 0.08;
export const hoveredItemAlpha = 0.04;
export const focusAlpha = 0.42;

export const palette = {
  primary: {
    green: createColors('#003b3b'),
    greenHover: createColors(blendColor(hexToComponent('#003b3b'), hoverAlpha)),
    greenPressed: createColors('#003434'),
    greenFocus: createColors(blendColor(hexToComponent('#003b3b'), focusAlpha)),
    greenSelected: createColors(blendColor(hexToComponent('#003b3b'), selectedItemAlpha)),
    mauve: createColors('#864f58'),
    lightMauve: createColors('#864f58'),
    darkMauve: createColors('#5C363C'),
    pink: createColors('#BF6E7C'),
    navigation: createColors('#1C4856')
  },
  secondary: {
    blue: createColors('#366F81'),
    teal: createColors('#BFE5DD'),
    lightGreen: createColors('#DFE9A0'),
    lightPink: createColors('#E1D0D6'),
    lightYellow: createColors('#FFFCAD'),
    salmon: createColors('#FF8D74')
  },
  tertiary: {
    greenBlack: createColors('#0E2826'),
    greenBlackHover: createColors(blendColor(hexToComponent('#0E2826'), hoverAlpha)),
    formGray: createColors('#F0F0F0'),
    lightGray: createColors('#F1F1F1'),
    lightMediumGray: createColors('#E4E4E4'),
    mediumGray: createColors('#D8D8D8'),
    darkGray: createColors('#646464'),
    error: createColors('#DD0000'),
    errorHover: createColors(blendColor(createColors('#FF0000').components, hoverAlpha)),
    errorFocus: createColors(blendColor(createColors('#FF0000').components, focusAlpha)),
    errorFill: createColors('#FAE9E9'),
    warning: createColors('#FF9900'),
    warningText: createColors('#d47901'),
    warningHover: createColors(blendColor(createColors('#FF9900').components, hoverAlpha)),
    warningFocus: createColors(blendColor(createColors('#FF9900').components, focusAlpha)),
    warningFill: createColors('#FBEBD2'),
    success: createColors('#2AA200'),
    successFill: createColors('#E9F2E6'),
    secondaryHover: createColors('#E1E7E7'),
    secondaryPressed: createColors('#C8D4D4'),
    marketing: createColors('#F8F3E8')
  }
};

export type Palette = typeof palette;
export type PaletteSection =
  | typeof palette.primary
  | typeof palette.secondary
  | typeof palette.tertiary;
