import * as moment from 'moment'

import { DateInput, parseSingleDate } from 'app2/components';

// if val has a time component, then it will be formatted using that
// timezone, else you can override the timezone that is used by passing it in
export function iso8601Date(val:DateInput, timezone?:string): string {
  const isMoment = moment.isMoment(val);

  if (val === undefined || val === null || val === '' || (isMoment && !val.isValid())) {
    return null;
  }

  // if we have a date time and not just a date, then we need to 
  // convert it to just a date, which means formatting it in 
  // the current time zone to get the correct date
  return parseSingleDate(val, timezone).format('YYYY-MM-DD');
}
