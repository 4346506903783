import { CourseKind, CourseStatus } from 'app2/api';

export type CoursesQueryVars = {
  seasonId?:string;
  companyId?:string;
  courseId?:string;

  statuses:CourseStatus[];
  kinds:CourseKind[];
  totals?:CourseStatus[][];
};

export function ownerToCourseQueryVars(props:{ownerKind:string, ownerId:string}) {
  let {ownerKind, ownerId} = props;
  ownerKind = ownerKind?.toLocaleLowerCase()

  if (ownerKind == 'season') {
    return {seasonId: ownerId}
  }

  if (ownerKind == 'company') {
    return {companyId: ownerId}
  }

  if (ownerKind == 'course') {
    return {courseId: ownerId}
  }
}

export function entityToCourseQueryVars(props:{entityKind:string, entityId:string}) {
  let {entityKind, entityId} = props;
  entityKind = entityKind?.toLocaleLowerCase()

  if (entityKind == 'season') {
    return {seasonId: entityId}
  }

  if (entityKind == 'company') {
    return {companyId: entityId}
  }

  if (entityKind == 'course') {
    return {courseId: entityId}
  }
}

export function courseQueryVarsToEntity(queryVars:CoursesQueryVars) {
  if (queryVars.seasonId) {
    return {entityKind:'Season', entityId: queryVars.seasonId}
  }

  if (queryVars.companyId) {
    return {entityKind:'Company', entityId: queryVars.companyId}
  }

  if (queryVars.courseId) {
    return {entityKind:'Course', entityId: queryVars.courseId}
  }
}

