import * as React from 'react'
import { useParams } from 'react-router';

import { StudentModal, Threads } from 'app2/views/shared'

import { OrganizerPage } from '../shared';
import { SiteRouteParams } from '../organizerRoutes';

export function SiteMessages() {
  const { site } = useParams<SiteRouteParams>();

  return <OrganizerPage title='Messages' subtitle='To send a new message, navigate to an individual activity, select one or more students, and compose your message from there.'>
    <Threads role='organizer' labelOrParticipant={{type: 'Site', id: site}} entityMessages={true} onClickStudent={id => <StudentModal id={id} />} />
  </OrganizerPage>
}
