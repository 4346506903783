import { DataTableColumn, colId } from 'app2/components';

export function orderCols<T = any>(order: string[], cols: (DataTableColumn<T> | string)[], removeNotOrdered:boolean) {
  if (!order) {
    return cols;
  }

  const colsIndex = new Map();
  cols.forEach(col => colsIndex.set(colId(col), col));

  const idsIndex = new Set();
  order.forEach(id => idsIndex.add(id));

  const missing = cols.filter(col => !idsIndex.has(colId(col)))
  const ordered = order.map(name => colsIndex.get(name) || name);

  return removeNotOrdered ? ordered : [...ordered, ...missing];
}
