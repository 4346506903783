import * as React from 'react';

import { TableAction, TableActionProps } from 'app2/views/shared';
import { finalizeCourses } from 'app2/views/shared';

export function FinalizeCourseAction(props:TableActionProps & {createActivities?:boolean}) {
  function render() {
    return <TableAction icon='Send' msg='Activities sent to the organizer' label='Send to organizer' clickEvent='Click Send to organizer' ability='finalizable' mutation={finalizeCourses} {...props} />
  }

  return render();
}
