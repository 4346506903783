import * as React from 'react';

import {
  buildCloudinaryImageUrl,
  HBox,
  Subtitle1,
  BoxProps,
  RatioBox,
  Link,
  Img,
  Box
} from 'app2/components';

import { eventManager, UserUtils } from 'app2/api';
import { ProviderCard as ProviderCardType } from 'app2/api';

export const PROVIDER_CARD_IMAGE_HEIGHT = 95;
export const PROVIDER_CARD_IMAGE_WIDTH = PROVIDER_CARD_IMAGE_HEIGHT;
export const PROVIDER_CARD_RATIO = (125 * 100) / 390 + '%';

interface Props extends BoxProps {
  item: ProviderCardType;
}

export function ProviderCard(props: Props) {
  const { item, ...remaining } = props;
  const initials = UserUtils.getInitials(item.name);
  const to = `/provider/${item.slug}/about`;
  const baseImageProps: BoxProps = {
    height: PROVIDER_CARD_IMAGE_HEIGHT,
    width: PROVIDER_CARD_IMAGE_HEIGHT,
    borderRadius: '50%',
    mr: '$30'
  };

  function handleProviderCardClick() {
    eventManager.log('clickProviderCard', {
      url: window.location.href,
      providerName: item.name,
      providerImageUploaded: Boolean(item.profile_photo)
    });
  }

  const image = item.profile_photo ? (
    <Img
      src={buildCloudinaryImageUrl(item.profile_photo)}
      {...baseImageProps}
    />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="brandDark"
      zIndex={0}
      {...baseImageProps}
    >
      <Box fontFamily="primary" color="white" fontSize="77px">
        {initials[0]}
      </Box>
    </Box>
  );

  return (
    <RatioBox
      boxShadow="box"
      borderRadius="standard"
      ratio={PROVIDER_CARD_RATIO}
      content={{ layout: 'vbox', px: '$15', py: '$15' }}
      {...remaining}
    >
      <Link
        to={to}
        underline={false}
        onClick={handleProviderCardClick}
        style={{ height: '100%', width: '100%' }}
      >
        <HBox flex="1" height="100%" width="100%" vAlign="center" hAlign="left">
          {image}
          <Subtitle1 fontWeight="bold" maxLines={2}>
            {item.name}
          </Subtitle1>
        </HBox>
      </Link>
    </RatioBox>
  );
}
