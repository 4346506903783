import * as React from 'react';

import { CourseDay } from 'app2/api';
import { longDateFormatter, formatTime } from 'app2/components';
import { SwooshHeader } from 'app2/views/shared-public'

import { AttendanceCourseSelections } from './generated';

interface Props {
  course:AttendanceCourseSelections;
  date:string;
  day:Partial<CourseDay>;
}

export function CourseHeader(props:Props) {
  const dayTime = props.day ? `${formatTime(props.day?.start)} - ${formatTime(props.day?.finish)}` : undefined;

  return <SwooshHeader icon='Activity' title={props.course?.name} breakAt={0}
    details={[props.course?.site?.name, longDateFormatter(props.date) as string, dayTime]} swoosh='center'
    />
}

