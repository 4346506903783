import * as React from 'react';

import { CurrencyField, Form, InputField, Modal, NotificationManager, SaveableResult, Section } from 'app2/components';
import { MutationFunction } from 'app2/views/shared';

export async function createAdjustmentHelper<V extends {amount:number, description:string}>(mutation:MutationFunction<V, any>, baseVariables:any) {
    const modal = <Modal title="Create adjustment">
      <Form>
        <Section label='Amount' name='amount' component={CurrencyField} />
        <Section label='Description' name='description' component={InputField} />
      </Form>
    </Modal>

    const result = await Modal.show(modal);

    if (result.result == SaveableResult.cancel) {
      return;
    }

    const variables = {...baseVariables, amount:result.result.amount, description:result.result.description}
    const [success] = await mutation({variables});

    if (success) {
      NotificationManager.add('Adjustment created');
    }

    return success;
}
