import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteInviteAcceptMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type SiteInviteAcceptMutation = { siteInviteAccept?: { success?: boolean | null | undefined } | null | undefined };

export const SiteInviteAcceptDocument = gql`
  mutation SiteInviteAccept($token: String!) {
    siteInviteAccept(token: $token) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SiteInviteAcceptOptions = ExecMutationOptions<SiteInviteAcceptMutationVariables>;

export function siteInviteAccept(options: SiteInviteAcceptOptions) {
  return executeMutation<SiteInviteAcceptMutation, SiteInviteAcceptMutationVariables>(SiteInviteAcceptDocument, options);
}
