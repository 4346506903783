import { colId, DataTableColumn } from 'app2/components';

export interface HomeroomFieldMeta {
  // if specified on a field, used for sort, filter, and report downloads
  // unless overridden by sortName, filterName, reportName
  serverName:string;
  sortName:string;
  filterName:string;
  reportName:string;
}

export function serverFieldName(col:DataTableColumn<any, any>) {
  return col.meta?.serverName || colId(col);
}

export function sortFieldName(col:DataTableColumn<any, any>) {
  return (col.meta as HomeroomFieldMeta)?.sortName || serverFieldName(col);
}

export function filterFieldName(col:DataTableColumn<any, any>) {
  return (col.meta as HomeroomFieldMeta)?.filterName || serverFieldName(col);
}

export function reportFieldName(col:DataTableColumn<any, any>) {
  return (col.meta as HomeroomFieldMeta)?.reportName || serverFieldName(col);
}
