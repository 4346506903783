import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type StudentScheduleFieldsFragment = {
  id: string;
  name: string;
  enrollments?:
    | Array<
        | {
            id: string;
            status: Types.EnrollmentStatusEnum;
            waitlistKind: Types.WaitlistKind;
            pickup?: string | null | undefined;
            dismissal?: string | null | undefined;
            groups?: Array<string> | null | undefined;
            rosterPeriod?: string | null | undefined;
            pickupDropoffRequired: boolean;
            startDate?: string | null | undefined;
            endDate?: string | null | undefined;
            isOption: boolean;
            priceConfig?:
              | { kind: Types.PriceConfigKind }
              | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string> }
              | { kind: Types.PriceConfigKind; weekdays: Array<string> }
              | { kind: Types.PriceConfigKind }
              | null
              | undefined;
            optionParent?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
            course: {
              id: string;
              name?: string | null | undefined;
              room?: string | null | undefined;
              siteGradeLabels?: Array<string | null | undefined> | null | undefined;
              ageGroup?: string | null | undefined;
              status: Types.CourseStatus;
              kind?: Types.CourseKind | null | undefined;
              noEnrichmentDays: Array<string | null | undefined>;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
              company?: { name?: string | null | undefined; id: string } | null | undefined;
              site: { id: string; name?: string | null | undefined; checkoutDestinations?: Array<string> | null | undefined; pickupDestinations?: Array<string> | null | undefined };
              season: { id: string; name: string };
              homeTeam?: { name?: string | null | undefined } | null | undefined;
              awayTeam?: { name?: string | null | undefined } | null | undefined;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const StudentScheduleFieldsFragmentDoc = gql`
  fragment studentScheduleFields on Student {
    id
    name
    enrollments(queries: [["CONFIRMED", "WAITLISTED"], "SCHEDULED", "PRESENT"]) {
      id
      status
      waitlistKind
      pickup
      dismissal
      groups
      rosterPeriod
      pickupDropoffRequired
      priceConfig {
        ... on SeasonPriceConfig {
          kind
          weekdays
        }
        ... on RecurringPriceConfig {
          kind
          unit
          weekdays
        }
        ... on DropInPriceConfig {
          kind
        }
        ... on UsagePriceConfig {
          kind
        }
      }
      startDate
      endDate
      isOption
      optionParent {
        id
        course {
          id
          startDate
        }
      }
      course {
        id
        name
        room
        siteGradeLabels
        ageGroup
        status
        kind
        courseDays {
          start
          finish
          days
        }
        noEnrichmentDays
        startDate
        endDate
        company {
          name
          id
        }
        site {
          id
          name
          checkoutDestinations
          pickupDestinations
        }
        season {
          id
          name
        }
        homeTeam {
          name
        }
        awayTeam {
          name
        }
      }
    }
  }
`;
