import * as React from 'react'
import { isEqual } from 'lodash-es';

import { MultiContext, BreakpointInfo } from 'app2/components';
import { useResizeObserver, useForceUpdate, useLifecycle } from './utils';

// scales the children but maintains the layout sizing (which
// css transform will not do on its own)

interface Props {
  // scale can be a number that represents the scale factor compared to its natural size
  // or it can be a % that represents the % of the parent to occupy
  // or it can be a string in pixels that specifies the desired width
  scale:number | string | (number | string)[];
  maxScale?:number;
  children:React.ReactElement;
  // if there's a parent transform that will affect getting the correct dimensions
  parentScale?:number;
}

export function Scalable(props:Props) {
  const forceUpdate = useForceUpdate();
  const ref = React.useRef<HTMLDivElement>();
  const dims = React.useRef<ReturnType<typeof calculateBaseDimensions>>({container:{height:0, width:0}, child:{height:0, width:0}});

  useResizeObserver(ref.current?.parentElement, onResize);
  // we need to watch the child in case its response, such that when we change
  // the size of the children, it might change aspect ratio and then
  // we need to recalculate the scale...this could cause an infinite loop
  // but hopefully not (and browsers guard against this in ResizeObserver).
  // and our recalc method bails if theres no change.
  useResizeObserver(ref.current?.firstElementChild as HTMLElement, onResize);
  useLifecycle({onMount});

  const context = React.useContext<BreakpointInfo>(MultiContext);

  function render() {
    const base = dims.current;
    const desired = Array.isArray(props.scale) ? props.scale[context.breakpoint] : props.scale;
    let width:number;
  
    if (typeof desired == 'string') {
      if (desired.endsWith('%') && ref.current) {
        width = (base.container.width * parseFloat(desired)) / 100;
      }
      else {
        width = parseFloat(desired) || ref.current?.getBoundingClientRect?.()?.width;
      }
    }
    else {
      width = desired * base.child.width;
    }

    // until we know the rendered width (which we know because we start with 0, then use the desired
    // width, so that the scale starts at 1).  this avoids an initial large scale which can cause some ui flashing
    // and large scrolling, leaving the initial rendering scrolled to a random location
    let scale = Math.min((Math.max(width, 1) / Math.max(base.child.width || width, 1)), props.maxScale || 99999);
    let height = base.child.height * scale;

    if (props.parentScale) {
      width /= props.parentScale;
      height /= props.parentScale;
    }

    return <div ref={ref} style={{transform:`scale(${scale})`, transformOrigin:'top left', width:width + 'px', minWidth:width + 'px', height:height + 'px', minHeight: height + 'px'}}>
      {props.children}
    </div>
  }

  function calculateBaseDimensions() {
    const container = {height:0, width:0};
    const child = {height:0, width:0};

    if (!ref.current) {
      return {container, child};
    }
    
    const overflow = ref.current.style.overflow;
    const transform = ref.current.style.transform;
    const beforeWidth = ref.current.style.width;
    const beforeHeight = ref.current.style.height;

    ref.current.style.overflow = 'hidden'; //need to clip the child to figure out the containers natural width
    ref.current.style.transform = '';
    ref.current.style.width = '100%';//we want to allow filling to the complete parent
    ref.current.style.minWidth = '';
    ref.current.style.height = '';

    container.width = ref.current.getBoundingClientRect().width;
    container.height = ref.current.getBoundingClientRect().height;

    child.width = ref.current.firstElementChild.getBoundingClientRect().width;
    child.height = ref.current.firstElementChild.getBoundingClientRect().height;

    ref.current.style.overflow = overflow;
    ref.current.style.transform = transform;
    ref.current.style.width = beforeWidth;
    ref.current.style.minWidth = beforeWidth;
    ref.current.style.height = beforeHeight;

    return {container, child};
  }

  function recalculate() {
    const updated = calculateBaseDimensions();

    if (isEqual(updated, dims.current)) {
      return;
    }

    dims.current = updated;
    forceUpdate();
  }

  function onMount() {
    recalculate();
  }

  function onResize() {
    if (!ref.current?.firstElementChild) {
      return;
    }

    recalculate();
  }

  return render();
}
