import * as React from 'react'

import { CourseFilterOptions, ageOptions } from 'app2/api';
import { Box, Checkbox, DatePicker, Dropdown, Field, FieldPlaceholder, Form, FormModel, Text, useFormToUrl, VBox } from 'app2/components';
import { dayOptions, courseKindBehavior, GradeField, CourseTagsDropdown } from 'app2/views/shared-public';

import { PublicSeason } from './PublicSeason';

interface Props {
  site:string;
  seasons:PublicSeason[];
  filterOptions:FormModel<CourseFilterOptions>;
}

export function Filter(props:Props) {
  useFormToUrl(props.filterOptions);

  const needsGradeFilter = React.useMemo(() => props.seasons.some(season => season.courses.some(c => c.grades?.length)), [props.site]);
  const needsAgeFilter = React.useMemo(() => props.seasons.some(season => season.courses.some(c => typeof c.ageMin == 'number')), [props.site]);

  function render() {
    return <Form mb='$30' onNavigation='nothing' form={props.filterOptions} width='100%'>
      <Box layout={['vbox', 'hbox', 'hbox']} gap='$8' hAlign={['stretch', 'left']} vAlign={['top', 'center']} width='100%' flexWrap='wrap'>
        <Text text='subtitle2' whiteSpace='nowrap'>Filter by</Text>
        {needsGradeFilter && <Field name='grades' label='Grades' placeholder={FieldPlaceholder.select} multiple component={GradeField} site={props.site} flex={1} nowrap disablePhoneInput />}
        {needsAgeFilter && <Field name='ages' label='Ages' placeholder={FieldPlaceholder.select} multiple component={Dropdown} options={ageOptions} flex={1} nowrap disablePhoneInput />}
        <Field name='tags' label='Subjects' placeholder={FieldPlaceholder.select} multiple component={CourseTagsDropdown} valueType='name' flex={1} nowrap disablePhoneInput />
        {courseKindBehavior[props.seasons[0].approvedCourseKindGroups[0]]?.sessionType == 'days'
          ? <Field name='days' label='Days' placeholder={FieldPlaceholder.select} multiple component={Dropdown} options={dayOptions} flex={1} nowrap disablePhoneInput />
          : <Field name='dates' label='Dates' placeholder={FieldPlaceholder.select} multiple component={DatePicker} type='range' flex={1} nowrap /> }
        <VBox gap='$4'>
          {needsGradeFilter && <Field name='byGrade' component={Checkbox} label='Show by grade' />}
          {needsAgeFilter && <Field name='byAge' component={Checkbox} label='Show by age' />}
        </VBox>
      </Box>
    </Form>
  }

  return render();
}

