import * as React from 'react'
import { omitBy, isUndefined } from 'lodash-es';

import { Dropdown, DropdownProps } from './Dropdown';
import { Input, InputProps } from './Input';
import { HBox, BoxProps } from './Box';
import { Option, OptionValue } from './Option';
import { createOrClone } from './utils';

interface InputLikeProps {
  value?:any;
  onChange?:(event:React.ChangeEvent<any>) => void;
}

interface Props extends BoxProps {
  options?:(string | Option)[];
  selected?:OptionValue;
  onChangeOptions?: React.ChangeEventHandler<Dropdown>;
  dropdown?:DropdownProps | React.ComponentType<DropdownProps> | React.ReactElement<DropdownProps>;

  placeholder?: string;
  value?: OptionValue;
  onChangeValue?:React.ChangeEventHandler<HTMLInputElement>;
  input?:InputProps | React.ComponentType<InputLikeProps> | React.ReactElement<InputLikeProps>;

  swap?:boolean;
}

export function Combobox(props:Props) {
  const {options, selected, onChangeOptions, dropdown, placeholder, value, onChangeValue, input, swap, ...remaining} = props;
  const dropdownElement = createOrClone(Dropdown, omitBy({width: '50%', maxWidth: '50%', flex: 1, minHeight:'100%', bg:'border', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', options, onChange:onChangeOptions, value:selected}, isUndefined), dropdown);
  const inputElement = createOrClone(Input, omitBy({width: '50%', maxWidth: '50%', flex: 1, borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', placeholder, value, onChange:onChangeValue, textAlign: 'right'}, isUndefined), input);

  return <HBox {...remaining}>
    {!swap ? <>{dropdownElement}{inputElement}</> : <>{inputElement}{dropdownElement}</>}
  </HBox>
}
