import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamParticipantsUpsertMutationVariables = Types.Exact<{
  entityKind: Types.EntityKind;
  entityId: Types.Scalars['ID'];
  participants: Array<Types.ParticipantInput> | Types.ParticipantInput;
}>;

export type TeamParticipantsUpsertMutation = {
  teamParticipantsUpsert?:
    | {
        entity?:
          | { id: string; participants?: Array<{ kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string }> | null | undefined }
          | { id: string; participants?: Array<{ kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string }> | null | undefined }
          | { id: string; participants?: Array<{ kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string } | { kind: Types.ParticipantKind; id: string }> | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const TeamParticipantsUpsertDocument = gql`
  mutation TeamParticipantsUpsert($entityKind: EntityKind!, $entityId: ID!, $participants: [ParticipantInput!]!) {
    teamParticipantsUpsert(entityKind: $entityKind, entityId: $entityId, participants: $participants) {
      entity {
        id
        participants {
          kind
          id
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TeamParticipantsUpsertOptions = ExecMutationOptions<TeamParticipantsUpsertMutationVariables>;

export function teamParticipantsUpsert(options: TeamParticipantsUpsertOptions) {
  return executeMutation<TeamParticipantsUpsertMutation, TeamParticipantsUpsertMutationVariables>(TeamParticipantsUpsertDocument, options);
}
