import * as React from 'react';
import { debounce } from 'lodash-es';

import { DataTable, MenuItem } from 'app2/components';
import { ContentType, downloadReport, createReportViewFromTableV2, HrDataTable, removePathSegment } from 'app2/views/shared';

import { TimeTrackingProps, getOwner } from '../TimeTrackingProps';

import { useTimeTrackingCols } from './useTimeTrackingCols';
import { TimeEntriesSelections, timeEntriesUpdate, useTimeEntriesQuery } from './generated';

export function TimeTrackingTable(props:TimeTrackingProps) {
  const variables = getOwner(props);
  const cols = useTimeTrackingCols(props);
  const table = React.useRef<DataTable<TimeEntriesSelections>>();

  function render() {
    return <HrDataTable<TimeEntriesSelections> 
      table={{cols, ref: table, cellStyle: 'read-only', editable: true, atLeastOneEditableRow: false, sortFilterWhenEditable: true, appendable: false, onDataUpdate}} 
      header={{title: 'Time log', editing: true, options: renderOptions()}} sortFilterType='v2' query={useTimeEntriesQuery} queryOptions={{variables}} />;
  }

  function renderOptions() {
    return [
      <MenuItem onClick={downloadCsv}>Download CSV</MenuItem>,
      <MenuItem onClick={downloadPdf}>Download PDF</MenuItem>
    ]
  }

  function downloadCsv() {
    download(ContentType.CSV);
  }

  function downloadPdf() {
    download(ContentType.PDF);
  }

  async function download(contentType:ContentType) {
    const report = createReportViewFromTableV2('timeEntries', variables, table.current);
    return downloadReport(`${props.entity.name} time log`, contentType, report);
  }

  const onDataUpdate = debounce(async () => {
    const changes = table.current.getChanges();
    const entries = Object.keys(changes).map(id => {
      const timeEntry = changes[id].item;
      return {id, checkedIn: timeEntry.checkedIn, checkedOut: timeEntry.checkedOut};
    })

    return await timeEntriesUpdate({variables: {...variables, entries}, error: {handler: table.current, transform: removePathSegment('entries')}});
  }, 1000);

  return render();
}

