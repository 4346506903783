export enum ErrorPathSegmentType {
  property,
  index,
  id
}

export interface ErrorPathSegment {
  type:ErrorPathSegmentType;
  value:string;
}

export interface ErrorWithPath {
  message:string;
  path:ErrorPathSegment[];
  code?:string;
}

// utilities for manipulating errors
export function errorHasPath(error:ErrorWithPath) {
  return error && error.path && error.path.length > 0;
}

export function popErrorPath(error:ErrorWithPath) {
  return {...error, path: error.path.slice(1)};
}