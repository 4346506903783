import * as React from 'react';
import { useHistory } from 'react-router';

import { impersonate } from 'app2/api'
import { Button, Field, EmailField, PhoneField } from 'app2/components';

import { AdminPage } from '../AdminPage';

import { SearchableList, UserLink, useAdminUsersQuery } from '../shared';

export function Users() {
  const history = useHistory();

  function render() {
    return <AdminPage title='Users'>
      <SearchableList query={useAdminUsersQuery} variables={variables} name='adminUsers' fields={[
        <Field name='name' label='Name' component={UserLink} />,
        <Field name='email' label='Email' component={EmailField} />,
        <Field name='phone' label='Phone' component={PhoneField} />,
        <Field name='id' render={props => <Button width='min-content' onClick={() => handleImpersonate(props.info.value)}>Impersonate</Button>} />,
      ]} />
    </AdminPage>
  }

  function variables(term:string) {
    return [{search:term}]
  }

  async function handleImpersonate(userId:string) {
    await impersonate(userId);

    history.replace('/');
  }

  return render();
}
