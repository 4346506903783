import * as React from 'react';

import { Link, Panel, RepeatingSection, Field } from 'app2/components';

import { useVendorInvitesQuery } from './generated';

export function VendorInvites() {
  const [ vendorInvitesResult ] = useVendorInvitesQuery();
  const userWithInvites = vendorInvitesResult.data?.user;

  if (!userWithInvites?.vendorInvites?.length) {
    return <></>;
  }

  return <Panel icon='Gift' title='Invitations' subtitle='You have invitations to teach at schools' initialValues={userWithInvites}>
    <RepeatingSection name='vendorInvites' numbered={false} fields={[
      <Field name='site.name' width='100%' />,
      <Field name='token' format={(token:string) => <Link button='primary' to={`/provider/activity-invite/${token}`}>Accept</Link>} />
    ]} />
  </Panel>
}
