import { ApiResponse } from "../error";

import { axios } from './axios';

interface Args {
  email:string;
}

export async function resetPasswordRequest(args:Args):Promise<ApiResponse> {
  try {
    const response = await axios.post('/auth/password', args)

    return {success: true, errors: []};
  }
  catch(e) {
    return {success: true, errors: []};
  }
}
