import * as React from 'react'
import { xor } from 'lodash-es';

import { DataTable, DataTableColumn, CellRenderProps, MultipleSelection, SelectionEventSource } from '../datatable';
import { Option, OptionValue, compareOptionValues, compareOptionValueArrays } from '../Option'
import { OptionText } from '../OptionText';
import { dispatchChangeEvent, Point } from '../dom-utils';
import { BoxProps } from '../Box';
import { getOptionStringValue } from '../Option';
import { createOrClone, TypePropsOrElement } from '../utils';

import { ListItemRenderer } from './ListItemRenderer';
import { SelectionRenderer } from './SelectionRenderer';

export interface ListProps extends Omit<BoxProps, 'onChange'> {
  options?:(string | Option)[];
  multiple?:boolean;
  focusable?:boolean;
  measuredRows?:boolean;
  maxLines?:number;
  rowHeight?:((row:number) => number) | number;
  renderer:TypePropsOrElement<any>;
  selectedStyle?: 'bar' | 'checkbox';
  value?:OptionValue | OptionValue[];
  tooltips?: boolean;//shows tooltips for list items
  // dictates if the enter key can toggle off the selection
  // of a list item.  for lists that allow additions, this
  // setting is useful to disallow enter from deselecting a 
  // newly added list item
  enterToggleSelects?:boolean;
  onChange?:React.ChangeEventHandler<List>;
  // triggered when the user initiates a selection change
  // action (click, enter key) that results in no change
  onNoChange?:() => void;
  onFocusChange?:(focus:Option) => void;
  makeSelectionVisible?:boolean;
}

interface State {
  focus?:OptionValue;
  focusIndex?:number;//used by SelectionItemRenderer and used to draw track focus independent of selection
  selected?:OptionValue[];
  selectedIndexes?:OptionValue[];
}
export class List extends React.Component<ListProps, State> {
  static defaultProps:Partial<ListProps> = {
    selectedStyle: 'bar',
    renderer:ListItemRenderer,
    focusable: true,
    enterToggleSelects: true,
    makeSelectionVisible: true
  }

  ref = React.createRef<DataTable>();
  // not stored in state because of react not updating state immediately
  options?:Option[];
  prevProps:ListProps;
  cols:DataTableColumn[];

  constructor(props:ListProps) {
    super(props);
    this.updateOptions();
    this.state = {};
    this.state = {...this.updateSelectedFromProps(true, true)};
  }

  get value() {
    return this.props.multiple ? this.state.selected.slice() : this.state.selected[0];
  }

  get table() {
    return this.ref.current;
  }

  isSelected(pos:number) {
    return this.state.selectedIndexes.indexOf(pos) != -1;
  }

  selectFromKeyboardEvent(event:React.KeyboardEvent<HTMLElement> | KeyboardEvent) {
    this.table.selectionManager.selectFromKeyboardEvent(event);
  }

  makeFocusVisible(delayed?:boolean) {
    if (delayed) {
      setTimeout(() => this.makeFocusVisible(), 150);
    }
    else {
      this.table?.makeVisible(new Point(0, this.state.focusIndex));
    }
  }

  componentDidMount() {
    if (this.state.focus !== undefined) {
      this.makeFocusVisible(true);
    }
  }

  componentDidUpdate(prevProps:ListProps) {
    let updatedOptions = false;

    if (this.props.options !== prevProps.options) {
      this.updateOptions();
      updatedOptions = true;
    }

    let updatedSelection = false;

    if (!compareOptionValues(this.props.value, prevProps.value)) {
      updatedSelection = true;
    }

    if (updatedOptions || updatedSelection) {
      this.setState(this.updateSelectedFromProps(updatedOptions, updatedSelection), () => {
        if (this.props.makeSelectionVisible) {
          // the list isn't redrawn when this is called (due to the layers 
          // in DataTable/VirtualTable so we need to delay this
          this.makeFocusVisible(true);
        }
      });
    }
  }

  render() {
    const {value, options, multiple, focusable, renderer:Renderer, selectedStyle, tooltips, enterToggleSelects, onChange, onNoChange, onFocusChange, measuredRows, maxLines, overflowY, height, makeSelectionVisible, ...remaining} = this.props;

    // make the focus the first element in the selection so that the
    // table will use that for selection
    let selectedValues = this.state.selected.filter(selected => !compareOptionValues(selected, this.state.focus));
    selectedValues.unshift(this.state.focus);

    let selected = this.valuesToIndexes(this.options, selectedValues);
    const textRendererProps = measuredRows ? {} : {whiteSpace: 'nowrap', overflowWrap: null};
    const cellRenderer = createOrClone(ListItemRenderer, {list:this}, Renderer) as unknown as React.ReactElement<CellRenderProps>;
    const colOptions = this.options;

    if (!this.cols || this.prevProps.measuredRows  != measuredRows || this.prevProps.maxLines != maxLines || colOptions != this.cols[0].options) {
      this.cols = [{
        name: 'value',      
        width: -1,
        disallowNone: true,
        maxLines,
        component: OptionText,
        options: colOptions,
        ...textRendererProps
      }]
    }

    this.prevProps = this.props;

    return <DataTable ref={this.ref} editable={false} colResize={false} colMove={false} rowHeaders={false} colHeaders={false} border='solid 1px' borderColor='border' borderRadius='standard'
      measuredRows={measuredRows} height={height || '200px'} onSelectionChange={this.updateSelectedFromInteraction} selection={selected} selectionRenderer={<SelectionRenderer list={this} />} enterMovesSelection={false}
      cellRenderer={cellRenderer} overflowY={overflowY || 'auto'} overflowX='hidden' bg='white' data={this.options} multipleSelection={this.props.multiple} cols={this.cols} {...remaining} scrollShadows />
  }

  updateOptions() {
    this.options = this.mapOptions(this.props.options);
  }

  mapOptions(options:(string | Option)[]) {
    return options.map((option:Option | string) => typeof option === 'string' ? {label: option, value: option} : option);
  }

  updateSelectedFromProps(updatedOptions:boolean, updatedSelection:boolean) {
    let selected:OptionValue[] = [];
    
    if (this.props.value !== undefined) {
      selected = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
    }
    else 
    if (this.state?.selected !== undefined) {
      selected = this.state.selected.slice();
    }

    // if we got new options, then set focus to the first selected
    // else, set the selected to the last selected so that focus moves from there
    const focusIndex = this.findFocusIndex(updatedOptions ? selected[0] : selected[selected.length - 1]);
    const focus = this.options[focusIndex]?.value;

    this.dispatchFocusChangeEvent(focusIndex);

    const selectedIndexes = this.valuesToIndexes(this.options, selected);

    return {selected, selectedIndexes, focus, focusIndex};
  }

  updateSelectedFromInteraction = (table:DataTable, selection:MultipleSelection, event:SelectionEventSource) => {
    const option = this.options[selection.selectedRows[0]];

    if (option.disabled) {
      return;
    }

    const focus = option.value;
    const focusIndex = this.findFocusIndex(focus);

    this.dispatchFocusChangeEvent(focusIndex);

    if (this.props.makeSelectionVisible) {
      this.setState({focus, focusIndex}, () => this.makeFocusVisible());
    }

    if (!this.isSelectionEvent(event)) {
      return;
    }

    event.stopPropagation();

    let selected:OptionValue[] = [focus];

    if (this.props.multiple) {
      selected = xor(this.state.selected.slice(), selected);

      const isEnter = event.type == 'keydown' && (event as KeyboardEvent).key == 'Enter';

      if (!this.props.enterToggleSelects && isEnter && this.state.selected.length > selected.length) {
        selected = this.state.selected.slice();
      }
    }

    if (compareOptionValueArrays(selected, this.state.selected)) {
      if (this.props.onNoChange) {
        this.props.onNoChange();
      }
      
      return;
    }

    this.setState({selected, selectedIndexes: this.valuesToIndexes(this.options, selected)});
    this.dispatchChangeEvent(selected);
  }

  isSelectionEvent(event:SelectionEventSource) {
    if (!event) {
      return false;
    }

    if (event.type == 'mouseup' || event.type == 'pointerup') {
      return true;
    }

    return event.type == 'keydown' && (event as KeyboardEvent).key == 'Enter';
  }

  findFocusIndex(focus:OptionValue) {
    let index = this.options.findIndex(option => compareOptionValues(option.value, focus));
    return index == - 1 ? 0 : index;
  }

  valuesToIndexes(options:Option[], values:OptionValue[]) {
    // todo - remove this when moving to ObservableCollection and rely on getId/getIndex
    // avoid a O(n^2) issue by mapping things to a set first
    const optionsMap = new Map(options.map((option, index) => [getOptionStringValue(option.value), index]));

    return values.map(value => optionsMap.get(getOptionStringValue(value))).filter(index => index !== undefined);
  }

  dispatchChangeEvent(selected:OptionValue[]) {
    if (!this.props.onChange) {
      return;
    }

    const value = this.props.multiple ? selected : selected[0];
    dispatchChangeEvent(this, value, this.props.onChange);
  }

  dispatchFocusChangeEvent(focusIndex:number) {
    const focus = this.options[focusIndex]?.value;

    if (compareOptionValues(this.state.focus, focus)) {
      return;
    }

    this.props.onFocusChange?.(this.options[focusIndex]);
  }
}
