import { UseQueryResponse } from 'urql'

import { organizerPreferences } from 'app2/api';
import { UseQueryArgs } from 'app2/views/shared/urql';

import { useSiteParameter } from './useSiteParameter';

type Result<T> = {site?:T};
type CompanyFrameworksArgs<T> = Omit<UseQueryArgs<{site:string}, Result<T>>, 'query'>;
type Query<T> = (args:CompanyFrameworksArgs<T>) => UseQueryResponse<Result<T>>;

export function useSiteQuery<T>(query:Query<T>) {
  const siteId = useSiteParameter();
  const [result] = query({variables:{site:siteId}});
  const site = result.data?.site;
  const fetching = result.fetching;
  const prefs = siteId ? organizerPreferences.sitePreferences(siteId) : null;

  return {site, prefs, id:siteId, fetching};
}
