import * as React from 'react';
import { Check } from 'react-feather';

import { theme } from './theme';
import { ComponentProps, componentStyles, createComponent, renderComponent } from './utils';
import { Text, TextProps } from './Text';
import { Layout, VBox } from './Box';
import { Icon } from './icons';

// a basic checkbox but in html a checkbox
// and label are separate, so this component
// is really three things:
//  - a container
//  - a checkbox
//  - a label
//
// you can pass styling for the container and the label
// custom checkbox styling is not supported
//
// since this is really three components not all the
// attributes available on the checkbox are exposed
//
// these are the only attributes available on the
// checkbox.  if you want more, add them here and
// then down below extract them off props and pass
// them to the checkbox.

// the entire set of properties available for this
// component are defined as CheckboxProps

// if there are children to the checkbox, they are rendered
// aligned with the checkbox label, and layed out vertically

interface CheckboxInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  name?: string;
  error?: boolean;
  readOnly?: boolean;
  layout?: Layout;
  infoTip?: React.ReactNode;
}

// these are non-standard and non-style attributes we add to
// CheckboxProps and do not want passed on to html
interface CheckboxCustomProps {
  label?: React.ReactNode;
  labelProps?: TextProps;
}

// these are the properties that are applied to the container
type CheckboxContainerProps = ComponentProps<Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'>>;

// the total properties available on the component
export type CheckboxProps = CheckboxContainerProps & CheckboxCustomProps & CheckboxInputProps;

const check = `url("data:image/svg+xml;utf8,${encodeURIComponent(
  renderComponent(<Check color="#fff" size={18} strokeWidth={3} />)
)}")`;

const propsToExclude: string[] = ['error'];

export const CheckboxStyles = createComponent('label', propsToExclude)<CheckboxProps>`
  display: inline-flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  input {
    appearance: none;
    outline: 0;
    cursor: pointer;
    position: relative;

    height: ${theme.iconSize.small + 2}px;
    min-height: ${theme.iconSize.small + 2}px;
    width: ${theme.iconSize.small + 2}px;
    min-width: ${theme.iconSize.small + 2}px;
    margin: 2px 8px 2px 2px;

    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.primary};
    background: ${theme.colors.white};

    :hover:not(:disabled) {
      border-color: ${props => props.error ? theme.colors.errorHover : theme.colors.primaryHover};
    }

    :focus-visible:not(:disabled),
    :focus:not(:disabled) {
      outline: solid 2px ${props => props.error ? theme.colors.errorFocused : theme.colors.primaryFocused};
      outline-offset: 0px;
    }

    :disabled {
      border-color: ${theme.colors.disabled};
      cursor: default;
    }

    :disabled:checked {
      border-color: rgb(0, 0, 0, 0);
      background: ${theme.colors.disabled};
      cursor: default;
    }

    :checked::before {
      content: '';
      background-image: ${check};
      background-repeat: no-repeat;
      background-size: ${theme.iconSize.small}px ${theme.iconSize.small}px;
      position: absolute;
      height: ${theme.iconSize.small}px;
      width: ${theme.iconSize.small}px;
    }

    :checked:not(:disabled) {
      border-color: rgb(0, 0, 0, 0);
      background: ${props => props.error ? theme.colors.error : theme.colors.primary};
    }

    :checked:hover:not(:disabled) {
      background: ${props => props.error ? theme.colors.errorHover : theme.colors.primaryHover};
    }

    transition: border .075s ease, background .075s ease;
  }

  ${componentStyles}    
` as React.ComponentType<CheckboxProps>;

export function Checkbox(props: CheckboxProps) {
  if (props.hidden) {
    return <></>;
  }
  return props.children === undefined ? renderControl(props) : renderWithChildren(props)
}

function renderWithChildren(props: CheckboxProps) {
  return <VBox>
    {renderControl(props)}
    <VBox pl='24px' pt='$8' vItemSpace='$8' layout={props.layout}>{props.children}</VBox>
  </VBox>
}

function renderControl(props: CheckboxProps) {
  const { label, labelProps, onChange, checked, disabled, name, children, error, readOnly, layout, infoTip, tooltip, ...remainingProps } = props;
  const checkValue = ('checked' in props && checked !== undefined) || onChange != undefined ? Boolean(checked) : undefined;

  // the onclick on the label is to block 2 onclick events from getting generated
  return <CheckboxStyles disabled={disabled} error={error} tooltip={infoTip || tooltip} {...remainingProps}>
    <input type="checkbox" onChange={onChange || noOp} {...{ checked:checkValue, disabled, name, readOnly }} />
    <Text userSelect="none" display='inline-flex' alignItems='center' disabled={disabled} error={error} text='formlabel' {...labelProps} 
      onClick={(e) => {e.stopPropagation()}} >{label}{infoTip && <Icon infoTip />}</Text>
  </CheckboxStyles>
}

// used to avoid a react warning when setting the check value but there's no change handler

function noOp() {
}

Checkbox.fieldProps = {
  valueProperty: 'checked',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur',
  labelProperty: 'label',
  disallowNone: true
}

Checkbox.displayName = 'Checkbox';
