import * as React from 'react'

import { EnrollmentUtils, Enrollment, RosterGroupingKind } from 'app2/api';
import { DropdownField, Modal, RepeatingSection, Field, Form, Section, useForm } from 'app2/components';

import { useGroupOptions } from './useGroupOptions';
import { setGroups } from './generated';

interface Props {
  enrollments:Partial<Enrollment>[];
  season:string;
}

export function GroupActionModal(props:Props) {
  const options = useGroupOptions({groupingId:props.season, groupingKind:RosterGroupingKind.Season});
  const form = useForm({enrollments:props.enrollments.map(e => ({ ...e, student: EnrollmentUtils.getStudentName(e as Enrollment) })), groups: [] as string[]}, []);

  function render() {
    return <Modal title='Group enrollments'>
      <Form form={form} onOk={onOk}>
        <Section name='groups' label='Group' component={DropdownField} additions placeholder='Select an existing group or enter a new one' options={options} />
        {renderEnrollments()}
      </Form>
    </Modal>
  }

  function renderEnrollments() {
    return <RepeatingSection name="enrollments" bordered fields={[
      <Field label="Students" name="student" />,
    ]}/>
  }

  async function onOk() {
    const [success] = await setGroups({variables:{ids:props.enrollments.map(e => e.id), groups:form.values.groups || []}});

    return success;
  }

  return render()
}
