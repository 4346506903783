import * as React from 'react';

import { NumberStepper } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function NumberStepperDemo() {
  const [count, setCount] = React.useState(0);

  return <Demos name='NumberStepper'>
    <Demo name='Basic'>
      <NumberStepper width='200px' />
    </Demo>
    <Demo name='Controlled'>
      <NumberStepper width='200px' value={count} onDecrement={() => setCount(count - 1)} onIncrement={() => setCount(count + 1)} />
      value: {count}
    </Demo>
  </Demos>
}
