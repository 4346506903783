import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RecalcPendingRecurringEnrollmentsMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type RecalcPendingRecurringEnrollmentsMutation = { enrollmentsRecalcPendingRecurring?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const RecalcPendingRecurringEnrollmentsDocument = gql`
  mutation RecalcPendingRecurringEnrollments($courseId: ID!, $ids: [ID!]!) {
    enrollmentsRecalcPendingRecurring(courseId: $courseId, ids: $ids) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RecalcPendingRecurringEnrollmentsOptions = ExecMutationOptions<RecalcPendingRecurringEnrollmentsMutationVariables>;

export function recalcPendingRecurringEnrollments(options: RecalcPendingRecurringEnrollmentsOptions) {
  return executeMutation<RecalcPendingRecurringEnrollmentsMutation, RecalcPendingRecurringEnrollmentsMutationVariables>(RecalcPendingRecurringEnrollmentsDocument, options);
}
