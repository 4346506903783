import * as React from 'react';
import { useHistory, useParams, generatePath } from 'react-router';

import { IconNames, Icon } from './icons';
import { ButtonKind, Button } from './Button';
import { A, Span } from './Tags';
import { TextType, TextCustomProps } from './Text';
import { useClickLoader } from './shield';
import { ComponentProps } from './utils';
import { formatPhone } from './form';

// a wrapper around <Link /> and <a /> so
// you don't have to do conditional checking
// when using both based on some condition
//
//  - inhertis Text's text formatting
//  - by default has a hover state for text and child icons
//  - hover state can be turned off by specifying hover=f{false} or you can use A and react-router Link's directly
//  - text style and hover can be changed independently
//  - if no text style is specified, all text styling is inherited (except if hover=true)

type OmitProps = 'draggable' | 'href' | 'defaultValue' | 'contentEditable' | 'spellCheck' | 'aria-relevant';
export interface LinkProps extends ComponentProps<Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, OmitProps>>, TextCustomProps {
  to?: string;
  hover?: boolean;
  selected?: boolean;
  button?: ButtonKind;
  autoLoader?:boolean;//only for internal routes
  icon?: IconNames;
  iconSize?: number | string;
  iconPosition?: 'right' | 'left';
  alt?:string;
  blurOnClick?:boolean;
  external?:boolean; // force relative links to be treated as external
  // given an email address this will create an email mailto link
  email?:string;
  // given an phone this will create an email tel link
  phone?:string;
  // any history state you want to push when the link is clicked
  state?:any;
  // forces inline layout so the link wraps like text
  inline?:boolean;
  // indicates history should replace instead of push
  replace?:boolean;
}

export function Link(props: React.PropsWithChildren<LinkProps>) {
  let { to, text, hover, button, selected, autoLoader, onClick, icon, iconPosition, iconSize, alt, blurOnClick, external, email, phone, state, inline, underline, children, replace, ...remainingProps } = props;

  if (email && !to) {
    to = 'mailto:' + email;

    if (!children) {
      children = email;
    }
  }

  if (phone && !to) {
    to = 'tel:' + phone;

    if (!children) {
      children = formatPhone(phone);
    }
  }

  const {loading, clickLoaderHandler, loader} = useClickLoader(handleClick, false, false, blurOnClick);
  const hasTo = to && to.length;
  const externalLink = external || /^(data|file|ftp|https?|javascript|mailto|tel|urn|wss|webcal?):/i.test(to);
  const params = useParams();
  const history = useHistory();
  const link = hover && !button;
  // inline-flex doesn't deal well with ellipse truncating
  // however inline-block doesn't deal well with a loader
  // try to guess based on if we have a loader and if we
  // have max lines, then dont define it, let the text max lines do that
  const display = props.display ? props.display : props.inline ? 'inline' : (loading && autoLoader) ? 'inline-flex' : (props.maxLines ? undefined : 'inline-block')

  if (hasTo && !externalLink) {
    // react router will throw an error if the path contains a regex
    // and the parameter values don't match the regex
    try {
      to = generatePath(to, params);
    }
    catch(e) {
    }
  }

  if (button) {
    children = <Button kind={button} icon={icon} iconPosition={iconPosition} selected={selected} small={props.small} width='100%'>{children}</Button>;
  }

  function render() {
    const common = {text, link, onClick: autoLoader ? clickLoaderHandler : handleClick, children, 
      display, alignItems: 'center', underline: button ? false : underline, ...remainingProps};

    if (props.as) {
      common.role = 'link';
    }

    if (!link) {
      common.cursor = 'pointer';
    }

    const hasChildren = common.children != null;

    if (icon && !button) {
      if (iconPosition === 'left' || !iconPosition) {
        common.children = <><Icon name={icon} mr={hasChildren ? '$8' : undefined} alt={alt} color='currentcolor' size={iconSize} />{common.children}</>;
      }
      else {
        common.children = <>{common.children}<Icon name={icon} ml={hasChildren ? '$8' : undefined} alt={alt} color='currentcolor' size={iconSize} /></>;
      }
    }

    if (loading && autoLoader) {
      common.children = renderLoader(common.children)
    }
    else
    if (common.children && typeof common.children !== 'string' && !props.display) {
      // if we have an icon we need to use flex to align things properly
      common.display = 'inline-flex';
      common.alignItems = 'center';
    }

    const testId = typeof children === 'string' ? `Link(${children})` : undefined;
    return !hasTo 
      ? <Span data-test={testId} {...common} />
      : <A data-test={testId} {...common} href={to} />
  }

  function renderLoader(children:any) {
    return <Span position='relative' display='inline-flex' alignItems='center' justifyContent='center' >
      <Span opacity={0}>{children}</Span>{loader}
    </Span>;
  }

  function handleClick(event:React.MouseEvent<HTMLAnchorElement>) {
    const result = typeof onClick == 'function' ? onClick(event) : onClick;

    const frame = window !== window.parent;
    const hasTarget = (frame && props.target == '_top') || (props.target && props.target != '_top')

    if (!to || externalLink || event.isPropagationStopped() || event.isDefaultPrevented() || hasTarget) {
      // stop propogation because we want to let the browser process the link 
      // and not a click listener
      if (externalLink || hasTarget) {
        event.stopPropagation();
      }
      return result;
    }

    event.preventDefault();

    if (replace) {
      history.replace(to, state);
    }
    else {
      history.push(to, state);
    }

    return result;
  }

  return render();
}

Link.defaultProps = {
  hover: true,
  text: 'body' as TextType,
  autoLoader: true,
  underline: true
}

Link.fieldProps = {
  valueProperty: 'children'
}
