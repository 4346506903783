import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminProcessPayoutMutationVariables = Types.Exact<{
  payout: Types.Scalars['ID'];
}>;

export type AdminProcessPayoutMutation = { payoutProcess?: { id: string } | null | undefined };

export const AdminProcessPayoutDocument = gql`
  mutation AdminProcessPayout($payout: ID!) {
    payoutProcess(payout: $payout) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminProcessPayoutOptions = ExecMutationOptions<AdminProcessPayoutMutationVariables>;

export function adminProcessPayout(options: AdminProcessPayoutOptions) {
  return executeMutation<AdminProcessPayoutMutation, AdminProcessPayoutMutationVariables>(AdminProcessPayoutDocument, options);
}
