import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { TeamParticipantsFragmentFragmentDoc } from '../../team/generated/teamParticipantsFragment.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentTeamByTokenQueryVariables = Types.Exact<{
  course: Types.Scalars['String'];
  student: Types.Scalars['ID'];
}>;

export type StudentTeamByTokenQuery = {
  studentByToken?:
    | {
        family?:
          | {
              id: string;
              teamParticipantsUpsert?: boolean | null | undefined;
              teamChangeRole?: boolean | null | undefined;
              teamSetAsContact?: boolean | null | undefined;
              userMemberRole?: Types.MemberRole | null | undefined;
              participants?:
                | Array<
                    | {
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                    | {
                        isContact?: boolean | null | undefined;
                        isCurrentUser?: boolean | null | undefined;
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                    | {
                        sentAt: string;
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentTeamByTokenDocument = gql`
  query StudentTeamByToken($course: String!, $student: ID!) {
    studentByToken(token: $course, id: $student) {
      family {
        id
        ...teamParticipantsFragment
      }
    }
  }
  ${TeamParticipantsFragmentFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentTeamByTokenArgs = MakeOptional<UseQueryArgs<StudentTeamByTokenQueryVariables, StudentTeamByTokenQuery>, 'query'>;

export function useStudentTeamByTokenQuery(options: StudentTeamByTokenArgs = {}) {
  return useQuery<StudentTeamByTokenQueryVariables, StudentTeamByTokenQuery>({ query: StudentTeamByTokenDocument, ...options });
}

export type StudentTeamByTokenOptions = ExecQueryOptions<StudentTeamByTokenQueryVariables>;

export function studentTeamByToken(options: StudentTeamByTokenOptions) {
  return executeQuery<StudentTeamByTokenQuery, StudentTeamByTokenQueryVariables>(StudentTeamByTokenDocument, options);
}

export type StudentTeamByTokenSelections = StudentTeamByTokenQuery['studentByToken'];
