import * as React from 'react';

import { Site } from 'app2/api'
import { Link, LinkProps, FieldInfo } from 'app2/components'

type SiteWithId = Pick<Site, 'id' | 'name'>;
type SiteWithSlug = Pick<Site, 'slug' | 'name'>;

interface Props extends LinkProps {
  info?:FieldInfo<Site>;
  site?: SiteWithId | SiteWithSlug;
}

export function PublicSiteLink(props:Props) {
  const {info, site:siteProp, ...remaining} = props;
  const site = siteProp || info.record;
  const id = (site as SiteWithSlug)?.slug || (site as SiteWithId)?.id;

  return <Link className='hr-value' text='body' to={`/sites/${id}`} {...remaining}>{site?.name}</Link>
}

PublicSiteLink.fieldProps = {
  fieldProperty: 'info',
}
