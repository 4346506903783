import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyMemberRemoveMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  user: Types.Scalars['ID'];
}>;

export type AdminCompanyMemberRemoveMutation = { companyAdminMemberRemove?: { id: string } | { id: string } | null | undefined };

export const AdminCompanyMemberRemoveDocument = gql`
  mutation AdminCompanyMemberRemove($company: ID!, $user: ID!) @urql(additionalTypenames: ["Company"]) {
    companyAdminMemberRemove(company: $company, user: $user) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminCompanyMemberRemoveOptions = ExecMutationOptions<AdminCompanyMemberRemoveMutationVariables>;

export function adminCompanyMemberRemove(options: AdminCompanyMemberRemoveOptions) {
  return executeMutation<AdminCompanyMemberRemoveMutation, AdminCompanyMemberRemoveMutationVariables>(AdminCompanyMemberRemoveDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Company']) }
  });
}
