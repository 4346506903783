import * as React from 'react'
import * as ReactIs from 'react-is'

import { Button, ButtonProps } from '../../Button'
import  { handleOnClickOrModal, MultiContext } from '../../utils';
import { NotificationManager } from '../../notification';

import { DataTable } from '..';

import { DataTableHeaderContext } from './DataTableHeader';

export type ActionHandler = ((table:DataTable) => boolean | Promise<boolean> | void | Promise<any> | React.ReactElement) | React.ReactElement;

interface Props extends Omit<ButtonProps, 'onClick'> {
  selection?:boolean;
  focus?:boolean;
  onClick?:ActionHandler;
}

export function ActionButton(props:Props) {
  let {selection, focus, onClick:handler, ...remaining} = props;
  const header = React.useContext<DataTableHeaderContext>(MultiContext).header;

  async function onClick(event:React.MouseEvent<HTMLElement>) {
    if (!handler) {
      return;
    }

    if (selection && !header.table.selections.selectedItems.length) {
      NotificationManager.add({ type: 'warning', message: 'Please select one or more rows' });
      header.table?.focus?.();
      return;
    }

    if (!ReactIs.isElement(handler)) {
      handler = handler.bind(handler, header.table as any);
    }

    await handleOnClickOrModal(handler as any, event);

    if (focus) {
      header.table?.focus?.();
    }

    header.table?.clearSelection?.();
  }

  return <Button onClick={onClick} {...remaining} />;
}

ActionButton.defaultProps = {
  iconPosition: 'left',
  kind: 'tertiary',
  small: true,
  focusable: false,
  selection: true,
  focus: true
};