import { axios, Provider } from 'app2/api';
import { observable } from 'app/observable';

import { CatalogResults } from './CatalogResults';

@observable
export class ProviderStore {
  slug: string;
  catalogPageSize: number;
  catalogPageNumber: number;
  provider: Provider;
  catalogs: CatalogResults;

  load(slug: string) {
    const promises = [];

    if (this.slug != slug) {
      this.slug = slug;
      this.provider = null;
      this.catalogs = null;
      this.catalogPageSize = null;
      this.catalogPageNumber = null;
      promises.push(this.loadProvider());
    }

    return promises;
  }

  loadCatalogPage(pageSize: number, pageNumber: number = 0) {
    if (
      this.catalogPageSize === pageSize &&
      this.catalogPageNumber === pageNumber
    ) {
      return;
    }

    this.catalogPageSize = pageSize;
    this.catalogPageNumber = pageNumber;

    return this.loadCatalogs();
  }

  async loadProvider() {
    try {
      const response = await axios.get<Provider>(
        `/companies/${this.slug}/seo_page`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(`/provider/${response.data.redirect_to_slug}/about`);
      }

      this.provider = response.data;
    } catch (_) {
      window.location.replace('/not-found');
    }
  }

  async loadCatalogs() {
    try {
      const response = await axios.get<CatalogResults>(
        `/companies/${this.slug}/catalog?items_per_page=${
          this.catalogPageSize
        }&page=${this.catalogPageNumber + 1}`
      );
      this.catalogs = response.data;
    } catch (_) {}
  }
}
