import * as React from 'react';
import { debounce, isEqual } from 'lodash-es';

import { DebounceSettings, Cancelable } from 'app2/api';

export function useDebouncedCallback<T extends (...args: any[]) => any>(callback: T, delay: number = 0, options?: DebounceSettings): T & Cancelable {
  return React.useCallback(debounce(callback, delay, options), [callback, delay, options]);
}

export function useDebounce<T>(value: T, delay: number = 0, options?: DebounceSettings): T {
  const previousValue = React.useRef(value);
  const [current, setCurrent] = React.useState(value);
  const debouncedCallback = useDebouncedCallback((value: T) => setCurrent(value), delay, options);

  React.useEffect(() => {
    if (!isEqual(value, previousValue.current)) {
      debouncedCallback(value);
      previousValue.current = value;
      return debouncedCallback.cancel;
    }
  }, [JSON.stringify(value)]);

  if (delay <= 0) {
    return value;
  }

  return current;
}
