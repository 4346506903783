import { last, debounce } from 'lodash-es';

import { assert } from './util/assert';

import { Observable } from './Observable';
import { Observer } from './Observer';
import { ObserverAdapter } from './ObserverAdapter';
import { EventEmitter } from 'events';

interface DependencyInfo {
  observer: Observer;
  dependencies: Set<Observable>;

  // due to inheritance, its possible for dependency
  // tracking to get called more than once for the
  // same component.  to avoid extra work, we
  // capture how many times tracking has been
  // invoked on the same component;
  count: number;
}

export class ObservableManager extends EventEmitter {
  static ON_REMOVED = 'onremoved';

  capturingDependencies: boolean;
  capturing: DependencyInfo[];

  dependencies: Map<Observable, Set<Observer>>;
  dependants: Map<Observer, Set<Observable>>;

  updateQueue: Set<Observer>;
  updateRemovedQueue: Set<Observer>;
  updateQueueBeingProcessed: Set<Observer>;

  observerAdapter: ObserverAdapter;

  constructor() {
    super();

    this.capturingDependencies = false;
    this.capturing = [];

    this.dependencies = new Map();
    this.dependants = new Map();

    this.updateQueue = new Set();
    this.updateRemovedQueue = new Set();
    this.updateQueueBeingProcessed = null;
  }

  hasDependencies(observable: Observable) {
    return this.getDependencyCount(observable) != 0;
  }

  getDependencyCount(observable: Observable) {
    const dependencies = this.dependencies.get(observable);

    return dependencies ? dependencies.size : 0;
  }

  startCapturingDependencies(observer: Observer) {
    if (this.capturing.length == 0) {
      this.capturingDependencies = true;
    } else {
      const lastCapture = last(this.capturing);

      if (lastCapture.observer === observer) {
        ++lastCapture.count;
        return;
      }
    }

    this.capturing.push({ observer, dependencies: new Set(), count: 1 });
  }

  stopCapturingDependencies(observer: Observer) {
    while (this.capturing.length && last(this.capturing).observer != observer) {
      this.subscribe(this.capturing.pop());
    }

    if (this.capturing.length != 0) {
      const lastCapture = last(this.capturing);
      lastCapture.count -= 1;

      if (lastCapture.count) {
        return;
      }

      this.subscribe(this.capturing.pop());
    }

    if (this.capturing.length == 0) {
      this.capturingDependencies = false;

      const updateRemovedQueue = this.updateRemovedQueue;
      this.updateRemovedQueue = new Set();

      updateRemovedQueue.forEach(observable => {
        this.dependencies.delete(observable);
        this.emit(ObservableManager.ON_REMOVED, observable);
      });
    }
  }

  // for debugging
  getCapturingDependencies(observer: Observer) {
    assert(this.capturingDependencies);

    const dependencyInfo = last(this.capturing);

    assert(dependencyInfo.observer == observer);

    return dependencyInfo.dependencies;
  }

  onObserved(target: Observable, propName?: PropertyKey) {
    if (!this.capturingDependencies) {
      return;
    }

    // for now, just object level dependencies
    const dependencyInfo = last(this.capturing);
    dependencyInfo.dependencies.add(target);
  }

  private subscribe(dependencyInfo: DependencyInfo) {
    this.unsubscribeAllInternal(dependencyInfo.observer, true);

    if (!dependencyInfo.dependencies.size) {
      return;
    }

    this.dependants.set(dependencyInfo.observer, dependencyInfo.dependencies);

    dependencyInfo.dependencies.forEach(observable => {
      let dependenciesForObservable = this.dependencies.get(observable);

      if (!dependenciesForObservable) {
        dependenciesForObservable = new Set();
        this.dependencies.set(observable, dependenciesForObservable);
      }

      dependenciesForObservable.add(dependencyInfo.observer);
      this.updateRemovedQueue.delete(observable);
    });
  }

  unsubscribeAll(observer: Observer) {
    this.unsubscribeAllInternal(observer, false);
  }

  private unsubscribeAllInternal(
    observer: Observer,
    recalculatingSubscriptions: boolean
  ) {
    const observables = this.dependants.get(observer);

    if (observables) {
      this.dependants.delete(observer);

      observables.forEach(observable => {
        const observers = this.dependencies.get(observable);
        observers.delete(observer);

        if (!observers.size) {
          if (this.capturingDependencies) {
            this.updateRemovedQueue.add(observable);
          } else {
            this.dependencies.delete(observable);
            this.emit(ObservableManager.ON_REMOVED, observable);
          }
        }
      });
    }

    // this is to handle when an observer is removed
    // before while an update is being broadcasted
    if (!recalculatingSubscriptions) {
      this.updateQueue.delete(observer);
    }
  }

  onChanged(observable: Observable, propName?: PropertyKey, propValue?: any) {
    this.onObserved(observable, propName);

    const dependencies = this.dependencies.get(observable);

    if (!dependencies) {
      return;
    }

    this.updateQueue = new Set([...this.updateQueue, ...dependencies]);
    this.updateDependencies();
  }

  private updateDependencies = debounce(() => {
    assert(!this.updateQueueBeingProcessed);

    this.updateQueueBeingProcessed = this.updateQueue;
    this.updateQueue = new Set();

    this.observerAdapter.onUpdate(this.updateQueueBeingProcessed);

    this.updateQueueBeingProcessed = null;
  }, 10);
}

export const observableManager = new ObservableManager();
