import * as React from 'react';

import { CopyAction } from './CopyAction';
import { PasteAction } from './PasteAction';
import { FindAction } from './FindAction';

export function StandardTableActions() {
  return <>
    <CopyAction />
    <PasteAction />
    <FindAction />
  </>
}
