import * as React from 'react';

import { Button, Modal, PopupManager, Icon, PopupManagerProps } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ModalDemos() {
  return <Demos name='Modal'>
    <Demo name='Basic'>
      <Button onClick={() => PopupManager.addModal(<Modal title='test' onOk={onOk}>modal content</Modal>)}>Show modal</Button>
    </Demo>
    <Demo name='Awaited'>
      <Button onClick={async () => {
        const result = await PopupManager.addModal(<Modal title='test'>modal content</Modal>);
        alert('You chose button #' + result.action);
      }}>Show modal</Button>
    </Demo>
    <Demo name='Not cancelable'>
      <Button onClick={() => PopupManager.addModal(<Modal title='test' onOk={onOk}>modal content</Modal>, {cancelable: false})}>Show modal</Button>
    </Demo>
    <Demo name='Modal on top of modal'>
      <Button onClick={showModal}>Show modal</Button>
    </Demo>
    <Demo name='Custom button'>
      <Button onClick={() => PopupManager.addModal(<Modal title='test' ok={<Button icon='AlertOctagon' onClick={onOk}>Custom button</Button>}/>)}>Show modal</Button>
    </Demo>
    <Demo name='Custom buttons'>
      <Button onClick={() => PopupManager.addModal(<Modal title='test' buttons={[<Icon name='XCircle' button />, <Icon name='CheckCircle' button onClick={onOk} />]} />)}>Show modal</Button>
    </Demo>
    <Demo name='External close 1'>
      <Button onClick={() => {{
        const modal = PopupManager.addModal(<Modal title='test'><Button onClick={() => modal.remove()}>Click me to close</Button></Modal>)
      }}}>Show modal</Button>
    </Demo>
    <Demo name='External close 2'>
      <Button onClick={() => PopupManager.addModal(<MyModal />)}>Show modal</Button>
    </Demo>
    <Demo name='Error'>
      <Button onClick={() => Modal.error('Title', 'This is an error message')}>Show error</Button>
    </Demo>
    <Demo name='Error, multiple strings'>
      <Button onClick={() => Modal.error('Title', ['This is an error message1', 'This is an error message2'])}>Show error</Button>
    </Demo>
    <Demo name='Error, multiple strings, using named parameters'>
      <Button onClick={() => Modal.error({title:'Title named parameters', content:['This is an error message1', 'This is an error message2']})}>Show error</Button>
    </Demo>
    <Demo name='Warning'>
      <Button onClick={() => Modal.warning('Title', 'This is an warning message')}>Show warning</Button>
    </Demo>
    <Demo name='Warning, multiple strings'>
      <Button onClick={() => Modal.warning('Title', ['This is an warning message1', 'This is an warning message2'])}>Show warning</Button>
    </Demo>
    <Demo name='Warning, multiple strings, using named parameters'>
      <Button onClick={() => Modal.warning({title:'Title from named parameters', content:['This is an warning message1', 'This is an warning message2']})}>Show warning</Button>
    </Demo>
  </Demos>
}

function showModal() {
  PopupManager.addModal(<Modal title='test' onOk={onOk}>
    <Button onClick={showModal}>show another modal</Button>
  </Modal>);
}

async function onOk() {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  throw new Error('test');
}

function MyModal(props:PopupManagerProps) {
  return <Modal title='test'><Button onClick={() => props.manager.remove()}>Click me to close</Button></Modal>
}