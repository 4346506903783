import * as React from 'react'

import { VendorInput } from 'app2/api';
import { EmailField, Field, Form, FormContent, FormModel, InputField, Modal, PhoneField, RepeatingSection, Section } from 'app2/components';

import { createProvider } from './gql'

interface Props {
  site:string;
}

export function AddProviderModal(props:Props) {
  function render() {
    return <Modal title='Add provider' ok='Add provider'>
      <Form editing onOk={onOk} initialValues={initialValues} onNavigation='nothing'>
        <FormContent icon='User' title='Provider details'>
          <Section label='Nickname' name='companyName' description="This is what you call the provider. It may differ from the name they give themselves." required component={InputField} />
        </FormContent>
        <FormContent icon="Phone" title="Contacts">
          <RepeatingSection name='contacts' add='Add contact' requireOne fields={[
            <Field label='Name' name='name' required component={InputField} />,
            <Field name='email' required component={EmailField} />,
            <Field name='phone' component={PhoneField} />,
            'remove'
          ]} />
        </FormContent>
      </Form>
    </Modal>;
  }

  async function onOk(form:FormModel<VendorInput>) {
    const [success] = await createProvider({variables:{siteId:props.site, attributes:form.values as VendorInput}, error: form});

    return success;
  }

  return render();
}

const initialValues = {
  contacts:[{}]
} as VendorInput;
