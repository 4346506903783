import * as React from 'react';

import { PrivatePage, PrivatePageProps } from 'app2/views/shared';

import { CompanyDropdown } from './CompanyDropdown';

interface Props extends PrivatePageProps {
}

export function CompanyPage(props:Props) {
  return <PrivatePage actions={<CompanyDropdown />} {...props} />;
}
