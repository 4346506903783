import * as React from 'react';

import { companyFolder } from 'app/helpers';
import { DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO, COURSE_IMAGE_WIDTH, COURSE_IMAGE_RATIO } from 'app2/views/shared-public';
import { CloudinaryPhotoInput, CloudinaryPhotoField, Panel, Section } from 'app2/components';
import { courseImageSearch } from 'app2/views';

import defaultCourseImg from 'images/default_course_img.png';

import { Demos, Demo } from './Demo';

export function CloudinaryDemos() {
  const companyId = 100;
  const siteId = 100;
  const options = {
    uploadPreset: 'course_card_image',
    folder: companyFolder(companyId),
    signatureUrl: `/courses/course_card_image_signature/${companyId}`,
    customSignatureParams: { site_id: siteId },
  };

  const courseImageOptions = {
    uploadPreset: 'course_image',
    folder: companyFolder(companyId),
    signatureUrl: `/courses/course_image_signature/${companyId}`,
    customSignatureParams: { site_id: siteId },
    cropping: true,
    croppingAspectRatio: COURSE_IMAGE_RATIO
  };

  return <Demos name='Cloudinary'>
    <Demo name='Basic'>
      <CloudinaryPhotoInput defaultImage={DEFAULT_COURSE_CARD_IMG} ratio={COURSE_CARD_IMAGE_RATIO} width={132} options={options} onChange={event => alert('url:' + event.target.value)} />
    </Demo>

    <Demo name='Panel/form'>
      <Panel title='Edit me' type='toggle' onOk={form => alert('form:' + form.values.courseCardImage)}>
        <Section label='Activity card image' name='courseCardImage' required component={CloudinaryPhotoField} defaultImage={DEFAULT_COURSE_CARD_IMG} ratio={COURSE_CARD_IMAGE_RATIO} width={132} options={options} />
      </Panel>
    </Demo>

    <Demo name='Browse'>
      <CloudinaryPhotoInput defaultImage={defaultCourseImg} ratio={COURSE_IMAGE_RATIO} width={COURSE_IMAGE_WIDTH} options={courseImageOptions} search={courseImageSearch} onChange={event => alert('url:' + event.target.value)} />
    </Demo>
  </Demos>
}

