import * as React from 'react';

import { Payouts as PayoutsBase, QueryApiNoParams } from '../shared';

import { useAdminMissingDepositPayoutsQuery } from './generated'

export function MissingDeposits() {
  function render() {
    return <PayoutsBase query={useAdminMissingDepositPayoutsQuery as QueryApiNoParams} variables={{kind:"admin_missing_deposit"}} to='/admin2/homeroom' 
      showTarget showSites={false} />
  }

  return render();
}
