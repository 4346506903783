import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { resetVersion } from 'helpers'
import { findRoute } from 'app2/components';


export class ScrollToTop extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.any,
    history: PropTypes.object,
    routes: PropTypes.array
  }

  componentWillReceiveProps() {
    resetVersion()
  }

  componentDidUpdate(prevProps) {
    const prevRoute = findRoute(prevProps.location.pathname, prevProps.routes);
    const curRoute = findRoute(this.props.location.pathname, this.props.routes);

    // note this only works to differentiate between top level routes.
    // if the top level route has child routes, they will be seen as the same route.

    if (curRoute && prevRoute && curRoute === prevRoute) {
      return;
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      const root = document.getElementById('root')

      if (root && root.scrollTo) {
        root.scrollTo(0, 0)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
