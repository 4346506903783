import * as React from 'react';
import { isEqual } from 'lodash-es';

import { CourseStatus, CourseFilterOptionKind, CourseSortBy, Vendor } from 'app2/api';
import { DataTableColumn, DropdownField, FieldInfo, FormModel, Link, longDateTimeFormatterTz, useForceUpdate, ObservableCollection, FieldValue, FormatOnly, useObservableCollection, FieldPlaceholder } from 'app2/components';
import { CourseFieldProps, useCourseFields, MIN_COL_WIDTH, CourseRecurringRatesDropdown, CourseRecurringRatesDisplay, formatRecurringRates, nonCancelledStatuses, parseRecurringRates, ConfigurableSeasonRatesDisplay, ConfigurableSeasonRatesDropdown, formatConfigurableSeasonRate, parseConfigurableSeasonRates } from 'app2/views/shared';
import { BetaLabel, dayOptions } from 'app2/views/shared-public';

import { CoursesQueryVars } from '../course/edit';

import { courseFilterOptions, TableCoursesSelections } from './generated';

export type CourseCounts = {
  regular:number;
  orgProvider:number;
  draft:number;
  request:number;
  awaitingApproval:number;
}

export interface UseCourseColsProps extends CourseFieldProps {
  courses:ObservableCollection<TableCoursesSelections>;
  defaultStatusFilter?: CourseStatus[];
  queryVars:CoursesQueryVars;

  // the timezone to put time based values in
  timezone?:string;
}

export function useCourseCols(props:UseCourseColsProps) {
  const { courses, timezone, queryVars } = props;
  const forceUpdate = useForceUpdate();
  const base = useCourseFields(props);
  const fields = base.fields;
  const coursesObserver = useObservableCollection(courses);
  const courseCounts = React.useRef<CourseCounts>({regular:0, orgProvider:0, draft: 0, request: 0, awaitingApproval: 0});
  const longDateTimeFormatter = longDateTimeFormatterTz(timezone);

  return React.useMemo(() => {
    function getAllCols() {
      const all = [{
          ...fields.site,
          width: 250, 
        },
        {
          ...fields.status,
          width: 148,
          filterable: true,
          filterParam: 'byStatus',
          filter: props.defaultStatusFilter || nonCancelledStatuses,
          getFilterOptions: getStatusFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Status
        }, {
          ...fields.kind,
        },
        {
          ...fields.hasParentOptionCourses,
          width: 148,
          filterable: true,
          filterParam: 'byHasParentOptionCourses',
          getFilterOptions: () => getFilterOptions(CourseFilterOptionKind.HasParentOptionCourses),
          sortable: true,
          sortParam: CourseSortBy.HasParentOptionCourses
        },
        { ...fields.vendor, 
          width: 250, 
          filterable: true, 
          filterParam: 'byVendor', 
          getFilterOptions: getVendorFilterOptions, 
          sortable: true, 
          sortParam: CourseSortBy.Vendor, 
          onChange:onChangeProviderInTable
        },
        { ...fields.courseFramework, 
          width: 250, 
          filterable: true, 
          filterParam: 'byCourseFramework', 
          getFilterOptions: 
          getCourseFrameworkFilterOptions, 
          sortable: true, 
          sortParam: CourseSortBy.CourseFramework
        },
        {
          ...fields.name,
          width: 250,
          filterable: true,
          filterParam: 'byName',
          getFilterOptions: getCourseNameFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Name
        },
        { ...fields.courseTags, 
          width: 148, 
          filterable: true, 
          filterParam: 'byCourseTag', 
          getFilterOptions: getCourseTagFilterOptions, 
          sortable: true, 
          sortParam: CourseSortBy.CourseTag
        },
        { ...fields.description, width: 300 },
        { ...fields.supplies, width: 300 },
        { 
          ...fields.teacher, width: 250, 
          filterable: true, 
          filterParam: 'byTeacher', 
          getFilterOptions: getTeacherFilterOptions, 
          sortable: true, 
          sortParam: 
          CourseSortBy.Teacher
        },
        { 
          ...fields['teacher.email'],
          width: MIN_COL_WIDTH, 
        },
        { 
          ...fields['teacher.phone'],
          width: MIN_COL_WIDTH, 
        },
        { ...fields.room, 
          width: 194, 
          filterable: true, 
          filterParam: 'byRoom', 
          getFilterOptions: 
          getRoomFilterOptions, 
          sortable: true, 
          sortParam: CourseSortBy.Room
        },
        { ...fields.grades, 
          width: MIN_COL_WIDTH, 
          filterable: true, 
          filterParam: 'byGradeLabel', 
          getFilterOptions: getGradeFilterOptions
        },
        { ...fields.ageMin, 
          label: 'Minimum age',
          width: MIN_COL_WIDTH, 
        },
        { ...fields.ageMax, 
          label: 'Maximum age',
          width: MIN_COL_WIDTH, 
        },
        { ...fields.courseDays, 
          width: 300, 
          filterable: true, 
          filterParam: 'byDay', 
          getFilterOptions: getCourseDayFilterOptions, 
          sortable: true, 
          sortParam: CourseSortBy.CourseDay
        },
        { ...fields.startTime, 
          width: MIN_COL_WIDTH, 
        },
        { ...fields.endTime,
          width: MIN_COL_WIDTH, 
        },
        {
          ...fields.startDate,
          width: 148,
          filterable: true,
          filterParam: 'byStartDate',
          getFilterOptions: getStartDateFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.StartDate
        },
        {
          ...fields.endDate,
          width: 148,
          filterable: true,
          filterParam: 'byEndDate',
          getFilterOptions: getEndDateFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.EndDate
        },
        {
          ...fields.noEnrichmentDays, 
          width: 300, 
          filterable: true, 
          filterParam: 'byNoEnrichmentDay', 
          getFilterOptions: getNoEnrichmentDayFilterOptions
        },
        {
          ...fields.enrollmentOpens.date,
          id: 'enrollmentOpens.date',
          label: 'Enrollment start date',
          width: 148,
          filterable: true,
          filterParam: 'byEnrollmentOpens',
          getFilterOptions: getEnrollmentOpensFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.EnrollmentOpens
        },
        {
          ...fields.enrollmentOpens.time,
          id: 'enrollmentOpens.time',
          label: 'Enrollment start time',
          width: MIN_COL_WIDTH
        },
        {
          ...fields.enrollmentCloses.date,
          id: 'enrollmentCloses.date',
          label: 'Enrollment end date',
          width: 148,
          filterable: true,
          filterParam: 'byEnrollmentCloses',
          getFilterOptions: getEnrollmentClosesFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.EnrollmentCloses
        },
        {
          ...fields.enrollmentCloses.time,
          id: 'enrollmentCloses.time',
          label: 'Enrollment end time',
          width: MIN_COL_WIDTH
        },
        {
          ...fields.minCapacity,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byMinCapacity',
          getFilterOptions: getMinCapacityFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.MinCapacity
        },
        {
          ...fields.maxCapacity,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byMaxCapacity',
          getFilterOptions: getMaxCapacityFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.MaxCapacity
        },
        {
          ...fields.teamMaxCapacity,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byTeamMaxCapacity',
          getFilterOptions: getTeamMaxCapacityFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.TeamMaxCapacity
        },
        {
          ...fields.prices,
          width: 250,
          filterable: false,
          sortable: false,
        },
        {
          ...fields['rates.season.rate'],
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'bySeasonRates',
          getFilterOptions: getSeasonRateFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.SeasonRate
        },
        {
          ...fields['rates.season.materialsRate'],
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byMaterialsRates',
          getFilterOptions: getMaterialsRateFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.MaterialsRate
        },
        {
          ...fields?.['rates.season.depositAmount'],
          width: MIN_COL_WIDTH
        },
        {
          ...fields?.['rates.season.installmentDates'],
          width: MIN_COL_WIDTH
        },
        {
          ...fields?.['rates.season.homeroomFeeRoundingIncrementCents'],
          label: <BetaLabel>Season Homeroom fee round-up (beta)</BetaLabel>,
          width: 300
        },
        {
          ...fields?.['rates.seasons'],
          label: 'Season price by number of days/week',
          width: 250,
          display: <ConfigurableSeasonRatesDisplay />,
          edit: {component: <ConfigurableSeasonRatesDropdown fields={fields} />, placeholder:FieldPlaceholder.select},
          none: false,
          copy: formatConfigurableSeasonRate,
          paste: parseConfigurableSeasonRates,
          // filterable: true,
          // filterParam: 'byRecurringLabels',
          // getFilterOptions: getRecurringRatesFilterOptions,
        },
        {
          ...fields?.['rates.dropIn.rate'],
          filterable: true,
          filterParam: 'byDropInRates',
          getFilterOptions: getDropInRateFilterOptions,
          label: 'Drop-in price',
          width: 200,
          sortable: true,
          sortParam: CourseSortBy.DropInRate
        },
        {
          ...fields?.['rates.dropIn.homeroomFeeRoundingIncrementCents'],
          label: <BetaLabel>Drop-in Homeroom fee round-up (beta)</BetaLabel>,
          width: 300
        },
        {
          ...fields?.['rates.recurring'],
          label: 'Subscription price(s)',
          width: 250,
          display: <CourseRecurringRatesDisplay />,
          edit: {component: <CourseRecurringRatesDropdown recurringFields={fields} />, placeholder:FieldPlaceholder.select},
          none: false,
          copy: formatRecurringRates,
          paste: parseRecurringRates,
          filterable: true,
          filterParam: 'byRecurringLabels',
          getFilterOptions: getRecurringRatesFilterOptions,
        },
        {
          ...fields?.['rates.usage.rate'],
          id: 'rates.usage.rate',
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byUsageRates',
          getFilterOptions: getUsageRateFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.UsageRate
        },
        {
          ...fields?.['rates.usage.unit'],
          id: 'rates.usage.unit',
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byUsageUnits',
          getFilterOptions: getUsageUnitFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.UsageUnit
        },
        {
          ...fields?.['rates.usage.roundingIncrement'],
          id: 'rates.usage.roundingIncrement',
          width: 220,
          disabled: false, // Needs to be disabled for daily rates.
          filterable: true,
          filterParam: 'byUsageRoundingIncrements',
          getFilterOptions: getUsageRoundingIncrementFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.UsageRoundingIncrement
        },
        {
          ...fields?.['rates.usage.gracePeriod'],
          id: 'rates.usage.gracePeriod',
          filterable: true,
          filterParam: 'byUsageGracePeriods',
          getFilterOptions: getUsagePeriodFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.UsageGracePeriod,
          width: 300
        },
        {
          ...fields?.['rates.usage.homeroomFeeRoundingIncrementCents'],
          id: 'rates.usage.homeroomFeeRoundingIncrementCents',
          label: <BetaLabel>Usage Homeroom fee round-up (beta)</BetaLabel>,
          width: 300
        },
        {
          ...fields.classesCount,
          width: 148,
          filterable: true,
          filterParam: 'byClassesCount',
          getFilterOptions: getClassCountFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Classes
        },
        {
          name: 'enrolledCount',
          label: 'Enrolled',
          disabled: true,
          readOnly: true,
          component: FormatOnly,
          format: renderEnrolledCount,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byEnrolledCount',
          getFilterOptions: getEnrolledCountFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Enrolled
        },
        {
          name: 'waitlistedCount',
          label: 'Waitlisted',
          disabled: true,
          readOnly: true,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byWaitlistedCount',
          getFilterOptions: getWaitlistedCountFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Waitlisted
        },
        {
          name: 'removedCount',
          label: 'Removed',
          disabled: true,
          readOnly: true,
          width: MIN_COL_WIDTH,
          filterable: true,
          filterParam: 'byRemovedCount',
          getFilterOptions: getRemovedCountFilterOptions,
          sortable: true,
          sortParam: CourseSortBy.Removed
        }, {
          name: fields.hideable.name,
          label: fields.hideable.label,
          format: fields.hideable.format,
          parse: fields.hideable.parse,
          ...DropdownField,
          options: [{label: 'Yes', value: true}, {label: 'No', value: false}],
          width: 200,
        }, {
          name: 'site.policy.token',
          label: 'Policies',
          id: 'policies',
          width: 100,
          readOnly: true,
          format: (val:string) => <Link icon='Shield' target='__blank' to={`/policies/${val}`} />,
        }, {
          name: 'site',
          label: 'Reg. link',
          id: 'registration',
          width: 100,
          readOnly: true,
          format: (val:TableCoursesSelections['site']) => <Link icon='Link' target='__blank' to={`/sites/${val?.slug}`} />,
        },
        {
          ...fields.homeTeam,
          width: 200,
        },
        {
          ...fields.awayTeam,
          width: 200,
        },
        {
          ...fields.homeScore,
          width: MIN_COL_WIDTH,
        },
        {
          ...fields.awayScore,
          width: 200,
        }
      ] as DataTableColumn<TableCoursesSelections>[];

      // remove hidden columns because they aren't user hidden they are system hidden
      return all.filter(c => !c.hidden)
    }

    async function getStatusFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Status);
    }

    function getCourseFrameworkFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.CourseFramework);
    }

    function getCourseNameFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Name);
    }

    function getCourseTagFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.CourseTags);
    }

    function getVendorFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Vendor);
    }

    function getTeacherFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Teacher);
    }

    function getRoomFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Room);
    }

    function getGradeFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Grades);
    }

    function getCourseDayFilterOptions() {
      return dayOptions;
    }

    function getStartDateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.StartDate);
    }

    function getEndDateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.EndDate);
    }

    function getNoEnrichmentDayFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.NoEnrichmentDays);
    }

    function getEnrollmentOpensFilterOptions() {
      return getDateTimeFilterOptions(CourseFilterOptionKind.EnrollmentOpens);
    }

    function getEnrollmentClosesFilterOptions() {
      return getDateTimeFilterOptions(CourseFilterOptionKind.EnrollmentCloses);
    }

    function getMinCapacityFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.MinCapacity);
    }

    function getMaxCapacityFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.MaxCapacity);
    }

    function getTeamMaxCapacityFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.TeamMaxCapacity);
    }

    function getSeasonRateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.SeasonRate);
    }

    function getMaterialsRateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.MaterialsRate);
    }

    function getDropInRateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.DropInRate);
    }

    function getRecurringRatesFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Recurring);
    }

    function getUsageRateFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.UsageRates);
    }

    function getUsageUnitFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.UsageUnits);
    }

    function getUsagePeriodFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.UsageGracePeriods);
    }

    function getUsageRoundingIncrementFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.UsageRoundingIncrements);
    }

    function getClassCountFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Classes);
    }

    function getEnrolledCountFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Enrolled);
    }

    function getWaitlistedCountFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Waitlisted);
    }

    function getRemovedCountFilterOptions() {
      return getFilterOptions(CourseFilterOptionKind.Removed);
    }

    async function getDateTimeFilterOptions(filterOptionKind: CourseFilterOptionKind) {
      const options = await getFilterOptions(filterOptionKind);
      return options.map(o => ({ ...o, label: longDateTimeFormatter(o.label) }));
    }

    async function getFilterOptions(filterOptionKind: CourseFilterOptionKind) {
      const [, result] = await courseFilterOptions({ variables: { ...queryVars, filterOptionKind } });
      return result.data?.seasonCourseFilterOptions;
    }

    // turn on the provider fields if the user chooses a organizer + provider as a course provider
    // note that while this will cause the columns to disappear if all organizer + providers are removed
    // from courses, this isn't watching for removed courses, so the provider columns would stick
    // around until the next provider change, which is fine...if that's needed we can add a listener
    // to the courses collection

    function onChangeProviderInTable(vendor:Vendor, info:FieldInfo<Partial<TableCoursesSelections>, 'vendor'>, settingMultipleValues:boolean) {
      // typescript is wrong about Partial<TableCoursesSelections> not fitting with Partial<Course>
      base.onChangeProvider(vendor, info as FieldInfo<any>, settingMultipleValues);
      checkOrganizerVendor();
    }

    function onCoursesChange() {
      checkOrganizerVendor();
    }

    function onFormReset(form:FormModel<TableCoursesSelections>) {
      base.onFormReset(form as any);
      checkOrganizerVendor();
    }

    function checkOrganizerVendor() {
      const counts = calcCourseTypes();

      // the course counts aren't used directly anymore, but we still need the
      // table to invalidate so that the save button titles update
      
      if (!isEqual(courseCounts.current, counts)) {
        // set hadOrganizerVendors so we avoid more force updates
        courseCounts.current = counts;
        // since this is called in response to form callbacks
        // including when rows are added, we want to do a delayed
        // update to the table, else it can cause re-entrancy problems 
        // with data such as adding columns in the middle of appending rows
        setTimeout(() => forceUpdate(), 1);
      }
    }

    function calcCourseTypes() {
      let regular = 0;
      let orgProvider = 0;
      let draft = 0;
      let request = 0;
      let awaitingApproval = 0;

      if (!courses) {
        return {regular, orgProvider, draft, request, awaitingApproval};
      }

      for (let pos = 0; pos < courses.length; ++pos) {
        const course = courses.getItem(pos);

        if (course.vendor?.company?.userCompanyRole) {
          ++orgProvider;
        }
        else {
          ++regular;
        }

        if (course.status == CourseStatus.Draft || !course.status) {
          ++draft;
        }
        else 
        if (course.status == CourseStatus.Request) {
          ++request;
        }
        else 
        if (course.status == CourseStatus.AwaitingApproval) {
          ++awaitingApproval;
        }
      }

      return {regular, orgProvider, draft, request, awaitingApproval};
    }

    function renderEnrolledCount(value: any, _:any, info:FieldInfo<TableCoursesSelections>) {
      return <FieldValue color={value < info.record.minCapacity ? 'negative' : 'positive'}>
        {value}
      </FieldValue>;
    }

    coursesObserver.onChange = onCoursesChange;

    return {cols: getAllCols(), onFormReset, courseCounts: calcCourseTypes()};
  }, [base, courses, status, forceUpdate.counter]);
}

export const allCourseCols = ['name', 'status', 'kind', 'hasParentOptionCourses', 'courseFramework', 'vendor', 'teacher', 'courseTags', 'description', 'supplies', 'grades', 'ageMin', 'ageMax', 'room', 'courseDays', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'minCapacity', 'maxCapacity', 'classesCount', 'enrolledCount', 'waitlistedCount', 'removedCount', 'searchable', 'prices', 'rates.season.rate', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'rates.season.homeroomFeeRoundingIncrementCents', 'rates.seasons', 'rates.dropIn.rate', 'rates.dropIn.homeroomFeeRoundingIncrementCents', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'rates.usage.homeroomFeeRoundingIncrementCents', 'policies', 'registration'];
