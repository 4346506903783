import * as React from 'react'

import { useLifecycle } from '../utils';
import { MultiContext } from '../utils';

import { ShieldConsumer } from './ShieldConsumer';
import { ShieldType, ShieldContext } from './Shield';

// pass true or false to turn the loader on or off
// passing undefined will do nothing
// however, you can use the return value to turn
// the loader and shield on/off
function useShieldBase(type?:ShieldType, show?:boolean) {
  // the key is just a random object/array that uniquely
  // identifies this component usage...we just need object
  // identity to act as a key into the loader key
  const provider = React.useContext<ShieldContext>(MultiContext).shield;
  const consumer = React.useMemo(() => new ShieldConsumer(provider), []);

  if (type !== undefined && show !== undefined) {
    if (type == 'shield') {
      consumer.shield(show);
    }
    else {      
      consumer.loader(show);
    }
  }

  useLifecycle({onUnmount});

  function onUnmount() {
    consumer.shield(false);
    consumer.loader(false);
  }

  return consumer;
}

export function useShield(show?:boolean) {
  return useShieldBase('shield', show);
}

export function useLoader(show?:boolean) {
  return useShieldBase('loader', show);
}

export function useCurrentShieldProvider() {
  return React.useContext<ShieldContext>(MultiContext).shield;
}

export function useIsShieldLoading() {
  const provider = React.useContext<ShieldContext>(MultiContext).shield;

  return provider?.showingLoader;
}
