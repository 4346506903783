import * as React from 'react';
import { merge } from 'lodash-es';

import { compact, CourseUtils, RefundKind, PaymentStatus, RateType } from 'app2/api';
import { HBox, Modal, RepeatingSection, Form, FormModel, Info, formatCurrency, Section, Field, CheckboxField, TextAreaField, FieldRendererProps, useForm, Subtitle2, Tooltip, Icon, VBox } from 'app2/components';
import { errorPathTransform, RefundTable } from 'app2/views';

import { PaymentStatusTag, StudentNameField, ScheduleField } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

import { getRemoveAthelticsWarnings } from '../RemoveStudentsModal';
import { RefundCombo } from './RefundCombo';
import { refundEnrollments, EnrollmentRefundsSelections, useEnrollmentRefundsQuery, useEnrollmentsRefundBreakdownsQuery } from './generated';

interface FormEnrollment extends DistinctEnrollmentsSelections {
  refunds: EnrollmentRefundsSelections[];
}

interface FormValues {
  kind: RefundKind;
  amount: number;
  remove: boolean;
  includeOptions: boolean;
  description: string;
  enrollments: FormEnrollment[];
}

type RefundForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function RefundModal(props: Props) {
  const [refundsResult] = useEnrollmentRefundsQuery({ variables: { ids: props.enrollments.map(e => e.id) as any } });
  const enrollmentsWithRefunds = refundsResult.data?.enrollments;
  const enrollments = props.enrollments.map(enrollment => {
    const refunds = enrollmentsWithRefunds?.find(e => e.refunds.some(r => r.enrollment.id == enrollment.id))?.refunds || [];
    return { ...enrollment, refunds };
  }) as unknown as FormEnrollment[]
  const hasRefundableEnrollments = props.enrollments.some(e => e.balance > 0);

  const initialValues = React.useMemo(() => {
    return {
      kind: RefundKind.Withdraw,
      amount: 0,
      remove: false,
      enrollments
    };
  }, [props.enrollments, enrollmentsWithRefunds]);
  const form = useForm<FormValues>(initialValues, [initialValues], {alwaysSave: true});

  const variables = { ids: form.values.enrollments.map(e => e.id), kind: form.values.kind, amount: form.values.amount };
  const [breakdownResult] = useEnrollmentsRefundBreakdownsQuery({ variables, autoPause: false, debounce: { delay: 500 }, hideLoader: true });
  const enrollmentRefundMap = React.useMemo(createEnrollmentRefundMap, [breakdownResult]);

  const remove = form.values.remove;
  const title = `Refund${remove ? ' and remove' : ''}`;
  const withdraw = form.values.kind === RefundKind.Withdraw;

  function render() {
    return (
      <Modal title={title} ok={title}>
        <Form icon="Refund" title="Refund options" form={form} onOk={handleSubmit} onNavigation="nothing">
          {renderTable()}
          {renderFields()}
          {renderWarnings()}
        </Form>
      </Modal>
    );
  }

  function renderTable() {
    const priceType = CourseUtils.getRateOrPriceType(props.course.rates);

    return (
      <RepeatingSection
        name="enrollments"
        bordered
        fields={[
          <Field label="Student" name="student.name" nameKind='full' link={false} component={StudentNameField} />,
          <Field label="Paid" name="paid" render={renderPaid} />,
          priceType == RateType.advanced && <Field label="Schedule" name="rosterPeriod" component={ScheduleField} />,
          priceType != RateType.basic && <Field label="Bill period" name="billPeriod" />,
          <Field label="Family receives" name="refunds" render={renderTooltip} />
        ]}
      />
    );
  }

  function renderPaid(fieldProps: FieldRendererProps<FormEnrollment>) {
    return <PaymentStatusTag paymentStatus={fieldProps.info.record.paymentStatus} label={formatCurrency(fieldProps.info.value)} />
  }

  function renderTooltip(fieldProps: FieldRendererProps) {
    return (
      <HBox gap="8px">
        {formatCurrency(enrollmentRefundMap[fieldProps.info.record.id] || 0)}
        <Tooltip tip={renderRefunds(fieldProps)}>
          {/* Container apparently needed to prevent reactjs-popup error: Uncaught TypeError: triggerRef.current.getBoundingClientRect is not a function */}
          <HBox alignItems="center">
            <Icon size="small" name="Info" />
          </HBox>
        </Tooltip>
      </HBox>
    );
  }

  function renderRefunds(fieldProps: FieldRendererProps) {
    return <VBox>
      <Subtitle2 mb="$20">Refund history</Subtitle2>
      <RefundTable refunds={fieldProps.info.value} />
    </VBox>
  }

  function renderFields() {
    const perSessionEnabled = props.enrollments.every(enrollment => enrollment.perSessionRefunds);

    return (
      <>
        <RefundCombo form={form} breakdown={breakdownResult.data?.enrollmentsRefundBreakdowns} perSessionEnabled={perSessionEnabled} course={props.course} />
        <VBox gap='$8'>
          <Section name="remove" label="Remove selected enrollments from this activity" component={CheckboxField} />
          {renderIncludeOtherEnrollments()}
        </VBox>
        <Section name="description" label="Reason for refund" component={TextAreaField} required autoSize minHeight='unset' />
      </>
    );
  }

  function renderIncludeOtherEnrollments() {
    const hasOptions = props.course.options?.length > 0;

    if (!hasOptions || form.values.kind != RefundKind.Withdraw) {
      return;
    }

    return <Section component={CheckboxField} label="Remove from activity options" name="includeOptions" />;
  }

  function renderWarnings() {
    const processing = props.enrollments.find(e => e.paymentStatus == PaymentStatus.Pending || e.paymentStatus == PaymentStatus.Processing) != null;
    const failed = props.enrollments.find(e => e.paymentStatus == PaymentStatus.RetriableError || e.paymentStatus == PaymentStatus.Failed) != null;
      const warnings = [];

    if (processing) {
      warnings.push('Processing purchases can not be refunded.  You must wait until the purchase processing completes.')
    }
    else {
      warnings.push('This action can not be undone.');

      if (withdraw) {
        if (remove && hasRefundableEnrollments) {
          warnings.push('The students will be removed and their parents will refunded in full.');
        }
        else
        if (!remove && hasRefundableEnrollments) {
          warnings.push('The parents will be refunded in full.');
        }
        else
        if (remove) {
          warnings.push('The students will be removed.');
        }
      }

      warnings.push(getRemoveAthelticsWarnings(props.course));

      if (failed) {
        warnings.push('Failed purchases will not be refunded, however they will be updated to indicate the parent no longer owes the refunded amount.')
      }
    }

    if (CourseUtils.usingInstallments(props.course)) {
      warnings.push('This activity may have multiple installment payments.')
    }

    return <Info type="warning">{compact(warnings)}</Info>;
  }

  function createEnrollmentRefundMap() {
    // map enrollments to their respective refund breakdowns
    const map: { [id: string]: number } = {};

    form.values.enrollments.forEach((e, index) => {
      const breakdown = breakdownResult?.data?.enrollmentsRefundBreakdowns?.individualBreakdowns?.find(bd => bd.enrollment.id == e.id);
      map[e.id] = breakdown?.familyReceives;
    });

    return map;
  }

  async function handleSubmit(form: RefundForm) {
    const ids = props.enrollments.map(e => e.id);
    const variables = { ids, courseId: props.course?.id, amount: form.values.amount, description: form.values.description, kind: form.values.kind, remove, includeOptions: form.values.includeOptions, withdraw };
    const [success] = await refundEnrollments({
      variables,
      successMsg: `Students refunded${remove ? ' and removed' : ''}`,
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments')] }
    });
    return success;
  }

  return render();
}
