import * as React from 'react';

import { ButtonStrip } from 'app2/components';
import { DaysButtonStrip } from 'app2/views';

import { Demos, Demo } from './Demo';

export function ButtonStripDemos() {
  const [selected, setSelected] = React.useState([]);

  return <Demos name='Tab'>
    <Demo name='Uncontrolled, single'>
      <ButtonStrip options={['B1', 'B2']} />
    </Demo>
    <Demo name='Controlled, single'>
      <ButtonStrip options={['Tab', 'A long tab']} selected={selected} onChange={event => setSelected(event.currentTarget.selected)} />
      selected: {selected.join(',')}
    </Demo>
    <Demo name='Days, multiple'>
      <ButtonStrip multiple options={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} />
    </Demo>
    <Demo name='Days, error'>
      <ButtonStrip error options={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} />
    </Demo>
    <Demo name='Days, disabled'>
      <ButtonStrip disabled options={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} />
    </Demo>
    <Demo name='DaysButtonStrip, controlled'>
      <DaysButtonStrip selected={selected} onChange={event => setSelected(event.currentTarget.selected)} />
      selected: {selected.join(',')}
    </Demo>
  </Demos>
}
