// attempts to simulate the events that occur from a keyboard event
// from a source event.  used to try and trigger the events that
// would occur if the user actually invoked the key press.

export function forwardKeyPress(source:React.KeyboardEvent | KeyboardEvent) {
  const element = document.activeElement as HTMLElement;

  if (!element) {
    return;
  }

  const tag = element.tagName.toLocaleLowerCase();

  // note if you are fowarding to a input, that is of type text
  // it will reject invalid numbers.  so if you are fowarding 
  // a negative char, it will reject it because its not a complete number.
  // there's no work around for this (temporarily converting to text 
  // to set the value, only delays the issue until you convert it back to number).
  const cantFoward = source.key == '-' && tag == 'input' && (element as HTMLInputElement).type?.toLowerCase() == 'number';

  if (cantFoward) {
    return false;
  }

  const keyDown = new KeyboardEvent('keydown', source);
  element.dispatchEvent(keyDown);

  if (keyDown.defaultPrevented) {
    return;
  }

  const keyPress = new KeyboardEvent('keypress', source);
  element.dispatchEvent(keyPress);

  if (tag == 'input' || tag == 'textarea') {
    // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js/46012210#46012210
    const setValue = Object.getOwnPropertyDescriptor(tag == 'input' ? HTMLInputElement.prototype : HTMLTextAreaElement.prototype, 'value').set;
    setValue.call(element, source.key);

    const input = new InputEvent('input', {data: source.key, inputType: 'insertText', bubbles: true});
    element.dispatchEvent(input);

    const change = new Event('change', {bubbles: true});
    element.dispatchEvent(change);
  }

  const keyUp = new KeyboardEvent('keyup', source);
  element.dispatchEvent(keyUp);
  
  return true;
}
