import * as React from 'react';

import { organizerPreferences } from 'app2/api';

import { Discounts, DiscountsProps } from './Discounts';

const PREFS_VERSION = '1'

export function PromotionalDiscounts(props:Omit<DiscountsProps, 'queries' | 'cols' | 'header'>) {
  return <Discounts queries='promotions' {...props} cols={cols} prefsVersion={PREFS_VERSION} pref={organizerPreferences.pref('promotionalDiscountsTable')}
    header={{title:'Discounts', subtitle:'Each enrollment can be paired with one discount code and one student discount. If multiple codes apply, we’ll choose the one with the greatest savings.'}} />
}

const cols = ['code', 'active', 'kind', 'amount', 'usesCount', 'scope.courseKinds', 'scope.courses'];
