import * as React from 'react';

import { EntityKind } from 'app2/api';
import { Modal, Form, FormModel, EmailField, Section, Text, TextAreaField, useForm } from 'app2/components';

import { useCurrentUser } from '../user';
import { startConversation } from './generated';

interface Props {
  to:string;
  toId:string;
  toKind:EntityKind;
  message:string;
}

export function ContactModal(props: Props) {
  const { user } = useCurrentUser();

  const form = useForm({ email: user?.email || '', message: props.message}, [props.message, user]);
  const email = form.values.email;

  function render() {
    if (!user) {
      return <Modal title={`Send message to ${props.to}`} ok={null} cancel='Close'>
        <Text text='body'>You must be logged in to send a message.</Text>
      </Modal>
    }

    return (
      <Modal title={`Send message to ${props.to}`} ok="Send email">
        <Form onOk={onOk} form={form}>
          {!user && <Section name='email' label='Your email address' required component={EmailField} />}
          <Section name="message" label="Message" required component={TextAreaField} description={`We'll email your message to the provider and send the response to your email address${email ? ` (${email})` : ''}`} />
        </Form>
      </Modal>
    );
  }

  async function onOk(form: FormModel<{ email:string, message: string }>) {
    const [success] = await startConversation({ variables: { content: form.values.message, toId: props.toId, toKind: props.toKind, from: form.values.email }, successMsg: 'Message sent' });

    return success;
  }

  return render();
}
