import * as React from 'react'

import { HBox, Box, BoxProps } from '../Box';
import { MultiContext, MultiContextProvider } from '../utils';

import { Loader } from './Loader'
import { useShieldProvider, ShieldProvider } from './ShieldProvider';

export type ShieldType = 'shield' | 'loader';

export interface ShieldContext {
  shield:ShieldProvider;
}

interface Props extends BoxProps {
  // shield or loader...a loader always has a shield as well
  type?:ShieldType;
  // if nest is false and there's a parent shield, then this shield
  // will not render.  if nest is true, and there's already a parent
  // shield, then this will render a shield inside a shield, and any
  // users of the context/hook functions would use this shield
  nest?:boolean;

  // indicates if this shield is being used for modals
  // if not, this shield will not shield modals, which is useful
  // for when some kind of handler shows a shield, and while that
  // processing is happening it needs to show a modal on top of it
  modal?:boolean;
}

export function Shield(props:Props) {
  const {type, nest, modal, children, ...remaining} = props;
  const parentShield = React.useContext<ShieldContext>(MultiContext).shield;
  const localShield = useShieldProvider(modal || parentShield?.modal);

  if (parentShield && !nest) {
    return <Box width='100%' position='relative' {...remaining}>{children}</Box>;
  }

  const showLoader = localShield.showingLoader || type == 'loader';
  const showShield = localShield.showingShield || type == 'shield' || showLoader;
  // reactjs-popup uses 999 for its zindex, so if this is a shield for
  // a popup, we go above that
  const zIndex = localShield.modal ?  99999 : 999;

  function render() {
    return <MultiContextProvider shield={localShield}>
      <Box width='100%' height='100%' position={modal ? 'absolute' : 'relative'} top={modal ? 0 : undefined} caretColor={showShield ? 'transparent' : undefined}  {...remaining}>
        {showShield && <Box position='fixed' top={0} left={0} width='100%' height='var(--app-height, 100vh)' zIndex={zIndex} onMouseDownCapture={handleShieldClick} />}
        <HBox key='loader' pointerEvents='none' hAlign='center' vAlign='center' position='fixed' top={0} left={0} width='100%' height='var(--app-height, 100vh)' zIndex={zIndex}>
          <Loader key='loader2' style={{opacity:showLoader ? 1 : 0}} />
        </HBox>
        {children}
      </Box>
    </MultiContextProvider>
  }

  function handleShieldClick(event:React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  return render();
}

// hack to get around circular import issue when putting 
// a shield on the children of a theme
//@ts-ignore
window['__Shield'] = Shield;
