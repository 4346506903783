import * as React from 'react'

import { CourseCard, CourseCardProps } from 'app2/views/shared-public'

import { CourseWithBreakdowns, useCourseWithBreakdowns } from './useCourseWithBreakdowns';

export function ActivityCardWithPriceUpdates(props:CourseCardProps & {course:CourseWithBreakdowns}) {
  const {course} = useCourseWithBreakdowns(props.course);

  return <CourseCard {...props} course={course} />
}
