import { Rect } from '../dom-utils';

import { TableSectionFactory } from './TableSectionFactory';

export enum TableSectionType {
  topLeft,
  topMiddle,
  middleLeft,
  middleMiddle
}

export interface TableSection {
  type:TableSectionType;
  factory:TableSectionFactory;
  coords:Rect;
}
