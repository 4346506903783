import * as React from 'react';

import { Modal, Form, Section, useForm, Body, FieldInfo } from 'app2/components';

import { CompaniesDropdown } from './CompaniesDropdown';
import { SitesDropdown } from './SitesDropdown';
import { companyCopyStripeToken, siteCopyStripeToken } from './generated';

type SiteOrCompany = {
  id:string;
  name?:string;
}

type CopyForm = {
  site?:SiteOrCompany;
  company?:SiteOrCompany;
}

interface Props {
  destination:SiteOrCompany;
  destinationType:string;
}

export function CopyStripeTokenModal(props:Props) {
  const form = useForm<CopyForm>({}, []);

  function render() {
    return <Modal title='Copy Stripe token' danger info={{type:'warning', message: 'This can not be undone'}}>
      <Form form={form} onOk={onOk} editing>
        <Body>Choose a school OR a provider who's Stripe account to <b>copy to {props.destination.name}</b></Body>
        <Section label="School" name='site' component={SitesDropdown} clearable validators={validateOnlyOne} />
        <Section label="Provider" name='company' component={CompaniesDropdown} clearable validators={validateOnlyOne} />
      </Form>
    </Modal>
  }

  async function onOk() {
    const source = form.values.site || form.values.company;
    let success = false;

    if (form.values.site) {
      success = (await siteCopyStripeToken({variables:{site: source.id, destination: props.destination.id, destinationType: props.destinationType}}))[0]
    }
    else {
      success = (await companyCopyStripeToken({variables:{company: source.id, destination: props.destination.id, destinationType: props.destinationType}}))[0]
    }

    return success;
  }

  return render();
}

function validateOnlyOne(value:SiteOrCompany, info: FieldInfo<CopyForm>) {
  return info.form.values.site && info.form.values.company
    ? "Please select either a school or provider to copy from, but not both"
    : null
}
