import * as React from 'react'

import { colId, DataTable, FilterParser, Form, Link, InputField, Section, useForm } from 'app2/components';
import { EntityKind } from 'app2/api';
import { Attendance } from 'app2/views/shared';

import { OrganizerPage, useCurrentSite } from '../shared';

export function SiteAttendance() {
  const { site } = useCurrentSite();
  const table = React.useRef<DataTable>();
  const form = useForm({filter: ''}, []);

  function render() {
    return <OrganizerPage title="Daily attendance">
      <Form form={form} onNavigation='nothing' mb='$16'>
        <Section name='filter' placeholder='Enter season, activity or student name to filter' component={InputField} />
      </Form>

      <Attendance table={{ref:table}} header={{primaryActions:renderPrimaryActions()}} url={`/organizer/${site?.slug || site?.id}/attendance`} site={site} queryOptions={{variables:{advancedFilter:getFilter()}, debounce: {delay:1000}}} views={{entityKind: EntityKind.Site, entityId: site?.id, table: 'site-attendance'}} />
      </OrganizerPage>
  }

  function renderPrimaryActions() {
    return site?.attendanceUrl && <Link button='primary' to={site?.attendanceUrl} target='__blank'>Family attendance page</Link>;
  }

  function getFilter() {
    return React.useMemo(() => {
      const cols = table.current?.allCols.filter(c => allowedCols.has(colId(c)));
      const filter = new FilterParser(cols).parse(form.values.filter, null)
      return filter || {};
    }, [form.values.filter]);
  }

  return render();
}

const allowedCols = new Set([
  'season.name',  
  'course.name', 
  'student.firstName',  
  'student.lastName', 
  'student.name'
])

