import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteDeactivateMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type AdminSiteDeactivateMutation = { siteDeactivate?: { id: string } | null | undefined };

export const AdminSiteDeactivateDocument = gql`
  mutation AdminSiteDeactivate($site: ID!) {
    siteDeactivate(site: $site) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSiteDeactivateOptions = ExecMutationOptions<AdminSiteDeactivateMutationVariables>;

export function adminSiteDeactivate(options: AdminSiteDeactivateOptions) {
  return executeMutation<AdminSiteDeactivateMutation, AdminSiteDeactivateMutationVariables>(AdminSiteDeactivateDocument, options);
}
