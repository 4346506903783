import * as React from 'react';

import { Shield, Button, useShield, MultiContext } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ShieldDemos() {
  const [useHook, setUseHook] = React.useState(false);

  return <Demos name='Shield'
  description={`
  The Shield component gives a way to put a mouse blocking shield on top of other components
  for when you want to block user interactivity (during a load or save).  The shield goes on
  top of the children that is passed to it.

  - Modes - shield has two modes:
   - shield - transparent blocker of interactivity
   - loader - displays a loading animation and also has a transparent shield

  - Using a shield:
   - render a shield and pass type to actively show a shield
   - render a shield, with no type property, but use the shield context and/or hook functions, to turn the shield/loader on and off as needed

  - Nesting - when shields are nested in the component hierarchy, by default the nested shields will not render.  to force
   nested rendering, specify the nested attribute.

   Note in all these demos the shield is given a partial color so you can see it, but normally it would be transparent. 

  `}>
    <Demo name='Direct'>
      <Shield type='shield' bg='red'>
        <Button>Try to click me...you can't because of the shield</Button>
      </Shield>
    </Demo>

    <Demo name='Direct, nested'>
      <Shield bg='blue'>
        <Button>I'm in a shield, but this shield is not turned on</Button>
        <Shield bg='red' type='shield'>
          <Button>I'm in a nested shield, but it didn't render because nested = undefined</Button>
        </Shield>
      </Shield>
    </Demo>

    <Demo name='Direct, nested'>
      <Shield bg='blue'>
        <Button>I'm in a shield, but this shield is not turned on</Button>
        <Shield bg='red' nest type='shield'>
          <Button>I'm in a nested shield and I can't be clicked</Button>
        </Shield>
      </Shield>
    </Demo>

    <Demo name='Indirect, use hook immediately'>
      <Shield bg='red'>
        <Button onClick={() => setUseHook(!useHook)}>Click me to turn on the shield</Button>
        {useHook && <MyComponent />}
      </Shield>
    </Demo>

    <Demo name='Indirect, use hook as needed'>
      <Shield bg='red'>
        <MyComponent2 />
      </Shield>
    </Demo>

    <Demo name='Indirect, use context in class component'>
      <Shield bg='red'>
        <MyComponent3 />
      </Shield>
    </Demo>
  </Demos>
}

function MyComponent() {
  useShield(true);

  return <span>Using shield now, and you can no longer click the button</span>;
}

function MyComponent2() {
  const [shieldOn, setShieldOn] = React.useState(false);
  const shield = useShield();

  async function toggleShield() {
    setShieldOn(true);
    shield.shield(true);

    await new Promise((resolve) => setTimeout(resolve, 5000));

    shield.shield(false);
    setShieldOn(false);
  }

  return <Button onClick={() => toggleShield()}>{shieldOn ? 'Shield is on' : 'Click me to turn the shield on for 5 seconds'}</Button>;
}

class MyComponent3 extends React.Component {
  static contextType = MultiContext;

  declare context: React.ContextType<typeof MultiContext>;
  state = {
    shieldOn: false
  }

  toggleShield = async () => {
    this.setState({shieldOn: true});
    this.context.shield(this, true);

    await new Promise((resolve) => setTimeout(resolve, 5000));

    this.context.shield(this, false);
    this.setState({shieldOn: false});
  }

  render() {
    return <Button onClick={() => this.toggleShield()}>{this.state.shieldOn ? 'Shield is on' : 'Click me to turn the shield on for 5 seconds'}</Button>
  }
}
