import * as React from 'react';

import { ModalWithTabs, HBox, Icon } from 'app2/components';
import { VendorCompliance, TeacherCompliance } from 'app2/views/shared';

import { Activities } from './Activities';
import { Details } from './Details';
import { useVendorQuery } from './gql';

interface Props {
  vendor: string;
}

export function ProviderModal(props: Props) {
  const query = useVendorQuery({ variables: { vendor: props.vendor } });
  const provider = query[0].data?.vendor;
  const teachersInCompliance = provider?.vendorTeachers?.every(t => t.inCompliance);

  function render() {
    return <ModalWithTabs title="Edit provider details" cancel={null} ok='Close' tabs={renderTabs()} {...props} />
  }

  function renderTabs() {
    return [
      {
        label: 'Provider details',
        content: <Details provider={provider} loading={query[0].fetching} />
      },
      provider?.courses?.length > 0 && {
        label: 'Activities',
        content: <Activities provider={provider} />
      },
      provider?.showProviderCompliance && {
        label: <HBox gap='$8' vAlign='center'>Provider compliance{!provider.vendorInCompliance && <Icon name='AlertCircle' color='error' />}</HBox>,
        content: <VendorCompliance vendor={props.vendor} />
      },
      provider?.showTeacherCompliance && {
        label: <HBox gap='$8' vAlign='center'>Instructor compliance{!teachersInCompliance && <Icon name='AlertCircle' color='error' />}</HBox>,
        content: <TeacherCompliance vendor={props.vendor} teachers={provider?.vendorTeachers || []} />
      }
    ];
  }

  return render();
}
