import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { QuestionFieldsFragmentDoc } from '../../../../custom-fields/generated/cfQuestionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentCfQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type StudentCfQuery = {
  student?:
    | {
        id: string;
        name: string;
        questions?:
          | Array<
              | {
                  id: string;
                  ownerId?: string | null | undefined;
                  ownerType?: Types.CfOwnerKind | null | undefined;
                  kind?: Types.CfKind | null | undefined;
                  title?: string | null | undefined;
                  description?: string | null | undefined;
                  choices?: Array<string> | null | undefined;
                  stage?: Types.CfStage | null | undefined;
                  required?: boolean | null | undefined;
                  owner?: { name?: string | null | undefined } | { name?: string | null | undefined } | { name: string } | { name: string } | { name?: string | null | undefined } | null | undefined;
                  files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        answers?: Array<{ ownerId?: string | null | undefined; ownerType?: Types.CfOwnerKind | null | undefined; answers?: { [key: string]: any } | null | undefined } | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export const StudentCfDocument = gql`
  query StudentCf($id: ID!) @urql(additionalTypenames: ["Student"]) {
    student(student: $id) {
      id
      name
      questions: cfQuestionsConfirmed {
        ...questionFields
      }
      answers: cfAnswersConfirmed {
        ownerId
        ownerType
        answers
      }
    }
  }
  ${QuestionFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentCfArgs = MakeOptional<UseQueryArgs<StudentCfQueryVariables, StudentCfQuery>, 'query'>;

export function useStudentCfQuery(options: StudentCfArgs = {}) {
  return useQuery<StudentCfQueryVariables, StudentCfQuery>({ query: StudentCfDocument, ...options });
}

export type StudentCfOptions = ExecQueryOptions<StudentCfQueryVariables>;

export function studentCf(options: StudentCfOptions) {
  return executeQuery<StudentCfQuery, StudentCfQueryVariables>(StudentCfDocument, options);
}

export type StudentCfSelections = StudentCfQuery['student'];

(StudentCfDocument as any).additionalTypenames = ['Student'];
