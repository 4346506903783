import * as React from 'react';

import { Site } from 'app2/api';
import { VBox, Box, HBox, Text, Icon, maxWidth } from 'app2/components';

import siteImg from './site.png';

interface Props {
  site:Pick<Site, 'name' | 'streetAddress' | 'city' | 'state' | 'zipcode'>;
}

export function SiteHeader(props:Props) {
  const site = props.site;

  function render() {
    const csz = site ? `${site?.city}, ${site?.state} ${site?.zipcode}` : ''
    const siteAddress = `${site?.streetAddress} ${csz}`;
    const googleMapSearchURI = encodeURI(`https://www.google.com/maps/search/?api=1&query=${siteAddress}`);

    return <VBox width="100%" minHeight="130px" px="$20" py="$12" bg='primary'>
      <VBox maxWidth={maxWidth} width='100%' height="100%" mx="auto" pb="$16">
        <Text text='heading2' color="#fff">{site?.name}</Text>
        <Box flex="1" />
        <HBox position="relative">
          {site && <Icon name='MapPin' color="#fff" size="22" mr='$16' />}
          <a href={googleMapSearchURI} target="_blank" style={{ textDecoration: '#fff underline' }}>
            <VBox>
              <Text text='body' color="#fff" lineHeight="18px">{site?.streetAddress}</Text>
              <Text text='body' color="#fff" lineHeight="18px">{csz}</Text>
            </VBox>
          </a>
          <Box flex="1" />
          <VBox width="120px" justifyContent="flex-end" position="absolute" right="0" bottom="-18px">
            <img src={siteImg} style={{ width: '100%' }} />
          </VBox>
        </HBox>
      </VBox>
    </VBox>
  }

  return render();
}
