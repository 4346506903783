import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type QuickStartGuideQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type QuickStartGuideQuery = {
  company?:
    | {
        zipcode?: string | null | undefined;
        quickStartGuide?:
          | {
              profileComplete?: boolean | null | undefined;
              courseFrameworksComplete?: boolean | null | undefined;
              coursesComplete?: boolean | null | undefined;
              intructorsComplete?: boolean | null | undefined;
              stripeComplete?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const QuickStartGuideDocument = gql`
  query QuickStartGuide($company: ID!) {
    company(company: $company) {
      quickStartGuide {
        profileComplete
        courseFrameworksComplete
        coursesComplete
        intructorsComplete
        stripeComplete
      }
      zipcode
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type QuickStartGuideArgs = MakeOptional<UseQueryArgs<QuickStartGuideQueryVariables, QuickStartGuideQuery>, 'query'>;

export function useQuickStartGuideQuery(options: QuickStartGuideArgs = {}) {
  return useQuery<QuickStartGuideQueryVariables, QuickStartGuideQuery>({ query: QuickStartGuideDocument, ...options });
}

export type QuickStartGuideOptions = ExecQueryOptions<QuickStartGuideQueryVariables>;

export function quickStartGuide(options: QuickStartGuideOptions) {
  return executeQuery<QuickStartGuideQuery, QuickStartGuideQueryVariables>(QuickStartGuideDocument, options);
}

export type QuickStartGuideSelections = QuickStartGuideQuery['company'];
