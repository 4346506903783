export function getPrototypeChain(clazz: any) {
  const chain: any[] = [];

  while (clazz && clazz != Object.prototype) {
    chain.push(clazz);
    clazz = Object.getPrototypeOf(clazz);
  }

  return chain;
}
