import * as React from 'react';

import { ClipboardUtils } from '../../dom-utils';

import { ActionButton } from './ActionButton';

export function CopyAction() {
  function render() {
    return <ActionButton icon='Copy' selection={false} onClick={onCopy}>Copy selected</ActionButton>;
  }

  function onCopy() {
    ClipboardUtils.generateCopyEvent();
  }

  return render();}
