import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdatePolicyWaiversMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  attributes: Array<Types.FileUploadAttributes> | Types.FileUploadAttributes;
}>;

export type OrganizerUpdatePolicyWaiversMutation = { siteUpdateWaivers?: { waivers?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const OrganizerUpdatePolicyWaiversDocument = gql`
  mutation OrganizerUpdatePolicyWaivers($siteId: ID!, $attributes: [FileUploadAttributes!]!) @urql(additionalTypenames: ["FileUpload"]) {
    siteUpdateWaivers(siteId: $siteId, attributes: $attributes) {
      waivers {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdatePolicyWaiversOptions = ExecMutationOptions<OrganizerUpdatePolicyWaiversMutationVariables>;

export function organizerUpdatePolicyWaivers(options: OrganizerUpdatePolicyWaiversOptions) {
  return executeMutation<OrganizerUpdatePolicyWaiversMutation, OrganizerUpdatePolicyWaiversMutationVariables>(OrganizerUpdatePolicyWaiversDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['FileUpload']) }
  });
}
