import * as React from 'react';

import { Enrollment } from 'app2/api';
import { HBox, BoxProps } from 'app2/components';

interface Props extends BoxProps {
  enrollment: Enrollment;
}

export function EnrollmentStatus(props: Props) {
  const { enrollment, ...remaining } = props;
  const statusProps = enrollment.invitationExpired ? statuses.expired : statuses.invited;

  return (
    <HBox bg={statusProps.bg} color={statusProps.color} text="formlabel" borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} width="fit-content">
      {statusProps.label}
    </HBox>
  );
}

EnrollmentStatus.fieldProps = {
  valueProperty: 'status'
};

const statuses = {
  invited: { bg: '#E1D0D6', color: undefined as string, label: 'Invited' },
  expired: { bg: '#FFBBBB', color: undefined as string, label: 'Expired' }
};
