import * as React from 'react';
import * as ReactIs from 'react-is';

import { Box, BoxProps } from '../Box';
import { Field, FieldProps, InputField } from '../form';
import { Part } from '../panel';
import { Option, OptionValue, SelectOptionInterface, useSelectOptions } from '../Option';
import { createOrClone } from '../utils';

import { Radio } from './Radio';

interface OptionWithInput extends Option {
  input?:React.ReactElement<Field> | FieldProps<any, any, any>;
  inputName?:string;
  description?:string;
}

interface Props extends BoxProps {
  onChange?:React.ChangeEventHandler<SelectOptionInterface>;
  options:OptionWithInput[];
  selected?:OptionValue;
  disabled?:boolean;
  error?:boolean;
  formMode?: 'display' | 'edit';
  component?:React.ComponentType<any> | React.ReactElement<any>;
  // normally the value format is a single value, but if you want to use an array
  // of values, set this to 'array'
  valueFormat?:'single' | 'array';
}

export const RadioGroup = React.forwardRef((props: Props, ref:any) => {
  const {onChange:onChangeProp, options:optionsProp, selected:selectedProp, disabled, error, formMode, component, valueFormat, ...remaining} = props;
  const {options, isSelected, onChange} = useSelectOptions(false, props, ref, valueFormat == 'array');

  function render() {
    return <Box vItemSpace={props.layout != 'hbox' ? '$16' : undefined} hItemSpace={props.layout == 'hbox' ? '$16' : undefined} {...remaining}>
      {options?.map((option, index) => renderRadio(option, index))}
    </Box>
  }

  function renderRadio(option:OptionWithInput, index:number) {
    const {label, value, input, inputName, ...remaining} = option;

    return createOrClone(Radio, {key:index, label: option.label, description: option.description, value: option.value, checked: isSelected(option.value), disabled, error, formMode, onChange:(event:React.ChangeEvent<any>) => onChange(event, option.value), children: renderInput(option), ...remaining}, component);
  }

  function renderInput(option:OptionWithInput) {
    const otherSelected = isSelected(option.value);

    if (ReactIs.isElement(option.input)) {
      //@ts-ignore
      return React.cloneElement(option.input, {disabled: !otherSelected});
    }
    else 
    if (option.input) {
      return <Part disabled={!otherSelected} {...option.input} />
    }

    if (option.inputName) {
      return <Field component={InputField} name={option.inputName} disabled={!otherSelected} />
    }

    return;
  }

  return render();
})

//@ts-ignore
RadioGroup.fieldProps = {
  valueProperty: 'selected',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  onChangeProperty: 'onChange',
  formModeProperty: 'formMode',
  onBlurProperty: 'onBlur',
}

RadioGroup.defaultProps = {
  layout: 'vbox',
  component: Radio,
}

RadioGroup.displayName = 'RadioGroup';