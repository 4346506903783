import * as React from 'react';
import { startCase } from 'lodash-es';

import { Button, Input, InputKind, HBox, VBox, Subtitle2 } from 'app/app2/components';

import { Demo } from './Demo';

export function InputDemos() {
  const [tags, setTags] = React.useState(['tag1', 'tag2', 'tag3']);
  const [value, setValue] = React.useState<string>();

  return <VBox>
    <Demo name='Basic states'>
      {renderInputKind('normal')}
      {renderInputKind('error')}
      {renderInputKind('disabled')}
    </Demo>
    <Demo name='Icon click handler'>
      <Input icon='AlertCircle' onIconClick={() => alert('Icon clicked')} width='300px' />
    </Demo>
    <Demo name='Controlled'>
      <Input value={value} onChange={(event:React.ChangeEvent<HTMLInputElement>) => setValue(event.currentTarget.value)} width='300px' />
      <Subtitle2>Value: {value}</Subtitle2>
    </Demo>
    <Demo name='Type = date'>
      <Input type='date' width='300px' />
    </Demo>
    <Demo name='Type = date, controlled'>
      <Input type='date' value={value} onChange={(event:React.ChangeEvent<HTMLInputElement>) => setValue(event.currentTarget.value)} width='300px' />
      <Subtitle2>Value: {value}</Subtitle2>
    </Demo>
    <Demo name='With tags'>
      <Input tags={tags} onTagClick={onTagClick} onTagCloseClick={removeTag.bind(removeTag, tags, setTags)} />
      <Button onClick={() => addTag(tags, setTags)}>Add tag</Button>
    </Demo>
    <Demo name='With tags, width'>
      <Input tags={tags} onTagClick={onTagClick} onTagCloseClick={removeTag.bind(removeTag, tags, setTags)} width='300px' />
      <Button onClick={() => addTag(tags, setTags)}>Add tag</Button>
    </Demo>
    <Demo name='With tags, icon, width'>
      <Input tags={tags} onTagClick={onTagClick} onTagCloseClick={removeTag.bind(removeTag, tags, setTags)} icon='Calendar' width='300px' />
      <Button onClick={() => addTag(tags, setTags)}>Add tag</Button>
    </Demo>
    <Demo name='With tags, icon, controlled'>
      <Input tags={tags} onTagClick={onTagClick} onTagCloseClick={removeTag.bind(removeTag, tags, setTags)} icon='Calendar' width='300px' value={value} onChange={(event:React.ChangeEvent<HTMLInputElement>) => setValue(event.currentTarget.value)} />
      <Button onClick={() => addTag(tags, setTags)}>Add tag</Button>
    </Demo>
    <Demo name='Various styles'>
      <Input m='10px' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer' />
    </Demo>
    <Demo name='Custom CSS'>
      <Input css={{
        ':hover': {
          border: 'solid 10px red'
        }}} placeholder='Mouse over me' />
    </Demo>
  </VBox>
}

function renderInputKind(name:InputKind) {
  return <HBox hItemSpace='15px' pb='15px' vAlign='center'>
    <Subtitle2 width='70px'>{startCase(name)}</Subtitle2>
    <Input kind={name} placeholder='Placeholder' />
    <Input kind={name} value='Value' onChange={() => {}} />
    <Input kind={name} icon='Calendar' placeholder='Placeholder' />
    <Input kind={name} icon='Calendar' value='Value' onChange={() => {}}/>
  </HBox>
}

function addTag(tags:string[], setTags:(tags:string[]) => void) {
  setTags([...tags, 'tag' + (tags.length + 1)])
}

function removeTag(tags:string[], setTags:(tags:string[]) => void, tag:string, tagNo:number) {
  tags = tags.slice();
  tags.splice(tagNo, 1);
  setTags(tags)
}

function onTagClick(tag:string, tagNo:number) {
  alert('You clicked on ' + tag);
}
