import { getFocusables } from './getFocusables';

export function isFocusable(elementOrNode:HTMLElement | Node) {
  if (!elementOrNode || elementOrNode.nodeType != Node.ELEMENT_NODE) {
    return false;
  }

  const element = elementOrNode as HTMLElement;
  const focusables = getFocusables(element.parentElement);

  return focusables.indexOf(element) != -1;
}

