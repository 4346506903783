import * as React from 'react'

import { PriceBreakdowns } from 'app2/api';
import { FieldInfo } from 'app2/components'
import { CoursePrices, CoursePricesProps } from 'app2/views/shared-public'

import { CourseWithBreakdowns, useCourseWithBreakdowns } from './useCourseWithBreakdowns';

interface Props extends CoursePricesProps {
  course:CourseWithBreakdowns;
  info?:FieldInfo<CourseWithBreakdowns>;
}

export function CoursePricesWithBreakdowns(props:Props) {
  const { info, ...remaining } = props;
  const {course, breakdowns} = useCourseWithBreakdowns(props.course || props.info?.record);

  return <CoursePrices {...remaining} course={course} breakdowns={breakdowns as PriceBreakdowns} />
}

CoursePricesWithBreakdowns.fieldProps = {
  fieldProperty: 'info'
}
