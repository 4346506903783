import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { OptionFieldsFragmentDoc } from '../../gql/generated/optionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseFilterOptionsQueryVariables = Types.Exact<{
  seasonId?: Types.InputMaybe<Types.Scalars['ID']>;
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  courseId?: Types.InputMaybe<Types.Scalars['ID']>;
  statuses: Array<Types.CourseStatus> | Types.CourseStatus;
  kinds: Array<Types.CourseKind> | Types.CourseKind;
  filterOptionKind: Types.CourseFilterOptionKind;
}>;

export type CourseFilterOptionsQuery = { seasonCourseFilterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined };

export const CourseFilterOptionsDocument = gql`
  query CourseFilterOptions($seasonId: ID, $companyId: ID, $courseId: ID, $statuses: [CourseStatus!]!, $kinds: [CourseKind!]!, $filterOptionKind: CourseFilterOptionKind!) {
    seasonCourseFilterOptions(seasonId: $seasonId, companyId: $companyId, courseId: $courseId, statuses: $statuses, kinds: $kinds, filterOptionKind: $filterOptionKind) {
      ...optionFields
    }
  }
  ${OptionFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CourseFilterOptionsArgs = MakeOptional<UseQueryArgs<CourseFilterOptionsQueryVariables, CourseFilterOptionsQuery>, 'query'>;

export function useCourseFilterOptionsQuery(options: CourseFilterOptionsArgs = {}) {
  return useQuery<CourseFilterOptionsQueryVariables, CourseFilterOptionsQuery>({ query: CourseFilterOptionsDocument, ...options });
}

export type CourseFilterOptionsOptions = ExecQueryOptions<CourseFilterOptionsQueryVariables>;

export function courseFilterOptions(options: CourseFilterOptionsOptions) {
  return executeQuery<CourseFilterOptionsQuery, CourseFilterOptionsQueryVariables>(CourseFilterOptionsDocument, options);
}

export type CourseFilterOptionsSelections = CourseFilterOptionsQuery['seasonCourseFilterOptions'][0];

(CourseFilterOptionsDocument as any).additionalTypenames = ['Option'];
