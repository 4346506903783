import * as React from 'react'

import { FilterOperator } from 'app2/api';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useAdminStudentsSearchQuery, AdminStudentsSearchSelections } from './generated';

export function StudentDropdown(props:SearchDropdownProps<AdminStudentsSearchSelections>) {
  function render() {
    return <SearchDropdown query={useAdminStudentsSearchQuery} variables={variables} renderItem={renderItem} {...props} />
  }

  function renderItem(item:AdminStudentsSearchSelections) {
    return `${item.name} (${item.id})`
  }

  function variables(term:string) {
    return [{name: 'name', op: FilterOperator.Contains, value:term}]
  }

  return render();
}
