import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  name:string;
}

interface Props {
  info?:FieldInfo<NameAndId>;
  family?:NameAndId;
}

export function FamilyLink(props:Props) {
  const family = props.family || props.info?.record;

  return <Link className='hr-value' text='body' to={`/admin2/families/${family?.id}`}>{family?.name}</Link>
}

FamilyLink.fieldProps = {
  fieldProperty: 'info',
}