import * as React from 'react'

import { DropdownBase, DropdownBaseProps, DropdownState, DropdownFocusType } from './DropdownBase'

export import PopupFocusType = DropdownFocusType;

export interface PopupProps extends DropdownBaseProps {
  trigger:React.ReactElement;
  dropdown?:React.ReactElement;
  children?:React.ReactElement;//dropdown and children serve the same purpose
  inline?:boolean;
  focusType?:DropdownFocusType;
  selectAllOnFocus?:boolean;
  sizeDropdownToTrigger?:boolean;
  onOpen?:() => void;
  onClose?:() => void;
  forceVisible?:boolean;
}

export class Popup extends DropdownBase<PopupProps> {
  static defaultProps = {
    inline: false,
    focusType: DropdownFocusType.dropdown,
    selectAllOnFocus: true,
    sizeDropdownToTrigger: false
  }

  constructor(props:PopupProps) {
    super(props);
    this.updateFromProps();
  }

  componentDidUpdate(prevProps: PopupProps, prevState: DropdownState): void {
      super.componentDidUpdate(prevProps, prevState);
      this.updateFromProps();
  }

  updateFromProps() {
    this.inline = this.props.inline;
    this.focusType = this.props.focusType;
    this.selectAllOnFocus = this.props.selectAllOnFocus;
    this.sizeDropdownToTrigger = this.props.sizeDropdownToTrigger;
    this.modal = this.props.modal;
    this.forceVisible = this.props.forceVisible;
  }

  renderTrigger(_:DropdownBaseProps):React.ReactElement {
    return this.props.trigger.props.onMouseDown
      ? this.props.trigger
      : React.cloneElement(this.props.trigger, {onMouseDown: this.toggleDropdown});
  }

  renderDropdown():React.ReactElement {
    return this.props.dropdown || this.props.children;
  }

  onOpen() {
    this.props.onOpen?.()
  }

  onClose() {
    this.props.onClose?.()
  }

  close() {
    this.open = false;
  }
}