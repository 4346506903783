import * as React from 'react';
import { useParams } from 'react-router';

import { Panel, Tabs, useQueryParam } from 'app2/components';

import { PrivatePage } from '../page';
import { studentTabs } from '../student';

export function Student() {
  const { id } = useParams<{id:string}>();
  const from = useQueryParam('from');

  function render() {
    return <PrivatePage title="Student" back={from == 'search' ? 'Back to search' : undefined}>
      <Panel>
        <Tabs tabs={studentTabs(id, true)} />
      </Panel>
    </PrivatePage>
  }

  return render();
}
