import * as React from 'react'
import pluralize from 'pluralize';

import { HBox, BoxProps } from './Box';
import { Icon, IconNames } from './icons';
import { useFormInfo } from './form';

export interface RatingProps extends BoxProps {
  rating:number;
  count?:number;
  units?:string;
  fill?:string;
  strokeWidth?:number;
  icon?:IconNames;
  editable?:boolean;
  onChange?:(rating:number) => void;
}

export function Rating(props:RatingProps) {
  const {rating, count, units, fill, strokeWidth, icon, editable:propsEditable, ...remaining} = props;
  const form = useFormInfo();
  const editable = propsEditable || form?.editing;

  function render() {
    const tooltip = count ? `Average ${rating}/5 from ${count} ${pluralize(units, rating)}` : undefined;

    return <HBox tooltip={tooltip} {...remaining}>
      {renderIcon(1, rating)}
      {renderIcon(2, rating - 1)}
      {renderIcon(3, rating - 2)}
      {renderIcon(4, rating - 3)}
      {renderIcon(5, rating - 4)}
    </HBox>
  }

  function renderIcon(value:number, amount:number) {
    const cursor = editable ? 'pointer' : undefined;

    if (amount <= 0) {
      return <Icon name={icon} strokeWidth={strokeWidth} cursor={cursor} onClick={() => onClick(value)} />
    }

    if (amount >= 1) {
      return <Icon name={icon} fill={fill} strokeWidth={strokeWidth} cursor={cursor} onClick={() => onClick(value)} />
    }

    const percentage = Math.round(amount * 100) + '%';
    const clipPath = `polygon(0 0, ${percentage} 0, ${percentage} 100%, 0% 100%)`;

    return <HBox position='relative' cursor={cursor} onClick={() => onClick(value)}>
      <Icon name={icon} fill={fill} clipPath={clipPath} strokeWidth={strokeWidth} />
      <Icon name={icon} position='absolute' strokeWidth={strokeWidth} />
    </HBox>
  }

  function onClick(value:number) {
    if (!editable) {
      return;
    }

    if (rating != value) {
      props.onChange(value);
    }
  }

  return render();
}

Rating.defaultProps = {
  units:'review',
  fill:'yellow',
  strokeWidth:1.5,
  icon:'Star'
}

Rating.fieldProps = {
  onChangeProperty: 'onChange',
  valueProperty: 'rating',
}
