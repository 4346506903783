import * as React from 'react';

import { CourseUtils, EnrollmentWithStudentName } from 'app2/api';
import { ActionButton, DataTable, Modal } from 'app2/components';

import { CourseSelections } from '../../../generated';

import { ReenrollModal } from './ReenrollModal';

interface Props {
  course: CourseSelections;
  table: DataTable<EnrollmentWithStudentName>;
}

export function ReenrollAction(props: Props) {
  function render() {
    const basicSeasonPricing = CourseUtils.usingBasicSeasonRateOrPrice(props.course?.prices) && !CourseUtils.usingInstallments(props.course)

    if (!basicSeasonPricing || !props.course?.behavior?.enrollmentsReenroll) {
      return <></>;
    }

    return <ActionButton icon="UserPlus" autoLoader={false} onClick={handleReenroll as any}>Reenroll</ActionButton>
  }

  function handleReenroll() {
    Modal.show(<ReenrollModal course={props.course} enrollments={props.table.selections.selectedItems} />);
  }

  return render();
}
