import * as React from 'react'
import { compact } from 'lodash-es'
import moment from 'moment'

import { Enrollment, PriceConfig, RecurringPriceConfig } from 'app2/api'
import { FieldInfo, formatCurrency, formatShortDate, Icon, Repeater, Text, VBox } from 'app2/components'
import { formatRecurringPrice } from 'app2/views/shared-public'

interface Props {
  info?:FieldInfo<Enrollment>;
}

export function ScheduleField(props:Props) {
  const enrollment = props.info.values[props.info.values.length - 2] as Enrollment;
  const discounted = Number.isFinite(enrollment.discountedPrice) && enrollment.listPrice != enrollment.discountedPrice
  const pendingChange = enrollment.pendingChange;

  function render() {
    const schedule = enrollment.rosterPeriods || enrollment.rosterPeriod;
    const tooltip = renderToolTip();

    return <Text text='body'>{schedule}{tooltip ? <Icon name='Info' size='small' tooltip={tooltip} ml='$4' /> : ''}</Text>;
  }

  function renderToolTip() {
    const tts = compact([renderDiscountTooltip(), renderPendingChangeToolTip(), renderLateStartToolTip(), renderUpdateDescription()]);

    return tts.length ? <Repeater items={tts} delimeter={<br />} />  : undefined;
  }

  function renderDiscountTooltip() {
    if (!discounted) {
      return;
    }

    return `Discounted price: ${formatCurrency(enrollment.discountedPrice)}`
  }

  function renderPendingChangeToolTip() {
    if (!pendingChange) {
      return;
    }

    const newConfig = pendingChange.priceConfig as RecurringPriceConfig;
    const fakePrice = {days: newConfig.weekdays.length, price: pendingChange.discountedPrice ?? pendingChange.listPrice, unit: newConfig.unit};

    return `Pending change - ${formatRecurringPrice(fakePrice, undefined, newConfig.weekdays)} taking effect on ${formatShortDate(pendingChange.startDate)}`
  }

  function renderLateStartToolTip() {
    if (!enrollment.lateStart || moment().isAfter(enrollment.startDate)) {
      return;
    }

    return `Start date: ${formatShortDate(enrollment.startDate)}`
  }

  function renderUpdateDescription() {
    const update = enrollment.priceConfigs?.find(config => (config as PriceConfig).updateDescription) as PriceConfig;

    if (!update) {
      return;
    }

    return `Update: ${update.updateDescription}`
  }

  return render();
}

ScheduleField.fieldProps = {
  fieldProperty: 'info'
}
