import { ApiResponse, standardizeAxiosApiError } from "../error";

import { axios } from './axios';

interface Args {
  'access-token':string;
  client:string;
  client_id:string;
  uid:string;
  password:string;
  password_confirmation:string;
}

export async function resetPassword(args:Args):Promise<ApiResponse> {
  try {
    const response = await axios.put('/auth/password', args)

    return {success: true, errors: []};
  }
  catch(e) {
    return standardizeAxiosApiError(e)
  }
}
