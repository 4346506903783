import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateProviderMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  attributes: Types.VendorInput;
}>;

export type CreateProviderMutation = { vendorCreate?: { vendor?: { id: string } | null | undefined } | null | undefined };

export const CreateProviderDocument = gql`
  mutation CreateProvider($siteId: ID!, $attributes: VendorInput!) {
    vendorCreate(siteId: $siteId, attributes: $attributes) {
      vendor {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CreateProviderOptions = ExecMutationOptions<CreateProviderMutationVariables>;

export function createProvider(options: CreateProviderOptions) {
  return executeMutation<CreateProviderMutation, CreateProviderMutationVariables>(CreateProviderDocument, options);
}
