import * as React from 'react'
import { useParams } from 'react-router-dom';

import { Message, MessageThread, DeepPartial, MessageServiceKind } from 'app2/api';
import { Box, DateText, Form, HBox, Field, Link, Scalable, Text, VBox, LONG_DATE_FORMAT, TIME_FORMAT } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';

import { useMessageQuery } from './generated'
import { findQuoteLocation } from './findQuoteLocation';
import { MessageParticipants } from './MessageParticipants';
import { useMessageRecipients } from './useMessageRecipients';

interface Props {
  message?:DeepPartial<Message>;
  primaryActions?:React.ReactNode;
  kind?: 'email' | 'sms';
  // the previously rendered message (the one above this one) if shown in a thread
  // this is used for dealing with the mini threads
  previous?:DeepPartial<Message>;
  thread?:DeepPartial<MessageThread>;
  onClickStudent?:(id:string) => void;
}

export function Message(props:Props) {
  const message = props.message || getMessage();
  const {messageInfo, expanded, setExpanded} = getMessageExpandedState(message);
  const recipients = useMessageRecipients(message);
  const {user, fromCurrent, sms} = getMessageSmsState(message);
  const kind = props.kind || (sms ? 'sms' : 'email');

  function render() {
    // dont render until we have the user loaded else the messages
    // will go from being by someone else to the current user in the
    // the ui, because the user isn't known yet
    if (!user) {
      return <></>
    }

    return renderSms()
  }

  function renderSms() {
    return !props.previous || !message.inReplyTo || props.previous.id == message.inReplyTo.id || !props.thread
      ? renderCurrentSms()
      : <>
        {renderInReplyTo()}
        {renderCurrentSms()}
      </>
  }

  function renderInReplyTo() {
    const repyTo = props.thread?.messages?.find(m => m.id == message.inReplyTo.id);

    if (!repyTo) {
      return;
    }

    return <Form initialValues={{repyTo}} position='relative' onNavigation='nothing' overflow='visible' hAlign='left' pr={0} mr={0} flex={0}>
      <Scalable scale={.7}>
        <VBox width='100%' hAlign='left' gap='$8' css={fromCurrent ? inReplyTailCurrent : inReplyTailOther}>
          <Box maxWidth='100%' width='fit-content' borderRadius='25px' padding='$12' css={inReplyBubbleCss}>
            {renderContent('disabled', repyTo.body.html || repyTo.body.text, !!repyTo.body.html, .7)}
          </Box>
          <Box width='100%' textAlign='left'><Field name="repyTo.from.name" />, <Field name="previous.createdAt" component={DateText} /></Box>
        </VBox>
      </Scalable>
    </Form>
  }

  function renderCurrentSms() {
    return <Form<typeof messageInfo> initialValues={messageInfo} position='relative' onNavigation='nothing' overflow='visible' hAlign={fromCurrent ? 'right' : 'left'} pr={0} mr={0} flex={0}>
      <VBox width='100%' hAlign={fromCurrent ? 'right' : 'left'} gap='$8'>
        <VBox maxWidth='100%' width='fit-content' borderRadius='25px' padding='$12' css={fromCurrent ? rightBubbleCss : leftBubbleCss} gap='$8'>
          {message.systemMessage && <Text text='body'>{message.subject}</Text>}
          {renderBody()}
        </VBox>
        <Box width='100%' textAlign={fromCurrent ? 'right' : 'left'}><MessageParticipants participants={[messageInfo.from]} onClickStudent={props.onClickStudent} display='inline-block' />, <Field name="createdAt" component={DateText} dateFormat={LONG_DATE_FORMAT + ' ' + TIME_FORMAT} /></Box>
        {props.primaryActions}
      </VBox>
    </Form>
  }

  function renderBody() {
    return !messageInfo.visible || expanded 
        ? renderExpanded()
        : renderCollapsed()
  }

  function renderExpanded() {
    return renderContent(fromCurrent ? 'white' : 'black', messageInfo.body.html || messageInfo.body.text, !!messageInfo.body.html);
  }

  function renderCollapsed() {
    return <>
      {renderContent(fromCurrent ? 'white' : 'black', messageInfo.visible, !!messageInfo.body.html)}
      {showMore()}
    </>
  }

  function renderContent(color:string, content:string, html:boolean, parentScale?:number) {
    return !html
      ? <Text text='body' whiteSpace='pre-wrap' wordBreak='break-word' color={kind == 'sms' ? color : undefined}>{content}</Text>
      : <Scalable scale='100%' maxScale={1} parentScale={parentScale}><VBox hAlign='left' width='fit-content' borderRadius='25px' overflow='hidden'><span dangerouslySetInnerHTML={{__html:content}} /></VBox></Scalable>
  }

  function showMore() {
    return <HBox mt='$8'><Link color={fromCurrent ? 'white' : 'black'} onClick={() => setExpanded(true)}>Show more</Link></HBox>
  }

  function getMessage() {
    const { message:id } = useParams<{message:string}>();
    const [result] = useMessageQuery({variables:{id}});

    return result.data?.msg;
  }

  function getMessageExpandedState(current:DeepPartial<Message>) {
    const messageInfo = React.useMemo(() => {
      const content = current.body.html || current.body.text;
      const quoteLocation = findQuoteLocation(content);
      
      return {
        ...current,
        visible: quoteLocation === -1 ? null : content.substring(0, quoteLocation)
      }
    }, [current]);

    const [expanded, setExpanded] = React.useState(false);

    return {messageInfo, expanded, setExpanded}
  }

  function getMessageSmsState(message:DeepPartial<Message>) {
    const { user } = useCurrentUser();
    const fromCurrent = message?.from?.from?.id == user?.id;
    const sms = message.preferredService == MessageServiceKind.Sms;

    return {user, fromCurrent, sms}
  }

  return render();
}

const singlePaperShadow = '-6px 3px 14px 1px rgba(0,0,0,0.1)';
const multiplePaperShadow = `
  0 -1px 1px rgba(0,0,0,0.15),
  0 -10px 0 -5px #eee,
  0 -10px 1px -4px rgba(0,0,0,0.15),
  0 -20px 0 -10px #eee,
  0 -20px 1px -9px rgba(0,0,0,0.15),
  ${singlePaperShadow}
`

const bubbleCss:any = {
  position: 'relative',
  '::before,::after': {
    position: 'absolute',
    bottom: 0,
    height: '25px',
    content: "''"
  },
}

const receivedColor = '#d7d7dd';
const leftBubbleCss:any = {
  ...bubbleCss,
  color:'#000',
  bg:receivedColor,
  '::before': {
    left: '-7px',
    width: '20px',
    backgroundColor: receivedColor,
    borderBottomRightRadius: '16px 14px'
  },
  '::after': {
    left: '-26px',
    width: '26px',
    backgroundColor:'formBackground',
    borderBottomRightRadius: '10px'
  }
}

const inReplyBubbleCss:any = {
  ...leftBubbleCss,
  bg:'#ffffff',
  '::before': {
    ...leftBubbleCss['::before'],
    bg:'#ffffff',
  }
}

const inReplyTailCurrent:any = {
  position: 'relative',
  '::before': {
    content: "''",
    position: 'absolute',
    bottom: '-60px',
    left: '40px',
    width: '50px',
    height: '50px',
    borderBottom: '4px solid #C8C8C8',
    borderLeft: '4px solid #C8C8C8',
    borderBottomLeftRadius: '30px'
  }
}

const inReplyTailOther:any = {
  position: 'relative',
  '::before': {
    content: "''",
    position: 'absolute',
    bottom: '-60px',
    left: '40px',
    width: '50px',
    height: '50px',
    borderLeft: '4px solid #C8C8C8',
  }
}

const sentColor = '#0b93f6';
const rightBubbleCss:any = {
  ...bubbleCss,
  marginRight:'10px',
  color:'#fff',
  bg:sentColor,
  '::before': {
    right: '-7px',
    width: '20px',
    backgroundColor: sentColor,
    borderBottomLeftRadius: '16px 14px'
  },
  '::after': {
    right: '-26px',
    width: '26px',
    backgroundColor:'formBackground',
    borderBottomLeftRadius: '10px'
  }
}
