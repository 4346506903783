import * as React from 'react';
import { cloneDeep } from 'lodash-es';

import { CourseUtils, Course, SeasonRate } from 'app2/api';
import { Part, Section } from 'app2/components';
import { useCourseFields } from 'app2/views/shared';

import { ActivityForm } from '../ActivityForm';

interface Props {
  editing: boolean;
  form: ActivityForm;
}

export function PaymentSchedule(props: Props) {
  const form = props.form;
  const course = (form?.values as unknown) as Course;
  const seasonPricing = CourseUtils.usingSeasonRateOrPrice(course?.rates);
  const activityFields = useCourseFields({site:course?.site?.id, course}).fields;

  if (!seasonPricing) {
    return <></>;
  }

  function render() {
    return (
      <Section label="Payment schedule">
        <Part {...activityFields['rates.season.depositAmount']} onChange={handleDepositChange} />
        <Part {...activityFields['rates.season.installmentDates']} infoTip="Balance is split equally across installments" onChange={handleInstallmentDatesChange} />
      </Section>
    );
  }

  function updateSeasonsRates(cb: (rate: SeasonRate) => void) {
    if (!form.values.rates || !form.values.rates.seasons) {
      return;
    }

    const rates = cloneDeep(form.values?.rates);

    rates.seasons.forEach(cb);

    form.setValues({ rates });
  }

  function handleDepositChange(deposit: number) {
    updateSeasonsRates(rate => {
      rate.depositAmount = deposit;
    });
  }

  function handleInstallmentDatesChange(installmentDates: string[]) {
    updateSeasonsRates(rate => {
      rate.installmentDates = installmentDates;
    });
  }

  return render();
}
