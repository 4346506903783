import * as React from 'react';

import { useLifecycle, useForceUpdate } from '../../utils';

import { DataTable, DataTableProps } from '../DataTable';
import { colId, DataTableColumn } from '../column/DataTableColumn';

import { GroupByHeader, groupByHeaderHeight } from './GroupByHeader';
import { groupBy, GroupByClosedPaths, toggleOpenClosedState } from './groupBy';

export interface GroupedDataTable2Props<T = any> extends DataTableProps<T> {
  groupBy?:(string | DataTableColumn<T>)[];
  groupHeader?:React.ComponentType<any>;
  groupHeaderHeight?:number;
}

export const GroupedDataTable2 = React.forwardRef((props:GroupedDataTable2Props<any>) => {
  const {groupHeader:GroupHeader, groupHeaderHeight, cols:propsCols, groupBy:propsGroupBy, ...remaining} = props;
  const {cols, groupByCols} = getCols();
  const [closedPaths, setClosedPaths] = React.useState<GroupByClosedPaths>({});
  const grouped = getGroups();
  const table = props.ref as React.MutableRefObject<DataTable> || React.useRef<DataTable>();

  const usingGroupBy = props.groupBy && grouped;

  // we need an extra render to get the table refs that the group headers need
  const nullRefs = usingGroupBy && !table.current;
  const forceUpdate = useForceUpdate();
  useLifecycle({onUpdate});

  function render() {
    return !usingGroupBy
      ? <DataTable ref={table} cols={cols} {...remaining} />
      : renderGrouped()
  }

  function renderGrouped() {
    return <DataTable {...remaining} ref={table} group={{state:'group-state', count: 'count', value: 'value', onToggleState, height: 38, bg:'primary', color:'#fff'}} cols={cols} data={grouped} minHeight={null} />
  }

  function getCols() {
    return React.useMemo(() => {
      // if the input of grouped cols are strings, then this converts them to columns
      // it also filters out invalid columns 
      const groupByCols = propsGroupBy?.map(groupBy => props.cols.find(col => colId(col) == colId(groupBy))).filter(col => !!col) || [];

      // removes any grouping columns from the column set
      const cols = props.cols.filter(col => !groupByCols.find(groupCol => colId(groupCol) == colId(col)));

      return {cols, groupByCols};
    }, [propsGroupBy]);
  }

  function getGroups() {
    return React.useMemo(() => {
      if (!props.groupBy?.length) {
        return;
      }

      const all = groupBy(props.data as any[], groupByCols.map(c => colId(c)), {flattenGroups:true, closedPaths});

      return all
    }, [props.data, closedPaths]);
  }

  function onUpdate() {
    if (nullRefs) {
      forceUpdate();
    }
  }

  function onToggleState(row:any) {
    setClosedPaths(toggleOpenClosedState(closedPaths, row));
  }

  return render();
})

GroupedDataTable2.defaultProps = {
  groupHeader: GroupByHeader,
  groupHeaderHeight: groupByHeaderHeight,
}

