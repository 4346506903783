import { generatePath as rrGeneratePath } from 'react-router-dom';

// an exception safe versoin of generatePath

export function generatePath(route:string, params:any) {
  try {
    return rrGeneratePath(route, params);
  } 
  catch(e) {
    return '';
  }
}


