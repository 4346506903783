import * as React from 'react';

import { MultiContext, useLifecycle } from 'app2/components';

export interface PageContext {
  page:{
    // by default this is true
    // if true, it mean the page will manage the scrollability
    // of the content area and allow scrolling. 
    // if false, page will constrict the content pane to the height
    // of the visible area and the content can define its own scrolling area.
    // use useScrollablePage to change this
    scrollable:boolean;
    setScrollable:(scrollable:boolean) => void;
  }
}

export function useScrollablePage(scrollable?:boolean) {
  scrollable = scrollable === false ? false : true;
  const page = React.useContext<PageContext>(MultiContext).page;

  if (page && page.scrollable != scrollable) {
    page.setScrollable(scrollable);
  }

  useLifecycle({onUnmount})

  function onUnmount() {
    if (!scrollable) {
      page.setScrollable(true);
    }
  }

  return page;
}
