import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProviderUpsertTeachersMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  teachers: Array<Types.TeacherInput> | Types.TeacherInput;
}>;

export type ProviderUpsertTeachersMutation = {
  companyUpsertTeachers?:
    | { company?: { id: string; teachers?: Array<{ id: string; name: string; email?: string | null | undefined; phone?: string | null | undefined }> | null | undefined } | null | undefined }
    | null
    | undefined;
};

export const ProviderUpsertTeachersDocument = gql`
  mutation ProviderUpsertTeachers($company: ID!, $teachers: [TeacherInput!]!) {
    companyUpsertTeachers(company: $company, teachers: $teachers) {
      company {
        id
        teachers {
          id
          name
          email
          phone
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ProviderUpsertTeachersOptions = ExecMutationOptions<ProviderUpsertTeachersMutationVariables>;

export function providerUpsertTeachers(options: ProviderUpsertTeachersOptions) {
  return executeMutation<ProviderUpsertTeachersMutation, ProviderUpsertTeachersMutationVariables>(ProviderUpsertTeachersDocument, options);
}
