import * as React from 'react';

import { Button, CheckboxField, CurrencyField, DateField, Dropdown, EmailField, Field, FieldRendererProps, floatParser, Form, FormContent, Input, InputField, List, Panel, Part, PhoneField, RadioGroup, Section, Text, TextAreaField, TimeField, formatCurrency, VBox } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function FormDemos() {
  return <Demos name='Form'
    description={`
    Form and Field are the building blocks for forms.  Both are UI components that have corresponding "models".

    These are low-level building blocks for creating forms.  See <a href='/admin/component-demos/panel'>panel demos</a> for 
    the higher level components to use to create forms.

    - FormModel
      - contains the form definition/schema (fields and validations)
      - contains form values
      - runs form validations as needed
      - overall manages the form state
    
    - FieldModel
      - contains the definition of a field
      - the definition includes field name and validation scripts
      - field models can contain other field models (for when dealing with a form that has nested or repeated (array) structures)

    - Form
      - renders an HTML form tag
      - creates a form model as needed (or uses the one passed into it)

    - FormContent
      - renders a section of the form with an optional title
      - if there's more than one in the form each section has a border

    - Field
      - renders a UI component that is bound to a form model
      - optionally creates a field model as needed
      - does not render labels or error state (other than what the component will render), use Panel for that
      - will call the passed in render function so you can customize has the field is rendered with a label and error information
    `}>

    <Demo name='Basic' description={`A form with a field.`}>
      <Form>
        <Field name='name' component={Input} />
      </Form>
    </Demo>

    <Demo name='Inline definition' description={`
      FieldModel's can be defined inline in the Field.  Here a validation function is added to the input.  Enter a number bigger than 10
      and it will fail validation (note you must move the focus out of the input for it to show an error).
    `}>
      <Form>
        <Field name='name' required validators={[(value) => Number(value) > 10 ? 'Number must be 10 or less' : false]} component={Input} />
      </Form>
    </Demo>

    <Demo name='Duplicate bound fields' description={`
      Fields can be bound to the same field.  In this case an input and label are bound to the same field.  Type in the input and it shows in the label.
    `}>
      <Form>
        <Field name='name' component={Input} />
        <Field name='name' />
      </Form>
    </Demo>

    <Demo name='Field components' description={`
      Fields work with any component that supports the HTML standard value property and onChange and onBlur events.  Field has properties
      to help adapt components.  By default FormValue is used.  Field "binds" the form fields value to field's component.

      Additionally you can pass component properties by specifying them on the field.  In this case the text property is passed to the
      Text component. All of these properties can be combined into a single object, so they can be re-used.
    `}>
      <Form>
        <Field name='name' component={Input} />
        <Field<Text> name='name' component={Text} valueProperty='children' text='heading2' />
      </Form>
    </Demo>

    <Demo name='Render function' description={`
      Fields can have a custom render function that can be used to render validation errors (don't forget to remove the focus from the control
      to see the error).
    `}>
      <Form>
        <Field name='name' component={Input} validators={() => 'Bad input'} render={(props:FieldRendererProps) => {
          return <VBox>
            {props.children}
            {props.info.errors}
          </VBox>
        }}/>
      </Form>
    </Demo>

    <Demo name='All controls'>
      <Panel type='edit' onOk={form => console.log('form values', form.values)} width={400}>
        <Section required label='Checkbox' name='checkbox' component={CheckboxField} />
        <Section required label='Currency' name='currency' component={CurrencyField} />
        <Section required label='Date' name='date' component={DateField} />
        <Section required label='Dropdown' name='dropdown' component={Dropdown} options={['red', 'yellow', 'green']} />
        <Section required label='Email' name='email' component={EmailField} />
        <Section required label='Input' name='input' component={InputField} />
        <Section required label='List' name='list' component={List} options={['red', 'yellow', 'green']} />
        <Section required label='Phone' name='phone' component={PhoneField} />
        <Section required label='RadioGroup' name='radio' component={RadioGroup} options={[
          {label:'0-18', value:'0-18'},
          {label:'19-30', value:'19-30'},
          {label:'30-50', value:'30-50'},
          {label:'51+', value:'51+'},
          {label:'Other', value:'other', inputName:'other'},
          {label:'Prefer not to say', value:'argumentative', input: <Part label='Enter a reason why' name='reason' component={InputField} />},
          {label:'Another way of adding an input', value:'another', input: <Field name='another' component={InputField} required />},
          {label:'One last way of adding an input', value:'oneLastWay', input: {name: 'oneLastWay', label:'Label for the input (but is not required)', ...CurrencyField }},
        ]} />
        <Section required label='TextArea' name='textArea' component={TextAreaField} />
        <Section required label='Time' name='time' component={TimeField} />
      </Panel>
    </Demo>

    <Demo name='Multiple sections' description={`A form with multiple sections.`}>
      <Form>
        <FormContent title='Name' icon='DollarSign'>
          <Field name='name' component={Input} />
        </FormContent>
        <FormContent title='Contacts' icon='Phone'>
          <Field name='name' component={Input} />
        </FormContent>
      </Form>
    </Demo>

    <Demo name='None - standard' description={`Demonstrates how to use the none attribute with a standard none message - none is only used when in form display mode`}>
      <Form editing={false}>
        <Section required label='Enter something' name='noneTest' none={false} display={Input} />
        <Section required label='None or something' name='noneTest' none />
      </Form>
    </Demo>

    <Demo name='None - custom message' description={`Demonstrates how to use the none attribute with a custom none message`}>
      <Form editing={false}>
        <Section required label='Enter something' name='noneTest' none={false} display={Input} />
        <Section required label='None or something' name='noneTest' none='Enter a value' />
      </Form>
    </Demo>

    <Demo name='None - custom component' description={`Demonstrates how to use the none attribute with a custom none react node`}>
      <Form editing={false}>
        <Section required label='Enter something' name='noneTest' none={false} component={Input} />
        <Section required label='None or something' name='noneTest' none={<Button>Custom none</Button>} />
      </Form>
    </Demo>
  </Demos>
}
