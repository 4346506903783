import * as React from 'react'
import * as qs from 'query-string';
import { useLocation } from 'react-router';

import { BaseAcceptInvite } from '../../shared'

import { companyInviteAccept } from './generated';

export function CompanyAcceptInvite() {
  const location = useLocation();
  const { invitor, company}  = qs.parse(location.search);
  const who = invitor ? `${invitor} has invited you` : "You've been invited";
  const message = `Welcome! ${who} to join ${company || 'a provider'}.`

  return <BaseAcceptInvite title='Provider team member invite' message={message} mutation={companyInviteAccept} onSuccess='/' />
}
