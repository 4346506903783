import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerImageSearchQueryVariables = Types.Exact<{
  folder: Types.Scalars['String'];
}>;

export type OrganizerImageSearchQuery = {
  imageSearch?:
    | Array<{
        publicId?: string | null | undefined;
        filename?: string | null | undefined;
        tags?: Array<string> | null | undefined;
        url?: string | null | undefined;
        height?: number | null | undefined;
        width?: number | null | undefined;
        aspectRatio?: number | null | undefined;
      }>
    | null
    | undefined;
};

export const OrganizerImageSearchDocument = gql`
  query OrganizerImageSearch($folder: String!) {
    imageSearch(folder: $folder) {
      publicId
      filename
      tags
      url
      height
      width
      aspectRatio
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerImageSearchArgs = MakeOptional<UseQueryArgs<OrganizerImageSearchQueryVariables, OrganizerImageSearchQuery>, 'query'>;

export function useOrganizerImageSearchQuery(options: OrganizerImageSearchArgs = {}) {
  return useQuery<OrganizerImageSearchQueryVariables, OrganizerImageSearchQuery>({ query: OrganizerImageSearchDocument, ...options });
}

export type OrganizerImageSearchOptions = ExecQueryOptions<OrganizerImageSearchQueryVariables>;

export function organizerImageSearch(options: OrganizerImageSearchOptions) {
  return executeQuery<OrganizerImageSearchQuery, OrganizerImageSearchQueryVariables>(OrganizerImageSearchDocument, options);
}

export type OrganizerImageSearchSelections = OrganizerImageSearchQuery['imageSearch'][0];

(OrganizerImageSearchDocument as any).additionalTypenames = ['Image'];
