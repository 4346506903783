import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TableViewRemoveMutationVariables = Types.Exact<{
  entityKind: Types.EntityKind;
  entityId: Types.Scalars['ID'];
  view: Types.Scalars['ID'];
}>;

export type TableViewRemoveMutation = {
  tableViewRemove?: { entity?: { tableViews?: Array<{ id: string }> | null | undefined } | { tableViews?: Array<{ id: string }> | null | undefined } | null | undefined } | null | undefined;
};

export const TableViewRemoveDocument = gql`
  mutation tableViewRemove($entityKind: EntityKind!, $entityId: ID!, $view: ID!) {
    tableViewRemove(entityKind: $entityKind, entityId: $entityId, view: $view) {
      entity {
        ... on WithTableViews {
          tableViews {
            id
          }
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TableViewRemoveOptions = ExecMutationOptions<TableViewRemoveMutationVariables>;

export function tableViewRemove(options: TableViewRemoveOptions) {
  return executeMutation<TableViewRemoveMutation, TableViewRemoveMutationVariables>(TableViewRemoveDocument, options);
}
