import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteBeforeActivityPoliciesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSiteBeforeActivityPoliciesQuery = {
  site?: { policy?: { id: string; teacherCheckin?: string | null | undefined; studentPickup?: string | null | undefined; missingStudent?: string | null | undefined } | null | undefined } | null | undefined;
};

export const OrganizerSiteBeforeActivityPoliciesDocument = gql`
  query OrganizerSiteBeforeActivityPolicies($siteId: ID!) {
    site(site: $siteId) {
      policy {
        id
        teacherCheckin
        studentPickup
        missingStudent
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSiteBeforeActivityPoliciesArgs = MakeOptional<UseQueryArgs<OrganizerSiteBeforeActivityPoliciesQueryVariables, OrganizerSiteBeforeActivityPoliciesQuery>, 'query'>;

export function useOrganizerSiteBeforeActivityPoliciesQuery(options: OrganizerSiteBeforeActivityPoliciesArgs = {}) {
  return useQuery<OrganizerSiteBeforeActivityPoliciesQueryVariables, OrganizerSiteBeforeActivityPoliciesQuery>({ query: OrganizerSiteBeforeActivityPoliciesDocument, ...options });
}

export type OrganizerSiteBeforeActivityPoliciesOptions = ExecQueryOptions<OrganizerSiteBeforeActivityPoliciesQueryVariables>;

export function organizerSiteBeforeActivityPolicies(options: OrganizerSiteBeforeActivityPoliciesOptions) {
  return executeQuery<OrganizerSiteBeforeActivityPoliciesQuery, OrganizerSiteBeforeActivityPoliciesQueryVariables>(OrganizerSiteBeforeActivityPoliciesDocument, options);
}

export type OrganizerSiteBeforeActivityPoliciesSelections = OrganizerSiteBeforeActivityPoliciesQuery['site'];
