import * as React from 'react'
import { useParams } from 'react-router-dom';

import { HBox, VBox, BoxProps } from '../Box';
import { Icon, IconNames } from '../icons';
import { Link } from '../Link';
import { MenuItemProps } from '../menu';
import { Subtitle1 } from '../Text';
import { convertElements, useStateWithDeps, useLifecycle } from '../utils';
import { useHistory, generatePath } from '../history';
import { scrollIntoView } from '../dom-utils';

import { NavigationLink } from './NavigationLink';

export interface NavigationSectionProps extends BoxProps {
  name?:string;
  label?:string;
  icon?:IconNames;
  to?:string;
  open?:boolean;
  onOpen?:(open:boolean) => void;
  children?:React.ReactElement<MenuItemProps>[];
}

export function NavigationSection(props:NavigationSectionProps) {
  let {name, label, icon, to, open:propsOpen, onOpen:propsOnOpen, children, ...remaining} = props;
  const [openState, setOpen] = useStateWithDeps(propsOpen ?? true, [propsOpen]);

  const params = useParams();
  const history = useHistory();
  const selected = React.useMemo(() => children.findIndex(child => history.location.pathname.indexOf(generatePath(child.props.to, params)) != -1), [history.location.pathname, children]);
  const open = openState || selected != -1;

  children = convertElements<MenuItemProps>(children, Link, NavigationLink);
  const hasChildren = children.length > 0;

  const links = React.useRef<HTMLDivElement>(null);
  useLifecycle({onUpdate});

  function render() {
    return <VBox {...remaining}>
      <Link to={to} underline={false}>
        <HBox vAlign='center' cursor='pointer' onClick={onOpen}>
          {icon && <Icon name={icon} size='medium' strokeWidth={1} pr='8px' color='navigationLink' />}
          <Subtitle1 pr='$12' color='navigationLink'>{name || label}</Subtitle1>
          {hasChildren && (open 
            ? <Icon name="DropdownClose" size='medium' color='navigationLink' /> 
            : <Icon name="DropdownOpen" size='medium' color='navigationLink' />)}
        </HBox>
      </Link>
      {open && <VBox ref={links} pt={['$20', '$20', '$12']} vItemSpace={['$20', '$30', '$12']} pl={icon ? '28px' : undefined}>{children}</VBox>}
    </VBox>
  }

  function onUpdate() {
    if (selected == -1) {
      return;
    }

    setTimeout(() => {
      if (!links.current || !links.current.children || !links.current.children[selected]) {
        return;
      }

      scrollIntoView(links.current.children[selected] as HTMLElement)
    }, 1);
  }

  function onOpen() {
    if (!hasChildren) {
      return;
    }

    if (propsOnOpen) {
      propsOnOpen(!open);
    }
    else {
      setOpen(!open);
    }
  }

  return render();
}
