import * as React from 'react';
import { useParams } from 'react-router';

import { ComplianceDefinitionInput, ComplianceDefinitions, ComplianceDefinition } from 'app2/api';
import { Field, FormModel, InputField, Link, Modal, Panel, RepeatingSection, SaveableResult, Section, TextAreaField } from 'app2/components'
import { addDeletedItems, arrayHasUpdates, FileUploads } from 'app2/views/shared'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerComplianceDefinitionsQuery } from './gql'
import { organizerUpsertComplianceDefinitions, OrganizerComplianceDefinitionsSelections } from './gql'

type Definitions = OrganizerComplianceDefinitionsSelections['complianceDefinitions'];

export function Compliance() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ complianceDefinitionsQueryResult ] = useOrganizerComplianceDefinitionsQuery({variables: { siteId }});
  const originalDefinitions = React.useMemo(() => complianceDefinitionsQueryResult.data?.site?.complianceDefinitions, 
    [complianceDefinitionsQueryResult.data?.site?.complianceDefinitions])

  function render() {
    return <Panel icon='Shield' title='Compliance checklist' type='toggle' initialValues={originalDefinitions} onOk={onOk}
        subtitle={<>Create requirements below and track in your <Link text='subtitle2' to='/organizer/providers'>Providers tab</Link></>}>
      <Section label='Provider compliance requirements (i.e. School contracts, W9, Certificates of insurance)'>
        {renderComplianceFields('vendor')}
      </Section>
      <Section label='Instructor compliance requirements (i.e. Background checks, TB tests, Livescan fingerprints)'>
      {renderComplianceFields('teacher')}
      </Section>
    </Panel>
  }

  function renderComplianceFields(name:string) {
    return <RepeatingSection name={name} add='Add requirement' maxCols={0} defaultRecord={{complianceType: name}} fields={[
      <Field label='Requirement name' name='name' required component={InputField} />,
      <Field label='Instructions' name='instructions' component={TextAreaField} />,
      <Field label='Files' name='downloads' component={FileUploads} />,
      'remove'
    ]} />
  }

  async function onOk(form:FormModel<ComplianceDefinitions>) {
    const updatedDefinitions = form.values;
    const complianceDefinitions = {
      'vendor': addDeletedItemsToDefinitions(originalDefinitions.vendor, updatedDefinitions.vendor),
      'teacher': addDeletedItemsToDefinitions(originalDefinitions.teacher, updatedDefinitions.teacher)
    }

    const allowTitleChange = await warnAboutTitleChanges(complianceDefinitions.vendor, complianceDefinitions.teacher);

    if (!allowTitleChange) {
      return false;
    }

    const variables = {siteId, complianceDefinitions}
    const [success] = await organizerUpsertComplianceDefinitions({variables, successMsg: "Compliance Definitions updated successfully.",error: form});

    return success;
  }

  async function warnAboutTitleChanges(vendor:ComplianceDefinitionInput[], teacher:ComplianceDefinitionInput[]) {
    const hasTitleChanges = arrayHasUpdates(vendor, originalDefinitions.vendor, ['name']) || arrayHasUpdates(teacher, originalDefinitions.teacher, ['name']);
    let allow = true;
    
    if (hasTitleChanges) {
      const result = await Modal.warning('Compliance name change', 'You are renaming the title of one the compliance requirements.  Note that changing the name will not change existing responses.  Please make sure the new name still applies to the existing responses.', true);
      allow = result.action == SaveableResult.ok;
    }

    return allow;
  }

  function addDeletedItemsToDefinitions(originalDefinitions:ComplianceDefinition[], updatedDefinitions:ComplianceDefinition[]) {
    const updatedDefinitionsWithDeletedDownloads = updatedDefinitions.map(updatedDefinition => {
      const originalDefinition = originalDefinitions.find(item => item.id == updatedDefinition.id);
      return {
        ...updatedDefinition,
        downloads: addDeletedItems(originalDefinition?.downloads || [], updatedDefinition.downloads || [],"archived")
      }
    });

    return addDeletedItems(originalDefinitions, updatedDefinitionsWithDeletedDownloads, "archived") as ComplianceDefinitionInput[]
  }

  return render();
}
