import * as React from 'react';
import * as ReactIs from 'react-is';

import  { Breakpoints, BreakpointInfo } from '../theme';

import  { MultiContext } from '../utils/MultiContextProvider';

interface Props {
  children?:React.ReactNode;
}

export function createBreakpointComponent(name:string, breakpoints:Breakpoints | Breakpoints[]) {
  const breakpointsSet = new Set(Array.isArray(breakpoints) ? breakpoints : [breakpoints]);

  function BreakpointComponent(props:Props) {
    const context = React.useContext<BreakpointInfo>(MultiContext);
    const breakpoint = context.breakpoint;

    return !breakpointsSet.has(breakpoint)
      ? <></>
      : ReactIs.isElement(props.children)
        ? props.children
        : <>{props.children}</>
  }

  BreakpointComponent.displayName = name;

  return BreakpointComponent;
}
