import * as React from 'react'

import { CourseFilterOptions, CourseUtils, DayCourses, GroupedCourses } from 'app2/api';
import { FormModel, hasAppMode, HBox, VBox, Text, Body } from 'app2/components';
import { Cart, CourseCard, CourseCardCourse, CourseKindBehavior, courseKindBehavior } from 'app2/views/shared-public'

import { SeasonHeader } from './SeasonHeader'
import { PublicSiteSelections } from './generated';
import { PublicSeason } from './PublicSeason';

type Course = PublicSeason['courses'][0];

interface Props {
  filterOptions:FormModel<CourseFilterOptions>;
  site:PublicSiteSelections;
  season:PublicSeason;
  courses:Course[];//filtered courses, not the same as season.courses
  cart:Cart;
}

export function Season(props:Props) {
  const byDay = props.season.approvedCourseKindGroups.some(kind => courseKindBehavior[kind].registration?.group == 'day');
  const sort:CourseKindBehavior['registration']['sort'] = byDay ? 'time' : courseKindBehavior[props.season.approvedCourseKindGroups[0]].registration?.sort;
  const tile = props.season.approvedCourseKindGroups.some(kind => courseKindBehavior[kind].registrationLayout == 'tile');
  const hideHeader = hasAppMode('embed') && !hasAppMode('season-header');

  function render() {
    return <VBox width='100%'>
      {props.site?.partner && !hideHeader && <SeasonHeader site={props.site} season={props.season} />}
      {renderNone()}
      {renderCourses()}
    </VBox>
  }

  function renderNone() {
    if (!props.season.courses.length) {
      return <Text text='subtitle2' mb='$8'>There are no enrolling activities yet</Text>
    }

    if (!props.courses.length) {
      return <Text text='subtitle2' mb='$8'>There are no activities that match your filters</Text>
    }

    return;
  }

  function renderCourses() {
    if (!props.courses?.length) {
      return;
    }

    return props.filterOptions.values.byGrade 
      ? renderCoursesByGrade()
      : props.filterOptions.values.byAge
        ? renderCoursesByAge()
        : byDay
          ? renderCoursesByDay()
          : renderCoursesUngrouped()
  }

  function renderCoursesByDay() {
    const coursesByDay = CourseUtils.groupCoursesByDay(props.courses);

    return <VBox gap='$30'>{coursesByDay.map(renderDayCourses)}</VBox>
  }

  function renderDayCourses(dayCourses:DayCourses<Course>) {
    const isWeekend = dayCourses.day == 'Saturday' || dayCourses.day == 'Sunday';
    const noCourses = !dayCourses.courses.length;

    if (isWeekend && noCourses) {
      return '';
    }

    return renderCourseGroup(dayCourses.day, dayCourses.courses, c => variantIsOnDay(c, dayCourses.day))
  }

  function variantIsOnDay(course:CourseCardCourse, day:string) {
    return course.courseDays.find(cd => cd.days.indexOf(day) != -1) != null
  }

  function renderCoursesByGrade() {
    const courses = CourseUtils.groupCoursesByGrade(props.courses, props.site.grades);

    return renderGroups(courses);
  }

  function renderCoursesByAge() {
    const courses = CourseUtils.groupCoursesByAge(props.courses);

    return renderGroups(courses);
  }

  function renderGroups(courses:GroupedCourses<PublicSeason['courses'][0]>[], variantFilter?:(c:CourseCardCourse) => boolean) {
    return <VBox gap='$30'>{courses.map(group => renderCourseGroup(group.label, group.courses), variantFilter)}</VBox>
  }
  function renderCoursesUngrouped() {
    const courses = sort == 'time' 
      ? CourseUtils.sortCoursesByCourseDayTime(props.courses) 
      : sort == 'date'
        ? CourseUtils.sortCoursesByDate(props.courses)
        : CourseUtils.sortCoursesByGrade(props.courses)
        
    return renderCourseGroup('', courses);
  }

  function renderCourseGroup(title:string, courses:Course[], variantFilter?:(c:CourseCardCourse) => boolean) {
    return <VBox key={title}>
      {title && <Text text='subtitle2' mb="$4">{title}</Text>}
      <HBox flexWrap='wrap' gap='$16'>{
        !courses?.length 
          ? <Body>No activities</Body>
          : courses.map(c => renderCourse(c, variantFilter))}
      </HBox>
    </VBox>
  }

  function renderCourse(c:Course, variantFilter?:(c:CourseCardCourse) => boolean) {
    const cart = props.cart;

    return <CourseCard key={c.id} course={c} siteCompanies={props.site.siteCompanies} cart={cart} partner={props.site.partner} variantFilter={variantFilter} size={tile ? 'tile' : 'auto'} />;
  }

  return render();
}
