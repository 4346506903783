import * as React from 'react';

import { RecurringRate } from 'app2/api';
import { InputProps, HBox, Popup, Input, Option, dispatchChangeEvent, DropdownFocusType } from 'app2/components';

import { CourseRecurringRates, RecurringFields } from './CourseRecurringRates';
import { formatRecurringRate } from './formatRecurringRates';

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  value?:RecurringRate[];
  onChange?:(event:React.ChangeEvent) => void;
  recurringFields:RecurringFields;
}

export function CourseRecurringRatesDropdown(props:Props) {
  const {value, onChange, recurringFields, ...remaining} = props;
  const ref = React.useRef<Popup>();

  function render() {
    return <Popup ref={ref} trigger={renderTrigger()} dropdown={renderDropdown()} width='100%' bg='background' />
  }

  function renderTrigger() {
    const tags = props.value?.map(formatRecurringRate);

    return <Input tags={tags} icon='DropdownOpen' readOnly onTagCloseClick={onTagCloseClick} onIconMouseDown={ref.current?.toggleDropdown} {...remaining} />
  }

  function renderDropdown() {
    return <HBox vAlign='center' height='100%' width='100%' p='$12' bg='background' border='solid 1px' borderColor='border' borderRadius='standard'>
      <CourseRecurringRates recurringFields={recurringFields} />
    </HBox>
  }

  function onTagCloseClick(tag:Option, index:number) {
    const value = props.value.slice();
    value.splice(index, 1);

    dispatchChangeEvent(ref.current, value, props.onChange);
  }

  return render();
}
