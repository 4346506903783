import * as React from 'react';

import { NavigationSection, NavigationLink } from 'app2/components';

export const adminLinks = <NavigationSection icon='BookOpen' name='Admin'>
  <NavigationLink to='/admin2/users' label='Users' />
  <NavigationLink to='/admin2/providers' label='Providers' />
  <NavigationLink to='/admin2/schools' label='Schools' />
  <NavigationLink to='/admin2/homeroom' label='Homeroom' />
  <NavigationLink to='/admin2/component-demos' label='Components' />
  <NavigationLink to='/admin2/events' label='Events' />
</NavigationSection>
