import * as React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { UnregisterCallback, Location } from 'history';
import { chunk } from 'lodash-es';
import * as qs from 'query-string';

import { observer } from 'app/observable';

import { Box, HBox, BoxProps } from './Box';
import { Button } from './Button';
import { CardRow } from './CardRow';

// The LoadMore component is a row based pagination
// component.  The "load more" button is contained by
// a link that a web crawler can use to load the next page
// but if clicked by a human will load the next page and
// append the results (where as the web crawler will
// only see a new page).  To use you must provide callbacks
// for loading data, and for rendering a row.

type ItemProperties = { item: any };
type RowProperties = { items: React.ReactElement[]; numPerRow?: number };
type RowElement = React.ReactElement<RowProperties>;
type RowType = React.ComponentType<RowProperties>;

interface LoadMoreProps extends BoxProps, RouteComponentProps<any> {
  urlProperty?: string;
  // loads a page
  load: (page: number) => Promise<any[]>;
  // renders a row (or specify a row component below)
  render?: (
    page: number,
    row: number,
    numPerRow: number,
    data: any[]
  ) => React.ReactNode;
  // item component
  component?:
    | React.ReactElement<ItemProperties>
    | React.ComponentType<ItemProperties>;
  // row component
  rowComponent?: RowElement | RowType;
  numPerRow: number;
  numPages: number;
  // if your dataset changes, setting a unique key per dataset ensures
  // the new data gets loaded and rendered (because data is loaded via callback
  // an extra mechanism is needed to tell LoadMore when to start over)
  dataKey?: string;
  buttonText?: string;
}

interface State {
  data: any[];
  page?: number;
}

enum NavigationType {
  link,
  click
}
@observer
export class InnerLoadMore extends React.Component<LoadMoreProps, State> {
  static defaultProps = {
    urlProperty: 'page',
    rowComponent: CardRow as RowType,
    buttonText: 'See more'
  };

  state: State = { data: [] };
  pageQueue: { page: number; type: NavigationType }[] = [];
  unregister: UnregisterCallback;

  render() {
    const {
      urlProperty,
      load,
      render,
      component,
      rowComponent,
      numPerRow,
      numPages,
      dataKey,
      history,
      location,
      match,
      staticContext,
      buttonText,
      ...remaining
    } = this.props;

    return (
      <Box pb="$10" {...remaining}>
        <Box>{this.renderData()}</Box>
        {this.renderLoadMore()}
      </Box>
    );
  }

  renderData() {
    const chunks = chunk(this.state.data, this.props.numPerRow);

    return chunks.map((rowData, index) => this.renderRow(rowData, index));
  }

  renderRow(rowData: any[], index: number) {
    if (this.props.render) {
      return this.props.render(
        this.state.page,
        index,
        this.props.numPerRow,
        rowData
      );
    }

    const items = rowData.map((item, index) => this.renderItem(item, index));

    if (typeof this.props.component == 'function') {
      const Component: React.ComponentType<RowProperties> = this.props
        .rowComponent as RowType;
      return (
        <Component key={index} items={items} numPerRow={this.props.numPerRow} />
      );
    }

    return React.cloneElement(this.props.rowComponent as RowElement, {
      key: index,
      items,
      numPerRow: this.props.numPerRow
    });
  }

  renderItem(item: any, index: number): React.ReactElement<ItemProperties> {
    if (typeof this.props.component == 'function') {
      const Component: React.ComponentType<ItemProperties> = this.props
        .component;
      return <Component key={index} item={item} />;
    }

    return React.cloneElement(this.props.component, { key: index, item });
  }

  renderLoadMore() {
    return this.state.page + 1 < this.props.numPages ? (
      <HBox width="100%" hAlign="center">
        <Link
          to={`${this.props.location.pathname}?${this.props.urlProperty}=${(this
            .state.page || 0) + 1}`}
        >
          <Button
            kind="tertiary"
            icon="ChevronDown"
            onClick={this.onLoadMore}
            loading={this.pageQueue.length != 0}
          >
            {this.props.buttonText}
          </Button>
        </Link>
      </HBox>
    ) : (
      ''
    );
  }

  get hasUrlParameter() {
    return this.props.urlProperty in qs.parse(this.props.location.search);
  }

  pageFromUrl(location?: Location) {
    return parseInt(
      qs.parse((location || this.props.location).search)[
        this.props.urlProperty
      ] || '0'
    );
  }

  componentDidMount() {
    this.unregister = this.props.history.listen(this.onHistoryChange);
    this.loadPage(this.pageFromUrl(), NavigationType.link);
  }

  componentDidUpdate(prevProps: LoadMoreProps) {
    if (prevProps.dataKey != this.props.dataKey) {
      this.loadPage(this.pageFromUrl(), NavigationType.link);
    }
  }

  onHistoryChange = (location: Location) => {
    this.loadPage(this.pageFromUrl(location), NavigationType.link);
  };

  componentWillUnmount() {
    this.unregister();
    this.unregister = null;
  }

  onLoadMore = (event: React.MouseEvent) => {
    // for regular behavior we load more,
    // appending to the current data and
    // don't update the url.  we don't update the
    // url because refreshing the browser would
    // lead to jumping to that page and thats
    // not the behavior of load more pages.
    // the link/url is for google.  google
    // will see the url, and navigating to that
    // url should work as google expects.

    event.preventDefault();
    this.loadPage((this.state.page || 0) + 1, NavigationType.click);
  };

  loadPage(page: number, type: NavigationType) {
    if (
      page === this.state.page ||
      this.pageQueue.find(info => info.page == page) != null
    ) {
      return;
    }

    this.pageQueue.push({ page, type });
    this.processQueue();
  }

  async processQueue() {
    if (!this.pageQueue.length) {
      return;
    }

    // because the loading of data is async
    // we don't remove the page from the queue
    // until after the data is loaded because otherwise
    // repeated requests to load the same page
    // would result in the same page getting
    // added to the queue while loading that page
    const info = this.pageQueue[0];
    const page = info.page;
    let data: any[] = [];

    try {
      data = (await this.props.load(page)).slice();
    } catch (e) {}

    if (
      page - 1 === this.state.page &&
      this.state.data &&
      info.type == NavigationType.click
    ) {
      data.unshift(...this.state.data);
    }

    let pos = this.pageQueue.findIndex(info => info.page == page);

    while (pos != -1) {
      this.pageQueue.splice(pos, 1);
      pos = this.pageQueue.findIndex(info => info.page == page);
    }

    this.setState({ page, data });
    this.processQueue();
  }
}

export const LoadMore = withRouter(InnerLoadMore);
