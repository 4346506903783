import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseInviteAcceptMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
  company?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type CourseInviteAcceptMutation = {
  courseInviteAccept?:
    | {
        success?: boolean | null | undefined;
        site?: { name?: string | null | undefined } | null | undefined;
        companies?: Array<{ id: string; name?: string | null | undefined; email?: string | null | undefined }> | null | undefined;
        user?: { joined?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const CourseInviteAcceptDocument = gql`
  mutation CourseInviteAccept($token: String!, $company: ID) {
    courseInviteAccept(token: $token, company: $company) {
      success
      site {
        name
      }
      companies {
        id
        name
        email
      }
      user {
        joined
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CourseInviteAcceptOptions = ExecMutationOptions<CourseInviteAcceptMutationVariables>;

export function courseInviteAccept(options: CourseInviteAcceptOptions) {
  return executeMutation<CourseInviteAcceptMutation, CourseInviteAcceptMutationVariables>(CourseInviteAcceptDocument, options);
}
