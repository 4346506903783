import * as React from 'react';

import { ComplianceRequirementInput, FileUploadAttributes, VendorTeacher } from 'app2/api';
import { Dropdown, HBox, Icon, Form, FieldInfo, Subtitle2 } from 'app2/components';
import { addDeletedItems } from 'app2/views/shared'

import { ComplianceFields, ComplianceFormValues } from './ComplianceFields';
import { updateVendorCompliance, useVendorTeacherComplianceQuery } from './generated';

interface Props {
  vendor: string;
  teachers:Partial<VendorTeacher>[];
  timezone?:string;
}

export function TeacherCompliance(props: Props) {
  const [teacherId, setTeacherId] = React.useState(props.teachers[0]?.teacher?.id);
  const query = useVendorTeacherComplianceQuery({ variables: { vendor: props.vendor, teacherId } });
  const compliance = { requirements: query[0].data?.vendorTeacherCompliance };
  const options = React.useMemo(() => props.teachers.map((t: Partial<VendorTeacher>) => ({value: t.teacher.id, text: t.teacher.name, label: <HBox gap='$8'>{t.teacher.name}{!t.inCompliance && <Icon name='AlertCircle' color='error' />}</HBox>})), [props.teachers]);

  function render() {
    return <Form initialValues={compliance} editing onNavigation='nothing'>
      <HBox vAlign="center" hItemSpace="$8" mb="$30">
        <Subtitle2>Instructor</Subtitle2>
        <Dropdown options={options} value={teacherId} onChange={onSelect} width='100%' />
      </HBox>
      <ComplianceFields onChange={onChange} />
    </Form>
  }

  async function onChange(_value: any, info: FieldInfo<ComplianceFormValues>) {
    const requirement = (info.record as unknown) as ComplianceRequirementInput;
    const initialVal = info.form.initialValues?.requirements?.find(req => req.id === requirement.id);

    const uploads = addDeletedItems(initialVal?.uploads as FileUploadAttributes[], requirement.uploads,'archived')

    const attributes: ComplianceRequirementInput = { id: requirement.id, status: requirement.status, expiresAt: requirement.expiresAt, uploads: uploads };
    const [success] = await updateVendorCompliance({ variables: { attributes }, error: info.form, successMsg: 'Compliance saved.' });
    return success;
  }

  function onSelect(event: React.ChangeEvent<Dropdown>) {
    setTeacherId(event.target.value);
  }

  return render();
}
