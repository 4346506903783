import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentEnrollmentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentEnrollmentsQuery = {
  user?:
    | {
        id: string;
        enrollments?: Array<{ course: { id: string; disambiguatedName?: string | null | undefined; company?: { id: string; name?: string | null | undefined } | null | undefined } } | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export const ParentEnrollmentsDocument = gql`
  query ParentEnrollments {
    user: currentUser {
      id
      enrollments(queries: [["CONFIRMED", "WAITLISTED"], "SCHEDULED", "PRESENT"]) {
        course {
          id
          disambiguatedName
          company {
            id
            name
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentEnrollmentsArgs = MakeOptional<UseQueryArgs<ParentEnrollmentsQueryVariables, ParentEnrollmentsQuery>, 'query'>;

export function useParentEnrollmentsQuery(options: ParentEnrollmentsArgs = {}) {
  return useQuery<ParentEnrollmentsQueryVariables, ParentEnrollmentsQuery>({ query: ParentEnrollmentsDocument, ...options });
}

export type ParentEnrollmentsOptions = ExecQueryOptions<ParentEnrollmentsQueryVariables>;

export function parentEnrollments(options: ParentEnrollmentsOptions) {
  return executeQuery<ParentEnrollmentsQuery, ParentEnrollmentsQueryVariables>(ParentEnrollmentsDocument, options);
}

export type ParentEnrollmentsSelections = ParentEnrollmentsQuery['user'];
