import * as React from 'react';

import { PolicyInput } from 'app2/api';
import { FormModel, VBox } from 'app2/components';

import { BeforeActivity } from './BeforeActivity';
import { AfterActivity } from './AfterActivity';
import { SchoolPolicies } from './SchoolPolicies';
import { Waivers } from './Waivers';
import { organizerUpdateSitePolicies } from './gql';

export function Policies() {
  async function handleOk(form: FormModel) {
    const [success] = await organizerUpdateSitePolicies({ variables: { attributes: form.values as PolicyInput }, error: form, successMsg: 'Policies saved' });
    return success;
  }

  return (
    <VBox>
      <BeforeActivity handleOk={handleOk} />
      <AfterActivity handleOk={handleOk} />
      <SchoolPolicies handleOk={handleOk} />
      <Waivers />
    </VBox>
  );
}
