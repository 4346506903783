import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerComplianceReportQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerComplianceReportQuery = {
  site?:
    | {
        complianceReport?: Array<{ type: string; provider: string; instructor?: string | null | undefined; requirement: string; status?: string | null | undefined; files?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const OrganizerComplianceReportDocument = gql`
  query OrganizerComplianceReport($siteId: ID!) {
    site(site: $siteId) {
      complianceReport {
        type
        provider
        instructor
        requirement
        status
        files
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerComplianceReportArgs = MakeOptional<UseQueryArgs<OrganizerComplianceReportQueryVariables, OrganizerComplianceReportQuery>, 'query'>;

export function useOrganizerComplianceReportQuery(options: OrganizerComplianceReportArgs = {}) {
  return useQuery<OrganizerComplianceReportQueryVariables, OrganizerComplianceReportQuery>({ query: OrganizerComplianceReportDocument, ...options });
}

export type OrganizerComplianceReportOptions = ExecQueryOptions<OrganizerComplianceReportQueryVariables>;

export function organizerComplianceReport(options: OrganizerComplianceReportOptions) {
  return executeQuery<OrganizerComplianceReportQuery, OrganizerComplianceReportQueryVariables>(OrganizerComplianceReportDocument, options);
}

export type OrganizerComplianceReportSelections = OrganizerComplianceReportQuery['site'];
