import { compact } from 'lodash-es'
import { O } from 'ts-toolbelt'
import moment from 'moment';

import { DeepPartial } from '..'
import { Contact, WaitlistKind, EnrollmentStatusEnum, Enrollment, PriceConfig, PriceConfigKind, RecurringPriceConfig, SeasonPriceConfig, UsagePriceConfig, RecurringUnit, UsageUnit } from '../graphql';

import { StudentUtils } from './StudentUtils'

export type CourseWithSeasonSite = O.P.Pick<Enrollment, ['course', 'season', 'site', 'usingPickups']>;
export type CourseWithSite = O.P.Pick<Enrollment, ['course', 'site', 'usingPickups']>;
export type EnrollmentWithWaitlist = Pick<Enrollment, 'waitlistKind'>;
export type PartialPriceConfig = O.Partial<PriceConfig, 'deep'>;
export type EnrollmentWithPriceConfig = {priceConfig?:PartialPriceConfig};
export type EnrollmentWithPriceConfigs = {priceConfigs?:PartialPriceConfig[]};
export type EnrollmentWithStudentName = Pick<Enrollment, 'id' | 'isInvitation' | 'student' | 'contacts' | 'parent'>;


export class EnrollmentUtils {
  static getStudentFirstName(enrollment: EnrollmentWithStudentName) {
    return enrollment.student 
      ? StudentUtils.getStudentFirstName(enrollment.student)
      : enrollment.contacts?.[0]?.name?.split?.(' ')?.[0];
  }

  static getStudentLastName(enrollment: EnrollmentWithStudentName) {
    return enrollment.student 
      ? enrollment.student.lastName
      : enrollment.contacts?.[0]?.name?.split?.(' ')?.[1];
  }

  static getStudentName(enrollment: EnrollmentWithStudentName) {
    return enrollment.student 
      ? StudentUtils.getStudentName(enrollment.student)
      : enrollment.contacts?.[0]?.name;
  }

  static getParentName(enrollment: EnrollmentWithStudentName) {
    return EnrollmentUtils.isPrivateEnrollment(enrollment)
      ? ''
      : enrollment.parent?.name || `${enrollment.parent?.firstName} ${enrollment.parent?.lastName}`
  }

  static getParentEmail(enrollment: Enrollment) {
    return EnrollmentUtils.isPrivateEnrollment(enrollment)
      ? enrollment.contacts?.[0]?.email
      : enrollment.parent?.email
  }

  static getParentPhone(enrollment: Enrollment) {
    return EnrollmentUtils.isPrivateEnrollment(enrollment)
      ? enrollment.contacts?.[0]?.phone
      : enrollment.parent?.phone
  }

  static isPrivateEnrollment(enrollment: {isInvitation:boolean, contacts?:Partial<Contact>[]}) {
    return enrollment.isInvitation && enrollment.contacts?.length > 0;
  }

  static isFutureEnrollment(enrollment:Pick<Enrollment, 'startDate' | 'priceConfig'>) {
    if (enrollment?.priceConfig?.kind != PriceConfigKind.Recurring) {
      return false;
    }
  }

  static usingPickups(enrollment: Pick<Enrollment, 'pickupDropoffRequired'> & (CourseWithSite | CourseWithSeasonSite | {})) {
    const site = (enrollment as CourseWithSeasonSite).course?.season?.site || (enrollment as CourseWithSite).course?.site;

    return enrollment.pickupDropoffRequired && (!site || site.usingPickups);
  }

  static unfinalizedProvisionalWaitlist(enrollment: Pick<Enrollment, 'status' | 'waitlistKind'>) {
    return this.waitlistUnfinalized(enrollment) && this.provisionalWaitlistStatus(enrollment);
  }

  static waitlisted(enrollment: Pick<Enrollment, 'status'>) {
    return enrollment.status === EnrollmentStatusEnum.ProvisionallyWaitlisted || enrollment.status === EnrollmentStatusEnum.Waitlisted;
  }

  static provisionalWaitlistStatus(enrollment: Pick<Enrollment, 'status'>) {
    return enrollment.status === EnrollmentStatusEnum.ProvisionallyWaitlisted;
  }

  static waitlistFinalized(enrollment:EnrollmentWithWaitlist) {
    return enrollment.waitlistKind == WaitlistKind.Finalized || enrollment.waitlistKind == WaitlistKind.Promoted;
  }

  static waitlistUnfinalized(enrollment:EnrollmentWithWaitlist) {
    return enrollment.waitlistKind == WaitlistKind.Carted;
  }

  static canUsingOptional(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs) {
    return EnrollmentUtils.usingPriceConfig(enrollment, PriceConfigKind.Season) || EnrollmentUtils.usingPriceConfig(enrollment, PriceConfigKind.Usage);
  }

  static usingOngoing(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs) {
    return this.usingRecurring(enrollment) || this.usingUsage(enrollment);
  }

  static ongoingInstance(enrollment:DeepPartial<Enrollment>) {
    return this.usingOngoing(enrollment) && enrollment.templateId;
  }

  static usingRecurring(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs):RecurringPriceConfig {
    return EnrollmentUtils.usingPriceConfig(enrollment, PriceConfigKind.Recurring) as RecurringPriceConfig;
  }

  static usingUsage(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs):UsagePriceConfig {
    return EnrollmentUtils.usingPriceConfig(enrollment, PriceConfigKind.Usage) as UsagePriceConfig;
  }

  static usingSeason(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs) {
    return EnrollmentUtils.usingPriceConfig(enrollment, PriceConfigKind.Season);
  }

  static usingConfigurableSeasonPricing(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs) {
    return EnrollmentUtils.usingSeason(enrollment) && EnrollmentUtils.usingWeekdays(enrollment);
  }
  
  static usingWeekdays(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs) {
    return EnrollmentUtils.findPriceConfig(enrollment, config => (config as SeasonPriceConfig | RecurringPriceConfig).weekdays?.length > 0);
  }

  static usingPriceConfig(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs, kind:PriceConfigKind) {
    return EnrollmentUtils.findPriceConfig(enrollment, config => config.kind == kind);
  }

  static billedNow(e:DeepPartial<Enrollment>) {
    return this.nonSeasonBilledNow(e) || this.seasonBilledNow(e);
  }

  static billedLater(e:DeepPartial<Enrollment>) {
    return this.nonSeasonBilledLater(e) || this.seasonBilledLater(e);
  }

  static nonSeasonBilledNow(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind != PriceConfigKind.Season && !e.priceConfig?.template;
  }
  
  static nonSeasonBilledLater(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind != PriceConfigKind.Season && e.priceConfig?.template;
  }

  static seasonBilledNow(e:DeepPartial<Enrollment>) {
    return this.seasonDeposit(e) || this.seasonInstallmentsInThePast(e) || this.seasonNoPaymentPlan(e);
  }

  static seasonDeposit(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.Season && !e.priceConfig.template && (e.priceConfig as SeasonPriceConfig).deposit;
  }

  static seasonInstallmentsInThePast(e:DeepPartial<Enrollment>) {
    return this.seasonInstallment(e) && !this.seasonSomeInstallmentsInTheFuture(e);
  }

  static seasonNoDepositPaymentPlan(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.Season && e.priceConfig.template && !(e.priceConfig as SeasonPriceConfig).depositAmount;
  }
  
  static seasonNoPaymentPlan(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.Season && !e.priceConfig.template && !(e.priceConfig as SeasonPriceConfig).installmentDates?.length;
  }

  static seasonHidden(e:DeepPartial<Enrollment>) {
    return (this.seasonTemplate(e) && !this.seasonNoDepositPaymentPlan(e)) || this.seasonBilledLater(e);
  }

  static seasonTemplate(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.Season && e.priceConfig.template;
  }

  static seasonBilledLater(e:DeepPartial<Enrollment>) {
    return this.seasonInstallment(e) && this.seasonSomeInstallmentsInTheFuture(e);
  }

  static seasonSomeInstallmentsInTheFuture(e:DeepPartial<Enrollment>) {
    const currentUtcDate = moment.utc().startOf('day');
    const result = (e.priceConfig as SeasonPriceConfig).installmentDates?.some(d => currentUtcDate.isBefore(moment.utc(d)));
    return result;
  }

  static seasonInstallment(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.Season && !e.priceConfig.template && (e.priceConfig as SeasonPriceConfig).installmentDates?.length > 0 && (e.status == EnrollmentStatusEnum.Pending || (e.priceConfig as SeasonPriceConfig).billingDate);
  }

  static scheduled(e:DeepPartial<Enrollment>) {
    return e.priceConfig.kind == PriceConfigKind.DropIn || !e.templateId
  }

  static findPriceConfig(enrollment:EnrollmentWithPriceConfig | EnrollmentWithPriceConfigs, predicate: (value: PartialPriceConfig, index: number, obj: PartialPriceConfig[]) => unknown) {
    const withPc = enrollment as EnrollmentWithPriceConfig;
    const withPcs = enrollment as EnrollmentWithPriceConfigs;

    const priceConfigs = compact(withPcs.priceConfigs ? withPcs.priceConfigs : [withPc.priceConfig]);

    return priceConfigs.find(predicate);
  }

  // gql has an issue with fields with the same name by different types
  // so we have to alias usage unit to usageUnit.
  static unit(enrollment:EnrollmentWithPriceConfig):RecurringUnit | UsageUnit | 'installment' | null {
    //@ts-ignore
    const unit = (enrollment.priceConfig as {unit:RecurringUnit})?.unit || (enrollment.priceConfig as {usageUnit:UsageUnit})?.usageUnit;

    return enrollment.priceConfig.kind == PriceConfigKind.Season 
      ? this.seasonBilledLater(enrollment)
        ? 'installment'
        : null
      : unit
  }
}
