import * as React from 'react';

import { Box, Button, HBox, Link, LinkProps, VBox } from 'app2/components';

interface Props {
  qrCode:string;
  link:LinkProps;
}

export function QrCode(props:Props) {
  function render() {
    if (!props?.qrCode || !props?.link) {
      return null;
    }

    return <VBox hAlign="left" gap="$16">
      <Box height="300px" width="300px" dangerouslySetInnerHTML={{ __html: props.qrCode }} />
      <HBox gap="$16">
        <Link target="_blank" button="primary" {...props.link} />
        <Button onClick={handlePrint}>Print</Button>
      </HBox>
    </VBox>
  }

  function handlePrint() {
    const printWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              margin: 0;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .qr-code {
              width: 4in;
              height: 4in;
              display: block; 
            }
          </style>
        </head>
        <body>
          <div class="qr-code">${props.qrCode}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();

    // Delay printing to ensure the window is ready
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  }

  return render();
}
