import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUpdateCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  nonPartnerApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type AdminUpdateCompanyMutation = {
  companyUpdate?: { company?: { id: string; name?: string | null | undefined; nonPartnerApproved?: boolean | null | undefined; stripeAccount?: string | null | undefined } | null | undefined } | null | undefined;
};

export const AdminUpdateCompanyDocument = gql`
  mutation AdminUpdateCompany($id: ID!, $name: String!, $nonPartnerApproved: Boolean) {
    companyUpdate(company: $id, name: $name, nonPartnerApproved: $nonPartnerApproved) {
      company {
        id
        name
        nonPartnerApproved
        stripeAccount
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminUpdateCompanyOptions = ExecMutationOptions<AdminUpdateCompanyMutationVariables>;

export function adminUpdateCompany(options: AdminUpdateCompanyOptions) {
  return executeMutation<AdminUpdateCompanyMutation, AdminUpdateCompanyMutationVariables>(AdminUpdateCompanyDocument, options);
}
