import { OperationContext, OperationResult } from 'urql';

import { Query, urql } from 'app2/api';
import { ErrorWithPath } from 'app2/components';
import { handleErrors, ErrorInfo, ErrorHandlerInfoOrHandlers } from 'app2/views/shared/error';

import { applyAdditionalTypenames } from './applyAdditionalTypenames';

export interface ExecQueryOptions<V> {
  variables: V;
  context?: Partial<OperationContext>;
  error?: ErrorHandlerInfoOrHandlers;
}

export async function executeQuery<D = any, V extends object = {}>(query: Query<D, V>, options: ExecQueryOptions<V>): Promise<[boolean, OperationResult<D, V>, ErrorWithPath[]]> {
  const context = applyAdditionalTypenames<D, V>(query, options.context);
  const result = await urql.client
    .query<D, V>(query, options.variables, context)
    .toPromise();
  const errorInfo: ErrorInfo = handleErrors(options.error, result);

  return [errorInfo.success, result, errorInfo.unhandled];
}

export type QueryFunction<V, D> = (options:ExecQueryOptions<V>) => Promise<[boolean, OperationResult<D, V>, ErrorWithPath[]]>;