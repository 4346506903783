import * as React from 'react'

import { Img, Text, VBox } from 'app2/components';

interface Props {
  label:string;
  description:string;
  image:string;
  checked?:boolean;
  onChange?: (event:any, selected:boolean) => void;
}

export function AccountType(props:Props) {
  const {label, description, image, checked} = props;
  
  return <VBox flex={1} hAlign='center' gap='$12' p='$12' onClick={event => props.onChange(event, !checked)} borderRadius='form' border='solid 2px' borderColor={checked ? 'primary' : 'rgba(0, 0, 0, 0)'}>
    <Img src={image} />
    <Text text='heading2'>{label}</Text>
    <Text text='body' textAlign='center'>{description}</Text>
  </VBox>
}
