import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ActivateUserMutation = { userActivate?: { id: string } | null | undefined };

export const ActivateUserDocument = gql`
  mutation ActivateUser($id: ID!) {
    userActivate(user: $id) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ActivateUserOptions = ExecMutationOptions<ActivateUserMutationVariables>;

export function activateUser(options: ActivateUserOptions) {
  return executeMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
}
