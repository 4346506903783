import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AttendanceCourseQueryVariables = Types.Exact<{
  token: Types.Scalars['ID'];
}>;

export type AttendanceCourseQuery = {
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        status: Types.CourseStatus;
        kind?: Types.CourseKind | null | undefined;
        room?: string | null | undefined;
        hasEnrolledStudents: boolean;
        enrollmentOpens?: string | null | undefined;
        enrollmentCloses?: string | null | undefined;
        ageMin?: number | null | undefined;
        ageMax?: number | null | undefined;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        noEnrichmentDays: Array<string | null | undefined>;
        behavior?: { attendanceLink?: boolean | null | undefined } | null | undefined;
        courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        season: { id: string; hasConfigurablePricing: boolean; hasNonSeasonPricing: boolean };
        company?: { id: string; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined;
        site: {
          id: string;
          name?: string | null | undefined;
          checkoutDestinations?: Array<string> | null | undefined;
          pickupDestinations?: Array<string> | null | undefined;
          timezone?: string | null | undefined;
          usingPriceTiers: boolean;
          userSiteRole?: Types.MemberRole | null | undefined;
        };
      }
    | null
    | undefined;
};

export const AttendanceCourseDocument = gql`
  query AttendanceCourse($token: ID!) {
    course(course: $token) {
      id
      name
      status
      kind
      room
      hasEnrolledStudents
      behavior {
        attendanceLink
      }
      courseDays {
        days
        start
        finish
      }
      enrollmentOpens
      enrollmentCloses
      ageMin
      ageMax
      startDate
      endDate
      noEnrichmentDays
      season {
        id
        hasConfigurablePricing
        hasNonSeasonPricing
      }
      company {
        id
        userCompanyRole
      }
      site {
        id
        name
        checkoutDestinations
        pickupDestinations
        timezone
        usingPriceTiers
        userSiteRole
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AttendanceCourseArgs = MakeOptional<UseQueryArgs<AttendanceCourseQueryVariables, AttendanceCourseQuery>, 'query'>;

export function useAttendanceCourseQuery(options: AttendanceCourseArgs = {}) {
  return useQuery<AttendanceCourseQueryVariables, AttendanceCourseQuery>({ query: AttendanceCourseDocument, ...options });
}

export type AttendanceCourseOptions = ExecQueryOptions<AttendanceCourseQueryVariables>;

export function attendanceCourse(options: AttendanceCourseOptions) {
  return executeQuery<AttendanceCourseQuery, AttendanceCourseQueryVariables>(AttendanceCourseDocument, options);
}

export type AttendanceCourseSelections = AttendanceCourseQuery['course'];
