import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminEventsQueryVariables = Types.Exact<{
  term: Types.Scalars['OptionValue'];
}>;

export type AdminEventsQuery = {
  events?:
    | Array<{
        id: string;
        createdAt: string;
        eventName?: string | null | undefined;
        recipient?: string | null | undefined;
        serviceType?: Types.EventService | null | undefined;
        contact?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export const AdminEventsDocument = gql`
  query AdminEvents($term: OptionValue!) {
    events(filters: [{ name: "eventName", op: CONTAINS, value: $term }], sorts: [{ name: "createdAt", direction: DESC }]) {
      id
      createdAt
      eventName
      recipient
      contact {
        id
        name
        email
        phone
      }
      serviceType
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminEventsArgs = MakeOptional<UseQueryArgs<AdminEventsQueryVariables, AdminEventsQuery>, 'query'>;

export function useAdminEventsQuery(options: AdminEventsArgs = {}) {
  return useQuery<AdminEventsQueryVariables, AdminEventsQuery>({ query: AdminEventsDocument, ...options });
}

export type AdminEventsOptions = ExecQueryOptions<AdminEventsQueryVariables>;

export function adminEvents(options: AdminEventsOptions) {
  return executeQuery<AdminEventsQuery, AdminEventsQueryVariables>(AdminEventsDocument, options);
}

export type AdminEventsSelections = AdminEventsQuery['events'][0];

(AdminEventsDocument as any).additionalTypenames = ['Event'];
