import * as React from 'react';

import { CheckboxField, Field, FormModel, FormContent, Input, InputField, Panel, Part, Radio, RadioGroup, Section, RepeatingSection } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function PanelDemos() {
  return <Demos name='Panel'
    description={`
    Panels and forms are composed of a few components that enable consistent layout:

    - Panel 
      - a title, icon and action buttons
      - contains sections
      - can be just a layout container, a form, or a toggle form

    - Section 
      - an optional title and a group of components layed out horizontally
      - contains a field or parts
      - when a section contains a single field, a section accepts field properties so you can define
        the section and field together

    - Part 
      - an optional title and a group of components layed out veritically
      - contains a field or group of fields
      - when a part contains a single field, a part accepts field properties so you can define
        the section and field together

    - Field
      - contains a form component
      - connects the component to form data
      - the demos here are specific to panel + form demos,  see <a href='/admin/component-demos/form'>form demos</a> for form only demos
      - for display, defaults to FieldValue
    `}>

    <Demo name='Section, part and component' description={`
      A panel can contain a section, which can contain a part, which can contain any components
    `}>
      <Panel icon='Clipboard' title='Panel title'>
        <Section label='Name'>
          <Part label='First name'><Input /></Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Multiple fields in a single row' description={`
      If there are multiple fields in a section, the horizontal space is equally divided.
    `}>
      <Panel icon='Clipboard' title='Panel title'>
        <Section label='Name'>
          <Part label='First name'><Input /></Part>
          <Part label='Last name'><Input /></Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Panel as a form' description={`
      A panel can be a form that will collect data.  Click OK to see the data collected in the form
    `}>
      <Panel icon='Clipboard' title='Panel title' type='edit' onOk={(form:FormModel) => alert(JSON.stringify(form.values))}>
        <Section label='Name'>
          <Part label='First name'><Field name='firstName' component={InputField} /></Part>
          <Part label='Last name'><Field name='LastName' component={InputField} /></Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Part as a field' description={`
      Rather than needing to specify field separately, Part can take all the attributes of Field.  When using Part this way
      if a field is required, the label will automitcally add an *, otherwise it will add "(optional)".  Additionally the part
      will display any field level errors.
    `}>
      <Panel icon='Clipboard' title='Panel title' type='edit' onOk={(form:FormModel) => alert(JSON.stringify(form.values))}>
        <Section label='Name'>
          <Part label='First name' name='firstName' component={InputField} required />
          <Part label='Last name' name='LastName' component={InputField} />
        </Section>
      </Panel>
    </Demo>

    <Demo name='Section as a field' description={`
      Like Part, Section can also act as a field.  Use this when there's only one field in a section.
    `}>
      <Panel icon='Clipboard' title='Panel title' type='edit' onOk={(form:FormModel) => alert(JSON.stringify(form.values))}>
        <Section label='Name' name='firstName' component={InputField}/ >
      </Panel>
    </Demo>

    <Demo name='Multiple rows under a single section title' description={`
      If you need multuple "rows" to a section, rather than add another component called row, we just
      use another section, but without a label.
    `}>
      <Panel icon='Clipboard' title='Panel title'>
        <Section label='School name'><Input /></Section>
        <Section label='School Address'>
          <Part label='Street'><Input /></Part>
        </Section>
        <Section continued>
          <Part label='City'><Input /></Part>
          <Part label='State'><Input /></Part>
          <Part label='Zip'><Input /></Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Multiple checkboxes in a single field' description={`
      You can add multiple components to a part.  This typically applies to checkboxes.
    `}>
      <Panel type='edit' icon='Clipboard' title='Panel title' onOk={(form) => console.log('zz', form.values)}>
        <Section label='Settings'>
          <Part label='Interests'>
            <Field component={CheckboxField} label='sports' name='sports' />
            <Field component={CheckboxField} label='movies' name='movies' />
            <Field component={CheckboxField} label='knitting' name='knitting' />
          </Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Radio group'>
      <Panel type='edit' icon='Clipboard' title='Panel title' onOk={(form) => console.log('zz', form.values)}>
        <Section label='Settings'>
          <Part label='Age' name='age' required component={RadioGroup} options={[
            {label:'0-18', value:'0-18'},
            {label:'19-30', value:'19-30'},
            {label:'30-50', value:'30-50'},
            {label:'51+', value:'51+'},
            {label:'Other', value:'other', input: <Field name='other' component={InputField} />},
            {label:'Prefer not to say', value:'argumentative', input: <Part label='Enter a reason why' name='other' component={InputField} />}
          ]} />
        </Section>
      </Panel>
    </Demo>

    <Demo name='Radios with children' description={`
      You can add multiple components to a part.  This typically applies to radio inputs.
    `}>
      <Panel type='edit' icon='Clipboard' title='Panel title' onOk={(form) => console.log('zz', form.values)}>
        <Section label='Settings'>
          <Part label='Age' name='age' required>
            <Field component={Radio} label='0-18' value='0-18' name='age' />
            <Field component={Radio} label='Other' value='19-30' name='age'>
              <Field component={InputField} name='otherAge' />
            </Field>
          </Part>
        </Section>
      </Panel>
    </Demo>

    <Demo name='Component attributes on field' description={`
      You can pass form component attributes as field attributes.  In this case since Part is acting as a field
      you can pass the form comonent attributes in the Part tag.  This example is passing the tags attribute to Input.
    `}>
      <Panel icon='Clipboard' title='Panel title'>
        <Part label='Name' component={InputField} tags={['tag1', 'tag2', 'tag3']} />
      </Panel>
    </Demo>

    <Demo name='Toggle form' description={`
      A Panel can be a toggle form that toggle's between display and edit mode.  By default the toggle form
      uses read only text fields as components in display mode.

      Click the pencil to go into edit mode.

      An onOk handler can be added, that will receive the form model and can be async.  Any errors thrown are shown in the form.
      In this example, there's a delay, to show that the form will automtically show a saving state.  After the
      delay, an error is thrown, to demonstrate the form will automatically show errors.
    `}>
      <Panel type='toggle' icon='Clipboard' title='Panel title' onOk={onOk}>
        <Section label='Name'>
          <Part label='First' name='first' component={InputField} />
          <Part label='Last' name='last' component={InputField} />
        </Section>
      </Panel>
    </Demo>

    <Demo name='Toggle form, override the display component' description={`
      You can specify the components to use in the display component by overriding the display attribute. You use the
      edit attribute to specify the edit component type.

      In this example, it uses a text field that has a different style.  Toggle the form, enter a value in first and last name.
      Save the form.  Notice that the first name has a different font because it's been overriddent.
    `}>
      <Panel type='toggle' icon='Clipboard' title='Panel title' initialValues={{first:'Joe', last:'Jill'}}>
        <Section label='Name'>
          <Part label='First' name='first' display={{text:'heading1'}} edit={InputField} />
          <Part label='Last' name='last' edit={InputField} />
        </Section>
      </Panel>
    </Demo>

    <Demo name='Multiple components bound to the same field' description={`
      Multiple components can be bound to the same form field.  In this case an input and label are
      bound to the same field.  As you type in the input, it shows in the label.
    `}>
      <Panel type='edit' icon='Clipboard' title='Panel title' onOk={onOk}>
        <Section label='Name'>
          <Part label='Name' name='name' component={InputField} />
          <Part label='Name' name='name' />
        </Section>
      </Panel>
    </Demo>

    <Demo name='Repeating section' description={`
      A repeating section automatically repeats for array values in a form.

      To use, you specify the name of the property in the form data that has the array and then
      specify the fields for each row.  All the other panel + form rules apply (such as displaying
      validation errors, using different field component types, etc.)

      The repeating section automatically adjusts its layout as needed.
    `}>
      <Panel icon='Clipboard' title='Panel title' type='toggle' onOk={form => console.log(form.values)} initialValues={{people: [
          {firstName:'This is a very long name so that you can check that values are top aligned in a repeating section.', lastName: 'Doe'}, 
          {firstName:'Jane', lastName: 'Doe'},
          {firstName:'John', lastName: 'Wayne'},
          {firstName:'Nancy', lastName: 'Sinatra'}
        ]}}>
        <RepeatingSection name='people' fields={[
            <Field label='First Name' name='firstName' />,
            <Field label='Middle Name' name='middleName' required component={InputField} />,
            <Field label='Last Name' name='lastName' />
          ]}
          />
      </Panel>
    </Demo>

    <Demo name='Repeating section, mobile' >
      <Panel icon='Clipboard' title='Panel title' type='toggle' onOk={form => console.log(form.values)} initialValues={{people: [
          {firstName:'This is a very long name so that you can check that values are top aligned in a repeating section.', lastName: 'Doe'}, 
          {firstName:'Jane', lastName: 'Doe'},
          {firstName:'John', lastName: 'Wayne'},
          {firstName:'Nancy', lastName: 'Sinatra'}
        ]}}>
        <RepeatingSection name='people' mode='card' fields={[
            <Field label='First Name' name='firstName' />,
            <Field label='Middle Name' name='middleName' required component={InputField} />,
            <Field label='Last Name' name='lastName' />
          ]}
          />
      </Panel>
    </Demo>

    <Demo name='Empty panel' description={`
      A panel with no components shouldn't have extra margins/padding.
    `}>
      <Panel icon='Clipboard' title='Panel title'>
      </Panel>
    </Demo>

    <Demo name='Panel with form and hidden fields' description={`
      A panel with hidden fields shouldn't have extra margins/padding.
    `}>
      <Panel icon='Clipboard' title='Panel title' type='toggle'>
        <Section label='Hidden in display mode' mode='edit' />
      </Panel>
    </Demo>

    <Demo name='Multiple sections' description={`A form with multiple sections.`}>
      <Panel>
        <FormContent title='Name' icon='DollarSign'>
          <Section label='Name'>
            <Part label='First name'><Input /></Part>
            <Part label='Last name'><Input /></Part>
          </Section>
        </FormContent>
        <FormContent title='Contacts' icon='Phone'>
          <Section label='School Address'>
            <Part label='Street'><Input /></Part>
          </Section>
        </FormContent>
      </Panel>
    </Demo>

    <Demo name='Various styles'>
      <Panel title='Funky styled panel' icon='Clipboard' m='10px' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer' />
    </Demo>

    <Demo name='Custom CSS'>
      <Panel title='Mouse over me' icon='Clipboard' css={{
        ':hover': {
          border: 'solid 10px red'
        }}} />
    </Demo>
  </Demos>
}

async function onOk(form:FormModel) {
  console.log(form.values);

  await new Promise((resolve) => setTimeout(resolve, 2000));
  throw new Error('test');
}
