import * as React from 'react';

import { BooleanField, Field, InputField } from 'app2/components';

import { SearchableList, SiteLink, useAdminSitesQuery } from '../shared';

export function SiteSearch() {
  function render() {
    return <SearchableList query={useAdminSitesQuery} name='sites' fields={[
      <Field name='name' label='Name' component={SiteLink} />,
      <Field name='city' label='City' component={InputField} />,
      <Field name='state' label='State' component={InputField} />,
      <Field name='zipcode' label='Zip' component={InputField} />,
      <Field name='partner' label='Partner' component={BooleanField} />,
      <Field name='active' label='Active' component={BooleanField} />,
      <Field name='retired' label='Retired' component={BooleanField} />,
      <Field name='slug' label='Active' component={InputField} />,
    ]} />
  }

  return render();
}
