import * as React from 'react';
import moment from 'moment';

import { ActionButton, DataTable, NotificationManager } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CourseSelections } from '../../../generated';

import { DistinctEnrollmentsSelections } from '../../enrolled';

import { CustomCharge } from './CustomCharge';

interface Props {
  table:DataTable<DistinctEnrollmentsSelections>;
  course: CourseSelections;
}

export function CustomChargeAction(props: Props) {
  function render() {
    const behavior = courseKindBehavior[props.course.kind];
    
    if (behavior.management.customCharge == false || !props.course?.behavior?.enrollmentsCustomCharge) {
      return <></>;
    }

    return <ActionButton icon="DollarSign" autoLoader={false} onClick={onClick}>Charge extra</ActionButton>
  }

  function onClick() {
    if (props.course?.children?.length) {
      return void(NotificationManager.warning("Please navigate to the specific time slot to issue an extra charge."))
    }

    const enrollments = props.table.selectedItems.filter(e => !e.isInvitation);
    const hasInvites = props.table.selectedItems.length != enrollments.length;

    if (hasInvites) {
      return void(NotificationManager.warning('Extra charges can\'t be issued to invites. The family must accept the invite first.'));
    }

    return <CustomCharge course={props.course} enrollments={enrollments} />
  }

  return render();
}

