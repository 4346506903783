import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteAfterActivityPoliciesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSiteAfterActivityPoliciesQuery = { site?: { policy?: { id: string; lateParent?: string | null | undefined; studentDismissal?: string | null | undefined } | null | undefined } | null | undefined };

export const OrganizerSiteAfterActivityPoliciesDocument = gql`
  query OrganizerSiteAfterActivityPolicies($siteId: ID!) {
    site(site: $siteId) {
      policy {
        id
        lateParent
        studentDismissal
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSiteAfterActivityPoliciesArgs = MakeOptional<UseQueryArgs<OrganizerSiteAfterActivityPoliciesQueryVariables, OrganizerSiteAfterActivityPoliciesQuery>, 'query'>;

export function useOrganizerSiteAfterActivityPoliciesQuery(options: OrganizerSiteAfterActivityPoliciesArgs = {}) {
  return useQuery<OrganizerSiteAfterActivityPoliciesQueryVariables, OrganizerSiteAfterActivityPoliciesQuery>({ query: OrganizerSiteAfterActivityPoliciesDocument, ...options });
}

export type OrganizerSiteAfterActivityPoliciesOptions = ExecQueryOptions<OrganizerSiteAfterActivityPoliciesQueryVariables>;

export function organizerSiteAfterActivityPolicies(options: OrganizerSiteAfterActivityPoliciesOptions) {
  return executeQuery<OrganizerSiteAfterActivityPoliciesQuery, OrganizerSiteAfterActivityPoliciesQueryVariables>(OrganizerSiteAfterActivityPoliciesDocument, options);
}

export type OrganizerSiteAfterActivityPoliciesSelections = OrganizerSiteAfterActivityPoliciesQuery['site'];
