import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentCreateAndAssignStudentAtCheckoutMutationVariables = Types.Exact<{
  attributes: Types.StudentInput;
  enrollmentId: Types.Scalars['ID'];
}>;

export type ParentCreateAndAssignStudentAtCheckoutMutation = { cartCreateAndAssignStudentToEnrollment?: { enrollment?: { id: string } | null | undefined } | null | undefined };

export const ParentCreateAndAssignStudentAtCheckoutDocument = gql`
  mutation ParentCreateAndAssignStudentAtCheckout($attributes: StudentInput!, $enrollmentId: ID!) @urql(additionalTypenames: ["Cart"]) {
    cartCreateAndAssignStudentToEnrollment(attributes: $attributes, enrollmentId: $enrollmentId) {
      enrollment {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentCreateAndAssignStudentAtCheckoutOptions = ExecMutationOptions<ParentCreateAndAssignStudentAtCheckoutMutationVariables>;

export function parentCreateAndAssignStudentAtCheckout(options: ParentCreateAndAssignStudentAtCheckoutOptions) {
  return executeMutation<ParentCreateAndAssignStudentAtCheckoutMutation, ParentCreateAndAssignStudentAtCheckoutMutationVariables>(ParentCreateAndAssignStudentAtCheckoutDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Cart']) }
  });
}
