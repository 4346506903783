import * as React from 'react';
import { OperationResult } from 'urql';
import { GraphQLError } from 'graphql'

import { Modal, Body, Panel, Section, InputField, FormModel, Part, DataTableHeader, DataTable, RepeatingSection, Field, DataTableColumn, modalErrorHandler, throwErrorHandler, errorToString } from 'app2/components';
import { addPathToMessage, handleErrors, errorPathTransform } from 'app2/views';

import { Demos, Demo } from './Demo';

export function ErrorDisplayDemos() {
  function render() {
    return <Demos name='Error Display'>
      <Demo name='Form'>
        <Panel title='Form' type='toggle' initialValues={initialValues} onOk={handleSubmitCase1}>
          <Section label='Name' name='name' component={InputField} />
          <Section label='Classrooms'>
            <RepeatingSection name='site.classrooms' add='Add classroom' fields={[
              <Field name='name' label='Name' component={InputField} />,
            ]} />
            <Part label='Activity days' name='courseDays' format={(courseDays:any) => courseDays.map((courseDay:any) => courseDay.days.join('')).join('')} />
          </Section>

          <Section label='Address' name='address'>
            <Body>A section for a parent value that handles errors where the error has no field present in the parent</Body>
          </Section>
          
        </Panel>
      </Demo>

      <Demo name='Form, adjusted path'>
        <Panel title='Form' type='toggle' initialValues={initialValues.site} onOk={handleSubmitCase2}>
          <Section label='Classrooms'>
            <RepeatingSection name='classrooms' add='Add classroom' fields={[
              <Field name='name' label='Name' component={InputField} />,
            ]} />
          </Section>
        </Panel>
      </Demo>

      <Demo name='Table, editable = false' omitProps={['data']}>
        <DataTableHeader icon='Book' title='Activities' width="800px" editing onSaveEdits={handleSubmitCase3}>
          <DataTable height="300px" width="800px" overflow="scroll"
            cols={cols} data={data} lockable sortable filterable
            />
        </DataTableHeader>
      </Demo>
    </Demos>
  }

  function handleSubmitCase1(form:FormModel) {
    handleErrors({handler:[form, throwErrorHandler]}, response);
    return false;
  }

  function handleSubmitCase2(form:FormModel) {
    handleErrors({handler:[form, modalErrorHandler], transform: [addPathToMessage, errorPathTransform('site.classrooms', 'classrooms')]}, response, );
    return false;
  }

  function handleSubmitCase3(table:DataTable) {
    const errorInfo = handleErrors({handler: table, transform: [addPathToMessage, errorPathTransform('site.classrooms.', '')]}, response);

    if (errorInfo.unhandled.length) {
      const messages = errorInfo.unhandled.map(e => errorToString(e));
      Modal.error('Error', messages);
    }
    return false;
    }

  return render();
}

const initialValues = {
  name: 'test', 
  courseDays: [{
    days: ['Monday']
  }],
  site: {
    classrooms: [
      {id: 'a1', name: 'c1'}, 
      {id: 'a2', name: 'c2'}
    ]
  },
  address: {
  }
}

const response:Partial<OperationResult> = {
  operation: null,
  data: {
    organizerUpsertCourses: {}
  },
  error: {
    name: 'combined error',
    message: 'combined error',
    networkError: {
      name: 'Network error',
      message: 'Network error'
    },
    graphQLErrors: [{
      message: "top level path found",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "name"
        ]
      }
    }, {
      message: "repeating section path not found",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "doesnotexist",
          "name"
        ]
      }
    }, {
      message: "nested path found by index (prefixed)",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "index-0",
          "name"
        ]
      }
    }, {
      message: "nested path found by index (no prefix)",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "0",
          "name"
        ]
      }
    }, {
      message: "nested path found by index partially",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "index-1",
          "unknownfield"
        ]
      }
    }, 
    {
      message: "Valid index path with invalid property path on non-repeating section",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "courseDays",
          "0",
          "days",
        ]
      }
    }, {
      message: "nested path found by id",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "id-a2",
          "name"
        ]
      }
    }, {
      message: "nested path found by id partially found",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "site",
          "classrooms",
          "id-a2",
          "fieldThatDoesNotExist"
        ]
      }
    }, {
      message: "path completely not found",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "dismissals",
          "id-a1",
          "name"
        ]
      }
    }, {
      message: "section error message",
      extensions: {
        code: "OBJECT_INVALID",
        path: [
          "address",
          "name"
        ]
      }
    }, {
      message: "operation error",
      extensions: {
        code: "OBJECT_INVALID",
        path: [] as string[],
      }
    }
  ] as unknown as GraphQLError[]
}}

const cols = [
  {name: 'name'},
  {name: 'classroom'}
] as DataTableColumn[]

const data = [
  {name: 'Jim', classroom: 'room blue', id: 'a1'},
  {name: 'Jane', classroom: 'room red', id: 'a2'},
]
