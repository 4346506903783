import * as React from 'react';
import * as ReactIs from 'react-is';

import { flattenChildren } from './flattenChildren';

export function keyElements(element:React.ReactNode) {
  const elements = flattenChildren(element);

  return elements.map((e, index) => ReactIs.isElement(e) ? React.cloneElement(e, {key:e.props.key || index}) : e);
}
