import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResendEnrollmentInvitesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  courseId: Types.Scalars['ID'];
}>;

export type ResendEnrollmentInvitesMutation = { enrollmentsResendInvites?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ResendEnrollmentInvitesDocument = gql`
  mutation ResendEnrollmentInvites($ids: [ID!]!, $courseId: ID!) {
    enrollmentsResendInvites(ids: $ids, courseId: $courseId) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ResendEnrollmentInvitesOptions = ExecMutationOptions<ResendEnrollmentInvitesMutationVariables>;

export function resendEnrollmentInvites(options: ResendEnrollmentInvitesOptions) {
  return executeMutation<ResendEnrollmentInvitesMutation, ResendEnrollmentInvitesMutationVariables>(ResendEnrollmentInvitesDocument, options);
}
