type ItemWithId = {id?:string};

// removes items from an array if they have no
// properties other than an id

export function removeBlanks<T extends ItemWithId>(items:T[]):T[] {
  return items.filter(o => {
    if (o === null || o === undefined || typeof o != 'object') {
      return false;
    }

    const keys = Object.keys(o);

    return keys.length > 1 || keys[0] != 'id';
  });
}
