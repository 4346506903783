import * as React from 'react';
import { useHistory } from 'react-router';
import pluralize from 'pluralize';

import { Link } from 'app2/components';
import { CourseKind, CourseKindGroup } from 'app2/api';
import { courseKindBehavior } from 'app2/views/shared-public';

interface Props {
  kind:CourseKind | CourseKindGroup;
}

export function CreateActivities(props:Props) {
  const history = useHistory();
  const behavior = courseKindBehavior[props.kind];
  const table = behavior.name;
  const target = history.location.pathname + `?edit=${table}`;
  const label = `Create ${pluralize(behavior.units.toLowerCase(), 2)}`;

  return <Link key='create-activities' button='primary' to={target}>{label}</Link>

}
