import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentReviewUpsertMutationVariables = Types.Exact<{
  review: Types.Scalars['String'];
  attributes: Types.ReviewInput;
}>;

export type ParentReviewUpsertMutation = { reviewUpsert?: { review?: { id: string } | null | undefined } | null | undefined };

export const ParentReviewUpsertDocument = gql`
  mutation ParentReviewUpsert($review: String!, $attributes: ReviewInput!) {
    reviewUpsert(review: $review, attributes: $attributes) {
      review {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentReviewUpsertOptions = ExecMutationOptions<ParentReviewUpsertMutationVariables>;

export function parentReviewUpsert(options: ParentReviewUpsertOptions) {
  return executeMutation<ParentReviewUpsertMutation, ParentReviewUpsertMutationVariables>(ParentReviewUpsertDocument, options);
}
