import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type VendorsQuery = { site?: { id: string; vendors: Array<{ id: string; displayName: string; company?: { id: string; name?: string | null | undefined } | null | undefined }> } | null | undefined };

export const VendorsDocument = gql`
  query Vendors($id: ID!) {
    site(site: $id) {
      id
      vendors {
        id
        displayName
        company {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorsArgs = MakeOptional<UseQueryArgs<VendorsQueryVariables, VendorsQuery>, 'query'>;

export function useVendorsQuery(options: VendorsArgs = {}) {
  return useQuery<VendorsQueryVariables, VendorsQuery>({ query: VendorsDocument, ...options });
}

export type VendorsOptions = ExecQueryOptions<VendorsQueryVariables>;

export function vendors(options: VendorsOptions) {
  return executeQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
}

export type VendorsSelections = VendorsQuery['site'];
