import { Rect } from '../dom-utils';

import { VirtualGrid, VirtualGridUpdateType } from "./VirtualGrid";
import { VirtualGridFactory, CellProps } from "./VirtualGridFactory";
import { MeasuredHeightFactoryHelper } from "./MeasuredHeightFactory";

// TableSectionFactory is a proxy factory that allows you to divide a factory
// into sections for the locked headers.  If you specify a negative numRows/Cols
// that will be used to subtract the number of rows/cols from the source factory.

export class TableSectionFactory implements VirtualGridFactory, MeasuredHeightFactoryHelper {
  _source:VirtualGridFactory;
  _startRow:number;
  _numRows:number;
  _startCol:number;
  _numCols:number;

  constructor(source:VirtualGridFactory, startRow:number, startCol:number, numRows:number, numCols:number) {
    this._source = source;
    this._startRow = startRow;
    this._startCol = startCol;
    this._numRows = numRows;
    this._numCols = numCols;
  }

  get source():VirtualGridFactory {
    const source = this._source.source;

    return source || this._source;
  }

  get bounds() {
    return new Rect(this.startCol, this.startRow, this.endCol, this.endRow);
  }

  get startRow() {
    return this._startRow;
  }

  get numRows():number {
    return this._numRows < 0 ? this._source.numRows + this._numRows + 1 : this._numRows;
  }

  get endRow():number {
    return this._startRow + this.numRows;
  }

  rowHeight(row:number):number {
    return this._source.rowHeight(row + this._startRow);
  }

  get startCol() {
    return this._startCol;
  }

  get numCols():number {
    return this._numCols < 0 ? this._source.numCols + this._numCols + 1 : this._numCols;
  }

  get endCol():number {
    return this._startCol + this.numCols;
  }

  colWidth(col:number):number {
    return this._source.colWidth(col + this._startCol);
  }

  render(props:CellProps):React.ReactElement {
    props.row += this._startRow;
    props.col += this._startCol;

    return this._source.render(props);
  }

  getRowHeight(row:number, useDefault:boolean):number {
    return (this._source as unknown as MeasuredHeightFactoryHelper).getRowHeight(row + this._startRow, useDefault);
  }

  measureRow(row:number, callback:() => void) {
    (this._source  as unknown as MeasuredHeightFactoryHelper).measureRow(row + this._startRow, callback);
  }

  reset(row:number = -1) {
    // we don't expect the source to have a reset
  }

  renderMeasurer():React.ReactElement {
    return null;
  }

  invalidateGrid(grid:VirtualGrid, row:number, col:number, type:VirtualGridUpdateType) {
    if (!grid) {
      return;
    }

    const inRange = (row === undefined || row - this._startRow < this.numRows) && (col === undefined || col - this._startCol < this.numCols);

    if (!inRange) {
      return;
    }

    grid.invalidate(row != -1 && row !== undefined ? row - this._startRow : undefined, col != -1 && col !== undefined ? col - this._startCol : undefined, type);
  }

  contains(row:number, col:number) {
    if (row < this._startRow || row >= this.endRow) {
      return false;
    }

    if (col < this._startCol || col >= this.endCol) {
      return false;
    }

    return true;
  }
}
