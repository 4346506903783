import { localStorageHelper } from "app/helpers";

export class GuestCart {
  static readonly GUEST_CART_TOKEN_KEY = 'hrCartGuestToken';
  static readonly GUEST_CART_TOKEN_HEADER = 'HR-GUEST-CART-TOKEN';

  public static storeToken(headers: Headers) {
    const guestCartToken = headers.get(this.GUEST_CART_TOKEN_HEADER);
    if (!guestCartToken) {
      return;
    }
    localStorageHelper.setItem(this.GUEST_CART_TOKEN_KEY, guestCartToken);
  }

  public static clearToken() {
    localStorageHelper.removeItem(this.GUEST_CART_TOKEN_KEY);
  }

  public static get headers() {
    const headers: { [key: string]: string } = {};
    const guestCartToken = localStorageHelper.getItem(this.GUEST_CART_TOKEN_KEY);
    if (guestCartToken) {
      headers[this.GUEST_CART_TOKEN_HEADER] = guestCartToken;
    }
    return headers;
  }
}
