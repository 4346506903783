import * as React from 'react';
import { useParams } from 'react-router'
import { flatten } from 'lodash-es'

import { CourseStatus, SeasonUtils } from 'app2/api';
import { CourseSchedule } from 'app2/views/shared'

import { useOrganizerPreference } from '../shared';
import { ScheduleRouteParams } from '../organizerRoutes'

import { useOrganizerScheduleSeasonQuery, useScheduleCoursesQuery } from './gql';

export function Sessions() {
  const {site, season:seasonId} = useParams<ScheduleRouteParams>();
  const [showWeekends, setShowWeekends] = useOrganizerPreference('showWeekends');

  const [seasonResult] = useOrganizerScheduleSeasonQuery({ variables: { seasonId } });
  const season = seasonResult.data?.season;

  const [result] = useScheduleCoursesQuery({ variables: { seasonId } });
  const courses = React.useMemo(() => {
    return flatten((result.data?.courses || []).
      map(c => c.children?.length ? c.children : c)).filter(c => c.status != CourseStatus.Cancelled)}, [result.data]);

  return <CourseSchedule season={season} courses={courses} weekends={showWeekends} onChangeWeekends={setShowWeekends}
    scheduleUrlBase={`/organizer/${site}/schedule/${seasonId}`} activityUrlBase={`/activities/manage`} week={SeasonUtils.nowOrSeasonBegin(season)} />
}
