import * as React from 'react'

import { buildCloudinaryImageProps, HBox, Img, Text, VBox } from 'app2/components';
import { COURSE_CARD_IMAGE_RATIO, DEFAULT_COURSE_CARD_IMG, CourseCapacity, CourseCategory, CoursePricesWithIcon, CourseDates, CourseGrades, CourseRating, CourseShareButton, PublicCourseLink, PublicSiteLink } from 'app2/views/shared-public';

import { DirectoryResult } from './DirectoryResult';
import { DirectorySiteSelections } from './generated';

interface Props {
  site:DirectorySiteSelections;
  result:DirectoryResult;
}

export function CourseResult(props:Props) {
  const course = props.result.course;

  function render() {
    return <VBox p="$16">
      <HBox gap='$8' width='100%'>
        <CourseCategory course={course} />
        <CourseGrades course={course} />
        <HBox flex={1} />
        <CourseShareButton course={course} icon />
      </HBox>
      <VBox width='100%' flex={1} text='body'>
        <HBox mb="$12">
          <Img mr="$12" {...buildCloudinaryImageProps(course?.courseCardImage || DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO, 145, 3)} />
          <VBox>
            <PublicCourseLink text='subtitle1' mb="$8" maxLines={2} course={course} underline={false} />
            <CourseDates course={course} />
            <CourseCapacity course={course} />
            <CoursePricesWithIcon course={course} />
          </VBox>
        </HBox>
        <Text text='body' maxLines={3} preserveWhitespace>{course.description}</Text>
        <HBox flex={1} minHeight='8px' />
        <HBox width='100%'>
          <span>Taught at&nbsp; <PublicSiteLink display='inline' site={course.site} underline={false} bold /></span>
          <HBox flex={1} />
          <CourseRating rating={props.result.reviewAverage} count={props.result.reviewCount} />
        </HBox>
      </VBox>
    </VBox>
  }

  return render();
}

