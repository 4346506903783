import moment from 'moment';

import { getSymbol } from './util/getSymbol';

import { handler } from './Observable';
import { ObservableSet } from './ObservableSet';
import { ObservableMap } from './ObservableMap';
import { ObservableArray } from './ObservableArray';

const observableSymbol: any = getSymbol('observable');
const observableTargetSymbol: any = getSymbol('observableTarget');

export class ObservableVisitor {
  depth: number = 0;
  cloning: boolean;
  visited: Set<any>;

  visit(obj: any) {
    const typeOf = obj ? typeof obj : null;

    if (
      !typeOf ||
      typeOf == 'number' ||
      typeOf == 'string' ||
      typeOf == 'function' ||
      typeOf == 'boolean' ||
      typeOf == 'bigint' ||
      typeOf == 'symbol' ||
      obj instanceof Date ||
      obj instanceof RegExp ||
      obj instanceof ResizeObserver ||
      obj instanceof MutationObserver ||
      moment.isMoment(obj) ||
      obj['then'] ||
      isElement(obj)
    ) {
      return obj;
    }

    if (!this.cloning) {
      const observable = obj[observableSymbol];

      if (observable) {
        return observable;
      } else {
        if (!this.visited) {
          this.visited = new Set();
        }

        if (this.visited.has(obj)) {
          return obj;
        }

        this.visited.add(obj);
      }
    }

    if (Array.isArray(obj)) {
      return this.visitArray(obj);
    }

    if (obj instanceof Set) {
      return this.visitSet(obj);
    }

    if (obj instanceof Map) {
      return this.visitMap(obj);
    }

    if (typeOf == 'object') {
      return this.visitObject(obj);
    }

    return obj;
  }

  visitArray(obj: any[]) {
    return new ObservableArray(obj);
  }

  visitSet(obj: Set<any>) {
    return new ObservableSet(obj);
  }

  visitMap(obj: Map<any, any>) {
    return new ObservableMap(obj.entries());
  }

  visitObject(obj: any) {
    if (!Object.isExtensible(obj)) {
      return obj;
    }

    const target = this.cloning
      ? Object.create(Object.getPrototypeOf(obj))
      : obj;
    const observable = new Proxy(target, handler);

    target[observableSymbol] = observable;
    target[observableTargetSymbol] = target;

    // if making an observable this makes sure existing
    // properties are observable.  else if cloning, it
    // clones the properties
    makeOwnPropertiesObservable(observable, obj);

    return observable;
  }
}

export function makeOwnPropertiesObservable(
  proxy: any,
  obj: any,
  ignoreProps?: any
) {
  const props = Object.getOwnPropertyNames(obj);

  for (const prop of props) {
    if (!ignoreProps || !ignoreProps[prop]) {
      proxy[prop] = visit(obj[prop]);
    }
  }
}

const visitor = new ObservableVisitor();

// if this is called while cloning, this will clone
// and not simply make the passed in object observable
export function makeObservable<T>(obj: any): T {
  return visit(obj);
}

export function cloneObservable<T>(obj: T): T {
  visitor.cloning = true;
  return visit(obj);
}

export function visit(obj: any) {
  ++visitor.depth;

  let result;

  try {
    result = visitor.visit(obj);
  } catch (e) {
    console.error('error during makeObservable or cloneObservable', e);
  }

  --visitor.depth;

  if (!visitor.depth) {
    visitor.visited = null;
    visitor.cloning = false;
  }

  return result;
}

function isElement(obj: any) {
  return obj && obj instanceof Element;
}
