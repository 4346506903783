import * as React from 'react';

import { EventListener, useEventListener } from './useEventListener';

export type TransitionMethods = {onTransitionStart?:EventListener<TransitionEvent>, onTransitionEnd?:EventListener<TransitionEvent>};

export function useTransitionListener(element:HTMLElement | Window, methods:TransitionMethods) {
  const animating = React.useRef(false);

  useEventListener('transitionstart', onStart, element);
  useEventListener('transitionend', onEnd, element);

  function onStart(event:TransitionEvent) {
    animating.current = true;
    methods.onTransitionStart?.(event);
  }

  function onEnd(event:TransitionEvent) {
    animating.current = false;
    methods.onTransitionEnd?.(event);
  }

  return animating;
}
