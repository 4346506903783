export const byDismissal = {
  title: [
    {cols:['dismissal', 'weekday', 'variables.date', 'groups'], separator: ' - '},
    {cols:['course.season.name', 'course.site.name'], separator: '; '}
  ],
  groups: ['dismissal', 'weekday', 'groups'],
  // only one column sort is supported for now
  sort: ['formattedDays', 'course.name'],
  cols: ['formattedDays', 'formattedTimes', 'course.name', 'course.room', 'student.firstName', 'student.lastName', 
    'student.grade',  'rosterPeriod', 'added', 'student.formattedContacts', 'student.authorizedPickupContacts', 'student.formattedMedicalAndNotes'],
}
