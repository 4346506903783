import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ArchiveCourseFrameworkMutationVariables = Types.Exact<{
  items: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type ArchiveCourseFrameworkMutation = { courseFrameworkArchive?: { success?: boolean | null | undefined } | null | undefined };

export const ArchiveCourseFrameworkDocument = gql`
  mutation ArchiveCourseFramework($items: [String!]!) @urql(additionalTypenames: ["Company"]) {
    courseFrameworkArchive(items: $items) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ArchiveCourseFrameworkOptions = ExecMutationOptions<ArchiveCourseFrameworkMutationVariables>;

export function archiveCourseFramework(options: ArchiveCourseFrameworkOptions) {
  return executeMutation<ArchiveCourseFrameworkMutation, ArchiveCourseFrameworkMutationVariables>(ArchiveCourseFrameworkDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Company']) }
  });
}
