import * as React from 'react'

import { Box, BoxProps, VBox } from '../Box';
import { useBreakpoints } from '../breakpoints';
import { getScrollbarWidth } from '../dom-utils';
import { useInterval, useLifecycle, useForceUpdate } from '../utils';

import { ScrollAxis, ScrollbarProps } from './Scrollbar'
import { StandardScrollbar } from './StandardScrollbar';

// allows a scrollable div that has custom scrollbars
// the height and width need to be determined somehow
// either by explicitly setting them or being in a 
// flex container, else the natural sizing will take over
// and no scrolling will occur.

export interface ScrollerProps extends Omit<BoxProps, 'px'> {
  horizontal?:React.ComponentType<ScrollbarProps>;
  vertical?:React.ComponentType<ScrollbarProps>;
  scrollbarSize?:number;
  px?:number | number[];
  scrollArea?:React.RefObject<HTMLElement>;
  // set to false to allow scrolling but hide scrollbars
  scrollbars?:boolean;
}

export function Scroller(props:ScrollerProps) {
  let {css, height, width, onScroll, overflow, vertical:Vertical, horizontal:Horizontal, scrollbarSize, pt, pb, py, px, scrollArea, children, scrollbars, ...remaining} = props;
  const breakpoint = useBreakpoints();
  const padding = (Array.isArray(px) ? px[breakpoint] : px) || 0;
  const defaultScrollbarSize = React.useMemo(() => getScrollbarWidth(), []);

  if (!scrollbarSize) {
    scrollbarSize = defaultScrollbarSize;
  }

  const lastScrollAreaUpdate = React.useRef(0);
  const lastScrollbarUpdate = React.useRef(0);
  const [vertSize, setVertSize] = React.useState(0);
  const [horzSize, setHorzSize] = React.useState(0);
  const forceUpdate = useForceUpdate();

  const scrollVert = React.useRef<HTMLElement>();
  const scrollHorz = React.useRef<HTMLElement>();
  const scrollAreaRef = scrollArea || React.useRef<HTMLElement>();
  const scrollAreaRect = scrollAreaRef.current?.getBoundingClientRect();
  const scrollable = overflow != 'hidden';
  const needsVert = scrollbars && scrollable && scrollAreaRef.current?.scrollHeight > scrollAreaRect?.height;
  const needsHorz = scrollbars && scrollable && scrollAreaRef.current?.scrollWidth > scrollAreaRect?.width;
  const vertScrollbarDim = needsHorz ? 'calc(100% - ' + (scrollbarSize) + 'px)' : '100%';
  const horzScrollbarDim = needsVert ? 'calc(100% - ' + (scrollbarSize) + 'px)' : '100%';
  const scrollAreaHeight = 'calc(100% - ' + (needsHorz ? scrollbarSize : 0) + 'px)';
  const scrollAreaWidth = 'calc(100% - ' + ((needsVert ? scrollbarSize : 0) + (padding * 2)) + 'px)';

  useLifecycle({onMount, onUpdate});
  // cant use ResizeObserver to detect changes to scroll width so we have to poll
  useInterval(onScrollResize, 1000);

  function render() {
    // the id below is important and used by the scroll to code to detect when its scrolling the main area
    return <Box position='relative' overflow='hidden' height={height} width={width}>
      <VBox ref={scrollAreaRef} id='Page' mx={padding + 'px'} py={py} pb={pb} overflow="auto" onScroll={onScrollAreaScroll} css={{...css, overscrollBehaviorX: 'none', scrollbarWidth:'none', '::-webkit-scrollbar': {width: '0px', height: '0px'}}} width={scrollAreaWidth} height={scrollAreaHeight} {...remaining}>
        <Box pt={pt} />
        {children}
      </VBox>
      {scrollable && 
        <>
          <Vertical axis={ScrollAxis.vertical} ref={scrollVert} position='absolute' top={0} right={0} height={vertScrollbarDim} size={vertSize} scrollbarSize={needsVert ? scrollbarSize : 0} onScroll={onVScrollbarScroll} opacity={needsVert ? 1 : 0} />
          <Horizontal axis={ScrollAxis.horizontal} ref={scrollHorz} position='absolute' left={0} bottom={0} width={horzScrollbarDim} size={horzSize} scrollbarSize={needsHorz ? scrollbarSize : 0} onScroll={onHScrollbarScroll} opacity={needsHorz ? 1 : 0} />
        </>}
    </Box>
  }

  function onMount() {
    onScrollAreaScroll();
  }

  function onUpdate() {
    onScrollAreaScroll();
  }

  function onVScrollbarScroll(start:number) {
    if (isUpdateFromScrollArea()) {
      return;
    }

    const scrollTop = (start / vertSize) * scrollAreaRef.current.scrollHeight;

    if (scrollAreaRef.current.scrollTop == scrollTop) {
      return;
    }

    scrollAreaRef.current.scrollTop = scrollTop;
    onScrollbarScroll();
  }

  function onHScrollbarScroll(start:number) {
    if (isUpdateFromScrollArea()) {
      return;
    }

    const scrollLeft = (start / horzSize) * scrollAreaRef.current.scrollWidth;

    if (scrollAreaRef.current.scrollLeft == scrollLeft) {
      return;
    }

    scrollAreaRef.current.scrollLeft = scrollLeft
    onScrollbarScroll();
  }

  function onScrollbarScroll() {
    setUpdateFromScrollBar();
    // setting the scrollleft/top will generate
    // a scroll event, but it's delayed, so we generate
    // one now, to avoid scroll drawing issues
    scrollAreaRef.current.dispatchEvent(new Event('scroll'))
  }

  function onScrollAreaScroll() {
    if (isUpdateFromScrollBar()) {
      return;
    }

    setUpdateFromScrollArea();

    if (scrollHorz.current) {
      scrollHorz.current.scrollLeft = (scrollAreaRef.current.scrollLeft / scrollAreaRef.current.scrollWidth) * horzSize;
    }

    if (scrollVert.current) {
      scrollVert.current.scrollTop = (scrollAreaRef.current.scrollTop / scrollAreaRef.current.scrollHeight) * vertSize
    }

    onScrollResize();
  }

  function onScrollResize() {
    if (!scrollAreaRef.current) {
      return;
    }

    const updatedScrollAreaRect = scrollAreaRef.current?.getBoundingClientRect();

    if (scrollHorz.current) {
      const newHorzSize = (scrollAreaRef.current.scrollWidth / scrollAreaRef.current.getBoundingClientRect().width) * scrollHorz.current.getBoundingClientRect().width;

      if (horzSize != newHorzSize) {
        scrollHorz.current.scrollLeft = (scrollAreaRef.current.scrollLeft / scrollAreaRef.current.scrollWidth) * newHorzSize;
        setHorzSize(newHorzSize);
      }
    }

    if (scrollVert.current) {
      const newVertSize = (scrollAreaRef.current.scrollHeight / scrollAreaRef.current.getBoundingClientRect().height) * scrollVert.current.getBoundingClientRect().height;

      if (vertSize != newVertSize) {
        scrollVert.current.scrollTop = (scrollAreaRef.current.scrollTop / scrollAreaRef.current.scrollHeight) * newVertSize;
        setVertSize(newVertSize);
      }
    }

    if (scrollAreaRect?.width != updatedScrollAreaRect?.width || scrollAreaRect?.height != updatedScrollAreaRect?.height) {
      forceUpdate();
    }
  }

  function setUpdateFromScrollBar() {
    lastScrollbarUpdate.current = Date.now();
  }

  function isUpdateFromScrollBar() {
    // setting the scrollbar's scrollleft/top will cause us
    // to get events but they will be behind the scrolling
    // of the scroll area.  we want to detect this and ignore 
    // it/not copy it back to the scroll area else it will 
    // cause flicking
    return Date.now() - lastScrollbarUpdate.current < 500;
  }

  function setUpdateFromScrollArea() {
    lastScrollAreaUpdate.current = Date.now();
  }

  function isUpdateFromScrollArea() {
    // similar to above, but for the setting scroll area
    return Date.now() - lastScrollAreaUpdate.current < 500;
  }

  return render();
}

Scroller.defaultProps = {
  horizontal:StandardScrollbar,
  vertical:StandardScrollbar,
  scrollbars:true
}

Scroller.displayName = 'Scroller'