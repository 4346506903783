import { differenceBy } from 'lodash-es'

type ItemWithDeleteFlagBase = {id?:string};
type ItemWithDeleteFlag<T extends ItemWithDeleteFlagBase, PropName extends string> = T & {[P in PropName]:boolean}

export function addDeletedItems<T extends ItemWithDeleteFlagBase, P extends string>(original:T[], updated:T[], prop:P):ItemWithDeleteFlag<T, P>[] {
  const removed = differenceBy(original, updated, 'id')
    .map(item => {return {...item, [prop]: true}}) as ItemWithDeleteFlag<T, P>[];

  const kept = updated
    ?.map(item => {return {...item, [prop]: false}}) as ItemWithDeleteFlag<T, P>[] || [];

  return kept.concat(removed);
}