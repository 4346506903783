import * as React from 'react'

import { BoxProps, Box } from '../Box';
import { useLifecycle } from '../utils';
import { RectInterface } from '../dom-utils/Rect';

import { embedMessageManager } from './EmbedMessageManager';

// the viewport component ensures anything inside of it stays on the
// the visible area.  this is only needed in embed mode, where the
// embeded frame might be larger than the screen (such that the embed
// frame scrolls with the main page content).  otherwise you don't need
// this and can use normal css.

type SetViewport = (r:RectInterface) => any;
let viewport:RectInterface;

const viewports:SetViewport[] = [];

export function Viewport(props:BoxProps) {
  const [vp, setVp] = React.useState(viewport);

  useLifecycle({onMount, onUnmount});

  function onMount() {
    viewports.push(setVp);
  }
  
  function onUnmount() {
    viewports.splice(viewports.indexOf(setVp), 1);
  }

  return <Box position='fixed' top={0} left={0} width='100%' height='var(--app-height, 100vh)' zIndex={1} pointerEvents='none' css={{'> *':{pointerEvents: 'all'}}} {...viewport} {...props} />
}

function onViewportChange(data:RectInterface) {
  viewport = data;
  document.documentElement.style.setProperty('--viewport-top', viewport.top + 'px')
  document.documentElement.style.setProperty('--viewport-height', viewport.height + 'px')
  viewports.forEach(setViewport => setViewport(data));
}

embedMessageManager.registerHandler('viewport-change', onViewportChange);
