import * as React from 'react'

import { useLifecycle } from './useLifecycle';

export function useInterval(cb:() => void, interval:number) {
  const intervalInfo = React.useRef({id: undefined, interval: 0, cb});
  useLifecycle({onUnmount});
  intervalInfo.current.cb = cb;

  if (intervalInfo.current.interval != interval || !intervalInfo.current.id) {
    addInterval();
  }

  function onUnmount() {
    removeInterval();
  }

  function addInterval() {
    removeInterval();

    // set the interval to the imeddiate onInterval
    // so we can use the latest callback
    intervalInfo.current.id = setInterval(onInterval, interval);
    intervalInfo.current.interval = interval;
  }

  function removeInterval() {
    if (!intervalInfo.current.id) {
      return;
    }

    clearInterval(intervalInfo.current.id);
    intervalInfo.current.id = undefined;
  }

  function onInterval() {
    intervalInfo.current.cb();
  }

  return addInterval;
}
