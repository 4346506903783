import * as React from 'react'

import { UserUtils } from 'app2/api';

import { useCurrentUser } from '../user';
import { ShareButton, ShareButtonProps } from '../ShareButton';

interface Props extends Partial<ShareButtonProps> {
  course:{
    name?:string;
    id:string;
  }
}

export function CourseShareButton(props:Props) {
  const {course, ...remaining} = props;

  const { user } = useCurrentUser();
  const shareQSParams = UserUtils.buildShareQSParams(user);
  const link = `${window.location.protocol}//${window.location.host}/activities/${course.id}${shareQSParams ? '?' + shareQSParams : ''}`
  const subject = `Join us in ${course.name} on Homeroom!`;
  const message = `I just found this course, ${course.name}, on Homeroom and thought you might be interested, too!`;

  return <ShareButton event='clickShareIconOnPublicCoursePage' link={link} subject={subject} message={message} {...remaining} />
}
