import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MergeFamiliesMutationVariables = Types.Exact<{
  source: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
}>;

export type MergeFamiliesMutation = { familiesMerge?: { family?: { id: string; name?: string | null | undefined } | null | undefined } | null | undefined };

export const MergeFamiliesDocument = gql`
  mutation MergeFamilies($source: ID!, $destination: ID!) {
    familiesMerge(source: $source, destination: $destination) {
      family {
        id
        name
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MergeFamiliesOptions = ExecMutationOptions<MergeFamiliesMutationVariables>;

export function mergeFamilies(options: MergeFamiliesOptions) {
  return executeMutation<MergeFamiliesMutation, MergeFamiliesMutationVariables>(MergeFamiliesDocument, options);
}
