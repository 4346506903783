import { capitalize } from 'lodash-es'

import { DateInput } from './DateInput'

const daysOfWeek = new Set(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);

export function isDayOfWeek(dateOrText:DateInput) {
  return typeof dateOrText == 'string' && daysOfWeek.has(dateOrText.toLocaleLowerCase());
}

export function parseDayOfWeek(dayOfWeek:DateInput) {
  if (!isDayOfWeek(dayOfWeek)) {
    return undefined;
  }

  return capitalize(String(dayOfWeek).toLocaleLowerCase().trim());
}

