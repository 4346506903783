import { captureSentry } from 'app/helpers';

import { scriptManager } from '../ScriptManager';
import { Sentry } from './Sentry';
import { SentryShim } from './SentryShim';

declare global {
  interface Window {
    Sentry: Sentry;
  }
}

const sentryUrl = 'https://browser.sentry-cdn.com/5.12.0/bundle.min.js';
let sentryShim: SentryShim | undefined;

export function loadSentry() {
  window.Sentry = sentryShim = new SentryShim();

  if (captureSentry()) {
    scriptManager.register(sentryUrl, true, onLoadSentry);
  }
}

function onLoadSentry() {
  window.Sentry.init({
    dsn: process.env.SENTRY_DSN,
    // try to surpress errors being generated by champions marketing agency
    ignoreErrors:[
      'ReferenceError: jQuery is not defined',
      'jQuery is not a function',
      "ReferenceError: Can't find variable: jQuery",
      "Can't find variable: jQuery"
    ],
    denyUrls: [
      /.+\.affirm\.com/i,
      /beacon\-v2\.helpscout\.net/i,
      /.+\.cloudinary.com/i,
    ],
  });

  if (sentryShim) {
    sentryShim.applyShimCalls();
    sentryShim = undefined;
  }
}
