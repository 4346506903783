import * as React from 'react'

import { Box, Button, Heading1, HBox, IconNames, MultiContextProvider, Panel, Point, Saveable, SaveableProps, TabStripTab, TabStripHookProps, useHashScrollWatcher, useNavigateToHash, useTabStrip, VBox } from 'app2/components'

import { WizardSteps } from './WizardSteps';

export interface WizardPage extends TabStripTab {
  icon?:IconNames;
  content:React.ReactNode;
  // either pass in a string and the prev/next
  // or true to indicate standard button and the
  // behavior will be automatic, or pass in any
  // react element and use the WizardApi to implement
  // the desired behavior
  next?:string | boolean | React.ReactElement<any>;
  prev?:string | boolean | React.ReactElement<any>;
  actions?:React.ReactNode;
}

export interface WizardProps extends TabStripHookProps, SaveableProps {
  tabs:WizardPage[];
  steps?:boolean;
  title?:boolean;
  type?:'page' | 'scroll' | 'collapse' | 'hide';
  history?:'push' | 'replace';
}

export interface WizardApi {
  next():void;
  prev():void;
  pages:WizardPage[];
  selected:number;
  page(page:number):void;
}

export interface WizardContext {
  wizard:WizardApi;
}

export const Wizard = React.forwardRef((props:WizardProps, ref) => {
  const nodeRef = React.useRef<HTMLDivElement>();
  const {tabs, steps, title, type, history, selected:selectedProp, onChange, pref, prefName, baseUrl, urlParameter, preserveQuery, ...remaining} = props;
  let {selectedTab:selected, normalizedTabs, selectTab:setSelected} = useTabStrip(props);
  const pages = normalizedTabs as WizardPage[];

  const usingHash = props.urlParameter == 'hash';
  const collapseOrHide = type == 'collapse' || type == 'hide';

  if (usingHash) {
    useHashScrollWatcher(nodeRef.current, pages.map(p => p.name), onScrolledToPage);
  }

  useNavigateToHash(null, new Point(20, 75));

  const api = { selected, pages, next, prev, page };
  React.useImperativeHandle(ref, () => api);

  const maxVisited = React.useRef(selected);
  const mb = styles[type].mb;
  const invisibleProps = {opacity:0, pointerEvents:'none' as 'none'}

  function render() {
    return <MultiContextProvider wizard={api}>
      <Box ref={nodeRef}>
        {steps && <WizardSteps sticky />}
        {type == 'page' ? renderSinglePage() : renderAll()}
      </Box>
    </MultiContextProvider>  
  }

  function renderSinglePage() {
    return renderOpen(selected);
  }

  function renderAll() {
    return pages.map((p, index) => {
      if (type == 'scroll') {
        return renderOpen(index)
      }
      else
      if (index == maxVisited.current) {
        return renderOpen(index)
      }
      else
      if (index < maxVisited.current) {
        return renderSeen(index);
      }
      else {
        return renderUnseen(index);
      }
    });
  }

  function renderOpen(pageNo:number) {
    return <Saveable key={pageNo} buttons={renderButtons(pageNo)} onActionComplete={onActionComplete} mb={mb} width='100%' id={pages[pageNo].name} {...remaining}>
      <VBox height='100%' overflow='auto'>
        {renderTitle(pageNo)}
        {pages[pageNo].content}
      </VBox>
    </Saveable>
  }

  function renderSeen(pageNo:number) {
    return <Saveable key={pageNo} buttons={[renderInvisibleButton()]} onActionComplete={onActionComplete} mb={mb} width='100%' id={pages[pageNo].name} {...remaining}>
      <VBox height='100%'>
        {renderTitle(pageNo)}
        {pages[pageNo].content}
      </VBox>
    </Saveable>
  }

  function renderUnseen(pageNo:number) {
    // we hide the unseen steps because we still want the browser
    // to be able trigger coming to it if its on the url when the
    // page opens, so it needs an element with the right id for that to happen
    const maybeHide = type == 'hide' ? invisibleProps : undefined;

    return <Saveable key={pageNo} buttons={[renderInvisibleButton()]} onActionComplete={onActionComplete} mb={mb} width='100%' id={pages[pageNo].name} {...maybeHide} {...remaining}>
      <VBox height='100%'>
        {renderTitle(pageNo)}
        {renderCollapsed(pageNo)}
      </VBox>
    </Saveable>
  }

  function renderTitle(pageNo:number) {
    const page = pages[pageNo];
    const label = title && <Heading1 mb='$4' flex={1}>{page.label}</Heading1>;
    const actions = page.actions;
    
    return label && actions
      ? <HBox width='100%' vAlign='center'>{label}{actions}</HBox>
      : label || actions
  }

  function renderCollapsed(pageNo:number) {
    return <Panel id={pages[pageNo].name} />
  }

  function renderButtons(pageNo:number) {
    return [renderPrev(pageNo), renderNext(pageNo)];
  }

  function renderPrev(pageNo:number) {
    const button = pages[pageNo].prev;
    return !button
      ? ''
      : button === true
        ? <Button kind='secondary' onClick={prev} disabled={pageNo == 0}>Previous</Button>
        : typeof button == 'string'
          ? <Button kind='secondary' onClick={prev} disabled={pageNo == 0}>{button}</Button>
          : button
  }

  function renderNext(pageNo:number) {
    const button = pages[pageNo].next;
    return !button
      ? ''
      : button === true
        ? <Button>Next</Button>
        : typeof button == 'string'
          ? <Button>{button}</Button>
          : button
  }

  // for collapse mode we render invisible buttons so that
  // when going past a step you don't see a jump that would
  // be caused by going from buttons to no buttons
  function renderInvisibleButton() {
    return <Button {...invisibleProps}>Nothing</Button>;
  }

  function prev():void {
    goto(selected - 1);
  }

  function next():void {
    if (selected < normalizedTabs.length) {
      updateMax(selected + 1);
      goto(selected + 1);
    }
  }

  function onActionComplete(button:number):void {
    if (button == 0) {
      return
    }

    updateMax(selected + 1);
    goto(selected + 1);
  }

  // externally exposed and updates the max visited
  function page(page:number):void {
    updateMax(page);
    goto(page);
  }

  // internal
  function goto(page:number):void {
    if (page == selected) {
      return;
    }

    setSelected(page);
  }

  function onScrolledToPage(page:number):void {
    if (collapseOrHide) {
      page = Math.min(page, maxVisited.current);
    }

    if (page == selected) {
      return;
    }

    setSelected(page, {initiatedFromHashWatcher: true});
  }

  function updateMax(page:number) {
    maxVisited.current = Math.max(maxVisited.current, page);
  }

  return render();
})

const styles = {
  page: {
    mb: undefined as string
  },
  scroll: {
    mb: '$120'
  },
  collapse: {
    mb:'$20' as string
  },
  hide: {
    mb:'$20' as string
  }
};


Wizard.defaultProps = {
  urlParameter: 'hash',
  type: 'page'
}

Wizard.displayName = 'Wizard';

