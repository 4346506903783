import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteClearStripeTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SiteClearStripeTokenMutation = { siteClearStripeToken?: { id: string } | { id: string } | null | undefined };

export const SiteClearStripeTokenDocument = gql`
  mutation SiteClearStripeToken($id: ID!) {
    siteClearStripeToken(site: $id) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SiteClearStripeTokenOptions = ExecMutationOptions<SiteClearStripeTokenMutationVariables>;

export function siteClearStripeToken(options: SiteClearStripeTokenOptions) {
  return executeMutation<SiteClearStripeTokenMutation, SiteClearStripeTokenMutationVariables>(SiteClearStripeTokenDocument, options);
}
