import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { BoxProps, HBox, Link } from '.';

const SEPARATOR = '\xa0>\xa0';

export function buildBreadCrumbStr(crumbs: BreadCrumb[]) {
  return crumbs.reduce((acc, val, idx, arr) => {
    const isLast = idx == arr.length - 1;
    const separator = isLast ? '' : SEPARATOR;

    acc += `${val.label}${separator}`;

    return acc;
  }, '');
}

export interface BreadCrumb {
  label: string;
  url: string;
}

export interface BreadCrumbProps extends BoxProps, RouteComponentProps {
  crumbs?: BreadCrumb[];
  separator?: string;
}

export function InnerBreadCrumbs(props: BreadCrumbProps) {
  let {
    crumbs,
    separator,
    history,
    location,
    match,
    staticContext,
    ...remaining
  } = props;

  if (!crumbs) {
    const folders = props.location.pathname.split('/');
    const root = folders.splice(0, 1)[0];

    crumbs = [];

    folders.reduce((prev, folder) => {
      const path = prev + '/' + folder;
      crumbs.push({ label: folder, url: path });
      return path;
    }, root);
  }

  return (
    <HBox text="subtitle2" {...remaining}>
      {crumbs.map((crumb, index) => {
        const last = index == crumbs.length - 1;
        const fontWeight = last ? 'bold' : 'normal';
        const separator = last ? '' : props.separator;

        return (
          <span key={index}>
            <Link fontWeight={fontWeight} text='subtitle2' underline={false} to={crumb.url}>
              {crumb.label}
            </Link>
            {separator}
          </span>
        );
      })}
    </HBox>
  );
}

InnerBreadCrumbs.defaultProps = {
  separator: SEPARATOR
};

export const BreadCrumbs = withRouter(InnerBreadCrumbs);
