import * as React from 'react'

import { EnrollmentUtils, Enrollment } from 'app2/api'
import { FieldInfo, Tag, TagProps } from 'app2/components'

// three ways to use this
// - bind it to an enrollment via a field, and it will only show if the enrollment is waitlisted
// - pass in an enrollment, and it will only show if the enrollment is waitlisted
// - pass in nothing and it will alway show the waitlists badge

interface Props extends TagProps {
  info?:FieldInfo<Pick<Enrollment, 'waitlistKind'>>;
  enrollment?:Pick<Enrollment, 'waitlistKind'>;
  cart: boolean;
}

export function WaitlistBadge(props:Props) {
  const {info, enrollment:enrollmentProp, cart, ...remaining} = props;
  const enrollment = enrollmentProp || info?.record;
  const waitlisted = !enrollment || (props.cart ? EnrollmentUtils.waitlistUnfinalized(enrollment) : EnrollmentUtils.waitlistFinalized(enrollment));

  if (!waitlisted) {
    return <></>;
  }

  return <Tag icon={false} small color='white' bg='#C77801' {...remaining}>Waitlisted</Tag>;
}

WaitlistBadge.defaultProps = {
  cart: false
};

WaitlistBadge.fieldProps = {
  fieldProperty: 'info',
}
