import * as React from 'react';

import { Box, BoxProps } from '../components';
import { useDownload, useNodeListeners } from './dom-utils';

// this will request the given url and render its contents
// including the head tag.  it basically the same as an iframe
// without the sandboxing

interface Props extends BoxProps {
  src:string;
}

export function ExternalHtml(props:Props) {
  const {src, ...remaining} = props;
  const {data:html} = useDownload(src);
  const listeners = useNodeListeners();
  const ref = React.useRef<HTMLDivElement>(null);
  const headNodes = React.useRef<ChildNode[]>([]);
  const bodyNodes = React.useRef<ChildNode[]>([]);

  React.useEffect(() => {
    removeAll();

    if(!html) {
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    headNodes.current = Array.from(doc.head.childNodes);
    bodyNodes.current = Array.from(doc.body.childNodes);

    appendNodes(document.head, headNodes.current);
    listeners.listen(headNodes.current, onHeadNodesLoaded);

    return () => removeAll;
  }, [html]);

  function removeAll() {
    clearNodes(headNodes.current);
    clearNodes(bodyNodes.current);
    listeners.unlisten();
  }

  function onHeadNodesLoaded() {
    appendNodes(ref.current, bodyNodes.current);
  }

  function appendNodes(target:HTMLElement, nodes:Node[]) {
    nodes.forEach(node => {
      target.appendChild(node);
    });
  };

  function clearNodes(nodes:Node[]) {
    for (const node of nodes) {
      if (node.parentNode) {
        node.parentNode.removeChild(node);
      }
    }
  };
  
  return <Box ref={ref} overflow='auto' {...remaining} />
}
