import * as React from 'react';

import { hasAppMode } from '../AppModes';
import { useResizeObserver } from '../utils/useResizeObserver';
import { useTimeout } from '../utils/useTimeout';

import { embedMessageManager } from './EmbedMessageManager';

// a hook that automatically will report when a component resizes
// when in embed mode.  used for resizing a frame to the size of the content
// this should be your scrollable area

export function useEmbedModeUpdateSize(page:React.RefObject<HTMLElement>) {
  const embedded = hasAppMode('embed') && hasAppMode('resize');
  const size = React.useRef({height:0, width:0});
  const timeout = useTimeout(checkSize, 500);
  useResizeObserver(page, onResize);

  if (!embedded) {
    return false;
  }

  if (!page?.current) {
    return true;
  }

  function onResize() {
    if (size.current.width == page.current.scrollWidth && size.current.height == page.current.scrollHeight && 
      // in emebed mode the page always grows to the size of the frame which means that unless we recompute
      // the page height (by removing the 100%) each time, we can't tell if we need to shrink.  we use the 
      // first child an an approximation of the content height, with some affordance of margins and padding
      getChildrenHeight() < page.current.scrollHeight - 100) {
      return true;
    }

    checkSize();

    // need to check the size again because for some reason it seems like the
    // the layout changes after loading things but we're not getting triggered
    // here, so we can't update the size
    timeout.reset();

    return true;
  }

  function getChildrenHeight() {
    const children = Array.from(page.current.children);
    let height = 0;

    children.forEach((child) => {
      height += child.getBoundingClientRect().height;
    });

    return height;
  }

  function checkSize() {
    // we have force a min size to get the true scroll height because the outer
    // container might be causing us to stretch
    const height = page.current.style.height;

    page.current.style.height = '1px';

    size.current = {width: page.current.scrollWidth, height: page.current.scrollHeight};

    page.current.style.height = height;

    embedMessageManager.sendMessage('resize', size.current);
    
    return true;
  }

  return onResize();
}
