import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type HideQuickStartGuideMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type HideQuickStartGuideMutation = { companyUpdate?: { company?: { id: string } | null | undefined } | null | undefined };

export const HideQuickStartGuideDocument = gql`
  mutation HideQuickStartGuide($company: ID!) {
    companyUpdate(company: $company, hideQuickStartGuide: true) {
      company {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type HideQuickStartGuideOptions = ExecMutationOptions<HideQuickStartGuideMutationVariables>;

export function hideQuickStartGuide(options: HideQuickStartGuideOptions) {
  return executeMutation<HideQuickStartGuideMutation, HideQuickStartGuideMutationVariables>(HideQuickStartGuideDocument, options);
}
