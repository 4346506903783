import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SeasonsQuery = { site?: { id: string; seasons: Array<{ id: string; name: string; status: Types.SeasonStatus; site: { id: string } }> } | null | undefined };

export const SeasonsDocument = gql`
  query Seasons($id: ID!) {
    site(site: $id) {
      id
      seasons {
        id
        name
        status
        site {
          id
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SeasonsArgs = MakeOptional<UseQueryArgs<SeasonsQueryVariables, SeasonsQuery>, 'query'>;

export function useSeasonsQuery(options: SeasonsArgs = {}) {
  return useQuery<SeasonsQueryVariables, SeasonsQuery>({ query: SeasonsDocument, ...options });
}

export type SeasonsOptions = ExecQueryOptions<SeasonsQueryVariables>;

export function seasons(options: SeasonsOptions) {
  return executeQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
}

export type SeasonsSelections = SeasonsQuery['site'];
