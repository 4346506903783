import * as React from 'react'
import { useParams } from 'react-router'

import { FormModel } from 'app/app2/components'
import { BaseAfterActivity } from 'app2/views/shared-public'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerSiteAfterActivityPoliciesQuery } from './gql'

interface Props {
    handleOk: (form:FormModel) => void;
}

export function AfterActivity(props: Props) {
  const {site} = useParams<SiteRouteParams>();
  const [policiesResult] = useOrganizerSiteAfterActivityPoliciesQuery({ variables: { siteId: site } })
  const data = policiesResult.data?.site?.policy;

  return <BaseAfterActivity type='toggle' initialValues={data} onOk={form => props.handleOk(form)} />
}
