import * as React from 'react'
import pluralize from 'pluralize'

import { EntityKind } from 'app2/api';
import { Button, HBox, longDateFormatter, Panel, Text, VBox } from 'app2/components';

import { ContactModal, PublicSiteLink } from 'app2/views/shared-public';

import { DirectoryResult } from './DirectoryResult';

interface Props {
  result:DirectoryResult;
}

export function SiteResult(props:Props) {
  const result = props.result;
  const site = result.site;

  const distance = result.distance > 100 ? '' : `${result.distance} ${pluralize('miles', result.distance)} away`;

  function render() {
    return <Panel text='body' p="$16" m={0} vItemSpace='16px'
      title={<PublicSiteLink text='subtitle1' underline={false} site={site} />} 
      subtitle={`${site.city}, ${site.state} ${site.zipcode}`} primaryActions={distance}>
        <HBox>
          <VBox flex={1}>
            <Text text='formlabel' bold>Subjects taught</Text>
            <Text text='body'>{site.directoryCourseTags.map(t => t.name).join(', ')}</Text>
          </VBox>
          {/* <VBox flex={1} hAlign='right'>
            <Text text='formlabel' bold>Most recent activities</Text>
            <Text text='body'>{longDateFormatter(site.latestSeason.coursesFinish)}</Text>
          </VBox> */}
        </HBox>
        <div><Button onClick={renderModal}>Contact</Button></div>
    </Panel>
  }

  function renderModal() {
    return <ContactModal to={site.name} toId={site.id} toKind={EntityKind.Site}
        message={`Hi, I'm interested in teaching activities at ${site.name}`} />
  }

  return render();
}

