import * as React from 'react'

import { Course } from 'app2/api';
import { Box, BoxProps, DateRangeText, commaAnd, renderDelimitedList } from 'app2/components';

import { getUniqueTimes } from './getUniqueTimes';
import { CourseDaysDisplay } from './CourseDaysDisplay';

type CourseWithDates = Pick<Course, 'startDate' | 'endDate' | 'courseDays'>

// displays start/end and is for camps

interface Props extends BoxProps {
  course?:CourseWithDates;
  time?:boolean;
}

export function CourseDatesDisplay(props:Props) {
  const {course, time, ...remaining} = props;

  function render() {
    return <Box display='inline-block' color='primary' {...remaining}>
      <DateRangeText from={course.startDate} to={course.endDate} />
      {time && renderTimes()}
    </Box>
  }

  function renderTimes() {
    const days = getUniqueTimes(course.courseDays);

    return days.length > 1
      ? <CourseDaysDisplay value={course.courseDays} />
      : renderDelimitedList(days, commaAnd, day => <> from <span>{day.start}</span> to <span>{day.finish}</span></>)
  }

  return render();
}

CourseDatesDisplay.fieldProps = {
  valueProperty: 'course'
}