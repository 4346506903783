import * as React from 'react';
import { omitBy, isUndefined } from 'lodash-es';

import { BoxProps, HBox } from './Box';
import { Button, ButtonProps } from './Button';
import { Option, OptionValue, SelectOptionInterface, useSelectOptions } from './Option';
import { createOrClone, TypePropsOrElement } from './utils';

import { ButtonStripButton } from './ButtonStripButton';

interface ButtonOption extends Option, Omit<ButtonProps, 'text' | 'value'> {
}

export interface ButtonStripProps extends BoxProps {
  small?:boolean;
  options?:(string | ButtonOption)[];
  multiple?:boolean;
  separate?:boolean;
  selected?:OptionValue[];
  renderer?:TypePropsOrElement<typeof Button>;
  onChange?:React.ChangeEventHandler<SelectOptionInterface>;
}

function ButtonStripC(props:ButtonStripProps, ref:React.MutableRefObject<HTMLElement>) {
  const {small, options:optionsProp, multiple, selected, onChange:onChangeProp, error, disabled, renderer: component, separate, ...remaining} = props;
  const {options, isSelected, onChange} = useSelectOptions(props.multiple, props, ref);

  function render() {
    return <HBox ref={ref} borderRadius='standard' itemFlex={1} hItemSpace='-1px' overflow='hidden' data-test='ButtonStrip' pr={separate ? '2px' : undefined} gap={separate ? '$8' : undefined} {...remaining} onBlur={onBlur}>
      {options.map(renderButton)}
    </HBox>
  }

  function renderButton(option:ButtonOption, index:number) {
    const selected = isSelected(option.value);
    const {label, value, text, tag, disabled, ...remaining} = option;
    const renderer = props.renderer || (separate ? Button : ButtonStripButton);
    const buttonProps = omitBy({key:index, kind: separate ? 'secondary' : undefined, small, error, disabled:props.disabled || option.disabled, selected, onClick:(event:any) => onChange(event, option.value)}, isUndefined);

    return createOrClone(Button, {...buttonProps, ...remaining, children: option.label}, renderer)
  }

  function onBlur(event:React.FocusEvent) {
    // if the thing getting the focus is another
    // button in the stripe, don't broadcast the onBlur
    const strip = event.currentTarget;
    if (strip.contains(event.relatedTarget as HTMLElement)) {
      event.stopPropagation();
      return;
    }

    props.onBlur?.(event as any);
  }

  return render();
}

ButtonStripC.fieldProps = {
  valueProperty: 'selected',
  errorProperty: 'error',
  disabledProperty: 'disabled',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur'
}

export const ButtonStrip = React.forwardRef(ButtonStripC as any) as React.ForwardRefExoticComponent<ButtonStripProps> & {fieldProps:any};

ButtonStrip.defaultProps = {
  maxWidth: '100%',
  small: true
}

ButtonStrip.fieldProps = ButtonStripC.fieldProps;
ButtonStrip.displayName = 'ButtonStrip';

