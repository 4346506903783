import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface Props {
  info?:FieldInfo<{id:string}>;
  event?:{id:string};
  label?:string;
}

export function EventLink(props:Props) {
  const event = props.event || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/events/${event?.id}`}>{props.label || event?.id}</Link>
}

EventLink.fieldProps = {
  fieldProperty: 'info',
}