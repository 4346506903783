import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorTeacherComplianceQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
  teacherId: Types.Scalars['ID'];
}>;

export type VendorTeacherComplianceQuery = {
  vendorTeacherCompliance?:
    | Array<
        | {
            expiresAt?: string | null | undefined;
            id: string;
            status?: string | null | undefined;
            definition: { complianceType: string; id: string; instructions?: string | null | undefined; name: string; downloads?: Array<{ id: string; name: string } | null | undefined> | null | undefined };
            uploads?: Array<{ id: string; name: string } | null | undefined> | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const VendorTeacherComplianceDocument = gql`
  query VendorTeacherCompliance($vendor: ID!, $teacherId: ID!) {
    vendorTeacherCompliance(vendor: $vendor, teacherId: $teacherId) {
      definition {
        complianceType
        downloads {
          id
          name
        }
        id
        instructions
        name
      }
      expiresAt
      id
      status
      uploads {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorTeacherComplianceArgs = MakeOptional<UseQueryArgs<VendorTeacherComplianceQueryVariables, VendorTeacherComplianceQuery>, 'query'>;

export function useVendorTeacherComplianceQuery(options: VendorTeacherComplianceArgs = {}) {
  return useQuery<VendorTeacherComplianceQueryVariables, VendorTeacherComplianceQuery>({ query: VendorTeacherComplianceDocument, ...options });
}

export type VendorTeacherComplianceOptions = ExecQueryOptions<VendorTeacherComplianceQueryVariables>;

export function vendorTeacherCompliance(options: VendorTeacherComplianceOptions) {
  return executeQuery<VendorTeacherComplianceQuery, VendorTeacherComplianceQueryVariables>(VendorTeacherComplianceDocument, options);
}

export type VendorTeacherComplianceSelections = VendorTeacherComplianceQuery['vendorTeacherCompliance'][0];

(VendorTeacherComplianceDocument as any).additionalTypenames = ['ComplianceRequirement'];
