import * as React from 'react';

import { providerPreferences } from 'app2/api';
import { Dropdown, HBox, Subtitle1 } from 'app2/components';
import { createOptions, useDropdownWithUrl } from 'app2/views/shared-public';

import { useProviderCompaniesQuery } from './generated'

export function CompanyDropdown() {
  const query = useProviderCompaniesQuery();
  const companies = query[0].data?.user?.companies;
  const options = React.useMemo(() => createOptions(companies), [companies]);
  const valueAndOnChange = useDropdownWithUrl({options, preferences: providerPreferences, urlParameter: 'company', valueParameter: 'slug'});

  const fetching = Boolean(query[0].fetching || !valueAndOnChange.value);
  const onlyOne = Boolean(options.length == 1 && valueAndOnChange.value == options?.[0]?.value)

  return fetching
    ? <></>
    : onlyOne
      ? <Subtitle1 text='subtitle2'>{options[0].label}</Subtitle1>
      : <HBox flex={1} minWidth='280px' vAlign='center' hAlign='right'>
        <Subtitle1 mr='$8'>Provider</Subtitle1>
        <Dropdown flex={[1, 'unset', 'unset']} options={options} {...valueAndOnChange} tooltips
          // @ts-ignore - ts is incorrectly generating an error here
          width={['unset', '300px', '300px']} />
      </HBox>
}
