import * as React from 'react'
import { useParams } from 'react-router';

import { Button, CurrencyField, DateField, Field, formatCurrency, Link, LinkField, FieldRendererProps, NotificationManager, RepeatingSectionFieldProps, useSafeState } from 'app2/components';

import { QueryList, QueryApi } from '../QueryList';
import { AdminSiteRouteParams } from '../../adminRoutes';

import { Payout } from './Payout';
import { adminProcessPayout } from './generated';

interface Props {
  query:QueryApi;
  to:string;
  variables?:any;
  showSites?:boolean;
  showTarget?:boolean;
  action?:RepeatingSectionFieldProps<any>;
}

export function Payouts(props:Props) {
  const { id, subid } = useParams<AdminSiteRouteParams>();
  const [entity, setEntity] = useSafeState({pendingPayoutTotal:0});

  function render() {
    if (subid) {
      return <Payout />
    }

    return <QueryList icon='DollarSign' title={`Payouts ($${entity?.pendingPayoutTotal || 0} pending)`} query={props.query} variables={props.variables} name='payouts' onQuery={entity => setTimeout(() => setEntity(entity as any))} fields={[
      <Field name='id' label='ID' component={LinkField} readOnly to={`${props.to}${id ? '/' + id : ''}/payouts/$value`} />,
      <Field name='scheduledAt' label='Scheduled at' component={DateField} />,
      <Field name='amount' label='Amount' component={CurrencyField} />,
      <Field name='status' label='Status' />,
      <Field name='paymentServiceId' label='Payment service id' component={LinkField} readOnly to='https://dashboard.stripe.com/connect/transfers/$value' />,
      props.showTarget ? <Field name='target.name' label='Target' /> : undefined,
      props.showSites ? <Field name='sites' label='Site' format={(val:{name:string}[]) => val.map(s => s.name).join(', ')} /> : undefined,
      <Field name='target.stripeAccount' label='Stripe account' render={renderStripeAccount} />,
      <Field name='lastError' label='Error' />,
      <Field render={renderProcess} />,
      props.action
    ]} />
  }

  function renderStripeAccount(props:FieldRendererProps) {
    return <Link to={`https://dashboard.stripe.com/connect/accounts/${props.info?.value}`}>{props.info?.value}{props.info.record.stripeBalance ? ` (${formatCurrency(props.info.record.stripeBalance)})` : ''}</Link>
  }

  function renderProcess(props:FieldRendererProps) {
    return props.info?.value?.status != 'pending' && props.info?.value?.status != 'retriable_error' && props.info?.value?.status != 'failed'
      ? <></>
      : <Button onClick={() => processPayout(props.info?.value?.id)}>Process</Button>
  }

  async function processPayout(payout:string) {
    const [success] = await adminProcessPayout({variables:{payout}});

    if (success) {
      NotificationManager.add('Payout processing initiated');
    }
  }

  return render();
}

Payouts.defaultProps = {
  showSites: true
}
