import { CourseDay, CourseDayName, DAY_ABBREVIATION_3_LETTER } from 'app2/api';

import { formatTime } from 'app/app2/components';

// formats a course day to text (for displaying in 
// a label, input or for copy/paste)

export function formatCourseDays(days:CourseDay[]) {
  if (!days) {
    return '';
  }

  return days.map(day => {
    return `${day.days?.map?.(day => DAY_ABBREVIATION_3_LETTER[day as CourseDayName]).join?.(', ')} from ${formatTime(day?.start)} to ${formatTime(day?.finish)}`
  }).join('; ');
}
