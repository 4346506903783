import { isRealProduction } from './environments';

export function imageFolderRoot() {
  return isRealProduction() ? 'prod' : 'dev'
}

export function companyFolder(id:number | string) {
  return `${imageFolderRoot()}/companies/${id}`
}

export function siteFolder(id:number | string) {
  return `${imageFolderRoot()}/sites/${id}`
}
