import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserAddRoleMutationVariables = Types.Exact<{
  role: Types.UserRole;
}>;

export type UserAddRoleMutation = { userAddRole?: { user?: { id: string } | null | undefined } | null | undefined };

export const UserAddRoleDocument = gql`
  mutation UserAddRole($role: UserRole!) {
    userAddRole(role: $role) {
      user {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UserAddRoleOptions = ExecMutationOptions<UserAddRoleMutationVariables>;

export function userAddRole(options: UserAddRoleOptions) {
  return executeMutation<UserAddRoleMutation, UserAddRoleMutationVariables>(UserAddRoleDocument, options);
}
