import * as React from 'react';

import { colId, Dropdown, DropdownProps, DataTableColumn } from 'app2/components';

interface Props extends Omit<DropdownProps, 'options'> {
  cols:DataTableColumn[];
}

export function GroupByPicker(props:Props) {
  const options = getOptions();

  function render() {
    return <Dropdown clearable {...props} options={options} multiple />
  }

  function getOptions() {
    return React.useMemo(() => {
      return props.cols.filter(c => !c.hidden).map(col => ({
        label: col.label,
        value: colId(col)
      }))
    }, [props.cols]);
  }

  return render();
}

export type GroupByPickerType = Dropdown