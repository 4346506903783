import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SingleAdminCompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SingleAdminCompanyQuery = { company?: { id: string; name?: string | null | undefined; nonPartnerApproved?: boolean | null | undefined; stripeAccount?: string | null | undefined } | null | undefined };

export const SingleAdminCompanyDocument = gql`
  query SingleAdminCompany($id: ID!) {
    company(company: $id) {
      id
      name
      nonPartnerApproved
      stripeAccount
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SingleAdminCompanyArgs = MakeOptional<UseQueryArgs<SingleAdminCompanyQueryVariables, SingleAdminCompanyQuery>, 'query'>;

export function useSingleAdminCompanyQuery(options: SingleAdminCompanyArgs = {}) {
  return useQuery<SingleAdminCompanyQueryVariables, SingleAdminCompanyQuery>({ query: SingleAdminCompanyDocument, ...options });
}

export type SingleAdminCompanyOptions = ExecQueryOptions<SingleAdminCompanyQueryVariables>;

export function singleAdminCompany(options: SingleAdminCompanyOptions) {
  return executeQuery<SingleAdminCompanyQuery, SingleAdminCompanyQueryVariables>(SingleAdminCompanyDocument, options);
}

export type SingleAdminCompanySelections = SingleAdminCompanyQuery['company'];
