import { Point, Rect } from '../dom-utils'

import { DataTable } from '.';
import { SelectionIterator, SelectionIteratorPredicate } from './SelectionIterator';

enum AnchorRow {
  top,
  bottom
}

enum AnchorCol {
  left,
  right
}

export enum Anchor {
  topLeft,
  topRight,
  bottomLeft,
  bottomRight
}

export class Selection {
  _table:DataTable;
  topLeft:Point;
  bottomRight:Point;
  anchorRowType:AnchorRow;
  anchorColType:AnchorCol;

  constructor(table:DataTable, topLeft:Point = undefined, bottomRight:Point = undefined, anchorRowType:AnchorRow = AnchorRow.top, anchorColType:AnchorCol = AnchorCol.left) {
    this._table = table;
    this.topLeft = topLeft ? topLeft.clone() : new Point();
    this.bottomRight = (bottomRight || this.topLeft).clone();
    this.anchorRowType = anchorRowType;
    this.anchorColType = anchorColType;

    this.makeValid();
  }

  get table() {
    return this._table;
  }

  set table(value:DataTable) {
    this._table = value;
    this.makeValid();
  }

  clone() {
    return new Selection(this._table, this.topLeft, this.bottomRight, this.anchorRowType, this.anchorColType);
  }

  equal(other:Selection) {
    return this.topLeft.equal(other.topLeft) && this.bottomRight.equal(other.bottomRight) && 
      (this.singleCellSelected || (this.anchorRowType == other.anchorRowType && this.anchorColType == other.anchorColType));
  }

  iterate(predicate:SelectionIteratorPredicate, start?:Point) {
    return SelectionIterator.iterate(this, predicate, start);
  }

  map(predicate:SelectionIteratorPredicate) {
    return SelectionIterator.map(this, predicate);
  }

  get headers() {
    return this.cols.map((col:number) => {
      const label = this.table.getColumn(col).label;
      return typeof label == 'string' ? label : '';
    });
  }

  // returnss the top left formatted value
  get formattedValue() {
    return this.formattedValues[0][0];
  }
  
  get formattedValues():string[][] {
    return this.map(this.getFormattedValue);
  }

  getFormattedValue = (iterator:SelectionIterator) => {
    const field = this.table.getFieldForCopyPaste(iterator.row, iterator.col, false);
    const info = this.table.getCellInfo(iterator.row, iterator.col);

    // use the copy function if available, else use the format function, else
    // use the raw value.  reject result that is an object and move to the 
    // next method when needed
    const copiedValue = [field.copy, field.format, (value:any) => value]
      .map(fn => fn?.(info.value, field, info))
      .filter(val => val !== undefined && val !== null && typeof val != 'object')[0]

    return copiedValue === undefined ? '' : String(copiedValue);
}

  get rawValues():any[][] {
    return this.map(this.getRawValue);
  }

  getRawValue = (iterator:SelectionIterator) => {
    return this.table.getCellValue(iterator.row, iterator.col);
  }

  get topRight():Point {
    return new Point(this.bottomRight.col, this.topLeft.row);
  }

  get bottomLeft():Point {
    return new Point(this.topLeft.col, this.bottomRight.row);
  }

  get anchorRow():number {
    return this.anchorRowType == AnchorRow.top ? this.topLeft.row : this.bottomRight.row;
  }

  get anchorCol():number {
    return this.anchorColType == AnchorCol.left ? this.topLeft.col : this.bottomRight.col;
  }

  get anchor():Point {
    return new Point(this.anchorCol, this.anchorRow);
  }

  set anchor(value:Point) {
    if (this.anchorRowType == AnchorRow.top) {
      this.topLeft.row = value.row;
    }
    else {
      this.bottomRight.row = value.row;
    }

    if (this.anchorColType == AnchorCol.left) {
      this.topLeft.col = value.col;
    }
    else {
      this.bottomRight.col = value.col;
    }
  }

  get focusRow():number {
    return this.anchorRowType == AnchorRow.bottom ? this.topLeft.row : this.bottomRight.row;
  }

  get focusCol():number {
    return this.anchorColType == AnchorCol.right ? this.topLeft.col : this.bottomRight.col;
  }

  get focus():Point {
    return new Point(this.focusCol, this.focusRow);
  }

  set focus(value:Point) {
    if (this.anchorRowType == AnchorRow.bottom) {
      this.topLeft.row = value.row;
    }
    else {
      this.bottomRight.row = value.row;
    }

    if (this.anchorColType == AnchorCol.right) {
      this.topLeft.col = value.col;
    }
    else {
      this.bottomRight.col = value.col;
    }
  }

  get normalizedRect():Rect {
    const tl = this.topLeft;
    const br = this.bottomRight;

    return new Rect(tl.x, tl.y, br.x, br.y);
  }

  get numRows():number {
    return this.bottomRight.y < 0 || this.topLeft.y < 0 ? 0 : this.bottomRight.y - this.topLeft.y + 1;
  }

  get numCols():number {
    return this.bottomRight.x < 0 || this.topLeft.x < 0 ? 0 : this.bottomRight.x - this.topLeft.x + 1;
  }

  get atRowEnd() {
    return this.focus.col == this._table.numCols - 1;
  }

  get atRowBegin() {
    return this.focus.col == 0;
  }

  get atColEnd() {
    return this.focus.row == this._table.numRows - 1;
  }

  get atEnd() {
    return this.atRowEnd && this.atColEnd;
  }

  selectAll() {
    this.anchorRowType = AnchorRow.top;
    this.anchorColType = AnchorCol.left;

    this.topLeft = new Point(0, 0);
    this.bottomRight = new Point(this._table.numCols - 1, this._table.numRows - 1);

    return this;
  }

  selectCol(col:number, extend:boolean = false) {
    if (extend) {
      this.anchor = new Point(this.anchorCol, 0);
      
    }
    else {
      this.moveTo(new Point(col, 0));
    }

    this.extendTo(new Point(col, this._table.numRows - 1));

    return this;
  }

  selectRow(row:number, extend:boolean = false) {
    if (extend) {
      this.anchor = new Point(0, this.anchorRow);
      
    }
    else {
      this.moveTo(new Point(0, row));
    }

    this.extendTo(new Point(this._table.numCols - 1, row));

    return this;
  }

  moveOrExtendTo(pos:Point, extend:boolean = false) {
    return !extend ? this.moveTo(pos) : this.extendTo(pos);
  }

  moveTo(pos:Point) {
    this.topLeft = pos.clone();
    this.anchorRowType = AnchorRow.top;
    this.anchorColType = AnchorCol.left;
    this.makeValid();
    this.collapse();

    return this;
  }

  extendTo(pos:Point) {
    this.focus = pos.clone();
    this.makeValid();

    return this;
  }

  moveOrExtendLeft(extend:boolean, moveToFarMost:boolean = false) {
    return !extend ? this.moveLeft(moveToFarMost) : this.extendLeft(moveToFarMost);
  }

  moveLeft(moveToFarMost:boolean = false) {
    this.anchor = this.anchor.offset(moveToFarMost ? -this.anchor.x : -1, 0);
    this.makeValid();
    this.collapse();

    return this;
  }
  
  extendLeft(moveToFarMost:boolean = false) {
    this.focus = this.focus.offset(moveToFarMost ? -this.focus.x : -1, 0);
    this.makeValid();

    return this;
  }

  moveOrExtendUp(extend:boolean, moveToFarMost:boolean = false) {
    return !extend ? this.moveUp(moveToFarMost) : this.extendUp(moveToFarMost);
  }

  moveUp(moveToFarMost:boolean = false) {
    this.anchor = this.anchor.offset(0, moveToFarMost ? -this.anchor.y : -1);
    this.makeValid();
    this.collapse();

    return this;
  }

  extendUp(moveToFarMost:boolean = false) {
    this.focus = this.focus.offset(0, moveToFarMost ? -this.focus.y : -1);
    this.makeValid();

    return this;
  }

  moveOrExtendRight(extend:boolean, moveToFarMost:boolean = false) {
    return !extend ? this.moveRight(moveToFarMost) : this.extendRight(moveToFarMost);
  }

  moveRight(moveToFarMost:boolean = false) {
    this.anchor = this.anchor.offset(moveToFarMost ? this._table.numCols - this.anchor.x : 1, 0);
    this.makeValid();
    this.collapse();

    return this;
  }

  extendRight(moveToFarMost:boolean = false) {
    this.focus = this.focus.offset(moveToFarMost ? this._table.numCols - this.focus.x : 1, 0);
    this.makeValid();

    return this;
  }

  moveOrExtendDown(extend:boolean, moveToFarMost:boolean = false) {
    return !extend ? this.moveDown(moveToFarMost) : this.extendDown(moveToFarMost);
  }

  moveDown(moveToFarMost:boolean = false) {
    this.anchor = this.anchor.offset(0, moveToFarMost ? this._table.numRows - this.anchor.y : 1);
    this.makeValid();
    this.collapse();

    return this;
  }

  extendDown(moveToFarMost:boolean = false) {
    this.focus = this.focus.offset(0, moveToFarMost ? this._table.numRows - this.focus.y : 1);
    this.makeValid();

    return this;
  }

  moveTab(shift:boolean) {
    if (shift) {
      return this.moveTabLeft()
    }
    else {
      return this.moveTabRight();
    }
  }

  moveTabLeft() {
    if (this.atRowBegin) {
      this.moveRight(true);
      this.moveUp();
    }
    else {
      this.moveLeft();
    }

    return this;
  }

  moveTabRight() {
    if (this.atRowEnd) {
      this.moveLeft(true);
      this.moveDown();
    }
    else {
      this.moveRight();
    }

    return this;
  }

  moveEnter(shift:boolean) {
    if (shift) {
        this.moveUp();
    }
    else {
      this.moveDown();
    }
    
    return this;
  }

  offset(x:number, y:number = 0) {
    this.topLeft.col += x;
    this.topLeft.row += y;
    this.bottomRight.col += x;
    this.bottomRight.row += y;

    this.makeValid();
  }

  collapse() {
    const anchor = this.anchor;
    this.topLeft = anchor;
    this.bottomRight = anchor.clone();
  }

  containsPoint(pt:Point) {
    return this.containsRow(pt.row) && this.containsCol(pt.col);
  }

  containsRow(row:number) {
    return row >= this.topLeft.row && row <= this.bottomRight.row;
  }

  containsCol(col:number) {
    return col >= this.topLeft.col && col <= this.bottomRight.col;
  }

  get rows():number[] {
    if (this.empty) {
      return [];
    }

    const rows:number[] = [];

    for (let pos = this.topLeft.row; pos <= this.bottomRight.row; ++pos) {
      rows.push(pos);
    }

    return rows;
  }

  get items() {
    return this.rows.map(row => this.table.data.getItem(row));
  }

  get cols():number[] {
    if (this.empty) {
      return [];
    }

    const cols:number[] = [];

    for (let pos = this.topLeft.col; pos <= this.bottomRight.col; ++pos) {
      cols.push(pos);
    }

    return cols;
  }

  get multipleRowsSelected() {
    return this.topLeft.row != this.bottomRight.row;
  }

  get multipleColsSelected() {
    return this.topLeft.col != this.bottomRight.col;
  }

  get allSelected() {
    return this.allRowsSelected && this.allColsSelected;
  }

  get allRowsSelected() {
    return !this.empty && this.topLeft.row == 0 && this.bottomRight.row == this._table.numRows - 1;
  }

  get allColsSelected() {
    return !this.empty && this.topLeft.col == 0 && this.bottomRight.col == this._table.numCols - 1;
  }

  get singleCellSelected() {
    return this.numRows == 1 && this.numCols == 1;
  }

  get empty() {
    return this.numRows <= 0 || this.numCols <= 0;
  }

  get valid() {
    return !this.invalid;
  }

  get invalid() {
    return this.topLeft.row < 0 || this.topLeft.row > this._table.numRows - 1 ||
      this.topLeft.col < 0 || this.topLeft.col > this._table.numCols - 1 ||
      this.bottomRight.row < 0 || this.bottomRight.row > this._table.numRows - 1 ||
      this.bottomRight.col < 0 || this.bottomRight.col > this._table.numRows - 1;
  }

  // clips the selection to the valid table bounds
  makeValid() {
    if (!this._table) {
      return this;
    }

    this.topLeft.row = Math.min(Math.max(0, this.topLeft.row), this._table.numRows - 1);
    this.topLeft.col = Math.min(Math.max(0, this.topLeft.col), this._table.numCols - 1);
    this.bottomRight.row = Math.min(Math.max(0, this.bottomRight.row), this._table.numRows - 1);
    this.bottomRight.col = Math.min(Math.max(0, this.bottomRight.col), this._table.numCols - 1);

    return this.normalize();
  }

  // moves the selection to be one cell at 0, 0 (assuming there are rows and columns)
  reset() {
    this.topLeft.row = 0;
    this.topLeft.col = 0;
    this.bottomRight.row = 0;
    this.bottomRight.col = 0;

    return this.makeValid();
  }

  private normalize() {
    if (this.topLeft.row > this.bottomRight.row) {
      const row = this.topLeft.row;
      this.topLeft.row = this.bottomRight.row;
      this.bottomRight.row = row;
      this.anchorRowType = this.anchorRowType == AnchorRow.top ? AnchorRow.bottom : AnchorRow.top;
    }

    if (this.topLeft.col > this.bottomRight.col) {
      const col = this.topLeft.col;
      this.topLeft.col = this.bottomRight.col;
      this.bottomRight.col = col;
      this.anchorColType = this.anchorColType == AnchorCol.left ? AnchorCol.right : AnchorCol.left;
    }

    return this;
  }
}
