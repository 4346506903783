import moment from 'moment';

import { DateInput } from '../date-utils'
import { compareTimes, TimeParts } from '../TimePicker'

import { FieldValidator, FieldValidatorResult } from './FieldModel';
import { FieldInfo } from './FormModel';

// basic required field validation
// can be assigned as a validator by itself (e.g. fieldValidators: {firstName: required}), and it will attempt to create a display name from the field name
// can be called to create a required validator with a custom display name (e.g. fieldValidators: {firstName: required('First name is reqiured')})
export function required<T, P extends keyof T>(message: string): FieldValidator<T, P>;
export function required<T, P extends keyof T>(value: any, info: FieldInfo<any, any>): FieldValidatorResult;
export function required<T, P extends keyof T>(value: string, info?: FieldInfo<any, any>): FieldValidatorResult | FieldValidator<T, P> {
  return info
    ? validateRequired(undefined, value, info)
    : customRequired(value as string);
}

required.required = true;

function customRequired<T, P extends keyof T>(message?: string): FieldValidator<T, P> {
  const fn = function(value: T[P], info: FieldInfo<T, P>) {
    return validateRequired(message, value, info);
  };

  // hack that allows forms to know if there's a custom required method
  fn.required = true;

  return fn;
}

function validateRequired(message: string, value: any, info: FieldInfo<any, any>): FieldValidatorResult {
  const required = typeof info.required == 'function' ? info.required(info) : info.required;
  const missing =
    value === undefined ||
    value === null ||
    value === '' ||
    (value === false && info.boolean) || //checking false is important for required checking of checkboxes but not for radio
    (Array.isArray(value) && value.length == 0);
  return missing && required
    ? message && typeof message == 'string'
      ? message
      : 'Required'
    : true;
}

validateRequired.required = true;

export function isRequiredValidator(fn:any) {
  return fn && fn.required == true;
}

export function makeRequiredValidator(fn:any) {
  fn.required = true;
  return fn;
}

export function numberRequired(value: any, info: FieldInfo<any, any>) {
  return Number.isFinite(value) || 'Must be number';
}

export function greaterThanZero(value: any, info: FieldInfo<any, any>) {
  return (Number.isFinite(value) && value > 0) || 'Must be greater than 0';
}

export function mustBePositive(value: any, info: FieldInfo<any, any>) {
  return (Number.isFinite(value) && value >= 0) || 'Must be a positive number';
}

export function greaterThan(field: string, msg: string) {
  return function(value: any, info: FieldInfo<any, any>) {
    if (!value) {
      return;
    }

    const otherValue = info.form.values[field];
    return value > otherValue || msg;
  }
}

// from https://stackoverflow.com/questions/16242449/regex-currency-validation
// Decimal and commas optional
const currency = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,2})?$/

export function isCurrency(value:string) {
  return currency.test(value);
}

export function validateCurrency(value:string, info: FieldInfo<any, any>) {
  return value && !isCurrency(value)
    ? 'Must be a valid amount'
    : undefined;
}

// checks to see if a value is a currency or a partial currency
// partial currency is used for testing as the user is typing in values
// but the value might still be incomplete (such as $123,)
const currencyLike = /^-?\$?[0-9,]*(\.\d?\d?)?$/

export function isCurrencyLike(value:string) {
  return currencyLike.test(value);
}

const numberLike = /^\-?[0-9,]*(\.\d?\d?)?$/

export function isNumberLike(value:string) {
  return numberLike.test(value);
}

export function validateEmail(value: any, info: FieldInfo<any, any>) {
  return value && !isValidEmail(String(value).trim())
    ? 'Invalid email address'
    : undefined
}

export function validatePhone(value: any, info: FieldInfo<any, any>) {
  return value && !isValidPhone(value) 
    ? 'Invalid phone number, must be 10 digits'
    : undefined;
}

export function validUrl(url: string) {
  return url && !isValidURL(url)
    ? 'Must be a valid URL including https://'
    : undefined;
}

export function validateZipcode(zipcode: string) {
  return zipcode && !isValidZipcode(zipcode) 
    ? 'Invalid zipcode, must be a 5 digit number'
    : undefined;
}

export function validateDateRange<T = any>(startName:keyof T, endName:keyof T, info: FieldInfo<T, keyof T>, prefix:string) {
  const start = info.record?.[startName] as unknown as DateInput;
  const end = info.record?.[endName] as unknown as DateInput;
  const startHasFocus = info.name == startName;

  return validateDateRangeValues(start, end, startHasFocus, prefix);
}

export function validateDateRangeValues(start:DateInput, end:DateInput, startHasFocus:boolean, prefix:string) {
  return !start || !end || moment(end).isSameOrAfter(start)
    ? true
    : startHasFocus
      ? prefix + ' start must be before the end'
      : prefix + ' end must be after the start';
}
export function validateTime(time:string) {
  const m = moment(time, 'h:mm A');

  return m.isValid()
    ? 'Invalid time'
    : undefined;
}

export function validateTimeRange<T = any>(startName:keyof T, endName:keyof T, info: FieldInfo<T, keyof T>, prefix:string, allowEqual:boolean = true) {
  const start = info.form.getValue([], startName) as unknown as TimeParts | string;
  const end = info.form.getValue([], endName) as unknown as TimeParts | string;
  const startHasFocus = info.name == startName;

  return validateTimeRangeValues(start, end, startHasFocus, prefix, allowEqual);
}

export function validateTimeRangeValues(start:TimeParts | string, end:TimeParts | string, startHasFocus:boolean, prefix:string, allowEqual:boolean = true) {
  const compareTo = allowEqual ? 0 : -1;
  return !start || !end || compareTimes(start, end) <= compareTo
    ? true
    : startHasFocus
      ? prefix + ' start must be before the end'
      : prefix + ' end must be after the start';
}

export function validateCancel(value:string) {
  return value?.toLowerCase() == 'cancel'
    ? null
    : 'You must type cancel to confirm you want to proceed with canceling'
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/i;
export const ZIPCODE_REGEX = /^[0-9]{5}([- /]?[0-9]{4})?$/i;
export const PASSWORD_REGEX = /(?=.*[0-9])(?=.*[a-z])/i;
export const URL_REGEX = /^(https?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
export const EIN_REGEX = /^\d{2}\-\d{7}$/;


export const isValidEmail = (email:string) => new RegExp(EMAIL_REGEX).test(email)
export const isValidPhone = (phone:string) => new RegExp(PHONE_REGEX).test(phone)
export const isValidZipcode = (zipcode:string) => new RegExp(ZIPCODE_REGEX).test(zipcode)
export const isValidPassword = (password:string) => new RegExp(PASSWORD_REGEX).test(password)
export const isValidURL = (url:string) => new RegExp(URL_REGEX).test(url)
export const isValidEin = (ein:string) => new RegExp(EIN_REGEX).test(ein)

export function validatePassword(password:string) {
  const PASSWORD_REGEX = /(?=.*[0-9])(?=.*[a-z])/i;

  if (!PASSWORD_REGEX.test(password)) {
    return 'Invalid password. Must have at least one number and one letter';
  }

  return password?.length > 7 || 'Password must have at least 8 characters';
}

export function validatePasswordsMatch(confirmation:string, info:FieldInfo<any>) {
  return confirmation == info.form.getValue([], 'password') || 'Passwords do not match';
}

