import * as React from 'react';

import { Link, Navigation, VBox, theme } from 'app2/components';
import { Page, PageProps, useCurrentUser } from 'app2/views/shared-public';
import { adminLinks } from 'app2/views/admin/adminLinks';
import { organizerLinks } from 'app2/views/organizer/organizerLinks';
import { companyLinks } from 'app/app2/views/provider/companyLinks';
import { parentLinks } from 'app2/views/parent/parentLinks';
import { teacherLinks } from 'app2/views/teacher/teacherLinks';

import { AppSearchDropdown } from '../search';

export interface PrivatePageProps extends Omit<PageProps, 'navigation'> {
  navigation?: boolean;
}

export function PrivatePage(props: PrivatePageProps) {
  const { navigation, header:propsHeader, ...remaining } = props;
  
  const { user } = useCurrentUser();
  const header = propsHeader === false
    ? false
    : propsHeader === true
      ? <AppSearchDropdown />
      : <><AppSearchDropdown />{propsHeader}</>;

  // the hbox's around the status and actions make it so that for multi-line titles
  // (such as can happen in the activity page) that the status and actions center
  // align to the first line of text vs all lines of text
  function render() {
    return <Page header={header} navigation={renderNavigation()} pb={theme.space.pageBottom} css={css} {...remaining} />
  }

  function renderNavigation() {
    if (!navigation) {
      return;
    }

    if (!user) {
      return <Navigation />;
    }

    const links = [] as React.ReactElement[];

    if (user.roles?.organizer) {
      links.push(organizerLinks);
    }

    if (user.roles?.provider) {
      links.push(companyLinks);
    }

    if (user.roles?.parent) {
      links.push(parentLinks);
    }

    if (user.roles?.course) {
      links.push(teacherLinks);
    }

    if (user.roles?.admin) {
      links.push(adminLinks);
    }

    return <Navigation footer={renderBetaPanel()}>
      {links.map((link, index) => React.cloneElement(link, { key: index }))}
    </Navigation>;
  }

  function renderBetaPanel() {
    return <VBox width='100%' p={['$30', '$60', '$30']} bg='brandDark' vItemSpace='$20'>
      <Link to='https://homeroom.helpscoutdocs.com/' icon='HelpCircle' color='navigationLink' alignItems='flex-start' target='_blank'>Help desk</Link>
      <Link to='mailto:hello@homeroom.com?subject=Feedback' icon='MessageSquare' underline={false} color='navigationLink' alignItems='flex-start'><span>Give&nbsp;<u>feedback</u>&nbsp;on the new look</span></Link>
    </VBox>
  }

  return render();
}

PrivatePage.defaultProps = {
  navigation:true
}

// clear the last children of margin because
// we want a consistent 160/120/80px padding at the bottom

const css = {
  '> :last-child': {
    mb: '0px!important',
  },
  '> :last-child > :last-child': {
    mb: '0px!important',
  },
  '> :last-child > :last-child > :last-child': {
    mb: '0px!important',
  },
  '> :last-child > :last-child > :last-child > :last-child': {
    mb: '0px!important',
  }
}