import * as React from 'react'
import { useParams } from 'react-router';

import { ContactInput } from 'app2/api'
import { FormModel } from 'app2/components'
import { BaseEmergencyContacts } from 'app/app2/views/shared-public'
import { addDeletedItems } from 'app/app2/views/shared'

import { SiteRouteParams } from '../organizerRoutes'

import { useOrganizerEmergencyContactsQuery, OrganizerEmergencyContactsSelections } from './gql'
import { organizerUpsertSiteContacts, OrganizerUpsertSiteContactsMutationVariables } from './gql'

export function EmergencyContacts() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [ emergencyContactsQueryResult ] = useOrganizerEmergencyContactsQuery({variables: { siteId }});
  const site = emergencyContactsQueryResult.data?.site;

  function render() {
    return <BaseEmergencyContacts type='toggle' initialValues={site} onOk={onOk} />
  }

  async function onOk(form:FormModel<OrganizerEmergencyContactsSelections>) {
    const variables: OrganizerUpsertSiteContactsMutationVariables = { siteId: site.id, contacts: addDeletedItems(site.contacts, form.values.contacts, '_destroy') as ContactInput[] }
    const [success] = await organizerUpsertSiteContacts({variables, successMsg: "Emergency Contacts updated successfully.", error: form})

    return success;
  }

  return render();
}