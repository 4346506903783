import * as React from 'react';

import { Modal, useForm } from 'app2/components';

import { CartCourse } from './Cart';
import { EnrollmentConfigurationForm } from './EnrollmentConfigurationForm';

interface EnrollmentConfigurationProps {
  course: CartCourse;
}

export function EnrollmentConfigurationModal(props: EnrollmentConfigurationProps) {
  const course = props.course;
  const form = useForm({}, [course]);

  function render() {
    return <Modal title={`Enroll in ${course.name}`}>
      <EnrollmentConfigurationForm course={course} form={form} stepped />
    </Modal>;
  }

  return render();
}
