import * as React from 'react';
import moment from 'moment'

import { Schedule, HBox, VBox, Subtitle2, Body } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function ScheduleDemos() {
  const [date, setDate] = React.useState(moment());
  const [weekends, setWeekends] = React.useState(true);

  return <Demos name='Calendar'>
    <Demo name='Basic'>
      <Schedule appointments={appointments} weekends={true} />
    </Demo>

    <Demo name='No weekends'>
      <Schedule appointments={appointments} weekends={false} />
    </Demo>

    <Demo name='Controlled'>
      <Schedule appointments={appointments} week={date} onChange={(date) => setDate(date)} weekends={weekends} onChangeWeekends={weekends => setWeekends(weekends)} />
      <HBox>Selected date: {date.format('MM/DD/YYYY')}</HBox>
      <HBox>Show weekends: {weekends.toString()}</HBox>
    </Demo>

    <Demo name='Width'>
      <Schedule appointments={appointments} maxWidth='700px' />
    </Demo>
  </Demos>
}

interface Props {
  name:string;
  grades:string[];
  start:string;
  end:string;
  room:string;
}

function Appointment(props:Props) {
  return <VBox p='$8' bg='courseDay' width='100%' borderRadius='standard'>
    <Subtitle2 mb='$8'>{props.name}</Subtitle2>
    <Body>
      <HBox mb='$4'>Grade:&nbsp;<HBox>{props.grades.join(', ')}</HBox></HBox>
      <HBox mb='$4'>Time:&nbsp;<HBox>{props.start} - {props.end}</HBox></HBox>
      <HBox>Room:&nbsp;<HBox>{props.room}</HBox></HBox>
    </Body>
  </VBox>
}

const appointments = [
  [<Appointment name='test' grades={['1', '5']} start='2:30 PM' end='3:30 PM' room='Room ABC' />],
  [<Appointment name='test' grades={['1', '5', '7', '8', '9', '10', '11', '12']} start='2:30 PM' end='3:30 PM' room='Room ABC' />],
  [<Appointment name='test' grades={['1', '5']} start='2:30 PM' end='3:30 PM' room='Room ABC' />, <Appointment name='test' grades={['1', '5']} start='2:30 PM' end='3:30 PM' room='Room ABC' />]
];