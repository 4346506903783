import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CloseCoursesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type CloseCoursesMutation = { coursesCloseEnrollment?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const CloseCoursesDocument = gql`
  mutation CloseCourses($ids: [ID!]!) {
    coursesCloseEnrollment(ids: $ids) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CloseCoursesOptions = ExecMutationOptions<CloseCoursesMutationVariables>;

export function closeCourses(options: CloseCoursesOptions) {
  return executeMutation<CloseCoursesMutation, CloseCoursesMutationVariables>(CloseCoursesDocument, options);
}
