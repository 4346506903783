import * as React from 'react';
import { debounce } from 'lodash-es';

import { useScrollWatcher } from '../dom-utils';

export function useHashScrollWatcher(node:HTMLElement, ids:string[], onUpdate:(selected:number) => void) {
  const callback = React.useRef(onUpdate);
  callback.current = onUpdate;

  const debouncedOnScroll = React.useMemo(() => debounce(onScroll, 250), []);
  const scrollWatcher = useScrollWatcher(node, {onScroll:debouncedOnScroll});

  function onScroll() {
    if (!scrollWatcher?.scroller) {
      return;
    }

    let selected = 0;
    let max = 0;
    const r = scrollWatcher.scroller.getBoundingClientRect();
    ids.forEach((id, index) => {
      const hashNode = document.getElementById(id);

      if (!hashNode) {
        return;
      }

      // we try to determine which is the "current" section by taking the section that 
      // has the most visible surface area.  however we treat fully visible as always
      // taking more precidence over partially visible, even if the partially visible
      // section has more visible (because its larger)
      const hashNodeR = hashNode.getBoundingClientRect();
      const allVisible = hashNodeR.top > r.top && hashNodeR.bottom < r.bottom;
      const overlap = Math.min(r.bottom, hashNodeR.bottom) - Math.max(r.top, hashNodeR.top) + (allVisible ? 10000 : 0);

      if (overlap > max) {
        selected = index;
        max = overlap;
      }
    });

    callback.current(selected);
  }
}
