import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type VendorQuery = {
  vendor?:
    | {
        companyName: string;
        siteCompany: boolean;
        email?: string | null | undefined;
        id: string;
        inCompliance: boolean;
        vendorInCompliance: boolean;
        displayName: string;
        name: string;
        phone?: string | null | undefined;
        showProviderCompliance: boolean;
        showTeacherCompliance: boolean;
        token: string;
        accepted: boolean;
        contacts: Array<{ email?: string | null | undefined; id: string; name?: string | null | undefined; phone?: string | null | undefined }>;
        courses?:
          | Array<
              | {
                  id: string;
                  name?: string | null | undefined;
                  status: Types.CourseStatus;
                  season: { name: string };
                  events?: Array<{ createdAt: string; eventName?: string | null | undefined; contact?: { email: string } | null | undefined }> | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        vendorTeachers?:
          | Array<
              { id: string; inCompliance?: boolean | null | undefined; teacher?: { email?: string | null | undefined; id: string; name: string; phone?: string | null | undefined } | null | undefined } | null | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const VendorDocument = gql`
  query Vendor($vendor: ID!) {
    vendor(vendor: $vendor) {
      companyName
      siteCompany
      contacts {
        email
        id
        name
        phone
      }
      courses {
        id
        name
        season {
          name
        }
        status
        events {
          createdAt
          eventName
          contact {
            email
          }
        }
      }
      email
      id
      inCompliance
      vendorInCompliance
      displayName
      name
      phone
      showProviderCompliance
      showTeacherCompliance
      vendorTeachers {
        id
        inCompliance
        teacher {
          email
          id
          name
          phone
        }
      }
      token
      accepted
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorArgs = MakeOptional<UseQueryArgs<VendorQueryVariables, VendorQuery>, 'query'>;

export function useVendorQuery(options: VendorArgs = {}) {
  return useQuery<VendorQueryVariables, VendorQuery>({ query: VendorDocument, ...options });
}

export type VendorOptions = ExecQueryOptions<VendorQueryVariables>;

export function vendor(options: VendorOptions) {
  return executeQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options);
}

export type VendorSelections = VendorQuery['vendor'];
