import * as React from 'react';

import { BoxProps, VBox, maxWidth as themeMaxWidth } from 'app2/components';

export const pagePadding = [15, 30, 40];
const pagePaddingStr = pagePadding.map(p => p + 'px');

interface PageContentProps extends BoxProps, React.PropsWithChildren<{}> {
}

export function PageContent(props: PageContentProps) {
  const { children, maxWidth, ...remainingProps } = props;

  return <VBox width="100%" flex={1}  maxWidth={maxWidth ? maxWidth : themeMaxWidth + 'px'} pt='15px' px={pagePaddingStr} mx="auto" {...remainingProps}>
    {children}
  </VBox>
}