import * as React from 'react'
import pluralize from 'pluralize';

import { Field, Repeater, Panel, HBox } from 'app2/components';
import { CourseRating } from 'app2/views/shared-public';

import { useCourseReviewsQuery } from './generated';

interface Props {
  course:string;
}

export function Reviews(props:Props) {  
  const [result] = useCourseReviewsQuery({ variables: { courseId: props.course } });
  const course = result.data?.course;

  function render() {
    return renderReviews();
  }

  function renderReviews() {
    return <Panel title='Reviews' initialValues={course} 
      subtitle={<HBox vAlign='center' text='formlabel' mb='$16' gap='$8'><CourseRating rating={course?.reviewAverage} count={course?.reviewCount} />{renderReviewTotal()}</HBox>}>
      <Repeater name='reviews' layout='vbox' gap='$16'>
        <span>
          <Field name='overallRating' component={CourseRating} none={false} />
          <Field name='title' none={false} bold />
          <Field name='comments' />
        </span>
      </Repeater>
    </Panel>
  }

  function renderReviewTotal() {
    if (!course?.reviews?.length) {
      return '';
    }

    return course?.reviewCount > course?.reviews?.length
      ? `showing ${course.reviews?.length} of ${course.reviewCount} reviews`
      : `${course.reviewCount} total ${pluralize('review', course.reviewCount)}`
  }

  return render();
}
