import { last } from 'lodash-es';

import { Point } from '../dom-utils'

import { Selection } from './Selection';

export type SelectionIteratorPredicate  = (iterator:SelectionIterator) => any;

export class SelectionIterator {
  private bounds:Selection;
  row:number;
  col:number;

  constructor(bounds:Selection, start?:Point) {
    this.bounds = bounds.clone();
    this.row = this.bounds.topLeft.row;
    this.col = this.bounds.topLeft.col;

    if (start) {
      while (!this.atEnd && (this.row != start.row || this.col != start.col)) {
        this.next();
      }
    }
  }

  next() {
    ++this.col;

    if (this.col > this.bounds.bottomRight.col) {
      ++this.row;
      this.col = this.bounds.topLeft.col;
    }
  }

  get pos() {
    return new Point(this.col, this.row);
  }

  get selection() {
    return new Selection(this.table, this.pos);
  }

  get atEnd() {
    return this.bounds.empty || this.row > this.bounds.bottomRight.row;
  }

  get table() {
    return this.bounds.table;
  }

  static iterate(bounds:Selection, predicate:SelectionIteratorPredicate, start?:Point) {
    const iterator = new SelectionIterator(bounds, start);

    while (!iterator.atEnd) {
      if (predicate(iterator)) {
        return iterator.pos;
      }

      iterator.next();
    }

    return null;
  }

  static map(selection:Selection, predicate:SelectionIteratorPredicate, start?:Point) {
    let prevRow = -1;
    const values:any[][] = [];

    this.iterate(selection, (iterator:SelectionIterator) => {
      if (iterator.row != prevRow) {
        values.push([]);
        prevRow = iterator.row;
      }

      last(values).push(predicate(iterator));
    }, start);

    return values;
  }
}
