import * as React from 'react'

import { Icon, IconProps } from './icons';

interface Props extends IconProps {
  active?:boolean;
  inverted?:boolean;
}

export function SmallLoader(props:Props) {
  const {active, inverted, ...remaining} = props;

  // active, inverted, className=loader, absolute positioning, removal of margins are for backwards compatibility with semantic ui loader
  const color = inverted ? 'primaryInverse' : 'primary';

  return <Icon className="loader" position='absolute' left='50%' top='50%' transform='translate(-50%, -50%)' 
    color={color} {...remaining} margin={0} css={{margin:'0!important'}} name='AnimatedLoader' />;
}
