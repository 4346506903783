import * as React from 'react'

import { StripeConnection } from 'app2/views/shared';

import { useCompanyQuery } from '../shared'

import { useCompanyStripeQuery } from './generated'

export function Stripe() {
  const { company } = useCompanyQuery(useCompanyStripeQuery);

  function render() {
    return <StripeConnection isSite={false} id={company?.id} query={() => company} />
  }

  return render();
}
