import { useParams } from 'react-router';

import { useSiteQuery } from 'app2/views/shared';
import { SeasonRouteParams } from '../organizerRoutes';

export function useCurrentSite() {
  const { site: siteId } = useParams<SeasonRouteParams>();
  return useSiteQuery(siteId);
}

