import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentAddCreditCardMutationVariables = Types.Exact<{
  token: Types.Scalars['ID'];
  family?: Types.InputMaybe<Types.Scalars['ID']>;
  updateCartPaymentService?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type ParentAddCreditCardMutation = { parentAddCreditCard?: { success?: boolean | null | undefined } | null | undefined };

export const ParentAddCreditCardDocument = gql`
  mutation ParentAddCreditCard($token: ID!, $family: ID, $updateCartPaymentService: Boolean) @urql(additionalTypenames: ["Family", "CreditCard"]) {
    parentAddCreditCard(token: $token, family: $family, updateCartPaymentService: $updateCartPaymentService) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentAddCreditCardOptions = ExecMutationOptions<ParentAddCreditCardMutationVariables>;

export function parentAddCreditCard(options: ParentAddCreditCardOptions) {
  return executeMutation<ParentAddCreditCardMutation, ParentAddCreditCardMutationVariables>(ParentAddCreditCardDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Family', 'CreditCard']) }
  });
}
