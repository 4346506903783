import * as React from 'react';

import { PrivatePage, PrivatePageProps } from 'app2/views/shared';

interface Props extends PrivatePageProps {
}

export function TeacherPage(props:Props) {
  function render() {
    return <PrivatePage {...props}>
    </PrivatePage>
  }


  return render();
}
