import * as React from 'react'
import { castArray } from 'lodash-es'

import { OptionValue, Text, TextProps, defaultFieldProps } from 'app2/components'

import { GradeSiteProps, useGradeOptions } from './useGradeOptions';

export interface GradeTextProps extends TextProps, GradeSiteProps {
  value?:OptionValue;
}

export function GradeText(props:GradeTextProps) {
  const { site:propsSite, info, value:propsValue, ...remaining } = props;
  const options = useGradeOptions(props);
  const value = castArray(propsValue);
  const text = value.map(v => options.find(o => o.value === v)?.label || v).join(', ');

  function render() {
    return <Text text='body' {...remaining}>{text}</Text>
  }

  return render();
}

GradeText.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info',
}