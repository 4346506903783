import * as React from 'react'
import { castArray } from 'lodash-es';

import { CompanyRole, CourseRole, OrganizerRole } from 'app2/api';
import { useHistory } from 'app2/components';

import { useCurrentUser } from '../user';

import { RouteProps } from './Route';

// if you do not pass in a path, it uses the current path in the history
export function useRouteInfo(path?:string) {
  const history = useHistory();

  if (!path) {
    path = history.location.pathname;
  }

  const route = React.useMemo(() => history.findRoute(path) as React.ReactElement<RouteProps>, [path]);
  const { user, loading, fetching } = useCurrentUser();
  const allowed = !route || isRouteAllowed(route.props, user);

  return {user, route, allowed, loading, fetching, history};
}

export function isRouteAllowed(route:RouteProps, user:ReturnType<typeof useCurrentUser>['user']) {
  const requiredRoles = castArray(route.role || []);

  if (!requiredRoles.length) {
    return true;
  }

  if (requiredRoles.length == 1 && requiredRoles[0] == 'logged-out' && !user) {
    return true;
  }

  if (requiredRoles.length == 1 && requiredRoles[0] == 'logged-in' && !!user) {
    return true;
  }

  const userRoles = user?.roles;

  const allowed = requiredRoles.find(requiredRole => {
    if (requiredRole == 'admin' && userRoles?.admin) {
      return true
    }

    if (requiredRole == 'parent' && userRoles?.parent) {
      return true
    }

    if (requiredRole == userRoles?.provider || (requiredRole == CompanyRole.CompanyMember && userRoles?.provider == CompanyRole.CompanyAdmin)) {
      return true
    }

    if (requiredRole == userRoles?.organizer || (requiredRole == OrganizerRole.SiteMember && userRoles?.organizer == OrganizerRole.Coordinator)) {
      return true
    }

    if (requiredRole == userRoles?.course || (requiredRole == CourseRole.Member && userRoles?.course == CourseRole.Admin)) {
      return true
    }

    return false;
  }) != null;

  return allowed;
}
