import * as React from 'react';

import { CourseUtils, PriceConfigKind } from 'app2/api';
import { Box, Info, Section } from 'app2/components';

import { EnrollmentBreakdownProps, EnrollmentBreakdown } from './EnrollmentBreakdown';
import { EnrollmentDiscountCombo } from './EnrollmentDiscountCombo';

export function DiscountComboAndBreakdown(props:EnrollmentBreakdownProps) {
  const showDiscountDepositWarning = CourseUtils.usingDeposit(props.course) && (props.config?.kind == PriceConfigKind.Season || props.config?.kind == PriceConfigKind.ConfigurableSeason);

  return props.parentCourse.behavior.enrollmentAddWithDiscount
    ? <Section label="Adjust price" width="100%" layout='vbox' gap='$30'>
      <Box layout={['vbox', 'hbox']} width="100%" vAlign={[undefined, 'top']} gap='$8'>
        <EnrollmentDiscountCombo course={props.parentCourse?.id} config={props.config} />
        <EnrollmentBreakdown {...props} />
      </Box>
      {showDiscountDepositWarning && <Info type='warning' message='Deposits are not subject to any adjustments in price' width='100%' small icon={false} title={false}/>}
    </Section>
  : <EnrollmentBreakdown {...props} />
}
