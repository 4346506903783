import * as React from 'react';

import { CourseUtils, EnrollmentStatusEnum } from 'app2/api';
import { ActionButton, DataTable, Modal } from 'app2/components';

import { WaitlistedEnrollmentsSelections } from '../waitlisted';
import { PromoteModal } from './PromoteModal';

import { CourseSelections } from '../../generated';

export function renderPromoteButton(course: CourseSelections, table: DataTable) {
  async function handlePromote() {
    const exit = await provisionalWaitlistCheck();
    if (exit) {
      return;
    }

    Modal.show(<PromoteModal course={course} enrollments={table.selections.selectedItems} />);
  }

  async function provisionalWaitlistCheck() {
    if (!provisionalWaitlistOpen()) {
      return false;
    }

    const result = await Modal.warning({
      title: 'Spots in carts',
      content:
        "There are spots in families carts. Are you sure you want to invite these students to enroll? Doing so may result in over enrollment if this activity is almost full. If not, check back in a little while–spots can only be held in carts for 25 minutes.",
      ok: 'Continue'
    });
    if (result.action) {
      return false;
    }

    return true;
  }

  function provisionalWaitlistOpen() {
    return (table.selections.selectedItems as WaitlistedEnrollmentsSelections[]).filter(e => e.status == EnrollmentStatusEnum.ProvisionallyWaitlisted).length > 1;
  }

  const abilities = CourseUtils.getCourseAbilities(course);
  if (!abilities.promoteable || !course?.behavior?.enrollmentsPromote) {
    return;
  }

  return (
    <ActionButton icon="UserPlus" autoLoader={false} onClick={handlePromote}>
      Promote
    </ActionButton>
  );
}
