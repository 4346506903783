import { EventProperties } from './EventRecorder';
import { EventRecorderProxy } from './EventRecorderProxy';
import { EventRecorderManager } from './EventRecorderManager';
import { FacebookAnalytics } from './FacebookAnalytics';
import { GoogleTagAnalytics } from './GoogleTagAnalytics';
import { EventRecorderShim } from './EventRecorderShim';

export * from './EventRecorder';

export let facebook: FacebookAnalytics;
export let gtag: GoogleTagAnalytics;
export const eventManager: EventRecorderProxy = new EventRecorderProxy(new EventRecorderShim());

let analyticsAggregator: EventRecorderManager;

export interface EventManagerConfig {
  gtm?:string;
}

export const eventManagerConfig:EventManagerConfig = {};

export function startAnalytics() {
  facebook = new FacebookAnalytics();
  gtag = new GoogleTagAnalytics(eventManagerConfig.gtm);
  analyticsAggregator = new EventRecorderManager([
    facebook,
    gtag
  ]);

  replaceShim();
}

function replaceShim() {
  if (analyticsAggregator.loaded) {
    const analyticsShim = eventManager.proxied as EventRecorderShim;

    eventManager.proxied = analyticsAggregator;
    analyticsShim.run(eventManager);
  } else {
    setTimeout(replaceShim, 100);
  }
}

// returns a function that when invoked will call the next function
export const logEventWrapper = (
  nextFn: Function = () => {},
  event: string,
  data?: EventProperties
) => {
  return function() {
    eventManager.log(event, data);
    nextFn(...[].slice.call(arguments));
  };
};
