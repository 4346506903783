import * as React from 'react'
import * as ReactIs from 'react-is'

import { Box } from '../../Box';
import { MouseCapture, captureMouse, scrollRectIntoView, Point, Rect, ScrollWatcher, cmdCtrlKey } from '../../dom-utils';
import { TableSection } from '../../virtualized';

import { DataTable } from '../DataTable';
import { Selection } from '../Selection';
import { SelectionRendererProps, adjustRectToRendered } from '../renderers';

class Props {
  table:DataTable;
  section:TableSection;
  style?:React.CSSProperties;
}

export class SelectionManager extends React.Component<Props> {
  start:Point;
  selectingCols:boolean;
  selectingRows:boolean;
  mouseCapture:MouseCapture;
  selection:Selection;
  scrollWatch:ScrollWatcher;
  element = React.createRef<HTMLDivElement>();
  focusPos:Point;
  focusSize:Point;

  componentDidMount() {
    this.scrollWatch = new ScrollWatcher({onScroll:this.onScrollOrResize})
    this.scrollWatch.watch(this.element.current);
  }

  componentWillUnmount() {
    this.mouseCapture?.stop();
    this.scrollWatch.unwatch();
  }

  get table():DataTable {
    return this.props.table;
  }

  onScrollOrResize = () => {
    // this causes performance issues when using the grouped datatable header due to all the
    // sub-datatables it can create.  as best as i can tell its no longer needed.  if we
    // start to see visual artifact problems then this might be the culprit.
    // this.setState({});
  }

  onKeyDown = (event:React.KeyboardEvent<HTMLElement>) => {
    // events that come from the table (and not cell renderers, and col menu)
    const fromTable = this.table.element == document.activeElement && event.isTrusted;

    // events being forwarded from an external input dropdown
    const forwarded = this.table.rootElement.contains(event.target as HTMLElement) && !event.isTrusted;

    if (!fromTable && !forwarded) {
        return;
    }

    this.selectFromKeyboardEvent(event);
  }

  selectFromKeyboardEvent(event:React.KeyboardEvent<HTMLElement> | KeyboardEvent, allowAddRow:boolean = true) {
    const table = this.table;

    if (!table.data.length) {
      return;
    }

    const multipleSelection = table.props.multipleSelection;
    const selection = table.selection?.clone() ||  new Selection(table);
    const extend = event.shiftKey && multipleSelection;
    let makeVisible:Point;

    switch (event.key) {
      case "a":
        if (cmdCtrlKey(event) && multipleSelection) {
          selection.selectAll();
        }
        else {
          return;
        }
        break;

      case "ArrowLeft":
        selection.moveOrExtendLeft(extend, cmdCtrlKey(event));
        makeVisible = selection.focus.clone();
        break;

      case "ArrowUp":
        selection.moveOrExtendUp(extend, cmdCtrlKey(event));
        makeVisible = selection.focus.clone();
      break;

      case "ArrowRight":
        selection.moveOrExtendRight(extend, cmdCtrlKey(event));
        makeVisible = selection.focus.clone();
        break;

      case "ArrowDown":
        selection.moveOrExtendDown(extend, cmdCtrlKey(event));
        makeVisible = selection.focus.clone();
        break;

      case "Tab":
        selection.moveTab(event.shiftKey);
        makeVisible = selection.focus.clone();
        break;
        
      case "Enter":
        const lastRow = selection.focusRow == table.numRows - table.colHeaderCount && table.editable;
        const addRow = allowAddRow && table.editable && lastRow && !event.shiftKey;

        if (addRow) {
          table.append();
        }

        if (table.props.enterMovesSelection) {
          selection.moveEnter(event.shiftKey);
          makeVisible = selection.focus.clone();
        }
        break;

      default:
        return;
    }

    event.preventDefault();
    table.setSelection(selection, false, event);

    if (makeVisible) {
      this.makeVisible(makeVisible);
    }
  }

  onMouseDown = (event:React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement>, forceSelect?:boolean) => {
    if (!this.table.element) {
      return;
    }

    if (!forceSelect && this.table.element != event.target && !this.isTableEvent(event) && !this.isSelectionRendererEvent(event)) {
      return;
    }

    this.selection = this.table.selection?.clone() || new Selection(this.table);
    this.start = new Point(event.clientX, event.clientY);

    const selection = this.selectFromMouseEvent(event, false);

    if (!selection) {
      this.selection = null;
      this.start = null;
      return;
    }

    this.selectingCols = selection.allRowsSelected;
    this.selectingRows = selection.allColsSelected;

    if (this.mouseCapture) {
      this.mouseCapture.stop();
    }

    this.mouseCapture = captureMouse(this.onMouseMove, this.onMouseUp, event, this.table.element);
  }

  onMouseMove = (event:MouseEvent) => {
    if (!this.didMoveFromStart(event)) {
      return;
    }

    this.selectFromMouseEvent(event, true);
  }

  onMouseUp = (event:MouseEvent) => {
    if (!this.selection) {
      return;
    }

    this.mouseCapture = null;
    this.selectFromMouseEvent(event, true);

    this.selectingCols = false;
    this.selectingRows = false;
    this.selection = null;
  }

  isTableEvent(event:React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement>) {
    return this.table.element?.contains(event.target as HTMLElement);
  }

  isSelectionRendererEvent(event:React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement>) {
    return this.element.current.contains(event.target as HTMLElement);
  }

  didMoveFromStart(event:MouseEvent) {
    if (!this.start) {
      return true;
    }

    if (Math.abs(this.start.x - event.clientX) > 5 ||  Math.abs(this.start.y - event.clientY) > 5) {
      this.start = null;
      return true;
    }

    return false;
  }

  selectFromMouseEvent(event:React.MouseEvent<HTMLElement> | MouseEvent, forceExtend = false, ignoreIfSelected:boolean = false) {
    const cell = this.table.getCellFromEvent(event);

    if (!cell) {
      return null;
    }

    event.preventDefault();

    const cellPos = cell.pos;
    const colHeaderCount = this.table.colHeaderCount;
    const rowHeaderCount = this.table.rowHeaderCount;
    const selectingCols = this.selectingCols || cellPos.row < colHeaderCount;
    const selectingRows = this.selectingRows || cellPos.col < rowHeaderCount;
    let selection:Selection = this.selection.clone();
    let makeVisible:Point;

    if (ignoreIfSelected && selection.containsPoint(cellPos.clone().offset(-rowHeaderCount, -colHeaderCount))) {
      return null;
    }

    const extend = (forceExtend || event.shiftKey) && this.table.props.multipleSelection;

    if (cellPos.row >= rowHeaderCount && cellPos.col >= colHeaderCount && !selectingCols && !selectingRows) {
      cellPos.row -= colHeaderCount;
      cellPos.col -= rowHeaderCount;

      selection.moveOrExtendTo(cellPos, extend);
      makeVisible = selection.focus.clone();
    }
    else 
    if (selectingCols && selectingRows) {
      selection.selectAll();
    }
    else 
    if (selectingCols) {
      selection.selectCol(cellPos.col - rowHeaderCount, extend);
      makeVisible = new Point(selection.focus.col, Math.ceil(this.table.virtualTable.renderedRowCols.middleY));
    }
    else {
      selection.selectRow(cellPos.row - colHeaderCount, extend);
      makeVisible = new Point(Math.ceil(this.table.virtualTable.renderedRowCols.middleX), selection.focus.row);
    }

    // don't check of the current selection is the same as the new selection
    // because we want to fire onSelectionChange event because some users of
    // the component want the selection change event via mouse up and not mouse down
    this.table.focus();
    this.table.setSelection(selection, false, event);
    this.selection = selection;

    if (makeVisible) {
      this.makeVisible(makeVisible);
    }

    return selection;
  }

  makeVisible(cellPos:Point) {
    const table = this.table;

    if (!table.element) {
      return;
    }

    const cellRect = table.getCellCoordinates(cellPos.clone().offset(table.rowHeaderCount, table.colHeaderCount));
    const offsetX = this.props.section.coords.left;
    const offsetY = this.props.section.coords.top;

    const scroller = this.scrollWatch.scroller;
    const scrollerRect = scroller.getBoundingClientRect();
    const tableRect = new Rect(table.element.getBoundingClientRect());
    // gets the table elements rect relative to the scroller
    tableRect.offset(scroller.scrollLeft - scrollerRect.left - offsetX, scroller.scrollTop - scrollerRect.top - offsetY);

    // if the grid is not positioned at 0, 0 in the scroller, we need to offset by that amount
    cellRect.offset(tableRect.left, tableRect.top);

    // the above includes borders, so subtract them off
    const tableDiv = table.rootElement;
    const borderSize = parseInt(getComputedStyle(tableDiv).borderWidth);
    cellRect.offset(-borderSize, -borderSize);

    // subtract the start of this section from the visible scrollable
    //  area so we don't think a cell that is underneath a header is visible
    const tlOffset = new Point();

    tlOffset.x += (table.props.stickyOffset?.x || 0) + offsetX;
    tlOffset.y += (table.props.stickyOffset?.y || 0) + offsetY;

    scrollRectIntoView(scroller, cellRect, tlOffset, undefined);
  }

  setOversizedCellSize(width:number, height:number) {
    const focus = this.table.selection.focus.clone().offset(this.table.rowHeaderCount, this.table.colHeaderCount);

    if (!this.focusPos || !this.focusPos.equal(focus) || !this.focusSize || !this.focusSize.equal(new Point(width, height))) {
      const table = this.table;
      const cellsNormalSize = adjustRectToRendered(table.getCellCoordinates(focus));

      this.focusPos = focus;
      this.focusSize = new Point(Math.max(width, cellsNormalSize.width), Math.max(height, cellsNormalSize.height));
      // setOversizedCellSize is usually called during cell
      // rendering, and forceUpdate/setState is not allowed
      // during then, so call this delayed
      setTimeout(() => {
        if (this.element.current) {
          this.forceUpdate()
        }
      }, 1);
    }
  }

  render() {
    const css = {
      '.hr-table-focus &.hr-table-main-selection': {
        border: 'solid 1px'
      }
    };

    return <Box ref={this.element} style={this.props.style} onKeyDown={this.onKeyDown} onMouseDown={this.onMouseDown} css={css} height='100%' width='100%' overflow='hidden' position='relative'>
      {this.renderSelections()}
      {this.props.children}
    </Box>
  }

  renderSelections() {
    const table = this.table;

    if (!table || !table.element || !this.scrollWatch) {
      return '';
    }

    if (this.focusPos && !this.focusPos.equal(this.table.selection.focus.clone().offset(table.rowHeaderCount, table.colHeaderCount))) {
      this.focusPos = null;
      this.focusSize = null;
    }

    const visibleRowCols = this.props.section.factory.bounds;
    // inflate visibleRowCols by 1 because empty is exclusive of the bottom right
    const visibleSelections = table.selections.selections.filter(selection => !selection.normalizedRect.offset(table.rowHeaderCount, table.colHeaderCount).intersection(visibleRowCols).inflate(0, 0, 1, 1).empty);

    const scroller = this.scrollWatch.scroller;
    const scrollerRect = scroller.getBoundingClientRect();
    const tableRect = new Rect(this.element.current.getBoundingClientRect());
    tableRect.offset(-scrollerRect.left, -scrollerRect.top);

    const visible = new Rect(Math.max(-tableRect.left, 0), Math.max(-tableRect.top, 0));
    visible.width = Math.min(tableRect.width, scrollerRect.width);
    visible.height = Math.min(tableRect.height, scrollerRect.height);

    return visibleSelections.map((selection, index) => this.renderSelection(selection, index, visible)).filter(s => s !== undefined);
  }

  renderSelection(selection:Selection, index:number, visible:Rect) {
    const table = this.table;
    const topLeftPos = selection.topLeft.clone().offset(table.rowHeaderCount, table.colHeaderCount);
    const bottomRightPos = selection.bottomRight.clone().offset(table.rowHeaderCount, table.colHeaderCount);

    const topLeft = adjustRectToRendered(table.getCellCoordinates(topLeftPos));
    const bottomRight = adjustRectToRendered(table.getCellCoordinates(bottomRightPos));

    const offsetX = -this.props.section.coords.left;
    const offsetY = -this.props.section.coords.top;

    topLeft.offset(offsetX, offsetY);
    bottomRight.offset(offsetX, offsetY);

    const mainSelection = table.selection == selection;

    if (this.focusPos && topLeftPos.equal(this.focusPos) && bottomRightPos.equal(this.focusPos)) {
      bottomRight.width = this.focusSize.x;
      bottomRight.height = this.focusSize.y;
    }

    if (visible.intersection(topLeft.left, topLeft.top, bottomRight.right, bottomRight.bottom).empty) {
      return;
    }

    const left = topLeft.left + 'px';
    const top = topLeft.top + 'px';
    const width = (bottomRight.right - topLeft.left) + 'px';
    const height = (bottomRight.bottom - topLeft.top) + 'px';

    // selection should be rendered on top of the cells but below headers so when scrolled it doesn't cover them and below editor manager
    const props = {key:index, table, position:'absolute', left, top, width, height, style:{pointerEvents: 'none', zIndex:10},
      rowStart:topLeftPos.row, colStart: topLeftPos.col, rowEnd:bottomRightPos.row, colEnd:bottomRightPos.col, main: mainSelection} as SelectionRendererProps;
      
    const SelectionRenderer = table.props.selectionRenderer as React.ComponentType<SelectionRendererProps>;

    return ReactIs.isElement(table.props.selectionRenderer)
      ? React.cloneElement(table.props.selectionRenderer as React.ReactElement<SelectionRendererProps>, props)
      : <SelectionRenderer {...props} />;
  }
}
