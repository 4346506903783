import { makeObservable, observableManager } from 'app/observable/observable';

export const ignoreProps: any = {
  updater: true,
  _reactInternalFiber: true,
  _reactInternalInstance: true,
  props: true,
  state: true,
  context: true,
  refs: true
};

export class ReactObservableProxyHandler {
  set(target: any, prop: PropertyKey, newValue: any, receiver: any) {
    const oldValue = target[prop];

    if (oldValue !== newValue && !ignoreProps[prop]) {
      newValue = makeObservable(newValue);
      observableManager.onChanged(receiver, prop, newValue);
    }

    return Reflect.set(target, prop, newValue, receiver);
  }

  get(target: any, prop: PropertyKey, receiver: any) {
    observableManager.onObserved(receiver, prop);

    return Reflect.get(target, prop, receiver);
  }
}

export const handler = new ReactObservableProxyHandler();
