import * as React from 'react';

import { Dropdown, Text, Option, OptionValue, iconNames } from 'app2/components';

import { Demos, Demo } from './Demo';

export function DropdownDemos() {
  const [value, setValue] = React.useState<OptionValue>();
  let [customOptions, setOptions] = React.useState(options);
  let [filteredOptions, setFilteredOptions] = React.useState(options);

  function render() {
    return <Demos name='Dropdown'>
      <Demo name='Single select'>
        <Dropdown options={options} width='200px' />
      </Demo>

      <Demo name='Single select + clearable'>
        <Dropdown options={options} width='200px' clearable />
      </Demo>

      <Demo name='Error'>
        <Dropdown error options={options} width='200px' />
      </Demo>

      <Demo name='Icons'>
        <Dropdown options={optionsWithIcons} width='200px' />
      </Demo>

      <Demo name='Icons, multiple'>
        <Dropdown multiple options={optionsWithIcons} width='200px' />
      </Demo>

      <Demo name='Multiple'>
        <Dropdown multiple options={options} width='200px' onChange={onChange} />
      </Demo>

      <Demo name='Multiple, checked'>
        <Dropdown multiple selectedStyle='checkbox' options={options} width='200px' onChange={onChange} />
      </Demo>

      <Demo name='Controlled'>
        <Dropdown multiple options={options} width='200px' value={value} onChange={(event) => setValue(event.currentTarget.value as OptionValue)} />
      </Demo>

      <Demo name='Controlled + clearable'>
        <Dropdown multiple options={options} width='200px' value={value} onChange={(event) => setValue(event.currentTarget.value as OptionValue)} clearable />
      </Demo>

      <Demo name='Custom icon'>
        <Dropdown multiple options={options} width='200px' onChange={onChange} icon='Search' />
      </Demo>

      <Demo name='Clearable Custom icon'>
        <Dropdown multiple options={options} width='200px' onChange={onChange} icon='Search' clearable />
      </Demo>

      <Demo name='Additions'>
        <Dropdown multiple options={customOptions} width='200px' onChange={(event) => setValue(event.currentTarget.value as OptionValue)} onAdd={text => {
          customOptions = customOptions.slice();
          customOptions.push({label:text, value: text});
          setOptions(customOptions);
          return text;
        }} />
      </Demo>

      <Demo name='Additions, comma separator'>
        <Dropdown multiple options={customOptions} width='200px' onChange={(event) => setValue(event.currentTarget.value as OptionValue)} separator=',' onAdd={text => {
          customOptions = customOptions.slice();
          customOptions.push({label:text, value: text});
          setOptions(customOptions);
          return text;
        }} />
      </Demo>

      <Demo name='Strings'>
        <Dropdown multiple options={['abc', '123', 'xyz']} width='200px' onChange={onChange} additions />
      </Demo>

      <Demo name='Word wrap'>
        <Dropdown measuredRows options={longLines} width='200px' onChange={onChange} />
      </Demo>

      <Demo name='Word wrap, truncate'>
        <Dropdown measuredRows maxLines={2} options={longLines} width='200px' onChange={onChange} />
      </Demo>

      <Demo name='Custom renderer'>
        <Dropdown width='200px' options={options.map(option => {
          return {
            value: option.value, 
            text: option.label as string, 
            label: <Text width='100%' height='100%' display='inline-block' text='subtitle1' border='solid 1px #00000000' css={{
              ':hover': {
                border: 'solid 1px red'
              }}}>{option.label}</Text>
          }
        })}/>
      </Demo>

      <Demo name='Custom search'>
        <Dropdown width='200px' options={filteredOptions} onSearchChange={onCustomSearch} />
      </Demo>

      <Demo name='Inline, multiple, checkbox, no tags, dont clear filter (for use in a table column menu)' description={`
        An inlinelist dropdown shows the list all the time, instead of requiring clicking on the button to show it
      `}>
        <Dropdown multiple inlinelist selectedStyle='checkbox' tags={false} clearOnSelect={false} options={options} width='200px' />
      </Demo>

      <Demo name='Inline, multiple, checkbox, no tags, dont clear filter (for use in a table column menu), wrapped and truncated'>
        <Dropdown multiple inlinelist selectedStyle='checkbox' measuredRows maxLines={2} tags={false} clearOnSelect={false} options={longLines} width='200px' />
      </Demo>
    </Demos>
  }

  function onChange(event:React.ChangeEvent<Dropdown>) {
    console.log('You chose:' + JSON.stringify(event.currentTarget.value));
  }
  
  function onCustomSearch(value:string) {
    setFilteredOptions(options.filter(o => o.label.toLocaleString().startsWith(value)));
  }

  return render();
}

const options = [
  {label:'abc'}, 
  {label:'123-a'}, 
  {label: 'xyz'}, 
  {label: '456'}, 
  {label: 'abc'}, 
  {label: '123-c'}, 
  {label: 'xyz'}, 
  {label: '456'},
  {label: 'abc'}, 
  {label: '123-b'}, 
  {label: 'xyz'}, 
  {label: '456'}
] as Option[];

options.forEach((option:any, index) => option.value = index);

const optionsWithIcons = options.map(o => ({...o, text:o.label as string, label: {icon: iconNames[Math.floor(Math.random() * iconNames.length)], color: 'red'}}));

const longLines = [
  'The quick brown fox jumped over the lazy dog.  The quick brown fox jumped over the lazy dog.  The quick brown fox jumped over the lazy dog.  The quick brown fox jumped over the lazy dog.', 
  "Frankly, my dear, I don't give a damn. Gone with the Wind (1939)",
  "I'm gonna make him an offer he can't refuse. The Godfather (1972)",
  "Toto, I've a feeling we're not in Kansas anymore. The Wizard of Oz (1939)",
  "Here's looking at you, kid. - Casablanca, 1942",
  "Go ahead, make my day. - Sudden Impact, 1983",
  "All right, Mr. DeMille, I'm ready for my close-up. - Sunset Boulevard, 1950",
  "May the Force be with you. - Star Wars, 1977",
  "Fasten your seatbelts. It's going to be a bumpy night. - All About Eve, 1950",
  "You talking to me? - Taxi Driver, 1976",
  "What we've got here is failure to communicate. - Cool Hand Luke, 1967",
  "I love the smell of napalm in the morning. - Apocalypse Now, 1979"
]