import * as React from 'react';

import { Checkbox } from "../Checkbox";
import { CurrencyInput } from '../CurrencyInput';
import { DatePicker } from '../DatePicker';
import { DateText } from '../DateText';
import { Dropdown } from '../Dropdown';
import { onlyDate, onlyDateArray } from '../date-utils';
import { Input, inputHeight } from "../Input";
import { Link } from "../Link";
import { NumberInput } from '../NumberInput';
import { findOption, findOptionByLabel, getOptionLabel, Option, OptionValue } from '../Option';
import { OptionText } from '../OptionText';
import { PercentInput } from '../PercentInput';
import { PhoneInput } from '../PhoneInput';
import { TextArea } from "../TextArea";
import { TimePicker } from '../TimePicker';

import { Radio, RadioGroup } from "../radio";

import { FormatOnly, FieldProps } from "./Field";
import { FieldComponentProps, FieldPlaceholder } from "./FieldComponentProps";
import { FieldValue } from "./FieldValue";
import { TIME_FORMAT, LONG_DATE_FORMAT, formatCurrency, formatFieldDate, formatEmail, formatPhoneLink, formatPercent } from './formatters';
import { dateFromTime, floatParser } from './parsers';
import { validateCancel, validateEmail, validatePhone } from './validators';

export const DateLabelField = {component:DateText, dateFormat:LONG_DATE_FORMAT } as FieldComponentProps<typeof FieldValue>;
export const DateField = {display: DateLabelField, edit: {component: DatePicker, placeholder: true, dateFormat: LONG_DATE_FORMAT, tagDateFormat: LONG_DATE_FORMAT, paste: onlyDate}, copy: formatFieldDate };
export const MultipleDateField = {...DateField, edit: {...DateField.edit, type: 'multiple', paste: onlyDateArray} };

export const TimeField = {display: {component: DateText, dateFormat:TIME_FORMAT }, edit: {component: TimePicker, placeholder: true, valueFormat:'datetime', paste: dateFromTime}, copy: formatFieldDate};

export const InputField = {edit: {component: Input, placeholder: true }} as FieldComponentProps<typeof Input>;

export const PercentField = {edit: {component: PercentInput, placeholder: true, paste: floatParser }, display: {component: FieldValue, format: formatPercent}};

export const NumberField = {edit: {component: NumberInput, placeholder: true, paste: floatParser }};

export const TextAreaField = {edit: {component: TextArea, placeholder: true, minHeight: '140px' }} as FieldComponentProps<typeof TextArea>;

// radio's have no error state...a wrapper, like a radio group, should display the error (vs. showing an error on each option)
export const RadioField = {edit: Radio, display: {component: Radio, disabled: true}} as FieldComponentProps<typeof Radio>;

export const CheckboxField = {edit: Checkbox, display: {component: Checkbox, disabled: true}, valueProperty:'checked', disallowNone: true, boolean: true};
export const DisbabledCheckboxField = {edit: {component: Checkbox, disabled: true}, display: {component: Checkbox, disabled: true}, valueProperty:'checked', disallowNone: true, boolean: true};

export function checkboxField(disabled:boolean) {
  return disabled ? DisbabledCheckboxField : CheckboxField;
}

export const CurrencyField = {edit: {component: CurrencyInput, placeholder:true, paste: floatParser}, format: formatCurrency};

export const EmailLabelField = {component:FormatOnly, valueProperty:'children', errorProperty: null, format:formatEmail,
  copy: (value:string) => value, onChangeProperty: null, onBlurProperty: null, label: 'Email address'} as FieldComponentProps<'span'>;
export const EmailField = {edit: { component: Input, placeholder: true }, display: EmailLabelField, validators: validateEmail, label: 'Email address'};

export const PhoneLabelField = {component:FormatOnly, valueProperty:'children', errorProperty: null, style:{maxWidth:'100%'}, format:formatPhoneLink, 
  copy: (value:string) => value, onChangeProperty: null, onBlurProperty: null, label: 'Phone number'} as FieldComponentProps<'span'>;
export const PhoneField = {edit: { component: PhoneInput, placeholder: true }, display: PhoneLabelField, validators: validatePhone, label: 'Phone number'} as FieldComponentProps<typeof Input>;

// this intentionally leaves null/undefined as no label and leaves false for no
export const BooleanLabelField = {component:FieldValue, valueProperty:'children', errorProperty: null as any, style:{maxWidth:'100%'}, format:(value:boolean) => value === null || value === undefined ? ""  : value ? "Yes" : "No", 
  copy: (value:string) => value, onChangeProperty: null as any, onBlurProperty: null as any};
export const BooleanField = {edit: CheckboxField, display: BooleanLabelField} as FieldComponentProps<typeof Input>;
export const BooleanRadioField = {edit: {component: RadioGroup, layout: 'hbox', options: [{label: 'Yes', value: true}, {label: 'No', value: false}]}, display: BooleanLabelField} as FieldComponentProps<typeof Input>;

export const LinkField = {component:FormatOnly, valueProperty:'children', errorProperty: null, style:{maxWidth:'100%'}, copy: (value:string) => value, onChangeProperty: null, onBlurProperty: null,
  format:(value:string, field:FieldProps<any, any, any>) => {
    return !value ? value : <Link to={`${field.to?.replace('$value', value) || value}`} className='hr-value' text='body'>{value}</Link>
  }  } as FieldComponentProps<'span'>;

export const DropdownField = {edit: {component: Dropdown, placeholder: FieldPlaceholder.select}, display: {component:OptionText, minHeight:inputHeight}, copy:copyOption, paste:pasteOption} as any as FieldComponentProps<any>;

export function copyOption(value:OptionValue, field:{options:(string | Option)[]}) {
  return getOptionLabel(findOption(field.options, value, true))
}

export function pasteOption(value:OptionValue, field:{options:(string | Option)[]}) {
  return ((findOption(field.options, value) || findOptionByLabel(field.options, value)) as Option)?.value;
}

// the validator assumes the name of the field is 'cancel'
export const CancelConfirmationField = {label:'To continue, type the word "cancel"', required: true, component: InputField, placeholder:'Enter the word "cancel"', validators:validateCancel} as FieldComponentProps<typeof Input>;

