import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
export type BreakdownFieldsFragment = {
  discountAmount?: number | null | undefined;
  homeroomCharges?: number | null | undefined;
  listPrice?: number | null | undefined;
  listPriceBeforeDiscount?: number | null | undefined;
  price?: number | null | undefined;
  priceBeforeDiscount?: number | null | undefined;
  siteCharges?: number | null | undefined;
  siteCreditCardFee?: number | null | undefined;
  siteReceives?: number | null | undefined;
  vendorCharges?: number | null | undefined;
  vendorCreditCardFee?: number | null | undefined;
  vendorReceives?: number | null | undefined;
};

export type ConfigurableSeasonBreakdownFieldsFragment = {
  discountAmount?: number | null | undefined;
  homeroomCharges?: number | null | undefined;
  listPrice?: number | null | undefined;
  listPriceBeforeDiscount?: number | null | undefined;
  price?: number | null | undefined;
  priceBeforeDiscount?: number | null | undefined;
  siteCharges?: number | null | undefined;
  siteCreditCardFee?: number | null | undefined;
  siteReceives?: number | null | undefined;
  vendorCharges?: number | null | undefined;
  vendorCreditCardFee?: number | null | undefined;
  vendorReceives?: number | null | undefined;
  days?: number | null | undefined;
};

export type RecurringBreakdownFieldsFragment = {
  discountAmount?: number | null | undefined;
  homeroomCharges?: number | null | undefined;
  listPrice?: number | null | undefined;
  listPriceBeforeDiscount?: number | null | undefined;
  price?: number | null | undefined;
  priceBeforeDiscount?: number | null | undefined;
  siteCharges?: number | null | undefined;
  siteCreditCardFee?: number | null | undefined;
  siteReceives?: number | null | undefined;
  vendorCharges?: number | null | undefined;
  vendorCreditCardFee?: number | null | undefined;
  vendorReceives?: number | null | undefined;
  days?: number | null | undefined;
  unit?: Types.RecurringUnit | null | undefined;
};

export type UsageBreakdownFieldsFragment = {
  discountAmount?: number | null | undefined;
  homeroomCharges?: number | null | undefined;
  listPrice?: number | null | undefined;
  listPriceBeforeDiscount?: number | null | undefined;
  price?: number | null | undefined;
  priceBeforeDiscount?: number | null | undefined;
  siteCharges?: number | null | undefined;
  siteCreditCardFee?: number | null | undefined;
  siteReceives?: number | null | undefined;
  vendorCharges?: number | null | undefined;
  vendorCreditCardFee?: number | null | undefined;
  vendorReceives?: number | null | undefined;
  unit?: Types.UsageUnit | null | undefined;
};

export const BreakdownFieldsFragmentDoc = gql`
  fragment breakdownFields on PriceBreakdown {
    discountAmount
    homeroomCharges
    listPrice
    listPriceBeforeDiscount
    price
    priceBeforeDiscount
    siteCharges
    siteCreditCardFee
    siteReceives
    vendorCharges
    vendorCreditCardFee
    vendorReceives
  }
`;
export const ConfigurableSeasonBreakdownFieldsFragmentDoc = gql`
  fragment configurableSeasonBreakdownFields on SeasonPriceBreakdown {
    discountAmount
    homeroomCharges
    listPrice
    listPriceBeforeDiscount
    price
    priceBeforeDiscount
    siteCharges
    siteCreditCardFee
    siteReceives
    vendorCharges
    vendorCreditCardFee
    vendorReceives
    days
  }
`;
export const RecurringBreakdownFieldsFragmentDoc = gql`
  fragment recurringBreakdownFields on RecurringPriceBreakdown {
    discountAmount
    homeroomCharges
    listPrice
    listPriceBeforeDiscount
    price
    priceBeforeDiscount
    siteCharges
    siteCreditCardFee
    siteReceives
    vendorCharges
    vendorCreditCardFee
    vendorReceives
    days
    unit
  }
`;
export const UsageBreakdownFieldsFragmentDoc = gql`
  fragment usageBreakdownFields on UsagePriceBreakdown {
    discountAmount
    homeroomCharges
    listPrice
    listPriceBeforeDiscount
    price
    priceBeforeDiscount
    siteCharges
    siteCreditCardFee
    siteReceives
    vendorCharges
    vendorCreditCardFee
    vendorReceives
    unit
  }
`;
