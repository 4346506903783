import * as React from 'react';

import { Icon, VBox } from 'app/app2/components';

import { Demo } from './Demo';

export function IconDemos() {
  return <VBox>
    <Demo name='Regular'>
      <Icon name='Trash2' />
    </Demo>

    <Demo name='Loading' description='Shows a loader...this is automatic for any click operation that returns a promise'>
      <Icon name='Trash2' loading />
      <Icon name='Trash2' onClick={async () => await new Promise((resolve) => setTimeout(resolve, 10000))} />
    </Demo>

    <Demo name='Size' description='Using theme sizes'>
      <Icon name='Trash2' size='medium' />
      <Icon name='Trash2' size='small' />
    </Demo>

    <Demo name='Hover' description='Hover adds a hover effect to the icon'>
      <Icon name='Trash2' hover />
    </Demo>

    <Demo name='Button' description='Button is hover + a pointer on mouse over'>
      <Icon name='Trash2' button />
    </Demo>
  </VBox>
}
