import * as React from 'react';
import * as ReactIs from 'react-is';

type PropsBaseType = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;
type Props<P extends PropsBaseType> = React.ComponentProps<P>;
type ReactType<P extends PropsBaseType> = React.ComponentType<Props<P>>;
export type TypePropsOrElement<P extends PropsBaseType> = ReactType<P> | Props<P> | React.ReactElement<P>;

// create an element from a base type and base props, that is overridden by the passed in type, props or element

// examples:
//  input:
//    baseType = Input
//    baseProps = {width: '50%'}
//    typePropsOrElement = CurrencyInput
//  output:
//    <CurrencyInput width='50%' />

//  input:
//    baseType = Input
//    baseProps = {width: '50%'}
//    typePropsOrElement = <NumberInput width='100%' border='solid' />
//  output:
//    <NumberInput width='100%' border='solid' />

//  input:
//    baseType = Input
//    baseProps = {width: '50%'}
//    typePropsOrElement = {border: 'solid'}
//  output:
//    <Input width='50%' border='solid' />

export function createOrClone<P extends PropsBaseType>(baseType:ReactType<P>, baseProps:Props<P>, typePropsOrElement:TypePropsOrElement<P>) {
  if (ReactIs.isElement(typePropsOrElement)) {
    const props = Object.assign({}, baseProps, typePropsOrElement.props, {key: typePropsOrElement.key ?? baseProps?.key ?? undefined});
    return React.cloneElement(typePropsOrElement, props);
  }

  if (ReactIs.isValidElementType(typePropsOrElement)) {
    return React.createElement(typePropsOrElement, baseProps);
  }

  return React.createElement(baseType, Object.assign({}, baseProps, typePropsOrElement));
}
