import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentApplyDiscountMutationVariables = Types.Exact<{
  code: Types.Scalars['ID'];
}>;

export type ParentApplyDiscountMutation = { cartApplyDiscount?: { success?: boolean | null | undefined } | null | undefined };

export const ParentApplyDiscountDocument = gql`
  mutation ParentApplyDiscount($code: ID!) @urql(additionalTypenames: ["User"]) {
    cartApplyDiscount(code: $code) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentApplyDiscountOptions = ExecMutationOptions<ParentApplyDiscountMutationVariables>;

export function parentApplyDiscount(options: ParentApplyDiscountOptions) {
  return executeMutation<ParentApplyDiscountMutation, ParentApplyDiscountMutationVariables>(ParentApplyDiscountDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
