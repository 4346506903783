import { setAppMode, disableSmoothScrolling } from 'app2/components';

import { AppStatusMonitor } from './AppStatusMonitor';

// doesn't anything other than ensure this code doesn't get optimized out
export function testModeMonitor() {
}

// used by the e2e tests
// @ts-ignore
window.setTestMode = () => {
  setAppMode('test');
  disableSmoothScrolling();
  AppStatusMonitor.idleReloader.stop()
}
