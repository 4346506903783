import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MergeStudentsMutationVariables = Types.Exact<{
  source: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
}>;

export type MergeStudentsMutation = { studentsMerge?: { student?: { id: string; name: string } | null | undefined } | null | undefined };

export const MergeStudentsDocument = gql`
  mutation MergeStudents($source: ID!, $destination: ID!) {
    studentsMerge(source: $source, destination: $destination) {
      student {
        id
        name
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MergeStudentsOptions = ExecMutationOptions<MergeStudentsMutationVariables>;

export function mergeStudents(options: MergeStudentsOptions) {
  return executeMutation<MergeStudentsMutation, MergeStudentsMutationVariables>(MergeStudentsDocument, options);
}
