import { EventRecorder } from './EventRecorder';

interface MethodData {
  name: string;
  args: any[];
}

export class EventRecorderShim extends EventRecorder {
  queuedCalls: MethodData[];

  constructor() {
    super();

    this.queuedCalls = [];
  }

  get recordForHomeroomStaff() {
    return true;
  }

  onLogRocketUrlUpdated(...args: any[]): void {
    this.queuedCalls.push({ name: 'onLogRocketUrlUpdated', args });
  }

  onAppStart(...args: any[]): void {
    this.queuedCalls.push({ name: 'onAppStart', args });
  }

  identifyUser(...args: any[]): void {
    // there might be events logged while we are starting
    // up and determining if the user is logged in, so
    // insert this before events if there's no initialize
    this.queuedCalls.splice(this.initializeInsertPos, 0, {
      name: 'identifyUser',
      args
    });
  }

  identifyNoUser(...args: any[]): void {
    // see above
    this.queuedCalls.splice(this.initializeInsertPos, 0, {
      name: 'identifyNoUser',
      args
    });
  }

  logStandard(...args: any[]): void {
    this.queuedCalls.push({ name: 'logStandard', args });
  }

  log(...args: any[]): void {
    this.queuedCalls.push({ name: 'log', args });
  }

  pageView(...args: any[]): void {
    this.queuedCalls.push({ name: 'pageView', args });
  }

  run(impl: EventRecorder) {
    // @ts-ignore
    this.queuedCalls.forEach(call => impl[call.name].apply(impl, call.args));
  }

  // see comment at identifyUser
  get initializeInsertPos(): number {
    const existingInitialize = this.queuedCalls.find(
      call => call.name.indexOf('User') != -1
    );

    return existingInitialize ? this.queuedCalls.length : 1;
  }
}
