import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorCourseFrameworksQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type VendorCourseFrameworksQuery = {
  vendor?:
    | {
        company?:
          | {
              courseFrameworks?:
                | Array<{
                    id: string;
                    name: string;
                    description: string;
                    courseCardImage?: string | null | undefined;
                    courseImage?: string | null | undefined;
                    supplies?: Array<string | null | undefined> | null | undefined;
                    typename: 'CourseFramework';
                    courseTags?: Array<{ id: string; name?: string | null | undefined } | null | undefined> | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const VendorCourseFrameworksDocument = gql`
  query VendorCourseFrameworks($vendor: ID!) @urql(additionalTypenames: ["CourseFramework"]) {
    vendor(vendor: $vendor) {
      company {
        courseFrameworks {
          typename: __typename
          id
          name
          description
          courseTags {
            id
            name
          }
          courseCardImage
          courseImage
          supplies
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorCourseFrameworksArgs = MakeOptional<UseQueryArgs<VendorCourseFrameworksQueryVariables, VendorCourseFrameworksQuery>, 'query'>;

export function useVendorCourseFrameworksQuery(options: VendorCourseFrameworksArgs = {}) {
  return useQuery<VendorCourseFrameworksQueryVariables, VendorCourseFrameworksQuery>({ query: VendorCourseFrameworksDocument, ...options });
}

export type VendorCourseFrameworksOptions = ExecQueryOptions<VendorCourseFrameworksQueryVariables>;

export function vendorCourseFrameworks(options: VendorCourseFrameworksOptions) {
  return executeQuery<VendorCourseFrameworksQuery, VendorCourseFrameworksQueryVariables>(VendorCourseFrameworksDocument, options);
}

export type VendorCourseFrameworksSelections = VendorCourseFrameworksQuery['vendor'];

(VendorCourseFrameworksDocument as any).additionalTypenames = ['CourseFramework'];
