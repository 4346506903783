import * as React from 'react';
import { cloneDeep } from 'lodash-es';

import { hasAppMode } from 'app2/components/AppModes';
import { ScriptLoader, ScriptLoaderComponentProps } from 'app/scriptLoading/ScriptLoader';
import { scriptManager } from '../ScriptManager';

export const stripeUrl = 'https://js.stripe.com/v3';
export const stripeUrlTest = stripeUrl + '/?advancedFraudSignals=false';

export class StripeLoader extends ScriptLoader {
  static initiateStripeLoading() {
    scriptManager.register(hasAppMode('test') ? stripeUrlTest : stripeUrl, true, undefined, true);
  }

  static defaultProps = {
    scripts: [{ script: stripeUrl, isUrl: true, blockInTestMode: true }]
  };

  constructor(props:ScriptLoaderComponentProps) {
    props = updateStripeUrlIfNeeded(props);
    super(props);
  }

  render() {
    return this.state.loaded ? this.props.children : '';
  }
}

export function updateStripeUrlIfNeeded(props:ScriptLoaderComponentProps) {
  if (hasAppMode('test')) {
    props = cloneDeep(props);
    props.scripts[0].script = stripeUrlTest;
  }

  return props;
}