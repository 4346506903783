import { getSymbol } from './util/getSymbol';

import { makeObservable } from './ObservableVisitor';
import { observableManager } from './ObservableManager';

const observableSymbol = getSymbol('observable');
const observableTargetSymbol: any = getSymbol('observableTarget');

// babel doesn't allow extending built in classes
// so this has to use the old school way of
// extending a class
import * as util from 'util';

interface ObservableMap<K, V> extends Map<K, V> {}

export const ObservableMap = (function<K, V>(
  this: ObservableMap<K, V>,
  entries?: IterableIterator<[K, V]>
) {
  const self = new Map();
  Object.setPrototypeOf(self, ObservableMap.prototype);

  //@ts-ignore
  self[observableSymbol] = self;

  //@ts-ignore
  self[observableTargetSymbol] = self;

  if (entries) {
    for (const entry of entries) {
      self.set(entry[0], makeObservable(entry[1]));
    }
  }
} as any) as {
  new (entries?: IterableIterator<[any, any]>): ObservableMap<any, any>;
};

util.inherits(ObservableMap, Map);
Object.setPrototypeOf(ObservableMap, Map);

ObservableMap.prototype.clear = function<K, V>() {
  observableManager.onChanged(this);

  Map.prototype.clear.call(this);
};

ObservableMap.prototype.delete = function<K, V>(key: K) {
  observableManager.onChanged(this);

  return Map.prototype.delete.call(this, key);
};

ObservableMap.prototype.get = function<K, V>(key: K): V | undefined {
  observableManager.onChanged(this);

  return Map.prototype.get.call(this, key);
};

ObservableMap.prototype.has = function<K, V>(key: K): boolean {
  observableManager.onChanged(this);

  return Map.prototype.has.call(this, key);
};

ObservableMap.prototype.set = function<K, V>(key: K, value: V) {
  observableManager.onChanged(this);

  return Map.prototype.set.call(this, key, value);
};

ObservableMap.prototype.forEach = function<K, V>(
  callbackfn: (value: V, key: K, map: Map<K, V>) => void,
  thisArg?: any
): void {
  observableManager.onChanged(this);

  return Map.prototype.forEach.call(this, callbackfn);
};

ObservableMap.prototype.entries = function<T>(): IterableIterator<[T, T]> {
  observableManager.onObserved(this);

  return Map.prototype.entries.call(this);
};

ObservableMap.prototype.keys = function<T>(): IterableIterator<T> {
  observableManager.onObserved(this);

  return Map.prototype.keys.call(this);
};

ObservableMap.prototype.values = function<T>(): IterableIterator<T> {
  observableManager.onObserved(this);

  return Map.prototype.values.call(this);
};

ObservableMap.prototype[Symbol.iterator] = function() {
  observableManager.onObserved(this);

  return Map.prototype[Symbol.iterator].call(this);
};
