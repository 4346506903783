// helper to make it easier to evaluate xpath expressions

export function xpath(path:string, root:HTMLElement) {
  const nodes = [];
  const iterator = document.evaluate(path, root || document, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
  
  let node = iterator.iterateNext();
  
  while (node) {
  nodes.push(node);
  node = iterator.iterateNext();
  }
  
  return nodes;
}