import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserFamiliesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserFamiliesQuery = { adminUser?: { families?: Array<{ id: string; name?: string | null | undefined }> | null | undefined } | null | undefined };

export const AdminUserFamiliesDocument = gql`
  query AdminUserFamilies($id: ID!) {
    adminUser(user: $id) {
      families(sorts: [{ name: "name", direction: DESC }]) {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserFamiliesArgs = MakeOptional<UseQueryArgs<AdminUserFamiliesQueryVariables, AdminUserFamiliesQuery>, 'query'>;

export function useAdminUserFamiliesQuery(options: AdminUserFamiliesArgs = {}) {
  return useQuery<AdminUserFamiliesQueryVariables, AdminUserFamiliesQuery>({ query: AdminUserFamiliesDocument, ...options });
}

export type AdminUserFamiliesOptions = ExecQueryOptions<AdminUserFamiliesQueryVariables>;

export function adminUserFamilies(options: AdminUserFamiliesOptions) {
  return executeQuery<AdminUserFamiliesQuery, AdminUserFamiliesQueryVariables>(AdminUserFamiliesDocument, options);
}

export type AdminUserFamiliesSelections = AdminUserFamiliesQuery['adminUser'];
