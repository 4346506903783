import * as React from 'react'

import { InputProps } from './Input'
import { isNumberLike, floatParser } from './form';
import { RestrictedInput } from './RestrictedInput';

export const NumberInput = React.forwardRef((props:InputProps, ref:any) => {
  return <RestrictedInput inputMode='numeric' parse={(amount:string) => floatParser(amount, Number(props.min), Number(props.max))} valid={isNumberLike} placeholder='0' {...props} />
})

NumberInput.displayName = 'NumberInput';
