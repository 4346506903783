import * as React from 'react';

import { HBox, Link, Text } from 'app2/components'

import { useCurrentSite } from '.';

export function CompanyDirectoryCTA() {
  const { site } = useCurrentSite();

return <HBox bg='successFill' border='solid 1px' borderColor='success' borderRadius='standard' p='$12' mb='$12' position='sticky' left={0} display={['none','flex']}>
    <Text text='body' flex={1}>Find more providers to teach at your school</Text>
    <Link to={`/directory/provider?site=${site?.slug}`} button='primary'>Provider directory</Link>
  </HBox>
}
