import * as React from 'react';

import { Box, Button, Phone, Tablet, Desktop } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function BreakpointDemos() {
  const [width, setWidth] = React.useState(undefined);

  return <Demos name='Breakpoint'>
    <Demo name='Container breakpoint'
      description={`
        Normally breakpoints are only based on the browser width.  This shows how to use ContainerBreakpoints
        which allows you to define breakpoints based on the container width.

        If you resize the browser, it will not affect the breakpoint inside the container.  Only
        resizing the container (which you can do via the buttons below) will change it's breakpoints.

        Note the breakpoints apply to the child of the container, not to the container itself.

        The background color is set to change with each breakpoint.
      `}>
      <Button onClick={() => setWidth(200)}>Set to phone</Button>
      <Button onClick={() => setWidth(500)}>Set to tablet</Button>
      <Button onClick={() => setWidth(1025)}>Set to desktop</Button>

      <Box border='solid 1px' layout='vbox' width={width} containerBreakpoints>
        <Box bg={['red', 'pink', 'yellow']}>
          <span>inside container:</span>
          <Phone>phone</Phone>
          <Tablet>tablet</Tablet>
          <Desktop>desktop</Desktop>
        </Box>
      </Box>

      <div>outside container:</div>
      <Phone>phone</Phone>
      <Tablet>tablet</Tablet>
      <Desktop>desktop</Desktop>
    </Demo>

    <Demo name='Custom breakpoint'
      description={`
        Changes the container to have different breakpoints compared to the theme.
        
        If you resize the browser, you'll see the breakpoints change inside the container
        vs. outside the container.  The breakpoints will not change based on the container size.

        Note the breakpoints apply to the child of the container, not to the container itself.

        The background color is set to change with each breakpoint.
      `}>
      <Button onClick={() => setWidth(200)}>Set to phone</Button>
      <Button onClick={() => setWidth(500)}>Set to tablet</Button>
      <Button onClick={() => setWidth(1025)}>Set to desktop</Button>

      <Box breakpoints={[600, 700]}>
        <Box border='solid 1px' layout='vbox' width={width} bg={['red', 'pink', 'yellow']}>
          <span>inside container:</span>
          <Phone>phone</Phone>
          <Tablet>tablet</Tablet>
          <Desktop>desktop</Desktop>
        </Box>
      </Box>

      <div>outside container:</div>
      <Phone>phone</Phone>
      <Tablet>tablet</Tablet>
      <Desktop>desktop</Desktop>
    </Demo>
  </Demos>
}

