import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentRefundsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Types.Scalars['OptionValue']>;
}>;

export type EnrollmentRefundsQuery = {
  enrollments?:
    | Array<{
        refunds: Array<
          | {
              createdAt: string;
              amount: number;
              pending: boolean;
              remainder: number;
              description?: string | null | undefined;
              initiatedBy?: { name?: string | null | undefined } | null | undefined;
              enrollment: { id: string; course: { disambiguatedName?: string | null | undefined } };
            }
          | null
          | undefined
        >;
      }>
    | null
    | undefined;
};

export const EnrollmentRefundsDocument = gql`
  query EnrollmentRefunds($ids: OptionValue) {
    enrollments(filters: [{ name: "id", value: $ids }]) {
      refunds {
        createdAt
        amount
        pending
        remainder
        description
        initiatedBy {
          name
        }
        enrollment {
          id
          course {
            disambiguatedName
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentRefundsArgs = MakeOptional<UseQueryArgs<EnrollmentRefundsQueryVariables, EnrollmentRefundsQuery>, 'query'>;

export function useEnrollmentRefundsQuery(options: EnrollmentRefundsArgs = {}) {
  return useQuery<EnrollmentRefundsQueryVariables, EnrollmentRefundsQuery>({ query: EnrollmentRefundsDocument, ...options });
}

export type EnrollmentRefundsOptions = ExecQueryOptions<EnrollmentRefundsQueryVariables>;

export function enrollmentRefunds(options: EnrollmentRefundsOptions) {
  return executeQuery<EnrollmentRefundsQuery, EnrollmentRefundsQueryVariables>(EnrollmentRefundsDocument, options);
}

export type EnrollmentRefundsSelections = EnrollmentRefundsQuery['enrollments'][0];

(EnrollmentRefundsDocument as any).additionalTypenames = ['Enrollment'];
