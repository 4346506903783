import * as React from 'react'
import { omit } from 'lodash-es';

import { MessageInput, MessageEntityInput, MessageServiceKind } from 'app2/api';
import { Collapsible, FieldInfo, FormModel, Form, Img, Modal, NotificationManager, RadioGroup, Saveable, SaveableProps, Section, Span, TextAreaField, useForm } from 'app2/components';

import { MessageEntity } from '../MessageEntity';
import { MessageParticipants, formatMessageEntity } from '../MessageParticipants';

import { ThreadComposeState } from './ThreadComposeState';
import { useFromTo } from './useFromTo';
import conversation from './conversation.png';
import megaphone from './megaphone.png';
import { createMessage } from './generated';

interface Props extends SaveableProps, ThreadComposeState {
  labels?:(MessageEntityInput | MessageEntity)[];
  kind?:'sms' | 'email';
  onClickStudent?:(id:string) => void;
}

export function CreateMessage(props:Props) {
  const { to, labels, onOk:propsOnOk, replyAll, hideReplyAll, service, hideService, signature, onClickStudent, ...remaming } = props;
  const { userInfo, uniqTo, from, fromEntity } = useFromTo(props);
  const body = React.useRef('');
  const form = useForm({
    preferredService: service || MessageServiceKind.Email, 
    messageType: replyAll ? 'conversation' : 'broadcast', 
  }, [userInfo.user]);

  function render() {
    if (!userInfo?.user) {
      return <></>;
    }

    // the empty class name is a hack to remove a border thats appearing when this component nested under another form
    return <Saveable {...remaming}>
      <Form width='100%' editing onOk={onOk} form={form} pr={0} className='' onNavigation='nothing'>
        <Collapsible minHeight={72}><Section label="To"><MessageParticipants participants={uniqTo} onClickStudent={onClickStudent} /></Section></Collapsible>
        {/* <Section label="Subject" name='subject' component={InputField} /> */}
        <Section>
          <Section label="From"><Span>{formatMessageEntity(from)}</Span></Section>
          {!hideService && <Section label="Format" name='preferredService' component={<RadioGroup layout='hbox' options={[
            {label: 'Email', value: MessageServiceKind.Email}, 
            {label: 'Text', value: MessageServiceKind.Sms}
            ]} />} />}
        </Section>
        <Section label="Message" name='body' required component={TextAreaField} onChange={onChangeBody} />
        {!hideReplyAll && <Section label="Message type" name='messageType' component={<RadioGroup options={[
          {label: <>Conversation <Img src={conversation} width='18px' ml='$8' /></>, description: 'All recipients will see every reply, fostering a group discussion.', value: 'conversation'}, 
          {label: <>Broadcast <Img src={megaphone} width='18px' ml='$8' /></>, description:'Replies from recipients will only be visible to you, the original sender.  Other recipients will not see their responses.', value: 'broadcast'}
          ]} />} />}
      </Form>
    </Saveable>
  }

  function onChangeBody(value:string, info:FieldInfo) {
    // const oldAutoSubject = body.current ? truncate(body.current, {length:25}) : undefined;

    // body.current = value;
    
    // if (info.form.values.subject == oldAutoSubject) {
    //   info.form.setValue('subject', truncate(value, {length:25}));
    // }
  }

  async function onOk(form:FormModel) {
    if (uniqTo.length == 0) {
      Modal.error({title: 'No recipients', content: 'Please select at least one recipient.  You can not send a message to yourself.'});
      return false;
    }

    const toIds = uniqTo.map(user => ({id: user.id, type: user.type, entity: (user as MessageEntityInput).entity}));
    const to = form.values.messageType == 'conversation' ? toIds : null;
    const bcc = form.values.messageType == 'broadcast' ? toIds : null;

    const attributes:MessageInput = {
      fromEntity, to, bcc,
      labels: labels?.map(label => ({id: label.id, type: label.type})),
      ...omit(form.values, 'messageType')
    }

    const [success] = await createMessage({variables: {attributes}});

    if (success) {
      NotificationManager.success('Message sent!');
      propsOnOk?.();
    }

    return success;
  }

  return render();
}

