import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUpdateCartPaymentServiceMutationVariables = Types.Exact<{
  paymentService?: Types.InputMaybe<Types.PaymentService>;
}>;

export type ParentUpdateCartPaymentServiceMutation = { cartUpdatePaymentService?: { success?: boolean | null | undefined } | null | undefined };

export const ParentUpdateCartPaymentServiceDocument = gql`
  mutation ParentUpdateCartPaymentService($paymentService: PaymentService) @urql(additionalTypenames: ["User"]) {
    cartUpdatePaymentService(paymentService: $paymentService) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentUpdateCartPaymentServiceOptions = ExecMutationOptions<ParentUpdateCartPaymentServiceMutationVariables>;

export function parentUpdateCartPaymentService(options: ParentUpdateCartPaymentServiceOptions) {
  return executeMutation<ParentUpdateCartPaymentServiceMutation, ParentUpdateCartPaymentServiceMutationVariables>(ParentUpdateCartPaymentServiceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
