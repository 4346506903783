
// some functionality to make it easier to pass messages to iframes

export type EmbedMessageHandler<T> = (data:T) => void;
let counter = 0;

export class EmbedMessageManager {
  prefix:string;
  handlers:{[event:string]:EmbedMessageHandler<any>} = {};

  sendMessage<T>(name:string, data:T) {
    const message = { name: this.prefix + name, data, __id: ++counter };
    window.parent.postMessage(message, '*');
  }

  registerHandler<T>(message:string, cb:EmbedMessageHandler<T>) {
    this.handlers[message] = cb;
  }

  onMessage = (event:MessageEvent) => {
    if (typeof event.data != 'object') {
      return;
    }

    let message = event.data.name as string;

    if (!message) {
      return;
    }

    if (this.prefix && !message.startsWith(this.prefix)) {
      return;
    }

    if (this.prefix) {
      message = message.substring(this.prefix.length);
    }

    if (!this.handlers[message]) {
      return;
    }
  
    this.handlers[message](event.data.data);
  }
}

export const embedMessageManager = new EmbedMessageManager();

window.addEventListener('message', embedMessageManager.onMessage);

