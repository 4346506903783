import * as React from 'react'

import { resetPasswordRequest } from 'app2/api';
import { Info, Panel, FormModel, HBox, EmailField, Link, Redirect, Section } from 'app2/components';
import { PublicPage } from 'app2/views/shared-public';

export function PasswordResetRequest() {
  const [submitted, setSubmitted] = React.useState(false);

  function render() {
    return <PublicPage title='Reset your password' useMaxWidth>
      {submitted ? renderSubmitted() : renderForm()}
    </PublicPage>
  }

  function renderForm() {
    return <Panel type='edit' onOk={submit} ok='Email reset link' footerActions={renderFooter()} onNavigation='nothing'>
      <Section name='email' label='Email address' component={EmailField} required />
    </Panel>
  }

  function renderSubmitted() {
    return <Info type='success' message='If an account is found you will be sent email instructions' />
  }

  function renderFooter() {
    return <HBox text='body' vAlign='center' gap='$8'><Link to='/login'>Back to login</Link></HBox>
  }

  async function submit(form:FormModel) {
    const response = await resetPasswordRequest(form.values);
    setSubmitted(true);
  }

  return render();
}
