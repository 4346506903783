import * as React from 'react';
import { isEqual } from 'lodash-es';
import { Prompt } from 'react-router-dom';

import { getAppMode } from './AppModes';

type UnsavedChangesInfo = {
  id:number,
  editing: boolean | (() => boolean);
  message: string;
};

// React router doesn't support multiple prompts at a time and will generate
// an error, so UnsavedChangesManager exists as a singleton to act as a proxy
// when there are multiple UnsavedChange component instances.  It looks for 
// the first editing instance and uses that message for a prompt to save when navigating.

export class UnsavedChangesManager extends React.Component {
  static instance:UnsavedChangesManager;

  constructor(props:{}) {
    super(props);

    UnsavedChangesManager.instance = this;
    window.addEventListener('beforeunload', this.handleUnload);
  }

  render() {
    return <Prompt when={true} message={this.handleRouteChange} />
  }

  prompts:UnsavedChangesInfo[] = [];

  add(prompt:UnsavedChangesInfo) {
    this.prompts.push(prompt);
  }

  remove(id:number) {
    const pos = this.prompts.findIndex(existing => existing.id == id);

    if (pos != -1) {
      this.prompts.splice(pos, 1);
    }
  }

  update(prompt:UnsavedChangesInfo) {
    const pos = this.prompts.findIndex(existing => existing.id == prompt.id);

    if (pos != -1 && !isEqual(this.prompts[pos], prompt)) {
      this.prompts[pos] = prompt;
    }
  }

  get current() {
    return this.prompts.find(existing => typeof existing.editing == 'function' ? existing.editing() : existing.editing);
  }

  handleRouteChange = () => {
    if (getAppMode('test')) {
      return;
    }

    return this.current?.message;
  }

  handleUnload = (event:Event) => {
    if (getAppMode('test')) {
      return event.returnValue;
    }
    
    const editing = this.current;

    if (editing) {
      // older browsers use preventDefault to indicate that you want to prompt to save
      event.preventDefault();
      // chrome now wants you to set returnValue to the message (which it seems to ignore and use its own)
      //@ts-ignore
      event.returnValue = editing.message;
    }

    return event.returnValue;
  }
}
