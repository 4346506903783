import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyCancellationQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyCancellationQuery = {
  company?:
    | {
        courseCancellationPolicy?: string | null | undefined;
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        slug: string;
      }
    | null
    | undefined;
};

export const CompanyCancellationDocument = gql`
  query CompanyCancellation($company: ID!) {
    company(company: $company) {
      ...commonCompany
      courseCancellationPolicy
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyCancellationArgs = MakeOptional<UseQueryArgs<CompanyCancellationQueryVariables, CompanyCancellationQuery>, 'query'>;

export function useCompanyCancellationQuery(options: CompanyCancellationArgs = {}) {
  return useQuery<CompanyCancellationQueryVariables, CompanyCancellationQuery>({ query: CompanyCancellationDocument, ...options });
}

export type CompanyCancellationOptions = ExecQueryOptions<CompanyCancellationQueryVariables>;

export function companyCancellation(options: CompanyCancellationOptions) {
  return executeQuery<CompanyCancellationQuery, CompanyCancellationQueryVariables>(CompanyCancellationDocument, options);
}

export type CompanyCancellationSelections = CompanyCancellationQuery['company'];
