import * as React from 'react';

import { VirtualTable, CellProps, Button } from 'app/app2/components';

import { Demo } from './Demo';

function createFactory(numRows:number = 500) {
  return {
    numRows,
    rowHeight: () => 40,
    numCols: 50,
    colWidth: () => 100,
    render: (props: CellProps) => {
      return <span key={`row:${props.row},col:${props.col}`} style={{background: '#fff', border: 'solid 1px', marginRight: '-1px', marginBottom: '-1px', ...props.style}}>{`row:${props.row}, col: ${props.col}`}</span>;
    }
  }
}

const rows500 = createFactory(500);
const rows25 = createFactory(25);

export function VirtualTableDemos() {
  return <div>
    <Demo name='Basic'>
      <div style={{ overflow: 'scroll', width: '600px', height: '400px' }}>
        <VirtualTable factory={rows500} headerRows={2} headerCols={2} />
      </div>
    </Demo>

    <Demo name='Two grids in the same scrollable area'>
      <div style={{ overflow: 'scroll', width: '600px', height: '400px' }}>
        <VirtualTable factory={rows25} headerRows={2} headerCols={2} style={{position:'relative', left:'75px', top:'25px'}} />
        <Button>Button</Button>
        <VirtualTable factory={rows25} headerRows={2} headerCols={2} />
      </div>
    </Demo>
  </div>
}
