import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyCoursesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCompanyCoursesQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        courses: Array<{
          id: string;
          name?: string | null | undefined;
          status: Types.CourseStatus;
          season: { id: string; name: string; site: { id: string; name?: string | null | undefined } };
          site: { id: string; name?: string | null | undefined };
        }>;
      }
    | null
    | undefined;
};

export const AdminCompanyCoursesDocument = gql`
  query AdminCompanyCourses($id: ID!) {
    company(company: $id) {
      id
      name
      courses {
        id
        name
        status
        season {
          id
          name
          site {
            id
            name
          }
        }
        site {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCompanyCoursesArgs = MakeOptional<UseQueryArgs<AdminCompanyCoursesQueryVariables, AdminCompanyCoursesQuery>, 'query'>;

export function useAdminCompanyCoursesQuery(options: AdminCompanyCoursesArgs = {}) {
  return useQuery<AdminCompanyCoursesQueryVariables, AdminCompanyCoursesQuery>({ query: AdminCompanyCoursesDocument, ...options });
}

export type AdminCompanyCoursesOptions = ExecQueryOptions<AdminCompanyCoursesQueryVariables>;

export function adminCompanyCourses(options: AdminCompanyCoursesOptions) {
  return executeQuery<AdminCompanyCoursesQuery, AdminCompanyCoursesQueryVariables>(AdminCompanyCoursesDocument, options);
}

export type AdminCompanyCoursesSelections = AdminCompanyCoursesQuery['company'];
