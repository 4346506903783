import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TimeEntryCheckOutMutationVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
}>;

export type TimeEntryCheckOutMutation = { timeEntryCheckOut?: { timeEntry?: { id: string } | null | undefined } | null | undefined };

export const TimeEntryCheckOutDocument = gql`
  mutation TimeEntryCheckOut($ownerType: EntityKind!, $ownerId: ID!) {
    timeEntryCheckOut(ownerType: $ownerType, ownerId: $ownerId) {
      timeEntry {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TimeEntryCheckOutOptions = ExecMutationOptions<TimeEntryCheckOutMutationVariables>;

export function timeEntryCheckOut(options: TimeEntryCheckOutOptions) {
  return executeMutation<TimeEntryCheckOutMutation, TimeEntryCheckOutMutationVariables>(TimeEntryCheckOutDocument, options);
}
