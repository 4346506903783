import * as React from 'react';

import { EntityKind, organizerPreferences } from 'app2/api';
import { formatCurrency } from 'app2/components';
import { ReportTransactions, SiteSelections } from 'app2/views/shared';

import { useCurrentSeason } from '../shared';

import { useOrganizerReportTransactionsQuery } from './gql'

interface Props {
  site:SiteSelections;
}

export function OrganizerReportTransactions(props:Props) {
  const season = useCurrentSeason();

  return <ReportTransactions type={EntityKind.Site} entity={props.site} totals={totals} season={season} queryHook={useOrganizerReportTransactionsQuery} prefs={organizerPreferences.seasonPreferences(season?.id)} prefsVersion='4' />;
}

const totals = [
  {name:'transactionAmount', label: 'Total family spend', format: formatCurrency},
  {name:'companyEarnings', label: 'Total provider earnings', format: formatCurrency},
  {name:'siteEarnings', label: 'Total school earnings', format: formatCurrency},
];

