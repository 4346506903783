import * as React from 'react';
import moment from 'moment-timezone';
import { isEmpty, omit } from 'lodash-es';

import { DeepPartial, SeasonUtils } from 'app2/api';
import { CheckboxField, DropdownField, Collapsible, Panel, Section, MultipleDateField, DateField, DateInput, FormattedText, VBox, InputField, Part, Box, Calendar, PanelProps, FormModel, FieldInfo, Modal, Link, Text, TextArea, TimeField, InfoProps, useForm, relatedEndDate, validateDateRange, startOfDay, endOfDay } from 'app2/components';
import { CourseKindGroupField, ImmediatePayoutConfig, removePathSegment, courseKindBehavior, useFormPanelEditingState, START_DATE, START_TIME, END_DATE, END_TIME, SiteSelections } from 'app2/views';

import { organizerSiteSeasons, OrganizerSeasonSelections } from '../shared';

import { OrganizerUpsertSeasonMutation, organizerUpsertSeason, useRegistrationGroupsQuery } from './gql';

export type MutatedSeason = Required<OrganizerUpsertSeasonMutation['seasonUpsert']['season']>;
type Form = FormModel<OrganizerSeasonSelections>;

interface Props extends Partial<PanelProps<OrganizerSeasonSelections>> {
  site: SiteSelections;
  title?: string;
  season?: DeepPartial<OrganizerSeasonSelections>;
  parentOnOk?: (season: MutatedSeason) => void;
  create?:boolean;
}

export function SeasonForm(props: Props) {
  const { title, site, season, parentOnOk, create, ...remaining } = props;
  const [calDates, setCalDates] = React.useState({ season, enrollment: { start: undefined, end: undefined }, courseDays: { start: undefined, end: undefined }, noEnrichment: [] });
  const [info, setInfo] = React.useState<InfoProps>();
  const { editing, handleToggleEditing } = useFormPanelEditingState(false, onHandleToggleEditing);
  const form = useForm<OrganizerSeasonSelections>(season, [season]);
  const editingOrTypeEdit = editing || props.type == 'edit';

  React.useEffect(() => {
    if (season !== calDates.season && season) {
      setCalDates({ season, ...calcCalDates(season as OrganizerSeasonSelections) });
    }
  }, [season, calDates.season]);

  const registrationGroupIsStandard = isStandardRegistrationGroupName();
  const registrationGroups = getRegistrationOptions()

  function render() {
    return (
      <Box width="100%" layout={['vbox', 'hbox']} majorItemSpace={['$12', '$12', '$12']}>
        <Panel mb={undefined} icon="BookOpen" title={props.title} type="toggle" form={form} onChange={handleFormChange} onOk={form => handleSubmit(form)} info={info} onToggleEditing={handleToggleEditing} {...remaining}>
            <Section>
              <Section label="Season name" name="name" required component={InputField} />
              <VBox gap='$8'>
                <Section label="Type" name="courseKindGroups" component={CourseKindGroupField} onChange={onChangeKind} />
              </VBox>
            </Section>
            <Section label="Enrollment starts" required pair>
              <Part name="enrollmentOpens" label={START_DATE} {...DateField} required timezone={site?.timezone} placeholder onChange={relatedEndDate<OrganizerSeasonSelections>('enrollmentCloses')} min='now' />
              <Part name="enrollmentOpens" label={START_TIME} {...TimeField} required timezone={site?.timezone} placeholder onChange={relatedEndDate<OrganizerSeasonSelections>('enrollmentCloses')} min='now' />
            </Section>
            <Section label="Enrollment ends" required pair>
              <Part name="enrollmentCloses" label={END_DATE} {...DateField} required timezone={site?.timezone} placeholder validators={validateEnrollmentDates} onChange={relatedEndDate<OrganizerSeasonSelections>('coursesBegin', true)} min='now' />
              <Part name="enrollmentCloses" label={END_TIME} {...TimeField} required timezone={site?.timezone} placeholder validators={validateEnrollmentDates} onChange={relatedEndDate<OrganizerSeasonSelections>('coursesBegin', true)} min='now' />
            </Section>
            <Section label="Season period" required pair>
              <Part name="coursesBegin" label={START_DATE} {...DateField} required timezone={site?.timezone} placeholder onChange={relatedEndDate<OrganizerSeasonSelections>('coursesFinish')} validators={validateSeasonDates} min='now' parse={startOfDay} />
              <Part name="coursesFinish" label={END_DATE} {...DateField} required timezone={site?.timezone} placeholder validators={validateSeasonDates} min='now' parse={endOfDay} />
            </Section>
            <Collapsible controls={!editingOrTypeEdit} open={!editingOrTypeEdit ? undefined : true} minHeight='50px' mb={0}>
              <Section label="No activity days" name="holidays" placeholder {...MultipleDateField} start={form.values?.coursesBegin} />
            </Collapsible>
            {site?.hasSiteCompanies && <ImmediatePayoutConfig />}
            <Section label='Private season' description='Mark a season private if you only intend to share it with a specific group, like students in your school. Note that you must share the “Private registration” link directly, as it will not be available to families in school search results.'>
              <Section label='Private' name='private' component={CheckboxField} />
            </Section>
            <Section label="Registration tab" name="registrationGroup" component={{...DropdownField, edit:{...DropdownField.edit, additions:true}}} options={registrationGroups} />
            <VBox gap='$8'>
              <Section label="Registration note" name="note" description={<Text bold>Display a note on your registration page</Text>} edit={<TextArea autoSize/>} display={FormattedText} placeholder />
            </VBox>
        </Panel>
        <Calendar
          width={['100%', '288px', '362px']}
          selected={SeasonUtils.nowOrSeasonBegin(season as OrganizerSeasonSelections)}
          legend={[
            { name: 'Enrollment', bg: 'enrollment', ...calDates.enrollment },
            { name: 'Activity days', bg: 'courseDay', ...calDates.courseDays },
            { name: 'No activity days', bg: 'noEnrichment', days: calDates.noEnrichment }
          ]}
          timezone={site?.timezone}
        />
      </Box>
    );
  }

  function onChangeKind() {
    if (form.values?.registrationGroup && (!registrationGroupIsStandard || isSelectedRegistrationGroupName())) {
      return;
    }

    const behavior = courseKindBehavior[form.values.courseKindGroups?.[0]];

    if (!behavior) {
      return;
    }

    form.setValue('registrationGroup', behavior.label);
  }

  function isStandardRegistrationGroupName() {
    return Object.values(courseKindBehavior).some(g => g.label.toLocaleLowerCase() == form.values?.registrationGroup?.toLowerCase());
  }

  function isSelectedRegistrationGroupName() {
    return Object.values(form.values.courseKindGroups).some(g => courseKindBehavior[g]?.label?.toLocaleLowerCase() == form.values?.registrationGroup?.toLowerCase());
  }

  function getRegistrationOptions() {
    const [registrationGroupsResult] = useRegistrationGroupsQuery({variables: {site: site?.id, kindGroups: form.values?.courseKindGroups}, pause: !editing});
    const registrationGroups = React.useMemo(() => {
      const options = registrationGroupsResult.data?.site?.registrationGroups || [];

      if (form.values?.registrationGroup && !options.includes(form.values?.registrationGroup)) {
        options.unshift(form.values?.registrationGroup);
      }

      return options;
    }, [registrationGroupsResult, form.values?.registrationGroup]);
  
    return registrationGroups;
  }

  function onHandleToggleEditing(editing: boolean) {
    if (!editing) {
      setInfo(null);
    }
  }

  function handleFormChange(form: Form) {
    setCalDates({ season, ...calcCalDates(form.values as OrganizerSeasonSelections) });
    setInfo(seasonPeriodWarning(form));
  }

  function calcCalDates(season: OrganizerSeasonSelections) {
    return {
      enrollment: { start: season.enrollmentOpens, end: season.enrollmentCloses },
      courseDays: { start: season.coursesBegin, end: season.coursesFinish },
      noEnrichment: season.holidays
    };
  }

  function seasonPeriodWarning(form: Form): InfoProps | undefined {
    const { values } = form;
    
    if (!editing || !values.courseKindGroups?.some(group => courseKindBehavior[group].sessionType == 'days') || !values.coursesBegin || !values.coursesFinish) {
      return;
    }

    const coursesBeginField = form.getInfo([], 'coursesBegin');
    const coursesFinishField = form.getInfo([], 'coursesFinish');
    const holidaysField = form.getInfo([], 'holidays');
    if (!coursesBeginField.touched && !coursesFinishField.touched && !holidaysField.touched) {
      return;
    }

    return {
      type: 'warning',
      children: 'Activities may be updated to reflect these new dates.'
    };
  }

  async function handleSubmit(form: Form) {
    if (payoutDateMightChange(form)) {
      await Modal.warning({
        title: 'These changes may delay payouts',
        content: (
          <Text text="body">
            Changing the end of this season&apos;s enrollment period may delay payouts. To keep enrollment open without affecting payouts,{' '}
            <Link to="https://homeroom.helpscoutdocs.com/article/64-extend-enrollment-course" target="_blank">
              follow these simple steps.
            </Link>
          </Text>
        )
      });
    }

    const attributes = { ...(omit(form.values, 'hasFutureCourses', 'hasCourses', 'completed') as OrganizerSeasonSelections), siteId: site.id };
    const [success, result] = await organizerUpsertSeason({ variables: { attributes }, successMsg: 'Season saved', error: {handler: form, transform: removePathSegment('attributes')} });

    if (success) {
      await organizerSiteSeasons({ variables: { siteId: site.id }});

      if (parentOnOk) {
        parentOnOk(result.data.seasonUpsert.season as MutatedSeason);
      }
    }

    return success;
  }

  function payoutDateMightChange(form: Form) {
    const { initialValues, values } = form;

    if (create || isEmpty(initialValues) || isEmpty(values)) {
      return false;
    }

    const now = moment();
    const currentEnrollmentCloses = moment(initialValues.enrollmentCloses);
    if (now.isAfter(currentEnrollmentCloses)) {
      return false;
    }

    const nextEnrollmentCloses = moment(values.enrollmentCloses);
    return nextEnrollmentCloses.startOf('day').diff(currentEnrollmentCloses.startOf('day'), 'days') > 0;
  }

  return render();
}

function validateSeasonDates(value:DateInput, info:FieldInfo<OrganizerSeasonSelections, 'coursesBegin' | 'coursesFinish'>) {
  return validateDateRange('coursesBegin', 'coursesFinish', info, 'Season');
}

function validateEnrollmentDates(value:DateInput, info:FieldInfo<OrganizerSeasonSelections, 'enrollmentOpens' | 'enrollmentCloses'>) {
  return validateDateRange('enrollmentOpens', 'enrollmentCloses', info, 'Enrollment');
}

