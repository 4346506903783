import * as React from 'react';
import { debounce } from 'lodash-es';

import { compact, Filter, FilterOperator } from 'app2/api';
import { Button, Field, FieldInfo, FieldRendererProps, Form, HBox, InputField, Modal, Panel, RepeatingSection, SaveableResult, Section, useForm } from 'app2/components';
import { PrivatePage } from 'app2/views/shared';

import { siteClaim, siteClaimSearch, SiteClaimSearchSelections, siteCreate } from './generated';

export function ClaimSite() {
  const [sites, setSites] = React.useState<SiteClaimSearchSelections[]>([]);
  const form = useForm({}, []);

  function render() {
    return <PrivatePage title='Search for your school'>
      <Panel type='edit-no-save-button' onNavigation='nothing' form={form}>
        <Section label='School name' name='school' component={InputField} onChange={search} />
        <Section label='Zip code' name='zip' component={InputField} onChange={search} />
      </Panel>
      
      <Form values={{sites}} onNavigation='nothing' p='$30'>
        <Section label="Can't find your school?" component={<HBox><Button onClick={() => handeSiteCreate()}>Create school</Button></HBox>} />

        <Section label='Search results'>
          <RepeatingSection name='sites' fields={[
            <Field name='name' label='School name' />,
            <Field name='streetAddress' label='Address' />,
            <Field name='city' label='City' />,
            <Field name='state' label='State' />,
            <Field name='zipcode' label='Zip code' />,
            <Field render={(props:FieldRendererProps<SiteClaimSearchSelections>) => <Button autoLoader onClick={() => handleClaimSite(props.info.value)}>Claim</Button>} />,
          ]} />
        </Section>
      </Form>
    </PrivatePage>;
  }

  const search = debounce(async function(value:string, info:FieldInfo) {
    const form = info.form;
    const filter = compact<Filter>([
      form.values.school ? { name: 'name', op: FilterOperator.Contains, value: form.values.school} : undefined,
      form.values.zip ? { name: 'zipcode', op: FilterOperator.Contains, value: form.values.zip} : undefined,
    ]);

    const [success, result] = await siteClaimSearch({ variables: { filter }});

    if (success) {
      setSites(result.data.sites);
    }
  }, 500);

  async function handleClaimSite(site:SiteClaimSearchSelections) {
    await siteClaim({ variables: { site: site.id } });
  }

  async function handeSiteCreate() {
    const result = await Modal.show(<Modal title='Create school'>
      <Form initialValues={form.values}>
        <Section label='School name' name='school' component={InputField} required />
        <Section label='Zip code' name='zip' component={InputField} required />
      </Form>
    </Modal>);

    if (result.action === SaveableResult.cancel) {
      return;
    }

    const [success] = await siteCreate({ variables: { name: result.result.school, zipcode: result.result.zip } });

    return success;
  }

  return render();
}
