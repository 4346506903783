import * as React from 'react';

import { theme } from '../theme';

interface Props {
  size?: number;
  color?: string;
}

export const InstagramWhite = React.forwardRef((props:Props, ref:React.LegacyRef<SVGSVGElement>) => {
  const color = props.color || theme.colors.primary;

  return <svg ref={ref} width={props.size} height={props.size} viewBox="0 0 18 18" stroke={color} strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <g fill='currentColor'>
      <path d="M12.7502 1.5H5.25018C3.17912 1.5 1.50018 3.17893 1.50018 5.25V12.75C1.50018 14.8211 3.17912 16.5 5.25018 16.5H12.7502C14.8213 16.5 16.5002 14.8211 16.5002 12.75V5.25C16.5002 3.17893 14.8213 1.5 12.7502 1.5Z" fill="white"/>
      <path d="M12.0001 8.52748C12.0926 9.15167 11.986 9.78914 11.6954 10.3492C11.4048 10.9093 10.9449 11.3635 10.3813 11.6472C9.81767 11.9309 9.17892 12.0297 8.55593 11.9294C7.93293 11.8292 7.35741 11.535 6.91121 11.0889C6.46502 10.6427 6.17088 10.0671 6.07064 9.44414C5.97039 8.82115 6.06913 8.1824 6.35283 7.61876C6.63653 7.05512 7.09073 6.59529 7.65082 6.30466C8.21092 6.01404 8.8484 5.90742 9.47259 5.99998C10.1093 6.0944 10.6987 6.39108 11.1539 6.84621C11.609 7.30134 11.9057 7.89079 12.0001 8.52748Z" fill="#5C363C"/>
      <path d="M13.1251 4.875H13.1334" stroke="#5C363C" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
})

InstagramWhite.displayName = 'InstagramWhite';
InstagramWhite.defaultProps = {
  size: 18
}