import * as React from 'react'

import { Course, CfOwnerKind } from 'app2/api'
import { UseQueryArgs } from 'app2/views/shared';

import { useOrganizerCfQuestionsQuery } from './generated';

export interface CfOwnerProps {
  owner?:{id:string};
  ownerType?:CfOwnerKind;
  ownerId?:string;
}

export function useCfQuestionsQuery(props:CfOwnerProps, options?:Omit<UseQueryArgs<any, any>, 'query' | 'variables'>) {
  const {ownerType, ownerId} = ownerPropsToOwnerParts(props);
  const [result] = useOrganizerCfQuestionsQuery({variables:{ownerType, ownerId}, ...options});
  
  return React.useMemo(() => {
    return {type: ownerType, id: ownerId, questions:result?.data?.cfQuestions}
  } , [result?.data?.cfQuestions, ownerType, ownerId]);
}

export function ownerPropsToOwnerParts(props:CfOwnerProps) {
  const ownerType = props.owner ? ownerToKind(props.owner) : props.ownerType;
  const ownerId = props.owner ? props.owner.id : props.ownerId;

  return {ownerType, ownerId};
}

function ownerToKind(owner:Partial<Course>) {
  const type = (owner as any).__typename;

  if (type.indexOf('Course') != -1) {
    return CfOwnerKind.Course;
  }

  if (type.indexOf('Season') != -1) {
    return CfOwnerKind.Season;
  }

  return null;
}