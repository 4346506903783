import * as React from 'react'

import { Box, BoxProps } from '../../Box';
import { selectedItemAlpha, theme } from '../../theme';

import { DataTable } from '../DataTable';

export interface SelectionRendererProps extends BoxProps {
  table:DataTable;
  rowStart:number;
  colStart:number;
  rowEnd:number;
  colEnd:number;
  main:boolean;
}

export function SelectionRenderer(props:SelectionRendererProps) {
  const {table, rowStart, colStart, rowEnd, colEnd, main, ...remaining} = props;
  const className = main ? `hr-table-main-selection` : undefined;

  return <Box bg={theme.colors.primary + (Math.ceil(selectedItemAlpha * 255)).toString(16)} className={className} borderWidth='standard' borderRadius='standard' borderColor='primary' {...remaining} />;
}
