import * as React from 'react'
import { useHistory } from 'react-router';

import { SearchType } from 'app2/api';
import { Dropdown, TabletOrDesktop } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';

import { StudentDropdown } from '../student';

import { SearchTypeMenu } from './SearchTypeMenu';
import { CourseSearchDropdown } from './CourseSearchDropdown';
import { useSearchTypePreference } from './useSearchTypePreference';

export function AppSearchDropdown() {
  const history = useHistory();
  
  const [type, setType] = useSearchTypePreference();
  const [inputText, setInputText] = React.useState('');

  const { user } = useCurrentUser();

  function render() {
    if (!user?.roles?.organizer && !user?.roles?.provider) {
      return <></>;
    }

    return <TabletOrDesktop>
      {type == SearchType.Student
      ? <StudentDropdown controls={renderSearchType()} onKeyDown={onKeyDown} onInputChange={onInputChange} onChange={onChange} placeholder='Search for' />
      : <CourseSearchDropdown controls={renderSearchType()} placeholder='Search for' />}
    </TabletOrDesktop>
  }

  function renderSearchType() {
    return <SearchTypeMenu type={type} setType={setType} />;
  }

  function onInputChange(val:string) {
    setInputText(val);
  }

  function onKeyDown(event:React.KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }

    history.push(`/search?type=${type}&term=${inputText}`);
  }

  function onChange(event:React.ChangeEvent<Dropdown>) {
    const id = event.target.value.id;
    return history.push(`/student/${id}`);
  }

  return render();
}
