export function getQueryParams(urlOrParams:URL | URLSearchParams | string) {
  const params = urlOrParams instanceof URL 
    ? urlOrParams.searchParams 
    : typeof urlOrParams == 'string'
      ? new URL(urlOrParams).searchParams
      : urlOrParams as URLSearchParams;

  return params;
}

export function mergeQueryParams(urlOrParams1:URL | URLSearchParams | string, urlOrParams2:URL | URLSearchParams | string) {
  const params1 = getQueryParams(urlOrParams1);
  const params2 = getQueryParams(urlOrParams2);
  const mergedParams = new URLSearchParams(params1);

  params2.forEach((value, key) => {
    mergedParams.set(key, value);
  });

  return mergedParams;
}
