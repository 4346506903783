import * as React from 'react';

import { FileUploadAttributes } from 'app2/api';
import { RepeatingSection, Panel, Field, InputField, FieldRendererProps } from 'app2/components';
import { FileLink, FileTags, FileUploader } from 'app2/views';

import { Demo, Demos } from './Demo';

export function FileDemos() {
  const [files, setFiles] = React.useState<{ files: FileUploadAttributes[] }>({
    files: [
      { id: '1', name: 'File 1', fileType: 'application.pdf' },
      { id: '2', name: 'File 2', fileType: 'application.pdf' }
    ]
  });

  function render() {
    return <Demos name="File">
      <Demo name="Editable">
        <Panel values={files} type="edit">
          <RepeatingSection
            name="files"
            buttonCols={1}
            fields={[
              <Field name="name" label="Waiver name" component={InputField} />,
              <Field name="name" label="File" render={(props: FieldRendererProps) => <FileLink id={props.info.record.id} name={props.info.value} target="_blank" />} />,
              'remove'
            ]}
            add={
              <FileUploader kind="tertiary" icon="PlusCircle" onChoose={onChooseFile}>
                Add waiver
              </FileUploader>
            }
          />
        </Panel>
      </Demo>

      <Demo name="Readonly">
        <FileTags files={files.files} onRemove={onRemoveFile} />
        <FileUploader kind="secondary" onChoose={onChooseFile}>
          Upload file
        </FileUploader>
      </Demo>
    </Demos>;
  }

  function onChooseFile(name: string, fileType: string, fileData: string) {
    setFiles({ files: files.files.concat({ name, fileType, fileData }) });
  }

  function onRemoveFile(pos: number) {
    files.files.splice(pos, 1);
    setFiles({ files: files.files });
  }

  return render();
}
