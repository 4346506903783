import { NotificationManager, copyToClipboard as _copyToClipboard } from 'app2/components';

export async function copyToClipboard(content: string, successMsg: string) {
  const success = await _copyToClipboard(content);
  if (success) {
    NotificationManager.add({ type: 'success', message: successMsg });
  } else {
    NotificationManager.add({ type: 'error', message: 'Oops! Your browser may not support this feature. Please try updating your browser or switching to another' });
  }
}
