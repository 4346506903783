import { SearchType } from '../SearchType';

import { Preferences } from './Preferences';
import { localStorageHelper } from 'app/helpers';

export const userPreferencesKeys = {
  showAutomatedMessages: 'showAutomatedMessages',
  searchType:'searchType'
};

export class UserPreferences extends Preferences {
  get showAutomatedMessages(): boolean {
    return this.load(userPreferencesKeys.showAutomatedMessages, true);
  }

  set showAutomatedMessages(value: boolean) {
    this.save(userPreferencesKeys.showAutomatedMessages, value);
  }

  get searchType(): SearchType {
    return this.load(userPreferencesKeys.searchType, true);
  }

  set searchType(value: SearchType) {
    this.save(userPreferencesKeys.searchType, value);
  }

  get path() {
    return super.path.concat(this.userId);
  }

  public get userId() {
    return currentUserId;
  }

  clearAll() {
    let count = 0;
    const keysToRemove:string[] = [];

    while (true) {
      const key = localStorageHelper.key(count)
      count += 1;

      if (!key) {
        break;
      }

      const isUserPreference = key.startsWith(`${this.userId}:`)

      if (isUserPreference) {
        // can't remove the keys in the loop because that screws up the iteration
        keysToRemove.push(key)
      }
    }

    for (const key of keysToRemove) {
      localStorageHelper.removeItem(key);
    }
  }
}

export const userPreferences = new UserPreferences();

// TODO - remove this hack and put user preferences on the current user context
let currentUserId:string;
export function setPreferenceUserId(id:string) {
  currentUserId = id;
}
