import { DataTableColumnSort } from 'app2/components';

export const byAll = {
  label: 'All',
  name: 'all',
  // only one column sort is supported for now
  sort: ['student.lastName', 'student.firstName', 'course.name'],
  cols: ['student.firstName', {name:'student.lastName', sort: DataTableColumnSort.ascending}, 'student.classroom.displayName', 'student.grade', 'student.dob',
    'course.name', 'formattedDays', 'formattedTimes', 
    'rosterPeriod', 'otherEnrollments', 'course.room', 'groups', 'balance', 'added',
    'pickup', 'dismissal', 'student.medical.allergies', 'student.medical.epiPen', 'student.medical.medications', 'student.medical.conditions', 'student.notes', 'parent.name', 'parent.phone', 
    'parent.email', 'student.emergencyContacts',  'student.authorizedPickupContacts', 'parent.ccContacts', 'overlaps', 'trackingParameters'],
}
