import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RosterItemsSetAdditionalDiscountMutationVariables = Types.Exact<{
  discounts: Array<Types.SetAdditionalDiscountInput> | Types.SetAdditionalDiscountInput;
}>;

export type RosterItemsSetAdditionalDiscountMutation = { rosterItemsSetAdditionalDiscount?: { enrollments?: Array<{ id: string }> | null | undefined } | null | undefined };

export const RosterItemsSetAdditionalDiscountDocument = gql`
  mutation RosterItemsSetAdditionalDiscount($discounts: [SetAdditionalDiscountInput!]!) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterItemsSetAdditionalDiscount(discounts: $discounts) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RosterItemsSetAdditionalDiscountOptions = ExecMutationOptions<RosterItemsSetAdditionalDiscountMutationVariables>;

export function rosterItemsSetAdditionalDiscount(options: RosterItemsSetAdditionalDiscountOptions) {
  return executeMutation<RosterItemsSetAdditionalDiscountMutation, RosterItemsSetAdditionalDiscountMutationVariables>(RosterItemsSetAdditionalDiscountDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['RosterItem', 'Enrollment']) }
  });
}
