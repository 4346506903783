import * as React from 'react'

import { Panel, PanelProps, useForm } from 'app2/components';

import { FamilyTeam, FamilyTeamProps } from './FamilyTeam';

interface Props extends FamilyTeamProps, Pick<PanelProps, 'type'> {
};

export function FamilyTeamPanel(props:Props) {
  const {type, onNavigation, ...remaining} = props;

  const form = useForm({}, []);

  function render() {
    return <Panel icon="Users" title="Contacts" autoSave={props.autoSave} type={type} onNavigation={onNavigation} form={form}>
      <FamilyTeam onNavigation={onNavigation} {...remaining} form={form} />
    </Panel>
  }

  return render();
}

FamilyTeamPanel.defaultProps = {
  autoSave:true,
  onNavigation:'prompt-dirty',
  type:'edit-no-save-button' 
};