import * as React from 'react'

import { MemoryPreferenceStore, PreferenceStore } from '../../preferences';
import { useForceUpdate } from '../../utils';

import { DataTable  } from '../DataTable';

import { SkipColPrefs, TablePrefs } from './TablePrefs';

export function useTablePrefs<T>(target:TablePrefs<T>, prefStore?: PreferenceStore, prefsKey?: string, prefsVersion?: string, prefsSkipAttributes?:SkipColPrefs) {
  setupPrefs();

  const forceUpdate = useForceUpdate();
  const dependencies = [prefsVersion, prefStore?.path?.join('.'), prefsKey, target.cols, target.lockedCol, forceUpdate.counter];
  const loadedPrefs = React.useMemo(() => loadTablePrefs(target), dependencies);
  const savePrefs = React.useCallback(_savePrefs, dependencies);
  const mergePrefs = React.useCallback(_mergePrefs, dependencies);

  function loadTablePrefs(target:TablePrefs<T>) {
    if (!prefStore) {
      return target;
    }

    return TablePrefs.load(prefStore, prefsKey, prefsVersion, target, prefsSkipAttributes);
  }

  function _savePrefs(tableOrPrefs:DataTable | TablePrefs) {
    if (!prefStore) {
      return;
    }

    const updatedPrefs = tableOrPrefs instanceof DataTable ? prefsFromTable(tableOrPrefs) : tableOrPrefs as TablePrefs

    TablePrefs.save(prefStore, prefsKey, prefsVersion, updatedPrefs, prefsSkipAttributes);

    // force update because if we are being used in a grouped table, this forces all the other groups to update as well
    forceUpdate();
  }

  function prefsFromTable(table:DataTable) {
    return {id: loadedPrefs?.id, updatedAt: new Date().toISOString(), lockedCol: table.lockedCol, cols: table.allCols};
  }

  function _mergePrefs(prefs:TablePrefs) {
    _savePrefs({...loadedPrefs, ...prefs});
  }

  function resetToDefaultView() {
    if (prefStore) {
      prefStore.clear(prefsKey);
    }

    forceUpdate();
  }

  function setupPrefs() {
    // we need a fake prefs store for the sort/filters to stick around
    // when no prefs were passed in
    ({prefStore, prefsKey, prefsVersion} = React.useMemo(() => {
      if (!prefStore) {
        prefStore = new MemoryPreferenceStore();
        prefsKey ||= 'table-prefs';
        prefsVersion ||= 'v1';
      }

      return {prefStore, prefsKey, prefsVersion};
    }, [prefStore, prefsKey, prefsVersion]));
  }

  return {loadedPrefs, savePrefs, mergePrefs, resetToDefaultView}
}