import * as React from 'react'
import * as ReactIs from 'react-is';
import { omitBy } from 'lodash-es';

import { VBox, BoxProps } from '../Box';
import { flattenChildren } from '../utils';

import { MenuDropdown } from './MenuDropdown';

export function Menu(props:React.PropsWithChildren<BoxProps>) {
  const children = flattenChildren(props.children).map(updateSubmenus)

  function render() {
    return <VBox minWidth='200px' vItemSpace='20px' p='19px' cursor='default'
      bg='white' borderRadius='standard' border='solid 1px' borderColor='border' 
      text='subtitle2' outline='none' {...props} children={children} />
  }

  function updateSubmenus(node:React.ReactNode, index:number) {
    return ReactIs.isElement(node)
      ? React.cloneElement(node, omitBy({nested: node.type == MenuDropdown, key:index}, value => value === false))
      : node;
  }
  
  return render();
}
