import { EventRecorder, StandardEvents, EventProperties, Callback } from './EventRecorder';
import { LoggedInUser } from './LoggedInUser';

type CallbackUser = (impl: EventRecorder, callback: Callback) => void;

export class EventRecorderManager extends EventRecorder {
  impls: EventRecorder[];

  constructor(impls: EventRecorder[]) {
    super();

    this.impls = impls;
  }

  get capturing() {
    return this.impls.filter(e => e.capture);
  }

  get loaded(): boolean {
    return this.capturing.filter(impl => !impl.loaded).length == 0;
  }

  get recordForHomeroomStaff() {
    return true;
  }

  onLogRocketUrlUpdated(url: string): void {
    this.capturing.forEach(impl => impl.onLogRocketUrlUpdated(url));
  }

  onAppStart(properties: EventProperties): void {
    this.capturing.forEach((impl: EventRecorder) => {
      if (this.isHomeroomStaff && !impl.recordForHomeroomStaff) {
        return;
      }

      impl.onAppStart(properties);
    });
  }

  identifyUser(user:LoggedInUser, callback?: Callback): void {
    if (this.user?.id == user?.id) {
      callback?.();
      return;
    }

    this.user = user;

    this.createCallbackFunction((impl: EventRecorder, innerCb: Callback) => {
      if (this.isHomeroomStaff && !impl.recordForHomeroomStaff) {
        innerCb();
        return;
      }

      impl.identifyUser(user, innerCb);
    }, callback);
  }

  identifyNoUser(callback?: Callback): void {
    this.createCallbackFunction((impl: EventRecorder, innerCb: Callback) => {
      impl.identifyNoUser(innerCb);
    }, callback);
  }

  logStandard(
    event: StandardEvents,
    data?: EventProperties,
    callback?: Callback
  ): void {
    this.createCallbackFunction((impl: EventRecorder, innerCb: Callback) => {
      if (this.isHomeroomStaff && !impl.recordForHomeroomStaff) {
        innerCb();
        return;
      }

      impl.logStandard(event, data, innerCb);
    }, callback);
  }

  log(event: string, data?: EventProperties, callback?: Callback): void {
    this.createCallbackFunction((impl: EventRecorder, innerCb: Callback) => {
      if (this.isHomeroomStaff && !impl.recordForHomeroomStaff) {
        innerCb();
        return;
      }

      impl.log(event, data, innerCb);
    }, callback);
  }

  pageView(url: string): void {
    this.capturing.forEach((impl: EventRecorder) => {
      if (this.isHomeroomStaff && !impl.recordForHomeroomStaff) {
        return;
      }

      impl.pageView(url);
    });
  }

  private createCallbackFunction(fn: CallbackUser, callback?: Callback) {
    let callbacks = this.capturing.length;

    if (!this.capturing?.length) {
      callback?.();
      return;
    }

    function onCallback() {
      --callbacks;

      if (callbacks == 0) {
        callback?.();
      }
    }

    this.capturing.forEach((impl: EventRecorder) => fn(impl, onCallback));
  }
}
