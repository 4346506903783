import * as React from 'react';

import { CourseKind } from 'app2/api';
import { BooleanField, BooleanRadioField, Field, FormContent, FormContentProps, InputField, DateField, Part, FieldParent, RepeatingSection, Section, TextAreaField, useFormInfo } from 'app2/components';
import { DefaultSite, GradeField, SitesDropdown, showAllStudentDetails } from 'app2/views/shared-public';

import { ClassroomsDropdown, ClassroomsDropdownProps } from './ClassroomsDropdown';

export interface StudentDetailsConfigProps {
  role:'organizer' | 'parent' | 'teacher';
  courseKinds?: CourseKind[];
  purpose?: 'add' | 'edit' | 'checkout'
}

interface Props extends StudentDetailsConfigProps {
  // show the student details title - can be turned off if that is part a panel title
  title?:boolean;
  getClassrooms?:ClassroomsDropdownProps['getClassrooms'];
  // for the classroom search
  connectedSitesOnly?:boolean;
  // default site is used for a) picking a school if the student has none and 
  // b) will show grades for this school AND ONLY this school (regardless of what the students school choice is)
  defaultSite?:DefaultSite;
} 

export function StudentDetailsFields(props:Props) {
  const titleProps:FormContentProps = props.title
    ? {icon:"User", title:"Student details"}
    : undefined;

  const allStudentDetails = showAllStudentDetails(props.courseKinds);

  return <>
    <FormContent {...titleProps}>
      {props.purpose != 'checkout' && <StudentNameFields {...props} />}
      <StudentDobSchool {...props} />
      <StudentGradeClassroom {...props} />
    </FormContent>
    <FormContent>
      <FieldParent name='medical'>
        <StudentAllergies {...props} />
        <StudentEpiPen {...props} />
        <StudentMedications {...props} />
        <StudentConditions {...props} />
      </FieldParent>
      {allStudentDetails && <StudentNotesField {...props} />}
    </FormContent>
  </>
}

export function StudentNameFields(props:StudentDetailsConfigProps) {
  const readOnlyName = props.role == 'teacher';

  return <Section>
    <Part label="First name" name='firstName' readOnly={readOnlyName} required component={InputField} />
    <Part label="Last name" name='lastName' readOnly={readOnlyName} required component={InputField} />
    <Part label="Nickname" name='nickname' readOnly={props.role == 'teacher'} component={InputField} />
  </Section>
}

function useRole(props:Props) {
  const info = useFormInfo();
  const readOnly = !info.editing || props.role == 'teacher';

  return {info, readOnly};
}

export function StudentDobSchool(props:Props) {
  const {info, readOnly} = useRole(props);
  const allStudentDetails = showAllStudentDetails(props.courseKinds);

  function render() {
    return <Section>
      <Part label="Date of birth" name='dob' readOnly={readOnly} required={!readOnly} display={DateField} edit={{component: DateField, max: 'now'}} />
      {allStudentDetails && <Part label="School *" name='site' readOnly={readOnly} component={<SitesDropdown searchableOnly={false} activeOnly={false} connectedSitesOnly={props.connectedSitesOnly} />} onChange={onChangeSite} default={props.defaultSite} />}
    </Section>
  }

  async function onChangeSite() {
    const allStudentDetails = showAllStudentDetails(props.courseKinds);
    info.form.setValue('classroom', !allStudentDetails ? {id: `${info.form.values.site.id}_unassigned`} : null);
  }

  return render();
}

export function StudentGradeClassroom(props:Props) {
  const {info, readOnly} = useRole(props);
  const allStudentDetails = showAllStudentDetails(props.courseKinds);

  function render() {
    // for grades the default site takes precedence over the student's site

    return <React.Fragment>
      <Section>
        <Part label="Grade" name='grade' readOnly={readOnly} required={!readOnly} component={GradeField} site={props.defaultSite?.id || info?.form?.values?.site?.id} placeholder />
        {allStudentDetails && <Part label="Classroom" name='classroom' readOnly={readOnly} required={!readOnly} component={<ClassroomsDropdown getClassrooms={props.getClassrooms} site={info.form?.values?.site?.id || props.defaultSite?.id} />} />}
      </Section>
      <Part label="Advance to the next grade at the end of the school year" name='automaticGradeUpdate' readOnly={readOnly} component={BooleanField} />
    </React.Fragment>
  }

  return render();
}

export function StudentAllergies(props:StudentDetailsConfigProps) {
  const {info, readOnly} = useRole(props);

  return <Section label="Allergies" readOnly={readOnly}>
    <RepeatingSection name='allergies' readOnly={readOnly} numbered={false} assignIds={false} add='Add allergy' labelType='formlabel' fields={[
      <Field label="Allergic to" name='allergicTo' readOnly={readOnly} required component={InputField} />,
      <Field label="Allergic reaction details" name="notes" readOnly={readOnly} component={InputField} />,
      'remove'
    ]} />
  </Section>
}

export function StudentEpiPen(props:StudentDetailsConfigProps) {
  const {info, readOnly} = useRole(props);

  return <Section label="EpiPen" name='epiPen' readOnly={readOnly} component={BooleanRadioField} />
}

export function StudentMedications(props:StudentDetailsConfigProps) {
  const info = useFormInfo();
  const readOnly = !info.editing || props.role == 'teacher';

  return <Section label="Medications" readOnly={readOnly}>
    <RepeatingSection name='medications' readOnly={readOnly} numbered={false} assignIds={false} add='Add medication' labelType='formlabel' fields={[
      <Field label="Medication" name='medication' readOnly={readOnly} required component={InputField} />,
      <Field label="Dose" name='dose' readOnly={readOnly} required component={InputField} />,
      <Field label="Reason for medication" name="notes" readOnly={readOnly} component={InputField} />,
      'remove'
    ]} />
  </Section>
}

export function StudentConditions(props:StudentDetailsConfigProps) {
  const {info, readOnly} = useRole(props);

  return <Section label="Conditions" name='conditions' readOnly={readOnly} edit={{component:TextAreaField, autoSize:true}} minHeight={undefined} />

}

export function StudentNotesField(props:StudentDetailsConfigProps) {
  const readOnly = props.role == 'teacher';

  return <Section label="Comments" name='notes' readOnly={readOnly}  edit={{component:TextAreaField, autoSize:true}} minHeight={undefined} />
}

StudentDetailsFields.defaultProps = {
  purpose: 'edit',
  courseKinds: [CourseKind.Enrichment]
}
