import { useParams } from 'react-router';

import { SeasonRouteParams } from '../organizerRoutes';
import { useOrganizerSeasonQuery } from './generated';

export function useCurrentSeason() {
  const { season: seasonId } = useParams<SeasonRouteParams>();
  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId } });
  const season = seasonResult?.data?.season;

  return season;
}

