import * as React from 'react';

import { renderRow } from './VirtualGrid';
import { MeasuringRow } from './MeasuringRow';
import { MeasuredHeightFactory } from './MeasuredHeightFactory';

// a component that is responsible for rendering
// temporary nodes that are used to measure row heights.
// in order to use the MeasuringHeightFactory, you must
// also render MeasuringHeightRenderer in your component.

type MeasureCallback = (height:number) => void;
export type MeasuringRowInfo = {
  row:number;
  element:React.ReactElement;
  cbs:MeasureCallback[];
}

export class MeasuringHeightRenderer extends React.Component<{}> {
  factory:MeasuredHeightFactory;
  rows:MeasuringRowInfo[] = [];

  componentWillUnmount() {
    this.clearTimeout();
  }

  measure(row:number, cb:MeasureCallback) {
    const rowInfo = this.rows.find(info => info.row == row);

    if (!rowInfo) {
      const element = renderRow(this.factory, row, row, row, 0, this.factory.numCols - 1, 0, 0, undefined, true);
      this.rows.push({row, element, cbs:[cb]});
      this.update();
    }
    else {
      rowInfo.cbs.push(cb);
    }
  }

  render() {
    return <div style={{opacity: 0, height: '0', overflow: 'hidden'}}>
      {this.rows.map((rowInfo, index) => <MeasuringRow key={rowInfo.row} row={rowInfo.row} onMount={this.onRowMount}>{rowInfo.element}</MeasuringRow>)}
    </div>
  }

  onRowMount = (row:number, node:HTMLElement) => {
    const hasLoadingImages = Array.from(node.querySelectorAll('img')).some(node => !node.complete);

    if (hasLoadingImages) {
      this.waitForImages(row, node);
      return;
    }

    const rowInfo = this.rows.find(info => info.row == row);
    this.rows.splice(this.rows.indexOf(rowInfo), 1);

    const height = node.getBoundingClientRect().height || 10;
    rowInfo.cbs.forEach(cb => cb(height));

    this.update();
  }

  waitForImages(row:number, node:HTMLElement) {
    const images = Array.from(node.querySelectorAll('img')).filter(node => !node.complete);
    let imageCount = images.length;

    images.forEach(image => image.addEventListener('load', () => {
      --imageCount;

      if (imageCount == 0) {
        this.onRowMount(row, node);
      }
    }));
  }

  timeOut:any;

  clearTimeout() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
  }

  update() {
    this.clearTimeout();

    this.timeOut = setTimeout(() => {
      this.timeOut = null;
      this.forceUpdate()
    }, 1);
  }
}
