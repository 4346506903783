import { ComponentProps, baseExcludeProps } from './createComponent';

// splits off the style props from other props
// use this sparingly as this probably isn't performant
export function separateStyleProps(props: any): SeparatedProps {
  const styleProps: ComponentProps<{}> = {};
  const otherProps: any = {};

  for (const propName in props) {
    if (baseExcludeProps[propName]) {
      //@ts-ignore
      styleProps[propName] = props[propName];
    } else {
      otherProps[propName] = props[propName];
    }
  }

  return { styleProps, otherProps };
}

export interface SeparatedProps {
  styleProps: ComponentProps<{}>;
  otherProps: any;
}
