import * as React from 'react'

import { Message, DeepPartial } from 'app2/api';

import { useMessageRecipients } from './useMessageRecipients';

export function useMessageParticipants(message?:DeepPartial<Message>) {
  const recipients = useMessageRecipients(message);

  return React.useMemo(() => {
    if (!message) {
      return {recipients: [], participants: []}
    }

    return {recipients, participants: [...recipients, message.from]};
  }, [message]);
}
