import { RecurringRate, RecurringUnit } from 'app2/api';

export function parseRecurringRates(rate:string | RecurringRate[]) {
  if (Array.isArray(rate)) {
    return rate;
  }

  if (typeof rate !== 'string') {
    return [];
  }

  const re = /([1-7]) days?\/week for \$?([0-9]+) per (week|month)[\s\S]?/gmi;
  const rates = [...(rate as any).matchAll(re)].map(r => {
    return {
      days: parseInt(r[1]),
      rate: parseInt(r[2]),
      unit: r[3].toLowerCase() == 'month' ? RecurringUnit.Month : RecurringUnit.Week
    }
  });

  return rates;
}
