import * as React from 'react'

import { Body, HBox, BoxProps } from 'app2/components'

import { FileTag } from './FileTag'
import { FileUploadAttributes } from 'app/app2/api';

interface Props extends BoxProps {
  files?:FileUploadAttributes[];
  onRemove?:(pos:number) => void;
}

export function FileTags(props:Props) {
  const {files, onRemove, ...remaining} = props;
  
  return <HBox flexWrap='wrap' gap='4px 4px' {...remaining}>
    {!files || !files.length || !Array.isArray(files)
      ? <Body>None</Body>
      : files.map((file, index) => <FileTag key={index} id={file.id} label={file.name} disabled={props.disabled} onCloseClick={() => onRemove?.(index)} />)}
    </HBox>;
}

FileTags.fieldProps = {
  valueProperty: 'files',
}