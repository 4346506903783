import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  name:string;
}

interface Props {
  info?:FieldInfo<NameAndId>;
  company?:NameAndId;
}

export function CompanyLink(props:Props) {
  const company = props.company || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/providers/${company?.id}`}>{company?.name} ({company?.id})</Link>
}

CompanyLink.fieldProps = {
  fieldProperty: 'info',
}