import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../../gql/generated/enrollmentFields.generated';
import { EnrollmentInvitationFieldsFragmentDoc } from '../../../gql/generated/enrollmentInvitationFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BilledEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
}>;

export type BilledEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              rosterPeriod?: string | null | undefined;
              billPeriod?: string | null | undefined;
              billedDate?: string | null | undefined;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              status: Types.EnrollmentStatusEnum;
              paymentService?: Types.PaymentService | null | undefined;
              paymentStatus: Types.PaymentStatus;
              paymentFailureReason?: string | null | undefined;
              isScholarshipHold: boolean;
              perSessionRefunds: boolean;
              refundsTotal: number;
              cfAnswers?: { [key: string]: any } | null | undefined;
              cfSeasonAnswers?: { [key: string]: any } | null | undefined;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              isInvitation: boolean;
              invitationExpired?: boolean | null | undefined;
              contacts?: Array<{ name?: string | null | undefined; email?: string | null | undefined } | null | undefined> | null | undefined;
              priceConfig?: { description: string } | null | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              family?: { parents?: Array<{ id: string; name?: string | null | undefined; email: string }> | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const BilledEnrollmentsDocument = gql`
  query BilledEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: BILLED, filters: $filters, sort: $sort) {
      total
      items {
        ...enrollmentFields
        ...enrollmentInvitationFields
        rosterPeriod
        billPeriod
        billedDate
        startDate
        endDate
        status
        paymentService
        paymentStatus
        paymentFailureReason
        isScholarshipHold
        perSessionRefunds
        refundsTotal
        contacts {
          name
          email
        }
        cfAnswers
        cfSeasonAnswers
        priceConfig {
          ... on CustomPriceConfig {
            description
          }
        }
      }
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentInvitationFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type BilledEnrollmentsArgs = MakeOptional<UseQueryArgs<BilledEnrollmentsQueryVariables, BilledEnrollmentsQuery>, 'query'>;

export function useBilledEnrollmentsQuery(options: BilledEnrollmentsArgs = {}) {
  return useQuery<BilledEnrollmentsQueryVariables, BilledEnrollmentsQuery>({ query: BilledEnrollmentsDocument, ...options });
}

export type BilledEnrollmentsOptions = ExecQueryOptions<BilledEnrollmentsQueryVariables>;

export function billedEnrollments(options: BilledEnrollmentsOptions) {
  return executeQuery<BilledEnrollmentsQuery, BilledEnrollmentsQueryVariables>(BilledEnrollmentsDocument, options);
}

export type BilledEnrollmentsSelections = BilledEnrollmentsQuery['rosterByGrouping']['items'][0];

(BilledEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
