import * as React from 'react';

import { HBox, Icon } from 'app2/components';
import { useCourseFields } from 'app2/views';

import { CourseSelections } from '../../generated';

interface Props {
  course: CourseSelections;
}

export function GracePeriod(props: Props) {
  const { fields } = useCourseFields(props.course as any);
  const gracePeriod = props.course?.rates?.usage?.[0]?.gracePeriod;
  if (!gracePeriod) {
    return null;
  }

  return (
    <HBox text="body" vAlign="center">
      Grace period: {gracePeriod} minutes
      <Icon name="Info" size="small" ml="$4" tooltip={fields['rates.usage.gracePeriod'].infoTip} />
    </HBox>
  );
}
