import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentsSetAdditionalDiscountMutationVariables = Types.Exact<{
  discounts: Array<Types.EnrollmentsSetAdditionalDiscountInput> | Types.EnrollmentsSetAdditionalDiscountInput;
}>;

export type EnrollmentsSetAdditionalDiscountMutation = { enrollmentsSetAdditionalDiscount?: { enrollments?: Array<{ id: string }> | null | undefined } | null | undefined };

export const EnrollmentsSetAdditionalDiscountDocument = gql`
  mutation EnrollmentsSetAdditionalDiscount($discounts: [EnrollmentsSetAdditionalDiscountInput!]!) @urql(additionalTypenames: ["Enrollment"]) {
    enrollmentsSetAdditionalDiscount(discounts: $discounts) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type EnrollmentsSetAdditionalDiscountOptions = ExecMutationOptions<EnrollmentsSetAdditionalDiscountMutationVariables>;

export function enrollmentsSetAdditionalDiscount(options: EnrollmentsSetAdditionalDiscountOptions) {
  return executeMutation<EnrollmentsSetAdditionalDiscountMutation, EnrollmentsSetAdditionalDiscountMutationVariables>(EnrollmentsSetAdditionalDiscountDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Enrollment']) }
  });
}
