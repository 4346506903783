import * as React from 'react';

import { InputField, Modal, Form, Section, Text, useForm, VBox } from 'app2/components';

import { FamilyDropdown } from '../shared';
import { AdminFamilySelections, mergeFamilies } from './generated';

interface Props {
  family:AdminFamilySelections;
}

export function MergeFamilies(props:Props) {
  const form = useForm<{family:AdminFamilySelections}>({}, []);
  const source = form.values.family?.name ? `${form.values.family.name} (${form.values.family.id})` : '[select family]';
  const destination = `${props.family.name} (${props.family.id})`;

  function render() {
    return <Modal title='Merge families' danger info={{type:'warning', message: 'This can not be undone', small: true, title: false, layout: 'hbox'}}>
      <Form form={form} onOk={onOk} editing>
        <Section label={`Choose which family to merge into ${destination}`} name='family' component={FamilyDropdown} />
        <Section label='Type merge! to confirm you want to merge' name='merge' validators={validateMerge} component={InputField} />
        <VBox>
          <Text text='body'>This will move the following <b>from</b> {source} <b>to</b> {destination}:</Text>
          <Text text='body'>- contacts (parents, parent invites, authorized pickups and emergency contacts)</Text>
          <Text text='body'>- students</Text>
          <Text text='body'>- enrollments, orders and refunds</Text>
          <Text text='body'>- stripe cards <b>only if {destination} does not have any</b>, otherwise it will remove them in Stripe from {source}</Text>
          <Text text='body'>- students <b>(note that students with the same name in the unified family will be merged)</b></Text>
          <Text text='body'>- the {destination} family name will be kept</Text>
          <Text text='body'>- {source}'s version history will stay on {source}</Text>
          <Text text='body'>- {source}'s event history will stay on {source}</Text>
        </VBox>
      </Form>
    </Modal>
  }

  async function onOk() {
    const [success] = await mergeFamilies({variables:{source: form.values.family.id, destination: props.family.id}})

    return success;
  }

  return render();
}

function validateMerge(value:string) {
  return value?.toLowerCase() == 'merge!'
    ? null
    : 'You must type merge! to confirm you want to merge the companies, which can not be undone'
}