import FacebookPixel from 'react-facebook-pixel';

import { isProductionEnv } from 'app/helpers/environments';

import { EventRecorder, StandardEvents, EventProperties, Callback, doNothing } from './EventRecorder';

const capture = isProductionEnv();

const standardEventsMapping = {
  [StandardEvents.CompleteRegistration]: 'CompleteRegistration',
  [StandardEvents.AddToCart]: 'AddToCart',
  [StandardEvents.Purchase]: 'Purchase'
};

const standardEventPropertyMapping = {
  [StandardEvents.Purchase]: {
    creditCardChargedAmount: 'value'
  },
  [StandardEvents.AddToCart]: {
    listPrice: 'value'
  }
};

export class FacebookAnalytics extends EventRecorder {
  constructor() {
    super();

    this.capture = capture;
    this.load();
  }

  load() {
    if (!this.capture) {
      return;
    }

    FacebookPixel.init('238165739914214');
  }

  logStandard(event: StandardEvents, data?: EventProperties, callback: Callback = doNothing): void {
    const mappedData = this.mapEventProperties(standardEventPropertyMapping, event, data);

    FacebookPixel.track(standardEventsMapping[event], mappedData);

    callback();
  }

  log(event: string, data?: EventProperties, callback: Callback = doNothing): void {
    FacebookPixel.trackCustom(event, data);

    callback();
  }

  pageView(url: string): void {
    FacebookPixel.pageView();
  }
}
