import * as React from 'react';
import moment from 'moment';
import * as qs from 'query-string';

import { axios } from 'app2/api';
import { Button, CurrencyField, DateField, HBox, Form, Panel, Part, Section, useForm } from 'app2/components';
import { downloadFile } from 'app2/views/shared';

import { useAdminHomeroomBalancesQuery } from './generated'

export function Financial() {
  const transactionsForm = useForm({start: moment(), end: moment()}, []);

  const [result] = useAdminHomeroomBalancesQuery({variables:{start: moment(transactionsForm.values.start).toISOString(), end: moment(transactionsForm.values.end).toISOString()}});
  const balances = result?.data?.homeroom || {};

  function render() {
    return <>
      <Panel initialValues={balances}>
        <Section label="Pending payouts balance" name='pendingPayoutBalance' component={CurrencyField} />
        <Section label="Homeroom balance (balance + earnings account)" name='hrStripeBalance' component={CurrencyField} />
        <Section label="Stripe balance" name='stripeBalance' component={CurrencyField} />
      </Panel>
      <Panel initialValues={balances}>
        <Form form={transactionsForm} onNavigation='nothing'>
          <Section label="Transactions">
            <Part label='Start' name='start' component={DateField} />
            <Part label='End' name='end' component={DateField} />
            <Part label=' ' component={<HBox><Button autoLoader onClick={transactionsReport}>Download</Button></HBox>} />
          </Section>
        </Form>

        <Section label="Net revenue" name='transactionTotals.netRevenue' component={CurrencyField} />
        <Section label="Cost of revenue" name='transactionTotals.costOfRevenue' component={CurrencyField} />
        <Section label="Gross profit" name='transactionTotals.grossProfit' component={CurrencyField} />
      </Panel>
    </>
  }

  async function transactionsReport() {
    const params = qs.stringify({
      entity_type: 'Homeroom',
      start_time: moment.utc(transactionsForm.values.start).startOf('day').format(),
      end_time: moment.utc(transactionsForm.values.end).endOf('day').format()
    });

    const request = axios.get(`/admin/transaction_items/export?${params}`, {responseType: 'blob'});
    await downloadFile(request, 'text/csv');
  }

  return render();
}
