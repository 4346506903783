import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteSearchQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  connectedSitesOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  activeOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  searchableOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type SiteSearchQuery = { sitesSearch?: Array<{ id: string; name?: string | null | undefined; city?: string | null | undefined; state?: string | null | undefined; slug: string }> | null | undefined };

export const SiteSearchDocument = gql`
  query SiteSearch($term: String!, $connectedSitesOnly: Boolean, $activeOnly: Boolean, $searchableOnly: Boolean, $limit: Int) {
    sitesSearch(term: $term, connectedSitesOnly: $connectedSitesOnly, activeOnly: $activeOnly, searchableOnly: $searchableOnly, limit: $limit) {
      id
      name
      city
      state
      slug
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteSearchArgs = MakeOptional<UseQueryArgs<SiteSearchQueryVariables, SiteSearchQuery>, 'query'>;

export function useSiteSearchQuery(options: SiteSearchArgs = {}) {
  return useQuery<SiteSearchQueryVariables, SiteSearchQuery>({ query: SiteSearchDocument, ...options });
}

export type SiteSearchOptions = ExecQueryOptions<SiteSearchQueryVariables>;

export function siteSearch(options: SiteSearchOptions) {
  return executeQuery<SiteSearchQuery, SiteSearchQueryVariables>(SiteSearchDocument, options);
}

export type SiteSearchSelections = SiteSearchQuery['sitesSearch'][0];

(SiteSearchDocument as any).additionalTypenames = ['Site'];
