import * as React from 'react';
import { useParams } from 'react-router';

import { EmailField, DateField, InputField, Part, Panel, PhoneField, Section } from 'app2/components';

import { UserRouteParams } from '../adminRoutes';

import { useSingleAdminUserQuery, SingleAdminUserSelections } from './generated';

export function UserInfo() {
  const { id } = useParams<UserRouteParams>();
  const [result] = useSingleAdminUserQuery({variables: {id}});
  const item = result.data?.adminUser || ({} as SingleAdminUserSelections);

  function render() {
    return <Panel title={`${item.lastName}, ${item.firstName} (${id})`} initialValues={item} type='toggle'>
      <Section label='Name'>
        <Part label='First' name='firstName' component={InputField} />
        <Part label='Last' name='lastName' component={InputField} />
      </Section>

      <Section label='Email' name='email' component={EmailField} />
      <Section label='Phone' name='phone' component={PhoneField} />

      <Section label='Deactivated at' name='deactivatedAt' component={DateField} readOnly />
    </Panel>
  }

  return render();
}
