import * as React from 'react'
import * as ReactIs from 'react-is'

interface Options {
  omitDefaultProps?:boolean;
  omitProps?:string[];
  omitFunctions?:Set<any>;
}

export function reactNodeToString(node:React.ReactNode, options:Options = {omitDefaultProps: true}):string {
  const functionsMap = new Map<string, string>();
  const markup = reactNodeToStringInternal(functionsMap, node, options, 0);

  let functions = '';
  functionsMap.forEach((fn:string, name:string) => functions += '\n\n' + fn);

  return markup + functions;
}

export function reactNodeToStringInternal(functions:Map<string, string>, node:React.ReactNode, options:Options = {omitDefaultProps: true}, spaces:number = 0):string {
  if (node === null || node === undefined) {
    return '';
  }
  
  const typeOf = typeof node;

  if (typeOf === 'string' || node instanceof Date) {
    return node.toString();
  }

  if (typeOf === 'number') {
    return node.toString();
  }

  if (typeOf === 'boolean') {
    return node.toString();
  }

  if (typeOf === 'function') {
    return getFunction(node as Function, functions, options);
  }

  if (Array.isArray(node)) {
    const contents = node.map(item => reactNodeToStringInternal(functions, item, options, spaces + 1));
    return contents.join('\n');
  }

  if (ReactIs.isElement(node)) {
    return elementToString(functions, node as React.ReactElement, options, spaces);
  }

  if ((node as any).displayName) {
    return (node as any).displayName;
  }

  return objectToString(functions, node, options);
}

export function elementToString(functions:Map<string, string>, element:React.ReactElement, options:Options, spaces:number = 0):string {
  // if type is undefined it means its a react fragment
  const type = typeof element.type == 'string' ? element.type : (element.type as any).displayName || (element.type as any).name || '';
  const defaultProps = (element.type as any).defaultProps || {};
  const padding = ''.padStart(spaces, ' ');
  let ret = padding + '<' + type;

  for (const propName in element.props) {
    if (propName == 'children' || (options.omitDefaultProps && defaultProps[propName] === element.props[propName]) || (options.omitProps && options.omitProps.includes(propName))) {
      continue;
    }

    const value = element.props[propName];

    if (value === true) {
      ret += ` ${propName}`;
    }
    else {
      const stringValue = propToString(functions, value, false, options);
      ret += ` ${propName}=${stringValue}`;
    }
  }

  const children = propToString(functions, element.props['children'], true, options, spaces + 1);
  const lf = typeof element.props['children'] !== 'string';

  return children.length ? ret + '>' + (lf ? '\n' : '') + children + (lf ? '\n' + padding : '') + '</' + type + '>' : ret + ' />';
}

export function objectToString(functions:Map<string, string>, obj:any, options:Options):string {
  const props:any = {};

  if (options.omitFunctions?.has(obj.constructor)) {
    return obj.constructor.name;
  }

  for (const propName in obj) {
    props[propName] = reactNodeToStringInternal(functions, obj[propName], options);
  }

  return JSON.stringify(props).replace(/\\\"/g, '"');
}

export function propToString(functions:Map<string, string>, node:React.ReactNode, children:boolean, options:Options, spaces:number = 0):string {
  if (node === undefined) {
    return '';
  }

  if (node === null) {
    return '{null}';
  }
  
  const typeOf = typeof node;

  if (typeOf === 'string' || node instanceof Date) {
    return children ? node.toString() : JSON.stringify(node);
  }

  if (typeOf === 'number') {
    return '{' + node + '}';
  }

  if (typeOf === 'boolean') {
    return '{' + node + '}';
  }

  if (typeOf === 'function') {
    return '{' + getFunction(node as Function, functions, options) + '}';
  }

  if (Array.isArray(node)) {
    const contents = node.map(item => reactNodeToStringInternal(functions, item, options, spaces + 1));
    return children ? contents.join('\n') : '{[' + contents.join(', ') + ']}';
  }

  if ((node as React.ReactElement).type) {
    return elementToString(functions, node as React.ReactElement, options, spaces + 1);
  }
  
  if ((node as any).displayName) {
    return "{" + (node as any).displayName + "}";
  }

  return objectToString(functions, node, options);
}

function getFunction(fn:any, functions:Map<string, string>, options:Options) {
  if (fn.displayName) {
    return fn.displayName;
  }

  let fnString = fn.toString();
  const isArrow = /^[^{]+?=>/.test(fn.toString());

  if (fn.name && !isArrow) {
    return fn.name;
  }

  let proto = Object.getPrototypeOf(fn);
  let max = 0;

  while (proto && proto != Object.getPrototypeOf({}) && max < 20) {
    if (proto == React.Component) {
      return fn.name;
    }

    proto = Object.getPrototypeOf(fn)
    ++max;
  }

  if (options.omitFunctions?.has(fn)) {
    return '';
  }

  //undo webpacks crappy rewriting
  fnString = fnString
    .replace(/\/\* @__PURE__ \*\/ ?/g, '')
    .replace(/[a-zA-Z0-9_]+\[\"([a-zA-Z0-9]+)\"]/g, '$1');

  if (isArrow) {
    return fnString;
  }

  functions.set(fn.name, fnString);

  return fn.name;
}
