import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentMedicalFieldsFragmentDoc } from '../../../student/tabs/gql/generated/studentMedicalFields.generated';
export type CourseAttendanceFieldsFragment = {
  id: string;
  pickup?: string | null | undefined;
  dismissal?: string | null | undefined;
  updatedAt?: string | null | undefined;
  checkedIn?: Types.CheckedIn | null | undefined;
  checkedInAt?: string | null | undefined;
  checkedInBy?: string | null | undefined;
  checkedOut?: boolean | null | undefined;
  checkedOutAt?: string | null | undefined;
  checkedOutBy?: string | null | undefined;
  checkoutDest?: string | null | undefined;
  groups?: Array<string> | null | undefined;
  parent?: { id: string; name?: string | null | undefined; email: string } | null | undefined;
  student?:
    | {
        id: string;
        firstName: string;
        lastName: string;
        name: string;
        grade: string;
        age?: number | null | undefined;
        nickname?: string | null | undefined;
        notes?: string | null | undefined;
        classroom?: { displayName: string } | null | undefined;
        medical?:
          | {
              epiPen?: boolean | null | undefined;
              conditions?: string | null | undefined;
              allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
              medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  otherEnrollments: Array<{ id: string; formattedDays: string; formattedTimes: string; course: { id: string; name?: string | null | undefined } }>;
};

export const CourseAttendanceFieldsFragmentDoc = gql`
  fragment courseAttendanceFields on RosterItem {
    id
    parent {
      id
      name
      email
    }
    student {
      id
      firstName
      lastName
      name
      grade
      age
      ...studentMedicalFields
      nickname
      classroom {
        displayName
      }
    }
    pickup
    dismissal
    updatedAt
    checkedIn
    checkedInAt
    checkedInBy
    checkedOut
    checkedOutAt
    checkedOutBy
    checkoutDest
    groups
    otherEnrollments {
      id
      formattedDays
      formattedTimes
      course {
        id
        name
      }
    }
  }
  ${StudentMedicalFieldsFragmentDoc}
`;
