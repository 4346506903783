import * as React from 'react';

import { isRealProduction } from 'app/helpers';
import { eventManagerConfig } from 'app/app2/api/events';
import { hasAppMode } from 'app2/components';

// configures the app in embed mode for the site being embeded

export function EmbedConfigLoader() {
  if (!isRealProduction()) {
    return <></>;
  }

  if (!hasAppMode('embed')) {
    return <></>;
  }

  // eventManagerConfig.gtm = '';

  return <></>;
}
