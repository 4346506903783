import * as React from 'react'

import { FileUpload } from 'app2/api'
import { isNewId, FieldInfo, Link, LinkProps, Text } from 'app2/components'

interface FileProps extends LinkProps {
  id:string;
  name:React.ReactNode;
  info?:FieldInfo<FileUpload>;
}

export function FileLink(props:FileProps) {
  const {id:idProp, name:nameProp, info, ...remaining} = props;

  const id = idProp || info?.record?.id;
  const name = nameProp || info?.record?.name;

  return id && !isNewId(id)
    ? <Link to={`/files/${id}`} text='body' target='_blank' {...remaining}>{name}</Link>
    : <Text text='body' {...remaining}>{name}</Text>
}

FileLink.fieldProps = {
  fieldProperty: 'info',
}
