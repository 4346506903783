import * as React from 'react';
import { useParams } from 'react-router';

import { BaseEmergencyContacts, BaseAfterActivity, BaseBeforeActivity, BaseSchoolPolicies, PublicPage, InactiveWarning } from 'app2/views/shared-public';

import { SiteHeader } from '../shared';

import { usePublicPolicyQuery } from './generated';

export function Policies() {
  const { policy } = useParams<{policy: string}>();
  const [result] = usePublicPolicyQuery({variables: {policy}});
  const policies = result?.data?.policy;

  function render() {
    return <PublicPage above={<SiteHeader site={policies?.site} />} title='School policies' useMaxWidth pt={null} markingLinksWhenLoggedIn={false}>
      <InactiveWarning site={policies?.site} />

      <BaseEmergencyContacts type='display' initialValues={policies?.site} />
      <BaseBeforeActivity type='display' initialValues={policies} />
      <BaseAfterActivity type='display' initialValues={policies} />
      <BaseSchoolPolicies type='display' initialValues={policies} />
    </PublicPage>
  }

  return render();
}

