import * as React from 'react';
import { useParams } from 'react-router';

import { Field, MenuItem, OptionsMenu, Panel, RepeatingSection, Section, useForm } from 'app2/components';

import { FamilyLink, UserLink } from '../shared';

import { MergeStudents } from './MergeStudents';
import { useAdminStudentQuery } from './generated';

export function StudentInfo() {
  const { id } = useParams<{id:string}>();
  const [result] = useAdminStudentQuery({variables: {id}});
  const item = result.data?.student;
  const form = useForm(item, [item]);

  function render() {
    return <Panel icon='Home' title='Information' form={form} primaryActions={renderActions()}>
      <Section label="ID" name="id" readOnly />
      <Section label="Student" name="name" />
      <Section label="Family" name='family.name' component={FamilyLink} />
      <Section label='Parents'>
        <RepeatingSection name='parents' fields={[
          <Field name='name' component={UserLink} />
        ]} />
      </Section>
    </Panel>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem label="Merge students..." onClick={<MergeStudents student={item} />} />
    </OptionsMenu>
  }

  return render();
}
