import * as React from 'react';

import { courseKindBehavior } from 'app2/views/shared-public';

import { approveCourses } from '../../course/course-actions';

import { TableAction, TableActionProps } from './TableAction';

export function ApproveCourseAction(props:TableActionProps) {
  const { kind, ...remaining} = props;
  const behavior = courseKindBehavior[props.kind];

  return <TableAction icon='CheckCircle' msg='Activities published' label={behavior?.terms?.publish || 'Publish activity'} clickEvent='Click Approve activity' ability='approvable' mutation={approveCourses} {...remaining} />
}
