import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import {
  BreakdownFieldsFragmentDoc,
  ConfigurableSeasonBreakdownFieldsFragmentDoc,
  RecurringBreakdownFieldsFragmentDoc,
  UsageBreakdownFieldsFragmentDoc
} from '../../course/breakdowns/generated/breakdownFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TableCoursesQueryVariables = Types.Exact<{
  seasonId?: Types.InputMaybe<Types.Scalars['ID']>;
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  courseId?: Types.InputMaybe<Types.Scalars['ID']>;
  statuses: Array<Types.CourseStatus> | Types.CourseStatus;
  kinds?: Types.InputMaybe<Array<Types.CourseKind> | Types.CourseKind>;
  totals?: Types.InputMaybe<Array<Array<Types.CourseStatus> | Types.CourseStatus> | Array<Types.CourseStatus> | Types.CourseStatus>;
  filters?: Types.InputMaybe<Types.CourseFilters>;
  sort?: Types.InputMaybe<Types.CourseSort>;
}>;

export type TableCoursesQuery = {
  seasonCourses?:
    | {
        totals: Array<number>;
        items: Array<
          | {
              id: string;
              status: Types.CourseStatus;
              kind?: Types.CourseKind | null | undefined;
              name?: string | null | undefined;
              description?: string | null | undefined;
              room?: string | null | undefined;
              grades?: Array<string | null | undefined> | null | undefined;
              ageMin?: number | null | undefined;
              ageMax?: number | null | undefined;
              searchable: boolean;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              classesCount: number;
              enrollmentOpens?: string | null | undefined;
              enrollmentCloses?: string | null | undefined;
              minCapacity?: number | null | undefined;
              maxCapacity?: number | null | undefined;
              hasOptions: boolean;
              hasParentOptionCourses: boolean;
              supplies?: Array<string | null | undefined> | null | undefined;
              enrolledCount: number;
              waitlistedCount: number;
              removedCount: number;
              noEnrichmentDays: Array<string | null | undefined>;
              teamMaxCapacity?: number | null | undefined;
              awayScore?: number | null | undefined;
              homeScore?: number | null | undefined;
              courseFramework?: { id: string; name: string; description: string; typename: 'CourseFramework' } | null | undefined;
              courseTags: Array<{ id: string; name?: string | null | undefined; typename: 'CourseTag' } | null | undefined>;
              vendor?:
                | { id: string; displayName: string; email?: string | null | undefined; typename: 'Vendor'; company?: { id: string; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined }
                | null
                | undefined;
              teacher?: { id: string; name: string; phone?: string | null | undefined; email?: string | null | undefined; typename: 'Teacher' } | null | undefined;
              courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
              season: { id: string; coursesBegin: string; coursesFinish: string };
              rates: {
                season: {
                  days?: number | null | undefined;
                  rate?: number | null | undefined;
                  homeroomFeeRoundingIncrementCents?: number | null | undefined;
                  materialsRate?: number | null | undefined;
                  depositAmount?: number | null | undefined;
                  installmentDates?: Array<string> | null | undefined;
                };
                seasons: Array<{
                  days?: number | null | undefined;
                  rate?: number | null | undefined;
                  homeroomFeeRoundingIncrementCents?: number | null | undefined;
                  materialsRate?: number | null | undefined;
                  depositAmount?: number | null | undefined;
                  installmentDates?: Array<string> | null | undefined;
                }>;
                recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; rate?: number | null | undefined; homeroomFeeRoundingIncrementCents?: number | null | undefined }>;
                dropIn: { rate?: number | null | undefined; homeroomFeeRoundingIncrementCents?: number | null | undefined };
                usage: Array<{
                  unit?: Types.UsageUnit | null | undefined;
                  rate?: number | null | undefined;
                  homeroomFeeRoundingIncrementCents?: number | null | undefined;
                  roundingIncrement?: number | null | undefined;
                  gracePeriod?: number | null | undefined;
                }>;
              };
              children: Array<{ id: string }>;
              priceBreakdowns: {
                season?:
                  | {
                      discountAmount?: number | null | undefined;
                      homeroomCharges?: number | null | undefined;
                      listPrice?: number | null | undefined;
                      listPriceBeforeDiscount?: number | null | undefined;
                      price?: number | null | undefined;
                      priceBeforeDiscount?: number | null | undefined;
                      siteCharges?: number | null | undefined;
                      siteCreditCardFee?: number | null | undefined;
                      siteReceives?: number | null | undefined;
                      vendorCharges?: number | null | undefined;
                      vendorCreditCardFee?: number | null | undefined;
                      vendorReceives?: number | null | undefined;
                    }
                  | null
                  | undefined;
                seasons?:
                  | Array<{
                      discountAmount?: number | null | undefined;
                      homeroomCharges?: number | null | undefined;
                      listPrice?: number | null | undefined;
                      listPriceBeforeDiscount?: number | null | undefined;
                      price?: number | null | undefined;
                      priceBeforeDiscount?: number | null | undefined;
                      siteCharges?: number | null | undefined;
                      siteCreditCardFee?: number | null | undefined;
                      siteReceives?: number | null | undefined;
                      vendorCharges?: number | null | undefined;
                      vendorCreditCardFee?: number | null | undefined;
                      vendorReceives?: number | null | undefined;
                      days?: number | null | undefined;
                    }>
                  | null
                  | undefined;
                recurring?:
                  | Array<{
                      discountAmount?: number | null | undefined;
                      homeroomCharges?: number | null | undefined;
                      listPrice?: number | null | undefined;
                      listPriceBeforeDiscount?: number | null | undefined;
                      price?: number | null | undefined;
                      priceBeforeDiscount?: number | null | undefined;
                      siteCharges?: number | null | undefined;
                      siteCreditCardFee?: number | null | undefined;
                      siteReceives?: number | null | undefined;
                      vendorCharges?: number | null | undefined;
                      vendorCreditCardFee?: number | null | undefined;
                      vendorReceives?: number | null | undefined;
                      days?: number | null | undefined;
                      unit?: Types.RecurringUnit | null | undefined;
                    }>
                  | null
                  | undefined;
                dropIn?:
                  | {
                      discountAmount?: number | null | undefined;
                      homeroomCharges?: number | null | undefined;
                      listPrice?: number | null | undefined;
                      listPriceBeforeDiscount?: number | null | undefined;
                      price?: number | null | undefined;
                      priceBeforeDiscount?: number | null | undefined;
                      siteCharges?: number | null | undefined;
                      siteCreditCardFee?: number | null | undefined;
                      siteReceives?: number | null | undefined;
                      vendorCharges?: number | null | undefined;
                      vendorCreditCardFee?: number | null | undefined;
                      vendorReceives?: number | null | undefined;
                    }
                  | null
                  | undefined;
                usage?:
                  | Array<{
                      discountAmount?: number | null | undefined;
                      homeroomCharges?: number | null | undefined;
                      listPrice?: number | null | undefined;
                      listPriceBeforeDiscount?: number | null | undefined;
                      price?: number | null | undefined;
                      priceBeforeDiscount?: number | null | undefined;
                      siteCharges?: number | null | undefined;
                      siteCreditCardFee?: number | null | undefined;
                      siteReceives?: number | null | undefined;
                      vendorCharges?: number | null | undefined;
                      vendorCreditCardFee?: number | null | undefined;
                      vendorReceives?: number | null | undefined;
                      unit?: Types.UsageUnit | null | undefined;
                    }>
                  | null
                  | undefined;
              };
              site: {
                id: string;
                name?: string | null | undefined;
                slug: string;
                timezone?: string | null | undefined;
                partner: boolean;
                userSiteRole?: Types.MemberRole | null | undefined;
                policy?: { token: string } | null | undefined;
              };
              awayTeam?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    disambiguatedName?: string | null | undefined;
                    userSiteRole?: Types.MemberRole | null | undefined;
                    userCompanyRole?: Types.MemberRole | null | undefined;
                    userCourseRole?: Types.MemberRole | null | undefined;
                  }
                | null
                | undefined;
              homeTeam?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    disambiguatedName?: string | null | undefined;
                    userSiteRole?: Types.MemberRole | null | undefined;
                    userCompanyRole?: Types.MemberRole | null | undefined;
                    userCourseRole?: Types.MemberRole | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const TableCoursesDocument = gql`
  query TableCourses($seasonId: ID, $companyId: ID, $courseId: ID, $statuses: [CourseStatus!]!, $kinds: [CourseKind!], $totals: [[CourseStatus!]!], $filters: CourseFilters, $sort: CourseSort)
    @urql(additionalTypenames: ["Course"]) {
    seasonCourses(seasonId: $seasonId, companyId: $companyId, courseId: $courseId, statuses: $statuses, kinds: $kinds, totals: $totals, filters: $filters, sort: $sort) {
      items {
        id
        status
        kind
        courseFramework {
          typename: __typename
          id
          name
          description
        }
        name
        description
        courseTags {
          typename: __typename
          id
          name
        }
        vendor {
          typename: __typename
          id
          displayName
          email
          company {
            id
            userCompanyRole
          }
        }
        teacher {
          typename: __typename
          id
          name
          phone
          email
        }
        room
        grades
        ageMin
        ageMax
        courseDays {
          days
          start
          finish
        }
        searchable
        season {
          id
          coursesBegin
          coursesFinish
        }
        startDate
        endDate
        classesCount
        enrollmentOpens
        enrollmentCloses
        minCapacity
        maxCapacity
        rates {
          season {
            days
            rate
            homeroomFeeRoundingIncrementCents
            materialsRate
            depositAmount
            installmentDates
          }
          seasons {
            days
            rate
            homeroomFeeRoundingIncrementCents
            materialsRate
            depositAmount
            installmentDates
          }
          recurring {
            days
            unit
            rate
            homeroomFeeRoundingIncrementCents
          }
          dropIn {
            rate
            homeroomFeeRoundingIncrementCents
          }
          usage {
            unit
            rate
            homeroomFeeRoundingIncrementCents
            roundingIncrement
            gracePeriod
          }
        }
        hasOptions
        hasParentOptionCourses
        children {
          id
        }
        priceBreakdowns {
          season {
            ...breakdownFields
          }
          seasons {
            ...configurableSeasonBreakdownFields
          }
          recurring {
            ...recurringBreakdownFields
          }
          dropIn {
            ...breakdownFields
          }
          usage {
            ...usageBreakdownFields
          }
        }
        supplies
        enrolledCount
        waitlistedCount
        removedCount
        teacher {
          name
        }
        noEnrichmentDays
        site {
          id
          name
          slug
          timezone
          partner
          userSiteRole
          policy {
            token
          }
        }
        teamMaxCapacity
        awayScore
        awayTeam {
          id
          name
          disambiguatedName
          userSiteRole
          userCompanyRole
          userCourseRole
        }
        homeScore
        homeTeam {
          id
          name
          disambiguatedName
          userSiteRole
          userCompanyRole
          userCourseRole
        }
      }
      totals
    }
  }
  ${BreakdownFieldsFragmentDoc}
  ${ConfigurableSeasonBreakdownFieldsFragmentDoc}
  ${RecurringBreakdownFieldsFragmentDoc}
  ${UsageBreakdownFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TableCoursesArgs = MakeOptional<UseQueryArgs<TableCoursesQueryVariables, TableCoursesQuery>, 'query'>;

export function useTableCoursesQuery(options: TableCoursesArgs = {}) {
  return useQuery<TableCoursesQueryVariables, TableCoursesQuery>({ query: TableCoursesDocument, ...options });
}

export type TableCoursesOptions = ExecQueryOptions<TableCoursesQueryVariables>;

export function tableCourses(options: TableCoursesOptions) {
  return executeQuery<TableCoursesQuery, TableCoursesQueryVariables>(TableCoursesDocument, options);
}

export type TableCoursesSelections = TableCoursesQuery['seasonCourses']['items'][0];

(TableCoursesDocument as any).additionalTypenames = ['Course'];
