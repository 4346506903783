import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CartAddInviteMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type CartAddInviteMutation = { cartAddInvite?: { success?: boolean | null | undefined } | null | undefined };

export const CartAddInviteDocument = gql`
  mutation CartAddInvite($token: String!) {
    cartAddInvite(token: $token) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CartAddInviteOptions = ExecMutationOptions<CartAddInviteMutationVariables>;

export function cartAddInvite(options: CartAddInviteOptions) {
  return executeMutation<CartAddInviteMutation, CartAddInviteMutationVariables>(CartAddInviteDocument, options);
}
