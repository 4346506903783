import * as React from 'react';

import { EntityKind } from 'app2/api';
import { formatCurrency } from 'app2/components';
import { ReportTransactions } from 'app2/views/shared';

import { useBasicCompany } from '../shared'

import { useCompanyReportTransactionsQuery } from './generated';

export function CompanyTransactionsReport() {
  const { company, prefs } = useBasicCompany();

  return <ReportTransactions type={EntityKind.Company} entity={company} totals={totals} queryHook={useCompanyReportTransactionsQuery} prefs={prefs} prefsVersion='1' />;
}

const totals = [
  {name:'companyEarnings', label: 'Total provider earnings', format: formatCurrency},
];

