import moment from 'moment'

import { DateInput } from './DateInput'

// returns date if its valid else it returns the default
// if default is null then it returns the current time

export function validOrDefault(date:DateInput, def?:DateInput) {
  date = date ? moment(date) : null;

  return date && date.isValid() ? date : def ? moment(def) : moment();
}
