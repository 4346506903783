// dropdowns might not be directly contained within
// their parent container.  they will however add a 
// property, portalParent, helping connecting them.
// this is a util method that will walk that parent
// chain (and the normal parent chain) to determine
// if an element is contained by another.

// checks if the given element has or contains in the focus
export function contains(parent:HTMLElement, child:HTMLElement) {
  if (!parent || !child) {
    return false;
  }

  while (child) {
    if (parent.contains(child)) {
      return true;
    }

    // dropdowns will add a property a property
    // connecting the dropdown to the container
    // @ts-ignore
    child = child.portalParent || child.parentElement;
  }

  return false;
}
