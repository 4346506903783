import * as React from 'react'

import { Box, HBox, BoxProps } from '../Box'
import { Text } from '../Text'

export interface LegendItem extends BoxProps {
  name:string;
}

interface Props extends BoxProps {
  items:LegendItem[];
}

export function Legend(props:Props) {
  const {items, ...remaining} = props;

  return <HBox width='100%' hAlign='justify' gap='$8' px='$4' pb='$8' {...remaining}>
    {items.map((type, index) => {
      const {name, ...styles} = type;
      return <HBox key={index} vAlign='center'>
        <Box minHeight='14px' maxHeight='14px' minWidth='14px' maxWidth='14px' borderRadius='7px' mr='4px' {...styles} />
        <Text text='formlabel'>{name}</Text>
      </HBox>
    })}
  </HBox>
}
