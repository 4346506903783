import * as React from 'react'

import { Info, Link } from 'app2/components'

interface Props {
  site: {
    active?:boolean;
  }
}

export function InactiveWarning(props:Props) {
  if (!props.site || props.site.active) {
    return <></>
  }

  return <Info type='warning' mb='$16'><span>Your school needs to be activated by Homeroom before families can enroll.  You can request this by sending an email to <Link to='mailto:hello@homeroom.com'>hello@homeroom.com</Link>.</span></Info>
}
