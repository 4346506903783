import * as React from 'react'

import { useLifecycle  } from './useLifecycle';
import { useStateWithDeps } from './useStateWithDeps';

type StateFn<T> = (prevState?:T) => T;

// similar to useState except it will always set delayed
// to avoid updating state during rendering and guard 
// against setting after being unmounted

export function useSafeState<T>(initialState?: T | StateFn<T>, deps?: React.DependencyList): [T, React.Dispatch<React.SetStateAction<T>>] {
  const mounted = useLifecycle();
  const timeout = React.useRef(null);
  const [state, actualSetState] = !deps ? React.useState(initialState) : useStateWithDeps(initialState, deps);

  function setState(state:T | StateFn<T>) {
    if (!mounted.current || timeout.current) {
      return;
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;

      if (!mounted.current) {
        return;
      }

      actualSetState(state);
    });
  }

  return [state, setState];
}
