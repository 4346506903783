import { LocationDescriptorObject, LocationState } from 'history';
import * as qs from 'query-string';

import { getAppModes } from 'app2/components/AppModes';
import { HistoryProxy } from 'app2/components/history/HistoryProxy';
import { embedMessageManager } from 'app2/components/embed';

import { RouteProps } from './Route';

export class HomeroomHistory extends HistoryProxy<LocationState, RouteProps> {
  onNavigate(location: LocationDescriptorObject):LocationDescriptorObject {
    const modes = getAppModes();

    if (modes && Object.keys(modes).length) {
      const query = qs.parse(location.search);

      for (const mode in modes) {
        query[mode] = modes[mode]
      }

      location.search = '?' + qs.stringify(query);
    }

    embedMessageManager.sendMessage('history-change', {url:(location.pathname || '') + (location.search || '') + (location.hash || '')});

    return location;
  }
}
