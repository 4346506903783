import * as React from 'react'

import { MessageEntityInput } from 'app2/api';
import { Collapsible, Form, FormModel, InputField, NotificationManager, Saveable, SaveableProps, Section, Span, TextAreaField, useForm } from 'app2/components';

import { MessageParticipants, formatMessageEntity } from '../MessageParticipants';
import { MessageEntity } from '../MessageEntity';
import { MessageThreadSelections } from '../generated';

import { useFromTo } from './useFromTo';
import { createReply } from './generated';

interface Props extends SaveableProps {
  replyTo:MessageThreadSelections['messages'][0];
  to:MessageEntity[];
  cc:MessageEntity[];
  labels?:(MessageEntityInput | MessageEntity)[];
  kind?:'sms' | 'email';
  role: 'parent' | 'user' | 'organizer' | 'provider';
}

export function CreateReply(props:Props) {
  const { replyTo, to, cc, labels, onOk:propsOnOk, kind, role, ...remaming } = props;
  const { userInfo, uniqTo, from, fromEntity } = useFromTo(props);
  const form = useForm(() => ({
    subject: /^re:/i.test(replyTo.subject) ? replyTo.subject : replyTo.subject ? `Re: ${replyTo.subject}`: null,
  }), []);

  function render() {
    return <Saveable {...remaming}>
      <Form width='100%' editing onOk={onOk} form={form} pr={0}>
        {kind != 'sms' && <Collapsible minHeight={72}><Section label="To"><MessageParticipants participants={uniqTo} /></Section></Collapsible>}
        {kind != 'sms' && <Section label="Subject" name='subject' component={InputField} />}
        {kind != 'sms' && <Section label="From"><Span>{formatMessageEntity(from)}</Span></Section>}
        <Section label="Message" name='body' component={TextAreaField} autoSize={false} />
      </Form>
    </Saveable>
  }

  async function onOk(form:FormModel) {
    const [success] = await createReply({variables: {fromEntity: role != 'parent' && role != 'user' ? fromEntity : null, replyTo: replyTo.id, subject: form.values.subject, body: form.values.body}});

    if (success) {
      NotificationManager.success('Reply sent!');
      propsOnOk?.();
    }

    return success;
  }

  return render();
}

