import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TableViewUpsertMutationVariables = Types.Exact<{
  entityKind: Types.EntityKind;
  entityId: Types.Scalars['ID'];
  view: Types.TableViewInput;
}>;

export type TableViewUpsertMutation = {
  tableViewUpsert?: { entity?: { tableViews?: Array<{ id: string; name: string }> | null | undefined } | { tableViews?: Array<{ id: string; name: string }> | null | undefined } | null | undefined } | null | undefined;
};

export const TableViewUpsertDocument = gql`
  mutation tableViewUpsert($entityKind: EntityKind!, $entityId: ID!, $view: TableViewInput!) {
    tableViewUpsert(entityKind: $entityKind, entityId: $entityId, view: $view) {
      entity {
        ... on WithTableViews {
          tableViews {
            id
            name
          }
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TableViewUpsertOptions = ExecMutationOptions<TableViewUpsertMutationVariables>;

export function tableViewUpsert(options: TableViewUpsertOptions) {
  return executeMutation<TableViewUpsertMutation, TableViewUpsertMutationVariables>(TableViewUpsertDocument, options);
}
