import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdatePayoutConfigMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  payoutConfig: Types.SitePayoutConfigInput;
}>;

export type OrganizerUpdatePayoutConfigMutation = { siteUpdateGeneralInfo?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdatePayoutConfigDocument = gql`
  mutation OrganizerUpdatePayoutConfig($siteId: ID!, $payoutConfig: SitePayoutConfigInput!) {
    siteUpdateGeneralInfo(site: $siteId, payoutConfig: $payoutConfig) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdatePayoutConfigOptions = ExecMutationOptions<OrganizerUpdatePayoutConfigMutationVariables>;

export function organizerUpdatePayoutConfig(options: OrganizerUpdatePayoutConfigOptions) {
  return executeMutation<OrganizerUpdatePayoutConfigMutation, OrganizerUpdatePayoutConfigMutationVariables>(OrganizerUpdatePayoutConfigDocument, options);
}
