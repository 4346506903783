import * as React from 'react'
import { Switch, useHistory } from 'react-router-dom';

import { userPreferences } from 'app2/api';
import { Checkbox, Breakpoints, HBox, Img, VRule, useBreakpoints, usePreference, VBox } from 'app2/components';
import { Route } from 'app2/views/shared-public';
import logo from 'app2/views/shared-public/page/logo-image-only.png'

import { Thread } from './Thread';
import { ThreadList, MessageListProps } from './ThreadList';
import { useBaseMessageUrl } from './useBaseMessageUrl';
import { useThreadId } from './useThreadId';

// this is broken into two different components so that the routes are rendered
// and we can get the thread id parameer

interface Props extends Omit<MessageListProps, 'nonSystemMessages' | 'systemMessages'> {
  role: 'parent' | 'user' | 'organizer' | 'provider';
  onClickStudent?:(id:string) => void;
}

export function Threads(props:Props) {
  const baseUrl = useBaseMessageUrl();
  const breakpoint = useBreakpoints();
  const inline = breakpoint == Breakpoints.desktop;

  function render() {
    return <Switch>
      <Route scrollable={!inline} path={`${baseUrl}/messages/:thread`} render={() => <ThreadsAndThread inline={inline} {...props} />} />
      <Route scrollable={!inline} path="*" render={() => <ThreadsAndThread inline={inline}  {...props} />} />
    </Switch>
  }

  return render();
}

function ThreadsAndThread(props:Props) {
  const history = useHistory();
  const baseUrl = useBaseMessageUrl();

  const { scoped, threadScope, threadId } = useThreadId();
  const showAutomatedToggle = props.role == 'organizer' || props.role == 'provider'
  const [showSystemMessages, setShowSystemMessages] = usePreference(userPreferences, 'showAutomatedMessages', true);

  function render() {
    return threadId == 'compose' ? renderThread() : !threadId || (scoped && !threadScope) ? renderListOrCombined() : renderThreadOrCombined();
  }

  function renderListOrCombined() {
    return props.inline
      ? renderCombined()
      : <>
      {renderHeader()}
      {renderList()}
    </>
  }

  function renderThreadOrCombined() {
    return props.inline
      ? renderCombined()
      : renderThread()
  }

  function renderHeader() {
    if (!showAutomatedToggle) {
      return <></>
    }

    return <VBox gap='$16' width='100%' mb='$16'>
      <Checkbox checked={showSystemMessages} onChange={event => toggleShowAutomated()} label={<><Img src={logo} height='16px' mr='$8' />Show automated</>} />
    </VBox>
  }

  function renderCombined() {
    return <>
      {renderHeader()}
      <HBox width='100%' bg='formBackground' borderRadius='form' overflow='hidden'>
        {renderList(true)}
        {threadId
          ? <>
            <VRule />
            {renderThread(true)}
          </>
          : null
        }
      </HBox>
    </>
  }

  function renderList(inline?:boolean) {
    return <ThreadList role={props.role} labelOrParticipant={props.labelOrParticipant} nonSystemMessages={true} systemMessages={showSystemMessages} entityMessages={props.entityMessages} primaryActions={props.primaryActions} inline={inline} />
  }

  function renderThread(inline?:boolean) {
    return <Thread role={props.role} labels={props.labelOrParticipant.type == 'Course' ? [props.labelOrParticipant] : []} inline={inline} entityMessages={props.entityMessages} onClickStudent={props.onClickStudent} />
  }

  function toggleShowAutomated() {
    setShowSystemMessages(!showSystemMessages);
    history.replace(`${baseUrl}/messages`);
  }

  return render();
}
