import * as React from 'react';

import { Button } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CourseSelections } from '../../../generated';

import { AddStudentModal } from './AddStudentModal';

interface Props {
  course: CourseSelections;
}

export function AddStudentAction(props: Props) {
  const behavior = courseKindBehavior[props.course?.kind];
  
  if (behavior.management.addStudent === false || !props.course?.behavior?.enrollmentAdd) {
    return <></>;
  }

  return <Button icon="Plus" iconPosition='left' autoLoader={false} onClick={<AddStudentModal course={props.course} />}>
    {`Add ${behavior.terms.participant}`}
  </Button>
}
