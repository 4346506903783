import { UseQueryResponse } from 'urql'

import { parentPreferences } from 'app2/api';
import { UseQueryArgs, useFamilyParameter } from 'app2/views/shared';

type Result<T> = {family?:T};
type FamilyArgs<T> = Omit<UseQueryArgs<{family:string}, Result<T>>, 'query'>;
type Query<T> = (args:FamilyArgs<T>) => UseQueryResponse<Result<T>>;

export function useFamilyQuery<T>(query:Query<T>) {
  const id = useFamilyParameter();
  const [result] = query({variables:{family:id}});
  const family = result.data?.family;
  const fetching = result.fetching;

  return {family, prefs:parentPreferences, id:id, fetching};
}
