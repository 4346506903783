import { get } from 'lodash-es';

export type GroupByClosedPaths = {
  [key: string]: true | GroupByClosedPaths;
};

interface GroupedByItem {
  [key: string]: any;
}

interface FlattenedGroupedByItem {
  "group-state": string;
  value:any;
  count:number;
}

type NestedGroupedItems = {
  [key: string]: NestedGroupedItems | GroupedByItem[];
};

type PropCallback = (data:any) => string;

export interface GroupByOptions {
  // normally there's a group line for each group, this will 
  // create one group by combining all group values
  flattenGroups?:boolean;
  closedPaths?:GroupByClosedPaths;
}

export function groupBy(arr: GroupedByItem[] | null | undefined, propNames:string[], options:GroupByOptions = {}): (FlattenedGroupedByItem | GroupedByItem)[] {
  if (!arr) {
    return [];
  }

  if (propNames.length === 0) {
    return arr;
  }

  const props = !options.flattenGroups
    ? propNames.map(name => (data:any) => get(data, name))
    : [getGroupValuesAsString(propNames)]

  const nestedGroupedData = groupIntoNestedHash(arr, props, options);
  const rows:(FlattenedGroupedByItem | GroupedByItem)[] = [];
  flattenNestedGroups(nestedGroupedData, options.closedPaths, rows);

  return rows;
}

function getGroupValuesAsString(props:string[]) {
  return (data:any) => (props.map(name => get(data, name)).filter(v => !!v).join(', '));
}

function groupIntoNestedHash(arr: GroupedByItem[], props: PropCallback[], options:GroupByOptions ): NestedGroupedItems {
  const result: NestedGroupedItems = {};

  for (const item of arr) {
    let currentLevel: NestedGroupedItems | GroupedByItem[] = result;

    for (let i = 0; i < props.length; i++) {
      const prop = props[i];
      const groupValue = prop(item) ?? '';

      if (i === props.length - 1) {
        // Ensure that `currentLevel[groupValue]` is an array at the deepest level
        if (!Array.isArray(currentLevel[groupValue])) {
          currentLevel[groupValue] = [];
        }
        (currentLevel[groupValue] as GroupedByItem[]).push(item);
      }
      else {
        // Move down the nested structure, creating objects as needed
        if (!currentLevel[groupValue]) {
          currentLevel[groupValue] = {};
        }
        currentLevel = currentLevel[groupValue] as NestedGroupedItems;
      }
    }
  }

  return result;
}

function flattenNestedGroups(nestedGroups: NestedGroupedItems, closedPaths:GroupByClosedPaths | true, output:GroupedByItem[] = [], path:string[] = []):number {
  let count = 0;
  const closed = closedPaths === true;

  Object.keys(nestedGroups).sort().forEach((key) => {
    const localClosedPaths = !closed ? closedPaths?.[key] : true;
    const localClosed = closed || localClosedPaths === true
    const localPath = path.concat(key);
    const value = nestedGroups[key];
    const parent = { value: key, "group-state": !localClosed ? 'opened' : 'closed', count: 0, path: localPath };

    if (!closed) {
      output.push(parent);
    }

    if (Array.isArray(value)) {
      parent.count += value.length;
      count += value.length;

      if (!localClosed) {
        output.push(...value);
      }
    } 
    else {
      const countThisLevel = flattenNestedGroups(value as NestedGroupedItems, localClosedPaths, output, localPath);
      parent.count += countThisLevel;
      count += countThisLevel;
    }
  });

  return count;
}

export function toggleOpenClosedState(closedPaths:GroupByClosedPaths, row:any) {
  const path:string[] = row.path;
  let curPath:GroupByClosedPaths = {...closedPaths};

  path.forEach((p, index) => {
    if (!curPath) {
      return;
    }

    const curPathEntry = curPath[p];
    const last = index == path.length - 1;
    const nextToLast = index == path.length - 2;

    if (!curPathEntry && last) {
      curPath[p] = true;
    }
    else 
    if (!curPathEntry) {
      curPath = curPath[p] = {};
    }
    else
    if (last || nextToLast) {
      delete curPath[p];
      curPath = null;
    }
  });
  
  return closedPaths;
}
