import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUpdateCartedEnrollmentMutationVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID'];
  studentId?: Types.InputMaybe<Types.Scalars['ID']>;
  beforeClassPickup?: Types.InputMaybe<Types.Scalars['String']>;
  afterClassDismissal?: Types.InputMaybe<Types.Scalars['String']>;
  startDate?: Types.InputMaybe<Types.Scalars['ISO8601Date']>;
}>;

export type ParentUpdateCartedEnrollmentMutation = { cartUpdateEnrollment?: { enrollment?: { id: string } | null | undefined } | null | undefined };

export const ParentUpdateCartedEnrollmentDocument = gql`
  mutation ParentUpdateCartedEnrollment($enrollmentId: ID!, $studentId: ID, $beforeClassPickup: String, $afterClassDismissal: String, $startDate: ISO8601Date) @urql(additionalTypenames: ["Cart"]) {
    cartUpdateEnrollment(enrollmentId: $enrollmentId, studentId: $studentId, beforeClassPickup: $beforeClassPickup, afterClassDismissal: $afterClassDismissal, startDate: $startDate) {
      enrollment {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentUpdateCartedEnrollmentOptions = ExecMutationOptions<ParentUpdateCartedEnrollmentMutationVariables>;

export function parentUpdateCartedEnrollment(options: ParentUpdateCartedEnrollmentOptions) {
  return executeMutation<ParentUpdateCartedEnrollmentMutation, ParentUpdateCartedEnrollmentMutationVariables>(ParentUpdateCartedEnrollmentDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Cart']) }
  });
}
