import * as React from 'react';

import { SearchType, Student } from 'app2/api';
import { EmailField, Field, InputField, Link, Panel, PhoneField, RepeatingSection, Section, useForm, useFormToUrl } from 'app2/components';
import { CourseStatusTag, CourseLink } from 'app2/views/shared-public';
import { PrivatePage, SeasonLink, useStudentsQuery } from 'app2/views/shared';

import { SearchTypeMenu } from './SearchTypeMenu';

import { CoursesSeachSelections, useCoursesSeachQuery } from './generated';

type CourseResult = CoursesSeachSelections['accessibleCourses'][0];

export function Search() {
  const form = useForm({term: '', type: SearchType.Student}, []);
  useFormToUrl(form);

  const [studentResults] = useStudentsQuery({variables: {term: form.values.term || ''}, debounce: {delay: 350}, hideLoader: true, pause: form.values.type != SearchType.Student});
  const [courseResults] = useCoursesSeachQuery({variables: {term: form.values.term || ''}, debounce: {delay: 350}, hideLoader: true, pause: form.values.type != SearchType.Activity});

  function render() {
    return <PrivatePage title="Search">
      <Panel title='Search' form={form} type='edit-no-save-button' onNavigation='nothing'>
        <Section name='term' component={InputField} placeholder='Enter student name' controls={<SearchTypeMenu type={form.values.type} setType={setType} />} />
      </Panel>

      {form.values.type == SearchType.Student ? renderStudents() : renderCourses()}
    </PrivatePage>
  }

  function renderStudents() {
    return <Panel key='students' initialValues={{items: studentResults.data?.studentsSearch}}>
      <RepeatingSection name='items' numbered={false} fields={[
        <Field label='First name' format={(s:Student) => <Link to={`/student/${s.id}?from=search`}>{s.firstName}</Link>} />,
        <Field label='Last name' format={(s:Student) => <Link to={`/student/${s.id}?from=search`}>{s.lastName}</Link>} />,
        <Field label='Family' name='parents.0.name' />,
        <Field label='Phone' name='parents.0.phone' component={PhoneField} />,
        <Field label='Email' name='parents.0.email' component={EmailField} />,
      ]}  />
    </Panel>
  }

  function renderCourses() {
    return <Panel key='courses' initialValues={{items: courseResults.data?.user?.accessibleCourses}}>
      <RepeatingSection name='items' numbered={false} fields={[
        <Field label='Name' format={(c:CourseResult) => <CourseLink course={c} role='organizer' />} />,
        <Field label='Status' format={(c:CourseResult) => <CourseStatusTag status={c.status} small />} />,
        <Field label='Season' format={(c:CourseResult) => <SeasonLink season={c.season} />} />,
      ]}  />
    </Panel>
  }

  function setType(type:SearchType) {
    form.setValue('type', type);
  }

  return render();
}
