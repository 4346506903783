import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteVendorsQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteVendorsQuery = {
  site?: { vendors: Array<{ id: string; displayName: string; typename: 'Vendor'; company?: { id: string; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined }> } | null | undefined;
};

export const SiteVendorsDocument = gql`
  query SiteVendors($site: ID!) {
    site(site: $site) {
      vendors {
        id
        displayName
        typename: __typename
        company {
          id
          userCompanyRole
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteVendorsArgs = MakeOptional<UseQueryArgs<SiteVendorsQueryVariables, SiteVendorsQuery>, 'query'>;

export function useSiteVendorsQuery(options: SiteVendorsArgs = {}) {
  return useQuery<SiteVendorsQueryVariables, SiteVendorsQuery>({ query: SiteVendorsDocument, ...options });
}

export type SiteVendorsOptions = ExecQueryOptions<SiteVendorsQueryVariables>;

export function siteVendors(options: SiteVendorsOptions) {
  return executeQuery<SiteVendorsQuery, SiteVendorsQueryVariables>(SiteVendorsDocument, options);
}

export type SiteVendorsSelections = SiteVendorsQuery['site'];
