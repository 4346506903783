import * as React from 'react'
import { useLocation } from 'react-router';

import { isRealProduction } from 'app/helpers';
import { Box, BoxProps, Img, findRoute } from 'app2/components';

import { useCurrentUser } from '../user';

import { useHrHistory } from './useHrHistory';
import logo from './logo-image-only.png'

interface Props {
  children:React.ReactNode;
  position?:'left' | 'top';
  production?:boolean;
}

export function Beta(props:Props) {
  const showBeta = useShowBeta() && (props.production || !isRealProduction());

  return showBeta ? <Box css={props.position == 'left' ? betaImageCssLeft : betaImageCssAbove}>{props.children}</Box> : <></>
}

Beta.defaultProps = {
  position: 'left',
  production: true
}

export function BetaIcon(props:BoxProps) {
  return <Box width='0px' {...props}><Img src={logo} width='15px' height='15px' /></Box>
}

export function useShowBeta() {
  const user = useCurrentUser();
  const showBeta = user.user?.homeroom;

  return showBeta;
}

export const betaImageCssAbove = {
  position: 'relative',
  '::before': {
    backgroundImage: `url(${logo})`,
    backgroundSize: 'cover',
    width: '15px',
    height: '15px',
    content: "' '",
    position: 'absolute',
    zIndex: 999,
    left: '0px',
    top: '-14px'
  } as React.CSSProperties
}

export const betaImageCssLeft = {
  position: 'relative',
  '::before': {
    backgroundImage: `url(${logo})`,
    backgroundSize: 'cover',
    width: '15px',
    height: '15px',
    content: "' '",
    position: 'absolute',
    zIndex: 999,
    left: '-17px',
    top: '2px',
    overflowY: 'hidden'
  } as React.CSSProperties
}

export function useIsBetaRoute() {
  const location = useLocation();
  const history = useHrHistory();
  const route = findRoute(location.pathname, history.routes);

  return route?.props?.beta;
}

interface BetaLabelProps {
  label?:React.ReactNode;
  children?:React.ReactNode;
}

export function BetaLabel(props:BetaLabelProps) {
  return <Box display='inline-flex' gap='$8'><BetaIcon width='15px' />{props.children || props.label}</Box>
}
