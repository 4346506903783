import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserFamiliesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserFamiliesQuery = { user?: { families?: Array<{ id: string; name?: string | null | undefined }> | null | undefined } | null | undefined };

export const UserFamiliesDocument = gql`
  query UserFamilies {
    user: currentUser {
      families {
        id
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type UserFamiliesArgs = MakeOptional<UseQueryArgs<UserFamiliesQueryVariables, UserFamiliesQuery>, 'query'>;

export function useUserFamiliesQuery(options: UserFamiliesArgs = {}) {
  return useQuery<UserFamiliesQueryVariables, UserFamiliesQuery>({ query: UserFamiliesDocument, ...options });
}

export type UserFamiliesOptions = ExecQueryOptions<UserFamiliesQueryVariables>;

export function userFamilies(options: UserFamiliesOptions) {
  return executeQuery<UserFamiliesQuery, UserFamiliesQueryVariables>(UserFamiliesDocument, options);
}

export type UserFamiliesSelections = UserFamiliesQuery['user'];
