import { FileUpload, FileUploadAttributes } from 'app2/api';

export function prepareFileUploads(files:Partial<(FileUpload | FileUploadAttributes)>[]):FileUploadAttributes[] {
  return files?.map(file => ({
    id: file.id,
    fileData: (file as FileUploadAttributes).fileData,
    fileType: file.fileType,
    name: file.name,
  }));
}

