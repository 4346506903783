import { split } from 'lodash-es';

import { localStorageHelper } from 'app/helpers/localStorageHelper';

import { User } from '../graphql';
import { axios } from '../account';
import { SHARER_PARAM, SHARED_FROM_PARAM } from '../events';

const SHARE_KEY = 'hr_shares'

export class UserUtils {
  static buildShareQSParams(user:Pick<User, 'uuid'>) {
    if (!user) {
      return;
    }

    const params = new URLSearchParams(`${SHARED_FROM_PARAM}=${location.pathname}`)

    if (user.uuid) {
      params.set(SHARER_PARAM, user.uuid)
    }

    return params
  }

  // if the user is not logged in, this will store the shared to 
  // uuid in local storage until the user loggs in

  static async recordCourseShare(courseId:string, sharerUUID:string, user?:Pick<User, 'id' | 'uuid'>) {
    const shareeUUID = user?.uuid;
    const shares = JSON.parse(localStorageHelper.getItem(SHARE_KEY) || '{}')

    if (courseId && sharerUUID) {
      shares[courseId] = sharerUUID
    }

    await Promise.all(
      Object.entries(shares).map(([courseId, sharerUUID]) => {
        try {
            if (!user) {
              return;
            }

            if (shareeUUID != sharerUUID) {
              this.captureShare(courseId, user.id, sharerUUID as string)
            }

            delete shares[courseId]
          } catch (_) {
        }
      })
    )

    localStorageHelper.setItem(SHARE_KEY, JSON.stringify(shares))
  }

  static async captureShare(courseId:string, shareeId:string, sharerUUID:string) {
    await axios.post('/shares', { share: {
      shareable_type: 'Course',
      sharer_uuid: sharerUUID,
      sharee_id: shareeId,
      shareable_id: courseId
    }})  
  }

  static getInitials(name: string, separator:RegExp | string = ' ') {
    const words = split(name || '', separator);
    const [first, last] = words;

    if (words.length < 2 || words.length > 2) {
      return first ? first[0] : '';
    }

    let initials = '';

    if (first) {
      initials += first[0].toUpperCase();
    }

    if (last) {
      initials += last[0].toUpperCase();
    }

    return initials;
  }
}
