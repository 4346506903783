import * as React from 'react';

export function useNodeListeners() {
  const listener = React.useRef<ListenerInfo>(new ListenerInfo());

  return listener.current;
}

class ListenerInfo {
  nodes:ChildNode[];
  onComplete:() => void;

  listen(nodes:ChildNode[], onComplete:() => void): void {
    this.removeAllListeners();
    this.nodes = nodes;
    this.onComplete = onComplete;

    if (this.nodes.length === 0) {
      this.onComplete();
      return;
    }

    this.nodes.slice().forEach((node) => {
      const watch = (node instanceof HTMLImageElement && !node.complete) || 
                    (node instanceof HTMLScriptElement && node.src) || 
                    (node instanceof HTMLLinkElement && node.rel === 'stylesheet');
      if (watch) {
        node.addEventListener('load', this.loadHandler);
        node.addEventListener('error', this.errorHandler);
      } 
      else {
        this.removeNode(node);
      }
    });
  }

  removeNode(node:ChildNode) {
    const index = this.nodes.findIndex(n => n === node);

    if (index === -1) {
      return;
    }

    this.nodes.splice(index, 1);

    if (this.nodes.length === 0) {
      this.onComplete();
    }
  }

  loadHandler = (event:Event) => {
    this.removeListeners(event.target as Element);
  };

  errorHandler = (event:Event) => {
    this.removeListeners(event.target as Element);
  };

  removeListeners(node:ChildNode): void {
    const index = this.nodes.findIndex(n => n === node);

    if (index === -1) {
      return;
    }

    node.removeEventListener('load', this.loadHandler);
    node.removeEventListener('error', this.errorHandler);

    this.removeNode(node);
  }

  unlisten() {
    this.removeAllListeners();
  }

  removeAllListeners() {
    if (!this.nodes) {
      return;
    }

    this.nodes.slice().forEach(node => this.removeListeners(node));
    this.nodes = null;
    this.onComplete = null;
  }
}
