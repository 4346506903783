import * as React from 'react'

import { Dropdown, Modal, Section, Form, FormModel } from 'app2/components'
import { courseKindBehavior, createOptions } from 'app2/views/shared-public';

import { useOrganizerSiteSeasonsQuery } from '../shared'

import { useNavigateToTableAndEdit } from './useNavigateToTableAndEdit';
import { seasonImport } from './gql';

interface Props {
  season:string;
  site:string;
}

export function ImportActivitiesModal(props:Props) {
  const navigate = useNavigateToTableAndEdit();
  const options = createOptions(useOrganizerSiteSeasonsQuery({variables:{siteId: props.site}})[0].data?.site?.seasonsByStatus || []);

  function render() {
    return <Modal title='Copy a past season'>
      <Form icon='BookOpen' title='Select season' onNavigation='nothing' onOk={onImport}>
        <Section name='season' label='Select season to copy activities from' component={Dropdown} options={options} placeholder required />
      </Form>
    </Modal>
  }

  async function onImport(form:FormModel) {
    const season = form.values.season;
    const [success, importResult] = await seasonImport({ variables: { season: props.season, importFrom: season.id } });

    if (success) {
      const courses = importResult?.data.seasonImport.courses;
      const importedKindBehaviors = courses.map(c => courseKindBehavior[c.kind]).sort((a, b) => a.order - b.order);
      const first = importedKindBehaviors[0];

      if (first) {
        navigate(first.table);
      }
    }

    return success;
  }

  return render();
}
