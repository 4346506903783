import * as React from 'react';
import { groupBy, toPairs } from 'lodash-es';

import { Point } from '../../dom-utils';
import { useLifecycle, useForceUpdate } from '../../utils';

import { DataTable, DataTableProps } from '../DataTable';
import { colId, compareRows, DataTableColumn, getColsFormatFunction } from '../column/DataTableColumn';

import { GroupByHeader, groupByHeaderHeight } from './GroupByHeader';

export interface GroupedDataTableProps<T = any> extends DataTableProps<T> {
  groupBy?:(string | DataTableColumn<T>)[];
  groupHeader?:React.ComponentType<any>;
  groupHeaderHeight?:number;
}

export interface GroupedDataTableHandle<T = any> {
  props:GroupedDataTableProps<T>;
  tables:React.RefObject<DataTable<T>>[];
}

export const GroupedDataTable = React.forwardRef((props:GroupedDataTableProps<any>, ref:React.Ref<GroupedDataTableHandle>) => {
  const {groupHeader:GroupHeader, groupHeaderHeight, cols:propsCols, groupBy:propsGroupBy, ...remaining} = props;
  const stickyOffset = new Point(props.stickyOffset?.x || 0, (props.stickyOffset?.y || 0) + groupHeaderHeight);
  const {cols, groupByCols, getGroupByLabel} = getCols();
  const groups = getGroups();
  const tables = getRefs();
  setUpImperativeHandle();

  const usingGroupBy = props.groupBy && groups;

  // we need an extra render to get the table refs that the group headers need
  const nullRefs = usingGroupBy && tables.length && !tables[0].current;
  const forceUpdate = useForceUpdate();
  useLifecycle({onUpdate});

  function render() {
    return !usingGroupBy
      ? <DataTable ref={tables[0]} cols={cols} {...remaining} />
      : renderGrouped()
  }

  function renderGrouped() {
    return <div>
      {groups.map((group, index) => (
        <div key={group[0]}>
          <GroupHeader position='sticky' top={(stickyOffset.y - groupHeaderHeight) + 'px'} zIndex={21} table={tables[index].current} cols={groupByCols} groupCount={groups.length} />
          <DataTable {...remaining} ref={tables[index]} cols={cols} data={group[1] as any} stickyOffset={stickyOffset} />
        </div>
      ))}
    </div>
  }

  function getCols() {
    return React.useMemo(() => { 
      if (!props.groupBy?.length) {
        return {cols: propsCols, groupByCols: undefined, getGroupByLabel: undefined};
      }

      const groupByCols = props.groupBy.map(groupBy => propsCols.find(col => colId(col) == colId(groupBy))).filter(col => !!col);
      const cols = props.cols.filter(col => props.groupBy.indexOf(colId(col)) == -1);

      return {cols, groupByCols, getGroupByLabel: getColsFormatFunction(groupByCols)};
    }, [propsCols, propsGroupBy]);
  }

  function getGroups() {
    return React.useMemo(() => {
      if (!props.groupBy?.length) {
        return undefined;
      }

      const unsortedGroups = toPairs(groupBy(props.data, (row) => getGroupByLabel(row) || 'none'));
      const sortedGroups = unsortedGroups.sort((a, b) => compareRows(a[1][0], b[1][0], groupByCols));

      // need at least 1 group so there's a table
      return sortedGroups.length ? sortedGroups :  [['', []] as [string, string[]]];
    }, [props.data, getGroupByLabel]);
  }

  function getRefs() {
    return React.useMemo(() => {
      return groups ? groups.map(group => React.createRef<DataTable<any>>()) : [React.createRef<DataTable<any>>()]
    }, [groups]);
  }

  function onUpdate() {
    if (nullRefs) {
      forceUpdate();
    }
  }

  function setUpImperativeHandle() {
    ref = ref || React.useRef();

    React.useImperativeHandle<any, any>(ref, () => ({props, tables}), [props, tables]);
  }

  return render();
})

GroupedDataTable.defaultProps = {
  groupHeader: GroupByHeader,
  groupHeaderHeight: groupByHeaderHeight,
}

// todo - get rid of all this mess when we get rid of GroupedDataTable and
// replace it with GroupedDataTable2
export type DataTableHandle<T = any> = DataTable<T> | GroupedDataTableHandle<T>

export function tableIsGrouped<T = any>(table:DataTableHandle<T>) {
  return groupedTable(table)?.tables != null
}

export function groupedTable<T = any>(table:DataTableHandle<T>) {
  return table as GroupedDataTableHandle;
}

export function ungroupedTable<T = any>(table:DataTableHandle<T>) {
  return table as DataTable<T>;
}

export function dataTable<T = any>(table:DataTableHandle<T>):DataTable<T> {
  return tableIsGrouped(table) ? groupedTable(table).tables[0]?.current : ungroupedTable(table);
}
