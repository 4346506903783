import { DataTableColumn, colId } from './DataTableColumn';
import { expandCols } from './expandCols';

// adds *missing* attributes in cols from baseCols into cols

export function mergeCols<T = any>(cols: (string | DataTableColumn<T>)[], baseCols: Partial<DataTableColumn<T>>[]):DataTableColumn<T>[] {
  const map:any = {};
  baseCols.forEach(c => map[colId(c)] = c);

  return expandCols(cols)
    .filter(c => !!c)
    .map(c => {
      const base = map[colId(c)];

      return base
        ? {
          ...base,
          ...c
        }
        : c
    })
}
