import * as React from 'react';

import { Body, Text, VBox, TextTypes, TextType, rgbToHex, HBox } from 'app/app2/components';

import { Demo } from './Demo';

const text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.'

export function TextDemos() {
  return <VBox width='100%'>
    {Object.values(TextTypes).map(name => renderTextType(name as TextType))}
    <Demo name='Using type'>
      <Text text='body'>{text}</Text>
    </Demo>
    <Demo name='Using tag'>
      <Body>{text}</Body>
    </Demo>
    <Demo name='Various styles'>
      <Body m='10px' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer'>{text}</Body>
    </Demo>
    <Demo name='Custom CSS'>
      <Body css={{
        ':hover': {
          border: 'solid 10px red'
        }}}>Move the mouse over me</Body>
    </Demo>
  </VBox>
}

function renderTextType(type:TextType) {
  return <Demo key={type} name={type}>
    <HBox>
      {renderText('Normal', <Text text={type}>{text}</Text>)}
      {renderText('Disabled', <Text text={type} disabled>{text}</Text>)}
      {renderText('Link', <Text text={type} link>{text}</Text>)}
    </HBox>
  </Demo>
}

function renderText(title:string, element:React.ReactElement) {
  const [ref, setRef] = React.useState<HTMLElement>();
  const style = ref ? window.getComputedStyle(ref) : undefined;
  const color = style?.color ? rgbToHex(style.color) : {};

  return <VBox maxWidth='400px'>
    <Text text='subtitle2'>{title}</Text>
    {React.cloneElement(element, {ref:(newRef:HTMLElement) => setRef(newRef)})}
    {style &&
      <VBox>
        <Body><b>Font family: </b>{style.fontFamily}</Body>
        <Body><b>Font size: </b>{style.fontSize}</Body>
        <Body><b>Font weight: </b>{style.fontWeight}</Body>
        <Body><b>Color: </b>{color}</Body>
        <Body><b>Line height: </b>{style.lineHeight}</Body>
      </VBox>
     }
  </VBox>
}