import * as React from 'react';

import { Form } from 'app2/components';
import { StudentDetailsFields } from 'app2/views/shared';

import { useRestrictedStudentDetailsQuery } from './generated';

interface Props {
  id: string;
  token: string;
}

export function RestrictedStudentDetails(props: Props) {
  const [result] = useRestrictedStudentDetailsQuery({ variables: { token: props.token, id: props.id } });
  const student = result?.data?.studentByToken;

  function render() {
    return <Form width="100%" initialValues={student} onNavigation='nothing' editing>
      <StudentDetailsFields role='teacher' />
    </Form>
  }

  return render();
}
