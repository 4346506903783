import * as React from 'react'
import moment from 'moment'

import { HBox, VBox } from '../Box'
import { Subtitle1 } from '../Text';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Span } from '../Tags';
import { Icon } from '../icons';
import { Breakpoints } from '../theme';
import { useBreakpoints } from '../breakpoints';

interface Props {
  startOfWeek:moment.Moment;
  onChange?:(date:moment.Moment) => void;
  weekends?:boolean;
  onChangeWeekends:(weekends:boolean) => void;
}

export function Header(props:Props) {
  const breakpoint = useBreakpoints();

  return breakpoint != Breakpoints.desktop ? renderPhone(props) : renderOther(props);
}

function renderPhone(props:Props) {
  return <VBox width='100%' p='$12' hAlign='center' bg='formBackground'>
    {renderCenter(props)}
    <HBox width='100%'>
      {renderPrev(props)}
      <HBox flex={1} />
      {renderNext(props)}
    </HBox>
  </VBox>
}

function renderOther(props:Props) {
  return <HBox width='100%' p='$30' hAlign='center' bg='formBackground'>
    {renderPrev(props)}
    {renderCenter(props)}
    {renderNext(props)}
  </HBox>
}

function renderPrev(props:Props) {
  const prev = props.startOfWeek.clone().subtract(7, 'd');
  return <Button kind='tertiary' icon='Left' iconPosition='left' whiteSpace='normal' onClick={() => props.onChange(prev)}>Previous week</Button>;
}

function renderCenter(props:Props) {
  const numDays = props.weekends ? 7 : 5;
  const endOfWeek = props.startOfWeek.clone().add(numDays - 1, 'd');

return <VBox flex={1} width='100%' hAlign='center' vItemSpace='$12' mb={['$16', '$16', 'unset']}>
    <HBox vAlign='center' textAlign='center' minWidth='225px'>
      <Subtitle1 display='inline-flex' alignItems='center' justifyContent='center' flexWrap='wrap'>
        <Icon name='Calendar' size='medium' mr='$10' />
        <Span whiteSpace='nowrap' data-test='startOfWeek'>{props.startOfWeek.format('MMMM D, YYYY')}</Span>
        <Span mx='$8'>-</Span>
        <Span whiteSpace='nowrap'>{endOfWeek.format('MMMM D, YYYY')}</Span>
      </Subtitle1>
    </HBox>
    <Checkbox label='Show weekends' checked={props.weekends} onChange={event => props.onChangeWeekends(event.target.checked)} />
  </VBox>
}

function renderNext(props:Props) {
  const next = props.startOfWeek.clone().add(7, 'd');
  return <Button kind='tertiary' icon='Right' whiteSpace='normal' onClick={() => props.onChange(next)}>Next week</Button>
}
