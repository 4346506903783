import * as React from 'react';
import styled, { FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import { variant, compose } from './styled-system';

import { ComponentProps, componentStyles, createComponent, property } from './utils';
import { theme } from './theme';

export enum TextTypeEnum {
  'heading1',
  'heading2',
  'subtitle1',
  'subtitle2',
  'body',
  'italic',
  'formlabel',
  'marketing'
}

export const TextTypes = Object.values(TextTypeEnum).filter(value => isNaN(Number(value)));
export type TextType = keyof typeof TextTypeEnum;
export type TextProps = ComponentProps<React.HtmlHTMLAttributes<HTMLSpanElement>> & TextCustomProps & {htmlFor?: string};

export interface TextCustomProps {
  text?: TextType | TextType[];
  disabled?: boolean;
  error?: boolean;
  maxLines?: number;
  preserveWhitespace?: boolean;
  underline?: boolean;
  bold?: boolean;
  italic?: boolean;
  link?: boolean;
  small?: boolean;
  hideEmpty?: boolean;
}

const text = variant({
  prop: 'text',
  variants: {
    heading1: {
      fontFamily: theme.fonts.heading1,
      fontSize: theme.fontSizes.heading1,
      lineHeight: theme.lineHeights.heading1,
      fontWeight: theme.fontWeights.bold,
      color: theme.colors.heading1
    },
    heading2: {
      fontFamily: theme.fonts.heading2,
      fontSize: theme.fontSizes.heading2,
      lineHeight: theme.lineHeights.heading2,
      fontWeight: theme.fontWeights.bold,
      color: theme.colors.heading2
    },
    subtitle1: {
      fontFamily: theme.fonts.subtitle1,
      fontSize: theme.fontSizes.subtitle1,
      lineHeight: theme.lineHeights.subtitle1,
      fontWeight: theme.fontWeights.bold,
      color: theme.colors.subtitle1
    },
    subtitle2: {
      fontFamily: theme.fonts.subtitle2,
      fontSize: theme.fontSizes.subtitle2,
      lineHeight: theme.lineHeights.subtitle2,
      fontWeight: theme.fontWeights.bold,
      color: theme.colors.subtitle2
    },
    body: {
      fontFamily: theme.fonts.body,
      fontSize: theme.fontSizes.body,
      lineHeight: theme.lineHeights.body,
      fontWeight: theme.fontWeights.normal,
      color: theme.colors.body
    },
    formlabel: {
      fontFamily: theme.fonts.formlabel,
      fontSize: theme.fontSizes.formlabel,
      lineHeight: theme.lineHeights.formlabel,
      fontWeight: theme.fontWeights.semibold,
      color: theme.colors.formlabel
    },
    marketing: {
      fontFamily: theme.fonts.marketing,
      fontSize: theme.fontSizes.marketing,
      lineHeight: theme.lineHeights.marketing,
      fontWeight: theme.fontWeights.bold,
      color: theme.colors.marketing
    }
  }
});

function calcMaxHeight(maxLines: number, lineHeightString: string, lineMargin = 0) {
  lineHeightString = lineHeightString || '';
  const lineHeight = parseFloat(lineHeightString);
  const units = lineHeightString.substr(lineHeight.toString().length);

  return (maxLines * (lineHeight + lineMargin)) + units;
}

export function maxLinesStyles(maxLines:number, lineHeight:any, textStyle:any, lineMargin = 0) {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    'overflow-wrap': 'anywhere',
    // using calc() instead of just returning the number is to work around a bug in either
    // styled components or styled system that is adding 'px' where it doesn't belong
    '-webkit-line-clamp': `calc(${maxLines})`,
    maxHeight: calcMaxHeight(maxLines, lineHeight ? (lineHeight as string) : (theme.lineHeights as any)[textStyle], lineMargin)
  }
}

export const maxLines = property(
  'maxLines',
  (maxLines: number, props: Partial<TextProps>) => {
    return maxLinesStyles(maxLines, props.lineHeight, props.text);
  },
  ['text', 'lineHeight']
);

export const preserveWhitespace = property(
  'preserveWhitespace',
  (preserveWhitespace: boolean) =>
    preserveWhitespace
      ? {
          whiteSpace: 'pre-wrap',
          overflowWrap: 'anywhere'
        }
      : {}
);

export const hideEmpty = property(
  'hideEmpty',
  (hideEmpty: boolean) =>
  hideEmpty
      ? {
          '&:empty': {
            display: 'none'
          }
        }
      : {}
);

export const link = property(
  'link',
  (link: boolean, props: Partial<TextProps>) => {
    if (!link) {
      return {};
    }

    const colors:any = theme.colors as any;
    const color = props.color as string;
    const text = props.text as string;

    return {
      ':hover': {
        color: colors[color + 'Hover'] || colors[color] || color || colors[text + 'Hover'],
        textDecoration: 'underline',
        cursor: 'pointer'
    }
  }
},
  ['text', 'color']
);

export function createTextComponent<Props extends object>(
  tag: keyof JSX.IntrinsicElements | React.ComponentType<any>,
  includeDisplay: boolean,
  propsToExclude?: string[],
  css?: FlattenInterpolation<ThemedStyledProps<Props, any>>
) {
  propsToExclude = ['text', 'error', 'maxLines', 'link', 'small', 'hideEmpty', 'underline', 'bold', 'italic', 'preserveWhitespace', ...(propsToExclude || [])];

  // note that display: inline-block is used because
  // CSS uses line-height differently between inline and inline-block
  // inline-block actually respects the line-height property
  //
  // passed in css is before component(text...), so that the
  // text props text precendence over component css (needed by button for button link type)
  // @ts-ignore
  return createComponent(tag, propsToExclude)<Props>`
    ${includeDisplay ? 'display: inline-block;' : ''}
    ${css || ''}
    ${(props: TextProps) => (props.underline ? 'text-decoration: underline;' : '')}
    ${(props: TextProps) => (props.italic ? 'font-style: italic;' : '')}
    ${compose(text, maxLines, preserveWhitespace, link, hideEmpty)}
    ${(props: TextProps & {theme:typeof theme}) => (props.error ? `color: ${props.theme.colors.error};` : props.disabled ? `color: ${props.theme.colors.disabled};` : '')}
    ${(props: TextProps) => props.small ? 'font-size: 14px;' : undefined}
    ${componentStyles}
    ${(props: TextProps) => (props.bold ? 'font-weight: bold;' : props.bold === false ? 'font-weight: normal' : '')}
` as React.ComponentType<Props>;
}

export const Text = createTextComponent<TextProps>('span', true);
Text.displayName = 'Text';

export const Heading1 = styled(Text).attrs({ text: 'heading1' })`` as React.ComponentType<TextProps>;
Heading1.displayName = 'Heading1';

export const Heading2 = styled(Text).attrs({ text: 'heading2' })`` as React.ComponentType<TextProps>;
Heading2.displayName = 'Heading2';

export const Subtitle1 = styled(Text).attrs({ text: 'subtitle1' })`` as React.ComponentType<TextProps>;
Subtitle1.displayName = 'Subtitle1';

export const Subtitle2 = styled(Text).attrs({ text: 'subtitle2' })`` as React.ComponentType<TextProps>;
Subtitle2.displayName = 'Subtitle2';

export const FormLabel = styled(Text).attrs({ text: 'formlabel' })`` as React.ComponentType<TextProps>;
FormLabel.displayName = 'FormLabel';

export const Body = styled(Text).attrs({ text: 'body' })`` as React.ComponentType<TextProps>;
Body.displayName = 'Body';

export const Marketing = styled(Text).attrs({ text: 'marketing' })`` as React.ComponentType<TextProps>;
Marketing.displayName = 'Marketing';
