import * as React from 'react'

import { compact, DiscountDefinitionFormat, PriceConfigKind } from 'app2/api';
import { Dropdown, DropdownProps, formatCurrency, formatPercent } from 'app2/components';
import { EnrollmentConfig } from 'app2/views/shared-public'

import { EligibleDiscountsSelections, useEligibleDiscountsQuery } from './generated';

interface Props extends Omit<DropdownProps, 'options'> {
  config?:EnrollmentConfig;
  enrollments?:string[];
  course?:string;
}

export function EligibleDiscountPicker(props:Props) {
  const {enrollments, course, ...remaining} = props;
  const options = getDiscountOptions();

  function render() {
    return <Dropdown options={options} dropdownWidth={370} {...remaining} />;
  }

  function getDiscountOptions() {
    const [result] = useEligibleDiscountsQuery({variables: compact({enrollments, course})});

    return React.useMemo(() => {
      const usage = props.config?.kind == PriceConfigKind.Usage;
      const discounts = result.data?.eligibleDiscounts?.filter(d => !usage || d.format != DiscountDefinitionFormat.Fixed) || [];
      const codes = discounts.map(d => d.code);
      const options = discounts.map(d => createDiscountOption(d, codes));

      return options;
    }, [result]);
  }

  function createDiscountOption(discount:EligibleDiscountsSelections, codes:string[]) {
    const hasDupes = codes.filter(c => c == discount.code).length > 1;
    const suffix = hasDupes ? ` (${discount.owner.name})` : '';
    const format = discount.format == DiscountDefinitionFormat.Fixed ? formatCurrency : formatPercent;

    return {label: `${discount.code} (${format(discount.amount)})` + suffix, value: discount.id};
  }

  return render();
}
