import { PreferenceStore} from './PreferenceStore'

export class MemoryPreferenceStore implements PreferenceStore {
  private store = new Map<string, any>();

  load<T>(name:string, defaultValue?:T):T {
    return this.store.get(name) || defaultValue;
  }

  save<T>(name:string, value:T) {
    this.store.set(name, value);
  }

  clear(name:string) {
    this.store.delete(name);
  }

  get path():(string | number)[] {
    return [];
  }
}

export const memoryPreferenceStore = new MemoryPreferenceStore();