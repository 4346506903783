import * as React from 'react';

import { CourseKind } from 'app2/api';
import { ActionButton, DataTable, Modal } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { TableCoursesSelections } from '../generated'

interface Props {
  table:DataTable<TableCoursesSelections>;
  kind?:CourseKind;
}

export function DeleteRowAction(props:Props) {
  const {table} = props;
  const behavior = courseKindBehavior[props.kind];
  const label = props.kind == CourseKind.TimeSlot ? 'time slot' : 'activity'

  function render() {
    return <ActionButton icon="Delete" onClick={deleteRows} clickEvent={`Click Delete ${label}`}>{behavior?.terms?.delete || `Delete ${label}`}</ActionButton>
  }

  function deleteRows() {
    const selected = table.selection.rows;
    const hasExistingCourse = selected.findIndex(row => !table.isNewRow(row)) != -1;

    if (hasExistingCourse) {
      Modal.warning({ title: 'Error', content: 'While editing, only newly created activities can be deleted.  Exit edit mode to delete existing activities.' });
      return;
    }

    table.removeSelected();
  }

  return render();
}