import * as React from 'react';

import { colId, DataTableColumn, Dropdown, Link, HBox, Form, Modal, Section, useForm } from 'app2/components';

interface Props {
  cols:Partial<DataTableColumn>[];
}

export function ChooseCols(props:Props) {
  const all = props.cols.map(c => ({label: c.label, value: colId(c)}))
  const allIds = props.cols.map(c => colId(c));
  const visible = props.cols.filter(c => !c.hidden).map(c => colId(c));
  const form = useForm({visible}, [])

  return <Modal title='Show columns'>
    <Form form={form} onNavigation='nothing'>
      <Section name='visible' component={Dropdown} options={all} selectedStyle='checkbox' tags={false} multiple inlinelist list={{makeSelectionVisible:false}} />
      <HBox>
      <Link onClick={() => form.setValue('visible', [])} small mr='$30'>Clear all</Link>
      <Link onClick={() => form.setValue('visible', allIds.slice())} small>Select all</Link>
    </HBox>
    </Form>
  </Modal>
  }
