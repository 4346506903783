import * as React from 'react';

import { CloudinaryLoader } from 'app/scriptLoading';

import { CloudinaryUploader, CloundinaryUploaderOptions, UploadSuccess } from './CloudinaryUploader';

interface Props {
  trigger: React.ComponentElement<any, any>;
  onChange: (imageId: string) => void;
  onUploadSuccess?: (info: UploadSuccess) => void;
  options: Partial<CloundinaryUploaderOptions>;
  imageId?: string;
}

interface State {
  uploader: any;
}

export class CloudinaryUploaderModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uploader: null
    };
  }

  render() {
    return (
      <CloudinaryLoader>
        {React.cloneElement(this.props.trigger, {
          onClick: this.onClickUpload
        })}
      </CloudinaryLoader>
    );
  }

  onClickUpload = (event: React.SyntheticEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const {
      options: {
        signatureUrl,
        customSignatureParams,
        folder,
        uploadPreset,
        croppingAspectRatio,
        cropping,
        showSkipCropButton
      },
    } = this.props;

    // Makes sure the crop/submit button isn't disabled if the image aspect ratio is already correct
    // https://support.cloudinary.com/hc/en-us/community/posts/360042871612/comments/360002173780
    const crop_ratio = croppingAspectRatio + 0.0000001;

    // when uploading a new image, we always change the id and never
    // resuse it because our images can be shared and we dont want to
    // overwrite an image in all places its used, only for the current use
    const publicId = this.props.imageId || Math.random()
      .toString(36)
      .substring(7);

    const customOptions = {
      publicId: publicId,
      signatureUrl: signatureUrl + `?image_name=${publicId}`,
      customSignatureParams: customSignatureParams,
      folder: folder,
      uploadPreset: uploadPreset,
      cropping: cropping,
      croppingAspectRatio: crop_ratio,
      showSkipCropButton: showSkipCropButton,
      croppingShowDimensions: true,
      croppingValidateDimensions: true,
      croppingShowBackButton: true
    };

    const newUploader = new CloudinaryUploader(
      customOptions,
      this.onUploadSuccess,
      this.onUploadError
    );

    newUploader.open();
    this.setState({ uploader: newUploader });
  };

  onUploadSuccess = async (info: UploadSuccess) => {
    const { onUploadSuccess } = this.props;
    if (onUploadSuccess) {
      onUploadSuccess(info);
    }
  };
  onUploadError = (err: any) => {};
}
