import * as React from 'react'
import { useHistory } from 'react-router';

import { Dropdown } from 'app2/components';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { CoursesSeachSelections, useCoursesSeachQuery } from './generated';

interface Props extends SearchDropdownProps<CoursesSeachSelections['accessibleCourses'][0]> {
}

export function CourseSearchDropdown(props:Props) {
  const history = useHistory();
  const [inputText, setInputText] = React.useState('');

  function render() {
    return <SearchDropdown dropdownWidth={400} onKeyDown={onKeyDown} onInputChange={onInputChange} onChange={onChange}
      query={useCoursesSeachQuery as any} resultProp='user.accessibleCourses' renderItem={renderItem} {...props} />
  }

  function renderItem(course:CoursesSeachSelections['accessibleCourses'][0]) {
    return `${course.disambiguatedName} (${course.season.name})`;
  }

  function onInputChange(val:string) {
    setInputText(val);
  }

  function onKeyDown(event:React.KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }

    history.push(`/search?type=activity&term=${inputText}`);
  }

  function onChange(event:React.ChangeEvent<Dropdown>) {
    const id = event.target.value.id;
    return history.push(`/activities/manage/${id}`);
  }

  return render();
}
