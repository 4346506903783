import { Student, DeepPartial } from 'app2/api'
import { FormModel } from 'app2/components';
import { errorPathTransform } from 'app2/views/shared';

export type StudentForm = DeepPartial<Student> & {family?:string};

export async function upsertStudent<T extends StudentForm>(mutation:any, form:FormModel<T>, student:T) {
  const attributes = {
    id: form.values.id,
    firstName: form.values.firstName,
    lastName: form.values.lastName,
    nickname: form.values.nickname,
    dob: form.values.dob,
    grade: form.values.grade,
    classroomId: form.values.classroom?.id,
    medical: form.values.medical,
    notes: form.values.notes,
    family: form.values.family,
    automaticGradeUpdate: form.values.automaticGradeUpdate
  }

  const transform = [errorPathTransform('classroom', 'classroom.id')];
  const [success] = await mutation({ variables:{attributes}, error: { handler: form, transform } });

  return success;
}
