import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentUpsertCfAnswersMutationVariables = Types.Exact<{
  studentId: Types.Scalars['ID'];
  answers: Array<Types.CfAnswerInput> | Types.CfAnswerInput;
}>;

export type StudentUpsertCfAnswersMutation = {
  cfAnswersUpsert?:
    | {
        student?:
          | {
              id: string;
              cfAnswersConfirmed?:
                | Array<{ ownerId?: string | null | undefined; ownerType?: Types.CfOwnerKind | null | undefined; answers?: { [key: string]: any } | null | undefined } | null | undefined>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentUpsertCfAnswersDocument = gql`
  mutation StudentUpsertCfAnswers($studentId: ID!, $answers: [CfAnswerInput!]!) @urql(additionalTypenames: ["EnrollmentsResult"]) {
    cfAnswersUpsert(studentId: $studentId, answers: $answers) {
      student {
        id
        cfAnswersConfirmed {
          ownerId
          ownerType
          answers
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type StudentUpsertCfAnswersOptions = ExecMutationOptions<StudentUpsertCfAnswersMutationVariables>;

export function studentUpsertCfAnswers(options: StudentUpsertCfAnswersOptions) {
  return executeMutation<StudentUpsertCfAnswersMutation, StudentUpsertCfAnswersMutationVariables>(StudentUpsertCfAnswersDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['EnrollmentsResult']) }
  });
}
