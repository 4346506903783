import * as React from 'react'
import { useHistory, useParams, useLocation } from 'react-router';
import { snakeCase, camelCase, pick, uniq } from 'lodash-es';

import { useLifecycle } from '../utils';

import { useFormSubscription } from './useFormSubscription';
import { FormModel } from './FormModel';

interface Options {
  // if you want some parameters to be on the path vs. query params then specify a path
  path?:string;

  // if you want to restrict to a specific set of parameters, specify them here
  params?:string[];

  // automatically synch the form to the url on mount, even if the form is empty - defaults to true
  triggerOnMount?:boolean;

  // automatically watch for form changes and update the url - defaults to true
  watchForm?:boolean;
  // callback when the form changes
  onFormChange?:() => void;
  // callback when the url changes
  onUrlChange?:() => void;
}

// returns a method that can be called to synchronize the form to the url
// combine with watchForm = false if you want to have a save button and 
// not commit the changes to the url until the user clicks save

export function useFormToUrl(form:FormModel, options:Options = {watchForm:true}) {
  const previousUpdate = React.useRef(0);

  const history = useHistory();
  const location = useLocation()
  const params = useParams<any>();
  const queryParams = location.search + location.hash;
  const url = location.pathname + queryParams;
  
  // need to use a ref to track the current path name
  // because our onChange handler is memoized in useFormSubscription
  // and its copy of location is not the current one
  const curPathName = React.useRef('');
  curPathName.current = location.pathname;

  const prevUrl = React.useRef(options.triggerOnMount === false ? url : 'fake url so that we trigger a url change the first time');
  const urlChanged = prevUrl.current != url;
  prevUrl.current = url;

  useLifecycle({onUpdate});
  useFormSubscription({form, onChange:onFormChange});

  function onUpdate() {
    if (!urlChanged) {
      return;
    }

    onUrlChange();
  }

  function onUrlChange() {
    urlToForm();
    options.onUrlChange?.()
  }

  function onFormChange() {
    if (options.watchForm === false) {
      return;
    }

    formToLocation();
    options.onFormChange?.();
  }

  function urlToForm() {
    const urlParams = new URLSearchParams(queryParams);
    let values = urlParamsToObject(urlParams);
    
    if (options.path) {
      const paramNames = getParamNames(options.path);
      paramNames.forEach(name => {
        values[name] = params[name] || '';
      })
    }

    if (options.params) {
      values = pick(values, options.params);

      // merge with existing form values so that we don't lose any when
      // only putting some values on the url
      values = {...form.values, ...values};
  }

    form.reset({values});
  }

  function formToUrl(overrides?:any) {
    const params = new URLSearchParams();

    let path = options.path || curPathName.current;
    const paramNames = options.params || getParamNames(path);

    let values = {...form.values};

    if (options.params?.length) {
      values = pick(values, options.params);
    }

    paramNames?.forEach(name => {
      path = path.replace(':' + name, values[name] || '');
      delete values[name];
    });

    objToUrlParams(values, params, overrides);
    
    // use window.location.hash over location.hash because it can be stale
    const hash = window.location.hash.length > 1 ? window.location.hash : '';
    const url = path + '?' + params.toString() + hash;

    return url;
  }

  function formToLocation() {
    const url = formToUrl();
    const push = Date.now() - previousUpdate.current > 2000;
    previousUpdate.current = Date.now();

    if (push) {
      history.push(url);
    }
    else {
      history.replace(url);
    }
  }

  return {formToUrl, formToLocation};
}

function getParamNames(path:string) {
  return (path.match(/\:[a-zA-Z0-9\_\-]+/g) || []).map(n => n.substring(1));
}

// URLSearchParams doesn't handle arrays properly.  

// for going to url params, it will turn an array
// into a comma separated string which will get
// turned into a single string properly when converting back.
// note this only works with a flat object as there's
// no standard url syntax that can handle nesting.
// also, since true/false isn't really handled correctly 
// they stay as strings when converting back, this
// will drop false values

export function objToUrlParams(o:any, params:URLSearchParams, overrides?:any):void {
  let pairs = Object.entries(o);

  if (overrides) {
    pairs = pairs.concat(Object.entries(overrides));
  }

  pairs.forEach(([key, value]) => {
    if (value === false || value === null || value === undefined || value === '') {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach(arrayValue => params.append(snakeCase(key), arrayValue));
    }
    else {
      params.append(snakeCase(key), value.toString())
    }
  });
}

// for going to an object, it will only take the first entry
// and ignore all the rest rather than turning them all into an array

export function urlParamsToObject(params:URLSearchParams):any {
  const o:any = {};

  params.forEach((_:string, key:string) => {
    const values = params.getAll(key);
    o[camelCase(key)] = values.length == 1 ? values[0] : values;
  });

  return o;
}