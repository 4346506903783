import * as React from 'react';

import { Panel, Section, Input, Info, Button, NotificationType, NotificationManager } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function InfoDemos() {
  return <Demos name='Info & Notification'>
    <Demo name='Info, manual usage'
      description={`
        An Info can be used anywhere, but are built into to some components such as in Modals.
      `}>
      <Panel icon='Clipboard' title='Panel title'>
        <Info type='success'>test</Info>
        <Info type='warning'>test</Info>
        <Info type='error'>test</Info>
        <Info type='error'>{['multiple lines 1', 'multiple lines 2']}</Info>
      </Panel>
    </Demo>

    <Demo name='Info, automatic usage'
      description={`
        Click ok on the form.  The ok handler throws an error which the form displays.
      `}>
      <Panel type='edit' icon='Clipboard' title='Panel title' onOk={onOk}>
        <Section label='Name' name='name' component={Input} />
      </Panel>
    </Demo>

    <Demo name='Notification'
      description={`
        To show a notification, use \`NotificationManager.add\`.
      `}>
      <Button onClick={() => NotificationManager.add({
        type:['success', 'warning', 'error'][Math.round(Math.random() * 3) % 3] as NotificationType, 
        message: 'test' + Math.ceil(Math.random() * 999)})}>Show notification</Button>
      <Button onClick={() => NotificationManager.removeAll()}>Remove notifications</Button>
    </Demo>
  </Demos>
}

async function onOk() {
  throw new Error('test');
}
