import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertSiteContactsMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  contacts: Array<Types.ContactInput> | Types.ContactInput;
}>;

export type OrganizerUpsertSiteContactsMutation = { siteUpsertContacts?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpsertSiteContactsDocument = gql`
  mutation OrganizerUpsertSiteContacts($siteId: ID!, $contacts: [ContactInput!]!) {
    siteUpsertContacts(site: $siteId, contacts: $contacts) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertSiteContactsOptions = ExecMutationOptions<OrganizerUpsertSiteContactsMutationVariables>;

export function organizerUpsertSiteContacts(options: OrganizerUpsertSiteContactsOptions) {
  return executeMutation<OrganizerUpsertSiteContactsMutation, OrganizerUpsertSiteContactsMutationVariables>(OrganizerUpsertSiteContactsDocument, options);
}
