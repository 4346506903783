import {
  createParser,
  css as styledSystemCss,
  SystemStyleObject
} from '../styled-system';

import { breakpointValue } from '../breakpoints/breakpointValue';

// system-components has a way to:
//  - create a new property that maps to specific css property
//  - create a new property that maps to multiple css properties with the same value
//  - create a property with variations
//  - add css but no way to handle array properties (that turn into breakpoints)
//
// but it has no way to create new properties with custom css that can handle
// array properties that turn into breakpoints.  this uses lower level styled
// system functions to create a way to do this.
//
// you pass in your property name, and a function that returns a styled
// system css object.  your function receives the value of the property.
// if the using code passes an array your function is called for each value
// in the array to create the breakpoint.  your function can optionally
// receive all of the component props.
//
// important - the returned props does not map breakpoint array properties
// unless you pass in which properties you want mapped (due to a lack
// of proper design in styled system) as a third parameter.
//
// usage:

// interface MyProps {
//   myProp:string;
// }

// const myProperty = property('myProp', (value:string, props:MyProps) => {
//   return {
//    color: value,
//    '*': {
//     color: value
//   }
// }})
//
// for performance reasons you should use styled system's compose function
// to compose this result with other styled system created properties, variants, css, etc.

export function property<T = any>(propName: string, styles: (value: any, props?: T, breakpoint?: number) => SystemStyleObject, dependentProperties?: (keyof T)[]) {
  let sx = (value: any, scale: any, props: any, breakpoint: number) => {
    if (breakpoint === undefined) {
      breakpoint = 2;
    }

    if (value === undefined) {
      return value;
    }

    if (dependentProperties) {
      props = Object.assign({}, props);

      dependentProperties.forEach(prop => {
        const value = props[prop];
        props[prop] = breakpointValue(value, breakpoint);
      });
    }

    return styledSystemCss(styles(value, props, breakpoint))(props.theme);
  };

  const config = {
    [propName]: sx
  };

  const parser = createParser(config);
  return parser;
}
