import * as React from 'react';

import { CourseKind } from 'app2/api';
import { Button, DateField, Field, Panel, RepeatingSection } from 'app2/components';
import { GradeField } from 'app2/views/shared-public';

import { ParentStudentLink, ParentStudentDetails, useFamilyQuery } from 'app2/views/parent/shared';

import { useFamilyStudentsQuery } from './generated';

export function Students() {
  const {family, id} = useFamilyQuery(useFamilyStudentsQuery);

  function render() {
    return <Panel icon='Users' title='Students' values={family} primaryActions={<Button iconPosition='left' icon='Plus' onClick={<ParentStudentDetails purpose='add' family={id} courseKinds={[CourseKind.Enrichment]} />}>Add student</Button>}>
      <RepeatingSection name='students' buttonCols={1} fields={[
        <Field label='Name' name='name' component={<ParentStudentLink />} />,
        <Field label='Date of birth' name='dob' component={DateField} />,
        <Field label='Grade' name='grade' component={GradeField} />,
        <Field label='Health & comments' name='formattedMedicalAndNotes' />,
        <Field label='Name' name='name' component={<ParentStudentLink edit display={false} />} />,
        ]}/>
    </Panel>
  }

  return render();
}
