import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentStudentUpsertCfAnswersMutationVariables = Types.Exact<{
  studentId: Types.Scalars['ID'];
  answers: Array<Types.CfAnswerInput> | Types.CfAnswerInput;
}>;

export type ParentStudentUpsertCfAnswersMutation = {
  cfAnswersUpsert?:
    | {
        student?:
          | {
              id: string;
              cfAnswers?: Array<{ ownerId?: string | null | undefined; ownerType?: Types.CfOwnerKind | null | undefined; answers?: { [key: string]: any } | null | undefined } | null | undefined> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentStudentUpsertCfAnswersDocument = gql`
  mutation ParentStudentUpsertCfAnswers($studentId: ID!, $answers: [CfAnswerInput!]!) {
    cfAnswersUpsert(studentId: $studentId, answers: $answers) {
      student {
        id
        cfAnswers {
          ownerId
          ownerType
          answers
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentStudentUpsertCfAnswersOptions = ExecMutationOptions<ParentStudentUpsertCfAnswersMutationVariables>;

export function parentStudentUpsertCfAnswers(options: ParentStudentUpsertCfAnswersOptions) {
  return executeMutation<ParentStudentUpsertCfAnswersMutation, ParentStudentUpsertCfAnswersMutationVariables>(ParentStudentUpsertCfAnswersDocument, options);
}
