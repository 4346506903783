import moment from 'moment-timezone';

import { DateInput } from '../date-utils';
import { compareTimes, TimeParts } from '../TimePicker';

import { FieldInfo } from '.';

// when a pair of fields are related such that one represents
// a start date and the other represents an end date, this
// returns a change handler that ensures that the end date
// is always same or after the start date.  this makes it
// easier to select the end date (its less clicking you have 
// to move the end date ahead).  
//
// the date picker does this automatically when both the 
// start and end are stored in a single field, but not when
// they are separate.

export function relatedEndDate<T = any, P extends keyof T = keyof T>(end:P, onlyIfBlank?:boolean) {
  return function(start:DateInput, info:FieldInfo<T>) {
    if (!start) {
      return;
    }

    const curEnd = info.form.getValue<P>([], end);

    if (!curEnd || (!onlyIfBlank && moment(curEnd).isBefore(start))) {
      info.form.setValue([], end, start as unknown as T[P]);
    }
  }
}

// ditto above but for time

export function relatedEndTime<T = any, P extends (keyof T) = keyof T>(end:P, onlyIfBlank?:boolean) {
  return function(start:(TimeParts | string), info:FieldInfo<T>) {
    if (!start) {
      return;
    }

    const curEnd = info.form.getValue<P>([], end) as unknown as (TimeParts | string);

    if (!curEnd || (!onlyIfBlank && compareTimes(curEnd, start) < 0)) {
      info.form.setValue([], end, start as unknown as T[P]);
    }
  }
}

// helper method that creates a change function that will clear a field
// whenever another field is set.  this is useful when you have two+ fields
// that are mutually exclusive, and you want to ensure that only one is set

export function clearOther<T = any, P extends keyof T = keyof T>(clearOthers:P[]) {
  return function(value:any, info:FieldInfo<T>) {
    if (value === undefined || value === null) {
      return;
    }

    for (const other of clearOthers) {
      const value = info.form.values[other];

      if (value !== undefined && value !== null) {
        info.form.setValue(other, null);
      }
    }
  }
}
