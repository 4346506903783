import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { MessageFieldsFragmentDoc } from './messageFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MessageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type MessageQuery = {
  msg?:
    | {
        id: string;
        threadId?: string | null | undefined;
        threadScope?: string | null | undefined;
        createdAt: string;
        preferredService?: Types.MessageServiceKind | null | undefined;
        systemMessage?: boolean | null | undefined;
        broadcast?: boolean | null | undefined;
        read?: boolean | null | undefined;
        subject?: string | null | undefined;
        inReplyTo?: { id: string } | null | undefined;
        from?:
          | { role?: string | null | undefined; from: { __typename: 'SharedUser'; id: string; name?: string | null | undefined }; students?: Array<{ id: string; name?: string | null | undefined }> | null | undefined }
          | null
          | undefined;
        to?:
          | Array<{
              smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              role?: string | null | undefined;
              to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
              toEntity?: { name?: string | null | undefined } | null | undefined;
              students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
        cc?:
          | Array<{
              smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              role?: string | null | undefined;
              to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
              toEntity?: { name?: string | null | undefined } | null | undefined;
              students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
        bcc?:
          | Array<{
              smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
              role?: string | null | undefined;
              to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
              toEntity?: { name?: string | null | undefined } | null | undefined;
              students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
        labels?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
        body?: { text?: string | null | undefined; html?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const MessageDocument = gql`
  query Message($id: ID!) {
    msg(message: $id) {
      inReplyTo {
        id
      }
      ...messageFields
    }
  }
  ${MessageFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type MessageArgs = MakeOptional<UseQueryArgs<MessageQueryVariables, MessageQuery>, 'query'>;

export function useMessageQuery(options: MessageArgs = {}) {
  return useQuery<MessageQueryVariables, MessageQuery>({ query: MessageDocument, ...options });
}

export type MessageOptions = ExecQueryOptions<MessageQueryVariables>;

export function message(options: MessageOptions) {
  return executeQuery<MessageQuery, MessageQueryVariables>(MessageDocument, options);
}

export type MessageSelections = MessageQuery['msg'];
