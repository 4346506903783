import * as React from 'react';

import { ReportTransaction } from 'app2/api';
import { HBox, BoxProps, theme, FieldInfo, FieldValue } from 'app2/components';

type Status = 'Pending' | 'Processing' | 'Deposited';

interface Props extends BoxProps {
  status: Status;
  info?:FieldInfo<ReportTransaction, 'companyExternalTransferStatus' | 'siteExternalTransferStatus'>;
}

export function PayoutStatusTag(props: Props) {
  const { status:statusProp, info, ...remaining } = props;
  const status = statusProp || info?.value as Status
  const statusProps = statuses[status];

  if (!statusProps) {
    return <FieldValue>{status}</FieldValue>;
  }

  return <HBox bg={statusProps.bg} color={statusProps.color}  tooltip={statusProps.tooltip} text="formlabel" borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} width="fit-content" cursor='default'>
    {statusProps.label}
  </HBox>
}

PayoutStatusTag.fieldProps = {
  valueProperty: 'status',
  fieldProperty: 'info',
};

export const statuses = {
  'Pending': { bg: theme.colors.disabledBackground, color: undefined as string, label: 'Pending', tooltip: "The payout is being held per the seasons payout settings" },
  'Processing': { bg: theme.colors.warningFill, color: undefined as string, label: 'Processing', tooltip: 'The payout has been sent to Stripe but is not yet in your bank account' },
  'Deposited': { bg: theme.colors.successFill, color: undefined as string, label: 'Deposited', tooltip: 'The payout has been deposited in your account' },
};
