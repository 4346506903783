import * as React from 'react'
import moment from 'moment-timezone';

import { FieldValueProps } from './form';
import { DateText } from './DateText';
import { LONG_DATE_FORMAT } from './form/formatters';//specific import due to circular import issue
import { DateInput } from './date-utils';

interface DateRangeTextProps extends FieldValueProps {
  dateFormat?:string;
  timezone?:string;
  from:DateInput;
  to:DateInput;
}

export function DateRangeText(props:DateRangeTextProps) {
  const { from, to, dateFormat, timezone, ...remaining} = props;
  
  const sameDay = moment(from).isSame(to, 'd');
  const sameYear = moment(from).isSame(to, 'y');

  return <>
    <DateText value={from} dateFormat={sameYear ? 'MMM D' : LONG_DATE_FORMAT} bold readOnly whiteSpace='nowrap' color='primary' {...remaining} />{!sameDay && <>&nbsp;to&nbsp;<DateText value={to} bold readOnly whiteSpace='nowrap' color='primary' {...remaining} /></>}
  </>
}
