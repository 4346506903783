import * as React from 'react'
import { useHistory, Redirect as RRRedirect, RedirectProps, Route, generatePath, matchPath } from 'react-router';

// A react router redirect that actually passes along the 
// query parameter in the url.  RR normally drops this.
// https://github.com/remix-run/react-router/issues/6892

// also ensures paramaters are filled in, including a trailing wildcard

interface Props extends Omit<RedirectProps, 'to'> {
  to?:string;
}

export function Redirect(props:Props) {
  const history = useHistory();
  let pathname = props.to || props.path;

  function render() {
    return pathname.startsWith('http')
      ? renderExternal()
      : renderRegular();
  }

  function renderExternal() {
    return <Route component={redirect} />;
  }

  function redirect():React.ReactElement {
    window.location.href = pathname;
    return null;
  }

  function renderRegular() {
    if (props.from?.endsWith('*')) {
      pathname = history.location.pathname.replace(new RegExp(props.from, 'i'), pathname);
    }

    const m = matchPath(history.location.pathname, props.from);
    
    if (m) {
      pathname = generatePath(pathname, m.params);
    }

    return <RRRedirect {...props} to={{pathname, state: history.location.state, search: history.location.search, hash: history.location.hash}} />
  }

  return render();
}
