import * as React from 'react';
import { useParams } from 'react-router';

import { Field, FormModel, InputField, Panel, RepeatingSection, PercentField, useForm, NumberInput } from 'app2/components';
import { addDeletedItems } from 'app2/views';

import { SiteRouteParams } from '../organizerRoutes';

import { OrganizerSitePriceTiersSelections, organizerUpsertPriceTiers, useOrganizerSitePriceTiersQuery } from './gql';

type FormValues = OrganizerSitePriceTiersSelections;

export function PriceTiers() {
  const { site: siteId } = useParams<SiteRouteParams>();
  const [result, reeexecutePriceTiersQuery] = useOrganizerSitePriceTiersQuery({ variables: { siteId }, context: React.useMemo(() => ({ additionalTypenames: ['OrganizerPriceTier'] }), []) });
  const priceTiers = result.data?.site?.priceTiers;
  const form = useForm<FormValues>({ priceTiers }, [priceTiers]);
  const defaultRecord = { name: '', rate: 0, displayOrder: (form.values.priceTiers?.length || 0) + 1 };

  return (
    <Panel icon="DollarSign" title="Price tiers" onOk={onOk} type="toggle" form={form}>
      <RepeatingSection
        name="priceTiers"
        add="Add price tier"
        defaultRecord={defaultRecord}
        fields={[
          <Field label="Name" name="name" required component={InputField} />,
          <Field label="Percent off" name="rate" required component={PercentField} />,
          <Field label="Display order" name="displayOrder" required edit={<NumberInput min={1} max={form.values.priceTiers?.length} />} />,
          'remove'
        ]}
      />
    </Panel>
  );

  async function onOk(form: FormModel) {
    const updatedTiers = addDeletedItems(priceTiers, form.values.priceTiers, 'archive');
    const [success] = await organizerUpsertPriceTiers({ variables: { ownerId: siteId, priceTiers: updatedTiers }, error:form });

    if (success) {
      reeexecutePriceTiersQuery();
    }

    return success;
  }
}
