import { getScrollParent } from "./getScrollParent";

// watchs a scrollable for scroll events, and resize events
// that resize the scrollable area.

export interface ScrollWatcherOptions {
  onScroll:() => void;
  onResize?:() => void;
  checkScrollableSize?:boolean;
  capture?:boolean;
}

export class ScrollWatcher {
  element:HTMLElement;
  scroller:HTMLElement;
  resizeObserver:ResizeObserver;
  onScroll:() => void;
  onResize:() => void;
  checkScrollableSize:boolean;
  capture:boolean;

  constructor(options:ScrollWatcherOptions) {
    this.onScroll = options.onScroll;
    this.onResize = options.onResize || options.onScroll;
    this.checkScrollableSize = options.checkScrollableSize;
    this.capture = options.capture;
  }

  get scrollEventOwner() {
    return this.scroller == document.documentElement ? document : this.scroller;
  }

  watch(element:HTMLElement) {
    if (this.element == element) {
      return;
    }

    this.unwatch();
    this.element = element;

    if (this.element) {
      this.scroller = getScrollParent(element, this.checkScrollableSize)
      this.scrollEventOwner.addEventListener('scroll', this.onScroll, this.capture);

      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.scroller);
    }
  }

  unwatch() {
    if (this.scroller) {
      this.element = null;

      this.scrollEventOwner.removeEventListener('scroll', this.onScroll, this.capture);
      this.scroller = null;

      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }
}