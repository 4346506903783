import { localStorageHelper } from 'app/helpers/localStorageHelper';

import { IdleDetector } from './IdleDetector';

export class IdleReloader {
  static TWELVE_HOURS = 1000 * 60 * 60 * 12;
  static FOUR_HOURS = 1000 * 60 * 60 * 4;
  static DURATION_BETWEEN_RELOADS = IdleReloader.FOUR_HOURS;
  static LAST_IDLE_RELOAD = 'last_idle_reload';

  idleDetector: IdleDetector;

  constructor() {
    this.idleDetector = new IdleDetector(this.onIdle);
  }

  // used by e2e tests
  stop() {
    this.idleDetector.stop();
  }

  onIdle = () => {
    const now = Date.now();
    const lastReload = parseInt(
      localStorageHelper.getItem(IdleReloader.LAST_IDLE_RELOAD) || '0'
    );
    const timeSinceLastReload = now - lastReload;
    const shouldReload =
      timeSinceLastReload >= IdleReloader.DURATION_BETWEEN_RELOADS;

    if (shouldReload) {
      localStorageHelper.setItem(IdleReloader.LAST_IDLE_RELOAD, now.toString());
      window.location.reload();
    }
  };
}
