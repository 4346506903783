import { DateInput, compareDates as compareDatesUtil, compareDateArrays as compareDateArraysUtil } from '../date-utils'

export function compareDateArrays(dates1:DateInput[], dates2:DateInput[], granularity?: moment.unitOfTime.StartOf) {
  return compareDateArraysUtil(dates1, dates2, startYear, endYear, granularity);
}

export function compareDates(date1:DateInput, date2:DateInput, granularity?: moment.unitOfTime.StartOf) {
  return compareDatesUtil(date1, date2, startYear, endYear, granularity);
}

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
].map((month, index) => {return {value: index, label: month}});

export const startYear = 1900;
export const endYear = 2100;
export const years = Array(endYear - startYear).fill(0).map((_, index) => {return {value: startYear + index, label: startYear + index + ''}});
