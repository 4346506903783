// Typically  React apps rely on:
// - rendering of new props as a way of communuicating to components to rerender
// - use immutable data structures as a way of generating new props
//
// However:
// - cloning a large array structure isn't ideal
// - getting a new collection doesn't tell you what changed
// - not knowing what changed means either doing a diff to try and determine what changed
// - or just rerendering everything which often might not be ideal

import { CollectionEvent } from "./CollectionEvent";

// So ObservableCollection is an interface to help communicate to the table what changed

export interface ObservableCollection<T> {
  length:number;
  getItem(position:number):T;
  getValue(position:number, property:string):any;

  // most methods operate on rows as an index however
  // for some usages are better of identifying things
  // based on a persistable id that can't change if the
  // collection is reordered.
  getId?(position:number): number | string;
  getIndex?(id:number | string): number;

  observe?(observer:ObservableCollectionObserver<T>):void;
  unobserve?(observer:ObservableCollectionObserver<T>):void;

  setValue?(position:number, property:string, value:any):void;
  insert?(position:number, item:T):void;
  remove?(position:number):T;
}

export type ObservableCollectionObserver<T> = (event:CollectionEvent<T>) => void;

export function collectionFind<T>(collection:ObservableCollection<T>, cb:(item:T) => boolean):number {
  for (let i = 0; i < collection.length; i++) {
    if (cb(collection.getItem(i))) {
      return i;
    }
  }

  return -1;
}

export function collectionToArray<T>(collection:ObservableCollection<T>) {
  const items:T[] = [];
  for (let i = 0; i < collection.length; i++) {
    items.push(collection.getItem(i));
  }

  return items;
}
