import * as React from 'react'

export interface LifecycleMethods {
  onMount?:() => void;
  onUnmount?:() => void;
  onUpdate?:() => void;
}

// the dependencies are only used for the effect for update
// you should call this method with the same configuration each time
// same method types and consistently has or doesn't have dependencies
// similiar to userEffect - method and dependency changes get ignored
// if you pass in differ method configurations, you will get a
// react warning that hooks are not being called in the same way
// for each render.

// returns a ref for if component is mounted

export function useLifecycle(methods:LifecycleMethods = {}, dependencies:any[] = undefined) {
  // normally you are not suppose to use hooks in if statements
  // because hooks have to be used consistently throughout the lifetime
  // of a component, but which methods are assigned should not ever change
  // through out the lifetime of the component so the if statement should be
  // ok, and you'll get an error otherwise
  const mounted = React.useRef(true);

  React.useEffect(() => {
    methods.onMount?.();

    return onUnmount;
  }, []);

  function onUnmount() {
    mounted.current = false;
    methods.onUnmount?.();
  }

  if (methods.onUpdate) {
    React.useEffect(() => {
      methods.onUpdate?.();
    }, dependencies);
  }

  return mounted;
}
