import * as React from 'react'

import { Student, StudentUtils, caselessEqual } from 'app2/api';
import { SearchDropdown, SearchDropdownProps } from 'app2/views/shared';
import { findQueryOption } from 'app2/views/shared-public';

import { students, useStudentsQuery, StudentsSelections } from './generated';

export interface StudentDropdownProps extends SearchDropdownProps<StudentsSelections> {
  site?:string;
  company?:string;
  entityKind?:string;
  entityId?:string;
  // excludes students that are enrolled in the specified course
  excludedCourses?:string[];
}

export function StudentDropdown(props:StudentDropdownProps) {
  const {site, company, entityKind, entityId, excludedCourses, ...remaining} = props;

  function render() {
    return <SearchDropdown query={useStudentsQuery} queryVars={{...entityPropsToSiteOrCompany(props), excludedCourses: props.excludedCourses}} renderItem={StudentUtils.getStudentName} {...remaining} />
  }

  return render();
}

StudentDropdown.copy = StudentUtils.getStudentName;

StudentDropdown.find = async function(student:string | Partial<Student>, props:StudentDropdownProps):Promise<Partial<Student>> {
  if (typeof student == 'object') {
    return student.id ? student : null;
  }

  student = student.trim();
  
  // the server doesn't support searching last, first
  if (student.indexOf(',') != -1) {
    const [last, first] = student.split(',').map(s => s.trim());
    student = `${first} ${last}`
  }

  const vars = entityPropsToSiteOrCompany(props);
  return findQueryOption(students, {...vars, term: student}, student, compareStudentNames);
}

function compareStudentNames(pasted:string, student:Partial<Student>) {
  // getStudentName sometimes adds in nickname and we also want to support
  // finding by first last
  return caselessEqual(pasted, StudentUtils.getStudentName(student as any)) ||
    (student.name && caselessEqual(pasted, student.name)) || 
    (student.firstName && student.lastName && caselessEqual(pasted, `${student.firstName} ${student.lastName}`))
}

function entityPropsToSiteOrCompany(props:StudentDropdownProps) {
  if (props.entityKind?.toLocaleLowerCase() == 'site') {
    return {site: props.entityId}
  }

  if (props.entityKind?.toLocaleLowerCase() == 'company') {
    return {company: props.entityId}
  }

  return {site: props.site, company: props.company};
}
