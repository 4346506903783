import { SystemStyleObject } from '../../styled-system';
import { theme } from '../../theme';
import { maxLinesStyles } from '../../Text';
import { Rect } from '../../dom-utils';

const px = 11;
const py = 10.5;

const gap = 1;
const border = 1;

// limits standard labels to two lines and put in ellipses
// when they are longer, except if it has the focus, then
// we allow it to be its full size.  for custom components,
// such as course days and course status, they will not use this 
// and will just get clipped by the cell container with no ellipses

export const cellCss:SystemStyleObject = {
  // for most text links
  ['div:not(.hr-table-cell-focus, .hr-measuring) >  .hr-value > a,' +
  // for most text values
  'div:not(.hr-table-cell-focus, .hr-measuring) >   .hr-value']: maxLinesStyles(2, undefined, 'body'),
  // for course days, 8 is the margin between course days
  'div:not(.hr-table-cell-focus, .hr-measuring) > ol.hr-value': maxLinesStyles(2, undefined, 'body', 8),
  '.hr-table-cell': {
    padding: `${gap + border + py}px ${gap + border + px}px ${gap + border + py}px ${gap + border + px}px`,
    display: 'flex', 
    alignItems: 'center',
    '::before': {
      background: '#fff',
      zIndex:-1
    }
  },
  '.hr-table-cell-disabled': {
    '::before': {
      background: theme.colors.disabledBackground,
      zIndex:-1
    }
  },
  '.hr-table-cell-readonly': {
    '::before': {
      content: '""',
      position: 'absolute',
      borderTop: `solid 1px ${theme.colors.border}`,
      borderBottom: `solid 1px ${theme.colors.border}`,
      left: 0,
      top: '1px',
      right: 0,
      bottom: '1px'
    }
  },
  '.hr-table-cell-editable': {
    '::before': {
      content: '""',
      position: 'absolute',
      border: `solid 1px ${theme.colors.border}`,
      borderRadius: '4px',
      left: '1px',
      top: '1px',
      right: '1px',
      bottom: '1px'
    },
  },
  '.hr-table-cell-editable.hr-table-first-col': {
    '::before': {
      borderLeft: 'none',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    }
  },
  '.hr-table-cell-readonly.hr-table-row-error': {
    '::after': {
      content: '""',
      position: 'absolute',
      borderTop: `solid 1px ${theme.colors.error}`,
      borderBottom: `solid 1px ${theme.colors.error}`,
      left: 0,
      top: '1px',
      right: 0,
      bottom: '1px',
      zIndex:10,
      pointerEvents: 'none',
    }
  },
  '.hr-table-cell-error': {
    '::after': {
      content: '""',
      position: 'absolute',
      border: `solid 1px ${theme.colors.error}`,
      borderRadius: '4px',
      left: 1,
      top: 1,
      right: 1,
      bottom: 1,
      zIndex:10,
      pointerEvents: 'none',
    }
  },
  '.hr-table-page-break': {
    borderLeft: `dashed 1px black`,
  }
}

// we render the cells smaller than their actual size because the design calls
// for the look of a component inside a cell.  these methods help take the
// normal sized cell and adjust them to the dimensions of what will actually be rendered.

export function adjustRectToRendered(r:Rect) {
  const adjustments = {left:gap, top:gap, right:gap, bottom:gap};

  return r.clone().inflate(-adjustments.left, -adjustments.top, -adjustments.right, -adjustments.bottom);
}
