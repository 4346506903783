import { dispatchChangeEvent } from './dispatchChangeEvent';

// for use with textarea and input

export function replaceSelectedText(element:HTMLInputElement | HTMLTextAreaElement, text:string, changeHandler:any) {
  let {value, selectionStart, selectionEnd} = element;
  value = value.substr(0, selectionStart) + text + value.substring(selectionEnd);
  element.value = value;
  element.selectionStart = element.selectionEnd = selectionStart + text.length;

  dispatchChangeEvent(element, value, changeHandler);
}
