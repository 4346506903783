import { scriptManager } from 'app/scriptLoading/ScriptManager';

import { Beacon } from './Beacon';
import { BeaconShim, applyShimCalls } from './BeaconShim';

declare global {
  interface Window {
    Beacon: Beacon;
  }
}

export function loadHelpScout() {
  window.Beacon = BeaconShim;

  scriptManager.register(helpScoutTextScript, false, onLoadHelpScout);
}

function onLoadHelpScout() {
  window.Beacon('init', '213ab36f-1032-4257-994e-1395a191c9cb');
  window.Beacon('config', {display: {style: "manual"}});

  applyShimCalls();
}

const helpScoutTextScript = `! function(e, t, n) {
  function a() {
    var e = t.getElementsByTagName("script")[0],
      n = t.createElement("script");
    n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
  }
  if (e.Beacon = n = function(t, n, a) {
      e.Beacon.readyQueue.push({
        method: t,
        options: n,
        data: a
      })
    }, n.readyQueue = [], "complete" === t.readyState) return a();
  e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
}(window, document, window.Beacon || function() {});`;
