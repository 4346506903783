import * as React from 'react'

import { Button, ButtonProps } from './Button'
import { Link } from './Link';
import { Menu, MenuItem } from './menu';
import { Popup } from './Popup';
import { convertElements } from './utils';

interface Props extends ButtonProps {
  menu:React.ReactNode[];
}

export function ButtonMenu(props:Props) {
  const {menu, ...remaining} = props;
  const updated = convertElements(menu, Link, MenuItem);

  return <Popup trigger={<Button {...remaining} />}>
      <Menu tabIndex={0}>{updated}</Menu>
  </Popup>
}