import React from 'react'
import { captureException } from 'app/helpers'

class ErrorBoundary extends React.Component {
  componentDidCatch(err, errInfo) {
    captureException({ err, errInfo })
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
