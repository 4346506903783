import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteCreateAdjustmentMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  amount: Types.Scalars['Float'];
  description: Types.Scalars['String'];
}>;

export type AdminSiteCreateAdjustmentMutation = { siteCreateAdjustment?: { id: string } | { id: string } | null | undefined };

export const AdminSiteCreateAdjustmentDocument = gql`
  mutation AdminSiteCreateAdjustment($site: ID!, $amount: Float!, $description: String!) {
    siteCreateAdjustment(site: $site, amount: $amount, description: $description) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSiteCreateAdjustmentOptions = ExecMutationOptions<AdminSiteCreateAdjustmentMutationVariables>;

export function adminSiteCreateAdjustment(options: AdminSiteCreateAdjustmentOptions) {
  return executeMutation<AdminSiteCreateAdjustmentMutation, AdminSiteCreateAdjustmentMutationVariables>(AdminSiteCreateAdjustmentDocument, options);
}
