import * as React from 'react'

import { MouseCapture, captureMouse, Point } from '../../dom-utils';
import { theme } from '../../theme'
import { TableSection } from '../../virtualized';

import { DataTable } from '../DataTable'

interface Props {
  table:DataTable;
  section:TableSection;
  resizeTolerance:number;
  minColSize:number;
}

interface State {
  activeCol:number;
  activeColX:number;
  mouseDown:boolean;
}

export class ColResizeManager extends React.Component<Props, State> {
  static defaultProps = {
    resizeTolerance: 10,
    minColSize: 20
  }

  state:State = {
    activeCol: -1,
    activeColX: 0,
    mouseDown: false
  }

  ref = React.createRef<HTMLDivElement>();
  mouseCapture:MouseCapture;
  startLeft:number;
  startColWidth:number;

  get table():DataTable {
    return this.props.table;
  }

  render() {
    const cursor = this.state.activeCol != -1 && (this.mouseCapture || !this.state.mouseDown) ? 'ew-resize' : undefined;

    return <div ref={this.ref} onMouseDown={this.onMouseDown} onMouseMove={this.onMouseMoveOrUp} onMouseUp={this.onMouseMoveOrUp} onMouseLeave={this.onMouseLeave} style={{cursor}}>
      <div style={{position:'absolute', zIndex:40, left: this.state.activeColX + 'px', top:0, bottom:0, border:`solid .75px ${theme.colors.border}`, width: '1px', pointerEvents: 'none', opacity: this.state.activeCol != -1 ? 1 : 0, transition: '.25s opacity'}} />
      {this.props.children}
    </div>
  }

  onMouseMoveOrUp = (event:React.MouseEvent<HTMLDivElement>) => {
    const mouseDown = (event.buttons & 1) == 1;

    if (this.state.mouseDown != mouseDown) {
      this.setState({mouseDown});
    }
    else
    if (mouseDown) {
      return;
    }

    if (!this.table.props.colResize) {
      return;
    }

    if (this.mouseCapture) {
      return;
    }

    const resizeCol = this.findResizeCol(event);

    if (this.state.activeCol != resizeCol) {
      this.setState({activeCol: resizeCol, activeColX: resizeCol == -1 ? this.state.activeColX : this.getResizeX(resizeCol)});
    }
  }

  onMouseDown = (event:React.MouseEvent<HTMLDivElement>) => {
    if (this.state.activeCol == -1) {
      return;
    }

    if (!this.table.rootElement.contains(event.target as HTMLElement)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({mouseDown: true});
    this.mouseCapture = captureMouse(this.onDragMouseMove, this.onDragMouseUp, event, this.table.rootElement);
  }

  onMouseLeave = (event:React.MouseEvent<HTMLDivElement>) => {
    if (this.state.activeCol == -1 || this.mouseCapture) {
      return;
    }

    this.setState({activeCol: -1});
  }

  onDragMouseMove = (event:MouseEvent) => {
    const table = this.table;
    const resizeColPos = this.state.activeCol - table.rowHeaderCount;
    const resizeCol = table.cols[resizeColPos];
    const curColWidth = resizeCol.width || table.defaultColWidth;
    const colLeft = this.getColLeft(this.state.activeCol);

    this.state.activeColX = this.getResizeX(this.state.activeCol);

    // when we resize, we calculate the target size based on the 
    // column size that we start with, not the current column size.
    // this is because since the user can result multiple columns
    // at once, and resize on in the middle of the selection, the
    // resizing columns left can move, as well as the width.  
    // we want the col moving and resizing to not affect the resize
    // behavior (else as the col moves and resizes, its position 
    // relative to the mouse will keep changing with each resize
    // causing sizing to bounce back and forth between growing and
    // shrinking).  using the original left and width avoids that.
    
    if (!this.startLeft) {
      this.startLeft = colLeft;
      this.startColWidth = curColWidth;
    }

    const mouseDelta = (event.clientX - this.startLeft) / this.startColWidth;
    const originalTarget = this.startColWidth * mouseDelta;
    const widthChange = originalTarget / curColWidth;
    const resizeColSelected = table.selection.containsCol(resizeColPos);
    const start = resizeColSelected ? table.selection.topLeft.col : resizeColPos;
    const end = resizeColSelected ? table.selection.bottomRight.col + 1 : resizeColPos + 1;
    const cols = table.cols.slice();
    let anyChange = false;

    for (let colPos = start; colPos < end; ++colPos) {
      const col = cols[colPos] = Object.assign({}, cols[colPos]);
      const newWidth = Math.max(Math.round((col.width || table.defaultColWidth) * widthChange), this.props.minColSize);
      anyChange = anyChange || col.width != newWidth;
      col.width = newWidth;
    }

    if (!anyChange) {
      return;
    }

    if (table.props.onColResize) {
      if (table.props.onColResize(cols, start, end - start, widthChange) === false) {
        return;
      }
    }

    table.updateCols(cols);
    table.props.onViewChange?.(table);
    table.forceUpdate();
  }

  onDragMouseUp = (event:MouseEvent) => {
    this.mouseCapture = null;
    this.startLeft = null;
    this.startColWidth = null;
    this.setState({activeCol: -1, mouseDown: false});
  }

  // resizing occurs between two columns, this will always
  // return the column on the left.
  findResizeCol(event:React.MouseEvent<HTMLDivElement>) {
    const table = this.table;
    const resizeTolerance = this.props.resizeTolerance;
    const cell = table.getCellFromEvent(event);

    if (!cell || cell.pos.col < table.rowHeaderCount) {
      return -1;
    }

    if (!cell.element || !cell.element.contains(event.target as HTMLElement)) {
      return -1;
    }

    let resizeCol = -1;
    const col = cell.pos.col;

    if (event.clientX >= cell.rect.left && event.clientX <= cell.rect.left + resizeTolerance) {
      resizeCol = col - 1;
    }
    else
    if (event.clientX >= cell.rect.right - resizeTolerance && event.clientX <= cell.rect.right) {
      resizeCol = col;
    }

    return resizeCol < table.rowHeaderCount ? -1 : resizeCol;
  }

  getColLeft(col:number) {
    const table = this.table;
    const r = this.ref.current.getBoundingClientRect();
    return r.left + table.getCellCoordinates(new Point(col, 0)).left - this.props.section.coords.left;
  }

  getResizeX(col:number) {
    return this.table.getCellCoordinates(new Point(col + 1, 0)).left - 1 - this.props.section.coords.left;
  }
}