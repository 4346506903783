import * as React from 'react';
import { flatten } from 'lodash-es';

import { CourseUtils, CourseOptionKind, DeepPartial, EnrollmentOption, EnrollmentOptionInput } from 'app2/api';

import { useCourseOptionsQuery, CourseOptionsSelections } from './generated';

export type EnrollmentOptions = EnrollmentOptionInput[] | DeepPartial<EnrollmentOption>[];
type CourseOption = CourseOptionsSelections['options'][0];
type CourseOptionChoice = CourseOption['choices'][0];

export function useCourseWithOptions(id:string, checkout?: boolean, choicesOrInput?:EnrollmentOptions) {
  const course = getCourse();
  const choices = getChoices();
  const {options, courses} = getOptions();
  
  // converts EnrollmentOption to EnrollmentOptionInput
  function getChoices() {
    return React.useMemo(() => {
      return typeof choicesOrInput?.[0]?.option == 'object'
        ? (choicesOrInput as EnrollmentOption[]).map(o => ({option: o.option.id, choices: o.choices.map(c => ({id: c.course.id}))}))
        : (choicesOrInput || []) as EnrollmentOptionInput[]
    }, [choicesOrInput]);
  }

  function getCourse() {
    const [result] = useCourseOptionsQuery({ variables: { id } });
    const course = result.data?.course

    return course;
  }

  function getOptions() {
    // filter out options that are not for checkout (if we are in checkout)
    // and convert the choices to options that have a value of an id
    const {options, courses} = React.useMemo(() => {
      const options = course?.options?.
        filter(o => !checkout || o.checkout).
        map(o => ({
            ...o,
            choices: o.choices?.map(c => courseToOption(o, c)),
            choicesByGrade: CourseUtils.groupCoursesByGrade(o.choices, course.site.grades).map(g => ({label: g.label, courses: g.courses.map(c => courseToOption(o, c))}))
        }));

      const courses = flatten(course?.options?.map(o => o.choices));

      return {options, courses};
    }, [course]);

    function courseToOption(o:CourseOption, c:CourseOptionChoice) {
      const disabled = o.kind == CourseOptionKind.SingleChoice
        ? choices.find(ec => ec.option == o.id)?.choices?.length > 0
        : choices.find(ec => ec.option == o.id && ec.choices?.find(ch => ch.id == c.id)) != null

      return {label: c, value: c.id, disabled};
    }

    return {options, courses};
  }

  return {choices, course, options, courses};
}
