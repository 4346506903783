import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { TeamParticipantsFragmentFragmentDoc } from '../../team/generated/teamParticipantsFragment.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentTeamQueryVariables = Types.Exact<{
  student: Types.Scalars['ID'];
}>;

export type StudentTeamQuery = {
  student?:
    | {
        family?:
          | {
              id: string;
              teamParticipantsUpsert?: boolean | null | undefined;
              teamChangeRole?: boolean | null | undefined;
              teamSetAsContact?: boolean | null | undefined;
              userMemberRole?: Types.MemberRole | null | undefined;
              participants?:
                | Array<
                    | {
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                    | {
                        isContact?: boolean | null | undefined;
                        isCurrentUser?: boolean | null | undefined;
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                    | {
                        sentAt: string;
                        id: string;
                        kind: Types.ParticipantKind;
                        name?: string | null | undefined;
                        role: string;
                        relation?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                      }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentTeamDocument = gql`
  query StudentTeam($student: ID!) {
    student(student: $student) {
      family {
        id
        ...teamParticipantsFragment
      }
    }
  }
  ${TeamParticipantsFragmentFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentTeamArgs = MakeOptional<UseQueryArgs<StudentTeamQueryVariables, StudentTeamQuery>, 'query'>;

export function useStudentTeamQuery(options: StudentTeamArgs = {}) {
  return useQuery<StudentTeamQueryVariables, StudentTeamQuery>({ query: StudentTeamDocument, ...options });
}

export type StudentTeamOptions = ExecQueryOptions<StudentTeamQueryVariables>;

export function studentTeam(options: StudentTeamOptions) {
  return executeQuery<StudentTeamQuery, StudentTeamQueryVariables>(StudentTeamDocument, options);
}

export type StudentTeamSelections = StudentTeamQuery['student'];
