import * as React from 'react';

import { HBox } from '../../Box';
import { Subtitle2 } from '../../Text';
import { keyElements } from '../../utils';

export function ActionBar(props:React.PropsWithChildren<{}>) {
  return <HBox width='100%' vAlign='center' pl={['$12', '$20', '$30']} py={['2px', '$8']} bg='border'>
    <HBox flex={1} overflowX='auto' overflowY='hidden' vAlign='center' pr='30px' className='thin-scrollbars'>
      <Subtitle2 mr='$12' key='label'>Actions:</Subtitle2>
      {keyElements(props.children)}
    </HBox>
  </HBox>
}
