import { isScrollable } from './isScrollable';

// it can be tricky to determine if an html element is
// scrollable  checking for scrollbars is not enough.
// how the element resizes matters, and it can change
// size over time.  normally we don't check the
// element size since its error prone, but you can
// opt into this.

export function getScrollParent(elementOrNode:Element | Node, checkScrollableSize:boolean = false):HTMLElement {
  if (elementOrNode == null) {
    return document.documentElement;
  }

  const  node = elementOrNode.nodeType != Node.ELEMENT_NODE ? elementOrNode.parentElement : elementOrNode as HTMLElement;

  if (isScrollable(node, checkScrollableSize)) {
    return node as HTMLElement;
  }
  else {
    return getScrollParent(node.parentElement, checkScrollableSize);
  }
}
