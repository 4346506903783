import * as React from 'react';

import { Button, Panel, InputField, Section, Part, useFormInfo, useForm, NotificationManager, Modal, SaveableResult, Text, Icon, HBox } from 'app2/components';

import { CheckoutModel } from './CheckoutModel';
import { DiscountModel } from './DiscountModel';
import { parentApplyDiscount } from './generated';

export function Discount() {
  const cartForm = useFormInfo<CheckoutModel>().form;
  const form = useForm<DiscountModel>({}, [], {alwaysSave: true});

  function render() {
    return (
      <Panel
        subtitle={
          <HBox vAlign="center">
            <Text>Apply a discount</Text>
            <Icon
              name="Info"
              size="small"
              tooltip="Each enrollment can only be paired with one discount code. If more than one discount is applied, we will automatically choose the one that offers the greatest savings."
              ml="$4"
            />
          </HBox>
        }
        form={form}
        onNavigation="nothing"
        type="edit-no-save-button"
      >
        <Section hAlign="left" layout="hbox" width={['100%', '100%', '50%']}>
          <Part name="code" component={InputField} onKeyPress={onKeyPress} />
          <Button autoLoader onClick={onApplyCode} flex={0} disabled={!form.values.code}>
            Apply
          </Button>
        </Section>
      </Panel>
    );
  }

  function onKeyPress(event:React.KeyboardEvent) {
    if (event.key == 'Enter') {
      event.stopPropagation();
      onApplyCode();
    }
  }

  async function onApplyCode() {
    form.clearErrors();

    const code = form.values.code;
    if (!code) {
      NotificationManager.add({ type: 'warning', message: 'Please enter a discount code' });
      form.errors = [];
      return false;
    }

    if (cartForm.values.cart.priceTierUse) {
      const result = await Modal.warning({
        title: 'Price tier selected',
        content: 'Applying a discount code will override the selected price tier. Are you sure you want to continue?',
        ok: 'Continue'
      });
      if (result.action == SaveableResult.cancel) {
        return false;
      }
    }

    const [success] = await parentApplyDiscount({ variables: { code }, error: form });
    if (success) {
      form.setValue('code', '');
    }

    return success;
  }

  return render();
}
