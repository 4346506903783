import * as React from 'react'
import { useParams } from 'react-router';

import { CourseDay, CourseUtils } from 'app2/api';
import { DateField, Form, RepeatingSection, Field, hasAppMode, Link, Panel, useForm, useFormToUrl, useLifecycle, VBox } from 'app2/components';
import { PublicPage } from 'app2/views/shared-public';

import { SiteHeader } from '../shared';
import { PublicSiteParams } from '../../publicRoutes';

import { Filter } from './Filter';
import { FilterOptions } from './FilterOptions';
import { useGameSeasonsQuery, useGamesQuery, GamesSelections } from './generated';

type Game = GamesSelections['games'][0];

export function Games() {
  const embedded = hasAppMode('embed');

  const filterOptions = useForm<FilterOptions>({}, []);
  const {formToUrl} = useFormToUrl(filterOptions);

  const site = getSite();
  const games = getAllGames();
  const filteredGames = filterGames();
  const gamesForm = useForm({games:filteredGames}, [filteredGames]);

  useLifecycle({onUpdate})

  function render() {
    return <PublicPage above={renderSiteHeader()} useMaxWidth pt={null} markingLinksWhenLoggedIn={false}>
      <VBox gap='$30'>
        <Filter site={site} games={games} filterOptions={filterOptions} />
        <Panel gap='$30' form={gamesForm} onNavigation='nothing'>
          <RepeatingSection name='games' numbered={false} fields={[
            <Field name='startDate' label='Date' component={DateField}  />,
            <Field name='courseDays.0' label='Time' format={(cd:CourseDay) => `${cd.start} - ${cd.finish}`} />,
            <Field name='disambiguatedName' label='Game' />,
            <Field name='homeTeam' label='Home team' format={renderTeam} />,
            <Field name='awayTeam' label='Away team' format={renderTeam} />,
            <Field name='room' label='Location' none={false} />,
            <Field name='score' label='Score' none={false} />,
          ]} />
        </Panel>
      </VBox>
    </PublicPage>
  }

  function renderSiteHeader() {
    return !embedded ? <SiteHeader site={site} /> : undefined;
  }

  function renderTeam(team:Game['homeTeam'] | Game['awayTeam']) {
    return <Link to={formToUrl({teams: team.id})}>{team.disambiguatedName}</Link>;
  }

  function getSite() {
    const { site:siteId } = useParams<PublicSiteParams>();
    const [result] = useGameSeasonsQuery({variables: {site:siteId}});
    const site = result.data?.site;

    return site;
  }

  function getAllGames() {
    const [result] = useGamesQuery({variables: {season: filterOptions.values.season}, pause: !filterOptions.values.season});

    return result?.data?.season?.games;
  }

  function filterGames() {
    return CourseUtils.filterCourses(games, filterOptions.values).filter(g => hasTeam(g));
  }

  function hasTeam(game:Game) {
    if (!filterOptions.values?.teams?.length) {
      return true;
    }

    return filterOptions.values?.teams.includes(game.homeTeam.id) || filterOptions.values?.teams.includes(game.awayTeam.id);
  }

  function onUpdate() {
    if (filterOptions.values.season || !site?.gameSeasons?.length) {
      return;
    }

    // seasons should be ordered by status, such that the most relevant
    // ones are listed first, so we can default to the first season
    filterOptions.setValue('season', site?.gameSeasons[0].id);
  }

  return render();
}
