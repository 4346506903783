import * as React from 'react';

import { studentTabs } from '../tabs';

import { StudentModalBase } from './StudentModalBase'

import { useStudentDetailsQuery } from '../tabs/gql';

interface Props {
  id: string;
}

export function StudentModal(props: Props) {
  const [result] = useStudentDetailsQuery({ variables: { id: props.id } });
  const student = result?.data?.student;

  function render() {
    return <StudentModalBase student={student} tabs={studentTabs(props.id, true)} />
  }

  return render();
}
