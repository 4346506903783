import * as React from 'react';
import moment from 'moment';
import { findLast } from "lodash-es";

import { CourseUtils } from 'app2/api';
import { useCourseDates } from 'app2/views/shared-public';

import { Attendance } from './Attendance'
import { CourseHeader } from './CourseHeader';
import { useAttendanceDate } from './useAttendanceDate';
import { useAttendanceCourseQuery } from './generated';

interface Props {
  token?:string;
  url:string;
  prefsKey?:string;
  pageHeader?:boolean;
  // dictates if the check in/out tabs should be shown
  // by default they show on mobile automatically
  tabs?:boolean;
  // is this the teacher view
  teacher?:boolean;
}

export function CourseAttendance(props: Props) {
  const {pageHeader, ...remaining} = props;

  const course = getCourse();
  const date = useAttendanceDate();
  const dates = useCourseDates(course);
  const session = course ? dates.sessions.find(d => d.isSame(date, 'date')) : undefined;
  const isSessionDay = course ? session != null : true;
  const prev = course ? findLast(dates.sessions, d => d.isBefore(date, 'date')) : moment(date).add(1, 'days');
  const next = course ? dates.sessions.find(d => d.isAfter(date, 'date')) : moment(date).subtract(1, 'days');

  function render() {
    return <Attendance {...remaining} course={course} dates={{isSessionDay, prev, next, legend: dates.legend}} pageHeader={renderHeader()} views={renderTableViews()} />
  }

  function renderHeader() {
    return pageHeader && <CourseHeader course={course} date={date} day={session?.courseDay} />
  }

  function renderTableViews() {
    if (props.teacher) {
      return;
    }

    const entityInfo = CourseUtils.getEntityInfo(course);
    return entityInfo ? {...entityInfo, table: 'course-attendance', isTeacherView: props.teacher, allowTeacherViews:true} : undefined;
  }

  function getCourse() {
    const [result] = useAttendanceCourseQuery({variables:{token: props.token}})
    const course = result.data?.course;

    return course;
  }

  return render();
};
