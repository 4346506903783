import * as React from 'react';
import { Route } from 'react-router-dom';

import { Redirect } from 'app2/components';

import { MarketArea, MarketAreaCatalogs, MarketAreaProviders, MarketAreaCategory, CatalogListing, Category, Provider } from '.';

export const seoRoutes = [
  <Route path="/provider/:id/about" exact component={Provider} />,

  <Route path="/in/:slug" exact component={MarketArea} />,
  <Route path="/in/:slug/catalog" exact component={MarketAreaCatalogs} />,
  <Route path="/in/:slug/providers" exact component={MarketAreaProviders} />,
  <Route path="/in/:marketAreaSlug/category/:categorySlug" exact component={MarketAreaCategory} />,

  <Route path="/catalog/:slug" exact component={CatalogListing} />,

  <Route path="/category/:slug" exact component={Category} />,

  <Redirect from='/instructors' to='/provider' />
];