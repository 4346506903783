import * as React from 'react';
import { useHistory } from 'react-router'

import { Link, LinkProps } from 'app2/components';

// if you do not provide a to property then this
// will automatically call browser back

export function BackLink(props: LinkProps) {
  const { children, ...remaining } = props;
  const history = useHistory();

  function render() {
    return <Link icon="Left" underline={false} text="subtitle2" display='flex' mb={['$10', '$30']} onClick={props.to ? undefined : onClick} {...remaining} children={children || 'Back'} />
  }

  function onClick() {
    history.goBack();
  }

  return render();
}
