import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpsertSeasonMutationVariables = Types.Exact<{
  attributes: Types.SeasonInput;
}>;

export type OrganizerUpsertSeasonMutation = { seasonUpsert?: { season?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpsertSeasonDocument = gql`
  mutation OrganizerUpsertSeason($attributes: SeasonInput!) {
    seasonUpsert(attributes: $attributes) {
      season {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpsertSeasonOptions = ExecMutationOptions<OrganizerUpsertSeasonMutationVariables>;

export function organizerUpsertSeason(options: OrganizerUpsertSeasonOptions) {
  return executeMutation<OrganizerUpsertSeasonMutation, OrganizerUpsertSeasonMutationVariables>(OrganizerUpsertSeasonDocument, options);
}
