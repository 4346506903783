import * as React from 'react';

import { HBox, Body, formatCurrency } from 'app2/components';

interface Props {
  label: string;
  amount: number | React.ReactNode;
}

export function FeeItem(props: Props) {
  return (
    <HBox alignItems="center" justifyContent="space-between" gap='$8'>
      <Body>{props.label}</Body>
      <Body textAlign='right'>{typeof props.amount == 'number' ? formatCurrency(props.amount) : props.amount}</Body>
    </HBox>
  );
}
