import * as React from 'react';
import { last } from 'lodash-es';

import { Box } from '../Box';
import { Body, Subtitle2 } from '../Text';
import { NotificationManager } from '../notification';

export class ClipboardUtils {
  static formatCsv(rows:string[][]):string {
    return this.format(rows, ',');
  }

  static formatTsv(rows:string[][]):string {
    return this.format(rows, '\t');
  }

  static format(rows:string[][], separator:string):string {
    return rows.map(row => row.map(cell => '"' + (cell || '').replace(/"/g, '""') + '"').join(separator)).join('\n');
  }

  static formatHtml(rows:string[][]):string {
    return '<table style="border-collapse: collapse">\n' + 
      rows.map(row => ' <tr>\n' + 
        '   ' + row.map(cell => '<td style="border:solid 1px #cccccc">' + (cell || '').replace('\n', '<br>') + '</td>').join('') + '\n' +
      ' </tr>\n').join('') + 
    '</table>\n';
  }

  static parseCsv(data:string):string[][] {
    return this.parse(data, ',');
  }

  static parseTsv(data:string):string[][] {
    return this.parse(data, '\t');
  }

  static parse(data:string, separator:string):string[][] {
    let rows:string[][] = [[]];
    let cell = '';
    let inQuote = false;

    for (let pos = 0; pos < data.length; ++pos) {
      const cur = data[pos];
      const next = pos < data.length - 1 ? data[pos + 1] : '';

      if (inQuote) {
        if (cur == '"' && next == '"') {
          cell += '"';
          ++pos;
        }
        else
        if (cur == '"') {
          inQuote = false;
        }
        else {
          cell += cur;
        }
      }
      else {
        if ((cur == '\r' && next == '\n') || cur == '\r' || cur == '\n') {
          last(rows).push(cell);
          cell = '';
          rows.push([]);
        }
        else
        if (cur == '"') {
          inQuote = true;
        }
        else
        if (cur == separator) {
          last(rows).push(cell);
          cell = '';
        }
        else {
          cell += cur;
        }
      }
    }

    if (cell.length) {
      last(rows).push(cell);
    }

    // if the lines have different number of columns, then
    // we convert to an array of one column each by joining 
    // the column values
    if (new Set(rows.map(row => row.length)).size != 1) {
      rows = rows.map(row => [row.join(separator)])
    }

    return rows;
  }

  // generates a copy event to the element with the focus

  static generateCopyEvent() {
    try {
      if (document.execCommand('copy')) {
        return true;
      }
    }
    catch(e) {
    }

    this.showCopyPasteKeyboardMessage();

    return true;
  }

  // generates a paste event to the element with the focus
  // with the specifed formats from the current clipboard

  static async generatePasteEvent(formats:string[]) {
    try {
      if (document.execCommand('paste', true)) {
        return true;
      }
    }
    catch(e) {
    }

    try {
      const clipboardData = await this.copyClipboardBufferToDataTransfer(formats);

      const event = new ClipboardEvent('paste', {bubbles:true, clipboardData})
      document.activeElement.dispatchEvent(event);

      return true;
    }
    catch(e) {
    }

    this.showCopyPasteKeyboardMessage();

    return false;
  }

  static async copyClipboardBufferToDataTransfer(formats:string[]) {
    const dataTransfer = new DataTransfer();
    const formatsSet = new Set(formats);
    const clipboardContents = await navigator.clipboard.read();

    for (const item of clipboardContents) {
      for (const type of item.types) {
        if (formatsSet.has(type)) {
          const blob = await item.getType(type);
          const data = await blob.text();
          dataTransfer.setData(type, data);
        }
      }
    }

    return dataTransfer;
  }

  static showCopyPasteKeyboardMessage() {
    NotificationManager.add({ type: 'warning', message: <Box width='100%'>
        <Subtitle2 mb='$4'>Copying and pasting</Subtitle2>
        <Body mb='$16'>These actions are unavailable via buttons, but you can still use:</Body>
        <Body mb='$4'><b>Ctrl+C</b> for copy, <b>Ctrl+V</b> for paste</Body>
        <Body><i>(on Mac use Command+C and Command+V)</i></Body>
      </Box>
    });
  }
}

