import { axios } from 'app2/api';
import { observable } from 'app/observable';
import { CatalogResults, MarketArea } from 'app2/api';

import { CategoryDetails } from './CategoryDetails';

interface PaginationMeta {
  provider_count: number;
}

type Catalogs = CatalogResults<PaginationMeta>;

@observable
export class MarketAreaCategoryStore {
  marketAreaSlug: string;
  categorySlug: string;
  pageSize: number;
  pageNumber: number;
  marketArea: MarketArea;
  category: CategoryDetails;
  catalogs: Catalogs;

  load(marketAreaSlug: string, categorySlug: string) {
    const promises = [];

    if (this.marketAreaSlug != marketAreaSlug) {
      this.marketAreaSlug = marketAreaSlug;
      this.category = null;
      this.catalogs = null;
      this.pageSize = null;
      this.pageNumber = null;
      promises.push(this.loadMarketArea());
    }

    if (this.categorySlug != categorySlug) {
      this.categorySlug = categorySlug;
      this.category = null;
      this.catalogs = null;
      this.pageSize = null;
      this.pageNumber = null;

      promises.push(this.loadCategory());
    }

    return promises;
  }

  loadCatalogPage(pageSize: number, pageNumber: number = 0) {
    if (this.pageSize === pageSize && this.pageNumber === pageNumber) {
      return;
    }

    this.pageSize = pageSize;
    this.pageNumber = pageNumber;

    return this.loadCatalogs();
  }

  async loadMarketArea() {
    try {
      const response = await axios.get<MarketArea>(
        `/market_areas/${this.marketAreaSlug}`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(
          `/in/${response.data.redirect_to_slug}/category/${this.categorySlug}`
        );
      }

      this.marketArea = response.data;
    } catch (_) {
      window.location.replace('/not-found');
    }
  }

  async loadCategory() {
    try {
      const response = await axios.get<CategoryDetails>(
        `/course_tags/${this.categorySlug}/seo_page`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(
          `/in/${this.marketAreaSlug}/category/${response.data.redirect_to_slug}`
        );
        return;
      }

      this.category = response.data;
    } catch (_) {
      window.location.replace('/not-found');
    }
  }

  async loadCatalogs() {
    try {
      const response = await axios.get<Catalogs>(
        `/market_areas/${this.marketAreaSlug}/catalog_by_course_tag/${
          this.categorySlug
        }?items_per_page=${this.pageSize}&page=${this.pageNumber + 1}`
      );
      this.catalogs = response.data;
    } catch (_) {}
  }
}
