import * as React from 'react';

import { PercentInput } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function PercentInputDemos() {
  const [value, setValue] = React.useState('415');

  return <Demos name='PercentInput'>
    <Demo name='Uncontrolled'>
      <PercentInput width='300px' />
    </Demo>

    <Demo name='Controlled'>
      <PercentInput width='300px' value={value} onChange={event => setValue(event.target.value)} />
      Value: {value}
    </Demo>
  </Demos>
}
