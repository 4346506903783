import * as React from 'react';

import { EnrollmentInput } from 'app2/api';
import { Button, formatCurrency, useFormInfo } from 'app2/components';

import { AddToCartProps } from '../course';

import { configureEnrollment } from './configureEnrollment';

interface Props extends Partial<AddToCartProps> {
  multiple:boolean;
  allWaitlist:boolean;
}

export function EnrollmentCourseOptionsAddToCart(props:Props) {
  const {course, multiple, allWaitlist} = props;
  const formInfo = useFormInfo();

  function render() {
    let buttonProps:any = {onClick, disabled: props.disabled || !formInfo.editing}
    const price = course.prices?.season?.price ? ` (${formatCurrency(course.prices?.season?.price)})` : '';
    const selected = isSelected();

    buttonProps.icon = multiple
      ? selected ? 'CheckSquare' : 'Plus'
      : selected ? 'RadioSelected' : 'Circle';

    buttonProps.children = multiple
      ? selected ? `Added${price}` : `Add${price}`
      : selected ? `Selected${price}` : `Select${price}`;

    if (course.closed) {
      buttonProps.icon = undefined;
      buttonProps.disabled = true;
      buttonProps.children = 'Closed';
    }

    if (course.waitlistOpen) {
      if (allWaitlist) {
        buttonProps.icon = undefined;
        buttonProps.disabled = true;
        buttonProps.children = 'Full';
        buttonProps.justifyContent = undefined;
      }
      else {
        buttonProps.children = 'Join waitlist';
      }
    }

    buttonProps = {kind:'secondary', iconPosition:'left', selected, justifyContent: [undefined, 'flex-end'], minWidth:'182px', ...buttonProps}

    return <Button {...buttonProps} />
  }

  function getChoices():EnrollmentInput[] {
    const choiceName = formInfo.parents[0];
    return formInfo.form.getValue([], choiceName) || [];
  }

  function setChoices(choices:EnrollmentInput[]) {
    const choiceName = formInfo.parents[0];
    return formInfo.form.setValue(choiceName, choices);
  }

  function isSelected() {
    return getChoices().find(c => c.id == course.id) != null;
  }

  async function onClick() {
    if (isSelected()) {
      setChoices(getChoices().filter(c => c.id != course.id));
    }
    else {
      const enrollment = await configureEnrollment(course);

      if (!enrollment) {
        return;
      }
  
      if (!multiple) {
        setChoices([enrollment]);
      }
      else {
        setChoices([...getChoices(), enrollment]);
      }
    }
  }

  return render();
}
