export function isDevelopmentEnv() {
  return process.env.NODE_ENV === 'development';
}

export function isProductionEnv() {
  return process.env.NODE_ENV === 'production';
}

export function isRealProduction() {
  return isProductionEnv() && !process.env.STAGING;
}

export function isDevOrStagingEnv() {
  return isDevelopmentEnv() || process.env.STAGING;
}

export function isReleaseEnv() {
  return document.location.href.startsWith('https://release.homeroom.com/');
}

export function isStagingEnv() {
  return document.location.href.startsWith('https://beta.homeroom.com/');
}

export function isAutomatedTestingEnv() {
  return document.location.href.startsWith('https://e2e-testing.homeroom.com/') || document.location.href.startsWith('http://0.0.0.0');
}

export function baseAPIUrl() {
  return process.env.BASE_URL;
}

declare global {
  interface Window {
    __logs: string[];
    __originalLogMethods: any;
  }
}

export function installTestLogCapturing() {
  if (!isAutomatedTestingEnv()) {
    return;
  }

  if (window.__logs !== undefined) {
    return;
  }

  window.__logs = [];
  window.__originalLogMethods = {};

  const logMethods = ['debug', 'error', 'info', 'log', 'warn'];

  logMethods.forEach(m => {
    //@ts-ignore
    window.__originalLogMethods[m] = console[m];
  });

  logMethods.forEach(m => {
    //@ts-ignore
    console[m] = function() {
      if (window.__logs.length > 200) {
        window.__logs.splice(0, 100);
      }

      const original = window.__originalLogMethods[m];
      window.__logs.push([m, ' - ', ...arguments].join(' '));

      if (m == 'error') {
        window.__logs.push(new Error().stack);
      }

      return original.apply(console, arguments);
    };
  });

  console.info('Automated test log capturing installed');
}
