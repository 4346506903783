import * as React from 'react'

import { HBox } from '../Box'
import { Button } from '../Button'
import { PreferenceStore } from '../preferences';
import { useRef } from '../utils';

import { Tab } from './Tab'
import { TabStripHookProps } from './TabStripHookProps'
import { useTabStrip } from './useTabStrip'
import { ScrollShadow } from './ScrollShadow';

export interface TabStripProps<T extends PreferenceStore = any> extends TabStripHookProps<T> {
}

export function TabStrip<T extends PreferenceStore>(props:TabStripProps<T>) {
  const ref = useRef<HTMLElement>();
  const {tabs, tabState, tabsRef, component, selected, onChange, pref, prefName, baseUrl, urlParameter, urlHideSuffix, mode, history, size, arrows, preserveQuery, ...remaining} = props;
  let {selectedTab, normalizedTabs, selectTab, hideTabs} = useTabStrip(props);
  const spaceProps = {mb:['$10', '$30'], minHeight:'36px'};
  
  function render() {
    const needArrows = arrows && ref.current?.scrollWidth > ref.current?.clientWidth;
    return needArrows ? renderWithArrows() : renderWithoutArrows(spaceProps);
  }

  function renderWithArrows() {
    return <HBox key='buttons' {...spaceProps}>
      <Button key='left' icon='Left' kind='secondary' border='solid .5px' bg='rgba(0,0,0,0)' p='2px' borderTopRightRadius={0} borderBottomRightRadius={0} onClick={() => scroll(-(ref.current.scrollWidth - 100))} />
      {renderWithoutArrows({px:'$12'})}
      <Button key='right' icon='Right' kind='secondary' border='solid .5px' bg='rgba(0,0,0,0)' p='2px' borderTopLeftRadius={0} borderBottomLeftRadius={0} onClick={() => scroll((ref.current.scrollWidth - 100))} />
    </HBox>
  }

  function scroll(amount:number) {
    ref.current.scrollTo({left:ref.current.scrollLeft + amount, top:0, behavior: 'smooth'});
  }

  function renderWithoutArrows(props?:any) {
    // the minHeight fixed pixel is needed because in some scrolling containers 
    // css does not seem to figure out the right size and will squeeze the tabs
    // to not show (even with a minHeight: fit-content)

    return <ScrollShadow key='tabs' ref={ref} layout='hbox' hItemSpace='$40' size={30} overflowX='auto' overflowY='hidden' position='relative' className='thin-scrollbars' vAlign='center' borderBottom={hideTabs ? undefined : 'solid 1px'} borderColor={hideTabs ? undefined : 'border'} {...props} {...remaining}>
      {renderTabs()}
    </ScrollShadow>
  }

  function renderTabs() {
    const Component = component || Tab;

    return hideTabs 
        ? '' 
        : normalizedTabs.map((tab, index) => <Component key={index} onClick={event => onClickTab(event, index)} selected={index == selectedTab} {...tab}>{tab?.label}</Component>)
  }

  function onClickTab(event:React.MouseEvent, index:number) {
    event.preventDefault();
    event.stopPropagation();
    selectTab(index);
  }

  return render();
}
