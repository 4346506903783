import * as React from 'react';

import { Box, BoxProps } from './Box';
import { useScrollWatcher } from './dom-utils';

// use this for when a component is inside a sticky element 
// but needs to scroll.  this will force scroll it by moving
// its position.

interface UnstickyProps extends BoxProps {
  horizontal?:boolean;
  vertical?:boolean;
  pad?:boolean;
}

export function Unsticky(props:UnstickyProps) {
  const {horizontal, vertical, pad, ...remaining} = props;
  const element = React.useRef<HTMLDivElement>();
  const [topLeft, setTopLeft] = React.useState<{left?:string, top?:string}>({});
  const scrollWatcher = useScrollWatcher(element.current, {onScroll, capture:true});

  function onScroll() {
    if (!element.current) {
      return;
    }

    update();
  }

  function update() {
    let top:string;
    let left:string;

    if (horizontal) {
      left = scrollWatcher.scroller.scrollLeft + 'px';
    }

    if (vertical) {
      top = scrollWatcher.scroller.scrollTop + 'px';
    }

    if (topLeft.top != top || topLeft.left != left) {
      // we need to set these because children might have
      // scroll listeners that do calculations that depend 
      // on appearing scrolled

      if (horizontal) {
        element.current.style.left = '-' + left;
      }

      if (vertical) {
        element.current.style.top = '-' + top;
      }

      // html will subtract off the left off the width of the container being made
      // unsticky depending on the container its inside of, so we optionally
      // add padding to keeep its width the same.

      if (pad) {
        if (horizontal) {
          element.current.style.paddingRight = left;
        }

        if (vertical) {
          element.current.style.paddingBottom = '-' + top;
        }
      }

      setTopLeft({top, left});
    }
  }

  return <Box ref={element} minWidth='fit-content' position='relative' top={'-' + topLeft.top} left={'-' + topLeft.left} 
    paddingRight={pad ? topLeft.left : undefined} paddingTop={pad ? topLeft.top : undefined} {...remaining}>
    {props.children}
  </Box>
}