import React from 'react'

import { UsBankAccount } from 'app2/api';
import { Body, HBox, VBox, Icon } from 'app2/components';

interface Props {
  usBankAccount:UsBankAccount;
  onRemove?:() => void;
}

export function Ach(props:Props) {
  return <VBox border='solid' borderColor='border' borderRadius='form' minWidth='200px' width='fit-content' bg='white' p='$10' vItemSpace='$8'>
    <Body small disabled letterSpacing='1.6px'>{'Account ending ' + props.usBankAccount.last4}</Body>
    <HBox hAlign='justify' width='100%'>
      <Body small disabled letterSpacing='1.6px'>{props.usBankAccount.bankName}</Body>
    </HBox>
    <HBox width='100%'>
      <HBox flex={1} />
      {props.onRemove && <Icon name='Trash2' size='medium' onClick={props.onRemove} button />}
    </HBox>
  </VBox>
}
