import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckoutFamiliesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CheckoutFamiliesQuery = {
  user?:
    | {
        name?: string | null | undefined;
        families?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              participants?:
                | Array<
                    | { name?: string | null | undefined; kind: Types.ParticipantKind }
                    | { name?: string | null | undefined; kind: Types.ParticipantKind }
                    | { name?: string | null | undefined; kind: Types.ParticipantKind }
                  >
                | null
                | undefined;
              students?: Array<{ name: string }> | null | undefined;
              creditCard?: { brand: Types.CardBrand; last4: string } | null | undefined;
              usBankAccount?: { bankName: string; last4: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CheckoutFamiliesDocument = gql`
  query CheckoutFamilies {
    user: currentUser {
      name
      families {
        id
        name
        participants {
          name
          kind
        }
        students {
          name
        }
        creditCard {
          brand
          last4
        }
        usBankAccount {
          bankName
          last4
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CheckoutFamiliesArgs = MakeOptional<UseQueryArgs<CheckoutFamiliesQueryVariables, CheckoutFamiliesQuery>, 'query'>;

export function useCheckoutFamiliesQuery(options: CheckoutFamiliesArgs = {}) {
  return useQuery<CheckoutFamiliesQueryVariables, CheckoutFamiliesQuery>({ query: CheckoutFamiliesDocument, ...options });
}

export type CheckoutFamiliesOptions = ExecQueryOptions<CheckoutFamiliesQueryVariables>;

export function checkoutFamilies(options: CheckoutFamiliesOptions) {
  return executeQuery<CheckoutFamiliesQuery, CheckoutFamiliesQueryVariables>(CheckoutFamiliesDocument, options);
}

export type CheckoutFamiliesSelections = CheckoutFamiliesQuery['user'];
