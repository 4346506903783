import { CourseDayName } from './CourseDayName';

export enum DAY_SORT_ENUM {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export const DAY_SORT_KEY: { [index: string]: number } = {
  monday: DAY_SORT_ENUM.Monday,
  Monday: DAY_SORT_ENUM.Monday,
  tuesday: DAY_SORT_ENUM.Tuesday,
  Tuesday: DAY_SORT_ENUM.Tuesday,
  wednesday: DAY_SORT_ENUM.Wednesday,
  Wednesday: DAY_SORT_ENUM.Wednesday,
  thursday: DAY_SORT_ENUM.Thursday,
  Thursday: DAY_SORT_ENUM.Thursday,
  friday: DAY_SORT_ENUM.Friday,
  Friday: DAY_SORT_ENUM.Friday,
  saturday: DAY_SORT_ENUM.Saturday,
  Saturday: DAY_SORT_ENUM.Saturday,
  sunday: DAY_SORT_ENUM.Sunday,
  Sunday: DAY_SORT_ENUM.Sunday
};

// do not use directly, use GRADE_OPTIONS or the grade maps
const GRADES_VALUES_AND_LABELS:[number, string][] = [
  [0, 'Primary'],
  [16, 'Preschool'],
  [17, 'N/A'],
  [16, 'Toddler/Preschool'],
  [1, 'TK'],
  [2, 'PreK'],
  [3, 'K'],
  [4, '1'],
  [5, '2'],
  [6, '3'],
  [7, '4'],
  [8, '5'],
  [9, '6'],
  [10, '7'],
  [11, '8'],
  [12, '9'],
  [13, '10'],
  [14, '11'],
  [15, '12'],
];

// do not use directly, use GRADE_OPTIONS or the grade maps
const GRADES_ORDERED = ["N/A", "Primary", "Preschool", "Toddler/Preschool", "PreK", "TK", "K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

export const GRADE_VALUE_TO_POSITION = new Map<number, number>();
GRADES_VALUES_AND_LABELS.forEach(info => GRADE_VALUE_TO_POSITION.set(info[0], GRADES_ORDERED.indexOf(info[1])));

export const GRADE_VALUE_TO_LABEL:Map<number, string> = new Map(GRADES_VALUES_AND_LABELS);

export const GRADE_LABEL_TO_POSITION = new Map<string, number>();
GRADES_VALUES_AND_LABELS.forEach(info => GRADE_LABEL_TO_POSITION.set(info[1], GRADES_ORDERED.indexOf(info[1])));

const GRADES_LABELS_AND_VALUES = GRADES_VALUES_AND_LABELS.map(info => (info.slice().reverse() as [string, number]))
export const GRADE_LABEL_TO_VALUE = new Map(GRADES_LABELS_AND_VALUES);

export const GRADE_OPTIONS = Array.from(GRADES_VALUES_AND_LABELS, ([key, value]) => ({
  key,
  text: value.toString(),
  value: value == 'Toddler/Preschool' ? 'Preschool' : value 
  // the slice is to remove the primary and individual preschool option
})).slice(2).sort((a, b) => GRADES_ORDERED.indexOf(a.text) - GRADES_ORDERED.indexOf(b.text));


export const WEEKDAYS: CourseDayName[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const WEEKDAYS_SET: Set<CourseDayName> = new Set(WEEKDAYS);
export const WEEKDAYS_ABBR = WEEKDAYS.map(day => day.slice(0, 3));
export const WEEKDAYS_ABBR_SET = new Set(WEEKDAYS_ABBR);

export const DAY_ABBREVIATION = {
  Sunday: 'Su',
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa'
};

export const DAY_ABBREVIATION_3_LETTER = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat'
};

type DaySortType = { [key in CourseDayName]: number };
export const DAY_SORT = WEEKDAYS.reduce(
  (sort, day, index) => {
    {
      sort[day] = index;
      return sort;
    }
  },
  {} as DaySortType
);
