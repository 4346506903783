import * as React from 'react';

import { Field, RepeatingSection, DatePicker, RadioGroup, FieldChangeHandler } from 'app2/components';
import { FileLinks, FileUploads } from 'app2/views/shared';

import { VendorComplianceSelections } from './generated';

export interface ComplianceFormValues {
  requirements: VendorComplianceSelections[];
}

interface Props {
  onChange:FieldChangeHandler<ComplianceFormValues>;
}

export function ComplianceFields(props:Props) {
  return (<RepeatingSection name='requirements' maxCols={3} separator onChange={props.onChange} fields={[
    <Field label='Requirement name' name='definition.name' />,
    <Field label='Expiration date' name='expiresAt' placeholder component={DatePicker} />,
    <Field label='Completed' name='status' component={RadioGroup} options={complianceOptions} layout='hbox' />,
    <Field label='Instructions' name='definition.instructions' />,
    <Field label='Documents to download' name='definition.downloads' component={FileLinks} />,
    <Field label='Completed documents' name='uploads' component={FileUploads} />,
  ]} />);
}

const complianceOptions = [
  { label: 'Yes', value: 'complete' },
  { label: 'No', value: 'incomplete' },
  { label: 'N/A', value: 'not_applicable' }
];

