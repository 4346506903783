import * as React from 'react';

import { CfStage, CfAnswerInput } from 'app2/api';
import { Panel, PanelType, FormProps } from 'app2/components';
import { CfStudentForm, CfQuestionsAndAnswers, questionsForStage } from 'app2/views/shared';
import { parentStudentUpsertCfAnswers } from 'app2/views/parent/shared';

import { useParentCartedCustomFieldsQuery, useParentUpcomingCustomFieldsQuery } from './generated';

interface Props extends FormProps<CfQuestionsAndAnswers> {
  id:string;
  type:PanelType;
  carted?:boolean;
  stage:CfStage;
}

export function FamilyCustomFields(props:Props) {
  const {id, type, carted, ...remaining} = props;
  const [result] = carted ? useParentCartedCustomFieldsQuery({variables:{id: props.id}}) : useParentUpcomingCustomFieldsQuery({variables:{id: props.id}});

  function render() {
    const student = result.data?.student;
    const questions = React.useMemo(() => (questionsForStage(student?.questions, props.stage)), [student?.questions, props.stage]);

    if (!questions?.length) {
      return <></>;
    }

    return <Panel id='questions' key={student.id} icon='Info' title='Registration questions' type={props.type} alwaysSave={false} {...remaining}>
      <CfStudentForm stage={props.stage} questions={student?.questions} answers={student?.answers} onOk={onOk} autoSave={props.autoSave} alwaysSave={props.alwaysSave} autoFocus={props.autoFocus} onNavigation={props.onNavigation} />
    </Panel>
  }

  async function onOk(answers:CfAnswerInput[]) {
    const [success] = await parentStudentUpsertCfAnswers({variables:{studentId:props.id, answers}})

    return success;
  }

  return render();
}
