import * as React from 'react'

import { defaultFieldProps, Dropdown, DropdownProps } from 'app2/components'

import { GradeSiteProps, useGradeOptions } from './useGradeOptions';

export interface GradeDropdownProps extends Omit<DropdownProps, 'options'>, GradeSiteProps {
  hideNA?: boolean;
}

export function GradeDropdown(props:GradeDropdownProps) {
  const { site, info, hideNA, ...remaining } = props;
  const options = useGradeOptions(props, false, undefined, hideNA);

  function render() {
    return <Dropdown options={options} {...remaining} />
  }

  return render();
}

GradeDropdown.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info',
}