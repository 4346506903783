import * as React from 'react'

import { Box, DropdownZindex, HBox, HRule, Icon, MultiContext, Link } from 'app2/components';

import { WizardPage, WizardContext } from './Wizard';
import { FloatingScrollShadow } from './FloatingScrollShadow';

interface Props {
  selected?:number;
  sticky?:boolean;
}

export function WizardSteps(props:Props) {
  const wizard = React.useContext<WizardContext>(MultiContext).wizard;
  const [onTop, setOnTop] = React.useState(false);

  function render() {
    return props.sticky
      ? renderSticky()
      : renderSteps();
  }

  function renderSticky() {
    return <Box position='sticky' top='0px' zIndex={onTop ? DropdownZindex + 2 : 1} pointerEvents='none'>
      {renderSteps()}
      <FloatingScrollShadow size={50} onUpdate={onShadowChange} />
    </Box>
  }

  function renderSteps() {
    return <HBox hAlign='center' vAlign='center' textAlign='center' bg='white' padding={['$12', '$20']} pointerEvents='all'>
      {wizard.pages.map(renderStep)}
    </HBox>
  }

  function onShadowChange(strength:number) {
    const newOnTop = strength == 0 ? false : true;

    if (onTop != newOnTop) {
      setOnTop(newOnTop);
    }
  }

  function renderStep(s:WizardPage, index:number) {
    return <React.Fragment key={index}>
      <Box layout={['vbox', 'hbox']} alignItems='center'>
        <Icon name={s.icon} strokeWidth={1} mr={[0, '$8']} mb={['$8', 0]}/>
        <Link key={index} text='body' underline={false} bold={wizard.selected == index} onClick={() => wizard.page(index)}>{s.label}</Link>
      </Box>
      {index != wizard.pages.length - 1 && <HRule width='40px' mx='$10' />}
    </React.Fragment>;
  }

  return render();
}