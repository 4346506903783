import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TimeEntriesUpdateMutationVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
  entries: Array<Types.TimeEntryInput> | Types.TimeEntryInput;
}>;

export type TimeEntriesUpdateMutation = { timeEntriesUpdate?: { entries?: Array<{ id: string }> | null | undefined } | null | undefined };

export const TimeEntriesUpdateDocument = gql`
  mutation TimeEntriesUpdate($ownerType: EntityKind!, $ownerId: ID!, $entries: [TimeEntryInput!]!) {
    timeEntriesUpdate(ownerType: $ownerType, ownerId: $ownerId, entries: $entries) {
      entries {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TimeEntriesUpdateOptions = ExecMutationOptions<TimeEntriesUpdateMutationVariables>;

export function timeEntriesUpdate(options: TimeEntriesUpdateOptions) {
  return executeMutation<TimeEntriesUpdateMutation, TimeEntriesUpdateMutationVariables>(TimeEntriesUpdateDocument, options);
}
