import { ErrorWithStringPath } from 'app2/api';
import { ErrorWithPath, errorToString } from 'app2/components';

import { showErrors } from './showErrors';

export function addPathToMessage(error:ErrorWithStringPath):ErrorWithStringPath {
  return {...error, message: error.message + errorPathToString(error)};
}

export function errorPathToString(error:ErrorWithStringPath) {
  return error.path && error.path.length ? ' (' + error.path.join('.') + ')' : '';
}

export function modalErrorHandler(errors:ErrorWithPath[]):ErrorWithPath[] {
  const timeout_err = errors.find(e => e.code === "TIMEOUT_ERROR");

  if (!!timeout_err) {
    timeout_err.message = "The request took too long. Please refresh the page and try again in a moment.";
    errors = [timeout_err];
  } 

  showErrors(errors.map(errorToString));

  return [];
}
