import * as React from 'react';

import { Course } from 'app2/api';
import { BoxProps, HBox, Icon } from 'app2/components';
import { CourseDatesDisplay } from 'app2/views';

interface Props extends BoxProps {
  course: Pick<Course, 'startDate' | 'endDate' | 'courseDays'>;
  time?:boolean;
  icon?:boolean;
}

export function CourseDates(props: Props) {
  const {course, time, icon, gap, ...remaining} = props;

  function render() {
    if (!course) {
      return <></>;
    }
    
    return <HBox mb="$8" vAlign="top" text='body' width='100%' gap={gap || '$8'}>
      {(course.startDate || course.endDate) && <Icon name="Calendar" size='medium' opacity={icon ? undefined : 0} />}
      <CourseDatesDisplay course={course} time={time} {...remaining} />
    </HBox>
  }

  return render();
}

CourseDates.defaultProps = {
  icon: true
}