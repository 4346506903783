import * as React from 'react'

import { Box, BoxProps } from '../Box';
import { Text } from '../Text';
import { FieldParent, useFormInfo } from '../form';
import { Delimeter, renderDelimitedList } from '../utils';

// Repeater is like RepeatingSection but without any pre-determined
// layout.  

// You can use Fields inside repeaters like RepeatingSection
// but just include them as children.

// You can style it as a box, but for that to take effect
// you need to specify layout=hbox or vbox.

export interface RepeaterProps<T, P extends keyof T> extends BoxProps {
  name?:string;
  items?:T[];
  render?:(record:T, index:number) => React.ReactNode;
  children?:React.ReactNode;
  component?:React.ComponentType<any>;
  delimeter?:Delimeter | React.ReactNode;
  none?:React.ReactNode;
  max?:number;
}

export function Repeater<T, P extends keyof T>(props:RepeaterProps<T, P>) {
  const {items, layout, none, renderItem, remaining} = useRepeaterItems(props);
  
  function render() {
    if (!items?.length) {
      return typeof none == 'string'
        ? <Text text='body'>{none}</Text>
        : <>{none}</>;
    }

    const renderedItems = renderDelimitedList(items, props.delimeter, renderItem);
    return layout ? <Box layout={layout} {...remaining}>{renderedItems}</Box> : renderedItems;
  }

  return render();
}

export function useRepeaterItems<T>(props:RepeaterProps<T, any>) {
  const {name, render:renderProp, children, component:Component, layout, items:itemsProp, delimeter:delimeterProp, none, max, ...remaining} = props;
  const formInfo = useFormInfo();
  const allItems = props.name ? formInfo?.form?.getValue(formInfo.parents, props.name) : itemsProp;
  const items = max ? allItems?.slice(0, max) : allItems;

  function renderItem(item:T, index:number) {
    const children = props.render
      ? props.render(item, index)
      : Component 
        ? <Component />
        : props.children || item

    return <FieldParent key={index} names={[props.name as any, index]}>{children}</FieldParent>
  }


  return {allItems, items, layout, none, renderItem, remaining};
}
