import * as React from 'react'
import * as ReactIs from 'react-is'
import { PopupPosition } from 'reactjs-popup/dist/types';

import { MultiContext } from '../utils/MultiContextProvider'
import { isPromise } from '../utils/isPromise'
import { EventLoggerContext, EventLogData } from './EventLogger'

export type OnClick = (event:React.MouseEvent<any>) => any;
export type OnClickOrElement = OnClick | React.ReactElement;

// createComponentBase is used by createComponent to add some default functionality to
// all components created with createComponent.  currently the base functionality is
// - automatically show modals if an element is specified as onClick or returned by onClick
// - show a tooltip if a tooltip property is specified
//
// there's no need to use this directly.  this functionality is inherited by all components.
//
// the base functionality is added by injecting another component in between the 
// component being created and the base of the component being created (which is usually
// an html tag).
//
// the injected component is created with a higher order function because it needs access
// to the previous base class, so we need a closure to have that.

// we can't import these directly due to circular import issues
// so these are hacks to get those classes
interface ModalType {
  show(modal:React.ReactElement):void;
};

let Modal:ModalType;
let Tooltip:React.JSXElementConstructor<any>;
let BreakpointsContainer:React.JSXElementConstructor<any>;

export interface ComponentBaseProps {
  tooltip?:React.ReactNode;
  tooltipPosition?:PopupPosition;
  onClick?:OnClickOrElement;
}

// only some components will use these
export interface OptionalComponentBaseProps {
  // customize the breakpoints used by the children of this container
  // this will insert an updated theme.  this will not affect the
  // properties on the container, those will use the parent theme.
  breakpoints?:number[];

  // changes the breakpoints to be based on the container
  // dimensions, instead of the browsers dimensions.
  containerBreakpoints?:boolean;

  // will log this event whenever the component is clicked
  clickEvent?:EventLogData;
}

const onHover = ['hover'];
const onHoverAndClick = ['hover', 'click'];

export function createComponentBase(Base:any) {
  // if this is already a component that was created via createComopent
  // then there's no reason to add the base yet again (in fact it causes issues)
  // we detect this via undocumented styled componentStyle property

  if (Base.componentStyle) {
    return Base;
  }

  const ComponentBase = React.forwardRef((props: ComponentBaseProps & OptionalComponentBaseProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, ref:any) => {
    const {onClick, tooltip, tooltipPosition, breakpoints, containerBreakpoints, clickEvent, ...remaining} = props;
    const hasOnClick = onClick != null || clickEvent != null;
    const context = React.useContext<EventLoggerContext>(MultiContext);

    getImports();

    function render() {
      let result = <Base onClick={hasOnClick ? handleClick : onClick} {...remaining} ref={ref} />;
      result = breakpoints || containerBreakpoints ? <BreakpointsContainer breakpoints={breakpoints} containerBreakpoints={containerBreakpoints}>{result}</BreakpointsContainer> : result;
      // if a tooltip is asked for and if the item is disabled, then we wrap in a div so the tooltip works
      result = tooltip ? <Tooltip offsetX={15} offsetY={-5} tip={tooltip} position={tooltipPosition} on={hasOnClick ? onHover : onHoverAndClick}>{(remaining as any).disabled ? <div style={{display:'flex'}}>{result}</div> : result}</Tooltip> : result;

      return result;
    }

    function handleClick(event:React.MouseEvent<HTMLElement>) {
      if (clickEvent && context.eventLogger) {
        if (typeof clickEvent == 'string') {
          context.eventLogger.log(clickEvent);
        }
        else {
          context.eventLogger.log(clickEvent.event, clickEvent.data);
        }
      }

      handleOnClickOrModal(onClick, event);
    }

    return render();
  });

  ComponentBase.displayName = Base;

  return ComponentBase;
}

export async function handleOnClickOrModal(onClick:OnClickOrElement, event:React.MouseEvent<HTMLElement>) {
  if (!onClick) {
    return;
  }

  const onClickIsElement = ReactIs.isElement(onClick);
  let result = onClickIsElement ? onClick : (onClick as OnClick)?.(event);

  if (isPromise(result)) {
    result = await result;
  }

  if (result && !event.isPropagationStopped() && !event.isDefaultPrevented() && ReactIs.isElement(result)) {
    event.stopPropagation();
    event.preventDefault();

    result = Modal.show(result);
  }

  return result;
}

function getImports() {
  if (!Modal) {
    // @ts-ignore
    Modal = window['__Modal'];
    // @ts-ignore
    Tooltip = window['__Tooltip'];
    // @ts-ignore
    BreakpointsContainer = window['__BreakpointsContainer'];
  }
}
