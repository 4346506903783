import * as React from 'react';

import { HBox, Icon, Section, RadioGroup } from 'app2/components';

export function ImmediatePayoutConfig() {
  return <Section label='Payout timing for school-run activities' description='Note that changing this only affects payouts for future purchases.'
   name='payoutConfig.autoSiteCompanyPayouts' component={RadioGroup} none={false} format={(val:any) => Boolean(val)}
    options={[{
      label: <HBox text='body' vAlign='center' gap='$4'>Receive payouts as funds become available<Icon name='Info' size='small' tooltip='You will receive payments incrementally, shortly after families pay for activities. This can provide a steady flow of funds but may result in multiple smaller payments.' /></HBox>,
      value: true, 
    }, {
      label: <HBox text='body' vAlign='center' gap='$4'>Receive payouts at the close of enrollment<Icon name='Info' size='small' tooltip='You will receive a lump sum payment once the enrollment period for an activity ends. This is ideal if you prefer handling finances in bulk, ensuring you have the total amount for the activity at one time, but it means waiting until the end of the enrollment period for any funds.' /></HBox>,
      value: false,
    }]}/>
}
