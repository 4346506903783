import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminFamilyStudentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminFamilyStudentsQuery = { family?: { students?: Array<{ id: string; name: string; parents: Array<{ id: string; name?: string | null | undefined }> }> | null | undefined } | null | undefined };

export const AdminFamilyStudentsDocument = gql`
  query AdminFamilyStudents($id: ID!) {
    family(family: $id) {
      students {
        id
        name
        parents {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminFamilyStudentsArgs = MakeOptional<UseQueryArgs<AdminFamilyStudentsQueryVariables, AdminFamilyStudentsQuery>, 'query'>;

export function useAdminFamilyStudentsQuery(options: AdminFamilyStudentsArgs = {}) {
  return useQuery<AdminFamilyStudentsQueryVariables, AdminFamilyStudentsQuery>({ query: AdminFamilyStudentsDocument, ...options });
}

export type AdminFamilyStudentsOptions = ExecQueryOptions<AdminFamilyStudentsQueryVariables>;

export function adminFamilyStudents(options: AdminFamilyStudentsOptions) {
  return executeQuery<AdminFamilyStudentsQuery, AdminFamilyStudentsQueryVariables>(AdminFamilyStudentsDocument, options);
}

export type AdminFamilyStudentsSelections = AdminFamilyStudentsQuery['family'];
