import * as React from 'react';

import { VirtualGridFactory, CellProps } from './VirtualGridFactory';
import { MeasuringHeightRenderer } from './MeasuringHeightRenderer';

// Provides the main implementation for supporting unknown row heights that 
// need to be measured.  When a row height is needed but not displayed, this
// uses a default row height, so that there can be an estimate on the total
// scrollable area.  When a row is visible, VirtualGrid calls measureRow, which
// determines the actual row height and then stores that value to be used in future
// rowHeight calls.

export interface MeasuredHeightFactoryHelper {
  getRowHeight(row:number, useDefault:boolean):number;
  measureRow(row:number, callback:() => void):void;
  reset(row:number):void;
  renderMeasurer():React.ReactElement;
}

export class MeasuredHeightFactory implements VirtualGridFactory, MeasuredHeightFactoryHelper {
  _source:VirtualGridFactory;
  _measurer:React.RefObject<MeasuringHeightRenderer>;
  _measured:number[];

  constructor(source:VirtualGridFactory) {
    this._source = source;
    this._measurer = React.createRef<MeasuringHeightRenderer>();
    this._measured = [];
  }

  get source():VirtualGridFactory {
    const source = this._source.source;

    return source || this._source;
  }

  get numRows():number {
    return this._source.numRows;
  }

  rowHeight(row:number):number {
    return this.getRowHeight(row, true);
  }

  get numCols():number {
    return this._source.numCols;
  }

  colWidth(col:number):number {
    return this._source.colWidth(col);
  }

  render(props:CellProps):React.ReactElement {
    return this._source.render(props);
  }

  getRowHeight(row:number, useDefault:boolean):number {
    const height = this._measured[row];

    if (height > 0) {
      return height;
    }

    if (!useDefault) {
      return undefined;
    }

    return height < 0 ? Math.abs(height) : this._source.rowHeight(row);
  }

  measureRow(row:number, callback:() => void) {
    if (!this._measurer.current) {
      callback();
      return;
    }

    this._measurer.current.factory = this;
    this._measurer.current.measure(row, (height:number) => {
      this._measured[row] = height
      callback();
    });
  }

  reset(row:number = -1) {
    // we attempt to keep old row heights after a reset so that if its only a
    // minor change, we don't fall back to default row heights and instead
    // keeping to the current row height while we measure.  
    
    // this matters especially for when using default row heights no scrollbar
    // is needed, but after measuring a scrollbar is needed. when this occurs,
    // the table will call reset because its width changed due to the scrollbar
    // appearing.  if we don't use the existing row height and go back to default
    // it can cause the scrollbar to disappear while measuring.  then this would
    // keep repeating infinitely.

    // we mark rows invalidated by making them negative. so this means
    // reset turns valid measurements to negative so that a) we know to remeasure, b) use that value as the base until measuring is done.

    // truncate the list down to size
    this._measured.splice(this._source.numRows);

    // reset everything after the specified row because currently we don't distinguish
    // between a row being invalidated or removed.  if its modified, we need to invalidate
    // that entry, but if its removed we'd need to remove that entry.  since we don't 
    // know we just mark them all invalid forward.
    const start = row === undefined || row === null || row === -1 || row > this._measured.length - 1 ? 0 : row;

    for (let pos = start; pos < this._measured.length; ++pos) {
      this._measured[pos] =  this._measured[pos] > 0 ? -this._measured[pos] : this._measured[pos];
    }
  }

  renderMeasurer():React.ReactElement {
    return <MeasuringHeightRenderer ref={this._measurer} />
  }
}

