import * as React from 'react'
import { UseQueryState } from 'urql';

import { FieldValue, FieldInfo, Link, Modal, useSafeState } from 'app2/components';
import { HrDataTable, MIN_COL_WIDTH } from 'app2/views/shared';

import { CompanyPage, SiteDirectoryCTA, useCompanyParameter } from '../shared'

import { SiteModal } from './SiteModal';
import { useCompanyVendorsQuery, CompanyVendorsSelections, CompanyVendorsQuery } from './generated'

export function Sites() {
  const company = useCompanyParameter();
  const queryOptions = { variables:{ company }};
  const [vendors, setVendor] = useSafeState<CompanyVendorsSelections['vendors']>([]);
  const [useCompliance, setUseCompliance] = useSafeState(false);

  function render() {
    return <CompanyPage title='Schools' above={<SiteDirectoryCTA />}>
      <HrDataTable header={{ icon: 'Home', title: 'Schools' }}
        queryHook={useCompanyVendorsQuery}
        queryOptions={queryOptions}
        queryResponseHandler={onQueryResponse}
        table={{ 
          none: 'No schools',
          cols: [
            {label: 'Name', name: 'site.name' as any, width:400, component: {underline: true, cursor: 'pointer', onClick: showSiteModal}},
            {label: 'Email', name: 'site.contact.email' as any, width:400},
            {label: 'Policies', name: 'site.policy.token' as any, format:((token:string) => <Link to={`/policies/${token}`}>Policies</Link>)},
            useCompliance && {label: 'Compliance', name: 'inCompliance', width:MIN_COL_WIDTH, format: getComplianceStatus}
          ]
         }}/>
    </CompanyPage>
  }

  function onQueryResponse(response:UseQueryState<CompanyVendorsQuery>) {
    const vendors = response?.data?.company?.vendors

    if (vendors) {
      setVendor(vendors);
      setUseCompliance(vendors.some(v => v.site.usingCompliance));
    }

    return {items:vendors};
  }

  function getComplianceStatus(inCompliance: boolean, _:any, info:FieldInfo<CompanyVendorsSelections['vendors'][0]>) {
    return !info.record.site.usingCompliance
      ? 'N/A' 
      : inCompliance 
        ? <FieldValue color='positive'>Complete</FieldValue> 
        : <FieldValue color='negative'>Incomplete</FieldValue>;
  }

  function showSiteModal(_:any, info:FieldInfo<CompanyVendorsSelections['vendors'][0]>) {
    const vendor = info.values[0];

    Modal.show(<SiteModal vendor={vendor.id} />);
  }


  return render();
}