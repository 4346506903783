import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyClearStripeTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type CompanyClearStripeTokenMutation = { companyClearStripeToken?: { id: string } | { id: string } | null | undefined };

export const CompanyClearStripeTokenDocument = gql`
  mutation CompanyClearStripeToken($id: ID!) {
    companyClearStripeToken(company: $id) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyClearStripeTokenOptions = ExecMutationOptions<CompanyClearStripeTokenMutationVariables>;

export function companyClearStripeToken(options: CompanyClearStripeTokenOptions) {
  return executeMutation<CompanyClearStripeTokenMutation, CompanyClearStripeTokenMutationVariables>(CompanyClearStripeTokenDocument, options);
}
