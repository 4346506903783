import * as React from 'react';

import { Panel, VBox, Text, Button, Checkbox, Section, InputField, Collapsible } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function CollapsibleDemos() {
  return <Demos name='Collapsible'>
    <Demo name='Box'>
      <VBox hAlign='left'>
        <Text text='subtitle1'>Visible</Text>
        <Button>Visible</Button>
        <Collapsible type='box'>
          <Text text='heading1'>Hidden</Text>
          <Text text='heading2'>Hidden</Text>
          <Checkbox label='Also hidden'></Checkbox>
        </Collapsible>
      </VBox>
    </Demo>

    <Demo name='Form' description='Collapsible type form uses FormContent to wrap children so its spaced like a form'>
      <Panel hAlign='left' title='A form'>
        <Section label='Name' component={InputField} />
        <Collapsible type='form'>
          <Section label='Hidden 1' component={InputField} />
          <Section label='Hidden 2' component={InputField} />
          <Checkbox label='Also hidden'></Checkbox>
        </Collapsible>
      </Panel>
    </Demo>
  </Demos>
}
