import * as React from 'react';

import { SiteFeeTypeEnum,  } from 'app2/api';
import { Dropdown, VBox, BoxProps, Subtitle2, Combobox, Part, useFormInfo, Input, CurrencyInput, PercentInput, FieldComponentProps, Field, FieldInfo, formatCurrency, formatPercent, useFormSubscription, Text } from 'app2/components';
import { FeeBreakdown, FeeBreakdownType } from 'app2/views';

interface FormWithSchoolFee {
  // only can be null for course
  feeType:SiteFeeTypeEnum | null;
  feeRate:number;
  // only provided for course
  effectiveSiteFeeType?:SiteFeeTypeEnum;
  effectiveSiteFeeRate?:number
}

interface Fields {
  feeType: FieldComponentProps<any>;
  feeRate: FieldComponentProps<any>;
}

interface Props extends BoxProps {
  fieldsConfig: Fields;
  fees:FeeBreakdownType;
}

export function SchoolFeeCombo(props: Props) {
  const {fieldsConfig, fees, ...remaining} = props;
  const fields = props.fieldsConfig;
  const formInfo = useFormInfo<FormWithSchoolFee>();
  const form = formInfo.form;

  useFormSubscription({field: 'feeType'});

  function render() {
    return <VBox width="100%">
      {renderTitle()}
      <VBox width="100%" {...remaining}>
        {renderFields()}
      </VBox>
    </VBox>
  }

  function renderTitle() {
    return !formInfo.editing ? '' : <Subtitle2 mb='$4'>Add a school fee</Subtitle2>;
  }

  function renderFields() {
    return !formInfo.editing ? renderDisplayFields() : renderEditableFields();
  }

  function renderDisplayFields() {
    return <Field {...fields.feeRate} label={undefined} />;
  }

  function renderEditableFields() {
    const course = form?.values;
    const none = !course?.feeType || course?.feeType == SiteFeeTypeEnum.NoFee;
    const fixed = course?.feeType == SiteFeeTypeEnum.AmountPerEnrollment;

    const input = none
      ? <Input disabled={true} /> // using Input for none ensures that 0 value is ignored and the placeholder is shown
      : fixed
        ? <CurrencyInput min={0} max={9999} />
        : <PercentInput /> 

    // use part below w/o labels, to get the error display feature of part (as field doesn't do any ui)
    return <>
      <Combobox mb="$4" width="100%" placeholder='Enter amount'
        dropdown={<Field {...fields.feeType} onChange={onChangeFeeType} />}
        input={<Part {...fields.feeRate} label={undefined} component={input} />}
      />
      <FeeBreakdown {...fees} />
    </>
  }

  function onChangeFeeType(value: string, info: FieldInfo<FormWithSchoolFee>) {
    form.setValue('feeRate', null);
  }

  return render();
}

function renderSchoolFeeDisplay(fees:FormWithSchoolFee) {
  if (!fees) {
    return '';
  }

  // feeType can be missing only for courses
  const useSchoolSettings = !fees.feeType;
  const feeRate = 'effectiveSiteFeeRate' in fees ? fees.effectiveSiteFeeRate : fees.feeRate;
  const feeType = !feeRate ? SiteFeeTypeEnum.NoFee : 'effectiveSiteFeeType' in fees ? fees.effectiveSiteFeeType : fees.feeType;

  const feeTypeOption = courseFeeOptions.find(option => option.value == feeType);
  const feeTypeLabel = feeTypeOption.label + (useSchoolSettings ? ' (Default school setting)' : '');

  const noFee = feeType == SiteFeeTypeEnum.NoFee;
  const percent = feeType == SiteFeeTypeEnum.PercentagePerEnrollment;
  const formatter = percent ? formatPercent : formatCurrency;
  const feeRateLabel = formatter(feeRate);

  return <VBox>
    <Text text='formlabel'>{feeTypeLabel}</Text>
    {!noFee && <Text text='body' mt='$8'>{feeRateLabel}</Text>}
  </VBox>
}

export const siteFeeOptions = [
  {
    label: 'No fee',
    value: SiteFeeTypeEnum.NoFee
  },
  {
    label: 'Fixed amount',
    value: SiteFeeTypeEnum.AmountPerEnrollment
  },
  {
    label: 'Percentage',
    value: SiteFeeTypeEnum.PercentagePerEnrollment
  }
];

export const courseFeeOptions = [
  {
    label: 'Use school settings',
    value: null
  },
  ...siteFeeOptions
];

export const siteFeeFields = {
  feeType: {
    name: 'feeType',
    label: 'School fee type',
    edit: {component: Dropdown, options: siteFeeOptions}
  },
  feeRate: {
    name: 'feeRate',
    label: 'School fee rate',
    display: {
      none: false,
      format: (value: any, field:any, info:FieldInfo<FormWithSchoolFee, 'feeType'>) => renderSchoolFeeDisplay(info.form.values)
    }
  },
}

export const courseFeeFields = {
  feeType: {
    ...siteFeeFields.feeType,
    edit: {component: Dropdown, options: courseFeeOptions}
  },
  feeRate: {
    ...siteFeeFields.feeRate,
  },
}
