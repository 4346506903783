import * as React from 'react';

import { Site } from 'app2/api'
import { Link, LinkProps, FieldInfo } from 'app2/components'

interface Props extends LinkProps {
  info?:FieldInfo<Site>;
  site?:Partial<Site>;
}

export function SiteLink(props:Props) {
  let {info, site, ...remaining} = props;
  site = site || info.record;

  return <Link text='body' to={`/organizer/${site.slug}`} {...remaining}>{site.name}</Link>
}

SiteLink.fieldProps = {
  fieldProperty: 'info',
}