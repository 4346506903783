import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EligibleDiscountsQueryVariables = Types.Exact<{
  enrollments?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  course?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type EligibleDiscountsQuery = {
  eligibleDiscounts: Array<
    | {
        id: string;
        code: string;
        amount: number;
        format: Types.DiscountDefinitionFormat;
        kind: Types.DiscountKind;
        owner: { name?: string | null | undefined } | { name?: string | null | undefined } | { name: string } | { name: string } | { name?: string | null | undefined };
      }
    | null
    | undefined
  >;
};

export const EligibleDiscountsDocument = gql`
  query EligibleDiscounts($enrollments: [ID!], $course: ID) {
    eligibleDiscounts(enrollments: $enrollments, course: $course) {
      id
      code
      amount
      format
      kind
      owner {
        name
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EligibleDiscountsArgs = MakeOptional<UseQueryArgs<EligibleDiscountsQueryVariables, EligibleDiscountsQuery>, 'query'>;

export function useEligibleDiscountsQuery(options: EligibleDiscountsArgs = {}) {
  return useQuery<EligibleDiscountsQueryVariables, EligibleDiscountsQuery>({ query: EligibleDiscountsDocument, ...options });
}

export type EligibleDiscountsOptions = ExecQueryOptions<EligibleDiscountsQueryVariables>;

export function eligibleDiscounts(options: EligibleDiscountsOptions) {
  return executeQuery<EligibleDiscountsQuery, EligibleDiscountsQueryVariables>(EligibleDiscountsDocument, options);
}

export type EligibleDiscountsSelections = EligibleDiscountsQuery['eligibleDiscounts'][0];

(EligibleDiscountsDocument as any).additionalTypenames = ['DiscountDefinition'];
