import * as React from 'react';
import { useParams } from 'react-router';

import { Panel, Section, RepeatingSection, Field } from 'app2/components';

import { AdminSiteRouteParams } from '../adminRoutes';
import { CourseLink, SeasonLink, SiteLink } from '../shared';

import { useAdminCompanyCoursesQuery } from './generated';

export function Courses() {
  const { id } = useParams<AdminSiteRouteParams>();
  const [result] = useAdminCompanyCoursesQuery({ variables: { id } });
  const company = result.data?.company

  function render() {
    return <Panel icon="Info" title="Courses" initialValues={company} type="display">
      <Section label="ID" name="id" readOnly />
      <Section label='Name' name='name' />
      <Section label='Activities'>
        <RepeatingSection name='courses' fields={[
          <Field name='name' label='Name' component={CourseLink} />,
          <Field name='status' label='Status' />,
          <Field name='season.name' label='Season' component={SeasonLink} />,
          <Field name='site.name' label='Site' component={SiteLink} />
        ]} />
      </Section>
    </Panel>
  }

  return render();
}
