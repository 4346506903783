import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteUserMutation = { userDelete?: { id: string } | null | undefined };

export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    userDelete(user: $id) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type DeleteUserOptions = ExecMutationOptions<DeleteUserMutationVariables>;

export function deleteUser(options: DeleteUserOptions) {
  return executeMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
