import * as React from 'react';

import { CourseUtils, EnrollmentStatusFilter, RateType, RosterGroupingKind, authorizedCoursePreferenceKeys } from 'app2/api';
import { DataTable, MenuItem, useRef } from 'app2/components';
import { byActivity, ContentType, downloadRoster } from 'app2/views/shared';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { renderRefundButton } from '../actions/refund';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { RemovedEnrollmentsSelections, useRemovedEnrollmentsQuery } from './generated';
import { ReenrollAction } from '../actions/reenroll';

const TABLE_PREFS_VERSION = '5';

interface Props {
  course: CourseSelections;
}

export function Removed(props: Props) {
  const cols = useCourseEnrollmentCols<RemovedEnrollmentsSelections>(removedCols, props.course, EnrollmentStatusFilter.Removed);
  const tableRef = useRef<DataTable<RemovedEnrollmentsSelections>>();

  function render() {
    if (CourseUtils.getRateOrPriceType(props.course?.prices) !== RateType.basic) {
      return null;
    }

    return (
      <EnrollmentsTable<RemovedEnrollmentsSelections>
        course={props.course}
        header={{ icon: 'User', title: 'Removed', options: renderOptions(), secondaryActions: renderSecondaryActions() }}
        table={{ cols, ref: tableRef, none: 'No removed students' }}
        view={{table:'course-removed'}}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.removedEnrollmentsTable}
        queryHook={useRemovedEnrollmentsQuery}
      />
    );
  }

  function renderOptions() {
    return [
      <MenuItem label="Download roster PDF" onClick={handleDownloadRosterPDF} />,
      <MenuItem label="Download roster CSV" onClick={handleDownloadRosterCSV} />
    ];
  }

  function renderSecondaryActions() {
    return [<ReenrollAction course={props.course} table={tableRef.current as DataTable<any>} />, renderRefundButton(props.course, tableRef.current)];
  }

  function handleDownloadRosterPDF() {
    return downloadRosterHelper(ContentType.PDF);
  }

  function handleDownloadRosterCSV() {
    return downloadRosterHelper(ContentType.CSV);
  }

  function downloadRosterHelper(type:ContentType) {
    const variables = {groupingId: props.course?.id, groupingKind: RosterGroupingKind.Course, enrollmentStatus: EnrollmentStatusFilter.Removed} 

    return downloadRoster(['Homeroom removed roster', props.course.name], type, 'rosterByGrouping', variables, tableRef.current, byActivity, ['title', 'groups', 'cols']);
  }

  return render();
}

export const removedCols = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'rosterPeriod',
  'student.classroom.displayName',
  'dismissal',
  'parent.name',
  'parent.phone',
  'parent.email',
  'groups'
];
