import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetAttendanceMutationVariables = Types.Exact<{
  attendances: Array<Types.AttendanceInput> | Types.AttendanceInput;
}>;

export type SetAttendanceMutation = { enrollmentsSetAttendance?: { attendances?: Array<{ id: string }> | null | undefined } | null | undefined };

export const SetAttendanceDocument = gql`
  mutation SetAttendance($attendances: [AttendanceInput!]!) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    enrollmentsSetAttendance(attendances: $attendances) {
      attendances {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SetAttendanceOptions = ExecMutationOptions<SetAttendanceMutationVariables>;

export function setAttendance(options: SetAttendanceOptions) {
  return executeMutation<SetAttendanceMutation, SetAttendanceMutationVariables>(SetAttendanceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['RosterItem', 'Enrollment']) }
  });
}
