import * as React from 'react';

import { OrganizerPage } from '../shared';

import { SiteHeader } from './SiteHeader';
import { CreateSeason } from './CreateSeason';
import { CurrentSeason } from './CurrentSeason';

export function Season() {
  return (
    <OrganizerPage title="Season">
      <SiteHeader />
      <CreateSeason />
      <CurrentSeason />
    </OrganizerPage>
  );
}
