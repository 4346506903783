import * as React from 'react';

import { EntityKind } from 'app2/api';
import { UnifiedTeam } from 'app2/views/shared';

import { useCurrentSite } from '../shared';

export function Team() {
  const { site } = useCurrentSite();

  return <UnifiedTeam entityKind={EntityKind.Site} entityId={site?.id} cols={cols} numbered panel editing={true} />
}

const cols = ['email', 'role', 'isContact', 'sentAt', 'resendInvite']