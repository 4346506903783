import * as React from 'react';
import { sortBy } from 'lodash-es';

import { Dropdown, Section, Panel, useFormInfo } from 'app2/components';

import { CheckoutModel } from './CheckoutModel';
import { parentSetPriceTier, ParentUserWithCartSelections } from './generated';

export function PriceTier() {
  const form = useFormInfo<CheckoutModel>().form;
  const cart = form.values.cart;
  const site = cart?.sites?.find(s => s.priceTiers.length);
  const options = React.useMemo(getOptions, [cart]);

  function render() {
    if (!site?.priceTiers) {
      return null;
    }

    return (
      <Panel icon="DollarSign" title="Price tier (if applicable)" hAlign="left">
        <Section name="cart.priceTierUse.priceTierId" description='Select the tier that applies to your family' component={Dropdown} autoLoader options={options} onChange={onChange} width="350px" maxWidth="100%" clearable disablePhoneInput />
      </Panel>
    );
  }

  async function onChange() {
    const id = cart.priceTierUse.priceTierId || null;
    const [success] = await parentSetPriceTier({ variables: { id } });
    return success;
  }

  function getOptions() {
    let priceTiers = site?.priceTiers || [];
    const cartedPriceTier = cart?.priceTierUse;

    // TODO: Do this on the server.
    if (cartedPriceTier) {
      priceTiers = priceTiers.filter(p => p.id !== cartedPriceTier.priceTierId);
      priceTiers.push(cartedPriceTier);
    }

    return sortBy(priceTiers, 'displayOrder').map(pt => ({ label: pt.name, value: (pt as CartedPriceTier).priceTierId || (pt as SitePriceTier).id }));;
  }

  return render();
}

type CartedPriceTier = ParentUserWithCartSelections['cart']['priceTierUse'];
type SitePriceTier = ParentUserWithCartSelections['cart']['sites'][0]['priceTiers'][0];
