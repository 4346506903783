import * as React from 'react';
import moment from 'moment'

import { DatePicker, HBox, Calendar } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function DatePickerDemos() {
  const [single, setSingle] = React.useState<moment.Moment>(moment());
  const [noEnrichment, setNoEnrichment] = React.useState<moment.Moment[]>([]);
  const [range, setRange] = React.useState<moment.Moment[]>([]);

  return <Demos name='DatePicker'>
    <Demo name='Basic'>
      <DatePicker />
    </Demo>

    <Demo name='Controlled'>
      <DatePicker value={single} onChange={event => setSingle(event.currentTarget.value as moment.Moment)} />
      <span>you selected:{single ? moment(single).format('MM/DD/YYYY') : ''}</span>
    </Demo>

    <Demo name='Min today'>
      <DatePicker value={single} onChange={event => setSingle(event.currentTarget.value as moment.Moment)} min='now' />
      <span>you selected:{single ? moment(single).format('MM/DD/YYYY') : ''}</span>
    </Demo>

    <Demo name='Range'>
      <HBox vAlign='top'>
        <DatePicker type='range-start' value={range} onChange={(event) => setRange(event.currentTarget.value as moment.Moment[])} width='300px' />
        <DatePicker type='range-end' value={range} onChange={(event) => setRange(event.currentTarget.value as moment.Moment[])} width='300px' />
      </HBox>
    </Demo>

    <Demo name='Multiple, controlled'>
      <HBox vAlign='top'>
        <DatePicker type='multiple' value={noEnrichment} onChange={(event) => setNoEnrichment(event.currentTarget.value as moment.Moment[])} width='300px' />
        <Calendar legend={[{name: 'No enrichment days', bg: 'noEnrichment', days:noEnrichment}]} />
      </HBox>
    </Demo>

    <Demo name='Period menu'>
      <DatePicker period type='range' />
    </Demo>

    <Demo name='Error'>
      <DatePicker error />
    </Demo>
  </Demos>
}

