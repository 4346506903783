import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentMedicalFieldsFragmentDoc } from './studentMedicalFields.generated';
export type StudentDetailsFieldsFragment = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  nickname?: string | null | undefined;
  dob?: string | null | undefined;
  grade: string;
  automaticGradeUpdate: boolean;
  notes?: string | null | undefined;
  site?: { id: string; name?: string | null | undefined } | null | undefined;
  classroom?: { id: string; name: string; grade: string } | null | undefined;
  parents: Array<{ id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
  medical?:
    | {
        epiPen?: boolean | null | undefined;
        conditions?: string | null | undefined;
        allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
        medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const StudentDetailsFieldsFragmentDoc = gql`
  fragment studentDetailsFields on Student {
    id
    name
    firstName
    lastName
    nickname
    dob
    grade
    automaticGradeUpdate
    site {
      id
      name
    }
    classroom {
      id
      name
      grade
    }
    ...studentMedicalFields
    parents {
      id
      name
      phone
      email
    }
  }
  ${StudentMedicalFieldsFragmentDoc}
`;
