import * as React from 'react';

import { HBox, Form, Icon, Input, InputField, Field, Radio, RadioGroup, Subtitle2, VBox } from 'app/app2/components';

import { Demo, Demos } from './Demo';

export function RadioDemos() {
  return <Demos name='Radio'
        description={`
        Radio supports both a single radio and when there are controls associated with the radio.

        - Use the label property to set the radio label.  Do not use children.
        
        - Use the children property to place controls underneath (and indented) that are associated with the Radio.

        - You can provide non-text React elements for the label.
      `}>
    {renderRadioVariations()}
    <Demo name='With children'>
      <Radio label='Submit'>
        <Input />
      </Radio>
    </Demo>
    <Demo name='With components as a label'>
      <Radio label={<><Icon name='CreditCard' /> Credit card</>}/>
    </Demo>
    <Demo name='Radio group, controled'
    description={`
      RadioGroup allows specifying a list of options as an array.  
    `}>
      <RadioGroup selected='19-30' options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>
    <Demo name='Radio group with inputs'
    description={`
      Any option can have an input control
    `}>
      <Form>
        <RadioGroup options={[
          {label:'0-18', value:'0-18'},
          {label:'19-30', value:'19-30'},
          {label:'30-50', value:'30-50'},
          {label:'51+', value:'51+'},
          {label:'Other', value:'other', input: <Field name='other' component={InputField} />}
        ]} />
      </Form>
    </Demo>
    <Demo name='Radio group, disabled'>
      <RadioGroup disabled options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>
    <Demo name='Radio group, error'>
      <RadioGroup error options={[
        {label:'0-18', value:'0-18'},
        {label:'19-30', value:'19-30'},
        {label:'30-50', value:'30-50'},
        {label:'51+', value:'51+'}
      ]} />
    </Demo>
    <Demo name='On a background'>
      <VBox padding='50px' bg='red'>
        <Radio label='Submit'/>
      </VBox>
    </Demo>
    <Demo name='Various styles'>
      <Radio m='$10' px='50px' border='solid 10px blue' color='red' background='purple' cursor='pointer' label='Submit'/> 
    </Demo>
    <Demo name='Custom CSS'>
      <Radio css={{':hover':{border: 'solid 10px red!important'}}} label='Move the mouse over me'/>
    </Demo>
    <Demo name='Using theme props'>
      <Radio fontSize='heading2' bg='brandLight' label='Submit' /> 
    </Demo>
  </Demos>
}

function renderRadioVariations() {
  return <>
    <Demo name='Basic'>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled</Subtitle2>
        <Radio name="group1" label='Radio'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, checked</Subtitle2>
        <Radio checked name="group1" label='Radio'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, error</Subtitle2>
        <Radio name="group2" error label='Radio'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Enabled, checked, error</Subtitle2>
        <Radio checked name="group2" error label='Radio'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Disabled</Subtitle2>
        <Radio disabled label='Radio'/>
      </HBox>
      <HBox hItemSpace='15px' pb='15px' vAlign='center'>
        <Subtitle2 width='70px'>Disabled</Subtitle2>
        <Radio disabled checked label='Radio'/>
      </HBox>
    </Demo>
  </>
}
