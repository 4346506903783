import { sleep } from '../dom-utils';

import { ShieldProvider } from "./ShieldProvider";

export class ShieldConsumer {
  provider:ShieldProvider;

  constructor(provider:ShieldProvider) {
    this.provider = provider;
  }

  shield(on:boolean):void {
    return this.provider?.shield(on, this);
  }

  get showingShield():boolean {
    return this.provider?.showingShield;
  }

  loader(on:boolean):void {
    return this.provider?.loader(on, this);
  }
  
  get showingLoader():boolean {
    return this.provider?.showingLoader;
  }

  async waitForLoader(maxWait?:number) {
    const start = Date.now();

    while (this.showingLoader && (maxWait === undefined || maxWait === null || Date.now() - start < maxWait)) {
      await sleep(250);
    }
  }
}
