import * as React from 'react'

import { Field } from 'app2/components';

import { HistoryLink, MutationChangesLink, FamilyLink, QueryList } from '../shared';

import { useAdminUserFamiliesQuery } from './generated'

export function Families() {
  return <QueryList icon='Home' title='Families' query={useAdminUserFamiliesQuery} name='families' fields={[
    <Field name='id' label='ID' />,
    <Field name='name' label='Name' component={FamilyLink} />,
    <Field name='id' label='History' component={MutationChangesLink} type='Family' />,
    <Field name='id' label='History (old)' component={HistoryLink} type='Family' />,
  ]} />
}