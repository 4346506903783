import { ErrorWithPath } from './ErrorWithPath'

export type ErrorHandlerFunction = (errors:ErrorWithPath[]) => ErrorWithPath[] | null | void;

export interface ErrorHandlerInterface {
  handleErrors:ErrorHandlerFunction;
}

// error handlers should return errors they don't handle
// error handlers can be a function or a member method
export type ErrorHandler = ErrorHandlerFunction | ErrorHandlerInterface;

export function applyErrorHandlers(errorHandlerOrHandlers:ErrorHandler | ErrorHandler[], errors:ErrorWithPath[]):ErrorWithPath[] {
  const errorHandlers = errorHandlersFrom(errorHandlerOrHandlers);
  errors = errors.slice();

  errorHandlers.forEach(errorHandler => {
    if (!errors.length) {
      return errors;
    }
    
    errors = (typeof errorHandler == 'function' ? errorHandler(errors) : errorHandler.handleErrors(errors)) || [];
  });

  return errors;
}

export function errorHandlersFrom(errorHandlerOrHandlers:ErrorHandler | ErrorHandler[]):ErrorHandler[] {
  const errorHandlers = !errorHandlerOrHandlers
    ? []
    : Array.isArray(errorHandlerOrHandlers) 
      ? errorHandlerOrHandlers.slice() 
      : [errorHandlerOrHandlers];

  return errorHandlers;
}

