import * as React from 'react';
// @ts-ignore
import { StripeProvider } from 'react-stripe-elements';

import { ScriptLoader, ScriptLoaderComponentState, ScriptLoaderComponentProps } from '../ScriptLoader';

import { stripeUrl, updateStripeUrlIfNeeded } from './StripeLoader';
import { loadStripeHelper } from './loadStripeHelper';

interface State extends ScriptLoaderComponentState {
  stripe?: any;
}

export class SafeStripeProvider extends ScriptLoader<ScriptLoaderComponentProps, State> {
  static defaultProps = {
    scripts: [{ script: stripeUrl, isUrl: true, blockInTestMode: true }]
  };

  constructor(props: ScriptLoaderComponentProps) {
    updateStripeUrlIfNeeded(props);
    super(props);

    // stripe has to be null not undefined, before its loaded
    this.state = Object.assign(this.state, { stripe: null });
  }

  render() {
    return <StripeProvider stripe={this.state.stripe}>
      {this.props.children}
    </StripeProvider>
  }

  onScriptsLoaded() {
    try {
      this.setState({ stripe: loadStripeHelper() });
    }
    catch(e) {
      console.warn('Stripe not loaded', e);
    }
  }
}
