import * as React from 'react'

import { Button, ButtonProps } from './Button'
import { List, ListProps } from './list';
import { Popup, PopupProps } from './Popup';

export interface ButtonListProps extends Omit<ButtonProps, 'value' | 'onChange'>, Pick<ListProps, 'value' | 'onChange' | 'options' | 'multiple' | 'selectedStyle'>, Pick<PopupProps, 'dropdownWidth'> {
}

export function ButtonList(props:ButtonListProps) {
  const {value, options, multiple, selectedStyle, dropdownWidth, onChange, ...remaining} = props;

  return <Popup trigger={<Button {...remaining} />} sizeDropdownToTrigger={!dropdownWidth} dropdownWidth={dropdownWidth}>
    <List value={value} onChange={onChange} options={options} multiple={multiple} selectedStyle={selectedStyle} width='100%'/>
  </Popup>
}