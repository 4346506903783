export const byClassroom = {
  title: [
    {cols:['student.classroom.displayName', 'weekday', 'variables.date', 'groups'], separator: ' - '},
    {cols:['course.season.name', 'course.site.name'], separator: '; '}
  ],
  groups: ['student.classroom.displayName', 'weekday', 'groups'],
  // only one column sort is supported for now
  sort: ['formattedDays', 'course.name'],
  cols: ['formattedDays', 'formattedTimes', 'course.name', 'course.room', 'student.firstName', 'student.lastName', 
    'otherEnrollments', 'rosterPeriod', 'added', 'pickup', 'dismissal', 'student.formattedMedicalAndNotes'],
}
