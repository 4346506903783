import { OperationContext } from "urql";

import { Query } from "app2/api";

export function applyAdditionalTypenames<D, V>(query: Query<D, V>, context: Partial<OperationContext>) {
  const additionalTypenames = (query as any).additionalTypenames;
  if (!additionalTypenames) {
    return context;
  }

  return {
    ...context,
    additionalTypenames: (context?.additionalTypenames || []).concat(additionalTypenames)
  };
}