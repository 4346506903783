import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateDonationConfigMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  donationConfig: Types.DonationConfigInput;
}>;

export type OrganizerUpdateDonationConfigMutation = { siteUpdateDonationConfig?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateDonationConfigDocument = gql`
  mutation OrganizerUpdateDonationConfig($siteId: ID!, $donationConfig: DonationConfigInput!) {
    siteUpdateDonationConfig(siteId: $siteId, donationConfig: $donationConfig) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateDonationConfigOptions = ExecMutationOptions<OrganizerUpdateDonationConfigMutationVariables>;

export function organizerUpdateDonationConfig(options: OrganizerUpdateDonationConfigOptions) {
  return executeMutation<OrganizerUpdateDonationConfigMutation, OrganizerUpdateDonationConfigMutationVariables>(OrganizerUpdateDonationConfigDocument, options);
}
