import * as React from 'react';

import { organizerPreferences } from 'app2/api';
import { Dropdown, HBox, Subtitle1 } from 'app2/components';
import { createOptions, useDropdownWithUrl } from 'app2/views/shared-public';

import { useSites, OrganizerSitesSelections } from '.'

export function SiteDropdown() {
  const { sites, loading } = useSites();
  const options = React.useMemo(() => createOptions(sites, sites?.length > 1 ? getSiteNameCityState : undefined), [sites]);
  const valueAndOnChange = useDropdownWithUrl({options, preferences: organizerPreferences, urlParameter: 'site', valueParameter: 'slug'});

  const fetching = Boolean(loading || !valueAndOnChange.value);
  const singleSite = Boolean(options.length == 1 && valueAndOnChange.value == options?.[0]?.value)

  return fetching
    ? <></>
    : singleSite
      ? <Subtitle1 text='subtitle2'>{options[0].label}</Subtitle1>
      : <HBox flex={1} minWidth='280px' vAlign='center' hAlign='right'>
        <Subtitle1 mr='$8'>School</Subtitle1>
        <Dropdown flex={[1, 'unset', 'unset']} options={options} {...valueAndOnChange} tooltips
          // @ts-ignore - ts is incorrectly generating an error here
          width={['unset', '300px', '300px']} />
      </HBox>
}

function getSiteNameCityState(site:OrganizerSitesSelections['sites'][0]) {
  return `${site.name} ${site.city ? `(${site.city}, ${site.state})` : ''}`;
}