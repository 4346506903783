import * as React from 'react';

import { CourseOptionKind, Course } from 'app2/api';
import { Field, Panel, RepeatingSection } from 'app2/components';

import { CourseOptionEdit } from './CourseOptionEdit';
import { ActivityForm } from '../../ActivityForm';

interface Props {
  form: ActivityForm;
  onOk: (form: ActivityForm) => Promise<boolean>;
  season:string;
}

export function CourseOptionsEditor(props:Props) {
  const form = props.form;
  const course = form?.values as unknown as Course;

  function render() {
    return <Panel icon="Star" title="Activity options" type="toggle" form={form} onOk={props.onOk}>
      <RepeatingSection name='options' add='Add option' defaultRecord={defaultOption} fields={[
        <Field component={CourseOptionEdit} season={props.season} course={course.id} />,
        'remove'
      ]} />
    </Panel>
  }

  return render();
}

const defaultOption = {kind: CourseOptionKind.SingleChoice, checkout: true}