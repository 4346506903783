import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerDeleteSeasonMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type OrganizerDeleteSeasonMutation = { seasonsDelete?: { seasons?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const OrganizerDeleteSeasonDocument = gql`
  mutation OrganizerDeleteSeason($siteId: ID!, $ids: [ID!]!) {
    seasonsDelete(siteId: $siteId, ids: $ids) {
      seasons {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerDeleteSeasonOptions = ExecMutationOptions<OrganizerDeleteSeasonMutationVariables>;

export function organizerDeleteSeason(options: OrganizerDeleteSeasonOptions) {
  return executeMutation<OrganizerDeleteSeasonMutation, OrganizerDeleteSeasonMutationVariables>(OrganizerDeleteSeasonDocument, options);
}
