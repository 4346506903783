import * as React from 'react'
import { flatten } from 'lodash-es';

import { MessageRecipient, Message, DeepPartial } from 'app2/api';

export function useMessageRecipients(message?:DeepPartial<Message>):DeepPartial<MessageRecipient>[]{
  return React.useMemo(() => {
    if (!message) {
      return []
    }

    return flatten([...message.to, ...message.cc, ...message.bcc])
  }, [message]);
}
