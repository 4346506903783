import * as React from 'react'

import { Course, CourseKind, DeepPartial } from 'app2/api';
import { defaultFieldProps, HBox, Icon, FieldInfo, TextProps } from 'app2/components'
import { CourseLink } from 'app2/views/shared-public'

interface Props extends Omit<TextProps, 'role'> {
  info?:FieldInfo<DeepPartial<Pick<Course, 'id' | 'name' | 'children' | 'kind'>>>;
  course?:DeepPartial<Pick<Course, 'id' | 'name' | 'kind'> & Partial<Pick<Course, 'children'>>>;
}

export function CourseLessonsIcon(props:Props) {
  let {info, course, ...remaining} = props;
  course = course || info?.record;

  function render() {
    if (course?.kind != CourseKind.LessonSet) {
      return <></>;
    }

    const variants = course.children || [];

    return <CourseLink position='relative' overflow='visible!important' display='inline-block!important' width='28px' height='15px' info={info as any} course={course as any} suffix='/time-slots' {...remaining}>
      <HBox display='inline-flex' vAlign='top' position='absolute'>
        <Icon name='Clock' />
        <HBox color='primaryInverse' bg='primary' zIndex={1} ml='-6px' borderRadius='8px' fontSize='10px' fontWeight='bold' width='14px' height='14px' hAlign='center' vAlign='center'>{variants.length}</HBox>
      </HBox>
    </CourseLink>
  }

  return render();
}

CourseLessonsIcon.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'info'
}
