import * as React from 'react';
import moment from 'moment';
import { isEqual, uniqBy, groupBy, orderBy, sumBy } from 'lodash-es';
import pluralize from 'pluralize';
import { commaListsAnd } from 'common-tags';

import { CartUtils, DeepPartial, EnrollmentUtils } from 'app2/api';
import { Button, commaAnd, Checkbox, Field, HBox, Link, Repeater, Text, VBox, formatCurrency, formatDate, useFormInfo } from 'app2/components';
import { coursePriceBillingAdverbs, marketingUrl, PublicSiteLink } from 'app2/views/shared-public';
import { FileLink } from 'app2/views/shared';

import { CheckoutModel } from '../CheckoutModel';

import { parentAcceptTermsOfService } from './generated';

type Enrollment = DeepPartial<CheckoutModel['cart']['enrollments'][0]>; 

interface Props {
  purchaseSummary?:boolean;
}

export function Total(props:Props) {
  const formUi = useFormInfo<CheckoutModel>();
  const form = formUi.form;
  const cart = form.values.cart;
  const { visible: enrollments, billedLater, installments, enrollmentCount, ongoingTemplateCount } = React.useMemo(() => CartUtils.classifyEnrollments(cart), [cart.enrollments]);
  const recurringEnrollments = filterRecurring(enrollments);
  const { discountTotal, donationAmount, dueLater, priceTierDiscountTotal, subtotal, total } = totals();

  function render() {
    return (
      <VBox vItemSpace="$16" hAlign="justify" width="100%" maxWidth="390px" mb="$16">
        {renderSubtotal()}
        {renderDiscounts()}
        {renderPriceTierDiscounts()}
        {renderDonation()}
        {renderDueNow()}
        {renderInstallments()}
        {renderDueLater()}
        {renderTos()}
        {renderWaivers()}
        {renderBillLaterAuthorization()}
        {renderConfirmAndPay()}
      </VBox>
    );
  }

  function renderSubtotal() {
    if (isEqual(subtotal, total)) {
      return null;
    }

    return (
      <HBox hAlign="justify" mb="$16">
        <Text text="subtitle1">Subtotal:</Text>
        <Text text="subtitle1">{formatCurrency(subtotal)}</Text>
      </HBox>
    );
  }

  function renderDiscounts() {
    if (!discountTotal) {
      return null;
    }

    return (
      <HBox hAlign="justify" mb="$16">
        <Text text="subtitle1">Discounts:</Text>
        <Text text="subtitle1">-{formatCurrency(discountTotal)}</Text>
      </HBox>
    );
  }

  function renderPriceTierDiscounts() {
    if (!priceTierDiscountTotal) {
      return null;
    }

    return (
      <HBox hAlign="justify" mb="$16">
        <Text text="subtitle1">Price tier discounts:</Text>
        <Text text="subtitle1">-{formatCurrency(priceTierDiscountTotal)}</Text>
      </HBox>
    );
  }

  function renderDonation() {
    if (!donationAmount) {
      return null;
    }

    return (
      <HBox hAlign="justify" mb="$16">
        <Text text="subtitle1">Donation:</Text>
        <Text text="subtitle1">+{formatCurrency(donationAmount)}</Text>
      </HBox>
    );
  }

  function renderDueNow() {
    return (
      <>
        <HBox hAlign="justify" mb="$16">
          <Text text="subtitle1">{!props.purchaseSummary ? `Total charged today:` : 'Paid:'}</Text>
          <Text text="subtitle1">{formatCurrency(total)}</Text>
        </HBox>
        {renderRecurring()}
      </>
    );
  }

  function renderInstallments() {
    if (!installments.length || props.purchaseSummary) {
      return;
    }

    const grouped = orderBy(Object.entries(groupBy(installments, 'priceConfig.billingDate'))
      .map(([date, items]) => ({
        date,
        amount: sumBy(items, 'amount')
      })),
      'priceConfig.billingDate', ['asc']);

    return grouped.map(({ date, amount }) => (
      <HBox hAlign="justify" mb="$16" key={date}>
        <Text text="subtitle1" fontWeight="normal" ml='$20'>Charged {formatDate(date, 'abbreviated')}:</Text>
        <Text text="subtitle1">{formatCurrency(amount)}</Text>
      </HBox>      
    ));
  }

  // - https://joinhomeroom.slack.com/archives/C03VDL4147K/p1687356981727359
  // - https://github.com/homeroom/frontend/pull/3149#issue-1766383154
  function renderRecurring() {
    return Object.entries(groupRecurring(recurringEnrollments)).map(([key, enrollments]) => {
      const [_kind, rawDate] = key.split('&');
      const date = formatDate(rawDate, 'abbreviated');
      const amount = formatCurrency(enrollments.reduce((sum, e) => sum + e.firstPayment.recurringAmount || 0, 0));
      const unit = EnrollmentUtils.unit(enrollments[0]);
      const billingAdverb = coursePriceBillingAdverbs[unit];

      return (
        <HBox key={key} hAlign="justify" mb="$16">
          <Text text="subtitle1" fontWeight="normal">
            Billed {billingAdverb} starting {date}:
          </Text>
          <Text text="subtitle1">{amount}</Text>
        </HBox>
      );
    });
  }

  function renderDueLater() {
    if (!dueLater) {
      return null;
    }

    return (
      <HBox hAlign="justify" mb="$16">
        <Text text="subtitle1">Due later:</Text>
        <Text text="subtitle1">{formatCurrency(dueLater)}</Text>
      </HBox>
    );
  }

  function renderTos() {
    if (props.purchaseSummary) {
      return;
    }

    return <Field name="user.tosAccepted" label={renderTosLabel()} required component={Checkbox} boolean onChange={onChangeTosAccepted} />
  }

  function renderTosLabel() {
    return (
      <div>
        I agree to Homeroom’s&nbsp;
        <Link to={marketingUrl('/tou')} target="_blank">
          Terms of Use
        </Link>
        &nbsp;and&nbsp;
        <Link to={marketingUrl('/privacy')} target="_blank">
          Privacy Policy
        </Link>
      </div>
    );
  }

  function hasWaivers() {
    return cart?.sites?.find(s => s.waivers?.length > 0) != null && !props.purchaseSummary;
  }

  function renderWaivers() {
    return hasWaivers() && <Field name="acceptedWaivers" label={renderAllSiteWaiversLabel()} required boolean component={Checkbox} />
  }

  function renderBillLaterAuthorization() {
    if (props.purchaseSummary) {
      return;
    }

    if (!billedLater?.length) {
      return;
    }
    
    const ongoingBillingPeriods = commaListsAnd`${[...new Set(billedLater.map(e => coursePriceBillingAdverbs[EnrollmentUtils.unit(e)]))]}`;
    const ongoingLabel = `I authorize Homeroom to charge my payment on file for each ${ongoingBillingPeriods} payment.`

    return <Field name="user.ongoingBillingAccepted" label={ongoingLabel} required component={Checkbox} boolean />
  }

  function renderAllSiteWaiversLabel() {
    return (
      <span>
        I accept the terms & conditions outlined by&nbsp;
        <Repeater name="cart.sites">
          <Field name="name" component={PublicSiteLink} target="_blank" />
          &nbsp;in&nbsp;
          <Repeater name="waivers" delimeter={commaAnd}>
            <Field name="name" component={FileLink} />
          </Repeater>
        </Repeater>
      </span>
    );
  }

  function renderConfirmAndPay() {
    if (props.purchaseSummary) {
      return;
    }

    const waitlistCount = uniqBy(cart.enrollments.filter(e => EnrollmentUtils.waitlistUnfinalized(e)), (e) => `${e.course.id}_${e.student?.id}`).length;
    const allWaitlist = waitlistCount == enrollmentCount;
    const allTemplate = ongoingTemplateCount == enrollmentCount;
    const label = allWaitlist ? 'Join ' + pluralize('waitlist', waitlistCount) : allTemplate ? 'Join' : 'Confirm and pay';

    return <Button autoLoader onClick={() => formUi.submit()}>{label}</Button>
  }


  async function onChangeTosAccepted(accepted:boolean) {
    const [success] = await parentAcceptTermsOfService({ variables: {accepted} });
    return success;
  }

  function totals() {
    const subtotal = cart?.subtotal || 0;
    const total = cart?.total || 0;
    const discountTotal = cart?.discountTotal || 0;
    const donationAmount = cart?.donation?.finalAmount || 0;
    const dueLater = cart?.dueLater;
    const priceTierDiscountTotal = cart?.priceTierDiscountTotal || 0;

    return {
      discountTotal,
      donationAmount,
      dueLater,
      priceTierDiscountTotal,
      subtotal,
      total
    };
  }

  return render();
}

function filterRecurring(enrollments: Enrollment[]): Enrollment[] {
  return enrollments.filter(e => {
    const recurring = EnrollmentUtils.usingRecurring(e);
    const isDueLater = moment(e.firstPayment?.dueOn).isAfter(moment(), 'day');
    const prorated = e.firstPayment?.prorated;

    return recurring && (prorated || isDueLater);
  });
}

function groupRecurring(enrollments: Enrollment[]) {
  const grouped: Record<string, Enrollment[]> = {};

  enrollments.forEach(enrollment => {
    const date = enrollment.firstPayment?.prorated && moment(enrollment.firstPayment?.dueOn).isSame(moment(), 'day') ? enrollment.firstPayment?.nextPaymentOn : enrollment.firstPayment?.dueOn;
    const key = `${EnrollmentUtils.unit(enrollment)}&${date}&${enrollment.firstPayment?.recurringAmount}`;

    if (!grouped[key]) {
      grouped[key] = [];
    }

    grouped[key].push(enrollment);
  });

  return grouped;
}
