import * as React from 'react'
import moment from 'moment'

import { HBox } from './Box';
import { DateInput, parseDate } from './date-utils'
import { dispatchChangeEvent } from './dom-utils';
import { Input, InputProps } from './Input'
import { List } from './list';
import { DropdownBase, DropdownState } from './DropdownBase';

type TimeFormat = 'time' | 'datetime';

export interface TimePickerProps extends Omit<InputProps, 'onChange' | 'value'> {
  onChange?: React.ChangeEventHandler<TimePicker>;
  value?:string | DateInput;
  valueFormat?:TimeFormat;
  timezone?:string;
}

interface State extends DropdownState {
  text?:string;// the text the user entered, which might be a partial time
  time?:string;// formatted time
  parts?:TimeParts;
}

export class TimePicker extends DropdownBase<TimePickerProps, State> {
  static defaultProps = {
    placeholder: 'Enter time',
    valueFormat: 'time' as TimeFormat,
  }

  state:State = {};
  inputRef = React.createRef<HTMLInputElement>();
  hoursRef = React.createRef<List>();
  minutesRef = React.createRef<List>();
  meridiemRef = React.createRef<List>();

  constructor(props:TimePickerProps) {
    super(props);
    this.state = this.updateTimeFromProps();
  }

  componentDidUpdate(prevProps:TimePickerProps) {
    if (this.props.value != prevProps.value) {
      this.setState(this.updateTimeFromProps());
    }
  }

  updateTimeFromProps() {
    // if the incoming value is "like" the internal user input, then we favor the internal
    // user input so that as the user types in times, partial times are not wiped out
    const parsedTime = this.valueToTime();
    const formattedTime = formatTime(parsedTime)
    const useState = this.state.text && formatTime(parseTime(this.state.text)) == formattedTime;

    let text = useState
      ? this.state.text 
      : formattedTime;

    // try to eliminate leading 0 when the value is coming from a prop
    if (!useState && text?.length && text.charAt(0) == '0') {
      text = text.slice(1);
    }

    return {text, time: formattedTime, parts: parsedTime};
  }

  // this returns time as a string or a moment depending on the timeFormat prop
  get value() {
    return this.timeToValue(this.state.time);
  }

  get timeValue() {
    return this.state.time;
  }

  renderTrigger(inputProps:TimePickerProps) {
    let {onChange, value, valueFormat, timezone, ...remaining} = inputProps;

    return <Input ref={this.inputRef} icon='Clock' onChange={this.onInputChange} onBlurCapture={this.handleBlur} onIconMouseDown={this.toggleDropdown} {...remaining} width='100%' value={this.state.text} />
  }

  renderDropdown() {
    return <HBox border='solid 1px' borderColor='border' borderRadius='standard'>
      <List ref={this.hoursRef} className='thin-scrollbars' options={hourOptions} width={50} value={this.state.parts?.hours12} onChange={this.onHourChange} border={null} />
      <List ref={this.minutesRef} className='thin-scrollbars' options={minuteOptions} width={50} value={this.state.parts?.minutes} onChange={this.onMinuteChange} border={null} />
      <List ref={this.meridiemRef} className='thin-scrollbars' options={meridiemOptions} width={50} value={this.state.parts?.meridiem} onChange={this.onMeridiemChange} border={null} />
    </HBox>
  }

  onInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (!value.length) {
      this.setState({text: value});
      return;
    }

    if (!isTimeLike(value)) {
      return;
    }

    this.setStateAndDispatchChangeEvent({text: value, time: formatTime(value), parts: parseTime(value)});
  }

  handleBlur = () => {
    if (this.state.text) {
      // once the user leaves the input ensure the time is formatted correctly
      this.setTimeParts();
    }
  }

  onHourChange = (event:React.ChangeEvent<List>) => {
    this.setTimeParts(event.currentTarget.value as number, undefined, undefined);
  }

  onMinuteChange = (event:React.ChangeEvent<List>) => {
    this.setTimeParts(undefined, event.currentTarget.value as number, undefined);
  }

  onMeridiemChange = (event:React.ChangeEvent<List>) => {
    this.setTimeParts(undefined, undefined, event.currentTarget.value as Meridiem);
  }

  // allows updating one part of the time (such as when the user clicks on a minute in the dropdown)
  setTimeParts(hour?:number, minute?:number, meridiem?:Meridiem) {
    const parts = parseTime(this.state.text);
    const hours12 = [hour, parts?.hours12, 1].find(num => Number.isFinite(num) && num > 0 && num <= 12)
    const minutes = [minute, parts?.minutes, 0].find(num => Number.isFinite(num) && num < 60);
    const text = formatTimeInternal(hours12, minutes, meridiem !== undefined ? meridiem : parts?.meridiem);

    this.setStateAndDispatchChangeEvent({text, time:formatTime(text), parts: parseTime(text)});
  }

  getTimeParts(time:string) {
    const parts = parseTime(time);

    const hour = parts?.hours12;
    const minute = parts?.minutes;
    const meridiem = parts?.meridiem == Meridiem.am ? 'AM' : 'PM';

    return {hour, minute, meridiem}
  }

  setStateAndDispatchChangeEvent(state:Partial<State>) {
    if (state.text == this.state.text && state.time == this.state.time) {
      return;
    }

    this.setState(state);

    if (this.props.onChange) {
      dispatchChangeEvent(this.inputRef.current, this.timeToValue(state.time), this.props.onChange)
    }
  }

  dispatchDropdownEvent(event:KeyboardEvent) {
    this.listAtCursor.selectFromKeyboardEvent(event);
  }

  // based on which part the cursor is in the
  // input, this returns the list that is
  // associated with that part of the time
  get listAtCursor() {
    const t = (this.state.text || '').substring(0, this.inputRef.current.selectionStart).toLowerCase();
    const lc = t.charAt(t.length - 1);

    if (lc == 'a' || lc == 'p' || lc == 'm') {
      return this.meridiemRef.current;
    }
    else
    if (t.indexOf(':') != -1) {
      return this.minutesRef.current;
    }
    else {
      return this.hoursRef.current;
    }
  }

  valueToTime() {
    if (this.props.valueFormat != 'datetime') {
      return parseTime(this.props.value as string);
    }

    const d = parseDate(this.props.value, this.props.timezone) as moment.Moment;
    return parseTime(d?.format('hh:mm a'));
  }

  timeToValue(time:string) {
    if (this.props.valueFormat != 'datetime') {
      return time;
    }

    const d = (parseDate(this.props.value || moment(), this.props.timezone) || moment()) as moment.Moment;
    const timeParts = parseTime(time);

    d.set({"hour": timeParts.hours24, "minute": timeParts.minutes});

    return d;
  }
}

const hourOptions = Array(12).fill(0).map((_, index) => {return {value: 1 + index, label: (1 + index) + ''}});
const minuteOptions = Array(60).fill(0).map((_, index) => {return {value: index, label: index.toString().padStart(1, '0') + ''}});
const meridiemOptions = [{label: 'AM', value: 0}, {label: 'PM', value: 1}]

const hours = '(1[0-2]|0?[1-9]|0)'
const minutes = '(:([0-5]?[0-9])?)?'
const meridiem = '( *((A|P)M?)?)'
const timeLike = new RegExp('^' + hours + '(' + minutes + '(' + meridiem + ')?' + ')?' + '$', 'i');

export enum Meridiem {
  am,
  pm
}

export interface TimeParts {
  time:string;
  hours:number;//0 - 11
  hours12:number;//1 - 12
  hours24:number;//0 - 23
  hoursInput:string;
  minutes:number;
  minutesInput:string;
  meridiem:Meridiem
  meridiemInput:string;
  minutesInDay:number;
}


export function parseTime(time:string):TimeParts {
  if (typeof time != 'string') {
    return;
  }

  const parts = timeLike.exec(time?.trim());

  if (!parts) {
    // see if its a iso timestamp
    const m = moment(time);

    if (!m.isValid()) {
      return null;
    }

    return parseTime(m.format('h:mm A'));
  }

  const hoursInput = parts[1];
  let hours = parseInt(hoursInput);

  if (hours == 12) {
    hours = 0;
  }

  const minutesInput = parts[4];
  const minutes = parseInt(minutesInput) || 0;
  // before 6 it defaults to pm because most things being scheduled do not happen 12 - 6 am
  const defaultMeridiem = hours < 6 ? 'p' : 'a';
  const meridiem = (parts[8] || defaultMeridiem)?.toLocaleLowerCase() != 'a' ? 1 : 0;
  const hours24 = meridiem == Meridiem.am ? hours : hours + 12;

  return {
    time,
    hours,
    hours12: hours == 0 ? 12 : hours,
    hours24,
    hoursInput,
    minutes,
    minutesInput,
    meridiem,
    meridiemInput: parts[7],
    minutesInDay: (hours24 * 60) + minutes
  };
}

export function isTimeLike(time:string) {
  return parseTime(time) != null;
}

export function formatTime(time:string | TimeParts) {
  if (time === undefined || time === null) {
    return '';
  }

  const parts = typeof time == 'string' ? parseTime(time) : time;

  if (!parts) {
    return '';
  }

  return formatTimeInternal(parts.hours12, parts.minutes, parts.meridiem);
}

function formatTimeInternal(hours12:number, minutes:number, meridiem:Meridiem) {
  return `${hours12}:${minutes.toString().padStart(2, '0')} ${meridiemText(meridiem)}`;
}

function meridiemText(meridiem:Meridiem) {
  return meridiem == Meridiem.am ? 'AM' : 'PM';
}

// returns 0 if equal, -1 if a is less than b, else 1
export function compareTimes(a:TimeParts | string, b:TimeParts | string, ...remainingPairs:(TimeParts | string)[]):number {
  if (typeof a == 'string') {
    a = parseTime(a);
  }

  if (typeof b == 'string') {
    b = parseTime(b);
  }

  if (a.hours24 < b.hours24) {
    return -1;
  } 

  if (a.hours24 > b.hours24) {
    return 1;
  } 

  if (a.minutes < b.minutes) {
    return -1;
  } 

  if (a.minutes > b.minutes) {
    return 1;
  }

  if (remainingPairs.length) {
    return compareTimes(remainingPairs[0], remainingPairs[1], ...remainingPairs.slice(2));
  }

  return 0;
}
