import * as React from 'react';

import { CourseUtils } from 'app2/api';
import { ActionButton, DataTable, Modal } from 'app2/components';
import { Beta } from 'app2/views';

import { CourseSelections } from '../../../generated';

import { BilledLaterAdvancedEnrollmentsSelections } from '../../enrolled';

import { RecalcPendingRecurringModal } from './RecalcPendingRecurringModal';

interface Props {
  course: CourseSelections;
  table: DataTable<BilledLaterAdvancedEnrollmentsSelections>;
}

export function RecalcPendingRecurringAction(props: Props) {
  function render() {
    const recurringPricing = CourseUtils.usingRecurringRatesOrPrices(props.course?.prices) && !CourseUtils.usingInstallments(props.course);

    if (!recurringPricing) {
      return <></>;
    }

    return (
      <Beta>
        <ActionButton icon="RefreshCw" autoLoader={false} onClick={handleRecalcPendingRecurring}>
          Recalculate subscription charges
        </ActionButton>
      </Beta>
    );
  }

  function handleRecalcPendingRecurring() {
    Modal.show(<RecalcPendingRecurringModal course={props.course} enrollments={props.table.selections.selectedItems} />);
  }

  return render();
}
