import * as React from 'react';

import { CatalogListing } from 'app2/api';
import {
  buildCloudinaryImageUrl,
  HBox,
  VBox,
  Body,
  Subtitle1,
  Subtitle2,
  Button,
  BoxProps,
  RatioBox,
  Link
} from 'app2/components';

import { eventManager } from 'app2/api';

export const CATALOG_CARD_RATIO = '85%';

interface Props extends BoxProps {
  item: CatalogListing;
}

export function CatalogCard(props: Props) {
  const { item, ...remaining } = props;
  const defaultImage = '/global-assets/default_course_card_img.png';
  const imageUrl = buildCloudinaryImageUrl(
    item.course_card_image || defaultImage,
    { width: 250, height: 190 }
  );

  function handleCatalogCardClick() {
    eventManager.log('clickCatalogCard', {
      url: window.location.href,
      catalogListingName: props.item.name,
      providerName: props.item.provider.name,
      catalogImageUploaded: Boolean(props.item.course_card_image).toString(),
      catalogListingCategory: props.item.subject
    });
  }

  return (
    <RatioBox
      boxShadow="box"
      borderRadius="standard"
      ratio={CATALOG_CARD_RATIO}
      content={{ layout: 'vbox', p: '$15' }}
      {...remaining}
    >
      <Link
        to={`/catalog/${item.slug}`}
        onClick={handleCatalogCardClick}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        underline={false}
        hover={false}
      >
        <HBox mb="$15" minHeight="fit-content" width='100%'>
          <RatioBox
            ratio="76%"
            minWidth="34.72222%"
            mr="$15"
            borderRadius="standard"
          >
            <VBox
              background={`url(${imageUrl})`}
              backgroundSize="cover"
              width="100%"
              height="100%"
            />
          </RatioBox>
          <VBox hAlign="left" height="100%">
            <Body maxLines={1}>{item.subject}</Body>
            <HBox flex="1" vAlign="center">
              <Subtitle1 maxLines={2}>{item.name}</Subtitle1>
            </HBox>
            <Subtitle2 maxLines={1}>
              by <Subtitle2 fontWeight="bold">{item.provider.name}</Subtitle2>
            </Subtitle2>
          </VBox>
        </HBox>

        <VBox flexGrow={1} overflow="hidden" width='100%'>
          <Subtitle2 fontWeight="bold">Description</Subtitle2>
          <Subtitle2
            mb="$15"
            flex={1}
            overflow="hidden"
            position="relative"
            whiteSpace="pre-wrap"
          >
            {item.description}
            {/* this feathers any overflowing text. we can't use maxLines because the card resizes dynamically
          and that would require a lot of breakpoint values on maxLines. */}
            <HBox
              background="linear-gradient(to bottom,#ffffff00 0%,#ffffffC0 60%,#ffffff 100%)"
              width="100%"
              bottom="0"
              height="20px"
              position="absolute"
            />
          </Subtitle2>
          <Button width="100%">Learn more</Button>
        </VBox>
      </Link>
    </RatioBox>
  );
}
