import * as React from 'react';
import { useParams } from 'react-router';

import { formatCurrency, DataTableColumn, Link, FieldInfo } from 'app2/components';

import { ReportRouteParams } from '../organizerRoutes';

import { useOrganizerCourseSummaryReportQuery, OrganizerCourseSummaryReportQuery } from './gql';
import { GenericReportTable } from '../../shared/reports/GenericReportTable';
import { useOldReportsNoneMsg } from './useOldReportsNoneMsg';

export function CourseSummaryReportTable() {
  const { site, season } = useParams<ReportRouteParams>();
  const cols = React.useMemo(getCols, [site, season]);
  const none = useOldReportsNoneMsg();

  function render() {
    return <GenericReportTable title='Activities' query={useOrganizerCourseSummaryReportQuery} resultType='siteCourseSummaryReport' none={none}
      totals={totals} cols={cols} colsVersion='3' colsPreferenceName='reportsCoursesOverview' csvUrl={`/seasons/${season}/reports/organizer_courses_overview_csv`} queryOptions={{ variables:{ seasonId: season }}} />
  }

  function getCols() {
    const cols:Array<DataTableColumn<CourseSummaryColumn>> = [
      {
        name: 'course',
        label: 'Activity name',
        format: (name:string, _:any, info:FieldInfo<CourseSummaryColumn>) => {
          return <Link to={`/organizer/${site}/reports/${season}/transactions/${info.record.id}`}>{name}</Link>;
        }
      },
      {
        name: 'grades',
        label: 'Grades'
      },
      {
        name: 'period',
        label: 'Days'
      },
      {
        name: 'provider',
        label: 'Provider'
      },
      {
        name: 'enrollments',
        label: 'Enrollments'
      },
      {
        name: 'listPrice',
        label: 'List Price',
        format: formatCurrency
      },
      {
        name: 'schoolEarnings',
        label: 'School Earnings',
        format: formatCurrency
      }
    ];

    return cols;
  }

  return render();
}

const totals = [
  {
    label: 'Total enrollments',
    name: 'totalEnrollments'
  }, {
    label: 'Total family spend',
    name: 'totalParentSpend',
    format: formatCurrency
  }, {
    label: 'Total provider earnings',
    name: 'totalProviderEarnings',
    format: formatCurrency
  }, {
    label: 'Total school earnings',
    name: 'totalSchoolEarnings',
    format: formatCurrency
  }
];

type CourseSummaryColumn = OrganizerCourseSummaryReportQuery['siteCourseSummaryReport']['data'][0];

