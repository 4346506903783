import { Student, Course } from 'app2/api';
import { ExecMutationOptions } from 'app2/views/shared';

import { parentUpdateCartedEnrollment } from './generated';

export async function updateCartedEnrollment(enrollmentId: string, course: Pick<Course, 'gradeGroup' | 'name'>, attributes: {student?: Pick<Student, 'name' | 'id'>, beforeClassPickup?:string, afterClassDismissal?:string, startDate?:string}, options?:Partial<ExecMutationOptions> ) {
  const variables = { enrollmentId, studentId: attributes.student?.id, ...attributes};
  delete variables.student;
  
  const [success] = await parentUpdateCartedEnrollment({ variables, ...options });

  return success;
}

