import { orderBy } from 'lodash-es';
import moment from 'moment';

import { DeepPartial } from '..';
import { Cart, Enrollment, PriceConfigKind, WaitlistKind } from '../graphql';
import { EnrollmentUtils, EnrollmentWithWaitlist } from '../shared';

type CartedEnrollment = DeepPartial<Enrollment>;
type CartWithEnrollments = DeepPartial<Omit<Cart, 'enrollments'>> & {enrollments:CartedEnrollment[]};

export class CartUtils {
  static hasPurchasableItems(cart: CartWithEnrollments) {
    if (!cart) {
      return false;
    }

    return cart.enrollments.some(item => [WaitlistKind.None, WaitlistKind.Promoted].includes((item as Enrollment).waitlistKind));
  }

  static allWaitlist(cart:CartWithEnrollments) {
    return cart && cart.enrollments.length == cart.enrollments.filter(e => EnrollmentUtils.waitlistUnfinalized(e as EnrollmentWithWaitlist)).length
  }

  static hasOngoing(cart:CartWithEnrollments) {
    return cart.enrollments.filter(e => EnrollmentUtils.usingOngoing(e)).length > 0;
  }

  static hasInstallments(cart:CartWithEnrollments) {
    return cart.enrollments.some(e => e.priceConfig.kind == PriceConfigKind.Season && e.pendingInstances?.length > 0);
  }

  static getCourseKinds(cart:CartWithEnrollments, enrollments:CartedEnrollment[]) {
    return Array.from(new Set(enrollments?.map(e => e.course?.kind)).keys());
  }

  static classifyEnrollments(cart:CartWithEnrollments) {
    if (!cart || !cart.enrollments) {
      return {};
    }

    const map = toMap(cart.enrollments);
    const all = cart.enrollments;
    const allWaitlisted = all.filter(e => EnrollmentUtils.waitlistUnfinalized(e as EnrollmentWithWaitlist));
    const allWaitlist = allWaitlisted.length == all.length;

    // non waitlisted
    const accepted = sortEnrollments(all.filter(e => !EnrollmentUtils.waitlistUnfinalized(e as EnrollmentWithWaitlist)));

    // all accepted enrollments that are not templates
    const purchases = sortEnrollments(accepted.filter(e => EnrollmentUtils.billedNow(e)));

    // all accepted templates
    const billedLater = sortEnrollments(accepted.filter(e => EnrollmentUtils.billedLater(e))).
      // or payment installments
      concat(...accepted.map(e => (e.pendingInstances || []).filter(e => EnrollmentUtils.billedLater(e))));

    const installments = sortEnrollments(billedLater.filter(e => EnrollmentUtils.seasonBilledLater(e)));

    // all onggoing enrollments that are from template instances
    const ongoingInstances = toMap(all.filter(e => EnrollmentUtils.ongoingInstance(e)));
    
    // all templates that are hidden because there's a first purchase
    const hidden = toMap(all.filter(e => e.templateId).map(e => map.get(e.templateId)).filter(e => !!e).concat(all.filter(e => EnrollmentUtils.seasonHidden(e))));

    // all visible enrollments (this will include zero deposit payment plan templates)
    const visible = sortEnrollments(all.filter(e => !hidden.has(e.id)));

    // all visible waitlisted
    const waitlisted = sortEnrollments(allWaitlisted.filter(e => !hidden.has(e.id)));

    // instances that have a different price than the recurring price
    const ongoingProrated = toMap(Array.from(ongoingInstances.values()).filter(e => hidden.get(e.templateId).amount != e.amount));

    // count of unique enrollents after collapsing templates, installmets, etc
    const enrollmentCount = new Set(all.map(e => `${e.course.id}-${e.student?.id}`)).size;
    const ongoingTemplateCount = all.filter(e => !!e.templateId && (e.priceConfig.kind == PriceConfigKind.Recurring || e.priceConfig.kind == PriceConfigKind.Usage)).length

    // with range warnings
    // exempt if student selection is not required. may need its own flag
    const outOfGradeRange = visible.filter(e => e.studentSelectionRequired && e.inGradeRange == false);
    const outofAgeRange = visible.filter(e => e.studentSelectionRequired && e.inAgeRange == false);

    return {allWaitlist, waitlisted, accepted, purchases, billedLater, installments, ongoingInstances, visible, ongoingProrated, enrollmentCount, ongoingTemplateCount, outOfGradeRange, outofAgeRange};
  }

  // returns the total for an enrollment, such that if this is a deposit, it will return the full amount, not the deposit amount
  static enrollmentPrices(e:CartedEnrollment, cart:CartWithEnrollments) {
    const noDepositPaymentPlan = EnrollmentUtils.seasonNoDepositPaymentPlan(e);

    if (!EnrollmentUtils.seasonDeposit(e) && !noDepositPaymentPlan) {
      return {
        price: e.firstPayment?.recurringAmount || e.amount, 
        listPrice: e.firstPayment?.recurringListPrice || e.listPrice
      }
    }

    const template = noDepositPaymentPlan ? e : cart.enrollments.find(otherE => otherE.id == e.templateId);

    return {
      price: template?.amount || 0,
      listPrice: template?.listPrice || 0,
      deposit: noDepositPaymentPlan ? 0 : e.amount,
    }
  }

  static enrollmentDiscounts(e:CartedEnrollment, cart:CartWithEnrollments) {
    // if this is a deposit, current they aren't discounted, so we pull it from the template
    const withDiscounts = EnrollmentUtils.seasonDeposit(e) ? cart.enrollments.find(other => other.id == e.templateId) || e : e;

    return withDiscounts
  }
}

function toMap<T extends {id?:string}>(items: T[]) {
  return new Map<string, T>(items.map(obj => [obj.id, obj]));
}

// because new enrollments can get created based on changes, it causes the enrollment
// order to change, so we want to sort on something other than id
export function sortEnrollments(enrollments:CartedEnrollment[], sortByStudent?:boolean):CartedEnrollment[] {
    // this groups by student then by start date, but tries to keep child enrollment together with its parent
    return orderBy(enrollments, [
      e => sortByStudent ? e.student?.name : true, 
      // group by start date or parent start date if this is a child enrollment
      e => moment(e.optionParent?.course?.startDate || e.course.startDate).valueOf(), 
      // group by start id or parent start id if this is a child enrollment
      // but because of the template mess, for consistency we want the template ids
      e => e.optionParent?.templateId || e.optionParent?.id || e.templateId || e.id,
      // force the parent enrollment to be first
      e => e.isOption,
      // sort the child enrollment by start date then id
      e => moment(e.course.startDate).valueOf(),
      e => e.id]) ;
}
