import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OpenCoursesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type OpenCoursesMutation = { coursesOpenEnrollment?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const OpenCoursesDocument = gql`
  mutation OpenCourses($ids: [ID!]!) {
    coursesOpenEnrollment(ids: $ids) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OpenCoursesOptions = ExecMutationOptions<OpenCoursesMutationVariables>;

export function openCourses(options: OpenCoursesOptions) {
  return executeMutation<OpenCoursesMutation, OpenCoursesMutationVariables>(OpenCoursesDocument, options);
}
