import * as React from 'react';

import { Form, Field, FieldRendererProps, Icon, RepeatingSection, createForm } from 'app2/components';
import { CourseLink } from 'app2/views/shared-public';
import { CourseStatusTag } from 'app2/views/shared-public';
import { EventUtils } from 'app2/views/shared';

import { VendorSelections } from './gql';

interface Props {
  provider:VendorSelections;
}

export function Activities(props: Props) {
  const form = React.useMemo(() => createForm({values: {activities: props.provider.courses }}), [props.provider]);
  const hasEvents = React.useMemo(() => props.provider.courses.some(c => c.events?.some(e => EventUtils.displayableEvent(e))), [props.provider]);

  function render() {
    return <Form form={form} icon='Clipboard' title='Activities' onNavigation='nothing'>
      <RepeatingSection name='activities' fields={[
        <Field label='Activity name' name='name' component={CourseLink} />,
        <Field label='Season' name='season.name' />,
        <Field label='Status' name='status' component={CourseStatusTag} small />,
        hasEvents && <Field label='Messages' render={renderEvents} />,
      ]} />
    </Form>
  }

  function renderEvents(props:FieldRendererProps<VendorSelections['courses'], 0>) {
    const events = props.info.value.events?.
      filter(e => EventUtils.displayableEvent(e))?.
      sort(EventUtils.sortEvents)?.
      map(e => EventUtils.formatEvent(e));

    if (!events?.length) {
      return;
    }
    
    return <Icon name='Info' tooltip={events} />
  }

  return render();
}
