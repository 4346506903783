import * as React from 'react';

import { CourseDay, Site, formatAges } from 'app2/api';
import { HBox, VBox, Subtitle2, formatTime, Link, Tag } from 'app2/components';
import { PublicSiteLink } from 'app2/views/shared-public';

import { ScheduledCourse } from './ScheduledCourse';

export interface ScheduledSessionProps<T extends ScheduledCourse = ScheduledCourse> {
  activityUrlBase:string;
  course:T;
  day:CourseDay;
  holiday?:boolean;
  bg?:string;
  actions?:React.ReactNode;
  header?:React.ReactNode;
  grades?:boolean;
  site?:Pick<Site, 'id' | 'name'>;
}

export function ScheduledSession<T extends ScheduledCourse = ScheduledCourse>(props:ScheduledSessionProps) {
  const course = props.course;

  function render() {
    return <Link to={`${props.activityUrlBase}/${props.course.id}`} as='div' underline={false} hover={false}>
      <VBox p='$8' bg={props.bg || 'courseDay'} width='100%' borderRadius='standard' opacity={props.holiday ? .5 : 1}>
        <HBox hAlign='justify' vAlign='center' mb='$8'>
          <Subtitle2>{course.name || course.disambiguatedName}</Subtitle2>
          {renderNoSession()}{props.actions}
        </HBox>
        <VBox text='body' gap='$4'>
          {props.header && <HBox>{props.header}</HBox>}
          {(props.site || course?.site) && <HBox><PublicSiteLink site={props.site || course.site} /></HBox>}
          {props.grades && Boolean(course.ageGroup ?? course.ageMin ?? false) && <HBox>{course.ageGroup || formatAges(course.ageMin, course.ageMax)}</HBox>}
          {props.grades && Boolean(course.siteGradeLabels?.length) && <HBox>Grade:&nbsp;<>{course.siteGradeLabels.join(', ')}</></HBox>}
          <HBox>Time:&nbsp;<>{formatTime(props.day.start)} - {formatTime(props.day.finish)}</></HBox>
          {course.room && <HBox>Location:&nbsp;<>{course.room}</></HBox>}
        </VBox>
      </VBox>
    </Link>
  }

  function renderNoSession() {
    if (!props.holiday) {
      return;
    }

    return <Tag icon={false} small color='white' bg='#C77801' whiteSpace='nowrap' marginY='-2px'>No class</Tag>;
  }

  return render();
}

ScheduledSession.defaultProps = {
  grades: true
}