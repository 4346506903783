import * as React from 'react'
import pluralize from 'pluralize';

import { Box, Dropdown, FieldPlaceholder, HBox, InputField, Panel, Part, useForm, useFormToUrl, VBox } from 'app2/components';
import { PublicPage } from 'app2/views/shared-public';
import { CourseTagsDropdown, gradeOptions } from 'app2/views/shared-public';

import { PublicCompanyDirectorySelections, usePublicCompanyDirectoryQuery, useDirectorySiteQuery } from './generated';

import { DirectoryResult } from './DirectoryResult';
import { CourseResult } from './CourseResult'
import { CompanyResult } from './CompanyResult'
import { DirectoryFilter } from './DirectoryFilter'

export function CompanyDirectory() {
  const [filter, setFilter] = React.useState<DirectoryFilter>({});
  const form = useForm<DirectoryFilter>(filter, []);
  const {formToLocation} = useFormToUrl(form, {path: '/directory/provider/:zip', watchForm: false, onUrlChange});

  const site = getSite();
  const { courses, stems, total } = getResults();

  const r = React.useRef<HTMLElement>();

  function render() {
    return <PublicPage title='Provider directory' subtitle='Find providers to teach at your school and message them'>
      <VBox gap='$8'>
        {renderFilter()}
        {courses === undefined
          ? ''
          : courses?.length 
            ? <HBox text='body' italic>Showing activities taught within the past year {site && ` (excluding activities from ${site.name})`}</HBox>
            : <HBox text='body' italic>No activities matching your criteria</HBox>}
        {renderResults()}
        {renderMore()}
      </VBox>
    </PublicPage>
  }

  function renderFilter() {
    return <Panel type='edit' form={form} onNavigation='nothing' gap='$12' cancel={null} ok='Search' onOk={onSearch}>
      <HBox gap='$8' vAlign='bottom'><Part name='keywords' label='Keywords' component={InputField} /></HBox>
      <Box layout={['vbox', 'hbox', 'hbox']} gap='$8' hAlign={['stretch', 'left']} vAlign={['top', 'center']} width='100%'>
        <Part name='zip' label='Zip code' component={InputField} flex={1} readOnly={!!site} none={site ? `${site.zipcode} (${site.name})` : undefined} />
        <Part name='grades' label='Grades' placeholder={FieldPlaceholder.select} multiple component={Dropdown} options={gradeOptions} flex={1} nowrap disablePhoneInput />
        <Part name='tags' label='Subjects' placeholder={FieldPlaceholder.select} multiple component={CourseTagsDropdown} valueType='name' flex={1} nowrap disablePhoneInput />
      </Box>
    </Panel>
  }

  function renderResults() {
    return React.useMemo(() => {
      return <VBox gap='$30' ref={r} position='relative'>
        {/* <TextHighlighter text={stems} parent={r} /> */}
        {courses?.map(renderResult)}
      </VBox>
    }, [courses, site]);
  }

  function renderResult(result:DirectoryResult) {
    return <HBox key={result.course.id} itemFlex={1} layout={['vbox', 'vbox', 'hbox']} border="solid 1px" borderColor="border" borderRadius="form" overflow='hidden'>
      <CourseResult site={site} result={result} />
      <CompanyResult site={site} result={result} />
    </HBox>
  }

  function renderMore() {
    if (courses?.length == total) {
      return;
    }

    const remaining = total - courses.length;

    return <HBox text='body'>{remaining} more {pluralize('activity', remaining)}</HBox>
  }

  function getSite() {
    const [result] = useDirectorySiteQuery({variables:{id:form.values.site}});
    return result.data?.site;
  }

  function getResults() {
    const { zip, keywords, grades, site, tags } = filter;

    const [result] = usePublicCompanyDirectoryQuery({variables:{zipCode:site ? undefined : zip, site, keywords, grades, tags}, autoPause: false});
    const results = result.data?.directoryCompanySearch || {} as PublicCompanyDirectorySelections;

    return results;
  }

  function onSearch() {
    // triggers a onUrlChange
    formToLocation();
  }

  function onUrlChange() {
    setFilter({...form.values});
  }

  return render();
}

