export type AnimateCallback = (configuration:AnimateConfiguration, property:AnimatePropertyDelta, position:number) => void;;
export interface AnimateConfiguration {
  element:HTMLElement;
  properties:AnimateProperty[];
  duration?:number;
  timing?:TimingFunction;
  cb?:AnimateCallback;
}

let counter = 0;
export class AnimateProperties implements AnimateConfiguration {
  element:HTMLElement;
  properties:AnimatePropertyDelta[];
  duration:number;
  timing:TimingFunction;
  cb?:AnimateCallback;
  keepGoing:boolean;
  finished:boolean;
  id:number;
  
  constructor(config:AnimateConfiguration) {
    this.element = config.element;
    this.duration = config.duration || 1000;
    this.timing = config.timing || EaseInOut;
    this.cb = config.cb;
    this.keepGoing = true;
    this.finished = false;
    this.id = ++counter;
    this.properties = config.properties.map(property => {
      const from = (this.element as any)[property.name];
  
      return {
        ...property,
        from,
        delta: property.to - from
      }
    });
  }

  stop = () => {
    this.keepGoing = false;
  }

  finish = () => {
    this.finished = true;
  }

  animate() {
    return animate(this.animateProperties, this.duration, this.timing, this.id);
  }

  private animateProperties = (position:number) => {
    if (!this.keepGoing) {
      return false;
    }

    if (this.finished) {
      position = 1;
      this.keepGoing = false;
    }

    this.properties.forEach(property => {
      const val = property.from + (property.delta * position);
      (this.element as any)[property.name] = val;
      this.cb?.(this, property, val)
    });

    return this.keepGoing;
  }
}

interface AnimateProperty {
  name:string;
  to:number;
}

interface AnimatePropertyDelta extends AnimateProperty {
  from:number;
  delta:number;
}



// from https://medium.com/allenhwkim/animate-with-javascript-eef772f1f3f3

export type AnimateFunction = (position:number) => boolean;
export type TimingFunction = (time:number) => number;

export function animate(animate:AnimateFunction, duration:number = 500, timing:TimingFunction = EaseInOut, id:number = -1) {
  const start = performance.now();
  let resolve:(value:any) => void;
  const promise = new Promise(r => {
    resolve = r;
    requestAnimationFrame(onAnimationFrame);
  });

  function onAnimationFrame() {
    // don't use the time passed to our callback because in some cases it can be before start
    const time = performance.now();
    const timeFraction = Math.min((time - start) / duration, 1);
    const keepGoing = animate(timing(timeFraction));

    if (keepGoing && timeFraction < 1) {
      requestAnimationFrame(onAnimationFrame);
    }
    else {
      resolve(true);          
    }
  }
  
  return promise;
}

export function EaseIn(n:number) {
  return Math.pow(n, 1.675);
}

export function EaseOut(n:number) {
  return 1 - Math.pow(1 - n, 1.675);
}

export function EaseInOut(n:number) {
  return .5 * (Math.sin((n - .5) * Math.PI) + 1);
}

export function InOutExpoential(n:number) {
  if (n == 0 || n == 1) {
    return n;
  }

  if ((n *= 2) < 1) {
    return .5 * Math.pow(1024, n - 1);
  }
  
  return .5 * (-Math.pow(2, -10 * (n - 1)) + 2);
}
