import * as React from 'react';

import { getAppMode, hasAppMode, LinkProps } from 'app2/components';

import { BackLink } from './BackLink';

export function EmbedBackLink(props: LinkProps) {
  const shouldRender = hasAppMode('embed') && getAppMode('back');
  return shouldRender ? <BackLink {...props} /> : <></>;
}
