import * as React from 'react';

import { FormModel } from './FormModel';
import { createForm, CreateFormOptions } from './FormModelImpl';
import { useFormSubscription } from './useFormSubscription';

// if you pass in existing as a form option, instead of creating a form, it will
// use that form and reset its values based on the dependencies
interface ExistingFormOption<T> {
  existing?:FormModel<T>;
}

export function useForm<T>(initialValues: Partial<T> | (() => Partial<T>) = {}, deps?: any[], options?:CreateFormOptions<T> & ExistingFormOption<T>) {
  if (deps === undefined) {
    deps = [initialValues]
  }

  const form = React.useMemo(() => {
    const {existing, ...remaining} = options || {};
    const values = typeof initialValues === 'function' ? initialValues() : initialValues;
    let form:FormModel<T>;

    if (existing) {
      existing.reset({...remaining, initialValues: values});
      form = existing;
    }
    else {
      form = createForm<T>({...remaining, values});
    }

    return form;
  }, deps);

  useFormSubscription({ form });

  return form;
}
