import * as React from 'react'

import { Page, PageProps } from '../page'

import { HBox, Link, MenuDropdown, Navigation, useBreakpoints, Breakpoints } from 'app2/components'

import { marketingUrl, useCurrentUser } from '..'

interface PublicPageProps extends PageProps {
  markingLinksWhenLoggedIn?:boolean;
}

export function PublicPage(props:PublicPageProps) {
  const {header, footer, navigation, markingLinksWhenLoggedIn, children, ...remaining} = props;
  const breakpoint = useBreakpoints();
  const { user } = useCurrentUser();

  function render() {
    return <Page header={header && renderHeader()} footer={footer} navigation={navigation && renderNavigation()} {...remaining}>
      {children}
    </Page>
  }

  function renderHeader() {
    return breakpoint == Breakpoints.desktop
      ? renderDesktopHeader()
      : <></>;
  }
  
  function renderDesktopHeader() {
    if (markingLinksWhenLoggedIn === false && user) {
      return <></>;
    }

    return <>
      <HBox mr={[0, 0, 0]} flex={1} />
      <MenuDropdown label='For schools' mb='$100' small>
        <Link to={marketingUrl('/for-schools/public-schools')} small>Public schools</Link>
        <Link to={marketingUrl('/for-schools/independent-schools')} small>Independent schools</Link>
      </MenuDropdown>
      <MenuDropdown label='Solutions' mb='$100' small>
        <Link to={marketingUrl('/solutions/enrichment-programs')} small>Enrichment programs</Link>
        <Link to={marketingUrl('/solutions/extended-day-care')} small>Extended day care</Link>
        <Link to={marketingUrl('/solutions/summer-camp')} small>Summer camp</Link>
        <Link to={marketingUrl('/solutions/activity-providers')} small>Activity providers</Link>
      </MenuDropdown>
      <MenuDropdown label='Resources' mb='$100' small>
        <Link to={marketingUrl('/resources/blog')} small>Blog</Link>
        <Link to='/directory/provider' small>Activity provider directory</Link>
        <Link to={marketingUrl('/resources/pricing')} small>Pricing</Link>
      </MenuDropdown>
    </>
  }
    
  function renderNavigation() {
    if (markingLinksWhenLoggedIn === false && user) {
      return null;
    }

    return breakpoint != Breakpoints.desktop && <Navigation>
      <MenuDropdown label='For schools' mb={['60px', '100px']}>
        <Link to={marketingUrl('/for-schools/public-schools')} small>Public schools</Link>
        <Link to={marketingUrl('/for-schools/independent-schools')} small>Independent schools</Link>
      </MenuDropdown>
      <MenuDropdown label='Solutions' mb={['60px', '100px']}>
        <Link to={marketingUrl('/solutions/enrichment-programs')} small>Enrichment programs</Link>
        <Link to={marketingUrl('/solutions/extended-day-care')} small>Extended day care</Link>
        <Link to={marketingUrl('/solutions/summer-camp')} small>Summer camp</Link>
        <Link to={marketingUrl('/solutions/activity-providers')} small>Activity providers</Link>
      </MenuDropdown>
      <MenuDropdown label='Resources' mb={['60px', '100px']}>
        <Link to={marketingUrl('/resources/blog')} small>Blog</Link>
        <Link to='/directory/provider' small>Activity provider directory</Link>
        <Link to={marketingUrl('/resources/pricing')} small>Pricing</Link>
      </MenuDropdown>
    </Navigation>
  }

  return render();
}

PublicPage.defaultProps = {
  navigation: true,
  header: true,
  footer: true
}