import { Modal } from '../modal';

import { ErrorWithPath, ErrorPathSegment } from './ErrorWithPath';

export function modalErrorHandler(errors:ErrorWithPath[]):ErrorWithPath[] {
  const messages = errors.map(errorToString);
  Modal.error('Error', messages);

  return [];
}

export function throwErrorHandler(errors:ErrorWithPath[]):ErrorWithPath[] {
  throw errors.map(e => errorToString(e));
}

export function noOpErrorHandler(errors:ErrorWithPath[]):ErrorWithPath[] {
  return [];
}

export function errorToString(error:ErrorWithPath) {
  return error.path && error.path.length ? error.path.map(errorPathSegmentToString).join('.') + ' - ' + error.message : error.message;
}

export function errorPathSegmentToString(segment:ErrorPathSegment) {
  return typeof segment == 'object'
    ? segment.value
    : segment
}
