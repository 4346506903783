import { startAnalytics } from 'app2/api';
import { hasAppMode } from 'app2/components/AppModes';

type ScriptCallback = () => void;

enum ScriptLoadState {
  notLoaded,
  loading,
  loaded
}

interface ScriptInfo {
  string: string;
  isUrl: boolean;
  callbacks: ScriptCallback[];
  loadState: ScriptLoadState;
  blockInTestMode?: boolean;
}

const DELAY_DURATION = process.env.THIRD_PARTY_DELAY
  ? parseInt(process.env.THIRD_PARTY_DELAY)
  : 5000;

class ScriptManager {
  hitDelay: boolean;
  scripts: Map<string, ScriptInfo>;
  timeout: any;

  constructor() {
    this.hitDelay = false;
    this.scripts = new Map();
    this.timeout = setTimeout(this.loadScripts.bind(this), DELAY_DURATION);
  }

  register(script: string, isUrl: boolean, callback?: ScriptCallback, blockInTestMode?:boolean) {
    let info = this.scripts.get(script);

    if (info == undefined) {
      info = {
        string: script,
        isUrl: isUrl,
        callbacks: [],
        loadState: ScriptLoadState.notLoaded,
        blockInTestMode
      };
      this.scripts.set(script, info);
    }

    if (typeof callback == 'function') {
      info.callbacks.push(callback);
    }

    //if hit delay, load immediately
    if (this.hitDelay) {
      this.loadScript(info);
    }
  }

  loadNow() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.loadScripts();
    }
  }

  private loadScripts() {
    this.timeout = null;
    this.hitDelay = true;
    this.scripts.forEach(info => this.loadScript(info));
    startAnalytics();
  }

  private loadScript(info: ScriptInfo) {
    if (info.blockInTestMode && hasAppMode('test')) {
      info.loadState = ScriptLoadState.loaded;
    }

    if (info.loadState == ScriptLoadState.loaded) {
      this.onScriptLoaded(info);
      return;
    }

    if (info.loadState == ScriptLoadState.loading) {
      return;
    }

    if (info.isUrl) {
      this.loadUrlScript(info);
    } else {
      this.loadTextScript(info);
    }
  }

  private loadUrlScript(info: ScriptInfo) {
    //console.log('script load request from url', info.string);

    info.loadState = ScriptLoadState.loading;

    const script = document.createElement('script');
    script.src = info.string;
    script.onload = this.onScriptLoaded.bind(this, info);
    script.onerror = this.onScriptLoadError.bind(this, info);;
    document.body.appendChild(script);
  }

  private loadTextScript(info: ScriptInfo) {
    //console.log('script load request from text', info.string);

    info.loadState = ScriptLoadState.loading;

    const script = document.createElement('script');
    script.text = info.string;
    document.body.appendChild(script);
    this.onScriptLoaded(info);
  }

  private onScriptLoaded(info: ScriptInfo) {
    //console.log('script loaded', info.string);

    info.loadState = ScriptLoadState.loaded;
    
    info.callbacks.forEach(callback => {
      try {
        callback?.()
      }
      catch(e) {
        if ((global as any).Sentry) {
          Sentry.captureException(e)
        }
      }
    });

    info.callbacks = [];
  }

  private onScriptLoadError(info: ScriptInfo) {
    if (!hasAppMode('test')) {
      console.warn('script not loaded', info.string);
    }
    
    this.onScriptLoaded(info);
  }
}

export const scriptManager = new ScriptManager();
