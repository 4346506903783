import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertStudentLogEntryMutationVariables = Types.Exact<{
  student: Types.Scalars['ID'];
  attributes: Types.StudentLogEntryInput;
}>;

export type UpsertStudentLogEntryMutation = { studentUpsertLogEntry?: { success?: boolean | null | undefined } | null | undefined };

export const UpsertStudentLogEntryDocument = gql`
  mutation UpsertStudentLogEntry($student: ID!, $attributes: StudentLogEntryInput!) @urql(additionalTypenames: ["Student"]) {
    studentUpsertLogEntry(student: $student, attributes: $attributes) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpsertStudentLogEntryOptions = ExecMutationOptions<UpsertStudentLogEntryMutationVariables>;

export function upsertStudentLogEntry(options: UpsertStudentLogEntryOptions) {
  return executeMutation<UpsertStudentLogEntryMutation, UpsertStudentLogEntryMutationVariables>(UpsertStudentLogEntryDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Student']) }
  });
}
