import * as React from 'react'

import { HBox, Icon, IconNames, Img, Link, LinkProps, Text, VBox } from 'app2/components';

import { marketingUrl } from '../marketingUrl'

import logo from './logo-image-only-white.png'

export function Footer() {
  return <VBox width='100%' bg='brandDark' mt='$60'>
    <HBox width='100%' py={['$16', '$30']} px={['$16', '$60']} borderBottom='solid 2px white'>
      <Img src={logo} width={['40px', '70px']} height={['31px', '54px']} />
      <HBox flex={1} />
      <VBox>
        <VBox mb='$40'>
          <HBox mb='$8'><MarketingLink fontWeight='bold' width={['80px','130px']}>Company</MarketingLink><MarketingLink fontWeight='bold'>Product</MarketingLink></HBox>
          <HBox mb='$16'><MarketingLink to='about-us' width={['80px','130px']}>About Us</MarketingLink><MarketingLink to='https://meetings.hubspot.com/homeroom/book-a-demo?utm_campaign=Demos%20Scheduled&amp;utm_source=Direct'>Request demo</MarketingLink></HBox>
          <HBox mb='$16'><MarketingLink to='/resources/blog' width={['80px','130px']}>Blog</MarketingLink><MarketingLink to='/resources/pricing'>Pricing</MarketingLink></HBox>
          <HBox mb='$16'><MarketingLink to='careers' width={['80px','130px']}>Careers</MarketingLink><MarketingLink to='support'>Support</MarketingLink></HBox>
        </VBox>
        <HBox hItemSpace='$30'>
          <MarketingIcon to='http://instagram.com/joinhomeroom' icon='InstagramWhite' />
          <MarketingIcon to='https://www.facebook.com/joinhomeroom' icon='Facebook'  />
          <MarketingIcon to='https://www.linkedin.com/company/homeroom-enrichment' icon='Linkedin' />
          <MarketingIcon to='https://twitter.com/joinhomeroom' icon='Twitter' />
        </HBox>
      </VBox>
    </HBox>
    <HBox width='100%' px='$60' height={['40px', '60px']} vAlign='center' hItemSpace='$30'>
      <MarketingLink fontWeight='bold' to='privacy'>Privacy policy</MarketingLink>
      <MarketingLink fontWeight='bold' to='tou'>Terms of use</MarketingLink>
    </HBox>
  </VBox>
}

interface MarketingLinkProps extends LinkProps {
  to?:string;
}

function MarketingLink(props:MarketingLinkProps) {
  const {to, ...remaining} = props;

  return to
    ? <Link text='subtitle2' color='white' fontWeight='normal' fontSize={['12px', '14px']} underline={false} to={marketingUrl(to)} {...remaining} />
    : <Text text='subtitle2' color='white' fontWeight='normal' fontSize={['12px', '14px']} {...remaining} />
}

interface MarketingIconProps {
  to:string;
  icon:IconNames;
}

function MarketingIcon(props:MarketingIconProps) {
  return <Link to={props.to} target="_blank" rel="noopener noreferrer"><Icon name={props.icon} size='medium' strokeWidth={0} fill='white' /></Link>
}