import * as React from 'react'

import { ObservableCollection } from 'app2/components';
import { useLifecycle } from '../../utils';

export interface ObservableCollectionInfo<T> {
  collection?:ObservableCollection<T>;
  onChange?:() => void
}

interface PrivateObservableCollectionInfo<T> extends ObservableCollectionInfo<T> {
  internalOnChange?:() => void;
}

// returns an object that you can attach a change handler to

export function useObservableCollection<T>(collection:ObservableCollection<T>, onChange?:() => void):ObservableCollectionInfo<T> {
  const cur = React.useRef<PrivateObservableCollectionInfo<T>>({});

  useLifecycle({onUnmount});
  updateSubscribers();

  function updateSubscribers() {
    if (cur.current.collection != collection) {
      removeSubscriber();
  
      cur.current.collection = collection;
      cur.current.internalOnChange = internalOnChange;

      addSubscriber();
    }

    if (onChange !== undefined) {
      cur.current.onChange = onChange;
    }
  }

  function addSubscriber() {
    if (!cur.current.collection) {
      return
    }

    cur.current.collection.observe(cur.current.internalOnChange);
  }

  function removeSubscriber() {
    if (!cur.current.collection) {
      return;
    }

    cur.current.collection.unobserve(cur.current.internalOnChange);
    cur.current.collection = null;
  }

  function onUnmount() {
    removeSubscriber();
  }

  // uses an internal change handler intermediary so that
  // we can stably unsubscribe and so the caller can change
  // the on change handler whenever they need to (in case its 
  // not available at the time of the hook).
  
  function internalOnChange() {
    cur.current.onChange?.();
  }

  return cur.current;
}
