import * as React from 'react'

import { MessageRecipient, MessageSender, MessageEntity } from 'app2/api';
import { Box, BoxProps, Link, Repeater, Span } from 'app2/components';

interface Props<T = MessageEntity | MessageRecipient | MessageSender> extends BoxProps {
  participants: T[];
  itemRenderer?:(participant:Partial<T>, index?:number, onClickStudent?:(id:string) => void) => ReturnType<typeof formatMessageEntity>;
  onClickStudent?:(id:string) => void;
}

export function MessageParticipants<T = MessageEntity>(props:Props<T>) {
  const {participants, itemRenderer, onClickStudent, ...remaining} = props;

  return <Box text='body' {...remaining}><Repeater items={participants} delimeter=', ' display='inline-block' render={(record, index) => itemRenderer(record, index, onClickStudent)} /></Box>
}

MessageParticipants.defaultProps = {
  itemRenderer: formatMessageEntity
}

MessageParticipants.fieldProps = {
  valueProperty: 'participants',
}

export function formatMessageEntity(participantOrRecipient:Partial<MessageEntity | MessageRecipient | MessageSender>, index?:number, onClickStudent?:(id:string) => void) {
  const sender = participantOrRecipient as MessageSender;
  const recipient = participantOrRecipient as MessageRecipient;
  const participant:MessageEntity = sender?.from
    ? sender.from
    : recipient?.to 
      ? recipient.to 
      : recipient?.toEntity
        ? recipient.toEntity
        : (participantOrRecipient as MessageEntity);
  
  const hasBoth = participant.name && participant.email;
  const nameOrEmail = participant.name || participant.email;
  const tooltip = hasBoth ? participant.email : null;

  const students = sender?.students || recipient?.students;
  const role = sender?.role || recipient?.role;
  const context = (role == 'parent' || !role) && students?.length
    ? <Repeater items={students} delimeter=', ' render={student => !onClickStudent ? student.firstNameWithNickname || student.name : <Link onClick={event => onClickStudent(student.id)}>{student.firstNameWithNickname || student.name}</Link>} /> 
    : role;

  return <Span display='inline' tooltip={tooltip} cursor={tooltip ? 'pointer' : undefined}>{nameOrEmail}{context ? <> ({context})</> : undefined}</Span>
}

