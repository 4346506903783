import * as React from 'react';
import { useHistory } from 'react-router';

import { CheckboxField, MenuItem, OptionsMenu, Tabs, Form, InputField, Modal, SaveableResult, Section } from 'app2/components';

import { AdminPage } from '../AdminPage';
import { SiteSearch } from './SiteSearch';
import { UpcomingSeasons } from './season';
import { adminSiteCreate } from './generated';

export function Sites() {
  const history = useHistory();

  function render() {
    return <AdminPage title='Schools' actions={renderActions()}>
      <Tabs baseUrl={`/admin2/schools/:tab`} urlParameter='tab' tabs={[
      {label:'Search', name: 'search', content: <SiteSearch />},
      {label:'Upcoming Seasons', name: 'upcoming_seasons', content: <UpcomingSeasons />}
    ]} />
    </AdminPage>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem onClick={onClickAddSite}>Add school</MenuItem>
    </OptionsMenu>
  }

  async function onClickAddSite() {
    const result = await Modal.show(<Modal title='Create school'>
      <Form initialValues={{partner: false}}>
        <Section label='School name' name='name' component={InputField} required />
        <Section label='Address' name='streetAddress' component={InputField} required />
        <Section label='Zip code' name='zipcode' component={InputField} required />
        <Section label='Partner' name='partner' component={CheckboxField} />
        <Section label='Active' name='active' component={CheckboxField} />
        <Section label='Searchable' name='searchable' component={CheckboxField} />
      </Form>
    </Modal>);

    if (result.action === SaveableResult.cancel) {
      return;
    }

    const [success, createResult] = await adminSiteCreate({ variables: { attributes: result.result }} );

    if (success) {
      history.push(`/admin2/schools/${createResult.data.siteCreate.site.id}/info`);
    }

    return success;
  }

  return render();
}
