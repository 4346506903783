import * as React from 'react';

import { SeasonRate } from 'app2/api';
import { Box, BoxProps, VBox } from 'app2/components';

import { formatConfigurableSeasonRate } from './formatConfigurableSeasonRates';

interface Props extends BoxProps {
  value?: SeasonRate[];
}

export function ConfigurableSeasonRatesDisplay(props: Props) {
  const { value, ...remaining } = props;

  return (
    <VBox className="hr-value" {...remaining}>
      {value?.map?.((item, index) => (
        <Box key={index} text="body">
          {formatConfigurableSeasonRate(item)}
        </Box>
      ))}
    </VBox>
  );
}
