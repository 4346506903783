import { axios } from 'app2/api';
import { observable } from 'app/observable';

import { CatalogListingDetails } from './CatalogListingDetails';

@observable
export class CatalogListingStore {
  slug: string;
  catalog: CatalogListingDetails;

  load(slug: string) {
    if (this.slug == slug) {
      return false;
    }

    this.slug = slug;

    return this.loadCatalog();
  }

  async loadCatalog() {
    try {
      const response = await axios.get<CatalogListingDetails>(
        `/course_frameworks/${this.slug}/catalog_page`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(`/catalog/${response.data.redirect_to_slug}`);
        return false;
      }

      this.catalog = response.data;

      return true;
    } catch (_) {
      window.location.replace('/not-found');

      return false;
    }
  }
}
