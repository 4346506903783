import * as React from 'react';
import { useTheme } from 'styled-components';

import { useResizeObserver, useEventListener, useSafeState } from '../utils';
import { Theme, ThemeProvider } from '../theme';

// allows you to customize when the breakpoints occur on an container
// if breakpoints are specified, they will override the theme breakpoints.

// additionally the containerBreakpoints feature allows you to use
// the container width as what dictates when breakpoints are used.
// normally breakpoints are based on the device width. setting
// containerBreakpoints effectively tricks css into using the
// container width as the current breakpoint instead of the device
// width.
// 
// for example - given the following:
//  current browser width = 1500px
//  container width = 500px
//
//  defined breakpoints are:
//    phoneWidth = 440;
//    tabletWidth = 1024;
//    maxWidth = 1200;
//
// normally the current breakpoint would be
// desktop (#2) because 1500px > 1200px
//
// with container breakpoints the current
// breakpoint becomes table (#1) because
// 500px > 440px but 500px < 1200px


interface BreakpointsContainerProps {
  containerBreakpoints?:boolean;
  breakpoints?:number[];
  children:React.ReactElement;
}


export function BreakpointsContainer(props: BreakpointsContainerProps) {
  const childRef = (props.children as any).ref || React.useRef();

  // the onResize handler can get called multiple times with the same dimensions
  // save that info to a ref so that we can avoid extra state calls that might be
  // causing the "Maximum update depth exceeded." because react state doesn't 
  // update immediately in callbacks.
  const widthsRef = React.useRef({container:window.innerWidth, browser: window.innerWidth});
  const [widths, setWidths] = useSafeState({container:window.innerWidth, browser: window.innerWidth});

  const theme = useTheme() as Theme;
  const updatedTheme = React.useMemo(() => {
    const ratio = props.containerBreakpoints ? widths.browser / widths.container : 1;
    const breakpointValues = props.breakpoints || theme.breakpoints;
    const breakpoints = breakpointValues.map(breakpoint => (parseFloat(breakpoint as string) * ratio) + 'px') as Theme['breakpoints'];
    breakpoints.phone = breakpoints[0];
    breakpoints.tablet = breakpoints[1];

    return {
      ...theme,
      breakpoints
    }
  }, [theme, widths, props.containerBreakpoints, props.breakpoints]);

  useResizeObserver(childRef, onResize);
  useEventListener('resize', onResize, window);

  function render() {
    return <ThemeProvider theme={updatedTheme}>{React.cloneElement(props.children, {...props.children.props, ref: childRef})}</ThemeProvider>;
  }

  function onResize() {
    if (!childRef.current || typeof childRef.current.getBoundingClientRect != 'function') {
      return;
    }

    const newValue = {container: childRef.current.getBoundingClientRect().width, browser:window.innerWidth};
    
    if (widthsRef.current.container == newValue.container && widthsRef.current.browser == newValue.browser) {
      return;
    }

    widthsRef.current = newValue;
    setWidths(newValue);
  }

  return render();
}

BreakpointsContainer.displayName = 'BreakpointsContainer';

// makes the BreakpointsContainer class available to the component base
// because of circular imports it cant be imported directly
// @ts-ignore
window['__BreakpointsContainer'] = BreakpointsContainer;