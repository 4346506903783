import * as React from 'react'
import { omit, cloneDeep, isEqual } from 'lodash-es';

import { Course } from 'app2/api';
import { isNewId } from 'app2/components';
import { CourseCardCourse, CourseBreakdowns } from 'app2/views/shared-public';

import { useCoursePriceBreakdownsQuery } from './generated';

export type CourseWithBreakdowns = Pick<Course, 'rates' | 'feeType' | 'feeRate'> & CourseCardCourse & {priceBreakdowns:CourseBreakdowns};

// takes an existing course object and returns price breakdowns merged into the course
// this is for code that is editing the course and needs realtime breakdown updates

export function useCourseWithBreakdowns(course:CourseWithBreakdowns) {
  const rates = React.useRef({rates: null as Course['rates'], feeType: null as Course['feeType'], feeRate: null as Course['feeRate'], updateCount:0});
  const ratesChanged = course && ((course.rates && !isEqual(course.rates, rates.current.rates)) || course.feeType != rates.current.feeType || course.feeRate != rates.current.feeRate)

  if (ratesChanged) {
    // if it was null before it just hadn't loaded yet so don't count that as an update
    rates.current.updateCount += rates.current.rates ? 1 : 0;
    // need to clone rates because our forms modify data inline
    rates.current.rates = cloneDeep(course?.rates);
    rates.current.feeType = course?.feeType;
    rates.current.feeRate = course?.feeRate;
  }

  const [result] = useCoursePriceBreakdownsQuery({
    variables: { id: course?.id, rates: omit(rates.current.rates, 'id'), feeType: course?.feeType, feeRate: course?.feeRate },
    hideLoader: true,
    // null is a legitimate feeRate/feeType so we need to turn off autoPause once we have the course id
    autoPause: false,
    pause: isNewId(course?.id) || rates.current.updateCount < 1,
    debounce: { delay: 500 }
  });

  const breakdowns = result.data?.coursePriceBreakdowns || course?.priceBreakdowns;
  const updated = React.useMemo(() => ({...course, prices:breakdowns}), [breakdowns]);

  return {course:updated, breakdowns};
}
