import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeacherScheduleCoursesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TeacherScheduleCoursesQuery = {
  seasonCourses?:
    | {
        items: Array<
          | {
              id: string;
              name?: string | null | undefined;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              room?: string | null | undefined;
              noEnrichmentDays: Array<string | null | undefined>;
              status: Types.CourseStatus;
              courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
              company?: { name?: string | null | undefined; id: string } | null | undefined;
              site: { id: string; name?: string | null | undefined };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const TeacherScheduleCoursesDocument = gql`
  query TeacherScheduleCourses {
    seasonCourses(filters: { byStatus: [ACTIVE, COMPLETED, ENROLLING, UPCOMING] }, kinds: [camp, enrichment, extended_care, game, time_slot]) {
      items {
        id
        name
        startDate
        endDate
        room
        noEnrichmentDays
        status
        courseDays {
          start
          finish
          days
        }
        company {
          name
          id
        }
        site {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TeacherScheduleCoursesArgs = MakeOptional<UseQueryArgs<TeacherScheduleCoursesQueryVariables, TeacherScheduleCoursesQuery>, 'query'>;

export function useTeacherScheduleCoursesQuery(options: TeacherScheduleCoursesArgs = {}) {
  return useQuery<TeacherScheduleCoursesQueryVariables, TeacherScheduleCoursesQuery>({ query: TeacherScheduleCoursesDocument, ...options });
}

export type TeacherScheduleCoursesOptions = ExecQueryOptions<TeacherScheduleCoursesQueryVariables>;

export function teacherScheduleCourses(options: TeacherScheduleCoursesOptions) {
  return executeQuery<TeacherScheduleCoursesQuery, TeacherScheduleCoursesQueryVariables>(TeacherScheduleCoursesDocument, options);
}

export type TeacherScheduleCoursesSelections = TeacherScheduleCoursesQuery['seasonCourses']['items'][0];
