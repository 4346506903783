import * as React from 'react';

import { COURSE_IMAGE_WIDTH, COURSE_IMAGE_RATIO } from 'app2/views/shared-public';
import { companyFolder } from 'app/helpers';
import defaultCourseImg from 'images/default_course_img.png';

import { CloudinaryPhotoField, CloudinaryPhotoInput, FieldComponentProps, Section } from 'app2/components';
import { courseImageSearch } from 'app2/views';

interface Props extends FieldComponentProps<any> {
  siteId?:string;
  companyId:string;
}

export function CourseImageField(props:Props) {
  const {siteId, companyId, ...remaining} = props;

  function render() {
    return <Section {...courseImage} defaultImage={defaultCourseImg} ratio={COURSE_IMAGE_RATIO} imageWidth={COURSE_IMAGE_WIDTH} 
      options={getBackgroundImageOptions()} component={CloudinaryPhotoField} edit={{component:CloudinaryPhotoInput, search:courseImageSearch}} {...remaining} />
  }

    function getBackgroundImageOptions() {
      return React.useMemo(() => {
        return {
          uploadPreset: 'course_image',
          folder: companyFolder(companyId),
          signatureUrl: `/courses/course_image_signature/${companyId}`,
          customSignatureParams: { site_id: siteId },
          cropping: true,
          croppingAspectRatio: COURSE_IMAGE_RATIO
        };
      }, [siteId, companyId]);
    }

    return render()
  }

const  courseImage = {
  name: 'courseImage',
  label: 'Background image',
  disabled: false
}
