import moment from 'moment';

// for cases where the server wants a date but
// we are sending a date time, this puts it in
// a format that will ensure the server will
// get the right thing for either date or date time
// it does this by returning a iso8601 formatted in
// local time, but that contains the time zone offset
// if the server wants a date, it will just take the date 
// and ignore the time
// if it wants a date time, then even though its in
// local time, it will have the the timezone in it,
// so the server can convert it to utc correctly

export function fixDateVars(vars:any) {
  let updated:any;
  const isArray = Array.isArray(vars);

  for (const propName in vars) {
    const propValue = vars[propName];

    if (moment.isMoment(propValue) || propValue instanceof Date) {
      if (!updated) {
        updated = isArray ? vars.slice() : {...vars};
      }

      updated[propName] = moment(propValue).format();
    }
    else
    if (typeof propValue == 'object') {
      const updatedChild = fixDateVars(propValue);

      if (updatedChild !== propValue) {
        if (!updated) {
          updated = isArray ? vars.slice() : {...vars};
        }

        updated[propName] = updatedChild;
      }
    }
  }

  return updated || vars;
}
