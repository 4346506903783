import * as React from 'react';

import { BoxProps, HBox } from './Box';

// CardRow is simple a HBox that ensures consistent
// sizing of the row elements by putting in hidden
// placeholders when a row is not completely full

export interface CardRowProps extends BoxProps {
  items: React.ReactElement[];
  numPerRow: number;
}

export function CardRow(props: CardRowProps) {
  let { items, numPerRow, hItemSpace, vItemSpace, ...remaining } = props;

  // the layout doesn't work if the row isn't entirely filled
  // because the browser margin doesn't seem to be counted
  // as part of the width, so we add an empty rectangle to prevent this
  while (items.length < numPerRow) {
    items.push(<HBox opacity={0} />);
  }

  const width = 100 / numPerRow + '%';
  items = items.map((card, index) =>
    React.cloneElement(card, { key: index, width, mb: { vItemSpace } })
  );

  return (
    <HBox
      maxWidth="100%"
      minWidth="100%"
      hItemSpace={hItemSpace}
      {...remaining}
    >
      {items}
    </HBox>
  );
}

CardRow.defaultProps = {
  hItemSpace: '$15',
  vItemSpace: '$10'
};
