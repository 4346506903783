import moment from 'moment'

import { Season } from '../graphql';

export type SeasonWithDates = Partial<Pick<Season, 'enrollmentOpens' | 'enrollmentCloses' | 'holidays' | 'coursesBegin' | 'coursesFinish'>>;

export class SeasonUtils {
  static oldReports(season:Pick<Season, 'enrollmentOpens'>) {
    return moment(season?.enrollmentOpens).isBefore('2019-08-01');
  }

  static nowOrSeasonBegin(season:Pick<Season, 'coursesBegin' | 'coursesFinish'>) {
    const now = moment();

    return now.isBetween(season?.coursesBegin, season?.coursesFinish) ? now : season?.coursesBegin
  }

  static getSeasonDates(season:SeasonWithDates) {
    return [
      { name: 'Enrollment', bg: 'enrollment', start: season.enrollmentOpens, end: season.enrollmentCloses },
      { name: 'Activity days', bg: 'courseDay', start: season.coursesBegin, end: season.coursesFinish },
      { name: 'No activity days', bg: 'noEnrichment', days: season?.holidays }
    ]
  }
}