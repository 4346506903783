import * as React from 'react';

import { FileUpload, FileUploadAttributes } from 'app2/api';
import { FieldInfo, useFormInfo, VBox } from 'app2/components';

import { FileLinks } from './FileLinks';
import { FileTags } from './FileTags';
import { FileUploader } from './FileUploader';

interface Props {
  value?: FileUploadAttributes[];
  field?: FieldInfo<any>;
  disabled?: boolean;
  uploader?:boolean;//force into edit mode
}

export function FileUploads(props: Props) {
  const formInfo = useFormInfo();

  function render() {
    return (
      <VBox>
        {!formInfo.editing && !props.uploader
          ? <FileLinks files={props.value as FileUpload[]} />
          : <>
            <FileTags files={props.value} mb="$12" onRemove={onRemoveFile} disabled={props.disabled} />
            <FileUploader kind="secondary" onChoose={onChooseFile} disabled={props.disabled} />
          </>}
      </VBox>
    );
  }

  function onChooseFile(name: string, fileType: string, fileData: string) {
    const existing = formInfo.form.getValue(props.field.parents, props.field.name)
    const uploads = (Array.isArray(existing) ? existing : []).concat({ name, fileType, fileData });
    formInfo.form.setValue(props.field.parents, props.field.name, uploads);
  }

  function onRemoveFile(pos: number) {
    const uploads = formInfo.form.getValue(props.field.parents, props.field.name).slice();
    uploads.splice(pos, 1);

    formInfo.form.setValue(props.field.parents, props.field.name, uploads);
  }

  return render();
}

FileUploads.fieldProps = {
  valueProperty: 'value',
  fieldProperty: 'field'
};
