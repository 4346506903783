import * as React from 'react';

import { Wizard, VBox, Dropdown } from 'app/app2/components';

import { Demos, Demo } from './Demo';

export function WizardDemos() {
  return <Demos name='Tab'>
    <Demo name='Tabs'>
      <Wizard width='400px' border='solid 1px' tabs={[{
         label: 'Cart',
         icon: 'ShoppingCart',
         content: <VBox>
            <VBox text='heading1'>Cart</VBox>
            <VBox text='body'>cart item 1 ... $10</VBox>
            <VBox text='body'>cart item 2 ... $5</VBox>
            <VBox text='body'>cart item 3 ... $10</VBox>
          </VBox>,
         next: true,
         prev: false
      }, {
        label: 'Payment',
        icon: 'DollarSign',
       content: <VBox hAlign='left'><Dropdown options={['abc', '123']} /></VBox>,
        next: 'Finish'
     }]} />
    </Demo>
  </Demos>
}
