import * as React from 'react'

import { CourseKind, CourseUtils } from 'app2/api';
import { Button, ButtonProps, NumberStepper, VBox } from 'app2/components';

import { Cart } from '../../cart';

import { CourseCardCourse } from './CourseInfo';
import { CourseVariantsAddToCart } from './CourseVariantsAddToCart';

export type AddToCartElement = React.ReactElement<AddToCartProps>;

export interface AddToCartProps extends ButtonProps {
  course:CourseCardCourse;
  cart:Cart;
  addToCart?:AddToCartElement;
  primaryActions?:React.ReactElement[];
  variantMode?:'variants' | 'no-variants' | 'either';
  variantFilter?:(c:CourseCardCourse) => boolean;
}

export function AddToCart(props:AddToCartProps) {
  const {course, cart, width, addToCart, primaryActions, variantMode, variantFilter, ...remaining} = props;
  const quantity = cart?.counts?.get(course.id) || 0;

  function render() {
    const variants = course.children;
    const hasVariants = variants?.length;
  
    if (variantMode === 'variants' && !hasVariants) {
      return <></>;
    }
  
    if (variantMode === 'no-variants' && (hasVariants || course.kind == CourseKind.LessonSet)) {
      return <></>;
    }

    return <VBox gap='$8' width={width || ['100%', 'unset']} >
      {primaryActions?.map((node:React.ReactNode, index:number) => React.cloneElement(node as React.ReactElement, {key:index}))}
      {!variants?.length ? renderActionButton() : renderVariants()}
    </VBox>
  }

  function renderActionButton() {
    return <VBox key='add-to-cart' gap='$16' onClick={onClick}>
      {addToCart 
        ? React.cloneElement(addToCart, props)
        : quantity 
          ? renderStepper() 
          : renderAddToCartButton()}
    </VBox>
  }

  function renderStepper() {
    return <NumberStepper width="100%" minWidth="160px" maxWidth='414px'
    onIncrement={() => cart?.addToCart?.(props.course)} onDecrement={() => cart?.removeFromCart?.(props.course.id)} value={quantity} />
  }

  function renderAddToCartButton() {
    const buttonProps = {
      width: '100%',
      minWidth: '160px',
      autoLoader: true,
      onClick: () => cart?.addToCart?.(props.course),
      ...remaining
    };

    if (props.course.closed) {
      return <Button {...{ ...buttonProps, disabled: true }}>Closed</Button>;
    }

    if (props.course.waitlistOpen) {
      return <Button {...buttonProps}>Join waitlist</Button>;
    }

    const prompt = CourseUtils.schedulableAndNeedsPrompt(course);
    const label = prompt ? 'Choose schedule' : course.hasCheckoutOptions ? 'Choose options' : 'Add to cart';

    return <Button {...buttonProps}>{label}</Button>;
  }

  function renderVariants() {
    return <CourseVariantsAddToCart course={course} cart={cart} addToCart={addToCart} variantFilter={variantFilter} />
  }

  function onClick(event:React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  return render();
}
