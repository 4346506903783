import * as React from 'react';

import { Button, CurrencyField, DateField, Field, FieldRendererProps, NotificationManager, NotificationProps } from 'app2/components';

import { CourseLink, QueryList, StudentLink, UserLink } from '../shared';

import { adminProcessPendingRefund, useAdminSitePendingRefundsQuery } from './generated';

export function PendingRefunds() {
  function render() {
    return <QueryList icon='Home' title='Pending refunds' query={useAdminSitePendingRefundsQuery} name='pendingRefunds' fields={[
      <Field name='createdAt' label='Created' component={DateField} />,
      <Field name='parent.name' label='Parent' component={UserLink} />,
      <Field name='student.name' label='Student' component={StudentLink} />,
      <Field name='course.name' label='Activity' component={CourseLink} />,
      <Field name='amount' label='Amount' component={CurrencyField} />,
      <Field name='id' render={(props:FieldRendererProps) => <Button onClick={() => process(props.info.value)}>Process now</Button>} />,
    ]} />
  }

  async function process(refund:string) {
    const [_, result] = await adminProcessPendingRefund({variables: {refund}});

    const success = result.data.homeroomProcessPendingRefund;
    const notification:NotificationProps = {title: 'Process refund', message: success ? 'Successful' : 'Failed', type: success ? 'success' : 'error'};

    NotificationManager.add(notification);
  }

  return render();
}
