import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonCoursesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SeasonCoursesQuery = { season?: { id: string; courses: Array<{ id: string; name?: string | null | undefined; status: Types.CourseStatus }> } | null | undefined };

export const SeasonCoursesDocument = gql`
  query SeasonCourses($id: ID!) {
    season(season: $id) {
      id
      courses {
        id
        name
        status
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SeasonCoursesArgs = MakeOptional<UseQueryArgs<SeasonCoursesQueryVariables, SeasonCoursesQuery>, 'query'>;

export function useSeasonCoursesQuery(options: SeasonCoursesArgs = {}) {
  return useQuery<SeasonCoursesQueryVariables, SeasonCoursesQuery>({ query: SeasonCoursesDocument, ...options });
}

export type SeasonCoursesOptions = ExecQueryOptions<SeasonCoursesQueryVariables>;

export function seasonCourses(options: SeasonCoursesOptions) {
  return executeQuery<SeasonCoursesQuery, SeasonCoursesQueryVariables>(SeasonCoursesDocument, options);
}

export type SeasonCoursesSelections = SeasonCoursesQuery['season'];
