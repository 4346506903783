import { CoursePreferences } from './CoursePreferences';

import { UserPreferences, preference } from '../shared/preferences';

import { CoursesTableWithTabsPreferences } from './CoursesTableWithTabsPreferences';

export class AuthorizedPreferences extends UserPreferences {
  tablePreferences(type:string) {
    return new CoursesTableWithTabsPreferences(this, type);
  }

  coursePreferences(course: string) {
    return new CoursePreferences(course);
  }

  @preference
  dontShowSaveAndTransition:boolean;

  @preference
  showWeekends:boolean;
}

export const authorizedPreferences = new AuthorizedPreferences();
