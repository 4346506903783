import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyCreateAdjustmentMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  amount: Types.Scalars['Float'];
  description: Types.Scalars['String'];
}>;

export type AdminCompanyCreateAdjustmentMutation = { companyCreateAdjustment?: { id: string } | { id: string } | null | undefined };

export const AdminCompanyCreateAdjustmentDocument = gql`
  mutation AdminCompanyCreateAdjustment($company: ID!, $amount: Float!, $description: String!) {
    companyCreateAdjustment(company: $company, amount: $amount, description: $description) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminCompanyCreateAdjustmentOptions = ExecMutationOptions<AdminCompanyCreateAdjustmentMutationVariables>;

export function adminCompanyCreateAdjustment(options: AdminCompanyCreateAdjustmentOptions) {
  return executeMutation<AdminCompanyCreateAdjustmentMutation, AdminCompanyCreateAdjustmentMutationVariables>(AdminCompanyCreateAdjustmentDocument, options);
}
