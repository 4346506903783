import * as React from 'react'

import { NonePanel } from 'app2/views/shared-public'
import { CourseSchedule } from 'app2/views/shared'

import { TeacherPage, useTeacherPreference } from '../shared'

import schedule from './schedule.png'
import { useTeacherScheduleCoursesQuery } from './generated'

export function Schedule() {
  const [result] = useTeacherScheduleCoursesQuery();
  const fetching = result.fetching;
  const courses = result.data?.seasonCourses?.items;
  const [showWeekends, setShowWeekends] = useTeacherPreference('showWeekends');

  function render() {
    return <TeacherPage title='Schedule'>
      {fetching
        ? <></>
        : !courses?.length 
          ? renderNone() 
          : <CourseSchedule courses={courses as any} weekends={showWeekends} onChangeWeekends={setShowWeekends}
            scheduleUrlBase={`/instructor/schedule`} activityUrlBase={`/activities/manage`} />}
    </TeacherPage>
  }

  function renderNone() {
    return <NonePanel description='You don’t have any activities scheduled at the moment' image={schedule} add={undefined} />
  }

  return render()
}