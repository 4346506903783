import * as React from 'react'
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';

import { SearchType } from 'app2/api';
import { OptionsMenu, MenuItem } from 'app2/components';

interface Props {
  type:SearchType;
  setType:(type:SearchType) => void;
}

export function SearchTypeMenu(props:Props) {
  const {type:propsType, setType} = props;
  const type = propsType ?? SearchType.Student;

  return <OptionsMenu label={capitalize(pluralize(type))} dropdown={{zIndex:5000, offset:{x: -27, y:8}}}>
    <MenuItem selected={type == SearchType.Student} onClick={() => setType(SearchType.Student)}>Students</MenuItem>
    <MenuItem selected={type == SearchType.Activity} onClick={() => setType(SearchType.Activity)}>Activities</MenuItem>
  </OptionsMenu>
}