import * as React from 'react';
import { OperationResult } from 'urql';

import { CreditCard } from 'app2/api';
import { hasAppMode, ErrorWithPath } from 'app2/components';
import { ErrorHandlerInfoOrHandlers, handleErrors } from 'app2/views';

import { parentAddCreditCard, ParentAddCreditCardMutation, ParentAddCreditCardMutationVariables } from './generated';

interface AddCreditCardOptions {
  family?: string;
  name: string;
  stripe: any;
  error?: ErrorHandlerInfoOrHandlers;
  updateCartPaymentService?: boolean;
}

export async function addCreditCard(options: AddCreditCardOptions): Promise<[boolean, OperationResult<ParentAddCreditCardMutation, ParentAddCreditCardMutationVariables>, ErrorWithPath[], CreditCard]> {
  const {error, token} = !hasAppMode('test') 
    ? await options.stripe.createToken({ name: options.name })
    : {error: null, token: {id:'test', card: {id: 'test', name: 'test', last4: '4242', exp_month: 12, exp_year: 2022, brand: 'visa'}}};

  if (error) {
    handleErrors(options.error, [error])

    return [false, null, null, null]
  }

  const result = await parentAddCreditCard({ variables: { token: token.id, family: options.family, updateCartPaymentService: options.updateCartPaymentService }, error: options.error, context: { additionalTypenames: ['User'] } });
  const success = result[0];
  if (!success) {
    return [...result, null];
  }

  const card = {
    id: token.card.id,
    name: token.card.name,
    last4: token.card.last4,
    expiration: token.card.exp_month + '/' + token.card.exp_year.toString().substr(2),
    brand: token.card.brand.toLowerCase()
  };
  return [...result, card];
}
