import pluralize from 'pluralize';

import { SeasonRate } from 'app2/api';

export function formatConfigurableSeasonRates(items: SeasonRate[]) {
  return items?.map(formatConfigurableSeasonRate).join('\n');
}

export function formatConfigurableSeasonRate(item: SeasonRate) {
  if (!item) {
    return;
  }

  return `${item.days || '?'} ${pluralize('day', item.days)}/week for $${Number.isFinite(item.rate) ? item.rate : '?'}`;
}
