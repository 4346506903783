import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MarkThreadReadMutationVariables = Types.Exact<{
  threadScope?: Types.InputMaybe<Types.Scalars['String']>;
  lastMessage: Types.Scalars['ID'];
}>;

export type MarkThreadReadMutation = { messageThreadMarkRead?: { success?: boolean | null | undefined } | null | undefined };

export const MarkThreadReadDocument = gql`
  mutation MarkThreadRead($threadScope: String, $lastMessage: ID!) {
    messageThreadMarkRead(threadScope: $threadScope, lastMessage: $lastMessage) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type MarkThreadReadOptions = ExecMutationOptions<MarkThreadReadMutationVariables>;

export function markThreadRead(options: MarkThreadReadOptions) {
  return executeMutation<MarkThreadReadMutation, MarkThreadReadMutationVariables>(MarkThreadReadDocument, options);
}
