import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Heading2, Checkbox, HBox, Link, Navigation, NavigationSection, MenuItem, VBox } from 'app/app2/components';

import { Demo } from './Demo';
import { Page } from 'app/app2/views';

export function NavigationDemo() {
  const [isOrganizer, setIsOrganizer] = React.useState(true);
  const [isProvider, setIsProvider] = React.useState(true);
  const [isParent, setIsParent] = React.useState(true);

  return <Demo name='Navigation'>
    <VBox>
      <Heading2>User roles</Heading2>
      <HBox hItemSpace='$15' pb='$40'>
        <Checkbox label='Organizer' checked={isOrganizer} onChange={(event) => setIsOrganizer(event.currentTarget.checked)} />
        <Checkbox label='Provider' checked={isProvider} onChange={(event) => setIsProvider(event.currentTarget.checked)} />
        <Checkbox label='Parent' checked={isParent} onChange={(event) => setIsParent(event.currentTarget.checked)} />
      </HBox>
    </VBox>
    <Page header navigation={renderNavigation(isOrganizer, isProvider, isParent)}>
      <Switch>
        {isOrganizer && renderOrganizerRoutes()}
        {isProvider && renderProviderRoutes()}
        {isParent && renderParentRoutes()}
      </Switch>
    </Page>
  </Demo>
}

function renderNavigation(isOrganizer:boolean, isProvider:boolean, isParent:boolean) {
  return <Navigation>
    <NavigationSection icon='Home' name='Home' />
    {isOrganizer && renderOrganizerLinks()}
    {isProvider && renderProviderLinks()}
    {isParent && renderParentLinks()}
  </Navigation>
}

function renderOrganizerLinks() {
  return <NavigationSection icon='Book' name='School'>
    <MenuItem to='/admin/component-demos/navigation/season' label='Season' />
    <MenuItem to='/admin/component-demos/navigation/general' label='General Info'/>
    <MenuItem to='/admin/component-demos/navigation/team' label='Team' />
    <MenuItem to='/admin/component-demos/navigation/admin' label='Admin tools' />
    <MenuItem to='/admin/component-demos/navigation/policies' label='Policies' />
    <MenuItem to='/admin/component-demos/navigation/providers' label='Providers' />
    <MenuItem to='/admin/component-demos/navigation/reports' label='Reports' />
  </NavigationSection>
}

function renderOrganizerRoutes() {
  return <>
    <Route path='/admin/component-demos/navigation/season' exact>
      <VBox>
        Season
        <Link to='/admin/component-demos/navigation/season/activity/1'>Go to activity</Link>
      </VBox>
    </Route>
    <Route path='/admin/component-demos/navigation/season/activity/:slug' exact>Activity</Route>
    <Route path='/admin/component-demos/navigation/general' exact><VBox bg='yellow' width='100%'>General Info</VBox></Route>
    <Route path='/admin/component-demos/navigation/team' exact component={Team} />
    <Route path='/admin/component-demos/navigation/admin' exact>Admin tools</Route>
    <Route path='/admin/component-demos/navigation/policies' exact>Policies</Route>
    <Route path='/admin/component-demos/navigation/providers' exact>Providers</Route>
    <Route path='/admin/component-demos/navigation/reports' exact>Reports</Route>
  </>
}

function renderProviderLinks() {
  return <NavigationSection icon='Briefcase' name='Company' />;
}

function renderProviderRoutes() {
}

function renderParentLinks() {
  return <NavigationSection icon='Users' name='Family' />;
}

function renderParentRoutes() {
}

function Team() {
  return <VBox bg='yellow' width='100%'>Team</VBox>;
}
