import * as React from 'react'

import { useLifecycle } from '../utils';

interface MeasuringProps {
  row:number;
  onMount:(row:number, node:HTMLElement) => void;
  children:React.ReactNode;
}

export function MeasuringRow(props:MeasuringProps) {
  const ref = React.useRef();

  useLifecycle({onMount});

  function onMount() {
    props.onMount(props.row, ref.current);
  }

  return <div ref={ref} style={{position:'relative', display: 'flex'}}>{props.children}</div>
}