import * as React from 'react';
import pluralize from 'pluralize';

import { Course } from 'app2/api';
import { Body, HBox, Icon } from 'app2/components';

interface Props {
  course: Pick<Course, 'minCapacity' | 'maxCapacity'>;
}

export function CourseCapacity(props: Props) {
  const course = props.course;
  const capacity = course.maxCapacity || 0;

  function render() {
    return <HBox mb="$8" vAlign="center">
      <Icon name="Users" size='medium' mr="$8" />
      <Body fontWeight="normal">{capacity} {pluralize('student', capacity)}</Body>
    </HBox>
  }

  return render();
}
