import * as React from 'react'
import shallowEqual from 'shallowequal';

import { ScrollWatcher, Rect, Point } from '../dom-utils';

import { VirtualGridFactory } from './VirtualGridFactory';
import { MeasuredHeightFactory, MeasuredHeightFactoryHelper } from './MeasuredHeightFactory';

export enum VirtualGridUpdateType {
  redraw,
  resize
}

export interface CellInfo {
  pos:Point;
  rect:Rect;
  element:HTMLElement;
}

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  factory:VirtualGridFactory;
  virtualizedY?:boolean;
  virtualizedX?:boolean;
  measuredRows?:boolean;
  onDimensionsChanged?:() => void;
}

export class VirtualGrid extends React.Component<Props> {
  static defaultProps:Partial<Props> = {
    virtualizedY: true,
    virtualizedX: true
  }

  factory:VirtualGridFactory;
  totalHeight:number = 0;
  totalWidth:number = 0;
  renderedXY:Rect = new Rect();
  renderedRowCols:Rect = new Rect();
  element = React.createRef<HTMLDivElement>();
  scrollWatch:ScrollWatcher;
  scrollPos:Point = new Point();
  computeTimeout:any;
  computeTotalOnUpdate:boolean;
  cells?:React.ReactNode[];

  componentDidMount() {
    this.scrollWatch = new ScrollWatcher({onScroll: this.onScroll, onResize: this.onResize});
    this.scrollWatch.watch(this.element.current);
    this.onUpdateProps(null, this.props);
  }

  componentWillReceiveProps(nextProps:Props) {
    if (!shallowEqual(this.props, nextProps)) {
      this.onUpdateProps(this.props, nextProps);
    }
  }

  componentWillUnmount() {
    this.clearComputeTimeout();
    this.scrollWatch.unwatch();
  }

  get visibleTopLeft() {
    return this.renderedRowCols.topLeft;
  }

  get mounted() {
    return Boolean(this.scrollWatch?.scroller || this.element)
  }

  onUpdateProps(oldProps:Props, newProps:Props) {
    const needFactory = oldProps?.measuredRows != newProps?.measuredRows || oldProps?.factory != newProps?.factory;

    if (needFactory) {
      this.factory = newProps.measuredRows && !(newProps.factory as unknown as MeasuredHeightFactoryHelper).getRowHeight ? new MeasuredHeightFactory(newProps.factory) : newProps.factory;
    }

    const needCompute = needFactory || oldProps.virtualizedX != newProps.virtualizedX || oldProps.virtualizedY != newProps.virtualizedY;

    if (needCompute) {
      this.computeVisibleAndRender(true);
    }
  }

  deferredCompute(computeTotal:boolean) {
    this.clearComputeTimeout();

    this.computeTotalOnUpdate = this.computeTotalOnUpdate || computeTotal;
    this.computeTimeout = setTimeout(() => this.computeVisibleAndRender(), 100);
  }

  clearComputeTimeout() {
    if (this.computeTimeout) {
      clearTimeout(this.computeTimeout);
      this.computeTimeout = undefined;
    }
  }

  computeIfNeeded() {
    if (!this.computeTimeout) {
      return;
    }

    this.computeVisibleAndRender(false, true);
  }

  computeVisibleAndRender(computeTotal:boolean = false, rendering:boolean = false) {
    // this shouldnt happen but we've seen it in LR
    if (!this.mounted) {
      return;
    }

    computeTotal = computeTotal || this.computeTotalOnUpdate;
    this.computeTotalOnUpdate = false;

    this.clearComputeTimeout();

    if (computeTotal) {
      this.computeTotal();
    }
    
    const renderedXY = this.renderedXY;
    const renderedRowColsBefore = this.renderedRowCols;

    this.computeVisible();

    // this causes performance issues when using the grouped datatable header due to all the
    // sub-datatables it can create.  as best as i can tell its no longer needed.  if we
    // start to see visual artifact problems (or the datatable filter not updating) then this might be the culprit.
    if (!rendering && renderedRowColsBefore && renderedRowColsBefore.equals(this.renderedRowCols) && renderedXY && renderedXY.equals(this.renderedXY)) {
      return;
    }

    this.renderVisible(rendering);
  }

  render() {
    const {factory, virtualizedY, virtualizedX, measuredRows, onDimensionsChanged, style, ...remaining} = this.props;

    this.computeIfNeeded();

    return <div ref={this.element} style={{position: 'relative', width: `${this.totalWidth}px`, height: `${this.totalHeight}px`, ...style}} {...remaining}>
      {measuredRows ? (factory as unknown as MeasuredHeightFactoryHelper).renderMeasurer() : ''}
      {this.cells}
    </div>
  }

  onScroll = () => {
    this.computeVisibleAndRender();
  }

  onResize = () => {
    this.deferredCompute(false);
  }

  computeTotal() {
    const { height, width } = computeTotal(this.factory);

    if (this.totalHeight != height || this.totalWidth != width) {
      this.totalHeight = height;
      this.totalWidth = width;

      if (this.props.onDimensionsChanged) {
        this.props.onDimensionsChanged();
      }
    }
  }

  computeVisible() {
    // this shouldnt happen but we've seen it in LR
    if (!this.mounted || !this.scrollWatch?.scroller) {
      return;
    }

    // determines the bounds of the visible rows & cols of the
    // grid based on the intersection of the visible scrolling region
    // and the components dimensions.  it does this by simply iterating
    // through each row and column adding the dimensions of each to the 
    // next until it encounters the first and last visible of each.

    const factory = this.factory;
    let scroller = this.scrollWatch.scroller;
    let scrollRect = new Rect(scroller.getBoundingClientRect());
    this.scrollPos = new Point(scroller.scrollLeft, scroller.scrollTop);

    let elementRect = new Rect(this.element.current.getBoundingClientRect());
    elementRect.offset(-scrollRect.left, -scrollRect.top);

    let y = 0;
    let yStart = -1, yEnd = -1;
    let row = 0;
    let rowStart = -1, rowEnd = -1;

    // instead of using scroller.scrollTop we look at the element position in case it's
    // not positioned at the top of the scrollable area.  because of that we also
    // need to reduce the scrollBottom if there's a difference between the two.
    let scrollTop = this.props.virtualizedY ? Math.max(0, -elementRect.top) : 0;
    let scrollBottom = scrollTop + scrollRect.height - (elementRect.top > 0 ? elementRect.top : 0);

    if (scrollTop == 0 && factory.numRows > 0) {
      yStart = rowStart = yEnd = rowEnd = 0;
    }
    
    for (; row < factory.numRows; ++row) {
      const height = factory.rowHeight(row);
      y += height;

      if (y > scrollTop) {
        if (rowStart == -1) {
          yStart = y - height;
          rowStart = row;
        }

        yEnd = y;
        rowEnd = row;
      }

      if (y > scrollBottom) {
        break;
      }
    }

    let x = 0;
    let xStart = -1, xEnd = -1;
    let col = 0;
    let colStart = -1, colEnd = -1;

    // see comment about scrollTop.
    let scrollLeft = this.props.virtualizedX ? Math.max(0, -elementRect.left) : 0;
    let scrollRight = scrollLeft + scrollRect.width - (elementRect.left > 0 ? elementRect.left : 0);

    if (scrollLeft == 0 && factory.numCols > 0) {
      xStart = colStart = xEnd = colEnd = 0;
    }

    for (; col < factory.numCols; ++col) {
      const width = factory.colWidth(col);
      x += width;

      if (x > scrollLeft) {
        if (colStart == -1) {
          xStart = x - width;
          colStart = col;
        }

        xEnd = x;
        colEnd = col;
      }

      if (x > scrollRight) {
        break;
      }
    }

    this.renderedXY = new Rect(xStart, yStart, xEnd, yEnd);
    this.renderedRowCols = new Rect(colStart, rowStart, colEnd, rowEnd);
  }

  renderVisible(rendering:boolean) {
    const measuredRows = this.props.measuredRows;
    const factory = this.factory;
    const cells = [];
    let top = this.renderedXY.top;

    if (this.renderedRowCols.top >= 0 && this.renderedRowCols.height >= 0) {
      for (let row = this.renderedRowCols.top; row <= this.renderedRowCols.bottom; ++row) {
        let height = measuredRows ? (factory as unknown as MeasuredHeightFactoryHelper).getRowHeight(row, false) : factory.rowHeight(row);
        let left = this.renderedXY.left;

        if (height == undefined && measuredRows && this.totalWidth) {
          height = factory.rowHeight(row);
          const measuringFactory = factory as unknown as MeasuredHeightFactoryHelper;

          measuringFactory.measureRow(row, () => {
            this.deferredCompute(true);
          });
        }
      
        const rowContainer = renderRow(factory, row, this.renderedRowCols.top, this.renderedRowCols.bottom, this.renderedRowCols.left, this.renderedRowCols.right, top, left, height, false);
        cells.push(rowContainer);

        top += height;
      }
    }

    this.cells = cells;

    if (!rendering) {
      this.setState({});
      //this.forceUpdateTimer.start(() => this.setState({}), 250);
    }
  }

  getCellFromEvent(event:React.MouseEvent | MouseEvent):CellInfo {
    const factory = this.factory;
    const r = this.element.current.getBoundingClientRect();

    let rowPos = r.top;
    let rowHeight;
    let rowNo;

    for (rowNo = 0; rowNo < factory.numRows; ++rowNo) {
      rowHeight = factory.rowHeight(rowNo);

      if (rowPos + rowHeight > event.clientY) {
        break;
      }

      rowPos += rowHeight;
    }

    if (rowNo == factory.numRows) {
      return null;
    }

    let colPos = r.left;
    let colWidth;
    let colNo = 0;

    for (colNo = 0; colNo < factory.numCols; ++colNo) {
      colWidth = factory.colWidth(colNo);

      if (colPos + colWidth > event.clientX) {
        break;
      }

      colPos += colWidth
    }

    if (colNo == factory.numCols) {
      return null;
    }

    const cellNo = ((rowNo - this.renderedRowCols.top) * (this.renderedRowCols.width + 1)) + colNo - this.renderedRowCols.left;
    const element = this.element.current.children[cellNo] as HTMLElement;

    return {pos: new Point(colNo, rowNo), rect: new Rect(colPos, rowPos, colPos + colWidth, rowPos + rowHeight), element};
  }

  getCellCoordinates(cell:Point):Rect {
    return getCellCoordinates(this.factory, cell);
  }

  // called externally when content has changed:
  //  - undefined row or col means invalidate all along that axis.
  //  - type can be redraw or resize.  resize is more expensive
  //    when there are measured row heights as it will invalidate
  //    all measured rows from the specified row on forward.

  invalidate(row:number, col:number, type:VirtualGridUpdateType) {
    if (type == VirtualGridUpdateType.resize) {
      (this.factory as unknown as MeasuredHeightFactoryHelper).reset?.(row);
    }

    this.deferredCompute(true);
  }
}

export function computeTotal(factory:VirtualGridFactory) {
  let height = 0;

  for (let row = 0; row < factory.numRows; ++row) {
    height += factory.rowHeight(row);
  }

  let width = 0;

  for (let col = 0; col < factory.numCols; ++col) {
    width += factory.colWidth(col);
  }

  return new Point(width, height);
}

export function getCellCoordinates(factory:VirtualGridFactory, cell:Point) {
  let rowPos = 0;
  let rowNo;

  for (rowNo = 0; rowNo < cell.row; ++rowNo) {
    rowPos += factory.rowHeight(rowNo);
  }

  let colPos = 0;
  let colNo = 0;

  for (colNo = 0; colNo < cell.col; ++colNo) {
    colPos += factory.colWidth(colNo);
  }

  const cellCoordinates = new Rect(colPos, rowPos);
  cellCoordinates.bottom += factory.rowHeight(rowNo);
  cellCoordinates.right += factory.colWidth(colNo);

  return cellCoordinates;
}  

export function renderRow(factory:VirtualGridFactory, row:number, startRow:number, endRow:number, startCol:number, endCol:number, top:number, left:number, height:number, measuring:boolean = true) {
  const rowChildren = [];

  if (startCol >= 0 && endCol - startCol >= 0) {
    for (let col = startCol; col <= endCol; ++col) {
      const width = factory.colWidth(col);
      const dims = {top, left, height, width}

      const span = factory.span?.(row, col);
      let render = true;

      if (span) {
        const rowSpan = span.endRow - span.startRow;
        const colSpan = span.endCol - span.startCol;

        if (rowSpan > 0 || colSpan > 0) {
          // see if we already have a cell for this span
          // TODO this doesn't handle row span checks because we are only rendering a row here
          const alreadyRendered = span.startRow >= startRow && span.startRow < row && span.startCol >= startCol && span.startCol < col;
          render = !alreadyRendered;

          let y = row;
          while (y > span.startRow) {
            --y;
            dims.top -= factory.rowHeight(y);
            dims.height += factory.rowHeight(y);
          }

          let x = col;
          while (x > span.startCol) {
            --x;
            dims.left -= factory.colWidth(x);
            dims.width += factory.colWidth(x);
          }

          y = row;
          while (y < span.endRow) {
            ++y;
            dims.height += factory.rowHeight(y);
          }

          x = col;
          while (x < span.endCol) {
            ++x;
            dims.width += factory.colWidth(x);
          }
        }
      }

      const style = {
        overflow: 'hidden',
        position: !measuring ? 'absolute' : undefined,
        top: dims.top + 'px',
        left: dims.left + 'px',
        height: dims.height + 'px',
        minHeight: measuring ? undefined : dims.height + 'px',
        maxHeight: dims.height + 'px',
        width: dims.width + 'px',
        minWidth: dims.width + 'px',
        maxWidth: dims.width + 'px'
      }  as React.CSSProperties;

      let cell = render ? factory.render({row, col, ...dims, style, measuring}) : null;

      if (cell && (cell.key === undefined || cell.key === null)) {
        cell = React.cloneElement(cell, {key: row + ':' + col});
      }

      rowChildren.push(cell);
      left += width;
    }
  }

  return <React.Fragment key={row}>{rowChildren}</React.Fragment>;
}
