import * as React from 'react'
import moment from 'moment'

import { BoxProps, HBox, VBox } from '../Box'
import { Unsticky } from '../Unsticky'
import { DateInput, validOrDefault } from '../date-utils';

import { Header } from './Header';
import { DayOfWeek } from './DayOfWeek';

export type GetAppointments = (date:moment.Moment) => React.ReactElement[];

export interface ScheduleProps extends Omit<BoxProps, 'onChange'> {
  week?:DateInput;
  onChange?:(date:moment.Moment) => void;
  weekends?:boolean;
  onChangeWeekends?:(weekends:boolean) => void;
  appointments:(React.ReactElement[][]) | GetAppointments;
}

export function Schedule(props:ScheduleProps) {
  let {week, onChange, weekends, onChangeWeekends, appointments, ...remaining} = props;
  const [weekState, setWeek] = React.useState(week);
  const [weekendsState, setWeekends] = React.useState(weekends);

  if (weekends === undefined) {
    weekends = weekendsState;
  }

  const startDay = weekends ? 0 : 1;
  const numDays = weekends ? 7 : 5;
  const startOfWeek = validOrDefault(week || weekState).clone().day(startDay).startOf('d');

  const actualAppointments = typeof appointments == 'function'
    ? Array.from(Array(7).keys()).map(index => (appointments as GetAppointments)(startOfWeek.clone().startOf('w').add(index, 'd')))
    : appointments;

  return <VBox {...remaining} data-test='Schedule' borderRadius='standard' border='solid 1px' borderColor='border'>
    <Header startOfWeek={startOfWeek} onChange={date => {setWeek(date); onChange?.(date)}} 
      weekends={weekends} onChangeWeekends={weekends => {setWeekends(weekends); onChangeWeekends?.(weekends)}} />
    <Unsticky horizontal pad width='100%' height='100%'>
      <HBox width='100%' height='100%' flex={1}>{Array.from(Array(numDays).keys()).map(index => <DayOfWeek key={index} 
        startOfWeek={startOfWeek} numDays={numDays} day={index} appointments={actualAppointments[startDay + index]} />)}</HBox>
    </Unsticky>
  </VBox>
}
