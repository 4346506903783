import { FeatherIconNames, featherIconNames } from './FeatherIconNames'
import { CustomIconNames, customIconNames } from './CustomIconNames';

export type AllIconNames = 
  FeatherIconNames |
  CustomIconNames
  ;

export type IconNames = AllIconNames | AllIconNames[];

export const iconNames: IconNames[] = [
  ...featherIconNames,
  ...customIconNames
];
