import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamSetAsContactMutationVariables = Types.Exact<{
  entityKind: Types.EntityKind;
  entityId: Types.Scalars['ID'];
  member: Types.Scalars['ID'];
}>;

export type TeamSetAsContactMutation = {
  teamSetAsContact?:
    | {
        entity?:
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | { id: string; participants?: Array<{ id: string } | { id: string } | { id: string }> | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const TeamSetAsContactDocument = gql`
  mutation TeamSetAsContact($entityKind: EntityKind!, $entityId: ID!, $member: ID!) {
    teamSetAsContact(entityKind: $entityKind, entityId: $entityId, member: $member) {
      entity {
        id
        participants {
          id
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type TeamSetAsContactOptions = ExecMutationOptions<TeamSetAsContactMutationVariables>;

export function teamSetAsContact(options: TeamSetAsContactOptions) {
  return executeMutation<TeamSetAsContactMutation, TeamSetAsContactMutationVariables>(TeamSetAsContactDocument, options);
}
