import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { CourseAttendanceFieldsFragmentDoc } from './courseAttendanceFields.generated';
import { SiteAttendanceFieldsFragmentDoc } from './siteAttendanceFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AttendanceQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  groupingKind: Types.RosterGroupingKind;
  filters?: Types.InputMaybe<Types.RosterFilters>;
  advancedFilter?: Types.InputMaybe<Types.Scalars['JSON']>;
  sort?: Types.InputMaybe<Types.RosterSort>;
  date?: Types.InputMaybe<Types.Scalars['ISO8601Date']>;
}>;

export type AttendanceQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              id: string;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              updatedAt?: string | null | undefined;
              checkedIn?: Types.CheckedIn | null | undefined;
              checkedInAt?: string | null | undefined;
              checkedInBy?: string | null | undefined;
              checkedOut?: boolean | null | undefined;
              checkedOutAt?: string | null | undefined;
              checkedOutBy?: string | null | undefined;
              checkoutDest?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              otherEnrollments: Array<{ id: string; formattedDays: string; formattedTimes: string; course: { id: string; name?: string | null | undefined } }>;
              season?: { id: string; name: string } | null | undefined;
              course: { id: string; name?: string | null | undefined };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const AttendanceDocument = gql`
  query Attendance($groupingId: ID!, $groupingKind: RosterGroupingKind!, $filters: RosterFilters, $advancedFilter: JSON, $sort: RosterSort, $date: ISO8601Date) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: $groupingKind, enrollmentStatus: ATTENDANCE, filters: $filters, advancedFilter: $advancedFilter, sort: $sort, date: $date) {
      items {
        ...courseAttendanceFields
        ...siteAttendanceFields
      }
      total
    }
  }
  ${CourseAttendanceFieldsFragmentDoc}
  ${SiteAttendanceFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AttendanceArgs = MakeOptional<UseQueryArgs<AttendanceQueryVariables, AttendanceQuery>, 'query'>;

export function useAttendanceQuery(options: AttendanceArgs = {}) {
  return useQuery<AttendanceQueryVariables, AttendanceQuery>({ query: AttendanceDocument, ...options });
}

export type AttendanceOptions = ExecQueryOptions<AttendanceQueryVariables>;

export function attendance(options: AttendanceOptions) {
  return executeQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
}

export type AttendanceSelections = AttendanceQuery['rosterByGrouping']['items'][0];

(AttendanceDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
