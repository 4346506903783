import * as React from 'react';

import { PrivatePage, PrivatePageProps } from 'app2/views/shared';
import { useCurrentUser } from 'app2/views/shared-public';

import { InactiveWarning } from './InactiveWarning';
import { ClaimSite } from './claim-site';
import { SiteDropdown, useSites, useCurrentSite } from '.';

interface Props extends PrivatePageProps {
  sites?:boolean
}

export function OrganizerPage(props:Props) {
  const { sites:showSites, ...remaining} = props;
  const { loading:loadingUser } = useCurrentUser();
  const { site, loading:loadingSite } = useCurrentSite();
  const { sites, loading:loadingSites } = useSites();
  const noSites = !loadingUser && !site && !loadingSite && !sites?.length && !loadingSites;

  function render() {
    if (noSites) {
      return <ClaimSite />;
    }

    return <PrivatePage actions={showSites && <SiteDropdown />} {...(!site ? null : remaining)}>
      <InactiveWarning />
      {props.children}
    </PrivatePage>
  }

  return render();
}

OrganizerPage.defaultProps = {
  sites: true
}