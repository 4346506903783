import * as React from 'react'

import { Box, Collapsible, FormattedText, HBox, VBox, Text, DateText, Panel, Title, LONG_DATE_FORMAT, TIME_FORMAT } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public'

import { PublicSeason } from './PublicSeason';
import { PublicSiteSelections } from './generated';

interface Props {
  site:PublicSiteSelections;
  season:PublicSeason;
}

export function SeasonHeader(props:Props) {
  const behavior = courseKindBehavior[props.season.approvedCourseKindGroups[0]];
  const collapsible = props.season.note
    ? {label:'Show season details', hideLabel:'Hide season details'}
    : {minHeight: ['44px', '62px']}

  function render() {
    return <VBox width='100%' mb='$20'>
      <Title icon={['Backdrop', behavior?.icon]} title={props.season?.name} mb='$8' flexWrap='nowrap' />
      <Panel width='100%'>
        <VBox gap='$8'>
          {props.season.note && <FormattedText text='body'>{props.season.note}</FormattedText>}
          <Collapsible type='box' layout={['vbox', 'hbox', 'hbox']} gap='$12' hAlign='justify' {...collapsible}>
              <VBox>
                <Text text='subtitle2'>Enrollment</Text>
                <HBox text='body' display='block'>
                  <DateText timezone={props.site?.timezone} value={props.season.enrollmentOpens} dateFormat={`${LONG_DATE_FORMAT} ${TIME_FORMAT}`} />&nbsp;-&nbsp;
                  <DateText timezone={props.site?.timezone} value={props.season.enrollmentCloses} dateFormat={`${LONG_DATE_FORMAT} ${TIME_FORMAT}`} />
                </HBox>
              </VBox>
              <VBox>
                <Text text='subtitle2'>Season dates</Text>
                <HBox text='body' display='block'>
                  <DateText timezone={props.site?.timezone} value={props.season.coursesBegin} />&nbsp;-&nbsp;
                  <DateText timezone={props.site?.timezone} value={props.season.coursesFinish} />
                </HBox>
              </VBox>
              <VBox maxWidth={['unset', '50%']}>
                <Text text='subtitle2'>No activity dates</Text>
                {props.season.holidays?.length 
                  ? <DateText text='body' timezone={props.site?.timezone} value={props.season.holidays} /> 
                  : <Text text='body'>None</Text>}
              </VBox>
          </Collapsible>
        </VBox>
      </Panel>
    </VBox>
  }

  return render();
}
