import * as React from 'react';
//@ts-ignore
import Bowser from 'bowser';
import styled from 'styled-components';
import { AlertOctagon } from 'react-feather';

import { Button, ThemeProvider, Text, NotificationManager } from 'app2/components';
import { localStorageHelper } from 'app/helpers';

import logo from 'images/logo.png';
import edge from 'images/edge.png';
import chrome from 'images/chrome.png';

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 476px;
  }

  .center-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
`;

export class BrowserCompatibility extends React.Component {
  render() {
    return isCompletelyUnsupported()
      ? this.renderCompletelyUnsupported()
      : this.props.children;
  }

  componentDidMount() {
    if (!isCompletelyUnsupported()) {
      checkBrowserCompatibility();
    }
  }

  renderCompletelyUnsupported() {
    return (
      <ThemeProvider>
        <Styles>
          <div>
            <img src={logo} width={'108px'} style={{ marginBottom: '40px' }} />
            <div className="center-row" style={{ marginBottom: '10px' }}>
              <AlertOctagon color={'#E4464C'} style={{ marginRight: '10px' }} />
              <Text text="heading2">Browser not supported</Text>
            </div>
            <div
              className="center-row"
              style={{
                marginBottom: '40px',
                marginLeft: '10px',
                marginRight: '10px'
              }}
            >
              <Text text="body">
                Unfortunately, Homeroom does not support your browser. <br /> Try
                downloading a different browser below or updating your current
                browser.
              </Text>
            </div>

            <div className="center-row">
              <div style={{ marginRight: '45px' }}>
                <img src={edge} style={{ marginBottom: '20px' }} />
                <a href="https://www.microsoft.com/edge">
                  <Button kind="secondary">Download Edge</Button>
                </a>
              </div>

              <div>
                <img src={chrome} style={{ marginBottom: '20px' }} />
                <a href="https://www.google.com/chrome">
                  <Button kind="secondary">Download Chrome</Button>
                </a>
              </div>
            </div>
          </div>
      </Styles>
    </ThemeProvider>
    );
  }
}

declare global {
  interface Window {
    __proxySupport: boolean;
  }
}

function isCompletelyUnsupported() {
  // see proxy.html for where window.__proxySupport comes from
  return (
    isIe() ||
    isUnsupportedChrome() ||
    isUnsupportedSafari() ||
    !window.__proxySupport
  );
}

function isIe() {
  try {
    // checks IE only attribute that appears to be a
    // reliable way of detecting IE
    // https://www.w3schools.com/jsref/prop_doc_documentmode.asp
    // @ts-ignore
    return window.document.documentMode;
  } catch (e) {
    return false;
  }
}

function isUnsupportedChrome() {
  const browser = Bowser.getParser(navigator.userAgent);

  return (
    browser.getBrowserName() === 'Chrome' &&
    browser.satisfies({ chrome: '<63' })
  );
}

function isUnsupportedSafari() {
  const browser = Bowser.getParser(navigator.userAgent);

  return (
    browser.getBrowserName() === 'Safari' &&
    browser.satisfies({ safari: '<11' })
  );
}

function checkBrowserCompatibility() {
  if (hasShownToUser()) {
    return;
  }

  if (!checkBrowser()) {
    showNotification(getMessage());
  }
}

function hasShownToUser() {
  return localStorageHelper.getItem('homeroom_browser_warning_showed') || false;
}

function checkBrowser() {
  if (localStorageHelper.isPolyFill) {
    return false;
  }

  const browser = Bowser.getParser(navigator.userAgent);

  return browser.satisfies({
    chrome: '>=70',
    safari: '>=14'
  });
}

function getMessage() {
  const browser = Bowser.getParser(navigator.userAgent);

  if (browser.getBrowserName() === 'Chrome') {
    return 'Update to a newer version of Chrome for the best experience.';
  } else if (browser.getBrowserName() === 'Safari') {
    return 'Update to a newer version of Safari for the best experience.';
  } else {
    return "Homeroom isn't optimized for your browser, so you may encounter minor issues. Use Chrome or Safari for the best experience.";
  }
}

async function showNotification(msg: string) {
  await NotificationManager.add(msg);
  
  localStorageHelper.setItem('homeroom_browser_warning_showed', 'true');
}
