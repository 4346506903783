import * as React from 'react'

import { Box } from '../Box';
import { selectedItemAlpha, hoveredItemAlpha } from '../theme';
import { SelectionRendererProps } from '../datatable';

import { List } from './List';

export interface Props extends Partial<SelectionRendererProps> {
  list:List;
}

export function SelectionRenderer(props:Props) {
  const {table, style, list, rowStart, colStart, rowEnd, colEnd, main, ...remaining} = props;
  const isFocus = rowStart == list.state.focusIndex;

  if (!isFocus && props.list.props.selectedStyle == 'checkbox') {
    return <></>;
  }

  const alpha = isFocus ? hoveredItemAlpha : selectedItemAlpha;

  return <Box bg='primary' opacity={alpha} style={style} {...remaining} />;
}