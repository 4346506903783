import * as React from 'react'

import { FCT } from '../form';

import { FieldWithLabelProps, FieldWithLabel } from './FieldWithLabel'

export type PartProps<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any> = FieldWithLabelProps<T, P, C1, C2>;

export function Part<T = any, P extends keyof T = any, C1 extends FCT = any, C2 extends FCT = any>(props:PartProps<T, P, C1, C2>) {
  return <FieldWithLabel inner={{layout:'vbox', vItemSpace:'16px'}} {...props} labelProps={{text: 'formlabel', mb:'$4', ...props.labelProps}} />
}

