import * as React from 'react'

import { BoxProps, HBox } from '../Box';
import { Icon } from '../icons';
import { Body } from '../Text';

interface Props extends Omit<BoxProps, 'error'> {
  type?: 'success' | 'warning' | 'error';
  error:React.ReactNode;
}

export function FieldError(props:Props) {
  const {type, error, ...remaining} = props;

  if (!error) {
    return <></>;
  }

  return <HBox vAlign='center' mt='$4' data-test='Error' className='hr_error_locator' {...remaining}>
    <Icon name='AlertCircle' size='small' color={type} mr='$8' />
    <Body color={type}>{error}</Body>
  </HBox>
}

FieldError.defaultProps = {
  type: 'error'
}
