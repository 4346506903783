import moment from 'moment'

import { DateInput } from './DateInput'
import { isValidDate } from './isValidDate'

export function compareDates(date1:DateInput, date2:DateInput, startYear?:number, endYear?:number, granularity?: moment.unitOfTime.StartOf, timezone?:string) {
  if (!date1 && !date2) {
    return true;
  }

  if (!date1 && date2) {
    return false;
  }

  if (date1 && !date2) {
    return false;
  }

  date1 = moment.tz(date1, timezone);
  date2 = moment.tz(date2, timezone);

  if (!isValidDate(date1, startYear, endYear) && !isValidDate(date2, startYear, endYear)) {
    return true;
  }

  if (!isValidDate(date1, startYear, endYear) && isValidDate(date2, startYear, endYear)) {
    return false;
  }

  if (isValidDate(date1, startYear, endYear) && !isValidDate(date2, startYear, endYear)) {
    return false;
  }

  return date1.isSame(date2, granularity || 'date');
}
