import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyTeamQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type FamilyTeamQuery = {
  family?: { id: string; teamMembers?: Array<{ id: string; email?: string | null | undefined; name?: string | null | undefined; role?: Types.MemberRole | null | undefined }> | null | undefined } | null | undefined;
};

export const FamilyTeamDocument = gql`
  query FamilyTeam($id: ID!) {
    family(family: $id) {
      id
      teamMembers {
        id
        email
        name
        role: normalizedRole
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyTeamArgs = MakeOptional<UseQueryArgs<FamilyTeamQueryVariables, FamilyTeamQuery>, 'query'>;

export function useFamilyTeamQuery(options: FamilyTeamArgs = {}) {
  return useQuery<FamilyTeamQueryVariables, FamilyTeamQuery>({ query: FamilyTeamDocument, ...options });
}

export type FamilyTeamOptions = ExecQueryOptions<FamilyTeamQueryVariables>;

export function familyTeam(options: FamilyTeamOptions) {
  return executeQuery<FamilyTeamQuery, FamilyTeamQueryVariables>(FamilyTeamDocument, options);
}

export type FamilyTeamSelections = FamilyTeamQuery['family'];
