import * as React from 'react'

import { useLifecycle } from './useLifecycle';

export function useResizeObserver(elementOrRef:HTMLElement | React.RefObject<any>, onResize:() => void, options?:ResizeObserverOptions) {
  const observer = React.useRef<ResizeObserver>();
  const resizeHandler = React.useRef(onResize);
  let element = getElement();

  React.useMemo(() => {
    disconnect();
    connect();
  }, [elementOrRef]);

  useLifecycle({onMount, onUnmount});

  function getElement() {
    let element = elementOrRef && (elementOrRef as HTMLElement).nodeType ? elementOrRef as HTMLElement : (elementOrRef as React.RefObject<any>)?.current as HTMLElement;
    // in case we get a react element, try to use common pattern that exposes the dom element with element property
    element = (element as any)?.element as HTMLElement || element;

    return element?.nodeName === undefined ? undefined : element;
  }

  function onMount() {
    // if the ref didn't have an element when first called
    // try again to get the element from the ref
    if (element || !elementOrRef) {
      return;
    }

    element = getElement();
    connect();
  }

  function onUnmount() {
    disconnect();
  }

  function onResizeWrapper() {
    if (!elementOrRef || (elementOrRef as HTMLElement).nodeType == undefined && !(elementOrRef as React.RefObject<any>).current) {
      return;
    }

    onResize();
  }

  function connect() {
    if (element) {
      observer.current = new ResizeObserver(onResizeWrapper);
      observer.current.observe(element, options);

      resizeHandler.current = onResizeWrapper
      window.addEventListener('resize', resizeHandler.current);
    }
  }

  function disconnect() {
    if (observer.current) {
      observer.current.disconnect();
      observer.current = null;
      window.removeEventListener('resize', resizeHandler.current);
    }
  }
}