import * as React from 'react';

import { Form } from 'app2/components';
import { CourseDaysPicker } from 'app2/views';

import { Demos, Demo } from './Demo';

export function CourseDaysPickerDemos() {
  return <Demos name='CourseDaysPicker'>
    <Demo name='Basic'>
      <Form>
        <CourseDaysPicker maxWidth='250px' />
      </Form>
    </Demo>
  </Demos>
}

