import * as React from 'react';

import { Tabs } from 'app2/components';
import { FamilyTeamPanel } from 'app2/views/shared';

import { ParentPage } from 'app2/views/parent/shared';

import { Students } from './Students';
import { CcContacts } from './CcContacts';

export function Family() {
  function render() {
    return <ParentPage title='Family'>
      <Tabs baseUrl='/family/:family/family/:tab' urlParameter='tab' tabs={[{
        label: 'Students',
        name: 'students',
        content: <Students />,
      }, {
        label: 'Contacts',
        name: 'contacts',
        content: <>
          <FamilyTeamPanel type='edit' />
          <CcContacts newUi={true} />
        </>
      }]} />
    </ParentPage>
  }

  return render();
}
