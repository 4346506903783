import { getSymbol } from './util/getSymbol';

const observableSymbol: any = getSymbol('observable');
const observableTargeSymbol: any = getSymbol('observableTarget');

export function isObservable(o: any) {
  return !!o && typeof o == 'object' && o[observableSymbol];
}

export function getObservableTarget(observable: any) {
  return observable[observableTargeSymbol];
}
