import * as React from 'react';

import { Link, Icon, Box } from 'app2/components';

interface Props {
  prevPage: string;
  nextPage: string;
}

export function CardNavigation(props: Props) {
  return (
    <>
      <Link to={`${props.prevPage}`}>
        <Icon name="ChevronLeft" />
      </Link>
      <Box mr='$40' />
      <Link to={`${props.nextPage}`}>
        <Icon name="ChevronRight" />
      </Link>
    </>
  );
}

