import * as React from 'react'
import { useParams, useHistory } from 'react-router';

import { HBox, Icon, Link, Text, VBox } from 'app2/components';

import { CompanyPage } from '../shared'
import { CompanyRouteParams } from '../companyRoutes';

import { useQuickStartGuideQuery, hideQuickStartGuide } from './generated';

export function QuickStartGuide() {
  const { company } = useParams<CompanyRouteParams>();
  const history = useHistory();
  const [results] = useQuickStartGuideQuery({ variables: { company } });
  const quickStart = results?.data?.company?.quickStartGuide;

  function render() {
    return <CompanyPage title='Quick start guide'>
      <VBox gap='$60' maxWidth='850px'>
        <VBox gap='$30' position='relative'>
          {renderSection('Complete your profile', 'Verify your details and make a great first impression', '/provider/general-info', quickStart?.profileComplete)}
          {renderSection('Describe your offerings', 'Create activity templates to highlight your services', '/provider/templates', quickStart?.courseFrameworksComplete)}
          {renderSection('Preview your provider page', 'Check out how you look to schools and families', `/provider/${company}/about`)}
          {renderSection('Add instructors', 'If it’s more than just you', '/provider/instructors', quickStart?.intructorsComplete)}
          {renderSection('Connect Stripe account', 'Get set up to get paid', '/provider/setup/payment', quickStart?.stripeComplete)}
          {renderSection('Schedule activities', 'Some schools will send you an activity request first - these will come in via email, so hang tight until they come in!', '/provider/activities', quickStart?.coursesComplete)}
          {renderSection('Discover new schools', 'Find local schools to offer your activities and expand your reach', `/directory/school/${results?.data?.company?.zipcode || ''}`)}

          <HBox position='absolute' bg='primary' width='2px' left='14px' top='5px' bottom='60px' zIndex={-1} />
        </VBox>
        <VBox hAlign='right'>
          <Text text='subtitle2' bold={false}>I'm done with the quick start guide.  <Link text='subtitle2' underline={false} onClick={hide}>Hide this page.</Link></Text>
        </VBox>
      </VBox>
    </CompanyPage>
  }

  function renderSection(title:string, description:string, link?:string, complete?:boolean) {
    const icon = complete ? 'CheckCircle' : 'Circle';

    return <VBox>
      <HBox vAlign='center' gap='$16'><Icon name={icon} size='30' bg='white' /><Link text='subtitle1' underline={false} to={link}>{title}</Link></HBox>
      <HBox vAlign='center' gap='$16'><Icon name={icon} size='30' opacity={0} /><Text text='subtitle2' bold={false}>{description}</Text></HBox>
    </VBox>
  }

  async function hide() {
    const [success] = await hideQuickStartGuide({variables: {company}});

    if (success) {
      history.replace('/provider/schedule');
    }

    return success;
  }

  return render();
}