import * as React from 'react'

import { ActionButton, DataTable, Modal } from 'app2/components';

import { GroupActionModal } from './GroupActionModal';

interface Props<T> {
  table:DataTable<T>;
  season:string;
}

export function GroupActionButton<T>(props:Props<T>) {
  return <ActionButton icon="Users" autoLoader={false} tooltip="Assign enrollments to a group" 
    onClick={() => Modal.show(<GroupActionModal enrollments={props.table.selections.selectedItems} season={props.season} />)}>Group</ActionButton>
}
