import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyStudentsQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type FamilyStudentsQuery = {
  family?:
    | {
        students?:
          | Array<{
              id: string;
              firstName: string;
              lastName: string;
              name: string;
              nickname?: string | null | undefined;
              grade: string;
              dob?: string | null | undefined;
              formattedMedicalAndNotes: string;
              classroom?: { siteId: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FamilyStudentsDocument = gql`
  query FamilyStudents($family: ID!) @urql(additionalTypenames: ["Student"]) {
    family(family: $family) {
      students {
        id
        firstName
        lastName
        name
        nickname
        grade
        dob
        formattedMedicalAndNotes
        classroom {
          siteId
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyStudentsArgs = MakeOptional<UseQueryArgs<FamilyStudentsQueryVariables, FamilyStudentsQuery>, 'query'>;

export function useFamilyStudentsQuery(options: FamilyStudentsArgs = {}) {
  return useQuery<FamilyStudentsQueryVariables, FamilyStudentsQuery>({ query: FamilyStudentsDocument, ...options });
}

export type FamilyStudentsOptions = ExecQueryOptions<FamilyStudentsQueryVariables>;

export function familyStudents(options: FamilyStudentsOptions) {
  return executeQuery<FamilyStudentsQuery, FamilyStudentsQueryVariables>(FamilyStudentsDocument, options);
}

export type FamilyStudentsSelections = FamilyStudentsQuery['family'];

(FamilyStudentsDocument as any).additionalTypenames = ['Student'];
