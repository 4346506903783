import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertUserCcContactsMutationVariables = Types.Exact<{
  contacts: Array<Types.CcContactInput> | Types.CcContactInput;
}>;

export type UpsertUserCcContactsMutation = { parentUpsertCcContacts?: { user?: { ccContacts?: Array<{ id: string; email?: string | null | undefined }> | null | undefined } | null | undefined } | null | undefined };

export const UpsertUserCcContactsDocument = gql`
  mutation UpsertUserCcContacts($contacts: [CcContactInput!]!) {
    parentUpsertCcContacts(contacts: $contacts) {
      user {
        ccContacts {
          id
          email
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpsertUserCcContactsOptions = ExecMutationOptions<UpsertUserCcContactsMutationVariables>;

export function upsertUserCcContacts(options: UpsertUserCcContactsOptions) {
  return executeMutation<UpsertUserCcContactsMutation, UpsertUserCcContactsMutationVariables>(UpsertUserCcContactsDocument, options);
}
