import * as React from 'react';

import { Checkbox, ButtonStrip, InputField, NumberField, Panel, Part, Section, TextArea, useForm, useLifecycle, RadioGroup, VBox } from 'app2/components'
import { Beta } from 'app2/views/shared-public';

import { useCurrentSite } from '../shared';

export function Embed() {
  const { site } = useCurrentSite();
  const exampleDiv = React.useRef<HTMLDivElement>();
  const scriptUrl = React.useRef('');
  const initialValues = {page:'/sites/' + site?.slug || '', back: true, support: true, resize:true, insets:{left:0, top:0, right:0, bottom:0}, style: '', class: '', type: 'inline', gtm: '', insert: '', position: '', show: false, wix: false};
  const form = useForm(initialValues, [site]);

  useLifecycle({onMount:renderExample, onUpdate: renderExample});
  
  function render() {
    // the overflow hidden is so that the embed doesn't grab the panel as the scroll parent
    // for when dealing with moving the shopping cart icon around so its visible

    return <Beta position='top'>
      <Panel title="Embed" type='edit-no-save-button' form={form} onNavigation='nothing' css={{'>*':{overflow:'hidden'}}} autoFocus={false}>
        <Section label='Code' description="Embed the following code on your website to embed your school's registration page on your site">
          <TextArea readOnly value={renderScriptTag()} autoSize />
        </Section>
        <Section label='Options' layout='vbox'>
          <Part label='Back button' name='back' component={Checkbox}>In embed mode a back button is inserted at the top left each page automatically</Part>
          <Part label='Support widget' name='support' component={Checkbox}>Toggle the help widget that appears in the bottom right of the page</Part>
          <Part label='Autosize' name='resize' component={Checkbox}>Automatically resize the embed so it scrolls with the main page</Part>
          <Part label='Type' name='type' component={RadioGroup} options={[{label:'Inline', value:'inline'}, {label:'Panel', value:'panel'}]} />
          <Part label='Google tag manager id' name='gtm' component={InputField}></Part>
          <Part label='Insert location' name='insert' component={InputField} description='This is optional - if specified the Homeroom widget will be placed after the html element you specify by id'></Part>
          <Part label='Insert position' name='position' component={InputField} description='See https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentElement'></Part>
          <Part label='Wix' name='wix' component={Checkbox}>Turn this on for Wix</Part>
        </Section>
        <Section label='Insets' description='Ability to adjust where the shopping cart and support widget show on the page to account for overhanging elements like headers'>
          <Part label='Left' name='insets.left' component={NumberField} />
          <Part label='Top' name='insets.top' component={NumberField} />
          <Part label='Right' name='insets.right' component={NumberField} />
          <Part label='Bottom' name='insets.bottom' component={NumberField} />
        </Section>
        <Section label='Page'>
          <div style={{flex:'unset'}}><div style={{flex:'unset'}}><Part component={<ButtonStrip onChange={event => form.setValue('page', event.target.selected as any)} options={getPageOptions()} />} /></div></div>
          <Part name='page' component={InputField} onChange={onChangePage} />
        </Section>
        <Section continued component={<Checkbox checked={hasShowSeasonHeader()} onClick={onClickShowSeasonHeader} label='Show season header' />} />
        <Section label={<Part label='Show embed test' name='show' component={Checkbox} />} />
      </Panel>

      {form.values.show &&
        <VBox border='solid 1px' borderRadius='standard' gap='$20' m='$10' pb='$30'>
          <VBox width='100%' bg='brand' color='white' text='heading2' height='90px' p='$20' vAlign='center'>
            Fake test website
          </VBox>
          <div ref={exampleDiv} />
        </VBox>}
    </Beta>
  }

  function onClickShowSeasonHeader() {
    const page = form.getValue([], 'page')
    const url = new URL(window.location.origin + page);
    const params = url.searchParams;

    if (params.has('season-header')) {
      params.delete('season-header');
    }
    else {
      params.set('season-header', undefined);
    }

    const newPage = url.toString().substring(window.location.origin.length).replace('=undefined', '').replace('=&', '&').replace(/=$/, '');
    form.setValue([], 'page', newPage, {bypassOnChange:true})
  }

  function hasShowSeasonHeader() {
    const page = form.getValue([], 'page')
    const url = new URL(window.location.origin + page);
    const params = url.searchParams;

    return params.has('season-header');
  }

  function onChangePage(url:string) {
    const [path, search] = url.split('?')
    const parts = path.split('/').map(p => encodeURIComponent(decodeURIComponent(p).split(' ').map(w => w.toLowerCase()).join('-')));
    const corrected = parts.join('/') + (search ? '?' + search : '');

    if (url == corrected) {
      return
    }

    form.setValue('page', corrected);
  }

  function renderExample() {
    if (!site?.slug || !form.values.show) {
      removeSample();
      return;
    }

    const newScriptUrl = renderScriptUrl();

    if (newScriptUrl == scriptUrl.current) {
      return;
    }

    removeSample();

    scriptUrl.current = newScriptUrl;

    const script = document.createElement('script');
    script.id = 'homeroom';
    script.src = scriptUrl.current;

    exampleDiv.current.appendChild(script);
  }

  function removeSample() {
    while (exampleDiv.current?.firstChild) {
      exampleDiv.current.removeChild(exampleDiv.current.firstChild);
    }

    scriptUrl.current = '';
  }

  function renderScriptTag() {
    return `<script type="text/javascript" id='homeroom' src="${renderScriptUrl()}"></script>`
  }

  function renderScriptUrl() {
    let src = location.origin + '/homeroom-embed.js';

    src = addParam(src, 'page');
    src = addParam(src, 'style');
    src = addParam(src, 'class');
    src = addParam(src, 'type');
    src = addParam(src, 'resize');
    src = addParam(src, 'insets');
    src = addParam(src, 'back');
    src = addParam(src, 'support');
    src = addParam(src, 'gtm');
    src = addParam(src, 'insert');
    src = addParam(src, 'position');
    src = addParam(src, 'wix');

    return src;
  }

  function addParam(src:string, name:keyof typeof initialValues) {
    const value = form.values[name];

    if (value === null || value === undefined || value === '') {
      return src;
    }

    src += src.indexOf('?') != -1 ? '&' : '?';
    src += name + '=';
    src += encodeURIComponent(
        typeof value == 'string' 
          ? value
          : typeof value === 'object' 
            ? Object.entries(value).map(([k, v]) => `${k}=${v}`).join(',')
            : JSON.stringify(value));

    return src;
  }

  function getPageOptions() {
    return [
      {label: 'Registration', value: '/sites/' + site?.slug},
      {label: 'Private registration', value:'/sites/private/' + site?.slug},
      {label: 'Games', value: '/sites/' + site?.slug + '/games'},
    ]
  }

  return render();
}
