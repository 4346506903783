import * as React from 'react'

import { changePassword, PasswordArgs } from 'app2/api';
import { Panel, Button, Section, InputField, FormModel, validatePassword, validatePasswordsMatch } from 'app2/components';
import { handleErrors } from 'app2/views/shared';

export function PasswordForm() {
  function render() {
    return <Panel icon='Key' title='Password' hAlign='left' type='toggle' editToggle={<Button>Change password</Button>} onOk={onOk}>
      <Section mode='edit' label='New password' name='password' required component={InputField} type='password' validators={validatePassword} autoComplete="new-password" />
      <Section mode='edit' label='Confirm new password' name='password_confirmation' required type='password' component={InputField}  validators={validatePasswordsMatch} autoComplete="new-password" />
    </Panel>
  }

  async function onOk(form:FormModel<PasswordArgs>) {
    const response = await changePassword(form.values as PasswordArgs);

    if (response.success) {
      return true;
    }

    handleErrors(form, response.errors);

    return false;
  }

  return render();
}

