import * as React from 'react'

import { Stripe } from './Stripe'

export function PaymentTools() {
  function render() {
    return <Stripe />
  }

  return render();
}