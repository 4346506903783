import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveCourseFromCartMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type RemoveCourseFromCartMutation = { cartRemoveEnrollment?: { success?: boolean | null | undefined } | null | undefined };

export const RemoveCourseFromCartDocument = gql`
  mutation RemoveCourseFromCart($id: ID!) @urql(additionalTypenames: ["User"]) {
    cartRemoveEnrollment(id: $id) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RemoveCourseFromCartOptions = ExecMutationOptions<RemoveCourseFromCartMutationVariables>;

export function removeCourseFromCart(options: RemoveCourseFromCartOptions) {
  return executeMutation<RemoveCourseFromCartMutation, RemoveCourseFromCartMutationVariables>(RemoveCourseFromCartDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
