import * as React from 'react';
import { debounce } from 'lodash-es';

import { PaymentService } from 'app2/api';
import { Button, Checkbox, HBox, NumberField, Section, Field, Icon, useFormInfo, VBox, formatCurrency } from 'app2/components';
import { errorPathTransform } from 'app2/views';

import { CheckoutModel } from '../CheckoutModel';

import { parentUpsertDonation } from './generated';

export function Donation() {
  const presets = [5, 10, 25];
  const form = useFormInfo<CheckoutModel>().form;
  const cart = form.values.cart;
  const disabled = !!getDonationError();

  function render() {
    if (cart?.sites?.some(s => !s.donationConfig.enabled)) {
      return null;
    }

    return (
      <Section label={renderDonationLabel()} disabled={disabled} name="cart.donation">
        <VBox>
          <HBox gap="$8" mb="$20">
            {presets.map(p => (
              <Button key={p} kind="secondary" flex={1} disabled={disabled} onClick={() => handleAmountChange(p)}>{`$${p}`}</Button>
            ))}
            <Field name="initialAmount" disabled={disabled} component={NumberField} flex={1} onChange={updateDonation} min={0} max={10000} />
          </HBox>
          <Section
            name="coverTxnFees"
            label={`Make my gift go further. Please increase my gift${cart?.donation?.txnFee ? ` by ${formatCurrency(cart?.donation?.txnFee)} ` : ' '}to cover transaction fees.`}
            disabled={disabled || !cart.donation?.initialAmount}
            component={Checkbox}
            onChange={updateDonation}
          />
        </VBox>
      </Section>
    );
  }

  function renderDonationLabel() {
    const error = getDonationError();
    return <>Add donation {error && <Icon name="Info" size="small" tooltip={error} />}</>;
  }

  function getDonationError() {
    let error;
    if (cart?.sites?.length > 1) {
      error = 'Donations cannot be added while activities from multiple schools are in your cart';
    }
    if (form.values.type === PaymentService.Affirm) {
      error = 'Donations cannot be added when paying with Affirm';
    }
    return error;
  }

  function handleAmountChange(amount: number) {
    form.setValue('cart.donation.initialAmount' as keyof CheckoutModel, amount as any);
  }

  const updateDonation = debounce(async () => {
    const cart = form.values.cart;
    const donation = {
      coverTxnFees: cart.donation.coverTxnFees,
      initialAmount: cart.donation.initialAmount || 0
    };

    const [success] = await parentUpsertDonation({ variables: { donation }, error: { handler: form, transform: errorPathTransform('donation', 'cart.donation') } });
    return success;
  }, 1000)

  return render();
}
