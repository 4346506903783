import * as React from 'react';
import moment from 'moment-timezone';
import { round, isFinite } from 'lodash-es';

import { DateInput } from '../date-utils';
import { Link } from '../Link';

import { FieldProps } from "./Field";

export const formatCurrencyHelper = (amount:any, currency = 'USD') => {
  const formatOptions = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }
  if (!Number.isInteger(parseFloat(amount))) {
    formatOptions.minimumFractionDigits = 2
    formatOptions.maximumFractionDigits = 2
  }
  return new Intl.NumberFormat('en-US', formatOptions).format(amount)
}


export function formatCurrency(amount: number | string) {
  if (typeof amount === 'string') {
    return amount;
  }

  if (!isFinite(amount)) {
    return '';
  }

  return formatCurrencyHelper(amount);
}

export function formatPercent(amount: number | string) {
  if (typeof amount === 'string') {
    return amount;
  }

  if (isNaN(amount) || amount === null || amount === undefined) {
    return '';
  }

  if (!isFinite(amount)) {
    return String(amount);
  }

  return round(typeof amount === 'string' ? parseFloat(amount) : amount, 2) + '%';
}

// there's no way to do decimal alignment in HTML/CSS
// this is a hack that returns hidden 0 padded html
// that causes all numbers to be the same width but
// not look that way
interface DecimalAlignOptions {
  maxIntDigits?:number;
  currency?:boolean;
}

export function createDecimalAlignedNumber(options:DecimalAlignOptions) {
  return function(amount: number | string) {
    return formatDecimalAlignedHelper(amount, options);
  }
}

export const formatDecimalAligned = createDecimalAlignedNumber({maxIntDigits:6, currency:true});

export function formatDecimalAlignedHelper(amount: number | string, options:DecimalAlignOptions) {
  if (!isFinite(amount)) {
    const s = typeof amount == 'string' ? amount : '';
    // 1 for the sign, 1 for each digit, count of commans, 1 more since letters are on average thinner than numbers
    const maxIntChars = 1 + options.maxIntDigits + ((options.maxIntDigits / 3) - 1) + 1;
    const zerosAndCommas = Math.max(maxIntChars - s.length, 0);
    return <>
      <span style={{whiteSpace:'nowrap'}}><span style={{opacity: 0}}>{Array(zerosAndCommas + 1).join('0')}</span>{amount}</span>
    </>
  }

  if (typeof amount === 'string') {
    amount = Number(amount);
  }

  const minus = amount < 0;
  const s = Number(Math.abs(amount)).toFixed(2);
  const decimal = s.indexOf('.');
  const left = s.substring(0, decimal);
  const right = s.substring(decimal + 1);
  const padded = left.padStart(options.maxIntDigits, '0');
  const withCommas = padded.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let firstNonZero = withCommas.search(/[1-9]/);

  if (firstNonZero == -1) {
    firstNonZero = withCommas.length - 1;
  }

  return <>
    <span style={{whiteSpace:'nowrap'}}><span style={{opacity: 0}}>{withCommas.substring(0, firstNonZero)}{minus ? '' : '+'}</span>{minus ? '-' : ''}${withCommas.substring(firstNonZero)}.{right}</span>
  </>
}

export function arrayToString(array?: string[]) {
  return Array.isArray(array) ? array.join(', ') : array;
}

function createDateFormatter(format:string) {
  function formatter(d:DateInput) {
    return d ? moment(d).format(format) : '';
  }
  return applyDateFormatter(formatter);
}

export function createDateFormatterTz(format:string, timezone:string, applyFormat?:boolean) {
  function formatter(d:DateInput) {
    if (!d) {
      return '';
    }
  
    const m = moment.tz(d, timezone);
  
    if (!m.isValid()) {
      return '';
    }

    return applyFormat ? m.format(format) : m;
  }
  return applyDateFormatter(formatter);
}

function createDateTimeFormatter(dateFormat:string, timeFormat:string) {
  function formatter(d:DateInput) {
    return d ? moment(d).format(`${dateFormat} [at] ${timeFormat}`) : '';
  }
  return applyDateFormatter(formatter);
}

export function formatShortDate(d: DateInput | DateInput[], timezone?: string) {
  return formatDate(d, 'short', timezone);
}

export function formatAbbreviatedDate(d: DateInput | DateInput[], timezone?: string) {
  return formatDate(d, 'abbreviated', timezone);
}

export function formatDate(d: DateInput | DateInput[], format?: string, timezone?: string) {
  if (!format || format == 'short') {
    format = SHORT_DATE_FORMAT;
  } else if (format == 'long') {
    format = LONG_DATE_FORMAT;
  } else if (format == 'abbreviated') {
    format = ABBREVIATED_DATE_FORMAT;
  }

  return createDateFormatterTz(format, timezone, true)(d);
}

export function formatFieldDate(val:DateInput, fieldProp:FieldProps<any, any, any>) {
  // this is a hack looking at multiple places for the date format.  this really
  // needs be passed a flattened version and we need all fields to have a date
  // format, edit & display, regardless of whether the component needs it
  // because this helper needs it
  return formatDate(val, fieldProp.dateFormat || fieldProp.display?.dateFormat, fieldProp.timezone)
}

// in order to avoid a more complicated parser, we use semicolon
// as a separator instead of a comma, because comma's appear in long dates.

function applyDateFormatter(formatter: (d: DateInput) => string | moment.Moment) {
  return function(date: DateInput | DateInput[]) {
    return Array.isArray(date) ? date.map(formatter).join('; ') : formatter(date);
  };
}

export const ABBREVIATED_DATE_FORMAT = 'M/D';

export const LONG_DATE_FORMAT = 'MMM D, YYYY';
export const longDateFormatter = createDateFormatter(LONG_DATE_FORMAT);
export const longDateFormatterTz = (timeZone: string) => createDateFormatterTz(LONG_DATE_FORMAT, timeZone, true);

export const SHORT_DATE_FORMAT = 'M/D/YY';
export const shortDateFormatter = createDateFormatter(SHORT_DATE_FORMAT);

export const TIME_FORMAT = 'h:mm A'
export const longDateTimeFormatter = createDateTimeFormatter(LONG_DATE_FORMAT, TIME_FORMAT)
export const longDateTimeFormatterTz = (timeZone: string) => createDateFormatterTz(`${LONG_DATE_FORMAT} [at] ${TIME_FORMAT}`, timeZone, true);

export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} ${TIME_FORMAT}`;

export function timeFromDate(date:DateInput) {
  if (!date) {
    return '';
  }

  const m = moment(date);

  if (!m.isValid()) {
    return '';
  }

  return m.format(TIME_FORMAT);
}

export function formatPhone(phone:string) {
  if (!phone) {
    return '';
  }

  const digits = phone.replace(/\(|\)|\-|\s/g, '');

  return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`;
}

export function formatPhoneLink(phone:string) {
  return phone
    ? <Link phone={phone} className='hr-value' text='body' />
    : ''
}

export function noopFormatter(value:any) {
  return value;
}

export function formatEmail(value:string) {
  return <Link to={`mailto:${value}`} className='hr-value' text='body'>{value}</Link>;
}
