import * as React from 'react'
import { useForceUpdate, ForceUpdate } from '../utils';

export interface ShieldProvider {
  // shows/hide a mouse shield that blocks all mouse interactions
  // the key is needed because many components can show a shield/loader
  // and so they key is used to track the cumulation of all requests
  // if you use the useShield hook, you don't need to worry about the key
  // because a unique key is created automatically. if you call this directly
  // from a class component, you can pass in the class' this pointer as a key.
  shield(on:boolean, key:any):void;
  showingShield:boolean;

  // shows/hide a loader, showing a loader automatically places a shield as well
  loader(on:boolean, key:any):void;
  showingLoader:boolean;

  showingEither:boolean;
  modal?:boolean;
}

export class ShieldProviderImpl implements ShieldProvider {
  animationRequest:number;
  modal:boolean;
  forceUpdate:ForceUpdate;
  shieldUsers:Set<any>;
  loaderUsers:Set<any>;

  constructor(modal:boolean, forceUpdate:ForceUpdate, shieldUsers:Set<any> = null, loaderUsers:Set<any> = null) {
    this.modal = modal;
    this.forceUpdate = forceUpdate;
    this.shieldUsers = shieldUsers || new Set();
    this.loaderUsers = loaderUsers || new Set();
  }

  get showingEither() {
    return this.showingShield || this.showingLoader;
  }

  shield(on:boolean, key:any):void {
    if (this.shieldUsers.has(key) == on) {
      return;
    }

    const showingBefore = this.showingEither;

    if (on) {
      this.shieldUsers.add(key);
    }
    else {
      this.shieldUsers.delete(key);
    }

    if (this.showingEither == showingBefore) {
      return;
    }

    this.update();
  }

  get showingShield() {
    return this.shieldUsers.size > 0 || this.showingLoader;
  }

  loader(on:boolean, key:any):void {
    if (this.shieldUsers.has(key) == on && this.loaderUsers.has(key) == on) {
      return;
    }

    const showingBefore = this.showingEither;

    if (on) {
      this.shieldUsers.add(key);
    }
    else {
      this.shieldUsers.delete(key);
    }

    if (on) {
      this.loaderUsers.add(key);
    }
    else {
      this.loaderUsers.delete(key);
    }

    if (this.showingEither == showingBefore) {
      return;
    }
    
    this.update();
  }

  get showingLoader() {
    return this.loaderUsers.size > 0;
  }

  private update() {
    if (this.animationRequest) {
      return;
    }

    this.animationRequest = requestAnimationFrame(() => {
      this.animationRequest = undefined;

      this.forceUpdate();
    });
  }
}

export function useShieldProvider(modal:boolean) {
  const forceUpdate = useForceUpdate();
  const provider = React.useMemo(() => new ShieldProviderImpl(modal, forceUpdate), []);

  return provider;
}
