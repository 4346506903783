import * as React from 'react'

import { CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement, ReactStripeElements } from 'react-stripe-elements'

import { hasAppMode, inputPaddingX, inputPaddingY, Box, BoxProps, FormContent, InputField, Part, Section, theme, palette, useFormInfo } from 'app2/components'

import { CardModel } from './CardModel';
import { CardIcon } from './CardIcon';
import { Card } from './Card';
import { parentRemoveCreditCard } from './generated';

// this assumes you've used StripeElements as a parent to CardForm somewhere
// that loads stripe, and calls injectStripe.  if you get errors like this:
//
//    Uncaught TypeError: ReactDebugCurrentFrame$1.setExtraStackFrame is not a function
//
// its because you didn't wrap it in StripeElements (the reason this doesn't already
// wrap itself is because the parent code that does whatever with this data needs
// to be wrapped as well.

interface Props {
  required: boolean;
  onRemove?:() => void;
}

export function CardForm(props:Props) {
  const info = useFormInfo<CardModel>();
  const form = info.form;

  function render() {
    return form.values.existingCard
      ? renderCreditCard()
      : renderCardForm()
  }

  function renderCreditCard() {
    return <Card card={form.values.existingCard} onRemove={onRemoveCard} />
  }

  async function onRemoveCard() {
    const [success] = await parentRemoveCreditCard({ variables: { token: form.values.existingCard?.id }});
    if (!success) {
      return;
    }
    
    props.onRemove?.();
  }

  function renderCardForm() {
    if (!info.editing) {
      return <></>;
    }

    const required = hasAppMode('test') ? false : props.required;

    return <FormContent maxWidth='600px'>
      <Section>
        <Part name='newCard.name' required={props.required} label='Cardholder name' component={InputField} />
      </Section>
      <Section>
        <Part name='newCard.cardNumber' required={required} data-field='Card number' label={<><CardIcon />Card number</>} component={
          <StripeElementBorder><CardNumberElement onChange={onChange} style={stripeStyles} /></StripeElementBorder>} />
      </Section>
      <Section>
        <Part name='newCard.cardExpiry' required={required} label='Expiration date' component={
          <StripeElementBorder><CardExpiryElement onChange={onChange} style={stripeStyles} /></StripeElementBorder>} />
        <Part name='newCard.cardCvc' required={required} label='CVC' component={
          <StripeElementBorder><CardCVCElement onChange={onChange} style={stripeStyles} /></StripeElementBorder>} />
        <Part name='newCard.postalCode' required={required} label='Zipcode' component={
          <StripeElementBorder><PostalCodeElement onChange={onChange} style={stripeStyles} /></StripeElementBorder>} />
      </Section>
    </FormContent>
  }

  function onChange(event: ReactStripeElements.ElementChangeResponse) {
    form.setValue(['newCard'], event.elementType as any, event.complete || undefined);
  }

  return render();
}

CardForm.defaultProps = {
  required: true
};

function StripeElementBorder(props:BoxProps) {
  return <Box borderRadius='standard' px={inputPaddingX} py={inputPaddingY}  bg='white' border='solid' borderColor='border' borderWidth='standard' {...props} />
}

export const stripeStyles = {
  base: {
    display: 'block',
    fontFamily: `${theme.fonts.input}`,
    fontSize: `${theme.fontSizes.input}`,
    lineHeight: `${theme.lineHeights.input}`,
    color: `${theme.colors.input}`,
    letterSpacing: '0.025em',
    '::placeholder': {
      // the color isn't coming out correctly, compared to placeholders in our
      // app. my best guess is that because stripe loads in an iframe that our
      // muli font is not getting loaded, so we choose a lighter hard coded color
      //color: `${theme.colors.placeholder}`,
      color: '#848484'
    }
  },
  invalid: { color: `${theme.colors.error}` }
}
