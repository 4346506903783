import * as React from 'react';

import { Rates } from 'app2/api';
import { Field, RepeatingSection, RepeatingSectionProps } from 'app2/components';

import { useCourseFields } from '../course';

export type ConfigurableSeasonFields = Pick<ReturnType<typeof useCourseFields>['fields'], 'rates.seasons' | 'rates.seasons.days' | 'rates.seasons.rate' | 'rates.seasons.homeroomFeeRoundingIncrementCents'>;

interface Props extends Partial<RepeatingSectionProps<Rates>> {
  seasonsFields: ConfigurableSeasonFields;
}

export function ConfigurableSeasonRates(props: Props) {
  const { seasonsFields, ...remaining } = props;

  function render() {
    return (
      <RepeatingSection
        assignIds={false}
        name={seasonsFields['rates.seasons'].name}
        labelType="formlabel"
        numbered={false}
        alwaysOne
        add="Add option"
        fields={[<Field {...seasonsFields['rates.seasons.days']} />, <Field {...seasonsFields['rates.seasons.rate']} />, <Field {...seasonsFields['rates.seasons.homeroomFeeRoundingIncrementCents']} />, 'remove']}
        {...remaining}
      />
    );
  }

  return render();
}
