import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentRemoveFromWaitlistMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type ParentRemoveFromWaitlistMutation = { parentRemoveEnrollmentsFromWaitlist?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ParentRemoveFromWaitlistDocument = gql`
  mutation ParentRemoveFromWaitlist($ids: [ID!]!) {
    parentRemoveEnrollmentsFromWaitlist(ids: $ids) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentRemoveFromWaitlistOptions = ExecMutationOptions<ParentRemoveFromWaitlistMutationVariables>;

export function parentRemoveFromWaitlist(options: ParentRemoveFromWaitlistOptions) {
  return executeMutation<ParentRemoveFromWaitlistMutation, ParentRemoveFromWaitlistMutationVariables>(ParentRemoveFromWaitlistDocument, options);
}
