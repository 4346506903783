import { getFocusables } from './getFocusables';
import { isFocusable } from './isFocusable';

export function getFirstFocusableOfParent(parentOrNode:Node, includeParent:boolean = false, prioritizeInputs:boolean = false):HTMLElement {
  if (!parentOrNode) {
    return null;
  }

  const parent = parentOrNode.nodeType != Node.ELEMENT_NODE ? parentOrNode.parentElement : parentOrNode as HTMLElement;

  if (includeParent && isFocusable(parent)) {
    return parent;
  }

  let focusables = getFocusables(parent);

  if (!focusables.length) {
    return null;
  }

  if (prioritizeInputs) {
    const inputs = focusables.filter(f => f.tagName == 'INPUT' || f.tagName == 'TEXTAREA' || f.tagName == 'SELECT');
    focusables = inputs.length ? inputs : focusables;
  }


  return focusables[0];
}
