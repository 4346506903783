import * as React from 'react';

import { EnrollmentUtils, EnrollmentWithStudentName } from 'app2/api';
import { Modal, RepeatingSection, Info, Form, Field, FormModel } from 'app2/components';
import { errorPathTransform } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';
import { reenroll } from './generated';

interface FormEnrollment {
  student: string;
}

interface FormValues {
  enrollments: FormEnrollment[];
}

type ReenrollForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: EnrollmentWithStudentName[];
}

export function ReenrollModal(props: Props) {
  const initialValues = React.useMemo(() => {
    const initialEnrollments = props.enrollments.map(e => ({ student: EnrollmentUtils.getStudentName(e) }));
    return { enrollments: initialEnrollments };
  }, props.enrollments);
  const title = 'Reenroll';
  const warning = 'Are you sure you want to reenroll these students?';

  function render() {
    return (
      <Modal title={title} ok={title}>
        <Form initialValues={initialValues} onOk={handleSubmit} onNavigation="nothing" alwaysSave>
          <RepeatingSection name="enrollments" bordered fields={[<Field label="Student" name="student" />]} />
          <Info type="warning">{warning}</Info>
        </Form>
      </Modal>
    );
  }

  async function handleSubmit(form: ReenrollForm) {
    const [success] = await reenroll({
      variables: { courseId: props.course?.id, ids: props.enrollments.map(e => e.id) },
      successMsg: 'Students reenrolled',
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments')] }
    });
    return success;
  }

  return render();
}
