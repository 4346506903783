import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveEnrollmentsMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  childEnrollments?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type RemoveEnrollmentsMutation = { enrollmentsRemove?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const RemoveEnrollmentsDocument = gql`
  mutation RemoveEnrollments($courseId: ID!, $ids: [ID!]!, $childEnrollments: Boolean) {
    enrollmentsRemove(courseId: $courseId, ids: $ids, childEnrollments: $childEnrollments) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RemoveEnrollmentsOptions = ExecMutationOptions<RemoveEnrollmentsMutationVariables>;

export function removeEnrollments(options: RemoveEnrollmentsOptions) {
  return executeMutation<RemoveEnrollmentsMutation, RemoveEnrollmentsMutationVariables>(RemoveEnrollmentsDocument, options);
}
