import * as React from 'react'
import { useHistory } from 'react-router-dom';
import { last } from 'lodash-es';

import { isImpersonatingUser, MessageEntityInput, MessageServiceKind } from 'app2/api';
import { Button, Field, HBox, HRule, VBox, Panel, Span } from 'app2/components';
import { CourseLink, BackLink, useCurrentUser } from 'app2/views/shared-public';

import { CreateMessage, CreateReply } from './create';
import { Message } from './Message';
import { MessageParticipants } from './MessageParticipants';
import { useMessageParticipants } from './useMessageParticipants';
import { useThreadId } from './useThreadId';
import { markThreadRead, useMessageThreadQuery } from './generated'

import { useScrollToTheBottom } from './useScrollToTheBottom';

interface Props {
  labels?:MessageEntityInput[];
  inline?:boolean;
  role: 'parent' | 'user' | 'organizer' | 'provider';
  onClickStudent?:(id:string) => void;
  entityMessages?:boolean;
}

export function Thread(props:Props) {
  const { id, threadScope } = useThreadId();
  const thread = getThread();
  const firstInThread = last(thread?.messages);
  const sms = firstInThread?.preferredService == MessageServiceKind.Sms;
  const kind:'sms' | 'email' = 'sms';//sms ? 'sms' : 'email';

  const newThread = id == 'compose';
  const [createMessageOrReply, setCreateMessage] = React.useState<boolean | typeof thread.messages[0]>(newThread);
  const replyToMessage = createMessageOrReply as typeof thread.messages[0];
  
  const userInfo = useCurrentUser();
  const history = useHistory();
  const {participants} = useMessageParticipants(firstInThread);

  const ref = React.useRef<Panel>();
  useScrollToTheBottom(ref.current?.element?.lastElementChild as HTMLElement, !createMessageOrReply, [thread]);

  markRead();

  function render() {
    return <Panel id='threads' initialValues={thread} className='' overflow='auto' p='$16' flex={1} height='fit-content' ref={ref}>
      {renderHeader()}
      {thread?.messages?.map(renderMessage)}
      {renderCreateMessage()}
    </Panel>
  }

  function renderHeader() {
    if (createMessageOrReply || kind == 'email' || !thread) {
      return renderBack()
    }

    return <VBox width='100%' gap='$8' position='sticky' top='-16px' bg='formBackground' zIndex={1}>
      {renderBack()}
      {firstInThread?.subject && <Span text='subtitle2' flex={1}>{firstInThread?.subject}</Span>}
      <HBox>
        <MessageParticipants participants={participants} maxLines={2} flex={1} onClickStudent={props.onClickStudent} />
        {thread?.labels?.[0]?.id != props.labels?.[0]?.id && <Field name="labels.0.name" component={CourseLink} role={props.role == 'organizer' ? 'organizer' : 'public'} maxWidth='40%' maxLines={1} none={false} />}
      </HBox>
      <HRule />
    </VBox>
  }

  function renderBack() {
    return thread && !props.inline && <BackLink>Back to messages</BackLink>;
  }

  function renderMessage(message:typeof thread.messages[0], index:number) {
    const previous = thread.messages[index - 1];

    return <Message key={message.id} kind={kind} message={message} previous={previous} thread={thread} primaryActions={renderReply(message, index)} onClickStudent={props.onClickStudent} />
  }

  function renderReply(message:typeof thread.messages[0], index:number) {
    const participantsBesidesCurrentUser = userInfo?.user && [message.from?.from, ...message.to.map(r => r.to), ...message.cc.map(r => r.to)].filter(to => isUserType(to) && to.id != userInfo.user.id).length > 0;
    const canReply = participantsBesidesCurrentUser && (kind == 'email' || index == thread?.messages?.length - 1);

    return canReply && !createMessageOrReply
      ? <Button onClick={() => onReply(message)}>Reply</Button>
      : undefined;
  }

  function renderCreateMessage() {
    if (!createMessageOrReply) {
      return;
    }

    const common = {onCancel: onFinishCreate, ok:'Send', onOk:onFinishCreate};
    return newThread
      ? <CreateMessage {...history.location.state} kind={kind} labels={props.labels || thread?.labels} onClickStudent={props.onClickStudent} {...common} />
      : <CreateReply role={props.role} kind={kind} replyTo={replyToMessage} to={[replyToMessage.from?.from, ...replyToMessage.to.map(r => r.to)]} cc={replyToMessage.cc.map(r => r.to)} labels={replyToMessage?.labels || props.labels} {...common} />
  }

  function onReply(message:typeof thread.messages[0]) {
    setCreateMessage(message);
  }

  function onFinishCreate(historyChange?:any) {
    if (historyChange === true) {
      return;
    }

    setCreateMessage(false);

    if (newThread) {
      history.goBack();
    }
  }

  function isUserType(to:any) {
    return to?.__typename == 'SharedUser';
  }

  function getThread() {
    const [result] = useMessageThreadQuery({variables:{id, entityMessages: props.entityMessages}, pause: id == 'compose'});
    const thread = result.data?.msgThread;

    return thread;
  }

  function markRead() {
    const {hasUnread, markedRead, setMarkedRead} = getHasUnread();

    if (isImpersonatingUser() || !userInfo.user) {
      return;
    }

    if (!thread || !hasUnread || markedRead || (!threadScope && thread.broadcast)) {
      return;
    }

    const lastMessage = getHighestMessageId();

    setMarkedRead(true);
    markThreadRead({variables:{threadScope, lastMessage}});
  }

  function getHasUnread() {
    const [markedRead, setMarkedRead] = React.useState(false);
    const hasUnread = React.useMemo(() => thread?.messages?.some(m => !m.read), [thread]);

    return {hasUnread, markedRead, setMarkedRead};
  }

  function getHighestMessageId() {
    return thread.messages.reduce((latest, msg) => msg.id > latest.id ? msg : latest, thread.messages[0]).id;
  }
  
  return render();
}
