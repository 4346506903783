import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdatePickupDestinationsMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  pickupDestinations: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type OrganizerUpdatePickupDestinationsMutation = { siteUpdatePickups?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdatePickupDestinationsDocument = gql`
  mutation OrganizerUpdatePickupDestinations($siteId: ID!, $pickupDestinations: [String!]!) {
    siteUpdatePickups(site: $siteId, pickups: $pickupDestinations) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdatePickupDestinationsOptions = ExecMutationOptions<OrganizerUpdatePickupDestinationsMutationVariables>;

export function organizerUpdatePickupDestinations(options: OrganizerUpdatePickupDestinationsOptions) {
  return executeMutation<OrganizerUpdatePickupDestinationsMutation, OrganizerUpdatePickupDestinationsMutationVariables>(OrganizerUpdatePickupDestinationsDocument, options);
}
