import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'

import { resetVersion } from 'app/helpers'
import { eventManager, urql } from 'app2/api'
// due to legacy dependancy issues, this has to be here
// and not after the imports below
import { routes } from '../routes'

import { AppModes, UnsavedChangesManager, MultiContextProvider } from 'app2/components'
import { EmbedConfigLoader } from 'app2/views/shared-public'

import App from '../App'

import ScrollToTop from './Scroll'
import { BrowserCompatibility } from './BrowserCompatibility'

class Root extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  constructor(props) {
    super(props);
    urql.onReset(this.onUrqlReset);
  }

  onUrqlReset = () => {
    this.forceUpdate();
  }

  componentWillMount() {
    resetVersion()
  }

  render() {
    return (
      <MultiContextProvider eventLogger={eventManager}>
        <UrqlProvider value={urql.client}>
          <BrowserCompatibility>
            <Router
              history={this.props.history}
              onUpdate={() => window.scrollTo(0, 0)}
            >
              <UnsavedChangesManager />
              <AppModes modes={['embed', 'test', 'back', 'support', 'gtm', 'resize', 'season-header', 'CID', 'device', 'gad_source', 'gclid', 'utm_adgroup', 'utm_campaign', 'utm_content', 'utm_geo', 'utm_geo_interest', 'utm_medium', 'utm_source', 'utm_term', 'fbclid']}>
                <EmbedConfigLoader />
                <ScrollToTop routes={routes}>
                  <App />
                </ScrollToTop>
              </AppModes>
            </Router>
          </BrowserCompatibility>
        </UrqlProvider>
      </MultiContextProvider>
    )
  }
}

export default hot(Root)
