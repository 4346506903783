import * as React from 'react'
import * as qs from 'query-string';
import { useLocation } from 'react-router';

import { BaseAcceptInvite } from '../shared'

import { familyInviteAccept } from './generated';

export function FamilyAcceptInvite() {
  const location = useLocation();
  const {invitor, family}  = qs.parse(location.search);
  const message = invitor ? `${invitor} has invited you to join their family` : `You've been invited to join ${family ? `${family}'s family` : 'a family'}.`
  
  return <BaseAcceptInvite title='Family member invite' message={message} mutation={familyInviteAccept} onSuccess='/family' />
}
