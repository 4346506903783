import * as React from 'react';

import { CourseKind } from 'app2/api';
import { Info, VBox } from 'app2/components';
import { NonePanel } from 'app2/views/shared-public';
import { CourseTablesForGroups } from 'app2/views/shared';
import Instructor from 'images/Instructor.svg'

import { TeacherPage } from '../shared';

import { TeacherCoursesTable } from './TeacherCoursesTable';
import { useTeacherCourseKindsQuery } from './generated';

export function TeacherCourses() {
  const [result] = useTeacherCourseKindsQuery();
  const courseKinds = result.data?.currentUser?.courseKindGroups;
  const empty = courseKinds && !courseKinds.length;

  function render() {
    return <TeacherPage title='Activities'>
        {empty ? renderEmpty() : renderCourses()}
    </TeacherPage>
  }

  function renderCourses() {
    return <VBox gap="$40">
      <Info type='success' icon={false}>To view the roster for any activity, click on the activity name.</Info>
      <CourseTablesForGroups groups={courseKinds} filter={allowKind} component={TeacherCoursesTable} />
    </VBox>
  }

  function allowKind(kind:CourseKind) {
    return kind != CourseKind.AthleticIndividualReg && kind != CourseKind.AthleticReg;
  }

  function renderEmpty() {
    return <NonePanel description="You don’t have any activities scheduled at the moment"
      image={Instructor} add={undefined} />
  }
  
  return render();
}
