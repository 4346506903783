import * as React from 'react'

export function flattenChildren(children: React.ReactNode): React.ReactNode[] {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.reduce((flatChildren: React.ReactNode[], child) => {
    if ((child as React.ReactElement<any>).type === React.Fragment) {
      return flatChildren.concat(flattenChildren((child as React.ReactElement<any>).props.children));
    }

    flatChildren.push(child);

    return flatChildren;
  }, []);
}