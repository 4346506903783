import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyTimezoneQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyTimezoneQuery = { company?: { id: string; timezone?: string | null | undefined } | null | undefined };

export const CompanyTimezoneDocument = gql`
  query CompanyTimezone($company: ID!) {
    company(company: $company) {
      id
      timezone
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyTimezoneArgs = MakeOptional<UseQueryArgs<CompanyTimezoneQueryVariables, CompanyTimezoneQuery>, 'query'>;

export function useCompanyTimezoneQuery(options: CompanyTimezoneArgs = {}) {
  return useQuery<CompanyTimezoneQueryVariables, CompanyTimezoneQuery>({ query: CompanyTimezoneDocument, ...options });
}

export type CompanyTimezoneOptions = ExecQueryOptions<CompanyTimezoneQueryVariables>;

export function companyTimezone(options: CompanyTimezoneOptions) {
  return executeQuery<CompanyTimezoneQuery, CompanyTimezoneQueryVariables>(CompanyTimezoneDocument, options);
}

export type CompanyTimezoneSelections = CompanyTimezoneQuery['company'];
