import * as React from 'react'

import { FileUpload } from 'app2/api';
import { Body, HBox, BoxProps } from 'app2/components'

import { FileLink } from './FileLink'

interface Props extends BoxProps {
  files?:FileUpload[];
  onRemove?:(pos:number) => void;
}

export function FileLinks(props:Props) {
  const {files, onRemove, ...remaining} = props;
  return <HBox flexWrap='wrap' gap='4px 4px' {...remaining}>
    {!files || !files.length
      ? <Body>None</Body>
      : files.map((file, index) => <FileLink key={index} id={file.id} name={file.name} />)}
    </HBox>;
}

FileLinks.fieldProps = {
  valueProperty: 'files',
}