import * as React from 'react';

export type EventListener<T extends Event = any> = (event:T) => void;

export function useEventListener<T extends Event = any>(event:string, cb:EventListener<T>, element:HTMLElement | Window) {
  const handler = React.useRef(cb);
  handler.current = cb;

  function onEvent(event:T) {
    handler.current?.(event);
  }

  React.useEffect(() => {
    element?.addEventListener(event, onEvent as any);

    return () => element?.removeEventListener(event, onEvent as any);
  }, [event, element]);
}
