import { Course } from 'app2/api';
import { DropdownProps, FieldInfo } from 'app2/components';

export interface PickerEntityProps {
  field?:FieldInfo<Course>;
  course?:Course;
  vendor?:string;
  company?:string;
}

export type PickerProps = Omit<DropdownProps, 'options'> & PickerEntityProps;

export function getPickerEntityId(props:PickerEntityProps) {
  const course = (props.course || props.field?.values?.[0]) as Course;
  const vendor = course?.vendor;
  const vendorId = props.vendor || course?.vendor?.id;
  const company = course?.company || vendor?.company;
  const companyId = props.company || company?.id;

  return {company, companyId, vendor, vendorId, course};
}

