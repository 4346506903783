import * as React from 'react'

import { CourseTag } from 'app2/api'
import { Dropdown, DropdownProps } from 'app2/components'

import { findQueryOption } from './findQueryOption';
import { courseTags, useCourseTagsQuery, CourseTagsSelections } from './generated'

export interface CourseTagsDropdownProps extends Omit<DropdownProps, 'options'> {
  valueType?:'id' | 'name' | 'object';
}

export function CourseTagsDropdown(props:CourseTagsDropdownProps) {
  const { valueType, ...remaining } = props;
  const options = getOptions();

  function render() {
    return <Dropdown options={options} {...remaining} />
  }

  function getOptions() {
    const [result] = useCourseTagsQuery({requestPolicy: 'cache-first', hideLoader: true});
    const tags = result.data?.courseTags;
    
    // note this explicitly uses name as a value, because thats what CourseUtil
    // uses in filtering and also its more url friendly
    return React.useMemo(() => tags?.map(t => ({value: getTagValue(t), label: t.name})).sort((a, b) => a.label.localeCompare(b.label)), [tags]);
  }

  function getTagValue(tag:CourseTagsSelections) {
    return valueType == 'object' ? tag : valueType == 'name' ? tag.name : tag.id
  }

  return render();
}

CourseTagsDropdown.defaultProps = {
  valueType: 'id'
}

export async function findCourseTag(nameOrObArrayj:string | CourseTag | string[] | CourseTag[]) {
  const item = await findQueryOption(courseTags, {}, nameOrObArrayj);

  return item ? [item] : null;
}

CourseTagsDropdown.findTag = findCourseTag;