import * as React from 'react';

import { NavigationSection, NavigationLink } from 'app2/views/shared-public';

import { getTeacherPath } from './teacherRoutes';

export const teacherLinks = <NavigationSection icon='User' name='Instructor'>
  <NavigationLink to={getTeacherPath('schedule')} label='Schedule' />
  <NavigationLink to={getTeacherPath('activities')} label='Activities'/>
</NavigationSection>
