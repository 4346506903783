import * as React from 'react'

import { MultiContextProvider } from '../utils';

import { useFormInfo } from './useFormInfo'

// adds a parent in the list of fields
// so that when a field accesses a value from a form
// it knows that the field is a child of the parent field

interface Props extends React.PropsWithChildren<{}> {
  name?:string | number;
  names?:(string | number)[];
  append?:boolean; //append to current parents
}

export function FieldParent(props:Props) {
  const formInfo = useFormInfo();
  const names = props.names ? props.names : (props.name ?? undefined) !== undefined ? [props.name] : [];
  const existingParents = props.append ? (formInfo?.parents || []) : [];
  const parents = existingParents.slice().concat(names);

  return <MultiContextProvider formInfo={{...formInfo, parents}}>{props.children}</MultiContextProvider>
}

FieldParent.defaultProps = {
  append: true
}