import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApproveCoursesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type ApproveCoursesMutation = { coursesApprove?: { courses?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const ApproveCoursesDocument = gql`
  mutation ApproveCourses($ids: [ID!]!) {
    coursesApprove(ids: $ids) {
      courses {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ApproveCoursesOptions = ExecMutationOptions<ApproveCoursesMutationVariables>;

export function approveCourses(options: ApproveCoursesOptions) {
  return executeMutation<ApproveCoursesMutation, ApproveCoursesMutationVariables>(ApproveCoursesDocument, options);
}
