import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteActivateMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type AdminSiteActivateMutation = { siteActivate?: { id: string } | null | undefined };

export const AdminSiteActivateDocument = gql`
  mutation AdminSiteActivate($site: ID!) {
    siteActivate(site: $site) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSiteActivateOptions = ExecMutationOptions<AdminSiteActivateMutationVariables>;

export function adminSiteActivate(options: AdminSiteActivateOptions) {
  return executeMutation<AdminSiteActivateMutation, AdminSiteActivateMutationVariables>(AdminSiteActivateDocument, options);
}
