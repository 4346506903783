import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteCopyStripeTokenMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  destination: Types.Scalars['ID'];
  destinationType: Types.Scalars['String'];
}>;

export type SiteCopyStripeTokenMutation = { siteCopyStripeToken?: { id: string } | { id: string } | null | undefined };

export const SiteCopyStripeTokenDocument = gql`
  mutation SiteCopyStripeToken($site: ID!, $destination: ID!, $destinationType: String!) {
    siteCopyStripeToken(site: $site, destination: $destination, destinationType: $destinationType) {
      id
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SiteCopyStripeTokenOptions = ExecMutationOptions<SiteCopyStripeTokenMutationVariables>;

export function siteCopyStripeToken(options: SiteCopyStripeTokenOptions) {
  return executeMutation<SiteCopyStripeTokenMutation, SiteCopyStripeTokenMutationVariables>(SiteCopyStripeTokenDocument, options);
}
