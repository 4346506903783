import * as React from 'react';
import { flatten } from 'lodash-es';

import { observer } from 'app/observable';

import {
  FormData,
  FieldValidators,
  setFieldValue,
  touchField,
  getFieldInfo,
  FieldInfo,
  addFieldValidator,
  removeFieldValidator,
  ValidatorResult
} from './FormData';
import { FormContext } from './Form';
import { required } from './validators';

type ParseHandler = (value: any) => any;
type FormatHandler = (value: any) => any;

interface FieldAdapter<T> {
  // automatically assigned
  field?: Field<T>;

  // ideally a form has initial values
  // but when a field is optional for a
  // variety of controls, they seem to not
  // handle well when there's no value, so
  // defaultValue is for that and expected
  // to be used more in control adapters
  // than one of field usage
  defaultValue?: keyof T;

  valueProperty?: string;
  onChangeProperty?: string;
  onBlurProperty?: string;
  errorProperty?: string;

  format?: ParseHandler;
  parse?: FormatHandler;

  props?: () => any;
  changeHandler?: (...args: any[]) => any;
  blurHandler?: () => void;
}

const standardAdapter: FieldAdapter<any> = {
  // defaultValue is '' by default because if
  // no value is assigned, React will complain about
  // going from uncontrolled to controlled as the user types in data
  defaultValue: '',
  valueProperty: 'value',
  onChangeProperty: 'onChange',
  onBlurProperty: 'onBlur',
  errorProperty: 'error',

  format: function(value: any) {
    return value;
  },

  parse: function(value: any) {
    return value;
  },

  props: function(): any {
    if (!this.field.props.name) {
      return separateProps(this.field.props).otherProps;
    }

    const { value, displayableErrors, disabled } = getFieldInfo(
      this.field.formData,
      this.field.props.name
    );
    const error = displayableErrors.length
      ? displayableErrors.join('; ')
      : undefined;

    const props: any = separateProps(this.field.props).otherProps;

    if (this.valueProperty && !props[this.valueProperty]) {
      // empty string is considered false, so we can't use a || statement to the undefined value to use
      const formattedValue = [
        this.format(value),
        this.defaultValue,
        this.field.props.children
      ].find(value => value !== undefined);

      if (formattedValue !== undefined) {
        props[this.valueProperty] = formattedValue;
      }
    }

    if (this.onChangeProperty) {
      props[this.onChangeProperty] = this.field.onChange;
    }

    if (this.onBlurProperty) {
      props[this.onBlurProperty] = this.field.onBlur;
    }

    if (this.errorProperty && !props[this.errorProperty]) {
      props[this.errorProperty] = error;
    }

    if (disabled) {
      props.disabled = disabled;
    }

    return props;
  },
  
  changeHandler: function(eventOrValue: React.SyntheticEvent<HTMLInputElement> | any, possibleSemanticUiValue: { value: any }) {
    function isEvent() {
      return eventOrValue != null && eventOrValue.nativeEvent !== undefined;
    }

    let value;

    if (eventOrValue.currentTarget && eventOrValue.currentTarget.value !== undefined) {
      value = eventOrValue.currentTarget.value;
    } else if (possibleSemanticUiValue && possibleSemanticUiValue.value) {
      value = possibleSemanticUiValue.value;
    } else 
    // else just use the value coming through unless it still looks like an event
    if (eventOrValue !== undefined && !isEvent()) {
      value = eventOrValue;
    }
    else {
      // use null to clear values not undefined because undefined doesn't serialize in JSON
      value = null;
    }

    return value;
  },

  blurHandler: function() {
    touchField(this.field.formData, this.field.props.name);
  }
};

export interface FieldProps<T = any> extends Partial<FieldAdapter<T>> {
  name?: keyof T;
  component?: React.ReactElement<any> | React.ComponentType<any>;
  touchOnChange?: boolean;
  validators?: FieldValidators<T, keyof T>;
  adapter?: FieldAdapter<T>;
  required?: boolean;
}

@observer
export class Field<T = any> extends React.Component<FieldProps<T>> {
  get formData(): FormData<T> {
    return this.context;
  }

  componentWillMount() {
    if (this.props.validators || this.props.required) {
      addFieldValidator(this.formData, this.props.name, this.validate);
    }
  }

  componentDidUpdate(prevProps: FieldProps<T>) {
    if (
      (prevProps.validators && this.props.validators) ||
      (!prevProps.validators && !this.props.validators) ||
      prevProps.required != this.props.required
    ) {
      return;
    }

    if (prevProps.validators || prevProps.required) {
      removeFieldValidator(this.formData, this.props.name, this.validate);
    }

    if (this.props.validators || this.props.required) {
      addFieldValidator(this.formData, this.props.name, this.validate);
    }
  }

  componentWillUnmount() {
    if (this.props.validators) {
      removeFieldValidator(this.formData, this.props.name, this.validate);
    }
  }

  get isElement(): boolean {
    return typeof this.props.component == 'object';
  }

  get element(): React.ReactElement {
    return typeof this.props.component != 'function'
      ? this.props.component
      : null;
  }

  get Component(): React.ComponentType {
    return typeof this.props.component == 'function'
      ? this.props.component
      : null;
  }

  getAdapter(): FieldAdapter<T> {
    const adapter = {
      ...standardAdapter,
      ...this.props.adapter,
      ...getFieldProps<T>(this.props)
    };

    adapter.field = this;

    return adapter;
  }

  render() {
    if (!this.props.component) {
      return this.props.children || '';
    }

    const adapter: FieldAdapter<T> = this.getAdapter();
    const props = adapter.props();

    return this.renderComponent(props);
  }

  renderComponent(props: any) {
    return this.isElement ? (
      React.cloneElement(this.element, props)
    ) : (
      <this.Component {...props} />
    );
  }

  validate = async (
    value: T[keyof T],
    info: FieldInfo<T, keyof T>
  ): Promise<ValidatorResult> => {
    if (!this.props.validators && !this.props.required) {
      return;
    }

    const validators = Array.isArray(this.props.validators)
      ? this.props.validators
      : this.props.validators
      ? [this.props.validators]
      : [];

    if (this.props.required) {
      validators.push(required);
    }

    const promises = validators.map(validator => validator(value, info));

    const results = await Promise.all(promises);
    const errors = flatten<ValidatorResult>(results).filter(
      result => typeof result === 'string'
    ) as string[];

    return errors;
  };

  onChange = (...args: any[]) => {
    const adapter = this.getAdapter();
    const value = adapter.changeHandler(...args);
    this.setFieldValue(value);

    const existingOnChage =
      (this.props as any)[adapter.onChangeProperty || 'onChange'] ||
      (this.isElement
        ? this.element.props[adapter.onChangeProperty || 'onChange']
        : undefined);

    if (existingOnChage) {
      existingOnChage(...args);
    }
  };

  onBlur = () => {
    const adapter = this.getAdapter();
    adapter.blurHandler();

    const existingOnBlur = (this.props as any)[
      adapter.onBlurProperty || 'onBlur'
    ];

    if (existingOnBlur) {
      existingOnBlur();
    }
  };

  setFieldValue(value: any) {
    const adapter = this.getAdapter();
    value = adapter.parse(value);

    setFieldValue(
      this.formData,
      this.props.name,
      value,
      !!this.props.touchOnChange
    );

    // due to react needing a synchronious update to avoid the selection jumping
    this.forceUpdate();
  }
}

Field.contextType = FormContext;

export function getFieldProps<T>(props: any): Required<FieldProps<T>> {
  const fieldProps: Required<FieldProps<T>> = {
    field: undefined,
    defaultValue: props.defaultValue,
    name: props.name,
    component: props.component,
    touchOnChange: props.touchOnChange,
    validators: props.validators,
    required: props.required,
    adapter: props.adapter,

    valueProperty: props.valueProperty,
    onChangeProperty: props.onChangeProperty,
    onBlurProperty: props.onBlurProperty,
    errorProperty: props.errorProperty,
    format: props.format,
    parse: props.parse,
    props: props.props,
    changeHandler: props.changeHandler,
    blurHandler: props.blurHandler
  };

  Object.keys(fieldProps).forEach(prop => {
    //@ts-ignore
    if (fieldProps[prop] === undefined) {
      //@ts-ignore
      delete fieldProps[prop];
    }
  });

  return fieldProps;
}

// separates Field properties from component properties
export function separateProps<T, O = any>(
  props: FieldProps<T> & Partial<O>
): { fieldProps: FieldProps<T>; otherProps: Partial<O> } {
  const fieldProps = getFieldProps<T>(props);
  const otherProps: Partial<O> = {};

  Object.keys(props).forEach((key: string) => {
    if (!(key in fieldProps)) {
      //@ts-ignore
      otherProps[key] = props[key];
    }
  });

  return { fieldProps, otherProps };
}
