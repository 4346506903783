import * as React from 'react';

import { RecurringRate } from 'app2/api';
import { Box, BoxProps, VBox } from 'app2/components';

import { formatRecurringRate } from './formatRecurringRates'

interface Props extends BoxProps {
  value?:RecurringRate[];
}

export function CourseRecurringRatesDisplay(props:Props) {
  const {value, ...remaining} = props;
  
  return  <VBox className='hr-value' {...remaining}>
    {value?.map?.((item, index) => <Box key={index} text='body'>{formatRecurringRate(item)}</Box>)}
  </VBox>
}

