import * as React from 'react';

import { observableManager } from '../../observable/ObservableManager';
import { isNonClassFunction } from '../../observable/util/isNonClassFunction';

import { ReactObserverAdapter } from './ReactObserverAdapter';

import { makeFunctionObserver } from './function/makeFunctionObserver';
import { makeClassObserver } from './class/makeClassObserver';

observableManager.observerAdapter = new ReactObserverAdapter();

export function observer<
  T extends React.FunctionComponent | React.ComponentClass<any>
>(component: T): T {
  return isNonClassFunction(component)
    ? (makeFunctionObserver(component as React.FunctionComponent) as T)
    : (makeClassObserver(component as React.ComponentClass) as T);
}
