import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type MessageRecipientFieldsFragment = {
  smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
  emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
  role?: string | null | undefined;
  to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
  toEntity?: { name?: string | null | undefined } | null | undefined;
  students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
};

export const MessageRecipientFieldsFragmentDoc = gql`
  fragment messageRecipientFields on MessageRecipient {
    to {
      id
      name
      __typename
    }
    toEntity {
      name
    }
    smsStatus
    emailStatus
    role
    students {
      id
      firstNameWithNickname
    }
  }
`;
