import * as React from 'react'

import { Button, Field, FieldInfo, Link, Modal, Panel, RepeatingSection, SaveableResult, formatDate } from 'app2/components'
import { CourseFrameworkModal, SeasonLink, SiteLink } from 'app2/views/shared'
import { NonePanel } from 'app2/views/shared-public'
import Community from 'images/Community.svg'

import { CompanyPage, useCompanyQuery } from '../shared'

import { archiveCourseFramework, CompanyFrameworksSelections, useCompanyFrameworksQuery } from './generated'

export function CourseFrameworks() {
  const {company} = useCompanyQuery(useCompanyFrameworksQuery);

  function render() {
    return <CompanyPage title='Activity templates'>
      <Panel icon='File' title='Activity templates' initialValues={company} primaryActions={renderAdd()}>
        <RepeatingSection name='courseFrameworks' none={renderNone()} width='100%' equalWidths={false} readOnly={false} onRemove={archiveFramework} fields={[
          <Field name='name' label='Name' width='20%' component={Link} onClick={showCourseFramework} />,
          <Field name='description' label='Description' maxLines={3} />,
          <Field name='lastUsedCourse' label='Last used' width='225px' format={renderLastUsed} />,
          'remove'
        ]} />
      </Panel>
    </CompanyPage>
  }

  function renderAdd() {
    // avoid two add buttons
    if (!company?.courseFrameworks?.length) {
      return;
    }

    return <Button icon='Plus' iconPosition='left' onClick={addCourseFramework}>Add activity template</Button>
  }

  function renderNone() {
    return <NonePanel description='An activity template is a blueprint for your classes. Fill in essential details now, so you won’t have to start from scratch next time.' 
      image={Community} add='Add activity template' onAdd={addCourseFramework} />
  }

  function renderLastUsed(course:CompanyFrameworksSelections['courseFrameworks'][0]['lastUsedCourse']) {
    if (!course) {
      return;
    }

    return course.season.site.partner
      ? <><SeasonLink season={course.season} inline /> at <SiteLink site={course.season.site} inline /></>
      : <>{formatDate(course.startDate, 'long')} at <SiteLink site={course.season.site} inline /></>
  }

  function showCourseFramework(_:React.MouseEvent, info:FieldInfo<CompanyFrameworksSelections>) {
    return <CourseFrameworkModal company={company.id} framework={info.record.id} />
  }

  function addCourseFramework() {
    return <CourseFrameworkModal company={company.id} />
  }

  async function archiveFramework(index:number) {
    const result = await Modal.warning("Remove activity template", "Are you sure you want to permanently delete this activity template?", true);

    if (result.action != SaveableResult.ok) {
      return
    }

    return archiveCourseFramework({variables:{items:[company.courseFrameworks[index].id]}})
  }

  return render();
}
