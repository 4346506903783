import * as React from 'react'
import { Shield } from '../..';

import { MouseCapture, captureMouse, Point } from '../../dom-utils';
import { TableSection } from '../../virtualized';

import { DataTable } from '../DataTable'

interface Props {
  table:DataTable;
  section:TableSection;
}

export class ColMoveManager extends React.Component<Props> {
  mouseCapture:MouseCapture;
  mouseDown:boolean;
  activeCol:number = -1;

  get table():DataTable {
    return this.props.table;
  }

  render() {
    const cursor = this.dragging
      ? 'grabbing' 
      : this.canDrag
        ? 'grab'
        : this.canSelect
          ? 'pointer'
          : undefined;

    // the shield while dragging ensures that we have a dragging cursor
    // over the entire screen
    return <div onMouseDown={this.onMouseDown} onMouseMove={this.onMouseMoveOrUp} onMouseUp={this.onMouseMoveOrUp} style={{cursor}}>
      {this.dragging ? <Shield nest type='shield' /> : undefined}
      {this.props.children}
    </div>
  }

  get dragging() {
    return this.mouseCapture != null;
  }

  get canDrag() {
    return this.canSelect && !this.mouseDown && this.isColSelected(this.activeCol) && !this.isColLocked(this.activeCol);
  }

  get canSelect() {
    return !this.dragging && this.activeCol != -1 && !this.isColLocked(this.activeCol);;
  }

  onMouseMoveOrUp = (event:React.MouseEvent<HTMLDivElement>) => {
    this.updateMouseDown(event);

    if (this.dragging) {
      return;
    }

    const col = this.getDragCol(event);

    if (this.activeCol != col) {
      this.activeCol = col;
      this.setState({});
    }
  }

  onMouseDown = (event:React.MouseEvent<HTMLDivElement>) => {
    this.updateMouseDown(event);

    let col = this.getDragCol(event);

    if (col == -1) {
      return;
    }

    if (!this.isColSelected(col)) {
      return;
    }

    // ensure that the drag col is the start of the selection
    // else it leads to unexepcted results, such as not all selected
    // columns being moved
    col = this.table.selection.topLeft.x + this.table.rowHeaderCount;

    event.preventDefault();
    event.stopPropagation();

    this.activeCol = col;
    this.mouseCapture = captureMouse(this.onDragMouseMove, this.onDragMouseUp, event, this.table.rootElement);
    this.setState({});
  }

  onDragMouseMove = (event:MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    this.scrollIfDraggingNearEdges(event);

    const table = this.table;
    const cell = table.getCellFromEvent(event);

    if (!cell) {
      return;
    }

    const selection = table.selection.clone();
    const dragToCol = Math.min(Math.max(table.rowHeaderCount, table.visibleTopLeft.col ,cell.pos.col), table.numCols - selection.numCols + 1);

    if (this.activeCol == dragToCol) {
      return;
    }

    const draggingRight = this.activeCol < dragToCol;

    if (!this.draggedFarEnough(draggingRight, dragToCol, event)) {
      return;
    }

    const start = draggingRight ? selection.numCols - 1 : 0;
    const end = draggingRight ? -1 : Math.min(selection.numCols, table.cols.length - 1);
    const increment = draggingRight ? -1 : 1;
    let cols = table.cols.slice();

    for (let colPos = start; colPos != end; colPos += increment) {
      const tempCols = cols.slice();
      const sourceCol = this.activeCol + colPos - table.rowHeaderCount;
      const destCol = dragToCol + colPos - table.rowHeaderCount;

      if (this.isColLocked(sourceCol + table.rowHeaderCount) || this.isColLocked(destCol + table.rowHeaderCount)) {
        return;
      }
  
      cols[sourceCol] = tempCols[destCol];
      cols[destCol] = tempCols[sourceCol];
    }

    if (table.props.onColMove) {
      if (table.props.onColMove(cols, Math.min(start, end), Math.abs(end - start), dragToCol) === false) {
        return;
      }
    }

    selection.offset(dragToCol - this.activeCol, 0);

    this.activeCol = dragToCol;
    table.updateCols(cols);
    table.selection = selection;

    table.props.onViewChange?.(table);
  }

  onDragMouseUp = (event:MouseEvent) => {
    this.updateMouseDown(event);

    event.preventDefault();
    event.stopPropagation();

    this.mouseCapture = null;
    this.setState({});
  }

  getDragCol(event:React.MouseEvent<HTMLDivElement>) { 
    if (!this.table.props.colMove) {
      return -1;
    }

    if (!this.table.rootElement.contains(event.target as HTMLElement)) {
      return -1;
    }

    const cell = this.table.getCellFromEvent(event);

    if (!cell) {
      return -1;
    }

    const isRowHeader = cell.pos.row < this.table.rowHeaderCount;

    if (!isRowHeader) {
      return -1;
    }

    const isTopLeft = cell.pos.col < this.table.rowHeaderCount;

    if (isTopLeft) {
      return -1;
    }

    if (this.isColLocked(cell.pos.col)) {
      return -1;
    }

    return cell.pos.col;
  }

  isColSelected(col:number) {
    return this.table.selection && this.table.selection.containsCol(col - this.table.rowHeaderCount) && this.table.selection.allRowsSelected;
  }

  isColLocked(col:number) {
    return col <= (this.table.rowHeaderCount + this.table.lockedCol)
  }

  updateMouseDown(event:React.MouseEvent<HTMLDivElement> | MouseEvent) { 
    const mouseDown = (event.buttons & 1) == 1;

    if (this.mouseDown != mouseDown) {
      this.mouseDown = mouseDown;
      this.setState({});
    }
  }

  draggedFarEnough(draggingRight:boolean, dragToCol:number, event:MouseEvent) {
    // the dragging column needs to far enough over 
    // the drag to column that the dragging column will fit
    // so as to prevent the two from jumping back and forth
    // for example:
    //  - dragging column - col 1, width 100 pixels
    //  - drag to column - col 2, width 500 pixels
    //
    //  if you swap the two while the cursor is in the
    //  first 100 pixels (the width of col 1) of column 2
    //  after they swap, they will swap right back because
    //  then after the swap col 1 will again hit col 2 
    //  (which is now in col 1 position).
    //  
    //  to avoid this scenario, we want to wait on swapping
    //  until the mouse is over to where the column would 
    //  be if the swap occured (not just over the drag to
    //  column). doing so will ensure that once the swap
    //  occurs, an immediately swap back doesn't occur

    const table = this.table;
    const r = table.element.getBoundingClientRect();
    const draggingRect = table.getCellCoordinates(new Point(this.activeCol, 0)).offset(r.left, r.top).offset(-this.props.section.coords.left, -this.props.section.coords.top);
    const dragToRect = table.getCellCoordinates(new Point(dragToCol, 0)).offset(r.left, r.top).offset(-this.props.section.coords.left, -this.props.section.coords.top);;

    const notYet = (draggingRight && event.clientX < dragToRect.right - draggingRect.width) || 
                   (!draggingRight && event.clientX > dragToRect.left + draggingRect.width);

    return !notYet;
  }

  scrollIfDraggingNearEdges(event:MouseEvent) {
    const r = this.table.scroller.getBoundingClientRect();
    const buffer = 100;

    if (event.clientX < r.left + buffer) {
      this.table.scroller.scrollLeft -= 20;
    }
    else
    if (event.clientX > r.right - buffer) {
      this.table.scroller.scrollLeft += 20;
    }
  }
}
