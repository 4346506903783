import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUpdateSiteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.SiteInput;
}>;

export type AdminUpdateSiteMutation = { siteUpdate?: { site?: { id: string } | null | undefined } | null | undefined };

export const AdminUpdateSiteDocument = gql`
  mutation AdminUpdateSite($id: ID!, $attributes: SiteInput!) {
    siteUpdate(id: $id, attributes: $attributes) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminUpdateSiteOptions = ExecMutationOptions<AdminUpdateSiteMutationVariables>;

export function adminUpdateSite(options: AdminUpdateSiteOptions) {
  return executeMutation<AdminUpdateSiteMutation, AdminUpdateSiteMutationVariables>(AdminUpdateSiteDocument, options);
}
