import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetGroupsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  groups: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type SetGroupsMutation = { enrollmentsSetGroups?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const SetGroupsDocument = gql`
  mutation SetGroups($ids: [ID!]!, $groups: [String!]!) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    enrollmentsSetGroups(ids: $ids, groups: $groups) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SetGroupsOptions = ExecMutationOptions<SetGroupsMutationVariables>;

export function setGroups(options: SetGroupsOptions) {
  return executeMutation<SetGroupsMutation, SetGroupsMutationVariables>(SetGroupsDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['RosterItem', 'Enrollment']) }
  });
}
