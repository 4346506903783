import { DataTableColumn } from 'app2/components';

import { discountDefinitionFields } from '../discounts';

export type EntityType = 'Site' | 'Season' | 'Course' | 'Company' | 'DiscountDefinition';

type FieldsFunction = (props:any) => DataTableColumn[];

const fields:Partial<Record<EntityType, FieldsFunction>> = {
  'DiscountDefinition': discountDefinitionFields
}

export function getFields(type:EntityType, props:any) {
  return fields[type](props);
}
