// use a global because of there are multiple versions
// of the library loaded each will get its own copy of the
// symbols causing interop issues

const observableSymbols: Map<string, Symbol> = new Map();

export function getSymbol(name: string) {
  let symbol = observableSymbols.get(name);

  if (!symbol) {
    symbol = Symbol(name);
    observableSymbols.set(name, symbol);
  }

  return symbol;
}
