import { axios } from 'app2/api';
import { observable } from 'app/observable';

import { CatalogResults } from './CatalogResults';
import { CategoryDetails } from './CategoryDetails';

interface PaginationMeta {
  provider_count: number;
}

type Catalogs = CatalogResults<PaginationMeta>;

@observable
export class CategoryStore {
  slug: string;
  pageSize: number;
  pageNumber: number;
  category: CategoryDetails;
  catalogs: Catalogs;

  load(slug: string) {
    const promises = [];

    if (this.slug != slug) {
      this.slug = slug;
      this.category = null;
      promises.push(this.loadCategory());
    }

    return promises;
  }

  loadCategoryPage(pageSize: number, pageNumber: number = 0) {
    if (this.pageSize == pageSize && this.pageNumber == pageNumber) {
      return;
    }

    this.pageSize = pageSize;
    this.pageNumber = pageNumber;

    return this.loadCatalogs();
  }

  async loadCategory() {
    try {
      const response = await axios.get<CategoryDetails>(
        `/course_tags/${this.slug}/seo_page`
      );

      if (response.data.redirect_to_slug) {
        window.location.replace(`/category/${response.data.redirect_to_slug}`);
        return;
      }

      this.category = response.data;
    } catch (_) {
      window.location.replace('/not-found');
    }
  }

  async loadCatalogs() {
    try {
      const response = await axios.get<Catalogs>(
        `/course_tags/${this.slug}/catalog?items_per_page=${
          this.pageSize
        }&page=${this.pageNumber + 1}`
      );
      this.catalogs = response.data;
    } catch (_) {}
  }
}
