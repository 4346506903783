import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteCreateMutationVariables = Types.Exact<{
  attributes: Types.SiteInput;
}>;

export type AdminSiteCreateMutation = { siteCreate?: { site?: { id: string } | null | undefined } | null | undefined };

export const AdminSiteCreateDocument = gql`
  mutation AdminSiteCreate($attributes: SiteInput!) {
    siteCreate(attributes: $attributes) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSiteCreateOptions = ExecMutationOptions<AdminSiteCreateMutationVariables>;

export function adminSiteCreate(options: AdminSiteCreateOptions) {
  return executeMutation<AdminSiteCreateMutation, AdminSiteCreateMutationVariables>(AdminSiteCreateDocument, options);
}
