import { colId, DataTableColumn } from 'app2/components';

import { ExpressionNode } from './ExpressionNode';
import { ComparisonOperator, ComparisonValue } from './ComparisonNode';
import { DateNode } from './DateNode';
import { TimeNode } from './TimeNode';

export function filterToString(expression:ExpressionNode, cols:DataTableColumn[]): string {
  if (!expression) {
    return '';
  }

  switch (expression.type) {
    case "Group": 
      return `(${filterToString(expression.expr, cols)})`
    case "RepeatingLogical":
      return `${expression.op}(${expression.expressions.map(e => filterToString(e, cols)).join(', ')})}`;
    case "Logical":
      return `${filterToString(expression.left, cols)} ${expression.op} ${filterToString(expression.right, cols)}`;
    case "Words":
      return `${expression.words.map(wordToString).join(', ')}`;
    case "Comparison":
      const col = cols.find(c => colId(c) == expression.left)
      const right = expression.right !== null ? valueToString(expression.right) : "";

      return `'${col.label || expression.left}' ${operatorToString(expression.op)} ${right}`;
    default:
      throw new Error("Unsupported expression type");
  }
}

function operatorToString(operator: ComparisonOperator): string {
  switch (operator) {
    case "EQ":
      return "=";
    case "NOT_EQ":
      return "!=";
    case "LT_EQ":
      return "<=";
    case "LT":
      return "<";
    case "GT_EQ":
      return ">=";
    case "GT":
      return ">";
    case "IN":
      return "in";
    case "BETWEEN":
      return "between";
    case "CONTAINS":
      return "contains";
    case "IS_EMPTY":
      return "is empty";
    case "IS_NOT_EMPTY":
      return "is not empty";
    default:
      throw new Error(`Unsupported operator: ${operator}`);
  }
}

function valueToString(value: ComparisonValue): string {
  if (Array.isArray(value)) {
    return `${value.map(v => valueToString(v)).join(', ')}`;
  }

  if (typeof value == 'boolean') {
    return value.toString();
  }

  if (typeof value === "string") {
    const quote = value.indexOf("'") != -1 ? '"' : "'"
    return `${quote}${value}${quote}`;
  }

  if (typeof value === "number") {
    return value.toString();
  }

  if (value.type === "Date") {
    return dateToString(value);
  }

  if (value.type === "Time") {
    return timeToString(value);
  }

  if (value.type === "DateTime") {
    return `${dateToString(value.date)} ${timeToString(value.time)}`;
  }

  if (value.type == 'Relative') {
    return `last ${value.num} ${value.units}`;
  }

  throw new Error("Unsupported value type");
}

function dateToString(date: DateNode): string {
  return `${date.month}/${date.day}/${date.year}`;
}

function timeToString(time: TimeNode): string {
  const minute = time.minute.toString().padStart(2, "0");
  const hour = time.hour.toString();
  return time.period && time.period !== "24-hour"
    ? `${hour}:${minute} ${time.period}`
    : `${hour}:${minute}`;
}

function wordToString(word:string) {
  if (word.indexOf('"') != -1 || word.indexOf(' ') != -1) {
    return `'${word}'`
  }

  if (word.indexOf("'") != -1) {
    return `"${word}"`
  }

  return word;
}