import { OperationResult, UseQueryState } from 'urql';

import { ErrorWithStringPath } from 'app2/api';
import { ErrorWithPath, ErrorHandler, applyErrorHandlers, errorHandlersFrom } from 'app2/components';

import { convertGraphQlErrors } from './convertGraphQlErrors';
import { parsePaths } from './parsePaths';
import { transformErrors, ErrorTransform } from './transformErrors';
import { modalErrorHandler } from './utils';

export interface ErrorHandlerInfo {
  handler?:ErrorHandler | ErrorHandler[];
  transform?:ErrorTransform[] | ErrorTransform;
};

export type ErrorHandlerInfoOrHandlers = ErrorHandlerInfo| (ErrorHandler | ErrorHandler[]);

export interface ErrorInfo {
  errors:ErrorWithPath[];
  unhandled:ErrorWithPath[];
  success:boolean;
}

export function handleErrors(handlerInfoOrHandlers:ErrorHandlerInfoOrHandlers, responseOrErrors:ErrorWithStringPath[] | Partial<OperationResult> | UseQueryState<any, any>, defaultHandler:ErrorHandler = modalErrorHandler) {
  try {
    const handlerInfo = getHandlerInfo(handlerInfoOrHandlers);
    const {untransformedErrors, input} = convertErrors(responseOrErrors);
    const transformedErrors = transformErrors(untransformedErrors, handlerInfo.transform, input);
    const errors = parsePaths(transformedErrors);

    const result = {errors, unhandled: errors, success: errors.length == 0};
    
    const errorHandlers = addDefaultErrorHandler(handlerInfo.handler, defaultHandler);
    result.unhandled = applyErrorHandlers(errorHandlers, result.errors);

    return result;
  }
  catch(e) {
    applyErrorHandlers(defaultHandler, [e])
    return {unhandled:[e], errors: [e], success: false};
  }
}

function getHandlerInfo(handlerInfo:ErrorHandlerInfoOrHandlers) {
  if (!handlerInfo) {
    return {handler:[]};
  }

  if (Array.isArray(handlerInfo) || (!(handlerInfo as ErrorHandlerInfo).handler && !(handlerInfo as ErrorHandlerInfo).transform)) {
    return {handler: handlerInfo as ErrorHandler | ErrorHandler[]};
  }
  else {
    return handlerInfo as ErrorHandlerInfo;
  }
}

function addDefaultErrorHandler(errorHandlerOrHandlers:ErrorHandler | ErrorHandler[], defaultHandler:ErrorHandler) {
  const errorHandlers = errorHandlersFrom(errorHandlerOrHandlers);

  if (defaultHandler) {
    errorHandlers.push(defaultHandler);
  }

  return errorHandlers;
}

function convertErrors(responseOrErrors:ErrorWithStringPath[] | Partial<OperationResult> | UseQueryState<any, any>) {
  return Array.isArray(responseOrErrors)
    ? {untransformedErrors: responseOrErrors, input: null}
    : {untransformedErrors:convertGraphQlErrors(responseOrErrors), input: responseOrErrors?.operation?.variables}
}

export function removeAuthorizationError(errors:ErrorWithPath[]) {
  return errors.filter(e => e.code != "AUTHORIZATION_ERROR")
}

export function removeAuthenticationError(errors:ErrorWithPath[]) {
  return errors.filter(e => e.code != "AUTHENTICATION_ERROR")
}

export function removeAuthError(errors:ErrorWithPath[]) {
  return errors.filter(e => e.code != "AUTHENTICATION_ERROR" && e.code != "AUTHORIZATION_ERROR")
}
